import { memo, useContext, useMemo } from 'react'

import { useObjectVal } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'

import { Dialog, LinearProgress } from '@mui/material'

import { type QuickGuiddeType } from 'app/types'
import {
    useAuth,
    useBrandKit,
    useConfiguration,
    useLocalStorage,
    useNotification,
    useQuery,
    useServiceUsage
} from 'hooks'
import { delay, rtdb } from 'modules'

import { enUScode, useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'
import { VideoStatusContext } from '../VideoStatusProvider'

import {
    type RTDBTransitionSequenceType,
    type WizardData,
    currentWizardPreferencesStorageKey,
    wizardPreferencesStorageKey,
    wizardTopAudioIdsStorageKey
} from './wizard-utils'
import { Wizard } from './Wizard'

const wizardSuccessMessage =
    "Your guide was successfully created! Don't forget to publish and share it"

type Props = {
    playbook: QuickGuiddeType
    onClose: () => void
}

export const WizardWrapper = memo(({ playbook, onClose }: Props) => {
    const { isUploadSuccess } = useContext(VideoStatusContext)

    const { showSuccessNotification } = useNotification()

    const { uid } = useAuth()
    const { authorizedData, isConfigurationLoading } = useConfiguration()
    const { hasVoiceOverTextToSpeech, hasStudioVoices, isLoading } = useServiceUsage()

    const [firstQuickGuiddeId] = useObjectVal<string | undefined>(
        ref(rtdb, `userActivity/${uid}/createdFirstQuickGuidde`)
    )

    const [currentWizardPreferences] = useLocalStorage<WizardData | null>(
        currentWizardPreferencesStorageKey,
        null
    )

    const [wizardPreferences] = useLocalStorage<WizardData | null>(
        wizardPreferencesStorageKey,
        null
    )

    const { getSpeakersList, getDefaultSpeaker } = useSpeakerOptions()
    const speakers = useMemo(() => {
        const languageCode = playbook.magicCapture?.language?.langCode || enUScode
        return getSpeakersList(languageCode)
    }, [getSpeakersList, playbook.magicCapture])

    const $transitions = useQuery<Array<RTDBTransitionSequenceType>>(
        '/c/v1/config/qg/transitions?sequence=true',
        { method: 'GET' },
        {
            revalidateIfStale: false,
            revalidateOnFocus: false
        }
    )

    const [topAudioIds] = useLocalStorage<Array<string>>(wizardTopAudioIdsStorageKey, [])
    const { brandKitData, brandKitLoading } = useBrandKit()
    const audioBackgrounds = useMemo(() => {
        const brandKitAudio = brandKitData?.audioBackground.data

        if (!brandKitAudio) {
            return null
        }

        return [
            ...topAudioIds.flatMap(
                topAudio => brandKitAudio.find(audio => audio.id === topAudio) ?? []
            ),
            ...brandKitAudio.filter(
                audio => !audio.isEditable && !topAudioIds?.some(topAudio => audio.id === topAudio)
            )
        ]
    }, [topAudioIds, brandKitData?.audioBackground.data])

    const wizardInitialData = useMemo(() => {
        const speakerPreferenceId =
            currentWizardPreferences?.speakerId ?? wizardPreferences?.speakerId
        const speakerPreferenceDetails = speakers.find(
            speaker => speaker.id === speakerPreferenceId
        )
        const confirmedSpeakerPreference =
            !speakerPreferenceDetails?.isStudio || hasStudioVoices ? speakerPreferenceId : null

        return {
            speakerId:
                (hasVoiceOverTextToSpeech
                    ? confirmedSpeakerPreference ?? getDefaultSpeaker(speakers)?.id
                    : null) ?? null,
            transitionName:
                currentWizardPreferences?.transitionName ??
                wizardPreferences?.transitionName ??
                // 0 index - None transition
                $transitions.data?.[1].name ??
                null,
            audioId:
                currentWizardPreferences?.audioId ??
                wizardPreferences?.audioId ??
                audioBackgrounds?.[0].id ??
                null,
            currentStep: currentWizardPreferences?.currentStep ?? 0
        }
    }, [
        currentWizardPreferences,
        wizardPreferences,
        speakers,
        hasStudioVoices,
        hasVoiceOverTextToSpeech,
        getDefaultSpeaker,
        $transitions.data,
        audioBackgrounds
    ])

    return (
        <Dialog open PaperProps={{ sx: { height: 650, maxWidth: 840, borderRadius: 3 } }} fullWidth>
            {speakers.length === 0 ||
            !$transitions.data ||
            brandKitLoading ||
            audioBackgrounds === null ||
            isLoading ||
            isConfigurationLoading ? (
                <LinearProgress />
            ) : (
                <Wizard
                    transitions={$transitions.data}
                    playbook={playbook}
                    speakers={speakers}
                    wizardPreferences={wizardPreferences}
                    wizardInitialData={wizardInitialData}
                    audioBackgrounds={audioBackgrounds}
                    topAudioBackgroundIds={topAudioIds}
                    onClose={() => {
                        const showConfetti = firstQuickGuiddeId === playbook.id && isUploadSuccess

                        // get the editor preview play button
                        const playButton = document.getElementById('play-button')
                        if (!playButton) return

                        if (!showConfetti) {
                            delay(3000).then(() => {
                                playButton.click()
                                showSuccessNotification(wizardSuccessMessage)
                            })
                            return
                        }

                        // Delay the confetti for a better user experience
                        delay(1000).then(onClose)
                        // Wait for the confetti to almost finish and start playing the video
                        // Reduce 40% from the total playing time of the confetti
                        const tweenDuration = Number(authorizedData?.confettiTime) || 0

                        delay(tweenDuration * 0.6).then(() => {
                            playButton.click()
                            showSuccessNotification(wizardSuccessMessage)
                        })
                    }}
                />
            )}
        </Dialog>
    )
})
