import { useState } from 'react'

import { styled } from '@mui/material/styles'
import FolderIcon from '@mui/icons-material/Folder'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import TheatersIcon from '@mui/icons-material/Theaters'

import { type FileType } from 'UI/Routes/integrations/Components/GDriveTableCell'

import msgraph from 'modules/msgraph'

const PREFIX = 'ItemThumbnail'

const classes = {
    thumbnailContainer: `${PREFIX}-thumbnailContainer`,
    thumbnail: `${PREFIX}-thumbnail`,
    thumbnailIcon: `${PREFIX}-thumbnailIcon`
}

const Root = styled('div')({
    [`& .${classes.thumbnailContainer}`]: {
        width: '3rem',
        height: '3rem',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.thumbnail}`]: {
        maxWidth: '44px',
        maxHeight: '44px'
    },
    [`& .${classes.thumbnailIcon}`]: {
        width: '44px',
        height: '44px',
        fill: '#b9d1fd'
    }
})

type Props = {
    file: FileType
}
export const ItemThumbnail = ({ file }: Props) => {
    const [couldNotFetchImg, setError] = useState(false)

    return (
        <Root className={classes.thumbnailContainer}>
            {file.kind === 'drive#drive' &&
                (file.id === 'root' ? (
                    <FolderIcon className={classes.thumbnailIcon} />
                ) : (
                    <FolderSharedIcon className={classes.thumbnailIcon} />
                ))}

            {!file.mimeType && file.kind !== 'drive#drive' && (
                <TheatersIcon className={classes.thumbnailIcon} />
            )}

            {file.mimeType === 'application/vnd.google-apps.folder' && (
                <FolderIcon className={classes.thumbnailIcon} />
            )}

            {(file.mimeType === 'video/mp4' || file.mimeType === 'video/quicktime') &&
                (file.hasThumbnail && !couldNotFetchImg ? (
                    <img
                        alt=""
                        src={file.thumbnailLink}
                        className={classes.thumbnail}
                        onError={() => setError(true)}
                    />
                ) : (
                    <TheatersIcon className={classes.thumbnailIcon} />
                ))}
        </Root>
    )
}

export const OneDriveItemThumbnail = ({ file }: any) => {
    return (
        <div className={classes.thumbnailContainer}>
            {msgraph.isFolder(file) ? (
                <FolderIcon className={classes.thumbnailIcon} />
            ) : (
                <TheatersIcon className={classes.thumbnailIcon} />
            )}
        </div>
    )
}
