import { Stack, Typography } from '@mui/material'

type Props = {
    areVideosNotCreated?: boolean
}

export const InsightsEmptyChartLabel = ({ areVideosNotCreated = true }: Props) => {
    return (
        <Stack p={3} sx={{ backgroundColor: '#ECECEC', borderRadius: '12px' }} alignSelf="center">
            <Typography
                fontSize={14}
                lineHeight="18px"
                fontStyle="italic"
                color="rgba(9, 12, 16, 0.6)"
                textAlign="center"
            >
                {areVideosNotCreated ? (
                    <>
                        Uh oh! Looks like you haven't created
                        <br />
                        any videos yet. Let's get started!
                    </>
                ) : (
                    'No data to display for the selected period'
                )}
            </Typography>
        </Stack>
    )
}
