import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'

import { Box, Button, Tooltip } from '@mui/material'

import { ConfirmationDialog, VideoStatusContext } from 'UI/Components'

import { round, uploadThumbnail, isDeepEqual } from 'modules'
import { useBoolean, useNotification, useAuth, useDataMutation } from 'hooks'

import { type SectionType } from 'ducks/reducers'
import { setInitialSections, setSectionsReset } from 'ducks/actions'

const OPERATION_TYPES = {
    TRIM: 'trim',
    DELETE: 'delete',
    CHAPTER: 'chapter'
}

type Props = {
    playbookId: string
    canvasElements: Array<HTMLCanvasElement>
    onUpdateTime: (seconds: number) => void
}

export const VideoEditorButtons = ({ playbookId, onUpdateTime, canvasElements }: Props) => {
    const { orgId, uid } = useAuth()

    const dispatch = useDispatch()

    const { checkVideoStatus, editable } = useContext(VideoStatusContext)

    const { showSuccessNotification } = useNotification()

    const loading = useBoolean()
    const confirmDialog = useBoolean()

    const { sections, initialSections, duration } = useSelector(state => state.videoEditor)

    const $updateChapters = useDataMutation('/b/v1/playbook/edit/metadata', 'POST', {
        onSuccess: () => {
            showSuccessNotification('Chapters updated successfully')
            checkVideoStatus()
            onUpdateTime(0)
            confirmDialog.setFalse()
            loading.setFalse()
        }
    })

    const updateAutoSuggestion = async (playbookId: string, sections: Array<SectionType>) => {
        loading.setTrue()
        dispatch(setInitialSections(sections))
        dispatch(setSectionsReset())

        const chapters = sections.map((section, idx: number) => {
            const start = round(duration * (section.rangeValues[0] / 100))
            const end = round(duration * (section.rangeValues[1] / 100))

            const isHidden = idx === 0 || idx === sections.length - 1

            return {
                operation: isHidden
                    ? OPERATION_TYPES.TRIM
                    : section.isDeleted
                      ? OPERATION_TYPES.DELETE
                      : OPERATION_TYPES.CHAPTER,
                start,
                end,
                title: section.title || `Chapter (${idx})`,
                thumbnail: section.thumbnail || '',
                applications: section.applications,
                tags: section.tags
            }
        })

        // generate link for each thumb
        const promises = canvasElements?.filter(Boolean).map(async (element, idx: number) => {
            await uploadThumbnail({
                playbookId,
                fileName: `${playbookId}-${idx}.png`,
                base64Data: element.toDataURL(),
                orgId,
                uid
            }).then(url => {
                // +1 as the first one is hidden
                chapters[idx + 1].thumbnail = url
                return url
            })
        })

        await Promise.all(promises)

        await $updateChapters.mutate({
            playbookId,
            chapters
        })
    }

    const showSaveChanges = !isDeepEqual(initialSections, sections)

    if (!showSaveChanges) return null

    return (
        <>
            <Prompt message="You have unsaved changes, if you leave, your changes will be lost" />

            <Box display="flex">
                <Box mr={1}>
                    <Button
                        disabled={loading.isTrue}
                        size="small"
                        color="inherit"
                        variant="contained"
                        onClick={() => {
                            dispatch(setSectionsReset())
                            onUpdateTime(0)
                        }}
                        data-test="cancel-button"
                    >
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <Tooltip title={editable ? '' : 'Video is still processing'}>
                        <span>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    const isFirstSectionChanged =
                                        sections[0].rangeValues[1] - sections[0].rangeValues[0] >
                                        0.1
                                    const isLastSectionChanged =
                                        sections[sections.length - 1].rangeValues[1] -
                                            sections[sections.length - 1].rangeValues[0] >
                                        0.1

                                    // only in case of trim (first or last chapter changed) we need to show the dialog, as trim process takes time
                                    // otherwise, this change will not take a lot of time (like changing the title), so we can send the request
                                    if (isFirstSectionChanged || isLastSectionChanged) {
                                        confirmDialog.setTrue()
                                        return
                                    }
                                    updateAutoSuggestion(playbookId, sections)
                                }}
                                disabled={loading.isTrue || !editable}
                                data-test="save-button"
                            >
                                Save
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <ConfirmationDialog
                isOpen={confirmDialog.isTrue}
                title="Save Changes"
                text="Your edits will be applied. We will notify you once ready in a few minutes"
                loading={loading.isTrue}
                onClose={confirmDialog.setFalse}
                onConfirm={() => {
                    updateAutoSuggestion(playbookId, sections)
                }}
            />
        </>
    )
}
