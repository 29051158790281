import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { Box, LinearProgress } from '@mui/material'

import { LaptopDrawer } from './LaptopDrawer'
import { MobileDrawer } from './MobileDrawer'

import { useOrgFeatureFlags, useGlobalFeatureFlags, useRoles } from 'hooks'

import { paths } from 'app/paths'

const pathsWithoutMenu = [
    paths.publicPlaybook,
    paths.publicPlaylist,
    paths.welcome,
    paths.onboarding,
    paths.extUninstalled
]

export const Menu = () => {
    const { roles } = useSelector(state => state.appAuth)

    const { isViewer } = useRoles()

    const orgFeatureFlagsLoading = useOrgFeatureFlags()?.isLoading
    const globalFeatureFlagsLoading = useGlobalFeatureFlags()?.isLoading

    const hideMenu = pathsWithoutMenu.filter(path => useRouteMatch(path)?.isExact).length

    if (!roles?.o || orgFeatureFlagsLoading || globalFeatureFlagsLoading) {
        return <LinearProgress />
    }

    if (hideMenu) return null

    if (roles.n && !isViewer) return null // do not show menu on onboarding page

    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <LaptopDrawer />
            </Box>

            <Box sx={{ display: { md: 'none' } }}>
                <MobileDrawer />
            </Box>
        </>
    )
}
