import { type ReactNode, memo } from 'react'

import clsx from 'clsx'

import { styled, Avatar, Box, Grid, Typography } from '@mui/material'

import { ReactComponent as AutoAwesome } from 'assets/icons/auto-awesome.svg'

import { SpacedGroup } from 'UI/Components'
import { OPTION_TYPES, SPACE_GROUP_VALUES } from './const'

const PREFIX = 'SelectedOption'

const classes = {
    small: `${PREFIX}-small`,
    medium: `${PREFIX}-medium`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.small}`]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft: theme.spacing(0.5),

        '& img': {
            objectFit: 'contain'
        }
    },

    [`& .${classes.medium}`]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        padding: '3px',

        '& img': {
            objectFit: 'contain'
        }
    }
}))

type Props = {
    option: any
    controls?: ReactNode
}

export const SelectedOption = memo(({ option, controls }: Props) => {
    return (
        <StyledGrid container alignItems="center">
            <Grid item xs={10}>
                <SpacedGroup>
                    <Avatar
                        src={option.logo}
                        className={clsx(
                            option.group === 'Spaces' && option.logo
                                ? classes.medium
                                : classes.small
                        )}
                    />
                    <Typography variant="body2">
                        {option.group === OPTION_TYPES.SPACES && 'Anyone at '}
                        <Typography variant="subtitle2" component="span" style={{ lineHeight: 1 }}>
                            <b>{option.label || ''}</b>
                            <Typography
                                component="p"
                                variant="subtitle2"
                                color="textSecondary"
                                style={{ lineHeight: 1 }}
                            >
                                {option.group !== OPTION_TYPES.SPACES && option.value}
                            </Typography>
                        </Typography>
                        {option.group === OPTION_TYPES.SPACES &&
                            !Object.values(SPACE_GROUP_VALUES).includes(option.value) && (
                                <b>Space</b>
                            )}
                    </Typography>
                </SpacedGroup>
            </Grid>

            <GridItem display={option.disabled && option.group === OPTION_TYPES.SPACES}>
                <Typography variant="body2">
                    {option.value === SPACE_GROUP_VALUES.CONNECTED ? 'admin only' : 'view only'}
                </Typography>
            </GridItem>

            <GridItem display={option.owner && option.group === OPTION_TYPES.USERS}>
                <Typography variant="body2">Owner</Typography>
            </GridItem>

            <GridItem display={option.suggested && option.group !== OPTION_TYPES.USERS}>
                <Typography variant="body2">
                    <AutoAwesome style={{ width: 20, opacity: 0.6 }} /> suggested
                </Typography>
            </GridItem>
            <GridItem display={Boolean(controls)}>{controls}</GridItem>
        </StyledGrid>
    )
})

type GridItemProps = {
    children: ReactNode
    display: boolean
}
const GridItem = ({ display, children }: GridItemProps) => {
    if (!display) return null

    return (
        <Grid item xs={2}>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                {children}
            </Box>
        </Grid>
    )
}
