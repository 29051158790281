import * as yup from 'yup'
import { Form, Formik } from 'formik'

import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { ConnectedInput } from 'UI/Components'

type SubmitValuesType = {
    name: string | null
    url: string
}
type Props = {
    onSubmit: (data: SubmitValuesType) => void
    onClose: () => void
}

export const LinkForm = ({ onSubmit, onClose }: Props) => {
    const link =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ name: null, url: '' }}
            validationSchema={yup.object().shape({
                name: yup.string().trim().nullable(),
                url: yup
                    .string()
                    .trim()
                    .nullable()
                    .required('Field is required')
                    .matches(link, 'Valid link is required')
            })}
        >
            {({ dirty, isValid, isInitialValid }) => (
                <Form>
                    <DialogTitle>
                        <Typography
                            data-test="add-link"
                            sx={theme => ({
                                fontSize: '1.5rem',
                                textAlign: 'center',
                                fontWeight: theme.typography.fontWeightBold
                            })}
                        >
                            Add Link
                        </Typography>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            overflow: 'hidden'
                        }}
                    >
                        <ConnectedInput
                            name="name"
                            placeholder="Enter title"
                            width="400px"
                            data-test="name-input"
                        />
                        <Box mt={3}>
                            <ConnectedInput
                                name="url"
                                placeholder="Enter URL"
                                width="400px"
                                data-test="url-input"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ p: 3, justifyContent: 'center' }}>
                        <Button
                            size="medium"
                            type="button"
                            onClick={onClose}
                            variant="contained"
                            sx={{
                                backgroundColor: '#e0e0e0',
                                color: 'common.black',
                                '&:hover': {
                                    backgroundColor: '#e0e0e0'
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="medium"
                            type="submit"
                            disabled={(dirty && !isValid) || (!dirty && !isInitialValid)}
                            data-test="add-button"
                            variant="contained"
                        >
                            <b>Add</b>
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    )
}
