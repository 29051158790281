import * as types from 'ducks/types'

import { type ActionType, type Reducer } from 'ducks/common'

export type UploadType = 'video' | 'playlist'

export type UploadSource = 'upload' | 'gdrive' | 'onedrive' | 'youtube' | 'zoom'

export type UploadFile = {
    id: string
    name: string
    progress?: number
    thumbnail?: string
    source: UploadSource
    type: UploadType
}

export type UploadFiles = {
    [key: string]: UploadFile
}

type StateType = {
    isOpen: boolean
    files: UploadFiles
}

const initialState: StateType = {
    isOpen: false,
    files: {}
}

export const uploadPopupReducer: Reducer<StateType> = (
    state = initialState,
    action: ActionType
) => {
    switch (action.type) {
        case types.OPEN_UPLOAD_PROGRESS_POPUP: {
            const { payload } = action

            return {
                ...state,
                files: { ...state.files, ...payload.files },
                isOpen: true
            }
        }
        case types.UPDATE_FILE_PROGRESS: {
            const { fileId, progress } = action.payload

            return {
                ...state,
                files: { ...state.files, [fileId]: { ...state.files[fileId], progress } }
            }
        }
        case types.CLOSE_UPLOAD_PROGRESS_POPUP: {
            return initialState
        }
        default: {
            return state
        }
    }
}
