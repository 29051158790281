import { memo, useEffect, useState, type ChangeEvent } from 'react'

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    capitalize,
    Divider,
    FormControlLabel,
    IconButton,
    Typography,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ArrowIcon from '@mui/icons-material/ArrowForward'

import { ReactComponent as MergeIcon } from 'assets/icons/merge.svg'

import { FullScreenLoader, PlaybookSearch, SpacedGroup } from 'UI/Components'

import { QuickGuiddeCard, type QGCardItemType } from './QuickGuiddeCard'

import { type QuickGuiddeType, type SearchPlaybookType } from 'app/types'

const StyledButton = styled(Button)({
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.40) !important',
    '& .Mui-disabled': {
        color: '#FFF !important',
        opacity: 0.5,
        background: '#CB0000 !important'
    }
})

const mergeParamsInitialState = {
    cover: true,
    ending: false
}

export type MergeParamsType = {
    mergeFrom: string
    cover: boolean
    ending: boolean
}

type Props = {
    isOpen: boolean
    isLoading: boolean
    mergeToQG: QuickGuiddeType
    onClose: () => void
    onSubmit: (data: MergeParamsType) => Promise<void>
}

export const MergeQGsDialog = memo(({ mergeToQG, isOpen, isLoading, onClose, onSubmit }: Props) => {
    const [mergeFromQG, setMergeFromQG] = useState<QGCardItemType | null>(null)
    const [mergeParams, setMergeParams] = useState(mergeParamsInitialState)

    useEffect(() => {
        if (isOpen) return

        setMergeParams(mergeParamsInitialState)
        setMergeFromQG(null)
    }, [isOpen])

    const handleMerge = () => {
        if (!mergeFromQG) return

        onSubmit({
            ...mergeParams,
            mergeFrom: mergeFromQG.id
        })
    }

    const onPlaybookSelect = (pb: SearchPlaybookType) => {
        setMergeFromQG({
            id: pb.id,
            duration: pb.duration,
            title: pb.title,
            screenshotUrl: pb.img,
            visibility: pb.visibility
        })
    }

    const onMergeParamChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setMergeParams(prev => ({
            ...prev,
            [e.target.name]: checked
        }))
    }

    if (isLoading) {
        return (
            <FullScreenLoader
                isOpen={true}
                title="Hang tight!"
                text="We’re importing the guide steps"
                dataCy="merge-qg-dialog-loader"
            />
        )
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            data-test="merge-qg-dialog"
            transitionDuration={0}
            PaperProps={{
                style: { overflow: 'visible' }
            }}
        >
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1}
                    pt={2}
                >
                    <Typography style={{ fontWeight: 500, fontSize: 20 }}>Import guides</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon fontSize="small" style={{ color: '#090C10' }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style={{ overflow: 'visible', padding: '4px 32px 16px' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <QuickGuiddeCard quickGuidde={mergeFromQG} dataCyPrefix="search" />
                    <Box bgcolor="#F5F5F5" width={40} height={40} borderRadius="6px" padding={1}>
                        <ArrowIcon color="primary" />
                    </Box>
                    <QuickGuiddeCard quickGuidde={mergeToQG} dataCyPrefix="root" />
                </Box>

                <Box mt={3} />
                <PlaybookSearch
                    onResultClick={(_, pb) => onPlaybookSelect(pb)}
                    compactMaxHeight="calc(50vh - 140px)"
                    compactResults={true}
                    mb={0.5}
                    searchParams={{
                        modes: ['quickGuidde'],
                        copy: true
                    }}
                    placeholder="Search for a video"
                />
                <Typography variant="caption" style={{ color: 'rgba(9, 12, 16, 0.60)' }}>
                    No changes will be made to the selected guide
                </Typography>

                <SpacedGroup mt={2}>
                    <Typography
                        style={{ fontWeight: 700, color: !mergeFromQG ? '#BDBDBD' : 'inherit' }}
                    >
                        Include
                    </Typography>
                    <MergeCheckbox
                        checked={mergeParams.cover}
                        disabled={!mergeFromQG}
                        name="cover"
                        minWidth={42}
                        onChange={onMergeParamChange}
                    />
                    <MergeCheckbox
                        checked={mergeParams.ending}
                        disabled={!mergeFromQG}
                        name="ending"
                        onChange={onMergeParamChange}
                    />
                </SpacedGroup>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Box width="100%" justifyContent="space-between" display="flex" px={3} py={1}>
                    <StyledButton
                        onClick={onClose}
                        sx={{
                            color: 'rgba(9, 12, 16, 0.60)'
                        }}
                        variant="contained"
                        size="large"
                        color="inherit"
                    >
                        CANCEL
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!mergeFromQG}
                        sx={{
                            '&.Mui-disabled': {
                                background: '#CB0000 !important',
                                opacity: 0.5,
                                color: '#fff'
                            }
                        }}
                        onClick={handleMerge}
                        startIcon={<MergeIcon />}
                        data-test="merge-qg-dialog-button"
                    >
                        IMPORT
                    </StyledButton>
                </Box>
            </DialogActions>
        </Dialog>
    )
})

type MergeCheckboxProps = {
    checked: boolean
    name: string
    disabled: boolean
    minWidth?: number
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const MergeCheckbox = ({ checked, name, disabled, minWidth, onChange }: MergeCheckboxProps) => (
    <FormControlLabel
        disabled={disabled}
        control={
            <Checkbox
                checked={checked}
                onChange={onChange}
                color="primary"
                size="small"
                inputProps={{
                    id: `merge-qg-checkbox-${name}`
                }}
            />
        }
        name={name}
        label={
            <Typography
                style={{
                    minWidth,
                    fontWeight: checked ? 700 : 400,
                    color: disabled ? '#BDBDBD' : 'inherit'
                }}
            >
                {capitalize(name)}
            </Typography>
        }
        labelPlacement="end"
    />
)
