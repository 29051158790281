import { useMemo } from 'react'

import { bytesToMB } from 'modules'

const MAX_UPLOAD_FILE_SIZE_IN_BYTES = 3221225472 // 3 GB

export const useUploadFileMaxLimit = () => {
    return useMemo(
        () => ({
            maxUploadFileSizeInBytes: MAX_UPLOAD_FILE_SIZE_IN_BYTES,
            maxUploadFileSizeInMb: bytesToMB(MAX_UPLOAD_FILE_SIZE_IN_BYTES)
        }),
        []
    )
}
