import { type KeyboardEvent, memo, useEffect, useState } from 'react'

import { Box, ClickAwayListener, IconButton, TextField, Typography, styled } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import { ConfirmationDialog, DictionaryWrapper } from 'UI/Components'
import { type Subtitle } from './TranscriptTypes'

import { logToAnalytics, formatTime } from 'modules'
import { useBoolean, useDataMutation, useNotification } from 'hooks'
import { type PlaybookType } from 'app/types'

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    '& .MuiInputBase-root.Mui-disabled': {
        color: 'inherit',
        padding: theme.spacing(0, 1)
    },
    '& textarea': {
        lineHeight: 2,
        fontSize: '12px',
        margin: theme.spacing(1, 0)
    }
}))
const SubtitleBlock = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '8px',
    borderRadius: '4px',
    margin: '8px 0',
    padding: '4px 8px',
    fontSize: '12px'
})

type Props = {
    subtitle: Subtitle
    playbook: PlaybookType
    isActive: boolean
    searchWords: string
    onClick: () => void
}

export const EditableSegment = memo(
    ({ subtitle, playbook, isActive, onClick, searchWords }: Props) => {
        const { showSuccessNotification } = useNotification()

        const edit = useBoolean()
        const hovered = useBoolean()
        const isOpen = useBoolean()
        const isContinueEdit = useBoolean()

        const [subtitleText, setSubtitleText] = useState<string>(subtitle.text)

        const $edit = useDataMutation('/c/v1/transcript/edit', 'POST', {
            onSuccess: () => showSuccessNotification(`Updated successfully`)
        })

        useEffect(() => {
            setSubtitleText(subtitle.text)
        }, [subtitle.text])

        const handleTranscriptEdit = () => {
            const updateItem = {
                playbookId: playbook.id,
                segmentIndex: subtitle.segmentIndex,
                subtitleIndex: subtitle.subtitleIndex,
                text: subtitleText
            }
            $edit.mutate(updateItem).then(() => {
                logToAnalytics('edit_transcript', updateItem)
            })
            edit.setFalse()
        }

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.keyCode === 27) {
                cancelEdit()
            }
        }

        const cancelEdit = () => {
            setSubtitleText(subtitle.text)
            edit.setFalse()
            isOpen.setFalse()
            isContinueEdit.setFalse()
        }

        /*
           In case the user clicks away from the text field, we want to warn him
           on data lose. We also want to make sure that the user can continue editing if needed.

           If the data changed and the user clicked away a confirmation dialog will appear.
           Cancel - user stays in edit mode and does not lose the new text
           Confirm - Edit mode is closed and changes were lost
       */
        const handleClickAway = () => {
            // text has changed
            if (subtitleText !== subtitle.text) {
                if (isContinueEdit.isFalse) {
                    isOpen.setTrue()
                    return
                }
            }
            // text is the same, close edit mode
            if (isContinueEdit.isFalse) {
                cancelEdit()
            }
        }

        return (
            <>
                <ClickAwayListener
                    onClickAway={() => {
                        if (edit.isTrue) handleClickAway()
                    }}
                >
                    {/* Subtitle text and timestamp container */}
                    <SubtitleBlock
                        onMouseEnter={hovered.setTrue}
                        onMouseLeave={hovered.setFalse}
                        onKeyDown={handleKeyDown}
                        sx={{
                            border: isActive ? 'solid yellow 1px' : 'none',
                            background: isActive ? '#FAFAFA' : 'transparent'
                        }}
                    >
                        {edit.isTrue ? (
                            // Display subtitle text in a text field for editing
                            // ok and cancel edit icons
                            <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                <Box alignSelf="end">
                                    <IconButton
                                        style={{ padding: '1px' }}
                                        onClick={handleTranscriptEdit}
                                    >
                                        <CheckIcon
                                            style={{
                                                width: '15px',
                                                height: '15px'
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton style={{ padding: '1px' }} onClick={cancelEdit}>
                                        <CloseIcon
                                            style={{
                                                width: '15px',
                                                height: '15px'
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                                <StyledTextField
                                    multiline
                                    // used for preventing video jumping bug
                                    onKeyDown={e => e.stopPropagation()}
                                    variant="outlined"
                                    value={subtitleText}
                                    onChange={e => setSubtitleText(e.target.value)}
                                    onClick={() => {
                                        isContinueEdit.setFalse()
                                        isOpen.setFalse()
                                    }}
                                    fullWidth
                                />
                            </Box>
                        ) : (
                            // Display subtitle text in view mode
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                style={{ width: '100%' }}
                            >
                                <DictionaryWrapper
                                    text={subtitle.text}
                                    subtitle={subtitle}
                                    searchWords={searchWords}
                                    applications={playbook.applications}
                                    transcriptLanguage={playbook.currentTranscriptLang}
                                    showAddToDictionary={hovered.isTrue} // we need to show "add to dictionary" only in edit mode
                                />

                                {/*Edit icon shown on subtitle hover*/}
                                <Box>
                                    <IconButton
                                        size="small"
                                        style={{
                                            visibility:
                                                hovered.isTrue && edit.isFalse
                                                    ? 'visible'
                                                    : 'hidden'
                                        }}
                                        onClick={edit.setTrue}
                                    >
                                        <EditIcon
                                            style={{
                                                width: '15',
                                                height: '15'
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                        <Typography
                            sx={{
                                fontSize: '12px',
                                marginRight: '4px',
                                cursor: 'pointer',
                                color: '#CB0000',
                                fontWeight: 500
                            }}
                            onClick={onClick}
                        >
                            {formatTime(subtitle.start)}
                        </Typography>
                    </SubtitleBlock>
                </ClickAwayListener>
                <ConfirmationDialog
                    title="Edit Transcript"
                    text="The edit changes you made will be lost, are you sure you want to continue without saving ?"
                    isOpen={isOpen.isTrue}
                    onClose={() => {
                        isContinueEdit.setTrue()
                        isOpen.setFalse()
                    }}
                    onConfirm={() => {
                        setSubtitleText(subtitle.text)
                        cancelEdit()
                    }}
                    confirmBtnText="Confirm"
                />
            </>
        )
    }
)
