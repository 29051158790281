import { memo } from 'react'

import { styled, Box, Dialog, Typography, Button } from '@mui/material'

import { SpacedGroup } from 'UI/Components'

import { ReactComponent as CheckIcon } from 'assets/icons/custom-check.svg'
import { ReactComponent as SpaceIcon } from 'assets/icons/space.svg'

import Image from 'assets/images/first_space.png'

import { useWindowView } from 'hooks'

const PREFIX = 'FirstSpaceCreatingDialog'

const classes = {
    paper: `${PREFIX}-paper`,
    section: `${PREFIX}-section`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.paper}`]: { maxWidth: 800 },

    [`& .${classes.section}`]: {
        width: '50%',
        minHeight: 380,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            padding: '40px',
            width: '100%',
            minHeight: 180
        }
    }
}))

const list = [
    'Organize spaces by teams, projects, or any type of group that works for you.',
    'Members can join or leave spaces, share videos or playlists, and interact with one another.',
    'Space members are notified of relevant space activities to maintain team engagement.'
]

type Props = {
    isOpen: boolean
    onClose: () => void
    onCreate: () => void
}

export const FirstSpaceCreatingDialog = memo(({ isOpen, onClose, onCreate }: Props) => {
    const { isMobileView } = useWindowView()

    return (
        <StyledDialog fullWidth classes={{ paper: classes.paper }} open={isOpen} onClose={onClose}>
            <Box
                display="flex"
                height="100%"
                flexDirection={isMobileView ? 'column-reverse' : 'row'}
            >
                <img src={Image} width={isMobileView ? '100%' : 400} />
                <Box
                    className={classes.section}
                    style={{
                        padding: isMobileView ? '20px' : '40px 40px 30px 70px',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: isMobileView ? 'center' : 'flex-start'
                    }}
                >
                    <Box>
                        <Typography style={{ fontWeight: 500, fontSize: '20px' }}>
                            Streamline Your Team’s
                        </Typography>
                        <Typography style={{ fontWeight: 500, fontSize: '20px' }}>
                            Communication
                        </Typography>
                        {!isMobileView && (
                            <>
                                <Box mt={1} />

                                <Typography variant="caption" color="textSecondary">
                                    Bring your team and how-tos together in spaces
                                </Typography>

                                <Box>
                                    {list.map((item, i) => (
                                        <SpacedGroup
                                            key={i}
                                            mt={2.3}
                                            spacing={1}
                                            alignItems="flex-start"
                                        >
                                            <Box
                                                bgcolor="#73CC6D"
                                                minWidth={20}
                                                height={20}
                                                borderRadius="50%"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                mt={0.5}
                                            >
                                                <CheckIcon />
                                            </Box>
                                            <Typography variant="caption">{item}</Typography>
                                        </SpacedGroup>
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>

                    <Box mt="20px">
                        <Button
                            data-test="spaces-popup-create-first-space-button"
                            variant="contained"
                            onClick={() => {
                                onCreate()
                                onClose()
                            }}
                            fullWidth={isMobileView}
                            startIcon={<SpaceIcon style={{ color: 'inherit' }} />}
                            style={{
                                whiteSpace: 'nowrap',
                                letterSpacing: 1.25
                            }}
                        >
                            CREATE YOUR FIRST SPACE
                        </Button>
                    </Box>
                </Box>
            </Box>
        </StyledDialog>
    )
})
