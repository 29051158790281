import {
    type KeyboardEvent,
    type FocusEvent,
    type ChangeEvent,
    useState,
    useRef,
    memo,
    useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { styled, Box, Typography } from '@mui/material'
import { SpacedGroup } from 'UI/Components'

import { formatTime, timeToSeconds, roundToHundredth } from 'modules'

import { useBoolean, useNotification } from 'hooks'
import { setSections } from 'ducks/actions'

const percentToTime = (duration: number, percent = 0) => Math.round((duration / 100) * percent)

const objToSeconds = (obj: { hours: string; minutes: string; seconds: string }) =>
    timeToSeconds(Object.values(obj).join(':'))

const timeToPercent = (time: number, duration: number) => roundToHundredth((time * 100) / duration)

const generateValue = (duration: number) => {
    const roundedDuration = Math.floor(duration)
    const data = formatTime(roundedDuration).split(':')

    if (data.length < 3) data.unshift('00')

    return {
        hours: data[0],
        minutes: data[1],
        seconds: data[2]
    }
}

const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ffffff',
    borderRadius: '15px',
    padding: '5.5px 10px'
})

const StyledInput = styled('input')({
    fontSize: '12px',
    border: '1px solid #e0e0e0',
    width: '28px',
    boxShadow: 'none',
    background: 'transparent',
    outline: 'none',
    fontFamily: 'Roboto, sans-serif',
    '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    },
    margin: '0 4px'
})

export const TrimTimePickers = () => {
    const dispatch = useDispatch()

    const { duration, sections } = useSelector(state => state.videoEditor)

    // we take sections?.[1] as [0] is hidden, same as the last part
    const startTime = percentToTime(duration, sections?.[1]?.rangeValues?.[0])

    const endTime = percentToTime(
        duration,
        sections?.reduce((acc, it, i) => {
            const getPercentage = (section: { rangeValues: Array<number> }) =>
                section?.rangeValues?.[1] - section?.rangeValues?.[0]

            return i === sections.length - 1 ? acc : acc + getPercentage(it)
        }, 0)
    ) // calculate sum of rangeValues inside sections property

    const onChangeStart = (time: number) => {
        const percent = timeToPercent(time, duration)

        const newParts = sections.map((section, idx) => {
            if (idx === 0) {
                return {
                    rangeValues: [0, percent],
                    title: '',
                    thumbnail: '',
                    start: 0,
                    end: 0
                }
            }
            if (idx === 1) {
                return {
                    ...section,
                    rangeValues: [percent, section.rangeValues[1]]
                }
            }
            return section
        })

        dispatch(setSections(newParts))
    }

    const onChangeEnd = (time: number) => {
        const percent = timeToPercent(time, duration)

        const newParts = sections.map((part, idx) => {
            // one before the last
            if (idx === sections.length - 2) {
                return {
                    ...part,
                    rangeValues: [part.rangeValues[0], percent] as [number, number]
                }
            }
            // the last element (hidden)
            if (idx === sections.length - 1) {
                return {
                    ...part,
                    rangeValues: [percent, 100],
                    title: '',
                    thumbnail: ''
                }
            }
            return part
        })

        dispatch(setSections(newParts))
    }

    const maxTimeStart = percentToTime(duration, sections?.[1]?.rangeValues[1])
    const minTimeEnd = percentToTime(duration, sections?.[0]?.rangeValues[1])

    const showHoursInput = generateValue(duration)?.hours !== '00'

    return (
        <SpacedGroup>
            <CustomTimePicker
                minTime={0}
                maxTime={Math.floor(maxTimeStart) - 1}
                label="Start time"
                value={startTime}
                onChange={onChangeStart}
                showHours={showHoursInput}
            />
            <CustomTimePicker
                minTime={Math.floor(minTimeEnd + 1)}
                maxTime={duration}
                value={endTime}
                label="End time"
                onChange={onChangeEnd}
                showHours={showHoursInput}
            />
        </SpacedGroup>
    )
}

type TimePickerProps = {
    value: number
    minTime: number
    maxTime: number
    label: string
    onChange: (_time: number) => void
    showHours: boolean
}

const CustomTimePicker = memo(
    ({ value, maxTime, minTime, label, onChange, showHours }: TimePickerProps) => {
        const { showWarningNotification } = useNotification()

        const containerFocussed = useBoolean()

        const [activeEl, setActiveEl] = useState<number | null>(null)

        const [time, setTime] = useState(generateValue(value))

        const hoursRef = useRef<HTMLInputElement>(null)
        const minutesRef = useRef<HTMLInputElement>(null)
        const secondsRef = useRef<HTMLInputElement>(null)
        const containerRef = useRef<HTMLDivElement>(null)

        const [prevCaretPos, setPrevCaretPos] = useState<number | null>(null)

        const inputsList = [hoursRef, minutesRef, secondsRef]

        const refList = showHours ? inputsList : inputsList.slice(1)

        useEffect(() => {
            const time = generateValue(value)

            setTime(time)
        }, [value])

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            const { value, name, max } = e.target
            const number = +value.replace(/^0+/, '')

            if (!Number.isInteger(number) || number > Number(max)) return

            setTime(prev => ({
                ...prev,
                [name]: value
            }))
        }

        const changeFocus = (way: number) => {
            if (!activeEl) return

            const delta = showHours ? 0 : 1
            const index = activeEl + way - delta

            if (index < 3 && index > -1) {
                const el = refList[index]?.current

                if (!el) return
                const length = el.value.length

                el.focus()
                el.selectionStart = way === 1 ? length : 0
            }
        }

        const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
            const id = +(event.target.getAttribute('number') as string)

            containerFocussed.setTrue()
            setActiveEl(id)
        }

        const handleBlur = () => {
            containerFocussed.setFalse()

            setActiveEl(null)
            setPrevCaretPos(null)
        }

        const onKeyUp = (e: KeyboardEvent) => {
            if (e.keyCode === 13) {
                // if user press Enter
                ;(e.target as HTMLInputElement).blur()
                return
            }

            if (e.keyCode !== 37 && e.keyCode !== 39) return

            const { selectionStart, value } = e.target as HTMLInputElement

            const toRight = e.keyCode === 39
            const toLeft = e.keyCode === 37

            const length = `${value}`.length

            const onEnd = length === prevCaretPos
            const onStart = prevCaretPos === 0

            if (onEnd && toRight) changeFocus(1)
            if (onStart && toLeft) changeFocus(-1)

            setPrevCaretPos(selectionStart)
        }

        const resetTime = (text: string) => {
            showWarningNotification(text)
            setTime(generateValue(value))
        }

        const update = () => {
            const result = objToSeconds(time)

            if (result === value) return

            if (result > maxTime) {
                resetTime(`Time cannot be bigger than ${formatTime(maxTime)}`)
                return
            }

            if (result < minTime) {
                resetTime(`Time cannot be less than ${formatTime(minTime)}`)
                return
            }

            onChange(result)
        }

        return (
            <Box>
                <Typography
                    variant="caption"
                    sx={{
                        color: '#7f8c9a',
                        fontSize: '10px'
                    }}
                >
                    {label}
                </Typography>

                <Container
                    ref={containerRef}
                    onBlur={({ relatedTarget }) => {
                        if (!containerRef.current!.contains(relatedTarget)) {
                            update()
                        }
                    }}
                >
                    {showHours && (
                        <>
                            <StyledInput
                                ref={hoursRef}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                type="text"
                                // @ts-ignore custom attr
                                number={0}
                                step={1}
                                onClick={e => {
                                    setPrevCaretPos((e.target as HTMLInputElement).selectionStart)
                                }}
                                name="hours"
                                onChange={handleChange}
                                max={99}
                                value={time.hours}
                                maxLength={2}
                                onKeyUp={onKeyUp}
                            />
                            <b>:</b>
                        </>
                    )}
                    <StyledInput
                        ref={minutesRef}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        type="text"
                        // @ts-ignore custom attr
                        number={1}
                        step={1}
                        onClick={e => {
                            setPrevCaretPos((e.target as HTMLInputElement).selectionStart)
                        }}
                        name="minutes"
                        onChange={handleChange}
                        max={59}
                        value={time.minutes}
                        maxLength={2}
                        onKeyUp={onKeyUp}
                    />
                    <b>:</b>
                    <StyledInput
                        ref={secondsRef}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        type="text"
                        // @ts-ignore custom attr
                        number={2}
                        step={1}
                        onClick={e => {
                            setPrevCaretPos((e.target as HTMLInputElement).selectionStart)
                        }}
                        name="seconds"
                        onChange={handleChange}
                        max={59}
                        value={time.seconds}
                        maxLength={2}
                        onKeyUp={onKeyUp}
                    />
                </Container>
            </Box>
        )
    }
)
