import { type ReactNode, type CSSProperties, forwardRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Box, styled } from '@mui/material'

import { PlayControls } from '@guidde-co/shared.video-player.play-controls'

import { setDuration } from 'ducks/actions'

import { useBoolean, useQueryParams, useWindowView } from 'hooks'
import { round } from 'modules'

const HoverControls = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '20px',
    right: '23px',
    '& > *': {
        opacity: 0,
        transform: 'translateX(30px)',
        transition: 'opacity 0.1s linear, transform 0.1s linear',
        marginBottom: '4px',
        '&:nth-child(2)': {
            transitionDelay: '0.1s'
        },
        '&:nth-child(3)': {
            transitionDelay: '0.2s'
        },
        '&:nth-child(4)': {
            transitionDelay: '0.3s'
        },
        '&:nth-child(5)': {
            transitionDelay: '0.4s'
        }
    }
})

const PlayControlsWrapper = styled(Box)({
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 3,
    width: 65,
    height: 65
})

const VideoCorners = styled(Box)({
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
    position: 'relative'
})

const Container = styled(Box)({
    position: 'relative',
    width: '100%',
    overflow: 'hidden'
})

const videoStyles = {
    cursor: 'pointer',
    maxHeight: '60vh',
    display: 'block',
    background: 'black',
    width: '100%',
    height: 'auto'
}

const hoverVisibleStyles = {
    opacity: 1,
    zIndex: 1,
    '& > *': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'opacity 0.1s linear, transform 0.1s linear'
    }
}

type Props = {
    playbookUrl: string
    playbookId: string
    poster: string
    link: string
    style?: CSSProperties
    showCaptions?: boolean
    hoverControls?: ReactNode
    subtitlesUrl?: string
    controls?: boolean
    animationUrl?: string
    onPlay?: () => void
    onPause?: () => void
    onEnded?: () => void
    onLoadedData?: () => void
    onTimeUpdate?: (e: any) => void
}

export const videPlayerId = 'guidde-video-player'

type StatusType = 'loading' | 'playing' | 'stopped'

export const ControlledVideoPlayer = forwardRef<HTMLVideoElement | null, Props>(
    (
        {
            poster,
            playbookUrl,
            subtitlesUrl,
            hoverControls,
            showCaptions,
            playbookId,
            link,
            animationUrl,
            onPlay,
            onLoadedData,
            onTimeUpdate,
            onPause,
            onEnded,
            ...videoProps
        },
        ref: any
    ) => {
        const dispatch = useDispatch()

        const { isMobileView } = useWindowView()

        const hoverState = useBoolean()

        const [status, setStatus] = useState<StatusType>('loading')

        const params = useQueryParams()
        const t = params.get('t')

        useEffect(() => {
            if (!ref?.current || !t) return

            ref.current.currentTime = Number(t)
        }, [t, ref])

        return (
            <Container onMouseEnter={hoverState.setTrue} onMouseLeave={hoverState.setFalse}>
                <VideoCorners
                    onClick={() => {
                        if (
                            !ref?.current ||
                            status === 'loading' ||
                            videoProps.controls ||
                            // video player is in the full screen mode
                            document.fullscreenElement === ref.current
                        ) {
                            return
                        }

                        if (status === 'playing') ref.current.pause()
                        else ref.current.play()
                    }}
                >
                    <video
                        crossOrigin="anonymous"
                        ref={ref}
                        // using in share dialog inside `share tab` in `start at` input
                        id={videPlayerId}
                        style={videoStyles}
                        preload="auto"
                        controlsList="nodownload"
                        controls={false}
                        onContextMenu={e => e.preventDefault()}
                        onLoadedData={() => {
                            setStatus('stopped')
                            onLoadedData?.()
                        }}
                        onSeeking={() => setStatus('loading')}
                        onSeeked={(e: any) => setStatus(e.target.paused ? 'stopped' : 'playing')}
                        onDurationChange={(e: any) => {
                            dispatch(setDuration(round(e.target.duration)))
                        }}
                        onTimeUpdate={e => onTimeUpdate?.(e)}
                        onPlay={() => {
                            onPlay?.()
                            setStatus('playing')
                        }}
                        onPause={() => {
                            if (isMobileView) ref.current.controls = false

                            onPause?.()
                            setStatus('stopped')
                        }}
                        onEnded={() => onEnded?.()}
                        poster={animationUrl || poster}
                        {...videoProps}
                        src={playbookUrl}
                    >
                        {subtitlesUrl && showCaptions && (
                            <track
                                label="English"
                                kind="subtitles"
                                srcLang="en"
                                src={subtitlesUrl}
                                default
                            />
                        )}
                    </video>
                </VideoCorners>

                <PlayControlsWrapper>
                    {!isMobileView && (
                        <PlayControls
                            key={status}
                            onClick={() => {
                                if (status === 'playing') ref.current.pause()
                                else ref.current.play()
                            }}
                            visible={hoverState.isTrue && !videoProps.controls}
                            status={status}
                        />
                    )}
                </PlayControlsWrapper>

                <HoverControls sx={hoverState.isTrue ? hoverVisibleStyles : {}}>
                    {hoverControls}
                </HoverControls>
            </Container>
        )
    }
)
