export const SERVICE_STATUS = {
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    DONE: 'done',
    ERROR: 'error',
    SKIPPED: 'skipped',
    RETRY: 'retry',
    UPLOAD_STATUS: 'upload_process',
    TOO_MANY_UNTITLED: 'too_many_untitled'
} as const
