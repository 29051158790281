import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
    type SelectProps,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material'

import { SpacedGroup } from 'UI/Components'
import { FooterLinks } from 'UI/Routes/share/common'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ReactComponent as GuiddeLogo } from 'assets/icons/guidde_red.svg'

import { useDataMutation, useAuth, useNotification, useWindowView } from 'hooks'
import {
    createTafiliateCustomer,
    generalErrorMessage,
    getReferralCode,
    logToAnalytics
} from 'modules'

import { handleSessionRedirects } from '../signIn/utils'
import {
    type OnboardingStepPayload,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type PostOnboardingPayloadType,
    type RolesResponseType,
    type TopicType,
    OnboardingSteps
} from './PageOnboarding'

type Props = {
    data?: RolesResponseType
    $postOnboarding: ReturnType<typeof useDataMutation<PostOnboardingPayloadType, {}, Error>>
}

const selectMenuProps: SelectProps['MenuProps'] = {
    sx: {
        '& .Mui-selected': {
            backgroundColor: '#e0e0e0 !important',
            '&:hover': {
                backgroundColor: '#e0e0e0 !important'
            }
        }
    },
    MenuListProps: {
        style: {
            maxHeight: 305,
            overflowY: 'auto'
        }
    }
}

export const SelectRoles = ({ data, $postOnboarding }: Props) => {
    const { showErrorNotification } = useNotification()

    const history = useHistory()

    const { uid, orgId } = useAuth()

    const { isMobileView } = useWindowView()

    const [role, setRole] = useState('')
    const [other, setOther] = useState('')
    const [companySize, setCompanySize] = useState('')
    const [workspaceName, setWorkspaceName] = useState(data?.initialCompanyName || '')
    const [topics, setTopics] = useState<Array<TopicType>>([])

    const isExtensionInstalled = useSelector(state => state.extensionStatus.isInstalled)

    const $celloSignUp = useDataMutation('/c/v1/celloSignup', 'POST')

    const showOtherInput = topics.some(it => it.withExtraInput)
    const disableSave =
        $celloSignUp.isLoading ||
        $postOnboarding.isLoading ||
        !topics.length ||
        !role ||
        (data?.initialCompanyName && !workspaceName) ||
        (showOtherInput && !other) ||
        (data?.companySizes && !companySize)

    const handleStart = async () => {
        let result: OnboardingStepPayload = {
            activeStep: OnboardingSteps.Roles,
            data: {
                role,
                companySize,
                topics: topics.map(it => it.title),
                handledOnClient: true // temporary, hint to server that no need to send analytics event
            }
        }

        if (data?.initialCompanyName) {
            result.data.companyName = workspaceName
        }

        if (showOtherInput) {
            result.data.other = other
        }

        if (workspaceName) {
            logToAnalytics('teamDiscovery_team_created', {
                uid,
                orgId,
                orgName: workspaceName,
                page: 'onboarding'
            })
        }
        logToAnalytics('onboarding_letsStartBtn_clicked', {
            companySize: result.data.companySize,
            jobFunction: result.data.role,
            feature: [...result.data.topics, showOtherInput ? other : ''].filter(Boolean)
        })

        const referralId = await getReferralCode()

        $postOnboarding
            .mutate(result)
            .then(async () => {
                createTafiliateCustomer(uid)
                if (referralId) await $celloSignUp.mutate({ ucc: referralId })
                logToAnalytics('onboarding_questions', {
                    ...result,
                    topics: result.data.topics.join(','),
                    referralId
                })

                handleSessionRedirects({ isExtensionInstalled, historyPush: history.push })
            })
            .catch(e => {
                showErrorNotification(
                    e.name === 'team-name-already-taken'
                        ? 'This team name is already taken please try a different one'
                        : generalErrorMessage
                )
            })
    }

    const logTopicChange = (currentTopics: Array<TopicType>, otherValue: string) => {
        const standardTopics = currentTopics
            .filter(it => !it.withExtraInput)
            .map(topic => topic.title)

        const feature = [...standardTopics, otherValue].filter(Boolean)

        logToAnalytics('onboarding_feature_selected', {
            feature
        })
    }

    const onTopicClick = (clickedTopic: TopicType, isSelected: boolean) => {
        const newTopicsList = isSelected
            ? topics.filter(topic => topic.title !== clickedTopic.title)
            : [...topics, clickedTopic]

        setTopics(newTopicsList)

        const isOtherUnselected = isSelected && Boolean(clickedTopic.withExtraInput)

        logTopicChange(newTopicsList, isOtherUnselected ? '' : other)
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            minHeight="100vh"
        >
            <Box width="100%" height="5px">
                {$postOnboarding.isLoading && <LinearProgress />}
            </Box>
            <Box>
                <Box width={isMobileView ? '100%' : '610px'} px={2}>
                    <Box
                        display="flex"
                        justifyContent={isMobileView ? 'flex-start' : 'center'}
                        mt={isMobileView ? '32px' : '40px'}
                    >
                        <GuiddeLogo data-test="welcome-page-guidde-logo" height="30px" />
                    </Box>
                    <Box mt={2.5} />
                    <Typography
                        data-test="welcome-page-title"
                        variant="h4"
                        align={isMobileView ? 'left' : 'center'}
                    >
                        Tell us about yourself
                    </Typography>
                    <Box mt={3} />
                    <Typography data-test="welcome-page-subtitle" style={{ fontSize: 18 }}>
                        We'll customize your guidde experience based on your choice
                    </Typography>
                    {data?.initialCompanyName && (
                        <Box mt={4.5}>
                            <Typography data-test="welcome-page-workspace-name">
                                How would you like to call your workspace?
                            </Typography>
                            <Box mt={1} />
                            <TextField
                                data-test="teamDiscovery-workspace-name"
                                label="Workspace name"
                                value={workspaceName}
                                onChange={e => setWorkspaceName(e.target.value)}
                                onBlur={() => {
                                    logToAnalytics('onboarding_workspaceName_typed', {
                                        workspaceName
                                    })
                                }}
                                fullWidth
                                variant="filled"
                            />
                        </Box>
                    )}
                    <SpacedGroup flexDirection={isMobileView ? 'column' : 'row'}>
                        {data?.companySizes && (
                            <Box width="100%">
                                <Box mt={3} />
                                <Typography data-test="welcome-page-comapny-size-question">
                                    What’s your company size?
                                </Typography>
                                <Box mt={1} />
                                <FormControl
                                    data-test="onboarding-company-size-selection"
                                    variant="filled"
                                    fullWidth
                                >
                                    <InputLabel id="role">
                                        {companySize ? 'Company size' : 'Select company size'}
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="companySize"
                                        id="companySize"
                                        MenuProps={selectMenuProps}
                                        variant="filled"
                                        value={companySize}
                                        onChange={e => {
                                            const companySizeValue = e.target.value

                                            setCompanySize(companySizeValue)
                                            logToAnalytics('onboarding_companySize_selected', {
                                                companySize: companySizeValue
                                            })
                                        }}
                                    >
                                        {data?.companySizes?.map(it => (
                                            <MenuItem
                                                value={it}
                                                key={it}
                                                divider
                                                style={{
                                                    margin: '0 8px',
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    padding: '12px 8px'
                                                }}
                                            >
                                                {it} employees
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                        <Box mt={3} width="100%">
                            <Typography data-test="welcome-page-role-question">
                                What kind of work do you do?
                            </Typography>
                            <Box mt={1} />
                            <FormControl
                                data-test="onboarding-role-selection"
                                variant="filled"
                                fullWidth
                            >
                                <InputLabel id="role">
                                    {role ? 'Role' : 'Select response'}
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="role"
                                    id="role"
                                    variant="filled"
                                    MenuProps={selectMenuProps}
                                    value={role}
                                    onChange={e => {
                                        const roleValue = e.target.value
                                        const jobFunction = data?.roles?.find(
                                            role => role.id === roleValue
                                        )?.name

                                        setRole(roleValue)
                                        logToAnalytics('onboarding_jobFunction_selected', {
                                            jobFunction
                                        })
                                    }}
                                >
                                    {data?.roles?.filter(Boolean).map(it => (
                                        <MenuItem
                                            data-test="onboarding-role-item"
                                            value={it.id}
                                            key={it.id}
                                            divider
                                            style={{
                                                margin: '0 8px',
                                                fontWeight: 500,
                                                fontSize: 14,
                                                padding: '12px 8px'
                                            }}
                                        >
                                            {it.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </SpacedGroup>
                    <Box mt={4} />
                    <Typography data-test="welcome-page-topic-question">
                        What are you thinking of using guidde for? (select one or more)
                    </Typography>
                    <Box mt={2} />
                    <Grid container spacing={2} alignItems="stretch">
                        {data?.topics?.map((it, i) => {
                            const isSelected = topics.some(topic => topic.title === it.title)

                            return (
                                <Grid key={i} item xs={6} sm={3}>
                                    <Box
                                        data-test="onboarding-topics"
                                        onClick={() => onTopicClick(it, isSelected)}
                                        py={isMobileView ? 1 : 2}
                                        px={isMobileView ? 1 : 1.5}
                                        borderRadius="4px"
                                        height="100%"
                                        minHeight={52}
                                        border={`1px solid ${isSelected ? '#cb4000' : '#E0E0E0'}`}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        display="flex"
                                        flexDirection={isMobileView ? 'row' : 'column'}
                                        alignItems={isMobileView ? 'center' : 'flex-start'}
                                    >
                                        <SpacedGroup
                                            spacing={1}
                                            flexDirection={isMobileView ? 'row' : 'column'}
                                        >
                                            <img src={it.imageUrl} width={24} height={24} />

                                            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                                                {it.title}
                                            </Typography>
                                        </SpacedGroup>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    {showOtherInput && (
                        <Box mt={4}>
                            <TextField
                                variant="filled"
                                value={other}
                                onChange={e => setOther(e.target.value.trim())}
                                placeholder="What you’ll be using guidde for?"
                                fullWidth
                                onBlur={e => logTopicChange(topics, e.target.value.trim())}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box
                width="100%"
                mt={5}
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Button
                    startIcon={<ArrowForwardIcon />}
                    onClick={handleStart}
                    disabled={disableSave}
                    variant="contained"
                    size="large"
                    style={{ boxShadow: 'none' }}
                >
                    <Box
                        data-test="welcome-page-lets-start-button"
                        ml={-0.5}
                        style={{ letterSpacing: '1.25px' }}
                    >
                        LET'S START
                    </Box>
                </Button>

                <Box
                    mt="40px"
                    bgcolor="rgba(9, 12, 16, 0.08)"
                    height={40}
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="center"
                    color="rgba(9, 12, 16, 0.4)"
                >
                    <SpacedGroup spacing={2}>
                        <Typography
                            variant="caption"
                            style={{
                                color: 'rgba(9, 12, 16, 0.6)'
                            }}
                        >
                            © {new Date().getFullYear()} guidde Inc.
                        </Typography>

                        <FooterLinks />
                    </SpacedGroup>
                </Box>
            </Box>
        </Box>
    )
}
