import { useContext, useEffect, useState } from 'react'

import { Box, Button, CircularProgress, LinearProgress, Link, Typography } from '@mui/material'

import { defaultSpeakingRate, enUScode, useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'
import { SpeakersList } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/TextToSpeech/SpeakersList'
import { StepsContext, VideoStatusContext } from 'UI/Components'
import { T2sBlockedMessage } from './T2sBlockedMessage'

import { ReactComponent as RollNext } from 'assets/icons/roll-next.svg'

import { type BrandKitType, type QuickGuiddeType, type SpeakerType } from 'app/types'

import { useBrandKit, useDataMutation, useLocalStorage, useServiceUsage } from 'hooks'
import { logToAnalytics, playbookToAnalyticsProps } from 'modules'

type Props = {
    isBlocked: boolean
    playbook: QuickGuiddeType
    onClose: () => void
    onDone: () => void
}

type GetInitialSpeakerProps = {
    lastSpeakerId?: string
    qgLanguageCode: string
    hasStudioVoices: boolean
    brandKitVoiceover: BrandKitType['voiceover']['data']
    currentLangSpeakersList: SpeakerType[]
}

const getInitialSpeaker = ({
    lastSpeakerId,
    qgLanguageCode,
    brandKitVoiceover,
    hasStudioVoices,
    currentLangSpeakersList
}: GetInitialSpeakerProps) => {
    const lastSpeaker = currentLangSpeakersList.find(s => s.id === lastSpeakerId)

    const isSpeakerLangAndQgLangMatch = lastSpeaker?.langCode === qgLanguageCode

    if (isSpeakerLangAndQgLangMatch) return lastSpeaker

    const brandKitSpeaker = brandKitVoiceover.find(
        voiceover => voiceover.langCode === qgLanguageCode
    )?.speaker

    if (brandKitSpeaker) return brandKitSpeaker

    const defaultSpeakerIdx = currentLangSpeakersList.findIndex(speaker => !speaker.isStudio)

    if (defaultSpeakerIdx < 0) {
        console.error('[getInitialSpeaker]: can not select default speaker', {
            currentLangSpeakersList,
            hasStudioVoices
        })
        return null
    }

    return currentLangSpeakersList[defaultSpeakerIdx]
}

export const ConvertStep = ({ playbook, isBlocked, onClose, onDone }: Props) => {
    const { brandKitVoiceover, brandKitLoading } = useBrandKit()

    const { hasStudioVoices, isLoading: isServiceUsageLoading } = useServiceUsage()

    const { checkVideoStatus, onConvert } = useContext(VideoStatusContext)
    const { stepsProcessing } = useContext(StepsContext)

    const qgLanguageCode = playbook?.magicCapture?.language?.langCode || enUScode

    const [speakingRate] = useLocalStorage('speakingRate', defaultSpeakingRate)

    const { getSpeakersList, speakerId } = useSpeakerOptions()

    const [speakerConfig, setSpeakerConfig] = useState<null | SpeakerType>(null)

    useEffect(() => {
        if (brandKitLoading || isServiceUsageLoading || !brandKitVoiceover.length) return

        const currentLangSpeakersList = getSpeakersList(qgLanguageCode)

        const speaker = getInitialSpeaker({
            qgLanguageCode,
            hasStudioVoices: Boolean(hasStudioVoices),
            lastSpeakerId: speakerId,
            brandKitVoiceover,
            currentLangSpeakersList
        })

        setSpeakerConfig(speaker)
    }, [
        isServiceUsageLoading,
        speakerConfig,
        brandKitLoading,
        brandKitVoiceover,
        hasStudioVoices,
        speakerId,
        qgLanguageCode,
        getSpeakersList
    ])

    const $convertT2s = useDataMutation<
        { playbookId: string; config: SpeakerType },
        unknown,
        Error
    >('/c/v1/quickguidde/convertT2s', 'POST', {
        onSuccess: () => {
            checkVideoStatus().then(() => {
                stepsProcessing.setFalse()
                onConvert()
            })
        }
    })

    if (isBlocked) return <T2sBlockedMessage onClose={onClose} />

    if (!speakerConfig) {
        return (
            <Box
                width="100%"
                height="335px"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box width="100%">
            {$convertT2s.isLoading && <LinearProgress />}

            <Box p={2}>
                <Typography>
                    <b>
                        Generate natural sounding voiceovers <br /> from the step descriptions
                    </b>
                </Typography>

                <Box my={2}>
                    <Typography>01. Review the descriptions.</Typography>
                    <Typography>02. Choose speaker.</Typography>
                    <Typography>03. Generate!</Typography>
                </Box>

                <Box
                    mb={3}
                    mr={7}
                    p={1}
                    bgcolor="#F5F5F5"
                    borderRadius="4px"
                    display={speakerConfig ? 'block' : 'none'}
                >
                    <SpeakersList
                        languageCode={qgLanguageCode}
                        currentSpeaker={speakerConfig}
                        onSelect={setSpeakerConfig}
                        onSpeakerClick={() =>
                            logToAnalytics('voiceover_t2vTab_speaker_selected', {
                                playbookId: playbook.id,
                                source: 'magicCapture'
                            })
                        }
                        onAudioPlay={speaker => {
                            logToAnalytics('voiceover_play_speaker_preview', {
                                playbookId: playbook.id,
                                activeStep: 0,
                                speaker,
                                source: 'magicCapture'
                            })
                        }}
                    />
                </Box>

                <Button
                    disabled={$convertT2s.isLoading}
                    startIcon={<RollNext />}
                    onClick={() => {
                        if (!speakerConfig) return

                        logToAnalytics('magic_capture_converted', {
                            languageCode: speakerConfig.langCode,
                            languageName: speakerConfig.langName,
                            ...playbookToAnalyticsProps(playbook)
                        })

                        stepsProcessing.setTrue()
                        $convertT2s
                            .mutate({
                                playbookId: playbook.id,
                                config: { ...speakerConfig, speakingRate }
                            })
                            .then(onDone)
                    }}
                    variant="contained"
                >
                    GENERATE
                </Button>

                <Box mt={1}>
                    <Link
                        style={{ color: '#2D9CDB' }}
                        component="button"
                        variant="body1"
                        color="secondary"
                        underline="always"
                        onClick={() => {
                            logToAnalytics('magic_capture_ignored', {
                                ...playbookToAnalyticsProps(playbook)
                            })
                            onConvert()
                        }}
                    >
                        Ignore
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}
