import { Stack } from '@mui/system'

import PeopleIcon from '@mui/icons-material/PeopleOutlineOutlined'

type Props = {
    src: string
    size: number
    testIdentified?: string
    border?: string
}

export const WorkspaceLogo = ({ src, size, testIdentified, border }: Props) => {
    return (
        <Stack
            sx={{
                border: border ?? '1px solid rgba(9, 12, 16, 0.08)',
                borderRadius: '50%',
                height: size,
                width: size,
                minWidth: size,
                backgroundColor: src ? '#FFF' : '#bdbdbd',
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            data-test={testIdentified}
        >
            {src ? (
                <img
                    src={src}
                    alt="Workspace logo"
                    style={{ objectFit: 'cover', maxWidth: size }}
                />
            ) : (
                <PeopleIcon fontSize="small" sx={{ color: '#FFF' }} />
            )}
        </Stack>
    )
}
