import { useState, memo } from 'react'

import {
    MenuItem,
    Select,
    TextField,
    Box,
    Button,
    IconButton,
    FormControl,
    InputLabel
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { SpacedGroup } from 'UI/Components'

type Props = {
    initialPhrase: string
    handleClose: () => void
    createPhrase: (phrase: {
        description: string
        phrase: string
        appId: string
        source: string
        lang: string
    }) => Promise<void | { message: any; params: any }>
    applicationsList: Array<{ name: string; value: string }>
    transcriptLanguage: string
}
export const DictionaryTranscriptForm = memo(
    ({ initialPhrase, handleClose, createPhrase, applicationsList, transcriptLanguage }: Props) => {
        const [application, setApplication] = useState(applicationsList?.[0]?.value || '')
        const [phrase, setPhrase] = useState(initialPhrase)

        return (
            <Box px={3} pb={2} py={3} position="relative">
                <IconButton
                    style={{ position: 'absolute', right: '5px', top: '5px' }}
                    onClick={handleClose}
                    size="small"
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                <SpacedGroup style={{ alignItems: 'flex-end' }}>
                    <TextField
                        label="Add phrase"
                        variant="standard"
                        value={phrase}
                        onChange={e => setPhrase(e.target.value.trim())}
                        style={{ width: 'auto', minWidth: '50px' }}
                        error={!phrase.length}
                    />
                    {applicationsList.length > 0 && (
                        <FormControl>
                            <InputLabel shrink>Application</InputLabel>
                            <Select
                                value={application}
                                onChange={({ target }) => {
                                    if (target.value) setApplication(target.value as string)
                                }}
                                variant="standard"
                                displayEmpty
                                style={{ minWidth: '120px' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {applicationsList.map((type, i) => (
                                    <MenuItem key={i} value={type.value}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </SpacedGroup>
                <Box mt={2} display="flex" flex={1} justifyContent="flex-end">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            createPhrase({
                                description: 'other',
                                phrase,
                                appId: application,
                                source: 'player',
                                lang: transcriptLanguage
                            }).then(handleClose)
                        }}
                        disabled={!phrase}
                        size="small"
                    >
                        Add
                    </Button>
                </Box>
            </Box>
        )
    }
)
