import { type ServiceUsageStateType } from 'ducks/reducers'
import * as types from 'ducks/types'

export const showPlanDialog = (payload: keyof ServiceUsageStateType['dialogs']) =>
    ({
        type: types.SHOW_SERVICE_USAGE_DIALOG,
        payload
    }) as const

export const closePlanDialogs = () =>
    ({
        type: types.CLOSE_SERVICE_USAGE_DIALOGS
    }) as const

export const showPlanNotifications = (payload: Partial<ServiceUsageStateType['notifications']>) =>
    ({
        type: types.SHOW_SERVICE_USAGE_NOTIFICATIONS,
        payload
    }) as const
