import { Box } from '@mui/material'

import { links } from 'app/links'

export const PoweredBy = () => {
    return (
        <Box fontSize="14px" position="absolute" color="#fff" left="18px" bottom="9px">
            Powered by{' '}
            <a
                href={links.guiddeWebsite}
                rel="noreferrer"
                target="_blank"
                style={{ color: '#fff' }}
            >
                guidde
            </a>
        </Box>
    )
}
