import { memo } from 'react'
import { Link } from 'react-router-dom'

import { type LocationDescriptor } from 'history'

import { DrawerItem, type DrawerItemProps } from './DrawerItem'

type Props = DrawerItemProps & {
    id?: string
    to: LocationDescriptor<{ prevPath?: string }>
    onClick?: () => void
}

export const DrawerLink = memo(
    ({ id, to, onClick, isActive, activeColor, ...itemProps }: Props) => {
        return (
            <Link
                to={to}
                style={{
                    textDecoration: 'none',
                    maxWidth: '240px',
                    position: 'relative',
                    ...(isActive && { color: activeColor })
                }}
                onClick={e => {
                    onClick && onClick()
                    if (isActive) e.preventDefault()
                }}
                id={id}
            >
                <DrawerItem isActive={isActive} activeColor={activeColor} {...itemProps} />
            </Link>
        )
    }
)
