import { Image } from 'react-konva'

import useImage from 'use-image'

import { type BrowserBarShapeType } from 'app/types'

type Props = {
    layer: BrowserBarShapeType
}

export const BrowserBarShape = ({ layer }: Props) => {
    const [image] = useImage(layer.url || '', 'anonymous')

    if (!image) return null

    return (
        <Image
            name="browserBar"
            id={layer.id}
            image={image}
            scaleX={layer.scaleX}
            scaleY={layer.scaleY}
            y={layer.y}
            x={layer.x}
        />
    )
}
