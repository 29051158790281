import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    Box,
    Checkbox,
    Chip,
    ClickAwayListener,
    Divider,
    FormControlLabel,
    IconButton,
    Paper,
    Slider,
    Typography,
    Popover,
    styled
} from '@mui/material'

import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import VolumeOnIcon from '@mui/icons-material/VolumeUp'

import { useStepSpeakingRate } from 'UI/Routes/quick-guidde/hooks'

import clsx from 'clsx'

import { SpacedGroup } from './SpacedGroup'

import { useBoolean, useTextDebounce } from 'hooks'
import { formatTime, logToAnalytics } from 'modules'

import { updateUserPreferences } from 'ducks/reducers'
import { type SpeakerType } from 'app/types'

const PREFIX = 'AudioPlayer'

const classes = {
    speedBtn: `${PREFIX}-speedBtn`,
    rail: `${PREFIX}-rail`,
    track: `${PREFIX}-track`,
    root: `${PREFIX}-root`,
    volumeRoot: `${PREFIX}-volumeRoot`,
    colorSecondary: `${PREFIX}-colorSecondary`,
    thumb: `${PREFIX}-thumb`,
    volumeThumb: `${PREFIX}-volumeThumb`,
    volume: `${PREFIX}-volume`,
    openedVolume: `${PREFIX}-openedVolume`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.speedBtn}`]: {
        border: '1px solid rgba(255, 255, 255, 0.60)',
        fontSize: 10,
        width: 48,
        height: 22,
        color: 'rgba(255, 255, 255, 0.60)',
        padding: '3px 4px',
        borderRadius: '50%',
        cursor: 'pointer'
    },

    [`& .${classes.rail}`]: {
        height: 2,
        borderRadius: 2
    },

    [`& .${classes.track}`]: {
        height: 3,
        zIndex: 3,
        position: 'relative',
        border: 'none'
    },

    [`& .${classes.root}`]: {
        width: '100%',
        height: 3,
        borderRadius: 3,
        color: '#FFFFFF',
        '&:hover': {
            '& $thumb': {
                visibility: 'visible'
            }
        }
    },

    [`& .${classes.volumeRoot}`]: {
        width: 30,
        height: 3,
        borderRadius: 3,
        color: '#FFFFFF'
    },

    [`& .${classes.colorSecondary}`]: {
        backgroundColor: 'rgba(9, 12, 16, 0.10)'
    },

    [`& .${classes.thumb}`]: {
        zIndex: 1,
        visibility: 'hidden',
        width: 10,
        height: 10,
        '&:after': {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        '&:hover, &.Mui-focusVisible': {
            boxShadow: 'none'
        }
    },

    [`& .${classes.volumeThumb}`]: {
        width: 10,
        height: 10,
        '&:after': {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        '&:hover, &.Mui-focusVisible, &:active': {
            boxShadow: 'none !important'
        }
    },

    [`& .${classes.volume}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderRadius: 20,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        width: 26
    },

    [`& .${classes.openedVolume}`]: {
        paddingLeft: '10px',
        width: 100,
        background: '#D53333',
        '& #slider': {
            display: 'block'
        }
    }
}))

const style = {
    color: '#FFFFFF'
}

const speedValues = [0.75, 1, 1.25, 1.5]
const minVolume = 5

const formatSpeed = (speed: number) => (speed % 1 === 0 ? speed.toFixed(1) : speed)

type Props = {
    audioFile: string
    duration: number
    speakerConfig?: SpeakerType
    speakingRate?: number
    onChangeSpeed?: (speed: number, applyToAllSteps: boolean) => void
    onPlay?: () => void
    onTimePointerClick?: (time: number) => void
}

export const AudioPlayer = ({
    onPlay,
    onChangeSpeed,
    duration,
    audioFile,
    speakerConfig,
    speakingRate,
    onTimePointerClick
}: Props) => {
    const { userPreferences } = useSelector(state => state.configs)

    const { updateSystemSpeakingRate } = useStepSpeakingRate()

    const audioRef = useRef<HTMLAudioElement>(null)
    const sliderRef = useRef<HTMLElement>(null)

    const loading = useBoolean()
    const isPlaying = useBoolean()
    const openVolume = useBoolean()
    const applySpeedToAllSteps = useBoolean()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const [activeTime, setActiveTime] = useState(0)
    const [volume, setVolume] = useState(userPreferences.playerVolume)

    const debouncedVolume = useTextDebounce(volume)

    useEffect(() => {
        if (!debouncedVolume) return

        if (audioRef.current) {
            audioRef.current.volume = debouncedVolume / 100
        }

        updateUserPreferences('playerVolume', debouncedVolume)
    }, [debouncedVolume])

    const onSpeedChange = (speed: number) => {
        // save new speed value in local storage for the next videos
        updateSystemSpeakingRate(speed)
        onChangeSpeed?.(speed, applySpeedToAllSteps.isTrue)

        if (speakerConfig) {
            logToAnalytics('audioPlayer_playbackSpeed_selected', {
                speed,
                speaker: speakerConfig
            })
        } else {
            console.error(['[speed change log error]]: `speakerConfig` in undefined'])
        }
    }

    const ActionIcon = isPlaying.isFalse || loading.isTrue ? PlayIcon : PauseIcon

    return (
        <>
            <audio
                src={audioFile}
                ref={audioRef}
                onLoadStart={loading.setTrue}
                onLoadedData={e => {
                    loading.setFalse()
                    isPlaying.setFalse()

                    // set initial volume once player is ready
                    e.currentTarget.volume = volume / 100
                }}
                controls
                onError={() => {
                    loading.setTrue()
                    console.error('Error loading audio file')
                }}
                onPlay={() => {
                    isPlaying.setTrue()
                    onPlay?.()
                }}
                onPause={isPlaying.setFalse}
                style={{ display: 'none' }}
                onTimeUpdate={e => setActiveTime(Math.floor(e.currentTarget.currentTime))}
                onSeeking={e => onTimePointerClick?.(Math.floor(e.currentTarget.currentTime))}
            />
            <StyledBox
                bgcolor="rgba(203, 0, 0, 1)"
                height={40}
                borderRadius="30px"
                minWidth={270}
                display="flex"
                px={1}
                py={0.8}
                alignItems="center"
            >
                <IconButton
                    size="small"
                    disabled={loading.isTrue}
                    onClick={() => {
                        if (isPlaying.isTrue) {
                            audioRef.current?.pause()
                        } else {
                            audioRef.current?.play()
                        }
                    }}
                >
                    <ActionIcon style={style} fontSize="small" />
                </IconButton>

                <Box display="flex" alignItems="center" mr={1}>
                    <Typography style={style} variant="caption">
                        {formatTime(audioRef.current?.currentTime || 0)}
                    </Typography>
                    <Typography
                        style={{
                            color: 'rgba(255, 255, 255, 0.60)'
                        }}
                        variant="caption"
                    >
                        /{formatTime(duration)}
                    </Typography>
                </Box>

                <Slider
                    value={activeTime}
                    ref={sliderRef}
                    max={duration}
                    step={1}
                    min={0}
                    onChange={(_, newTime) => {
                        if (!audioRef.current || Array.isArray(newTime)) return

                        audioRef.current.currentTime = newTime
                    }}
                    disabled={loading.isTrue}
                    classes={{
                        thumb: classes.thumb,
                        root: classes.root,
                        colorSecondary: classes.colorSecondary,
                        rail: classes.rail,
                        track: classes.track
                    }}
                />
                <Box mr={2} />

                <Box
                    className={clsx(classes.volume, {
                        [classes.openedVolume]: openVolume.isTrue
                    })}
                    mr={1}
                >
                    <SpacedGroup spacing={0.5}>
                        {openVolume.isTrue && (
                            <ClickAwayListener onClickAway={openVolume.setFalse}>
                                <Slider
                                    value={volume}
                                    disabled={loading.isTrue}
                                    style={{ width: 50 }}
                                    min={minVolume}
                                    onChange={(_, volume) => {
                                        if (Array.isArray(volume)) return

                                        if (!audioRef.current) {
                                            console.error('[audio ref error]: no audio ref')
                                            return
                                        }

                                        setVolume(volume)
                                        audioRef.current.volume = volume / 100
                                    }}
                                    classes={{
                                        thumb: classes.volumeThumb,
                                        root: classes.volumeRoot,
                                        colorSecondary: classes.colorSecondary,
                                        track: classes.track
                                    }}
                                />
                            </ClickAwayListener>
                        )}
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (openVolume.isFalse) {
                                    openVolume.setTrue()
                                    return
                                }

                                if (!audioRef.current) {
                                    console.error('[audio ref error]: no audio ref')
                                    return
                                }

                                setVolume(prev => (prev === minVolume ? 100 : minVolume))
                            }}
                            sx={{ p: '3px' }}
                        >
                            <VolumeOnIcon style={style} fontSize="small" />
                        </IconButton>
                    </SpacedGroup>
                </Box>

                {speakingRate && (
                    <Box onClick={e => setAnchorEl(e.currentTarget)} className={classes.speedBtn}>
                        x{formatSpeed(speakingRate)}
                    </Box>
                )}
            </StyledBox>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Paper
                    style={{
                        padding: 8
                    }}
                >
                    <Typography style={{ fontSize: 14, fontWeight: 700 }}>Speed</Typography>
                    <SpacedGroup mt={1} mb={2} spacing={1} flexWrap="wrap">
                        {speedValues.map(speed => {
                            const isActive = speed === speakingRate

                            return (
                                <Chip
                                    key={speed}
                                    onClick={() => onSpeedChange(speed)}
                                    label={
                                        <Typography variant="caption">
                                            x{formatSpeed(speed)}
                                        </Typography>
                                    }
                                    color={isActive ? 'primary' : 'default'}
                                    style={{ minWidth: 46 }}
                                    sx={
                                        isActive
                                            ? { background: '#FAE5E5', color: '#090C10' }
                                            : {
                                                  background: '#F5F5F5',
                                                  color: '#828282'
                                              }
                                    }
                                    clickable
                                    variant="outlined"
                                    size="small"
                                />
                            )
                        })}
                    </SpacedGroup>

                    <Divider />
                    <Box mt={0.5} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={applySpeedToAllSteps.isTrue}
                                color="primary"
                                onChange={(_, val) => applySpeedToAllSteps.set(val)}
                                name="checkedA"
                            />
                        }
                        label={
                            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                                Apply speed to all steps
                            </Typography>
                        }
                    />
                </Paper>
            </Popover>
        </>
    )
}
