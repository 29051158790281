import { Box, Button, capitalize, Dialog, LinearProgress, Typography } from '@mui/material'

import VideoIcon from '@mui/icons-material/VideoCall'

import {
    generateVideoStep,
    loadVideoMetadata
} from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { SpacedGroup } from 'UI/Components'

import { useAuth, useBoolean, useNotification } from 'hooks'
import { getFirebaseUrl, uploadWithSignedUrl, uuid } from 'modules'
import { firebaseConfig } from 'env'

import { type StepType } from 'app/types'

const generateStep = async ({
    file,
    stepId,
    fileUrl,
    kind
}: {
    file: File
    stepId: string
    fileUrl: string
    kind: 'intro' | 'outro'
}) => {
    const { width, height, duration } = await loadVideoMetadata(fileUrl)

    return generateVideoStep({
        kind,
        windowDimensions: {
            innerHeight: height,
            innerWidth: width,
            outerHeight: height,
            outerWidth: width
        },
        stepId,
        duration,
        fileName: file.name,
        fileUrl,
        width,
        height,
        x: 0,
        y: 0,
        scale: 1
    })
}

type VideoPreviewAndUploadDialogProps = {
    previewUrl: string
    file: File
    stepKind: 'intro' | 'outro'
    isOpen: boolean
    loading?: boolean
    buttonLabel: string
    onClose: () => void
    onPrimaryBtnClick: (step: StepType) => void
    onCancel?: () => void
}
export const VideoPreviewAndUploadDialog = ({
    isOpen,
    stepKind,
    file,
    previewUrl,
    buttonLabel,
    loading,
    onClose,
    onPrimaryBtnClick,
    onCancel
}: VideoPreviewAndUploadDialogProps) => {
    const { showErrorNotification } = useNotification()

    const generating = useBoolean()

    const { uid } = useAuth()

    const uploadVideo = async (stepId: string) => {
        const fileId = uuid()

        const storagePath = `quickguiddes/${uid}/${stepId}/uploads/${fileId}.mp4`
        const bucket = `gs://${firebaseConfig.projectId}-temporary`

        try {
            await uploadWithSignedUrl(bucket + '/' + storagePath, file)
            return await getFirebaseUrl(storagePath, true)
        } catch (error) {
            console.error('[Error uploadVideo]', { error, storagePath, file })

            showErrorNotification('Something went wrong, please try again')
            onClose()
        }
    }

    const generateStepData = async () => {
        generating.setTrue()
        const stepId = uuid()

        const fileUrl = await uploadVideo(stepId)

        if (!fileUrl) {
            console.error('[Error handleAddVideoStep]', {
                stepId,
                fileUrl,
                file
            })
            showErrorNotification('Something went wrong, please try again')
            onClose()
            return
        }
        const step = await generateStep({
            kind: stepKind,
            file,
            stepId,
            fileUrl
        })

        onPrimaryBtnClick(step)
        generating.setFalse()
    }

    const isLoading = generating.isTrue || loading

    return (
        <VideoPreviewDialog
            isOpen={isOpen}
            videoUrl={previewUrl}
            isLoading={isLoading}
            title={`Add ${capitalize(stepKind)} Video`}
            buttonLabel={buttonLabel}
            onPrimaryBtnClick={generateStepData}
            onCancel={onCancel}
            onClose={onClose}
        />
    )
}
type VideoPreviewDialogProps = {
    isOpen: boolean
    title: string
    isLoading?: boolean
    videoUrl: string
    buttonLabel?: string
    onClose: () => void
    onPrimaryBtnClick?: () => void
    onCancel?: () => void
}

export const VideoPreviewDialog = ({
    isOpen,
    videoUrl,
    isLoading,
    title,
    buttonLabel,
    onClose,
    onPrimaryBtnClick,
    onCancel
}: VideoPreviewDialogProps) => (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={onClose}>
        <Box height={4}> {isLoading && <LinearProgress />}</Box>
        <Box p={2} display="flex" justifyContent="space-between">
            <SpacedGroup spacing={1}>
                <VideoIcon />
                <Typography style={{ fontSize: 20, fontWeight: 500 }}>{title}</Typography>
            </SpacedGroup>

            <SpacedGroup>
                {onCancel && (
                    <Button
                        disabled={isLoading}
                        color="inherit"
                        size="small"
                        variant="contained"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                )}
                {onPrimaryBtnClick && (
                    <Button
                        disabled={isLoading}
                        size="small"
                        onClick={onPrimaryBtnClick}
                        variant="contained"
                    >
                        {buttonLabel}
                    </Button>
                )}
            </SpacedGroup>
        </Box>
        <video src={videoUrl} controls />
    </Dialog>
)
