import { Typography } from '@mui/material'

import { useWindowView } from 'hooks'

type InnerHTMLType = {
    html: string
    className?: string
    fontWeight?: 'bold' | 'normal'
}

export const InnerHTML = ({ html, fontWeight = 'normal', className }: InnerHTMLType) => {
    const { isMobileView } = useWindowView()

    return (
        <Typography
            noWrap
            style={{
                fontWeight,
                fontSize: isMobileView ? '12px' : 'default'
            }}
            className={className}
            dangerouslySetInnerHTML={{
                __html: html
            }}
            component="span"
            data-test="inner-html"
        />
    )
}
