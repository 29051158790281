import { useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Box, Button, ClickAwayListener, Divider, Paper, Popper } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { TitleStep } from './TitleStep'
import { ImageStep } from './ImageStep'
import { ImportSteps } from './ImportSteps'
import { PresentationSteps } from './PresentationSteps'
import { VideoStep } from './VideoStep'
import { VideoRecordingStep } from './VideoRecordingStep'

import { useBoolean } from 'hooks'

import { paths } from 'app/paths'
import { type QuickGuiddeType } from 'app/types'

export const DEFAULT_STEP = {
    title: '',
    kind: 'step',
    type: 'click',
    duration: 3,
    url: '',
    screenshot: '',
    drawnScreenshot: '',
    layers: [],
    windowDimensions: {
        innerWidth: 1920,
        innerHeight: 1080,
        outerWidth: 1920,
        outerHeight: 1080
    }
} as const

type Props = {
    quickGuidde: QuickGuiddeType
}

export const AddNewStep = ({ quickGuidde }: Props) => {
    const open = useBoolean()

    const anchorRef = useRef(null)

    const { sourceCaptureVideo, language } = quickGuidde

    const qgEditPage = useRouteMatch(paths.editQuickGuidde)?.isExact
    // Current page is not qg edit
    if (!qgEditPage) return null

    return (
        <>
            <Button
                data-test="editor-add-step-button"
                data-intercom="editor-add-step-button"
                ref={anchorRef}
                onClick={open.setTrue}
                variant="text"
                color="inherit"
                startIcon={<AddIcon />}
            >
                Add step
            </Button>

            <Popper open={open.isTrue} anchorEl={anchorRef?.current} sx={{ zIndex: 1 }}>
                <ClickAwayListener onClickAway={open.setFalse}>
                    <Paper sx={{ width: 240 }}>
                        <TitleStep onDone={open.setFalse} />
                        <Box px={1}>
                            <Divider />
                        </Box>
                        <ImageStep onDone={open.setFalse} />
                        <Box px={1}>
                            <Divider />
                        </Box>
                        <VideoStep language={quickGuidde.language} onDone={open.setFalse} />
                        <Box px={1}>
                            <Divider />
                        </Box>
                        <ImportSteps onDone={open.setFalse} mergeToQG={quickGuidde} />
                        <Box px={1}>
                            <Divider />
                        </Box>
                        <PresentationSteps onDone={open.setFalse} playbook={quickGuidde} />
                        {sourceCaptureVideo && (
                            <>
                                <Box px={1}>
                                    <Divider />
                                </Box>
                                <VideoRecordingStep
                                    language={language}
                                    sourceCaptureVideo={sourceCaptureVideo}
                                    onDone={open.setFalse}
                                />
                            </>
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
