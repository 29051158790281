import { useMemo } from 'react'

import useSWR, { type SWRConfiguration } from 'swr'

import { useNotification } from './use-notification'
import { generalErrorMessage, logToAnalytics, request } from 'modules'
import { type ErrorType } from 'app/types'

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE'

type QueryPayloadType = { method: MethodType; body?: Record<string, any> }

export const useQuery = <T>(
    url: string,
    { method, body }: QueryPayloadType,
    params?: SWRConfiguration<T, ErrorType>
) => {
    const { showErrorNotification } = useNotification()

    const { data, error, isValidating, mutate } = useSWR<T, any>(
        // use null for conditional fetching
        // see: https://swr.vercel.app/docs/conditional-fetching
        url ? [url, ...(body ? JSON.stringify(body) : [])] : null,
        () => request(url, method, body),
        {
            refreshWhenHidden: false,
            focusThrottleInterval: 60000,
            dedupingInterval: 10000,
            ...params,
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                if (error.status === 404) return
                if (error.message === generalErrorMessage && retryCount > 4) return

                // Didn't provide errorRetryCount or finished last retry
                if (!params?.errorRetryCount || retryCount > params.errorRetryCount) return

                // One api call + 3 retries
                if (retryCount === 4 && error.message === generalErrorMessage) {
                    logToAnalytics('server_response_not_json', error)
                    showErrorNotification(generalErrorMessage)
                    return
                }

                const id = setTimeout(() => {
                    revalidate({ retryCount })
                    clearTimeout(id)
                }, 2000)
            },
            onError: (...err) => {
                params?.onError?.(...err)
            }
        }
    )

    return useMemo(
        () => ({
            data,
            error,
            mutate,
            isValidating
        }),
        [data, error, mutate, isValidating]
    )
}
