import { memo } from 'react'
import { useDispatch } from 'react-redux'

import { styled, Box, Typography, Button } from '@mui/material'
import { ReactComponent as PremiumIcon } from 'assets/icons/premium.svg'

import { SpacedGroup } from 'UI/Components'

import { logToAnalytics } from 'modules'
import { useRoles, useServiceUsage } from 'hooks'
import { showPlanDialog } from 'ducks'

const PREFIX = 'PlanUsageBanner'

const classes = {
    plansBtn: `${PREFIX}-plansBtn`,
    additional: `${PREFIX}-additional`,
    wrapper: `${PREFIX}-wrapper`,
    progressWrapper: `${PREFIX}-progressWrapper`,
    progress: `${PREFIX}-progress`,
    iconWrapper: `${PREFIX}-iconWrapper`
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
    [`& .${classes.plansBtn}`]: {
        fontWeight: 'normal',
        color: '#2D9CDB',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
            background: 'transparent'
        }
    },

    [`& .${classes.additional}`]: {
        marginBottom: '20px'
    },

    [`& .${classes.wrapper}`]: {
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        padding: '6px 16px',
        background: '#F5F5F5',
        '& p': {
            fontSize: 'inherit',
            letterSpacing: '0.25px'
        }
    },

    [`& .${classes.progressWrapper}`]: {
        width: `${progressWidth}px`,
        marginLeft: '20px',
        background: 'rgba(9, 12, 16, 0.08)',
        height: '8px',
        position: 'relative',
        overflow: 'hidden'
    },

    [`& .${classes.progress}`]: {
        background: '#FED243',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0
    },

    [`& .${classes.iconWrapper}`]: {
        marginLeft: spacing(1),
        '& path': {
            fill: '#090C10'
        }
    }
}))

const progressWidth = 120

export const PlanUsageBanner = memo(() => {
    const {
        isLoading,
        usedPlaybooksSlots,
        playbooksQuota,
        isTrialPlan,
        trialDaysLeft,
        isFreePlan,
        isDefaultValues
    } = useServiceUsage()
    const dispatch = useDispatch()

    const { isMember } = useRoles()

    // is role is less than member role, return null
    if (!isMember) return null

    if (isLoading || (!isTrialPlan && (!isFreePlan || playbooksQuota == null)) || isDefaultValues) {
        return null
    }

    const isMaxReached = usedPlaybooksSlots >= playbooksQuota

    const progressValue = usedPlaybooksSlots / playbooksQuota

    return (
        <StyledBox display="flex" justifyContent="center" margin="0 auto">
            <Box mx={3} className={classes.wrapper}>
                {isTrialPlan ? (
                    <>
                        <Box mr={1} className={classes.iconWrapper}>
                            <PremiumIcon />
                        </Box>
                        <span>
                            Free business trial <b>• {trialDaysLeft}</b> days left
                        </span>
                    </>
                ) : (
                    <>
                        <SpacedGroup spacing={1.5}>
                            {isMaxReached ? (
                                <Typography>
                                    Your account has reached the <b>{playbooksQuota}</b> video limit
                                </Typography>
                            ) : (
                                <Typography>
                                    Your account has almost reached its limit{' '}
                                    <b>• {usedPlaybooksSlots}</b>/{playbooksQuota} videos
                                </Typography>
                            )}
                        </SpacedGroup>

                        <Box className={classes.progressWrapper} flexShrink={0}>
                            <Box
                                className={classes.progress}
                                width={progressWidth * progressValue}
                            />
                        </Box>
                    </>
                )}

                <SpacedGroup spacing={1} ml={2} flexShrink={0}>
                    <Button
                        size="medium"
                        variant="text"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        className={classes.plansBtn}
                        onClick={() => {
                            logToAnalytics('open_plans_dialog', {
                                source: 'usage_banner',
                                isFreePlan,
                                isTrialPlan
                            })
                            dispatch(showPlanDialog('upgradePlanDialog'))
                        }}
                    >
                        {isMaxReached ? `Upgrade` : `See Plans`}
                    </Button>
                </SpacedGroup>
            </Box>
        </StyledBox>
    )
})
