import { type MouseEvent } from 'react'

import { type BoxProps, Box, Typography } from '@mui/material'

import { type IconType } from 'app/menu'
import { SpacedGroup } from 'UI/Components'

type Props = Partial<{
    title: string
    height: BoxProps['height']
    width: BoxProps['width']
    disabled: boolean
    Icon: IconType
    onClick: (e: MouseEvent<HTMLElement>) => void
}>

export const ActionCard = ({
    Icon,
    disabled,
    title,
    height = 165,
    width = 210,
    onClick = () => {}
}: Props) => (
    <Box
        border="1px solid rgba(9, 12, 16, 0.08)"
        borderRadius="4px"
        style={{
            cursor: disabled ? 'auto' : 'pointer'
        }}
        onClick={disabled ? undefined : onClick}
        height={height}
        width={width}
        minWidth={width}
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <SpacedGroup flexDirection="column">
            {Icon && <Icon style={{ color: '#CB0000' }} />}
            <Typography variant="caption" color="textSecondary" align="center">
                {title}
            </Typography>
        </SpacedGroup>
    </Box>
)
