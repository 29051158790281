import { type ReactNode, type HTMLProps } from 'react'

type Props = {
    text: ReactNode
} & HTMLProps<HTMLHeadingElement>

export const PageTitle = ({ text, style = {}, ...props }: Props) => (
    <h1
        data-test="page-title"
        style={{ fontSize: '24px', fontWeight: 'bold', textWrap: 'nowrap', ...style }}
        {...props}
    >
        {text}
    </h1>
)
