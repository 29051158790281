import { useMemo } from 'react'

import { generatePath, useHistory, useParams } from 'react-router-dom'

import { paths } from 'app/paths'
import { type QuickGuiddeType, type PlaylistType } from 'app/types'

import { logToAnalytics } from 'modules'

import { useAuth, useDataMutation } from './index'

type DuplicateType = {
    playbookId: string
    playbookTitle?: string
    playbookCreatorName?: string
}

type Props = {
    uploadedByOrgId: string
}

type AnalyticsPropsType = DuplicateType & {
    isPlaylist?: boolean
}

export const useDuplicate = ({ uploadedByOrgId }: Props) => {
    const history = useHistory()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { orgId } = useAuth()

    const sendAnalyticsCopyLogs = (logData: AnalyticsPropsType) => {
        const playbookTypeKey = logData.isPlaylist ? 'playlist-id' : 'pb-id'

        logToAnalytics('duplicated-qg-created', {
            isPlaylist: Boolean(logData.isPlaylist),
            [playbookTypeKey]: playbookId,
            'new-pb-id': logData.playbookId,
            'pb-creator-org-id': uploadedByOrgId,
            'pb-duplicated-from-id': uploadedByOrgId,
            'pb-title': logData.playbookTitle || '',
            'pb-creator-name': logData.playbookCreatorName || ''
        })
    }

    const $duplicateQG = useDataMutation<DuplicateType, DuplicateType, Error>(
        '/c/v1/quickguidde/copy',
        'POST',
        {
            onSuccess: response => {
                if (!response?.playbookId) return

                sendAnalyticsCopyLogs(response)

                history.push(
                    generatePath(paths.editQuickGuidde, {
                        playbookId: response.playbookId
                    })
                )
            }
        }
    )

    const $duplicatePlaylist = useDataMutation<DuplicateType, DuplicateType, Error>(
        '/c/v1/copy-playlist',
        'POST',
        {
            onSuccess: response => {
                if (!response?.playbookId) return

                sendAnalyticsCopyLogs({ ...response, isPlaylist: true })

                history.push(
                    generatePath(paths.editPlaylist, {
                        playbookId: response.playbookId
                    })
                )
            }
        }
    )

    return useMemo(
        () => ({
            isDuplicationAvailable: uploadedByOrgId === orgId,
            duplicationIsLoading: $duplicateQG.isLoading || $duplicatePlaylist.isLoading,
            duplicateQG: (playbook: QuickGuiddeType) => {
                return $duplicateQG.mutate({ playbookId: playbook.id })
            },
            duplicatePlaylist: (playbook: PlaylistType) => {
                return $duplicatePlaylist.mutate({ playbookId: playbook.id })
            }
        }),
        [$duplicatePlaylist, $duplicateQG, orgId, uploadedByOrgId]
    )
}
