import { memo } from 'react'
import { Form, Formik } from 'formik'

import { Box, DialogActions, Grid, Typography, Button } from '@mui/material'

import { CommonDialog, ConnectedSelect, SubtitleUploader } from 'UI/Components'
import { enUScode } from 'UI/Routes/quick-guidde/hooks'

import {
    type UseBooleanType,
    useAuth,
    useBoolean,
    useDataMutation,
    labelCodes,
    useNotification
} from 'hooks'

import { logToAnalytics, playbookToAnalyticsProps } from 'modules'
import { type PlaybookType } from 'app/types'

type Props = {
    open: UseBooleanType
    playbook: PlaybookType
}

export const UploadSubtitleModal = memo(({ open, playbook }: Props) => {
    const { uid } = useAuth()

    const fileUploaded = useBoolean()

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const $uploadSubtitle = useDataMutation<unknown, unknown, Error>(
        '/c/v1/transcript/upload',
        'POST',
        {
            onSuccess: () => showSuccessNotification('Subtitle file uploaded successfully'),
            onFailure: error => {
                console.error(error)
                showErrorNotification(
                    'The specified file could not be uploaded. The file content is invalid.'
                )
            }
        }
    )

    return (
        <CommonDialog
            maxWidth={'xs'}
            isOpen={open.isTrue}
            title="Upload Subtitles"
            onClose={open.setFalse}
            titleSize="sm"
            hideConfirmBtn={true}
            hideCloseBtn={true}
            showDivider={true}
            loading={$uploadSubtitle.isLoading}
            content={
                <Formik
                    onSubmit={(values, { resetForm }) => {
                        $uploadSubtitle.mutate(values).then(() => {
                            resetForm()
                            open.setFalse()
                            logToAnalytics('upload_subtitles', {
                                ...playbookToAnalyticsProps(playbook),
                                ...{
                                    language: values.language
                                }
                            })
                        })
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        playbookId: playbook.id,
                        language: playbook.currentTranscriptLang || enUScode,
                        fileUrl: ''
                    }}
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <Box mb={2}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Typography>Subtitles (.srt / .vtt):</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <SubtitleUploader
                                            storagePath={`uploads/${uid}/${playbook.id}`}
                                            onDone={url => {
                                                fileUploaded.setTrue()
                                                setFieldValue('fileUrl', url)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mb={2}>
                                <Grid container>
                                    <Grid container item xs={5}>
                                        <Typography>Language:</Typography>
                                    </Grid>

                                    <Grid item xs={7}>
                                        <ConnectedSelect
                                            name="language"
                                            options={labelCodes}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <DialogActions
                                sx={{
                                    padding: '24px',
                                    justifyContent: 'center'
                                }}
                            >
                                <Button
                                    size="large"
                                    color="inherit"
                                    onClick={open.setFalse}
                                    variant="contained"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    size="large"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={$uploadSubtitle.isLoading || fileUploaded.isFalse}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            }
        />
    )
})
