import { useCallback } from 'react'

import { Box, Typography } from '@mui/material'

import { BasicChapters } from '@guidde-co/shared.chapters.basic-chapters'
import { EditableChapters } from 'UI/Components'

import { type PlaybookType } from 'app/types'
import { logToAnalytics, playbookToAnalyticsProps } from 'modules'

type Props = {
    playbook: PlaybookType
    isEditMode: boolean
    videoRef: HTMLVideoElement
    canvasRef: Array<HTMLCanvasElement>
    searchString?: string
}

export const PlaybookChapters = ({
    videoRef,
    playbook,
    isEditMode,
    canvasRef,
    searchString
}: Props) => {
    // Report to analytics chapters search results
    const handleChange = useCallback(
        (searchString: string, counter: number) => {
            logToAnalytics(counter ? 'search' : 'search-no-results', {
                text: searchString.toLowerCase(),
                hits: counter,
                inVideo: true,
                ...playbookToAnalyticsProps(playbook)
            })
        },
        [playbook]
    )

    if ((playbook.slicingSuggestion || []).length === 0) {
        return (
            <Typography>
                guidde will transcribe your video and magically slice it into chapters soon. Please
                check again in a few seconds.
            </Typography>
        )
    }

    return (
        <Box data-test="playbook-chapters" position="relative" zIndex={3}>
            {isEditMode ? (
                <EditableChapters
                    canvasElements={canvasRef}
                    searchString={searchString}
                    videoElement={videoRef}
                    playbookId={playbook.id}
                    slicingSuggestion={playbook.slicingSuggestion || []}
                    playbook={playbook}
                />
            ) : (
                <BasicChapters
                    chapters={playbook.slicingSuggestion || []}
                    searchString={searchString}
                    videoElement={videoRef}
                    onChange={handleChange}
                />
            )}
        </Box>
    )
}
