import { type ActionType } from 'ducks/common'
import * as types from 'ducks/types'

import { type MultipleCtaType, type SingleCtaType } from 'app/types'

type StateType = {
    multiCtaVisibility: boolean
    singleCtaVisibility: boolean
    stepCtaMultiApplyVisibility: boolean
    action?: SingleCtaType['action']
    isSingleCTA: boolean
    editItemId: number
    currentCta?: MultipleCtaType
}

const initialState: StateType = {
    multiCtaVisibility: false,
    singleCtaVisibility: false,
    stepCtaMultiApplyVisibility: false,
    action: {
        enabled: true,
        link: '',
        text: '',
        linkType: 'goToStep',
        start: 0,
        mode: 'playbook'
    },
    isSingleCTA: true,
    editItemId: 0,
    currentCta: {
        ctaType: 'multiple',
        title: '',
        actions: []
    }
}

export const qgCtaReducer = (state = initialState, action: ActionType): StateType => {
    switch (action.type) {
        case types.SET_QUICK_GUIDDE_CURRENT_CTA: {
            const currentCta = action.payload
            return {
                ...state,
                currentCta
            }
        }

        case types.SET_QUICK_GUIDDE_STEP_CTA_VISIBILITY: {
            return {
                ...state,
                singleCtaVisibility: action.payload
            }
        }

        case types.SET_QUICK_GUIDDE_MULTI_CTA_VISIBILITY: {
            return {
                ...state,
                multiCtaVisibility: action.payload
            }
        }

        case types.SET_QUICK_GUIDDE_MULTI_APPLY_VISIBILITY: {
            return {
                ...state,
                stepCtaMultiApplyVisibility: action.payload
            }
        }

        case types.SET_QUICK_GUIDDE_STEP_CTA_ACTION: {
            return {
                ...state,
                action: action.payload.action,
                isSingleCTA: action.payload.isSingleCTA,
                editItemId: action.payload.editItemId
            }
        }

        default: {
            return state
        }
    }
}
