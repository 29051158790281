import * as types from 'ducks/types'

type SetVideoProgressType = {
    playbookId: string
    fileName: string
    source: 'playbooks' | 'gdrive' | 'onedrive' | 'zoom' | 'youtube'
}

export const setVideoProgress = (payload: SetVideoProgressType) =>
    ({
        type: types.SET_VIDEO_PROGRESS,
        payload
    }) as const

export const resetVideoProgress = (playbookId: string) =>
    ({
        type: types.RESET_VIDEO_PROGRESS,
        payload: playbookId
    }) as const
