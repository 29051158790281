import { memo, useMemo, useState } from 'react'

import {
    type BoxProps,
    Box,
    Button,
    Dialog,
    Divider,
    IconButton,
    Typography,
    Checkbox,
    List,
    TextField,
    ListItemSecondaryAction,
    ListItemIcon,
    ListItemText,
    LinearProgress,
    InputAdornment,
    ListItemButton,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PublicIcon from '@mui/icons-material/PublicOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

import { ReactComponent as TeamIcon } from 'assets/icons/team-icon.svg'
import { ReactComponent as ConnectedIcon } from 'assets/icons/connected-icon.svg'
import { ReactComponent as AddCircle } from 'assets/icons/add_circle.svg'

import { type PlaybookVisibilityType } from 'app/types'

import { TextOverflow } from '@guidde-co/shared.textoverflow'
import { SpacedGroup } from 'UI/Components/SpacedGroup'

import { useTextDebounce } from 'hooks'

const PREFIX = 'ShareCommonDialog'

const classes = {
    itemIcon: `${PREFIX}-itemIcon`,
    list: `${PREFIX}-list`
}

const StyledDialog = styled(Dialog)({
    [`& .${classes.itemIcon}`]: {
        minWidth: '30px'
    },
    [`& .${classes.list}`]: {
        maxHeight: 300,
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 0
    }
})

const maxLength = 150

const visibilityIcons = {
    Private: <LockOutlinedIcon style={{ height: 20 }} />,
    Team: <TeamIcon style={{ height: 20 }} />,
    Connected: <ConnectedIcon style={{ height: 20 }} />,
    Public: <PublicIcon style={{ height: 20 }} />
} as const

const buttonStyles = {
    boxShadow: 'none',
    textTransform: 'uppercase',
    letterSpacing: '1.25 px'
} as const

type Option = {
    id: string
    name: string
    isSelected: boolean
    isDisabled?: boolean
    visibility?: PlaybookVisibilityType
}

type Props = {
    isOpen: boolean
    isLoading?: boolean
    autoFocusTitle?: boolean
    label?: string
    createButtonLabel?: string
    header: string
    setTitle: (title: string) => void
    onClose: () => void
    onAddClick?: () => void
    onItemClick?: (id: string, isSelected: boolean) => void
    onCreateClick: () => void
    showCreateButton: boolean
    options: Array<Option>
    title: string
    disableCreate?: boolean
    buttonLabel: string
    maxWidth?: BoxProps['maxWidth']
    showSearchField?: boolean
    emptyStateImage?: string
    emptyStateText?: string
}

export const ShareCommonDialog = memo(
    ({
        maxWidth = '600px',
        isOpen,
        isLoading,
        title,
        buttonLabel,
        disableCreate,
        showCreateButton,
        createButtonLabel = 'Create',
        options,
        label,
        header,
        autoFocusTitle,
        onCreateClick,
        setTitle,
        onAddClick = () => {},
        onItemClick = () => {},
        onClose,
        showSearchField = true,
        emptyStateText,
        emptyStateImage
    }: Props) => {
        const [text, setText] = useState('')
        const debouncedText = useTextDebounce(text, 250)

        const filteredOptions = useMemo(() => {
            return options
                .filter(item => item.name.toLowerCase().includes(debouncedText.toLowerCase()))
                .sort((a, b) => {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                })
        }, [options, debouncedText])

        return (
            <StyledDialog
                open={isOpen}
                onClose={onClose}
                fullWidth
                maxWidth="xs"
                PaperProps={{
                    sx: {
                        maxWidth
                    }
                }}
            >
                <Box minHeight={5}>{isLoading && <LinearProgress />}</Box>
                <Box px="8px" py="18px">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mt="-15px"
                        mr="-8px"
                    >
                        <Box px="8px">
                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontSize: 20,
                                    color: 'rgba(9, 12, 16, 1)'
                                }}
                            >
                                {header}
                            </Typography>
                        </Box>
                        <IconButton onClick={onClose} size="large">
                            <CloseIcon style={{ color: '#000' }} />
                        </IconButton>
                    </Box>
                    <Divider style={{ backgroundColor: 'rgba(9, 12, 16, 0.08)' }} />
                    {showSearchField && (
                        <Box my={1}>
                            <TextField
                                fullWidth
                                label=""
                                placeholder="Search"
                                value={text}
                                disabled={isLoading}
                                style={{
                                    backgroundColor: '#F5F5F5',
                                    color: '#090C10',
                                    fontSize: '16px',
                                    fontWeight: 400
                                }}
                                InputProps={{
                                    style: { height: '54px' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlinedIcon
                                                style={{
                                                    fill: 'rgba(9, 12, 16, 0.3)',
                                                    marginLeft: '30px'
                                                }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                variant="standard"
                                onChange={e => setText(e.target.value)}
                            />
                        </Box>
                    )}

                    <List className={classes.list}>
                        {filteredOptions.map(item => (
                            <ListItemButton
                                key={item.id}
                                divider
                                disableGutters
                                onClick={() => onItemClick(item.id, item.isSelected)}
                                disabled={item.isDisabled || isLoading}
                                selected={item.isSelected}
                                sx={{
                                    padding: '4px 12px',
                                    minHeight: 50,
                                    '&.Mui-selected': {
                                        background: 'rgba(0, 0, 0, 0.08)',
                                        '&:hover': {
                                            background: 'rgba(0, 0, 0, 0.08)'
                                        }
                                    }
                                }}
                            >
                                <ListItemIcon classes={{ root: classes.itemIcon }}>
                                    <Checkbox
                                        edge="start"
                                        color="primary"
                                        checked={item.isSelected}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <TextOverflow lineClamp={1}>
                                            <Typography
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    width: item.visibility
                                                        ? 'calc(100% - 20px)'
                                                        : '100%'
                                                }}
                                            >
                                                {item.name}
                                            </Typography>
                                        </TextOverflow>
                                    }
                                />
                                {item.visibility && (
                                    <ListItemSecondaryAction color="#1C1B1F" sx={{ right: 16 }}>
                                        {visibilityIcons[item.visibility]}
                                    </ListItemSecondaryAction>
                                )}
                            </ListItemButton>
                        ))}
                    </List>

                    {showCreateButton && (
                        <Box mt={1} mb={4}>
                            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                                {label}
                            </Typography>
                            <Box mt={2} />
                            <TextField
                                data-test="shareCommonDialog-textfield"
                                variant="filled"
                                label="Title"
                                value={title}
                                multiline
                                maxRows={3}
                                autoFocus={autoFocusTitle}
                                onFocus={e => {
                                    if (!autoFocusTitle) return

                                    e.currentTarget.setSelectionRange(
                                        e.currentTarget.value.length,
                                        e.currentTarget.value.length
                                    )
                                }}
                                inputProps={{
                                    maxLength
                                }}
                                onChange={e => setTitle(e.target.value)}
                                fullWidth
                            />
                            <Box display="flex" justifyContent="flex-end">
                                <Typography color="textSecondary" variant="caption">
                                    {title.length}/{maxLength}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignContent="center"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {emptyStateImage && !showCreateButton && (
                            <Box mt={2} height={152}>
                                <img src={emptyStateImage} alt="no spaces" />
                            </Box>
                        )}

                        {emptyStateText && !showCreateButton && (
                            <Box mb={2} fontSize={12} fontWeight={400} color="rgba(9, 12, 16, 0.6)">
                                {emptyStateText}
                            </Box>
                        )}
                    </Box>
                    {emptyStateImage && (
                        <Divider style={{ backgroundColor: 'rgba(9, 12, 16, 0.08)' }} />
                    )}
                    <SpacedGroup
                        mt={1}
                        style={{
                            justifyContent: `${showCreateButton ? 'flex-end' : 'flex-start'}`
                        }}
                    >
                        {showCreateButton ? (
                            <Button
                                data-test="shareCommonDialog-create-button"
                                style={buttonStyles}
                                onClick={onCreateClick}
                                variant="contained"
                                disabled={disableCreate || isLoading}
                                color="primary"
                                startIcon={<CheckIcon color="inherit" />}
                            >
                                {createButtonLabel}
                            </Button>
                        ) : (
                            <Button
                                data-test="shareCommonDialog-add-button"
                                style={{
                                    ...buttonStyles,
                                    color: 'rgba(45, 156, 219, 1)',
                                    background: '#FFFFFF',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    letterSpacing: 1.25
                                }}
                                onClick={onAddClick}
                                variant="contained"
                                startIcon={<AddCircle color="inherit" />}
                            >
                                {buttonLabel}
                            </Button>
                        )}
                    </SpacedGroup>
                </Box>
            </StyledDialog>
        )
    }
)
