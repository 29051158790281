import { useDispatch } from 'react-redux'

import { styled, Button, Divider, List, Paper, Popover } from '@mui/material'

import { ReactComponent as WorkspaceIcon } from 'assets/icons/workspace.svg'

import { FullScreenLoader } from 'UI/Components'

import { type WorkspaceType } from './DesktopWorkspacePanel'
import { notifyExtension, WorkspaceListItem } from './WorkspaceListItem'

import { logToAnalytics } from 'modules'
import { useAuth, useDataMutation } from 'hooks'

import { type UserAuthStateType } from 'ducks/reducers'

import { resetAppAuth } from 'ducks'

const StyledPaper = styled(Paper)({
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: '20px'
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D8D8D8',
        borderRadius: '20px',
        border: '6px solid transparent',
        backgroundClip: 'content-box'
    }
})

type Props = {
    anchorEl: HTMLElement | null
    onClose: () => void
    workspaces: Array<WorkspaceType>
    activeWorkspace: WorkspaceType
    canCreateWorkspace: boolean
}

export const WorkspaceSwitcher = ({
    anchorEl,
    workspaces,
    activeWorkspace,
    onClose,
    canCreateWorkspace
}: Props) => {
    const dispatch = useDispatch()

    const $createNewWorkspace = useDataMutation<
        void,
        {
            roles: UserAuthStateType['roles']
            message: string
        },
        Error
    >('/auth/v1/new-workspace', 'POST', {
        onFailure: onClose
    })

    const { uid } = useAuth()

    return (
        <>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disablePortal={false}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <StyledPaper style={{ width: 360, maxHeight: 540 }}>
                    <List dense disablePadding data-test="teamDiscovery_workspacesList">
                        {/* active workspace should be first in the list of workspaces */}
                        {Array.from(new Set([activeWorkspace, ...workspaces])).map(workspace => (
                            <WorkspaceListItem
                                key={workspace.orgId}
                                workspace={workspace}
                                isActive={workspace.orgId === activeWorkspace.orgId}
                                canLeaveWorkspace={workspaces.length > 1}
                                onFailure={onClose}
                            />
                        ))}
                    </List>

                    {canCreateWorkspace && (
                        <>
                            <Divider />
                            <Button
                                data-test="teamDiscovery_createButton"
                                variant="text"
                                style={{
                                    color: '#2D9CDB',
                                    justifyContent: 'flex-start',
                                    fontWeight: 500,
                                    textTransform: 'uppercase',
                                    fontSize: 14,
                                    padding: '8px 16px'
                                }}
                                color="secondary"
                                onClick={() => {
                                    logToAnalytics('teamDiscovery_createButton_clicked', {
                                        uid,
                                        page: 'left menu'
                                    })
                                    // The next EP will trigger roles to be updated on BE and
                                    // FE will read those changes before the request will end
                                    // and this crashes the page. To avoid this we reset roles
                                    // so users will see the guidde loader, as if he opens the
                                    // app for the first time; and we set flag to the store that
                                    // we use to prevent setting new token to the state and just
                                    // refresh the page
                                    $createNewWorkspace.mutate()
                                    notifyExtension()
                                    dispatch(
                                        resetAppAuth({ refreshPageOnTheNextTokenUpdate: true })
                                    )
                                }}
                                fullWidth
                                startIcon={
                                    <WorkspaceIcon
                                        style={{ color: 'inherit', width: 24, height: 24 }}
                                    />
                                }
                                size="small"
                            >
                                create a new workspace
                            </Button>
                        </>
                    )}
                </StyledPaper>
            </Popover>

            <FullScreenLoader isOpen={$createNewWorkspace.isLoading} title="Creating" />
        </>
    )
}
