import axios from 'axios';
import { Axios } from 'axios';
import { SDK_SOURCE_TYPE } from '../configuration';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  GetEntitlementsQuery,
  GetPaywallQuery,
  GetSdkConfigurationQuery,
} from '@stigg/api-client-js/src/generated/sdk';
import { ObservablePoller } from '../utils/ObservablePoller';

type EdgeApiClientConfiguration = {
  apiKey: string;
  baseEdgeUri: string;
  enableEdge: boolean;
};

export class EdgeApiClient {
  private readonly httpClient: Axios;

  private constructor(
    config: EdgeApiClientConfiguration,
    private readonly dynamicData: { customerToken?: string | null },
  ) {
    const { apiKey, baseEdgeUri } = config;
    this.httpClient = axios.create({
      baseURL: baseEdgeUri,
      headers: {
        'X-API-KEY': apiKey,
        source: SDK_SOURCE_TYPE,
      },
    });
  }

  static create(
    config: EdgeApiClientConfiguration,
    dynamicData: { customerToken?: string | null },
  ): EdgeApiClient | null {
    return config.enableEdge ? new EdgeApiClient(config, dynamicData) : null;
  }

  getPaywall(productId?: string, billingCountryCode?: string) {
    const prefix = productId ? `/p/${productId}` : '';
    const billingCountryCodeQueryParam = billingCountryCode ? `billingCountryCode=${billingCountryCode}` : '';

    return this.get<GetPaywallQuery>(`/v1${prefix}/paywall.json?${billingCountryCodeQueryParam}`);
  }

  getEntitlements(customerId: string, resourceId: string | undefined) {
    const optionalResourceQueryParam = resourceId ? `?resourceId=${resourceId}` : '';
    return this.get<GetEntitlementsQuery>(`/v1/c/${customerId}/entitlements.json${optionalResourceQueryParam}`);
  }

  getSdkConfiguration() {
    return this.get<GetSdkConfigurationQuery>(`/v1/config/client-sdk-configuration.json`);
  }

  watchEntitlements(customerId: string, interval: number, resourceId: string | undefined) {
    return new ObservablePoller<GetEntitlementsQuery>(() => this.getEntitlements(customerId, resourceId), interval);
  }

  private get<T>(url: string) {
    const headers: Record<string, string> = {};

    // customer may be changed overtime, so fetching it before every query
    const { customerToken } = this.dynamicData;
    if (customerToken) {
      headers['X-CUSTOMER-KEY'] = customerToken;
    }

    return this.httpClient.get<ApolloQueryResult<T>>(url, { headers }).then((response) => response.data);
  }
}
