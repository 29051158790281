import { useQuery } from './use-query'

import { type PlaybookApplicationType, type ApplicationType } from 'app/types'

type Response = Record<string, ApplicationType>

export const useFavicon = (applications: Array<PlaybookApplicationType> = []) => {
    const appId = applications[0]?.applicationId || ''

    const { data } = useQuery<Response>(
        appId ? `/c/v1/applications?appId=${appId}` : '',
        {
            method: 'GET'
        },
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    )

    return data?.[appId]?.favicon || ''
}
