import { type ReactNode, memo } from 'react'

import { Container, Grid } from '@mui/material'

type Props = {
    children: JSX.Element
    sidePanel?: ReactNode
}

export const DesktopPlaybookContainer = memo(({ children, sidePanel }: Props) => {
    return (
        <Container sx={{ padding: '0 24px' }} maxWidth={false}>
            <Grid
                container
                sx={theme => ({
                    [theme.breakpoints.up('md')]: {
                        minHeight: '100vh'
                    }
                })}
            >
                <Grid item xs={12} md={sidePanel ? 8 : 12} sx={{ background: '#FAFAFA' }}>
                    {children}
                </Grid>
                {sidePanel && (
                    <Grid item xs={12} md={4}>
                        {sidePanel}
                    </Grid>
                )}
            </Grid>
        </Container>
    )
})
