import clsx from 'clsx'

import { styled, TableRow, TableCell, Checkbox, Tooltip } from '@mui/material'

import WarningIcon from '@mui/icons-material/Warning'

const PREFIX = 'FileItem'

const classes = {
    mainCell: `${PREFIX}-mainCell`,
    flexContainer: `${PREFIX}-flexContainer`,
    checkbox: `${PREFIX}-checkbox`,
    secondaryText: `${PREFIX}-secondaryText`,
    pointer: `${PREFIX}-pointer`,
    selected: `${PREFIX}-selected`,
    thumbnail: `${PREFIX}-thumbnail`,
    popper: `${PREFIX}-popper`
}

const StyledTableRow = styled(TableRow)({
    [`& .${classes.mainCell}`]: {
        padding: '0.5rem'
    },
    [`& .${classes.flexContainer}`]: {
        display: 'flex',
        alignItems: 'center'
    },
    [`& .${classes.checkbox}`]: {
        width: '3rem',
        marginRight: '1rem'
    },
    [`& .${classes.secondaryText}`]: {
        color: '#78909c'
    },
    [`&.${classes.pointer}`]: {
        cursor: 'pointer'
    },
    [`& .${classes.selected}`]: {
        backgroundColor: '#f4f6f8'
    },
    [`& .${classes.thumbnail}`]: {
        width: '3rem',
        height: '3rem',
        marginRight: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
            width: '2.75rem',
            height: '2.75rem',
            fill: '#b9d1fd'
        },
        '& > img': {
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    [`& .${classes.popper}`]: {
        zIndex: 9999
    }
})

type Props = {
    file: any
    thumbnail: JSX.Element
    columns: Array<{
        value: string
        isSecondary?: boolean
        cellContent: (file: any) => string
    }>
    isSelected: boolean
    isSelectable?: boolean
    showCheckbox?: boolean
    showSizeWarning?: boolean
    onClick: (e: any) => void
}

export const FileItem = ({
    file,
    thumbnail,
    columns,
    isSelected,
    isSelectable,
    showCheckbox,
    showSizeWarning,
    onClick
}: Props) => {
    return (
        <StyledTableRow
            onClick={isSelectable ? onClick : () => {}}
            hover={isSelectable}
            className={clsx(classes.pointer, isSelected && classes.selected)}
        >
            {columns.map((column, index) => {
                if (index === 0) {
                    return (
                        <TableCell key={column.value} className={classes.mainCell}>
                            <div className={classes.flexContainer}>
                                {showCheckbox && (
                                    <Checkbox
                                        color="primary"
                                        className={classes.checkbox}
                                        checked={isSelected}
                                    />
                                )}
                                {showSizeWarning && (
                                    <Tooltip
                                        classes={{ popper: classes.popper }}
                                        title="This file can not be uploaded due to excessive size"
                                    >
                                        <WarningIcon color="primary" className={classes.checkbox} />
                                    </Tooltip>
                                )}
                                <div className={classes.thumbnail}>{thumbnail}</div>
                                <span>{column.cellContent(file)}</span>
                            </div>
                        </TableCell>
                    )
                }

                return (
                    <TableCell
                        key={column.value}
                        className={column.isSecondary ? classes.secondaryText : undefined}
                    >
                        {column.cellContent(file)}
                    </TableCell>
                )
            })}
        </StyledTableRow>
    )
}
