import { useCallback, useMemo } from 'react'

import { type PlaybookType } from 'app/types'

import { useDataMutation } from './use-data-mutation'
import { useNotification } from './use-notification'
import { useQuery } from './use-query'
import { useOrganization } from './use-organization'

import { getVisibilityList } from 'UI/Components'

type PlaylistOptionType = {
    playlist: PlaybookType
    isSelected: boolean
}

export type GetPlaylistsType =
    | {
          playlists: Array<PlaylistOptionType>
      }
    | {
          playlists: Array<
              PlaylistOptionType & {
                  rejectedPlaybooksIds: Array<string>
              }
          >
          rejectedPlaybooks: Record<string, PlaybookType>
      }

export const usePlaylistsHttp = (playbooksIds: Array<string>) => {
    const { showSuccessNotification } = useNotification()
    const { name: orgName } = useOrganization()

    const { data, mutate, isValidating } = useQuery<GetPlaylistsType>(
        playbooksIds.length ? '/c/v1/get-playlists-accessability' : '',
        {
            method: 'POST',
            body: {
                playbooksIds
            }
        },
        {
            revalidateOnFocus: false
        }
    )

    const $addToPlaylists = useDataMutation<
        {
            playbooksIds: Array<string>
            playlistsIds: Array<string>
        },
        { message: string },
        Error
    >('/c/v1/add-to-playlists', 'POST')

    const $removeFromPlaylists = useDataMutation<
        {
            playbooksIds: Array<string>
            playlistsIds: Array<string>
        },
        { message: string },
        Error
    >('/c/v1/remove-from-playlists', 'POST')

    const $createPlaylist = useDataMutation<
        {
            playbooksIds: Array<string>
            title: string
        },
        { message: string },
        Error
    >('/c/v1/create-playlist', 'POST')

    const addToPlaylistsMutate = $addToPlaylists.mutate
    const removeFromPlaylistsMutate = $removeFromPlaylists.mutate
    const createPlaylistMutate = $createPlaylist.mutate

    const addToPlaylists = useCallback(
        (playlistsIds: Array<string>) =>
            addToPlaylistsMutate({ playbooksIds, playlistsIds })
                .then(() => {
                    showSuccessNotification('Successfully saved to the playlist')
                    return mutate()
                })
                .catch(console.error),
        [addToPlaylistsMutate, playbooksIds, mutate, showSuccessNotification]
    )

    const removeFromPlaylists = useCallback(
        (playlistsIds: Array<string>) =>
            removeFromPlaylistsMutate({ playbooksIds, playlistsIds })
                .then(() => {
                    showSuccessNotification('Successfully removed from the playlist')
                    return mutate()
                })
                .catch(console.error),
        [removeFromPlaylistsMutate, playbooksIds, showSuccessNotification, mutate]
    )

    const createPlaylist = useCallback(
        (title: string, playbooksIds: Array<string>) =>
            createPlaylistMutate({ playbooksIds, title }).then(() => {
                showSuccessNotification('Playlist was created')
                return mutate()
            }),
        [createPlaylistMutate, mutate, showSuccessNotification]
    )

    const orderedPlaylistsOptions = useMemo<GetPlaylistsType | undefined>(() => {
        if (!data) return

        const playlists = data.playlists || []

        const visibilityList = getVisibilityList(orgName)

        return {
            ...data,
            playlists: [
                ...playlists.filter(
                    ({ playlist }) => playlist.visibility === visibilityList.private
                ),
                ...playlists.filter(({ playlist }) => playlist.visibility === visibilityList.team),
                ...playlists.filter(
                    ({ playlist }) => playlist.visibility === visibilityList.connected
                ),
                ...playlists.filter(({ playlist }) => playlist.visibility === visibilityList.public)
            ]
        }
    }, [data, orgName])

    const isPlaylistsLoading =
        $addToPlaylists.isLoading ||
        $removeFromPlaylists.isLoading ||
        isValidating ||
        $createPlaylist.isLoading

    return useMemo(
        () => ({
            addToPlaylists,
            removeFromPlaylists,
            createPlaylist,
            isPlaylistsLoading,
            playlistsOptions: orderedPlaylistsOptions?.playlists || []
        }),
        [
            addToPlaylists,
            removeFromPlaylists,
            createPlaylist,
            isPlaylistsLoading,
            orderedPlaylistsOptions
        ]
    )
}
