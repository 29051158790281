import { type ReactNode } from 'react'
import { useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'

import { Drawer } from '@mui/material'

import { useMenuStatus } from 'UI/Components'
import { useAuth } from 'hooks'
import { paths } from 'app/paths'

const PREFIX = 'DrawerBody'

const classes = {
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerOpen: `${PREFIX}-drawerOpen`,
    drawerClose: `${PREFIX}-drawerClose`,
    drawerHovered: `${PREFIX}-drawerHovered`,
    drawer: `${PREFIX}-drawer`
}

const openedDrawerWidth = 240
const closedDrawerWidth = 70

export const getDrawerWidth = (isClosed: boolean) =>
    isClosed ? closedDrawerWidth : openedDrawerWidth

type Props = {
    children: ReactNode
}

export const DrawerBody = ({ children }: Props) => {
    const { isNotLogged } = useAuth()
    const isQGEditPage = !!useRouteMatch(paths.editQuickGuidde)?.isExact

    const { isMenuClosed } = useMenuStatus()

    if (isNotLogged) return null

    const drawerWidth = getDrawerWidth(isMenuClosed)

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            classes={{
                root: classes.drawer,
                paper: clsx(classes.drawerPaper, {
                    [classes.drawerClose]: isMenuClosed,
                    [classes.drawerOpen]: !isMenuClosed
                })
            }}
            sx={theme => ({
                [`& .${classes.drawerPaper}`]: {
                    backgroundColor: '#f5f5f5',
                    border: 'none',
                    borderRight: '1px solid rgba(9, 12, 16, 0.1)',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    })
                },

                [`& .${classes.drawerOpen}`]: {
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    width: drawerWidth,
                    height: '100vh'
                },

                [`& .${classes.drawerClose}`]: {
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    }),
                    overflowX: 'hidden',
                    width: '70px'
                },

                [`& .${classes.drawerHovered}`]: {
                    width: drawerWidth,
                    boxShadow: '4px 0px 15px rgba(0, 0, 0, 0.25)',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen
                    })
                },

                [`& .${classes.drawer}`]: {
                    width: drawerWidth,
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen
                    }),
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    overflowX: 'hidden',
                    height: '100vh'
                },

                position: 'relative',
                width: !isMenuClosed && !isQGEditPage ? openedDrawerWidth : closedDrawerWidth
            })}
        >
            {children}
        </Drawer>
    )
}
