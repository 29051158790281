import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { round } from 'modules'

export const useTimer = (maxTime: number) => {
    const frameRef = useRef<number | null>(null)
    const startTimeRef = useRef(0)
    const latestTimeRef = useRef(0)

    const [time, setTime] = useState(0)
    const [status, setStatus] = useState<'running' | 'stopped'>('stopped')

    const updateTimer = useCallback(
        (timestamp: number) => {
            const ms = timestamp - startTimeRef.current
            const seconds = ms / 1000

            if (seconds > maxTime) {
                setTime(maxTime)
                return
            }

            setTime(seconds)
            latestTimeRef.current = seconds
            frameRef.current = requestAnimationFrame(updateTimer)
            return
        },
        [maxTime]
    )

    const start = useCallback(() => {
        if (status === 'running') return

        setStatus('running')
        startTimeRef.current = performance.now() - latestTimeRef.current * 1000
        requestAnimationFrame(updateTimer)
    }, [status, updateTimer])

    const pause = useCallback(() => {
        if (status === 'stopped') return
        setStatus('stopped')
        if (frameRef.current) {
            cancelAnimationFrame(frameRef.current)
            frameRef.current = null
        }
    }, [status])

    const stop = useCallback(() => {
        setStatus('stopped')
        setTime(0)
        latestTimeRef.current = 0
        if (frameRef.current) {
            cancelAnimationFrame(frameRef.current)
            frameRef.current = null
            startTimeRef.current = 0
        }
    }, [])

    const updateTime = useCallback((t: number) => {
        setTime(t)
        latestTimeRef.current = t
        startTimeRef.current = performance.now() - t * 1000
    }, [])

    useEffect(() => {
        return () => {
            if (frameRef.current) {
                cancelAnimationFrame(frameRef.current)
                frameRef.current = null
            }
        }
    }, [])

    return useMemo(
        () => ({
            time: round(time),
            setTime: updateTime,
            start,
            pause,
            stop,
            status
        }),
        [time, updateTime, start, pause, stop, status]
    )
}
