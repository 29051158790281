import {
    createContext,
    memo,
    type ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { type DataSnapshot, off, onValue, ref } from 'firebase/database'

import { rtdb } from 'modules'
import { type SubtitlesStatusType } from 'app/types'
import { setStepsSubtitles } from 'ducks'

import { useBoolean, type UseBooleanType, useDataMutation } from 'hooks'
import { VideoStatusContext } from 'UI/Components'

type Payload = {
    entityId: string
    stepIds: Array<string>
}

export const AsyncSubtitlesContext = createContext({
    processingStepsId: [] as Array<string>,
    disableAsyncSubtitles: {} as UseBooleanType
})

type Props = {
    children: ReactNode
}

export const AsyncSubtitlesProvider = memo(({ children }: Props) => {
    const dispatch = useDispatch()

    const disabled = useBoolean()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { processFinished } = useContext(VideoStatusContext)

    const [stepsId, setStepsId] = useState<Array<string>>([])

    const query = useMemo(
        () => ref(rtdb, `subtitles_processing_pipeline/${playbookId}/steps`),
        [playbookId]
    )

    // Changes status of subtitles processing from 'done' to 'applied'
    const { mutate: changeSubtitlesStatus } = useDataMutation<Payload, Response, Error>(
        '/c/v1/subtitles/processing/status',
        'PATCH'
    )

    const handleSubtitlesStatusChange = useCallback(
        (snapshot: DataSnapshot) => {
            const data: Record<string, SubtitlesStatusType> = snapshot.val()
            if (!data) return

            const stepsToApply = Object.values(data).flatMap(step => {
                const { status, stepId } = step

                switch (status) {
                    case 'in_progress':
                        setStepsId(prevValues => {
                            if (prevValues.includes(stepId)) return prevValues
                            return [...prevValues, stepId]
                        })
                        return []
                    case 'done':
                    case 'error':
                        setStepsId(prevValues => prevValues.filter(id => id !== stepId))
                        return step
                    default:
                        return []
                }
            })

            // No subtitles to apply
            if (!stepsToApply.length) return
            // Publish is in progress
            if (disabled.isTrue || !processFinished) return

            dispatch(setStepsSubtitles(stepsToApply))

            changeSubtitlesStatus({
                entityId: playbookId,
                stepIds: stepsToApply.map(step => step.stepId)
            })
        },
        [changeSubtitlesStatus, dispatch, playbookId, disabled.isTrue, processFinished]
    )

    useEffect(() => {
        onValue(query, handleSubtitlesStatusChange, console.error)
        return () => off(query, 'value', handleSubtitlesStatusChange)
    }, [query, handleSubtitlesStatusChange])

    return (
        <AsyncSubtitlesContext.Provider
            value={{
                processingStepsId: stepsId,
                disableAsyncSubtitles: disabled
            }}
        >
            {children}
        </AsyncSubtitlesContext.Provider>
    )
})
