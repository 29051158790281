import { useDispatch } from 'react-redux'

import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { setSidePanelMode } from 'ducks'

export const SubtitlesStylePanel = () => {
    const dispatch = useDispatch()

    return (
        <>
            <Box
                p={2}
                height={62}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid rgba(9, 12, 16, 0.08)"
                borderLeft="none"
            >
                <Box style={{ fontSize: 20, fontWeight: 500 }}>Subtitles styling</Box>
                <IconButton onClick={() => dispatch(setSidePanelMode('voiceOver'))} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>
        </>
    )
}
