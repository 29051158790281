import { type CSSProperties, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Box,
    Checkbox,
    ClickAwayListener,
    FormControlLabel,
    Paper,
    Popper,
    Tooltip,
    Typography
} from '@mui/material'

import Icon from '@mui/icons-material/BorderColorOutlined'

import { ColorItem } from 'UI/Components/BrandKitColorPicker'

import { useBoolean, useBrandKit } from 'hooks'
import { setLayerStrokeColor, setQuickGuiddeSteps } from 'ducks'
import {
    type ArrowShapeType,
    type CircleShapeType,
    type CircleWithTextShapeType,
    type RectangleShapeType,
    type StepType,
    Shape
} from 'app/types'

type Props = {
    currentLayer: RectangleShapeType | CircleShapeType | ArrowShapeType | CircleWithTextShapeType
}

export const FigureColor = ({ currentLayer }: Props) => {
    const dispatch = useDispatch()

    const applyToAll = useBoolean()

    const colorRef = useRef(null)
    const figureColor = useBoolean()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const layerIdx = steps[activeStep]?.layers?.findIndex(layer => layer.id === currentLayer?.id)

    const color = useMemo(() => {
        switch (currentLayer.type) {
            case Shape.Arrow: {
                return currentLayer.fill
            }
            case Shape.Rectangle:
            case Shape.Circle: {
                return currentLayer.strokeColor
            }
            case Shape.CircleWithText: {
                return currentLayer.circle.strokeColor
            }
            default:
                return ''
        }
    }, [currentLayer])

    const onChangeColor = (color: string) => {
        const { type } = currentLayer

        switch (type) {
            case Shape.CircleWithText: {
                const payload = { strokeColor: color, fill: color }

                if (applyToAll.isFalse) {
                    dispatch(setLayerStrokeColor({ circle: payload, idx: layerIdx }))
                    break
                }

                const updatedSteps = getUpdatedStepsByType(type, {
                    circle: {
                        ...currentLayer.circle,
                        ...payload
                    }
                })

                dispatch(setQuickGuiddeSteps(updatedSteps))
                break
            }
            case Shape.Arrow: {
                const payload = { fill: color }

                if (applyToAll.isFalse) {
                    dispatch(setLayerStrokeColor({ ...payload, idx: layerIdx }))
                    break
                }
                const updatedSteps = getUpdatedStepsByType(type, payload)
                dispatch(setQuickGuiddeSteps(updatedSteps))
                break
            }
            case Shape.Rectangle:
            case Shape.Circle: {
                const payload = { strokeColor: color }

                if (applyToAll.isFalse) {
                    dispatch(
                        setLayerStrokeColor({
                            ...payload,
                            idx: layerIdx
                        })
                    )
                    break
                }
                const updatedSteps = getUpdatedStepsByType(type, payload)
                dispatch(setQuickGuiddeSteps(updatedSteps))
                break
            }
            default: {
                console.error('Wrong current layer type')
            }
        }
    }

    const getUpdatedStepsByType = (
        type: Shape,
        payload: {
            circle?: {
                strokeColor: string
                fill: string
            }
            strokeColor?: string
            fill?: string
        }
    ) => {
        return steps.map(step => ({
            ...step,
            layers: step.layers.map(layer => {
                if (layer.type === type) return { ...layer, ...payload }

                return layer
            })
        })) as Array<StepType>
    }

    if (layerIdx === -1) return null

    return (
        <div>
            <Tooltip
                title={currentLayer?.type === Shape.CircleWithText ? 'Fill color' : 'Line color'}
            >
                <Icon
                    fontSize="small"
                    ref={colorRef}
                    onClick={figureColor.toggle}
                    style={{ cursor: 'pointer' }}
                />
            </Tooltip>

            <Popper
                open={figureColor.isTrue}
                anchorEl={colorRef.current}
                placement="top-end"
                style={{ zIndex: 1300 }}
            >
                <ClickAwayListener onClickAway={figureColor.setFalse}>
                    <Paper style={{ padding: 8 }}>
                        <Box display="flex" justifyContent="center">
                            <ColorPicker currentColor={color} onClick={onChangeColor} />
                        </Box>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={applyToAll.isTrue}
                                        color="primary"
                                        size="small"
                                        onChange={(_, val) => {
                                            applyToAll.set(val)
                                        }}
                                        name="checkedA"
                                    />
                                }
                                label={
                                    <Typography
                                        color="textSecondary"
                                        variant="caption"
                                        style={{ fontWeight: 500 }}
                                    >
                                        Apply to all{' '}
                                        {currentLayer?.type === Shape.CircleWithText
                                            ? 'numbers'
                                            : 'shapes'}
                                    </Typography>
                                }
                            />
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

type ColorPickerProps = {
    currentColor: CSSProperties['color']
    onClick: (color: string) => void
}

const ColorPicker = ({ currentColor, onClick }: ColorPickerProps) => {
    const { brandKitData } = useBrandKit()

    return (
        <Box width="min-content">
            <Box
                display="flex"
                width="min-content"
                border="1px solid rgba(9, 12, 16, 0.08)"
                borderRadius="4px"
            >
                {brandKitData?.color?.data?.map((color, i) => (
                    <ColorItem
                        key={color.id}
                        withBorder={i !== 0}
                        onClick={onClick}
                        isActive={color.value === currentColor}
                        color={color.value}
                        currentColor={currentColor}
                    />
                ))}
            </Box>
            <Box
                display="flex"
                width="min-content"
                border="1px solid rgba(9, 12, 16, 0.08)"
                borderRadius="4px"
                mt={1}
            >
                {brandKitData?.qgHighlightColor?.data?.map((color, i) => (
                    <ColorItem
                        key={color.id}
                        withBorder={i !== 0}
                        onClick={onClick}
                        isActive={color.value === currentColor}
                        color={color.value}
                        currentColor={currentColor}
                    />
                ))}
            </Box>
        </Box>
    )
}
