import { useMemo } from 'react'

import { useQuery } from './use-query'

import { type ApplicationType } from 'app/types'

type Response = {
    [key: string]: ApplicationType
}

export const useApplications = () => {
    const $apps = useQuery<Response>('/c/v1/applications', {
        method: 'GET'
    })

    const options = useMemo(() => {
        if (!$apps.data) return []

        return Object.values($apps.data).map(app => ({
            value: {
                applicationId: app.id,
                applicationLogo: app.logo
            },
            label: app.title
        }))
    }, [$apps.data])

    return useMemo(
        () => ({
            applicationsData: $apps.data || {},
            options,
            isLoaded: !$apps.isValidating
        }),
        [$apps.data, $apps.isValidating, options]
    )
}
