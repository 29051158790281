import { useRef } from 'react'

import { useDispatch } from 'react-redux'

import {
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
    Typography
} from '@mui/material'
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft'
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter'
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight'
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom'
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter'
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop'

import { useBoolean } from 'hooks'
import { multiAlignElements } from 'ducks'

const options = [
    { icon: AlignHorizontalLeftIcon, label: 'Align Left', align: 'left' },
    { icon: AlignHorizontalRightIcon, label: 'Align Right', align: 'right' },
    { icon: AlignVerticalTopIcon, label: 'Align Top', align: 'top' },
    { icon: AlignVerticalBottomIcon, label: 'Align Bottom', align: 'bottom' },
    { icon: AlignHorizontalCenterIcon, label: 'Align Center', align: 'horizontalCenter' },
    { icon: AlignVerticalCenterIcon, label: 'Align Middle', align: 'verticalCenter' }
] as const

export const ElementsAlignment = () => {
    const dispatch = useDispatch()

    const anchorEl = useRef()

    const showOptions = useBoolean()

    return (
        <>
            <Tooltip title="Align elements" ref={anchorEl}>
                <AlignHorizontalLeftIcon
                    fontSize="small"
                    onClick={showOptions.toggle}
                    style={{ cursor: 'pointer', marginRight: '4px' }}
                />
            </Tooltip>

            <Popper
                open={showOptions.isTrue}
                anchorEl={anchorEl?.current}
                placement="top"
                style={{ zIndex: 2000, marginBottom: 5 }}
            >
                <ClickAwayListener
                    onClickAway={e => {
                        if ((e.target as Element).nodeName === 'BODY') return
                        showOptions.setFalse()
                    }}
                >
                    <Paper style={{ borderRadius: 2 }}>
                        <MenuList style={{ borderRadius: 'inherit', padding: 0 }}>
                            {options.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    divider
                                    sx={{ margin: '4px' }}
                                    onClick={() => {
                                        dispatch(multiAlignElements(option.align))
                                        showOptions.setFalse()
                                    }}
                                >
                                    <option.icon sx={{ marginRight: 1, padding: '3px' }} />
                                    <Typography fontWeight={500}>{option.label}</Typography>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
