import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined'
import CropIcon from '@mui/icons-material/Crop'

import { ImageCropper } from 'UI/Components'
import { ImageWithLoad } from './ImageWithLoad'

import { useAuth, useBoolean } from 'hooks'
import { uuid } from 'modules'
import { setActiveStep } from 'ducks'

import { type StepType } from 'app/types'
import { paths } from 'app/paths'

type Props = {
    index: number
    playbookId: string
    isEditMode: boolean
    stepLabel: JSX.Element
    hasVideoLayer: boolean
    step: StepType
    isRtl: boolean
    onUpdate: (_url: string) => void
}

export const StepScreenshot = ({
    index,
    isEditMode,
    hasVideoLayer,
    step,
    isRtl,
    onUpdate,
    stepLabel
}: Props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        id,
        docScreenshot,
        previewScreenshot,
        drawnScreenshot,
        windowDimensions: { innerWidth, innerHeight }
    } = step

    const showCropper = useBoolean()

    const { playbookId } = useParams<{ playbookId: string }>()
    const { uid } = useAuth()

    // Show skeleton for the introduction (automated) step
    if (id === 'introductionStep') {
        return (
            <Skeleton
                variant="rectangular"
                height="350px"
                width="100%"
                sx={{ borderRadius: '12px' }}
            />
        )
    }

    const screenshot = docScreenshot || previewScreenshot || drawnScreenshot
    if (!screenshot) return null

    const imageUrl = `${screenshot}&time=${new Date()}`

    return (
        <>
            <Box position="relative" mb={1}>
                <ImageWithLoad
                    imageUrl={imageUrl}
                    title={step.title}
                    stepLabel={stepLabel}
                    isRtl={isRtl}
                />
                {hasVideoLayer && (
                    <Stack
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        sx={{
                            position: 'absolute',
                            top: 16,
                            left: 16,
                            background: 'black',
                            color: 'white',
                            padding: '4px 5px',
                            borderRadius: '4px',
                            pointerEvents: 'none'
                        }}
                    >
                        <SmartDisplayIcon />
                        <Typography variant="body2" fontWeight={500}>
                            Video content
                        </Typography>
                    </Stack>
                )}
                {isEditMode && (
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16
                        }}
                    >
                        <Button
                            variant="text"
                            color="inherit"
                            onClick={() => {
                                dispatch(setActiveStep(index, true))

                                history.push(
                                    generatePath(paths.editQuickGuidde, {
                                        playbookId
                                    })
                                )
                            }}
                            style={{ background: 'white' }}
                            startIcon={<MovieCreationOutlinedIcon fontSize="small" />}
                        >
                            Edit visual
                        </Button>
                        <Button
                            data-test="editor-crop-button"
                            data-intercom="editor-crop-button"
                            variant="text"
                            color="inherit"
                            onClick={showCropper.setTrue}
                            style={{ background: 'white' }}
                            startIcon={<CropIcon fontSize="small" />}
                        >
                            Crop
                        </Button>
                    </Stack>
                )}
            </Box>

            {showCropper.isTrue && (
                <ImageCropper
                    initialPreviewURL={imageUrl}
                    aspectRatio={innerWidth / innerHeight}
                    labelComponent={null}
                    title="Drag & Zoom"
                    isTempStorage
                    storagePath={`imageUploads/${uid}/${playbookId}/${id}_${uuid()}`}
                    onDone={({ height, width, url }) => {
                        onUpdate(url)
                        console.error({ height, width, url })
                        showCropper.setFalse()
                    }}
                    tooltipTitle="Revert to Default"
                    onClose={showCropper.setFalse}
                />
            )}
        </>
    )
}
