import { type ChangeEvent, memo, useEffect, useState } from 'react'

import { Box, TextField as MuiTextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Label } from 'UI/Components'

import { updateTitle, logToAnalytics } from 'modules'
import { type PlaybookModeType } from 'app/types'

const DefaultTextField = styled(MuiTextField)({
    '& input': {
        paddingTop: '10px',
        fontSize: 12
    },
    '& * ': {
        color: 'black !important',
        fontSize: '12px'
    },
    '& label': {
        color: '#7f8c9a !important'
    },
    '& .MuiFilledInput-root': {
        background: '#FAFAFA',
        borderRadius: '4px',
        paddingTop: '10px',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.13)'
        }
    },
    '& .MuiFormLabel-root': {
        display: 'none'
    },
    '&:before': {
        borderBottomColor: '#e0e0e0'
    }
})

const TransparentTextField = styled(MuiTextField)({
    '& .MuiFormLabel-root': {
        display: 'none'
    },
    '& .MuiInputBase-root': {
        border: '1px solid transparent',
        height: '45px',
        textAlign: 'left',
        color: 'white',
        fontSize: 20,
        fontWeight: 600
    },
    '&:hover .MuiInputBase-root': {
        borderColor: 'rgba(255,255,255,0.5)'
    },
    '& .MuiInputBase-input': {
        textOverflow: 'ellipsis'
    },
    '& .MuiInputBase-root.Mui-focused': {
        borderColor: 'white'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important'
    }
})

type Props = {
    playbookId: string
    value: string
    fieldLabel?: string
    hideLabel?: boolean
    transparent?: boolean
    placeholder?: string
    disabled?: boolean
    mode: PlaybookModeType
    showCharCount?: boolean
    charCountPosition?: 'left' | 'right'
    maxLength?: number
    multiline?: boolean
    onTitleUpdate?: () => void
    onSave?: (value: string) => void
    onChange?: (value: string) => void
    onFocus?: () => void
    onBlur?: (value: string) => void
}

type EventType = HTMLInputElement | HTMLTextAreaElement

export const EditTitle = memo(
    ({
        hideLabel,
        fieldLabel = 'Title',
        placeholder,
        playbookId,
        transparent,
        value,
        disabled = false,
        showCharCount = false,
        charCountPosition = 'left',
        maxLength = 200,
        multiline = false,
        mode,
        onSave,
        onChange,
        onTitleUpdate,
        onFocus,
        onBlur
    }: Props) => {
        const [title, setTitle] = useState(value)

        useEffect(() => {
            setTitle(value)
        }, [value])

        const handleBlur = ({ target }: ChangeEvent<EventType>) => {
            // Restore title if empty
            if (target.value.trim().length === 0) {
                setTitle(title)
                return
            }

            onBlur?.(target.value)

            if (target.value !== value || onChange) {
                logToAnalytics(`update_title`, {
                    'pb-title': target.value,
                    playbookType: mode,
                    playbookId
                })

                if (onSave) return onSave(target.value)
                updateTitle({ playbookId, title: target.value }).then(() => onTitleUpdate?.())
            }
        }

        const onKeyPress = (e: any) => {
            e.stopPropagation()
            const key = e.key
            const forbiddenChars = ['<', '>']

            if (forbiddenChars.includes(key)) e.preventDefault()
            if (key === 'Enter') e.target?.blur()
        }

        const TextFiled = transparent ? TransparentTextField : DefaultTextField

        return (
            <Box width="100%">
                {!hideLabel && <Label label="Title" />}
                <Box mt={transparent ? 0 : 1} display="flex" alignItems="center">
                    <TextFiled
                        label={hideLabel && fieldLabel}
                        placeholder={placeholder}
                        variant={transparent ? 'outlined' : 'filled'}
                        value={title || ''}
                        disabled={disabled}
                        onChange={e => {
                            setTitle(e.target.value)
                            onChange?.(e.target.value)
                        }}
                        onFocus={onFocus}
                        onBlur={handleBlur}
                        onKeyPress={onKeyPress}
                        InputProps={{
                            disableUnderline: true
                        }}
                        inputProps={{ maxLength }}
                        fullWidth
                        multiline={multiline}
                        maxRows={5}
                        data-test="editor-video-title"
                    />
                </Box>
                {showCharCount && (
                    <Typography
                        sx={{
                            paddingRight: '4px',
                            paddingTop: '4px',
                            fontSize: '12px',
                            color: '#828282'
                        }}
                        style={{ textAlign: charCountPosition }}
                    >
                        {`${title?.length || 0}/${maxLength}`}
                    </Typography>
                )}
            </Box>
        )
    }
)
