import { useSelector } from 'react-redux'

import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Text2SpeechInfo from 'assets/images/text-to-speech-info.jpg'

import { updateUserPreferences } from 'ducks/reducers'

export const S2tBanner = () => {
    const { s2tBanner } = useSelector(state => state.configs.userPreferences)

    if (!s2tBanner) return null

    return (
        <Box p={0.5} textAlign="center" borderRadius="4px" bgcolor="#F5F5F5" position="relative">
            <IconButton
                sx={{
                    margin: '0 0 0 auto',
                    alignSelf: 'center',
                    position: 'absolute',
                    top: 4,
                    right: 4
                }}
                size="small"
                onClick={() => updateUserPreferences('s2tBanner', false)}
            >
                <CloseIcon />
            </IconButton>

            <Box p={0.5}>
                <img src={Text2SpeechInfo} alt="text2speech" width="80%" />
            </Box>

            <Typography style={{ fontSize: 20, fontWeight: 500 }}>Record a voiceover</Typography>
            <Typography>
                Enhance your how-to videos with voiceovers. <br /> Voiceovers are also transcribed
                to text.
            </Typography>
        </Box>
    )
}
