import { useEffect, memo } from 'react'
import { useHistory } from 'react-router-dom'

import { styled, Box, Dialog, Typography, LinearProgress } from '@mui/material'

import { SpacedGroup } from 'UI/Components'

import { getExtUrl, getUserBrowser, openLink, logToAnalytics } from 'modules'
import { useWindowView } from 'hooks'

import { ExtensionButton } from './ExtensionButton'

import Chrome from 'assets/images/chrome_download.png'
import Edge from 'assets/images/edge_download.png'
import { ReactComponent as CheckIcon } from 'assets/icons/custom-check.svg'

const Section = styled(Box)(({ theme }) => ({
    width: '50%',
    minHeight: 380,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        padding: '40px',
        width: '100%',
        minHeight: 180
    }
}))

const list = [
    'Explain how your product works with video',
    'Instantly share knowledge with your team',
    'Describe project workflows'
]

type Props = {
    isOpen: boolean
    onClose: () => void
    onDownloadLaterClick?: () => void
    isLoading?: boolean
}

const isEdge = getUserBrowser() === 'edge'

export const InstallExtDialog = memo(
    ({ isOpen, onClose, onDownloadLaterClick, isLoading }: Props) => {
        const { isMobileView } = useWindowView()

        const { replace } = useHistory()

        useEffect(() => {
            const { search, hash } = window.location
            const queryParams = new URLSearchParams(search)

            if (isOpen) queryParams.set('extensionPopup', 'true')
            else queryParams.delete('extensionPopup')

            replace({
                search: queryParams.toString(),
                hash
            })
        }, [isOpen, replace])

        const ActionIcon = isEdge ? Edge : Chrome

        return (
            <Dialog
                fullWidth
                maxWidth="md"
                sx={{
                    [`& .MuiPaper-root`]: {
                        maxWidth: 800
                    }
                }}
                open={isOpen}
            >
                <Box position="absolute" top={0} left={0} right={0}>
                    {isLoading && <LinearProgress />}
                </Box>
                <Box
                    display="flex"
                    height="100%"
                    flexDirection={isMobileView ? 'column-reverse' : 'row'}
                >
                    <Section bgcolor="#E7EFFD" justifyContent="flex-end">
                        <img src={ActionIcon} width={isMobileView ? '100%' : 355} alt="action" />
                    </Section>
                    <Section
                        sx={{
                            padding: isMobileView ? '20px' : '40px 40px 30px 70px',
                            justifyContent: 'space-between',
                            alignItems: isMobileView ? 'center' : 'flex-start'
                        }}
                    >
                        <Box>
                            <Typography style={{ fontWeight: 500, fontSize: '20px' }}>
                                Create stunning step by
                            </Typography>
                            <Typography style={{ fontWeight: 500, fontSize: '20px' }}>
                                step videos in seconds
                            </Typography>
                            {!isMobileView && (
                                <>
                                    <Box mt={1} />

                                    <Typography variant="caption" color="textSecondary">
                                        Personalize your video by editing images,
                                    </Typography>
                                    <Typography
                                        display="block"
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        text and callouts
                                    </Typography>
                                    <Box>
                                        {list.map((item, i) => (
                                            <SpacedGroup key={i} mt={2.3} spacing={1}>
                                                <Box
                                                    bgcolor="#73CC6D"
                                                    minWidth={20}
                                                    height={20}
                                                    borderRadius="50%"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <CheckIcon />
                                                </Box>
                                                <Typography variant="caption">{item}</Typography>
                                            </SpacedGroup>
                                        ))}
                                    </Box>
                                </>
                            )}
                        </Box>

                        <Box mt="20px">
                            <ExtensionButton
                                variant="contained"
                                style={{
                                    padding: '8px 15px'
                                }}
                                onClick={browserType => {
                                    openLink(getExtUrl(browserType))
                                    onClose()
                                    /* removeDialogQuery() */
                                }}
                            />
                        </Box>

                        <Box mt={2} />
                        <Typography
                            data-test="extension-download-later"
                            onClick={() => {
                                onDownloadLaterClick?.()

                                logToAnalytics('downloadLaterClicked')
                            }}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            variant="caption"
                            color="textSecondary"
                        >
                            Download later
                        </Typography>
                    </Section>
                </Box>
            </Dialog>
        )
    }
)
