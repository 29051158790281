import { useDispatch, useSelector } from 'react-redux'

import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import { togglePlaybookSection } from 'ducks/actions'
import { logToAnalytics } from 'modules'

type Props = {
    index: number
}

export const DeleteButton = ({ index }: Props) => {
    const dispatch = useDispatch()

    const { sections } = useSelector(state => state.videoEditor)
    const isDeleted = Boolean(sections[index].isDeleted)

    return (
        <Box position="absolute" top="100%" left="46%">
            <Tooltip title={isDeleted ? 'Undo' : 'Delete slice'}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 'calc(100% + 8px)',
                        right: '-0.75rem',
                        bgcolor: '#090c10',
                        borderRadius: '50%'
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={() => {
                            dispatch(togglePlaybookSection(index))
                            logToAnalytics(isDeleted ? 'undo_delete_chapter' : 'delete_chapter', {
                                chapterIndex: index
                            })
                        }}
                    >
                        <DeleteIcon
                            sx={{
                                color: isDeleted ? '#cd0000' : 'white',
                                fontSize: 16
                            }}
                        />
                    </IconButton>
                </Box>
            </Tooltip>
        </Box>
    )
}
