import { type OptionType } from 'modules'
import {
    type AudioBackgroundType,
    type MultipleCtaType,
    type SingleCtaType,
    type StepType
} from './quickGuiddes'

export type PlaybookTeamsType = {
    id: string
    name: string
    logo?: string // now we haven't this field but after backend fix field will be required
}

export type PlaybookApplicationType = {
    applicationId: string
    applicationName: string
    applicationLogo: string
}

type PlaybookTranscriptLanguageType =
    | 'de-DE'
    | 'es-ES'
    | 'it-IT'
    | 'fr-FR'
    | 'iw-IL'
    | 'pt-BR'
    | 'en-US'
    | 'en-AU'
    | 'en-GB'
    | 'en-CA'
    | 'en-IN'
    | 'en-IE'
    | 'en-ZA'

export type PlaybookChapterType = {
    title: string
    start: number
    end: number
    thumbnail: string
}

export type PlaybookFileType = {
    id: string
    name: string
    uid: string
    url: string
}

export type PlaybookLinkType = {
    id: string
    name: string | null
    uid: string
    url: string
}

export type PlaybookPublishedForUserType = {
    displayName: string
    email: string
    photoURL: string
    uid: string
}

export type PlaybookVisibilityType = 'Private' | 'Team' | 'Connected' | 'Public'

export type CallToActionType = {
    enabled: boolean
    text: OptionType
    link: string
    position?: 'bottomLeft' | 'bottomRight' | 'topRight' | 'topLeft'
    start: number
    end: number
    image?: string | null
    imageOption: 'default' | 'custom'
    timeOption: 'entireVideo' | 'lastChapter'
}

export const enum PlaybookMode {
    Playbook = 'playbook',
    Playlist = 'playlist',
    QuickGuidde = 'quickGuidde'
}

export type PlaybookModeType = `${PlaybookMode}`

export type MagicCaptureType = {
    captureType?: {
        id: string
        title: string
        imageUrl: string
        withExtraInput?: boolean
    }
    description: string
    language?: {
        langCode: string
        langName: string
    }
}

export type PlaybookType = {
    url: string
    screenshotUrl: string
    organizationId: string
    creator_uid: string
    uploadedByUid: string
    duration: number
    uploadedBy: {
        displayName: string
        email: string
        photoURL: string
    }
    previewUrl?: string
    id: string
    username: string
    userImage?: string
    uploadedAt: number
    title: string
    description: string
    tags: Array<string>
    isPublished: boolean
    type: 'zoom' | 'manual' | 'webapp'
    gifUrl: string
    link: string
    teams?: Array<PlaybookTeamsType>
    views: number
    applications: Array<PlaybookApplicationType>
    originalFileName: string
    rating: number
    transcriptUrl: string
    subtitlesUrl: string
    currentTranscriptLang: PlaybookTranscriptLanguageType
    slicingSuggestion?: Array<PlaybookChapterType>
    files?: Array<PlaybookFileType> | null
    links?: Array<PlaybookLinkType> | null
    isMainIncluded: boolean
    publishedForUsers?: Array<PlaybookPublishedForUserType>
    visibility: PlaybookVisibilityType
    previousScreenshotUrl: string
    uploadedByOrgId: string
    orgName: string
    isPublic: boolean
    spaceGroups?: Array<string> | null
    orgLogo?: string
    orgColor?: string
    coverColor?: string
    cta?: CallToActionType
    mode: PlaybookMode.Playbook
    lastUpdatedAt: number
    transition?: string
}

export type PlaylistType = Omit<PlaybookType, 'mode'> & {
    mode: PlaybookMode.Playlist
    isPlaylist: true
    playbookList: Array<string>
}

export type QuickGuiddeType = Omit<PlaybookType, 'mode' | 'cta'> & {
    mode: PlaybookMode.QuickGuidde
    cta?: SingleCtaType | MultipleCtaType
    steps: Array<StepType>
    version: number
    magicCapture?: MagicCaptureType
    audioBackground?: AudioBackgroundType | null
    language?: {
        langCode: string
        langName: string
    }
    goToLabel: string
    showAiDialog: boolean
    previewUrl?: string
    isDraft?: boolean
    draftVersion?: number
    lastUpdatedBy: string
    isWizard?: boolean
    isFakeDraft?: boolean
    smallPreviewUrl?: string
    sourceCaptureVideo?: {
        url: string
        duration: number
        videoThumbnailPreview: string
    }
    generateAutomaticVoiceoverSkipped?: boolean
}

export type AnyPlaybookType = PlaybookType | QuickGuiddeType | PlaylistType

export type SearchSectionsType = Array<{
    id: string
    start: number
    _snippetResult: {
        transcript: SearchSnippetField
        tags: Array<SearchSnippetField>
        title: SearchSnippetField
    }
}>

type SearchSnippetField = {
    matchLevel: 'none' | 'full'
    value: string
}

export type SearchPlaybookType = {
    _snippetResult?: {
        transcript: {
            matchLevel: 'none' | 'full'
            value: string
        }
        tags: Array<{ value: string; matchLevel: 'none' | 'full' }>
        title: {
            value: string
            matchLevel: 'none' | 'full'
        }
    }
    visibility: PlaybookVisibilityType
    coverColor?: string
    isPlaylist: boolean
    applications: Array<PlaybookApplicationType>
    title: string
    creator_name: string
    id: string
    img: string
    sections: SearchSectionsType
    tags: Array<string>
    nbHits: number
    duration: number
    mode: PlaybookModeType
}

export type VideoOverlayDataType = {
    showEndingScreenFooter: boolean
    hidePublicGetStartedBanner: boolean
    hideVideoReactions: boolean
    hideNewPublicEmbedEnding: boolean
    hidePauseScreen: boolean
    showSocialFacebookButton: boolean
    isFreePlan: boolean
    isTrialPlan: boolean
    brandKit: {
        logo: string
        color: string
    }
}

export type PlaybookTrackingLinkType = {
    createdAt: number
    id: string
    uid: string
    orgId: string
    playbookId: string
    name: string
    domain?: string
}
