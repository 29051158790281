import { type ReactNode } from 'react'

import clsx from 'clsx'

import {
    Table,
    styled,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress
} from '@mui/material'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

const PREFIX = 'FileTable'

const classes = {
    headerCell: `${PREFIX}-headerCell`,
    loader: `${PREFIX}-loader`
}

const StyledTable = styled(Table)({
    [`& .${classes.headerCell}`]: {
        backgroundColor: '#ffffff',
        '&:not(&:first-child)': {
            width: '10rem'
        },
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        }
    },
    [`& .${classes.loader}`]: {
        padding: '0',
        border: 'none'
    }
})

type Props = {
    columns: Array<{
        value: string
        label: string
    }>
    isLoading: boolean
    children: ReactNode
    sortBy: string
    sortDesc: boolean
    onSortChange: (value: string) => void
}
export const FileTable = ({
    columns,
    isLoading,
    children,
    sortBy,
    sortDesc,
    onSortChange
}: Props) => {
    return (
        <StyledTable stickyHeader>
            <TableHead>
                <TableRow>
                    {columns.map(column => (
                        <TableCell
                            key={column.value}
                            className={clsx(classes.headerCell)}
                            onClick={() => onSortChange(column.value)}
                        >
                            {sortBy === column.value &&
                                (sortDesc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
                {isLoading && (
                    <TableRow>
                        <TableCell className={classes.loader} colSpan={99}>
                            <LinearProgress />
                        </TableCell>
                    </TableRow>
                )}
            </TableHead>
            <TableBody>{children}</TableBody>
        </StyledTable>
    )
}
