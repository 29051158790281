import { useMemo } from 'react'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    List,
    Avatar,
    Box
} from '@mui/material'
import { styled } from '@mui/material/styles'

import ArrowIcon from '@mui/icons-material/ArrowDropDown'
import PeopleIcon from '@mui/icons-material/PeopleOutlineOutlined'

import { WorkspaceListItem } from './WorkspaceListItem'
import { type WorkspaceType } from './DesktopWorkspacePanel'

import { SpacedGroup } from 'UI/Components'

import { useAuth, useQuery } from 'hooks'

const PREFIX = 'MuiAccordion'

const classes = {
    root: `${PREFIX}-root`,
    expanded: `${PREFIX}-expanded`
}

const StyledAccordion = styled(Accordion)({
    [`&.${classes.root}`]: {
        background: 'transparent',
        boxShadow: 'none',

        '&:before': {
            display: 'none'
        }
    },
    [`&.${classes.expanded}`]: {
        margin: '0 !important'
    }
})

type WorkspacesResponse = {
    workspaces: Array<WorkspaceType>
    activeOrgId: string
    canCreateWorkspace: boolean
}

export const MobileWorkspacePanel = () => {
    const { orgId } = useAuth()

    const $workspaces = useQuery<WorkspacesResponse>('/c/v1/workspaces', { method: 'GET' })

    const activeWorkspace = useMemo(() => {
        return $workspaces.data?.workspaces?.find(workspace => workspace.orgId === orgId)
    }, [$workspaces.data, orgId])

    if (!activeWorkspace) return null

    return (
        <StyledAccordion
            classes={{
                root: classes.root,
                expanded: classes.expanded
            }}
        >
            <AccordionSummary
                expandIcon={
                    Number($workspaces.data?.workspaces?.length) > 1 ? (
                        <ArrowIcon style={{ color: 'rgba(9, 12, 16, 0.6)' }} />
                    ) : null
                }
                style={{ maxHeight: 70, padding: '8px  16px 8px 8px' }}
            >
                <SpacedGroup my={1} mx={1} spacing={1}>
                    <Avatar
                        src={activeWorkspace.logo}
                        sizes="40px"
                        style={{ border: '1ps solid rgba(9, 12, 16, 0.08)' }}
                    >
                        <PeopleIcon fontSize="small" />
                    </Avatar>
                    <Box>
                        <Typography variant="caption" style={{ color: 'rgba(9, 12, 16, 0.6)' }}>
                            Your workspace
                        </Typography>
                        <TextOverflow lineClamp={1}>
                            <Typography style={{ fontWeight: 600, fontSize: 14, lineHeight: 1 }}>
                                {activeWorkspace.name}
                            </Typography>
                        </TextOverflow>
                        <Typography variant="caption" style={{ color: 'rgba(9, 12, 16, 0.6)' }}>
                            {activeWorkspace.members.length} user
                            {activeWorkspace.members.length > 1 ? 's' : ''}
                        </Typography>
                    </Box>
                </SpacedGroup>
            </AccordionSummary>
            <AccordionDetails style={{ background: '#FFFFFF', padding: 0 }}>
                <List dense disablePadding style={{ width: '100%' }}>
                    {$workspaces.data?.workspaces
                        ?.filter(it => it.orgId !== activeWorkspace.orgId)
                        ?.map(workspace => (
                            <WorkspaceListItem
                                key={workspace.orgId}
                                menuIconColor="rgba(9, 12, 16, 0.6)"
                                workspace={workspace}
                                isActive={workspace.orgId === activeWorkspace.orgId}
                                canLeaveWorkspace={
                                    Number($workspaces.data?.workspaces?.length || 0) > 1
                                }
                            />
                        ))}
                </List>
            </AccordionDetails>
        </StyledAccordion>
    )
}
