import * as types from 'ducks/types'
import { type SectionType } from 'ducks/reducers'

export const setInitialSections = (sections: Array<SectionType>) =>
    ({
        type: types.SET_INITIAL_SECTIONS,
        payload: sections
    }) as const

export const setSections = (sections: Array<SectionType>) =>
    ({
        type: types.SET_SECTIONS,
        payload: sections
    }) as const

export const setSectionsReset = () =>
    ({
        type: types.SET_SECTIONS_RESET
    }) as const

export const setChapterTitle = (payload: any) =>
    ({
        type: types.SET_CHAPTER_TITLE,
        payload
    }) as const

export const setCurrentTime = (time: any) =>
    ({
        type: types.SET_CURRENT_TIME,
        payload: time
    }) as const

export const setDuration = (duration: any) =>
    ({
        type: types.SET_DURATION,
        payload: duration
    }) as const

export const setActivePlaybookPart = (number: number) =>
    ({
        type: types.SET_ACTIVE_SECTION,
        payload: number
    }) as const

export const togglePlaybookSection = (index: number) =>
    ({
        type: types.TOGGLE_PLAYBOOK_SECTION,
        payload: index
    }) as const

export const closeVideoEditor = () =>
    ({
        type: types.CLOSE_VIDEO_EDITOR
    }) as const
