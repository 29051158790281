import { Box } from '@mui/material'

import { CaptionTextField } from './CaptionText'

type Props = {
    onBlur: (_newText: string) => void
    onFocus: () => void
}

export const EmptyCaptionBlock = ({ onBlur, onFocus }: Props) => {
    return (
        <Box p={2} pl={2.5} pr={1.5}>
            <CaptionTextField onFocus={onFocus} onBlur={e => onBlur(e.target.value)} />
        </Box>
    )
}
