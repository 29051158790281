import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useObjectVal } from 'react-firebase-hooks/database'
import { useAuthState } from 'react-firebase-hooks/auth'

import { ref } from 'firebase/database'

import {
    ProLimitationDialog,
    BasicLimitationDialog,
    ProPlanStartedDialog,
    UpgradePlanDialog,
    TrialPlanStartedDialog,
    TrialEndDialog,
    TrialWeekToEndDialog,
    UpdatePlanDialog,
    PaymentInProcessDialog,
    FreePlanStartedDialog
} from 'UI/Components'
import { RoleDowngradeBanner } from './RoleDowngradeBanner'

import {
    useServiceUsage,
    planTypes,
    useAuth,
    useRoles,
    useWindowView,
    useQueryParams,
    useNotification,
    usePayment,
    useBoolean,
    useLocalStorage,
    useOrganizationUsers
} from 'hooks'
import { showPlanDialog, setNewAuth, showPlanNotifications, closePlanDialogs } from 'ducks'
import { filterURLParams, auth, rtdb } from 'modules'

const usageDialogBreakpoint = [75, 100, 120]

// it's a controller for the components
// main goal is to display notification dialogs in a special order
const ServiceUsageController = () => {
    const [user, isAuthLoading] = useAuthState(auth)

    const {
        dialogs: { updatePlanDialog, upgradePlanDialog },
        notifications: {
            trialPlanStarted,
            paidPlanLimitation,
            freePlanLimitation,
            paidPlanStarted,
            freePlanStarted
        }
    } = useSelector(state => state.serviceUsage)

    const { roles, newAuth, orgId, uid } = useAuth()
    const { orgUsers } = useOrganizationUsers()
    const currentOrgUser = orgUsers?.find(user => user.uid === uid)
    const { isAdmin } = useRoles()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const paymentInProgress = useBoolean()

    const {
        usedPercent,
        activePlanId,
        disableTrialDialog,
        isLoading,
        isTrialPlan,
        isFreePlan,
        trialDuration,
        isReversedTrial
    } = useServiceUsage()

    const [planId, setPlanId] = useState(activePlanId)
    const queryParams = useQueryParams()

    const showPlans = queryParams.get('showPlans')

    const { isDesktopView } = useWindowView()
    const { showErrorNotification } = useNotification()
    const { chargeCustomer } = usePayment()

    const [nextUsageBreakpoint, setNextUsageBreakpoint] = useLocalStorage(
        'gd-notification-dialog',
        0
    )

    const [data] = useObjectVal<{ trialEnded?: boolean; trialWillEndAt?: number }>(
        ref(rtdb, `stripe/${orgId}/showDialog/${uid}`)
    )

    const { trialEnded, trialWillEndAt } = data || {}

    useEffect(() => {
        // show dialog that paid/trial plan started when plan id actually changed id db
        if (isLoading || !activePlanId) return

        setPlanId(activePlanId)

        if (planId && planId !== activePlanId) {
            dispatch(
                showPlanNotifications({
                    trialPlanStarted: isTrialPlan,
                    freePlanStarted: isFreePlan,
                    paidPlanStarted: !isTrialPlan && !isFreePlan
                })
            )
        }
    }, [activePlanId, planId, isLoading, dispatch, isTrialPlan, isFreePlan])

    // open Dialog once or after login if usage more than 75%/100%/120% of his plan
    // and store data in local storage
    useEffect(() => {
        if (isLoading) return

        if (usedPercent < usageDialogBreakpoint[0]) {
            dispatch(
                showPlanNotifications({ freePlanLimitation: false, paidPlanLimitation: false })
            )
        }

        const exceededBreakpoints = usageDialogBreakpoint.filter(n => n > usedPercent)
        const newNextBreakpoint = exceededBreakpoints.length
            ? Math.min(...exceededBreakpoints)
            : Math.max(...usageDialogBreakpoint)
        const showAfterLogin = newAuth && usedPercent > usageDialogBreakpoint[0]

        setNextUsageBreakpoint(newNextBreakpoint)

        if (!showAfterLogin && newNextBreakpoint <= nextUsageBreakpoint) {
            return
        }

        dispatch(
            showPlanNotifications({
                freePlanLimitation: isFreePlan,
                paidPlanLimitation: !isFreePlan
            })
        )
    }, [
        dispatch,
        newAuth,
        nextUsageBreakpoint,
        usedPercent,
        setNextUsageBreakpoint,
        isLoading,
        activePlanId,
        isFreePlan
    ])

    const query = useQueryParams()
    const pathname = history.location.pathname
    const historyReplace = history.replace

    useEffect(() => {
        // after successful setupIntent stripe redirects user from a bank account
        // to our app with query params that we need to start actual payment
        const redirectStatus = query.get('redirect_status')
        const setupIntent = query.get('setup_intent')
        const clientSecret = query.get('setup_intent_client_secret')

        if (redirectStatus !== 'succeeded') {
            if (setupIntent && clientSecret) {
                showErrorNotification('Something went wrong, please try again')
            }

            return
        }

        if (setupIntent && clientSecret) {
            paymentInProgress.setTrue()
            chargeCustomer({ clientSecret, setupIntent }).finally(paymentInProgress.setFalse)
        }

        historyReplace(pathname)
    }, [query, pathname, historyReplace, showErrorNotification, chargeCustomer, paymentInProgress])

    useEffect(() => {
        if (!showPlans) return

        dispatch(showPlanDialog('upgradePlanDialog'))
        historyReplace(filterURLParams(location, ['showPlans']))
    }, [dispatch, historyReplace, location, showPlans])

    if (isAuthLoading || user?.isAnonymous || roles?.n || activePlanId === planTypes.enterprise) {
        return null
    }

    const showDowngradeBanner =
        currentOrgUser?.viewerOnlyAfterMs && currentOrgUser.viewerOnlyAfterMs > Date.now()

    return (
        <>
            {/*normal popup*/}
            {paymentInProgress.isTrue && <PaymentInProcessDialog />}

            {/*normal popup*/}
            {upgradePlanDialog && (
                <UpgradePlanDialog onClose={() => dispatch(closePlanDialogs())} />
            )}

            {/* will be removed in the future, these components haven't mobile view */}
            {isDesktopView && (
                <>
                    {/*normal popup*/}
                    {updatePlanDialog && (
                        <UpdatePlanDialog
                            onClose={() => dispatch(closePlanDialogs())}
                            onChangePlan={() => dispatch(showPlanDialog('upgradePlanDialog'))}
                        />
                    )}

                    {/* Your trial has started! dialog */}
                    {trialPlanStarted && !isReversedTrial && (
                        <TrialPlanStartedDialog
                            trialDays={trialDuration}
                            onClose={() =>
                                dispatch(showPlanNotifications({ trialPlanStarted: false }))
                            }
                        />
                    )}

                    {/* "We’ll miss you" dialog*/}
                    {freePlanStarted && (
                        <FreePlanStartedDialog
                            onClose={() =>
                                dispatch(showPlanNotifications({ freePlanStarted: false }))
                            }
                        />
                    )}

                    {/* "Congratulations!" dialog*/}
                    {paidPlanStarted && (
                        <ProPlanStartedDialog
                            onClose={() =>
                                dispatch(showPlanNotifications({ paidPlanStarted: false }))
                            }
                        />
                    )}

                    {/* "Keep on going" dialog */}
                    {paidPlanLimitation && (
                        <ProLimitationDialog
                            onClose={() => {
                                dispatch(showPlanNotifications({ paidPlanLimitation: false }))
                                dispatch(setNewAuth(false))
                            }}
                            onPrimaryClick={() => {
                                if (isAdmin) {
                                    dispatch(showPlanDialog('upgradePlanDialog'))
                                }
                            }}
                        />
                    )}

                    {/* "Keep on going" dialog */}
                    {freePlanLimitation && (
                        <BasicLimitationDialog
                            onClose={() => {
                                dispatch(showPlanNotifications({ freePlanLimitation: false }))
                                dispatch(setNewAuth(false))
                            }}
                        />
                    )}

                    {/* Your trial has expired dialog */}
                    {trialEnded && !isTrialPlan && isFreePlan && (
                        <TrialEndDialog onClose={() => disableTrialDialog('trialEnded')} />
                    )}

                    {/* Your trial has expired in xDays dialog */}
                    {trialWillEndAt && isTrialPlan && (
                        <TrialWeekToEndDialog
                            onClose={() => disableTrialDialog('trialWillEndAt')}
                            date={trialWillEndAt}
                        />
                    )}
                </>
            )}
            {showDowngradeBanner && <RoleDowngradeBanner date={currentOrgUser.viewerOnlyAfterMs} />}
        </>
    )
}

export default ServiceUsageController
