import { type CSSProperties } from 'react'
import clsx from 'clsx'

import { styled, Box } from '@mui/material'

import NotInterestedIcon from '@mui/icons-material/NotInterested'

import { useBrandKit } from 'hooks'

const PREFIX = 'BrandKitColorPicker'

const classes = {
    option: `${PREFIX}-option`,
    active: `${PREFIX}-active`
}

const StyledBox = styled(Box)({
    [`& .${classes.option}`]: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        cursor: 'pointer',
        border: '1px solid #EDEEEF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.active}`]: {
        border: '2px solid #CB0000'
    }
})

const defaultColors = ['#D53333', '#FED243', '#EBA64D', '#9F90FD', '#42CFD8', '#73CC6D']

const borderColor = '1px solid rgba(9, 12, 16, 0.08)'

const borderedStyles = {
    border: borderColor,
    borderRadius: 4
}

type Props = {
    currentColor: CSSProperties['color']
    withBorder?: boolean
    additionalColors?: Array<string>
    onClick: (color: string) => void
    sectionsDirection?: 'column' | 'row'
}

export const BrandKitColorPicker = ({
    withBorder,
    currentColor,
    additionalColors = [],
    onClick,
    sectionsDirection = 'column'
}: Props) => {
    const { brandKitColors } = useBrandKit()

    // new Set is using for removing duplications
    const list = [...new Set([...additionalColors, '#000000', '#FFFFFF', ...defaultColors])]

    return (
        <Box display="flex" flexDirection={sectionsDirection}>
            <Box display="flex" width="min-content" style={withBorder ? borderedStyles : {}}>
                {brandKitColors.map((color, i) => (
                    <ColorItem
                        key={color}
                        withBorder={withBorder && i !== 0}
                        onClick={onClick}
                        isActive={color === currentColor}
                        color={color}
                        currentColor={currentColor}
                    />
                ))}
            </Box>

            <Box
                display="flex"
                width="min-content"
                style={withBorder ? borderedStyles : {}}
                mt={sectionsDirection === 'column' ? 1 : 0}
                ml={sectionsDirection === 'row' ? 2 : 0}
            >
                {list.map((color, i) => (
                    <ColorItem
                        withBorder={withBorder && i !== 0}
                        key={color}
                        onClick={onClick}
                        isActive={color === currentColor}
                        color={color}
                        currentColor={currentColor}
                    />
                ))}
            </Box>
        </Box>
    )
}

type ColorItemProps = {
    onClick: (color: string) => void
    isActive: Boolean
    color: string
    currentColor?: string
    withBorder?: boolean
}
export const ColorItem = ({
    onClick,
    withBorder,
    currentColor,
    isActive,
    color
}: ColorItemProps) => {
    return (
        <StyledBox
            style={{
                background: isActive ? 'rgba(203, 0, 0, 0.1)' : '',
                borderLeft: withBorder ? borderColor : ''
            }}
        >
            <Box
                m={0.5}
                onClick={() => onClick(color)}
                style={{ background: color }}
                className={clsx(classes.option, {
                    [classes.active]: currentColor === color
                })}
            >
                {color === 'transparent' && (
                    <NotInterestedIcon
                        style={{
                            fontSize: isActive ? 18 : 20,
                            color: 'rgba(0, 0, 0, 0.54)'
                        }}
                    />
                )}
            </Box>
        </StyledBox>
    )
}
