import { type MouseEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, ClickAwayListener, styled } from '@mui/material'

import { SmartPreviewContext } from './SmartPreviewProvider'

import { formatTime, logToAnalytics } from 'modules'
import { setActiveStep } from 'ducks'

const tooltipWidth = 40

const Wrapper = styled(Box)({
    height: 0,
    transform: 'translateY(-8px)',
    paddingTop: 4,
    paddingLeft: 1,
    paddingRight: 1,
    '&:hover': {
        transform: 'translateY(-14px)',
        '& > div': {
            height: 10
        }
    }
})

const MainBar = styled(Box)({
    position: 'relative',
    width: '100%',
    height: 4,
    background: 'darkgray',
    cursor: 'pointer'
})

const ActiveBar = styled(Box)({
    position: 'absolute',
    height: '100%',
    background: '#cd0000',
    pointerEvents: 'none',
    zIndex: 1,
    transition: 'width 0.1s linear'
})

const HoverBar = styled(Box, { shouldForwardProp: prop => prop !== 'tooltipRight' })(
    ({ tooltipRight }: { tooltipRight: number }) => ({
        position: 'absolute',
        height: '100%',
        background: 'rgba(255,255,255,0.4)',
        pointerEvents: 'none',
        zIndex: 2,
        '&::after': {
            content: 'attr(data-time)',
            position: 'absolute',
            top: '-75%',
            right: tooltipRight,
            width: tooltipWidth,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: 'translate(50%, -100%)',
            background: 'gainsboro',
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.54)',
            borderRadius: 8
        }
    })
)

export const TimelineBar = () => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const [hoverPercent, setHoverPercent] = useState<null | number>(null)
    const [tooltipRight, setTooltipRight] = useState(0)

    const { stepsTimeline, setTime, pause, status, totalProgress, time } =
        useContext(SmartPreviewContext)

    const { activeStep } = useSelector(state => state.qgEditor.present)

    const pauseWithStepUpdate = useCallback(
        (time: number = stepsTimeline.totalDuration) => {
            pause()
            const newIndex = stepsTimeline.steps.findIndex(step => time <= step.stepEnd)
            if (newIndex === activeStep) return
            if (newIndex === -1) return dispatch(setActiveStep(stepsTimeline.steps.length - 1))
            dispatch(setActiveStep(newIndex))
        },
        [activeStep, dispatch, pause, stepsTimeline]
    )

    const seekToPosition = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            const { width, left } = e.currentTarget.getBoundingClientRect()
            const position = e.clientX - left
            const percent = Math.min(100, Math.max(0, position / width))

            const newTime = stepsTimeline.totalDuration * percent
            setTime(newTime)
            logToAnalytics('smart_preview_seek', {
                playbookId,
                time: newTime
            })

            pauseWithStepUpdate(newTime)
        },
        [stepsTimeline.totalDuration, setTime, playbookId, pauseWithStepUpdate]
    )

    // Pause timer and update active step when the last step is finished
    useEffect(() => {
        const isFinished = totalProgress === 100
        if (isFinished) pauseWithStepUpdate(stepsTimeline.totalDuration)
    }, [pauseWithStepUpdate, stepsTimeline.totalDuration, totalProgress])

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={e => {
                const isPlayButton = (e.target as HTMLElement)?.id === 'play-button'
                if (status === 'stopped' || isPlayButton) return
                pauseWithStepUpdate(time)
                logToAnalytics('smart_preview_pause', {
                    playbookId,
                    time
                })
            }}
        >
            <Wrapper>
                <MainBar
                    onClick={seekToPosition}
                    onMouseMove={e => {
                        const { width, left } = e.currentTarget.getBoundingClientRect()
                        const position = e.clientX - left
                        const percent = Math.max((position / width) * 100, 0)

                        setHoverPercent(percent)

                        const hoverBarWidth = (width / 100) * percent
                        setTooltipRight(Math.max(hoverBarWidth + tooltipWidth / 2 - width, 0))
                    }}
                    onMouseLeave={() => setHoverPercent(null)}
                >
                    <ActiveBar width={(time / stepsTimeline.totalDuration) * 100 + '%'} />
                    {hoverPercent !== null && totalProgress !== hoverPercent && (
                        <HoverBar
                            tooltipRight={tooltipRight}
                            width={hoverPercent + '%'}
                            data-time={formatTime(
                                (stepsTimeline.totalDuration / 100) * (hoverPercent || 0)
                            )}
                        />
                    )}
                </MainBar>
            </Wrapper>
        </ClickAwayListener>
    )
}
