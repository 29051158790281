import { type Key, Suspense, memo } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import { paths } from 'app/paths'
import { type RouteType, routes } from 'app/routes'

import PageNotFound from 'UI/Routes/PageNotFound'
import { GuiddeLoad, PrivateRoute } from 'UI/Components'

export const renderRoutes = (routes: Array<RouteType>) => {
    return routes.map(route => {
        const { path, role, feature, component, isPrivate } = route

        const RouteComponent = isPrivate ? PrivateRoute : Route

        return (
            <RouteComponent key={path as Key} exact path={path} role={role} feature={feature}>
                {component}
            </RouteComponent>
        )
    })
}

export const AppRouter = memo(() => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    return (
        <Suspense
            fallback={
                <GuiddeLoad message="Magically create video documentation with AI in seconds!" />
            }
        >
            <Switch>
                <Redirect
                    exact
                    from="/home/library"
                    to={{ pathname: paths.home, search: queryParams.toString() }}
                />
                {renderRoutes(routes)}
                <Route component={PageNotFound} />
            </Switch>
        </Suspense>
    )
})
