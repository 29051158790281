import { useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Divider,
    Paper,
    Popper,
    Typography
} from '@mui/material'

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'

import { BrandKitColorPicker } from 'UI/Components'
import { DEFAULT_BACKGROUND_COLOR_LAYER } from 'UI/Routes/quick-guidde/CanvasEditor'
import { VideoUploader } from './VideoUploader'
import { ImageUploader } from './ImageUploader'
import { Gradients } from './Gradients'
import { Templates } from './Templates'

import { type QuickGuiddeType, type RectangleShapeType, Shape } from 'app/types'
import { useBoolean, useBrandKit, noBrandKitBackgroundId } from 'hooks'
import { setBackgroundLayer } from 'ducks'

import { uuid } from 'modules'

export const getGradientPoints = (width: number, height: number, deg: number) => {
    // Compute angle in radians - CSS starts from 180 degrees and goes clockwise
    // Math functions start from 0 and go anti-clockwise, so we use 180 - angleInDeg to convert between the two
    const angle = ((180 - deg) / 180) * Math.PI

    // This computes the length such that the start/stop points will be at the corners
    const length = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle))

    // Compute the actual x,y points based on the angle, length of the gradient line and the center of the div
    const halfx = (Math.sin(angle) * length) / 2.0
    const halfy = (Math.cos(angle) * length) / 2.0
    const cx = width / 2.0
    const cy = height / 2.0
    const x1 = cx - halfx
    const y1 = cy - halfy
    const x2 = cx + halfx
    const y2 = cy + halfy

    return {
        fillLinearGradientStartPoint: { x: x1, y: y1 },
        fillLinearGradientEndPoint: { x: x2, y: y2 }
    }
}

type Props = {
    quickGuidde: QuickGuiddeType
}

export const BackgroundSelection = ({ quickGuidde }: Props) => {
    const dispatch = useDispatch()
    const { brandKitData } = useBrandKit()

    const open = useBoolean()
    const anchorRef = useRef(null)

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const {
        windowDimensions: { innerWidth, innerHeight },
        layers,
        kind
    } = steps[activeStep] || {}

    const bgRectLayer = layers?.find(layer => {
        return layer.type === Shape.Rectangle && layer.isBackground
    }) as RectangleShapeType | undefined

    const isComplexBg = layers.filter(layer => 'isBackground' in layer).length > 1

    const applyToAll = useBoolean(Boolean(kind === 'step' && isComplexBg))

    const hideBackgroundSelection = useMemo(() => {
        if (kind !== 'step' || isComplexBg) return false

        const bgImageLayer = layers.find(layer => layer.type === Shape.Image && layer.isBackground)
        const noBgOption = brandKitData?.background.active === noBrandKitBackgroundId

        if (noBgOption && bgImageLayer) return true
    }, [brandKitData?.background.active, isComplexBg, kind, layers])

    if (!steps[activeStep] || hideBackgroundSelection) return null

    return (
        <>
            <Button
                data-test="editor-background-button"
                ref={anchorRef}
                variant="text"
                color="inherit"
                startIcon={<FormatColorFillIcon />}
                onClick={open.setTrue}
            >
                Background
            </Button>
            <Popper
                open={open.isTrue}
                anchorEl={anchorRef?.current}
                placement="top-end"
                style={{ zIndex: 1300 }}
            >
                <ClickAwayListener onClickAway={open.setFalse}>
                    <Paper style={{ width: 455, padding: '8px 16px 16px 16px' }}>
                        <Box
                            mb={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                                Background
                            </Typography>

                            <ImageUploader
                                layers={layers}
                                applyToAll={applyToAll.isTrue}
                                windowDimensions={steps[activeStep].windowDimensions}
                            />
                            <VideoUploader language={quickGuidde.language} />
                        </Box>

                        <Box mb={2}>
                            <Divider />
                        </Box>

                        <Templates />

                        <Gradients bgRectLayer={bgRectLayer} applyToAll={applyToAll.isTrue} />

                        <Box display="flex" alignItems="flex-end" mt={2}>
                            <BrandKitColorPicker
                                currentColor={bgRectLayer?.fill || ''}
                                additionalColors={
                                    layers.every(layer => layer.type !== Shape.BrowserBar)
                                        ? ['transparent']
                                        : undefined
                                }
                                withBorder
                                sectionsDirection="row"
                                onClick={color => {
                                    dispatch(
                                        setBackgroundLayer(
                                            {
                                                ...DEFAULT_BACKGROUND_COLOR_LAYER,
                                                width: innerWidth,
                                                height: innerHeight,
                                                fill: color,
                                                id: uuid()
                                            },
                                            applyToAll.isTrue
                                        )
                                    )
                                }}
                            />
                        </Box>

                        {kind === 'step' && isComplexBg && (
                            <Box ml={-1} mt={2}>
                                <label style={{ cursor: 'pointer' }}>
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        disableRipple
                                        sx={{
                                            padding: '5px',
                                            '&:hover': {
                                                backgroundColor: 'transparent !important'
                                            }
                                        }}
                                        checked={applyToAll.isTrue}
                                        onChange={applyToAll.toggle}
                                    />
                                    <Typography variant="caption">Apply to all steps</Typography>
                                </label>
                            </Box>
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
