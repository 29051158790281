import { useDispatch } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { Button, Typography } from '@mui/material'
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined'

import { setActiveStep } from 'ducks'
import { paths } from 'app/paths'

type Props = {
    index: number
    isEditMode: boolean
    text: string
    isRtl: boolean
}

export const StepVoiceover = ({ index, isEditMode, text, isRtl }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const history = useHistory()

    return (
        <>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'rgba(9, 12, 16, 0.6)',
                    mb: 2,
                    wordBreak: 'break-word'
                }}
            >
                <bdi>{text}</bdi>
            </Typography>

            {isEditMode && (
                <Button
                    variant="outlined"
                    onClick={() => {
                        dispatch(setActiveStep(index, true))

                        history.push(
                            generatePath(paths.editQuickGuidde, {
                                playbookId
                            })
                        )
                    }}
                    startIcon={
                        <MovieCreationOutlinedIcon
                            fontSize="small"
                            style={{ marginLeft: isRtl ? 8 : 0 }}
                        />
                    }
                    sx={{
                        background: 'white',
                        mb: 2,
                        color: 'common.black',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        '&:hover': {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            background: 'white'
                        }
                    }}
                >
                    Edit voiceover
                </Button>
            )}
        </>
    )
}
