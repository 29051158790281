import { memo } from 'react'
import { styled, Box, FormHelperText, TextField } from '@mui/material'

const StyledTextField = styled(TextField)({
    '& input::placeholder': {
        fontStyle: 'italic'
    }
})

type Props = {
    color: string
    setColor: (color: string) => void
    placeholder?: string
    autoFocus?: boolean
    invalid?: boolean
    inputFullWidth?: boolean
}

export const ColorInput = memo(
    ({
        autoFocus,
        color,
        setColor,
        placeholder = 'Paste HEX here',
        invalid,
        inputFullWidth
    }: Props) => (
        <Box width={inputFullWidth ? '100%' : 'auto'}>
            <StyledTextField
                data-test="brandkit-color-input"
                placeholder={placeholder}
                variant="filled"
                fullWidth
                autoFocus={autoFocus}
                value={color}
                inputProps={{
                    maxLength: 6,
                    style: {
                        textTransform: color.length ? 'uppercase' : 'none',
                        padding: '15px 0',
                        fontSize: '16px'
                    }
                }}
                InputProps={{
                    startAdornment: <Box>{color.length ? '#' : ''}</Box>,
                    style: {
                        display: 'flex',
                        alignItems: 'baseline'
                    }
                }}
                onChange={e => {
                    const value = e.target.value.replace(/[^\w\s]/gi, '')
                    setColor(value.trim())
                }}
            />
            {invalid && (
                <FormHelperText error style={{ position: 'absolute' }}>
                    Color format is invalid
                </FormHelperText>
            )}
        </Box>
    )
)
