import { type ReactNode, useCallback, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TimelineWithSections } from './video-player/timeline-with-sections'
import { CustomControls } from '@guidde-co/shared.video-player.controls.custom-controls'

import { Box, styled } from '@mui/material'

import { VideoOverlayWrapper } from 'UI/Routes/share/common/VideoOverlayWrapper'
import { ControlledVideoPlayer, SpacedGroup, TrimTimePickers } from 'UI/Components'

import { CtaControl, ToggleSidePanel } from './hoverControls'
import { EditableTimelineWithSections } from './EditableTimelineWithSections'
import { VideoEditorButtons } from './VideoEditorButtons'
import { CallToActionWrapperQG, CallToActionWrapperVideo } from './CallToAction'

import { useVideoSections } from './use-video-sections'

import {
    type PublicBrandKitType,
    type UseBooleanType,
    CollectVideoPlayAnalytics,
    useBoolean
} from 'hooks'
import { closeVideoEditor, setCurrentTime } from 'ducks/actions'
import { roundToHundredth, logToAnalytics, playbookToAnalyticsProps, isQG } from 'modules'
import {
    type PlaybookType,
    type VideoRefType,
    type CanvasRefType,
    type QuickGuiddeType,
    type VideoOverlayDataType,
    type PlaylistType
} from 'app/types'

const CustomControlsContainer = styled(Box)({
    backgroundColor: '#000',
    '& > div': {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8
    }
})

type Props = {
    playbook: PlaybookType | QuickGuiddeType
    playlist?: PlaylistType
    src: string
    videoRef: VideoRefType
    canvasRef?: CanvasRefType
    isEditMode?: boolean
    showSidePanel?: UseBooleanType
    playlistAutoPlay?: UseBooleanType
    showVideoOverlay?: UseBooleanType
    overlayData?: VideoOverlayDataType
    hideCurrentChapters?: boolean
    onEnded?: () => void
    onLoadedData?: () => void
    onCTaVisibilityChange?: (visible: boolean) => void
    // exists only in public pages
    publicBrandKit?: PublicBrandKitType
    videoOverlay?: ReactNode
    onPause?: () => void
}

export const VideoEditor = memo(
    ({
        playbook,
        playlist,
        src,
        videoRef,
        playlistAutoPlay,
        canvasRef,
        isEditMode,
        showSidePanel,
        showVideoOverlay,
        overlayData,
        publicBrandKit,
        onLoadedData,
        onCTaVisibilityChange,
        hideCurrentChapters = false,
        onEnded,
        videoOverlay,
        onPause
    }: Props) => {
        const dispatch = useDispatch()

        const captions = useBoolean(false)

        const ctaVisible = useBoolean()

        useVideoSections({ slicingSuggestion: playbook.slicingSuggestion, isEditMode })

        const videoFocussed = useBoolean()

        const { duration, sections, currentTime } = useSelector(state => state.videoEditor)

        const handleTimeUpdated = (e: any) => {
            if (e?.target?.currentTime === undefined || !e?.target?.duration) return null

            if (isEditMode) dispatch(setCurrentTime(e.target.currentTime))

            if (
                e?.target?.currentTime >=
                (duration / 100) * sections?.[sections.length - 1]?.rangeValues[1]
            ) {
                e.target.pause()
            }
        }

        const updateTimeByPercentage = useCallback(
            (percentage: number) => {
                if (videoRef?.current) {
                    const newTime = (duration / 100) * percentage || 0

                    return (videoRef.current.currentTime = newTime)
                }
            },
            [duration, videoRef]
        )

        const updateTimeBySeconds = useCallback(
            (seconds: number) => {
                if (videoRef?.current) {
                    return (videoRef.current.currentTime = seconds)
                }
            },
            [videoRef]
        )

        const handlePlay = () => {
            const endTime = (duration / 100) * sections?.[sections.length - 1]?.rangeValues[1]

            if (roundToHundredth(currentTime) >= roundToHundredth(endTime)) {
                if (!videoRef.current) return

                videoRef.current.currentTime = (duration / 100) * sections[0].rangeValues[0]
            }
            return videoRef?.current?.play()
        }

        // Close video editor mode on unmount
        useEffect(() => {
            return () => {
                dispatch(closeVideoEditor())
            }
        }, [dispatch])

        return (
            <Box position="relative">
                {!isEditMode && (
                    <CollectVideoPlayAnalytics
                        playbook={playbook}
                        playlist={playlist}
                        videoElement={videoRef.current}
                    />
                )}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    position="relative"
                    onMouseEnter={videoFocussed.setTrue}
                    onMouseLeave={videoFocussed.setFalse}
                >
                    {isQG(playbook) ? (
                        <CallToActionWrapperQG
                            playbook={playbook}
                            videoElement={videoRef.current}
                            onCTaVisibilityChange={visibility => {
                                onCTaVisibilityChange?.(visibility)
                                ctaVisible.set(visibility)
                            }}
                        />
                    ) : (
                        <CallToActionWrapperVideo
                            playbook={playbook}
                            videoElement={videoRef.current}
                            onCTaVisibilityChange={visibility => {
                                onCTaVisibilityChange?.(visibility)
                                ctaVisible.set(visibility)
                            }}
                        />
                    )}

                    <Box style={{ width: '100%' }}>
                        <ControlledVideoPlayer
                            animationUrl={playbook.previewUrl}
                            key={src}
                            poster={playbook.screenshotUrl}
                            ref={videoRef}
                            playbookUrl={src}
                            subtitlesUrl={playbook.subtitlesUrl}
                            showCaptions={captions.isTrue}
                            link={playbook.link}
                            playbookId={playbook.id}
                            controls={false}
                            onLoadedData={onLoadedData}
                            onTimeUpdate={handleTimeUpdated}
                            onPlay={() => {
                                showVideoOverlay?.setFalse()
                                handlePlay()
                                logToAnalytics(
                                    isEditMode ? 'editorPlay' : 'videoPlayClicked',
                                    playbookToAnalyticsProps(playbook)
                                )
                            }}
                            onPause={() => {
                                logToAnalytics(
                                    isEditMode ? 'editorStop' : 'videoStopClicked',
                                    playbookToAnalyticsProps(playbook)
                                )
                                onPause?.()
                            }}
                            onEnded={() => {
                                showVideoOverlay?.setTrue()
                                onEnded?.()
                            }}
                            hoverControls={
                                <>
                                    {duration && !isQG(playbook) ? (
                                        <>
                                            <ToggleSidePanel showSidePanel={showSidePanel} />
                                            {isEditMode && <CtaControl playbook={playbook} />}
                                        </>
                                    ) : null}
                                </>
                            }
                        />
                    </Box>
                </Box>
                {videoOverlay}
                {showVideoOverlay?.isTrue && ctaVisible.isFalse && (
                    <VideoOverlayWrapper
                        showVideoOverlay={showVideoOverlay}
                        playbook={playbook}
                        overlayData={overlayData}
                        videoRef={videoRef}
                        publicBrandKit={publicBrandKit}
                    />
                )}
                <CustomControlsContainer>
                    <CustomControls
                        isFocused={videoFocussed.isTrue}
                        videoElement={videoRef.current}
                        autoPlay={playlistAutoPlay}
                        captionsInitialState={captions.isTrue}
                        hideCaptions={!playbook.subtitlesUrl}
                        onCaptionsClick={captions.toggle}
                        hideCurrentChapters={hideCurrentChapters}
                        chapters={
                            isEditMode
                                ? sections.slice(1, sections.length - 1) // remove hidden first and last elements (used for video editor only)
                                : playbook.slicingSuggestion || []
                        }
                    />
                </CustomControlsContainer>

                <Box
                    style={{
                        padding: '0 3px',
                        transform: 'translateY(-15px)'
                    }}
                >
                    {isEditMode ? (
                        <>
                            <EditableTimelineWithSections
                                onUpdateTime={updateTimeByPercentage}
                                canvasElements={canvasRef?.current as Array<HTMLCanvasElement>}
                                videoElement={videoRef?.current as HTMLVideoElement}
                            />
                            <Box display="flex" justifyContent="space-between" mt={0.5} mb={1.5}>
                                <TrimTimePickers />
                                <SpacedGroup spacing={1}>
                                    <VideoEditorButtons
                                        canvasElements={
                                            canvasRef?.current as Array<HTMLCanvasElement>
                                        }
                                        playbookId={playbook.id}
                                        onUpdateTime={updateTimeBySeconds}
                                    />
                                </SpacedGroup>
                            </Box>
                        </>
                    ) : (
                        <TimelineWithSections
                            sections={playbook?.slicingSuggestion || []}
                            videoElement={videoRef?.current as HTMLVideoElement}
                            // needed to the force rerender of the timeline
                            // when the active playlist video is changed
                            key={src}
                        />
                    )}
                </Box>
            </Box>
        )
    }
)
