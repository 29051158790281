import { memo, useEffect, useState } from 'react'
import { Box, FormHelperText, Link, TextField, Typography } from '@mui/material'

import { useNotification, useQueryParams } from 'hooks'
import { isValidUrl, logToAnalytics, playbookToAnalyticsProps } from 'modules'

import { type QuickGuiddeType } from 'app/types'

const errorMessage = 'The link format is invalid'

type Props = {
    isEditMode: boolean
    hovered: boolean
    playbook: QuickGuiddeType
    stepGoTo: {
        href: string
        host: string
    }
    link: string
    onUpdate: (_value: string) => void
}

export const StepGoTo = memo(
    ({ isEditMode, hovered, stepGoTo, link, playbook, onUpdate }: Props) => {
        const { showErrorNotification } = useNotification()

        const query = useQueryParams()

        const [linkField, setLinkField] = useState(link)

        useEffect(() => {
            setLinkField(link)
        }, [link])

        if (isEditMode) {
            const showErrorMessage = linkField?.length > 0 && !isValidUrl(linkField)

            return (
                <>
                    <TextField
                        label="Go to link"
                        value={linkField}
                        variant="filled"
                        fullWidth
                        onBlur={() => {
                            if (showErrorMessage) {
                                showErrorNotification(errorMessage)
                                setLinkField(link)
                                return
                            }
                            // we need to make update only if field was changed
                            if (link !== linkField) onUpdate(linkField)
                        }}
                        onClick={e => e.stopPropagation()}
                        onChange={event => setLinkField(event.target.value)}
                    />
                    {showErrorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
                </>
            )
        }

        // Show placeholder if stepGoTo link is empty but user can edit
        if (!link) {
            return (
                <Box
                    px={1.5}
                    py={0.5}
                    borderRadius="12px"
                    bgcolor={hovered ? '#FED243' : 'gainsboro'}
                    display="inline-block"
                >
                    Add go to link
                </Box>
            )
        }

        return (
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                <bdi>
                    {playbook.goToLabel}{' '}
                    <Link
                        underline="none"
                        href={stepGoTo.href}
                        onClick={() => {
                            logToAnalytics('openCta', {
                                ...playbookToAnalyticsProps(playbook),
                                ...{
                                    cta: playbook.cta,
                                    ctaType: 'clickedGoTo',
                                    ctaLink: stepGoTo.href,
                                    orgName: query.get('track_link_name') || ''
                                }
                            })
                        }}
                        target="_blank"
                        variant="body1"
                        style={{
                            color: '#2D9CDB',
                            fontWeight: 'bold'
                        }}
                    >
                        {stepGoTo.host}
                    </Link>
                </bdi>
            </Typography>
        )
    }
)
