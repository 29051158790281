import { firebaseConfig } from '../env'

let extensionIds = [
    'jakfnejjbhgcmjbigdnobibhnllihmgj', //staging id
    'chkfdipjdldekkcflcflmaopfioieiem',
    'jgnijfadmjdidipjnbbmnimpmbiippml',
    'hocgfmngoiokpfpanmilkknmgoehhien'
]

if (
    firebaseConfig.projectId === 'guidde-production' ||
    firebaseConfig.projectId === 'guidde-production-eu'
) {
    extensionIds = [
        'oacmmmjedhheaijfjidilonpngccnhdl',
        'igndnknlpmojcdlckjjhgghnegpjfcpc',
        'pldeojbhedianlcaejpfbnckfhkacphe'
    ]
}

export const envConfig = {
    firebaseConfig,
    extensionIds
}
