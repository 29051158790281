import { type ReactElement } from 'react'
import { type SvgIconProps, Box, Button, styled, Tooltip } from '@mui/material'

const StyledButton = styled(Button)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(38 50 56 / 50%)',
    minWidth: '40px',
    minHeight: '40px',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
        backgroundColor: 'rgb(38 50 56 / 80%)'
    }
})

type Props = {
    onClick: any
    title: string
    icon: ReactElement<SvgIconProps>
}

export const HoverButton = ({ onClick, title, icon }: Props) => (
    <Box display="flex" alignItems="center" justifyContent="center" zIndex={1}>
        <Tooltip placement="left" title={title}>
            <StyledButton color="inherit" onClick={onClick} size="medium">
                {icon}
            </StyledButton>
        </Tooltip>
    </Box>
)
