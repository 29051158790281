import {
    log as logFullStory,
    event as logFullStoryEvent,
    identify as identifyFullStoryUser,
    getCurrentSessionURL
} from '@fullstory/browser'
import { getAuth } from 'firebase/auth'
import { setUser as setSentryUser } from '@sentry/browser'
import { captureException } from '@sentry/react'

import { isLocalhost, request } from './utils'

import { type AnyPlaybookType, type PlaylistType, type UserType } from 'app/types'

const checkIflLogEventsDisabled = async () => {
    if (isLocalhost) return true

    const currentUser = getAuth()?.currentUser
    if (!currentUser) return false

    const tokenResult = await currentUser.getIdTokenResult()

    if (!tokenResult) return false

    const isLoggedInByGuidde = Boolean(tokenResult?.claims?.g)

    return isLoggedInByGuidde
}

// Identifies user in FullStory + Sentry + Mixpanel

type UserPropsType = Partial<{
    displayName: string
    email: string
    uid: string
    orgId: string | null
    env: string
    plan: string
}>

const detectBrowserWithVersion = () => {
    const nAgt = navigator.userAgent
    let browserName = navigator.appName
    let fullVersion = '' + parseFloat(navigator.appVersion)
    let nameOffset, verOffset, ix

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf('OPR/')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 4)
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 6)
        if ((verOffset = nAgt.indexOf('Version')) != -1) fullVersion = nAgt.substring(verOffset + 8)
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browserName = 'Microsoft Internet Explorer'
        fullVersion = nAgt.substring(verOffset + 5)
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browserName = 'Google Chrome'
        fullVersion = nAgt.substring(verOffset + 7)
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browserName = 'Safari'
        fullVersion = nAgt.substring(verOffset + 7)
        if ((verOffset = nAgt.indexOf('Version')) != -1) fullVersion = nAgt.substring(verOffset + 8)
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browserName = 'Mozilla Firefox'
        fullVersion = nAgt.substring(verOffset + 8)
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset)
        fullVersion = nAgt.substring(verOffset + 1)
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) != -1) fullVersion = fullVersion.substring(0, ix)
    if ((ix = fullVersion.indexOf(' ')) != -1) fullVersion = fullVersion.substring(0, ix)

    const majorVersion = parseInt('' + fullVersion, 10)
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion)
    }

    return {
        browser: browserName,
        browserVersion: fullVersion
    }
}

const getOS = () => {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (/Linux/.test(platform)) {
        os = 'Linux'
    }

    return os
}

const registerUserInAnalytics = async (props: UserPropsType) => {
    try {
        const isLogEventDisabled = await checkIflLogEventsDisabled()

        if (isLogEventDisabled) return

        const { displayName, email, uid, orgId, env, plan } = props

        const appInfo = {
            source: 'web-application',
            environment: env,
            os: getOS(),
            ...detectBrowserWithVersion()
        }

        const userInfo = {
            'user-id': uid,
            email,
            plan,
            name: displayName,
            'organization-id': orgId
        }

        // Fill in properties which we use every time when call backend to track any analytic event
        globalProperties = { ...appInfo }
        userProperties = { ...userInfo }

        // SENTRY
        setSentryUser({
            id: uid,
            username: displayName,
            ...appInfo,
            ...userInfo
        })

        // FULLSTORY
        // @ts-ignore // uid is required but in our case sometimes it's undefined
        identifyFullStoryUser(uid, { ...appInfo, ...userInfo })
    } catch (e) {
        console.error(e)
    }
}

// We use these properties in use-video-portal to enrich videoPlay event
export let userProperties = {}
export let globalProperties = {}
type EventConfigType = { allowToTrackSuperAdminEvent?: boolean }

const trackEvent = async (event: string, props = {}, eventConfig?: EventConfigType) => {
    return request('/tk/v1/track', 'POST', {
        userProperties,
        globalProperties,
        eventData: {
            event,
            ...props
        },
        eventConfig
    })
}

// Also logs an event to FullStory
const logToAnalytics = async (event: string, eventData = {}, eventConfig?: EventConfigType) => {
    const isLogEventDisabled = await checkIflLogEventsDisabled()

    if (isLogEventDisabled) return

    const sessionURL = getCurrentSessionURL(true)

    const eventProps = {
        ...eventData,
        sessionURL,
        url: window.location.href,
        timeMs: new Date().getTime()
    }

    trackEvent(event, eventProps, eventConfig).catch(error => {
        logFullStory('debug', `Track event error: ${error}`)
        console.error('Track event error: ', error)
        captureException(new Error('track_event_error'), {
            extra: {
                error
            }
        })
    })

    try {
        logFullStoryEvent(event, eventProps)
    } catch (e) {
        console.error('Log fullStoryEvent error: ', e)
        captureException(new Error('fullStory_event_error'), {
            extra: {
                error: e
            }
        })
    }
}

// UTILS
const playbookToAnalyticsProps = (
    playbook: AnyPlaybookType &
        Partial<{ campaignId: string; utmCampaign: string; playlist: PlaylistType }>,
    isAnonymous?: boolean
) => ({
    'pb-app': playbook.applications?.[0]?.applicationName || null,
    'pb-appId': playbook.applications?.[0]?.applicationId || null,
    'pb-apps': playbook.applications || null,
    'pb-tags': playbook.tags,
    'pb-id': playbook.id,
    'pb-title': playbook.title,
    'pb-visibility': playbook.visibility,
    'pb-creator-org-id': playbook.uploadedByOrgId,
    'pb-mode': playbook.mode,
    'pb-creator-name': playbook.username,
    'link-track-campaign-name': playbook.utmCampaign,
    'link-track-campaign-id': playbook.campaignId,
    playbookType: playbook.mode,
    ...(isAnonymous && {
        'anonymous-playbook': true,
        'organization-id': ''
    }),
    ...(playbook.playlist && {
        'playlist-id': playbook.playlist.id,
        'playlist-title': playbook.playlist.title
    })
})

const userToAnalyticsProps = ({ displayName, email, uid }: UserType, prefix?: string) => ({
    [`${prefix ? `${prefix}-` : ''}user-display-name`]: displayName,
    [`${prefix ? `${prefix}-` : ''}user-email`]: email,
    [`${prefix ? `${prefix}-` : ''}user-uid`]: uid
})

const spaceToAnalyticsProps = ({ id, name, description, members, owners }: any = {}) => ({
    'space-id': id,
    'space-name': name,
    'space-description': description,
    'space-members': Object.fromEntries(
        Object.entries(members || {}).map(([id, userMember]) => [
            id,
            userToAnalyticsProps(userMember as UserType)
        ])
    ),
    'space-owners': Object.fromEntries(
        Object.entries(owners || {}).map(([id, userOwner]) => [
            id,
            userToAnalyticsProps(userOwner as UserType)
        ])
    )
})

const logPlaybookDelete = (playbook: AnyPlaybookType) => {
    logToAnalytics('delete_video', playbookToAnalyticsProps(playbook))
}

type TResource = 'localDrive' | 'Zoom' | 'Youtube' | 'oneDrive' | 'GDrive'
type TFileMetadata = { size?: number; type?: string }

const logVideoImport = (inputType: TResource, metadata?: TFileMetadata) => {
    const { size, type } = metadata || {}

    logToAnalytics('videoImported', {
        inputType,
        ...(size && { fileSize: size }),
        ...(type && { fileType: type })
    })
}

export {
    logVideoImport,
    logToAnalytics,
    registerUserInAnalytics,
    logPlaybookDelete,
    playbookToAnalyticsProps,
    spaceToAnalyticsProps,
    userToAnalyticsProps
}
