import { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Stack,
    Typography,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'

import { ReactComponent as Premium } from 'assets/icons/workspace_premium.svg'

import { getFirstSpeaker, SpacedGroup } from 'UI/Components'
import { enGBcode, enUScode } from 'UI/Routes/quick-guidde/hooks'

import { showPlanDialog } from 'ducks'
import { updateUserPreferences } from 'ducks/reducers'

import { delay, getTimeString, logToAnalytics } from 'modules'
import { useServiceUsage } from 'hooks'

import { type SpeakerType } from 'app/types'

const enum playMode {
    PLAY = 'play',
    STOP = 'stop'
}

const Card = styled(Box)({
    backgroundColor: 'white',
    borderRadius: '4px',
    width: '111px',
    height: '158px',
    border: 'solid transparent 1px',
    '&:hover': {
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 20px 0 rgba(0, 0, 0, 0.12)'
    }
})

const Sample = styled(Box)({
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '14px'
})

const IconStyles = {
    width: '24px',
    height: '24px',
    cursor: 'pointer'
}

const StyledPlayIcon = styled(PlayIcon)({ ...IconStyles, color: '#000000' })
const StyledPauseIcon = styled(PauseIcon)(IconStyles)

type Props = {
    showBanner: boolean
    disabled?: boolean
}

export const T2sBanner = ({ showBanner, disabled }: Props) => {
    const dispatch = useDispatch()

    const { planName } = useServiceUsage()
    const audioRef = useRef<HTMLMediaElement | null>(null)

    // current playing time
    const [currentTime, setCurrentTime] = useState('00:00')
    // used to maintain the current playing state
    const [playStatus, setPlayStatus] = useState<playMode>(playMode.STOP)
    // used for toggling the play pause icon for a specific speaker
    const [playSpeaker, setPlaySpeaker] = useState<SpeakerType | null>(null)

    const {
        audioConfigs: { speakers }
    } = useSelector(state => state.configs)

    const figures = useMemo(() => {
        if (!Object.values(speakers || {}).length) return null

        const male = getFirstSpeaker(speakers, 'male', enGBcode)
        const female = getFirstSpeaker(speakers, 'female', enUScode)

        if (!male || !female) return null

        return {
            male,
            female
        }
    }, [speakers])

    if (!showBanner && !disabled) return null

    return (
        <ClickAwayListener
            onClickAway={() => {
                audioRef?.current?.pause()
                setPlayStatus(playMode.STOP)
                setCurrentTime('00:00')
            }}
        >
            <Box
                data-test="editor-T2sBanner"
                data-intercom="editor-T2sBanner"
                p={2}
                bgcolor="#F5F5F5"
                borderRadius="4px"
                textAlign="center"
                position="relative"
                zIndex={2}
            >
                <audio
                    src={playSpeaker?.sampleAudioUrl ?? ''}
                    ref={audioRef}
                    onPlay={() => setPlayStatus(playMode.PLAY)}
                    onPause={() => setPlayStatus(playMode.STOP)}
                    onTimeUpdate={(event: any) => {
                        setCurrentTime(getTimeString(Number(event?.target?.currentTime)).toString())
                    }}
                    onEnded={() => {
                        setPlayStatus(playMode.STOP)
                        setCurrentTime('00:00')
                    }}
                />

                {!disabled && (
                    <IconButton
                        style={{
                            margin: '0 0 0 auto',
                            alignSelf: 'center',
                            position: 'absolute',
                            top: 4,
                            right: 4
                        }}
                        size="small"
                        onClick={() => updateUserPreferences('t2sBanner', false)}
                    >
                        <CloseIcon />
                    </IconButton>
                )}

                <Typography style={{ fontSize: 20, fontWeight: 500 }}>
                    Don’t record. Type it in.
                </Typography>
                <Typography>Get a natural sounding voiceover using text.</Typography>
                <Typography>Technology has truly come a long way.</Typography>

                <Stack mt={1} spacing={1} direction="row" justifyContent="center">
                    {figures &&
                        Object.values(figures).map((figure, i) => {
                            const isPlaying =
                                figure.id === playSpeaker?.id && playStatus === playMode.PLAY

                            const iconButtonColor = isPlaying ? '#CB0000' : '#F5F5F5'

                            return (
                                <Card
                                    key={i}
                                    p={2}
                                    borderColor={isPlaying ? '#CB0000' : 'transparent'}
                                >
                                    <img
                                        src={figure.avatarUrlCompressed}
                                        alt={figure.name}
                                        width="80"
                                        height="80"
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 700
                                        }}
                                    >
                                        <b>
                                            {figure.name},{' '}
                                            {
                                                figure.langName
                                                    .replace('(', '')
                                                    .replace(')', '')
                                                    .split(' ')[1]
                                            }
                                        </b>
                                    </Typography>
                                    <SpacedGroup>
                                        <IconButton
                                            disableRipple
                                            sx={{
                                                color: 'white',
                                                padding: '3px',
                                                marginRight: '4px !important',
                                                backgroundColor: iconButtonColor,
                                                '&:hover': { backgroundColor: iconButtonColor }
                                            }}
                                            onClick={event => {
                                                event.stopPropagation()
                                                setPlaySpeaker(figure)

                                                delay(0).then(() => {
                                                    if (isPlaying) audioRef.current?.pause()
                                                    else audioRef.current?.play()
                                                })
                                            }}
                                            size="small"
                                        >
                                            {/*Toggle between play and pause button*/}
                                            {isPlaying ? <StyledPauseIcon /> : <StyledPlayIcon />}
                                        </IconButton>

                                        {/*Display the current audio playing time or "sample" when not playing*/}
                                        <Sample
                                            sx={{
                                                color: isPlaying
                                                    ? '#CB0000'
                                                    : 'rgba(9, 12, 16, 0.6)'
                                            }}
                                        >
                                            {isPlaying ? currentTime : 'Sample'}
                                        </Sample>
                                    </SpacedGroup>
                                </Card>
                            )
                        })}
                </Stack>

                {disabled && (
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                logToAnalytics('voiceover_upgradeBtn_clicked', {
                                    source: 'editor',
                                    currentPlan: planName
                                })
                                dispatch(showPlanDialog('upgradePlanDialog'))
                            }}
                        >
                            <Premium /> <Box ml={1}>UPGRADE</Box>
                        </Button>
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    )
}
