import type Konva from 'konva'

import { getCenter } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel/FloatingActions'

const threshold = 5 // threshold for alignment detection

type RectangleParams = {
    width: number
    height: number
    centerX: number
    centerY: number
    angle: number // Angle in degrees
}

const calculateRectangleCorners = (params: RectangleParams) => {
    const { width, height, centerX, centerY, angle } = params
    const angleInRadians = (angle * Math.PI) / 180

    const halfWidth = width / 2
    const halfHeight = height / 2

    // Calculate the coordinates of the four corners of the rectangle
    const corners: Array<{ x: number; y: number }> = [
        // Top left corner
        {
            x:
                centerX -
                halfWidth * Math.cos(angleInRadians) +
                halfHeight * Math.sin(angleInRadians),
            y:
                centerY -
                halfWidth * Math.sin(angleInRadians) -
                halfHeight * Math.cos(angleInRadians)
        },
        // Top right corner
        {
            x:
                centerX +
                halfWidth * Math.cos(angleInRadians) +
                halfHeight * Math.sin(angleInRadians),
            y:
                centerY +
                halfWidth * Math.sin(angleInRadians) -
                halfHeight * Math.cos(angleInRadians)
        },
        // Bottom right corner
        {
            x:
                centerX +
                halfWidth * Math.cos(angleInRadians) -
                halfHeight * Math.sin(angleInRadians),
            y:
                centerY +
                halfWidth * Math.sin(angleInRadians) +
                halfHeight * Math.cos(angleInRadians)
        },
        // Bottom left corner
        {
            x:
                centerX -
                halfWidth * Math.cos(angleInRadians) -
                halfHeight * Math.sin(angleInRadians),
            y:
                centerY -
                halfWidth * Math.sin(angleInRadians) +
                halfHeight * Math.cos(angleInRadians)
        }
    ]

    return corners
}

type GetThresholdCoordinatesType = {
    targetLayer: Konva.Shape
    canvasCenter: { x: number; y: number }
    width: number
    height: number
}

const getElementThresholdCoordinates = ({
    targetLayer,
    canvasCenter,
    width,
    height
}: GetThresholdCoordinatesType) => {
    if (targetLayer.attrs.radius) return { x: canvasCenter.x, y: canvasCenter.y }

    const corners = calculateRectangleCorners({
        width,
        height,
        centerX: canvasCenter.x,
        centerY: canvasCenter.y,
        angle: targetLayer.attrs.rotation || 0
    })

    const minX = Math.min(...corners.map(corner => corner.x))
    const topLeftCornerY = corners[0].y

    return { x: minX, y: topLeftCornerY }
}

export const calculateAlignment = (
    targetLayer: Konva.Shape,
    canvasCenter: { x: number; y: number }
) => {
    const radius = targetLayer.attrs.radius || 0
    const isCircle = Boolean(radius)

    const { width: layerWidthRect = 0, height: layerHeightRect = 0 } =
        targetLayer.getClientRect?.({
            skipTransform: true,
            skipStroke: true
        }) || {}

    const layerWidth = isCircle ? radius * 2 : layerWidthRect
    const layerHeight = isCircle ? radius * 2 : layerHeightRect

    const { x: layerX, y: layerY } = targetLayer.getPosition()

    const scaledLayerWidth = layerWidth * targetLayer.scaleX()
    const scaledLayerHeight = layerHeight * targetLayer.scaleY()

    const layerAdjustment = radius ? (targetLayer.height() * targetLayer.scaleY()) / 2 : 0

    const layerCenter = getCenter({
        rotation: targetLayer.attrs.rotation,
        x: layerX - layerAdjustment,
        y: layerY - layerAdjustment,
        width: scaledLayerWidth,
        height: scaledLayerHeight
    })

    const isAlignedToCenterX = Math.abs(layerCenter.x - canvasCenter.x) < threshold
    const isAlignedToCenterY = Math.abs(layerCenter.y - canvasCenter.y) < threshold

    const thresholdCoordinates = getElementThresholdCoordinates({
        targetLayer,
        canvasCenter,
        width: scaledLayerWidth,
        height: scaledLayerHeight
    })

    return {
        isAlignedToCenterX,
        isAlignedToCenterY,
        alignPosition: {
            x: isAlignedToCenterX ? thresholdCoordinates.x : layerX,
            y: isAlignedToCenterY ? thresholdCoordinates.y : layerY
        }
    }
}

export const isAligned = (
    targetLayer: Konva.Shape | Konva.Stage,
    relativeLayer: Konva.Shape,
    type: 'xStart' | 'xEnd' | 'yStart' | 'yEnd'
): boolean => {
    const targetScale = targetLayer.scale() || { x: 1, y: 1 }
    const targetPos = targetLayer.getPosition()
    const targetRect = targetLayer.getClientRect({
        skipTransform: true,
        skipStroke: true
    })

    const layerScale = relativeLayer.scale() || { x: 1, y: 1 }
    const layerPos = relativeLayer.getPosition()
    const layerRect = relativeLayer.getClientRect({
        skipTransform: true,
        skipStroke: true
    })

    const axis = type.includes('x') ? 'x' : 'y'
    const side = type.includes('x') ? 'width' : 'height'

    // For circle-like shapes, we need to adjust the position from the center of the shape
    const targetAdjustment = targetLayer.attrs.radius ? (targetRect.height * targetScale.y) / 2 : 0
    const layerAdjustment = relativeLayer.attrs.radius ? (layerRect.height * layerScale.y) / 2 : 0

    if (type.includes('Start')) {
        const targetStart = targetPos[axis] - targetAdjustment
        const layerStart = layerPos[axis] - layerAdjustment

        return Math.abs(layerStart - targetStart) < threshold
    }

    if (type.includes('End')) {
        const targetEnd = targetPos[axis] + targetRect[side] * targetScale[axis] - targetAdjustment
        const layerEnd = layerPos[axis] + layerRect[side] * layerScale[axis] - layerAdjustment

        return Math.abs(layerEnd - targetEnd) < threshold
    }

    return false
}
