import { useRef } from 'react'
import { useSelector } from 'react-redux'

import {
    Box,
    CircularProgress,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from '@mui/material'

import { Icon, Tooltip, IconButton } from '@guidde/design-system'
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical'
import { faClosedCaptioning } from '@fortawesome/pro-regular-svg-icons/faClosedCaptioning'

import { useBoolean } from 'hooks'
import { fetchBlobData, logToAnalytics } from 'modules'
import { type SubtitlesType } from 'app/types'

type Payload = {
    playbookId: string
    playbookName: string
    format: 'srt' | 'vtt'
    langCode: string
    steps: Array<{
        duration: number
        transitionDuration?: number
        subtitles: SubtitlesType // Array<{ text: string, start: number, end: number }>
    }>
}

type Props = {
    variant: 'captions' | 'menu'
    playbookId: string
    langCode: string
    playbookName: string
}

export const DownloadCaptions = ({ variant, playbookId, playbookName, langCode }: Props) => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)

    const open = useBoolean()
    const loading = useBoolean()

    const { steps } = useSelector(state => state.qgEditor.present)

    const downloadCaptions = (format: 'vtt' | 'srt') => async () => {
        loading.setTrue()

        logToAnalytics('download_captions', {
            playbookId,
            format
        })

        const payload: Payload = {
            playbookId,
            playbookName,
            format,
            langCode,
            steps: steps.flatMap(step => ({
                duration: step.duration,
                transitionDuration: step.transition?.duration,
                subtitles: step.subtitles || []
            }))
        }

        const objectURL = await fetchBlobData(`/c/v1/subtitles/export`, payload)

        // create a tag with attributes
        let a = document.createElement('a')
        a.href = objectURL
        a.setAttribute('download', `${playbookName}.${format}`)
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        // clean up
        document.body.removeChild(a)
        URL.revokeObjectURL(a.href)
        loading.setFalse()
    }

    return (
        <>
            <Box ref={anchorRef}>
                <Tooltip title="Download captions" placement="bottom">
                    <IconButton
                        label="captions"
                        variant="text"
                        onClick={open.toggle}
                        color="secondary"
                        size="small"
                    >
                        {loading.isTrue ? (
                            <CircularProgress size={24} />
                        ) : (
                            <>
                                {variant === 'captions' ? (
                                    <Icon icon={faClosedCaptioning} />
                                ) : (
                                    <Icon icon={faEllipsisVertical} />
                                )}
                            </>
                        )}
                    </IconButton>
                </Tooltip>
            </Box>

            <Popper open={open.isTrue} anchorEl={anchorRef?.current} placement="top">
                <ClickAwayListener onClickAway={open.setFalse}>
                    <Paper>
                        <MenuList sx={{ padding: 0 }}>
                            <MenuItem disabled={loading.isTrue} onClick={downloadCaptions('vtt')}>
                                Download .vtt
                            </MenuItem>
                            <MenuItem disabled={loading.isTrue} onClick={downloadCaptions('srt')}>
                                Download .srt
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
