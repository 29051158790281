import * as types from 'ducks/types'
import { type ActionType } from 'ducks/common'

import { type PlaybookApplicationType, type QuickGuiddeType } from 'app/types'

export type SidePanelModeType = 'voiceOver' | 'motion' | 'subtitles' | 'captions'

type StateType = {
    sidePanelMode: SidePanelModeType
    qgDescription: string
    qgApplications: Array<PlaybookApplicationType>
    qgDeletedApps: Array<PlaybookApplicationType>
    qgNewTags: Array<string>
    qgDeletedTags: Array<string>
    audio: QuickGuiddeType['audioBackground']
}

const initialState: StateType = {
    sidePanelMode: 'voiceOver',
    qgDescription: '',
    qgApplications: [],
    qgDeletedApps: [],
    qgNewTags: [],
    qgDeletedTags: [],
    audio: null
}

export const qgMetadataReducer = (state = initialState, action: ActionType): StateType => {
    switch (action.type) {
        case types.SET_SIDE_PANEL_MODE: {
            return {
                ...state,
                sidePanelMode: action.payload
            }
        }

        case types.SET_QUICK_GUIDDE_METADATA: {
            return {
                ...state,
                ...action.payload
            }
        }

        case types.SET_QUICK_GUIDDE_DESCRIPTION: {
            return {
                ...state,
                qgDescription: action.payload
            }
        }

        case types.SET_QUICK_GUIDDE_APPLICATIONS: {
            const { qgApplications, qgDeletedApps } = action.payload
            return {
                ...state,
                qgApplications,
                qgDeletedApps
            }
        }

        case types.SET_QUICK_GUIDDE_TAGS: {
            const { qgNewTags, qgDeletedTags } = action.payload
            return {
                ...state,
                qgNewTags,
                qgDeletedTags
            }
        }
        case types.SET_QUICK_GUIDDE_AUDIO: {
            return {
                ...state,
                audio: action.payload
            }
        }
        case types.SET_QUICK_GUIDDE_AUDIO_VOLUME: {
            if (!state.audio) return state

            return {
                ...state,
                audio: {
                    ...state.audio,
                    volume: action.payload
                }
            }
        }

        default: {
            return state
        }
    }
}
