import { memo, useEffect, useMemo, useState } from 'react'

import {
    Box,
    Dialog,
    DialogContent,
    Divider,
    DialogActions,
    IconButton,
    Typography,
    Button,
    LinearProgress,
    Grid
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { AudioItem } from './AudioItem'

import { type AudioBackgroundType } from 'app/types'

import { logToAnalytics } from 'modules'

const buttonStyles = {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25
}

type Props = {
    isOpen: boolean
    onClose: () => void
    onCloseButtonClick?: () => void
    onApply?: (audio: AudioBackgroundType | null) => void
    onPlaySample?: () => void
    disabledIds?: Array<string>
    initialActiveId?: string
    isLoading?: boolean
    disableIfEmpty?: boolean
    options?: Array<AudioBackgroundType>
}

export const AudioBackgroundDialog = memo(
    ({
        isOpen,
        initialActiveId = '',
        disabledIds = [],
        disableIfEmpty,
        options = [],
        isLoading,
        onApply,
        onClose,
        onPlaySample,
        onCloseButtonClick
    }: Props) => {
        const { defaultAudio } = useMemo(() => {
            const myAudio: Array<AudioBackgroundType> = []
            const defaultAudio: Array<AudioBackgroundType> = []

            options.forEach(item => {
                const target = item.isEditable ? myAudio : defaultAudio
                target.push(item)
            })

            return {
                myAudio,
                defaultAudio
            }
        }, [options])

        const [activeId, setActiveId] = useState<string>(initialActiveId)
        const [playingId, setPlayingId] = useState<string>('')

        useEffect(() => {
            setActiveId(initialActiveId)
        }, [initialActiveId])

        // on close reset to initial values
        useEffect(() => {
            if (isOpen) return

            setActiveId(initialActiveId)
        }, [initialActiveId, isOpen])

        const disabled = disableIfEmpty ? !activeId : false

        const onAudioClick = (id: string) => {
            if (id === activeId) {
                setActiveId('')
            } else {
                setActiveId(id)
            }
        }

        const handleApply = () => {
            if (!activeId) {
                onApply?.(null)
                return
            }

            const newAudio = options.find(it => it.id === activeId)

            if (newAudio) {
                logToAnalytics('selectedMusicFile', {
                    musicFileName: newAudio.name
                })
                onApply?.(newAudio)
            } else {
                onClose()
                console.error('set audio error', { newAudio, activeId, options })
            }
        }

        return (
            <>
                <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm" scroll="paper">
                    <Box height={4}>{isLoading && <LinearProgress />}</Box>
                    <DialogContent style={{ padding: 30, paddingBottom: 20 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h5" style={{ fontWeight: 500 }}>
                                Choose background music
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    onClose()
                                    onCloseButtonClick?.()
                                }}
                                size="small"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box mt={2.5} mb={1.2} flexGrow={1}>
                            {defaultAudio?.length > 0 && (
                                <Grid container spacing={1}>
                                    {defaultAudio.map(audio => (
                                        <Grid xs={6} item key={audio.id}>
                                            <AudioItem
                                                {...audio}
                                                maxHeight={105}
                                                playingId={playingId}
                                                setPlayingId={setPlayingId}
                                                disabled={disabledIds.includes(audio.id)}
                                                size={80}
                                                onClick={() => onAudioClick(audio.id)}
                                                isActive={activeId === audio.id}
                                                onPlaySample={onPlaySample}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions
                        disableSpacing
                        style={{ padding: '10px 30px', justifyContent: 'flex' }}
                    >
                        <Button
                            disabled={disabled || isLoading}
                            key={String(disabled)}
                            onClick={handleApply}
                            variant="contained"
                            style={{
                                background: disabled ? 'rgba(203, 0, 0, 0.40)' : 'auto',
                                boxShadow: disabled ? 'none' : 'auto',
                                color: '#FFF',
                                ...buttonStyles
                            }}
                        >
                            APPLY
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
)
