import { memo, useEffect, useMemo, type MouseEvent, useState } from 'react'

import {
    type PopoverOrigin,
    Popover,
    Box,
    ClickAwayListener,
    List,
    ListItem,
    Paper
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { type OptionType, option } from 'modules'

import { enUScode, useSpeakerOptions, useStepSpeakingRate } from 'UI/Routes/quick-guidde/hooks'
import { SpacedGroup } from 'UI/Components'

type Props = {
    language: OptionType | null
    onDropdownOpen?: () => void
    onSelect: (_option: OptionType) => void
    showOther?: boolean
    anchorOriginHorizontal?: PopoverOrigin['horizontal']
    transformOriginHorizontal?: PopoverOrigin['horizontal']
}

export const languageOptionOther = option('other', 'Other')

export const LanguageDropdown = memo(
    ({
        language,
        onDropdownOpen,
        onSelect,
        showOther,
        anchorOriginHorizontal,
        transformOriginHorizontal
    }: Props) => {
        const { resetSpeakingRage } = useStepSpeakingRate()

        const {
            languagesList,
            defaultLanguage,
            speakers,
            speakerId,
            setLastSpeaker,
            getLastSelectedLanguage
        } = useSpeakerOptions()

        const languages: Array<OptionType> = useMemo(() => {
            if (!languagesList) return []

            if (showOther) {
                return [...languagesList, languageOptionOther]
            }

            return languagesList
        }, [showOther, languagesList])

        // Last selected option
        const lastSelectedOption = useMemo(() => {
            return getLastSelectedLanguage(showOther)
        }, [getLastSelectedLanguage, showOther])

        useEffect(() => {
            // If we already have value - no need to do anything
            if (language) return
            // If we have previous option saved - select it
            if (lastSelectedOption) return onSelect(lastSelectedOption)
            if (!languages.length) return
            // If org has defaultLanguage, and it's supported - select it as a default lang option
            const defaultOption = languages.find(option => option.value === defaultLanguage)
            if (defaultOption) return onSelect(defaultOption)
            // if nothing worked, select first available language
            const fallbackLang = languages.find(option => option.value === enUScode)
            if (fallbackLang) onSelect(fallbackLang)
        }, [defaultLanguage, lastSelectedOption, onSelect, languages, speakerId, language])

        if (!languages?.length) return null

        return (
            <LanguageDropdownPopover
                language={language}
                languages={languages}
                onDropdownOpen={onDropdownOpen}
                anchorOriginHorizontal={anchorOriginHorizontal}
                transformOriginHorizontal={transformOriginHorizontal}
                onSelectLanguage={selectedLanguage => {
                    onSelect(selectedLanguage)

                    const speaker = speakers[selectedLanguage.value]?.options?.find(
                        speaker => speaker.langCode === selectedLanguage.value
                    )

                    const newSpeakerId = speaker?.id || selectedLanguage.value
                    setLastSpeaker(newSpeakerId)
                    // reset speakingRate only if user selects new language
                    if (lastSelectedOption?.value !== selectedLanguage.value) {
                        resetSpeakingRage()
                    }
                }}
            />
        )
    }
)

type LanguageDropdownPopoverProps = {
    language: OptionType | null
    disabled?: boolean
    languages: Array<OptionType>
    onOpen?: (e: MouseEvent<HTMLElement>, callback: () => void) => void
    onSelectLanguage: (language: OptionType) => void
    onDropdownOpen?: () => void
    anchorOriginHorizontal?: PopoverOrigin['horizontal']
    transformOriginHorizontal?: PopoverOrigin['horizontal']
}
export const LanguageDropdownPopover = ({
    language,
    disabled,
    languages,
    onOpen,
    onSelectLanguage,
    onDropdownOpen,
    anchorOriginHorizontal = 'right',
    transformOriginHorizontal = 'left'
}: LanguageDropdownPopoverProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleOpen = (e: MouseEvent<HTMLElement>) => {
        onDropdownOpen?.()
        setAnchorEl(e.currentTarget)
    }
    return (
        <>
            <SpacedGroup
                data-test="editor-speaker-language"
                data-intercom="editor-speaker-language"
                flexDirection="column"
                spacing={0}
                onClick={event => {
                    if (disabled) return

                    if (onOpen) onOpen(event, () => handleOpen(event))
                    else handleOpen(event)
                }}
                style={{ cursor: disabled ? 'auto' : 'pointer', display: 'inline-block' }}
            >
                <SpacedGroup>
                    <Box style={{ fontSize: '14px', fontWeight: 500 }}>Language</Box>
                    <ArrowDropDownIcon style={{ color: '#090C10' }} />
                </SpacedGroup>
                <Box>{language?.label}</Box>
            </SpacedGroup>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disablePortal
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: anchorOriginHorizontal
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: transformOriginHorizontal
                }}
            >
                <Paper elevation={0}>
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <List style={{ cursor: 'pointer', padding: '0', maxHeight: 500 }}>
                            {languages.map(option => (
                                <ListItem
                                    key={option.value}
                                    button={true}
                                    onClick={() => {
                                        onSelectLanguage(option)
                                        setAnchorEl(null)
                                    }}
                                >
                                    {option.label}
                                </ListItem>
                            ))}
                        </List>
                    </ClickAwayListener>
                </Paper>
            </Popover>
        </>
    )
}
