import { useEffect } from 'react'

type Props = {
    title: string
}

export const DocumentTitle = ({ title }: Props) => {
    useEffect(() => {
        document.title = `${title} | Guidde`
        return () => {
            document.title = 'Guidde'
        }
    }, [title])

    return null
}
