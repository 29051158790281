import { useEffect } from 'react'

import { type AnyPlaybookType, type PlaybookType } from 'app/types'
import { isPlaylist, logToAnalytics, playbookToAnalyticsProps } from 'modules'

import { useRoles } from './use-roles'
import { useAuth } from './use-auth'
import { useAnonymAuth } from './use-anonym-auth'

type Props = {
    playbook: AnyPlaybookType
    playbookList?: Array<PlaybookType>
}

export const useVideoLoadAnalytics = ({ playbook, playbookList }: Props) => {
    const { isSuperAdmin } = useRoles()
    const { uid } = useAuth()

    const isOwner = uid === playbook.creator_uid

    const { isAnonymous } = useAnonymAuth()

    const shouldSend = !isSuperAdmin && !isOwner

    useEffect(() => {
        if (shouldSend) {
            const videoLoadPlaybooks = playbookList || [playbook]

            videoLoadPlaybooks.map(videoLoadPlaybook =>
                logToAnalytics('videoLoad', {
                    ...playbookToAnalyticsProps(
                        {
                            ...videoLoadPlaybook,
                            playlist: isPlaylist(playbook) ? playbook : undefined
                        },
                        isAnonymous
                    )
                })
            )
        }
        // Should be sent only once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSend])
}
