import { useCallback, useMemo, useState, Fragment } from 'react'

import { ConfirmationDialog } from 'UI/Components'

import { type AnyPlaybookType, type PlaybookType, type PlaybookVisibilityType } from 'app/types'

import { useDataMutation } from 'hooks'
import { isQG, logToAnalytics } from 'modules'

import { useBoolean } from './use-boolean'

export type PbsInPlsRejectionType = {
    mapPlaylistsPlaybooks: Record<string, string[]>
    playbooks: Record<string, PlaybookType>
    playlists: Record<string, PlaybookType>
}

export type RejectedType = Array<{
    incompatibleRes?: PbsInPlsRejectionType
    rejectedRes?: PbsInPlsRejectionType
}>

export type ShareResponseErrorType = {
    rejectedPlaylists?: RejectedType
    rejectedVideos?: RejectedType
}

export type ShareResponseType = ShareResponseErrorType & {
    code: number
    // new data includes mode field but for now we need only this `visibility`
    newData: Array<{ visibility: PlaybookVisibilityType }>
    message: string
    spaceId?: string
}

export type ShareProps = {
    playbooksIds: Array<string>
    spacesIds?: Array<string>
    userIds?: Array<string>
    emails?: Array<string>
    notes?: string
    spaceNames?: Array<string>
    isForceConfirmed?: boolean
    visibilityLevel?: 'restricted' | 'anyoneInOrg' | 'anyone'
    notificationsReceivers?: 'anyone' | 'members' | 'membersAndSpaces'
    shareMode?: 'share' | 'unshare' | 'overwriteByOption'
}

type LogShareByEmailProps = {
    eventName: 'share_playbook' | 'publish_playbook'
    sharedByUid: string
    sentTo: Array<string>
    playbookIds: Array<string>
}
export const logShareByEmail = ({
    eventName,
    sharedByUid,
    sentTo,
    playbookIds
}: LogShareByEmailProps) => {
    logToAnalytics(eventName, {
        sharedByUid,
        sentTo,
        playbookIds
    })
}

export const logVisibilityChanging = ({
    playbook,
    visibilityLevel
}: {
    visibilityLevel: string
    playbook: AnyPlaybookType
}) => {
    logToAnalytics('publish_playbook', {
        isQuickGuidde: isQG(playbook),
        isPlaylist: playbook.mode === 'playlist',
        visibilityLevel
    })
}

type Props = {
    onApprovalClose?: () => void
    onSuccess?: (data: ShareResponseType, input: ShareProps) => void
    onFailure?: (error: Error, input: ShareProps) => void
}
export const usePlaybookShare = ({ onApprovalClose, onSuccess, onFailure }: Props = {}) => {
    const $share = useDataMutation<ShareProps, ShareResponseType, Error>(
        '/c/v1/playbook-share',
        'POST',
        { onFailure, onSuccess }
    )
    const showConfirmation = useBoolean()
    const [shareProps, setShareProps] = useState<ShareProps | null>(null)

    const sharePlaybook = useCallback(
        (props: ShareProps, approved?: boolean) => {
            setShareProps(null)

            return $share
                .mutate({ ...props, ...(approved && { isForceConfirmed: true }) })
                .then((data: ShareResponseType | undefined) => {
                    // share visibility

                    if (data?.code === 422) {
                        setShareProps(props)
                        showConfirmation.setTrue()
                        return data
                    }

                    return data
                })
        },
        [$share, showConfirmation]
    )

    const ApprovalModal = useCallback(() => {
        const data =
            $share?.data && ('rejectedPlaylists' in $share.data || 'rejectedVideos' in $share.data)
                ? $share.data
                : null

        if (showConfirmation.isFalse || !data) return null

        return (
            <ConfirmationDialog
                isOpen
                title="Are you sure?"
                onClose={() => {
                    showConfirmation.setFalse()
                    onApprovalClose?.()
                }}
                onConfirm={() => {
                    showConfirmation.setFalse()

                    if (!shareProps) return

                    sharePlaybook(shareProps, true)
                }}
                text={
                    <>
                        {data.rejectedVideos?.map(({ incompatibleRes }) => {
                            if (!incompatibleRes) return

                            const { mapPlaylistsPlaybooks, playlists, playbooks } = incompatibleRes
                            const videoId = Object.values(mapPlaylistsPlaybooks)[0][0]
                            const playlistIds = Object.keys(mapPlaylistsPlaybooks)

                            return (
                                <Fragment key={videoId}>
                                    This visibility change will also remove{' '}
                                    <b>{playbooks[videoId].title}</b> from{' '}
                                    {playlistIds.length === 1 ? 'playlist' : 'playlist'}{' '}
                                    <b>
                                        {playlistIds
                                            .map(playlistId => playlists[playlistId].title)
                                            .join(', ')}
                                    </b>
                                    <br />
                                    Are you sure you want to proceed?
                                </Fragment>
                            )
                        })}
                        {data.rejectedPlaylists?.map(({ rejectedRes }) => {
                            if (!rejectedRes) return

                            const { mapPlaylistsPlaybooks, playbooks, playlists } = rejectedRes

                            return Object.entries(mapPlaylistsPlaybooks).map(
                                ([playlistId, videoIds]) => (
                                    <Fragment key={playlistId}>
                                        This visibility change will remove from{' '}
                                        <b>{playlists[playlistId].title}</b> videos{' '}
                                        <b>
                                            {videoIds
                                                .map(videoId => playbooks[videoId].title)
                                                .join(', ')}
                                        </b>
                                        <br />
                                        Are you sure you want to proceed?
                                    </Fragment>
                                )
                            )
                        })}
                    </>
                }
            />
        )
    }, [$share.data, onApprovalClose, sharePlaybook, shareProps, showConfirmation])

    return useMemo(
        () => ({
            sharePlaybook,
            ApprovalModal: ApprovalModal(),
            isSharing: $share.isLoading
        }),
        [$share.isLoading, ApprovalModal, sharePlaybook]
    )
}
