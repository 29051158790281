import { type ReactNode, useRef, useState } from 'react'

import {
    Box,
    ClickAwayListener,
    LinearProgress,
    Paper,
    Popper,
    Tooltip,
    Typography
} from '@mui/material'

import ImageIcon from '@mui/icons-material/CropOriginal'
import ReplayIcon from '@mui/icons-material/Replay'
import EditIcon from '@mui/icons-material/EditOutlined'

import { ReactComponent as UploadImage } from 'assets/icons/upload-image.svg'

import { FileUploader, playbookColors } from 'UI/Components'

import { type PlaylistType } from 'app/types'

import { useAuth, useBoolean, useBrandKit, useNotification } from 'hooks'
import { type UpdatePlaylistCover, isColorLight, updatePlaylistCover } from 'modules'

type Props = {
    playlist: PlaylistType
}

export const PlaylistCover = ({ playlist }: Props) => {
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const { uid } = useAuth()

    const uploading = useBoolean()
    const open = useBoolean()

    const { brandKitColors } = useBrandKit()

    const anchorRef = useRef(null)

    const [activeCover, setActiveCover] = useState({
        coverColor: playlist.coverColor,
        screenshotUrl: playlist.screenshotUrl,
        previousScreenshotUrl: playlist.previousScreenshotUrl || playlist.screenshotUrl
    })

    const update = (data: Omit<UpdatePlaylistCover, 'id'>, message = 'Playlist cover updated') => {
        setActiveCover(prev => ({
            ...prev,
            ...data
        }))

        updatePlaylistCover({
            ...data,
            id: playlist.id
        })
            .then(() => showSuccessNotification(message))
            .catch(() => showErrorNotification('Something went wrong'))
    }

    const isEmpty = !activeCover.coverColor && !activeCover.screenshotUrl

    const checkIfActive = (color: string) => {
        return activeCover.coverColor === color && !activeCover.screenshotUrl
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                ref={anchorRef}
                height={160}
                border="1px solid rgba(9, 12, 16, 0.08)"
                borderRadius={4}
                overflow="hidden"
                position="relative"
                style={{ cursor: isEmpty ? 'pointer' : 'auto' }}
                onClick={isEmpty ? open.toggle : () => {}}
            >
                {uploading.isTrue && (
                    <Box position="absolute" top={0} left={0} right={0}>
                        <LinearProgress />
                    </Box>
                )}
                {!isEmpty && (
                    <Tooltip title="Edit playlist cover">
                        <Box
                            position="absolute"
                            top={8}
                            left={8}
                            style={{ cursor: 'pointer' }}
                            bgcolor="rgba(9, 12, 16, 0.40)"
                            width={25}
                            height={25}
                            onClick={open.toggle}
                            borderRadius={4}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <EditIcon style={{ color: '#FFF' }} fontSize="small" />
                        </Box>
                    </Tooltip>
                )}
                {isEmpty ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <UploadImage style={{ color: '#cb0000' }} />
                        <Box mt={1.2} />
                        <Typography variant="caption" color="textSecondary">
                            Add Cover
                        </Typography>
                    </Box>
                ) : (
                    <Box width="100%" height="100%" bgcolor={activeCover.coverColor}>
                        {activeCover.screenshotUrl && (
                            <img
                                src={activeCover.screenshotUrl}
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <Popper
                open={open.isTrue}
                anchorEl={anchorRef?.current}
                placement="bottom-start"
                style={{ marginTop: 10 }}
            >
                <ClickAwayListener onClickAway={open.setFalse}>
                    <Paper
                        style={{
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            border: '1px solid rgba(9, 12, 16, 0.08)',
                            padding: 4
                        }}
                    >
                        <Box display="flex">
                            {brandKitColors.map((color, idx) => (
                                <ColorItem
                                    roundedBorderLeft={idx === 0}
                                    roundedBorderRight={idx === brandKitColors?.length - 1}
                                    key={color + idx}
                                    idx={idx}
                                    color={color}
                                    isActive={checkIfActive(color)}
                                    onClick={() => update({ coverColor: color, screenshotUrl: '' })}
                                />
                            ))}
                            <Box mr={0.5} />
                            {playbookColors
                                .filter(it => !brandKitColors.includes(it))
                                .map((color, idx) => (
                                    <ColorItem
                                        roundedBorderLeft={idx === 0}
                                        key={color + idx}
                                        idx={idx}
                                        color={color}
                                        isActive={checkIfActive(color)}
                                        onClick={() =>
                                            update({ coverColor: color, screenshotUrl: '' })
                                        }
                                    />
                                ))}

                            <FileUploader
                                accept="image/*"
                                logoUrl=""
                                iconColor="#828282"
                                labelComponent={
                                    <ItemContainer>
                                        <ImageIcon fontSize="small" />
                                    </ItemContainer>
                                }
                                saveHeight={720}
                                saveWidth={1280}
                                hideAttachFile={true}
                                hideProgress={true}
                                hidePreview={true}
                                disabled={uploading.isTrue}
                                filename={playlist.id}
                                previewWidth={150}
                                previewHeight={87}
                                storagePath={`playbookCover/${uid}/`}
                                onDone={screenshotUrl => {
                                    const previousScreenshotUrl =
                                        screenshotUrl && activeCover.previousScreenshotUrl
                                            ? activeCover.previousScreenshotUrl
                                            : screenshotUrl
                                    update({
                                        screenshotUrl,
                                        previousScreenshotUrl
                                    })
                                }}
                                onProgressChange={process => uploading.set(process !== 100)}
                            />

                            {activeCover.previousScreenshotUrl && (
                                <Tooltip title="Back to default cover">
                                    <Box>
                                        <ItemContainer
                                            onClick={() => {
                                                update(
                                                    {
                                                        coverColor: '',
                                                        screenshotUrl:
                                                            activeCover.previousScreenshotUrl
                                                    },
                                                    'Default cover selected'
                                                )
                                            }}
                                            roundedBorderRight={true}
                                            noBorderLeft={true}
                                        >
                                            <ReplayIcon fontSize="small" />
                                        </ItemContainer>
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

type ItemContainerProps = {
    children: ReactNode
    onClick?: () => void
    isActive?: boolean
    noBorderLeft?: boolean
    roundedBorderLeft?: boolean
    roundedBorderRight?: boolean
}

const ItemContainer = ({
    onClick,
    isActive,
    noBorderLeft,
    children,
    roundedBorderLeft,
    roundedBorderRight
}: ItemContainerProps) => (
    <Box
        onClick={() => onClick?.()}
        width={32}
        height={32}
        p={0.5}
        borderRadius={roundedBorderLeft ? '4px 0 0 4px' : roundedBorderRight ? '0 4px 4px 0' : 0}
        border={`1px solid rgba(${isActive ? '203, 0, 0, 1' : '9, 12, 16, 0.08'})`}
        borderLeft={
            noBorderLeft
                ? undefined
                : `1px solid rgba(${isActive ? '203, 0, 0, 1' : '9, 12, 16, 0.08'})`
        }
        style={{
            cursor: isActive ? 'auto' : 'pointer'
        }}
        bgcolor={!isActive ? 'transparent' : 'rgba(203, 0, 0, 0.1)'}
    >
        {children}
    </Box>
)

type ColorItemProps = {
    idx: number
    onClick: () => void
    color: string
    isActive: boolean
    roundedBorderLeft?: boolean
    roundedBorderRight?: boolean
}

const ColorItem = ({
    isActive,
    idx,
    color,
    roundedBorderLeft,
    roundedBorderRight,
    onClick
}: ColorItemProps) => (
    <ItemContainer
        noBorderLeft={idx === 0}
        isActive={isActive}
        onClick={onClick}
        roundedBorderLeft={roundedBorderLeft}
        roundedBorderRight={roundedBorderRight}
    >
        <Box
            data-test="editor-background-selection"
            width="100%"
            height="100%"
            style={{
                background: color
            }}
            border={
                !isActive
                    ? isColorLight(color)
                        ? '1px solid grey'
                        : ''
                    : `2px solid rgba(203, 0, 0, 1)`
            }
            borderRadius="50%"
        />
    </ItemContainer>
)
