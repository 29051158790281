import { useContext, useEffect, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { VideoStatusContext } from 'UI/Components'

const schema = {
    '20': {
        color: '#629BF2'
    },
    '40': {
        color: '#8A5EE8'
    },
    '60': {
        color: '#D435D4'
    },
    '80': {
        color: '#EA36B5'
    },
    '100': {
        color: '#EC5C73'
    }
} as const

const transition = 'left 1s linear'
const background =
    'linear-gradient(270deg, #ED725D -3.18%, #EB507D 10.44%, #EA38A7 19.82%, #EA34C0 34.64%, #E332CE 46.79%, #B439DF 58.78%, #8066EA 77.1%, #629AF1 95.88%)'

type Props = {
    isDone: boolean
    stepsCount: number
}

export const WizardProgressBanner = ({ isDone, stepsCount }: Props) => {
    const { revalidatePlaybook } = useContext(VideoStatusContext)

    const history = useHistory()

    const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null)
    const [progress, setProgress] = useState(0)

    const { color } = useMemo(() => {
        // calculate the closest value/key of the schema

        // Example:
        // progress - 9, schema key - 20
        // progress - 22, schema key - 40
        // progress - 90, schema key - 100
        const key = Object.keys(schema).reduce(
            (prev, curr) => (+prev >= +progress ? prev : curr),
            Object.keys(schema)[0]
        ) as keyof typeof schema

        return schema[key]
    }, [progress])

    // progress increase
    useEffect(() => {
        if (isDone) return

        // `0.5` - it's a approximately time that BE need for generate one step
        // EXAMPLE: 10 steps * `0.5` = 5s - time for generating 10 steps
        const stepPercent = (100 / stepsCount) * 0.5

        const id = setInterval(() => {
            setProgress(prev => {
                const newProgress = Math.min(prev + stepPercent, 100)
                if (newProgress === 100) clearInterval(id)

                return newProgress
            })
        }, 1000)

        setIntervalId(id)

        return () => {
            clearInterval(id)
        }
    }, [stepsCount, isDone])

    // closing banner when percentage is 100% AND status is done
    useEffect(() => {
        if (!isDone) return

        setProgress(100)

        const timeoutId = setTimeout(() => {
            revalidatePlaybook()
            clearTimeout(timeoutId)

            history.push({
                pathname: history.location.pathname,
                state: { skipProgress: true }
            })

            if (intervalId) clearInterval(intervalId)
        }, 1000)
    }, [progress, intervalId, isDone, revalidatePlaybook, history])

    return (
        <Box borderRadius="4px" px={4} pt={2} pb={5}>
            <Box position="relative">
                {/* progress COLORED background */}
                <Box
                    width="100%"
                    overflow="hidden"
                    mt={1}
                    height={8}
                    position="relative"
                    borderRadius="45px"
                    zIndex={2}
                    style={{ background }}
                >
                    {/* step dots */}
                    <Box
                        px="3px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        height={8}
                    >
                        {Array.from({ length: stepsCount + 1 }, (_, i) => i).map(dot => (
                            <Box
                                key={dot}
                                width="4px"
                                visibility={dot === 0 ? 'hidden' : 'visible'}
                                height="4px"
                                borderRadius="50%"
                                bgcolor="#FFF"
                                zIndex={2}
                                position="relative"
                            />
                        ))}
                    </Box>
                    {/* progress NOT COLORED background */}
                    <Box
                        position="absolute"
                        top={0}
                        right={0}
                        left={`${progress}%`}
                        bgcolor="rgba(58, 61, 64, 1)"
                        height={8}
                        style={{ overflow: 'hidden', transition }}
                    />
                </Box>
                {/* progress vertical line */}
                <Box
                    style={{
                        transition,
                        position: 'absolute',
                        left: `calc(${Math.min(progress, 99)}% - 1px)`,
                        top: 0,
                        width: 1,
                        height: 22,
                        background: color
                    }}
                />
            </Box>
            {/* progress percentage text */}
            <Box width={'100%'} position="relative">
                <Typography
                    variant="caption"
                    style={{
                        position: 'absolute',
                        left: `${Math.min(progress, 67)}%`,
                        transition,
                        top: 10,
                        color: color
                    }}
                >
                    {Math.round(progress)}% complete
                </Typography>
            </Box>
        </Box>
    )
}
