import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Circle, Group, Text } from 'react-konva'

import type Konva from 'konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { type CircleWithTextShapeType } from 'app/types'
import { setLayerTransform, setStepNumberingText } from 'ducks'
import { useBoolean } from 'hooks'

type Props = {
    scale: number
    layer: CircleWithTextShapeType
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const StepNumbering = ({
    scale,
    layer,
    onDragStart,
    onDragMove,
    onDragEnd,
    onSelect
}: Props) => {
    const dispatch = useDispatch()

    const {
        id,
        x,
        y,
        text: { title, color, fontSize, x: textX, y: textY, width, align },
        circle: { radius, x: circleX, y: circleY, fill, strokeColor, strokeWidth },
        rotation,
        scaleX,
        scaleY
    } = layer

    const shapeRef = useRef<Konva.Group>(null)

    const editMode = useBoolean()

    return (
        <Group
            ref={shapeRef}
            name="stepNumbering"
            id={id}
            x={x}
            y={y}
            scaleX={scaleX}
            scaleY={scaleY}
            rotation={rotation}
            draggable={true}
            onDragStart={onDragStart}
            onDragMove={onDragMove}
            onDragEnd={onDragEnd}
            onClick={e => onSelect(e.evt.shiftKey)}
            onTransformEnd={({ target }) => {
                dispatch(
                    setLayerTransform({
                        layerId: layer.id,
                        x: target.x(),
                        y: target.y(),
                        scaleX: target.scaleX(),
                        scaleY: target.scaleY(),
                        rotation: target.rotation()
                    })
                )
            }}
            onDblClick={event => {
                editMode.setTrue()
                // find position of stage container on the page:
                const stageBox = event.target.getStage()?.attrs.container.getBoundingClientRect()

                const textPosition = shapeRef.current!.getAbsolutePosition()

                // so position of input will be the sum of positions above:
                const areaPosition = {
                    x: stageBox.left + textPosition.x,
                    y: stageBox.top + textPosition.y + window.scrollY
                }

                // create input and style it
                const input = document.createElement('input')
                document.body.appendChild(input)
                const timeout = setTimeout(() => {
                    input.focus()
                    clearTimeout(timeout)
                }, 0)

                input.onkeypress = e => {
                    if (!/^[0-9]$/.test(e.key)) {
                        e.preventDefault()
                    }
                }

                input.value = title
                input.maxLength = 2

                input.style.position = 'absolute'
                input.style.top = areaPosition.y + 'px'
                input.style.left = areaPosition.x + 'px'

                input.style.width = width * scale * scaleX + 'px'
                input.style.height = radius * 2 * scale * scaleX + 'px'

                input.style.margin = '0'
                input.style.padding = '0'

                input.style.transformOrigin = 'left top'
                input.style.color = color
                input.style.background = 'transparent'

                input.style.fontSize = fontSize * scale * scaleX + 'px'
                // input.style.fontWeight = fontStyle
                input.style.textAlign = 'center'

                input.style.lineHeight = '1'
                input.style.lineBreak = 'word'

                input.style.overflow = 'hidden'
                input.style.outline = 'none'
                input.style.border = '2px solid transparent'

                input.style.transform = rotation ? 'rotateZ(' + rotation + 'deg)' : ''

                const abortController = new AbortController()

                const updateText = () => {
                    dispatch(setStepNumberingText({ title: input.value.trim(), layerId: id }))

                    abortController.abort()
                    document.body.removeChild(input)
                    editMode.setFalse()
                }

                document.body.addEventListener(
                    'mousedown',
                    e => {
                        if (e.target !== input) {
                            updateText()
                        }
                    },
                    { signal: abortController.signal }
                )
            }}
        >
            <Circle
                x={circleX}
                y={circleY}
                radius={radius}
                fill={fill}
                strokeWidth={strokeWidth}
                stroke={strokeColor}
            />

            <Text
                visible={editMode.isFalse}
                x={textX}
                y={textY}
                text={title}
                fontSize={fontSize}
                fontFamily="Roboto"
                // fontStyle={fontStyle}
                fill={color}
                lineHeight={1}
                align={align}
                width={width}
            />
        </Group>
    )
}
