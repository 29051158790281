import { type ReactNode, memo, useState } from 'react'

import dayjs from 'dayjs'

import { type GridCellParams, type GridSortItem, DataGrid } from '@mui/x-data-grid'

import {
    Avatar,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    ListItemText,
    Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import NoDataImg from 'assets/icons/no-data-cloud.svg'

import { SpacedGroup } from 'UI/Components'
import { EventIcon } from 'UI/Routes/activity'

import { AnalyticsFilter } from './AnalyticsFilter'
import { PlaybookMetadata } from './PlaybookMetadata'

import { useNotification, useQuery } from 'hooks'

import { type AnyPlaybookType, PlaybookMode } from 'app/types'

type VideoMetaData = {
    percentage: number
    segmentsWatched: number
    totalSegments: number
}

type AnalyticsType = {
    id: string
    playbookId: string
    time: number
    name: string
    photoUrl: string
    orgName: string
    spaces: Array<string>
    watchDuration: number
    url: string
    event: string
    eventLabel: string
    isTrackedLink: boolean
    playbookTitle?: string
    videoMetaData?: VideoMetaData
}

const renderVideoPlayStats = (rowData: AnalyticsType): string => {
    const { videoMetaData, watchDuration } = rowData

    if (rowData.event === 'docView') {
        return `${videoMetaData?.segmentsWatched} steps (${videoMetaData?.percentage}%)`
    }

    if (videoMetaData && videoMetaData?.percentage && watchDuration) {
        return `${secondToString(watchDuration)} (${videoMetaData.percentage}%)`
    }
    return ''
}

const secondToString = (seconds: number): string => {
    if (seconds >= 60) return `${Math.round(seconds / 60)}min`
    return `${seconds}sec`
}

const playbookTitlePercentage = 17

// be careful summary all items should have
const generateWidth = (percentage: number, isPlaylist?: boolean) => {
    // 1230 - table width in px
    // 6 - columns count without playbook title
    const getPixels = (num: number) => (1230 * num) / 100
    const additional = getPixels(isPlaylist ? playbookTitlePercentage / 6 : 0)

    return getPixels(percentage) + additional
}

const getPlayListData = (event: string, title: string, playbookTitle: string) => {
    if (event === 'open_playlist') return title
    if (event === 'videoPlay') return playbookTitle
    return ''
}

type Props = {
    isOpen: boolean
    onClose: () => void
    playbook: AnyPlaybookType
}

export const ViewAnalyticsDialog = memo(({ isOpen, onClose, playbook }: Props) => {
    const { showErrorNotification } = useNotification()

    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState<7 | 30>(30)

    const [sortModel, setSortModel] = useState<Array<GridSortItem>>([
        {
            field: 'time',
            sort: 'desc'
        }
    ])

    const { isValidating, data = [] } = useQuery<Array<AnalyticsType>>(
        isOpen ? '/b/v1/playbookviewsanalytics' : '',
        {
            method: 'POST',
            body: {
                days: filter,
                playbookId: playbook.id
            }
        },
        {
            onError: () => {
                onClose()
                showErrorNotification(
                    'Something went wrong while getting video analytics. Please try again later'
                )
            }
        }
    )

    const isPlaylist = playbook.mode === PlaybookMode.Playlist

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
            <IconButton
                sx={{
                    position: 'absolute',
                    right: 16,
                    top: 16,
                    padding: 0.5,
                    margin: 0.5,
                    zIndex: 15
                }}
                onClick={onClose}
                data-test="close-button"
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle
                align="center"
                sx={{
                    fontWeight: 600
                }}
                data-test="title"
            >
                Recent Activity
            </DialogTitle>

            <DialogContent>
                <PlaybookMetadata playbook={playbook} />

                <Divider />
                <Box mt={2} />

                <AnalyticsFilter filter={filter} onFilterChange={setFilter} />

                {!data?.length && !isValidating ? (
                    <SpacedGroup my={2} justifyContent="center" width="100%">
                        <img src={NoDataImg} width="40px" height="40px" />
                        <Typography>No data</Typography>
                    </SpacedGroup>
                ) : (
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
                                outline: 'none!important',
                                backgroundColor: 'white'
                            }
                        }}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 25,
                                    page: page
                                }
                            }
                        }}
                        pageSizeOptions={[25]}
                        onPaginationModelChange={({ page }) => setPage(page + 1)}
                        columns={[
                            {
                                field: 'event',
                                headerName: 'Action',
                                width: generateWidth(10, isPlaylist),
                                renderCell: ({ row }: GridCellParams) => (
                                    <ListItem>
                                        <SpacedGroup spacing={1}>
                                            <EventIcon type={row.event} />
                                            <Typography variant="body2">
                                                {row.eventLabel}
                                            </Typography>
                                        </SpacedGroup>
                                    </ListItem>
                                )
                            },
                            {
                                field: isPlaylist ? 'title' : 'playbookTitle',
                                headerName: isPlaylist ? 'Title' : 'Playbook Title',
                                width: generateWidth(
                                    isPlaylist ? playbookTitlePercentage : 15,
                                    isPlaylist
                                ),
                                renderCell: ({ row }: GridCellParams) => {
                                    if (!isPlaylist) {
                                        return (
                                            <ListItem
                                                title={row.playbookTitle}
                                                body={row.playbookTitle}
                                            />
                                        )
                                    }

                                    return (
                                        <ListItem>
                                            <SpacedGroup spacing={1}>
                                                <Typography variant="body2">
                                                    {getPlayListData(
                                                        row.event,
                                                        row.title,
                                                        row.playbookTitle
                                                    )}
                                                </Typography>
                                            </SpacedGroup>
                                        </ListItem>
                                    )
                                }
                            },
                            {
                                field: 'time',
                                headerName: 'Time',
                                width: generateWidth(10, isPlaylist),
                                renderCell: ({ row }: GridCellParams) => (
                                    <ListItem
                                        body={dayjs(row.time * 1000).format('ddd DD MMM, hh:mm A')}
                                    />
                                )
                            },
                            {
                                field: 'name',
                                headerName: 'Name',
                                width: generateWidth(15, isPlaylist),
                                renderCell: ({ row }: GridCellParams) => (
                                    <ListItem>
                                        <SpacedGroup spacing={0.5}>
                                            <Avatar
                                                src={row.photoUrl}
                                                style={{
                                                    width: '30px',
                                                    height: '30px'
                                                }}
                                            />

                                            <Box>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    {row.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {row.orgName}
                                                </Typography>
                                            </Box>
                                        </SpacedGroup>
                                    </ListItem>
                                )
                            },
                            {
                                field: 'spaces',
                                headerName: 'Spaces',
                                width: generateWidth(15, isPlaylist),
                                renderCell: ({ row }: GridCellParams) => (
                                    <ListItem
                                        title={row.spaces.join(', ')}
                                        body={row.spaces.join(', ')}
                                    />
                                )
                            },
                            {
                                field: 'url',
                                headerName: 'URL',
                                width: generateWidth(18, isPlaylist),
                                renderCell: ({ row }: GridCellParams) => (
                                    <ListItem
                                        title={row.url}
                                        body={
                                            <Link href={row.url} color="inherit" target="_blank">
                                                {row.url}
                                            </Link>
                                        }
                                    />
                                )
                            },
                            {
                                field: 'watchDuration',
                                headerName: 'Duration',
                                width: generateWidth(9, isPlaylist),
                                align: 'center',
                                renderCell: ({ row }: GridCellParams) => (
                                    <ListItem body={renderVideoPlayStats(row)} />
                                )
                            }
                        ]}
                        rows={data}
                        loading={isValidating}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        disableColumnSelector
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
})

type ListItemProps = {
    title?: string
    body?: ReactNode | string
    children?: ReactNode
}

const ListItem = ({ title, body, children }: ListItemProps) => (
    <ListItemText
        title={title}
        sx={{
            '& p, a': {
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }}
    >
        <Typography variant="body2">{body}</Typography>
        {children && <Box>{children}</Box>}
    </ListItemText>
)
