import { Box, CircularProgress } from '@mui/material'

import { CircularProgressWithLabel } from 'UI/Components'

/**
 * Shows onboarding progress bar. Could be easily places wherever you like, using positioning props
 * @param valuesColorsMap Set how color should change according to current value { value1: 'color1', value2: 'color2' }
 * @param value Current value
 * @param defaultColor Fallback color if color map isn't provided or it has an error
 * @param size
 * @example <ColoredCircularProgress size={75} value={55} valuesColorsMap={{0: 'green', 50: 'yellow', 75: 'orange', 100: 'red'}} />
 */
export const ColoredCircularProgress = ({
    size,
    value,
    valuesColorsMap,
    defaultColor = '#cb0000',
    textInsteadValue
}) => {
    if (isNaN(value)) return null

    const activeColor = valuesColorsMap
        ? Object.entries(valuesColorsMap)
              .filter(([val]) => val <= value)
              .sort((a, b) => a[0] - b[0])
              .slice(-1)[0]?.[1] || Object.values(valuesColorsMap)[0]
        : defaultColor

    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                style={{ position: 'absolute', color: '#e0e0e0' }}
                size={size}
                value={100}
            />
            <CircularProgressWithLabel
                renderWhenValueZero={true}
                textInsteadValue={textInsteadValue}
                size={size}
                value={value}
                style={{ color: activeColor }}
                textColor={activeColor}
            />
        </Box>
    )
}
