import { memo } from 'react'

import { type BoxProps, Typography, Box, styled } from '@mui/material'

import ArrowIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { useWindowView } from 'hooks'

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    height: '35px',
    border: '1px solid rgba(9, 12, 16, 0.08)',
    padding: theme.spacing(1),
    borderRadius: '100px',
    [theme.breakpoints.down('lg')]: {
        paddingLeft: theme.spacing(1.5)
    }
}))

type Props = {
    onClick?: () => void
    selectedCount: number
    defaultLabel: string
    label: string
    width: BoxProps['width']
    labelWidth?: BoxProps['width']
    // optional
    labelIcon?: null | JSX.Element
    transparent?: boolean
    renderBorder?: boolean
    onFocus?: () => void
    multipleItemsLabel?: string
}

export const FilterLabel = memo(
    ({
        transparent = false,
        onFocus = () => {},
        onClick = () => {},
        selectedCount,
        label,
        width,
        defaultLabel = '',
        multipleItemsLabel = ''
    }: Props) => {
        const { isMobileView } = useWindowView()

        return (
            <Container
                tabIndex={0}
                width={width}
                onFocus={onFocus}
                onClick={onClick}
                data-test={`filter-${multipleItemsLabel}`}
                style={{
                    background: transparent ? 'none' : '#f5f5f5'
                }}
            >
                <Typography
                    style={{
                        color: 'rgba(9, 12, 16, 0.6)',
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        width: '100%',
                        marginLeft: 10
                    }}
                    variant="caption"
                >
                    {selectedCount === 0 && defaultLabel}
                    {selectedCount === 1 && label}
                    {selectedCount > 1 && `${multipleItemsLabel}: ${selectedCount}`}
                </Typography>
                {isMobileView ? (
                    <ArrowIcon fontSize="small" />
                ) : (
                    <ArrowDropUpIcon
                        fontSize="small"
                        style={{ transform: 'rotate(180deg)', color: 'rgba(9, 12, 16, 0.6)' }}
                    />
                )}
            </Container>
        )
    }
)
