import { useSelector } from 'react-redux'

import { doc } from 'firebase/firestore'

import { useAuth } from 'hooks'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from 'modules'

export const useExtCard = () => {
    const { uid } = useAuth()

    const [userAchievements, loading, error] = useDocumentData(doc(db, `userAchievements/${uid}`))

    const isExtensionInstalled = useSelector(state => state.extensionStatus.isInstalled)

    const { createdFirstQuickGuidde: firstQuickGuiddeIsCreated, firstExtInstall } =
        userAchievements || {}

    // extension was never installed
    const showWhenIsNotInstalled = !isExtensionInstalled && !firstExtInstall
    // extension installed but user never recorded QG
    const showWithQG = isExtensionInstalled && !firstQuickGuiddeIsCreated

    // const showExtCard = status === 'success' && (showWhenIsNotInstalled || showWithQG)
    const showExtCard = !loading && !error && (showWhenIsNotInstalled || showWithQG)

    return showExtCard
}
