import { type ReactNode, memo } from 'react'

import {
    type DialogProps,
    Dialog,
    IconButton,
    LinearProgress,
    Typography,
    Box
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { SpacedGroup } from 'UI/Components'

import { type IconType } from 'app/menu'

export type GeneralDialogProps = {
    maxWidth?: DialogProps['maxWidth']
    isOpen: boolean
    onClose: () => void
    isLoading?: boolean
    headerActions?: ReactNode
    children: ReactNode
    titleIcon?: IconType
    title: string
    subtitle?: string
    actions: ReactNode
}

export const GeneralDialog = memo(
    ({
        isLoading,
        isOpen,
        onClose,
        maxWidth = 'sm',
        actions,
        titleIcon,
        subtitle,
        headerActions,
        title,
        children
    }: GeneralDialogProps) => (
        <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullWidth>
            <Box minHeight="5px">{isLoading && <LinearProgress />}</Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={2}
                py={1}
                mb={1}
            >
                <SpacedGroup>
                    <>
                        {titleIcon}

                        <Box>
                            <Typography style={{ fontSize: 20, fontWeight: 500 }}>
                                {title}
                            </Typography>

                            <Typography variant="caption" color="textSecondary">
                                {subtitle}
                            </Typography>
                        </Box>
                    </>
                </SpacedGroup>
                <SpacedGroup>
                    {headerActions || (
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </SpacedGroup>
            </Box>

            {children}
            <Box p={2} width="100%">
                {actions}
            </Box>
        </Dialog>
    )
)
