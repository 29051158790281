import { type ReactNode } from 'react'

import { Box } from '@mui/material'

type Props = {
    children: ReactNode
}

export const VideoOverlayContainer = ({ children }: Props) => (
    <Box
        zIndex={5}
        style={{
            width: '100%',
            // 10px - player padding in the bottom
            height: 'calc(100% - 10px)',
            position: 'absolute',
            borderRadius: 8,
            overflow: 'hidden',
            top: 0
        }}
    >
        {children}
    </Box>
)
