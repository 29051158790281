type Props = {
    description: string
}

export const Description = ({ description }: Props) => {
    if (!description) return null

    return (
        <div style={{ marginBottom: '16px' }}>
            <p style={{ fontSize: '12px', color: '#828282' }}>{description}</p>
            <hr />
        </div>
    )
}
