import { styled, Tooltip } from '@mui/material'

const Container = styled('span')({
    textDecoration: 'none',
    backgroundImage: 'linear-gradient(#cd0000, #cd0000)',
    backgroundPosition: '0% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '0% 2px',
    transition: 'background-size .3s',
    cursor: 'pointer',
    '&:hover': {
        backgroundSize: 'calc(100% - 2px) 2px'
    }
})

type Props = {
    children: string
    onClick: (e: Event, children: string) => void
}

export const DictionaryPotentialItem = ({ children, onClick }: Props) => (
    <Tooltip
        title="Add to dictionary"
        enterDelay={1000}
        enterNextDelay={1000}
        placement="top"
        arrow
        style={{
            top: '10px'
        }}
    >
        <Container onClick={(e: any) => onClick(e, children)}>{children}</Container>
    </Tooltip>
)
