import { useRouteMatch } from 'react-router-dom'
import dayjs from 'dayjs'

import { styled, Box, Typography } from '@mui/material'
import Icon from '@mui/icons-material/ErrorOutline'

import { SpacedGroup } from 'UI/Components'

import clsx from 'clsx'
import { paths } from 'app/paths'

const PREFIX = 'RoleDowngradeBanner'

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    text: `${PREFIX}-text`,
    additional: `${PREFIX}-additional`
}

const StyledBox = styled(Box)(({ theme: { breakpoints } }) => ({
    [`&.${classes.wrapper}`]: {
        borderRadius: '10px',
        color: '#f57c00',
        background: 'rgba(245,124,0, 0.06)',
        padding: '10px 15px'
    },

    [`& .${classes.text}`]: {
        [breakpoints.down('md')]: {
            fontSize: '13px'
        }
    },

    [`&.${classes.additional}`]: {
        marginBottom: '20px'
    }
}))

const specialPaths = [
    paths.editPlaylist,
    paths.editPlaybook,
    paths.playlistDetails,
    paths.playbookDetails,
    paths.spaceLibrary,
    paths.editQuickGuidde,
    paths.spaceDetails
]

type Props = {
    date?: number
}

export const RoleDowngradeBanner = ({ date }: Props) => {
    // check if a user opened the page
    // in which should add an additional margin
    const addBottomMargin = useRouteMatch(specialPaths)?.isExact

    if (!date) return null

    return (
        <StyledBox
            mx={3}
            mt={2}
            className={clsx(classes.wrapper, {
                [classes.additional]: addBottomMargin
            })}
        >
            <SpacedGroup spacing={1.5}>
                <Icon />

                <Typography className={classes.text}>
                    You are set to become a Viewer on <b>{dayjs(date).format('MMM Do')}</b>. Your
                    plan has currently exceeded its limit. Contact your admin for more details.
                </Typography>
            </SpacedGroup>
        </StyledBox>
    )
}
