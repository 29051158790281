import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, CircularProgress, Stack } from '@mui/material'

import { type StepType, Shape } from 'app/types'
import { useBrandKit, useDataMutation } from 'hooks'
import { overwriteStep } from 'ducks'

const protectedTemplates = ['guiddeCover', 'guiddeEnding']

type PayloadType = {
    kind: 'generate'
    width: number
    height: number
    title: string
    playbookId: string
    step: StepType
}

export const Templates = () => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const [selectedLoader, setSelectedLoader] = useState(-1)

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const {
        kind,
        windowDimensions: { innerWidth, innerHeight },
        layers,
        audioNote
    } = steps[activeStep] || {}

    const audioLayer = layers.find(layer => layer.type === Shape.AudioCircle)
    const profileLayer = layers.find(layer => layer.type === Shape.ImageCircle)

    const { brandKitData, brandKitBackground } = useBrandKit()
    const templates = brandKitData?.[kind] ?? null

    // If it's default org cover or ending template - we show background gradient color instead of template.drawnScreenshot
    const gradient =
        brandKitBackground && 'gradient' in brandKitBackground ? brandKitBackground.gradient : ''

    const $generateTemplate = useDataMutation<PayloadType, StepType, Error>(
        '/c/v1/quickguidde/generateTemplate',
        'POST',
        {
            onSuccess: () => setSelectedLoader(-1),
            onFailure: err => {
                console.error(err)
                setSelectedLoader(-1)
            }
        }
    )

    if (!templates) return null

    return (
        <Stack direction="row" flexWrap="wrap" gap={0.5}>
            {templates.data.map((template, idx) => {
                const isActive = template.id === steps[activeStep].id

                return (
                    <Box
                        key={idx}
                        p={0.5}
                        width={80}
                        height={45}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{ cursor: 'pointer' }}
                        borderRadius="4px"
                        border={`1px solid rgba(${isActive ? '203, 0, 0, 1' : '9, 12, 16, 0.08'})`}
                        onClick={() => {
                            // don't send another request while processing
                            if (selectedLoader !== -1) return
                            if (isActive) return

                            setSelectedLoader(idx)
                            $generateTemplate
                                .mutate({
                                    step: template,
                                    playbookId,
                                    width: innerWidth,
                                    height: innerHeight,
                                    title: steps[activeStep].title,
                                    kind: 'generate'
                                })
                                .then(template => {
                                    if (!template) return

                                    const newLayers = [
                                        ...template.layers,
                                        ...(audioLayer ? [audioLayer] : []),
                                        ...(profileLayer ? [profileLayer] : [])
                                    ]

                                    dispatch(
                                        overwriteStep({
                                            newStep: {
                                                ...template,
                                                layers: newLayers,
                                                audioNote
                                            }
                                        })
                                    )
                                })
                        }}
                    >
                        <Box width="100%" height="100%" position="relative" display="flex">
                            {protectedTemplates.includes(template.id) && gradient ? (
                                <Box width="100%" height="100%" style={{ background: gradient }} />
                            ) : (
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    height="40px"
                                    src={template?.previewScreenshot || template.drawnScreenshot}
                                    alt="preview"
                                />
                            )}

                            {selectedLoader === idx && (
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    style={{
                                        transform: 'translate(-50%,-50%)'
                                    }}
                                >
                                    <CircularProgress size={25} />
                                </Box>
                            )}
                        </Box>
                    </Box>
                )
            })}
        </Stack>
    )
}
