import { memo, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@mui/material'

import type Konva from 'konva'

import { TransitionSlide } from './TransitionSlide'
import { CanvasSlide } from './CanvasSlide'

type Props = {
    activeIndex: number
    status: 'stopped' | 'running'
}

export const Slides = memo(({ activeIndex, status }: Props) => {
    const {
        present: { steps }
    } = useSelector(state => state.qgEditor)

    const stageRefs = useRef<Array<Konva.Stage | null>>(Array(steps.length).fill(null))

    const slidesForTransition = useMemo(() => {
        return {
            previous: stageRefs.current[activeIndex - 1]?.toCanvas({
                pixelRatio: window.devicePixelRatio
            }),
            current: stageRefs.current[activeIndex]?.toCanvas({
                pixelRatio: window.devicePixelRatio
            })
        }
        // Trick to force +1 re-render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex, status])

    const closestSlides = useMemo(() => {
        return steps.map((_, index) => {
            // Render only the active slide and the slides before and after it
            const isValid = Math.abs(index - activeIndex) <= 1
            if (!isValid) return null

            return (
                <CanvasSlide
                    key={index}
                    stageRefs={stageRefs}
                    stepIndex={index}
                    isActive={activeIndex === index && status === 'running'}
                />
            )
        })
    }, [activeIndex, status, steps])

    return (
        <Box>
            <Box width="100%" position="absolute" display="flex" justifyContent="center" zIndex={1}>
                {/* webGl transition which is shown only when the transition is in progress */}
                <TransitionSlide
                    showTransition={status === 'running'}
                    currentSlide={slidesForTransition.current}
                    previousSlide={slidesForTransition.previous}
                    transition={steps[activeIndex].transition}
                />
            </Box>

            {/* Hidden instances of react-konva to fix delay when active step changes */}
            {closestSlides}
        </Box>
    )
})
