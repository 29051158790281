import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Stack, Typography } from '@mui/material'

import MusicOffIcon from '@mui/icons-material/MusicOff'

import { type AudioBackgroundType } from 'app/types'

import { type WizardData, wizardTopAudioIdsStorageKey } from '../../wizard-utils'
import { WizardDialogContent } from '../WizardDialogContent'
import { WizardAudioBackgroundItem } from './WizardAudioBackgroundItem'
import { logToAnalytics } from 'modules'

type Props = {
    audioBackgrounds: Array<AudioBackgroundType>
    topAudioBackgroundIds: Array<string>
    activeAudioId: string | null
    onChangeData: (wizardData: Partial<WizardData>) => void
    onPrimaryActionClick: () => void
}

export const WizardAudioBackgroundStep = ({
    onChangeData,
    audioBackgrounds,
    activeAudioId,
    topAudioBackgroundIds,
    onPrimaryActionClick
}: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    const [playingId, setPlayingId] = useState<string>()

    return (
        <WizardDialogContent
            title="Choose background music"
            activeStep={3}
            onChangeStep={step => onChangeData({ currentStep: step })}
            primaryActionText="Let’s go"
            onPrimaryActionClick={() => {
                localStorage.setItem(
                    wizardTopAudioIdsStorageKey,
                    JSON.stringify([
                        ...(!activeAudioId || topAudioBackgroundIds.includes(activeAudioId)
                            ? []
                            : [activeAudioId]),
                        ...topAudioBackgroundIds.slice(0, 4)
                    ])
                )

                onPrimaryActionClick()
            }}
            onBackButtonClick={() => logToAnalytics('wizard_musicBackBtn_clicked', { playbookId })}
        >
            <Box
                width="100%"
                sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}
            >
                <AudioBackgroundEmptyOption
                    isSelected={activeAudioId === null}
                    onClick={() => onChangeData({ audioId: null })}
                />
                {audioBackgrounds.map(audio => (
                    <WizardAudioBackgroundItem
                        key={audio.id}
                        isSelected={activeAudioId === audio.id}
                        setPlayingId={setPlayingId}
                        playingId={playingId}
                        audio={audio}
                        onClick={() => onChangeData({ audioId: audio.id })}
                    />
                ))}
            </Box>
        </WizardDialogContent>
    )
}

const AudioBackgroundEmptyOption = ({
    onClick,
    isSelected
}: {
    onClick: () => void
    isSelected: boolean
}) => {
    return (
        <Stack
            direction="row"
            spacing={1}
            p={1}
            sx={{
                cursor: 'pointer',
                borderRadius: 0.5,
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                ...(isSelected && {
                    outline: '4px solid #FED243',
                    backgroundColor: 'common.white',
                    '&:hover': {
                        backgroundColor: 'common.white'
                    }
                })
            }}
            onClick={onClick}
            alignItems="center"
            height={78}
        >
            <Stack
                width={60}
                height={60}
                sx={{
                    borderRadius: '50%',
                    backgroundColor: '#F5F5F5',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <MusicOffIcon />
            </Stack>
            <Stack>
                <Typography
                    fontSize={14}
                    letterSpacing={0.25}
                    fontWeight={700}
                    color={isSelected ? 'default' : 'rgba(9, 12, 16, 0.6)'}
                >
                    None
                </Typography>
                <Typography
                    fontSize={12}
                    lineHeight="14px"
                    letterSpacing={0.25}
                    fontStyle="italic"
                    color="rgba(9, 12, 16, 0.4)"
                >
                    No music
                </Typography>
            </Stack>
        </Stack>
    )
}
