import { Box, IconButton, Link } from '@mui/material'

import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg'
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg'

import { links } from 'app/links'

export const FooterLinks = () => (
    <Box display="flex" alignItems="center" mr={2}>
        <Link href={links.facebook} target="_blank" rel="noreferrer">
            <IconButton size="small">
                <FacebookIcon color="inherit" />
            </IconButton>
        </Link>

        <Link href={links.twitter} target="_blank" rel="noreferrer">
            <IconButton size="small">
                <TwitterIcon color="inherit" />
            </IconButton>
        </Link>

        <Link href={links.linkedin} target="_blank" rel="noreferrer">
            <IconButton size="small">
                <LinkedinIcon color="inherit" />
            </IconButton>
        </Link>
    </Box>
)
