import { useState, memo, useRef } from 'react'

import { Box, Switch, Typography, Divider } from '@mui/material'

import { BasicChapters } from '@guidde-co/shared.chapters.basic-chapters'
import { SearchTranscript } from '@guidde-co/shared.transcript.search-transcript'

import {
    type Subtitle,
    type PlaybookTranscriptType,
    TabPanel,
    FixedVisibleHeight,
    SpacedGroup
} from 'UI/Components'

import { useAnonymAuth, useBoolean } from 'hooks'

import { type PlaybookType, type PlaylistType, type VideoRefType } from 'app/types'

import { PlaylistTranscript } from './PlaylistTranscript'
import { PlaybookList } from './PlaybookList'
import { ControlPanel } from './ControlPanel'
import { StartUsingMessage } from './start-using-message'

export type PlaylistSidePanelProps = {
    playbook: PlaybookType
    playlist: PlaylistType
    videoRef: VideoRefType
    playbookList: Array<PlaybookType>
    isMobileView?: boolean
    showStartUsing?: boolean
    onPlaybookClick: (playbookId: string) => void
    activePlaybookId: string
    handleSegmentClick: (item: Subtitle) => void
    transcript: PlaybookTranscriptType
}

export const PlaylistSidePanel = memo(
    ({
        transcript,
        playbook,
        playlist,
        isMobileView,
        activePlaybookId,
        onPlaybookClick,
        handleSegmentClick,
        videoRef,
        playbookList,
        showStartUsing
    }: PlaylistSidePanelProps) => {
        const [tabValue, setTabValue] = useState(0)
        const [searchString, setSearchString] = useState('')

        const chaptersAreEmpty = !playbook?.slicingSuggestion
        const disableSwitch = !transcript?.segments?.length

        const ref = useRef<HTMLDivElement>(null)

        const showTranscript = useBoolean(disableSwitch || chaptersAreEmpty)

        useAnonymAuth()

        if (!playbook) return null

        const heightFromTop =
            window.pageYOffset + (ref.current ? ref.current.getBoundingClientRect().top : 0)

        return (
            <Box bgcolor="#EDEEEF" style={{ height: '100vh' }}>
                <ControlPanel
                    showStartUsing={showStartUsing}
                    position={isMobileView && tabValue === 1 ? 'fixed' : 'relative'}
                    setTabValue={value => {
                        if (showStartUsing) return
                        setTabValue(value)
                    }}
                    tabValue={tabValue}
                    transparent={showStartUsing}
                    playbookList={playbookList}
                    activePlaybookId={activePlaybookId}
                    playlist={playlist}
                />
                <Box ref={ref} />

                <Box
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    sx={{
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '20px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#D8D8D8',
                            borderRadius: '20px',
                            border: '6px solid transparent',
                            backgroundClip: 'content-box'
                        }
                    }}
                    height={showStartUsing ? 'calc(100vh - 440px)' : 'calc(100vh - 100px)'}
                    overflow="auto"
                >
                    <TabPanel
                        value={tabValue}
                        index={0}
                        px={2}
                        py={0}
                        style={{ padding: isMobileView ? 2 : 0, margin: 0, width: '100%' }}
                    >
                        <PlaybookList
                            playbookList={playbookList}
                            onPlaybookClick={onPlaybookClick}
                            activePlaybookId={activePlaybookId}
                        />
                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                        <>
                            <SpacedGroup>
                                <SearchTranscript
                                    onChange={setSearchString}
                                    data-test="search-transcript"
                                />
                                {!chaptersAreEmpty && (
                                    <Box>
                                        <Typography variant="body2">Transcript</Typography>
                                        {playbook?.transcriptUrl && (
                                            <Switch
                                                checked={showTranscript.isTrue}
                                                size="small"
                                                disabled={disableSwitch}
                                                onChange={showTranscript.toggle}
                                                color="primary"
                                                data-test="transcript-switch"
                                            />
                                        )}
                                    </Box>
                                )}
                            </SpacedGroup>
                            <Box m={1} />
                            <Divider />
                            <Box mt={1} />
                            {showTranscript.isFalse && !chaptersAreEmpty ? (
                                <FixedVisibleHeight>
                                    <BasicChapters
                                        chapters={playbook?.slicingSuggestion || []}
                                        searchString={searchString}
                                        videoElement={videoRef?.current as HTMLVideoElement}
                                    />
                                </FixedVisibleHeight>
                            ) : (
                                <PlaylistTranscript
                                    playbook={playbook}
                                    transcript={transcript}
                                    searchString={searchString}
                                    handleSegmentClick={handleSegmentClick}
                                    videoRef={videoRef}
                                    heightFromTop={heightFromTop}
                                />
                            )}
                        </>
                    </TabPanel>
                </Box>

                <Box mt={2} />
                {showStartUsing && <StartUsingMessage playbook={playbook} />}
            </Box>
        )
    }
)
