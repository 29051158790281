import { generatePath, useHistory } from 'react-router-dom'
import { mutate } from 'swr'

import { type SubmitDataType, CommonSpaceDialog } from './common'

import { useAuth, useNotification, useDataMutation } from 'hooks'
import { generalErrorMessage, SPACE_ROLES } from 'modules'

import { paths } from 'app/paths'

type Props = {
    open: boolean
    onClose: () => void
    onSuccess?: () => void
}

export const CreateSpaceDialog = ({ open, onClose, onSuccess }: Props) => {
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const history = useHistory()

    const { uid, displayName, email, photoURL } = useAuth()

    const $createSpace = useDataMutation<SubmitDataType, { spaceId: string }, Error>(
        '/c/v1/space/create',
        'POST',
        {
            onSuccess: ({ spaceId }) => {
                showSuccessNotification('Space was successfully created')
                onClose()
                // be careful with this key, if one day we change url on `PageAllSpaces` component
                // this key should be updated as well
                mutate(['/c/v1/spaces'])
                onSuccess?.()
                history.push(
                    generatePath(paths.spaceLibrary, {
                        spaceId
                    })
                )
            },
            onFailure: () => {
                showErrorNotification(generalErrorMessage)
                onClose()
            }
        }
    )

    return (
        <CommonSpaceDialog
            isOpen={open}
            onClose={onClose}
            title="New space"
            ownerOption={{
                displayName,
                isSelected: true,
                email,
                uid,
                spaceRole: SPACE_ROLES.OWNER,
                logo: photoURL
            }}
            onSubmit={$createSpace.mutate}
            isLoading={$createSpace.isLoading}
        />
    )
}
