import { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Form, Formik } from 'formik'

import * as yup from 'yup'

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    Divider,
    Typography,
    LinearProgress,
    FormControlLabel,
    IconButton
} from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import {
    ConnectedAutocomplete,
    ConnectedFilledInput,
    ConnectedRadioGroup,
    FileUploader,
    IOSSwitch,
    SpacedGroup
} from 'UI/Components'

import { useAuth, useBoolean, useDataMutation, useNotification, useOrganization } from 'hooks'
import { logToAnalytics, option, playbookToAnalyticsProps, uuid } from 'modules'
import { type CallToActionType, type PlaybookType } from 'app/types'

const textOptions = [
    option('Learn more', 'Learn more'),
    option('Open in App', 'Open in App'),
    option('Book a Demo', 'Book a Demo'),
    option('Sign up', 'Sign up'),
    option('Schedule a meeting', 'Schedule a meeting')
]

const positionOptions = [
    option('bottomLeft', 'Lower left'),
    option('bottomRight', 'Lower Right'),
    option('topRight', 'Upper Right'),
    option('topLeft', 'Upper Left')
]

const defaultInitialValues = {
    start: null,
    end: null,
    text: textOptions[0],
    link: '',
    pip: false,
    image: '',
    imageOption: 'default',
    timeOption: 'entireVideo',
    enabled: true,
    position: 'bottomRight'
} as const

const itemTitleStyle = {
    fontWeight: 600,
    fontSize: 16
}

type Props = {
    isOpen: boolean
    onClose: () => void
    callback?: (data: { cta: PlaybookType['cta'] }) => void
    playbook: PlaybookType
    lastItemLabel?: string
}

const generateStyles = (isActive?: boolean) => ({
    border: `1px solid rgba${isActive ? '(203, 0, 0, 1)' : '(9, 12, 16, 0.08)'}`,
    borderRadius: 4,
    background: isActive ? '#FAE5E5' : 'transparent',
    cursor: isActive ? 'auto' : 'pointer',
    transition: 'background-color 0.2s ease',
    width: 90,
    height: 55,
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

export const CtaDialog = memo(
    ({ isOpen, onClose, callback, playbook, lastItemLabel = 'Last chapter' }: Props) => {
        const initialValues = playbook.cta

        const params = useParams<{ playbookId: string }>()
        const { sections, duration } = useSelector(state => state.videoEditor)

        const { orgId, uid } = useAuth()
        const { logo } = useOrganization()

        const imageUploading = useBoolean()
        const { showSuccessNotification, showErrorNotification } = useNotification()

        const $updateCta = useDataMutation<unknown, unknown, Error>(
            '/c/v1/playbook/update',
            'PATCH',
            {
                onSuccess: () => showSuccessNotification('CTA successfully updated'),
                onFailure: error => {
                    console.error(error)
                    showErrorNotification(error.message)
                }
            }
        )

        const root = initialValues || defaultInitialValues

        return (
            <Dialog
                maxWidth="sm"
                open={isOpen}
                style={{ width: '100%' }}
                onClose={onClose}
                fullWidth
            >
                <Box height="4px">{$updateCta.isLoading && <LinearProgress />}</Box>
                <Box pt={2.5}>
                    <Formik
                        onSubmit={(values, { resetForm }) => {
                            const source = sections.length ? sections : playbook.slicingSuggestion

                            const delta = sections.length ? 2 : 1

                            const position =
                                positionOptions.find(it => it.label === values?.position?.value)
                                    ?.value || 'bottomRight'

                            const newCta = {
                                ...values,
                                position: position as CallToActionType['position'],
                                image: values.imageOption === 'default' ? logo : values.image,
                                start:
                                    values.timeOption === 'entireVideo'
                                        ? 0
                                        : source?.[source.length - delta]?.start || 0,
                                end:
                                    values.timeOption === 'entireVideo'
                                        ? duration
                                        : source?.[source.length - delta]?.end || 0
                            }

                            const newData = {
                                playbookId: params.playbookId,
                                cta: newCta
                            }

                            $updateCta.mutate(newData).then(() => {
                                resetForm()
                                onClose()
                                // trigger mutate without revalidate
                                callback?.({ cta: newCta })
                                logToAnalytics('saveCta', {
                                    ...playbookToAnalyticsProps(playbook),
                                    ...{
                                        cta: {
                                            ...newCta,
                                            action: playbook.cta ? 'update' : 'create'
                                        }
                                    }
                                })
                            })
                        }}
                        enableReinitialize={true}
                        initialValues={{
                            ...root,
                            position: positionOptions.find(it => it.value === root.position)
                        }}
                        validationSchema={yup.object().shape({
                            text: yup.object().test((option, { createError }: any) => {
                                const invalidLength = (option?.value as string)?.length > 30
                                const empty = !option.value
                                const invalid = empty || invalidLength

                                if (invalid) {
                                    return createError({
                                        message: empty
                                            ? 'Field is required'
                                            : `Max length is 30 characters. Current length: ${
                                                  (option?.value as string).length
                                              }`,
                                        path: 'text'
                                    })
                                }

                                return true
                            }),
                            link: yup
                                .string()
                                .trim()
                                .nullable()
                                .url('Enter valid link: https://example.com')
                                .required('Field is required')
                        })}
                    >
                        {({ setFieldValue, values, dirty }) => (
                            <Form>
                                <SpacedGroup px={3.5} flexDirection="column" pb={3} spacing={3}>
                                    <Box
                                        width="100%"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography style={itemTitleStyle}>
                                            {initialValues ? 'Update' : 'Add'} action item
                                        </Typography>

                                        <IconButton onClick={onClose} size="small">
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>

                                    <Box>
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16
                                            }}
                                        >
                                            Image
                                        </Typography>

                                        <Box mt={1.5}>
                                            {values.imageOption === 'default' && (
                                                <SpacedGroup>
                                                    {/* Temporary solution, it will be a real list of items in near future */}
                                                    {[logo].map((item, i) => (
                                                        <Box key={i} style={generateStyles(true)}>
                                                            {item && (
                                                                <img
                                                                    src={item}
                                                                    alt="orgLogo"
                                                                    height="100%"
                                                                    style={{
                                                                        objectFit: 'cover'
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    ))}
                                                </SpacedGroup>
                                            )}
                                            {values.imageOption === 'custom' && (
                                                <FileUploader
                                                    accept="image/*"
                                                    onProgressChange={progress =>
                                                        imageUploading.set(Boolean(progress))
                                                    }
                                                    onDone={url => {
                                                        setFieldValue('image', url)
                                                        imageUploading.setFalse()
                                                    }}
                                                    previewStyle={{
                                                        ...generateStyles(true),
                                                        objectFit: 'contain'
                                                    }}
                                                    previewWidth={50}
                                                    filename={`${params.playbookId}_${uuid()}`}
                                                    logoUrl={initialValues?.image || values.image}
                                                    storagePath={`playbookCta/${orgId}/${uid}`}
                                                    labelComponent={<Typography>Upload</Typography>}
                                                />
                                            )}
                                        </Box>
                                        <Box mt={2.5} />
                                        <ConnectedRadioGroup
                                            options={[
                                                option('default', 'Account logo'),
                                                option('custom', 'Custom')
                                            ]}
                                            spacing={3}
                                            checkboxSpacing={1}
                                            optionFontSize={14}
                                            name="imageOption"
                                        />
                                    </Box>

                                    <Box data-test="cta-title-dropdown">
                                        <ConnectedAutocomplete
                                            disableCloseOnSelect={false}
                                            fullWidth={true}
                                            name="text"
                                            variant="filled"
                                            label="Title"
                                            freeSolo={true}
                                            multiple={false}
                                            disableClearable={true}
                                            options={textOptions}
                                            filterSelectedOptions={false}
                                        />
                                        <Typography variant="caption" color="textSecondary">
                                            It will appear on the action item
                                        </Typography>
                                    </Box>
                                    <Box pb={2}>
                                        <ConnectedFilledInput
                                            name="link"
                                            label="Link"
                                            placeholder="https://example.com"
                                        />
                                    </Box>

                                    <Box data-test="cta-position-dropdown">
                                        <ConnectedAutocomplete
                                            disableCloseOnSelect={false}
                                            fullWidth={true}
                                            label="Position"
                                            name="position"
                                            variant="filled"
                                            freeSolo={true}
                                            multiple={false}
                                            disableClearable={true}
                                            options={positionOptions}
                                            filterSelectedOptions={false}
                                        />
                                        <Typography variant="caption" color="textSecondary">
                                            Position of the action button
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Typography style={itemTitleStyle}>
                                            Timeline appearance
                                        </Typography>
                                        <Box mt={1} />
                                        <ConnectedRadioGroup
                                            name="timeOption"
                                            options={[
                                                option('entireVideo', 'Whole video'),
                                                option('lastChapter', lastItemLabel)
                                            ]}
                                            checkboxSpacing={1}
                                            optionFontSize={14}
                                            spacing={4.5}
                                        />
                                    </Box>
                                </SpacedGroup>

                                <Divider />

                                <DialogActions disableSpacing>
                                    <SpacedGroup
                                        px={2}
                                        py={1}
                                        justifyContent="flex-end"
                                        spacing={4}
                                    >
                                        <FormControlLabel
                                            onChange={() => {
                                                setFieldValue('enabled', !values.enabled)
                                            }}
                                            control={
                                                <IOSSwitch
                                                    checked={values.enabled}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="subtitle2"
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        letterSpacing: 1.25,
                                                        textTransform: 'uppercase',
                                                        color: 'rgba(9, 12, 16, 0.6)'
                                                    }}
                                                >
                                                    ACTIVE
                                                </Typography>
                                            }
                                        />

                                        <Button
                                            data-test="cta-save-button"
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            startIcon={<CheckIcon />}
                                            disabled={
                                                $updateCta.isLoading ||
                                                imageUploading.isTrue ||
                                                !dirty
                                            }
                                        >
                                            Save
                                        </Button>
                                    </SpacedGroup>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Dialog>
        )
    }
)
