import { useDispatch } from 'react-redux'

import { Autocomplete, Button, TextField, Tooltip, styled } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

import { SpacedGroup } from 'UI/Components'
import { setFontStyles } from 'ducks'

const StyledButton = styled(Button)({
    height: 24,
    borderRadius: 0,
    borderWidth: 0.5,
    minWidth: 20,
    padding: 4,
    borderColor: 'rgba(0, 0, 0, 0.23)'
})

type Props = {
    size: number
    scale: number
}

export const FontSize = ({ size, scale }: Props) => {
    const dispatch = useDispatch()

    const onePx = 1 / scale

    return (
        <SpacedGroup spacing={0}>
            <Tooltip title="Decrease the font size">
                <StyledButton
                    size="small"
                    style={{ borderRight: 'none' }}
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                        const newValue = Number(size - onePx)
                        if (newValue < 1) return
                        dispatch(setFontStyles({ fontSize: newValue }))
                    }}
                >
                    <RemoveIcon fontSize="small" />
                </StyledButton>
            </Tooltip>

            <Tooltip title="Font size in px">
                <Autocomplete
                    value={Math.floor(size * scale) || 0}
                    disableClearable
                    freeSolo
                    disablePortal
                    sx={{
                        '& .MuiAutocomplete-input': {
                            padding: '0 !important',
                            textAlign: 'center'
                        },
                        '& .MuiAutocomplete-inputRoot': {
                            padding: 0,
                            fontSize: '14px',
                            height: 24,
                            borderRadius: 0
                        },
                        '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                            fontSize: '12px',
                            '&[aria-selected="true"]': {
                                background: 'rgba(0, 0, 0, 0.06)'
                            }
                        }
                    }}
                    options={[10, 12, 16, 24, 30, 36, 48, 60, 72, 96]}
                    onChange={(event: any) => {
                        event.persist()
                        dispatch(
                            setFontStyles({
                                fontSize:
                                    Number(event.target.value || event.target.innerText) / scale
                            })
                        )
                    }}
                    onPaste={e => e.preventDefault()}
                    getOptionLabel={option => String(option) || '0'}
                    renderInput={({ InputProps, inputProps, ...params }) => (
                        <TextField
                            variant="outlined"
                            InputProps={{
                                style: { width: '50px', padding: '1px 8px', borderRadius: 0 }
                            }}
                            {...params}
                            inputProps={{ ...inputProps, maxLength: 3 }}
                            onKeyPress={e => {
                                if (!/^[0-9]$/.test(e.key)) {
                                    e.preventDefault()
                                }
                            }}
                            onKeyUp={e => {
                                const value = (e.target as HTMLInputElement).value
                                if (value && Number.isInteger(Number(value))) {
                                    dispatch(setFontStyles({ fontSize: Number(value) / scale }))
                                }
                            }}
                        />
                    )}
                />
            </Tooltip>

            <Tooltip title="Increase the font size">
                <StyledButton
                    size="small"
                    style={{ borderLeft: 'none' }}
                    variant="outlined"
                    color="inherit"
                    onClick={() => dispatch(setFontStyles({ fontSize: Number(size + onePx) }))}
                >
                    <AddIcon fontSize="small" />
                </StyledButton>
            </Tooltip>
        </SpacedGroup>
    )
}
