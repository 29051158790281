import { type ReactNode } from 'react'

import { Box, styled, Typography } from '@mui/material'
import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { ReactComponent as MergeCardIcon } from 'assets/icons/merge-qg-card.svg'

import { SpacedGroup, visibilityIcons } from 'UI/Components'

import { type QuickGuiddeType } from 'app/types'

import { formatTime } from 'modules'

const Image = styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
})
const Title = styled(Typography)({
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px'
})

const Duration = styled(Box)({
    background: 'rgba(9,12,16, 0.6)',
    borderRadius: 2,
    fontSize: '12px',
    position: 'absolute',
    color: '#fff',
    bottom: 5,
    right: 5,
    zIndex: 2,
    padding: '2px 4px'
})

export type QGCardItemType = Pick<
    QuickGuiddeType,
    'duration' | 'title' | 'screenshotUrl' | 'visibility' | 'id'
> | null

type Props = {
    quickGuidde: QGCardItemType
    dataCyPrefix: string
}

export const QuickGuiddeCard = ({ dataCyPrefix, quickGuidde }: Props) => {
    if (!quickGuidde) {
        return (
            <Container>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    <MergeCardIcon />

                    <EmptyText>Select a guide</EmptyText>
                    <EmptyText>to merge with</EmptyText>
                </Box>
            </Container>
        )
    }

    return (
        <Container>
            <Box width="100%" height="120px" position="relative">
                <Image src={quickGuidde.screenshotUrl} data-test={`${dataCyPrefix}-qg-thumbnail`} />
                <Duration data-test={`${dataCyPrefix}-qg-duration`}>
                    {formatTime(quickGuidde.duration)}
                </Duration>
            </Box>
            <SpacedGroup width="100%" p="10px" spacing={1}>
                <Box
                    data-test={`${dataCyPrefix}-qg-visibility`}
                    data-test-visibility={quickGuidde.visibility}
                >
                    {visibilityIcons[quickGuidde.visibility]}
                </Box>
                <TextOverflow lineClamp={2}>
                    <Title data-test={`${dataCyPrefix}-qg-title`}>{quickGuidde.title}</Title>
                </TextOverflow>
            </SpacedGroup>
        </Container>
    )
}

const EmptyText = ({ children }: { children: ReactNode }) => (
    <Typography
        variant="caption"
        align="center"
        display="block"
        style={{
            color: 'rgba(9, 12, 16, 0.60)',
            fontStyle: 'italic'
        }}
    >
        {children}
    </Typography>
)

const Container = ({ children }: { children: ReactNode }) => (
    <Box
        border="1px solid rgba(9, 12, 16, 0.08)"
        borderRadius="6px"
        overflow="hidden"
        width="210px"
        height="170px"
        position="relative"
    >
        {children}
    </Box>
)
