import { useCallback, useMemo } from 'react'
import { useNotification } from './use-notification'

export const copyToClipboardMessage = 'Linked GIF was copied to the clipboard'
export const useCopyToClipboard = () => {
    const { showSuccessNotification } = useNotification()

    const copyToClipboard = useCallback(
        (html: string, message: string = copyToClipboardMessage) => {
            const container = document.createElement('div')
            container.innerHTML = html
            container.style.position = 'fixed'
            container.style.pointerEvents = 'none'
            container.style.opacity = '0'
            document.body.appendChild(container)
            window?.getSelection()?.removeAllRanges()
            const range = document.createRange()
            range.selectNode(container)
            window?.getSelection()?.addRange(range)
            document.execCommand('copy')
            document.body.removeChild(container)
            showSuccessNotification(message)
        },
        [showSuccessNotification]
    )

    return useMemo(() => ({ copyToClipboard }), [copyToClipboard])
}
