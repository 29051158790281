import { styled, Avatar, Chip, Tooltip } from '@mui/material'

import LinkIcon from '@mui/icons-material/Link'
import PdfIcon from '@mui/icons-material/PictureAsPdf'

const StyledAvatar = styled(Avatar)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#2D9CDB !important',
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    width: 'auto !important',
    height: 'auto !important'
})

const truncateString = (string: string, numberOfCharachters: number) => {
    if (string) {
        return string.length > numberOfCharachters
            ? `${string.substring(0, numberOfCharachters - 1)}...`
            : string
    }
}
type OpenType = '_blank' | '_self'

type Props = {
    /**
     * Item data (name, url and id)
     */
    item: { name: string | null; url: string; id: string }
    /**
     * Handle delete item
     */
    onDelete?: (itemId: string, isLink?: boolean) => void
    /**
     * Whether the item is a link
     */
    isLink: boolean
    /**
     * Whether we are in an edit mode
     */
    isEditMode: boolean
    /**
     * Open link
     */
    openLink: (link: string, type: OpenType) => void
}
export const InfoListItem = ({
    item: { name, url, id },
    onDelete,
    isLink,
    isEditMode,
    openLink
}: Props) => {
    const isPdf = name?.endsWith('pdf')

    return (
        <Tooltip title={!isLink || name ? `${name}` : `${url}`}>
            <Chip
                size="small"
                variant="outlined"
                onDelete={isEditMode && onDelete ? () => onDelete(id, isLink) : undefined}
                onClick={() => openLink(url, '_blank')}
                label={
                    !isLink || name ? truncateString(name as string, 12) : truncateString(url, 12)
                }
                avatar={
                    isLink ? (
                        <StyledAvatar>
                            <LinkIcon sx={{ fontSize: '14px' }} data-test="link-icon" />
                        </StyledAvatar>
                    ) : isPdf ? (
                        <StyledAvatar>
                            <PdfIcon />
                        </StyledAvatar>
                    ) : (
                        <Avatar src={url} alt="img" />
                    )
                }
                sx={{
                    '& .MuiChip-outlined': {
                        color: '#2D9CDB'
                    },
                    color: '#2D9CDB',
                    border: 'none',
                    padding: '5px',
                    marginRight: '4px'
                }}
                data-test="attachment-item"
            />
        </Tooltip>
    )
}
