import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, Button, Divider } from '@mui/material'

import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import {
    LanguageDropdown,
    QgTranscript,
    VoiceOverLoader,
    VoiceOverPreview
} from 'UI/Routes/quick-guidde/LeftPanel'
import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor'
import { defaultSpeakingRate, useStepSpeakingRate } from 'UI/Routes/quick-guidde/hooks'
import { AsyncSubtitlesContext } from 'UI/Routes/quick-guidde/AsyncSubtitlesProvider'
import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'

import { SpacedGroup } from 'UI/Components'
import { T2sBanner } from './T2sBanner'
import { SpeakersList } from './SpeakersList'

import { useDataMutation } from 'hooks'
import { type OptionType, isDeepEqual, logToAnalytics, option, round, uuid } from 'modules'

import { setActiveStep, setMultipleAudioNotes, setTempAudioNote } from 'ducks'
import {
    type AudioCircleType,
    type QuickGuiddeType,
    type SpeakerType,
    type StepType,
    type SubtitlesType,
    type TextToSpeechType,
    Shape
} from 'app/types'

type AudioNotePayload = {
    audioNote: {
        text: string
        markdown: string
    }
    stepId: string
    subtitles?: SubtitlesType
    config: SpeakerType
    stepHasAudioLayer: boolean
    isActiveStep: boolean
}

type Payload = {
    playbookId: string
    segments: Array<AudioNotePayload>
    audioImage: AudioCircleType
    activeStep: number
    activeSpeaker: SpeakerType
    t2sAvatar: boolean
}

type Response = Array<
    AudioNotePayload & {
        audioUrl: string
        duration: number
        subtitles: SubtitlesType
        subGenerationId?: string
    }
>

type Props = {
    hasVoiceOverTextToSpeech: boolean | null
    playbook: QuickGuiddeType
}

export const TextToSpeechTab = ({ hasVoiceOverTextToSpeech, playbook }: Props) => {
    const dispatch = useDispatch()

    const { processingStepsId } = useContext(AsyncSubtitlesContext)
    const { setVoiceOverProcessing } = useContext(VoiceOverContext)

    const { playbookId } = useParams<{ playbookId: string }>()
    const { audioImage } = useLayers()

    const { getStepSpeakingRate } = useStepSpeakingRate()

    const { t2sBanner, t2sAvatar } = useSelector(state => state.configs.userPreferences)

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { audioNote, tempAudioNote, id } = steps?.[activeStep]

    const applyAllSpeakers = useBoolean(true)

    const markdown = audioNote?.tempMarkdown || audioNote?.markdown || ''

    const [value, setValue] = useState<string>(markdown)

    const { magicCapture } = playbook

    const playbookLanguage = magicCapture?.language
        ? option(magicCapture.language.langCode, magicCapture.language.langName)
        : null

    const [language, setLanguage] = useState<OptionType | null>(playbookLanguage)
    const [speakerConfig, setSpeakerConfig] = useState<null | SpeakerType>(null)

    const buttonOnBottom = hasVoiceOverTextToSpeech && !t2sBanner

    const $createTextToSpeech = useDataMutation<Payload, Response, Error>(
        '/c/v1/quickguidde/text-to-speech',
        'POST',
        {
            onSuccess: (data, { activeSpeaker, activeStep, audioImage, t2sAvatar }) => {
                dispatch(setActiveStep(activeStep))

                // If we have multiple steps - we should prepare array of audio notes and add audio layer to each of them
                const multipleT2s = data.map(dataStep => {
                    const isAvatarEnabled = Boolean(t2sAvatar)

                    const { isActiveStep, stepHasAudioLayer } = dataStep

                    const audioLayer = {
                        ...audioImage,
                        id: uuid(),
                        render: {
                            ...audioImage.render,
                            url: dataStep.config.avatarUrl
                        }
                    }

                    return {
                        audioNote: {
                            type: 'textToSpeech' as const,
                            isUserEdited: true,
                            text: dataStep.audioNote.text,
                            markdown: dataStep.audioNote.markdown,
                            audioUrl: dataStep.audioUrl,
                            audioDuration: round(dataStep.duration),
                            speakerConfig: dataStep.config,
                            speakingRate: dataStep.config.speakingRate
                        } as TextToSpeechType,
                        stepId: dataStep.stepId,
                        subtitles: dataStep.subtitles,
                        subGenerationId: dataStep.subGenerationId,
                        // we should add audio layer only if enabled in user preferences or if current step already has it
                        ...((stepHasAudioLayer || (isActiveStep && isAvatarEnabled)) && {
                            audioLayer
                        })
                    }
                })

                dispatch(setMultipleAudioNotes(multipleT2s))

                logToAnalytics('voiceover_add', {
                    voiceOverType: 'textToSpeech',
                    playbookId,
                    activeStep,
                    multipleSteps: data.length > 1,
                    languageCode: activeSpeaker.langCode,
                    speaker: activeSpeaker,
                    data
                })
            },
            onFailure: console.error
        }
    )

    const generateSegments = useCallback(
        (
            markdown: string,
            speakingRate: number,
            applyToAllSteps: boolean
        ): Array<AudioNotePayload> => {
            const checkIfStepChanged = (
                audioNote: StepType['audioNote'],
                isActiveStep: boolean
            ): boolean => {
                // Speed rate can be changed for both active and non-active steps
                const stepSpeakingRate =
                    audioNote?.type === 'textToSpeech' && audioNote.speakerConfig.speakingRate
                const isSpeedChanged = stepSpeakingRate
                    ? stepSpeakingRate !== speakingRate
                    : speakingRate !== 1

                if (isSpeedChanged) return true

                // In active step, we need to check if text is changed only
                if (isActiveStep) {
                    const isNewText = audioNote?.markdown !== markdown
                    return isNewText || audioNote.type == 'defaultSubtitles'
                }
                // All the other steps check the speaker change
                else {
                    if (audioNote?.type !== 'textToSpeech') return false
                    // Check if speaker is changed
                    return speakerConfig?.id !== audioNote.speakerConfig?.id
                }
            }

            return steps.flatMap(step => {
                const { audioNote, id } = step
                const isActiveStep = id === steps[activeStep].id

                // Skip non-active steps if `applyToAllSteps` is not checked or no audioNote
                if (!isActiveStep && (!applyToAllSteps || !audioNote)) return []
                // Skip step with no changes
                if (!checkIfStepChanged(audioNote, isActiveStep)) return []

                const stepHasAudioLayer = step.layers.some(
                    layer => layer.type === Shape.AudioCircle
                )

                return {
                    isActiveStep,
                    stepHasAudioLayer,
                    stepId: id,
                    audioNote: {
                        text: isActiveStep ? markdown : audioNote!.text,
                        markdown: isActiveStep ? markdown : audioNote!.markdown
                    },
                    config: {
                        ...(speakerConfig || (audioNote as TextToSpeechType).speakerConfig),
                        speakingRate
                    }
                }
            })
        },
        [activeStep, speakerConfig, steps]
    )

    useEffect(() => {
        // if current step has no tempAudioNote - reset fields to default
        if (!tempAudioNote || tempAudioNote.type !== 'textToSpeech') {
            history.replaceState('', '')
            setValue(markdown)
            return
        }

        // If user clicks on edit voiceOver we save all the fields in the tempAudioNote,
        if (tempAudioNote?.markdown && value !== tempAudioNote.markdown) {
            setValue(tempAudioNote.markdown)
        }

        // If user clicks on edit voiceOver we save all the fields in the tempAudioNote,
        if (language?.value !== tempAudioNote.speakerConfig.langCode) {
            setLanguage(
                option(tempAudioNote.speakerConfig.langCode, tempAudioNote.speakerConfig.langName)
            )
        }

        // Prevent unnecessary re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempAudioNote, markdown])

    if (processingStepsId.includes(id) || $createTextToSpeech.isLoading) {
        return <VoiceOverLoader title="Generating voiceover..." />
    }

    if (audioNote && audioNote.type === 'textToSpeech') {
        return (
            <VoiceOverPreview
                showSpeedButton={true}
                onEdit={({
                    markdown,
                    speakingRate = defaultSpeakingRate,
                    applyToAllSteps = false
                }) => {
                    const segments = generateSegments(markdown, speakingRate, applyToAllSteps)

                    setVoiceOverProcessing(true)
                    $createTextToSpeech
                        .mutate({
                            playbookId,
                            segments,
                            activeStep,
                            audioImage,
                            activeSpeaker: audioNote.speakerConfig,
                            t2sAvatar
                        })
                        .finally(() => setVoiceOverProcessing(false))
                }}
            />
        )
    }

    return (
        <Box position={hasVoiceOverTextToSpeech ? 'initial' : 'relative'}>
            <T2sBanner showBanner={t2sBanner} disabled={!hasVoiceOverTextToSpeech} />

            {!hasVoiceOverTextToSpeech && (
                <Box
                    sx={theme => ({
                        left: theme.spacing(-2),
                        top: theme.spacing(-2),
                        position: 'absolute',
                        width: 'calc(100% + 32px)',
                        height: 'calc(100% + 32px)',
                        background: 'white',
                        opacity: 0.5,
                        zIndex: 1
                    })}
                />
            )}

            <SpacedGroup mt={1} spacing={2} flexDirection="column">
                <SpacedGroup alignItems="center">
                    <LanguageDropdown
                        language={language}
                        onSelect={setLanguage}
                        anchorOriginHorizontal="right"
                        transformOriginHorizontal="left"
                    />
                    <SpeakersList
                        languageCode={language?.value || ''}
                        currentSpeaker={speakerConfig}
                        onSelect={value => {
                            if (!isDeepEqual(value, speakerConfig)) {
                                setSpeakerConfig(value)
                            }

                            // Only if we already have tempAudioNote, its type is textToSpeech and there is a change
                            if (
                                tempAudioNote?.type === 'textToSpeech' &&
                                !isDeepEqual(value, tempAudioNote.speakerConfig)
                            ) {
                                dispatch(
                                    setTempAudioNote({
                                        type: 'textToSpeech',
                                        text: tempAudioNote.text,
                                        markdown: tempAudioNote.markdown,
                                        speakerConfig: value
                                    })
                                )
                            }
                        }}
                        onAudioPlay={speaker => {
                            logToAnalytics('voiceover_play_speaker_preview', {
                                playbookId,
                                activeStep,
                                speaker,
                                source: 'voiceovert2vTab'
                            })
                        }}
                        onMenuOpen={() =>
                            logToAnalytics('voiceover_t2vTab_speaker_clicked', { playbookId })
                        }
                        onSpeakerClick={() =>
                            logToAnalytics('voiceover_t2vTab_speaker_selected', {
                                playbookId,
                                source: 'voiceovert2vTab'
                            })
                        }
                        onApplyAllClick={() =>
                            logToAnalytics('voiceover_t2vTab_speaker_applyAllChk_clicked', {
                                playbookId
                            })
                        }
                        applyAll={applyAllSpeakers}
                    />
                </SpacedGroup>
                <Box>
                    <QgTranscript
                        text={audioNote?.text || ''}
                        markdown={value}
                        type="textToSpeech"
                        speakerConfig={speakerConfig}
                        language={language?.label || ''}
                        onChange={setValue}
                    />
                </Box>
            </SpacedGroup>

            <Box
                mt={buttonOnBottom ? 0 : 6}
                px={buttonOnBottom ? 2 : 0}
                position={buttonOnBottom ? 'absolute' : 'initial'}
                bottom={16}
                left={0}
                width="100%"
                textAlign="center"
            >
                <Box mx={-2} mb={2}>
                    <Divider />
                </Box>

                {speakerConfig && (
                    <Button
                        variant="contained"
                        size="large"
                        data-test="t2s-add-voiceover-button"
                        data-intercom="editor-add-voiceover-t2s"
                        disabled={!value?.length}
                        fullWidth
                        onClick={() => {
                            logToAnalytics('voiceover_addVoiceoverBtn_clicked', {
                                playbookId,
                                textInput: value
                            })

                            const segments = generateSegments(
                                value,
                                getStepSpeakingRate(steps[activeStep]),
                                applyAllSpeakers.isTrue
                            )

                            setVoiceOverProcessing(true)
                            $createTextToSpeech
                                .mutate({
                                    playbookId,
                                    segments,
                                    activeStep,
                                    audioImage,
                                    activeSpeaker: speakerConfig,
                                    t2sAvatar
                                })
                                .finally(() => setVoiceOverProcessing(false))
                        }}
                    >
                        ADD VOICEOVER
                    </Button>
                )}
            </Box>
        </Box>
    )
}
