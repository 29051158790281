import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useObjectVal } from 'react-firebase-hooks/database'

import { ref } from 'firebase/database'

import { InstallExtDialog } from './InstallExtDialog'

import { useDataMutation, useAuth, useRoles } from 'hooks'
import { rtdb } from 'modules'

import { paths } from 'app/paths'

type ExtPopupType = {
    isShown: boolean
    isSkippable?: boolean
} | null

const ignorePaths = [paths.onboarding, paths.welcome, paths.editQuickGuidde]

const OnboardingDialog = () => {
    const { orgId, uid } = useAuth()
    const { isMember } = useRoles()

    const [data, loading] = useObjectVal<ExtPopupType>(
        ref(rtdb, `showSomething/${uid}/postOnboardingDialog`)
    )

    const isExtensionInstalled = useSelector(state => state.extensionStatus.isInstalled)

    const prohibitedLocation = useRouteMatch(ignorePaths)?.isExact ?? false

    const $closeOnboarding = useDataMutation('/c/v1/close-onboarding-dialog', 'POST')
    const handleClose = () => $closeOnboarding.mutate({}).catch(console.error)

    const hideDialog =
        isExtensionInstalled || loading || !data?.isShown || !orgId || prohibitedLocation

    if (!isMember) return

    return (
        <InstallExtDialog
            isOpen={!hideDialog}
            onClose={handleClose}
            onDownloadLaterClick={handleClose}
            isLoading={$closeOnboarding.isLoading}
        />
    )
}

export default OnboardingDialog
