import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { ChapterContent } from '@guidde-co/shared.chapters.chapter-content'

import { setActivePlaybookPart, setChapterTitle } from 'ducks/actions'
import { type SectionType } from 'ducks/reducers'

import {
    round,
    uuid,
    logToAnalytics,
    openLink,
    delay,
    playbookToAnalyticsProps,
    host
} from 'modules'

import { useDataMutation, useNotification } from 'hooks'

import { paths } from 'app/paths'
import { type PlaybookType } from 'app/types'

type Props = {
    canvasElements: Array<HTMLCanvasElement>
    videoElement: HTMLVideoElement
    playbookId: string
    slicingSuggestion: Array<{
        start: number
        end: number
        title?: string
        thumbnail?: string
    }>
    searchString?: string
    playbook: PlaybookType
}
const MIN_SEARCH_LENGTH = 2

export const EditableChapters = ({
    canvasElements,
    videoElement,
    playbookId,
    slicingSuggestion,
    searchString = '',
    playbook
}: Props) => {
    const dispatch = useDispatch()
    const [exportedChapterIndex, setExportedChapterIndex] = useState<null | number>(null)
    const { activeSection, sections, duration, currentTime } = useSelector(
        state => state.videoEditor
    )
    // remove hidden first and last elements (use for video editor only)
    const chapters = useMemo(() => [...sections.slice(1, sections.length - 1)], [sections])

    const { showSuccessNotification } = useNotification()
    const $sliceVideo = useDataMutation('/b/v1/playbookAction/', 'POST', {
        onSuccess: () => {
            showSuccessNotification('Playbook created successfully')
            logToAnalytics('playbook_from_chapter')
        }
    })

    const handleSlice = (chapter: SectionType) => {
        const start = round(duration * (chapter.rangeValues[0] / 100))
        const end = round(duration * (chapter.rangeValues[1] / 100) - start)
        const id = uuid()

        $sliceVideo
            .mutate({
                action: 'sliceChapter',
                playbookId,
                chapter: {
                    id, // generate id order to go to edit once ready
                    start,
                    duration: end,
                    title: chapter.title
                }
            })
            .then(() => {
                setExportedChapterIndex(null)
                openLink(
                    host +
                        generatePath(paths.editPlaybook, {
                            playbookId: id
                        })
                )
            })
    }

    // set initial thumbnail to canvas
    useLayoutEffect(() => {
        delay(1000).then(() => {
            canvasElements?.forEach((canvas, idx) => {
                const ctx = canvas?.getContext('2d')
                if (!ctx) return
                const img = new Image()
                img.crossOrigin = 'anonymous'
                img.src = slicingSuggestion?.[idx]?.thumbnail || canvas.toDataURL()
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, 360, 210)
                }
            })
        })
    }, [canvasElements, slicingSuggestion])

    // change thumbnail on active chapter change (on click or during playing)
    useEffect(() => {
        if (!videoElement || videoElement.seeking || sections.length === 0 || !Boolean(duration))
            return
        const ctx = canvasElements?.[activeSection]?.getContext('2d')
        if (!ctx) return
        const startRangeTime = (duration / 100) * sections?.[activeSection + 1]?.rangeValues[0] // activeSection +1 as we start sections with a hidden section
        if (Math.abs(currentTime - startRangeTime) <= 0.1) {
            ctx.drawImage(videoElement, 0, 0, 360, 210)
        }
    }, [canvasElements, videoElement, activeSection, currentTime, sections, duration])

    // change active section
    useEffect(() => {
        const endTimes = chapters.map(chapter => round((duration / 100) * chapter.rangeValues[1]))
        endTimes.some((end, idx) => {
            if (end > currentTime) {
                if (idx === activeSection) return true
                dispatch(setActivePlaybookPart(idx))
                return true
            }
        })
    }, [currentTime, chapters, activeSection, dispatch, duration])

    const filteredChapters = useMemo(() => {
        // For empty search string or search length under MIN_SEARCH_LENGTH return all the subtitles
        if (searchString.length <= MIN_SEARCH_LENGTH) return chapters
        // limit search results to 50 items
        const res = chapters
            .filter(item => item.title.toLowerCase().includes(searchString.toLowerCase()))
            .slice(0, 50)
        logToAnalytics(res?.length ? 'search' : 'search-no-results', {
            text: searchString.toLowerCase(),
            hits: res?.length,
            inVideo: true,
            ...playbookToAnalyticsProps(playbook)
        })
        return res
    }, [searchString, chapters, playbook])

    const isHideChapter = useCallback(
        (chapter: SectionType) => {
            if (searchString.length <= MIN_SEARCH_LENGTH) return false
            return searchString
                ? !chapter.title.toLowerCase().includes(searchString.toLowerCase())
                : false
        },
        [searchString]
    )

    return (
        <Box mr={1}>
            {filteredChapters?.length === 0 && chapters?.length > 0 && (
                <Typography>We could not find a match for "{searchString}"</Typography>
            )}
            {chapters.map((chapter, idx) => (
                // filter out chapters which does not include the search string but still render them as hidden elements
                // so the thumbnails still exist in order to keep the thumbnails' logic in the video player
                <Box key={idx} hidden={isHideChapter(chapter)}>
                    <>
                        {/* When user changes start time - we render screenshot with canvas */}
                        <canvas
                            // @ts-ignore
                            ref={el => (canvasElements[idx] = el)}
                            width="360"
                            height="210"
                            style={{
                                height: '70px',
                                border: '2px solid #e6e7e8',
                                borderRadius: '2px',
                                width: '120px',
                                zIndex: 3,
                                backgroundColor: 'black',
                                display: 'none'
                            }}
                        />
                    </>
                    <ChapterContent
                        chapter={chapter}
                        duration={duration}
                        isEditMode={true}
                        isLoading={$sliceVideo.isLoading && exportedChapterIndex === idx}
                        isActive={idx === activeSection}
                        onClick={() => {
                            if (!videoElement) return
                            videoElement.currentTime =
                                (duration / 100) * chapter?.rangeValues[0] + 0.01
                        }}
                        onExportClick={() => {
                            setExportedChapterIndex(idx)
                            handleSlice(chapter)
                        }}
                        onTitleChange={value => {
                            dispatch(
                                setChapterTitle({
                                    title: value,
                                    idx: idx + 1 // as we hide sections (first and last)
                                })
                            )
                        }}
                    />
                </Box>
            ))}
        </Box>
    )
}
