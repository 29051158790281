import { type StepType } from 'app/types'

export const currentWizardPreferencesStorageKey = 'currentWizardPreferences'
export const wizardPreferencesStorageKey = 'wizardPreferences'
export const wizardTopAudioIdsStorageKey = 'wizardTopAudioIds'

export enum WizardStep {
    INTRO,
    SPEAKER,
    TRANSITION,
    AUDIO,
    LOADER
}

export type WizardData = {
    speakerId: string | null
    transitionName: string | null
    audioId: string | null
    currentStep: number
}

export type RTDBTransitionSequenceType = {
    isSequence: boolean
    image: string
    label: string
    name: string
    previewUrl?: string
    coverEndingTransition: StepType['transition']
    transitionPattern: StepType['transition'][]
}

export const preloadImages = (imageUrls: string[]): void => {
    imageUrls.forEach(url => {
        const img = new Image()
        img.src = url
    })
}

export const getRandomElement = <T, K extends keyof T>(arr: T[], fieldName: K): T[K] => {
    const index = Math.floor(Math.random() * arr.length)
    return arr[index][fieldName]
}
