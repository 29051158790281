import { memo, useEffect, useRef } from 'react'

import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import { Box, Button, IconButton } from '@mui/material'
import StopIcon from '@mui/icons-material/Stop'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ReplayIcon from '@mui/icons-material/Replay'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Replay5Icon from '@mui/icons-material/Replay5'
import Forward5Icon from '@mui/icons-material/Forward5'

import { SpacedGroup } from 'UI/Components'

import { RedButton } from './RedButton'

type PlayerProps = {
    audioUrl?: string
    onRestart: () => void
    onDelete: () => void
    onRewind: () => void
    onForward: () => void
}

export const Player = memo(
    ({ audioUrl, onRestart, onDelete, onRewind, onForward }: PlayerProps) => {
        const audioRef = useRef<HTMLAudioElement | null>(null)

        const playing = useBoolean()
        const resetPlaying = playing.setFalse

        const Icon = playing.isTrue ? StopIcon : PlayArrowIcon

        // Every time when audioUrl changes, we need to stop play state
        useEffect(() => {
            resetPlaying()
        }, [audioUrl, resetPlaying])

        return (
            <>
                {audioUrl && (
                    <Box mb={2} width="100%" display="none">
                        <audio
                            ref={audioRef}
                            src={audioUrl}
                            controls
                            style={{ width: '100%' }}
                            onPlay={playing.setTrue}
                            onPause={playing.setFalse}
                            onEnded={playing.setFalse}
                        />
                    </Box>
                )}

                <SpacedGroup mb={2} width="100%">
                    <Button
                        startIcon={<ReplayIcon />}
                        color="inherit"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={onRestart}
                    >
                        Restart
                    </Button>

                    {audioUrl && (
                        <Button
                            startIcon={<DeleteOutlineIcon />}
                            color="inherit"
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    )}
                </SpacedGroup>

                <SpacedGroup justifyContent="center" alignItems="center">
                    {audioUrl && (
                        <IconButton
                            size="small"
                            onClick={() => {
                                audioRef.current!.currentTime -= 5
                                onRewind()
                            }}
                        >
                            <Replay5Icon />
                        </IconButton>
                    )}

                    <RedButton
                        active={true}
                        onClick={() => {
                            playing.isTrue ? audioRef.current?.pause() : audioRef.current?.play()
                        }}
                        label={<Icon fontSize="large" />}
                    />

                    {audioUrl && (
                        <IconButton
                            size="small"
                            onClick={() => {
                                audioRef.current!.currentTime += 5
                                onForward()
                            }}
                        >
                            <Forward5Icon />
                        </IconButton>
                    )}
                </SpacedGroup>
            </>
        )
    }
)
