import { useDispatch } from 'react-redux'

import { Box, Button, IconButton, useTheme } from '@mui/material'

import ClearAllIcon from '@mui/icons-material/ClearAll'
import DeleteIcon from '@mui/icons-material/Delete'

import { resetLibraryPlaybookFilters } from 'ducks/actions'

import { useWindowView } from 'hooks'

import { type ObjectType, isFiltersEmpty } from './playbook-filters'

type Props = {
    filters: ObjectType
    clickHandler?: () => void
}

export const ClearFiltersButton = ({ filters, clickHandler }: Props) => {
    const { isMobileView } = useWindowView()

    const theme = useTheme()

    const dispatch = useDispatch()

    const handelClick = () => {
        clickHandler ? clickHandler() : dispatch(resetLibraryPlaybookFilters())
    }

    if (isFiltersEmpty(filters)) return null

    if (isMobileView) {
        return (
            <Box mx={1} width="40px">
                <IconButton
                    size="small"
                    onClick={handelClick}
                    style={{
                        background: '#fff',
                        boxShadow: theme.shadows[1],
                        width: '34px',
                        height: '34px'
                    }}
                >
                    <DeleteIcon style={{ fontSize: '15px' }} />
                </IconButton>
            </Box>
        )
    }

    return (
        <Box p={1} mx={1}>
            <Button
                size="small"
                variant="contained"
                onClick={handelClick}
                data-test="clear-button"
                startIcon={<ClearAllIcon />}
                sx={{
                    height: '34px',
                    backgroundColor: '#e0e0e0',
                    color: 'common.black',
                    '&:hover': {
                        backgroundColor: '#e0e0e0'
                    }
                }}
            >
                Clear
            </Button>
        </Box>
    )
}
