import { type MappedSpaceType } from 'app/types'

import { type OptionType, option, uniqueArray } from 'modules'
import { getLabelFromCode } from 'hooks'

import { typeList } from './use-playbooks-filter'

export const alphabetSort = (arr: Array<any>): Array<OptionType> => {
    return arr.sort((a, b) => {
        if (!a?.label || !b?.label) return -1
        return a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1
    })
}

export type ObjectType = Partial<{
    [key: string]: any
}>

export const generateOptions = (obj: ObjectType) => {
    if (!obj) return []
    return alphabetSort(uniqueArray(Object.keys(obj).flatMap(key => option(key, key))) || [])
}

export const generateAppOptions = (apps: ObjectType, obj: Record<string, number>) => {
    if (!obj) return []
    return alphabetSort(
        uniqueArray(Object.keys(obj).flatMap(key => option(key, apps[key]?.title))) || []
    )
}

export const generateTypesOptions = (obj: ObjectType) => {
    if (!obj) return []

    const filteredObj = Object.fromEntries(Object.entries(obj).filter(option => option[1]))

    const options = uniqueArray(
        Object.keys(filteredObj).map(key => {
            switch (key) {
                default:
                case 'playbook':
                    return option(typeList.playbook, typeList.playbook)
                case 'playlist':
                    return option(typeList.playlist, typeList.playlist)
            }
        })
    )

    return alphabetSort(options)
}

export const generateLanguageOptions = (obj: ObjectType) => {
    if (!obj) return []
    return alphabetSort(
        uniqueArray(Object.keys(obj).flatMap(key => option(key, getLabelFromCode(key))) || [])
    )
}

type OptionsType = Array<ReturnType<typeof option>>

export const generateSpaceOptions = (
    teamIds: Array<string>,
    userSpaces: Array<MappedSpaceType>
) => {
    if (!teamIds.length || !userSpaces.length) return []

    const options = teamIds.reduce((acc: OptionsType, spaceId: string) => {
        const name = userSpaces.find(({ id }) => id === spaceId)?.name

        if (name) {
            acc.push(option(spaceId, name))
        }

        return acc
    }, [])

    return alphabetSort(options)
}

export const isFiltersEmpty = (obj: ObjectType) =>
    !Object.values(obj).some(value => value.length !== 0)
