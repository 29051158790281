import { Fragment, memo, useContext, useEffect, useState } from 'react'
import MaskedInput from 'react-input-mask'

import { Icon, Tooltip } from '@guidde/design-system'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo'

import { Box, Stack, TextField, Typography } from '@mui/material'

import { SmartPreviewContext } from 'UI/Routes/quick-guidde/CanvasEditor'

import { useBoolean, useNotification } from 'hooks'
import { timeToSeconds } from 'modules'

const formatDuration = (durationInSeconds: number): string => {
    // Convert the duration to milliseconds
    const durationInMilliseconds = Math.round(durationInSeconds * 1000)

    // Calculate hours, minutes, seconds, and milliseconds
    const milliseconds = durationInMilliseconds % 1000
    const totalSeconds = Math.floor(durationInMilliseconds / 1000)
    const seconds = totalSeconds % 60
    const totalMinutes = Math.floor(totalSeconds / 60)
    const minutes = totalMinutes % 60
    const hours = Math.floor(totalMinutes / 60)

    // Format the results with leading zeros where necessary
    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(seconds).padStart(2, '0')
    const formattedMilliseconds = String(milliseconds).charAt(0)

    const hoursPrefix = hours > 0 ? `${formattedHours}:` : ''

    return `${hoursPrefix}${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`
}

type Props = {
    isEditable: boolean
    start: number
    end: number
    error?: string
} & (
    | {
          isActive: false
          onClick?: () => void
      }
    | {
          isActive: true
          onTimeUpdate: (newTime: { start?: number; end?: number }) => void
      }
)

export const CaptionTime = memo((props: Props) => {
    const { isEditable, isActive, start, end, error } = props
    const onTimeUpdate = isActive ? props.onTimeUpdate : undefined
    const onClick = !isActive ? props.onClick : undefined

    const { stepsTimeline } = useContext(SmartPreviewContext)

    const focus = useBoolean()
    const hover = useBoolean()

    const InfoIcon = () => (
        <Tooltip text={error}>
            <Icon icon={faCircleInfo} />
        </Tooltip>
    )

    if (!isEditable) {
        const Wrapper = error ? Stack : Fragment
        return (
            <Wrapper
                {...(error && {
                    spacing: 0.25,
                    direction: 'row',
                    alignItems: 'center'
                })}
            >
                <Typography
                    sx={theme => ({
                        '&, & ~ *>svg': {
                            color: error ? theme.palette.error['700'] : theme.palette.grey['600']
                        },
                        cursor: isEditable ? 'pointer' : 'default',
                        borderRight: '2px solid transparent'
                    })}
                    onClick={onClick}
                >
                    {formatDuration(start)}&nbsp;-&nbsp;{formatDuration(end)}
                </Typography>
                {error && <InfoIcon />}
            </Wrapper>
        )
    }

    return (
        <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            sx={theme => ({
                color: error && !isActive ? theme.palette.error['700'] : theme.palette.grey['600'],
                ...(error && {
                    '& svg': {
                        color: theme.palette.error['700'] + ' !important'
                    }
                })
            })}
        >
            <Stack
                pr={0.25}
                direction="row"
                alignItems="center"
                spacing={0}
                onClick={onClick}
                onMouseEnter={hover.setTrue}
                onMouseLeave={hover.setFalse}
                sx={theme => ({
                    width: 'fit-content',
                    borderRight: '2px solid transparent',
                    ...(!isActive && {
                        '&:hover': {
                            borderRight: `2px solid ${theme.palette.primary['300']}`,
                            background: theme.palette.primary['50']
                        }
                    })
                })}
            >
                <TimeInput
                    isActive={isActive}
                    isError={Boolean(error)}
                    value={formatDuration(start)}
                    maxValue={stepsTimeline.totalDuration}
                    onFocus={focus.setTrue}
                    onBlur={focus.setFalse}
                    onUpdate={value => onTimeUpdate?.({ start: value })}
                />

                <Typography>&nbsp;-&nbsp;</Typography>

                <TimeInput
                    isActive={isActive}
                    isError={Boolean(error)}
                    value={formatDuration(end)}
                    maxValue={stepsTimeline.totalDuration}
                    onFocus={focus.setTrue}
                    onBlur={focus.setFalse}
                    onUpdate={value => onTimeUpdate?.({ end: value })}
                />
            </Stack>
            {error && <InfoIcon />}
        </Stack>
    )
})

type TimeInputProps = {
    isActive: boolean
    isError: boolean
    value: string
    maxValue: number
    onFocus: () => void
    onBlur: () => void
    onUpdate: (_seconds: number) => void
}

export const TimeInput = ({
    isActive,
    isError,
    value,
    maxValue,
    onFocus,
    onBlur,
    onUpdate
}: TimeInputProps) => {
    const { showWarningNotification } = useNotification()

    const [time, setTime] = useState(value)

    useEffect(() => {
        setTime(value)
    }, [value])

    return (
        <Box position="relative">
            {/*Input tag doesn't support dynamic width based on its content, so we use a hidden Typography to mirror the input value*/}
            <Typography
                sx={{
                    padding: isActive ? '6px 12px' : 0,
                    visibility: 'hidden',
                    color: 'inherit'
                }}
            >
                {time}
            </Typography>
            <MaskedInput
                mask="99:99.9"
                maskPlaceholder="-"
                placeholder="00:00.0"
                value={time}
                onChange={event => {
                    const value = event.target.value
                    setTime(value)
                }}
                // We take the width from the hidden Typography to make it dynamic
                style={{ position: 'absolute', inset: 0 }}
                onFocus={onFocus}
                onBlur={e => {
                    onBlur()

                    const protectedValue = e.target.value.replaceAll('-', '0')
                    const currentValue = timeToSeconds(protectedValue)

                    const outsideOfRange = currentValue < 0 || currentValue > maxValue
                    if (outsideOfRange) {
                        showWarningNotification("Caption block can't exceed the video's duration")

                        setTime(value)
                        return
                    }

                    onUpdate(currentValue)
                }}
            >
                <TextField
                    value={time}
                    sx={theme => ({
                        '& .MuiInputBase-root': {
                            color: 'inherit',
                            ...(!isActive
                                ? { padding: 0, border: 'none', boxShadow: 'none' }
                                : {
                                      border: isError
                                          ? `1px solid ${theme.palette.error['400']}`
                                          : '1px solid #D0D5DD'
                                  })
                        },
                        '& input': { padding: 0, minWidth: 'auto', height: 20 },
                        '& fieldset': { display: 'none' }
                    })}
                    variant="outlined"
                />
            </MaskedInput>
        </Box>
    )
}
