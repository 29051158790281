import { type Dispatch, type SetStateAction, useRef, useState, useContext, useEffect } from 'react'

import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Radio,
    LinearProgress,
    Divider,
    Tooltip,
    TextField,
    Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import HelpIcon from '@mui/icons-material/Help'

import { SpacedGroup, VideoStatusContext } from 'UI/Components'
import { Download } from 'UI/Routes/main/common'

import { NotAvailableMessage } from './NotAvailableMessage'

import {
    useNotification,
    useBoolean,
    createResponsiveEmbedCode,
    createNotResponsiveEmbedCode,
    useLocalStorage
} from 'hooks'
import { copyToClipboard, formatTime, logToAnalytics, playbookToAnalyticsProps } from 'modules'

import { type AnyPlaybookType } from 'app/types'

const PREFIX = 'ExportTab'

const classes = {
    title: `${PREFIX}-title`,
    closeButton: `${PREFIX}-closeButton`,
    code: `${PREFIX}-code`,
    actions: `${PREFIX}-actions`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.title}`]: {
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold
    },

    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5)
    },

    [`& .${classes.code}`]: {
        padding: theme.spacing(1),
        background: '#fff',
        borderRadius: '4px',
        maxHeight: 100,
        overflowY: 'auto',
        '& p': {
            margin: 0
        }
    },

    [`& .${classes.actions}`]: {
        padding: theme.spacing(3),
        justifyContent: 'center'
    }
}))

const iframeType = {
    responsive: 'Responsive',
    fixed: 'Fixed'
}
type Props = {
    playbook: AnyPlaybookType
    hasVideoExport: boolean
}

export type TranscriptResponse = {
    language: string
    playbookId: string
    segments: Array<{
        duration: number
        formattedTime: string // example - '10:00'
        start: number
        stepId: string
        stepIndex: number
        subtitles: Array<{
            duration: number
            start: number
            text: string
        }>
    }>
    version: string
}

type TranscriptType = Array<{ time: string; text: string }>

export const generateTranscript = (data?: TranscriptResponse) => {
    if (!data) return []

    return data.segments.reduce<TranscriptType>((acc, it) => {
        return [
            ...acc,
            ...it.subtitles.map(it => ({
                time: formatTime(it.start),
                text: it.text
            }))
        ]
    }, [])
}

export const ExportTab = ({ playbook, hasVideoExport }: Props) => {
    const { showSuccessNotification } = useNotification()

    const { processFinished, isGifGenerationFinished, isLoading } = useContext(VideoStatusContext)

    // local storage is using for solving next scenario:

    // if user turned off the checkbox in playbook “A”, but then open playbooks
    // “B” or “C” - checkbox will be turned off in every playbook,
    // but if he will enable the checkbox in some pb - it will be active in every pb
    const [withTranscript, setWithTranscript] = useLocalStorage(
        'optimizeQGCheckbox',
        playbook.transcriptUrl ? 1 : 0
    )

    const codeRef = useRef<HTMLDivElement | null>(null)

    const [type, setType] = useState(iframeType.fixed)
    const [width, setWidth] = useState(700)
    const [height, setHeight] = useState(400)

    const transcriptLoading = useBoolean()

    const [transcript, setTranscript] = useState<TranscriptType>([])

    const setTranscriptLoading = transcriptLoading.set
    useEffect(() => {
        if (!playbook.transcriptUrl) return

        const getTranscript = async () => {
            setTranscriptLoading(true)

            const response = await fetch(playbook.transcriptUrl)
            const data: TranscriptResponse = await response.json()

            setTranscriptLoading(false)
            setTranscript(generateTranscript(data))
        }

        getTranscript()
    }, [playbook.transcriptUrl, setTranscriptLoading])

    const handleSizeChange = (event: any, callback: Dispatch<SetStateAction<number>>) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '')
        if (onlyNums.length < 5) callback(onlyNums)
    }

    const isEmbedLocked = !playbook.isMainIncluded && !playbook.isPublic

    const showOptimizeCheckbox = transcriptLoading.isFalse && playbook.transcriptUrl

    return (
        <StyledBox position="relative" overflow="auto">
            <Box width="100%" position="relative">
                {!hasVideoExport && (
                    <NotAvailableMessage
                        style={{ marginTop: '-15px' }}
                        message="Options available on paid plans"
                    />
                )}

                {playbook && (
                    <Download
                        playbook={playbook}
                        playbookId={playbook.id}
                        isGifGenerationFinished={isGifGenerationFinished}
                        disableMP4={!processFinished || isLoading || !hasVideoExport}
                        disableGIF={isLoading || !hasVideoExport}
                    />
                )}
            </Box>

            <Box position="relative">
                {isEmbedLocked && (
                    <NotAvailableMessage
                        style={{ marginTop: '-15px' }}
                        message="Options available for non restricted videos"
                    />
                )}

                <Box mt={3} borderTop="1px solid #e2e2e3" py={2}>
                    <Typography
                        data-test="exportTab-embed-title-text"
                        style={{
                            fontWeight: 700
                        }}
                    >
                        Embed
                    </Typography>

                    <SpacedGroup>
                        <FormControlLabel
                            value={iframeType.responsive}
                            onClick={() => {
                                setType(iframeType.responsive)

                                logToAnalytics('shareModal_exportTab_embedRb_clicked', {
                                    type: 'responsive',
                                    playbookId: playbook.id,
                                    ...playbookToAnalyticsProps(playbook)
                                })
                            }}
                            control={
                                <Radio color="primary" checked={type === iframeType.responsive} />
                            }
                            label={
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: type === iframeType.responsive ? 500 : 400
                                    }}
                                    color={
                                        type === iframeType.responsive ? 'inherit' : 'textSecondary'
                                    }
                                >
                                    {iframeType.responsive}
                                </Typography>
                            }
                        />
                        <SpacedGroup>
                            <FormControlLabel
                                value={iframeType.fixed}
                                onClick={() => {
                                    setType(iframeType.fixed)

                                    logToAnalytics('shareModal_exportTab_embedRb_clicked', {
                                        type: 'fixed',
                                        playbookId: playbook.id,
                                        ...playbookToAnalyticsProps(playbook)
                                    })
                                }}
                                control={
                                    <Radio color="primary" checked={type === iframeType.fixed} />
                                }
                                label={
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: type === iframeType.fixed ? 500 : 400
                                        }}
                                        color={
                                            type === iframeType.fixed ? 'inherit' : 'textSecondary'
                                        }
                                    >
                                        {iframeType.fixed}
                                    </Typography>
                                }
                            />
                            {type === iframeType.fixed && (
                                <SpacedGroup spacing={1}>
                                    <TextField
                                        value={width}
                                        onChange={e => handleSizeChange(e, setWidth)}
                                        variant="filled"
                                        size="small"
                                        inputProps={{
                                            style: inputStyles
                                        }}
                                    />
                                    <Typography style={{ fontSize: '14px' }} color="textSecondary">
                                        —
                                    </Typography>
                                    <TextField
                                        value={height}
                                        onChange={e => handleSizeChange(e, setHeight)}
                                        variant="filled"
                                        size="small"
                                        inputProps={{
                                            style: inputStyles
                                        }}
                                    />
                                    <Typography style={{ fontSize: '14px' }} color="textSecondary">
                                        px
                                    </Typography>
                                </SpacedGroup>
                            )}
                        </SpacedGroup>
                    </SpacedGroup>
                </Box>
                <Box className={classes.code} mb={4}>
                    <div ref={codeRef}>
                        {type === iframeType.responsive
                            ? createResponsiveEmbedCode(playbook)
                            : createNotResponsiveEmbedCode(playbook, { width, height })}
                        <br />
                        {Boolean(withTranscript) && (
                            <>
                                {`<p style="display: none">`}
                                {transcript.map((it, i) => (
                                    <p key={i}>
                                        {it.time}: {it.text}
                                    </p>
                                ))}
                                {`</p>`}
                            </>
                        )}
                    </div>
                </Box>

                <Divider />
                <Box height={4}>{transcriptLoading.isTrue && <LinearProgress />}</Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={showOptimizeCheckbox ? 'space-between' : 'flex-end'}
                    p={2}
                    pb={0}
                    pt={1}
                >
                    {showOptimizeCheckbox && (
                        <FormControlLabel
                            value={Boolean(withTranscript)}
                            onClick={() => {
                                setWithTranscript(withTranscript ? 0 : 1)

                                logToAnalytics('shareModal_exportTab_optimizeChk', {
                                    state: withTranscript ? 'uncheck' : 'check',
                                    playbookId: playbook.id,
                                    ...playbookToAnalyticsProps(playbook)
                                })
                            }}
                            control={
                                <Checkbox
                                    color="primary"
                                    disabled={transcriptLoading.isTrue}
                                    checked={Boolean(withTranscript)}
                                />
                            }
                            label={
                                <SpacedGroup spacing={0.5}>
                                    <Typography
                                        style={{ fontSize: '14px', fontWeight: 500 }}
                                        color="textSecondary"
                                    >
                                        Optimize your video for search
                                    </Typography>
                                    <Tooltip
                                        title="When this option is selected, search engines will index the video based on the transcription"
                                        placement="top"
                                    >
                                        <HelpIcon style={{ width: 16, color: '#BDBDBD' }} />
                                    </Tooltip>
                                </SpacedGroup>
                            }
                        />
                    )}

                    <Button
                        data-test="export-tab-copy-embed-button"
                        onClick={() => {
                            logToAnalytics('shareModal_exportTab_copyBtn_clicked', {
                                playbookId: playbook.id,
                                ...playbookToAnalyticsProps(playbook)
                            })
                            copyToClipboard(codeRef?.current?.innerText).then(() => {
                                showSuccessNotification('Code was copied to the clipboard')
                            })
                        }}
                        variant="contained"
                    >
                        COPY
                    </Button>
                </Box>
            </Box>
        </StyledBox>
    )
}

const inputStyles = {
    paddingTop: '6px',
    width: '40px',
    textAlign: 'center',
    background: '#ffff',
    borderRadius: '4px 4px 0 0',
    paddingX: '',
    overflow: 'hidden',
    height: 15,
    color: 'rgba(9, 12, 16, 0.6)',
    fontSize: 14,
    paddingBottom: '6px'
} as const
