import { type MetricsByDate } from 'app/types/insights'
import { useOrgFeatureFlags } from 'hooks'

import { formatWatchPercent } from 'UI/Components'

import { InsightsAreaChart } from './InsightsAreaChart/InsightsAreaChart'

type Props = {
    isEmpty: boolean
    chartData: Array<MetricsByDate>
    chartHeight: number
    analyticsEventSource: string
    isSmall?: boolean
}

export const InsightsAvgPercentWatchedChart = ({
    isEmpty,
    chartData,
    chartHeight,
    analyticsEventSource,
    isSmall
}: Props) => {
    const { hideInsightsLoadsAndWatchTime } = useOrgFeatureFlags()

    if (hideInsightsLoadsAndWatchTime) return null

    return (
        <InsightsAreaChart
            isEmpty={isEmpty}
            isSmall={isSmall}
            data={chartData}
            title="Avg. % Watched"
            description="The average percentage of video watched by viewers."
            areas={[{ id: 'avgWatched', color: '#E9827F', label: 'Avg. % Watched' }]}
            height={chartHeight}
            valueFormatter={formatWatchPercent}
            analyticsChartHoverEventName="insights_avgPercentageWatchedChart_hover"
            analyticsDescriptionHoverEventName="insights_avgPercentageWatchedTitleTooltip_hover"
            analyticsEventSource={analyticsEventSource}
        />
    )
}
