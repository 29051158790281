import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import {
  GetCustomerPortalByRefIdDocument,
  GetCustomerPortalByRefIdQuery,
  GetCustomerPortalByRefIdQueryVariables,
  GetCustomerByIdDocument,
  GetCustomerByIdQuery,
  GetCustomerByIdQueryVariables,
  GetActiveSubscriptionsQuery,
  GetActiveSubscriptionsQueryVariables,
  GetActiveSubscriptionsDocument,
} from '@stigg/api-client-js/src/generated/sdk';

class CustomersApi {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  getCustomer(customerId: string) {
    return this.client.query<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>({
      query: GetCustomerByIdDocument,
      variables: {
        input: { customerId },
      },
      fetchPolicy: 'no-cache',
    });
  }

  async getActiveSubscriptions(customerId: string, resourceId?: string) {
    return this.client.query<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>({
      query: GetActiveSubscriptionsDocument,
      variables: {
        input: { customerId, resourceId },
      },
      fetchPolicy: 'no-cache',
    });
  }

  getCustomerPortal(customerId: string, resourceId: string | undefined) {
    return this.client.query<GetCustomerPortalByRefIdQuery, GetCustomerPortalByRefIdQueryVariables>({
      query: GetCustomerPortalByRefIdDocument,
      variables: {
        input: {
          customerId,
          resourceId,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }
}

export default CustomersApi;
