import { type ReactNode, memo } from 'react'
import { useSelector } from 'react-redux'

import { Box, TextField, Typography } from '@mui/material'

import { delay } from 'modules'

export const pause = ' /pause/ '

type Props = {
    value: string
    extraText?: ReactNode
    onChange: (_value: string) => void
    onFocus?: () => void
}

export const TranscriptBlock = memo(({ value, extraText, onChange, onFocus }: Props) => {
    const maxLength = useSelector(state => state.configs.audioConfigs.t2s.maxChars)

    const exceedLimit = value.length > maxLength

    return (
        <Box mt={1}>
            <Typography
                variant="body2"
                sx={{
                    color: '#7f8c9a',
                    paddingBottom: '2px',
                    fontSize: '14px'
                }}
            >
                {extraText}
            </Typography>

            <Box
                display="flex"
                sx={{
                    '& *': {
                        color: 'black',
                        fontSize: '14px'
                    }
                }}
            >
                <TextField
                    data-test="t2s-transcript-box"
                    data-intercom="editor-transcript"
                    variant="filled"
                    value={value}
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={20}
                    inputProps={{ maxLength }}
                    InputProps={{
                        disableUnderline: true,
                        slotProps: {
                            root: { sx: { padding: '10px', borderRadius: '4px', fontSize: '14px' } }
                        }
                    }}
                    onChange={e => onChange(e.target.value)}
                    onFocus={() => onFocus?.()}
                    onInput={e => {
                        const inputElement = e.target as HTMLInputElement
                        if (inputElement.value.length >= maxLength) return e.preventDefault()
                    }}
                    onKeyDown={e => {
                        const inputElement = e.target as HTMLInputElement
                        const selectionStart = inputElement.selectionStart as number
                        const selectionEnd = inputElement.selectionEnd as number

                        // Without shiftKey
                        switch (e.key) {
                            case 'Delete':
                            case 'Backspace':
                                if (selectionStart === 0 && selectionEnd === 0) return

                                const pauseStart = value.lastIndexOf(pause, selectionStart)
                                const pauseEnd = pauseStart != -1 ? pauseStart + pause.length : -1

                                if (pauseStart <= selectionStart && pauseEnd >= selectionEnd) {
                                    e.preventDefault()
                                    const beforePause = value.substring(0, pauseStart)
                                    const afterPause = value.substring(pauseEnd)
                                    onChange(beforePause + afterPause)
                                    delay(0).then(() =>
                                        inputElement.setSelectionRange(pauseStart, pauseStart)
                                    )
                                }
                                break
                        }

                        // With shiftKey
                        if (!e.shiftKey) return
                        switch (e.key) {
                            case 'Enter':
                                e.preventDefault()

                                const beforeCursor = value.substring(0, selectionStart)
                                const afterCursor = value.substring(selectionEnd)

                                onChange(beforeCursor + pause + afterCursor)
                                delay(0).then(() => {
                                    const newCursorPosition = selectionStart + pause.length
                                    inputElement.setSelectionRange(
                                        newCursorPosition,
                                        newCursorPosition
                                    )
                                })
                                break
                        }
                    }}
                />
            </Box>

            <Box textAlign="right" pt={0.5}>
                <Typography
                    sx={{
                        paddingRight: '4px',
                        fontSize: '12px',
                        color: '#828282'
                    }}
                >
                    <span style={{ color: exceedLimit ? '#CB0000' : '' }}>{value.length || 0}</span>
                    /{maxLength}
                </Typography>
            </Box>
        </Box>
    )
})
