import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import { SmartPreviewContext } from './SmartPreview'
import { QGContext } from './QgProvider'
import { pause } from 'UI/Routes/quick-guidde/LeftPanel'

export const Subtitles = () => {
    const { steps } = useSelector(state => state.qgEditor.present)

    const { frameWidth, frameHeight } = useContext(QGContext)
    const { time, stepsTimeline, activeIndex } = useContext(SmartPreviewContext)

    const { subtitles } = steps[activeIndex] || {}

    const currentSubtitle = useMemo(() => {
        // Every step audioNote.subtitles starts with 0,
        // so we use stepsTimeline to find beginning of the step which is equal to 0
        return subtitles?.find(subtitle => {
            if (!subtitle.end) return false
            // which has startTime, endTime and transitionDuration
            const actualStart = stepsTimeline.steps[activeIndex].stepStart
            const actualEnd = actualStart + subtitle.end

            return time >= actualStart && time <= actualEnd

            // Old code: keep it here in case we need to revert previous behavior
            // return time <= stepTime.stepStart + stepTime.transitionDuration + subtitle.end
        })
    }, [activeIndex, stepsTimeline.steps, subtitles, time])

    if (!currentSubtitle) return null

    return (
        <Box
            width={frameWidth}
            height={frameHeight}
            sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                pointerEvents: 'none',
                transition: 'opacity 0.1s linear'
            }}
        >
            <Typography
                sx={{
                    fontSize: 18,
                    fontWeight: 'normal',
                    lineHeight: 1.2,
                    width: '100%',
                    position: 'absolute',
                    bottom: 16,
                    borderRadius: 1,
                    pointerEvents: 'none',
                    textAlign: 'center',
                    color: 'white',
                    background: 'rgba(0, 0, 0, 0.8)',
                    padding: '0 8px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}
            >
                {currentSubtitle.text.replace(new RegExp(pause, 'g'), '')}
            </Typography>
        </Box>
    )
}
