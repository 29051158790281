import { useDispatch } from 'react-redux'

import { ReactComponent as ImageStepIcon } from 'assets/icons/image-step.svg'

import { setImageStepArea } from 'ducks'

import { AddStepMenuItem } from './AddStepMenuItem'

type Props = {
    onDone: () => void
}

export const ImageStep = ({ onDone }: Props) => {
    const dispatch = useDispatch()

    return (
        <AddStepMenuItem
            title="Image"
            Icon={ImageStepIcon}
            onClick={() => {
                dispatch(setImageStepArea({ visible: true, placement: 'after' }))
                onDone()
            }}
        />
    )
}
