import { type ReactNode } from 'react'

import { Box } from '@mui/material'

import { sendSoleMessageToExt } from 'modules'

type Props = {
    labelComponent: ReactNode
    className?: string
}
export const VideoRecorder = ({ labelComponent, className = '' }: Props) => (
    <Box
        className={className}
        onClick={() => {
            // Trigger chrome extension
            sendSoleMessageToExt({
                msg: { type: 'WEBAPP/LAUNCH_EXT_RECORDER' }
            })
        }}
        display="flex"
        alignItems="center"
    >
        {labelComponent}
    </Box>
)
