import { Dialog, DialogContent, DialogTitle, Typography, Box } from '@mui/material'
import { GuiddeRoundLoader } from 'UI/Components'

export const PaymentInProcessDialog = () => (
    <Dialog open fullWidth maxWidth="xs">
        <DialogTitle>
            <Box mt={2}>
                <Typography data-test="payment-in-progress-text" variant="subtitle1" align="center">
                    Your payment is in progress
                </Typography>
            </Box>
        </DialogTitle>
        <DialogContent>
            <GuiddeRoundLoader minHeight={200} />
        </DialogContent>
    </Dialog>
)
