import { useRef, useState } from 'react'

import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import { CommonDialog } from 'UI/Components'
import { minMaxValue, TrimSection } from './TrimSection'

import { TimeIndicator } from './TimeIndicator'
import { StyledMaskedInput } from './StyledMaskedInput'

import { isDeepEqual, round } from 'modules'
import { useBoolean } from 'hooks'

type Props = {
    loading?: boolean
    videoInfo: { url: string; duration: number; thumbnail: string }
    initialStart: number
    initialEnd: number
    onClose: () => void
    onConfirm: (start: number, end: number) => void
}

export const TrimDialog = ({
    initialStart,
    initialEnd,
    videoInfo,
    loading,
    onClose,
    onConfirm
}: Props) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    const [currentTime, setCurrentTime] = useState<number>(0)
    const [tempState, setTempState] = useState({
        start: initialStart,
        end: initialEnd
    })

    const loaded = useBoolean()
    const play = useBoolean()

    const { duration, url } = videoInfo
    const { start, end } = tempState

    return (
        <CommonDialog
            maxWidth="md"
            isOpen={true}
            onClose={onClose}
            title="Edit video"
            noPadding
            disabled={loading}
            loading={loading}
            content={
                <>
                    <video
                        preload="auto"
                        ref={videoRef}
                        crossOrigin="anonymous"
                        width="100%"
                        style={{
                            maxHeight: '50vh',
                            background: 'black'
                        }}
                        src={url}
                        onLoadStart={loaded.setFalse}
                        onLoadedData={e => {
                            loaded.setTrue()
                            const target = e.currentTarget as HTMLVideoElement
                            target.currentTime = start
                        }}
                        onTimeUpdate={e => {
                            const target = e.currentTarget
                            setCurrentTime(target.currentTime)
                            if (target.currentTime >= end) target.pause()
                        }}
                        onPause={play.setFalse}
                        onPlay={e => {
                            play.setTrue()

                            const target = e.currentTarget
                            if (target.currentTime >= end) target.currentTime = start
                        }}
                    />
                    <Typography my={2} align="center">
                        <b>Drag to trim the video</b>
                    </Typography>

                    <Divider />

                    <Box ml={2} mr={3} my={3} display="flex" alignItems="center">
                        <Box
                            mr={1}
                            width={40}
                            height={40}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {loaded.isTrue ? (
                                <Box
                                    width={40}
                                    height={40}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="gainsboro"
                                    borderRadius="50%"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        const videoEl = videoRef.current
                                        if (!videoEl) return

                                        // If the video is not ready or corrupted - reload and play
                                        if (videoEl.readyState < 3) {
                                            videoEl.addEventListener('canplay', videoEl.play, {
                                                once: true
                                            })
                                            videoEl.load()
                                            return
                                        }

                                        const isPlaying = !videoEl.paused && !videoEl.ended
                                        videoEl[isPlaying ? 'pause' : 'play']()
                                    }}
                                >
                                    {play.isTrue ? <PauseIcon /> : <PlayArrowIcon />}
                                </Box>
                            ) : (
                                <CircularProgress size={40} />
                            )}
                        </Box>

                        <TrimSection
                            thumbnailUrl={videoInfo.thumbnail}
                            range={[round((start / duration) * 100), round((end / duration) * 100)]}
                            minRangeDuration={round(1 / duration) * 100} // 1s
                            timeIndicator={
                                <TimeIndicator
                                    currentTime={Math.min(currentTime, end)}
                                    duration={duration}
                                />
                            }
                            onClick={percent => {
                                const videoEl = videoRef.current
                                if (!videoEl || videoEl.readyState < 3) return
                                videoEl.currentTime = (duration / 100) * percent
                            }}
                            onChange={(startPercent, endPercent) => {
                                if (!videoRef.current) return
                                const newStart = (duration / 100) * startPercent
                                const newEnd = (duration / 100) * endPercent

                                if (!newStart && !newEnd) return

                                const videoElement = videoRef.current
                                const currentTime = videoElement.currentTime

                                const isLessThanStart = currentTime < newStart
                                if (isLessThanStart) videoElement.currentTime = newStart

                                const isMoreThanEnd = currentTime > newEnd
                                if (isMoreThanEnd) videoElement.currentTime = start

                                // User moved the slider
                                const newState = { start: newStart, end: newEnd }
                                if (!isDeepEqual(newState, tempState)) setTempState(newState)
                            }}
                        />
                    </Box>

                    <Divider />

                    <Box mx={3} mt={3.5} display="flex" gap="32px" position="absolute">
                        <StyledMaskedInput
                            label="Start at:"
                            value={start}
                            onChange={newStart => {
                                const protectedStart = minMaxValue(newStart, 0, end - 1)
                                setTempState({ start: protectedStart, end })
                                videoRef.current!.currentTime = protectedStart
                            }}
                            onReset={() => {
                                if (start === 0) return
                                setTempState({ start: 0, end })
                                videoRef.current!.currentTime = 0
                            }}
                        />
                        <StyledMaskedInput
                            label="End at:"
                            value={end}
                            onChange={newEnd => {
                                const protectedEnd = minMaxValue(newEnd, start + 1, duration)
                                setTempState({ start, end: protectedEnd })
                                videoRef.current!.currentTime = protectedEnd
                            }}
                            onReset={() => {
                                if (end === duration) return
                                setTempState({ start, end: duration })
                                videoRef.current!.currentTime = duration
                            }}
                        />
                    </Box>
                </>
            }
            hideCloseBtn
            justifyActions="flex-end"
            confirmBtnText="SAVE VIDEO"
            onConfirm={() => onConfirm(tempState.start, tempState.end)}
        />
    )
}
