import * as types from 'ducks/types'

export const setExtensionStatus = (isInstalled: boolean) =>
    ({
        type: types.SET_EXTENSION_STATUS,
        payload: isInstalled
    }) as const

export const setQuickGuiddeRecordingStatus = (payload: boolean) =>
    ({
        type: types.SET_QUICK_GUIDDE_RECORDING_STATUS,
        payload
    }) as const
