import { useMemo } from 'react'

import { useQuery } from 'hooks'

export type PublicBrandKitType = {
    isEnterprise: boolean // values that shows creator org plan
    color: string // hex value of default org color
    logoAndFavicon: string // url of active org's logo
    brandKitHighlightColor: string
    brandKitBackground: string
}

export const usePublicBrandKit = (orgId?: string) => {
    const $brandKit = useQuery<PublicBrandKitType>(
        orgId ? '/c/v1/brand-kit/get-public' : '',
        {
            method: 'POST',
            body: {
                orgId
            }
        },
        {
            refreshWhenHidden: false,
            revalidateOnFocus: false,
            revalidateIfStale: false
        }
    )

    const { data, isValidating } = $brandKit

    return useMemo(
        () => ({
            isEnterprise: Boolean(data?.isEnterprise),
            color: data?.color || '',
            brandKitHighlightColor: data?.brandKitHighlightColor || '',
            logoAndFavicon: data?.logoAndFavicon || '',
            brandKitBackground: data?.brandKitBackground || '',
            brandKitLoading: isValidating
        }),
        [data, isValidating]
    )
}
