import {
    type ReactNode,
    createContext,
    memo,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { useHistory } from 'react-router-dom'

import { VideoStatusContext } from 'UI/Components'

import { delay, filterURLParams, round } from 'modules'
import { type QuickGuiddeType } from 'app/types'
import { useBoolean, useQueryParams } from 'hooks'

export enum StepsStatus {
    idle = 'idle',
    generating = 'generating',
    typing = 'typing',
    done = 'done',
    skipped = 'skipped',
    retry = 'retry'
}

const initialState = {
    stepsStatus: StepsStatus.idle,
    stepsProgress: {} as Record<number, number>,
    stepsProcessing: {} as ReturnType<typeof useBoolean>
}

export const StepsContext = createContext(initialState)

type Props = {
    children: ReactNode
    playbook: QuickGuiddeType
}

export const StepsProvider = memo(({ playbook, children }: Props) => {
    const { services, revalidatePlaybook } = useContext(VideoStatusContext)

    const params = useQueryParams()

    const history = useHistory()
    const historyReplace = history.replace
    const location = history.location

    const { showAiDialog, steps } = playbook

    const stepsProcessing = useBoolean()

    const [stepsStatus, setStepsStatus] = useState(StepsStatus.idle)
    const [stepsProgress, setStepsProgress] = useState<(typeof initialState)['stepsProgress']>({})

    // Set step status based on status of video service (VideoStatusProvider)
    // to show animation of typing (MagicDescription.tsx) when we use openAI to generate step description (note)
    useEffect(() => {
        if (Object.keys(services).length === 0) return

        // If user closed popup - skip all steps and do not show popup anymore
        if (!showAiDialog) {
            setStepsStatus(StepsStatus.skipped)
            return
        }

        // Otherwise, check if we need to show typing animation
        if (stepsStatus === StepsStatus.idle) {
            setStepsStatus(params.get('postRecording') ? StepsStatus.generating : StepsStatus.done)
            // Clean up `postRecording` query parameter from URL once page is loaded
            historyReplace(filterURLParams(location, ['postRecording']))
        }

        switch (services.qg_enrich) {
            case 'in_progress':
            case 'pending':
                break
            case 'done':
            case 'error':
                if (stepsStatus === StepsStatus.generating) {
                    revalidatePlaybook().then(() => setStepsStatus(StepsStatus.typing))
                    break
                }
                break
            case 'retry':
            case 'too_many_untitled':
                if (stepsStatus !== StepsStatus.retry) setStepsStatus(StepsStatus.retry)
                break
            default: // undefined, skipped, error
                if (stepsStatus !== StepsStatus.skipped) setStepsStatus(StepsStatus.skipped)
                break
        }
    }, [historyReplace, location, params, revalidatePlaybook, services, showAiDialog, stepsStatus])

    useEffect(() => {
        // We use only these 2 statuses to show typing animation
        if (![StepsStatus.generating, StepsStatus.typing].includes(stepsStatus)) return

        const values = Object.values(stepsProgress)

        // Until we start typing animation - this useEffect should be called only once to fill stepsProgress with 0
        if (values.length > 0 && stepsStatus !== StepsStatus.typing) return

        // Once every step inside stepsProgress prop has progress 100% - finish typing animation
        if (values.length > 0 && values.every(v => v === 100)) {
            delay(2000).then(() => setStepsStatus(StepsStatus.done))
            return
        }

        delay(50).then(() => {
            const nextProgress = steps
                .filter(({ kind }) => !['intro', 'outro'].includes(kind))
                .reduce((acc, curr, idx) => {
                    if (values[idx] === 100) return { ...acc, [idx]: 100 }

                    // Introduction step doesn't have voiceover, so we show 100% right away
                    if (curr.recordingEvent) return { ...acc, [idx]: 100 }

                    const text = curr.audioNote?.text || ''
                    if (!text || values[idx] === undefined) return { ...acc, [idx]: 0 }

                    const charPercent = round(100 / text.length)
                    return {
                        ...acc,
                        [idx]: Math.min(100, round((values[idx] || 0) + charPercent))
                    }
                }, {})
            setStepsProgress(nextProgress)
        })
    }, [steps, stepsProgress, stepsStatus])

    const contextValue = useMemo(
        () => ({
            stepsStatus,
            stepsProgress,
            stepsProcessing
        }),
        [stepsStatus, stepsProgress, stepsProcessing]
    )

    return <StepsContext.Provider value={contextValue}>{children}</StepsContext.Provider>
})
