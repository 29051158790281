import { useEffect } from 'react'

import { useBoolean } from 'hooks'
import { delay } from 'modules'

type Props = {
    delayTime?: number
    children: JSX.Element
}

export const DelayedRender = ({ delayTime = 500, children }: Props) => {
    const load = useBoolean(false)

    const isLoaded = load.isTrue
    const setLoaded = load.setTrue

    useEffect(() => {
        if (isLoaded) return

        delay(delayTime).then(setLoaded)
    }, [delayTime, isLoaded, setLoaded])

    return <>{isLoaded ? children : null}</>
}
