import { useAuth, useDataMutation, useQueryParams } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

export const useWorkspaceAutoswitcher = () => {
    const params = useQueryParams()
    const activeWorkspaceId = params.get('activeWorkspaceId') || ''
    const history = useHistory()
    const { orgId, isLoading: isAuthLoading } = useAuth()

    const historyReplace = history.replace

    const $switchWorkspace = useDataMutation<void, {}, Error>(
        `/c/v1/workspaces/${activeWorkspaceId}`,
        'PUT'
    )

    const switchWorkspaceMutate = $switchWorkspace.mutate

    const clearUrlFromTheParams = useCallback(() => {
        params.delete('activeWorkspaceId')

        historyReplace({
            search: params.toString()
        })
    }, [historyReplace, params])

    useEffect(() => {
        if (orgId === activeWorkspaceId) return clearUrlFromTheParams()

        if (!activeWorkspaceId || $switchWorkspace.isLoading || isAuthLoading || !orgId) return

        switchWorkspaceMutate().finally(clearUrlFromTheParams)
    }, [
        switchWorkspaceMutate,
        activeWorkspaceId,
        clearUrlFromTheParams,
        historyReplace,
        isAuthLoading,
        orgId,
        params,
        $switchWorkspace.isLoading
    ])

    return useMemo(
        () => ({
            showPageLoader:
                orgId &&
                (activeWorkspaceId || $switchWorkspace.isLoading) &&
                orgId !== activeWorkspaceId
        }),
        [$switchWorkspace.isLoading, activeWorkspaceId, orgId]
    )
}
