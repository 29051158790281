import { type CSSProperties, useState } from 'react'

import { Box } from '@mui/material'

import { Label } from 'UI/Components'
import { Tags } from './Tags'

import { logToAnalytics } from 'modules'
import { useDataMutation, useNotification } from 'hooks'

import { type PlaybookModeType } from 'app/types'

type Props = {
    playbookId: string
    values: Array<string>
    width?: CSSProperties['width']
    mode: PlaybookModeType
    onChange?: (newTags: Array<string>, toDeletedTags: Array<string>) => void
    onFocus?: () => void
    onBlur?: () => void
}

export const EditTags = ({ playbookId, mode, values, width, onChange, onFocus, onBlur }: Props) => {
    const { showSuccessNotification } = useNotification()
    const [deletedTags, setDeletedTags] = useState<Array<string>>([])

    const $updateTags = useDataMutation('/c/v1/playbook/update', 'PATCH', {
        onSuccess: () => showSuccessNotification('Tags updated successfully')
    })

    const handleDeleteTag = (index: number) => {
        const newTags = [...values]
        const toDeletedTags = newTags.splice(index, 1)

        // in QG we save only with the save button, so we need a list
        // of all the deleted items and not just a single one
        setDeletedTags(prevState => [...prevState, toDeletedTags[0]])

        if (onChange) {
            onChange(newTags, [...deletedTags, toDeletedTags[0]])
            return
        }

        $updateTags
            .mutate({
                playbookId,
                tags: newTags
            })
            .then(() => {
                logToAnalytics('delete_tag', {
                    'pb-tags': toDeletedTags,
                    playbookType: mode
                })
            })
    }

    const handleSaveTag = (tag: string) => {
        if (onChange) {
            onChange([...values, tag], [])
            return
        }

        $updateTags
            .mutate({
                playbookId,
                tags: [...values, tag]
            })
            .then(() => {
                logToAnalytics('update_tags', {
                    'pb-tags': [...values, tag],
                    playbookType: mode
                })
            })
    }

    return (
        <Box width="100%">
            <Label label="Tags" />

            <Tags
                onFocus={onFocus}
                onBlur={onBlur}
                onDeleteTag={handleDeleteTag}
                onSaveTag={handleSaveTag}
                tags={values?.filter(Boolean)}
                disabled={false}
                width={width}
            />
        </Box>
    )
}
