import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, LinearProgress, Slider, Typography } from '@mui/material'

import { SpacedGroup } from 'UI/Components'
import { TransitionCard } from './TransitionCard'
import { TransitionDirection } from './TransitionDirection'

import { setStepTransition } from 'ducks'
import { type StepType } from 'app/types'
import { useQuery, useTextDebounce } from 'hooks'
import { logToAnalytics } from 'modules'

type CodeType = {
    glsl: string
}

export type RTDBTransitionType = {
    name: string
    image: string
    label: string
} & (
    | {
          direction: {
              left: CodeType
              right: CodeType
              up: CodeType
              down: CodeType
          }
      }
    | CodeType
)

type Props = {
    transition: StepType['transition'] | null
    motionToAll: boolean
}

export const TransitionsList = ({ transition, motionToAll }: Props) => {
    const dispatch = useDispatch()

    const { data, isValidating } = useQuery<Array<RTDBTransitionType>>(
        '/c/v1/config/qg/transitions',
        { method: 'GET' },
        {
            revalidateIfStale: false,
            dedupingInterval: 0
        }
    )

    const [duration, setDuration] = useState(transition?.duration || 1)
    const defValue = useTextDebounce(duration)

    const activeElement = useMemo(() => {
        if (!data) return -1
        return data.findIndex(option => option.name === transition?.name)
    }, [transition, data])

    const activeRow = useMemo(() => {
        if (activeElement === -1) return -1
        return Math.ceil((activeElement + 1) / 3)
    }, [activeElement])

    const activeColumn = useMemo(() => {
        if (activeElement === -1) return 'none'
        return (activeElement % 3) + 1
    }, [activeElement])

    useEffect(() => {
        if (!transition?.duration) return
        setDuration(transition.duration)
    }, [transition?.duration])

    useEffect(() => {
        if (!transition || !defValue || defValue === transition?.duration) return
        dispatch(setStepTransition({ ...transition, duration: defValue }))
        // In order to prevent unnecessary re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defValue, dispatch])

    if (!data && isValidating) return <LinearProgress />
    if (!data) return null

    return (
        <Box
            mt={2}
            pb={2}
            gap={2}
            maxHeight="73vh"
            display="grid"
            gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        >
            {data.map((option, idx) => {
                const isNone = option.name === 'none'
                const isActive = transition?.name === option.name || (!transition && isNone)

                return (
                    <TransitionCard
                        key={idx}
                        isActive={isActive}
                        label={option.label}
                        image={option.image}
                        onClick={() => {
                            window.showTransition()

                            const direction = 'direction' in option ? ('right' as const) : undefined

                            const newTransition = {
                                name: option.name,
                                duration,
                                ...(direction ? { direction } : {})
                            }

                            dispatch(setStepTransition(isNone ? null : newTransition, motionToAll))

                            if (!isActive) {
                                logToAnalytics('selectedTransition', {
                                    transition: newTransition,
                                    applyToAll: motionToAll,
                                    action: 'change transition'
                                })
                            }
                        }}
                    />
                )
            })}

            {transition && transition?.name !== 'none' && (
                <Box
                    style={{
                        // Place the box below the active row
                        gridRow: `${activeRow + 1} / auto`,
                        gridColumn: '1 / -1'
                    }}
                >
                    <Box position="relative" display="grid" gridTemplateColumns="repeat(3, 1fr)">
                        <Box
                            position="absolute"
                            top={-10}
                            left={0}
                            right={0}
                            width={0}
                            height={0}
                            margin="auto"
                            gridColumn={`${activeColumn} / ${activeColumn}`}
                            style={{
                                borderWidth: '0 10px 10px',
                                borderStyle: 'solid',
                                borderColor: 'transparent transparent #f4f4f6'
                            }}
                        />
                    </Box>

                    <SpacedGroup
                        spacing={1}
                        flexDirection="column"
                        bgcolor="#f4f4f6"
                        px={2}
                        py={1}
                        pt={1.5}
                        mx={-2}
                        overflow="hidden"
                    >
                        <TransitionDirection transition={transition} motionToAll={motionToAll} />

                        <Box>
                            <Typography>Speed (seconds)</Typography>
                            <Slider
                                size="small"
                                min={0.6}
                                max={3}
                                step={0.2}
                                value={duration}
                                valueLabelDisplay="auto"
                                onChange={(_, value) => setDuration(value as number)}
                            />
                        </Box>
                    </SpacedGroup>
                </Box>
            )}
        </Box>
    )
}
