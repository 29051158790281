export const firebaseConfig = {
    "projectId": "guidde-production",
    "appId": "1:484452355157:web:2c5a764b5d360dbef6d8dc",
    "databaseURL": "https://guidde-production-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-production.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyATarjLOqtsa2O2VJqtnv81Dje5REI2TYc",
    "authDomain": "app.guidde.com",
    "messagingSenderId": "484452355157",
    "environment": "production"
}

export const stripeKey =
'pk_live_51Iwi5dCWJDAdG9MOvpptwEf9yJtku5u6TSB7j7dAacOfdWG8nmqLDqJ9o9oFDrQSZFdzrZLiwmpxJLl4N5hkbD0X00kbzR2jrw'
export const stiggClientKey =
'$2b$10$vz2LfYEcVSchWKD8ZNlm1.zYHWPEdvjoWEHbKD3S9gWmT0yS80hou:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const facebookAppId = '3155065591456766'
export const celloConfig = {
    script: 'https://assets.cello.so/app/latest/cello.js',
    attributionScript: 'https://assets.cello.so/attribution/latest/cello-attribution.js',
    productId: 'app.guidde.com'
}

export const STIGG_CLIENT_KEY =
'$2b$10$vz2LfYEcVSchWKD8ZNlm1.zYHWPEdvjoWEHbKD3S9gWmT0yS80hou:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'38971-9bf427'