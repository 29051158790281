import clsx from 'clsx'

import { styled, Box, List, IconButton } from '@mui/material'

import { InfoListItem } from './InfoListItem'
import { PlaybookFileUploader } from './PlaybookFileUploader'

import { type PlaybookType } from 'app/types'

import { Link as LinkIcon } from '@mui/icons-material'

const PREFIX = 'InfoList'

const classes = {
    root: `${PREFIX}-root`,
    rootViewMode: `${PREFIX}-rootViewMode`,
    roowEditMode: `${PREFIX}-roowEditMode`,
    public: `${PREFIX}-public`,
    listSection: `${PREFIX}-listSection`,
    editMode: `${PREFIX}-editMode`,
    viewMode: `${PREFIX}-viewMode`,
    ul: `${PREFIX}-ul`,
    linkBtn: `${PREFIX}-linkBtn`,
    emptyListIcon: `${PREFIX}-emptyListIcon`
}

const StyledList = styled(List)(({ theme }) => ({
    [`&.${classes.root}`]: {
        position: 'relative',
        display: 'flex',
        fontSize: '12px'
    },

    [`& .${classes.rootViewMode}`]: {
        flexDirection: 'row'
    },

    [`&.${classes.roowEditMode}`]: {
        flexDirection: 'column'
    },

    [`&.${classes.public}`]: {
        top: 0,
        right: 0
    },

    [`& .${classes.listSection}`]: {
        display: 'flex',
        padding: 0,
        marginTop: '5px',
        marginBottom: '5px',
        marginLeft: theme.spacing(-0.5),
        alignItems: 'start'
    },

    [`& .${classes.editMode}`]: {
        padding: 0,
        flexDirection: 'column'
    },

    [`& .${classes.viewMode}`]: {
        padding: '0px',
        flexDirection: 'row'
    },

    [`& .${classes.ul}`]: {
        display: 'flex',
        gap: 8,
        marginRight: theme.spacing(2)
    },

    [`& .${classes.linkBtn}`]: {
        marginRight: theme.spacing(1),
        color: '#2D9CDB',
        padding: '2px 0 0 0'
    },

    [`& .${classes.emptyListIcon}`]: {
        color: '#2D9CDB !important'
    }
}))

type OpenType = '_blank' | '_self'

type Props = {
    /**
     * Playbook object
     */
    playbook: PlaybookType
    /**
     * Whether the files array is empty
     */
    isFilesEmpty?: boolean
    /**
     * Whether the linksarray is empty
     */
    isLinksEmpty?: boolean
    /**
     * Whether we are in an edit mode
     */
    isEditMode: boolean
    /**
     * Whether the playbook is in public mode or not
     */
    isPublicMode: boolean
    /**
     * Open link
     */
    openLink: (link: string, type: OpenType) => void
    /**
     * Open Add Link Modal
     */
    openModal?: () => void
    /**
     * Handle removing of an item
     */
    onDelete?: (itemId: string, isLink?: boolean) => void
}

export const InfoList = ({
    playbook,
    isEditMode,
    isLinksEmpty,
    isFilesEmpty,
    isPublicMode,
    openLink,
    openModal,
    onDelete
}: Props) => {
    const { links, files } = playbook

    if (isLinksEmpty && !isEditMode) return null
    return (
        <StyledList
            className={clsx(classes.root, {
                [classes.public]: isPublicMode,
                [classes.roowEditMode]: isEditMode
            })}
            subheader={<li />}
        >
            <li className={clsx(classes.listSection)}>
                {isEditMode && (
                    <IconButton
                        className={clsx(classes.linkBtn)}
                        onClick={isEditMode ? openModal : undefined}
                        data-test="add-link-button"
                        size="large"
                    >
                        <LinkIcon fontSize="small" />
                    </IconButton>
                )}
                {!isLinksEmpty && (
                    <ul
                        className={clsx(
                            classes.ul,
                            isEditMode ? classes.editMode : classes.viewMode
                        )}
                    >
                        {links?.map(item => (
                            <Box key={item.id}>
                                <InfoListItem
                                    isEditMode={isEditMode}
                                    item={item}
                                    onDelete={onDelete}
                                    isLink={true}
                                    openLink={openLink}
                                />
                            </Box>
                        ))}
                    </ul>
                )}
            </li>

            <li className={clsx(classes.listSection)}>
                <PlaybookFileUploader playbook={playbook} />

                {!isFilesEmpty && (
                    <ul
                        className={clsx(
                            classes.ul,
                            isEditMode ? classes.editMode : classes.viewMode
                        )}
                    >
                        {files?.map(item => (
                            <Box key={item.id}>
                                <InfoListItem
                                    isEditMode={isEditMode}
                                    item={item}
                                    onDelete={onDelete}
                                    isLink={false}
                                    openLink={openLink}
                                />
                            </Box>
                        ))}
                    </ul>
                )}
            </li>
        </StyledList>
    )
}
