import { type ReactNode, useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material'

import { TabPanel } from './TabPanel'

type Props = {
    keepInDOM?: boolean // whether we need to keep inactive tab rendered in the DOM
    loadDelay?: number
    tabs: Array<{
        label: ReactNode
        icon?: any
        component: ReactNode
    }>
    value?: number
    disabled?: boolean
    px?: number
    py?: number
    onChange?: (newValue: number) => void
}

export const CustomTabs = ({
    value = 0,
    tabs,
    onChange,
    keepInDOM,
    loadDelay,
    disabled,
    px,
    py
}: Props) => {
    const [tabValue, setTabValue] = useState(value)

    useEffect(() => {
        if (typeof value !== 'number') return
        setTabValue(value)
    }, [value])

    return (
        <>
            <Tabs
                value={tabValue}
                indicatorColor="primary"
                onChange={(_e: any, value: number) => {
                    setTabValue(value)
                    onChange?.(value)
                }}
                variant="fullWidth"
                centered
                sx={{
                    height: '100%',

                    '& .MuiTabs-flexContainer': {
                        height: '100%'
                    }
                }}
            >
                {tabs.map((tab, i) => (
                    <Tab
                        data-test={`editor-${tab?.label?.toString().replaceAll(' ', '')}-${i}`}
                        key={i}
                        label={tab.label}
                        icon={tab.icon}
                        disabled={disabled}
                        wrapped
                        sx={theme => ({
                            minWidth: '50px',
                            whiteSpace: 'nowrap',
                            fontWeight: 500,
                            color: 'rgba(9, 12, 16, 0.6)',
                            lineHeight: '16px',
                            letterSpacing: '0.4px',
                            [theme.breakpoints.up('lg')]: {
                                fontSize: '13px'
                            },
                            [theme.breakpoints.down('md')]: {
                                fontSize: '12px'
                            },
                            '&.MuiTab-root': {
                                minHeight: '25px',
                                padding: '4px',
                                display: 'flex',
                                flex: 1,
                                width: '100%'
                            },
                            '&.MuiTab-wrapped': {
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: '4px',
                                minHeight: '25px',
                                background: 'transparent',
                                width: '50%',
                                '& svg': {
                                    minWidth: '25px',
                                    marginBottom: '0 !important',
                                    marginRight: 0.5,
                                    color: '#828282'
                                }
                            },
                            '&.Mui-selected': {
                                '& svg': {
                                    color: 'primary.main'
                                }
                            }
                        })}
                    />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <TabPanel
                    key={index}
                    value={tabValue}
                    index={index}
                    keepInDOM={keepInDOM}
                    loadDelay={loadDelay}
                    px={px}
                    py={py}
                >
                    {tab.component}
                </TabPanel>
            ))}
        </>
    )
}
