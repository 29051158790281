import { memo } from 'react'

import { type BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'

import dayjs from 'dayjs'
import { styled, Button, Typography, Box } from '@mui/material'

import CheckIcon from '@mui/icons-material/CheckCircle'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'

import { ReactComponent as RocketIcon } from 'assets/icons/rocket.svg'

import { SpacedGroup } from 'UI/Components'

import { type PlanIdType, type MappedPlanType, planTypes, useServiceUsage, useStigg } from 'hooks'

import { getPriceLabel } from './helpers'

const Container = styled(Box)(({ theme }) => ({
    color: '#14192B',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '6px',
    padding: theme.spacing(5, 3, 3),
    height: '100%',
    boxShadow: '0px 2px 23px #D7D7DF',
    overflow: 'hidden'
}))

const Recommended = styled(Box)({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    color: '#fff',
    padding: 5,
    display: 'flex',
    justifyContent: 'center'
})

const CtaSubtitle = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    paddingTop: theme.spacing(0.5),
    color: '#78909c',
    fontSize: '12px'
}))

type Props = {
    plan: MappedPlanType
    selectedPeriod: BillingPeriod
    planId: PlanIdType
    onPlanSelect?: (planId: PlanIdType) => void
    planNames: Array<string>
    hideTrialDate?: boolean
}
export const PlanItem = memo(
    ({ plan, selectedPeriod, planNames, planId, hideTrialDate, onPlanSelect }: Props) => {
        const { bucket } = useStigg()

        const { activePlanId, upcomingPlan, isTrialPlan, isFreePlan } = useServiceUsage()

        const generateFeaturesTitle = (text?: string) => {
            if (!text) return ''

            const words = text.split(' ')
            const planNameIdx = words.findIndex(it =>
                planNames.some(planName => {
                    // remove all special symbols from string
                    return planName === it.replace(/[^a-zA-Z ]/g, '')
                })
            )

            if (planNameIdx > 0) {
                words[planNameIdx] = `<b>${words[planNameIdx]}</b>`
            }

            return words.join(' ')
        }

        const { pricePoints, metadata, planDescription, planName } = plan
        const { priceLabel } = getPriceLabel(
            pricePoints,
            Boolean(metadata.isCustomPrice),
            selectedPeriod
        )
        const { isRecommended } = metadata

        const upcomingPlanDate =
            upcomingPlan && dayjs((upcomingPlan?.startsAt).toDate()).format('DD MMM YYYY')

        let planCtaText = metadata.actionTitle

        if (activePlanId === planId) {
            if (isTrialPlan) {
                planCtaText = 'UPGRADE'
            } else {
                planCtaText = 'Current Plan'
            }
        }

        const isBusinessInReverse = isRecommended && isTrialPlan

        if (planId === planTypes.individual) {
            if (
                upcomingPlan?.planId === planTypes.individual ||
                activePlanId === planTypes.individual
            ) {
                planCtaText = 'Current Plan'
            } else {
                planCtaText = 'BACK TO FREE'
            }

            if (isBusinessInReverse || hideTrialDate) {
                if (isFreePlan) {
                    planCtaText = 'Current Plan'
                } else {
                    planCtaText = 'BACK TO FREE'
                }
            }
        }

        const planColor = metadata.color
        const isPlanDisabled = activePlanId === planId && !isTrialPlan

        const businessBtnInTrialCta =
            upcomingPlan && dayjs(upcomingPlan.startsAt.seconds * 1000).diff(dayjs(), 'days') + 1

        return (
            <Container
                style={{
                    background: isRecommended ? '#F5F9FF' : 'transparent',
                    paddingTop: isRecommended ? 62 : 40
                }}
            >
                {isRecommended ? (
                    <Recommended style={{ background: planColor }}>
                        <SpacedGroup spacing={0.5}>
                            <Typography
                                data-test="planItem-popular-option-text"
                                variant="caption"
                                style={{
                                    fontWeight: 700,
                                    color: 'inherit'
                                }}
                            >
                                Popular option
                            </Typography>
                            <RocketIcon color="inherit" />
                        </SpacedGroup>
                    </Recommended>
                ) : (
                    <Box
                        height={8}
                        bgcolor="#14192B"
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                    />
                )}

                <Typography
                    data-test="planItem-plan-title"
                    sx={{
                        fontSize: 22,
                        fontWeight: 600,
                        textAlign: 'center'
                    }}
                >
                    {planName}
                </Typography>

                <Box
                    minHeight="80px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    textAlign="center"
                    mt={1}
                >
                    <Typography data-test="planItem-plan-description" style={{ fontSize: 11 }}>
                        {planDescription}
                    </Typography>

                    <Box
                        data-test="planItem-plan-price"
                        minHeight="72px"
                        sx={{
                            fontSize: '22px',
                            minHeight: '30px',
                            fontWeight: 600,
                            textAlign: 'center'
                        }}
                    >
                        {priceLabel}
                    </Box>
                </Box>
                <Box position="relative" width="100%" textAlign="center" mt={onPlanSelect ? 4 : 0}>
                    {onPlanSelect && (
                        <>
                            {isBusinessInReverse ? (
                                <Button
                                    fullWidth
                                    sx={theme => ({
                                        fontSize: '13px',
                                        height: '50px',
                                        padding: theme.spacing(1.3, 1.5),
                                        borderRadius: '6px',
                                        background: '#FED243',
                                        color: '#14192B',
                                        boxShadow: 'none',
                                        border: 'none',
                                        '&:hover': {
                                            border: 'none',
                                            color: '#14192B',
                                            background: '#FED243'
                                        }
                                    })}
                                    size="large"
                                    variant={isRecommended ? 'contained' : 'outlined'}
                                    disableRipple
                                    onClick={() => onPlanSelect(planId)}
                                >
                                    <Box>
                                        <Typography
                                            data-test="planItem-plan-cta-text"
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 13
                                            }}
                                        >
                                            {planCtaText}
                                        </Typography>
                                        {Boolean(businessBtnInTrialCta) && (
                                            <Typography
                                                data-test="planItem-plan-trial-text"
                                                display="block"
                                                style={{
                                                    color: 'rgba(9, 12, 16, 0.6)',
                                                    fontSize: 10
                                                }}
                                            >
                                                {businessBtnInTrialCta} days left
                                            </Typography>
                                        )}
                                    </Box>
                                </Button>
                            ) : isPlanDisabled ? (
                                <Box
                                    minHeight={40}
                                    sx={theme => ({
                                        background: '#F5F5F5',
                                        borderRadius: '6px',
                                        height: 50,
                                        padding: theme.spacing(1.5, 1.2)
                                    })}
                                >
                                    <SpacedGroup spacing={1}>
                                        <CheckIcon style={{ color: '#73CC6D' }} />
                                        <Typography
                                            data-test="planItem-current-plan-text"
                                            style={{ fontSize: 10 }}
                                        >
                                            {planCtaText}
                                        </Typography>
                                    </SpacedGroup>
                                </Box>
                            ) : (
                                <Button
                                    data-test="planItem-cta-button"
                                    fullWidth
                                    sx={theme => ({
                                        fontSize: 13,
                                        height: 50,
                                        padding: theme.spacing(1.3, 1.5),
                                        borderRadius: '6px',
                                        ...(!isRecommended && {
                                            background: 'transparent',
                                            color: '#14192B',
                                            border: '1px solid #14192B',
                                            '&:hover': {
                                                background: 'rgba(20, 25, 43, 0.1)',
                                                border: '1px solid #14192B'
                                            }
                                        })
                                    })}
                                    variant={isRecommended ? 'contained' : 'outlined'}
                                    disableRipple
                                    onClick={() => onPlanSelect(planId)}
                                    disabled={isPlanDisabled}
                                >
                                    {planCtaText}
                                </Button>
                            )}
                        </>
                    )}

                    {upcomingPlan && !hideTrialDate && (
                        <Box minHeight="22px">
                            {upcomingPlan?.planId === planId && (
                                <CtaSubtitle>Starts after {upcomingPlanDate}</CtaSubtitle>
                            )}

                            {activePlanId === planId && (
                                <CtaSubtitle>
                                    {isTrialPlan ? 'In trial until ' : 'Until '}
                                    {upcomingPlanDate}
                                </CtaSubtitle>
                            )}
                        </Box>
                    )}
                </Box>

                {onPlanSelect && <Box mt={4} />}

                {metadata.features && (
                    <Box width="100%">
                        <Typography
                            sx={{ fontSize: 11 }}
                            dangerouslySetInnerHTML={{
                                __html: generateFeaturesTitle(metadata.includesTitle)
                            }}
                        />

                        <Box mt={2}>
                            {metadata.features.map((item, i) => (
                                <SpacedGroup spacing={1} key={i} mt={1}>
                                    <CheckIcon
                                        style={{
                                            color: isRecommended ? '#7EB0FC' : '#ACB4BD'
                                        }}
                                    />
                                    <Typography style={{ fontSize: 10 }}>{item}</Typography>
                                </SpacedGroup>
                            ))}
                        </Box>
                        {metadata.minUnitQuantity &&
                            Number(metadata.minUnitQuantity[bucket.period]) > 1 && (
                                <SpacedGroup spacing={1} mt={1}>
                                    <AccountCircleOutlinedIcon
                                        style={{
                                            color: isRecommended ? '#7EB0FC' : '#ACB4BD'
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            fontSize: 10,
                                            color: '#ACB4BD'
                                        }}
                                    >
                                        Minimum {metadata.minUnitQuantity[bucket.period]} users
                                    </Typography>
                                </SpacedGroup>
                            )}
                    </Box>
                )}
            </Container>
        )
    }
)
