import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, ClickAwayListener, Stack, Typography, styled } from '@mui/material'

import { ReactComponent as DeleteIcon } from 'assets/icons/white_bin.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit_white.svg'
import { ReactComponent as SKIP } from 'assets/icons/skip.svg'
import { ReactComponent as ExternalLink } from 'assets/icons/open_in_new.svg'

import { useBoolean, useServiceUsage } from 'hooks'

import { QGContext } from 'UI/Routes/quick-guidde/CanvasEditor/QgProvider'
import { SpacedGroup } from 'UI/Components'

import { AddAction } from './AddAction'
import { CTAToolbarButtons } from './CTAToolbarButtons'
import { PoweredBy } from './PoweredBy'
import { MultipleCtaTitle } from './MultipleCtaTitle'

import { type SingleCtaType } from 'app/types'

import {
    disableShortcuts,
    enableShortcuts,
    setCurrentCTA,
    setQuickGuiddeStepCTA,
    setStepCtaAction,
    setStepCtaVisibility,
    setStepMultiApplyVisibility,
    setStepMultiCtaVisibility
} from 'ducks'

const Wrapper = styled(Box)({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(3px)'
})

const Container = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: '12px'
})

const ControlButton = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(9, 6, 12, 0.8)',
    width: '24px',
    height: '24px',
    borderRadius: '4px'
})

export const DEFAULT_TEXT = 'Edit this action item'
export const DEFAULT_HEADER = 'How would you like to continue?'

export const DEFAULT_ACTION: SingleCtaType['action'] = {
    enabled: true,
    text: DEFAULT_TEXT,
    linkType: 'goToStep',
    link: '',
    image: '',
    start: 0,
    mode: 'playbook'
}

export const MultipleCTA = () => {
    const dispatch = useDispatch()

    const ctaVisibility = useBoolean()

    const { isFreePlan } = useServiceUsage()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { frameWidth, frameHeight } = useContext(QGContext)

    const { cta: originalData } = steps[activeStep]

    const hasCTA = originalData?.ctaType === 'multiple'

    const [title, setTitle] = useState<string>('')

    const [invalidActionsIndexes, setInvalidActionsIndexes] = useState<Array<number>>([])

    const {
        multiCtaVisibility,
        singleCtaVisibility,
        currentCta: updatedData
    } = useSelector(state => state.qgCta)

    const handleAddAction = () => {
        if (!updatedData) return

        dispatch(setStepMultiApplyVisibility(true))
        dispatch(
            setCurrentCTA({
                ctaType: 'multiple',
                title: DEFAULT_HEADER,
                actions: [...updatedData.actions, DEFAULT_ACTION]
            })
        )
    }

    const handleDelete = (index: number) => {
        if (!updatedData) return

        dispatch(setStepMultiApplyVisibility(true))
        dispatch(
            setCurrentCTA({
                ctaType: 'multiple',
                title: DEFAULT_HEADER,
                actions: updatedData.actions.filter((_item, idx) => idx !== index)
            })
        )
    }

    useEffect(() => {
        if (hasCTA) {
            dispatch(setCurrentCTA(originalData))
            setTitle(originalData.title)
            return
        }

        setTitle(DEFAULT_HEADER)
    }, [dispatch, originalData, hasCTA])

    useEffect(() => {
        dispatch(disableShortcuts())
        return () => {
            dispatch(enableShortcuts())
        }
    }, [dispatch])

    if (!multiCtaVisibility || !updatedData) return null

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={e => {
                const target = e.target as HTMLElement
                const isToggle = target.getAttribute('data-test') === 'toggle-multi-cta'

                if (!multiCtaVisibility || singleCtaVisibility || isToggle) return
                dispatch(setStepMultiCtaVisibility(false))
            }}
        >
            <Wrapper width={frameWidth} height={frameHeight}>
                <CTAToolbarButtons
                    onApplyClick={() => {
                        const emptyActions = updatedData.actions.flatMap((item, index) => {
                            if (item.link.length) return []
                            return index
                        })
                        if (emptyActions.length) {
                            setInvalidActionsIndexes(emptyActions)
                            return
                        }

                        dispatch(setQuickGuiddeStepCTA(updatedData))
                        dispatch(setStepMultiCtaVisibility(false))
                    }}
                    frameWidth={frameWidth}
                />

                <Box display="flex" flexDirection="column" alignItems="center">
                    <MultipleCtaTitle title={title} />
                    <SpacedGroup justifyContent="center">
                        <Container
                            sx={{ maxWidth: `${updatedData.actions.length > 3 ? 600 : 300}px` }}
                        >
                            {updatedData.actions.map((item, index) => (
                                <SpacedGroup
                                    position="relative"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    fontSize="14px"
                                    fontWeight="400"
                                    sx={{
                                        borderRadius: '33px',
                                        width: '257px',
                                        maxWidth: '257px',
                                        height: '36px',
                                        background: '#FFFFFF',
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                                        padding: '0px 8px',
                                        ...(updatedData.actions.length > 0 && {
                                            '&:hover > .deleteCTA': {
                                                display: 'block !important',
                                                cursor: 'pointer'
                                            }
                                        }),
                                        ...(invalidActionsIndexes.includes(index) && {
                                            border: 'solid red 1px',
                                            backgroundColor: '#FFEBEE'
                                        })
                                    }}
                                    key={index}
                                >
                                    <Box mt={0.5} ml={1.1} mr={1}>
                                        &#x2022;
                                    </Box>
                                    <Typography
                                        title={item.text}
                                        sx={{
                                            marginRight: '4px',
                                            width: '100%',
                                            fontSize: '14px',
                                            ...(item.text === DEFAULT_TEXT
                                                ? {
                                                      fontWeight: 400,
                                                      fontStyle: 'italic'
                                                  }
                                                : {
                                                      fontWeight: 600,
                                                      whiteSpace: 'nowrap',
                                                      fontStyle: 'normal',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis',
                                                      maxWidth: '250px'
                                                  })
                                        }}
                                    >
                                        {item.text}
                                    </Typography>

                                    {item.linkType === 'externalLink' && (
                                        <ExternalLink style={{ width: '55px', marginRight: 0 }} />
                                    )}

                                    <Box
                                        position="absolute"
                                        right="15px"
                                        className="deleteCTA"
                                        display="none"
                                    >
                                        <SpacedGroup spacing={0.5}>
                                            <ControlButton
                                                onClick={() => {
                                                    dispatch(setStepCtaVisibility(true))

                                                    dispatch(
                                                        setStepCtaAction({
                                                            action:
                                                                updatedData.ctaType === 'multiple'
                                                                    ? updatedData.actions[index]
                                                                    : undefined,
                                                            isSingleCTA: false,
                                                            editItemId: index
                                                        })
                                                    )
                                                }}
                                            >
                                                <EditIcon />
                                            </ControlButton>
                                            {updatedData.actions.length > 1 && (
                                                <ControlButton onClick={() => handleDelete(index)}>
                                                    <DeleteIcon />
                                                </ControlButton>
                                            )}
                                        </SpacedGroup>
                                    </Box>
                                </SpacedGroup>
                            ))}

                            {/*This action will be added  as part of the flex wrap*/}
                            {updatedData.actions.length > 4 && updatedData.actions.length < 6 && (
                                <Box onClick={handleAddAction}>
                                    <AddAction />
                                </Box>
                            )}
                        </Container>
                    </SpacedGroup>
                    {/*This action will be added to the end of the list*/}
                    {updatedData.actions.length > 0 && updatedData.actions.length <= 4 && (
                        <Box mt={2} onClick={handleAddAction}>
                            <AddAction />
                        </Box>
                    )}
                    <Stack
                        my={3}
                        direction="row"
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            color: '#FFFFFF',
                            cursor: 'pointer'
                        }}
                    >
                        <SKIP />
                        <Box ml={1}>SKIP</Box>
                    </Stack>
                </Box>

                {isFreePlan && ctaVisibility.isTrue && <PoweredBy />}
            </Wrapper>
        </ClickAwayListener>
    )
}
