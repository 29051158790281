import { useDispatch } from 'react-redux'

import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { ThemeProvider } from '@guidde/design-system'

import { CaptionsEditor } from './CaptionsEditor'

import { setSidePanelMode } from 'ducks'
import { useGlobalFeatureFlags } from 'hooks'
import { type QuickGuiddeType } from 'app/types'

type Props = {
    playbook: QuickGuiddeType
}

export const CaptionsPanel = ({ playbook }: Props) => {
    const dispatch = useDispatch()

    const { enableCaptionsEditor } = useGlobalFeatureFlags()

    if (!enableCaptionsEditor) return null

    return (
        <>
            <Box
                p={2}
                height={62}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid rgba(9, 12, 16, 0.08)"
                borderLeft="none"
            >
                <Box sx={{ fontSize: 20, fontWeight: 500 }}>Captions</Box>
                <IconButton onClick={() => dispatch(setSidePanelMode('voiceOver'))} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>

            <ThemeProvider>
                <div>
                    <CaptionsEditor playbook={playbook} />
                </div>
            </ThemeProvider>
        </>
    )
}
