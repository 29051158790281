import { type MouseEvent, memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, Paper, Popover, Checkbox, Typography, ClickAwayListener } from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { LanguageSpeakers } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'
import { useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'
import { SpacedGroup } from 'UI/Components'

import { UpgradePopup } from './UpgradePopup'

import { type SpeakerType } from 'app/types'

import { delay, isDeepEqual, logToAnalytics } from 'modules'
import { type UseBooleanType } from 'hooks'

type Props = {
    languageCode: string
    currentSpeaker: SpeakerType | null
    showIconOnly?: boolean
    applyAll?: UseBooleanType
    onSelect: (_value: SpeakerType) => void
    onAudioPlay: (speaker: SpeakerType) => void
    onMenuOpen?: () => void
    onSpeakerClick?: () => void
    onApplyAllClick?: () => void
}

export const SpeakersList = memo(
    ({
        currentSpeaker,
        languageCode,
        showIconOnly,
        applyAll,
        onSelect,
        onMenuOpen,
        onSpeakerClick,
        onAudioPlay,
        onApplyAllClick
    }: Props) => {
        const { setLastSpeaker, hasStudioVoices, getSpeakersList, getDefaultSpeaker } =
            useSpeakerOptions()

        const { activeStep, steps } = useSelector(state => state.qgEditor.present)

        const { tempAudioNote, audioNote } = steps[activeStep] || {}

        const [contactUsAnchorEl, setContactUsAnchorEl] = useState<HTMLElement | null>(null)

        const speakers = useMemo(() => {
            return getSpeakersList(languageCode)
        }, [getSpeakersList, languageCode])

        const initialSpeaker = useMemo(() => {
            if (currentSpeaker?.langCode === languageCode) return currentSpeaker

            if (
                tempAudioNote?.type === 'textToSpeech' &&
                tempAudioNote.speakerConfig.langCode === languageCode
            )
                return tempAudioNote.speakerConfig

            if (
                audioNote?.type === 'textToSpeech' &&
                audioNote.speakerConfig.langCode === languageCode
            )
                return audioNote.speakerConfig

            return getDefaultSpeaker(speakers)
        }, [audioNote, currentSpeaker, getDefaultSpeaker, languageCode, speakers, tempAudioNote])

        const openMenu = (_: MouseEvent<HTMLElement>, callback: () => void) => {
            if (!initialSpeaker) return

            callback()
            delay(0).then(() => {
                const element = document.getElementById(initialSpeaker.id)
                if (!element) return
                element.scrollIntoView()
            })

            onMenuOpen?.()
        }

        const closeMenu = () => setContactUsAnchorEl(null)

        const onSpeakerSelect = (
            e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
            speaker: SpeakerType
        ) => {
            if (speaker.isStudio) {
                logToAnalytics('studioVoiceSelected', {
                    speakerName: speaker.name,
                    speaker,
                    isSupported: Boolean(hasStudioVoices)
                })

                if (!hasStudioVoices) {
                    setContactUsAnchorEl(e.currentTarget)
                    return
                }
            }
            setLastSpeaker(speaker.id)

            onSelect(speaker)
            onSpeakerClick?.()
            closeMenu()
        }

        useEffect(() => {
            if (isDeepEqual(currentSpeaker, initialSpeaker)) return

            if (initialSpeaker) onSelect(initialSpeaker)
        }, [initialSpeaker, onSelect, currentSpeaker])

        if (!currentSpeaker) return null

        if (showIconOnly) {
            return <img src={currentSpeaker.avatarUrl} width="40" height="40" alt="avatar" />
        }

        return (
            <>
                <SpeakersListPopover
                    onOpen={openMenu}
                    onApplyAllClick={onApplyAllClick}
                    onSpeakerSelect={onSpeakerSelect}
                    onClickAway={() => {
                        if (!contactUsAnchorEl) closeMenu()
                    }}
                    onAudioPlay={onAudioPlay}
                    hasStudioVoices={hasStudioVoices}
                    speakers={speakers}
                    applyAll={applyAll}
                    currentSpeaker={currentSpeaker}
                />
                <UpgradePopup
                    anchorEl={contactUsAnchorEl}
                    options={speakers.filter(speaker => speaker.isStudio).slice(0, 3)}
                    onClose={closeMenu}
                />
            </>
        )
    }
)

type SpeakersListPopoverProps = {
    currentSpeaker: SpeakerType
    hasStudioVoices?: boolean | null
    disabled?: boolean
    speakers: Array<SpeakerType>
    applyAll?: UseBooleanType
    onApplyAllClick?: () => void
    onOpen?: (e: MouseEvent<HTMLElement>, callback: () => void) => void
    onAudioPlay?: (speaker: SpeakerType) => void
    onSpeakerSelect: (
        event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
        speaker: SpeakerType
    ) => void
    onClickAway?: () => void
}

export const SpeakersListPopover = ({
    onOpen,
    onApplyAllClick,
    onSpeakerSelect,
    onClickAway,
    onAudioPlay,
    hasStudioVoices = null,
    speakers = [],
    applyAll,
    currentSpeaker,
    disabled
}: SpeakersListPopoverProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

    const closeLanguageSpeakersSelection = () => setAnchorEl(null)

    return (
        <>
            <Box
                sx={{ width: '154px', height: '45px', cursor: disabled ? 'auto' : 'pointer' }}
                pt={0}
                onClick={e => {
                    if (disabled) return

                    if (onOpen) onOpen(e, () => setAnchorEl(e.currentTarget))
                    else setAnchorEl(e.currentTarget)
                }}
            >
                <SpacedGroup
                    alignContent="center"
                    data-test="editor-speaker"
                    data-intercom="editor-speaker"
                >
                    <img src={currentSpeaker.avatarUrl} width="40" height="40" alt="avatar" />

                    <SpacedGroup flexDirection="column" spacing={0.25}>
                        <SpacedGroup>
                            <Box style={{ fontSize: '14px', fontWeight: 500 }}>Speaker</Box>
                            <ArrowDropDownIcon style={{ color: '#090C10' }} />
                        </SpacedGroup>
                        <Box style={{ fontSize: '14px', fontWeight: 400, textAlign: 'left' }}>
                            {currentSpeaker.name}
                        </Box>
                    </SpacedGroup>
                </SpacedGroup>
            </Box>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disablePortal
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                slotProps={{
                    paper: {
                        sx: {
                            padding: '16px 16px 4px 16px'
                        }
                    }
                }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        onClickAway?.()
                        closeLanguageSpeakersSelection()
                    }}
                >
                    <Paper elevation={0}>
                        <LanguageSpeakers
                            speakerItemDataCy="speaker-card"
                            onAudioPlay={onAudioPlay}
                            onSelect={(e, speaker) => {
                                onSpeakerSelect(e, speaker)

                                const canCloseLanguageSpeakers =
                                    !speaker.isStudio || hasStudioVoices

                                if (canCloseLanguageSpeakers) closeLanguageSpeakersSelection()
                            }}
                            currentSpeaker={currentSpeaker}
                            hasStudioVoices={hasStudioVoices}
                            width={430}
                            gridGap="10px 16px"
                            options={speakers}
                        />

                        {applyAll && (
                            <Box mb={1}>
                                <label style={{ cursor: 'pointer' }}>
                                    <Checkbox
                                        data-test="t2s-doc-checkbox"
                                        color="primary"
                                        checked={applyAll.isTrue}
                                        onChange={() => {
                                            applyAll.toggle()
                                            onApplyAllClick?.()
                                        }}
                                    />

                                    <Typography variant="caption">
                                        Apply Speaker to all steps
                                    </Typography>
                                </label>
                            </Box>
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popover>
        </>
    )
}
