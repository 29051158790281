import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { TextOverflow } from '@guidde-co/shared.textoverflow'
import { Button, List, ListItem, Popover, Tooltip, Typography } from '@mui/material'

import ArrowIcon from '@mui/icons-material/ArrowDropDown'

import { setFontStyles } from 'ducks'
import { useBrandKit } from 'hooks'

type Props = {
    fontFamilyTitle?: string
}

export const FontFamily = ({ fontFamilyTitle }: Props) => {
    const dispatch = useDispatch()

    const { brandKitData, brandKitFont, brandKitLoading } = useBrandKit()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const layerActiveFont = brandKitData?.font?.data?.find(font => font.title === fontFamilyTitle)
    const activeFont = layerActiveFont || brandKitFont

    return (
        <>
            <Tooltip title="Select font family">
                <Button
                    onClick={e => setAnchorEl(e.currentTarget)}
                    disabled={brandKitLoading}
                    variant="outlined"
                    color="inherit"
                    sx={{
                        margin: 0,
                        textAlign: 'left',
                        height: 24,
                        borderRadius: '4px',
                        minWidth: 35,
                        width: 100,
                        border: '1px solid rgba(9, 12, 16, 0.08)',
                        padding: '4px 2px'
                    }}
                    endIcon={<ArrowIcon />}
                >
                    <TextOverflow lineClamp={1}>
                        <Typography
                            style={{
                                fontSize: 14,
                                fontWeight: 500
                            }}
                        >
                            {activeFont?.title}
                        </Typography>
                    </TextOverflow>
                </Button>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <List style={{ padding: 8, maxHeight: 450, overflow: 'auto', width: 233 }}>
                    {brandKitData?.font.data?.map(font => (
                        <ListItem
                            key={font.id}
                            disabled={font.id === activeFont?.id}
                            style={{ minHeight: 48 }}
                            button
                            divider
                            selected={font.id === activeFont?.id}
                            onClick={() => {
                                setAnchorEl(null)
                                dispatch(
                                    setFontStyles({
                                        fontFamily: font.id
                                    })
                                )
                            }}
                        >
                            <TextOverflow lineClamp={1}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        fontFamily: font.title
                                    }}
                                >
                                    {font.title}
                                </Typography>
                            </TextOverflow>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    )
}
