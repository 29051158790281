import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { envConfig } from './envConfig'

const app = initializeApp(envConfig.firebaseConfig)

export const db = getFirestore(app)
export const rtdb = getDatabase(app)
export const auth = getAuth(app)
