import { envConfig } from './envConfig'

type ExtensionMessage = { type: string; params?: any }

export const pingExtension = (extId: string, msg: ExtensionMessage) =>
    new Promise((resolve, reject) => {
        const { type, params } = msg
        window?.chrome?.runtime?.sendMessage(extId, { type, params }, response => {
            // Check if receiving end exists
            if (window?.chrome?.runtime?.lastError) {
                reject('NO_EXTENSION')
                return
            }

            if (!response) {
                reject('NO_RESPONSE')
                return
            }

            resolve(response)
        })
    })

export const sendSoleMessageToExt = ({
    msg,
    successAction,
    fallbackAction
}: {
    msg: ExtensionMessage
    successAction?: (_response: any) => void
    fallbackAction?: () => void
}) => {
    if (window.chrome?.runtime) {
        // Multiple extensions can be installed, so we take the Promise that was resolved first
        Promise.any?.(
            envConfig.extensionIds.map(extId => pingExtension(extId, msg).catch(console.error))
        )
            .then(response => {
                successAction?.(response)
            })
            .catch(() => {
                // All possible extension ids were rejected, execute fallback action
                fallbackAction?.()
            })
    } else {
        // If sendMessage API is unavailable, we execute fallback action
        fallbackAction?.()
    }
}
