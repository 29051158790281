import * as types from 'ducks/types'
import { type MultipleCtaType, type SingleCtaType } from 'app/types'

export const setCurrentCTA = (payload?: MultipleCtaType) =>
    ({
        type: types.SET_QUICK_GUIDDE_CURRENT_CTA,
        payload
    }) as const

export const setStepCtaVisibility = (payload: boolean) =>
    ({
        type: types.SET_QUICK_GUIDDE_STEP_CTA_VISIBILITY,
        payload
    }) as const

export const setStepMultiCtaVisibility = (payload: boolean) =>
    ({
        type: types.SET_QUICK_GUIDDE_MULTI_CTA_VISIBILITY,
        payload
    }) as const

export const setStepMultiApplyVisibility = (payload: boolean) =>
    ({
        type: types.SET_QUICK_GUIDDE_MULTI_APPLY_VISIBILITY,
        payload
    }) as const

export const setStepCtaAction = (payload: {
    action: SingleCtaType['action'] | undefined
    isSingleCTA: boolean
    editItemId: number
}) =>
    ({
        type: types.SET_QUICK_GUIDDE_STEP_CTA_ACTION,
        payload
    }) as const
