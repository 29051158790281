import { type ReactNode } from 'react'

import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material'

export const InsightsLayoutButton = ({
    Icon,
    onClick,
    isLoading,
    description
}: {
    Icon: ReactNode
    onClick: () => void
    description: string
    isLoading?: boolean
}) => {
    return (
        <Stack position="relative">
            {isLoading && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                    }}
                    size={36}
                />
            )}
            <Tooltip title={description} disableInteractive>
                <IconButton
                    disabled={isLoading}
                    onClick={onClick}
                    sx={{
                        borderRadius: '50%',
                        backgroundColor: 'rgba(9, 12, 16, 0.1)',
                        color: 'rgba(9, 12, 16, 0.6)',
                        p: 0.75,
                        '&.Mui-disabled': {
                            backgroundColor: 'rgba(9, 12, 16, 0.1)'
                        }
                    }}
                >
                    {Icon}
                </IconButton>
            </Tooltip>
        </Stack>
    )
}
