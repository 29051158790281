import { useState } from 'react'

import { Box, Link, Typography } from '@mui/material'
import { type GridColDef, DataGrid } from '@mui/x-data-grid'

import { useMenuStatus } from 'UI/Components'

import { useNotification, useQuery } from 'hooks'
import { formatDate } from 'modules'

type InvoiceType = {
    id: string
    invoiceUrl: string
    refundUrl: string
    planName: string
    total: number
    timestamp: number
}

type InvoicesPayload = {
    invoices: Array<InvoiceType>
    nextPage: string
    totalCount: number
}

const createQuery = (data: Record<string, string | number>) => {
    const notEmptyValues = Object.entries(data)
        .filter(([_, value]) => value !== '')
        .map(([key, value]) => [key, value.toString()])

    const result = new URLSearchParams(notEmptyValues).toString()

    return result
}

const columns: Array<GridColDef<InvoiceType>> = [
    {
        field: 'timestamp',
        headerName: 'Date',
        flex: 0.4,
        renderCell: data => (
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{formatDate(data.value)}</Typography>
        )
    },
    {
        field: 'invoiceUrl',
        headerName: 'Invoice',
        width: 150,
        flex: 0.2,
        renderCell: data => (
            <Box>
                <Link
                    href={data.row.invoiceUrl}
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    display="block"
                >
                    View Invoice
                </Link>
                {data.row.refundUrl && (
                    <Link
                        display="block"
                        href={data.row.refundUrl}
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                    >
                        View Refund
                    </Link>
                )}
            </Box>
        )
    },
    {
        field: 'total',
        headerName: 'Amount',
        width: 150,
        flex: 0.2,
        valueGetter: data => `$${data.value}`
    },
    {
        field: 'planName',
        headerName: 'Plan',
        flex: 0.2,
        editable: true
    }
]

const pageSize = 25
export const InvoicesTable = () => {
    const { showErrorNotification } = useNotification()
    const { isMenuClosed } = useMenuStatus()

    const [prevPages, setPrevPages] = useState<{ [key: number]: string }>({ 0: '' })

    const [queryParams, setQueryParams] = useState({
        limit: pageSize,
        nextPage: ''
    })

    const { data, isValidating } = useQuery<InvoicesPayload>(
        `/c/v1/invoices?${createQuery(queryParams)}`,
        {
            method: 'GET'
        },
        {
            dedupingInterval: 0,
            onError: e => {
                showErrorNotification('Failed to fetch invoices. Please try again later.')
                console.error('[Failed to fetch invoices]:', e)
            },
            onSuccess: ({ nextPage }) => {
                if (!nextPage) return

                setPrevPages(prev => {
                    const pagesCount = Object.keys(prev).length

                    return { ...prev, [pagesCount]: nextPage }
                })
            }
        }
    )

    const activePage = Object.values(prevPages).findIndex(page => page === queryParams.nextPage)

    return (
        <Box width={`calc(100vw - ${isMenuClosed ? 120 : 290}px)`}>
            <DataGrid
                autoHeight
                disableColumnMenu
                rows={data?.invoices || []}
                columns={columns}
                paginationMode="server"
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: queryParams.limit,
                            page: activePage
                        }
                    }
                }}
                pagination
                rowCount={data?.totalCount || 0}
                onPaginationModelChange={({ pageSize, page }, { reason }) => {
                    if (!reason) return

                    setQueryParams({
                        nextPage: prevPages[page],
                        limit: pageSize
                    })
                }}
                pageSizeOptions={[pageSize]}
                disableRowSelectionOnClick
                loading={isValidating && !data?.invoices?.length}
            />
        </Box>
    )
}
