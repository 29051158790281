import { Typography } from '@mui/material'

import { useRoles, useServiceUsage } from 'hooks'

import { ServiceUsageDialog } from './ServiceUsageDialog'

type Props = {
    onClose: () => void
    onPrimaryClick: () => void
}

export const ProLimitationDialog = ({ onClose, onPrimaryClick }: Props) => {
    const { isAdmin } = useRoles()

    const { usedPercent, isLoading, planName } = useServiceUsage()

    if (isLoading) return null

    return (
        <ServiceUsageDialog
            title="Keep on going"
            onClose={onClose}
            description={
                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                    Your team reached{' '}
                    <Typography component="span" fontWeight={700} color="primary.main">
                        {usedPercent}% of your {planName} plan.
                    </Typography>
                    {'\n'}
                    Unlock exclusive features today and get unlimited video minutes, video
                    analytics, and more exciting features!
                </Typography>
            }
            actionText={isAdmin ? 'Upgrade plan' : 'Contact your admin'}
            onActionClick={() => {
                if (!isAdmin) {
                    onClose()
                    return
                }

                onPrimaryClick()
            }}
        />
    )
}
