import { type Dayjs } from 'dayjs'

export const enum InsightsPeriod {
    Week = 'week',
    Month = 'month',
    Quarter = 'quarter',
    HalfYear = 'halfYear',
    Custom = 'custom'
}

export type InsightsPredefinedPeriod = Exclude<InsightsPeriod, 'custom'>

export const insightsPeriodSchema = {
    [InsightsPeriod.Week]: 7,
    [InsightsPeriod.Month]: 30,
    [InsightsPeriod.Quarter]: 90,
    [InsightsPeriod.HalfYear]: 180
} as const

export enum InsightsView {
    charts = 'charts',
    table = 'table'
}

export type TotalMetrics = {
    views: number
    viewers: number
    avgWatched: number
    avgWatchTime: number
}

export type MetricsByPlaybook = TotalMetrics & {
    loads: number
}

export type MetricsByDate = MetricsByPlaybook & {
    date: number
}

export type PlaybooksTableMetrics = {
    id: string
    name: string
    screenshotUrl: string
    creator: string
} & MetricsByPlaybook

export type TopPlaybook = {
    total: number
    id: string
    screenshotUrl: string
    name: string
    photoURL: string
    creator: string
    email: string
    applications: Array<string>
}

export type TopCreator = {
    total: number
    uid: string
    email: string
    displayName: string
    photoURL: string
}

export type InsightsCharts = {
    metricsByDate: Array<MetricsByDate>
    topPlaybooks: Array<TopPlaybook>
    topCreators: Array<TopCreator>
}

export type InsightsTable = {
    playbooksTableMetrics: {
        metrics: Array<PlaybooksTableMetrics>
        total: number
        max: number
    }
}

export type InsightsWithinPlaybook = InsightsCharts & {
    playbooksTableMetrics: Array<PlaybooksTableMetrics>
}

export type InsightsV2Response<T> = {
    data:
        | (T & {
              totalMetrics: TotalMetrics
              totalMetricsPrevious?: TotalMetrics
          })
        | null
}

export type DatePeriod = { start: Dayjs; end: Dayjs }
export type InsightsPeriodSelectValue = { period: InsightsPeriod; dateRange: DatePeriod | null }
