import { forwardRef } from 'react'

import { type BoxProps, Box } from '@mui/material'

// We really need it for smooth migration to mui v5
const ignoreSsrWarning =
    '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */'

type Props = {
    spacing?: number
    flexDirection?: 'row' | 'column'
} & Omit<BoxProps, 'flexDirection'>

// Old component, use mui Stack instead
export const SpacedGroup = forwardRef<HTMLDivElement | null, Props>((props, ref) => {
    const { children, spacing = 2, flexDirection = 'row', flexWrap, ...rest } = props

    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection={flexDirection}
            ref={ref}
            flexWrap={flexWrap}
            sx={
                flexDirection === 'row'
                    ? {
                          [`& > *:not(:last-child)${ignoreSsrWarning}`]: {
                              marginRight: spacing
                          },
                          [`& > *:last-child${ignoreSsrWarning}`]: {
                              marginRight: flexWrap ? spacing : 0
                          }
                      }
                    : {
                          alignItems: 'flex-start',
                          '& > *': {
                              width: '100%'
                          },
                          [`& > *:not(:first-child)${ignoreSsrWarning}`]: {
                              marginTop: spacing
                          }
                      }
            }
            {...rest}
        >
            {children}
        </Box>
    )
})
