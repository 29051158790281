import { memo, useState } from 'react'

import { type BrandKitType } from 'app/types'

import { Box } from '@mui/material'
import Icon from '@mui/icons-material/PaletteOutlined'

import { type SectionProps, ItemCard, Section, RadioLabel } from './components'
import { ColorDialog } from './dialogs'

import { cardLimits } from '../PageBrandKit'

import { useBoolean, useNotification } from 'hooks'

type Props = {
    values: BrandKitType['color']
} & SectionProps

export const Color = memo(
    ({ values, isLoading, popupProps, onDelete, onAdd, onEdit, onSetActive }: Props) => {
        const { showSuccessNotification } = useNotification()

        const addDialog = useBoolean()

        const [editColorData, setEditColorData] = useState<null | {
            id: string
            value: string
        }>(null)

        const handleDelete = (colorId: string) => {
            onDelete({ color: colorId }).then(() => {
                showSuccessNotification('Color removed')
                setEditColorData(null)
            })
        }

        const handleAddColor = (color: string) => {
            addDialog.setFalse()
            onAdd({ color }).then(() => showSuccessNotification('Color added'))
        }

        const handleEditColor = (color: string) => {
            if (!editColorData) return

            onEdit({
                color: {
                    id: editColorData?.id,
                    data: color
                }
            }).then(() => {
                showSuccessNotification('Color edited')
                setEditColorData(null)
            })
        }

        return (
            <>
                <Section
                    popupProps={popupProps}
                    title="Colors"
                    subtitle="Add brand colors and use them inside a color palette across your project"
                    content={values.data.map(item => {
                        const isActive = values.active === item.id
                        const isOneItemInList = values.data.length < 2
                        const disableDelete = isActive || isOneItemInList

                        const tooltipText = isOneItemInList
                            ? "Last selection can't be deleted"
                            : "Active selection can't be deleted"

                        return (
                            <Box data-test="color-box-brandkit" key={item.id}>
                                <ItemCard
                                    onEdit={() => setEditColorData(item)}
                                    onDelete={() => handleDelete(item.id)}
                                    disableDelete={disableDelete}
                                    hideDelete={isActive}
                                    popupProps={popupProps}
                                    tooltipText={tooltipText}
                                    showTooltip={isActive}
                                    disableLabelButton={isActive}
                                    label={
                                        <RadioLabel
                                            validateClick={popupProps.validateClick}
                                            isActive={isActive}
                                            onClick={() => {
                                                onSetActive({
                                                    color: item.id
                                                }).then(() => {
                                                    showSuccessNotification('Active color changed')
                                                })
                                            }}
                                        />
                                    }
                                    bgcolor={item.value}
                                />
                            </Box>
                        )
                    })}
                    actionTitle="Add Brand Color"
                    showActionCard={cardLimits.colors > values.data.length}
                    actionIcon={Icon}
                    onActionItemClick={addDialog.setTrue}
                />
                <ColorDialog
                    title="Add New Color"
                    isOpen={addDialog.isTrue}
                    onClose={addDialog.setFalse}
                    onSubmit={handleAddColor}
                    isLoading={isLoading}
                />
                <ColorDialog
                    title="Edit Color"
                    isOpen={Boolean(editColorData)}
                    initialColor={editColorData?.value}
                    onClose={() => setEditColorData(null)}
                    onSubmit={handleEditColor}
                    isLoading={isLoading}
                />
            </>
        )
    }
)
