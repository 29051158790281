import { useDispatch } from 'react-redux'

import { Box, Stack } from '@mui/material'

import {
    getArrowDataKey,
    getArrowType,
    type svgArrowData
} from 'UI/Routes/quick-guidde/CanvasEditor/Workspace/Shapes/svg-arrow-data'

import { ReactComponent as BottomLeft } from 'assets/icons/bottomLeft.svg'
import { ReactComponent as BottomRight } from 'assets/icons/bottomRight.svg'
import { ReactComponent as TopLeft } from 'assets/icons/topLeft.svg'
import { ReactComponent as TopRight } from 'assets/icons/topRight.svg'

import { setArrowDirection } from 'ducks'
import { useOrgFeatureFlags } from 'hooks'

const enum COLORS {
    black = '#000000',
    grey = '#979797'
}

const getOptions = (type?: 'sketch' | 'wix') =>
    [
        {
            dataKey: getArrowDataKey('bottomRight', type),
            image: BottomRight
        },
        {
            dataKey: getArrowDataKey('bottomLeft', type),
            image: BottomLeft
        },
        {
            dataKey: getArrowDataKey('topRight', type),
            image: TopRight
        },
        {
            dataKey: getArrowDataKey('topLeft', type),
            image: TopLeft
        }
    ] as const

type Props = {
    dataKey: keyof typeof svgArrowData
}

export const ArrowDirection = ({ dataKey }: Props) => {
    const dispatch = useDispatch()

    const { enableSketchedArrow, enableWixArrow } = useOrgFeatureFlags()

    const arrowType = getArrowType({ enableSketchedArrow, enableWixArrow })

    return (
        <div style={{ cursor: 'pointer', color: '#757575' }}>
            <Stack direction="row" spacing={1}>
                {getOptions(arrowType).map((option, i) => {
                    const Icon = option.image
                    const isActive = dataKey === option.dataKey

                    return (
                        <Box key={i} onClick={() => dispatch(setArrowDirection(option.dataKey))}>
                            <Icon stroke={isActive ? COLORS.black : COLORS.grey} />
                        </Box>
                    )
                })}
            </Stack>
        </div>
    )
}
