import { type ChangeEvent, useEffect, useState } from 'react'

import { Box, TextField, Typography } from '@mui/material'

import { Label } from 'UI/Components'

import { updateDescription, logToAnalytics } from 'modules'
import { type PlaybookModeType } from 'app/types'

type EventType = HTMLInputElement | HTMLTextAreaElement

type Props = {
    playbookId: string
    value: string
    placeholder?: string
    disabled?: boolean
    mode: PlaybookModeType
    showCharCount?: boolean
    charCountPosition?: 'left' | 'right'
    multiline?: boolean
    minRows?: number
    maxLength?: number
    onSave?: (value: string) => void
    onChange?: (value: string) => void
    onFocus?: () => void
    onBlur?: (value: string) => void
}

const forbiddenChars = ['<', '>']

export const EditDescription = ({
    placeholder,
    playbookId,
    value,
    disabled,
    mode,
    showCharCount = false,
    charCountPosition = 'left',
    multiline = false,
    maxLength = 1500,
    minRows,
    onSave,
    onChange,
    onFocus,
    onBlur
}: Props) => {
    const [description, setDescription] = useState(value || '')

    useEffect(() => setDescription(value || ''), [value])

    const handleBlur = ({ target }: ChangeEvent<EventType>) => {
        onBlur?.(target.value)

        if (target.value !== value) {
            logToAnalytics(`update_description`, {
                'pb-desc': target.value,
                playbookType: mode
            })

            if (onSave) {
                return onSave(target.value)
            }

            updateDescription({ playbookId, description: target.value })
        }
    }

    if (!description && disabled) return null

    return (
        <Box>
            <Label label="Description" />
            <Box
                mt={1}
                display="flex"
                alignItems="center"
                sx={{
                    '& * ': {
                        color: 'black !important',
                        fontSize: '12px'
                    },
                    '& label': {
                        color: '#7f8c9a !important'
                    }
                }}
            >
                <TextField
                    placeholder={placeholder}
                    variant="filled"
                    value={description}
                    disabled={disabled}
                    onChange={e => {
                        setDescription(e.target.value)
                        onChange?.(e.target.value)
                    }}
                    onFocus={onFocus}
                    onBlur={handleBlur}
                    onKeyPress={e => {
                        if (forbiddenChars.includes(e.key)) {
                            e.preventDefault()
                        }
                    }}
                    InputProps={{
                        disableUnderline: true,
                        sx: {
                            '&': {
                                paddingTop: '10px',
                                background: '#FAFAFA',
                                borderRadius: '4px'
                            }
                        }
                    }}
                    inputProps={{ maxLength }}
                    sx={{
                        '& textarea': {
                            fontSize: '12px !important'
                        },
                        '& :before': {
                            borderBottomColor: '#e0e0e0'
                        }
                    }}
                    fullWidth
                    multiline={multiline}
                    minRows={minRows}
                    maxRows={10}
                    data-test="editor-video-description"
                />
            </Box>
            {showCharCount && (
                <Typography
                    sx={{
                        paddingRight: '4px',
                        paddingTop: '4px',
                        fontSize: '12px',
                        color: '#828282',
                        textAlign: charCountPosition
                    }}
                >
                    {`${description.length}/${maxLength}`}
                </Typography>
            )}
        </Box>
    )
}
