import { type ReactNode, memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { ThemeProvider, Typography } from '@guidde/design-system'

import clsx from 'clsx'

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import TouchAppIcon from '@mui/icons-material/TouchApp'
import DragIcon from '@mui/icons-material/DragHandle'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'

import { SpacedGroup } from 'UI/Components'
import { StepThumbnail } from './StepThumbnail'
import { ActiveStepControls } from './ActiveStepControls'
import { StepTitle } from './StepTitle'

import { ReactComponent as MotionIcon } from 'assets/icons/union.svg'
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg'

import { type StepType, Shape } from 'app/types'
import { setActiveStep, setStepMultiCtaVisibility, toggleMultipleSelectedSteps } from 'ducks'
import { delay } from 'modules'

const formatStepDuration = (duration: number) => {
    const min = Math.floor(duration / 60)
    const sec = duration % 60
    return (min ? `${min}m` : '') + (sec ? `${Math.round(sec)}s` : '')
}

const PREFIX = 'Step'

const classes = {
    step: `${PREFIX}-step`,
    durationBadge: `${PREFIX}-durationBadge`,
    videoBadge: `${PREFIX}-videoBadge`,
    stepBadge: `${PREFIX}-stepBadge`,
    right: `${PREFIX}-right`,
    left: `${PREFIX}-left`,
    imageWrapper: `${PREFIX}-imageWrapper`,
    bottomPart: `${PREFIX}-bottomPart`,
    transition: `${PREFIX}-transition`,
    multiCta: `${PREFIX}-multiCta`,
    activeMultiCta: `${PREFIX}-activeMultiCta`
}

const StyledWrapper = styled('div')(({ theme }) => ({
    [`& .${classes.step}`]: {
        height: 120,
        width: 120,
        position: 'relative'
    },
    [`& .${classes.videoBadge}`]: {
        height: 16,
        position: 'absolute',
        top: 3,
        left: 4,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(9, 12, 16, 0.6)',
        borderRadius: '2px'
    },
    [`& .${classes.durationBadge}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 3,
        right: 4,
        height: 16,
        padding: '0 4px',
        borderRadius: '2px',
        backgroundColor: 'rgba(9, 12, 16, 0.6)',
        fontSize: '10px',
        fontWeight: 500,
        color: '#ffffff'
    },
    [`& .${classes.stepBadge}`]: {
        position: 'absolute',
        bottom: theme.spacing(-1),
        background: 'black',
        borderRadius: '50%',
        display: 'flex',
        padding: 2,
        '& > svg': {
            fontSize: 16,
            color: 'white'
        }
    },
    [`& .${classes.right}`]: {
        right: 8
    },
    [`& .${classes.left}`]: {
        left: 8
    },
    [`& .${classes.imageWrapper}`]: {
        display: 'flex',
        position: 'relative',
        marginBottom: 4,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4
    },
    [`& .${classes.bottomPart}`]: {
        height: 26,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    [`& .${classes.transition}`]: {
        width: 20,
        height: 20,
        position: 'absolute',
        left: 0,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BFCDE7',
        background: 'white',
        border: '1px solid #BFCDE7',
        pointerEvents: 'none',
        borderRadius: '50%',
        zIndex: 1
    },
    [`& .${classes.multiCta}`]: {
        paddingTop: 4,
        width: '33px',
        height: '105px',
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
        color: '#828282',
        borderLeft: 'dashed #E0E0E0 1px',
        borderRight: 'solid #E0E0E0 1px'
    },
    [`& .${classes.activeMultiCta}`]: {
        background: '#EDEEEF',
        color: '#06080A'
    }
}))

type Props = {
    isSelected: boolean
    step?: StepType
    stepLabel: ReactNode
    isLoading?: boolean
    isActive: boolean
    originalIndex: number
}

export const Step = memo(
    ({ isSelected, originalIndex, isActive, step, isLoading, stepLabel }: Props) => {
        const dispatch = useDispatch()

        const { multiCtaVisibility } = useSelector(state => state.qgCta)

        if (!step) return null

        const { id, audioNote, cta, duration = 3, kind, transition, title, layers } = step

        const hasSingleCTA = cta?.ctaType === 'single' && cta.action.enabled
        const hasMultipleCTA = cta?.ctaType === 'multiple' && cta.actions.length > 0

        const videoStep = layers.some(layer => layer.type === Shape.Video)

        const isInUse = isActive || isSelected

        const borderColor = isInUse ? '#FED243' : 'transparent'
        const backgroundColor = isInUse && !multiCtaVisibility ? '#EDEEEF' : 'transparent'

        return (
            <StyledWrapper>
                <SpacedGroup position="relative" spacing={0}>
                    {Boolean(transition) && (
                        <div className={classes.transition}>
                            <MotionIcon />
                        </div>
                    )}

                    <div
                        // id is used in cypress + for scrollIntoView
                        id={id}
                        data-test={`step-thumbnail-${originalIndex}`}
                        className={classes.step}
                        style={{
                            padding: '0 12px 12px 12px',
                            border: `2px solid ${borderColor}`,
                            cursor: isLoading ? 'auto' : 'pointer',
                            backgroundColor: backgroundColor
                        }}
                        onMouseDown={async e => {
                            if (isLoading) return

                            // We need delay in order to wait until 'ClickAwayListener' is triggered to save temp audio note
                            await delay(100)

                            // Selecting multiple steps mode
                            if (e.shiftKey) {
                                dispatch(
                                    toggleMultipleSelectedSteps({
                                        stepId: id,
                                        stepIndex: originalIndex
                                    })
                                )
                                return
                            }

                            if (!isActive) dispatch(setActiveStep(originalIndex))
                        }}
                    >
                        <StepTitle
                            title={title || (kind === 'end' ? 'Thank you' : 'Untitled step')}
                        />

                        <div className={classes.imageWrapper}>
                            {videoStep && (
                                <div className={classes.videoBadge}>
                                    <VideoIcon style={{ padding: 1 }} />
                                </div>
                            )}

                            <div className={classes.durationBadge}>
                                {formatStepDuration(duration + (transition?.duration || 0))}
                            </div>

                            <StepThumbnail
                                step={step}
                                height={50}
                                width={90}
                                isLoading={isLoading}
                            />

                            {Boolean(audioNote && audioNote?.type !== 'defaultSubtitles') && (
                                <div className={clsx(classes.stepBadge, classes.right)}>
                                    <KeyboardVoiceIcon />
                                </div>
                            )}
                            {hasSingleCTA && (
                                <div className={clsx(classes.stepBadge, classes.left)}>
                                    <TouchAppIcon />
                                </div>
                            )}
                        </div>

                        <div className={classes.bottomPart}>
                            <ThemeProvider>
                                <Box
                                    sx={{
                                        marginLeft: '4px',
                                        minWidth: '26px',
                                        // #TODO Remove as soon as we move to guidde design system
                                        '& p': {
                                            fontFamily: 'Roboto, sans-serif'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="text"
                                        color="grey.950"
                                        size="sm"
                                        fontWeight="medium"
                                    >
                                        {stepLabel}
                                    </Typography>
                                </Box>
                            </ThemeProvider>
                            {isActive && kind === 'step' && (
                                <DragIcon
                                    fontSize="small"
                                    style={{
                                        color: '#BDBDBD'
                                    }}
                                />
                            )}
                            {(isActive || isSelected) && !isLoading && <ActiveStepControls />}
                        </div>
                    </div>

                    {hasMultipleCTA && (
                        <div
                            data-test="toggle-multi-cta"
                            className={clsx(classes.multiCta, {
                                [classes.activeMultiCta]: isActive && multiCtaVisibility
                            })}
                            style={{
                                cursor: isLoading ? 'auto' : 'pointer'
                            }}
                            onMouseDown={() => {
                                if (isLoading) return

                                // Toggle multi cta visibility
                                if (isActive || !multiCtaVisibility) {
                                    dispatch(setStepMultiCtaVisibility(!multiCtaVisibility))
                                }
                                if (!isActive) dispatch(setActiveStep(originalIndex))
                            }}
                        >
                            <TouchAppIcon fontSize="small" />
                        </div>
                    )}
                </SpacedGroup>
            </StyledWrapper>
        )
    }
)
