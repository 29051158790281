import dayjs from 'dayjs'

import { getDatabase, ref, set, remove } from 'firebase/database'

import { paths } from 'app/paths'
import { isDev, host } from 'modules'

import { openLink, request } from './utils'

export const removeConnect = uid => remove(ref(getDatabase(), `zoomUsers/${uid}/connected`))

const CLIENT_ID = isDev ? 'pmDakdxeQKWh_IsPjYW7g' : 'ZI4oIjtOT8aLZo7FjvfvKw'

const ZOOM_DATEFORMAT = 'YYYY-MM-DD'

const redirectUri = `${host}${paths.myVideos}?zoomDialog=open`

export const initZoomAuthFlow = () => {
    openLink(
        `https://zoom.us/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${redirectUri}`,
        '_self'
    )
}

export const sendAuthCode = code =>
    request(`/b/v1/zoom/connect?code=${code}&redirectURI=${redirectUri}`, 'GET')

export const getRecordings = selectedDate =>
    new Promise(resolve => {
        const { startDate, endDate } = selectedDate

        // Split date range into chunks (because zoom API supports 1 month max)
        const start = dayjs(startDate)
        const end = dayjs(endDate)
        const splitPoints = []

        for (let current = dayjs(start); current.isBefore(end); current = current.add(30, 'days')) {
            splitPoints.push(dayjs(current))
        }

        const dateRanges = splitPoints.map((splitPoint, index, arr) => {
            if (index === arr.length - 1) {
                return [splitPoint.format(ZOOM_DATEFORMAT), end.format(ZOOM_DATEFORMAT)]
            }

            return [
                splitPoint.format(ZOOM_DATEFORMAT),
                dayjs(splitPoint).add(29, 'days').format(ZOOM_DATEFORMAT)
            ]
        })

        request(`/b/v1/zoom/recordings?dates=${JSON.stringify(dateRanges)}`, 'GET')
            .then(resolve)
            .catch(() => resolve({}))
    })

export const uploadZoomFile = (file, uid, uploadId, uploadedBy) => {
    const { id, title, url, fileSize } = file
    const baseRef = `zoom/${uid}/${id}`
    const data = { uploadId, uploadedBy, title, url, fileSize }

    return set(ref(getDatabase(), baseRef), { data, progress: -1 }).catch(() => {})
}
