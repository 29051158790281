import { useMemo } from 'react'

import { Typography } from '@mui/material'

import { openLink } from 'modules'
import { SpacedGroup } from 'UI/Components'
import { useBoolean, useWindowView } from 'hooks'
import { type AnyPlaybookType, type CallToActionType, type SingleCtaType } from 'app/types'
import { useCtaReport } from './use-cta-report'
import { getRealCtaLink } from 'UI/Routes/quick-guidde/CanvasEditor'

const positions = {
    bottomLeft: {
        bottom: '0',
        left: '0'
    },
    bottomRight: {
        bottom: '0',
        right: '0'
    },
    topRight: {
        top: '0',
        right: '0'
    },
    topLeft: {
        top: '0',
        left: '0'
    }
} as const

const mobilePosition = {
    top: '10px',
    right: '5px'
}

type Props = {
    videoElement: HTMLVideoElement | null
    cta: CallToActionType | SingleCtaType['action']
    playbook: AnyPlaybookType
}

export const CallToAction = ({ cta, playbook, videoElement }: Props) => {
    const { reportEvent } = useCtaReport({ playbook, videoElement })

    const { isMobileView } = useWindowView()

    const imageLoaded = useBoolean()

    const positionStyles = isMobileView ? mobilePosition : positions[cta?.position || 'bottomRight']

    const getText = useMemo(() => {
        if (!cta) return null

        return 'linkType' in cta ? cta.text : cta.text.label
    }, [cta])

    const ctaOpacity = useMemo(() => {
        if (!cta.image) return 1

        return imageLoaded.isTrue ? 1 : 0
    }, [cta.image, imageLoaded.isTrue])

    return (
        <SpacedGroup
            sx={{
                cursor: 'pointer',
                position: 'absolute',
                height: '38px',
                maxWidth: 'max(300px, 40%)',
                zIndex: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: '1px solid #E0E0E0',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
                transition: 'opacity 0.3s linear',
                borderRadius: '4px',
                '&:hover': {
                    boxShadow: '0px 0px 8px 4px rgba(0, 0, 0, 0.25)'
                }
            }}
            m={1}
            onClick={() => {
                reportEvent(cta)

                if (!cta.link) return

                // CTA from quick guide
                if ('linkType' in cta) {
                    if (cta.linkType === 'goToStep' && videoElement) {
                        // For step link calculate the time by the step id and set the video current time
                        videoElement.currentTime = cta?.start || 0
                        videoElement.play()
                        return
                    }

                    openLink(
                        getRealCtaLink(cta),
                        cta.linkType === 'externalLink' ? '_blank' : '_self'
                    ) // externalLink or internalLink
                    return
                }

                // CTA from regular video
                openLink(cta.link, '_blank')
            }}
            title={cta.link}
            style={{
                opacity: ctaOpacity,
                ...positionStyles
            }}
        >
            <SpacedGroup spacing={0}>
                {cta.image && (
                    <img
                        src={cta.image}
                        style={{
                            height: '30px',
                            margin: '0 6px'
                        }}
                        alt="cta"
                        onLoad={imageLoaded.setTrue}
                    />
                )}
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 500,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                        margin: '0 6px !important'
                    }}
                >
                    {getText}
                </Typography>
            </SpacedGroup>
        </SpacedGroup>
    )
}
