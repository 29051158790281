import { memo, useState } from 'react'

import { FieldArray, getIn, useFormikContext } from 'formik'

import {
    Popover,
    Box,
    Button,
    IconButton,
    Paper,
    Typography,
    FormHelperText,
    Stack
} from '@mui/material'

import { ReactComponent as DeleteIcon } from 'assets/icons/bin.svg'

import { SpacedGroup } from 'UI/Components'

import { OptionsAutocomplete } from './OptionsAutocomplete'
import { SelectedOption } from './SelectedOption'
import { OPTION_TYPES } from './const'

export type SearchOptionType = {
    disabled?: boolean
    label: string
    logo?: string
    value: string
    group: string
    suggested?: boolean
}

type Props = {
    name: string
    options: Array<SearchOptionType>
    viewOnlyOption?: null | {
        uid: string
        logo: string
        group: string
        value: string
        label: string
    }
    placeholder: string
    handleRemoveShare?: () => void
    freeSolo?: boolean
    selectedOptionsMaxHeight?: number
}
export const SearchFieldArray = memo(
    ({
        name,
        options,
        viewOnlyOption,
        placeholder,
        handleRemoveShare,
        freeSolo = false,
        selectedOptionsMaxHeight
    }: Props) => {
        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
        const [popperData, setPopperData] = useState<null | {
            idx: number
            option: SearchOptionType
        }>(null)

        const { values, errors } = useFormikContext()

        const valueOptions = (getIn(values, name) || []) as Array<any>

        const fieldError = (errors as any)[name]

        return (
            <FieldArray name={name}>
                {arrayHelpers => (
                    <Box mt={3}>
                        <Box
                            mb={1}
                            sx={{
                                '& .MuiAutocomplete-endAdornment': {
                                    right: 16
                                }
                            }}
                        >
                            <OptionsAutocomplete
                                freeSolo={freeSolo}
                                value={valueOptions}
                                onChange={updatedValues => {
                                    const addedOption = updatedValues.filter(
                                        option =>
                                            !valueOptions?.find(
                                                ({ value }) => option?.value === value
                                            )
                                    )?.[0]

                                    const removedOption = valueOptions.filter(
                                        option =>
                                            !updatedValues?.find(
                                                ({ value }) => option?.value === value
                                            )
                                    )?.[0]

                                    if (removedOption) {
                                        const index = valueOptions.findIndex(
                                            ({ value }) => value === removedOption.value
                                        )
                                        arrayHelpers.remove(index)
                                    }

                                    if (addedOption) arrayHelpers.push(addedOption)
                                }}
                                options={options}
                                placeholder={placeholder}
                                isShowPopupIcon
                            />
                            {typeof fieldError === 'string' && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {fieldError}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box maxHeight={selectedOptionsMaxHeight ?? '33vh'} overflow="auto">
                            {viewOnlyOption && (
                                <Box
                                    py={1}
                                    style={{
                                        boxShadow: 'inset 0 -1px 0 0 #e0e0e0'
                                    }}
                                >
                                    <SelectedOption option={viewOnlyOption} />
                                </Box>
                            )}
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                disablePortal={false}
                                onClose={() => {
                                    setAnchorEl(null)
                                    setPopperData(null)
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <Paper>
                                    <Box px={2} py={3} width={280}>
                                        <Typography
                                            style={{
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontSize: 14
                                            }}
                                        >
                                            Are you sure you want to remove the access for{' '}
                                            {popperData?.option.label}?
                                        </Typography>
                                        <SpacedGroup mt={3}>
                                            <Button
                                                color="inherit"
                                                style={{
                                                    backgroundColor: '#F5F5F5',
                                                    color: 'rgba(9, 12, 16, 0.6)',
                                                    boxShadow: 'none',
                                                    letterSpacing: 1.25
                                                }}
                                                onClick={() => {
                                                    setAnchorEl(null)
                                                    setPopperData(null)
                                                }}
                                            >
                                                CANCEL
                                            </Button>
                                            <Button
                                                style={{
                                                    boxShadow: 'none',
                                                    letterSpacing: 1.25,
                                                    whiteSpace: 'nowrap'
                                                }}
                                                variant="contained"
                                                onClick={() => {
                                                    if (!popperData) return

                                                    setAnchorEl(null)
                                                    const { idx, option } = popperData

                                                    const isSuggestedOption =
                                                        option.suggested &&
                                                        option.group !== OPTION_TYPES.USERS

                                                    if (isSuggestedOption) {
                                                        arrayHelpers?.remove(idx)
                                                    } else {
                                                        if (typeof idx === 'number') {
                                                            arrayHelpers?.remove(idx)
                                                        }
                                                        handleRemoveShare?.()
                                                    }
                                                }}
                                            >
                                                REMOVE ACCESS
                                            </Button>
                                        </SpacedGroup>
                                    </Box>
                                </Paper>
                            </Popover>
                            {valueOptions?.map((option, idx) => (
                                <Box
                                    py={1}
                                    key={idx}
                                    sx={theme => ({
                                        cursor: 'pointer',
                                        '&:hover': {
                                            background: theme.palette.action.hover
                                        }
                                    })}
                                >
                                    <SelectedOption
                                        option={option}
                                        controls={
                                            <Stack alignItems="flex-end" flex={1}>
                                                <IconButton
                                                    size="small"
                                                    aria-label="delete"
                                                    onClick={e => {
                                                        setAnchorEl(e.currentTarget)
                                                        setPopperData({ idx, option })
                                                    }}
                                                >
                                                    <DeleteIcon style={{ color: '#090C10' }} />
                                                </IconButton>
                                            </Stack>
                                        }
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
            </FieldArray>
        )
    }
)
