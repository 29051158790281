import { ReactComponent as ProPlanStartedImage } from 'assets/images/pro-update.svg'

import { ServiceUsageDialog } from './ServiceUsageDialog'

type Props = {
    onClose: () => void
}

export const ProPlanStartedDialog = ({ onClose }: Props) => (
    <ServiceUsageDialog
        title="Congratulations!"
        onClose={onClose}
        data-test="planPopup-text"
        description="Your plan has been successfully upgraded! You now have access to even more exciting features and benefits."
        actionText="Let’s go!"
        onActionClick={onClose}
        Image={<ProPlanStartedImage />}
    />
)
