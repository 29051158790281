import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { type RectangleShapeType, Shape } from 'app/types'

import { useBrandKit, noBrandKitBackgroundId } from 'hooks'

import { getGradientPoints } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { DEFAULT_STEP } from './AddNewStep'

export const useAddStepBackground = () => {
    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const {
        layers,
        windowDimensions: { innerWidth, innerHeight }
    } = steps[activeStep] || DEFAULT_STEP

    const { brandKitBackground } = useBrandKit()

    return useMemo(() => {
        if (!brandKitBackground || brandKitBackground?.id === noBrandKitBackgroundId) return null

        const bgLayer = layers.find(
            layer => layer.type === Shape.Rectangle && layer.isBackground
        ) as RectangleShapeType

        if (!bgLayer) {
            // Image as background
            if ('url' in brandKitBackground && 'scaleFactor' in brandKitBackground) {
                return { url: brandKitBackground.url, scaleFactor: brandKitBackground.scaleFactor }
            }

            // If no background layer, use the active gradient from the brand kit
            const { fillLinearGradientStartPoint, fillLinearGradientEndPoint } = getGradientPoints(
                innerWidth,
                innerHeight,
                brandKitBackground.angle || 90
            )

            return {
                fillLinearGradientStartPoint,
                fillLinearGradientEndPoint,
                fillLinearGradientColorStops: brandKitBackground.canvasGradient
            }
        }

        // If there is a background layer, use the fill or gradient or image as bg from it
        return {
            ...(bgLayer.fill ? { fill: bgLayer.fill } : {}),
            ...(bgLayer.fillLinearGradientColorStops
                ? { fillLinearGradientColorStops: bgLayer.fillLinearGradientColorStops }
                : {}),
            ...(bgLayer.fillLinearGradientStartPoint
                ? { fillLinearGradientStartPoint: bgLayer.fillLinearGradientStartPoint }
                : {}),
            ...(bgLayer.fillLinearGradientEndPoint
                ? { fillLinearGradientEndPoint: bgLayer.fillLinearGradientEndPoint }
                : {}),
            ...(bgLayer.url ? { url: bgLayer.url } : {}),
            ...(bgLayer.scaleFactor ? { scaleFactor: bgLayer.scaleFactor } : {})
        }
    }, [brandKitBackground, innerHeight, innerWidth, layers])
}
