import { useRef, useState } from 'react'

import { Box } from '@mui/material'

import { type BrandKitType, type UploadFileType } from 'app/types'

import { type BrandKitResponseType, useBoolean, useNotification } from 'hooks'

import { ReactComponent as Icon } from 'assets/icons/box.svg'

import { cardLimits } from '../PageBrandKit'

import { CropperDialog } from './dialogs'
import { type SectionProps, ItemCard, RadioLabel, Section } from './components'

type Props = {
    values: BrandKitType['logoAndFavicon']
    uploadFile: (data: UploadFileType) => Promise<BrandKitResponseType>
} & SectionProps

const inputAttributes = {
    type: 'file',
    style: { display: 'none' },
    accept: 'image/png'
}

export const LogoAndFavicon = ({
    popupProps,
    values,
    uploadFile,
    onDelete,
    onSetActive
}: Props) => {
    const { showSuccessNotification } = useNotification()

    const [newImage, setNewImage] = useState('')
    const [editData, setEditData] = useState<{ id: string; url: string } | null>(null)

    const newImageRef = useRef<HTMLInputElement>(null)

    const uploading = useBoolean()

    const handleUpload = (data: Omit<UploadFileType, 'type'>, callBack: () => void) => {
        uploading.setTrue()

        return uploadFile({ ...data, type: 'logo&favicon' })
            .then(() => {
                callBack()
                uploading.setFalse()
            })
            .finally(uploading.setFalse)
    }

    return (
        <>
            <input
                ref={newImageRef}
                {...inputAttributes}
                onChange={e => {
                    const file = e.target.files?.[0]

                    if (!file) return

                    setNewImage(URL.createObjectURL(file))
                }}
            />

            <CropperDialog
                isLoading={uploading.isTrue}
                isOpen={Boolean(newImage)}
                cropBoxResizable={true}
                initialAspectRatio={2}
                maxHeight={250}
                dimensions={[250, 175]}
                title="Edit logo"
                onClose={() => setNewImage('')}
                url={newImage}
                onDelete={() => setNewImage('')}
                onSubmit={file => {
                    handleUpload(
                        {
                            file
                        },
                        () => {
                            showSuccessNotification('Logo added')
                            setNewImage('')
                        }
                    )
                }}
            />

            <Section
                title="Logo"
                popupProps={popupProps}
                subtitle={
                    <>
                        <Box fontSize="inherit" color="inherit">
                            You can use different logos, we suggest adding light and dark versions
                            of your logo.
                        </Box>
                        <Box fontSize="inherit" color="inherit">
                            Recommended sizes: w 100X h 50 px, .png format
                        </Box>
                    </>
                }
                content={values.data.map(item => {
                    const { id, url } = item
                    const isActive = values.active === id

                    const isOneItemInList = values.data.length < 2

                    const disableDelete = isActive || isOneItemInList

                    const tooltipText = isOneItemInList
                        ? "Last selection can't be deleted"
                        : "Active selection can't be deleted"

                    return (
                        <ItemCard
                            popupProps={popupProps}
                            onEdit={() => setEditData({ url, id })}
                            onDelete={() => {
                                onDelete({ logoAndFavicon: id }).then(() => {
                                    showSuccessNotification('Image removed')
                                    setEditData(null)
                                })
                            }}
                            showTooltip={disableDelete}
                            disableDelete={disableDelete}
                            tooltipText={tooltipText}
                            disableLabelButton={isActive}
                            label={
                                <RadioLabel
                                    isActive={isActive}
                                    validateClick={popupProps.validateClick}
                                    onClick={() => {
                                        onSetActive({
                                            logoAndFavicon: id
                                        }).then(() => {
                                            showSuccessNotification('New logo was set as default')
                                        })
                                    }}
                                />
                            }
                            key={id}
                        >
                            <Box
                                width="100%"
                                height="100%"
                                position="absolute"
                                justifyContent="center"
                                display="flex"
                                alignItems="center"
                            >
                                <img
                                    src={url}
                                    style={{ objectFit: 'contain', maxHeight: 40 }}
                                    width="100px"
                                    height="auto"
                                />
                            </Box>
                        </ItemCard>
                    )
                })}
                actionTitle="Add Logo or Favicon"
                showActionCard={cardLimits.logoAndFavicon > values.data.length}
                actionIcon={Icon}
                onActionItemClick={() => newImageRef.current?.click?.()}
            />

            {editData && (
                <CropperDialog
                    isLoading={uploading.isTrue}
                    isOpen={true}
                    cropBoxResizable={true}
                    maxHeight={250}
                    dimensions={[250, 175]}
                    initialAspectRatio={2}
                    title="Edit Logo"
                    onClose={() => setEditData(null)}
                    url={editData?.url}
                    onDelete={() => {
                        onDelete({ logoAndFavicon: editData?.id }).then(() => {
                            setEditData(null)
                            showSuccessNotification('Image removed')
                        })
                    }}
                    onSubmit={file => {
                        handleUpload({ file, id: editData?.id }, () => {
                            setEditData(null)
                            showSuccessNotification('Image edited')
                        })
                    }}
                />
            )}
        </>
    )
}
