import * as types from 'ducks/types'

import { type ActionType, type Reducer } from 'ducks/common'

const initialState = {
    isInstalled: false,
    qgIsRecording: false
}

export const extensionStatusReducer: Reducer<typeof initialState> = (
    state = initialState,
    action: ActionType
): typeof initialState => {
    switch (action.type) {
        case types.SET_EXTENSION_STATUS: {
            return { ...state, isInstalled: action.payload }
        }
        case types.SET_QUICK_GUIDDE_RECORDING_STATUS: {
            return {
                ...state,
                qgIsRecording: action.payload
            }
        }
        default: {
            return state
        }
    }
}
