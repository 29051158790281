import { useEffect, useMemo } from 'react'

import { getAuth, signInAnonymously } from 'firebase/auth'
import { auth } from 'modules'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useLocation } from 'react-router-dom'
import { paths } from 'app/paths'

export const useAnonymAuth = () => {
    const [user, isLoadingAuth] = useAuthState(auth)
    const location = useLocation()

    useEffect(() => {
        // Do not call sign in on PageFinishSignUp component (paths.emailLink) because
        // on component render we trigger user login with magic link which collides with anonymous login
        // and sometimes the last one overwrites the first one
        if (isLoadingAuth || user || location.pathname === paths.emailLink) return

        signInAnonymously(getAuth()).catch(({ code, message }) => {
            if (code === 'auth/operation-not-allowed') {
                console.error('You must enable Anonymous auth in the Firebase Console.')
            } else {
                console.error(message, 'signInAnonymously')
            }
        })
    }, [isLoadingAuth, location.pathname, user])

    return useMemo(
        () => ({
            isLoggedIn: user,
            isAnonymous: user?.isAnonymous,
            isLoadingAuth: isLoadingAuth
        }),
        [isLoadingAuth, user]
    )
}
