import { memo } from 'react'

import { TextOverflow } from '@guidde-co/shared.textoverflow'
import { Box, Typography } from '@mui/material'

import { ReactComponent as ViewsIcon } from 'assets/icons/icon-eye.svg'
import { ReactComponent as IconRating } from 'assets/icons/icon-rating.svg'

import { SpacedGroup } from 'UI/Components'

import { type PlaybookType } from 'app/types'
import { formatDate } from 'modules'

type Props = {
    playbookList: Array<PlaybookType>
    onPlaybookClick: (playbookId: string) => void
    activePlaybookId: string
}

export const PlaybookList = memo(({ playbookList, activePlaybookId, onPlaybookClick }: Props) => {
    return (
        <SpacedGroup flexDirection="column" spacing={1.5}>
            {playbookList.map(playbook => (
                <Playbook
                    playbook={playbook}
                    key={playbook.id + activePlaybookId}
                    onClick={() => onPlaybookClick(playbook.id)}
                    active={activePlaybookId === playbook.id}
                />
            ))}
        </SpacedGroup>
    )
})

type PlaybookProps = {
    playbook: PlaybookType
    active: boolean
    onClick: () => void
}

const Playbook = ({ playbook, active, onClick }: PlaybookProps) => {
    return (
        <Box
            sx={{
                cursor: 'pointer',
                borderRadius: '4px',
                overflow: 'hidden'
            }}
            onClick={onClick}
            border={`1px solid ${active ? '#FED243' : '#E0E0E0'}`}
        >
            <Box display="flex" style={{ background: '#FAFAFA' }}>
                <img
                    src={playbook.screenshotUrl}
                    alt="pb_img"
                    style={{
                        width: '100px',
                        height: '65px',
                        objectFit: 'cover'
                    }}
                />

                <Box py={1.2} px={1}>
                    <TextOverflow>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '12px'
                            }}
                        >
                            {playbook.title}
                        </Typography>
                    </TextOverflow>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1.1}
                color="#828282"
                style={{ background: '#FFF' }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginRight: 1,
                        maxWidth: '70%',
                        color: 'inherit'
                    }}
                >
                    {playbook.username} &#9679; {formatDate(playbook.uploadedAt)}
                </Typography>
                <SpacedGroup>
                    {playbook?.views !== 0 && (
                        <SpacedGroup spacing={0.5}>
                            <ViewsIcon width={20} height={20} color="inherit" />
                            <Typography variant="body2" color="inherit">
                                {playbook.views}
                            </Typography>
                        </SpacedGroup>
                    )}
                    {playbook.rating > 0 && (
                        <SpacedGroup spacing={0.5}>
                            <IconRating
                                width={20}
                                height={20}
                                color="inherit"
                                style={{ transform: 'translate(6px, 2px)' }}
                            />
                            <Typography variant="body2" color="inherit">
                                {playbook.rating}%
                            </Typography>
                        </SpacedGroup>
                    )}
                </SpacedGroup>
            </Box>
        </Box>
    )
}
