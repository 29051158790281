import { memo, useMemo, useState } from 'react'

import { type AnyPlaybookType, PlaybookMode } from 'app/types'

import { useAuth, useBoolean, usePlaylistsHttp } from 'hooks'
import { logToAnalytics } from 'modules'

import { ShareCommonDialog } from './ShareCommonDialog'
import EmptyImage from 'assets/images/playlist_small.svg'

type Props = {
    isOpen?: boolean
    selectedPlaybooks: Array<AnyPlaybookType>
    onClose: () => void
    searchRefresh?: () => void
    isSearchRevalidating?: boolean
    singleMode?: boolean
}

export const ShareToPlaylist = memo(
    ({ isOpen = true, selectedPlaybooks, onClose, searchRefresh = () => {} }: Props) => {
        const { orgId, uid } = useAuth()

        const [title, setTitle] = useState('')

        const creating = useBoolean()

        const selectedPlaybooksIds = selectedPlaybooks
            .filter(({ mode }) => mode !== PlaybookMode.Playlist)
            .map(({ id }) => id)

        const loading = useBoolean()

        const {
            addToPlaylists,
            removeFromPlaylists,
            isPlaylistsLoading,
            playlistsOptions,
            createPlaylist
        } = usePlaylistsHttp(selectedPlaybooksIds)

        const handleCreate = () => {
            logToAnalytics('playlistsModal_CreateBtn_Clicked', { orgId, userId: uid })

            createPlaylist(title, selectedPlaybooksIds).then(() => {
                searchRefresh()
                onClose()
            })
        }

        const handleItemClick = async (id: string, wasSelected: boolean) => {
            // for multiselect we don't allow to remove videos from playlist, only adding
            if (!wasSelected) {
                await addToPlaylists([id])
            } else {
                await removeFromPlaylists([id])
            }

            searchRefresh()
        }

        const isEmptyState = playlistsOptions.length === 0

        const showSearchField = useMemo(() => {
            if (isPlaylistsLoading) return false
            return !isEmptyState
        }, [isPlaylistsLoading, isEmptyState])

        const emptyStateText = useMemo(() => {
            if (isPlaylistsLoading) return undefined
            return isEmptyState ? 'Create your first playlist' : undefined
        }, [isPlaylistsLoading, isEmptyState])

        const emptyStateImage = useMemo(() => {
            if (isPlaylistsLoading) return undefined

            return isEmptyState ? EmptyImage : undefined
        }, [isPlaylistsLoading, isEmptyState])

        const options = useMemo(() => {
            return playlistsOptions.map(({ playlist, isSelected }) => ({
                id: playlist.id,
                name: playlist.title,
                visibility: playlist.visibility,
                isSelected
            }))
        }, [playlistsOptions])

        return (
            <ShareCommonDialog
                maxWidth={450}
                label="New Playlist"
                header="Add to playlist"
                buttonLabel="Add new Playlist"
                showCreateButton={creating.isTrue}
                title={title}
                setTitle={setTitle}
                onAddClick={creating.setTrue}
                onCreateClick={handleCreate}
                onItemClick={handleItemClick}
                disableCreate={title.length < 1}
                isLoading={loading.isTrue || isPlaylistsLoading}
                showSearchField={showSearchField}
                emptyStateText={emptyStateText}
                emptyStateImage={emptyStateImage}
                onClose={() => {
                    onClose()
                    setTitle('')
                    creating.setFalse()
                }}
                isOpen={isOpen}
                options={options}
            />
        )
    }
)
