import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from '@mui/material'
import CropIcon from '@mui/icons-material/Crop'

import { ImageCropper } from 'UI/Components'

import { type ImageShapeType, type StepType, Shape } from 'app/types'

import { uuid } from 'modules'
import { useAuth, useBoolean } from 'hooks'
import { setStepLayer } from 'ducks'

type Props = {
    step: StepType
}

export const ZoomBackgroundImage = memo(({ step }: Props) => {
    const open = useBoolean()

    const dispatch = useDispatch()

    const { uid } = useAuth()
    const { playbookId } = useParams<{ playbookId: string }>()

    const bgImageLayer = step.layers?.find(
        layer => layer.type === Shape.Image && layer.isBackground
    ) as ImageShapeType | undefined

    if (!bgImageLayer) return null

    const {
        id: bgImageId,
        originalScale = 1,
        originalUrl = '',
        scaleY,
        scaleX,
        width: bgImageWidth,
        height: bgImageHeight,
        url: bgImageUrl
    } = bgImageLayer

    const onResetClick = () => {
        if (!bgImageLayer) return

        dispatch(
            setStepLayer({
                layerId: bgImageId,
                layer: {
                    ...bgImageLayer,
                    url: originalUrl,
                    scaleX: originalScale,
                    scaleY: originalScale
                }
            })
        )
    }
    return (
        <>
            <Button
                variant="text"
                color="inherit"
                onClick={open.setTrue}
                style={{ minWidth: 30 }}
                startIcon={<CropIcon fontSize="small" />}
            >
                Crop
            </Button>

            {open.isTrue && (
                <ImageCropper
                    initialPreviewURL={bgImageUrl}
                    labelComponent={null}
                    title="Drag & Zoom"
                    showReplacePhotoButton={true}
                    aspectRatio={bgImageWidth / bgImageHeight}
                    isTempStorage
                    storagePath={`imageUploads/${uid}/${playbookId}_${uuid()}`}
                    onDone={({ height, width, url }) => {
                        dispatch(
                            setStepLayer({
                                layerId: bgImageId,
                                layer: {
                                    ...bgImageLayer,
                                    scaleX: (bgImageWidth * scaleX) / width,
                                    scaleY: (bgImageHeight * scaleY) / height,
                                    originalUrl: originalUrl || bgImageUrl,
                                    originalScale: originalScale || scaleX,
                                    url,
                                    width,
                                    height
                                }
                            })
                        )
                        open.setFalse()
                    }}
                    disableReset={!originalUrl}
                    tooltipTitle="Revert to Default"
                    onReset={onResetClick}
                    onClose={open.setFalse}
                />
            )}
        </>
    )
})
