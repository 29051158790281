import dayjs from 'dayjs'

type ThumbnailType = {
    height: string
    url: string
    width: string
}

export type YoutubeItemType = {
    etag: string
    id: string
    kind: string
    snippet: {
        channelId: string
        channelTitle: string
        description: string
        localized: { title: string; description: string }
        publishedAt: string
        resourceId: {
            videoId: string
        }
        thumbnails: {
            default: ThumbnailType
        }
        title: string
    }
}

export const youtubeColumns = [
    {
        value: 'title',
        label: 'Title',
        cellContent: (file: YoutubeItemType) => file.snippet.title
    },
    {
        value: 'publishedAt',
        label: 'Creating Date',
        isSecondary: false,
        cellContent: (file: YoutubeItemType) =>
            dayjs(file.snippet.publishedAt).format('DD MMM, YYYY')
    }
]

const compareStrings = (a: string, b: string) =>
    a.localeCompare(b, undefined, { sensitivity: 'base' })

export type SortingByType = 'title' | 'publishedAt'

export const sortYoutubeFiles = (
    sortBy: SortingByType,
    sortDesc: boolean,
    a: YoutubeItemType,
    b: YoutubeItemType
) => {
    const paramA = a.snippet[sortBy]
    const paramB = b.snippet[sortBy]

    if (!paramA && !paramB) {
        return compareStrings(a.snippet.title, b.snippet.title)
    }

    if (sortBy === 'title') {
        return sortDesc ? compareStrings(paramB, paramA) : compareStrings(paramA, paramB)
    }

    if (sortBy === 'publishedAt') {
        const momentA = dayjs(paramA).valueOf()
        const momentB = dayjs(paramB).valueOf()

        return sortDesc ? momentA - momentB : momentB - momentA
    }

    return 0
}
