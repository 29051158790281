import { memo } from 'react'

import { styled, Box, Typography } from '@mui/material'

import { getIconColor, getPlaybookTypeIcon } from '@guidde-co/shared.playbook-visibility'

import { SpacedGroup } from 'UI/Components'
import IconWatch from 'assets/images/watch.png'

import { type AnyPlaybookType, PlaybookMode } from 'app/types'
import { formatDate, formatTime } from 'modules'

const PREFIX = 'PlaybookMetadata'

const classes = {
    iconWatch: `${PREFIX}-iconWatch`,
    image: `${PREFIX}-image`,
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    appLogo: `${PREFIX}-appLogo`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.iconWatch}`]: {
        width: '15px',
        height: '15px',
        color: 'rgba(120, 144, 156, 0.87)'
    },
    [`& .${classes.image}`]: {
        width: '150px',
        height: '100px',
        overflow: 'hidden',
        borderRadius: '5px',
        objectFit: 'cover'
    },
    [`& .${classes.title}`]: {
        fontWeight: theme.typography.fontWeightBold,
        maxWidth: '70vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    [`& .${classes.content}`]: {
        padding: theme.spacing(1, 2, 0),
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    [`& .${classes.appLogo}`]: {
        maxHeight: '25px',
        width: 'auto'
    }
}))

type Props = {
    playbook: AnyPlaybookType
}

export const PlaybookMetadata = memo(({ playbook }: Props) => {
    const { username, duration, screenshotUrl, title, uploadedAt, visibility, applications, mode } =
        playbook

    const isPlaylist = mode === PlaybookMode.Playlist

    return (
        <StyledBox position="relative" mb={2}>
            <Typography variant="h5" className={classes.title}>
                {title}
            </Typography>

            <Box display="flex" mt={2}>
                {screenshotUrl && (
                    <img alt="placeholder" className={classes.image} src={screenshotUrl} />
                )}

                <Box className={classes.content}>
                    <SpacedGroup>
                        <Typography variant="caption" color="textSecondary">
                            {username}
                        </Typography>
                        <Typography variant="caption">{formatDate(uploadedAt)}</Typography>
                        {duration && (
                            <SpacedGroup spacing={0.5}>
                                <img className={classes.iconWatch} src={IconWatch} alt="watch" />
                                <Typography variant="caption" color="textSecondary">
                                    {formatTime(duration)}
                                </Typography>
                            </SpacedGroup>
                        )}
                        <SpacedGroup
                            spacing={1}
                            color={getIconColor(visibility)}
                            display="flex"
                            alignItems="center"
                        >
                            {getPlaybookTypeIcon(visibility)}{' '}
                            <Typography color="inherit" variant="caption">
                                {visibility}
                            </Typography>
                        </SpacedGroup>
                    </SpacedGroup>
                    <SpacedGroup flexWrap="wrap">
                        {applications.length > 0 && (
                            <SpacedGroup spacing={0.5} mt={1}>
                                {isPlaylist && 'Apps in playlist:'}
                                {applications.map((it, i) => (
                                    <img
                                        key={i}
                                        src={it.applicationLogo}
                                        className={classes.appLogo}
                                    />
                                ))}
                            </SpacedGroup>
                        )}
                    </SpacedGroup>
                </Box>
            </Box>
        </StyledBox>
    )
})
