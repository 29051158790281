import { useState } from 'react'

import { ClickAwayListener, IconButton, MenuItem, MenuList, Popper } from '@mui/material'
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'

import { useBoolean } from 'hooks'
import { logToAnalytics } from 'modules'

import { type PlaybookType } from 'app/types'

import { UploadSubtitleModal } from 'UI/Components'

type Props = {
    playbook: PlaybookType
}

export const SubtitlePopper = ({ playbook }: Props) => {
    const loading = useBoolean()
    const modalOpen = useBoolean()

    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

    const downloadSubtitle = async () => {
        logToAnalytics('download_subtitles', {
            playbookId: playbook.id
        })

        loading.setTrue()
        const res = await fetch(playbook.subtitlesUrl)
        const blob = await res.blob()
        const objectURL = URL.createObjectURL(blob)
        // create a tag with attributes
        let a = document.createElement('a')
        a.href = objectURL
        a.setAttribute('download', `${playbook?.title?.replace(/\./g, '-') || playbook.id}.vtt`)
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        // clean up
        document.body.removeChild(a)
        URL.revokeObjectURL(a.href)
        loading.setFalse()
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton size="small" onClick={e => setAnchorEl(anchorEl ? null : e.currentTarget)}>
                <ClosedCaptionIcon />
            </IconButton>

            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                sx={theme => ({
                    background: 'white',
                    borderRadius: theme.spacing(0.5),
                    boxShadow: theme.shadows[8],
                    zIndex: 2
                })}
            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <MenuList sx={{ padding: 0 }}>
                        <MenuItem disabled={!playbook.subtitlesUrl} onClick={downloadSubtitle}>
                            Download Subtitles
                        </MenuItem>
                        <MenuItem onClick={modalOpen.setTrue}>Upload Subtitles</MenuItem>
                    </MenuList>
                </ClickAwayListener>
            </Popper>

            <UploadSubtitleModal playbook={playbook} open={modalOpen} />
        </>
    )
}
