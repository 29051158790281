import { memo, useState } from 'react'

import {
    Box,
    LinearProgress,
    MenuItem,
    Select,
    Typography,
    Tooltip,
    styled,
    capitalize
} from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/LockOutlined'
import PublicIcon from '@mui/icons-material/PublicOutlined'

import { ConfirmationDialog, SpacedGroup, TeamIcon } from 'UI/Components'

import {
    type ShareProps,
    useAuth,
    useBoolean,
    useNotification,
    useOrgFeatureFlags,
    usePlaybookShare,
    useServiceUsage,
    logVisibilityChanging
} from 'hooks'
import { type AnyPlaybookType, type PlaybookVisibilityType, PlaybookMode } from 'app/types'
import { generalErrorMessage, logToAnalytics, option } from 'modules'

type VisibilityLevelType = ShareProps['visibilityLevel']

const VisibilityLevel = {
    restricted: 'restricted',
    anyoneInOrg: 'anyoneInOrg',
    anyone: 'anyone'
} as const

const StyledLinearProgress = styled(LinearProgress)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    borderRadius: '4px'
})

const StyledSelect = styled(Select)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 600,
    paddingBottom: 0,
    '&:before, &:after': {
        display: 'none'
    },
    '& svg': {
        color: theme.palette.text.primary
    }
}))

const RoundedIcon = styled(Box)({
    background: '#EDEEEF',
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    position: 'relative',
    '& svg': {
        color: 'inherit'
    }
})

const getPublicityList = ({
    orgName,
    isPrivateAccount,
    disableExternalSharing
}: {
    orgName: string
    isPrivateAccount: boolean
    disableExternalSharing?: boolean
}) => [
    {
        ...option(VisibilityLevel.anyone, 'Public'),
        disabled: disableExternalSharing,
        disabledTooltipMessage: 'Please contact your admin for more info'
    },
    ...(isPrivateAccount ? [] : [option(VisibilityLevel.anyoneInOrg, capitalize(orgName))]),
    option(VisibilityLevel.restricted, 'Restricted')
]

type OptionType = ReturnType<typeof getPublicityList>[0]

type GetPublicityStaticProps = {
    id: VisibilityLevelType
    target?: string
}

const getPublicityStaticData = ({ id, target }: GetPublicityStaticProps) => {
    switch (id) {
        case VisibilityLevel.restricted: {
            return {
                visibilityIcon: <PeopleAltIcon />,
                visibilityMessage: 'Only members and spaces added can open this link',
                visibilitySuccessMessage: `${target} is Restricted now`
            }
        }
        case VisibilityLevel.anyoneInOrg: {
            return {
                visibilityIcon: <TeamIcon />,
                visibilityMessage: 'All members in this account can view',
                visibilitySuccessMessage: `${target} is shared with your organization now`
            }
        }
        case VisibilityLevel.anyone: {
            return {
                visibilityIcon: <PublicIcon />,
                visibilityMessage: 'Anyone with the link can view',
                visibilitySuccessMessage: `${target} is Public now`
            }
        }
        default: {
            return {}
        }
    }
}

const getActiveOption = (list: Array<OptionType>, isPublic?: boolean, isMainIncluded?: boolean) => {
    if (isPublic) return list.find(({ value }) => value === VisibilityLevel.anyone)
    if (isMainIncluded) return list.find(({ value }) => value === VisibilityLevel.anyoneInOrg)
    return list.find(({ value }) => value === VisibilityLevel.restricted)
}

type Props = {
    playbook: AnyPlaybookType
    onChange?: (playbookId: string, newVisibility?: PlaybookVisibilityType) => void
    disabled?: boolean
}

export const ShareLink = memo(({ playbook, onChange, disabled }: Props) => {
    const {
        isPublic,
        id: playbookId,
        uploadedByUid,
        title,
        teams,
        isMainIncluded,
        orgName = 'Organization',
        mode
    } = playbook

    const isPlaylist = mode === PlaybookMode.Playlist

    const { uid, isPrivateAccount } = useAuth()

    const isOwner = uid === uploadedByUid

    const showConfirmation = useBoolean()

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const { hasPrivacyControl } = useServiceUsage()
    const { disableExternalSharing } = useOrgFeatureFlags()

    const publicity = getPublicityList({ orgName, isPrivateAccount, disableExternalSharing })
    const [linkType, setLinkType] = useState<OptionType>(
        getActiveOption(publicity, isPublic, isMainIncluded) || publicity[0]
    )
    const target = isPlaylist ? 'Playlist' : 'Video'
    const { visibilityIcon, visibilityMessage } = getPublicityStaticData({
        id: linkType.value as VisibilityLevelType,
        target
    })

    const resetVisibilityOption = () =>
        setLinkType(getActiveOption(publicity, isPublic, isMainIncluded) || publicity[0])

    const { sharePlaybook, isSharing, ApprovalModal } = usePlaybookShare({
        onApprovalClose: resetVisibilityOption,
        onSuccess: (data, input) => {
            if (data?.code === 422) return

            showSuccessNotification(
                getPublicityStaticData({ target, id: input.visibilityLevel })
                    .visibilitySuccessMessage
            )
            onChange?.(playbook.id, data.newData[0].visibility)

            logVisibilityChanging({ playbook, visibilityLevel: input.visibilityLevel || '' })
            logToAnalytics('change_visibility', {
                playbookType: playbook.mode,
                isPublic: input.visibilityLevel === 'anyone',
                visibilityLevel: input.visibilityLevel
            })
        },
        onFailure: (error: Error) => {
            resetVisibilityOption()
            console.error(error)
            showErrorNotification(error?.message || generalErrorMessage)
        }
    })

    const changePublicity = (visibilityLevel: ShareProps['visibilityLevel']) => {
        sharePlaybook({
            visibilityLevel,
            playbooksIds: [playbookId],
            shareMode: 'overwriteByOption'
        })
    }

    const isSharingEnabled = hasPrivacyControl || !isPublic

    return (
        <>
            <Box borderRadius="4px" p={2}>
                {isSharing && <StyledLinearProgress />}

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    style={{ opacity: isSharingEnabled ? 1 : 0.6 }}
                >
                    <Tooltip
                        arrow
                        PopperProps={{
                            sx: theme => ({
                                zIndex: theme.zIndex.modal + 1
                            })
                        }}
                        title={isSharingEnabled ? '' : 'Available on the Business Plan'}
                        placement="right"
                    >
                        <Box>
                            <Box display="flex" alignItems="center">
                                <RoundedIcon mr={1}>{visibilityIcon}</RoundedIcon>
                                <Box position="relative">
                                    <StyledSelect
                                        data-test="visibility-select"
                                        value={linkType}
                                        onChange={e => {
                                            const option = e.target.value as OptionType

                                            if ('disabled' in option && option.disabled) return

                                            setLinkType(option)
                                            // If admin tries to change publicity from Public to Private and it was not published to any space / Main space -
                                            // then we need to show Confirmation dialog as after that Admin will not be able to open that playbook
                                            if (
                                                !isOwner &&
                                                !teams?.length &&
                                                !isMainIncluded &&
                                                option.value === VisibilityLevel.restricted
                                            ) {
                                                return showConfirmation.setTrue()
                                            }

                                            changePublicity(option.value as VisibilityLevelType)
                                        }}
                                        disabled={disabled || isSharing || !isSharingEnabled}
                                        MenuProps={{
                                            sx: {
                                                transformX: '80px !important'
                                            },
                                            MenuListProps: {
                                                sx: {
                                                    padding: '8px',
                                                    width: 225
                                                }
                                            }
                                        }}
                                        renderValue={value => (value as OptionType).label}
                                    >
                                        {publicity.map(option => (
                                            <Tooltip
                                                key={option.value}
                                                // @ts-ignore value of type of OptionType
                                                value={option}
                                                data-test={`${option.value}-item`}
                                                title={
                                                    ('disabledTooltipMessage' in option &&
                                                        option.disabledTooltipMessage) ||
                                                    ''
                                                }
                                                placement="right"
                                                disableFocusListener={
                                                    'disabled' in option && !option.disabled
                                                }
                                                disableHoverListener={
                                                    'disabled' in option && !option.disabled
                                                }
                                                disableTouchListener={
                                                    'disabled' in option && !option.disabled
                                                }
                                            >
                                                <MenuItem
                                                    divider={true}
                                                    sx={theme => ({
                                                        width: '100%',
                                                        fontWeight: 600,
                                                        fontSize: '14px',
                                                        padding: theme.spacing(1.7, 2),
                                                        '& svg': {
                                                            color: '#000'
                                                        }
                                                    })}
                                                    disabled={
                                                        (!hasPrivacyControl &&
                                                            option.value !== 'anyone') ||
                                                        ('disabled' in option && option.disabled)
                                                    }
                                                >
                                                    <SpacedGroup height={20}>
                                                        <Box width={20}>
                                                            {
                                                                getPublicityStaticData({
                                                                    id: option.value as VisibilityLevelType
                                                                }).visibilityIcon
                                                            }
                                                        </Box>

                                                        <Typography
                                                            variant="inherit"
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500
                                                            }}
                                                        >
                                                            {option.label}
                                                        </Typography>
                                                    </SpacedGroup>
                                                </MenuItem>
                                            </Tooltip>
                                        ))}
                                    </StyledSelect>

                                    <Typography color="textSecondary" style={{ fontSize: 14 }}>
                                        {visibilityMessage}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>

            {showConfirmation.isTrue && (
                <ConfirmationDialog
                    isOpen
                    title="Make private"
                    onClose={() => {
                        showConfirmation.setFalse()
                        resetVisibilityOption()
                    }}
                    onConfirm={() => {
                        showConfirmation.setFalse()
                        changePublicity(linkType.value as VisibilityLevelType)
                    }}
                    text={
                        <>
                            Are you sure you want to make <b>{title} </b>
                            {target.toLowerCase()} private?
                        </>
                    }
                />
            )}
            {ApprovalModal}
        </>
    )
})
