import { memo } from 'react'

import { ReactComponent as EmojiNotHelpful } from 'assets/icons/icon-feedback-not-helpful.svg'
import { ReactComponent as EmojiHelpful } from 'assets/icons/icon-feedback-helpful.svg'

import { Button, Box, Divider, styled } from '@mui/material'

import {
    type UseBooleanType,
    useGetPlaybookReaction,
    useSetPlaybookReaction,
    reactionValues
} from 'hooks'

const IconWrapper = styled(Box)({
    '& svg': {
        width: '24px',
        height: '24px',
        minWidth: '24px',
        cursor: 'pointer',
        color: '#828282',
        transition: 'transform 0.2s ease 0s',
        transform: 'translateY(2px)'
    }
})

const StyledButton = styled(Button)({
    justifyContent: 'flex-start',
    color: `#828282`,
    display: 'flex',
    alignItems: 'end',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    '&:hover svg': { fill: '#ffca28', transform: 'translateY(-1px)' },
    '&.Mui-disabled': {
        color: `#828282`,
        backgroundColor: `transparent !important`,
        '&:hover': {
            backgroundColor: `transparent !important`
        }
    }
})

export const minimalRatingToShow = 50

export const negativeAnswersList = [
    'Not what I was looking for',
    'The quality of the video was bad',
    "It's not up to date"
]
type Props = {
    showReactions: UseBooleanType
    playbookId: string
    showFinishStep: UseBooleanType
}
export const VideoReactions = memo(({ showReactions, playbookId, showFinishStep }: Props) => {
    const { data } = useGetPlaybookReaction(playbookId)
    const { setUserReaction } = useSetPlaybookReaction(playbookId)

    const isActive = (value: number) => data?.reaction === value

    return (
        <Box
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="end"
            sx={{ gap: '8px' }}
        >
            <StyledButton
                variant="text"
                onClick={() => {
                    setUserReaction({
                        reaction: reactionValues.helpful
                    }).then(() => {
                        showReactions.setFalse()
                        showFinishStep.setFalse()
                    })
                }}
                disabled={isActive(reactionValues.helpful)}
            >
                <IconWrapper>
                    <EmojiHelpful
                        style={{
                            ...(isActive(reactionValues.helpful) && {
                                color: '#ffca28',
                                fill: 'currentColor'
                            })
                        }}
                    />
                </IconWrapper>
                <Box fontSize="12px" fontWeight="400" color="#090C10">
                    Helpful
                </Box>
            </StyledButton>

            <Divider orientation="vertical" light={true} style={{ height: '18px' }} />

            <StyledButton
                variant="text"
                onClick={() => {
                    showReactions.setTrue()
                    showFinishStep.setFalse()
                }}
                disabled={isActive(reactionValues.notHelpful)}
            >
                <IconWrapper>
                    <EmojiNotHelpful
                        style={{
                            ...(isActive(reactionValues.notHelpful) && {
                                color: '#ffca28',
                                fill: 'currentColor'
                            })
                        }}
                    />
                </IconWrapper>
                <Box fontSize="12px" fontWeight="400" color="#090C10">
                    Not helpful
                </Box>
            </StyledButton>
        </Box>
    )
})
