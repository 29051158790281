import { type ReactNode, memo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Container, Toolbar, Hidden, Box } from '@mui/material'

import { Menu } from 'UI/Components/Menu'

import { useAuth } from 'hooks'

import { paths } from 'app/paths'

type Props = {
    children: ReactNode
}

export const AppContainer = memo(({ children }: Props) => {
    const { isNotLogged, uid } = useAuth()

    const isOnboardingPage = useRouteMatch(paths.onboarding)?.isExact
    return (
        <Box display="flex">
            {uid && <Menu />}
            <Container maxWidth={false} style={{ padding: 0 }}>
                {!isNotLogged && !isOnboardingPage && (
                    <Hidden implementation="css" mdUp>
                        <Toolbar />
                    </Hidden>
                )}
                {children}
            </Container>
        </Box>
    )
})
