import { type Dispatch, type SetStateAction } from 'react'

import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

type Props = {
    text: string
    setText: Dispatch<SetStateAction<string>>
    placeholder?: string
    onChange?: () => void
}

export const SearchField = ({
    text,
    setText,
    placeholder = 'Enter Search text',
    onChange
}: Props) => {
    return (
        <TextField
            fullWidth
            value={text}
            label={placeholder}
            onChange={e => {
                setText(e.target.value)
                return onChange?.()
            }}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon
                            style={{
                                color: 'rgba(120, 144, 156, 0.87)'
                            }}
                        />
                    </InputAdornment>
                )
            }}
        />
    )
}
