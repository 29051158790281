import { useCallback, useEffect, useMemo, useState } from 'react'
import { VideoSection } from './video-section'

type ApplicationItem = {
    applicationId: string
    applicationName: string
    applicationLogo?: string
}

export type SectionType = {
    start: number
    end: number
    duration?: number
    title: string
    thumbnail?: string
    tags?: Array<string>
    applications?: Array<ApplicationItem>
}

export type TimelineWithSectionsProps = {
    /**
     * array of data from slicingSuggestion property
     */
    sections: Array<SectionType>
    /**
     * ref to video tag
     */
    videoElement: HTMLVideoElement
}

export function TimelineWithSections({ sections = [], videoElement }: TimelineWithSectionsProps) {
    const duration = useMemo(() => {
        if (!videoElement?.duration) return 0
        return Math.round(videoElement.duration * 10) / 10 // round to tens
    }, [videoElement?.duration])

    // fallback if there are no chapters
    if (!sections.length) {
        const sectionType: SectionType = {
            start: 0,
            end: duration,
            title: ''
        }
        sections = [sectionType]
    }

    const formattedSections = useMemo(() => {
        return [
            { rangeValues: [0, 0], start: 0, end: 0, title: '' }, // first one is hidden, to be in the same format as edit mode
            ...sections?.map(section => {
                const start = (section.start / duration) * 100 || 0
                const end = (section.end / duration) * 100

                return {
                    ...section,
                    rangeValues: [start, end]
                }
            }),
            { rangeValues: [100, 100], start: 0, end: 0, title: '' } // last one is hidden, to be in the same format as edit mode
        ]
    }, [duration, sections])

    const updateTimeByPercentage = useCallback(
        (percentage: number) => {
            if (videoElement) {
                videoElement.currentTime = (duration / 100) * percentage || 0
                return
            }
        },
        [duration, videoElement]
    )

    const [videoProgress, setVideoProgress] = useState(0)

    useEffect(() => {
        const newProgress = (target: HTMLVideoElement) => {
            const { currentTime, duration } = target
            if (!currentTime || !duration) return 0

            return +((currentTime / duration) * 100).toFixed(2)
        }

        const updateTime = (e: Event) => {
            setVideoProgress(newProgress(e.target as HTMLVideoElement))
        }

        videoElement?.addEventListener('timeupdate', updateTime)

        return () => {
            videoElement?.removeEventListener('timeupdate', updateTime)
        }
    }, [videoElement])

    if (!videoElement) return null

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                backgroundColor: 'transparent'
            }}
        >
            {formattedSections?.map((_, idx) => {
                return (
                    <VideoSection
                        key={idx}
                        index={idx}
                        sections={formattedSections}
                        onUpdateTime={updateTimeByPercentage}
                        videoElement={videoElement}
                        videoProgress={videoProgress}
                    />
                )
            })}
        </div>
    )
}
