import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setInitialSections, setSections } from 'ducks/actions'
import { roundToHundredth, isDeepEqual } from 'modules'
import { type PlaybookChapterType } from 'app/types'

type Props = {
    slicingSuggestion?: Array<PlaybookChapterType>
    isEditMode?: boolean
}

export const useVideoSections = ({ slicingSuggestion, isEditMode }: Props) => {
    const dispatch = useDispatch()

    const {
        duration,
        sections: newSections,
        initialSections
    } = useSelector(state => state.videoEditor)

    useEffect(() => {
        // We need this hook in edit mode only (redux)
        if (!isEditMode) return

        if (!slicingSuggestion || slicingSuggestion.length === 0 || !duration) return

        // this is a temporary solution in order to prevent playbook metadata update to affect chapters edit
        if (!isDeepEqual(newSections, initialSections)) return

        const sections =
            slicingSuggestion.map((section, idx) => {
                const start = (section.start / duration) * 100
                const end = (section.end / duration) * 100

                const generatedTitle = `Chapter (${idx + 1})`
                const title = section.title || generatedTitle

                return {
                    ...section,
                    title,
                    rangeValues: [roundToHundredth(start), roundToHundredth(end)]
                }
            }) || []

        const chaptersWithHidden = [
            {
                start: 0,
                end: 0,
                rangeValues: [0, 0],
                title: '',
                thumbnail: ''
            }, // default hidden part to have ability to split from the beginning
            ...sections,
            {
                start: 0,
                end: 0,
                rangeValues: [100, 100],
                title: '',
                thumbnail: ''
            } // default hidden part to have ability to split from the end
        ]

        dispatch(setSections(chaptersWithHidden))
        dispatch(setInitialSections(chaptersWithHidden))
        // added here so we won't re-render the page everytime the suggestion is changed
        // eslint-disable-next-line
    }, [dispatch, duration, slicingSuggestion])
}
