import { Box, Typography, Divider, styled, IconButton } from '@mui/material'

import HappyIcon from '@mui/icons-material/SentimentSatisfiedSharp'
import SadIcon from '@mui/icons-material/SentimentDissatisfiedSharp'

import { NotHelpfulDialog } from './NotHelpfulDialog'

import {
    reactionValues,
    useAuth,
    useBoolean,
    useGetPlaybookReaction,
    useSetPlaybookReaction
} from 'hooks'

const StyledIconButton = styled(IconButton)({
    marginBottom: -4,
    '&.Mui-disabled': {
        color: '#ffca28 !important'
    }
})

const Container = styled(Box)({
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
})

type Props = {
    playbookId: string
    creatorUid: string
}

export const MobileVideoReactions = ({ playbookId, creatorUid }: Props) => {
    const dialog = useBoolean()

    const { uid } = useAuth()

    const { data } = useGetPlaybookReaction(playbookId)
    const { setUserReaction } = useSetPlaybookReaction(playbookId)

    // hide if user is owner of this playbook
    if (uid === creatorUid) return null

    return (
        <Box>
            <Divider />
            <Box display="flex" justifyContent="center">
                <Box display="flex" justifyContent="center" width="50%">
                    <Container>
                        <StyledIconButton
                            onClick={() => {
                                setUserReaction({
                                    reaction: reactionValues.helpful
                                })
                            }}
                            disabled={data?.reaction === reactionValues.helpful}
                            sx={{
                                ...(data?.reaction === reactionValues.helpful && {
                                    color: '#ffca28'
                                })
                            }}
                        >
                            <HappyIcon />
                        </StyledIconButton>

                        <Typography variant="caption">Helpful</Typography>
                    </Container>
                </Box>
                <Box>
                    <Divider orientation="vertical" />
                </Box>
                <Box display="flex" justifyContent="center" width="50%">
                    <Container>
                        <StyledIconButton
                            onClick={dialog.setTrue}
                            disabled={data?.reaction === reactionValues.notHelpful}
                            sx={{
                                ...(data?.reaction === reactionValues.notHelpful && {
                                    color: '#ffca28'
                                })
                            }}
                        >
                            <SadIcon />
                        </StyledIconButton>

                        <Typography variant="caption">Not Helpful</Typography>
                    </Container>
                </Box>
            </Box>
            <Divider />
            <NotHelpfulDialog
                isOpen={dialog.isTrue}
                onClose={dialog.setFalse}
                playbookId={playbookId}
            />
        </Box>
    )
}
