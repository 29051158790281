import { Box } from '@mui/material'

import { VideoReactions, VideoReactionsAnswers } from 'UI/Components'

import { type VideoRefType } from 'app/types'

import { useBoolean } from 'hooks'

type Props = {
    playbookId: string
    videoRef: VideoRefType
}

export const PlaybookReactions = ({ playbookId, videoRef }: Props) => {
    const showReactions = useBoolean()
    const showFinishStep = useBoolean()

    return (
        <Box style={{ position: 'relative' }}>
            <VideoReactions
                playbookId={playbookId}
                showReactions={showReactions}
                showFinishStep={showFinishStep}
            />
            <VideoReactionsAnswers
                playbookId={playbookId}
                showReactions={showReactions}
                showFinishStep={showFinishStep.isTrue}
                width={videoRef?.current?.offsetWidth || 0}
                height={videoRef?.current?.offsetHeight || 0}
                distance={85}
            />
        </Box>
    )
}
