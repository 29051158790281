import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AddStepMenuItem } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { type MergeParamsType, MergeQGsDialog } from './MergeQGsDialog'

import { ReactComponent as ImportStepIcon } from 'assets/icons/import-step.svg'

import { type QuickGuiddeType, type StepType } from 'app/types'

import { useBoolean, useDataMutation, useNotification } from 'hooks'
import { setActiveStep, setQuickGuiddeSteps } from 'ducks'
import { logToAnalytics } from 'modules'

type Props = {
    onDone: () => void
    mergeToQG: QuickGuiddeType
}

type InputType = {
    mergeTo: string
} & MergeParamsType

type ResponseType = {
    steps: QuickGuiddeType['steps']
}

export const ImportSteps = memo(({ mergeToQG, onDone }: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()
    const dispatch = useDispatch()

    const { showErrorNotification } = useNotification()

    const dialog = useBoolean()

    const { steps, activeStep } = useSelector(state => state.qgEditor.present)

    const $mergeQG = useDataMutation<InputType, ResponseType, Error>(
        '/c/v1/quickguidde/merge',
        'POST'
    )

    const isEndingStepActive = steps[activeStep].kind === 'end'

    const mergeSteps = async (mergeParams: MergeParamsType) => {
        logToAnalytics('importSteps', {
            playbookId,
            mergeFromId: mergeParams.mergeFrom,
            cover: mergeParams.cover,
            ending: mergeParams.ending
        })

        const stepsToMerge = await getStepsToMerge(mergeParams)

        if (!stepsToMerge.length) return

        const newStepsList = generateNewStepsList(stepsToMerge)
        dispatch(setQuickGuiddeSteps(newStepsList))

        if (!isEndingStepActive) {
            dispatch(setActiveStep(activeStep + 1))
        }

        dialog.setFalse()
        onDone()
    }

    const getStepsToMerge = async (mergeParams: MergeParamsType) => {
        const response = await $mergeQG
            .mutate({
                mergeTo: mergeToQG.id,
                ...mergeParams
            })
            .catch(e => {
                showErrorNotification(e.message || 'Something went wrong, please try later')
                console.error(e)
            })

        return response?.steps || []
    }

    const generateNewStepsList = (stepsToAdd: Array<StepType>) => {
        let combinedSteps: Array<StepType> = []

        // On merge, copy all steps of the selected guide into the current one, after the active step
        // If the Ending slide is active so before the active step
        if (isEndingStepActive) {
            combinedSteps = [...steps.slice(0, activeStep), ...stepsToAdd, steps[activeStep]]
        } else {
            const isFirstStepActive = steps[activeStep].kind === 'cover'

            let start = []

            if (isFirstStepActive) start = [steps[0]]
            else start = steps.slice(0, Math.max(activeStep + 1))

            combinedSteps = [...start, ...stepsToAdd, ...steps.slice(activeStep + 1)]
        }

        return combinedSteps
    }

    return (
        <>
            <AddStepMenuItem
                title="Add from Guidde"
                onClick={dialog.setTrue}
                Icon={ImportStepIcon}
                dataCy="qg-edit-import-steps"
            />

            {dialog.isTrue && (
                <MergeQGsDialog
                    isOpen={true}
                    isLoading={$mergeQG.isLoading}
                    onClose={dialog.setFalse}
                    onSubmit={mergeSteps}
                    mergeToQG={mergeToQG}
                />
            )}
        </>
    )
})
