import { memo, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { type DataSnapshot, getDatabase, ref, onValue, off } from 'firebase/database'

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CircularProgress,
    IconButton,
    Box,
    Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'

import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'

import { CircularProgressWithLabel, ItemThumbnail } from 'UI/Components'

import { useAuth } from 'hooks'

import { closeUploadProgressPopup, updateFileProgress } from 'ducks/actions'
import { type UploadFile } from 'ducks/reducers'

const PREFIX = 'UploadProgressPopup'

const classes = {
    accordion: `${PREFIX}-accordion`,
    headerRoot: `${PREFIX}-headerRoot`,
    header: `${PREFIX}-header`,
    list: `${PREFIX}-list`,
    resetPadding: `${PREFIX}-resetPadding`,
    item: `${PREFIX}-item`,
    itemText: `${PREFIX}-itemText`,
    itemIcon: `${PREFIX}-itemIcon`
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    [`&.${classes.accordion}`]: {
        position: 'fixed',
        bottom: '3rem',
        right: '3rem',
        zIndex: 4
    },

    [`& .${classes.headerRoot}`]: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontWeight: theme.typography.fontWeightBold
    },

    [`& .${classes.header}`]: {
        width: '15rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&.Mui-expanded': {
            margin: '0'
        }
    },

    [`& .${classes.list}`]: {
        padding: 0,
        maxHeight: '40vh',
        overflowY: 'scroll'
    },

    [`& .${classes.resetPadding}`]: {
        padding: 0
    },

    [`& .${classes.item}`]: {
        padding: '0.5rem 1rem'
    },

    [`& .${classes.itemText}`]: {
        width: '15rem',
        paddingLeft: '0.5rem',
        fontSize: '0.75rem',
        wordBreak: 'break-word'
    },

    [`& .${classes.itemIcon}`]: {
        justifyContent: 'flex-end',
        fontSize: '2rem',
        color: '#5CB85C' // For success tick
    }
}))

const SOURCE_LABELS = {
    gdrive: 'Google Drive',
    zoom: 'Zoom',
    onedrive: 'One Drive',
    upload: 'your device',
    youtube: 'YouTube'
} as const

type Props = {
    file: UploadFile
    uid: string
}

const File = memo(({ file, uid }: Props) => {
    const dispatch = useDispatch()

    const progress = file.progress || -2

    useEffect(() => {
        if (file.source === 'upload') return

        const path = `${file.source}/${uid}/${file.id}`
        const docRef = ref(getDatabase(), path)

        const listenerCallback = (snapshot: DataSnapshot) => {
            const data = snapshot.val()
            const percent = Math.min(data.progress * 100, 100)
            dispatch(updateFileProgress(file.id, percent))

            // Remove the listener once the value reaches 100
            if (data === 1) off(docRef, 'value', listenerCallback)
        }

        onValue(docRef, listenerCallback)
    }, [dispatch, file.id, file.source, uid])

    return (
        <ListItem className={classes.item}>
            {file.thumbnail ? (
                <img src={file.thumbnail} width={30} height={30} />
            ) : (
                <ItemThumbnail file={file} />
            )}
            <ListItemText classes={{ primary: classes.itemText }}>{file.name}</ListItemText>
            <ListItemIcon className={classes.itemIcon}>
                {progress <= 0 && <CircularProgress />}
                {progress > 0 && progress < 100 && <CircularProgressWithLabel value={progress} />}
                {progress === 100 && <CheckIcon fontSize="inherit" />}
            </ListItemIcon>
        </ListItem>
    )
})

const generateType = (files: Array<UploadFile>) => {
    if (files.every(it => it.type === 'video')) {
        return 'video'
    }
    if (files.every(it => it.type === 'playlist')) {
        return 'playlist'
    }

    return 'items'
}

const generateSourceName = (files: Array<UploadFile>) => {
    const allSources = files.reduce(
        (prev, curr) => [...prev, SOURCE_LABELS[curr.source]],
        [] as Array<string>
    )
    const sources = [...new Set(allSources)]

    return sources.join(', ')
}

const UploadProgressPopup = () => {
    const dispatch = useDispatch()

    const { uid } = useAuth()

    const { isOpen, files: filesObject } = useSelector(state => state.uploadProgressPopup)

    const files = useMemo(() => Object.values(filesObject), [filesObject])
    const sourceName = useMemo(() => generateSourceName(files), [files])
    const type = useMemo(() => generateType(files), [files])

    const areFilesUploaded = files.every(file => file.progress === 100)

    if (!isOpen || files.length === 0) return null

    return (
        <StyledAccordion defaultExpanded className={classes.accordion}>
            <AccordionSummary classes={{ root: classes.headerRoot, content: classes.header }}>
                <Typography style={{ fontWeight: 'inherit' }}>
                    {`${files.length} ${type}${files.length > 1 ? 's are ' : ' is '} ${
                        areFilesUploaded ? 'uploaded' : 'uploading'
                    } from ${sourceName}`}
                </Typography>
                <Box display="flex">
                    <IconButton color="inherit" size="small">
                        <ExpandMoreIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={event => {
                            event.stopPropagation()
                            dispatch(closeUploadProgressPopup())
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.resetPadding}>
                <List className={classes.list}>
                    {files.map(file => (
                        <File key={file.id} file={file} uid={uid} />
                    ))}
                </List>
            </AccordionDetails>
        </StyledAccordion>
    )
}

export default UploadProgressPopup
