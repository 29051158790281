import { useState, useEffect, useMemo, memo } from 'react'

import { Box } from '@mui/material'

import { Label, MultipleApplications } from 'UI/Components'

import { useApplications, useDataMutation, useNotification } from 'hooks'

import { type AnyPlaybookType, type PlaybookApplicationType } from 'app/types'
import { logToAnalytics } from 'modules'

type Props = {
    playbook: AnyPlaybookType
    qgApplications?: Array<PlaybookApplicationType>
    onChange?: (
        newApps: Array<PlaybookApplicationType>,
        deletedApp: Array<PlaybookApplicationType>
    ) => void
    onFocus?: () => void
    onBlur?: () => void
}

export const EditApplications = memo(
    ({ playbook, qgApplications, onChange, onFocus, onBlur }: Props) => {
        const initialApplications = useMemo(() => {
            return qgApplications || playbook?.applications || []
        }, [playbook?.applications, qgApplications])

        const [applications, setApplications] = useState(initialApplications)
        const [deletedApps, setDeletedApps] = useState<Array<PlaybookApplicationType>>([])

        useEffect(() => setApplications(initialApplications), [initialApplications])

        const { showSuccessNotification } = useNotification()

        const $updateApps = useDataMutation('/c/v1/playbook/update', 'PATCH', {
            onSuccess: () => showSuccessNotification('Applications updated successfully')
        })

        const { options } = useApplications()

        if (!Object.values(playbook || {}).length) return null

        return (
            <Box>
                <Label label="Applications" mb={1} />
                <MultipleApplications
                    value={applications.flat(1)}
                    canEdit={true}
                    disabled={$updateApps.isLoading}
                    onChange={(newApps: Array<PlaybookApplicationType>, deletedApp) => {
                        if (onChange) {
                            setDeletedApps(prevState =>
                                deletedApp ? [...prevState, deletedApp] : [...prevState]
                            )
                            onChange(
                                newApps,
                                deletedApp ? [...deletedApps, deletedApp] : [...deletedApps]
                            )
                            setApplications(newApps)
                            return
                        }

                        $updateApps
                            .mutate({
                                playbookId: playbook?.id,
                                applications: newApps
                            })
                            .then(() => {
                                setApplications(newApps)

                                if (deletedApp) {
                                    return logToAnalytics(`delete-applications`, {
                                        'pb-apps': deletedApp,
                                        playbookType: playbook.mode
                                    })
                                } else {
                                    logToAnalytics(`update-applications`, {
                                        'pb-apps': newApps,
                                        playbookType: playbook.mode
                                    })
                                }
                            })
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    options={options}
                    chipBackground="transparent"
                />
            </Box>
        )
    }
)
