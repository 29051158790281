import React, { memo, useState } from 'react'

import { Typography, Grid, Box } from '@mui/material'

import { IOSSwitch, SpacedGroup } from 'UI/Components'

import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'

import { openLink, logToAnalytics } from 'modules'
import {
    type BucketType,
    type GetPaywallResType,
    type PlanIdType,
    type UpdateBucketType,
    type UpdatePlanType,
    planTypes,
    useNotification,
    useServiceUsage,
    useStigg,
    useWindowView,
    useBoolean
} from 'hooks'

import { links } from 'app/links'

import { getPriceLabel, SCREEN } from './helpers'

import { PlanItem } from './PlanItem'
import { UnsubscribeDialog } from './UnsubscribeDialog'

const generateEvent = (plan: string) => `select_${plan}_plan_upgrade_plan_dialog`

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
    updateBucket: UpdateBucketType
    startTrial: (planId: string) => void
    updatePlan: UpdatePlanType
    isLoading: boolean
    bucket: BucketType
    plans: GetPaywallResType['allPlans']
    availablePlanIds: Array<PlanIdType>
}

export const SelectPlan = memo(
    ({ setStep, updateBucket, bucket, updatePlan, plans, isLoading, availablePlanIds }: Props) => {
        const { showWarningNotification } = useNotification()

        const [selectedPeriod, setSelectedPeriod] = useState(bucket.period)
        const { activePlanId } = useServiceUsage()

        const unsubscribeDialog = useBoolean()

        const { trial } = useStigg()

        const { isMobileView } = useWindowView()

        const handleClick = (planId: keyof typeof plans) => {
            if (plans[activePlanId].metadata.isCustomPrice) {
                // if current plan has custom price we don't allow user to change it
                showWarningNotification('Please, contact sales to make this change')
                return
            }

            if (plans[planId].metadata.isCustomPrice) {
                // if plan has custom price it doesn't have pricePoints
                logToAnalytics(generateEvent(planId))
                openLink(links.contactSales)
                return
            }

            if (plans[planId].pricePoints.every(({ amount }) => amount === 0)) {
                unsubscribeDialog.setTrue()
                return
            }

            logToAnalytics(generateEvent(planId))
            updateBucket({
                planId,
                period: selectedPeriod
            })

            setStep(SCREEN.SelectSeats)
        }

        // find business plan id
        const recommendedPlanId = Object.values(plans).find(it => it.metadata.isRecommended)?.planId

        const handleUnsubscribe = async () => {
            logToAnalytics(generateEvent(planTypes.individual))
            await updatePlan({ planId: planTypes.individual })
        }

        return (
            <>
                <Box
                    width="100%"
                    height={isMobileView ? 60 : 75}
                    mb={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <SpacedGroup spacing={1.5}>
                        <Typography
                            data-test="plansDialog-monthly-billing-type-text"
                            style={{ fontSize: 13, fontWeight: 500 }}
                        >
                            Billed Monthly
                        </Typography>
                        <Box width={50} display="flex" justifyContent="center">
                            <Box style={{ transform: 'scale(1.7)' }}>
                                <IOSSwitch
                                    data-test="plansDialog-billing-type-switch"
                                    checked={selectedPeriod === BillingPeriod.Annually}
                                    onChange={(_, checked) => {
                                        setSelectedPeriod(
                                            checked ? BillingPeriod.Annually : BillingPeriod.Monthly
                                        )
                                    }}
                                />
                            </Box>
                        </Box>

                        <Box position="relative">
                            <Typography
                                data-test="plansDialog-annual-billing-type-text"
                                style={{ fontSize: 13, fontWeight: 500 }}
                            >
                                Billed Annually
                            </Typography>
                            <Box
                                position="absolute"
                                right={isMobileView ? '100%' : -8}
                                top={isMobileView ? '-8px' : '50%'}
                                fontSize={10}
                                style={{
                                    transform: 'translate(100%, -50%)'
                                }}
                            >
                                <Box
                                    data-test="plansDialog-annual-percentage-off"
                                    bgcolor="#F8C521"
                                    borderRadius={4}
                                    py="0.5px"
                                    px={0.5}
                                    fontWeight={500}
                                    whiteSpace="nowrap"
                                >
                                    {
                                        getPriceLabel(
                                            plans[planTypes.pro].pricePoints,
                                            false,
                                            BillingPeriod.Annually
                                        ).benefit
                                    }
                                    % off
                                </Box>
                            </Box>
                        </Box>
                    </SpacedGroup>
                </Box>
                <Grid container spacing={isMobileView ? 1 : 2}>
                    {availablePlanIds.map(planId => {
                        const gridItemSize = availablePlanIds.length > 3 ? 3 : 4

                        return (
                            <Grid
                                item
                                key={planId}
                                xs={12}
                                sm={6}
                                md={gridItemSize}
                                lg={gridItemSize}
                                style={{
                                    paddingTop: plans[planId].metadata.isRecommended
                                        ? isMobileView
                                            ? 16
                                            : 0
                                        : 22
                                }}
                            >
                                <PlanItem
                                    key={planId}
                                    hideTrialDate={recommendedPlanId === trial?.planId}
                                    planNames={availablePlanIds.map(plan => plans[plan]?.planName)}
                                    onPlanSelect={handleClick}
                                    selectedPeriod={selectedPeriod}
                                    planId={planId}
                                    plan={plans[planId]}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                {unsubscribeDialog.isTrue && (
                    <UnsubscribeDialog
                        isOpen={true}
                        onClose={unsubscribeDialog.setFalse}
                        isLoading={isLoading}
                        onConfirm={handleUnsubscribe}
                    />
                )}
            </>
        )
    }
)
