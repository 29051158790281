import { useCallback, useEffect, useMemo } from 'react'
import { ActionCreators } from 'redux-undo'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'

import { minimumOneStepRequiredMessage } from 'UI/Routes/quick-guidde/CanvasEditor/StepsPreview/ActiveStepControls'

import { copyToClipboard, logToAnalytics } from 'modules'
import {
    copySelectedLayer,
    moveSelectedLayer,
    pasteSelectedLayer,
    removeActiveStep,
    removeSelectedLayer,
    resetCopiedLayer
} from 'ducks'
import { updateUserPreferences } from 'ducks/reducers'
import { useNotification } from 'hooks'

import { Shape } from 'app/types'
import { paths } from 'app/paths'

const checkIfShortcutsDisabled = () => {
    const isPopoverOpen = document.querySelector('[class*=MuiPopover-root]')
    if (isPopoverOpen) return true

    const isPopperOpen = document.querySelector('[class*=Popper-root]')
    if (isPopperOpen) return true

    const isDialogOpen = document.querySelector('[role*=dialog]:not(#step-template):not(iframe)') // Iframe added due to collission with Intercome's iframe
    if (isDialogOpen) return true

    const focusedElement = document.activeElement as HTMLElement

    const isFocusedElementEditable = focusedElement.isContentEditable
    if (isFocusedElementEditable) return true

    const isInputFocused = ['INPUT', 'TEXTAREA'].includes(focusedElement?.tagName)
    if (isInputFocused) return true

    return false
}

type Props = {
    onSave: () => void
}

export const useQgShortcuts = ({ onSave }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const {
        past,
        present: {
            copiedLayers,
            selectedLayersIds,
            isEditorBlockingShortcuts,
            steps,
            activeStep,
            selectedSteps
        },
        future,
        index = 0
    } = useSelector(state => state.qgEditor)

    const { showSuccessNotification, showInfoNotification, showErrorNotification } =
        useNotification()

    const brandKitPage = useRouteMatch([paths.brandKitDetails, paths.brandKit])?.isExact

    const { audioNote, kind: activeStepKind, layers, id: activeStepId } = steps[activeStep] || {}

    const selectedLayers = layers?.filter(layer => selectedLayersIds.includes(layer.id)) || []

    const selectedOverlay = selectedLayers.some(({ type }) => type === Shape.Overlay)
    const selectedAudioCircle = selectedLayers.some(({ type }) => type === Shape.AudioCircle)
    const selectedWatermark = selectedLayers.some(
        layer => layer.type === Shape.Image && layer.isWatermark
    )

    const brandKitTemplateTitle =
        brandKitPage && selectedLayers.some(layer => layer.type === Shape.Text && layer.isTitle)

    const pastStateExists = past[index - 1]?.steps?.length !== 0
    const futureStateExists = future.length !== 0

    const stepsArrayToCopy = useMemo(() => {
        return steps.filter(step => [activeStepId, ...selectedSteps].includes(step.id))
    }, [activeStepId, selectedSteps, steps])

    const handleCopy = useCallback(() => {
        logToAnalytics('qg-copy-clicked')

        // If layer is selected, we copy layer.
        if (selectedLayersIds.length > 0) {
            dispatch(copySelectedLayer())
            // reset copied step id
            copyToClipboard('')
        }
        // Otherwise, we copy the whole step
        else {
            if (brandKitPage) return // we don't want to copy step on brand-kit page
            if (copiedLayers.length) dispatch(resetCopiedLayer())

            // Multi select
            if (selectedSteps.length) {
                const includesIntroOutro = stepsArrayToCopy.some(step =>
                    ['intro', 'outro'].includes(step.kind)
                )

                if (includesIntroOutro) {
                    copyToClipboard('')
                    showErrorNotification(
                        'You can copy only regular steps. Intro/Outro steps won’t be copied.'
                    )
                    return
                }
            }
            // Try to copy the active step only
            if (!selectedSteps.length) {
                if (['intro', 'outro'].includes(activeStepKind)) {
                    copyToClipboard('')
                    showErrorNotification('You cannot copy the Intro/Outro steps')
                    return
                }
            }

            copyToClipboard(JSON.stringify({ steps: stepsArrayToCopy, playbookId }))
            showSuccessNotification(
                `Step${stepsArrayToCopy.length > 1 ? 's' : ''} copied to clipboard`
            )
        }
    }, [
        activeStepKind,
        brandKitPage,
        copiedLayers.length,
        dispatch,
        playbookId,
        selectedLayersIds.length,
        selectedSteps.length,
        showErrorNotification,
        showSuccessNotification,
        stepsArrayToCopy
    ])

    const handleDelete = useCallback(() => {
        // If layer is selected, we remove layer.
        if (selectedLayersIds.length) {
            if (brandKitTemplateTitle && activeStepKind === 'cover') return

            dispatch(removeSelectedLayer())
            // If we remove audio layer, we need to change user preference to avoid adding it by default after audioNote creation
            if (selectedAudioCircle) {
                switch (audioNote?.type) {
                    case 'speechToText':
                        return updateUserPreferences('s2tAvatar', false)
                    case 'textToSpeech':
                        return updateUserPreferences('t2sAvatar', false)
                }
            }
        }
        // Otherwise, we remove the whole step
        else {
            if (brandKitPage) return // we don't want to delete step on brand-kit page

            const selectedAllSteps = selectedSteps.length === steps.length
            if (steps.length === 1 || selectedAllSteps) {
                showInfoNotification(minimumOneStepRequiredMessage)
                return
            }

            dispatch(removeActiveStep())
            logToAnalytics('qg-delete-chapter', {
                activeChapter: activeStep + 1,
                source: activeStepKind
            })
        }
    }, [
        activeStep,
        activeStepKind,
        audioNote?.type,
        brandKitPage,
        brandKitTemplateTitle,
        dispatch,
        selectedAudioCircle,
        selectedLayersIds.length,
        selectedSteps.length,
        showInfoNotification,
        steps.length
    ])

    // Keyboard shortcuts
    useEffect(() => {
        const shortcutsHandler = (e: KeyboardEvent) => {
            // While any field of the metadata is focussed - we should ignore shortcuts to prevent events conflicts
            if (isEditorBlockingShortcuts || checkIfShortcutsDisabled()) return

            const delKey = e.key === 'Backspace' || e.key === 'Delete'
            const ctrl = e.ctrlKey || e.metaKey
            const sKey = e.keyCode === 83
            const zKey = e.keyCode === 90
            const yKey = e.keyCode === 89
            const cKey = e.keyCode === 67
            const vKey = e.keyCode === 86
            const upKey = e.keyCode === 38
            const rightKey = e.keyCode === 39
            const bottomKey = e.keyCode === 40
            const leftKey = e.keyCode === 37

            if (upKey || rightKey || bottomKey || leftKey) {
                if (selectedOverlay || selectedWatermark) return

                logToAnalytics('qg-arrow-clicked')
                if (upKey) dispatch(moveSelectedLayer({ direction: 'TOP' }))
                if (rightKey) dispatch(moveSelectedLayer({ direction: 'RIGHT' }))
                if (bottomKey) dispatch(moveSelectedLayer({ direction: 'BOTTOM' }))
                if (leftKey) dispatch(moveSelectedLayer({ direction: 'LEFT' }))
            }

            // Save
            if (ctrl && sKey) {
                e.preventDefault()
                onSave()
            }
            // Delete
            if (delKey) handleDelete()

            // Copy
            if (ctrl && cKey) handleCopy()

            // Paste
            if (ctrl && vKey) {
                dispatch(pasteSelectedLayer())
                logToAnalytics('qg-paste-clicked')
            }

            // Undo
            if (ctrl && zKey && pastStateExists) {
                dispatch(ActionCreators.undo())
                logToAnalytics('qg-undo-clicked')
            }
            // Redo
            if (ctrl && yKey) {
                e.preventDefault()
                if (!futureStateExists) return
                dispatch(ActionCreators.redo())
                logToAnalytics('qg-redo-clicked')
            }
        }

        document.body.addEventListener('keydown', shortcutsHandler)
        return () => {
            document.body.removeEventListener('keydown', shortcutsHandler)
        }
    }, [
        dispatch,
        futureStateExists,
        handleCopy,
        handleDelete,
        onSave,
        pastStateExists,
        selectedOverlay,
        selectedWatermark,
        isEditorBlockingShortcuts
    ])
}
