import { type CSSProperties } from 'react'

import { type BoxProps, Box, CircularProgress, Typography } from '@mui/material'

import CodeIcon from '@mui/icons-material/Code'

import { ReactComponent as ConfluenceIcon } from 'assets/icons/Confluence.svg'
import { ReactComponent as NotionIcon } from 'assets/icons/Notion.svg'
import { ReactComponent as ZendeskIcon } from 'assets/icons/Zendesk.svg'
import { ReactComponent as SalesforceIcon } from 'assets/icons/Salesforce.svg'
import { ReactComponent as ServiceNowIcon } from 'assets/icons/ServiceNow.svg'
import { ReactComponent as WebflowIcon } from 'assets/icons/Webflow.svg'
import { ReactComponent as WordPressIcon } from 'assets/icons/WordPress.svg'
import { ReactComponent as SourceCodeIcon } from 'assets/icons/code_in_square.svg'

import { ReactComponent as MarkdownIcon } from 'assets/icons/markdown.svg'
import { ReactComponent as GitHubIcon } from 'assets/icons/GitHub.svg'
import { ReactComponent as BitBucketIcon } from 'assets/icons/BitBucket.svg'
import { ReactComponent as ClickUpIcon } from 'assets/icons/ClickUp.svg'
import { ReactComponent as SharePointIcon } from 'assets/icons/SharePoint.svg'
import { ReactComponent as GitLabIcon } from 'assets/icons/GitLab.svg'
import { ReactComponent as ReadMeIcon } from 'assets/icons/ReadMe.svg'
import { ReactComponent as HubspotIcon } from 'assets/icons/Hubspot.svg'
import { ReactComponent as GitBook } from 'assets/icons/GitBook.svg'

import { type QuickGuiddeType } from 'app/types'

import { logToAnalytics, playbookToAnalyticsProps } from 'modules'
import {
    type PlaybookImageWidthType,
    useBoolean,
    useCopyHtml,
    useGlobalFeatureFlags,
    useNotification
} from 'hooks'

import { SpacedGroup } from 'UI/Components'

const markDownItems = [
    {
        name: 'Markdown',
        bgcolor: '#CB0000',
        Icon: MarkdownIcon,
        custom: false
    },
    {
        name: 'GitHub',
        bgcolor: '#75147C',
        Icon: GitHubIcon
    },
    {
        name: 'SharePoint',
        bgcolor: '#3D898D',
        Icon: SharePointIcon
    },
    {
        name: 'Bitbucket',
        bgcolor: '#4382F7',
        Icon: BitBucketIcon
    },
    {
        name: 'ClickUp',
        bgcolor: '#7A55E2',
        Icon: ClickUpIcon
    },
    {
        name: 'GitLab',
        bgcolor: '#CB4E34',
        Icon: GitLabIcon
    },
    {
        name: 'ReadMe',
        bgcolor: '#018EF5',
        Icon: ReadMeIcon
    }
]

type CopyEmbedCodeType = {
    appName: string
    withHyperLinkTitle?: boolean
    width?: PlaybookImageWidthType
    headerSize?: number
    iframeWidth?: number
    responsive?: boolean
    headerAlignment?: 'left' | 'center'
    transcript?: Array<{ time: string; text: string }>
}

type Props = {
    playbook: QuickGuiddeType
}

export const SmartCopyTab = ({ playbook }: Props) => {
    const { showSuccessNotification } = useNotification()

    const {
        copyHTMLWithPlayButton,
        copyHTMLWithEmbedCode,
        copyIframeWithSteps,
        copyMarkdown,
        copyResponsiveEmbedCode,
        copyGitbookMarkdown
    } = useCopyHtml()

    const { enableNgnixLinks } = useGlobalFeatureFlags()

    const showNotification = (appName: string, type = 'HTML') =>
        showSuccessNotification(`${appName} ${type} was copied to clipboard`)

    const copyGitbookCodeWithSteps = (name: string) => {
        copyGitbookMarkdown(playbook).then(() => showNotification(name))
    }

    const copyMarkdownCode = (appName = '', custom = true) => {
        copyMarkdown(playbook).then(() => showNotification(appName, custom ? 'Markdown' : ''))
    }
    const copyHTMLCode = (appName = '', width?: CopyEmbedCodeType['width']) => {
        copyHTMLWithPlayButton(playbook, width).then(() => {
            showNotification(appName)
            logToAnalytics('copyHtmlClicked', {
                context: 'QG',
                playbookId: playbook.id
            })
        })
    }

    const transcriptLoading = useBoolean()

    const copyEmbedCode = ({
        appName,
        width,
        headerSize,
        iframeWidth,
        responsive,
        headerAlignment,
        withHyperLinkTitle
    }: CopyEmbedCodeType) => {
        copyHTMLWithEmbedCode({
            withHyperLinkTitle,
            playbook,
            width,
            headerSize,
            iframeWidth,
            responsive,
            headerAlignment
        }).then(() => showNotification(appName))
    }

    const copyCodeAsForWebflow = (appName: string, params?: { withHyperLinkTitle?: boolean }) => {
        copyEmbedCode({
            appName,
            withHyperLinkTitle: Boolean(params?.withHyperLinkTitle),
            width: '100%',
            headerSize: 2,
            iframeWidth: 825,
            responsive: true,
            headerAlignment: 'center'
        })
    }

    const copyEmbedCodeWithSteps = async (name: string) => {
        copyResponsiveEmbedCode({ playbook, renderSteps: true }).then(() => showNotification(name))
    }

    // Iframe from Notion and steps from Zendesk.
    const copyHubspotCode = async () => {
        copyIframeWithSteps(playbook).then(() => showNotification('Hubspot'))
    }

    const logHtmlIconClick = (type: string) => {
        logToAnalytics('shareModal_smartcopyTab_HTMLIcon_clicked', {
            playbookId: playbook.id,
            type,
            ...playbookToAnalyticsProps(playbook)
        })
    }
    const logMarkdownIconClick = (type: string) => {
        logToAnalytics('shareModal_smartcopyTab_MarkdownIcon_clicked', {
            playbookId: playbook.id,
            type: 'copy' + type,
            ...playbookToAnalyticsProps(playbook)
        })
    }

    return (
        <Box width="100%" py={1}>
            <Typography
                data-test="smart-copy-html-text"
                style={{ fontSize: 14, fontWeight: 700 }}
                align="center"
            >
                Copy to any product that supports HTML
            </Typography>

            <Box display="flex" justifyContent="space-around" alignItems="center" mt={3}>
                <CopyButton
                    label="HTML"
                    bgcolor="#CB0000"
                    onClick={() => {
                        logHtmlIconClick('HTML')
                        copyHTMLCode()
                    }}
                    custom={false}
                    Icon={CodeIcon}
                />
                <CopyButton
                    label="Source Code"
                    bgcolor="#CB0000"
                    onClick={() => {
                        logHtmlIconClick('SourceCode')
                        copyCodeAsForWebflow('Source Code', { withHyperLinkTitle: false })
                    }}
                    custom={false}
                    Icon={SourceCodeIcon}
                />

                <CopyButton
                    label="Confluence"
                    bgcolor="#2785FF"
                    onClick={() => {
                        logHtmlIconClick('Confluence')
                        copyHTMLCode('Confluence')
                    }}
                    Icon={ConfluenceIcon}
                />
                {enableNgnixLinks && (
                    <CopyButton
                        label="Notion"
                        bgcolor="#000000"
                        onClick={() => {
                            logHtmlIconClick('Notion')
                            copyHTMLCode('Notion', 'auto')
                        }}
                        Icon={NotionIcon}
                    />
                )}
                <CopyButton
                    data-intercom="copy_guidde_zendesk"
                    label="Zendesk"
                    loading={transcriptLoading.isTrue}
                    bgcolor="#719E23"
                    onClick={() => {
                        logHtmlIconClick('Zendesk')
                        copyEmbedCodeWithSteps('Zendesk')
                    }}
                    Icon={ZendeskIcon}
                />
                <CopyButton
                    data-intercom="copy_guidde_salesforce"
                    label="Salesforce"
                    bgcolor="#00A1E0"
                    onClick={() => {
                        logHtmlIconClick('Salesforce')
                        copyHTMLCode('Salesforce')
                    }}
                    Icon={SalesforceIcon}
                />
            </Box>
            <Box mb={3} mt={1} display="flex" justifyContent="center">
                <CopyButton
                    width={90}
                    data-intercom="copy_guidde_servicenow"
                    label="ServiceNow"
                    bgcolor="#81B5A1"
                    onClick={() => {
                        logHtmlIconClick('ServiceNow')
                        copyEmbedCode({ appName: 'ServiceNow', width: '50%' })
                    }}
                    Icon={ServiceNowIcon}
                />
                <CopyButton
                    data-intercom="copy_guidde_webflow"
                    label="Webflow"
                    width={90}
                    bgcolor="#4353FF"
                    onClick={() => {
                        logHtmlIconClick('Webflow')
                        copyCodeAsForWebflow('Webflow')
                    }}
                    Icon={WebflowIcon}
                />
                {enableNgnixLinks && (
                    <CopyButton
                        width={90}
                        data-intercom="copy_guidde_wordpress"
                        label="WordPress"
                        bgcolor="#5DA2CF"
                        onClick={() => {
                            logHtmlIconClick('WordPress')
                            copyEmbedCode({ appName: 'WordPress' })
                        }}
                        Icon={WordPressIcon}
                    />
                )}
                <CopyButton
                    label="Hubspot"
                    bgcolor="#F7583E"
                    width={90}
                    onClick={() => {
                        logHtmlIconClick('Hubspot')
                        copyHubspotCode()
                    }}
                    Icon={HubspotIcon}
                />

                <CopyButton
                    label="GitBook"
                    bgcolor="#F7583E"
                    width={90}
                    onClick={() => {
                        logHtmlIconClick('GitBook')
                        copyGitbookCodeWithSteps('GitBook')
                    }}
                    Icon={GitBook}
                />
            </Box>
            <Typography
                data-test="smart-copy-markdown-text"
                style={{ fontSize: 14, fontWeight: 700 }}
                align="center"
            >
                Copy to any product that supports Markdown
            </Typography>

            <Box display="flex" justifyContent="space-between" alignItems="center" my={3}>
                <Box width={40} />
                {markDownItems.map(({ name, bgcolor, Icon, custom }, i) => (
                    <CopyButton
                        key={i}
                        label={name}
                        bgcolor={bgcolor}
                        onClick={() => {
                            logMarkdownIconClick(name)
                            copyMarkdownCode(name, custom)
                        }}
                        Icon={Icon}
                    />
                ))}
                <Box width={40} />
            </Box>
        </Box>
    )
}

type CopyButtonProps = {
    bgcolor: CSSProperties['background']
    label: string
    custom?: boolean
    width?: BoxProps['width']
    loading?: boolean
    Icon: any
    onClick: () => void
}

const CopyButton = ({
    width = 40,
    bgcolor,
    label,
    Icon,
    custom = true,
    loading,
    onClick
}: CopyButtonProps) => (
    <SpacedGroup
        style={{ cursor: loading ? 'auto' : 'pointer' }}
        spacing={1}
        width={width}
        flexDirection="column"
        onClick={() => {
            if (loading) return

            onClick()

            if (!custom) return

            logToAnalytics('copy_guidde', {
                appName: label
            })
        }}
    >
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            id={`copy_guidde_btn copy_guidde_${label.toLowerCase()}`}
            data-test={`copy_guidde_${label.toLowerCase()}`}
        >
            <Box
                style={{ background: bgcolor }}
                width={40}
                height={40}
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="relative"
            >
                {loading && <CircularProgress style={{ position: 'absolute', left: 0, top: 0 }} />}
                <Icon style={{ color: '#fff' }} />
            </Box>
            <Box mt={1} />
            <Typography
                data-test={`copy_guidde_${label.toLowerCase()}_text`}
                color="textSecondary"
                variant="caption"
                noWrap
            >
                {label}
            </Typography>
        </Box>
    </SpacedGroup>
)
