import dayjs from 'dayjs'

import { humanFileSize } from 'modules'

export const zoomColumns = [
    {
        value: 'title',
        label: 'Meeting Topic',
        cellContent: file => file.title
    },
    {
        value: 'startTime',
        label: 'Meeting Date',
        isSecondary: true,
        cellContent: file => dayjs(file.startTime).format('DD MMM, YYYY')
    },
    {
        value: 'fileSize',
        label: 'Size',
        cellContent: file => humanFileSize(file.fileSize)
    }
]

const compareStrings = (a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })

export const sortZoomFiles = (sortBy, sortDesc, a, b) => {
    const paramA = a[sortBy]
    const paramB = b[sortBy]

    if (sortBy === 'title') {
        return sortDesc ? compareStrings(paramB, paramA) : compareStrings(paramA, paramB)
    }

    if (sortBy === 'fileSize') {
        return sortDesc ? paramB - paramA : paramA - paramB
    }

    if (sortBy === 'startTime') {
        const momentA = dayjs(paramA).valueOf()
        const momentB = dayjs(paramB).valueOf()

        return sortDesc ? momentB - momentA : momentA - momentB
    }

    return 0
}
