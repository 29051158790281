import { createStore } from 'redux'
import { rootReducer } from './rootReducer'
import { isLocalhost } from 'modules/utils'

const initialState = {}

const enableReduxDevTools = () =>
    isLocalhost ? (window as any).__REDUX_DEVTOOLS_EXTENSION__?.() : undefined

export const store = createStore(rootReducer, initialState as any, enableReduxDevTools())

export type RootState = ReturnType<typeof rootReducer>
