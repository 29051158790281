import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

export const roles = {
    viewer: 'isViewer',
    contentManager: 'isContentManager',
    team: 'isMember',
    admin: 'isAdmin',
    superAdmin: 'isSuperAdmin'
} as const

export const ROLE = {
    VIEWER: 10,
    MEMBER: 20,
    CONTENT_MANAGER: 60,
    ADMIN: 100
} as const

export const ROLES_LIST = [
    {
        name: 'Administrator',
        level: ROLE.ADMIN
    },
    {
        name: 'Content Manager',
        level: ROLE.CONTENT_MANAGER
    },
    {
        name: 'Member',
        level: ROLE.MEMBER
    },
    {
        name: 'Viewer',
        level: ROLE.VIEWER
    }
]

export const getRoleName = (roleLevel: number) => {
    const role = ROLES_LIST.find(({ level }) => level === roleLevel)
    return role || ROLES_LIST[0]
}

export const useRoles = (orgIdForCheck?: string) => {
    const { roles } = useSelector(state => state.appAuth)

    const isThisOrg = !orgIdForCheck || orgIdForCheck === roles?.o

    const isSuperAdmin: boolean = roles?.s
    const isAdmin = (isThisOrg && roles?.a === ROLE.ADMIN) || isSuperAdmin
    const isContentManager = (isThisOrg && roles?.a >= ROLE.CONTENT_MANAGER) || isSuperAdmin
    const isMember = (isThisOrg && roles?.a >= ROLE.MEMBER) || isSuperAdmin
    const isViewer = roles?.a === ROLE.VIEWER && !isSuperAdmin

    const isNewUser = roles?.n

    const checkRoles = useCallback(
        (orgId: string) => {
            const isThisOrg = !orgId || orgId === roles?.o
            const isMember = (isThisOrg && roles?.a >= ROLE.MEMBER) || isSuperAdmin
            const isContentManager = (isThisOrg && roles?.a >= ROLE.CONTENT_MANAGER) || isSuperAdmin
            const isAdmin = (isThisOrg && roles?.a === ROLE.ADMIN) || isSuperAdmin

            return { isAdmin, isSuperAdmin, isContentManager, isMember }
        },
        [isSuperAdmin, roles?.o, roles?.a]
    )

    return useMemo(
        () => ({
            userRoles: {
                isViewer,
                isMember,
                isContentManager,
                isAdmin,
                isSuperAdmin
            },
            isViewer,
            isNewUser,
            isMember,
            isContentManager,
            isAdmin,
            isSuperAdmin,
            checkRoles
        }),
        [isViewer, isNewUser, isAdmin, isContentManager, isSuperAdmin, isMember, checkRoles]
    )
}

export type RoleType = keyof typeof useRoles
