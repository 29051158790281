import { Link } from 'react-router-dom'

import { styled, Box, List, ListItem, Typography } from '@mui/material'

import { formatTime } from 'modules'
import { type SearchSectionsType } from 'app/types'

import { InnerHTML } from './InnerHTML'

const PREFIX = 'TimeLineItem'

const classes = {
    timeLabel: `${PREFIX}-timeLabel`,
    listItemLink: `${PREFIX}-listItemLink`
}

const StyledList = styled(List)(({ theme }) => ({
    [`& .${classes.timeLabel}`]: {
        color: '#78909c',
        marginRight: '8px',
        fontSize: theme.typography.pxToRem(10)
    },

    [`& .${classes.listItemLink}`]: {
        color: '#212121',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}))

type TimeLineItemProps = {
    sections?: SearchSectionsType
    pageLink: {
        pathname: string
        state: {
            prevRoute: string
        }
    }
}

export const TimeLineItem = ({ sections, pageLink }: TimeLineItemProps) => {
    if (!sections?.length) return null

    return (
        <StyledList sx={{ p: 0 }}>
            {sections?.map(o => {
                const result = o._snippetResult.transcript || null

                if (result && result?.matchLevel !== 'none') {
                    return (
                        <ListItem key={o.id} style={{ overflow: 'auto' }}>
                            <Link
                                to={{
                                    ...pageLink,
                                    search: `?t=${Math.round(o.start)}`
                                }}
                                className={classes.listItemLink}
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.timeLabel}>
                                        {formatTime(o.start)}
                                    </Typography>

                                    <InnerHTML html={`...${result.value}...`} />
                                </Box>
                            </Link>
                        </ListItem>
                    )
                }
                return null
            })}
        </StyledList>
    )
}
