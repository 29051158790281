import { useMemo, useState } from 'react'

import * as yup from 'yup'
import { Form, Formik } from 'formik'

import {
    Avatar,
    Box,
    FormHelperText,
    Typography,
    Tooltip,
    Divider,
    styled,
    Stack
} from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/LockOutlined'
import PublicIcon from '@mui/icons-material/PublicOutlined'

import { ReactComponent as TeamIcon } from 'assets/icons/team-icon.svg'
import { ReactComponent as PrivacyEyeIcon } from 'assets/icons/privacy-eye.svg'
import { ReactComponent as PrivacyEyeWithLockIcon } from 'assets/icons/privacy-eye-with-lock.svg'

import { ConnectedAutocomplete, ConnectedFreeSoloInput, ConnectedInput } from 'UI/Components'
import { FancyDropdown } from './FancyDropdown'

import {
    useDataMutation,
    useAuth,
    useOrganization,
    ORG_SHARE_OPTIONS,
    useServiceUsage,
    useOrgFeatureFlags,
    useSpaces
} from 'hooks'
import { type OptionType, privateDomains, option, logToAnalytics } from 'modules'

import { PRIVACY_SETTINGS_OPTIONS } from 'app/types'
import { ThemeProvider, Toggle, Button, Icon } from '@guidde/design-system'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faArrowsRotate'

const SpaceLogo = styled(Avatar)(({ theme }) => ({
    background: '#1976d2',
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(13)
}))

const SectionTitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    color: '#090C10',
    marginBottom: '8px'
})

const Caption = styled(Typography)({
    fontSize: '14px',
    fontWeight: 400,
    color: '#090C10'
})

type FormValuesType = {
    name: string
    isPosterInEmbedON: boolean
    domains: Array<string>
    defaultSpaces: Array<OptionType>
    defaultSharingSettings: keyof typeof ORG_SHARE_OPTIONS
    allowSsoOnly?: boolean
    privacySettings?: keyof typeof PRIVACY_SETTINGS_OPTIONS
}

type SubmitValuesType = Omit<FormValuesType, 'defaultSpaces'> & {
    defaultSpaces: Array<string>
}

type Props = {
    onSubmit: (fieldsData: SubmitValuesType) => void
    disabled?: boolean
    orgId: string
}

const privacySettingOptions = [
    {
        icon: (
            <Avatar sx={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                <PrivacyEyeIcon />
            </Avatar>
        ),
        value: PRIVACY_SETTINGS_OPTIONS.internal,
        label: 'Internal',
        caption: 'Anyone with your company domain can join'
    },
    {
        icon: (
            <Avatar sx={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                <PrivacyEyeWithLockIcon />
            </Avatar>
        ),
        value: PRIVACY_SETTINGS_OPTIONS.restricted,
        label: 'Restricted',
        caption: 'Only invited members can join the team'
    }
]

const getPbPublicityOptions = ({
    orgName,
    privateAccount,
    disableExternalSharing
}: {
    orgName: string
    privateAccount: boolean
    disableExternalSharing?: boolean
}) => {
    return [
        {
            icon: (
                <Avatar style={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                    <PublicIcon />
                </Avatar>
            ),
            value: ORG_SHARE_OPTIONS.public,
            label: 'Public',
            caption: 'Anyone with the link can view',
            disabled: disableExternalSharing,
            disabledTooltipMessage: 'Please contact your admin for more info'
        },
        {
            icon: (
                <Avatar style={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                    <PeopleAltIcon />
                </Avatar>
            ),
            value: ORG_SHARE_OPTIONS.private,
            label: 'Private',
            caption: 'Only members and spaces added can open this link'
        },
        ...(privateAccount
            ? []
            : [
                  {
                      icon: (
                          <Avatar style={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                              <TeamIcon />
                          </Avatar>
                      ),
                      value: ORG_SHARE_OPTIONS.anyoneInOrg,
                      label: `Anyone in ${orgName}`,
                      caption: 'All members in this account can view'
                  }
              ])
    ]
}
export const OrganizationForm = ({ orgId, onSubmit, disabled }: Props) => {
    const { isPrivateAccount, uid } = useAuth()
    const { hasPrivacyControl, allowSsoOnly: serviceUsageAllowSSO } = useServiceUsage()

    const [existingDomains, setExistingDomains] = useState<FormValuesType['domains']>([])
    const { orgSpaces, isLoading } = useSpaces()
    const { disableExternalSharing } = useOrgFeatureFlags()

    const {
        name,
        domains,
        defaultSpaces = [],
        defaultSharingSettings,
        allowSsoOnly,
        isPosterInEmbedON,
        privacySettings
    } = useOrganization(orgId)

    const $checkDomain = useDataMutation<unknown, { exists: boolean }, Error>(
        '/c/v1/checkDomain',
        'POST'
    )

    const checkDomain = (domain: string) => {
        return $checkDomain
            .mutate({
                domain
            })
            .then(obj => obj!.exists)
    }

    const getExistingDomains = async (values: FormValuesType) => {
        const newDomains = values.domains.filter(domain => !domains.includes(domain))
        const uniqueDomains = [...new Set(newDomains)]

        const domainsValidationResult = await Promise.all(uniqueDomains.map(checkDomain))

        const existingDomains = newDomains.reduce<Array<string>>((acc, domain, index) => {
            const domainExist = domainsValidationResult[index]
            if (domainExist) acc.push(domain)

            return acc
        }, [])

        return existingDomains
    }

    const onDone = async (values: FormValuesType) => {
        const existingDomains = await getExistingDomains(values)

        if (existingDomains.length > 0) {
            setExistingDomains(existingDomains)
            return
        }

        onSubmit({
            ...values,
            domains: values.domains.map(it => it.toLowerCase()),
            defaultSpaces: values.defaultSpaces?.map(({ value }) => value) || []
        })
    }

    const spaceOptions = useMemo(() => {
        return orgSpaces
            .filter(({ isPrivate }) => !isPrivate)
            .map(({ name, id }) => option(id, name))
            .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    }, [orgSpaces])

    return (
        <Box mr={3} mt={3}>
            <Formik
                enableReinitialize={true}
                onSubmit={values => {
                    if (!values) return

                    const { allowSsoOnly, ...restValues } = values

                    onDone({
                        ...(serviceUsageAllowSSO && { allowSsoOnly }),
                        ...restValues
                    })
                }}
                initialValues={{
                    name,
                    domains,
                    isPosterInEmbedON,
                    defaultSharingSettings,
                    allowSsoOnly,
                    privacySettings,
                    defaultSpaces: isLoading
                        ? []
                        : defaultSpaces.map(spaceId => {
                              const space = orgSpaces.find(({ id }) => spaceId === id)

                              return option(spaceId, space?.name || '')
                          })
                }}
                validationSchema={yup.object().shape({
                    name: yup.string().trim().nullable().required('Field is required'),
                    ...(!isPrivateAccount && {
                        domains: yup
                            .array()
                            .min(1, 'Field is required')
                            .of(
                                yup
                                    .string()
                                    .trim()
                                    .test('isAllowedDomain', '', (value, { createError }) => {
                                        const invalid = privateDomains.includes(
                                            value?.toLowerCase() || ''
                                        )

                                        if (invalid) {
                                            return createError({
                                                message: `${value} can't be added`,
                                                path: 'domains'
                                            })
                                        }

                                        return true
                                    })
                            )
                    })
                })}
            >
                {({ values, dirty, setFieldValue }) => (
                    <Form>
                        <Stack direction="column" spacing={2}>
                            <ConnectedInput
                                fullWidth
                                label="Account Name"
                                name="name"
                                placeholder="Enter account name"
                                disableUnderline
                                InputLabelProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        color: '#090C10'
                                    }
                                }}
                            />

                            <Divider light={true} />

                            {!isPrivateAccount && (
                                <Box>
                                    <ConnectedFreeSoloInput
                                        fullWidth
                                        variant="standard"
                                        label="Account Email Domains"
                                        name="domains"
                                        placeholder={
                                            !values?.domains?.length ? 'Enter domains' : ''
                                        }
                                        onChange={(_, value) => {
                                            setExistingDomains([])
                                            setFieldValue('domains', value)
                                        }}
                                        disableUnderline
                                        InputLabelProps={{
                                            style: {
                                                fontSize: '18px',
                                                fontWeight: 500,
                                                color: '#090C10'
                                            }
                                        }}
                                    />
                                    {existingDomains.length > 0 && (
                                        <FormHelperText error>
                                            {`${existingDomains.join(', ')} ${
                                                existingDomains.length > 1 ? 'are' : 'is'
                                            } already in use`}
                                        </FormHelperText>
                                    )}
                                    <Divider light={true} />
                                </Box>
                            )}

                            <Box>
                                <SectionTitle>Account Default Spaces</SectionTitle>

                                <Caption>Spaces that members will automatically join</Caption>
                                <Box
                                    mt={1.2}
                                    sx={{
                                        '&::placeholder': {
                                            fontSize: 12
                                        }
                                    }}
                                >
                                    <ConnectedAutocomplete
                                        fullWidth
                                        multiple
                                        placeholder="Select spaces"
                                        variant="standard"
                                        name="defaultSpaces"
                                        filterSelectedOptions={false}
                                        disableUnderline
                                        options={spaceOptions}
                                        renderOption={(props, { label, value: spaceId }) => {
                                            const space = orgSpaces.find(({ id }) => id === spaceId)
                                            if (!space) return

                                            return (
                                                // @ts-ignore
                                                <Box {...props}>
                                                    <SpaceLogo src="" variant="circular">
                                                        {label.slice(0, 2).toUpperCase()}
                                                    </SpaceLogo>
                                                    <Typography variant="body2">
                                                        <b>{label}</b>
                                                    </Typography>
                                                </Box>
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Divider light={true} />

                            <Box style={{ opacity: hasPrivacyControl ? 1 : 0.6 }} mb={2}>
                                <SectionTitle>Sharing settings</SectionTitle>
                                <Caption>
                                    Default sharing settings for all guides in your account.
                                </Caption>

                                <Box mt={1.2} mb={3}>
                                    <Tooltip
                                        arrow
                                        title={
                                            hasPrivacyControl
                                                ? ''
                                                : 'Available on the Business Plan'
                                        }
                                        placement="right"
                                    >
                                        <Box mt={1}>
                                            <FancyDropdown
                                                options={getPbPublicityOptions({
                                                    orgName: name,
                                                    privateAccount: isPrivateAccount,
                                                    disableExternalSharing
                                                })}
                                                name="defaultSharingSettings"
                                                disabled={!hasPrivacyControl}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Divider light={true} />

                            <Box my={2}>
                                <SectionTitle>Privacy settings</SectionTitle>
                                <Caption>Control who can join your account</Caption>
                                <Box my={1}>
                                    <FancyDropdown
                                        options={privacySettingOptions}
                                        name="privacySettings"
                                        onChange={value => {
                                            logToAnalytics(
                                                'teamDiscovery_privacySettings_selected',
                                                {
                                                    teamName: name,
                                                    teamId: orgId,
                                                    uid,
                                                    privacyOptionSelected: value
                                                }
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Stack>

                        <ThemeProvider>
                            {serviceUsageAllowSSO && (
                                <>
                                    <Box my={2}>
                                        <Divider light={true} />
                                    </Box>

                                    <Toggle
                                        size="small"
                                        checked={values.allowSsoOnly}
                                        onChange={(e: any, value: boolean) =>
                                            setFieldValue('allowSsoOnly', value)
                                        }
                                        label="Security Settings"
                                        description="Allow Single Sign-On (SSO) only"
                                    />
                                </>
                            )}

                            <Box my={2}>
                                <Divider light={true} />
                            </Box>

                            <Toggle
                                size="small"
                                checked={values.isPosterInEmbedON}
                                onChange={(e: any, value: boolean) =>
                                    setFieldValue('isPosterInEmbedON', value)
                                }
                                label="Embed Settings"
                                description="Display video thumbnail GIF"
                            />
                            <Box mt={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    startIcon={<Icon icon={faArrowsRotate} />}
                                    disabled={disabled || existingDomains.length > 0 || !dirty}
                                >
                                    Update
                                </Button>
                            </Box>
                        </ThemeProvider>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}
