import { memo, useState, useMemo, useEffect } from 'react'

import { Button, Box, TextField, Typography, Link } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

import { ReactComponent as MedalIcon } from 'assets/icons/medal.svg'

import { GeneralDialog } from './GeneralDialog'

import { SpacedGroup } from 'UI/Components'

import { useAuth, useServiceUsage } from 'hooks'

import { links } from 'app/links'
import { type BrandKitFontType } from 'app/types'
import { logToAnalytics } from 'modules'

const borderColor = '1px solid rgba(9, 12, 16, 0.08)'
const adornmentColor = 'rgba(9, 12, 16, 0.6)'

type Props = {
    isOpen: boolean
    onClose: () => void
    activeFont: BrandKitFontType
    fonts: Array<BrandKitFontType>
    onSubmit: (font: string) => void
    isLoading: boolean
}

export const FontDialog = memo(({ activeFont, onSubmit, fonts, isLoading, ...props }: Props) => {
    const { canUploadCustomFont } = useServiceUsage()

    const [search, setSearch] = useState('')
    const [newFont, setNewFont] = useState<BrandKitFontType>(activeFont)

    const { orgId, uid } = useAuth()

    useEffect(() => {
        if (props.isOpen) return

        setSearch('')
        setNewFont(activeFont)
    }, [props.isOpen, activeFont])

    const list = useMemo(() => {
        return fonts
            .filter(font => font.title.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => (a.title > b?.title ? 1 : b?.title > a?.title ? -1 : 0))
    }, [fonts, search])

    return (
        <GeneralDialog
            title="Font"
            {...props}
            maxWidth="sm"
            actions={
                <Box
                    display="flex"
                    width="100%"
                    justifyContent={canUploadCustomFont ? 'flex-end' : 'space-between'}
                >
                    {!canUploadCustomFont && (
                        <Box
                            bgcolor="#F5F5F5"
                            borderRadius="4px"
                            padding="8px 12px"
                            display="flex"
                            flexWrap="nowrap"
                            alignItems="center"
                        >
                            <MedalIcon color="#cb0000" />
                            <Typography
                                style={{
                                    fontSize: 14,
                                    marginRight: '4px',
                                    marginLeft: '8px',
                                    color: 'rgba(9, 12, 16, 0.60)'
                                }}
                                color="textSecondary"
                            >
                                Upload your custom font
                            </Typography>
                            <Link
                                color="primary"
                                href={links.contactSales}
                                target="_blank"
                                onClick={() => {
                                    logToAnalytics('fontsModal_contactUsLink_clicked', {
                                        orgId,
                                        uid
                                    })
                                }}
                            >
                                Contact us
                            </Link>
                        </Box>
                    )}
                    <Button
                        data-test="font-save-changes-button"
                        disabled={newFont.id === activeFont.id}
                        variant="contained"
                        onClick={() => onSubmit(newFont?.id)}
                    >
                        SAVE CHANGES
                    </Button>
                </Box>
            }
        >
            <Box px={2}>
                <TextField
                    data-test="brandkit-fontname-input"
                    placeholder="Type font name here"
                    variant="filled"
                    fullWidth
                    sx={{
                        '& input::placeholder': {
                            fontStyle: 'italic'
                        }
                    }}
                    autoFocus
                    value={search}
                    inputProps={{
                        maxLength: 6,
                        style: {
                            padding: '15px 0',
                            fontSize: '16px'
                        }
                    }}
                    InputProps={{
                        sx: {
                            height: 50
                        },
                        startAdornment: (
                            <SearchIcon
                                style={{
                                    color: adornmentColor,
                                    marginRight: '16px'
                                }}
                            />
                        ),
                        endAdornment: (
                            <>
                                {search.length > 0 && (
                                    <ClearIcon
                                        fontSize="small"
                                        onClick={() => setSearch('')}
                                        style={{
                                            cursor: 'pointer',
                                            color: adornmentColor
                                        }}
                                    />
                                )}
                            </>
                        )
                    }}
                    onChange={e => {
                        const value = e.target.value
                        setSearch(value.trim())
                    }}
                />

                {list.length > 0 ? (
                    <Box mt={2} border={borderColor} maxHeight={250} style={{ overflowY: 'auto' }}>
                        {list.map((font, i) => {
                            const isActive = font.id === newFont.id

                            return (
                                <Box
                                    key={font.id}
                                    sx={{
                                        cursor: isActive ? 'auto' : 'pointer',
                                        '&:hover': {
                                            background: isActive
                                                ? 'inherit'
                                                : 'rgba(9, 12, 16, 0.08)'
                                        }
                                    }}
                                >
                                    <SpacedGroup
                                        p={1.25}
                                        onClick={() => setNewFont(font)}
                                        borderBottom={i + 1 !== fonts.length ? borderColor : 'none'}
                                    >
                                        <Box width={15}>
                                            {isActive && (
                                                <CheckIcon color="primary" fontSize="small" />
                                            )}
                                        </Box>
                                        <link href={font.url} rel="stylesheet" />
                                        <Typography
                                            data-test="brandkit-font-name"
                                            color={isActive ? 'primary' : 'inherit'}
                                            style={{ fontFamily: font.title }}
                                        >
                                            {font.title}
                                        </Typography>
                                    </SpacedGroup>
                                </Box>
                            )
                        })}
                    </Box>
                ) : (
                    <Box my={2}>
                        <Typography>No search results</Typography>
                    </Box>
                )}
            </Box>
        </GeneralDialog>
    )
})
