import { memo } from 'react'

import { Box, Typography } from '@mui/material'
import NotInterestedIcon from '@mui/icons-material/NotInterested'

import { ColorPicker } from 'UI/Components'

import { updatePlaybookCoverColor } from 'modules'
import { useBrandKit, useNotification } from 'hooks'

export const playbookColors = [
    '#00c067',
    '#ffbe0b',
    '#ff8a65',
    '#e4572e',
    '#b388ff',
    '#1976d2',
    '#40c4ff',
    '#00bfa5',
    '#fd7bca',
    '#354662'
]

type Props = {
    playbookId: string
    color?: string
    onDone?: () => void
}

export const PlaybookColorPicker = memo(({ playbookId, color = '', onDone }: Props) => {
    const { showSuccessNotification } = useNotification()

    const { brandKitColors } = useBrandKit()

    return (
        <ColorPicker
            onSelect={() => {
                showSuccessNotification('Video cover color changed')
                onDone?.()
            }}
            customColors={playbookColors}
            additionalColors={brandKitColors}
            additionalColorsLabel="Brand Colors"
            hideCustomColors={true}
            currentColor={color}
            setCurrentColor={coverColor => {
                updatePlaybookCoverColor({ playbookId, coverColor })
            }}
            colorsLabel="Other"
            additionalContent={
                <Box display="flex" alignItems="center" m={1} ml={0.5}>
                    <Box
                        p={0.5}
                        border="1px solid rgba(0, 0, 0, 0.54)"
                        borderRadius="5px"
                        mr={1}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            updatePlaybookCoverColor({
                                playbookId,
                                coverColor: ''
                            }).then(() => {
                                showSuccessNotification('Video cover color removed')
                                onDone?.()
                            })
                        }}
                        data-test="update-cover-color"
                    >
                        <NotInterestedIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </Box>
                    <Box>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: 500 }}
                        >
                            No Color.
                        </Typography>
                        <Typography style={{ fontSize: '10px' }} color="textSecondary">
                            Don’t show a colored cover
                        </Typography>
                    </Box>
                </Box>
            }
        />
    )
})
