import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Stack, Button, Typography } from '@mui/material'

import { ReactComponent as AddCircle } from 'assets/icons/add_circle_gray.svg'

import { GuiddeLoad, PageTitle, DictionaryList } from 'UI/Components'

import { type Dictionary } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/QgTranscript/PronunciationText'
import { PronunciationPopup } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/QgTranscript/PronunciationPopup'

import { type AudioConfigsType } from 'app/types'

import { useAuth, useQuery } from 'hooks'
import { setAudioConfigs } from 'ducks'
import { logToAnalytics } from 'modules'

const initialState = {
    initialValue: {
        alternate: '',
        ipa: '',
        newWord: '',
        workspaceDictionary: true
    },
    selectedWord: '',
    activeLanguage: '',
    contentEditable: false
}

type InitialStateType = typeof initialState

export const DictionaryTab = () => {
    const dispatch = useDispatch()

    const { orgId } = useAuth()

    const [state, setState] = useState(initialState)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const buttonRef = useRef<HTMLButtonElement | null>(null)

    const setPartialState = useCallback((newState: Partial<InitialStateType>) => {
        setState(prevState => ({ ...prevState, ...newState }))
    }, [])

    // get all exiting dictionary entries
    const {
        data: dictionaryData,
        isValidating,
        mutate
    } = useQuery<Dictionary>('/c/v1/pronunciations', {
        method: 'GET'
    })

    const { isValidating: audioConfigsValidating } = useQuery<AudioConfigsType>(
        '/c/v1/config/qg/audio',
        {
            method: 'GET'
        },
        {
            onSuccess: data => dispatch(setAudioConfigs(data)),
            revalidateOnFocus: false
        }
    )

    if (isValidating || audioConfigsValidating) return <GuiddeLoad />

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                spacing={2}
            >
                <Stack spacing={1}>
                    <PageTitle text="Dictionary" />
                    <Typography style={{ fontSize: '14px' }}>
                        Improve your guidde’s transcription and search experience by adding words or
                        phrases used frequently to your organisational dictionary
                    </Typography>
                </Stack>
                <Button
                    size="large"
                    variant="contained"
                    color="inherit"
                    onClick={() => {
                        setAnchorEl(buttonRef.current)
                        setPartialState({
                            ...{ initialValue: initialState.initialValue },
                            contentEditable: true
                        })
                        logToAnalytics('dictionary_addWordBtn_clicked', {
                            orgId
                        })
                    }}
                    startIcon={<AddCircle style={{ color: 'inherit' }} />}
                    ref={buttonRef}
                >
                    ADD WORD
                </Button>
            </Stack>

            {dictionaryData && (
                <DictionaryList
                    newDictionary={dictionaryData}
                    mutate={mutate}
                    onDictionaryEdit={(anchorEl, row) => {
                        setAnchorEl(anchorEl)
                        setPartialState({
                            initialValue: {
                                alternate: row.alternative,
                                ipa: row.ipa,
                                newWord: row.word,
                                workspaceDictionary: true
                            },
                            selectedWord: row.word,
                            activeLanguage: row.language,
                            contentEditable: false
                        })
                    }}
                />
            )}

            <PronunciationPopup
                setAnchorEl={setAnchorEl}
                isAddToDictionaryDisabled={true}
                showPronunciationCheckbox={false}
                anchorEl={anchorEl}
                initialValue={state.initialValue}
                selectedWord={state.selectedWord}
                selectedLanguage={state.activeLanguage}
                contentEditable={state.contentEditable}
                mode="dictionary"
                onSubmit={() => mutate()}
            />
        </>
    )
}
