import { useCallback, useState, useMemo } from 'react'

import { logToAnalytics, triggerPlaybookUploadFinishListener } from 'modules'
import { useAuth, useNotification, useDataMutation, useOrgFeatureFlags } from 'hooks'

import { type QuickGuiddeType } from 'app/types'

export const ALLOWED_VIDEO_FORMATS = '.mp4, .mov, .webm'

type FileType = { name: string; type: any; size?: number }
type SourceType = 'ext' | 'webapp' | 'zoom' | 'gdrive' | 'onedrive' | 'youtube'

type CreateQgFromVideoPayload = {
    title: string
    source: SourceType
    videoUrl: string
    language?: {
        langCode: string
        langName: string
    }
}

export const getTitleFromFileName = (fileName: string) => {
    // Convert the ALLOWED_VIDEO_FORMATS to an array and escape the periods
    const extensions = ALLOWED_VIDEO_FORMATS.split(', ').map(ext => ext.replace('.', '\\.'))

    // Create a dynamic regular expression pattern
    const pattern = new RegExp(`(${extensions.join('|')})$`, 'i')

    // Replace the extension using the dynamic pattern
    return fileName.replace(pattern, '')
}

export const useUploader = () => {
    const { enableQGFromVideo } = useOrgFeatureFlags()

    const [postProcessing, setPostProcessing] = useState(false)

    const { displayName, photoURL, email, uid } = useAuth()
    const { showErrorNotification } = useNotification()

    const uploadedBy = useMemo(
        () => ({ displayName, photoURL, email }),
        [displayName, email, photoURL]
    )

    const $createQgFromVideo = useDataMutation<CreateQgFromVideoPayload, QuickGuiddeType, Error>(
        `/c/v1/quickguidde/create-from-video`,
        'POST',
        {
            onSuccess: data => {
                logToAnalytics('upload_from_local_drive', {
                    'pb-id': data.id,
                    'pb-title': data.title
                })
            },
            onFailure: () => {
                showErrorNotification(
                    'Something went wrong while uploading your video, please try again'
                )
            }
        }
    )

    const createQgFromVideo = $createQgFromVideo.mutate

    const triggerQGCreationFromUploadedVideo = useCallback(
        async (_playbookId: string, file: FileType, videoUrl: string) => {
            const title = getTitleFromFileName(file.name)

            await createQgFromVideo({
                title,
                source: 'webapp',
                videoUrl
            })
        },
        [createQgFromVideo]
    )

    const triggerPlaybookCreationFromUploadedVideo = useCallback(
        async (playbookId: string, file: FileType, url: string) => {
            setPostProcessing(true)

            const title = getTitleFromFileName(file.name)

            const uploadResponse = await triggerPlaybookUploadFinishListener({
                playbookId,
                originalFileName: file.name,
                title,
                uid,
                type: 'webapp',
                uploadedBy,
                isRecording: false,
                mimeType: file.type,
                fileSize: file.size,
                url
            })

            setPostProcessing(false)

            if (!uploadResponse) {
                logToAnalytics('failed_to_upload_after_recording', {
                    'pb-id': playbookId,
                    'pb-title': title
                })
                showErrorNotification(
                    'Something went wrong while uploading your video, please try again'
                )
                return
            }

            logToAnalytics('upload_from_local_drive', {
                'pb-id': playbookId,
                'pb-title': title
            })
        },
        [uid, uploadedBy, showErrorNotification]
    )

    const isPostProcessingLoading = postProcessing || $createQgFromVideo.isLoading

    return useMemo(
        () => ({
            handleUploadSuccess: enableQGFromVideo
                ? triggerQGCreationFromUploadedVideo
                : triggerPlaybookCreationFromUploadedVideo,
            isPostProcessingLoading
        }),
        [
            enableQGFromVideo,
            isPostProcessingLoading,
            triggerQGCreationFromUploadedVideo,
            triggerPlaybookCreationFromUploadedVideo
        ]
    )
}
