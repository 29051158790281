import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Stack, styled, Box, Button, Tooltip } from '@mui/material'

import { SpacedGroup } from 'UI/Components'

import { ReactComponent as DeleteIcon } from 'assets/icons/white_bin.svg'
import { ReactComponent as OkIcon } from 'assets/icons/white_check.svg'

import {
    setStepMultiCtaVisibility,
    setQuickGuiddeStepCTA,
    setCurrentCTA,
    setStepMultiApplyVisibility
} from 'ducks'
import { logToAnalytics } from 'modules'

import { DEFAULT_ACTION, DEFAULT_HEADER } from './MultipleCTA'

const StyledButton = styled(Button)({
    background: 'rgba(255, 255, 255, 0.4) !important',
    minHeight: '35px'
})

const LightTooltip = styled(Tooltip)(({ theme }) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    }
}))

type Props = {
    onApplyClick: () => void
    frameWidth: number
}

const MAXIMUM_FRAME_SIZE = 890

export const CTAToolbarButtons = ({ onApplyClick, frameWidth }: Props) => {
    const dispatch = useDispatch()
    const { playbookId } = useParams<{ playbookId?: string }>()

    const { stepCtaMultiApplyVisibility } = useSelector(state => state.qgCta)

    const isSmallContainer = frameWidth < MAXIMUM_FRAME_SIZE

    return (
        <Stack
            direction="row"
            top={isSmallContainer ? '0' : '10px'}
            right={isSmallContainer ? '0' : '5px'}
            padding={isSmallContainer ? '8px' : '10px'}
            spacing={1}
            sx={{
                position: 'absolute',
                borderRadius: '4px'
            }}
        >
            <LightTooltip title={isSmallContainer ? 'DELETE' : ''}>
                <StyledButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                        dispatch(setQuickGuiddeStepCTA())
                        dispatch(setStepMultiCtaVisibility(false))
                        dispatch(setStepMultiApplyVisibility(false))
                        dispatch(
                            setCurrentCTA({
                                ctaType: 'multiple',
                                title: DEFAULT_HEADER,
                                actions: [DEFAULT_ACTION]
                            })
                        )
                    }}
                >
                    <SpacedGroup
                        alignItems="center"
                        alignContent="center"
                        spacing={1}
                        onClick={() => {
                            logToAnalytics('insert_action_multi_deleteIcn_clicked', {
                                playbookId
                            })
                        }}
                    >
                        <DeleteIcon />
                        {!isSmallContainer && (
                            <Box mt={0.5} letterSpacing="1.25px">
                                DELETE
                            </Box>
                        )}
                    </SpacedGroup>
                </StyledButton>
            </LightTooltip>
            {stepCtaMultiApplyVisibility && (
                <LightTooltip title={isSmallContainer ? 'APPLY' : ''}>
                    <StyledButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                            logToAnalytics('insert_action_multi_applyIcn_clicked', {
                                playbookId
                            })
                            onApplyClick()
                        }}
                    >
                        <SpacedGroup alignItems="center" alignContent="center" spacing={1}>
                            <OkIcon />
                            {!isSmallContainer && (
                                <Box mt={0.5} letterSpacing="1.25px">
                                    APPLY
                                </Box>
                            )}
                        </SpacedGroup>
                    </StyledButton>
                </LightTooltip>
            )}
        </Stack>
    )
}
