import { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { type KeyedMutator } from 'swr'

import {
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Radio,
    Stack
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'

import { ConfirmationDialog, SpacedGroup } from 'UI/Components'
import { brandKitRequestPath } from 'UI/Routes/brand-kit/PageBrandKit'
import { BrandKitCardDialog } from 'UI/Routes/brand-kit/sections/dialogs'
import { ActionContainer } from './ItemCard'

import { type BrandKitResponseType, useBoolean, useDataMutation, useNotification } from 'hooks'
import { isColorLight, request } from 'modules'

import { type MultiBrandKitItem } from 'app/types'
import { paths } from 'app/paths'

type Props = {
    selectedBrandKit: MultiBrandKitItem
    revalidateMultiBrandKit: KeyedMutator<Array<MultiBrandKitItem>>
}

export const BrandKitCard = ({ selectedBrandKit, revalidateMultiBrandKit }: Props) => {
    const { name, logo, color, id, isDefault } = selectedBrandKit

    const { showSuccessNotification } = useNotification()

    const [anchor, setAnchor] = useState<null | HTMLElement>(null)

    const history = useHistory()

    const loading = useBoolean()
    const hovered = useBoolean()

    const showEditDialog = useBoolean()
    const showDeleteDialog = useBoolean()

    const $updateMultiBrandKit = useDataMutation<
        Partial<MultiBrandKitItem>,
        BrandKitResponseType,
        Error
    >(brandKitRequestPath, 'PATCH')

    return (
        <Box
            width={210}
            height={162}
            borderRadius={4}
            border="1px solid rgba(9,12,16,0.08)"
            sx={{ cursor: 'pointer', overflow: 'hidden' }}
            onMouseEnter={hovered.setTrue}
            onMouseLeave={hovered.setFalse}
            position="relative"
        >
            {hovered.isTrue && (
                <ActionContainer
                    position="absolute"
                    right="8px"
                    top="8px"
                    onClick={e => setAnchor(e.currentTarget)}
                >
                    <MoreVertIcon
                        data-cy="brandkit-itemcard-edit-button"
                        style={{ color: 'white' }}
                        fontSize="small"
                    />
                </ActionContainer>
            )}

            <SpacedGroup
                flexDirection="column"
                justifyContent="center"
                height={120}
                width={208}
                style={{ backgroundColor: color, borderRadius: '4px 4px 0 0' }}
                onClick={() => {
                    history.push(
                        generatePath(paths.brandKitDetails, {
                            id
                        })
                    )
                }}
            >
                <img
                    src={logo}
                    height="auto"
                    style={{ objectFit: 'contain', maxHeight: '30px', marginTop: 5 }}
                    alt="brand-logo"
                />
                <Box
                    px={3}
                    fontWeight={700}
                    fontSize={12}
                    height={36}
                    color={isColorLight(color) ? 'black' : 'rgba(255, 255, 255, 1)'}
                    textAlign="center"
                    style={{ wordBreak: 'break-word' }}
                >
                    {name}
                </Box>
            </SpacedGroup>

            <Stack
                direction="row"
                alignItems="center"
                sx={{ borderTop: 'solid 1px rgba(9,12,16,0.08)' }}
                onClick={() => {
                    $updateMultiBrandKit
                        .mutate({
                            id,
                            isDefault: true
                        })
                        .then(() => revalidateMultiBrandKit())
                }}
            >
                <Radio
                    size="small"
                    color="primary"
                    checked={isDefault}
                    name="radio-buttons"
                    style={{ marginRight: 0 }}
                />

                <Box
                    fontWeight={700}
                    fontSize={12}
                    color={isDefault ? '#090C10' : 'rgba(9, 12, 16, 0.60)'}
                >
                    {isDefault ? 'Workspace Default' : 'Set as Workspace Default'}
                </Box>
            </Stack>

            <Popper open={Boolean(anchor)} anchorEl={anchor} placement="bottom-end" disablePortal>
                <Paper onMouseLeave={() => setAnchor(null)}>
                    <MenuList
                        autoFocusItem={false}
                        onClick={() => setAnchor(null)}
                        dense={false}
                        sx={{ minWidth: 150 }}
                    >
                        <MenuItem onClick={showEditDialog.setTrue}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Rename" />
                        </MenuItem>
                        {!isDefault && (
                            <MenuItem onClick={showDeleteDialog.setTrue}>
                                <ListItemIcon>
                                    <BinIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete brand kit" />
                            </MenuItem>
                        )}
                    </MenuList>
                </Paper>
            </Popper>

            {showEditDialog.isTrue && (
                <BrandKitCardDialog
                    onClose={showEditDialog.setFalse}
                    currentName={name}
                    id={id}
                    isDefault={isDefault}
                />
            )}

            {showDeleteDialog.isTrue && (
                <ConfirmationDialog
                    isOpen
                    onClose={showDeleteDialog.setFalse}
                    onConfirm={() => {
                        loading.setTrue()
                        request(`${brandKitRequestPath}/${id}`, 'DELETE', {})
                            .then(() => {
                                revalidateMultiBrandKit()
                                showSuccessNotification('Brand Kit successfully removed')
                            })
                            .finally(loading.setFalse)
                    }}
                    title={`Delete ${name}`}
                    text={`Are you sure you want to delete the ${name} ?`}
                    loading={loading.isTrue}
                />
            )}
        </Box>
    )
}
