import { type MouseEvent } from 'react'

import { Box, Typography, Button } from '@mui/material'

import { ReactComponent as GreyImage } from 'assets/icons/brandKitGrey.svg'
import { ReactComponent as WhiteImage } from 'assets/icons/brandKitWhite.svg'

import { SpacedGroup } from 'UI/Components'

import { ReactComponent as Icon } from 'assets/icons/medal.svg'
import { useServiceUsage } from 'hooks'

type Props = {
    isFreePlan: boolean
    setAnchorEl: (val: HTMLElement | null) => void
}

export const Banner = ({ isFreePlan, setAnchorEl }: Props) => {
    const { canUseTrial } = useServiceUsage()

    return (
        <Box
            p={2}
            borderRadius="4px"
            boxShadow={
                isFreePlan
                    ? '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)'
                    : 'none'
            }
            bgcolor={isFreePlan ? '#F5F5F5' : 'transparent'}
        >
            <SpacedGroup spacing={5} alignItems="flex-start">
                <Box>{isFreePlan ? <WhiteImage /> : <GreyImage />}</Box>
                <Box>
                    <Typography variant="h4">Ensure your videos are on-brand.</Typography>
                    <Box my={2}>
                        <Typography>
                            Your brand isn’t just your name and logo, create videos that ‘speak’
                            your language.
                        </Typography>
                        <Typography>
                            With our Brand kit you can adjust fonts, colors and more.
                        </Typography>
                    </Box>
                    {isFreePlan && (
                        <Button
                            onClick={(e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
                            startIcon={<Icon />}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {canUseTrial ? 'Get 14 days trial' : 'UPGRADE'}
                        </Button>
                    )}
                </Box>
            </SpacedGroup>
        </Box>
    )
}
