import { type ReactNode, createContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useTimer } from './use-timer'

export type StepTimeline = {
    stepStart: number
    stepEnd: number
    stepDuration: number
    transitionDuration: number
}

export const SmartPreviewContext = createContext({
    start: () => {},
    pause: () => {},
    stop: () => {},
    time: 0,
    setTime: (_time: number) => {},
    status: 'stopped' as 'stopped' | 'running',
    activeIndex: 0,
    stepsTimeline: {
        steps: [] as Array<StepTimeline>,
        totalDuration: 0
    },
    totalProgress: 0
})

export const TransitionContext = createContext<{ transitionProgress: number }>({
    transitionProgress: 0
})

type Props = {
    children: ReactNode
}

export const SmartPreviewProvider = ({ children }: Props) => {
    const {
        present: { steps, activeStep }
    } = useSelector(state => state.qgEditor)

    const stepsTimeline = useMemo(() => {
        return steps.reduce(
            (acc, step) => {
                const stepDuration = step.duration
                const transitionDuration = step.transition?.duration || 0
                const stepEnd = acc.totalDuration + stepDuration + transitionDuration

                return {
                    steps: [
                        ...acc.steps,
                        {
                            stepStart: acc.totalDuration,
                            stepEnd,
                            stepDuration,
                            transitionDuration
                        }
                    ],
                    totalDuration: stepEnd
                }
            },
            {
                steps: [] as Array<StepTimeline>,
                totalDuration: 0
            }
        )
    }, [steps])

    const { time, setTime, start, pause, stop, status } = useTimer(stepsTimeline.totalDuration)

    const activeIndex = useMemo(() => {
        const index = stepsTimeline.steps.findIndex(step => time <= step.stepEnd)
        if (index === -1) return activeStep

        return index
    }, [activeStep, stepsTimeline.steps, time])

    const transitionProgress = useMemo(() => {
        if (activeIndex === -1) return 0

        const { stepDuration, stepEnd, transitionDuration } = stepsTimeline.steps[activeIndex]
        if (!transitionDuration) return 0

        const percent = 100 - ((stepEnd - time - stepDuration) / transitionDuration) * 100

        return Math.min(100, Math.max(0, percent))
    }, [activeIndex, stepsTimeline.steps, time])

    const totalProgress = useMemo(() => {
        return Math.floor(Math.min(100, Math.max(0, (time / stepsTimeline.totalDuration) * 100)))
    }, [stepsTimeline.totalDuration, time])

    useEffect(() => {
        if (status === 'running') return
        if (stepsTimeline.totalDuration >= time) return

        setTime(stepsTimeline.totalDuration)
        // Prevent unnecessary re-renders on every time change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTime, status, stepsTimeline.totalDuration])

    // We don't want to use SmartPreviewContext inside Step
    // because it will cause re-render of all children every x ms due to time change
    // So instead of that, we use global function which is isolated from context
    useEffect(() => {
        const updateTimeOnStepChange = (index: number) => {
            if (index === activeIndex) return
            const step = stepsTimeline.steps[index]
            setTime(step.stepStart + 0.01) // to fix an issue with detecting activeIndex
        }

        ;(window as any).updateTimeOnStepChange = updateTimeOnStepChange

        return () => {
            ;(window as any).updateTimeOnStepChange = undefined
        }
    }, [activeIndex, setTime, stepsTimeline])

    const optimizedTime = Math.floor(time * 10) / 10

    const contextValue = useMemo(
        () => ({
            start,
            pause,
            stop,
            time: optimizedTime, // 10 times per second
            setTime: (value: number) => setTime(Math.min(value, stepsTimeline.totalDuration)),
            status,
            stepsTimeline,
            activeIndex,
            totalProgress
        }),
        [
            activeIndex,
            optimizedTime,
            pause,
            setTime,
            start,
            status,
            stepsTimeline,
            stop,
            totalProgress
        ]
    )

    const transitionContextValue = useMemo(() => ({ transitionProgress }), [transitionProgress])

    // Sync smart preview time every time when activeStep changes
    useEffect(() => {
        ;(window as any).updateTimeOnStepChange(activeStep)
    }, [activeStep])

    return (
        // We have two different contexts because we don't want to re-render all children when transitionProgress changes
        // That's we use TransitionContext only in TransitionSlide, all the rest uses SmartPreviewContext
        <SmartPreviewContext.Provider value={contextValue}>
            <TransitionContext.Provider value={transitionContextValue}>
                {children}
            </TransitionContext.Provider>
        </SmartPreviewContext.Provider>
    )
}
