import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Box,
    Button,
    DialogActions
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ErrorImage from 'assets/images/payment-error.png'

type Props = {
    isOpen: boolean
    onClose: () => void
    onClick: () => void
    errorMessage?: string
}

export const PaymentErrorDialog = ({ isOpen, onClose, onClick, errorMessage }: Props) => (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
        <IconButton
            onClick={onClose}
            sx={theme => ({
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1)
            })}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle>
            <Box mt={2}>
                <Typography variant="subtitle1" align="center">
                    Something went wrong
                </Typography>
            </Box>
        </DialogTitle>
        <DialogContent
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
            }}
        >
            <img src={ErrorImage} alt="img" />
            {errorMessage ? (
                <Typography variant="subtitle2">{errorMessage}</Typography>
            ) : (
                <>
                    <Typography variant="subtitle2">
                        We aren’t able to process your payment.
                    </Typography>
                    <Typography variant="subtitle2">Please try again</Typography>
                </>
            )}
        </DialogContent>
        <DialogActions sx={theme => ({ padding: theme.spacing(3), justifyContent: 'center' })}>
            <Button onClick={onClick}>Back to the payment</Button>
        </DialogActions>
    </Dialog>
)
