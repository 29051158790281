import { useCallback } from 'react'
import { Image, Layer } from 'react-konva'

import useImage from 'use-image'

import { RectangleShape } from './RectangleShape'

import { type ImageShapeType, type RectangleShapeType, type StepType, Shape } from 'app/types'

type Props = {
    step: StepType
}

export const BgShapes = ({ step }: Props) => {
    const findLayer = useCallback(
        (type: Shape.Image | Shape.Rectangle) =>
            step?.layers.find(layer => layer.type === type && layer.isBackground),
        [step]
    )

    const bgImageLayer = findLayer(Shape.Image) as ImageShapeType | undefined
    const bgRectLayer = findLayer(Shape.Rectangle) as RectangleShapeType | undefined

    const [image] = useImage(bgImageLayer?.url || '', 'anonymous')

    if (!bgImageLayer && !bgRectLayer) return null

    return (
        <Layer>
            {bgRectLayer && (
                <RectangleShape
                    layer={bgRectLayer}
                    hasOverlay={false} // we don't use it if isBackground
                    onDragStart={() => {}} // we don't use it if isBackground
                    onDragMove={() => {}} // we don't use it if isBackground
                    onDragEnd={() => {}} // we don't use it if isBackground
                    onSelect={() => {}} // we don't use it if isBackground
                />
            )}

            {bgImageLayer && (
                <Image
                    id={bgImageLayer.id}
                    name="backgroundImage"
                    image={image}
                    scaleX={bgImageLayer.scaleX}
                    scaleY={bgImageLayer.scaleY}
                    y={bgImageLayer.y}
                    x={bgImageLayer.x}
                />
            )}
        </Layer>
    )
}
