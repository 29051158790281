import { Box } from '@mui/material'

import { SpacedGroup } from 'UI/Components'

const palette = {
    slate: [
        '#0F172A',
        '#1E293B',
        '#334155',
        '#475569',
        '#64748B',
        '#94a3b8',
        '#cbd5e1',
        '#e2e8f0',
        '#f1f5f9',
        '#f8fafc',
        '#000000'
    ],
    grey: [
        '#111827',
        '#1f2937',
        '#374151',
        '#4b5563',
        '#6b7280',
        '#9ca3af',
        '#d1d5db',
        '#e5e7eb',
        '#f3f4f6',
        '#f9fafb',
        '#FFFFFF'
    ],
    neutral: [
        '#171717',
        '#262626',
        '#404040',
        '#525252',
        '#737373',
        '#a3a3a3',
        '#d4d4d4',
        '#e5e5e5',
        '#f5f5f5',
        '#fafafa'
    ],
    red: [
        '#7f1d1d',
        '#991b1b',
        '#b91c1c',
        '#dc2626',
        '#ef4444',
        '#f87171',
        '#fca5a5',
        '#fecaca',
        '#fee2e2',
        '#fef2f2'
    ],
    orange: [
        '#7c2d12',
        '#9a3412',
        '#c2410c',
        '#ea580c',
        '#f97316',
        '#fb923c',
        '#fdba74',
        '#fed7aa',
        '#ffedd5',
        '#fff7ed'
    ],
    amber: [
        '#78350f',
        '#92400e',
        '#b45309',
        '#d97706',
        '#f59e0b',
        '#fbbf24',
        '#fcd34d',
        '#fde68a',
        '#fef3c7',
        '#fffbeb'
    ],
    yellow: [
        '#713f12',
        '#854D0E',
        '#A16207',
        '#CA8A04',
        '#eab308',
        '#facc15',
        '#FDE047',
        '#fef08a',
        '#fef9c3',
        '#fefce8'
    ],
    lime: [
        '#365314',
        '#3f6212',
        '#4d7c0f',
        '#65a30d',
        '#84cc16',
        '#a3e635',
        '#bef264',
        '#d9f99d',
        '#ecfccb',
        '#f7fee7'
    ],
    green: [
        '#14532d',
        '#166534',
        '#15803d',
        '#16a34a',
        '#22c55e',
        '#4ade80',
        '#86efac',
        '#bbf7d0',
        '#dcfce7',
        '#f0fdf4'
    ],
    emerald: [
        '#064e3b',
        '#065f46',
        '#047857',
        '#059669',
        '#10b981',
        '#34d399',
        '#6ee7b7',
        '#a7f3d0',
        '#d1fae5',
        '#ecfdf5'
    ],
    teal: [
        '#134e4a',
        '#115e59',
        '#0f766e',
        '#0d9488',
        '#14b8a6',
        '#2dd4bf',
        '#5eead4',
        '#99f6e4',
        '#ccfbf1',
        '#f0fdfa'
    ],
    cyan: [
        '#164e63',
        '#155e75',
        '#0e7490',
        '#0891b2',
        '#06b6d4',
        '#22d3ee',
        '#67e8f9',
        '#a5f3fc',
        '#cffafe',
        '#ecfeff'
    ],
    sky: [
        '#0C4A6E',
        '#075985',
        '#0369A1',
        '#0284C7',
        '#0ea5e9',
        '#38BDF8',
        '#7dd3fc',
        '#BAE6FD',
        '#E0F2FE',
        '#f0f9ff'
    ],
    blue: [
        '#1e3a8a',
        '#1e40af',
        '#1d4ed8',
        '#2563eb',
        '#3b82f6',
        '#60a5fa',
        '#93c5fd',
        '#bfdbfe',
        '#dbeafe',
        '#eff6ff'
    ],
    indigo: [
        '#312e81',
        '#3730a3',
        '#4338ca',
        '#4f46e5',
        '#6366f1',
        '#818cf8',
        '#a5b4fc',
        '#c7d2fe',
        '#e0e7ff',
        '#eef2ff'
    ],
    violet: [
        '#4c1d95',
        '#5b21b6',
        '#6d28d9',
        '#7c3aed',
        '#8b5cf6',
        '#a78bfa',
        '#c4b5fd',
        '#ddd6fe',
        '#ede9fe',
        '#f5f3ff'
    ],
    purple: [
        '#581c87',
        '#6b21a8',
        '#7e22ce',
        '#9333ea',
        '#a855f7',
        '#c084fc',
        '#d8b4fe',
        '#e9d5ff',
        '#f3e8ff',
        '#faf5ff'
    ],
    fuchsia: [
        '#701a75',
        '#86198f',
        '#a21caf',
        '#c026d3',
        '#d946ef',
        '#e879f9',
        '#f0abfc',
        '#f5d0fe',
        '#fae8ff',
        '#fdf4ff'
    ],
    pink: [
        '#831843',
        '#9D174D',
        '#BE185D',
        '#DB2777',
        '#EC4899',
        '#F472B6',
        '#F9A8D4',
        '#FBCFE8',
        '#FCE7F3',
        '#FDF2F8'
    ],
    rose: [
        '#881337',
        '#9F1239',
        '#BE123C',
        '#E11D48',
        '#F43F5E',
        '#FB7185',
        '#FDA4AF',
        '#FECDD3',
        '#FFE4E6',
        '#FFF1F2'
    ]
}

export const generateColor = (str: string) => str.replace('#', '')

type Props = {
    setColor: (color: string) => void
    result?: string
}

export const ColorsPalette = ({ setColor, result = '' }: Props) => (
    <SpacedGroup m={2} spacing={1.1} alignItems="flex-start">
        {Object.entries(palette).map(([key, values]) => (
            <Box key={key}>
                {values.map(it => (
                    <Box
                        key={it + result}
                        bgcolor={it.toUpperCase() === result ? 'rgba(254, 210, 67, 1)' : 'none'}
                    >
                        <Box
                            mt={1.1}
                            bgcolor={it}
                            borderRadius="50%"
                            style={{ cursor: 'pointer' }}
                            width={20}
                            height={20}
                            border={
                                it.toUpperCase() === result
                                    ? '2px solid #cb0000'
                                    : '1px solid rgba(9, 12, 16, 0.08)'
                            }
                            onClick={() => setColor(generateColor(it.toUpperCase()))}
                        />
                    </Box>
                ))}
            </Box>
        ))}
    </SpacedGroup>
)
