import { useState } from 'react'

import Image from 'assets/images/remove-watermark.png'
import { ReactComponent as TrophyIcon } from 'assets/icons/trophy-icon.svg'

import { Button } from '@mui/material'

import { useServiceUsage } from 'hooks'

import { UpgradePlanPopup } from 'UI/Components'

export const RemoveWaterMark = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const { isFreePlan, isLoading } = useServiceUsage()

    if (!isFreePlan || isLoading) return null

    return (
        <>
            <Button
                color="inherit"
                style={{
                    whiteSpace: 'nowrap',
                    background: '#F8C521',
                    color: '#090C10',
                    fontWeight: 500,
                    fontSize: 14,
                    letterSpacing: 1.25,
                    textTransform: 'uppercase',
                    boxShadow: 'none'
                }}
                startIcon={<TrophyIcon />}
                onClick={e => setAnchorEl(e.currentTarget)}
            >
                remove watermark
            </Button>
            <UpgradePlanPopup
                imageSrc={Image}
                title="Remove watermarks"
                text="Take control of your brand and remove the guidde watermark."
                hideSeePlanFeatures={true}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </>
    )
}
