import { Box, CircularProgress, Typography } from '@mui/material'

type Props = {
    title: string
}

export const VoiceOverLoader = ({ title }: Props) => {
    return (
        <Box textAlign="center">
            <CircularProgress size={100} />
            <Box my={1}>
                <Typography variant="subtitle1">{title}</Typography>
            </Box>
            <Typography>Your transcript will be ready in just a few moments</Typography>
        </Box>
    )
}
