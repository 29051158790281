import { type UseBooleanType } from 'hooks'

import { HoverButton } from 'UI/Components/VideoEditor/hoverControls'

import LaunchIcon from '@mui/icons-material/Launch'

type Props = {
    showSidePanel?: UseBooleanType
}

export const ToggleSidePanel = ({ showSidePanel }: Props) => {
    if (!showSidePanel) return null

    return (
        <HoverButton
            icon={<LaunchIcon style={{ color: 'white', cursor: 'pointer' }} />}
            title={`${showSidePanel.isTrue ? 'Hide' : 'Show'} Side Panel`}
            onClick={() => showSidePanel.toggle()}
        />
    )
}
