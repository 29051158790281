import { Box, Typography, styled } from '@mui/material'

import EmptyIcon from 'assets/images/empty-page.png'

const PREFIX = 'EmptyPlaybooks'

const classes = {
    img: `${PREFIX}-img`,
    title: `${PREFIX}-title`,
    text: `${PREFIX}-text`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.img}`]: {
        [theme.breakpoints.down('md')]: {
            width: '70vw'
        }
    },

    [`& .${classes.title}`]: {
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            marginTop: theme.spacing(2)
        }
    },

    [`& .${classes.text}`]: {
        [theme.breakpoints.down('md')]: {
            fontSize: '14px'
        }
    }
}))

export const EmptyPlaybooks = () => {
    return (
        <StyledBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt="10%"
            mb="10%"
        >
            <Typography variant="h5" className={classes.title}>
                No videos yet
            </Typography>
            <Box mt={1} />
            <Typography variant="h5" className={classes.text}>
                Start creating videos and collaborate
            </Typography>
            <Typography variant="h5" className={classes.text}>
                with your team
            </Typography>
            <Box mt={2} />
            <img src={EmptyIcon} alt="img" className={classes.img} />
        </StyledBox>
    )
}
