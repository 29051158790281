import { Box, Tooltip } from '@mui/material'

import AddIcon from '@mui/icons-material/AddCircleOutlineSharp'
import { ReactComponent as NoColorIcon } from 'assets/icons/no-color.svg'

type ButtonAddProps = {
    onClick: () => void
    disabled: boolean
    tooltip: string
}

export const ButtonAdd = ({ onClick, disabled, tooltip }: ButtonAddProps) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        maxWidth={32}
        width={31}
        onClick={disabled ? undefined : onClick}
        style={{
            cursor: disabled ? 'auto' : 'pointer'
        }}
    >
        <Tooltip title={tooltip}>
            <AddIcon />
        </Tooltip>
    </Box>
)

type ColorButtonProps = {
    value: string
    onClick: (value: string) => void
    disabled: boolean
    isActive: boolean
    borderRight: string
    circleBorder: string
}

export const ColorButton = ({
    onClick,
    circleBorder,
    disabled,
    isActive,
    value,
    borderRight
}: ColorButtonProps) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width={32}
        onClick={() => {
            if (disabled) return

            onClick(value)
        }}
        sx={{
            cursor: disabled ? 'auto' : 'pointer',
            background: isActive ? 'rgba(203, 0, 0, 0.1)' : 'none',
            borderRight
        }}
    >
        {value === '' ? (
            <NoColorIcon />
        ) : (
            <Box
                width={20}
                height={20}
                borderRadius="50%"
                overflow="hidden"
                style={{
                    background: value,
                    border: isActive ? '1px solid #CB0000' : circleBorder
                }}
            />
        )}
    </Box>
)
