import { type MutableRefObject, useContext } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'

import type Konva from 'konva'
import { Stage } from 'react-konva'

import { Box } from '@mui/material'

import { QGContext, SmartPreviewContext } from 'UI/Routes/quick-guidde/CanvasEditor'
import { Shapes } from './Shapes'

import { store } from 'ducks/store'
import { selectLayer } from 'ducks'

type Props = {
    stageRef: MutableRefObject<Konva.Stage | null>
}

export const Workspace = ({ stageRef }: Props) => {
    const dispatch = useDispatch()

    const { status } = useContext(SmartPreviewContext)
    const { frameWidth, frameHeight, scale } = useContext(QGContext)

    const {
        present: { selectedLayersIds, selectedSteps, steps, activeStep }
    } = useSelector(state => state.qgEditor)

    if (status === 'running') return null

    return (
        <Box
            id="workspace"
            height="100%"
            width="100%"
            minWidth={300}
            minHeight={300}
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="center"
            onClick={e => {
                const target = e.target as HTMLDivElement

                if (target.id !== 'workspace') return
                if (selectedLayersIds.length || selectedSteps.length) {
                    dispatch(selectLayer(null))
                }
            }}
        >
            <Stage
                ref={stageRef}
                width={frameWidth}
                height={frameHeight}
                scale={{ x: scale, y: scale }}
                onClick={e => {
                    // we need to deselect element if user clicks on background (rect) layer
                    if (e.target.attrs?.name?.includes('background')) {
                        dispatch(selectLayer(null))
                    }
                }}
            >
                {/* Workaround based on this post: https://github.com/konvajs/react-konva/issues/311#issuecomment-454411007 */}
                <Provider store={store}>
                    {/* All supported shapes/layers */}
                    <Shapes
                        stageRef={stageRef}
                        scale={scale}
                        step={steps[activeStep]}
                        selectedLayersIds={selectedLayersIds}
                        isSmartPreview={false}
                    />
                </Provider>
            </Stage>
        </Box>
    )
}
