import { useEffect, useMemo, memo, useRef, useContext, useCallback } from 'react'
import { useSelector } from 'react-redux'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import VoiceIcon from '@mui/icons-material/SettingsVoice'

import { Typography, Box, Tooltip, IconButton, CircularProgress } from '@mui/material'

import { HighlightedSegment } from '@guidde-co/shared.transcript.highlighted-segment'
import { VirtualScroll } from '@guidde-co/shared.transcript.virtual-scroll'

import {
    useAuth,
    useBoolean,
    useConfiguration,
    useCopyToClipboard,
    useDataMutation,
    useNotification,
    useOrgFeatureFlags,
    useRoles,
    useWindowView,
    getLabelFromCode
} from 'hooks'

import {
    type SegmentType,
    type Subtitle,
    type TranscriptRange,
    type TranscriptType,
    SpacedGroup,
    VideoStatusContext,
    ConfirmationDialog,
    SubtitlePopper,
    ReTranscribe,
    EditableSegment
} from 'UI/Components'

import { logToAnalytics, playbookToAnalyticsProps } from 'modules'

import { links } from 'app/links'
import { type PlaybookType, type VideoRefType } from 'app/types'

const generateTranscriptRanges = (transcript: Array<SegmentType>) => {
    if (!transcript) return []

    const res: Array<TranscriptRange> = []
    transcript.forEach((segment: SegmentType) => {
        segment.subtitles.forEach((subtitle: Subtitle) => {
            res.push({
                start: subtitle.start,
                finish: subtitle.start + subtitle.duration
            })
        })
    })
    return res
}

const checkActiveSegment = (
    realVideoTime: number,
    transcriptRanges: Array<TranscriptRange>,
    idx: number
) => {
    if (!transcriptRanges[idx]) return false

    return (
        transcriptRanges[idx].start < realVideoTime && transcriptRanges[idx].finish > realVideoTime
    )
}

/*
    extract all the subtitles from all the segments into
    a single array and add to each subtitle segmentIndex and subtitleIndex
    that will be used later in edit mode
*/
const extractSubtitles = (transcript: Array<SegmentType>) => {
    if (!transcript) return []

    const res: Array<Subtitle> = []
    transcript.forEach((segment: SegmentType, index: number) => {
        segment.subtitles.forEach((subtitle: Subtitle, subTitleIndex: number) => {
            res.push({
                text: subtitle.text,
                start: subtitle.start,
                duration: subtitle.duration,
                segmentIndex: index,
                subtitleIndex: subTitleIndex
            })
        })
    })
    return res
}

const getSubtitlesText = (transcript: Subtitle[] = []) =>
    [
        ...transcript.map((subtitle: Subtitle) => `<p>${subtitle.text}</p>`),
        `<p><a target="_blank" style="color:#cd0000" href="${links.guiddeWebsite}">Powered by guidde</a></p>`
    ].join('')

const MIN_SEARCH_LENGTH = 2

type Props = {
    playbook: PlaybookType
    transcript: TranscriptType
    videoRef: VideoRefType
    isEditMode: boolean
    maxViewHeight?: number
    searchString?: string
}

const availableLanguages = [
    'de-DE',
    'es-ES',
    'it-IT',
    'fr-FR',
    'pt-BR',
    'en-US',
    'en-AU',
    'en-GB',
    'en-CA',
    'en-IN',
    'en-IE',
    'en-ZA',
    'zh'
]

export const Transcript = memo(
    ({
        playbook,
        transcript,
        videoRef,
        isEditMode,
        maxViewHeight = 100,
        searchString = ''
    }: Props) => {
        const { isMobileView } = useWindowView()
        const { uid, orgId } = useAuth()
        const { isAdmin, isContentManager } = useRoles()
        const { uploadSubtitles, enableVoiceOver } = useOrgFeatureFlags(orgId)
        const { copyToClipboard } = useCopyToClipboard()

        const { showSuccessNotification } = useNotification()

        const { editable } = useContext(VideoStatusContext)

        const isOwner = uid === playbook?.creator_uid

        const realVideoTime = useSelector(state => state.videoEditor.currentTime || 0)

        const activeSegmentRef = useRef(0)

        const confirmDialog = useBoolean()

        const virtuoso = useRef<any | null>(null)

        const subtitles = useMemo(() => {
            return extractSubtitles(transcript?.segments || [])
        }, [transcript?.segments])

        const transcriptRanges = generateTranscriptRanges(transcript?.segments || [])

        const { isConfigurationLoading, maxVoiceOverPlaybookDuration } = useConfiguration()

        const maxDuration = isConfigurationLoading ? 0 : Number(maxVoiceOverPlaybookDuration) || 300

        // Change video time according to clicked segment
        const handleSegmentClick = useCallback(
            (videoRef: VideoRefType, item: Subtitle) => {
                const seconds = item.start

                if (videoRef?.current) {
                    videoRef.current.currentTime = seconds
                    videoRef.current.play()
                    logToAnalytics('timestampClicked', {
                        ...playbookToAnalyticsProps(playbook),
                        timeOffset: item.start
                    })
                }
            },
            [playbook]
        )

        const isReTranscribeVisible =
            isEditMode && playbook.currentTranscriptLang && (isAdmin || isOwner || isContentManager)

        useEffect(() => {
            if (searchString?.length > 0) return

            const ind = subtitles?.findIndex((_: any, idx: number) =>
                checkActiveSegment(realVideoTime, transcriptRanges, idx)
            )

            if (ind === -1) return

            const activeIndex = ind === undefined || ind < 0 ? 0 : ind

            if (activeSegmentRef.current !== activeIndex) {
                activeSegmentRef.current = activeIndex
                virtuoso?.current?.scrollToIndex({
                    index: activeIndex,
                    align: 'start',
                    behavior: 'auto'
                })
            }
        }, [realVideoTime, transcriptRanges, transcript, subtitles, searchString?.length])

        const transcriptHeight = isMobileView ? 528 : 168

        const filteredSubtitles = useMemo(() => {
            // For empty search string or search length under MIN_SEARCH_LENGTH return all the subtitles
            if (searchString?.length <= MIN_SEARCH_LENGTH) return subtitles

            // limit search results to 50 items
            const res = subtitles
                .filter(item => item.text.toLowerCase().includes(searchString?.toLowerCase()))
                .slice(0, 50)

            logToAnalytics(res?.length ? 'search' : 'search-no-results', {
                text: searchString?.toLowerCase(),
                hits: res?.length,
                inVideo: true,
                ...playbookToAnalyticsProps(playbook)
            })

            return res
        }, [searchString, subtitles, playbook])

        const handleCopyClick = () => {
            copyToClipboard(
                getSubtitlesText(filteredSubtitles || []),
                'Transcription was copied to the clipboard'
            )
        }

        const handleVoiceOver = () => {
            $createVoiceOver.mutate({ playbookId: playbook.id }).then(() => {
                confirmDialog.setFalse()
                logToAnalytics('generate_voice_over', playbookToAnalyticsProps(playbook))
            })
        }

        const $createVoiceOver = useDataMutation('/c/v1/generateVoice', 'POST', {
            onSuccess: () => {
                showSuccessNotification('Voice over added')

                if (videoRef?.current) {
                    videoRef.current.currentTime = 0
                }
                confirmDialog.setFalse()
            }
        })

        const itemContent = useCallback(
            (idx: number, subtitle: Subtitle) => (
                <div key={idx}>
                    {isEditMode && editable ? (
                        <EditableSegment
                            subtitle={subtitle}
                            searchWords={searchString}
                            playbook={playbook}
                            isActive={checkActiveSegment(realVideoTime, transcriptRanges, idx)}
                            onClick={() => handleSegmentClick(videoRef, subtitle)}
                        />
                    ) : (
                        <HighlightedSegment
                            searchWords={
                                searchString?.length <= MIN_SEARCH_LENGTH ? [] : [searchString]
                            }
                            subtitle={subtitle}
                            onClick={() => handleSegmentClick(videoRef, subtitle)}
                            isActive={checkActiveSegment(realVideoTime, transcriptRanges, idx)}
                        />
                    )}
                </div>
            ),
            [
                isEditMode,
                editable,
                handleSegmentClick,
                playbook,
                transcriptRanges,
                realVideoTime,
                searchString,
                videoRef
            ]
        )

        const unsupportedVoiceOverLang = !availableLanguages.includes(
            playbook.currentTranscriptLang
        )

        const disableVoiceOver = playbook.duration > maxDuration || unsupportedVoiceOverLang

        const maxDurationInMin = maxDuration / 60

        return (
            <>
                {Boolean(subtitles?.length && filteredSubtitles?.length === 0) && (
                    <Typography>We could not find a match for "{searchString}"</Typography>
                )}

                <SpacedGroup spacing={1}>
                    {filteredSubtitles?.length > 0 && (
                        <Tooltip title="Copy to clipboard">
                            <IconButton size="small" onClick={handleCopyClick}>
                                <FileCopyOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {Boolean(subtitles?.length && enableVoiceOver && isEditMode) && (
                        <Tooltip
                            title={
                                unsupportedVoiceOverLang
                                    ? `voice over doesn’t support ${
                                          getLabelFromCode(playbook.currentTranscriptLang) ||
                                          'video language'
                                      }`
                                    : disableVoiceOver
                                      ? `voice over is limited to ${maxDurationInMin}-minutes`
                                      : 'Automatically generate voice-over replacing the original audio track'
                            }
                        >
                            <Box>
                                <IconButton
                                    size="small"
                                    disabled={disableVoiceOver}
                                    onClick={confirmDialog.setTrue}
                                >
                                    {$createVoiceOver.isLoading ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <VoiceIcon />
                                    )}
                                </IconButton>
                            </Box>
                        </Tooltip>
                    )}

                    {Boolean(uploadSubtitles && isEditMode) && (
                        <SubtitlePopper playbook={playbook} />
                    )}
                </SpacedGroup>

                {!subtitles?.length ? (
                    <Typography>
                        There is no transcript for this video. Please check again later.
                    </Typography>
                ) : (
                    <VirtualScroll
                        virtuoso={virtuoso}
                        maxViewHeight={maxViewHeight}
                        heightFromTop={transcriptHeight}
                        data={filteredSubtitles}
                        itemContent={itemContent}
                    />
                )}

                {isReTranscribeVisible && (
                    <Box position="absolute" bottom="4px" right="32px" zIndex={1}>
                        <ReTranscribe
                            playbookId={playbook.id}
                            transcriptLang={playbook.currentTranscriptLang}
                        />
                    </Box>
                )}
                <ConfirmationDialog
                    isOpen={confirmDialog.isTrue}
                    title="Are you sure?"
                    text="The original audio track will be replaced with an automatically generated voice-over"
                    onClose={confirmDialog.setFalse}
                    loading={$createVoiceOver.isLoading}
                    onConfirm={handleVoiceOver}
                />
            </>
        )
    }
)
