import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useArrayStorage, useAuth, useBoolean } from 'hooks'
import {
    uuid,
    uploadFromDrive,
    batchUpload,
    logToAnalytics,
    initClientGapi,
    getTopLevelFiles,
    getFolderFiles,
    getCurrentAuth,
    getRootItems,
    logVideoImport,
    isFolder,
    isDrive
} from 'modules'

import { openUploadProgressPopup, setVideoProgress } from 'ducks/actions'
import { paths } from 'app/paths'

import { Button, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { FileItem, ItemThumbnail, UploaderModal, FileTable } from 'UI/Components'

import { gDriveColumns, sortGdriveFiles } from './constants'

import { useUploadFileMaxLimit } from '../use-upload-file-max-limit'

export const GoogleDriveUploader = ({ labelComponent, onUploadCallback }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const loadingState = useBoolean()
    const { maxUploadFileSizeInBytes } = useUploadFileMaxLimit()

    const [gAuthState, setGAuthState] = useState(null)
    const [gDriveStorage, setgDriveStorage] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [{ sortBy, sortDesc }, setSortParams] = useState({
        sortBy: gDriveColumns[0].value,
        sortDesc: false
    })

    const selectedItems = useArrayStorage([], 'id')

    const { displayName, photoURL, email, uid } = useAuth()

    const { storage } = selectedItems

    const changeSorting = useCallback(
        sortBy =>
            setSortParams(prevSort => ({
                sortBy,
                sortDesc: sortBy === prevSort.sortBy ? !prevSort.sortDesc : false
            })),
        []
    )

    const loadGDriveFolder = useCallback(
        (folder = null, direction = '', drive = null) => {
            loadingState.setTrue()
            const getFiles = folder
                ? () => getFolderFiles(folder.id)
                : drive
                  ? () => getTopLevelFiles(drive.id)
                  : () => getRootItems()

            getFiles().then(files => {
                setgDriveStorage(files)
                setBreadcrumbs(breadcrumbs => {
                    if (!folder) {
                        if (drive) return breadcrumbs.concat(drive)
                        return []
                    }

                    return direction === 'back'
                        ? breadcrumbs.slice(0, -1)
                        : breadcrumbs.concat(folder)
                })
                loadingState.setFalse()
            })
        },
        [loadingState]
    )

    const initGapi = useCallback(() => {
        if (!gAuthState) {
            initClientGapi({
                uid,
                setLoading: loadingState.setTrue,
                setAuth: setGAuthState,
                afterLoadClient: loadGDriveFolder
            })
            return
        }
        loadGDriveFolder()
    }, [gAuthState, uid, loadGDriveFolder, loadingState.setTrue])

    const loadPreviousFolder = useCallback(() => {
        const folder = breadcrumbs.length === 1 ? null : breadcrumbs[breadcrumbs.length - 2]

        loadGDriveFolder(folder, 'back')
    }, [breadcrumbs, loadGDriveFolder])

    const resetGDriveModal = useCallback(() => {
        if (breadcrumbs.length) {
            loadGDriveFolder()
        }
        selectedItems.clearStorage()
    }, [breadcrumbs.length, loadGDriveFolder, selectedItems])

    const uploadFiles = useCallback(() => {
        loadingState.setTrue()

        const uploadedBy = { displayName, photoURL, email }
        const auth = { access_token: getCurrentAuth()?.access_token || '' }
        const fileList = []

        const uploadPromises = storage.map(file => {
            const uploadId = uuid()

            dispatch(
                setVideoProgress({
                    playbookId: uploadId,
                    fileName: file.name,
                    source: 'gdrive'
                })
            )
            return uploadFromDrive({
                file,
                uid,
                uploadId,
                auth,
                uploadedBy
            }).then(() => {
                fileList.push(uploadId)

                logVideoImport('GDrive', {
                    file: file.mimeType,
                    size: Number(file.size)
                })

                logToAnalytics('upload_from_g_drive', {
                    'pb-id': uploadId,
                    'pb-title': file.name
                })
            })
        })

        Promise.all(uploadPromises)
            .then(() => {
                const id = uuid()

                batchUpload({
                    id,
                    uploadedBy: uid,
                    fileList,
                    drive: 'gdrive'
                })

                if (onUploadCallback) {
                    onUploadCallback(fileList, id)
                } else {
                    history.push({ pathname: paths.myVideos })
                }

                const filesObject = storage.reduce(
                    (a, file) => ({
                        ...a,
                        [file.id]: {
                            ...file,
                            source: 'gdrive',
                            type: 'video'
                        }
                    }),
                    {}
                )

                dispatch(openUploadProgressPopup(filesObject))
            })
            .finally(loadingState.setFalse)
    }, [
        loadingState,
        displayName,
        photoURL,
        email,
        storage,
        uid,
        history,
        dispatch,
        onUploadCallback
    ])

    const sortedFiles = useMemo(
        () => gDriveStorage.sort((a, b) => sortGdriveFiles(sortBy, sortDesc, a, b)),
        [gDriveStorage, sortBy, sortDesc]
    )

    const validVideoFiles = useMemo(
        () => gDriveStorage.filter(file => !isFolder(file) && file.size < maxUploadFileSizeInBytes),
        [gDriveStorage, maxUploadFileSizeInBytes]
    )
    const areAllVideosSelected = useMemo(
        () => selectedItems.areAllSelected(validVideoFiles),
        [selectedItems, validVideoFiles]
    )

    return (
        <UploaderModal
            trigger={labelComponent}
            title="Google Drive"
            subtitle={
                breadcrumbs.length > 0 ? (
                    <Button
                        variant="text"
                        onClick={loadPreviousFolder}
                        sx={{
                            color: 'common.black',
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        <ChevronLeftIcon /> <b>{breadcrumbs[breadcrumbs.length - 1].name}</b>
                    </Button>
                ) : (
                    <Typography variant="subtitle2">
                        <b>All Drives</b>
                    </Typography>
                )
            }
            selectedItems={storage.length}
            areAllItemsSelected={areAllVideosSelected}
            onSelectAll={() =>
                areAllVideosSelected
                    ? selectedItems.deleteMultiple(validVideoFiles)
                    : selectedItems.addMultiple(validVideoFiles)
            }
            onOpen={initGapi}
            onClose={resetGDriveModal}
            onSubmit={uploadFiles}
        >
            <FileTable
                isLoading={loadingState.isTrue}
                columns={gDriveColumns}
                sortBy={sortBy}
                sortDesc={sortDesc}
                onSortChange={changeSorting}
            >
                <>
                    {sortedFiles.map(gDriveItem => {
                        const isFolderType = isFolder(gDriveItem)
                        const isDriveType = isDrive(gDriveItem)
                        const isSizeValid = gDriveItem.size < maxUploadFileSizeInBytes

                        return (
                            <FileItem
                                key={gDriveItem.id}
                                isSelectable={isDriveType || isFolderType || isSizeValid}
                                showCheckbox={!isFolderType && isSizeValid}
                                showSizeWarning={!isFolderType && !isDriveType && !isSizeValid}
                                columns={gDriveColumns}
                                thumbnail={<ItemThumbnail file={gDriveItem} />}
                                file={gDriveItem}
                                isSelected={selectedItems.isSelected(gDriveItem)}
                                onClick={() => {
                                    if (
                                        gDriveItem.mimeType === 'application/vnd.google-apps.folder'
                                    ) {
                                        loadGDriveFolder(gDriveItem)
                                    } else if (gDriveItem.kind === 'drive#drive') {
                                        loadGDriveFolder(null, null, gDriveItem)
                                    } else {
                                        selectedItems.toggleItem(gDriveItem)
                                    }
                                }}
                            />
                        )
                    })}
                </>
            </FileTable>
        </UploaderModal>
    )
}
