import { useEffect, useRef, useState } from 'react'

import useImage from 'use-image'
import { useDispatch } from 'react-redux'

import type Konva from 'konva'
import { Circle, Group, Path } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { type AudioCircleType } from 'app/types'

import { setLayerTransform } from 'ducks'
import { round } from 'modules'

const svgData = {
    qgMicrophoneIcon: `M5.99922 13.5531L4.24922 9.75313L0.449219 8.00313L4.24922 6.25313L5.99922 2.45312L7.74922 6.25313L11.5492 8.00313L7.74922 9.75313L5.99922 13.5531ZM30.4992 7.55313L29.3992 5.10313L26.9992 4.00313L29.3992 2.90312L30.4992 0.453125L31.5992 2.90312L34.0492 4.00313L31.5992 5.10313L30.4992 7.55313ZM34.0492 15.5531L32.9492 13.1031L30.4992 12.0031L32.9492 10.9031L34.0492 8.45312L35.1492 10.9031L37.5492 12.0031L35.1492 13.1031L34.0492 15.5531ZM18.9992 23.0031C17.5992 23.0031 16.4159 22.5198 15.4492 21.5531C14.4826 20.5865 13.9992 19.4031 13.9992 18.0031V6.00313C13.9992 4.60313 14.4826 3.41979 15.4492 2.45312C16.4159 1.48646 17.5992 1.00313 18.9992 1.00313C20.3992 1.00313 21.5826 1.48646 22.5492 2.45312C23.5159 3.41979 23.9992 4.60313 23.9992 6.00313V18.0031C23.9992 19.4031 23.5159 20.5865 22.5492 21.5531C21.5826 22.5198 20.3992 23.0031 18.9992 23.0031ZM17.4992 37.5031V30.9031C14.1992 30.5365 11.4579 29.1278 9.27522 26.6771C7.09122 24.2278 5.99922 21.3365 5.99922 18.0031H8.99922C8.99922 20.7698 9.97455 23.1278 11.9252 25.0771C13.8746 27.0278 16.2326 28.0031 18.9992 28.0031C21.7659 28.0031 24.1246 27.0278 26.0752 25.0771C28.0246 23.1278 28.9992 20.7698 28.9992 18.0031H31.9992C31.9992 21.3365 30.9079 24.2278 28.7252 26.6771C26.5412 29.1278 23.7992 30.5365 20.4992 30.9031V37.5031H17.4992ZM18.9992 20.0031C19.5659 20.0031 20.0412 19.8111 20.4252 19.4271C20.8079 19.0445 20.9992 18.5698 20.9992 18.0031V6.00313C20.9992 5.43646 20.8079 4.96113 20.4252 4.57713C20.0412 4.19446 19.5659 4.00313 18.9992 4.00313C18.4326 4.00313 17.9579 4.19446 17.5752 4.57713C17.1912 4.96113 16.9992 5.43646 16.9992 6.00313V18.0031C16.9992 18.5698 17.1912 19.0445 17.5752 19.4271C17.9579 19.8111 18.4326 20.0031 18.9992 20.0031Z`
}

type Props = {
    layer: AudioCircleType
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const AudioCircleShape = ({
    onSelect,
    layer,
    onDragStart,
    onDragMove,
    onDragEnd
}: Props) => {
    const dispatch = useDispatch()

    const shapeRef = useRef<Konva.Group>(null)

    const [image] = useImage(layer.render.url || '', 'anonymous')

    const [audioProps, setAudioProps] = useState(layer)

    const patternScale = Math.max(
        (audioProps.radius * 2) / (image?.width || 1),
        (audioProps.radius * 2) / (image?.height || 1)
    )

    useEffect(() => {
        setAudioProps(layer)
    }, [layer])

    return (
        <Group
            ref={shapeRef}
            className="alignable"
            name="audioCircle"
            x={audioProps.x}
            y={audioProps.y}
            scaleX={audioProps.scaleX}
            scaleY={audioProps.scaleY}
            id={audioProps.id}
            rotation={audioProps.rotation}
            draggable
            onClick={e => onSelect(e.evt.shiftKey)}
            onDragStart={onDragStart}
            onDragMove={onDragMove}
            onDragEnd={onDragEnd}
            onTransform={({ target }) => {
                setAudioProps(prevState => ({
                    ...prevState,
                    x: target.x(),
                    y: target.y(),
                    scaleX: target.scaleX(),
                    scaleY: target.scaleY()
                }))
            }}
            onTransformEnd={({ target }) => {
                dispatch(
                    setLayerTransform({
                        layerId: layer.id,
                        x: round(target.x()),
                        y: round(target.y()),
                        scaleX: target.scaleX(),
                        scaleY: target.scaleY()
                    })
                )
            }}
        >
            <Circle
                x={0}
                y={0}
                radius={audioProps.radius}
                stroke={audioProps.strokeColor}
                strokeWidth={audioProps.strokeWidth}
                url={audioProps.render.url}
                fillPatternImage={image}
                fillPatternRepeat="no-repeat"
                fillPatternX={-audioProps.radius}
                fillPatternY={-audioProps.radius}
                fillPatternScaleX={patternScale}
                fillPatternScaleY={patternScale}
            />
            {audioProps.render.type === 'svgRender' && (
                <Group x={audioProps.render.logo.x} y={audioProps.render.logo.y}>
                    <Circle
                        x={audioProps.render.logo.fillRadius}
                        y={audioProps.render.logo.fillRadius}
                        radius={audioProps.render.logo.fillRadius}
                        fill={audioProps.render.logo.fill}
                    />
                    <Path
                        x={audioProps.render.logo.icon.x}
                        y={audioProps.render.logo.icon.y}
                        data={svgData[audioProps.render.logo.icon.dataKey]}
                        fill={audioProps.render.logo.icon.fill}
                    />
                </Group>
            )}
        </Group>
    )
}
