import { type ReactNode, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { LinearProgress } from '@mui/material'

import { CommonInviteDialog } from 'UI/Components'

import { ReactComponent as ViewerIcon } from 'assets/icons/viewer.svg'
import { ReactComponent as ContentManagerIcon } from 'assets/icons/content_manager.svg'
import { ReactComponent as AdministratorIcon } from 'assets/icons/administrator.svg'

import PeopleOutlineSharpIcon from '@mui/icons-material/PeopleOutlineSharp'

import {
    useDataMutation,
    useNotification,
    useAuth,
    useOrganization,
    ROLES_LIST,
    ROLE,
    useRoles
} from 'hooks'
import { logToAnalytics, option } from 'modules'

import { paths } from 'app/paths'

import { InviteOptionsDialog } from './InviteOptionsDialog'

type RolesProps = {
    name: string
    level: number
}

const ROLES_LIST_ICONS: { [key: number]: ReactNode } = {
    [ROLE.ADMIN]: <AdministratorIcon />,
    [ROLE.CONTENT_MANAGER]: <ContentManagerIcon />,
    [ROLE.MEMBER]: <PeopleOutlineSharpIcon />,
    [ROLE.VIEWER]: <ViewerIcon />
}

const mappedRoles = ROLES_LIST.map(({ name, level }: RolesProps) => ({
    ...option(level + '', name),
    icon: ROLES_LIST_ICONS[level] || null
}))

const getAvailableRoles = (role: number) => mappedRoles.filter(({ value }) => role >= Number(value))

export const getInviteDefaults = (orgName: string, isPrivateAccount?: boolean) => ({
    message: `Hey,\n${
        isPrivateAccount ? `I am` : `Our team at ${orgName} is`
    } using guidde to magically create video documentation using AI. You should try it out for yourself here 👇`,
    maxLength: 1500
})

type Props = {
    isOpen: boolean
    onClose: () => void
    activeWorkspace: {
        teamId: string
        teamName: string
    }
}

type ValuesType = {
    emails: Array<string>
    role: number
    message: string
}

type InviteUserProps = {
    emails: Array<string>
    permissions: number
    message: string
    inviteTo?: string
}

type InvitePayload = {
    additionalQueryParams: {
        teamId: string
        teamName: string
    }
} & InviteUserProps

export const InviteDialog = ({ isOpen, onClose, activeWorkspace }: Props) => {
    const history = useHistory()
    const [values, setValues] = useState<null | ValuesType>(null)

    const { showSuccessNotification } = useNotification()

    const { isContentManager, isAdmin, isViewer } = useRoles()
    const { roles, orgId, uid, isPrivateAccount } = useAuth()
    const { name: orgName } = useOrganization()

    const $invite = useDataMutation<InvitePayload, {}, Error>(
        '/auth/v1/invite/organization',
        'POST',
        {
            onSuccess: () => {
                showSuccessNotification('Invitation has been sent')
                onClose()

                history.push(paths.accountSettings, {
                    isInviteRedirect: true
                })
            }
        }
    )

    const $validateUserInvite = useDataMutation<
        { emails: Array<string> },
        {
            canInviteToMyOrg: Array<string>
        },
        Error
    >('/c/v1/validate-invite', 'POST')

    if (!orgName) return <LinearProgress />

    const inviteUser = ({ emails, message, permissions, inviteTo }: InviteUserProps) => {
        const inviteProps = {
            emails,
            permissions,
            message,
            ...(inviteTo && { inviteTo }),
            additionalQueryParams: activeWorkspace
        }

        return $invite.mutate(inviteProps).then(() => {
            logToAnalytics('teamDiscovery_invite_sent', {
                emails: inviteProps.emails,
                ...activeWorkspace,
                uid
            })

            logToAnalytics('send_invite', {
                numberOfInvitees: emails.length,
                emails,
                permissionsLevel: permissions,
                roleName: ROLES_LIST.find(it => it.level === permissions)?.name
            })
        })
    }

    const onFormSubmit = async ({ role, message, emails }: ValuesType) => {
        if (isAdmin) {
            // admins can see extended invite options but only if they are valid emails
            const res = await $validateUserInvite.mutate({ emails })

            if (res?.canInviteToMyOrg.length) {
                return setValues({ role, message, emails })
            }
        }

        return inviteUser({ emails, message, permissions: role })
    }

    const loading = $invite.isLoading || $validateUserInvite.isLoading

    const shareLink = `${location.origin}/join/${orgId}?origin=${uid}&teamId${activeWorkspace.teamId}&teamName=${activeWorkspace.teamName}&referral=invite&invited_email=`

    const inviteDefaults = getInviteDefaults(orgName, isPrivateAccount)

    const roleList = getAvailableRoles(roles.a)

    const initialRolesValue = isViewer
        ? ROLE.VIEWER
        : isContentManager
          ? ROLE.CONTENT_MANAGER
          : ROLE.MEMBER

    return (
        <>
            <CommonInviteDialog
                isOpen={isOpen}
                title="Invite new teammates"
                subtitle="Your teammates will receive an email with an invitation to join
                Guidde"
                maxLength={500}
                selectorLabel="Role"
                showShareLink={true}
                onClose={onClose}
                onInvite={onFormSubmit}
                shareLink={shareLink}
                isLoading={loading}
                initialRole={
                    roleList.find(it => Number(it.value) === Number(initialRolesValue)) ||
                    roleList[0]
                }
                initialMessage={inviteDefaults.message}
                roles={roleList}
            />
            {values && $validateUserInvite.data && (
                <InviteOptionsDialog
                    emails={$validateUserInvite.data.canInviteToMyOrg}
                    onClose={() => setValues(null)}
                    onConfirm={inviteTo => {
                        const { role, message, emails } = values

                        inviteUser({ permissions: role, message, emails, inviteTo })
                    }}
                />
            )}
        </>
    )
}
