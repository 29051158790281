import { memo } from 'react'

import { FormControlLabel, Typography, Radio } from '@mui/material'

import { type UserOption } from './types'

type Props = {
    value: number
    label: string
    option: UserOption
    disabled: boolean
    onChange: (value: number) => void
}

export const CheckBoxOption = memo(({ onChange, disabled, value, label, option }: Props) => (
    <FormControlLabel
        value={value}
        onChange={() => onChange(value)}
        disabled={disabled}
        control={<Radio color="primary" size="small" checked={value === option.spaceRole} />}
        label={
            <Typography
                style={{ fontSize: 12 }}
                color={option.spaceRole === value ? 'inherit' : 'textSecondary'}
            >
                {label}
            </Typography>
        }
    />
))
