import { useRef } from 'react'
import { useSelector } from 'react-redux'

import type Konva from 'konva'

import { Box } from '@mui/material'

import { QgProvider } from './QgProvider'
import { InfoPanel } from './InfoPanel'
import { Workspace, ImageStepUploader, TransitionPreview } from './Workspace'
import { ControlPanel, MultipleCTA, SingleCTA } from './ControlPanel'
import { StepsPreview } from './StepsPreview'
import { SmartPreview, TimelineBar } from './SmartPreview'
import { Subtitles } from './Subtitles'

import { type QuickGuiddeType } from 'app/types'

type Props = {
    quickGuidde: QuickGuiddeType
}

export const CanvasEditor = ({ quickGuidde }: Props) => {
    const stageRef = useRef<Konva.Stage>(null)

    const { sidePanelMode } = useSelector(state => state.qgMetadata)
    const { imageStepArea, activeStep, steps } = useSelector(state => state.qgEditor.present)
    const { singleCtaVisibility, multiCtaVisibility } = useSelector(state => state.qgCta)

    if (!steps[activeStep] || !steps[activeStep].windowDimensions) return null

    return (
        <Box display="flex" flexDirection="column" height="100%" id="canvas-editor">
            <QgProvider>
                <InfoPanel quickGuidde={quickGuidde} />
                <Box flexGrow={1} position="relative" bgcolor="rgb(229, 229, 229)">
                    <Workspace stageRef={stageRef} />

                    {sidePanelMode === 'captions' && <Subtitles />}
                    {sidePanelMode === 'motion' && <TransitionPreview stageRef={stageRef} />}

                    <SmartPreview />

                    {imageStepArea.visible && <ImageStepUploader />}

                    {singleCtaVisibility && <SingleCTA quickGuidde={quickGuidde} />}
                    {multiCtaVisibility && <MultipleCTA />}
                </Box>
                <TimelineBar />
                <ControlPanel stageRef={stageRef} quickGuidde={quickGuidde} />
                <StepsPreview quickGuidde={quickGuidde} />
            </QgProvider>
        </Box>
    )
}
