import { memo } from 'react'
import { useHistory } from 'react-router-dom'

import { styled, Paper, Box, Typography } from '@mui/material'

import { SpacedGroup } from 'UI/Components'

import { TimeLineItem } from './TimeLineItem'

import IconWatch from 'assets/images/watch.png'

import { type PlaybookApplicationType, type SearchPlaybookType } from 'app/types'

import { formatTime } from 'modules'

const PREFIX = 'MobileSearchItem'

const classes = {
    card: `${PREFIX}-card`,
    link: `${PREFIX}-link`,
    image: `${PREFIX}-image`,
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    footer: `${PREFIX}-footer`,
    iconWatch: `${PREFIX}-iconWatch`,
    name: `${PREFIX}-name`,
    application: `${PREFIX}-application`,
    appLogo: `${PREFIX}-appLogo`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.card}`]: {
        minHeight: '80px',
        width: '100%',
        boxShadow: 'none',
        overflow: 'hidden',
        borderRadius: '5px',
        border: '1px solid #f5f5f5'
    },

    [`& .${classes.link}`]: {
        textDecoration: 'none !important'
    },

    [`& .${classes.image}`]: {
        width: '100%',
        height: '100%',
        minHeight: '72px',
        objectFit: 'cover'
    },

    [`& .${classes.title}`]: {
        fontWeight: theme.typography.fontWeightBold,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },

    [`& .${classes.content}`]: {
        padding: theme.spacing(1, 2),
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

    [`& .${classes.footer}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },

    [`& .${classes.iconWatch}`]: {
        height: '12px'
    },

    [`& .${classes.name}`]: {
        fontSize: '10px'
    },

    [`& .${classes.application}`]: {
        position: 'absolute',
        width: '90px',
        height: '15px',
        padding: '2px 4px',
        overflow: 'hidden',
        bottom: 0,
        left: 0,
        background: theme.palette.common.white
    },

    [`& .${classes.appLogo}`]: {
        maxHeight: '15px',
        width: 'auto',
        maxWidth: '30%'
    }
}))

type Props = {
    applications: Array<PlaybookApplicationType>
    imgURLs: Array<string>
    playbook: SearchPlaybookType
    pageLink: {
        pathname: string
        state: {
            prevRoute: string
        }
    }
}

export const MobileSearchItem = memo(({ applications, imgURLs, pageLink, playbook }: Props) => {
    const history = useHistory()

    const { creator_name, duration, img, title } = playbook

    return (
        <StyledBox px={2} py={1}>
            <Paper className={classes.card} onClick={() => history.push(pageLink)}>
                <Box display="flex">
                    <Box width="30%" position="relative">
                        <img alt="placeholder" className={classes.image} src={img} />
                        {imgURLs.length > 0 && (
                            <SpacedGroup className={classes.application} spacing={0.5}>
                                {imgURLs.slice(0, 3).map((src, i) => (
                                    <img
                                        key={src + applications[i].applicationName + i}
                                        src={src || ''}
                                        className={classes.appLogo}
                                    />
                                ))}
                            </SpacedGroup>
                        )}
                    </Box>

                    <Box className={classes.content}>
                        <Typography variant="body2" className={classes.title}>
                            {title}
                        </Typography>
                        <Box className={classes.footer}>
                            <Typography className={classes.name}>{creator_name}</Typography>
                            {duration && (
                                <SpacedGroup spacing={0.5}>
                                    <img
                                        className={classes.iconWatch}
                                        src={IconWatch}
                                        alt="watch"
                                    />

                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        style={{
                                            fontSize: '10px'
                                        }}
                                    >
                                        {formatTime(duration)}
                                    </Typography>
                                </SpacedGroup>
                            )}
                        </Box>
                    </Box>
                </Box>
                <TimeLineItem sections={playbook?.sections} pageLink={pageLink} />
            </Paper>
        </StyledBox>
    )
})
