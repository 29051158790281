import { memo, useEffect, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import PaletteIcon from '@mui/icons-material/PaletteOutlined'

import { SpacedGroup } from 'UI/Components'

import { GeneralDialog } from '../GeneralDialog'

import { ColorsPalette, generateColor } from './ColorsPalette'
import { ColorInput } from './ColorInput'

import { colorCodeRegex } from 'modules'

type Props = {
    isOpen: boolean
    onClose: () => void
    customAngle: number
    initialColors?: Array<string>
    onSubmit: (colors: [string, string]) => void
    validateIfBackgroundAlreadyExists: (colors: [string, string]) => boolean
    title: string
    isLoading: boolean
    buttonLabel: string
}

export const BackgroundDialog = memo(
    ({
        customAngle,
        buttonLabel,
        initialColors,
        validateIfBackgroundAlreadyExists,
        onSubmit,
        ...props
    }: Props) => {
        const [color1, setColor1] = useState(generateColor(initialColors?.[0] || ''))
        const [color2, setColor2] = useState(generateColor(initialColors?.[1] || ''))

        const [actions, setActions] = useState([setColor1, setColor2])

        useEffect(() => {
            if (actions.length) return

            setActions([setColor1, setColor2])
        }, [actions])

        useEffect(() => {
            if (!initialColors) return

            setColor1(generateColor(initialColors[0]))
            setColor2(generateColor(initialColors[1]))
        }, [initialColors])

        useEffect(() => {
            if (props.isOpen) return

            setColor1('')
            setColor2('')
        }, [props.isOpen])

        // Check if the input color code matches the regex pattern
        const isValid1 = colorCodeRegex.test('#' + color1)
        const isValid2 = colorCodeRegex.test('#' + color2)

        const isValid =
            (isValid1 && isValid2) || (isValid1 && !color2.length) || (isValid2 && !color1.length)

        const generateResult = (color1: string, color2: string): [string, string] => {
            const result1 = '#' + color1
            const result2 = '#' + color2

            if (isValid1 && isValid2) {
                return [result1, result2]
            }

            if (isValid1 && !isValid2) {
                return [result1, result1]
            }

            if (!isValid1 && isValid2) {
                return [result2, result2]
            }

            return ['', '']
        }

        const result = generateResult(color1, color2)
        const isSingeColor = result[0] === result[1]

        const gradient = isSingeColor
            ? result[0]
            : `linear-gradient(${customAngle}deg, ${result[0]} 0%, ${result[1]} 100%)`

        const backgroundAlreadyExists = validateIfBackgroundAlreadyExists(result)

        return (
            <GeneralDialog
                {...props}
                maxWidth="sm"
                subtitle="Select one color for a solid background or two for a beautiful color gradient"
                actions={
                    <Box display="flex" width="100%" justifyContent="flex-end">
                        <Button
                            disabled={!isValid || backgroundAlreadyExists}
                            onClick={() => onSubmit(result)}
                            variant="contained"
                            size="large"
                        >
                            {buttonLabel}
                        </Button>
                    </Box>
                }
            >
                <SpacedGroup spacing={2} px={2}>
                    <Box
                        mr={1}
                        minWidth={50}
                        minHeight={50}
                        borderRadius="4px"
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        sx={{
                            background: isValid ? gradient : '#F5F5F5'
                        }}
                    >
                        {!isValid && (
                            <PaletteIcon
                                width={20}
                                height={20}
                                style={{ color: 'rgba(9, 12, 16, 0.6)' }}
                            />
                        )}
                    </Box>

                    <ColorInput
                        setColor={setColor1}
                        color={color1}
                        autoFocus={true}
                        placeholder="Select 1st color"
                        invalid={!isValid1 && color1.length > 0}
                    />
                    <ColorInput
                        setColor={setColor2}
                        color={color2}
                        placeholder="Select 2nd color"
                        invalid={!isValid2 && color2.length > 0}
                    />
                </SpacedGroup>
                <Box height="8px" marginLeft="82px">
                    {backgroundAlreadyExists && (
                        <Typography color="error" variant="caption">
                            Background color already exists
                        </Typography>
                    )}
                </Box>

                <ColorsPalette
                    setColor={newColor => {
                        if (!color1) {
                            setColor1(newColor)
                            return
                        }
                        if (!color2) {
                            setColor2(newColor)
                            return
                        }

                        const action = actions?.[0]
                        if (action) {
                            action(newColor)
                            setActions(prev => prev.filter((_, i) => i !== 0))
                        }
                    }}
                />
            </GeneralDialog>
        )
    }
)
