import { Client } from '@microsoft/microsoft-graph-client'

/**
 * This module allows communication with microsoft graph api
 *
 * By: Nizar Jubran
 * Date: 15.06.2021
 */

const msGraph = () => {
    let client

    const init = msAuthInstance => {
        if (msAuthInstance) {
            client = Client.init({
                authProvider: done => {
                    done(null, msAuthInstance.getTokenData()?.accessToken)
                }
            })
        } else console.error('Invalid microsoft authentication instance')

        return returnedObject
    }

    const selectFolderById = id => {
        const getChildren = () => apiCall(`/me/drive/items/${id}/children`)
        const getMetadata = () => apiCall(`/me/drive/items/${id}`)

        return {
            getChildren,
            getMetadata
        }
    }

    const getUserInfo = () => apiCall('/me')

    const selectFolderByPath = path => {
        const getChildren = () => apiCall(rootParser(`/me/drive/root:/${path}:/children`))

        const getMetadata = () => apiCall(rootParser(`/me/drive/root:/${path}`))
        const rootParser = originalPath =>
            originalPath === '' || !originalPath
                ? originalPath
                : originalPath.replace('/:', '/').replace(':/', '/').replace('//', '/')

        return {
            getChildren,
            getMetadata
        }
    }

    const apiCall = async path => {
        if (!client) {
            console.error('Error performing api call to microsoft graph, client is not defined')
            return null
        }

        try {
            const response = await client.api(path).get()
            return response
        } catch (err) {
            console.error('error retrieving graph info', err)
            return null
        }
    }

    const isFolder = item => (item.folder ? true : false)

    const returnedObject = {
        init,
        selectFolderByPath,
        selectFolderById,
        isFolder,
        getUserInfo
    }

    return returnedObject
}

export default msGraph()
