import {
    type MutableRefObject,
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import type Konva from 'konva'

import { Stack, Tooltip } from '@mui/material'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import { QGContext } from 'UI/Routes/quick-guidde/CanvasEditor'
import { LABEL_SPACING } from 'UI/Routes/quick-guidde/CanvasEditor/Workspace/Shapes/TextShape'

import { FontSize } from './FontSize'
import { FontColors } from './FontColors'
import { FontStyles } from './FontStyles'
import { CompanyLogo } from './CompanyLogo'
import { FontAlignment } from './FontAlignment'
import { FigureColor } from './FigureColor'
import { ArrowDirection } from './ArrowDirection'
import { FontFamily } from './FontFamily'
import { Spotlight } from './Spotlight'
import { CropImage } from './CropImage'
import { ElementsAlignment } from './ElementsAlignment'

import { duplicateSelectedLayer, removeSelectedLayer } from 'ducks'
import { updateUserPreferences } from 'ducks/reducers'

import { roundToHundredth } from 'modules'

import { type QuickGuiddeLayerType, Shape } from 'app/types'
import { paths } from 'app/paths'

const PADDING = 12

// Calculate coordinates of the center of the shape
export const getCenter = (shape: {
    x: number
    y: number
    width: number
    height: number
    rotation?: number
}) => {
    const rotation = ((shape.rotation || 0) / 180) * Math.PI

    return {
        x: roundToHundredth(
            shape.x +
                (shape.width / 2) * Math.cos(rotation) +
                (shape.height / 2) * Math.sin(-rotation)
        ),
        y: roundToHundredth(
            shape.y +
                (shape.height / 2) * Math.cos(rotation) +
                (shape.width / 2) * Math.sin(rotation)
        )
    }
}

// Get new coordinates after specified rotation angle
const rotateAroundPoint = (
    shape: { x: number; y: number },
    angleDegrees: number,
    center: { x: number; y: number } // center of the shape
) => {
    let angleRadians = (angleDegrees * Math.PI) / 180 // sin + cos require radians

    const x =
        center.x +
        (shape.x - center.x) * Math.cos(angleRadians) -
        (shape.y - center.y) * Math.sin(angleRadians)
    const y =
        center.y +
        (shape.x - center.x) * Math.sin(angleRadians) +
        (shape.y - center.y) * Math.cos(angleRadians)

    return { x: Math.round(x), y: Math.round(y) }
}

const findTopLeftLayerByCoordinates = (layers: Array<QuickGuiddeLayerType>) => {
    return layers.reduce((acc, current) => {
        // for circle like shapes, we need to adjust the position based on radius
        const radius = ('radius' in current ? current.radius : 0) * current.scaleX
        const adjustedX = current.x - radius
        const adjustedY = current.y - radius

        if (adjustedX < acc.x) acc.x = adjustedX
        if (adjustedY < acc.y) acc.y = adjustedY

        return acc
    }, layers[0])
}

const iconStyle = { color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }
type Props = {
    stageRef: MutableRefObject<Konva.Stage | null>
}

export const FloatingActions = ({ stageRef }: Props) => {
    const actionsRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    const isBrandKitPage = useRouteMatch([paths.brandKitDetails, paths.brandKit])?.isExact

    const [actionsSize, setActionsSize] = useState({ width: 0, height: 0 })
    const [topLeftLayer, setTopLeftLayer] = useState<QuickGuiddeLayerType | null>(null)

    const { scale } = useContext(QGContext)

    const {
        present: { activeStep, steps, selectedLayersIds }
    } = useSelector(state => state.qgEditor)

    const { layers, audioNote, kind, windowDimensions } = steps[activeStep] || {}

    const selectedLayers = useMemo(
        () => layers.filter(layer => selectedLayersIds.includes(layer.id)),
        [layers, selectedLayersIds]
    )
    const isSingleSelection = selectedLayersIds.length === 1

    const findTopLeftLayer = useCallback(() => {
        // Returns layers with coordinates when no rotation
        const layersPositionWithoutRotation = selectedLayers.flatMap(selectedLayer => {
            const layerClientRect = stageRef.current
                ?.find(`#${selectedLayer.id}`)?.[0]
                ?.getClientRect?.({ skipTransform: true, skipStroke: true, skipShadow: true })
            if (!layerClientRect) return []

            // Return coordinates of the shape without rotation for keeping the same position of floating actions
            const { x, y } = rotateAroundPoint(
                selectedLayer,
                (selectedLayer.rotation || 0) * -1,
                getCenter({
                    rotation: selectedLayer.rotation,
                    x: selectedLayer.x,
                    y: selectedLayer.y,
                    width: layerClientRect.width * selectedLayer.scaleX,
                    height: layerClientRect.height * selectedLayer.scaleY
                })
            )
            return { ...selectedLayer, x, y }
        })

        // Find the top left corner of the bounding box with selected layers (included circle like shapes)
        return findTopLeftLayerByCoordinates(layersPositionWithoutRotation)
    }, [selectedLayers, stageRef])

    useEffect(() => {
        const upperLayer = findTopLeftLayer()
        setTopLeftLayer(upperLayer)
    }, [findTopLeftLayer])

    useLayoutEffect(() => {
        const { width = 0, height = 0 } = actionsRef.current?.getBoundingClientRect() || {}
        setActionsSize({ width, height })
    }, [selectedLayersIds, stageRef, topLeftLayer])

    if (!selectedLayers.length || !topLeftLayer) return null

    const brandKitTemplateTitle =
        isBrandKitPage &&
        isSingleSelection &&
        selectedLayers[0].type === Shape.Text &&
        selectedLayers[0].isTitle

    // Position of the canvas on the screen
    const { top: canvasTop = 0, left: canvasLeft = 0 } =
        stageRef.current?.attrs.container?.getBoundingClientRect() || {}

    // Some layers require extra handling
    const getPositionAdjustment = (axis: 'x' | 'y') => {
        const side = axis === 'x' ? 'width' : 'height'
        const scaleAxis = axis === 'x' ? 'scaleX' : 'scaleY'

        const defaultAdjustment = -(actionsSize[side] + PADDING)
        if (!isSingleSelection) return defaultAdjustment

        switch (topLeftLayer.type) {
            case Shape.Overlay:
                return 0
            case Shape.Text:
                if (!brandKitTemplateTitle) return defaultAdjustment
                return -(
                    actionsSize[side] +
                    PADDING +
                    (LABEL_SPACING[axis] / 2) * scale * topLeftLayer[scaleAxis]
                )
            default:
                return defaultAdjustment
        }
    }

    const left = canvasLeft + getPositionAdjustment('x') + topLeftLayer.x * scale
    const topDelta = left < 0 ? 50 : 0
    const top = canvasTop + getPositionAdjustment('y') + topLeftLayer.y * scale - topDelta

    return (
        <Stack
            direction="row"
            alignItems="center"
            ref={actionsRef}
            spacing={0.3}
            height={30}
            zIndex={1201}
            borderRadius="2px"
            border="1px solid gainsboro"
            bgcolor="white"
            pl={0.5}
            style={{
                top: top,
                left: Math.max(left, 10),
                position: 'absolute',
                boxShadow: '0px 1px 4px 2px rgba(0, 0, 0, 0.23)'
            }}
        >
            {selectedLayers.map(layer => {
                if (!isSingleSelection) return null
                const hasOverlay = layers.find(layer => layer.type === Shape.Overlay)

                switch (layer.type) {
                    case Shape.Arrow:
                    case Shape.CircleWithText:
                    case Shape.Rectangle:
                    case Shape.Circle:
                        return (
                            <Fragment key={layer.id}>
                                <FigureColor currentLayer={layer} />
                                {layer.type === Shape.Arrow && (
                                    <ArrowDirection dataKey={layer.dataKey} />
                                )}
                                {(layer.type === Shape.Rectangle || layer.type === Shape.Circle) &&
                                    hasOverlay && <Spotlight hasSpotlight={layer.isSpotlight} />}
                            </Fragment>
                        )

                    case Shape.Text:
                        return (
                            <Fragment key={layer.id}>
                                <FontFamily fontFamilyTitle={layer.fontFamily} />
                                <FontSize
                                    size={layer.fontSize}
                                    scale={Math.min(layer.scaleX, layer.scaleY)}
                                />
                                <FontColors
                                    color={layer.color}
                                    backgroundColor={layer.backgroundColor}
                                />
                                <FontStyles currentLayer={layer} />
                                <FontAlignment currentLayer={layer} />
                            </Fragment>
                        )

                    case Shape.Image:
                        return (
                            <Fragment key={layer.id}>
                                {layer.isBrandkitLogo && (
                                    <CompanyLogo currentLayer={layer} scale={scale} />
                                )}

                                {!layer.isBackground && !layer.isWatermark && (
                                    <CropImage
                                        currentLayer={layer}
                                        windowDimensions={windowDimensions}
                                    />
                                )}
                            </Fragment>
                        )
                    default:
                        return null
                }
            })}

            {!isSingleSelection && <ElementsAlignment />}

            {selectedLayers.every(
                layer =>
                    ![Shape.Overlay, Shape.AudioCircle].includes(layer.type) &&
                    !('isBackground' in layer && layer.isBackground)
            ) && (
                <Tooltip title="Duplicate element">
                    <FileCopyOutlinedIcon
                        fontSize="small"
                        onClick={() => dispatch(duplicateSelectedLayer())}
                        style={iconStyle}
                    />
                </Tooltip>
            )}

            {!(brandKitTemplateTitle && kind === 'cover') && (
                <Tooltip title="Delete element">
                    <DeleteOutlineIcon
                        data-test="editor-delete-icon"
                        onClick={() => {
                            dispatch(removeSelectedLayer())
                            // If we remove the audio layer, we need to change user preference to avoid adding it by default on audioNote creation
                            if (selectedLayers.some(({ type }) => type === Shape.AudioCircle)) {
                                switch (audioNote?.type) {
                                    case 'speechToText':
                                        return updateUserPreferences('s2tAvatar', false)
                                    case 'textToSpeech':
                                        return updateUserPreferences('t2sAvatar', false)
                                }
                            }
                        }}
                        style={iconStyle}
                    />
                </Tooltip>
            )}
        </Stack>
    )
}
