import { useState, memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
    styled,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Box,
    LinearProgress,
    CircularProgress
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { PaymentErrorDialog, CheckoutForm, PaymentInProcessDialog } from 'UI/Components'

import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'
import { Elements } from '@stripe/react-stripe-js'

import { logToAnalytics } from 'modules'

import {
    useNotification,
    usePayment,
    useServiceUsage,
    useBoolean,
    useStigg,
    MAX_PLAN_UNITS
} from 'hooks'
import { closePlanDialogs } from 'ducks'
import { DowngradeConfirmationDialog } from './UpgradePlanDialog/DowngradeConfirmationDialog'

const CloseIconButton = styled(IconButton)(({ theme }) => ({
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    padding: theme.spacing(1.5),
    margin: theme.spacing(0.5)
}))

const InfoRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px'
})

const TitleTypography = styled(Typography)({
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 600
})

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(0, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}))

const Counter = styled(Box)(({ theme }) => ({
    width: '380px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid #78909c',
    paddingBottom: theme.spacing(1)
}))

const Switcher = styled(Box)({
    borderRadius: '4px',
    display: 'flex',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '1px solid #e0e0e0',
    width: 'min-content'
})

type Props = {
    onClose: () => void
    onChangePlan: () => void
}

const contactSalesMessage = 'Please, contact sales to make this change'

export const UpdatePlanDialog = memo(({ onClose, onChangePlan }: Props) => {
    const { showWarningNotification, showSuccessNotification } = useNotification()
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const paymentError = useBoolean()
    const downgradeDialog = useBoolean()
    const paymentInProgress = useBoolean()

    const {
        usedUsersSeats,
        userSeatsQuota,
        billingPeriod,
        activePlanId,
        billingPeriod: activePlanPeriod,
        isTrialPlan,
        isLoading: isUsageLoading
    } = useServiceUsage()
    const { bucket, updateBucket, stiggLoading, revalidateStiggPaywall, plans } = useStigg()
    const { period, totalLicenses, planId, pricePerUnitMonthly } = bucket

    const {
        updatePlan,
        isLoading: isPaymentLoading,
        clientSecret,
        stripePromise,
        submitStripeForm,
        fetchUsersToDowngrade,
        usersToDowngrade
    } = usePayment({ totalLicenses })

    useEffect(() => {
        revalidateStiggPaywall()
    }, [revalidateStiggPaywall])

    const isLoading = isPaymentLoading || stiggLoading || isUsageLoading

    if (!plans || isLoading) return null

    const { pricePoints, metadata } = plans?.[activePlanId]
    const incrementSeatsBy = metadata.incrementSeatsBy || 1
    const minUnitQuantity = metadata.minUnitQuantity?.[period] || 1
    const maxUnitQuantity = metadata.maxUnitQuantity?.[period] || MAX_PLAN_UNITS
    const fixedUserSeats = userSeatsQuota || Math.max(usedUsersSeats, totalLicenses)
    const disableDowngrade = billingPeriod === BillingPeriod.Annually
    const availableSeats = fixedUserSeats - usedUsersSeats
    const additionalSeats = totalLicenses - fixedUserSeats
    const symbol = additionalSeats > 0 ? '+' : ''
    const disableUpdate = isLoading || (additionalSeats === 0 && period === billingPeriod)
    const priceDifference = pricePerUnitMonthly * (totalLicenses - fixedUserSeats)
    const priceText =
        additionalSeats >= 0
            ? `additional $${priceDifference}/month`
            : `$${priceDifference}/month less`

    const handleUpdate = () => {
        paymentInProgress.setTrue()
        updatePlan({
            planId,
            unitQuantity: totalLicenses,
            billingPeriod: period
        })
            .then(() => {
                logToAnalytics('change_pro_plan_configuration')
                onClose()
            })
            .catch(paymentError.setTrue)
            .finally(paymentInProgress.setFalse)
    }

    const confirmPlanUpdate = () => {
        if (!clientSecret) {
            handleUpdate()
            return
        }

        setStep(1)
    }

    const handleContinue = async () => {
        if (bucket.totalLicenses < Math.max(userSeatsQuota, usedUsersSeats) || !userSeatsQuota) {
            const users = await fetchUsersToDowngrade?.()

            if (users?.length) return downgradeDialog.setTrue()
        }

        return confirmPlanUpdate()
    }

    return (
        <>
            {downgradeDialog.isTrue && !!usersToDowngrade?.length && (
                <DowngradeConfirmationDialog
                    onConfirm={confirmPlanUpdate}
                    onClose={downgradeDialog.setFalse}
                    newSeatsAmount={totalLicenses}
                    usedUsersSeats={usedUsersSeats}
                    usersList={usersToDowngrade.map(({ email }) => email)}
                />
            )}
            <PaymentErrorDialog
                onClose={paymentError.setFalse}
                isOpen={paymentError.isTrue}
                onClick={paymentError.setFalse}
            />
            {paymentInProgress.isTrue && <PaymentInProcessDialog />}
            <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
                <CloseIconButton onClick={onClose}>
                    <CloseIcon />
                </CloseIconButton>

                {isLoading && <LinearProgress />}

                <DialogTitle style={{ position: 'relative', minHeight: '56px' }}>
                    <TitleTypography
                        data-test="updatePlanDialog-title-text"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate3d(-50%, -50%, 0)'
                        }}
                    >
                        Update plan
                    </TitleTypography>
                </DialogTitle>
                <TabPanel value={step} index={0}>
                    <StyledDialogContent>
                        <Box
                            sx={theme => ({
                                background: '#f5f5f5',
                                padding: theme.spacing(3, 4),
                                width: '100%',
                                borderRadius: '4px',
                                marginTop: theme.spacing(2)
                            })}
                        >
                            <InfoRow>
                                <Typography
                                    data-test="updatePlanDialog-current-plan-text"
                                    variant="caption"
                                >
                                    Your current plan:
                                </Typography>
                                <Typography
                                    data-test="updatePlanDialog-current-plan"
                                    variant="caption"
                                    sx={{ fontWeight: 600 }}
                                >
                                    {userSeatsQuota} Users
                                </Typography>
                            </InfoRow>
                            <InfoRow>
                                <Typography
                                    data-test="updatePlanDialog-active-users-text"
                                    variant="caption"
                                >
                                    Active users:
                                </Typography>
                                <Typography
                                    data-test="updatePlanDialog-active-users"
                                    variant="caption"
                                    sx={{ fontWeight: 600 }}
                                >
                                    {usedUsersSeats}
                                </Typography>
                            </InfoRow>
                            <InfoRow>
                                <Typography
                                    data-test="updatePlanDialog-seats-available-text"
                                    variant="caption"
                                >
                                    Seats available:
                                </Typography>
                                <Typography
                                    data-test="updatePlanDialog-seats-available"
                                    variant="caption"
                                    sx={{ fontWeight: 600 }}
                                >
                                    {availableSeats > 0 ? availableSeats : '-'}
                                </Typography>
                            </InfoRow>
                        </Box>

                        {stiggLoading ? (
                            <Box my={3}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <Switcher mt={3}>
                                    {pricePoints.find(
                                        ({ billingPeriod }) =>
                                            billingPeriod === BillingPeriod.Monthly
                                    ) && (
                                        <Box
                                            data-test="updatePlanDialog-monthly-billing-button"
                                            onClick={() => {
                                                if (disableDowngrade) {
                                                    showWarningNotification(contactSalesMessage)
                                                    return
                                                }
                                                logToAnalytics('select_monthly_plan_type')
                                                updateBucket({ period: BillingPeriod.Monthly })
                                            }}
                                            sx={{
                                                padding: '8px 16px',
                                                ...(period === BillingPeriod.Monthly && {
                                                    color: '#fff',
                                                    background: '#26c6da'
                                                })
                                            }}
                                        >
                                            Monthly
                                        </Box>
                                    )}

                                    <Box
                                        data-test="updatePlanDialog-annual-billing-button"
                                        onClick={() => {
                                            logToAnalytics('select_annual_plan_type')
                                            updateBucket({ period: BillingPeriod.Annually })
                                        }}
                                        sx={{
                                            padding: '8px 16px',
                                            ...(period === BillingPeriod.Annually && {
                                                color: '#fff',
                                                background: '#26c6da'
                                            })
                                        }}
                                    >
                                        Annually
                                    </Box>
                                </Switcher>
                                <Counter my={2}>
                                    <IconButton
                                        onClick={() => {
                                            if (
                                                (activePlanPeriod === BillingPeriod.Annually &&
                                                    totalLicenses - incrementSeatsBy <
                                                        userSeatsQuota &&
                                                    !isTrialPlan) ||
                                                additionalSeats === 0
                                            ) {
                                                return showWarningNotification(
                                                    'Please, contact sales to make this change'
                                                )
                                            }

                                            logToAnalytics('remove_seat_in_plan_configuration', {
                                                seats: totalLicenses
                                            })
                                            updateBucket({
                                                totalLicenses: totalLicenses - incrementSeatsBy
                                            })
                                        }}
                                        disabled={totalLicenses <= minUnitQuantity}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    <Typography
                                        data-test="updatePlanDialog-total-seats"
                                        variant="h4"
                                        style={{
                                            fontWeight: 600
                                        }}
                                    >
                                        {totalLicenses}
                                    </Typography>
                                    <IconButton
                                        onClick={() => {
                                            logToAnalytics('remove_seat_in_plan_configuration', {
                                                seats: totalLicenses + incrementSeatsBy
                                            })
                                            updateBucket({
                                                totalLicenses: totalLicenses + incrementSeatsBy
                                            })
                                        }}
                                        disabled={totalLicenses >= maxUnitQuantity}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Counter>
                                <TitleTypography>
                                    {symbol}
                                    {additionalSeats} seats ({priceText})
                                </TitleTypography>
                                <Typography variant="caption" sx={{ color: '#78909c' }}>
                                    {totalLicenses < usedUsersSeats && (
                                        <>
                                            You are about to purchase {totalLicenses} seats, while
                                            your account has {usedUsersSeats} users
                                        </>
                                    )}
                                </Typography>
                                <Box mt={3} />
                            </>
                        )}
                    </StyledDialogContent>

                    <DialogActions sx={{ padding: '24px', justifyContent: 'center' }}>
                        <Button size="large" color="inherit" variant="contained" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            data-test="updatePlanDialog-change-plan-button"
                            size="large"
                            color="inherit"
                            variant="contained"
                            onClick={() => {
                                logToAnalytics('open_upgrade_plan_dialog', {
                                    source: 'update_plan_dialog'
                                })
                                onClose()
                                onChangePlan()
                            }}
                        >
                            Change Plan
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            onClick={handleContinue}
                            disabled={disableUpdate}
                        >
                            Update now
                        </Button>
                    </DialogActions>
                </TabPanel>
                <TabPanel value={step} index={1}>
                    <StyledDialogContent>
                        <Box mb={4}>
                            {clientSecret && (
                                <Elements stripe={stripePromise} options={{ clientSecret }}>
                                    <CheckoutForm
                                        submitStripeForm={submitStripeForm}
                                        isLoading={isPaymentLoading}
                                        onSuccess={() => {
                                            dispatch(closePlanDialogs())
                                            showSuccessNotification(
                                                'We’ve received your request. Your plan will be changed shortly.'
                                            )
                                        }}
                                        onError={paymentError.setTrue}
                                    />
                                </Elements>
                            )}
                        </Box>
                    </StyledDialogContent>
                </TabPanel>
            </Dialog>
        </>
    )
})

type TabProps = {
    children: JSX.Element | JSX.Element[]
    value: number
    index: number
}

const TabPanel = ({ children, value, index }: TabProps) => {
    return <Box hidden={value !== index}>{children}</Box>
}
