import { type ReactNode, createContext, useState } from 'react'

import { isQG, SERVICE_STATUS } from 'modules'
import { useBoolean, useQuery } from 'hooks'

import { type AnyPlaybookType, type PlaybookType } from 'app/types'
import { type SWRConfiguration } from 'swr'

type StatusType = {
    processFinished: boolean
    services: Record<string, (typeof SERVICE_STATUS)[keyof typeof SERVICE_STATUS]>
    isLoading: boolean
    editable: boolean
}

const contextDefaultValues = {
    processFinished: false,
    services: {} as StatusType['services'],
    isLoading: true,
    editable: false,
    isGifGenerationFinished: false,
    isUploadSuccess: false,
    converted: false,
    onConvert: () => {},
    checkVideoStatus: () => Promise.resolve({} as StatusType),
    revalidatePlaybook: () => Promise.resolve({} as PlaybookType)
}

export const VideoStatusContext = createContext(contextDefaultValues)

type Props = {
    children: ReactNode
    playbook: AnyPlaybookType
    validateGifProgress?: boolean
    queryParams?: SWRConfiguration
    revalidatePlaybook?: () => Promise<any>
}

export const refreshIntervals = {
    qg: 1000,
    playbook: 5000
}

export const VideoStatusProvider = ({
    children,
    playbook,
    validateGifProgress,
    queryParams = {},
    revalidatePlaybook = () => Promise.resolve({} as PlaybookType)
}: Props) => {
    const { uploadedByUid, id, url } = playbook

    const converted = useBoolean()

    const [refreshInterval, setRefreshInterval] = useState(
        isQG(playbook) ? refreshIntervals.qg : refreshIntervals.playbook
    )

    const { data, error, mutate } = useQuery<StatusType>(
        `/c/v1/getVideoStatus?uid=${uploadedByUid}&id=${id}`,
        { method: 'GET' },
        {
            onSuccess: res => {
                if (validateGifProgress) {
                    if (res.services.gif_generation === SERVICE_STATUS.DONE) {
                        revalidatePlaybook?.()
                        setRefreshInterval(0)
                    }

                    return
                }
                // trigger extra re-fetch once video is ready to be shown
                if (res.services?.qg_video === SERVICE_STATUS.DONE && !url) {
                    revalidatePlaybook?.()
                }
                // General re-fetch and stop refresh interval once processing is done
                if (res.processFinished && res.editable) {
                    setRefreshInterval(0)
                    revalidatePlaybook?.()
                }
            },
            onError: e => {
                console.error(e)
                setRefreshInterval(0)
            },
            refreshInterval,
            dedupingInterval: refreshInterval,
            revalidateOnMount: true,
            // revalidate only on interval
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenOffline: false,
            refreshWhenHidden: false,
            ...queryParams
        }
    )

    return (
        <VideoStatusContext.Provider
            value={{
                isLoading: !data && !error,
                services: data?.services || {},
                isUploadSuccess: data?.services?.upload_process === SERVICE_STATUS.DONE,
                processFinished: data?.processFinished ?? false,
                isGifGenerationFinished:
                    data?.services && 'gif_generation' in data.services
                        ? data.services.gif_generation === SERVICE_STATUS.DONE
                        : true,
                editable: data?.editable ?? false,
                converted: converted.isTrue,
                onConvert: converted.setTrue,
                revalidatePlaybook,
                checkVideoStatus: () => {
                    // Trigger re-fetch immediately and set refresh interval to 1s
                    return mutate().then(res => {
                        setRefreshInterval(refreshIntervals.playbook)
                        return res
                    }) as Promise<StatusType>
                }
            }}
        >
            {children}
        </VideoStatusContext.Provider>
    )
}
