import { Document, Page, Image } from '@react-pdf/renderer'

import { filterIntoOutroSteps } from 'UI/Components'

import { type QuickGuiddeType } from 'app/types'

// link to page all sizes:
// https://github.com/diegomura/react-pdf/blob/master/packages/layout/src/page/getSize.js
const A4size = [595.28, 841.89]

const maxWidth = A4size[1]
const maxHeight = A4size[0]

type Props = {
    onRender: () => void
    playbook: QuickGuiddeType
}

export const PdfSlidesDocument = ({ playbook, onRender }: Props) => {
    const filteredSteps = filterIntoOutroSteps(playbook.steps)

    const { innerWidth, innerHeight } = filteredSteps[0].windowDimensions

    let widthScale = maxWidth / innerWidth
    let heightScale = maxHeight / innerHeight

    let scale = Math.max(widthScale, heightScale)

    const width = innerWidth * scale
    const height = innerHeight * scale

    return (
        <Document onRender={onRender}>
            {filteredSteps.map(step => (
                <Page
                    size={{ width, height }}
                    key={step.id}
                    style={{
                        paddingVertical: 0,
                        paddingHorizontal: 0,
                        margin: 0
                    }}
                >
                    <Image
                        src={step.drawnScreenshot}
                        style={{
                            height: '100%',
                            width: '100%'
                        }}
                    />
                </Page>
            ))}
        </Document>
    )
}
