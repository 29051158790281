import { styled, Typography, Box, Button, Container } from '@mui/material'

import GuiddeLogoRed from 'assets/icons/guidde_red.svg'
import NotFoundLogo from 'assets/icons/404.svg'

const PREFIX = 'FallbackMessage'

const classes = {
    container: `${PREFIX}-container`,
    mainLogo: `${PREFIX}-mainLogo`,
    message: `${PREFIX}-message`,
    youGotMe: `${PREFIX}-youGotMe`,
    button: `${PREFIX}-button`,
    oops: `${PREFIX}-oops`
}

const StyledContainer = styled(Container)(() => ({
    [`&.${classes.container}`]: {
        color: '#212121',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '70vw',
        height: 'calc(100vh - 48px)'
    },
    [`& .${classes.mainLogo}`]: {
        padding: '50px',
        width: '100%',
        height: '100%'
    },
    [`& .${classes.message}`]: {
        position: 'absolute',
        top: '19%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'inherit'
    },
    [`& .${classes.youGotMe}`]: {
        position: 'absolute',
        top: '40px',
        right: '20px',
        color: 'inherit'
    },
    [`& .${classes.button}`]: {
        position: 'absolute',
        bottom: '70px',
        right: '11%'
    },
    [`& .${classes.oops}`]: {
        position: 'absolute',
        bottom: 0,
        left: 0
    }
}))

type Props = {
    onClick: () => void
    title: string
    subtitle: string
    phrase?: string
}

export const FallbackMessage = ({ onClick, title, subtitle, phrase }: Props) => {
    return (
        <StyledContainer className={classes.container}>
            <Box className={classes.message}>
                <img src={GuiddeLogoRed} alt="red logo" />
                <Typography variant="h3">{title}</Typography>
                <Typography variant="h4">{subtitle}</Typography>
            </Box>
            <Typography className={classes.youGotMe} variant="h6">
                {phrase}
            </Typography>

            <Button
                size="large"
                color="inherit"
                style={{
                    position: 'absolute',
                    bottom: '85px',
                    right: '12%'
                }}
                onClick={onClick}
            >
                Take me Home
            </Button>

            <Box className={classes.oops}>
                <img src={GuiddeLogoRed} style={{ width: '80px' }} alt="red logo" />
            </Box>
            <img className={classes.mainLogo} src={NotFoundLogo} alt="400 logo" />
        </StyledContainer>
    )
}
