import { host } from 'modules'
import { HTTP_CODES } from 'app/http-codes'

import { useBoolean } from './use-boolean'
import { useAuth } from './use-auth'
import { useNotification } from './use-notification'

type Props = {
    url: string
    fileName: string
    successMessage: string
    body?: any
    fileNameHeader?: string
}

export const useDownloadFile = ({ url, fileName, body, fileNameHeader, successMessage }: Props) => {
    const { token } = useAuth()
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const isFileDownloading = useBoolean()

    const downloadFile = () => {
        isFileDownloading.setTrue()
        // I'm using fetch instead of our `useDataMutation` hook because
        // `useDataMutation` doesn't support content type other than json in response
        fetch(`${host}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },
            body: body ? JSON.stringify(body) : undefined
        })
            .then(async res => {
                if (res.status !== HTTP_CODES.OK) {
                    console.error('[FILE DOWNLOAD ERROR]:', res)
                    showErrorNotification(
                        'Apologies, something went wrong on our end; please try again later.'
                    )
                    return
                }

                const blob = await res.blob()

                if (!blob) return

                const fileURL = URL.createObjectURL(blob)
                const fileLink = document.createElement('a')

                fileLink.href = fileURL

                fileLink.download = fileNameHeader
                    ? res.headers.get(fileNameHeader) ?? fileName
                    : fileName

                fileLink.click()
                showSuccessNotification(successMessage)
            })

            .catch(e => {
                console.error('[FILE DOWNLOAD ERROR]:', e)
                showErrorNotification(
                    'Apologies, something went wrong on our end; please try again later.'
                )
            })
            .finally(isFileDownloading.setFalse)
    }

    return {
        downloadFile,
        isFileDownloading: isFileDownloading.isTrue
    }
}
