import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, Checkbox, Divider, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { TransitionsList } from './TransitionsList'

import { setSidePanelMode } from 'ducks'

import { useBoolean } from 'hooks'
import { logToAnalytics } from 'modules'

export const MotionPanel = () => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const motionToAll = useBoolean()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { transition } = steps[activeStep] || {}

    return (
        <>
            <Box
                p={2}
                height={62}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid rgba(9, 12, 16, 0.08)"
                borderLeft="none"
            >
                <Box style={{ fontSize: 20, fontWeight: 500 }}>Step transition</Box>
                <IconButton
                    onClick={() => {
                        dispatch(setSidePanelMode('voiceOver'))
                        logToAnalytics('motion_xBtn_clicked', {
                            playbookId
                        })
                    }}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box p={2} flexGrow={1} overflow="auto">
                <TransitionsList transition={transition} motionToAll={motionToAll.isTrue} />
            </Box>

            <Box mt={1} width="100%">
                <Divider />

                <Box p={2} display="flex" justifyContent="space-between">
                    <label style={{ cursor: 'pointer' }}>
                        <Checkbox
                            size="small"
                            color="primary"
                            disableRipple
                            checked={motionToAll.isTrue}
                            onChange={(_e, newValue) => {
                                motionToAll.set(newValue)
                                logToAnalytics('motion_applyAllChk_clicked', { playbookId })
                            }}
                        />
                        <Typography variant="caption">Apply to all steps</Typography>
                    </label>
                </Box>
            </Box>
        </>
    )
}
