import { memo, useEffect, useState } from 'react'

import { Box, CircularProgress, Skeleton } from '@mui/material'

import {
    type ImageShapeType,
    type RectangleShapeType,
    type StepType,
    type VideoShapeType,
    Shape
} from 'app/types'
import { useBoolean } from 'hooks'

const extractFirstFrame = (thumbnailPreview: string): Promise<string> => {
    const frameWidth = 80
    const frameHeight = 45

    return new Promise((resolve, reject) => {
        const image = new Image()
        image.crossOrigin = 'anonymous'
        image.src = thumbnailPreview

        image.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = frameWidth // Set the width to the desired size
            canvas.height = frameHeight // Set the height to the desired size
            ctx!.drawImage(image, 0, 0, frameWidth, frameHeight, 0, 0, frameWidth, frameHeight) // Draw the extracted portion directly onto the canvas
            canvas.toBlob(blob => {
                if (!blob) return reject('Error creating blob')
                resolve(URL.createObjectURL(blob))
            })
        }

        image.onerror = reject
    })
}

const findBgLayer = (
    layers: StepType['layers'],
    type: Shape.Rectangle | Shape.Image | Shape.Video
) => {
    return layers.find(layer => layer.type === type && layer.isBackground)
}

const generateGradientCSS = (gradientPoints: {
    startPoint: RectangleShapeType['fillLinearGradientStartPoint']
    endPoint: RectangleShapeType['fillLinearGradientEndPoint']
    colorStops: RectangleShapeType['fillLinearGradientColorStops']
}) => {
    const { startPoint, colorStops, endPoint } = gradientPoints
    if (!startPoint || !colorStops || !endPoint) return ''

    // Calculate the angle and direction of the gradient
    const angle = Math.atan2(startPoint.x - endPoint.x, startPoint.y - endPoint.y)

    // Convert radians to degrees
    const angleDegrees = ((angle * 180) / Math.PI) * -1

    const stops = colorStops
        .reduce<Array<string>>((stops, stop, index, array) => {
            if (index % 2 === 0) {
                const nextStop = array[index + 1]
                stops.push(`${nextStop} ${(stop as number) * 100}%`)
            }
            return stops
        }, [])
        .join(', ')

    return `linear-gradient(${angleDegrees}deg, ${stops})`
}

type Props = {
    step: StepType
    width: number
    height: number
    isLoading?: boolean
}

export const StepThumbnail = memo(({ step, width, height, isLoading }: Props) => {
    const { layers, drawnScreenshot, previewScreenshot } = step

    const loaded = useBoolean()

    const bgRectLayer = findBgLayer(layers, Shape.Rectangle) as RectangleShapeType | undefined
    const bgImageLayer = findBgLayer(layers, Shape.Image) as ImageShapeType | undefined
    const bgVideoLayer = findBgLayer(layers, Shape.Video) as VideoShapeType | undefined

    const [videoStepPreview, setVideoStepPreview] = useState('')

    const defaultSrc = previewScreenshot || drawnScreenshot || bgImageLayer?.url || videoStepPreview

    // Extract the first frame of the video as thumbnail if defaultSrc is not available
    useEffect(() => {
        if (defaultSrc || !bgVideoLayer) return

        const { sourceVideoThumbnailPreview } = bgVideoLayer
        if (!sourceVideoThumbnailPreview) return

        extractFirstFrame(sourceVideoThumbnailPreview).then(setVideoStepPreview)
    }, [bgVideoLayer, defaultSrc, height, width])

    // Preload the image to check if it's loaded, if not - show Skeleton
    useEffect(() => {
        if (!defaultSrc) return

        const img = new Image()
        img.src = defaultSrc
        img.onload = loaded.setTrue
    }, [defaultSrc, loaded.setTrue])

    if (!defaultSrc || isLoading) {
        const gradient = generateGradientCSS({
            startPoint: bgRectLayer?.fillLinearGradientStartPoint,
            endPoint: bgRectLayer?.fillLinearGradientEndPoint,
            colorStops: bgRectLayer?.fillLinearGradientColorStops
        })

        return (
            <Box
                height={height}
                width={width}
                style={{
                    background: isLoading ? '#E5E5E5' : gradient || bgRectLayer?.fill || 'white'
                }}
                borderRadius="4px"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {isLoading && <CircularProgress size={30} />}
            </Box>
        )
    }

    return (
        <>
            {loaded.isFalse && (
                <Skeleton
                    animation="wave"
                    width={width}
                    height={height}
                    style={{ transform: 'scale(1)', borderRadius: 'inherit' }}
                />
            )}

            {loaded.isTrue && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width,
                        height,
                        overflow: 'hidden',
                        borderRadius: 'inherit'
                    }}
                >
                    <img
                        src={defaultSrc}
                        height={height}
                        width="100%"
                        style={{ objectFit: 'contain' }}
                        alt="preloaded-img"
                        draggable={false}
                    />
                </div>
            )}
        </>
    )
})
