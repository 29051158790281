import { type MutableRefObject, useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import useImage from 'use-image'
import type Konva from 'konva'

import { Box } from '@mui/material'

import { QGContext } from 'UI/Routes/quick-guidde/CanvasEditor'
import { type RTDBTransitionType } from 'UI/Routes/quick-guidde/LeftPanel/MotionPanel/TransitionsList'
import { useAnimationFrame } from 'UI/Routes/quick-guidde/hooks'

import { useQuery } from 'hooks'

export const glPositions = [-1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0]

// Define vertex and fragment shaders
export const vertexShaderSource = `
  #ifdef GL_ES
  precision mediump float;
  #endif
  attribute vec2 position;
  varying vec2 _uv;
  
  void main() {
    gl_Position = vec4(position,0.0,1.0);
    _uv = vec2(0.5, 0.5) * (position+vec2(1.0, 1.0));
  }`

// Define vertex and fragment shaders
export const fragmentShaderSource = (transition: string) => {
    return `
        #ifdef GL_ES
        precision mediump float;
        #endif
        varying vec2 _uv;
        uniform sampler2D from, to;
        uniform float progress;
        
        vec4 getFromColor(vec2 uv) {
          vec2 uv1=vec2(1.,-1.)*uv+vec2(0.,1.);
          return texture2D(from,uv1);
        }
        
        vec4 getToColor(vec2 uv){
          vec2 uv1=vec2(1.,-1.)*uv+vec2(0.,1.);
          return texture2D(to,uv1);
        }
        
        ${transition}
        
        void main() {
          gl_FragColor = transition(_uv);
        }`
}

type Props = {
    stageRef: MutableRefObject<Konva.Stage | null>
}

export const TransitionPreview = ({ stageRef }: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const { data } = useQuery<Array<RTDBTransitionType>>(
        '/c/v1/config/qg/transitions',
        { method: 'GET' },
        {
            revalidateIfStale: false,
            dedupingInterval: 0
        }
    )

    const { frameWidth, frameHeight } = useContext(QGContext)

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const {
        transition,
        windowDimensions: { innerWidth, innerHeight }
    } = steps[activeStep]
    const { drawnScreenshot } = steps[activeStep - 1] || {}

    const [previousSlide] = useImage(drawnScreenshot, 'anonymous')

    const { start, stop, currentValue } = useAnimationFrame({
        duration: (transition?.duration || 0) * 1000 || 1000,
        finalValue: 1
    })

    const transitionCode = useMemo(() => {
        if (!transition || !data) return ''

        const option = data.find(item => item.name === transition.name)
        if (!option) return ''

        return 'direction' in option
            ? option.direction[transition.direction ?? 'left'].glsl
            : option.glsl
    }, [data, transition])

    const gl = useMemo(() => {
        const canvas = canvasRef.current
        if (!canvas) return null

        const glContext = canvas.getContext('webgl')
        if (!glContext) return null

        // We flip in the transifion function call insted of here
        // glContext.pixelStorei(glContext.UNPACK_FLIP_Y_WEBGL, true)

        return glContext
        // It should be here, otherwise it will always be null
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef.current])

    const vertexShader = useRef<WebGLShader | null>(null)
    const fragmentShader = useRef<WebGLShader | null>(null)
    const program = useRef<WebGLProgram | null>(null)
    const positionBuffer = useRef<WebGLBuffer | null>(null)
    const image1Texture = useRef<WebGLTexture | null>(null)
    const image2Texture = useRef<WebGLTexture | null>(null)

    useEffect(() => {
        if (!transitionCode || !gl || !stageRef.current) return

        // Create shaders
        if (!vertexShader.current) {
            vertexShader.current = gl.createShader(gl.VERTEX_SHADER)
            gl.shaderSource(vertexShader.current!, vertexShaderSource)
            gl.compileShader(vertexShader.current!)
            // This must be commented out in dev mode, otherwise it is hard to debug transitions
            /*
            const message = gl.getShaderInfoLog(vertexShader.current!)
            if (message?.length) {
                console.error('vertexShader', message, vertexShaderSource)
                throw message
            }
*/
        }
        if (!fragmentShader.current) {
            fragmentShader.current = gl.createShader(gl.FRAGMENT_SHADER)
            gl.shaderSource(fragmentShader.current!, fragmentShaderSource(transitionCode))
            gl.compileShader(fragmentShader.current!)
            // This must be commented out in dev mode, otherwise it is hard to debug transitions
            /*
            const message = gl.getShaderInfoLog(fragmentShader.current!)
            if (message?.length) {
                console.error('fragmentShader', message, fragmentShaderSource(transitionCode))
                throw message
            }
*/
        }

        if (!program.current) {
            // Create program
            program.current = gl.createProgram()
            gl.attachShader(program.current!, vertexShader.current!)
            gl.attachShader(program.current!, fragmentShader.current!)
            gl.linkProgram(program.current!)
            gl.useProgram(program.current!)
            // Set uniform locations
            const image1UniformLocation = gl.getUniformLocation(program.current!, 'from')
            gl.uniform1i(image1UniformLocation, 0)
            const image2UniformLocation = gl.getUniformLocation(program.current!, 'to')
            gl.uniform1i(image2UniformLocation, 1)
        }

        // Create buffer
        if (!positionBuffer.current) {
            positionBuffer.current = gl.createBuffer()
            gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer.current)
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(glPositions), gl.STATIC_DRAW)
        }

        // Set up attribute and enable it
        if (program.current) {
            const positionAttributeLocation = gl.getAttribLocation(program.current, 'position')
            gl.enableVertexAttribArray(positionAttributeLocation)
            gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0)
        }

        // Set uniform locations and progress
        if (program.current) {
            const progressULocation = gl.getUniformLocation(program.current, 'progress')
            gl.uniform1f(progressULocation, 0.0)
            gl.uniform1f(progressULocation, currentValue)
        }

        // Set active textures and bind them
        // Previous slide
        if (!image1Texture.current) {
            image1Texture.current = gl.createTexture()
            gl.activeTexture(gl.TEXTURE0)
            gl.bindTexture(gl.TEXTURE_2D, image1Texture.current)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
            if (previousSlide) {
                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, previousSlide)
            } else {
                gl.texImage2D(
                    gl.TEXTURE_2D,
                    0,
                    gl.RGBA,
                    1,
                    1,
                    0,
                    gl.RGBA,
                    gl.UNSIGNED_BYTE,
                    new Uint8Array([0, 0, 0, 255])
                )
            }
        }
        // Current slide
        if (!image2Texture.current) {
            image2Texture.current = gl.createTexture()
            gl.activeTexture(gl.TEXTURE1)
            gl.bindTexture(gl.TEXTURE_2D, image2Texture.current)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
            gl.texImage2D(
                gl.TEXTURE_2D,
                0,
                gl.RGBA,
                gl.RGBA,
                gl.UNSIGNED_BYTE,
                stageRef.current.toCanvas({
                    pixelRatio: window.devicePixelRatio
                })
            )
        }

        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 6)
    }, [previousSlide, currentValue, stageRef, transitionCode, gl])

    // Clean up
    useEffect(() => {
        if (!gl) return

        if (currentValue === 0 || currentValue === 1) {
            // Detach shaders and delete them
            if (program.current && vertexShader.current && fragmentShader.current) {
                gl.detachShader(program.current, vertexShader.current)
                gl.deleteShader(vertexShader.current)
                vertexShader.current = null
                gl.detachShader(program.current, fragmentShader.current)
                gl.deleteShader(fragmentShader.current)
                fragmentShader.current = null

                // Delete the program
                gl.deleteProgram(program.current)
                program.current = null
            }

            // Delete the position buffer
            if (positionBuffer.current) {
                gl.deleteBuffer(positionBuffer.current)
                positionBuffer.current = null
            }

            // Delete the textures
            if (image1Texture.current) {
                gl.deleteTexture(image1Texture.current)
                image1Texture.current = null
            }
            if (image2Texture.current) {
                gl.deleteTexture(image2Texture.current)
                image2Texture.current = null
            }
        }
    }, [currentValue, gl])

    useEffect(() => {
        window.showTransition = start
        window.stopTransition = stop

        stop()
    }, [start, stop])

    // Animation is finished or not started yet
    if (currentValue === 0 || currentValue === 1) return null

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top={0}
            zIndex={10}
        >
            <canvas
                ref={canvasRef}
                width={innerWidth}
                height={innerHeight}
                style={{
                    width: frameWidth,
                    height: frameHeight
                }}
            />
        </Box>
    )
}
