import dayjs from 'dayjs'

import { humanFileSize, getOwner, isFolder } from 'modules'

export const gDriveColumns = [
    { value: 'name', label: 'Name', cellContent: file => file.name },
    {
        value: 'owner',
        label: 'Owner',
        cellContent: file => {
            if (file.id === 'root' || file?.owners?.some(owner => owner.me)) return 'Me'
            if (!file.owners) return 'Shared'

            const ownerName = file.owners?.[0]?.displayName
            return ownerName || 'N/A'
        }
    },
    {
        value: 'modifiedTime',
        label: 'Last modified',
        isSecondary: true,
        cellContent: file =>
            file.kind !== 'drive#drive' && dayjs(file.modifiedTime).format('DD MMM, YYYY')
    },
    {
        value: 'size',
        label: 'File size',
        cellContent: file => (file.size ? humanFileSize(file.size) : '')
    }
]

const compareStrings = (a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })

export const sortGdriveFiles = (sortBy, sortDesc, a, b) => {
    if (a.mimeType !== b.mimeType) {
        // Folders first
        return isFolder(a) ? -1 : 1
    }

    const paramA = sortBy === 'owner' ? getOwner(a.owners) : a[sortBy]
    const paramB = sortBy === 'owner' ? getOwner(b.owners) : b[sortBy]

    if (!paramA && !paramB) {
        return compareStrings(a.name, b.name)
    }

    if (sortBy === 'name' || sortBy === 'owner') {
        return sortDesc ? compareStrings(paramB, paramA) : compareStrings(paramA, paramB)
    }

    if (sortBy === 'size') {
        return sortDesc ? paramB - paramA : paramA - paramB
    }

    if (sortBy === 'modifiedTime') {
        const momentA = dayjs(paramA).valueOf()
        const momentB = dayjs(paramB).valueOf()

        return sortDesc ? momentB - momentA : momentA - momentB
    }

    return 0
}
