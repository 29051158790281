import { useEffect } from 'react'
import { Redirect, useLocation, useParams, useRouteMatch, useHistory } from 'react-router-dom'

import { Tab, Tabs, styled } from '@mui/material'
import { DocumentTitle, TabPanel } from 'UI/Components'

import { AccountTab, UsersTab, DictionaryTab, PreferencesTab, BillingTab } from './tabs'

import { paths } from 'app/paths'
import {
    useRoles,
    useAuth,
    useServiceUsage,
    useQueryParams,
    useOrganizationUsers,
    useGlobalFeatureFlags
} from 'hooks'

const StyledTab = styled(Tab)({
    maxWidth: '170px',
    minWidth: '120px'
})

type QueryParamsType = {
    orgId: string
    tab: string
}

export const ACCOUNT_SETTINGS_TABS = {
    account: 0,
    billing: 1,
    dictionary: 2,
    users: 3,
    preferences: 4
}

const PageAccountSettings = () => {
    const { isSuperAdmin } = useRoles()
    const history = useHistory()
    const query = useQueryParams()
    const location = useLocation<{ isInviteRedirect: boolean }>()

    const tabIdx = location.state?.isInviteRedirect
        ? ACCOUNT_SETTINGS_TABS.users
        : ACCOUNT_SETTINGS_TABS.account
    const tab = Number(query.get('tab') || tabIdx)

    const params = useParams<QueryParamsType>()

    const { orgId, isPrivateAccount } = useAuth()
    const { orgUsers, loadingOrgUsers, refetchOrgUsers, pendingUsers, pendingRequests } =
        useOrganizationUsers({
            includePending: true,
            includeRequests: true
        })

    const {
        showDictionary,
        hideBillingTable,
        isLoading: featureFlagsLoading
    } = useGlobalFeatureFlags()

    const { isCustomPrice, usedUsersSeats, userSeatsQuota, isEnterprisePlan } = useServiceUsage()

    const hideBilling = isCustomPrice || isEnterprisePlan || hideBillingTable || featureFlagsLoading

    const isOrgSettingsRoute = useRouteMatch(paths.organizationSettings)?.isExact ?? false

    const organizationId = isOrgSettingsRoute && orgId ? params?.orgId : orgId
    const showUsersTab = !isPrivateAccount || orgUsers.length > 1

    useEffect(() => {
        // when user opens users tab and it's hidden we redirect him to default one
        if (loadingOrgUsers) return

        if (tab === ACCOUNT_SETTINGS_TABS.users && !showUsersTab) {
            query.set('tab', ACCOUNT_SETTINGS_TABS.account.toString())
            history.replace({
                pathname: location.pathname,
                search: query.toString()
            })
        }
    }, [history, loadingOrgUsers, location.pathname, query, showUsersTab, tab])

    if (isOrgSettingsRoute && !isSuperAdmin) return <Redirect to={paths.accountSettings} />

    if (!organizationId) return null

    const usersExceeded = Boolean(userSeatsQuota && usedUsersSeats > userSeatsQuota)

    return (
        <>
            <Tabs
                value={tab}
                onChange={(_event, newValue) => {
                    query.set('tab', newValue.toString())
                    history.replace({
                        pathname: location.pathname,
                        search: query.toString()
                    })
                }}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <StyledTab label="Account" />
                <StyledTab sx={{ display: hideBilling ? 'none' : 'flex' }} label="Billing" />
                <StyledTab sx={{ display: showDictionary ? 'flex' : 'none' }} label="Dictionary" />
                <StyledTab sx={{ display: showUsersTab ? 'flex' : 'none' }} label="Users" />
                <StyledTab label="Preferences" />
            </Tabs>
            <DocumentTitle title="Account Settings" />
            <TabPanel value={tab} index={ACCOUNT_SETTINGS_TABS.account}>
                <AccountTab orgId={organizationId} usersExceeded={usersExceeded} />
            </TabPanel>
            {!hideBilling && (
                <TabPanel value={tab} index={ACCOUNT_SETTINGS_TABS.billing} keepInDOM={false}>
                    <BillingTab />
                </TabPanel>
            )}
            {showDictionary && (
                <TabPanel value={tab} index={ACCOUNT_SETTINGS_TABS.dictionary}>
                    <DictionaryTab />
                </TabPanel>
            )}
            {showUsersTab && (
                <TabPanel value={tab} index={ACCOUNT_SETTINGS_TABS.users}>
                    <UsersTab
                        orgId={organizationId}
                        usersExceeded={usersExceeded}
                        refetchOrgUsers={refetchOrgUsers}
                        orgUsers={orgUsers}
                        pendingUsers={pendingUsers}
                        pendingRequests={pendingRequests}
                    />
                </TabPanel>
            )}
            <TabPanel value={tab} index={ACCOUNT_SETTINGS_TABS.preferences}>
                <PreferencesTab />
            </TabPanel>
        </>
    )
}

export default PageAccountSettings
