import { InfoList } from './InfoList'

import { type PlaybookType } from 'app/types'

export type FileType = {
    id: string
    name: string
    uid: string
    url: string
}

type OpenType = '_blank' | '_self'

type Props = {
    /**
     * Playbook object with {id, name, uid, url}
     */
    playbook: PlaybookType
    /**
     * Whether we are in edit mode
     */
    isEditMode: boolean
    /**
     * Open link
     */
    openLink: (link: string, type: OpenType) => void
    /**
     * Open modal
     */
    openModal?: () => void
    /**
     * Handle removing of an item
     */
    onDelete?: (itemId: string, isLink?: boolean) => void
    /**
     * Whether the playbook is in public mode or not
     */
    isPublicMode: boolean
}

export const Info = ({ openModal, playbook, isEditMode, ...props }: Props) => (
    <InfoList
        playbook={playbook}
        isFilesEmpty={!playbook?.files?.length}
        isLinksEmpty={!playbook.links?.length}
        isEditMode={isEditMode}
        openModal={isEditMode ? openModal : undefined}
        {...props}
    />
)
