import { useContext, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor'
import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'

import { FileUploader } from 'UI/Components'
import { DEFAULT_STEP } from './AddNewStep'
import { AddStepMenuItem } from './AddStepMenuItem'

import { ReactComponent as VideoStepIcon } from 'assets/icons/video-step.svg'

import { addNewStep } from 'ducks'
import { generalErrorMessage, logToAnalytics, round, uuid } from 'modules'
import {
    type BrowserBarShapeType,
    type QuickGuiddeType,
    type RectangleShapeType,
    Shape,
    type StepType
} from 'app/types'

import {
    noBrandKitBackgroundId,
    useAuth,
    useBrandKit,
    useConfiguration,
    useNotification
} from 'hooks'

const removeExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.')

    // If '.' exists, and it's not the first character
    if (lastDotIndex !== -1 && lastDotIndex > 0) return filename.substring(0, lastDotIndex)

    return filename
}

export const loadVideoMetadata = (url: string) => {
    return new Promise<{ width: number; height: number; duration: number }>((resolve, reject) => {
        const video = document.createElement('video')
        video.src = url
        video.preload = 'metadata'
        video.onloadedmetadata = () => {
            resolve({
                width: video.videoWidth,
                height: video.videoHeight,
                duration: round(video.duration)
            })
            video.remove()
        }
        video.onerror = reject
    })
}

export const getLayerCoordinates = (
    width: number,
    height: number,
    windowDimensions: StepType['windowDimensions'],
    scaleFactor: number
) => {
    const { innerWidth, innerHeight } = windowDimensions

    // If the video is bigger than the screen, scale it down. Otherwise, scale it up
    const scale = Math.min(innerWidth / width, innerHeight / height) * scaleFactor

    const x = (innerWidth - width * scale) / 2
    const y = (innerHeight - height * scale) / 2

    return {
        x,
        y,
        scale
    }
}

export const getVideoMetadata = async (
    video: File | string,
    windowDimensions: StepType['windowDimensions'],
    scaleFactor = 1
): Promise<VideoMetadataType> => {
    const videoUrl = typeof video === 'string' ? video : URL.createObjectURL(video)

    const { width, height, duration } = await loadVideoMetadata(videoUrl)
    const { x, y, scale } = getLayerCoordinates(width, height, windowDimensions, scaleFactor)

    URL.revokeObjectURL(videoUrl)
    return { width, height, duration, x, y, scale }
}

type GenerateVideoStepType = {
    duration: number
    fileName: string
    fileUrl: string
    width: number
    height: number
    x: number
    y: number
    scale?: number
    windowDimensions: StepType['windowDimensions']
    stepId?: string
    kind?: StepType['kind']
    sourceVideoThumbnailPreview?: string
    sourceDuration?: number
    start?: number
    end?: number
    backgroundLayer?: RectangleShapeType
    browserLayer?: BrowserBarShapeType
}

export const generateVideoStep = ({
    stepId,
    duration,
    fileName,
    fileUrl,
    width,
    height,
    x,
    y,
    scale = 1,
    windowDimensions,
    kind,
    sourceVideoThumbnailPreview,
    sourceDuration,
    start,
    end,
    backgroundLayer,
    browserLayer
}: GenerateVideoStepType) => {
    return {
        ...DEFAULT_STEP,
        ...(kind ? { kind } : {}),
        id: stepId || uuid(),
        title: removeExtension(fileName) || 'Video step',
        timeStamp: Date.now(),
        windowDimensions,
        duration: Math.ceil(duration),
        layers: [
            ...(backgroundLayer ? [backgroundLayer] : []),
            ...(browserLayer ? [browserLayer] : []),
            {
                ...DEFAULT_VIDEO_LAYER,
                id: uuid(),
                sourceVideoUrl: fileUrl,
                width,
                height,
                x,
                y,
                scaleX: scale,
                scaleY: scale,
                start: start || 0,
                end: end || duration,
                sourceDuration: sourceDuration || duration,
                generateArtifacts: true as const,
                isBackground: true,
                sourceVideoThumbnailPreview: sourceVideoThumbnailPreview || ''
            }
        ]
    }
}

const DEFAULT_VIDEO_LAYER = {
    type: Shape.Video,
    id: uuid(),
    url: '',
    sourceVideoUrl: '',
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    sourceDuration: 0,
    sourceVideoThumbnailPreview: ''
} as const

export type VideoMetadataType = {
    width: number
    height: number
    duration: number
    scale: number
    x: number
    y: number
}

type Props = {
    language: QuickGuiddeType['language']
    onDone: () => void
}

export const VideoStep = ({ language, onDone }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()
    const videoMetadata = useRef<VideoMetadataType | null>(null)

    const { uid } = useAuth()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { windowDimensions, kind } = steps[activeStep] || {}

    const { extractVideoStep } = useContext(VoiceOverContext)

    const { backgroundRectangle } = useLayers()

    // Complex bg means that 'No background' option from the brand-kit is not selected
    const { brandKitBackground } = useBrandKit()

    const scaleFactor = useMemo(() => {
        if (backgroundRectangle?.scaleFactor) return backgroundRectangle.scaleFactor

        const isNoBackground = brandKitBackground?.id === noBrandKitBackgroundId
        if (!brandKitBackground || isNoBackground) return 1

        const hasBrandKitScaleFactor = 'scaleFactor' in brandKitBackground
        return hasBrandKitScaleFactor ? brandKitBackground.scaleFactor : 0.8
    }, [backgroundRectangle, brandKitBackground])

    const { isConfigurationLoading, maxStepVideoUploadSize } = useConfiguration()

    const { showErrorNotification } = useNotification()

    return (
        <FileUploader
            accept="video/*"
            isTempStorage
            hideAttachFile
            hidePreview
            storagePath={`quickguiddes/${uid}/${playbookId}/uploads`}
            filename={`${uuid()}_source`}
            maxFileSizeInMb={Number(maxStepVideoUploadSize)}
            progressStyle={{ marginRight: 12, marginLeft: 12 }}
            labelComponent={
                <AddStepMenuItem
                    disabled={isConfigurationLoading && !maxStepVideoUploadSize}
                    title="Video"
                    Icon={VideoStepIcon}
                    onClick={() => {
                        logToAnalytics('addVideoStep_clicked', {
                            playbookId
                        })
                    }}
                />
            }
            onDone={(fileUrl, _metadata) => {
                if (!videoMetadata.current) {
                    showErrorNotification(generalErrorMessage)
                    return
                }

                const { width, height, x, y, scale, duration } = videoMetadata.current

                const stepId = uuid()

                extractVideoStep({
                    playbookId,
                    languageCode: language?.langCode || 'en-US',
                    languageName: language?.langName || 'English',
                    stepId,
                    videoUrl: fileUrl,
                    generateThumbnailPreview: true
                })

                dispatch(
                    addNewStep({
                        kind: 'step',
                        position: ['end', 'outro'].includes(kind) ? 'before' : 'after',
                        step: generateVideoStep({
                            windowDimensions,
                            stepId,
                            duration,
                            fileName: _metadata.name,
                            fileUrl,
                            width,
                            height,
                            x,
                            y,
                            scale,
                            backgroundLayer: backgroundRectangle
                        })
                    })
                )

                videoMetadata.current = null
                onDone()
            }}
            onFileChange={async (file, _fileId) => {
                const fileMetadata = await getVideoMetadata(file, windowDimensions, scaleFactor)

                const unsupportedFile = [fileMetadata.duration, fileMetadata.scale].includes(
                    Infinity
                )
                if (unsupportedFile) {
                    showErrorNotification('This file is unsupported. Please choose a different one')
                    return
                }

                videoMetadata.current = fileMetadata

                logToAnalytics('videoStep_video_imported', {
                    playbookId,
                    file,
                    activeStep: activeStep + 1
                })
            }}
        />
    )
}
