import { useCallback, useMemo, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

type UpdateFilterQueryProps = {
    [key: string]: Array<string>
}

const generateNewState = (
    filterNames: Array<string>,
    newValues: { [key: string]: Array<string> }
) => {
    const allValues = filterNames.reduce<{ [key: string]: Array<string> }>((acc, key) => {
        acc[key] = []

        return acc
    }, {})

    return { ...allValues, ...newValues }
}
export const useFiltersQuery = (filterNames: Array<string>) => {
    const location = useLocation()
    const history = useHistory()

    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

    const isFiltersEmpty = filterNames.filter(it => queryParams.getAll(it).length).length < 1

    const [isInitialFiltersEmpty] = useState(isFiltersEmpty)

    const getFiltersFromQuery = useCallback(() => {
        const filterValues = filterNames.reduce<UpdateFilterQueryProps>((acc, filterName) => {
            const filterValues = queryParams.getAll(filterName)

            if (filterValues?.length) {
                acc[filterName] = filterValues
            }
            return acc
        }, {})

        const parsedValues = Object.entries(filterValues).reduce<{
            [key: string]: Array<string>
        }>((acc, [key, values]) => {
            if (values?.[0]) {
                acc[key] = values[0].split(',')
            }

            return acc
        }, {})

        return generateNewState(filterNames, parsedValues)
    }, [filterNames, queryParams])

    const updateFilterQuery = useCallback(
        (data: UpdateFilterQueryProps) => {
            const entries = Object.entries(data)

            entries.forEach(([key, values]) => {
                if (!values.length) {
                    queryParams.delete(key)
                } else {
                    queryParams.set(key, values.join(','))
                }
            })

            history.replace({
                search: queryParams.toString()
            })
        },
        [history, queryParams]
    )

    const resetAllQueryFilters = useCallback(() => {
        filterNames.forEach(filterName => {
            queryParams.delete(filterName)
        })

        history.replace({
            search: queryParams.toString()
        })
    }, [filterNames, history, queryParams])

    return useMemo(
        () => ({
            resetAllQueryFilters,
            getFiltersFromQuery,
            updateFilterQuery,
            isInitialFiltersEmpty
        }),
        [updateFilterQuery, resetAllQueryFilters, getFiltersFromQuery, isInitialFiltersEmpty]
    )
}
