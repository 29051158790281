import { Button, Typography, Box, styled } from '@mui/material'
import Icon from '@mui/icons-material/ErrorOutline'

import { SpacedGroup } from 'UI/Components'

import { useNotification, usePayment } from 'hooks'

import { Elements, useStripe } from '@stripe/react-stripe-js'

const PaymentError = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '10px',
    padding: '10px 15px',
    color: '#cb0000',
    background: 'rgba(203,0,0, 0.06)',
    width: '120%'
})

export const SubmitPaymentNotification = () => {
    const { paymentFailed, paymentMethod, stripePromise } = usePayment()

    if (!paymentFailed) return null

    const needAdditionalAction =
        paymentFailed.error?.code === 'payment_intent_authentication_failure' ||
        !paymentFailed?.error

    const massage = needAdditionalAction
        ? 'Payment requires additional action'
        : 'Your payment failed'

    const buttonText = needAdditionalAction ? 'Confirm payment' : 'Try again'

    return (
        <Elements stripe={stripePromise} options={{ clientSecret: paymentFailed.clientSecret }}>
            <PaymentError mb={2}>
                <SpacedGroup spacing={1}>
                    <Icon />
                    <Typography variant="body2">{massage}</Typography>
                </SpacedGroup>
                {paymentMethod && (
                    <Submit
                        clientSecret={paymentFailed.clientSecret}
                        paymentMethod={paymentMethod}
                        buttonText={buttonText}
                    />
                )}
            </PaymentError>
        </Elements>
    )
}

type SubmitProps = {
    paymentMethod: { id: string }
    clientSecret: string
    buttonText: string
}

// component created because useStripe hook must be called inside Elements component
const Submit = ({ paymentMethod, clientSecret, buttonText }: SubmitProps) => {
    const stripe = useStripe()
    const { showErrorNotification, showSuccessNotification } = useNotification()

    const confirmFailedPayment = () => {
        stripe
            ?.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id
            })
            .then(res => {
                if (res.error) {
                    const message =
                        res.error.payment_intent?.last_payment_error?.message ||
                        'Something went wrong, please, try again later'

                    return showErrorNotification(message)
                }

                showSuccessNotification('Payment was successfuly confirmed!')
            })
    }

    return (
        <Button size="small" onClick={confirmFailedPayment} style={{ fontSize: '12px' }}>
            {buttonText}
        </Button>
    )
}
