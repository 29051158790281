import { useContext, useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { arrayMoveImmutable } from 'array-move'

import { ThemeProvider, Typography } from '@guidde/design-system'

import { Box } from '@mui/material'
import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'

import { SmartPreviewContext } from 'UI/Routes/quick-guidde/CanvasEditor/SmartPreview'

import { SortableComponent, SpacedGroup } from 'UI/Components'
import { AddIntroOutroStep } from './AddIntroOutroStep'
import { AddCoverEndingStep } from './AddCoverEndingStep'
import { Step } from './Step'

import { type QuickGuiddeType, type StepType, Shape } from 'app/types'
import { selectLayer, setActiveStep, setQuickGuiddeSteps } from 'ducks'
import { formatTime, logToAnalytics } from 'modules'

const ScrollableWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingRight: '4px !important',
    paddingLeft: theme.spacing(1.25),
    maxWidth: 'calc(100vw / 12 * 9)',
    [theme.breakpoints.up('xl')]: {
        maxWidth: 'calc(100vw / 12 * 10)'
    },
    overflowX: 'scroll',
    '&::-webkit-scrollbar, &::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        visibility: 'hidden'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(124, 124, 124, 1)',
        borderRadius: '20px',
        border: '5px solid transparent',
        backgroundClip: 'content-box',
        visibility: 'hidden'
    },
    '&:hover': {
        '&::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb': {
            visibility: 'visible'
        }
    }
}))

const findStepData = (steps: Array<StepType>, type: StepType['kind']) => {
    const step = steps.find(step => step.kind === type)
    const index = steps.findIndex(step => step.kind === type)
    return { step, index }
}

export const generateStepIndex = (steps: Array<StepType>, idx: number) => {
    const stepsBeforeIndex = steps
        .slice(0, 2) // we check only the steps before the regular kind:step
        .filter(step => ['intro', 'cover'].includes(step.kind)).length
    const indexDelta = 1 - stepsBeforeIndex // to make sure the first step is 01

    switch (steps[idx]?.kind) {
        case 'intro':
            return 'Intro'
        case 'cover':
            return 'Cover'
        case 'step':
            return (idx + indexDelta).toString().padStart(2, '0')
        case 'end':
            return 'Ending'
        case 'outro':
            return 'Outro'
    }
}

type Props = {
    quickGuidde: QuickGuiddeType
}

export const StepsPreview = ({ quickGuidde }: Props) => {
    const dispatch = useDispatch()

    const {
        present: { steps, activeStep, selectedSteps, selectedLayersIds }
    } = useSelector(state => state.qgEditor)

    const { pasteIndex, loadingSteps } = useSelector(state => state.pasteSteps)

    useEffect(() => {
        const element = document.getElementById(steps[activeStep]?.id || '')
        if (!element) return

        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' })
    }, [activeStep, steps])

    const { step: introStep, index: introStepIndex } = findStepData(steps, 'intro')
    const { step: coverStep, index: coverStepIndex } = findStepData(steps, 'cover')
    const { step: endingStep, index: endingStepIndex } = findStepData(steps, 'end')
    const { step: outroStep, index: outroStepIndex } = findStepData(steps, 'outro')

    const previewSteps = useMemo((): Array<StepType & { isLoading?: boolean }> => {
        const regularSteps = steps.filter(step => step.kind === 'step')

        if (!loadingSteps.length) return regularSteps

        const currentStepKind = steps[activeStep]?.kind
        let sliceIdx = pasteIndex

        if (currentStepKind === 'cover') sliceIdx = 0
        if (currentStepKind === 'step') {
            if ([introStep, coverStep].filter(Boolean).length === 2) sliceIdx -= 1
        }

        return [
            ...regularSteps.slice(0, sliceIdx),
            ...loadingSteps.map(step => ({
                ...step,
                isLoading: true
            })),
            ...regularSteps.slice(sliceIdx)
        ]
    }, [activeStep, coverStep, introStep, loadingSteps, pasteIndex, steps])

    return (
        <SpacedGroup>
            <PlayButton />

            <ScrollableWrapper
                onClick={() => {
                    if (!selectedLayersIds.length) return
                    dispatch(selectLayer(null))
                }}
            >
                {/* Intro step */}
                {introStep ? (
                    <Step
                        key={introStep.id}
                        isActive={activeStep === introStepIndex}
                        isSelected={selectedSteps.includes(introStep.id)}
                        step={introStep}
                        originalIndex={introStepIndex}
                        stepLabel={generateStepIndex(steps, introStepIndex)}
                    />
                ) : (
                    <AddIntroOutroStep
                        language={quickGuidde.language}
                        kind="intro"
                        showInnerDivider={Boolean(coverStep)}
                    />
                )}

                {/* Cover step */}
                {coverStep ? (
                    <Step
                        key={coverStep.id}
                        isActive={activeStep === coverStepIndex}
                        isSelected={selectedSteps.includes(coverStep.id)}
                        step={coverStep}
                        originalIndex={coverStepIndex}
                        stepLabel={generateStepIndex(steps, coverStepIndex)}
                    />
                ) : (
                    <AddCoverEndingStep title={quickGuidde.title} kind="cover" />
                )}

                <SortableComponent
                    direction="row"
                    disabled={loadingSteps.length > 0}
                    onSortEnd={({ oldIndex, newIndex }) => {
                        if (oldIndex === newIndex) return

                        // since we ignore intro and cover inside SortableComponent, need to add +1 for each step
                        const delta = [introStep, coverStep].filter(Boolean).length

                        const prevIndex = oldIndex + delta
                        const nextIndex = newIndex + delta

                        const hasStepNumbering = steps.some(step =>
                            step.layers.some(layer => layer.type === Shape.CircleWithText)
                        )

                        const newSteps: Array<StepType> = arrayMoveImmutable(
                            steps,
                            prevIndex,
                            nextIndex
                        ).map((step, stepIndex) => {
                            // Ignore steps that are not between prevIndex and nextIndex
                            if (
                                stepIndex < Math.min(prevIndex, nextIndex) ||
                                stepIndex > Math.max(prevIndex, nextIndex)
                            )
                                return step

                            const layersWithNewNumbering = step.layers.map(layer => {
                                if (layer.type !== Shape.CircleWithText) return layer
                                return {
                                    ...layer,
                                    text: {
                                        ...layer.text,
                                        title: generateStepIndex(steps, stepIndex)
                                    }
                                }
                            })

                            return {
                                ...step,
                                isChanged: hasStepNumbering,
                                layers: layersWithNewNumbering
                            }
                        })

                        dispatch(setQuickGuiddeSteps(newSteps))
                        dispatch(setActiveStep(newIndex + delta))
                    }}
                >
                    {previewSteps.map(({ isLoading, ...step }, idx) => {
                        const delta = [introStep, coverStep].filter(Boolean).length
                        const initialIndex = idx + delta

                        return (
                            <Step
                                key={step.id}
                                isActive={activeStep === initialIndex}
                                isSelected={selectedSteps.includes(step.id)}
                                step={step}
                                isLoading={isLoading}
                                originalIndex={initialIndex}
                                stepLabel={generateStepIndex(previewSteps, idx)}
                            />
                        )
                    })}
                </SortableComponent>

                {/* Ending step */}
                {endingStep ? (
                    <Step
                        isActive={activeStep === endingStepIndex}
                        isSelected={selectedSteps.includes(endingStep.id)}
                        step={steps.find(step => step.kind === 'end')}
                        originalIndex={endingStepIndex}
                        stepLabel={generateStepIndex(steps, endingStepIndex)}
                    />
                ) : (
                    <AddCoverEndingStep title="Ending" kind="end" />
                )}

                {/* Outro step */}
                {outroStep ? (
                    <Step
                        key={outroStep.id}
                        isActive={activeStep === outroStepIndex}
                        isSelected={selectedSteps.includes(outroStep.id)}
                        step={outroStep}
                        originalIndex={outroStepIndex}
                        stepLabel={generateStepIndex(steps, outroStepIndex)}
                    />
                ) : (
                    <AddIntroOutroStep
                        language={quickGuidde.language}
                        kind="outro"
                        showInnerDivider={Boolean(endingStep)}
                    />
                )}
            </ScrollableWrapper>
        </SpacedGroup>
    )
}

const PlayButton = () => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { activeStep } = useSelector(state => state.qgEditor.present)

    const { start, pause, stop, status, time, stepsTimeline, totalProgress } =
        useContext(SmartPreviewContext)

    const isFinished = totalProgress === 100

    return (
        <ThemeProvider>
            <Box height="100%" mx={2} display="flex" flexDirection="column" alignItems="center">
                <Box
                    id="play-button"
                    mt={2}
                    mb={1.25}
                    color="common.white"
                    style={{ cursor: 'pointer' }}
                    borderRadius="50%"
                    bgcolor="primary.600"
                    p={1.5}
                    onClick={() => {
                        if (status === 'running') {
                            pause()
                            const newIndex = stepsTimeline.steps.findIndex(
                                step => time <= step.stepEnd
                            )
                            logToAnalytics('smart_preview_pause', {
                                playbookId,
                                time
                            })
                            if (newIndex === activeStep) return
                            dispatch(setActiveStep(newIndex))
                            return
                        }

                        if (isFinished) {
                            dispatch(setActiveStep(0))
                            stop()
                        }
                        start()
                        logToAnalytics('smart_preview_play', {
                            playbookId,
                            time
                        })
                    }}
                >
                    <div style={{ pointerEvents: 'none' }}>
                        {status === 'stopped' ? <PlayIcon /> : <PauseIcon />}
                    </div>
                </Box>
                <Typography variant="text" size="xs" fontWeight="regular">
                    {formatTime(time)}/{formatTime(stepsTimeline.totalDuration)}
                </Typography>
            </Box>
        </ThemeProvider>
    )
}
