import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@mui/material'

import { AsyncSubtitlesContext } from 'UI/Routes/quick-guidde/AsyncSubtitlesProvider'
import { generateStepIndex } from 'UI/Routes/quick-guidde/CanvasEditor'

import { CaptionsStep } from './CaptionsStep'
import { CaptionsEditorProvider } from './CaptionsEditorProvider'
import { CaptionSearchPanel } from './CaptionSearchPanel'

import { type QuickGuiddeType } from 'app/types'

type Props = {
    playbook: QuickGuiddeType
}

export const CaptionsEditor = ({ playbook }: Props) => {
    const { processingStepsId } = useContext(AsyncSubtitlesContext)

    const { steps, activeStep } = useSelector(state => state.qgEditor.present)

    // Step captions starts from 0, but we want to show the time based on video
    const stepsTimeDelta = useMemo(() => {
        return steps.reduce(
            (acc, curr) => {
                const stepDuration = curr.duration
                const transitionDuration = curr.transition?.duration || 0
                const previousStepEnd = acc[acc.length - 1] || 0
                return [...acc, previousStepEnd + stepDuration + transitionDuration]
            },
            [0] as Array<number>
        )
    }, [steps])

    return (
        <CaptionsEditorProvider>
            <CaptionSearchPanel playbook={playbook} />
            <Box
                height="calc(100vh - 186px)"
                overflow="auto"
                sx={theme => ({
                    boxShadow: `inset 4px 0 0 ${theme.palette.grey['200']}`
                })}
            >
                {steps.map((step, index) => {
                    const stepIndexLabel = generateStepIndex(steps, index)

                    const isActive = index === activeStep
                    const isStepProcessing = processingStepsId.includes(step.id)

                    return (
                        <CaptionsStep
                            key={step.id}
                            stepTimeDelta={stepsTimeDelta[index]}
                            stepTitle={`${stepIndexLabel}: ${step.title || (step.kind === 'end' ? 'Thank you' : 'Untitled step')}`}
                            stepIndex={index}
                            subtitles={step.subtitles || []}
                            stepDuration={step.duration}
                            isActive={isActive}
                            isStepProcessing={isStepProcessing}
                        />
                    )
                })}
            </Box>
        </CaptionsEditorProvider>
    )
}
