import { useCallback, useMemo, useState } from 'react'

export const useArrayStorage = (initialValue, uniqueKey) => {
    const [storage, setStorage] = useState(initialValue ?? [])

    const isSelected = useCallback(
        item => storage.some(storageItem => storageItem[uniqueKey] === item[uniqueKey]),
        [storage, uniqueKey]
    )

    const areAllSelected = useCallback(
        items => items.length !== 0 && items.every(item => isSelected(item)),
        [isSelected]
    )

    const toggleItem = useCallback(
        item =>
            setStorage(prevStorage => {
                const includedIndex = prevStorage.findIndex(
                    storageItem => storageItem[uniqueKey] === item[uniqueKey]
                )

                if (includedIndex === -1) {
                    return prevStorage.concat([item])
                } else {
                    return [
                        ...prevStorage.slice(0, includedIndex),
                        ...prevStorage.slice(includedIndex + 1)
                    ]
                }
            }),
        [uniqueKey]
    )

    const addMultiple = useCallback(
        items => {
            const newItems = items.filter(item => !isSelected(item))
            setStorage(prevStorage => prevStorage.concat(newItems))
        },
        [isSelected]
    )

    const deleteMultiple = useCallback(
        items => {
            setStorage(prevStorage =>
                prevStorage.filter(
                    storageItem => !items.some(item => item[uniqueKey] === storageItem[uniqueKey])
                )
            )
        },
        [uniqueKey]
    )

    const clearStorage = useCallback(() => setStorage([]), [])

    return useMemo(
        () => ({
            storage,
            isSelected,
            areAllSelected,
            toggleItem,
            addMultiple,
            deleteMultiple,
            clearStorage
        }),
        [storage, isSelected, areAllSelected, toggleItem, addMultiple, deleteMultiple, clearStorage]
    )
}
