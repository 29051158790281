import { useMemo } from 'react'

import { useQuery } from './use-query'
import { useAuth } from './use-auth'

export type UserType = {
    uid: string
    email: string
    displayName: string
    roles: number
    photoURL: string
    status: 'active' | 'pending'
    viewerOnlyAfterMs?: number
}

type Props = {
    includePending?: boolean
    includeRequests?: boolean
}

export type UsersResponseType =
    | {
          orgUsers: Array<UserType>
          pendingUsers: Array<UserType>
          pendingRequests: Array<UserType>
      }
    | undefined

export const useOrganizationUsers = ({ includePending, includeRequests }: Props = {}) => {
    const { su } = useAuth()

    const queryParams = new URLSearchParams({
        ...(includePending && { includePending: includePending.toString() }),
        ...(includeRequests && { includeRequests: includeRequests.toString() }),
        ...(su && { orgId: su })
    }).toString()

    const { data, isValidating, mutate } = useQuery<UsersResponseType>(
        `/c/v1/org-users${queryParams ? `?${queryParams}` : ''}`,
        {
            method: 'GET'
        },
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    )

    return useMemo(
        () => ({
            orgUsers: data?.orgUsers || [],
            pendingUsers: data?.pendingUsers || [],
            pendingRequests: data?.pendingRequests || [],
            loadingOrgUsers: isValidating,
            refetchOrgUsers: () => mutate()
        }),
        [isValidating, mutate, data]
    )
}
