import { useState } from 'react'

import {
    type BoxProps,
    Box,
    ClickAwayListener,
    Tooltip,
    InputAdornment,
    TextField,
    Typography,
    Popover,
    styled
} from '@mui/material'

import { isColorLight } from 'modules'

export const initialColors = [
    '#ff5722',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#2c98f0',
    '#ffeb3b',
    '#c9de01',
    '#4caf50',
    '#009688',
    '#00bcd4',
    '#ffc107',
    '#ff9800',
    '#795548',
    '#9e9e9e',
    '#607d8b'
]

const ClickableBox = styled(Box)({
    borderRadius: '8px',
    cursor: 'pointer'
})

type Props = {
    currentColor: string
    hideCustomColors?: boolean
    setCurrentColor: (color: string) => void
    onSelect?: () => void
    preventEnterClick?: boolean
    additionalContent?: JSX.Element
    colorsLabel?: string
    additionalColorsLabel?: string
    customColors?: Array<string>
    additionalColors?: Array<string>
    onChange?: (color: string) => void
    height?: BoxProps['height']
    width?: BoxProps['width']
}

export const ColorPicker = ({
    currentColor,
    customColors,
    preventEnterClick,
    setCurrentColor,
    onChange,
    hideCustomColors = false,
    colorsLabel,
    height = '40px',
    width = '40px',
    onSelect = () => {},
    additionalColorsLabel,
    additionalColors = [],
    additionalContent
}: Props) => {
    const [anchorColorEl, setAnchorColorEl] = useState<HTMLElement | null>(null)
    const [customColor, setCustomColor] = useState('fefefe')

    const handleColorSelect = (color: string) => {
        const cleanedColor = `#${color?.replace('#', '')}` || currentColor

        setCurrentColor(cleanedColor)
        setAnchorColorEl(null)

        if (cleanedColor !== currentColor) onSelect?.()
    }

    const colors = customColors || initialColors

    return (
        <Box>
            <Tooltip title="Select color">
                <ClickableBox
                    width={width}
                    height={height}
                    style={{
                        background: currentColor,
                        border: '2px solid black'
                    }}
                    onClick={event => {
                        setAnchorColorEl(anchorColorEl ? null : event.currentTarget)
                    }}
                    data-test="select-color-button"
                />
            </Tooltip>

            <Popover
                open={Boolean(anchorColorEl)}
                anchorEl={anchorColorEl}
                disablePortal={true}
                style={{
                    zIndex: 1300,
                    marginLeft: '40px',
                    borderRadius: '16px',
                    overflow: 'hidden'
                }}
            >
                <ClickAwayListener onClickAway={() => setAnchorColorEl(null)}>
                    <Box
                        p={1}
                        sx={theme => ({
                            width: '256px',
                            background: 'white',
                            boxShadow: theme.shadows[10],
                            zIndex: 1
                        })}
                    >
                        {additionalColorsLabel && (
                            <Typography variant="caption" color="textSecondary">
                                {additionalColorsLabel}
                            </Typography>
                        )}
                        <Box display="flex" flexWrap="wrap">
                            {additionalColors.map(color => (
                                <ClickableBox
                                    key={color}
                                    m={0.5}
                                    onClick={() => handleColorSelect(color)}
                                    bgcolor={color}
                                    width={40}
                                    border={isColorLight(color) ? '1px solid grey' : ''}
                                    height={40}
                                    sx={{
                                        border: currentColor === color ? '2px solid black' : 'none'
                                    }}
                                    data-test="additional-color"
                                />
                            ))}
                        </Box>

                        {colorsLabel && (
                            <Typography variant="caption" color="textSecondary">
                                {colorsLabel}
                            </Typography>
                        )}
                        <Box display="flex" flexWrap="wrap">
                            {colors.map(color => (
                                <ClickableBox
                                    key={color}
                                    m={0.5}
                                    onClick={() => handleColorSelect(color)}
                                    bgcolor={color}
                                    width={40}
                                    height={40}
                                    border={isColorLight(color) ? '1px solid grey' : ''}
                                    sx={{
                                        border: currentColor === color ? '2px solid black' : 'none'
                                    }}
                                    data-test="additional-color"
                                />
                            ))}
                            {!hideCustomColors && (
                                <Box mt={2} mb={1}>
                                    <Box
                                        my={1}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-around"
                                    >
                                        <Box width="75%">
                                            <TextField
                                                value={customColor}
                                                onKeyPress={e => {
                                                    if (e.key === 'Enter') {
                                                        if (preventEnterClick) {
                                                            setAnchorColorEl(null)
                                                            e.preventDefault()
                                                            return false
                                                        }

                                                        handleColorSelect(customColor)
                                                    }
                                                }}
                                                onChange={({ target: { value } }) => {
                                                    onChange?.('#' + value)
                                                    setCustomColor(value)
                                                }}
                                                inputProps={{
                                                    maxLength: 6
                                                }}
                                                sx={{
                                                    '& .MuiFilledInput-root': {
                                                        background: 'red'
                                                    }
                                                }}
                                                variant="outlined"
                                                label="Custom color (HEX)"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            #
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box>
                                        <ClickableBox
                                            width={40}
                                            height={40}
                                            style={{
                                                backgroundColor: `#${
                                                    customColor?.replace('#', '') || '#fefefe'
                                                }`,
                                                border: '2px solid black'
                                            }}
                                            onClick={() => handleColorSelect(customColor)}
                                        />
                                    </Box>
                                </Box>
                            )}
                            {additionalContent && (
                                <Box onClick={() => setAnchorColorEl(null)}>
                                    {additionalContent}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </Popover>
        </Box>
    )
}
