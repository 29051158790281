import { type CSSProperties } from 'react'

import { type FieldProps, Field } from 'formik'

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    Typography,
    useTheme
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { SpacedGroup } from 'UI/Components'
import { type OptionType } from 'modules'

const PREFIX = 'ConnectedRadio'

const classes = {
    helperText: `${PREFIX}-helperText`,
    multipleControlLabel: `${PREFIX}-multipleControlLabel`,
    singleControlLabel: `${PREFIX}-singleControlLabel`,
    radioGroup: `${PREFIX}-radioGroup`,
    radio: `${PREFIX}-radio`
}

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    [`& .${classes.helperText}`]: {
        color: theme.palette.error.main
    },

    [`& .${classes.multipleControlLabel}`]: {
        margin: 0,
        marginRight: '24px'
    },

    [`& .${classes.singleControlLabel}`]: {
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    [`& .${classes.radioGroup}`]: {
        flexDirection: 'row'
    },

    [`& .${classes.radio}`]: {
        padding: 0
    }
}))

type ConnectedRadioProps = {
    name: string
    value: string
    label?: string
    disabled?: boolean
}

export const ConnectedRadio = ({ name, value, label, disabled }: ConnectedRadioProps) => {
    return (
        <Field name={name} value={value}>
            {({ field, form: { touched, errors } }: FieldProps) => (
                <>
                    <FormControlLabel
                        {...field}
                        value={value}
                        checked={field.value === value}
                        label={label}
                        control={<Radio className={classes.radio} />}
                        className={classes.singleControlLabel}
                        disabled={disabled}
                    />

                    {touched[name] && errors[name] && (
                        <FormHelperText className={classes.helperText}>
                            {errors[name] as string}
                        </FormHelperText>
                    )}
                </>
            )}
        </Field>
    )
}

type ConnectedRadioGroupProps = {
    options: Array<OptionType>
    name: string
    validValue?: string
    showErrors?: boolean
    spacing?: number
    checkboxSpacing?: number
    optionFontSize?: CSSProperties['fontSize']
    optionFontWeight?: CSSProperties['fontWeight']
}

export const ConnectedRadioGroup = ({
    name,
    options,
    spacing = 2,
    optionFontSize = 'inherit',
    optionFontWeight = 'inherit',
    checkboxSpacing = 1,
    showErrors = true
}: ConnectedRadioGroupProps) => {
    const theme = useTheme()

    return (
        <Field name={name} options={options}>
            {({ field, form: { touched, errors } }: FieldProps) => (
                <StyledRadioGroup {...field}>
                    <SpacedGroup spacing={spacing}>
                        {options.map((option, idx) => (
                            <FormControlLabel
                                key={idx}
                                value={option.value}
                                control={
                                    <Radio
                                        className={classes.radio}
                                        color="primary"
                                        style={{
                                            marginRight: theme.spacing(checkboxSpacing)
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        style={{
                                            fontSize: optionFontSize,
                                            fontWeight: optionFontWeight
                                        }}
                                        color={
                                            field.value === option.value
                                                ? 'inherit'
                                                : 'textSecondary'
                                        }
                                    >
                                        {option.label}
                                    </Typography>
                                }
                                className={classes.multipleControlLabel}
                            />
                        ))}
                    </SpacedGroup>

                    {touched[name] && errors[name] && showErrors && (
                        <FormHelperText className={classes.helperText}>
                            {errors[name] as string}
                        </FormHelperText>
                    )}
                </StyledRadioGroup>
            )}
        </Field>
    )
}
