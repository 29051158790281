import { useEffect, useState } from 'react'

import clsx from 'clsx'

import {
    Avatar,
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    useMediaQuery,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'

import { ReactComponent as CollapsedNav } from 'assets/icons/collapsedNav.svg'

import { TextOverflow } from '@guidde-co/shared.textoverflow'
import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import { StartUsingMessage } from 'UI/Routes/main/common'
import { getStepText, SpacedGroup, VisibilitySensor } from 'UI/Components'

import { type QuickGuiddeType } from 'app/types'

const PREFIX = 'QGNavigation'

const classes = {
    pointer: `${PREFIX}-pointer`,
    listItem: `${PREFIX}-listItem`,
    navContainer: `${PREFIX}-navContainer`,
    navHeader: `${PREFIX}-navHeader`,
    playIcon: `${PREFIX}-playIcon`,
    stepText: `${PREFIX}-stepText`,
    selectedItem: `${PREFIX}-selectedItem`,
    playAvatar: `${PREFIX}-playAvatar`,
    selectedPlayAvatar: `${PREFIX}-selectedPlayAvatar`,
    listWithScroll: `${PREFIX}-listWithScroll`,
    heightWithoutStartUsing: `${PREFIX}-heightWithoutStartUsing`,
    heightWithStartUsing: `${PREFIX}-heightWithStartUsing`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.pointer}`]: {
        cursor: 'pointer'
    },

    [`& .${classes.listItem}`]: {
        borderRadius: '4px'
    },

    [`&.${classes.navContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        position: 'sticky',
        overflow: 'hidden',
        backgroundColor: 'white',
        border: borderValue,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },

    [`& .${classes.navHeader}`]: {
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '14px',
        height: navHeader,
        borderTop: borderValue,
        borderBottom: borderValue
    },

    [`& .${classes.playIcon}`]: {
        width: '18px',
        height: '18px',
        color: '#1C1B1F',
        '&:hover': {
            color: 'white'
        }
    },

    [`& .${classes.stepText}`]: {
        fontWeight: 300,
        fontSize: '12px',
        color: '#000000'
    },

    [`& .${classes.selectedItem}`]: {
        backgroundColor: '#EDEEEF'
    },

    [`& .${classes.playAvatar}`]: {
        width: '24px',
        height: '24px',
        marginRight: '10px',
        backgroundColor: '#EDEEEF'
    },

    [`& .${classes.selectedPlayAvatar}`]: {
        backgroundColor: '#ffffff'
    },

    [`& .${classes.listWithScroll}`]: {
        overflowY: 'auto',
        fontSize: '0.875rem',
        '&::-webkit-scrollbar': {
            width: '20px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D8D8D8',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box'
        }
    },

    // Height of navigation without startUsing dialog = height of navigation header (44) + custom number (10)
    [`& .${classes.heightWithoutStartUsing}`]: {
        height: `calc(100% - ${navHeader + 10}px)`
    },

    [`& .${classes.heightWithStartUsing}`]: {
        transition: 'all 0.2s linear'
    }
}))

const pageHeader = 64
const drawerWidthMax = 326
const drawerWidthMin = 64
const navHeader = 44

const borderValue = '1px solid rgba(9, 12, 16, 0.08)'

type Props = {
    playbook: QuickGuiddeType
    onDrawerSizeChange?: (newSize: number) => void
    showStartUsing?: boolean
    videoPlayerIsReady?: boolean
    withoutTopBorder?: boolean
    hideGetStartedBanner?: boolean
    distanceFromTop?: number
}

export const QGNavigation = ({
    playbook,
    showStartUsing,
    withoutTopBorder,
    distanceFromTop = 0
}: Props) => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null)

    const isMinimalMenu = useBoolean()

    useEffect(() => {
        const selector = playbook.steps
            // step kind `end` doesn't exist in the navigation bar
            .filter(step => step.kind !== 'end')
            .map(it => `[id='${it.id}']`)
            .join(', ')

        if (!selector) return

        const list = Array.from(document?.querySelectorAll(selector))

        // add video element to list because in QG navigation first step is a `video`
        const videoPlayerEl = document.querySelector('video')
        if (videoPlayerEl) list.unshift(videoPlayerEl)

        const io = new IntersectionObserver(
            entries => {
                const el = entries.find(it => it.isIntersecting)

                if (el) {
                    // selected option can be string `video` (first step in navigation) or step id
                    const selectedVal = el.target.nodeName === 'VIDEO' ? 'video' : el.target.id

                    setSelectedOption(selectedVal)
                }
            },
            {
                threshold: 1
            }
        )

        list.forEach(el => io.observe(el))

        return () => {
            list.forEach(el => io.unobserve(el))
        }
    }, [playbook.steps])

    // special treatment for small height screens, all the rest works as expected
    const bigScreen = useMediaQuery('(min-height:720px)')

    return (
        <StyledBox
            className={classes.navContainer}
            borderTop={withoutTopBorder ? 'none !important' : borderValue}
            width={isMinimalMenu.isTrue ? drawerWidthMin : drawerWidthMax}
            sx={{
                height: `calc(100vh - ${distanceFromTop}px)`,
                top: distanceFromTop
            }}
        >
            {/* Closed menu */}
            <SpacedGroup flexDirection="column" display={isMinimalMenu.isTrue ? 'flex' : 'none'}>
                <SpacedGroup justifyContent="center" pt={1}>
                    <IconButton
                        onClick={isMinimalMenu.setFalse}
                        style={{ width: 36, height: 36 }}
                        size="large"
                    >
                        <CollapsedNav />
                    </IconButton>
                </SpacedGroup>
                <Box px={1} style={{ marginTop: '5px' }}>
                    <Divider />
                </Box>
            </SpacedGroup>

            {/* Opened menu */}
            <Box display={isMinimalMenu.isTrue ? 'none' : 'block'} height="100%">
                <SpacedGroup
                    px={2}
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.navHeader}
                    borderTop={withoutTopBorder ? 'none !important' : borderValue}
                >
                    Table of contents
                    <IconButton size="small" onClick={isMinimalMenu.setTrue}>
                        <CloseIcon />
                    </IconButton>
                </SpacedGroup>

                <Box
                    px={2}
                    className={clsx(classes.listItem, classes.listWithScroll, {
                        [classes.heightWithoutStartUsing]: !showStartUsing,
                        [classes.heightWithStartUsing]: showStartUsing
                    })}
                    sx={
                        // Height of navigation with startUsing dialog = height of navigation header (44) + custom number (380/240) according
                        // to screen height const bigScreen = useMediaQuery('(min-height:720px)')
                        showStartUsing
                            ? {
                                  height: `calc(100% - ${
                                      pageHeader + navHeader + (bigScreen ? 380 : 240)
                                  }px)`
                              }
                            : undefined
                    }
                >
                    <List className={classes.pointer}>
                        <Box
                            className={clsx(classes.listItem, {
                                [classes.selectedItem]: selectedOption === 'video'
                            })}
                        >
                            <ListItemButton
                                key="video"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    })

                                    setSelectedOption('video')
                                }}
                            >
                                <SpacedGroup alignContent="center">
                                    <Avatar
                                        className={clsx(classes.playAvatar, {
                                            [classes.selectedPlayAvatar]: selectedOption === 'video'
                                        })}
                                    >
                                        <PlayArrowOutlinedIcon
                                            className={classes.playIcon}
                                            data-test="scroll-to-video"
                                        />
                                    </Avatar>
                                    <Box>Video</Box>
                                </SpacedGroup>
                            </ListItemButton>
                        </Box>
                        {playbook.steps
                            .filter(step => step.kind === 'step')
                            .map((step, idx) => (
                                <VisibilitySensor
                                    key={step.id}
                                    placeholder={<Box height={40}>&nbsp;</Box>}
                                    threshold={0.01}
                                    delay={0}
                                >
                                    <Box
                                        key={step.id}
                                        className={clsx(classes.listItem, {
                                            [classes.selectedItem]: selectedOption === step.id
                                        })}
                                    >
                                        <ListItem
                                            button={true}
                                            className={classes.pointer}
                                            onClick={() => {
                                                const element = document.getElementById(step.id)

                                                if (element) {
                                                    element.scrollIntoView()
                                                }

                                                setSelectedOption(step.id)
                                            }}
                                        >
                                            <SpacedGroup>
                                                <Avatar
                                                    className={clsx(classes.playAvatar, {
                                                        [classes.selectedPlayAvatar]:
                                                            selectedOption === step.id
                                                    })}
                                                >
                                                    <Box className={classes.stepText}>
                                                        {(idx + 1).toString().padStart(2, '0')}
                                                    </Box>
                                                </Avatar>
                                                <TextOverflow lineClamp={1}>
                                                    {getStepText(step)}
                                                </TextOverflow>
                                            </SpacedGroup>
                                        </ListItem>
                                    </Box>
                                </VisibilitySensor>
                            ))}
                    </List>
                </Box>
                {showStartUsing && (
                    <>
                        <Box px={1} my={2}>
                            <Divider />
                        </Box>
                        <Box>
                            <StartUsingMessage playbook={playbook} />
                        </Box>
                    </>
                )}
            </Box>
        </StyledBox>
    )
}
