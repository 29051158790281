import { useMemo } from 'react'

import { useQuery } from './use-query'

type ConfigurationType = {
    authorizedData: {
        confettiTime: number
        welcomeVideoUrl: string
    }
    audio: {
        defaultAudioVolumeWithVO: number
        defaultAudioVolumeWithoutVO: number
    }
    glimpseUrl: {
        activityFeed: {
            url: string
        }
        insights: {
            url: string
        }
    }
    learnHowUrl: {
        footer: {
            url: string
        }
    }
    videoOverlayLinks: {
        links: {
            getStarted: string
            learnMore: string
            useGuiddeForFree: string
        }
    }
    maxAudioBackgroundSize: number
    maxVoiceOverPlaybookDuration: string
    pronunciationHighlightDelay: number
    publicData: {
        customerFeedbacks: {
            [key: string]: {
                avatar: string
                logo: string
                text: string
                title: string
                userName: string
                userPosition: string
            }
        }
    }
    pptImport: {
        maxFileSizeInMb: number
        maxSlidesCount: number
    }
    quickGuidde: {
        stepsLimit: number
    }
    maxCustomVoiceOverAudioSizeInMB: number
    maxStepVideoUploadSize: number
}

type ConfigurationPublicType = ConfigurationType['publicData']

export const usePublicConfiguration = () => {
    const $configuration = useQuery<ConfigurationPublicType>(
        `/c/v1/configuration-public`,
        {
            method: 'GET'
        },
        {
            revalidateOnFocus: false,
            dedupingInterval: 3600000 // 1 hour
        }
    )

    return useMemo(
        () => ({
            isConfigurationLoading: $configuration.isValidating,
            ...($configuration.data || {})
        }),
        [$configuration.data, $configuration.isValidating]
    )
}

export const useConfiguration = () => {
    const $configuration = useQuery<ConfigurationType>(
        '/c/v1/configuration',
        {
            method: 'GET'
        },
        {
            revalidateOnFocus: false,
            dedupingInterval: 3600000 // 1 hour
        }
    )

    return useMemo(
        () => ({
            isConfigurationLoading: $configuration.isValidating,
            ...($configuration.data || {})
        }),
        [$configuration.data, $configuration.isValidating]
    )
}
