import { type ChangeEvent, type ReactNode, memo, useCallback, useMemo, useState } from 'react'

import {
    Box,
    Dialog,
    IconButton,
    LinearProgress,
    Typography,
    Tabs,
    Button,
    Tab,
    styled
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { ReactComponent as MedalIcon } from 'assets/icons/medal.svg'

import { type AnyPlaybookType, type PlaybookType, type QuickGuiddeType } from 'app/types'

import clsx from 'clsx'

import { useAuth, useBoolean, useQuery, useRoles, useServiceUsage } from 'hooks'
import { isQG, logToAnalytics, playbookToAnalyticsProps } from 'modules'

import {
    generatePlaybook,
    ShareLink,
    SpacedGroup,
    TabPanel,
    useCanEditPlaybook,
    VideoStatusProvider,
    refreshIntervals
} from 'UI/Components'

import { ShareTab } from './ShareTab'
import { ExportTab } from './ExportTab'
import { TrackTab } from './TrackTab'
import { SmartCopyTab } from './SmartCopyTab'

import { AdvancedSharingTab } from './AdvancedSharingTab'
import { AdvancedTrackingTab } from './AdvancedTrackingTab'

const PREFIX = 'SharePublishDialog'

const classes = {
    bold: `${PREFIX}-bold`,
    content: `${PREFIX}-content`,
    disabledTab: `${PREFIX}-disabledTab`,
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.bold}`]: {
        fontWeight: theme.typography.fontWeightBold
    },

    [`& .${classes.content}`]: {
        background: '#F5F5F5',
        borderRadius: 4
    },

    [`& .${classes.disabledTab}`]: {
        borderBottom: '1px solid #e2e2e3'
    },

    [`& .${classes.root}`]: {
        minWidth: 142
    },

    [`& .${classes.paper}`]: {
        minHeight: 530
    }
}))

type Props = {
    isOpen: boolean
    onClose: () => void
    onDone?: (playbookId?: string, newVisibility?: PlaybookType['visibility']) => void
    title: string
    playbook: AnyPlaybookType
    forcePlaybookLoading?: boolean
}

export const SharePublishDialog = memo(
    ({ isOpen, onClose, title, playbook, forcePlaybookLoading, onDone = () => {} }: Props) => {
        const canViewAllTabs = useCanEditPlaybook(playbook)

        const { isContentManager, isViewer } = useRoles()
        const { uid } = useAuth()

        const [tabValue, setTabValue] = useState(0)

        const loading = useBoolean()
        const showAdvancedSharing = useBoolean()
        const showAdvancedTracking = useBoolean()

        const { hasTrackLink, hasVideoExport } = useServiceUsage()

        const showTabs = showAdvancedSharing.isFalse && showAdvancedTracking.isFalse

        const shouldUseFullPb = forcePlaybookLoading || (isQG(playbook) && !playbook.steps?.length)

        // we are using this endpoint because in the library page we are using Algolia
        // which don't include step filed in the playbook object.
        const $fullPlaybook = useQuery<QuickGuiddeType>(
            shouldUseFullPb ? `/c/v1/quickguidde?id=${playbook.id}` : '',
            {
                method: 'GET'
            },
            {
                dedupingInterval: 0,
                focusThrottleInterval: 0,
                revalidateIfStale: true,
                refreshWhenHidden: true
            }
        )

        const fullPlaybook = shouldUseFullPb
            ? (generatePlaybook($fullPlaybook?.data || {}) as any as QuickGuiddeType)
            : playbook

        const revalidate = $fullPlaybook.mutate
        const handleRevalidate = useCallback(
            (playbookId?: string, newVisibility?: PlaybookType['visibility']) => {
                if (shouldUseFullPb) {
                    return revalidate()
                }
                return Promise.resolve(onDone(playbookId, newVisibility))
            },
            [shouldUseFullPb, revalidate, onDone]
        )

        const advancedProps = useMemo(
            () => ({
                onDone: handleRevalidate,
                playbook: fullPlaybook as PlaybookType,
                isLoadingData: $fullPlaybook.isValidating,
                onClose,
                setLoading: loading.set
            }),
            [fullPlaybook, handleRevalidate, loading.set, onClose, $fullPlaybook.isValidating]
        )

        const getTabProps = (value: number, label: ReactNode) => ({
            label,
            className: clsx(tabValue !== value && classes.disabledTab),
            value,
            classes: {
                root: classes.root
            }
        })

        const isOwner = playbook.creator_uid === uid

        if (shouldUseFullPb && !$fullPlaybook.data) return null

        return (
            <VideoStatusProvider
                playbook={playbook}
                validateGifProgress={true}
                queryParams={{
                    focusThrottleInterval: refreshIntervals.qg,
                    dedupingInterval: refreshIntervals.qg,
                    revalidateIfStale: true,
                    refreshWhenHidden: true
                }}
                revalidatePlaybook={handleRevalidate}
            >
                <StyledDialog
                    open={isOpen}
                    onClose={onClose}
                    fullWidth
                    maxWidth="sm"
                    scroll="paper"
                    classes={{
                        paper: classes.paper
                    }}
                >
                    <Box position="absolute" top={0} width="100%">
                        {(loading.isTrue || $fullPlaybook.isValidating) && <LinearProgress />}
                    </Box>

                    {showTabs && (
                        <>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                pt={2}
                                px={2}
                            >
                                <Typography className={classes.bold} data-test="share-dialog-title">
                                    {title}
                                </Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={onClose}
                                    data-test="share-dialog-close-button"
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <ShareLink
                                playbook={fullPlaybook}
                                disabled={!isContentManager && !isOwner}
                                onChange={handleRevalidate}
                            />

                            <Box
                                className={classes.content}
                                m={2}
                                minHeight={tabValue === 2 ? 'auto' : !isContentManager ? 380 : 320}
                            >
                                <Tabs
                                    value={tabValue}
                                    onChange={(_e: ChangeEvent<{}>, value: number) => {
                                        setTabValue(value)
                                    }}
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab
                                        {...getTabProps(0, 'SHARE')}
                                        onClick={() => {
                                            logToAnalytics('shareModal_shareTab_clicked', {
                                                playbookId: playbook.id,
                                                ...playbookToAnalyticsProps(playbook)
                                            })
                                        }}
                                    />
                                    {isQG(playbook) && (
                                        <Tab
                                            data-test="shareDialog-smart-copy-tab"
                                            data-intercom="tab-smart-copy"
                                            {...getTabProps(3, 'SMART COPY')}
                                            onClick={() => {
                                                logToAnalytics('shareModal_smartcopyTab_clicked', {
                                                    playbookId: playbook.id,
                                                    ...playbookToAnalyticsProps(playbook)
                                                })
                                            }}
                                        />
                                    )}

                                    {(canViewAllTabs || (!isContentManager && !isViewer)) && (
                                        <Tab
                                            data-test="share-dialog-track-tab"
                                            onClick={() => {
                                                logToAnalytics('shareModal_trackTab_clicked', {
                                                    playbookId: playbook.id,
                                                    ...playbookToAnalyticsProps(playbook)
                                                })
                                            }}
                                            {...getTabProps(
                                                1,
                                                <SpacedGroup spacing={1}>
                                                    {!hasTrackLink && <MedalIcon color="#cb0000" />}
                                                    TRACK
                                                </SpacedGroup>
                                            )}
                                        />
                                    )}
                                    {canViewAllTabs && (
                                        <Tab
                                            onClick={() => {
                                                logToAnalytics('shareModal_exportTab_clicked', {
                                                    playbookId: playbook.id,
                                                    ...playbookToAnalyticsProps(playbook)
                                                })
                                            }}
                                            data-test="share-dialog-export-tab"
                                            {...getTabProps(2, 'EXPORT')}
                                        />
                                    )}
                                </Tabs>
                                <TabPanel value={tabValue} index={0}>
                                    <ShareTab {...advancedProps} />
                                </TabPanel>
                                <TabPanel value={tabValue} index={3}>
                                    <SmartCopyTab playbook={fullPlaybook as QuickGuiddeType} />
                                </TabPanel>

                                <TabPanel value={tabValue} index={1} pb={0}>
                                    <TrackTab playbook={fullPlaybook} isLocked={!hasTrackLink} />
                                </TabPanel>

                                <TabPanel value={tabValue} index={2} keepInDOM={false}>
                                    <ExportTab
                                        playbook={fullPlaybook}
                                        hasVideoExport={hasVideoExport}
                                    />
                                </TabPanel>
                            </Box>
                            {canViewAllTabs && (
                                <>
                                    {tabValue === 0 && (
                                        <AdvancedButton
                                            onClick={() => {
                                                showAdvancedSharing.setTrue()

                                                logToAnalytics(
                                                    'shareModal_shareTab_manageSharing_clicked',
                                                    {
                                                        playbookId: playbook.id,
                                                        ...playbookToAnalyticsProps(playbook)
                                                    }
                                                )
                                            }}
                                        >
                                            MANAGE SHARING
                                        </AdvancedButton>
                                    )}
                                    {tabValue === 1 && hasTrackLink && (
                                        <AdvancedButton
                                            data-test="share-dialog-advanced-tracking"
                                            onClick={() => {
                                                showAdvancedTracking.setTrue()

                                                logToAnalytics(
                                                    'shareModal_trackTab_advancedTracking_clicked',
                                                    {
                                                        playbookId: playbook.id,
                                                        ...playbookToAnalyticsProps(playbook)
                                                    }
                                                )
                                            }}
                                            disabled={!playbook.isPublic}
                                        >
                                            ADVANCED TRACKING
                                        </AdvancedButton>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {showAdvancedSharing.isTrue && (
                        <AdvancedSharingTab
                            {...advancedProps}
                            onBack={showAdvancedSharing.setFalse}
                        />
                    )}
                    {showAdvancedTracking.isTrue && (
                        <AdvancedTrackingTab
                            {...advancedProps}
                            onBack={showAdvancedTracking.setFalse}
                        />
                    )}
                </StyledDialog>
            </VideoStatusProvider>
        )
    }
)

type AdvancedButtonProps = {
    children: string
    disabled?: boolean
    onClick: () => void
}

const AdvancedButton = ({ children, onClick, disabled }: AdvancedButtonProps) => (
    <Box mb={2} display="flex" justifyContent="center" alignItems="center" width="100%">
        <Button
            data-test="share-advanced-button"
            variant="text"
            color="secondary"
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Button>
    </Box>
)
