import { memo } from 'react'

import { Box, IconButton, useScrollTrigger } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { Steps } from './Steps'
import { type QuickGuiddeType } from 'app/types'

import { containsRTL, isQG } from 'modules'

type Props = {
    playbook: QuickGuiddeType
}

export const QGStepsWithScroll = memo(({ playbook }: Props) => {
    const showButton = useScrollTrigger({
        disableHysteresis: true,
        target: window ? window : undefined,
        threshold: window.innerHeight - 250
    })

    const { language } = playbook

    const isRtl = containsRTL(language?.langCode || '')

    const scrollPage = () => {
        window?.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    if (!isQG(playbook)) return null

    return (
        <Box dir={isRtl ? 'rtl' : 'ltr'}>
            <Box pb={1} width="100%" display="flex" justifyContent="center">
                <Steps playbook={playbook} isRtl={isRtl} />
            </Box>
            <IconButton
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 75,
                    display: showButton ? 'flex' : 'none',
                    background: '#DBDCDD'
                }}
                onClick={scrollPage}
                size="large"
            >
                <ExpandLessIcon />
            </IconButton>
        </Box>
    )
})
