import { memo, useContext, useEffect, useMemo, useRef } from 'react'

import {
    fragmentShaderSource,
    glPositions,
    QGContext,
    vertexShaderSource,
    TransitionContext
} from 'UI/Routes/quick-guidde/CanvasEditor'
import { type RTDBTransitionType } from 'UI/Routes/quick-guidde/LeftPanel/MotionPanel/TransitionsList'

import { useQuery } from 'hooks'
import { type StepType } from 'app/types'

type Props = {
    showTransition: boolean
    previousSlide?: HTMLCanvasElement
    currentSlide?: HTMLCanvasElement
    transition: StepType['transition']
}

export const TransitionSlide = memo(
    ({ showTransition, previousSlide, currentSlide, transition }: Props) => {
        const canvasRef = useRef<HTMLCanvasElement | null>(null)

        const { data } = useQuery<Array<RTDBTransitionType>>(
            '/c/v1/config/qg/transitions',
            { method: 'GET' },
            {
                revalidateIfStale: false,
                dedupingInterval: 0
            }
        )

        const { transitionProgress: percentage } = useContext(TransitionContext)
        const { frameWidth, frameHeight } = useContext(QGContext)

        const transitionCode = useMemo(() => {
            if (!transition || !data) return ''

            const option = data.find(item => item.name === transition.name)
            if (!option) return ''

            return 'direction' in option
                ? option.direction[transition.direction ?? 'left'].glsl
                : option.glsl
        }, [data, transition])

        useEffect(() => {
            if (!transitionCode) return

            const canvas = canvasRef.current
            if (!canvas) return

            const gl = canvas.getContext('webgl')
            if (!gl) return

            // Create shaders
            const vertexShader = gl.createShader(gl.VERTEX_SHADER)
            if (!vertexShader) return
            gl.shaderSource(vertexShader, vertexShaderSource)
            gl.compileShader(vertexShader)

            const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
            if (!fragmentShader) return
            gl.shaderSource(fragmentShader, fragmentShaderSource(transitionCode))
            gl.compileShader(fragmentShader)

            // Create program
            const program = gl.createProgram()
            if (!program) return
            gl.attachShader(program, vertexShader)
            gl.attachShader(program, fragmentShader)
            gl.linkProgram(program)
            gl.useProgram(program)
            // Set uniform locations
            const image1UniformLocation = gl.getUniformLocation(program, 'from')
            gl.uniform1i(image1UniformLocation, 0)
            const image2UniformLocation = gl.getUniformLocation(program, 'to')
            gl.uniform1i(image2UniformLocation, 1)

            // Create buffer
            const positionBuffer = gl.createBuffer()
            if (!positionBuffer) return
            gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(glPositions), gl.STATIC_DRAW)

            // Set up attribute and enable it
            const positionAttributeLocation = gl.getAttribLocation(program, 'position')
            gl.enableVertexAttribArray(positionAttributeLocation)
            gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0)

            // Set uniform locations and progress
            const progressULocation = gl.getUniformLocation(program, 'progress')
            gl.uniform1f(progressULocation, 0.0)
            gl.uniform1f(progressULocation, (percentage || 0) / 100)

            // Set active textures and bind them
            // Previous slide
            const image1Texture = gl.createTexture()
            if (!image1Texture) return
            gl.activeTexture(gl.TEXTURE0)
            gl.bindTexture(gl.TEXTURE_2D, image1Texture)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
            if (previousSlide) {
                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, previousSlide)
            } else {
                gl.texImage2D(
                    gl.TEXTURE_2D,
                    0,
                    gl.RGBA,
                    1,
                    1,
                    0,
                    gl.RGBA,
                    gl.UNSIGNED_BYTE,
                    new Uint8Array([0, 0, 0, 255])
                )
            }
            // Current slide
            const image2Texture = gl.createTexture()
            if (!image2Texture) return
            gl.activeTexture(gl.TEXTURE1)
            gl.bindTexture(gl.TEXTURE_2D, image2Texture)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
            if (currentSlide) {
                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, currentSlide)
            }

            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 6)
        }, [previousSlide, percentage, transitionCode, currentSlide, frameWidth, frameHeight])

        if (!currentSlide || !transition) return null

        return (
            <canvas
                ref={canvasRef}
                width={currentSlide!.width}
                height={currentSlide!.height}
                style={{
                    width: frameWidth,
                    height: frameHeight,
                    backgroundColor: 'black',
                    display: !showTransition || percentage === 100 ? 'none' : 'block'
                }}
            />
        )
    }
)
