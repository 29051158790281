import { type ReactNode, memo, useCallback } from 'react'

import Box from '@mui/material/Box'

import { type SectionType } from './types'
import { HoverBar } from './hover-bar'
import { ActiveBar } from './active-bar'
import { useBoolean } from 'hooks'

const mapSectionsToWidths = (sections: Array<{ rangeValues: Array<number> }>) =>
    sections.map(section => section.rangeValues?.[1] - section.rangeValues?.[0])

type VideoSectionType = SectionType & {
    rangeValues: Array<number>
    isDeleted?: boolean
}

export type VideoSectionProps = {
    /**
     * index of the section
     */
    index: number
    /**
     * jump to specific percentage of the video
     */
    onUpdateTime: (_percentage: number) => void
    /**
     * Video progress percentage
     */
    videoProgress: number
    /**
     * Video tag
     */
    videoElement: HTMLVideoElement
    /**
     * Sections data that includes the start and end time, thumbnail, tags and applications
     */
    sections: Array<VideoSectionType>
    /**
     * is edit or view mode
     */
    isEditMode?: boolean
    /**
     * delete section button
     */
    deleteButton?: ReactNode
    /**
     * button to merge current section with the next one
     */
    mergeButton?: ReactNode
    /**
     * slider to change current section width
     */
    sliderButton?: ReactNode
    /**
     * on click handler to split current section at the active time point
     * @param _percentage
     */
    onSlice?: (_percentage: number) => void
}

export const VideoSection = memo(
    ({
        index,
        isEditMode,
        sections,
        onSlice,
        onUpdateTime,
        videoProgress,
        videoElement,
        deleteButton,
        mergeButton,
        sliderButton
    }: VideoSectionProps) => {
        const isFirst = index === 0
        const isLast = index === sections.length - 1
        const isHidden = isFirst || isLast
        const isDeleted = sections[index]?.isDeleted ?? false

        const width = sections[index].rangeValues?.[1] - sections[index].rangeValues?.[0]

        const widths = mapSectionsToWidths(sections)

        const totalPrevWidth = widths.slice(0, index).reduce((acc, cur) => acc + cur, 0)
        // We need to know which percent of current section is progressed based on videoProgress + width (full section percentage)
        const calculateSectionProgress = useCallback(() => {
            const leftProgress = videoProgress - totalPrevWidth

            return leftProgress >= width
                ? 100
                : leftProgress <= 0
                  ? 0
                  : (leftProgress / width) * 100
        }, [totalPrevWidth, videoProgress, width])

        const sectionProgress = calculateSectionProgress()

        const pointed = useBoolean()

        return (
            <Box
                /* className={classes.wrapper} */
                sx={{
                    width: `${width}%`,
                    paddingBottom: isEditMode ? '35px' : 0
                }}
                onPointerEnter={pointed.setTrue}
                onPointerLeave={pointed.setFalse}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '10px',
                        margin: '0 1px',
                        position: 'relative',
                        boxSizing: 'border-box',
                        background: isHidden
                            ? 'transparent'
                            : isDeleted
                              ? 'linear-gradient(-45deg, rgba(149, 146, 146, 0.6) 7.5%, rgba(255, 255, 255, 0.6) 7.5%, rgba(255, 255, 255, 0.6) 42.5%, rgba(149, 146, 146, 0.6) 42.5%, rgba(149, 146, 146, 0.6) 57.5%, rgba(255, 255, 255, 0.6) 57.5%, rgba(255, 255, 255, 0.6) 92.5%, rgba(149, 146, 146, 0.6) 92.5%) 0% 0% / 10px 10px'
                              : '#828282'
                    }}
                >
                    {!isHidden && pointed.isTrue && (
                        <HoverBar
                            duration={videoElement?.duration || 0}
                            onClick={onUpdateTime}
                            width={width}
                            totalPrevWidth={totalPrevWidth}
                            index={index}
                            totalSections={sections.length - 2} // hidden section
                            section={sections[index]}
                        />
                    )}

                    <ActiveBar
                        progress={isHidden ? 0 : sectionProgress} // percent of current slice
                        background={
                            isHidden
                                ? 'transparent'
                                : isDeleted
                                  ? 'linear-gradient(-45deg, rgba(149, 146, 146, 0.6) 7.5%, rgba(255, 255, 255, 0.6) 7.5%, rgba(255, 255, 255, 0.6) 42.5%, rgba(149, 146, 146, 0.6) 42.5%, rgba(149, 146, 146, 0.6) 57.5%, rgba(255, 255, 255, 0.6) 57.5%, rgba(255, 255, 255, 0.6) 92.5%, rgba(149, 146, 146, 0.6) 92.5%) 0% 0% / 10px 10px'
                                  : '#CB0000'
                        }
                        onSlice={() => onSlice?.((width / 100) * sectionProgress)}
                        showSlice={
                            isEditMode &&
                            pointed.isTrue &&
                            (width / 100) * sectionProgress >= 4 && // in order to create a new slice it should be
                            (width / 100) * sectionProgress <= 96 // at least 4% from total duration
                        }
                        showEnd={
                            (!isFirst &&
                                sectionProgress > 0 &&
                                Math.round(sectionProgress) < 100) ||
                            (index === 1 && sectionProgress === 0) ||
                            (isLast && Math.round(sectionProgress) === 100)
                        }
                    />

                    {!isHidden && isEditMode && (
                        <Box
                            sx={{
                                textAlign: 'center',
                                position: 'absolute',
                                fontSize: '10px',
                                left: '4px',
                                top: '-1px'
                            }}
                        >
                            <b>{index}</b>
                        </Box>
                    )}

                    {/*Button to delete (disable) current slice*/}
                    {pointed.isTrue && deleteButton}

                    {/*Button to change section width on mouse move*/}
                    {sliderButton}

                    {/*Button to merge with the next section*/}
                    {mergeButton}
                </Box>
            </Box>
        )
    }
)
