import CallToActionIcon from '@mui/icons-material/CallToAction'

import { HoverButton } from '../HoverButton'
import { CtaDialog } from './CtaDialog'

import { useBoolean } from 'hooks'
import { type PlaybookType } from 'app/types'

type Props = {
    playbook: PlaybookType
}

export const CtaControl = ({ playbook }: Props) => {
    const open = useBoolean()

    return (
        <>
            <HoverButton
                icon={<CallToActionIcon style={{ color: 'white' }} />}
                title="Call to action"
                onClick={open.setTrue}
            />

            {open.isTrue && <CtaDialog isOpen={true} onClose={open.setFalse} playbook={playbook} />}
        </>
    )
}
