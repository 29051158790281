import { useCallback, useMemo, useState } from 'react'

export const useFilterState = (initialState: Array<string> = []) => {
    const [state, setState] = useState<Array<string>>(initialState)

    const updateState = useCallback((value: Array<string>) => {
        setState(value)
    }, [])

    const resetState = useCallback(() => {
        setState(initialState)
    }, [initialState, setState])

    return useMemo(() => ({ state, updateState, resetState }), [resetState, state, updateState])
}

export type UseFilterStateType = ReturnType<typeof useFilterState>
