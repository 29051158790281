import { useEffect, useMemo, memo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import { HighlightedSegment } from '@guidde-co/shared.transcript.highlighted-segment'
import { VirtualScroll } from '@guidde-co/shared.transcript.virtual-scroll'

import { logToAnalytics, playbookToAnalyticsProps } from 'modules'

import { type PlaybookType, type VideoRefType } from 'app/types'

import {
    type SegmentType,
    type Subtitle,
    type TranscriptRange,
    type TranscriptType
} from 'UI/Components/Transcript'

/*
    Generate PlaylistTranscript Ranges which is an array of objects, for example:
    [{
        start :0,
        finish: 4.8
    }]
    This is used to calculate the active subtitle and add to it a css red background
    in DictionaryWrapper transcriptText css class
*/

const generateTranscriptRanges = (transcript: Array<SegmentType>) => {
    if (!transcript) return []

    const res: Array<TranscriptRange> = []
    transcript.forEach((segment: SegmentType) => {
        segment.subtitles.forEach((subtitle: Subtitle) => {
            res.push({
                start: subtitle.start,
                finish: subtitle.start + subtitle.duration
            })
        })
    })
    return res
}

const checkActiveSegment = (
    realVideoTime: number,
    transcriptRanges: Array<TranscriptRange>,
    idx: number
) => {
    if (!transcriptRanges[idx]) return false

    return (
        transcriptRanges[idx].start < realVideoTime && transcriptRanges[idx].finish > realVideoTime
    )
}

/*
    extract all the subtitles from all the segments into
    a single array and add to each subtitle segmentIndex and subtitleIndex
    that will be used later in edit mode
*/
const extractSubtitles = (transcript: Array<SegmentType>) => {
    if (!transcript) return []

    const res: Array<Subtitle> = []
    transcript.forEach((segment: SegmentType, index: number) => {
        segment.subtitles.forEach((subtitle: Subtitle, subTitleIndex: number) => {
            res.push({
                text: subtitle.text,
                start: subtitle.start,
                duration: subtitle.duration,
                segmentIndex: index,
                subtitleIndex: subTitleIndex
            })
        })
    })
    return res
}

const MIN_SEARCH_LENGTH = 2

type Props = {
    playbook: PlaybookType
    transcript: TranscriptType
    videoRef: VideoRefType
    heightFromTop?: number
    searchString: string
    maxViewHeight?: number
    handleSegmentClick: (item: Subtitle) => void
}

export const PlaylistTranscript = memo(
    ({
        heightFromTop = 0,
        handleSegmentClick,
        playbook,
        transcript,
        maxViewHeight = 100,
        searchString
    }: Props) => {
        const realVideoTime = useSelector(state => state.videoEditor.currentTime || 0)

        const activeSegmentRef = useRef(0)

        const virtuoso = useRef<any | null>(null)

        const subtitles = useMemo(() => {
            return extractSubtitles(transcript?.segments || [])
        }, [transcript?.segments])

        const transcriptRanges = generateTranscriptRanges(transcript?.segments || [])

        useEffect(() => {
            if (searchString?.length > 0) return

            const ind = subtitles?.findIndex((_: any, idx: number) =>
                checkActiveSegment(realVideoTime, transcriptRanges, idx)
            )

            if (ind === -1) return

            const activeIndex = ind === undefined || ind < 0 ? 0 : ind

            if (activeSegmentRef.current !== activeIndex) {
                activeSegmentRef.current = activeIndex
                virtuoso?.current?.scrollToIndex({
                    index: activeIndex,
                    align: 'start',
                    behavior: 'auto'
                })
            }
        }, [realVideoTime, transcriptRanges, transcript, subtitles, searchString?.length])

        const filteredSubtitles = useMemo(() => {
            // For empty search string or search length under MIN_SEARCH_LENGTH return all the subtitles
            if (searchString?.length <= MIN_SEARCH_LENGTH) return subtitles

            // limit search results to 50 items
            const res = subtitles
                .filter(item => item.text.toLowerCase().includes(searchString?.toLowerCase()))
                .slice(0, 50)

            logToAnalytics(res?.length ? 'search' : 'search-no-results', {
                text: searchString?.toLowerCase(),
                hits: res?.length,
                inVideo: true,
                ...playbookToAnalyticsProps(playbook)
            })

            return res
        }, [searchString, subtitles, playbook])

        if (subtitles?.length === 0) {
            return (
                <Typography>
                    There is no transcript for this video. Please check again later.
                </Typography>
            )
        }

        return (
            <>
                {filteredSubtitles?.length === 0 && (
                    <Typography>We could not find a match for "{searchString}"</Typography>
                )}

                <VirtualScroll
                    virtuoso={virtuoso}
                    maxViewHeight={maxViewHeight}
                    heightFromTop={heightFromTop}
                    data={filteredSubtitles}
                    itemContent={(idx: number, subtitle: Subtitle) => (
                        <Box key={idx}>
                            <HighlightedSegment
                                searchWords={
                                    searchString?.length <= MIN_SEARCH_LENGTH ? [] : [searchString]
                                }
                                subtitle={subtitle}
                                onClick={() => handleSegmentClick(subtitle)}
                                isActive={checkActiveSegment(realVideoTime, transcriptRanges, idx)}
                            />
                            {idx === filteredSubtitles.length - 1 && <Box height={70} />}
                        </Box>
                    )}
                />
            </>
        )
    }
)
