import { useHistory, Link, useLocation } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import clsx from 'clsx'

import {
    SwipeableDrawer,
    List,
    ListItem,
    AppBar,
    Toolbar,
    IconButton,
    Divider,
    Box,
    ListItemText,
    Avatar,
    ListItemButton,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'

import GuiddeLogoRed from 'assets/icons/guidde_red.svg'
import { ReactComponent as ExitIcon } from 'assets/icons/exit.svg'

import { MobileItemBody } from './common'
import { SpaceMenuItem } from './SpaceMenuItem'

import { SpacedGroup } from 'UI/Components'
import { PublicUtmButtons } from 'UI/Routes/share/common'

import { useBoolean, useAuth, useRoles } from 'hooks'
import { logToAnalytics, signOutGapi } from 'modules'

import { type MenuItemType, MENU_MOBILE } from 'app/menu'
import { paths } from 'app/paths'
import { type AnyPlaybookType } from 'app/types'

import { MobileWorkspacePanel } from './WorkspaceControlPanel'

const PREFIX = 'MobileDrawer'

const classes = {
    toolbar: `${PREFIX}-toolbar`,
    header: `${PREFIX}-header`,
    external: `${PREFIX}-external`,
    item: `${PREFIX}-item`,
    paper: `${PREFIX}-paper`,
    link: `${PREFIX}-link`,
    activeItem: `${PREFIX}-activeItem`,
    toggle: `${PREFIX}-toggle`,
    logoContainer: `${PREFIX}-logoContainer`,
    avatarImg: `${PREFIX}-avatarImg`,
    name: `${PREFIX}-name`,
    userInfo: `${PREFIX}-userInfo`
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    [`& .${classes.toolbar}`]: theme.mixins.toolbar,

    [`& .${classes.header}`]: {
        position: 'relative',
        zIndex: theme.zIndex.drawer + 1,
        display: 'flex',
        justifyContent: 'center',
        height: 55
    },

    [`& .${classes.external}`]: {
        justifyContent: 'space-between'
    },

    [`& .${classes.toggle}`]: {
        position: 'absolute',
        left: '20px'
    },

    [`& .${classes.logoContainer}`]: {
        backgroundColor: '#fefefe',
        height: '100%'
    }
}))

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    [`& .${classes.item}`]: {
        padding: theme.spacing(1, 3)
    },

    [`&.${classes.link}`]: {
        textDecoration: 'none',
        color: theme.palette.text.secondary
    },

    [`& .${classes.paper}`]: {
        width: 300,
        background: 'rgba(245, 245, 245, 1)',
        position: 'relative',
        zIndex: 0
    },

    [`& .${classes.avatarImg}`]: {
        height: 40,
        width: 40,
        borderRadius: '50%'
    },

    [`& .${classes.name}`]: {
        fontSize: 16,
        fontWeight: 500
    },

    [`& .${classes.userInfo}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 12
    }
}))

type Props = {
    isPublicPage?: boolean
    customLogo?: string
    customBackground?: string
    playbook?: AnyPlaybookType
}

export const MobileDrawer = ({ isPublicPage, customLogo, customBackground, playbook }: Props) => {
    const open = useBoolean()

    const history = useHistory()
    const location = useLocation()

    const { displayName, email, photoURL } = useAuth()

    const { userRoles } = useRoles()
    const { uid, isNotLogged } = useAuth()

    const currentLink = location?.pathname

    const Icon = open.isTrue ? CloseIcon : MenuIcon

    const logOut = () => {
        logToAnalytics('logout')
        signOutGapi(uid)
        signOut(getAuth()).then(() => history.push(paths.login))
    }

    if (isNotLogged && !isPublicPage) return null

    return (
        <StyledAppBar position="fixed">
            <Toolbar
                disableGutters={true}
                className={clsx(classes.header, classes.toolbar, {
                    [classes.external]: isNotLogged
                })}
                sx={{ backgroundColor: customBackground || '#fefefe' }}
                variant="dense"
            >
                {!isNotLogged && (
                    <IconButton
                        color="inherit"
                        className={clsx({
                            [classes.toggle]: !isPublicPage
                        })}
                        edge="start"
                        onClick={open.toggle}
                        size="large"
                    >
                        <Icon color="primary" />
                    </IconButton>
                )}

                <Box
                    className={classes.logoContainer}
                    px={3}
                    display="flex"
                    alignItems="center"
                    onClick={() => history.push(paths.index)}
                >
                    <img
                        src={customLogo || GuiddeLogoRed}
                        style={{
                            maxWidth: '98px',
                            height: '32px',
                            objectFit: 'contain'
                        }}
                        alt="logo"
                    />
                </Box>
                {isNotLogged && playbook && (
                    <Box mr={2}>
                        <PublicUtmButtons playbook={playbook} />
                    </Box>
                )}
            </Toolbar>

            <StyledSwipeableDrawer
                anchor="left"
                ModalProps={{
                    keepMounted: true
                }}
                onOpen={open.setTrue}
                open={open.isTrue}
                onClose={open.setFalse}
                classes={{
                    paper: classes.paper
                }}
                style={{
                    minWidth: 300
                }}
            >
                <Box p={3} display="flex" justifyContent="center">
                    <img
                        style={{
                            width: '100px'
                        }}
                        src={GuiddeLogoRed}
                        alt="red logo"
                    />
                </Box>
                <Divider />

                <MobileWorkspacePanel />
                <Divider />
                <ListItem data-test="menu-user" button key="a">
                    <SpacedGroup spacing={1}>
                        <Avatar
                            className={classes.avatarImg}
                            src={`${photoURL}&time=${new Date()}`}
                        />
                        <TextOverflow>
                            <ListItemText
                                classes={{
                                    primary: clsx(classes.name, classes.userInfo),
                                    secondary: classes.userInfo
                                }}
                                primary={displayName || email}
                                secondary={displayName === email ? '' : email}
                            />
                        </TextOverflow>
                    </SpacedGroup>
                </ListItem>
                <Divider />
                <List>
                    {MENU_MOBILE.map(link => {
                        if (Array.isArray(link.role) && link.role.every(r => !userRoles?.[r])) {
                            return null
                        }

                        const isSpaceLink = link.path === paths.spaces
                        if (isSpaceLink) {
                            return <SpaceMenuItem key={link.path} onClick={open.setFalse} />
                        }

                        return (
                            <CustomizedLink
                                key={link.path}
                                link={link}
                                currentLink={currentLink}
                                onClick={open.setFalse}
                            />
                        )
                    })}
                    {!isNotLogged && (
                        <>
                            <Divider />
                            <ListItemButton
                                className={classes.item}
                                color="primary"
                                onClick={logOut}
                            >
                                <MobileItemBody Icon={ExitIcon} text="Logout" />
                            </ListItemButton>
                        </>
                    )}
                </List>
            </StyledSwipeableDrawer>
        </StyledAppBar>
    )
}

type CustomizedLinkProps = {
    link: MenuItemType
    currentLink: string
    onClick: () => void
}

export const CustomizedLink = ({ link, currentLink, onClick }: CustomizedLinkProps) => {
    const isActive =
        currentLink === link.path || link.path.split('/')[1] === currentLink.split('/')[1]

    const Icon = link.startIcon

    return (
        <Link
            to={{
                pathname: link.path,
                state: {
                    prevPath: location.pathname
                }
            }}
            onClick={onClick}
            className={classes.link}
            style={{ textDecoration: 'none' }}
        >
            <ListItem
                className={clsx(classes.item, {
                    [classes.activeItem]: isActive
                })}
            >
                <MobileItemBody Icon={Icon} text={link.title} isActive={isActive} />
            </ListItem>
        </Link>
    )
}
