import { Link } from 'react-router-dom'

import { Box } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

type Props = {
    pageLink: {
        pathname: string
        state: {
            prevRoute: string
        }
    }
    iconColor?: string
}

export const NewBrowserTabLink = ({ pageLink, iconColor = '#c9d3d7' }: Props) => (
    <Link to={pageLink} target="_blank" title="Open link in a new tab">
        <Box ml={2}>
            <OpenInNewIcon style={{ fill: iconColor }} />
        </Box>
    </Link>
)
