import { memo } from 'react'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { Avatar, Box, Stack, Typography } from '@mui/material'

import { type UserOption } from './types'

type Props = {
    user: UserOption
}

export const Option = memo(({ user, ...props }: Props) => (
    <Stack spacing={1} direction="row" alignItems="center" {...props}>
        <Avatar src={user.logo} />
        <Box>
            <TextOverflow lineClamp={1}>
                <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                    {user.displayName || user.email}
                </Typography>
            </TextOverflow>
            <TextOverflow lineClamp={1}>
                <Typography style={{ fontSize: 14 }} color="textSecondary">
                    {!user.displayName ? user.email : ''}
                </Typography>
            </TextOverflow>
        </Box>
    </Stack>
))
