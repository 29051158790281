import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import type Konva from 'konva'
import { Group, Path, Rect } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { type ArrowShapeType } from 'app/types'

import { svgArrowData, svgArrowSize } from './svg-arrow-data'

import { setLayerTransform } from 'ducks'
import { round } from 'modules'

type Props = {
    layer: ArrowShapeType
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const ArrowShape = ({ layer, onDragStart, onDragMove, onDragEnd, onSelect }: Props) => {
    const dispatch = useDispatch()

    const shapeRef = useRef<Konva.Group>(null)

    const [arrowProps, setArrowProps] = useState(layer)

    useEffect(() => {
        setArrowProps(layer)
    }, [layer])

    return (
        <Group
            id={arrowProps.id}
            name="arrow"
            className="alignable"
            ref={shapeRef}
            x={arrowProps.x}
            y={arrowProps.y}
            scaleX={arrowProps.scaleX}
            scaleY={arrowProps.scaleY}
            rotation={arrowProps.rotation}
            draggable={true}
            onDragStart={onDragStart}
            onDragMove={onDragMove}
            onDragEnd={onDragEnd}
            onMouseOver={() => (document.body.style.cursor = 'move')}
            onMouseLeave={() => (document.body.style.cursor = '')}
            onClick={e => onSelect(e.evt.shiftKey)}
            onTransformEnd={() => {
                const node = shapeRef.current
                dispatch(
                    setLayerTransform({
                        layerId: layer.id,
                        x: round(node!.x()),
                        y: round(node!.y()),
                        scaleX: round(node!.scaleX()),
                        scaleY: round(node!.scaleY()),
                        rotation: node!.rotation()
                    })
                )
            }}
        >
            <Path
                x={0}
                y={0}
                stroke={arrowProps.strokeColor}
                fill={arrowProps.fill}
                data={svgArrowData[arrowProps.dataKey]}
            />
            {/*Transparent rectangle is used to increase the hit area*/}
            <Rect
                x={0}
                y={0}
                width={svgArrowSize.width}
                height={svgArrowSize.height}
                fill="transparent"
            />
        </Group>
    )
}
