import { useEffect } from 'react'

import { addReferralScripts, initReferralProgram, logoutReferral } from 'modules'

import { useAuth } from './use-auth'
import { useOrgFeatureFlags } from './use-feature-flags'
import { planTypes, useServiceUsage } from './use-service-usage'

export const useReferralProgram = () => {
    const { email, displayName, token } = useAuth()
    const { showReferralLink, isLoading } = useOrgFeatureFlags()
    const { isLoading: isServiceUsageLoading, activePlanId } = useServiceUsage()

    useEffect(() => {
        addReferralScripts()
    }, [])

    const isInitReferralProgram =
        !isLoading &&
        !isServiceUsageLoading &&
        showReferralLink &&
        activePlanId !== planTypes.enterprise

    useEffect(() => {
        if (!token) {
            logoutReferral()
            return
        }

        if (isInitReferralProgram) {
            initReferralProgram({ email, displayName })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitReferralProgram, token])
}
