import { useEffect, memo, useCallback } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Box, Pagination as MuiPagination } from '@mui/material'

import { useQueryParams } from 'hooks'

type Props = {
    page: number
    count: number
    onChange?: (page: number) => void
}

export const Pagination = memo(({ page, count, onChange = () => {} }: Props) => {
    const activePage = Number(useQueryParams().get('activePage') || 1)

    const { push } = useHistory()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)

    const handleChange = useCallback(onChange, [onChange])

    useEffect(() => {
        if (page === activePage) return

        handleChange(activePage)
    }, [activePage, handleChange, page])

    return (
        <Box display="flex" justifyContent="center" mt={3}>
            <MuiPagination
                size="large"
                page={activePage}
                count={count}
                onChange={(_, val) => {
                    onChange(val)
                    queryParams.set('activePage', `${val}`)

                    push({
                        search: queryParams.toString()
                    })
                }}
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}
            />
        </Box>
    )
})
