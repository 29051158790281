import { useEffect, memo, useCallback } from 'react'
import Dropzone, { type DropzoneProps } from 'react-dropzone'

import { Box, Typography } from '@mui/material'

import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined'

import { ReactComponent as GuiddeLogo } from 'assets/icons/guidde-white-logo.svg'

type Props = {
    accept?: Array<string>
    multiple?: boolean
    onError?: () => void
    onUpload: (files: Array<File>) => void
}

const checkTypes = (files: Array<File>, accept?: Array<string>) => {
    if (!accept) return true

    return files.every(file => accept.some(type => type === file.type))
}

export const DndArea = memo(({ accept, onError, onUpload, multiple, ...props }: Props) => {
    const pasteHandler = useCallback(
        (e: any) => {
            const data = e.clipboardData?.files || {}
            const files = Object.values<File>(data)

            const filesLength = files.length
            const overLimit = !multiple && filesLength > 1

            if (filesLength < 1) return

            const isFilesValid = checkTypes(files, accept)

            if (!isFilesValid || overLimit) {
                onError?.()
                return
            }

            onUpload(files)
        },
        [onUpload, onError, accept, multiple]
    )

    useEffect(() => {
        document.addEventListener('paste', pasteHandler)

        return () => {
            document.removeEventListener('paste', pasteHandler)
        }
    }, [pasteHandler])

    return (
        <Dropzone
            onDrop={(files: File[]) => {
                if (!files.length) {
                    onError?.()
                    return
                }

                onUpload(files.map((file: File) => Object.assign(file)))
            }}
            accept={accept?.reduce<DropzoneProps['accept']>(
                (acc, it) => ({ ...acc, [it]: [] }),
                {}
            )}
            multiple={multiple}
            {...props}
        >
            {({ getRootProps, getInputProps }) => (
                <Box
                    {...getRootProps({
                        draggable: true
                    })}
                    bgcolor="#BDBDBD"
                    padding={5}
                    height="100%"
                    position="relative"
                >
                    <GuiddeLogo style={{ position: 'absolute', top: 35, right: 45 }} />
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        border="4px dashed rgba(216, 216, 216, 1)"
                    >
                        <input {...getInputProps()} />

                        <Typography variant="h4">Drag and drop image</Typography>
                        <Typography style={{ fontSize: 14 }}>
                            Drop any .jpg, .png or{' '}
                            <u style={{ cursor: 'pointer' }}>browse your files</u>
                        </Typography>
                        <Box mt={4} />
                        <Typography color="textSecondary" variant="caption">
                            <KeyboardOutlinedIcon color="inherit" style={{ marginRight: 10 }} />
                            Ctr + V
                        </Typography>
                    </Box>
                </Box>
            )}
        </Dropzone>
    )
})
