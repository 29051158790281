import { useDispatch } from 'react-redux'

import { ReactComponent as TrialEndImage } from 'assets/images/trial-end.svg'

import { links } from 'app/links'
import { useRoles } from 'hooks'
import { calculateDaysLeft, openLink } from 'modules'
import { showPlanDialog } from 'ducks'

import { ServiceUsageActionButton, ServiceUsageDialog } from './ServiceUsageDialog'

type Props = {
    onClose: () => void
    date: number
}

export const openDemoPage = () => {
    openLink(links.bookDemo, '_blank')
}
export const TrialWeekToEndDialog = ({ onClose, date }: Props) => {
    const { isAdmin } = useRoles()

    const dispatch = useDispatch()

    const days = calculateDaysLeft(date)

    return (
        <ServiceUsageDialog
            title={`Your trial ends in ${days} ${days === 1 ? 'day' : 'days'}`}
            description="Are you making use of your trial as best as possible? Don’t forget to try out
        custom branding and blurring out sensitive information."
            actionText={isAdmin ? 'See plans' : 'Book a demo'}
            onActionClick={() => {
                onClose()

                if (isAdmin) {
                    dispatch(showPlanDialog('upgradePlanDialog'))
                } else {
                    openDemoPage()
                }
            }}
            onClose={onClose}
            Image={<TrialEndImage />}
            additionalActionButton={
                <>
                    {isAdmin && (
                        <ServiceUsageActionButton actionText="Book a demo" onClick={openDemoPage} />
                    )}
                </>
            }
        />
    )
}
