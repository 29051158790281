import { styled, CircularProgress } from '@mui/material'
import { AttachFile as FileIcon } from '@mui/icons-material'

import { FileUploader } from 'UI/Components'

import { useAuth, useBoolean } from 'hooks'
import { type PlaybookType } from 'app/types'

import { uuid, updateFiles } from 'modules'

const PREFIX = 'PlaybookFileUploader'

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    fileIcon: `${PREFIX}-fileIcon`
}

const Root = styled('label')({
    [`&.${classes.wrapper}`]: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: '10px',
        padding: '0',
        width: 'auto'
    },
    [`& .${classes.fileIcon}`]: {
        color: '#2D9CDB',
        marginTop: '4px'
    }
})

type FileType = {
    id: string
    name: string
    uid: string
    url: string
}

type Props = {
    playbook: PlaybookType
}

const ALLOWED_FORMATS = 'image/png, image/jpeg, application/pdf, application/json, application/zip'

export const PlaybookFileUploader = ({ playbook }: Props) => {
    const loading = useBoolean()

    const { orgId, uid } = useAuth()

    const handleUploadSuccess = (url: string, metadata: { name: string }) => {
        const files: Array<FileType> = (playbook.files ?? []).concat({
            id: uuid(),
            uid,
            name: metadata.name,
            url
        })
        updateFiles(playbook.id, files).then(loading.setFalse)
    }

    return (
        <Root className={classes.wrapper} data-test="file-uploader-wrapper">
            {loading.isTrue && <CircularProgress size={14} />}
            <FileUploader
                hideAttachFile
                hidePreview
                hideProgress
                accept={ALLOWED_FORMATS}
                storagePath={`playbookInfo/${orgId}/${uid}/${playbook.id}`}
                onFileChange={loading.setTrue}
                onDone={handleUploadSuccess}
            />

            <FileIcon fontSize="small" className={classes.fileIcon} data-test="file-icon" />
        </Root>
    )
}
