import { useEffect, useRef } from 'react'
import { connect } from 'formik'

const usePrevious = value => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}

export const FormikEffect = connect(({ onChange, formik }) => {
    const { values } = formik
    const prevValues = usePrevious(values)

    useEffect(() => {
        // Don't run effect on form init
        if (prevValues) {
            onChange({ prevValues, nextValues: values, formik }, formik)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    return null
})
