import { useState } from 'react'

import { type Dayjs } from 'dayjs'

import { Button, Popover, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import { type DatePeriod } from 'app/types/insights'
import { logToAnalytics } from 'modules'

import { DateRangePicker, insightsPeriodDates } from 'UI/Components'

type Props = {
    anchorElement: HTMLDivElement | null
    onClose: () => void
    onApply: (value: DatePeriod) => void
    initialStartDate: Dayjs | null
    initialEndDate: Dayjs | null
    maxPeriodLengthInDays: number
    analyticsEventSource: string
}

export const DialogSelectCustomRange = ({
    onClose,
    onApply,
    initialStartDate,
    initialEndDate,
    anchorElement,
    maxPeriodLengthInDays,
    analyticsEventSource
}: Props) => {
    const [startDate, setStartDate] = useState<Dayjs | null>(initialStartDate)
    const [endDate, setEndDate] = useState<Dayjs | null>(initialEndDate)

    const handleClose = () => {
        onClose()
        logToAnalytics('insights_dateRange_cancelBtn_clicked', {
            source: analyticsEventSource
        })
    }

    return (
        <Popover
            open
            onClose={handleClose}
            anchorEl={anchorElement}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <Stack justifyContent="space-between" height={300} p={3}>
                <Stack spacing={3} flex={1}>
                    <Typography fontSize={20} lineHeight="24px" fontWeight={500}>
                        Choose Date Range
                    </Typography>
                    <DateRangePicker
                        maxDate={insightsPeriodDates.halfYear.end}
                        minDate={insightsPeriodDates.halfYear.start}
                        startDate={startDate}
                        onChangeStartDate={setStartDate}
                        endDate={endDate}
                        onChangeEndDate={setEndDate}
                        showToday={false}
                        // For proper rendering of chart(Rechart has issue with rendering one dot)
                        minPeriodLengthInDays={2}
                        maxPeriodLengthInDays={maxPeriodLengthInDays}
                    />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{
                        '& button': {
                            textTransform: 'uppercase'
                        }
                    }}
                >
                    <Button
                        onClick={handleClose}
                        size="large"
                        sx={{
                            color: 'rgba(9, 12, 16, 0.6)',
                            '&:hover': {
                                background: 'none'
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        disabled={startDate === null || endDate === null}
                        onClick={() => {
                            if (startDate !== null && endDate !== null) {
                                onApply({ start: startDate, end: endDate })
                                onClose()
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Stack>
            </Stack>
        </Popover>
    )
}
