import { useAuth, useDataMutation, useNotification, useRoles } from 'hooks'
import { logToAnalytics } from 'modules'

import { CommonTrashDialog } from './CommonTrashDialog'
import { type AnyPlaybookType } from 'app/types'

type Props = {
    isOpen: boolean
    playbookIds: Array<string>
    body?: Array<string>
    playbooks: Array<AnyPlaybookType>
    title?: Array<string>
    onClose: () => void
    onSuccess: () => void
    onFinally?: () => void
}

export const MoveToTrashDialog = ({
    isOpen,
    playbookIds,
    playbooks,
    title = ['Move to trash?'],
    body,
    onClose,
    onSuccess,
    onFinally = () => {}
}: Props) => {
    const { showSuccessNotification } = useNotification()

    const { isContentManager } = useRoles()
    const { uid } = useAuth()

    const $moveToTrash = useDataMutation<
        { playbookIds: Array<string> },
        { message: string },
        Error
    >('/c/v1/move-to-trash', 'POST', {
        onSuccess: () => onSuccess(),
        onFailure: e => {
            console.error('[DELETING ERROR]:', e)
        }
    })

    const handleClose = () => {
        logToAnalytics('moveToTrashModal_cancelBtn_clicked', {
            playbookIds: playbookIds
        })

        onClose()
    }

    const isOwner = playbooks?.every(pb => pb?.creator_uid === uid)
    const isRestricted = playbooks[0]?.visibility === 'Private'

    const text = playbooks.length > 1 ? 'These guides' : 'This guide'

    const defaultRow = [`${text} will be deleted forever after 30 days.`]
    const notVisibleRow = ["Your trash bin won't show it; only the owner can see it."]

    const rowForAdmin = isRestricted ? notVisibleRow : []

    const notOwnerBody = [
        ...defaultRow,
        'You are trying to move a video owned by another user.',
        ...(isContentManager ? rowForAdmin : notVisibleRow)
    ]

    return (
        <CommonTrashDialog
            isOpen={isOpen}
            isLoading={$moveToTrash.isLoading}
            title={title}
            submitButtonLabel="MOVE TO TRASH"
            body={body ? body : isOwner ? defaultRow : notOwnerBody}
            onClose={handleClose}
            onSubmit={() => {
                logToAnalytics('moveToTrashModal_movedToTrashBtn_clicked', {
                    playbookIds,
                    multiselect: playbookIds.length > 1 ? 'on' : 'off'
                })
                $moveToTrash
                    .mutate({ playbookIds })
                    .finally(onFinally)
                    .then(() => {
                        const notification =
                            playbookIds.length > 1
                                ? `${playbookIds.length} guides were moved to trash`
                                : '1 guide was moved to trash'

                        showSuccessNotification(notification)
                    })
            }}
        />
    )
}
