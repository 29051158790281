import { useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import useImage from 'use-image'

import type Konva from 'konva'
import { Image } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { type ImageShapeType } from 'app/types'
import { setLayerTransform } from 'ducks'
import { useServiceUsage } from 'hooks'

type Props = {
    layer: ImageShapeType
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const ImageShape = ({ layer, onSelect, onDragStart, onDragMove, onDragEnd }: Props) => {
    const dispatch = useDispatch()

    const { isFreePlan } = useServiceUsage()

    const imageProps = useMemo(() => layer, [layer])
    const [image] = useImage(imageProps.url, 'anonymous')

    const shapeRef = useRef<Konva.Image>(null)

    // For background image layer we need to use another component
    // as it requires to have extra Layer wrapper to make spotlight works correctly
    if (imageProps.isBackground) return null

    return (
        <Image
            ref={shapeRef}
            id={layer.id}
            name={layer.isWatermark ? 'watermark' : 'image'}
            className="alignable"
            x={imageProps.x}
            y={imageProps.y}
            width={imageProps.width}
            height={imageProps.height}
            scaleX={imageProps.scaleX}
            scaleY={imageProps.scaleY}
            rotation={imageProps.rotation}
            image={image}
            draggable={!imageProps.isWatermark}
            onDragStart={onDragStart}
            onDragMove={onDragMove}
            onDragEnd={onDragEnd}
            onClick={e => {
                if (imageProps.isWatermark && isFreePlan) return
                onSelect(e.evt.shiftKey)
            }}
            onTransform={() => {
                if (imageProps.isWatermark) return
            }}
            onTransformEnd={e => {
                if (imageProps.isWatermark) return

                const { x, y, width, height, scaleX, scaleY, rotation } = e.target.attrs

                dispatch(
                    setLayerTransform({
                        layerId: layer.id,
                        x,
                        y,
                        width,
                        height,
                        scaleX,
                        scaleY,
                        rotation
                    })
                )
            }}
        />
    )
}
