import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Button, Stack, Typography } from '@mui/material'

import { ReactComponent as SelectSpeakerImage } from 'assets/icons/wizard/select-speaker.svg'
import { ReactComponent as SelectMotionImage } from 'assets/icons/wizard/select-motion.svg'
import { ReactComponent as SelectMusicImage } from 'assets/icons/wizard/select-music.svg'

import { logToAnalytics } from 'modules'
import { type SpeakerType } from 'app/types'

import { WizardDialogContent } from './WizardDialogContent'
import { preloadImages } from '../wizard-utils'

const wizardIntroSteps = [
    {
        Image: SelectSpeakerImage,
        title: 'Select \n your speaker',
        dotLeftMargin: '40px',
        imageLeftMargin: '-9px'
    },
    {
        Image: SelectMotionImage,
        title: 'Choose your \n motion effects'
    },
    {
        Image: SelectMusicImage,
        title: 'Pick your \n background music',
        dotRightMargin: '56px'
    }
]

type Props = {
    speakers: Array<SpeakerType>
    onChangeStep: (step: number) => void
    onSkipClick?: () => void
    onRepeatPreviousChoicesClick?: () => void
}

export const WizardIntroStep = ({
    speakers,
    onChangeStep,
    onRepeatPreviousChoicesClick,
    onSkipClick
}: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    useEffect(() => {
        preloadImages(speakers.map(item => item.avatarUrlCompressed))
    }, [speakers])

    return (
        <WizardDialogContent
            title="Personalize your Guidde"
            activeStep={null}
            onChangeStep={onChangeStep}
            primaryActionText="Let’s start"
            onPrimaryActionClick={() =>
                logToAnalytics('wizard_letsStartBtn_clicked', {
                    playbookId
                })
            }
            disableBackClick
            additionalAction={
                <>
                    {onSkipClick && (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#F5F5F5',
                                textTransform: 'uppercase',
                                color: 'rgba(9, 12, 16, 0.6)',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                }
                            }}
                            onClick={onSkipClick}
                        >
                            Skip
                        </Button>
                    )}
                    {onRepeatPreviousChoicesClick && (
                        <Button
                            sx={{
                                textTransform: 'uppercase',
                                color: '#2D9CDB',
                                '&:hover': {
                                    background: 'none'
                                }
                            }}
                            onClick={onRepeatPreviousChoicesClick}
                        >
                            Continue with my previous selection
                        </Button>
                    )}
                </>
            }
        >
            <Stack
                direction="row"
                maxWidth={512}
                alignItems="end"
                justifyContent="space-between"
                width="100%"
            >
                {wizardIntroSteps.map(
                    ({ Image, title, dotLeftMargin, imageLeftMargin, dotRightMargin }, index) => {
                        const isFirstStep = index === 0
                        const isLastStep = index === wizardIntroSteps.length - 1

                        return (
                            <Stack
                                key={title}
                                alignItems={isFirstStep ? 'start' : isLastStep ? 'end' : 'center'}
                                flex={1}
                            >
                                <Stack ml={imageLeftMargin}>
                                    <Image />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={0.5}
                                    sx={{
                                        ml: dotLeftMargin,
                                        mr: dotRightMargin,
                                        mb: '11px',
                                        mt: 1,
                                        alignSelf: 'stretch',
                                        alignItems: 'center',
                                        '& .line': {
                                            backgroundColor: 'rgba(9, 12, 16, 0.1)',
                                            height: 4,
                                            flex: 1
                                        }
                                    }}
                                >
                                    {!isFirstStep && <Stack className="line" />}
                                    <Box
                                        width={8}
                                        height={8}
                                        sx={{
                                            borderRadius: '50%',
                                            backgroundColor: 'primary.main'
                                        }}
                                    />
                                    {!isLastStep && <Stack className="line" />}
                                </Stack>
                                <Typography
                                    whiteSpace="pre-wrap"
                                    fontSize={14}
                                    letterSpacing={0.25}
                                    fontWeight={700}
                                    lineHeight="20px"
                                    textAlign="center"
                                >
                                    {title}
                                </Typography>
                            </Stack>
                        )
                    }
                )}
            </Stack>
        </WizardDialogContent>
    )
}
