import { useCallback, useEffect, useRef, useState } from 'react'

import { TextField, Box, InputAdornment } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { logToAnalytics, updateLink } from 'modules'
import { Label } from 'UI/Components'

type Props = {
    playbookId: string
    value: string
    disabled?: boolean
}

export const EditSourceUrl = ({ playbookId, value, disabled = true }: Props) => {
    const [link, setLink] = useState(value)
    const inputRef = useRef<HTMLInputElement>()
    const error = link?.length > 0 && !link.startsWith('https://')

    const handleBlur = () => {
        if (link === value) return

        handleSave()
    }

    const handleSave = useCallback(() => {
        if (!error && link !== value) {
            updateLink({ playbookId, link }).then(() =>
                logToAnalytics('update_playbook_source_url', {
                    'pb-link': link
                })
            )
        }
    }, [error, link, value, playbookId])

    useEffect(() => setLink(value), [value])

    if (disabled && !link) return null

    return (
        <Box position="relative">
            <Label hidden={disabled} label="Go to app link for this video" />

            <Box mt={1} display="flex" position="relative">
                <TextField
                    variant="standard"
                    placeholder="https://example.com"
                    value={link || ''}
                    inputRef={inputRef}
                    onChange={e => setLink(e?.target?.value)}
                    onBlur={handleBlur}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleSave()
                            return e.target?.blur()
                        }
                    }}
                    sx={{
                        '& input': {
                            fontSize: '12px !important'
                        },
                        '& :before': {
                            borderBottomColor: '#e0e0e0'
                        }
                    }}
                    data-test="edit-source-url"
                    InputProps={{
                        disableUnderline: disabled,
                        endAdornment: link?.length > 1 && (
                            <InputAdornment position="end">
                                <CloseIcon
                                    sx={{
                                        color: '#fff',
                                        cursor: 'pointer',
                                        height: '14px'
                                    }}
                                    onClick={() => {
                                        setLink('')
                                        inputRef.current?.focus()
                                    }}
                                />
                            </InputAdornment>
                        )
                    }}
                    error={error}
                    helperText={error ? 'Link should use the following format https://url' : ''}
                    fullWidth
                />
            </Box>
        </Box>
    )
}
