import { type ReactNode } from 'react'

import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { logToAnalytics } from 'modules'

import { InsightsEmptyChartLabel } from './InsightsEmptyChartLabel'

type Props = {
    title: string
    description?: string
    content: ReactNode | null
    actions?: ReactNode
    isEmpty?: boolean
    analyticsDescriptionHoverEventName?: string
    analyticsEventSource?: string
    isSmall?: boolean
}

export const InsightsStatisticContainer = ({
    title,
    description,
    content,
    actions,
    isEmpty,
    analyticsDescriptionHoverEventName,
    analyticsEventSource,
    isSmall = false
}: Props) => {
    const isEmptyState = content === null || isEmpty

    return (
        <Stack
            sx={{
                ...(!isSmall && {
                    border: '1px solid rgba(9, 12, 16, 0.1)',
                    borderRadius: '6px',
                    py: 2,
                    px: 3,
                    flex: 1
                }),
                position: 'relative'
            }}
        >
            <Stack spacing={2}>
                <Stack
                    direction={isSmall ? 'column' : 'row'}
                    spacing={isSmall ? 2 : 0}
                    sx={
                        isSmall
                            ? undefined
                            : { justifyContent: 'space-between', alignItems: 'center' }
                    }
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography
                            fontSize={16}
                            lineHeight={1.5}
                            fontWeight={600}
                            color={isEmptyState ? '#828282' : undefined}
                        >
                            {title}
                        </Typography>
                        {description && (
                            <Tooltip
                                title={description}
                                placement="right"
                                arrow
                                onOpen={() => {
                                    if (analyticsDescriptionHoverEventName)
                                        logToAnalytics(analyticsDescriptionHoverEventName, {
                                            source: analyticsEventSource
                                        })
                                }}
                                componentsProps={{ tooltip: { sx: { whiteSpace: 'pre-line' } } }}
                            >
                                <IconButton sx={{ p: 0 }}>
                                    <HelpOutlineIcon sx={{ color: 'rgba(9, 12, 16, 0.6)' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                    {actions}
                </Stack>
                {content || <InsightsEmptyChartLabel />}
            </Stack>
        </Stack>
    )
}
