import { type ReactNode, useEffect, useState } from 'react'

import { Box, IconButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { SpacedGroup } from 'UI/Components'

import { useBoolean } from 'hooks'

type Props = {
    spacing?: number
    children: ReactNode
}

export const Carousel = ({ spacing = 0, children }: Props) => {
    const [element, setElement] = useState<HTMLDivElement | null>(null)

    const disableLeftArrow = useBoolean()
    const disableRightArrow = useBoolean()
    const showArrows = useBoolean()

    const setShowArrows = showArrows.set

    useEffect(() => {
        if (!element) return

        if (element.clientWidth < element.scrollWidth) {
            setShowArrows(true)
        }

        const observer = new ResizeObserver(() => {
            if (element.clientWidth < element.scrollWidth) {
                return setShowArrows(true)
            }
            setShowArrows(false)
        })

        observer.observe(element)

        return () => {
            observer.unobserve(element)
        }
    }, [element, setShowArrows, children])

    useEffect(() => {
        if (!element) return

        const handleScroll = () => {
            if (element.scrollLeft === 0) {
                disableLeftArrow.setTrue()
                disableRightArrow.setFalse()
            } else {
                disableLeftArrow.setFalse()

                if (
                    Math.abs(
                        Math.ceil(element.scrollLeft) - (element.scrollWidth - element.clientWidth)
                    ) <= 1
                )
                    disableRightArrow.setTrue()
                else {
                    disableRightArrow.setFalse()
                }
            }
        }

        handleScroll()

        element.addEventListener('scroll', handleScroll)

        return () => {
            element.removeEventListener('scroll', handleScroll)
        }
    }, [disableLeftArrow, disableRightArrow, element, children])

    return (
        <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="0 auto"
            width={showArrows.isTrue ? 'calc(100% - 50px)' : '100%'}
        >
            {showArrows.isTrue && (
                <>
                    <IconButton
                        size="small"
                        style={{ position: 'absolute', left: '-25px', padding: 0 }}
                        disabled={disableLeftArrow.isTrue}
                        onClick={() => {
                            element?.scrollTo({
                                top: 0,
                                left: element.scrollLeft - element.clientWidth,
                                behavior: 'smooth'
                            })
                        }}
                    >
                        <KeyboardArrowLeftIcon />
                    </IconButton>

                    <IconButton
                        size="small"
                        style={{ position: 'absolute', right: '-25px', padding: 0 }}
                        disabled={disableRightArrow.isTrue}
                        onClick={() => {
                            element?.scrollTo({
                                top: 0,
                                left: element.scrollLeft + element.clientWidth,
                                behavior: 'smooth'
                            })
                        }}
                    >
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </>
            )}

            <SpacedGroup
                width="100%"
                overflow="hidden"
                spacing={spacing}
                ref={node => {
                    if (!node) return
                    setElement(node)
                }}
            >
                {children}
            </SpacedGroup>
        </Box>
    )
}
