import { Box, Typography } from '@mui/material'

import { formatTime } from 'modules'

const INDICATOR_WIDTH = 3

type Props = {
    currentTime: number
    duration: number
}

export const TimeIndicator = ({ currentTime, duration }: Props) => {
    return (
        <Box
            sx={{
                height: '100%',
                position: 'absolute',
                left: `calc(${(currentTime / duration) * 100}% - ${INDICATOR_WIDTH / 2}px)`,
                display: 'flex',
                justifyContent: 'center',
                pointerEvents: 'none'
            }}
        >
            <Box
                bgcolor="black"
                color="white"
                borderRadius="8px"
                sx={{
                    position: 'absolute',
                    top: '-20px',
                    zIndex: 2,
                    padding: '0 4px'
                }}
            >
                <Typography fontSize="12px">{formatTime(currentTime)}</Typography>
            </Box>
            <Box
                sx={{
                    height: 'calc(100% - 4px)',
                    width: INDICATOR_WIDTH,
                    backgroundColor: '#e9e9e9',
                    boxShadow: '0px 2px 6px rgb(0 0 0)'
                }}
            />
        </Box>
    )
}
