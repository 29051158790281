import { Box, Typography, IconButton, Tooltip } from '@mui/material'
import { Restore as RestoreIcon } from '@mui/icons-material'

import { FileUploader } from 'UI/Components'

import { updateCover } from 'modules'
import { useAuth, useNotification } from 'hooks'

import { type PlaybookType } from 'app/types'

type Props = {
    playbook: PlaybookType
}
export const PlaybookCover = ({ playbook }: Props) => {
    const { uid } = useAuth()

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const handleUploadCover = (newCoverUrl: string) => {
        updateCover({
            playbookId: playbook.id,
            previousScreenshotUrl: playbook.previousScreenshotUrl,
            newCoverUrl
        })
            .then(() => showSuccessNotification('Video cover updated'))
            .catch(() => showErrorNotification('Something went wrong'))
    }

    return (
        <Box display="flex" position="relative" mt={1}>
            <FileUploader
                accept="image/*"
                logoUrl={playbook.screenshotUrl}
                iconColor="#828282"
                labelComponent={
                    <Box ml={1}>
                        <Typography
                            style={{
                                color: '#828282',
                                fontSize: '12px'
                            }}
                        >
                            Update cover
                        </Typography>
                    </Box>
                }
                saveHeight={720}
                saveWidth={1280}
                hideProgress={true}
                filename={playbook.id}
                previewWidth={150}
                previewHeight={87}
                storagePath={`playbookCover/${uid}/`}
                onDone={handleUploadCover}
            />

            {playbook?.previousScreenshotUrl && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '2px',
                        left: ' 149px'
                    }}
                >
                    <Tooltip title="Back to default cover">
                        <IconButton
                            sx={{
                                position: 'absolute',
                                padding: '0px',
                                right: '2px',
                                top: '2px',
                                color: 'white',
                                backgroundColor: '#182332',
                                '&:hover, &.Mui-focusVisible': {
                                    backgroundColor: '#182332'
                                }
                            }}
                            onClick={() => handleUploadCover('')}
                        >
                            <RestoreIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </Box>
    )
}
