import { type ReactNode, type CSSProperties, useEffect, memo } from 'react'

import { type BoxProps, Box, LinearProgress } from '@mui/material'

import { useBoolean } from 'hooks'
import { delay } from 'modules'

type Props = {
    children: ReactNode
    value: number
    index: number
    linearStyles?: CSSProperties
    loadDelay?: number
    keepInDOM?: boolean
    px?: number
    py?: number
} & BoxProps

export const TabPanel = memo(
    ({
        children,
        value,
        index,
        id,
        linearStyles = {},
        loadDelay = 0, // new prop (default undefined = false) for backward compatibility
        keepInDOM = true,
        px = 3,
        py = 3,
        ...rest
    }: Props) => {
        const load = useBoolean(loadDelay === 0)

        const isLoaded = load.isTrue
        const setLoaded = load.setTrue

        useEffect(() => {
            if (isLoaded) return

            if (value === index) delay(loadDelay).then(setLoaded)
        }, [index, value, isLoaded, setLoaded, loadDelay])

        if (value === index && load.isFalse)
            return (
                <Box mt={2}>
                    <LinearProgress style={linearStyles} />
                </Box>
            )

        if (value !== index && !keepInDOM) return null

        return (
            <Box {...rest} hidden={value !== index}>
                {isLoaded && (
                    <Box py={py} px={px} id={id}>
                        {children}
                    </Box>
                )}
            </Box>
        )
    }
)
