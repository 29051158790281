import { useRef } from 'react'
import { useDispatch } from 'react-redux'

import {
    Box,
    ClickAwayListener,
    IconButton,
    Paper,
    Popper,
    Tooltip,
    Typography
} from '@mui/material'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import CloseIcon from '@mui/icons-material/Close'

import { useBoolean, useBrandKit } from 'hooks'
import { setCompanyLogo } from 'ducks'
import { type ImageShapeType } from 'app/types'
import { logToAnalytics } from 'modules'

type Props = {
    currentLayer: ImageShapeType
    scale: number
}

export const CompanyLogo = ({ currentLayer, scale }: Props) => {
    const dispatch = useDispatch()

    const anchorEl = useRef<null | HTMLElement>(null)

    const showLogos = useBoolean()
    const loading = useBoolean()

    const { brandKitData } = useBrandKit()

    if (!brandKitData) return null

    return (
        <>
            <Tooltip title="Select logo" ref={anchorEl}>
                <ImageOutlinedIcon
                    onClick={showLogos.toggle}
                    style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                />
            </Tooltip>

            <Popper
                open={showLogos.isTrue}
                anchorEl={anchorEl?.current}
                placement="left-start"
                style={{ maxWidth: 365, zIndex: 2000 }}
            >
                <ClickAwayListener
                    onClickAway={e => {
                        if ((e.target as Element).nodeName === 'BODY') return
                        showLogos.setFalse()
                    }}
                >
                    <Paper>
                        <Box p={2} pb={0} display="flex" justifyContent="space-between">
                            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                                Choose logo
                            </Typography>

                            <IconButton onClick={showLogos.setFalse} size="small">
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box
                            p={2}
                            display="flex"
                            justifyContent="center"
                            gap="10px"
                            flexWrap="wrap"
                        >
                            {brandKitData?.logoAndFavicon.data.map(({ id, url }) => {
                                const isActive = currentLayer.url === url

                                return (
                                    <Box
                                        key={id}
                                        justifyContent="center"
                                        display="flex"
                                        alignItems="center"
                                        minHeight={30}
                                        border={`1px solid ${isActive ? '#CB0000' : 'transparent'}`}
                                        borderRadius="4px"
                                        bgcolor={isActive ? '#FAE5E5' : ''}
                                        style={{ cursor: loading.isTrue ? 'progress' : 'pointer' }}
                                        onClick={() => {
                                            if (isActive) return
                                            loading.setTrue()

                                            const img = new Image()
                                            img.src = url
                                            img.onload = () => {
                                                dispatch(
                                                    setCompanyLogo({
                                                        width: img.width * scale,
                                                        height: img.height * scale,
                                                        url
                                                    })
                                                )
                                                loading.setFalse()
                                                logToAnalytics('logoSelectedInEditor')
                                            }
                                        }}
                                    >
                                        <img
                                            src={url}
                                            style={{ objectFit: 'contain', maxHeight: 40 }}
                                            width="100px"
                                            height="auto"
                                            alt="logo"
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
