import * as types from 'ducks/types'
import { type MyVideosType, type FacetsType, type LibraryType } from 'app/types'

export const setMyVideosFilters = (payload: Partial<MyVideosType>) =>
    ({
        type: types.SET_MY_GUIDDES_FILTERS,
        payload
    }) as const

export const setLibraryFacetFilters = (payload: FacetsType) =>
    ({
        type: types.SET_LIBRARY_FACET_FILTERS,
        payload
    }) as const

export const resetLibraryPlaybookFilters = () =>
    ({
        type: types.RESET_LIBRARY_PLAYBOOK_FILTERS
    }) as const

export const resetMyPlaybookFilters = () =>
    ({
        type: types.RESET_MY_PLAYBOOKS_FILTERS
    }) as const

export const setLibraryPlaybookFilters = (payload: LibraryType) =>
    ({
        type: types.SET_LIBRARY_PLAYBOOK_FILTERS,
        payload
    }) as const

export const resetLibraryPlaybookMode = () =>
    ({
        type: types.RESET_LIBRARY_PLAYBOOK_MODE
    }) as const
