import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Box,
    Grid,
    Radio,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as FromAnotherCompany } from 'assets/icons/from-another-company.svg'
import { ReactComponent as YourTeamMember } from 'assets/icons/your-team-member.svg'

import { useAuth, useBoolean } from 'hooks'

const PREFIX = 'InviteOptionsDialog'

const classes = {
    titleWrap: `${PREFIX}-titleWrap`,
    title: `${PREFIX}-title`,
    closeButton: `${PREFIX}-closeButton`,
    actions: `${PREFIX}-actions`,
    content: `${PREFIX}-content`,
    emailRow: `${PREFIX}-emailRow`,
    imageWrapper: `${PREFIX}-imageWrapper`,
    gridItem: `${PREFIX}-gridItem`,
    cardBody: `${PREFIX}-cardBody`
}

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
    [`& .${classes.titleWrap}`]: {
        padding: spacing(2, 2, 3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > h2': {
            width: '100%'
        }
    },

    [`& .${classes.title}`]: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        textAlign: 'left'
    },

    [`& .${classes.closeButton}`]: {
        padding: spacing(0.5),
        margin: spacing(0.5)
    },

    [`& .${classes.actions}`]: {
        padding: spacing(2),
        justifyContent: 'space-between',

        '& button': {
            textTransform: 'uppercase'
        }
    },

    [`& .${classes.content}`]: {
        padding: spacing(0, 2, 2),
        borderTop: 'none'
    },

    [`& .${classes.emailRow}`]: {
        background: '#F5F5F5',
        borderBottom: '1px solid rgba(9, 12, 16, 0.08)',
        borderRadius: '4px 4px 0px 0px'
    },

    [`& .${classes.imageWrapper}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '210px',
        height: '120px',
        background: '#E6E7E7'
    },

    [`& .${classes.gridItem}`]: {
        cursor: 'pointer',
        maxWidth: '210px',
        border: '1px solid rgba(9, 12, 16, 0.08)',
        borderRadius: '4px',
        fontSize: '12px',
        lineHeight: '16px',

        '& h3': {
            fontWeight: 700,
            fontSize: 'inherit',
            lineHeight: 'inherit'
        }
    },

    [`& .${classes.cardBody}`]: {
        display: 'flex',
        padding: '10px 20px 8px 5px'
    }
}))

type Props = {
    onClose: () => void
    emails: Array<string>
    onConfirm: (inviteTo: string) => void
}

export const InviteOptionsDialog = ({ onClose, emails, onConfirm }: Props) => {
    const { orgId } = useAuth()
    const otherOrg = useBoolean()

    return (
        <StyledDialog open fullWidth>
            <DialogTitle className={classes.titleWrap}>
                <Typography className={classes.title}>
                    {emails.length > 1 ? 'These persons look new' : 'This person looks new'}
                </Typography>
                <IconButton className={classes.closeButton} onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.content}>
                {emails.map(email => (
                    <Box p={2} key={email} className={classes.emailRow}>
                        {email}
                    </Box>
                ))}
                <Box mt={4}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <Box className={classes.gridItem} onClick={otherOrg.setFalse}>
                                <Box className={classes.imageWrapper}>
                                    <FromAnotherCompany />
                                </Box>
                                <Box className={classes.cardBody}>
                                    <Radio
                                        color="primary"
                                        checked={otherOrg.isFalse}
                                        onChange={otherOrg.setFalse}
                                        name="radio-buttons"
                                    />
                                    <Box>
                                        <Typography component="h3">From another company</Typography>
                                        They’ll be invited to a new guidde workspace
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box className={classes.gridItem} onClick={otherOrg.setTrue}>
                                <Box className={classes.imageWrapper}>
                                    <YourTeamMember />
                                </Box>
                                <Box className={classes.cardBody}>
                                    <Radio
                                        color="primary"
                                        checked={otherOrg.isTrue}
                                        onChange={otherOrg.setTrue}
                                        name="radio-buttons"
                                    />
                                    <Box>
                                        <Typography component="h3">Your team member</Typography>
                                        They’ll be added to your guidde workspace
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button size="medium" onClick={onClose} color="inherit" variant="contained">
                    Cancel
                </Button>

                <Button
                    size="medium"
                    variant="contained"
                    onClick={() => {
                        onConfirm(otherOrg.isTrue ? orgId : '')
                        onClose()
                    }}
                    disabled={false}
                >
                    Next
                </Button>
            </DialogActions>
        </StyledDialog>
    )
}
