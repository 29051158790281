import { type SyntheticEvent, type ReactNode, useRef, useState, memo, useEffect } from 'react'

import { type SxProps } from '@mui/material/styles'

import { type BoxProps, Box } from '@mui/material'

import { type SpeakerItemProps, SpeakerItem } from './SpeakerItem'

import { type SpeakerType } from 'app/types'

import { useBoolean } from 'hooks'
import { delay } from 'modules'

type Props = {
    onAudioPlay?: (speaker: SpeakerType) => void
    onSelect: SpeakerItemProps['onContainerClick']
    currentSpeaker: SpeakerType | null
    hasStudioVoices: boolean | null
    options: Array<SpeakerType>
    speakerItemDataCy?: string
    width?: BoxProps['width']
    speakerCardSx?: SxProps
    startItems?: ReactNode
    endItems?: ReactNode
    gridGap?: number | string
    premiumDisplayMode?: 'lock' | 'upgrade'
}

export const LanguageSpeakers = memo(
    ({
        width,
        currentSpeaker,
        hasStudioVoices,
        options,
        speakerItemDataCy,
        onAudioPlay,
        onSelect,
        speakerCardSx,
        startItems,
        endItems,
        gridGap,
        premiumDisplayMode
    }: Props) => {
        const audioRef = useRef<HTMLMediaElement | null>(null)
        // used for toggling the play pause icon for a specific speaker
        const [playSpeaker, setPlaySpeaker] = useState<SpeakerType | null>(null)

        // current playing time
        const [currentTime, setCurrentTime] = useState(0)

        // used to maintain the current playing state
        const playStatus = useBoolean()

        const setPlayStatus = playStatus.set

        // reset on close
        useEffect(() => {
            return () => {
                setPlaySpeaker(null)
                setPlayStatus(false)
            }
        }, [setPlayStatus])

        return (
            <Box width="100%">
                <audio
                    src={playSpeaker?.sampleAudioUrl ?? ''}
                    ref={audioRef}
                    onPlay={() => {
                        playStatus.setTrue()

                        if (playSpeaker) onAudioPlay?.(playSpeaker)
                    }}
                    onPause={playStatus.setFalse}
                    onTimeUpdate={(event: SyntheticEvent<HTMLAudioElement, Event>) => {
                        const { currentTime } = event.target as HTMLAudioElement
                        setCurrentTime(currentTime)
                    }}
                    onEnded={() => {
                        playStatus.setFalse()
                        setCurrentTime(0)
                    }}
                />
                <Box
                    data-test="card-container"
                    width={width ?? '100%'}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                        gap: gridGap ?? 2,
                        '&::-webkit-scrollbar': {
                            width: '20px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#D8D8D8',
                            borderRadius: '20px',
                            border: '6px solid transparent',
                            backgroundClip: 'content-box'
                        }
                    }}
                >
                    {startItems}
                    {options.map(speaker => {
                        const isPlaying = playStatus.isTrue && speaker.id === playSpeaker?.id

                        return (
                            <SpeakerItem
                                key={speaker.id}
                                dataCy={speakerItemDataCy}
                                speaker={speaker}
                                isActive={speaker.id === currentSpeaker?.id}
                                isPremium={!hasStudioVoices && speaker.isStudio}
                                isPlaying={playStatus.isTrue && speaker.id === playSpeaker?.id}
                                currentTime={currentTime}
                                onContainerClick={onSelect}
                                onActionButtonClick={speaker => {
                                    if (playSpeaker?.id !== speaker.id) {
                                        setPlaySpeaker(speaker)
                                    }

                                    const audioElement = audioRef.current
                                    if (!audioElement) return

                                    delay(0).then(() => {
                                        if (isPlaying) audioElement.pause()
                                        else audioElement.play()
                                    })
                                }}
                                sx={speakerCardSx}
                                premiumDisplayMode={premiumDisplayMode}
                            />
                        )
                    })}
                    {endItems}
                </Box>
            </Box>
        )
    }
)
