import { type MouseEvent, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import { processDictionaryData, SpacedGroup } from 'UI/Components'
import { PronunciationPopup } from './PronunciationPopup'
import { pause } from './TranscriptBlock'

import { useQuery, useRoles } from 'hooks'
import { logToAnalytics } from 'modules'

function isValidString(str: string) {
    // filter out forbidden characters  '/pause/', '{', '}', and '|'
    const allowedRegex = /\/pause\/|[{}|]/g

    // Test the string against the regex
    // If a match is found (i.e., any character not part of '/pause/', '{', '}', and '|'), return false
    return str.search(allowedRegex) === -1
}

const breakSubtitleIntoSegments = (text: string) => {
    const pauseParts = text.replaceAll(pause.trim(), 'AA')

    const regex =
        /[-:&^.,!#?:;'`+=_@$%^*()]*\{[^}]*\}[-:&^.,!#?:;'`+=_@$%^*()]*|[\p{L}'/\-:&^.,!#?:;'`+=_@$%^*()0123456789"]+/gu

    // split and keep whole words and square brackets mark up and replace AA  with /pause/ with
    const res = pauseParts.match(regex) || []

    return res.map(it => it.replaceAll('AA', pause))
}

const buildNewText = (
    subtitle: string,
    pronunciationExpression: string,
    wordIndex: number,
    data: DataProps
) => {
    const words = breakSubtitleIntoSegments(subtitle)

    const final = data.prefix + pronunciationExpression + data.suffix

    const newArr = [...words.slice(0, wordIndex), final, ...words.slice(wordIndex + 1)]

    return newArr.join(' ')
}

const generateWords = (text: string) => {
    if (!text) return []

    // Regular expression to match words and text inside curly braces as single units
    const regex = /[-:&^.,!#?:;'`+=_@$%^*()]*\{[^}]*\}[-:&^.,!#?:;'`+=_@$%^*()]*|\S+/g

    const matches = text.match(regex)

    if (!matches) return []

    return matches.filter(Boolean)
}

const checkSpecialCharacters = (word: string): SpecialCharResult => {
    const specialCharsAtStart = /^[-:&^.,!#?;'"`+=_@$%^*()]+/
    const specialCharsAtEnd = /[-:&^.,!#?;'"`+=_@$%^*()]+$/

    let result: SpecialCharResult = { prefix: '', suffix: '', word: '' }

    // Regular expression to match special characters at the start or end of the word
    const regex = /^[-:&^.,!#?;'"`+=_@$%^*()]+|[-:&^.,!#?;'"`+=_@$%^*()]+$/g

    // Check for special characters at the start
    const startMatch = word.match(specialCharsAtStart)
    if (startMatch) {
        result.prefix = startMatch[0]
    }

    // Check for special characters at the end
    const endMatch = word.match(specialCharsAtEnd)
    if (endMatch) {
        result.suffix = endMatch[0]
    }

    result.word = word.replace(regex, '')

    return result
}

const pronunciationPattern = /\{([^}]+)}/g

// Splitting the pronunciation expression if exists and extracting the phrase to use it
// in edit mode in the correct field
// For example {hi||hello} will be split to hi and hello returned by the index [0] or [1]
// extractWord('{hi||hello}', 0) => hi
// extractWord('{hi||hello}', 1) => hello
// extractWord('hello', 1) => hello
const extractWord = (word: string, index: number, addSpecialChars: boolean) => {
    let wordObject = checkSpecialCharacters(word)

    let finalWord = wordObject.word
    let type: PronunciationType

    const matchResult = word.match(pronunciationPattern)

    if (matchResult) {
        // Extracting the match
        let matchedString = matchResult[0]

        // sub (|)  - alternate
        // phoneme (||) - ipa
        matchedString.includes('||') ? (type = 'phoneme') : (type = 'sub')

        finalWord = matchedString
            .replace(/[{}]/g, '') // Remove '{' and '}'
            .split(/\|{1,2}/)[index]
    }

    return {
        finalWord: `${addSpecialChars ? wordObject.prefix : ''}${finalWord}${
            addSpecialChars ? wordObject.suffix : ''
        }`,
        matchResult,
        ...(type && { type })
    }
}

type SpecialCharResult = {
    prefix: string
    suffix: string
    word: string
}

type Values = {
    alternate: string
    ipa: string
    newWord: string
    workspaceDictionary: boolean
}

export type Word = {
    phonetic: string
    type: string
}

export type Words = {
    [word: string]: Word
}

export type Dictionary = {
    words: {
        [language: string]: Words
    }
}

export type PronunciationType = 'phoneme' | 'sub' | undefined

type DataProps = {
    phrase: string // contains the word the user clicked on
    wordIndex: number
    prefix: string
    suffix: string
}

type Props = {
    subtitle: string
    onPronunciationChange: (_value: string) => void
}

export const PronunciationText = ({ subtitle, onPronunciationChange }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const { isAdmin } = useRoles()

    const [data, setData] = useState<DataProps>({
        phrase: '',
        wordIndex: 0,
        prefix: '',
        suffix: ''
    })

    const [initialValue, setInitialValue] = useState<Values>({
        alternate: '',
        ipa: '',
        newWord: '',
        workspaceDictionary: false
    })

    const { playbookId } = useParams<{ playbookId: string }>()

    const handleClick = (
        e: MouseEvent<HTMLElement>,
        phrase: string,
        type: PronunciationType,
        wordIndex: number
    ) => {
        const res = checkSpecialCharacters(phrase)

        setData({ phrase: res.word.trim(), wordIndex, prefix: res.prefix, suffix: res.suffix })

        const parts = breakSubtitleIntoSegments(subtitle)

        const clickedWorld = extractWord(parts[wordIndex], 1, false)
        const initialValue = {
            alternate: type === 'sub' ? clickedWorld.finalWord : '',
            ipa: type === 'phoneme' ? clickedWorld.finalWord : '',
            newWord: '',
            workspaceDictionary: false // checkbox value
        }
        setInitialValue(initialValue)

        setAnchorEl(e.currentTarget as HTMLElement)
    }

    const onSubmit = (pronunciationExpression: string, wordIndex: number) => {
        const updatedSubtitle = buildNewText(subtitle, pronunciationExpression, wordIndex, data)
        onPronunciationChange(updatedSubtitle)
    }

    // get all exiting dictionary entries
    const { data: dictionaryData } = useQuery<Dictionary>('/c/v1/pronunciations', {
        method: 'GET'
    })

    const isAddToDictionaryDisabled = useMemo(() => {
        if (!dictionaryData) return false

        const flatten = processDictionaryData(dictionaryData)

        const isWordInDictionary = flatten.some(item => item.word === data.phrase.toLowerCase())

        // the word is not in the dictionary - checkbox is enabled
        if (!isWordInDictionary) return false

        return !isAdmin && isWordInDictionary
    }, [isAdmin, data.phrase, dictionaryData])

    return (
        <>
            <SpacedGroup flexWrap="wrap" spacing={0.5}>
                {generateWords(subtitle).map((word, i) => {
                    const { finalWord, matchResult, type } = extractWord(word, 0, true)

                    if (!isValidString(finalWord)) return <Box key={i}>{finalWord}</Box>

                    return (
                        <Box
                            key={i}
                            onClick={e => {
                                logToAnalytics('voiceover_t2vTab_highlightedWord_clicked', {
                                    playbookId,
                                    original_text: finalWord
                                })
                                handleClick(e, finalWord, type, i)
                            }}
                            sx={
                                matchResult
                                    ? {
                                          cursor: 'pointer',
                                          backgroundColor: 'rgba(254, 210, 67, 0.6)'
                                      }
                                    : {
                                          backgroundColor: 'transparent',
                                          transition: 'background-color 0s',
                                          cursor: 'pointer',
                                          '&:hover': {
                                              backgroundColor: 'rgba(254, 210, 67, 0.6)',
                                              transition: 'background-color 0.5s'
                                          }
                                      }
                            }
                        >
                            {finalWord}
                        </Box>
                    )
                })}
            </SpacedGroup>

            <PronunciationPopup
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                isAddToDictionaryDisabled={isAddToDictionaryDisabled}
                showPronunciationCheckbox={true}
                initialValue={initialValue}
                selectedWord={data.phrase}
                onSubmit={newPhrase => onSubmit(newPhrase, data.wordIndex)}
                onReset={() => onSubmit(data.phrase, data.wordIndex)}
            />
        </>
    )
}
