import { useCallback, useMemo } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'

export const useWindowView = () => {
    const theme = useTheme()

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
    const isTabletView = useMediaQuery(theme.breakpoints.only('sm'))
    const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'))

    const isLgDesktopView = useMediaQuery(theme.breakpoints.up('lg'))

    const isWindowWidthSmaller = useCallback(
        (value: number) => useMediaQuery(theme.breakpoints.down(value)),
        [theme]
    )

    return useMemo(
        () => ({
            isMobileView,
            isDesktopView,
            isTabletView,
            isLgDesktopView,
            isWindowWidthSmaller
        }),
        [isMobileView, isDesktopView, isLgDesktopView, isTabletView, isWindowWidthSmaller]
    )
}
