import * as types from 'ducks/types'

import { type ActionType, type Reducer } from 'ducks/common'

export type UserAuthStateType = {
    user: {
        uid: string
        displayName: string
        photoURL: string
        email: string
    }
    roles: {
        o: null | string // orgId
        s: boolean // super admin
        a: number // 10, 20, 60, 100
        n?: boolean // new user (for onboarding)
        f?: boolean // used for checking if user is first on the new org (user should be admin or not)
        v?: boolean // is viewer
        r?: {
            // read only mode (used for the stripe)
            p: boolean // can create playbooks
            s: boolean // can create spaces
            u: boolean // not used but exist
        }
    }
    su: null | string
    token: string
    newAuth: boolean
    isLoggedInByGuidde: boolean
    isLoading: boolean
    isSessionCalled: boolean
    refreshPageOnTheNextTokenUpdate: boolean
}

const initialState: UserAuthStateType = {
    user: {
        uid: '',
        displayName: '',
        photoURL: '',
        email: ''
    },
    roles: {
        o: null,
        s: false,
        a: 0
    },
    su: null,
    token: '',
    newAuth: false,
    isLoggedInByGuidde: false,
    isLoading: true,
    isSessionCalled: false,
    refreshPageOnTheNextTokenUpdate: false
}

export const appAuthReducer: Reducer<UserAuthStateType> = (
    state = initialState,
    action: ActionType
): UserAuthStateType => {
    switch (action.type) {
        case types.UPDATE_APP_AUTH: {
            return { ...state, ...action.payload, isLoading: false }
        }
        case types.RESET_APP_AUTH: {
            return { ...initialState, ...action.payload, isLoading: false }
        }
        case types.REGISTER_SET_AUTH_STATUS: {
            return { ...state, newAuth: action.payload, isLoading: false }
        }
        default: {
            return state
        }
    }
}
