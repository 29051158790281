import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { MenuItem, Select } from '@mui/material'

import { updateUserPreferences } from 'ducks/reducers'

import { useBoolean } from 'hooks'
import { logToAnalytics } from 'modules'

type Props = {
    deviceId: string
    onChange: Dispatch<SetStateAction<string>>
}

export const MicSelection = ({ deviceId, onChange }: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    const permission = useBoolean()
    const setPermission = permission.set

    const {
        userPreferences: { micId }
    } = useSelector(state => state.configs)

    const [options, setOptions] = useState<Array<MediaDeviceInfo>>([])

    useEffect(() => {
        if (permission.isFalse) return

        navigator.mediaDevices.enumerateDevices().then(devices => {
            const audioInputs = devices.filter(
                device => device.kind === 'audioinput' && device.deviceId !== 'default'
            )
            setOptions(audioInputs)

            // If user previously selected mic, and it's available now - select that mic as default option
            if (micId && audioInputs.some(input => input.deviceId === micId)) {
                onChange(micId)
                return
            }

            // If builtIn mic is available - select it as default. Otherwise, first available from the list
            const builtIn = audioInputs.find(input => input.label.includes('Built-in'))

            if (audioInputs?.[0]?.deviceId) {
                onChange(builtIn?.deviceId || audioInputs?.[0].deviceId || '')
            }
        })
    }, [micId, onChange, permission.isFalse])

    // Get mic permission on page load. Need to do it manually to have ability to stop stream immediately once permission granted
    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                setPermission(true)
                // reset stream
                stream.getTracks().forEach(track => track.stop())
            })
            .catch(err => {
                setPermission(false)
                console.error(err)
            })
    }, [setPermission])

    if (options.length === 0 || permission.isFalse) return null

    return (
        <Select
            data-test="editor-mic-selection"
            data-intercom="editor-mic-selection"
            fullWidth
            value={deviceId}
            displayEmpty
            variant="standard"
            onChange={({ target }) => {
                const value = target.value as string
                onChange(value as string)

                updateUserPreferences('micId', value)
            }}
            onOpen={() => logToAnalytics('voiceover_recordTab_microphone_clicked', { playbookId })}
        >
            {options.map(({ label, deviceId }) => (
                <MenuItem
                    key={deviceId}
                    value={deviceId}
                    color="inherit"
                    sx={{
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
                        }
                    }}
                >
                    {label}
                </MenuItem>
            ))}
        </Select>
    )
}
