import { type ReactNode } from 'react'

import { Box, Button, Dialog, DialogActions, Divider, IconButton, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

type Props = {
    isOpen: boolean
    onClose: () => void
    title: string
    text: ReactNode
    subtitle?: string
}

export const LimitationDialog = ({ isOpen, onClose, title, text, subtitle }: Props) => (
    <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="xs"
        PaperProps={{
            style: {
                maxWidth: 240
            }
        }}
    >
        <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography style={{ fontSize: 20, fontWeight: 500 }}>{title}</Typography>
            <IconButton size="small" onClick={onClose}>
                <CloseIcon fontSize="small" style={{ color: '#090C10' }} />
            </IconButton>
        </Box>
        <Box px={1}>
            <Divider />
        </Box>
        <Box px={2} py={1.2} minHeight={160}>
            <Typography style={{ fontSize: 14 }}>{text}</Typography>
            <Box mt={2} />
            <Typography style={{ fontSize: 14 }}>
                {subtitle || 'Please choose a smaller file or update the current one.'}
            </Typography>
        </Box>
        <DialogActions>
            <Button
                startIcon={<CheckIcon />}
                style={{
                    fontWeight: 500,
                    letterSpacing: 1.25
                }}
                onClick={onClose}
                variant="contained"
            >
                OK
            </Button>
        </DialogActions>
    </Dialog>
)
