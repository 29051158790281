import { useSelector } from 'react-redux'

import { Box, styled, Typography } from '@mui/material'

import { Label, SpacedGroup } from 'UI/Components'

const Title = styled(Typography)({
    fontSize: 20,
    fontWeight: 500,
    color: '#090C10'
})

const Subtitle = styled(Typography)({
    fontSize: 14,
    fontWeight: 400,
    color: 'rgba(9, 12, 16, 0.6)',
    paddingBottom: '8px',
    whiteSpace: 'nowrap'
})

type Props = {
    language: string
}

const TranscriptHeader = ({ language }: Props) => {
    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { audioNote } = steps?.[activeStep]

    const avatarUrl =
        audioNote?.type === 'textToSpeech' ? audioNote.speakerConfig.avatarUrlCompressed : null

    const name = audioNote?.type === 'textToSpeech' ? audioNote.speakerConfig.name : null

    return (
        <>
            {avatarUrl ? (
                <SpacedGroup height={30} mt={2.5} mb={2} alignContent="center">
                    <Box>
                        <img src={avatarUrl} alt="avatar" width={40} />
                    </Box>
                    <Box>
                        <Title>Transcript</Title>
                        <SpacedGroup>
                            {name && (
                                <Subtitle>
                                    {name}, {language}
                                </Subtitle>
                            )}
                        </SpacedGroup>
                    </Box>
                </SpacedGroup>
            ) : (
                <Box>
                    <Title>Transcript</Title>
                    <Label label={language} />
                </Box>
            )}
        </>
    )
}

export default TranscriptHeader
