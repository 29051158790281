import { type ReactNode, useEffect } from 'react'

import { Box, Typography } from '@mui/material'

import { useBoolean, useDataMutation, useNotification, useQuery, useRoles } from 'hooks'
import { generalErrorMessage, logToAnalytics } from 'modules'

import { ServiceUsageDialog } from 'UI/Components'

import { ReactComponent as StartCreatingImage } from 'assets/images/start-creating.svg'

type Props = {
    children: ReactNode
    setLoading?: (loading: boolean) => void
}
export const ProtectedViewerAction = ({ children, setLoading = () => {} }: Props) => {
    const { showErrorNotification } = useNotification()

    const dialogShown = useBoolean()

    const { isViewer } = useRoles()

    const { data, isValidating, mutate } = useQuery<{ allowed: boolean; admins?: string[] }>(
        '/c/v1/viewer-upgrade/get-status',
        {
            method: 'GET'
        },
        {
            revalidateOnMount: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
            onSuccess: data => {
                dialogShown.setTrue()
                logToAnalytics('videoportal_capture_click_successful', data)
            },
            onError: e => {
                showErrorNotification(generalErrorMessage)
                logToAnalytics('videoportal_capture_click_failed', e)
            }
        }
    )

    useEffect(() => {
        setLoading(isValidating)
    }, [isValidating, setLoading])

    const { mutate: upgradeToAdmin } = useDataMutation('/c/v1/viewer-upgrade', 'POST')

    const dialogProps = data?.allowed
        ? {
              title: 'Lace up and get in the game',
              description: `Create your own video spaces and share it with your team members.`,
              actionText: 'Create your own account',
              onActionClick: () => {
                  logToAnalytics('videoportal_create_account_click')
                  upgradeToAdmin({})
                      .then(dialogShown.setFalse)
                      .catch(e => {
                          showErrorNotification(generalErrorMessage)
                          logToAnalytics('videoportal_upgrade_failed', e)
                      })
              }
          }
        : {
              title: 'Start creating today',
              description: (
                  <Typography>
                      You can view everything, but to start capturing, contact{' '}
                      <Typography fontWeight={700}>
                          {data?.admins?.length === 1
                              ? data.admins[0]
                              : data?.admins?.sort().slice(0, 2).join(' or ') || 'admins'}
                      </Typography>
                  </Typography>
              ),
              actionText: 'Contact your admin',
              onActionClick: dialogShown.setFalse
          }

    return (
        <>
            <Box
                onClick={() => {
                    if (isViewer) {
                        mutate()
                    }
                }}
                sx={{ cursor: 'pointer' }}
            >
                <Box sx={{ pointerEvents: isViewer ? 'none' : 'auto' }}>{children}</Box>
            </Box>

            {dialogShown.isTrue && (
                <ServiceUsageDialog
                    {...dialogProps}
                    onClose={dialogShown.setFalse}
                    Image={<StartCreatingImage />}
                />
            )}
        </>
    )
}
