import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { type AnyPlaybookType, type PlaylistType } from 'app/types'
import {
    host,
    playbookToAnalyticsProps,
    globalProperties,
    userProperties,
    isLocalhost
} from 'modules'

import { useRoles } from './use-roles'
import { useAuth } from './use-auth'
import { useAnonymAuth } from './use-anonym-auth'
import { useDataMutation } from './use-data-mutation'
import { useQueryParams } from './use-query-params'

type Segment = {
    start: number // second point
    end: number
}

type CurrentSegment = {
    segmentIdx: number
    segment: Segment
}

type Props = {
    playbook: AnyPlaybookType
    playlist?: PlaylistType
    videoElement: HTMLVideoElement | null
}

const useVideoPlayAnalytics = ({ playbook, playlist, videoElement }: Props) => {
    const { isAnonymous } = useAnonymAuth()

    const params = useQueryParams()
    const origin = params.get('origin') || ''
    const utmCampaign = params.get('track_link_name') || ''
    const campaignId = params.get('track_link_id') || ''
    const spaceId = params.get('spaceId') || ''

    const videoPlayData = useMemo(
        () => ({
            eventData: {
                spaceId,
                origin,
                url: window.location.href,
                ...playbookToAnalyticsProps(
                    {
                        ...playbook,
                        campaignId,
                        utmCampaign,
                        playlist
                    },
                    isAnonymous
                )
            },
            userProperties,
            globalProperties
        }),
        [campaignId, isAnonymous, origin, playbook, playlist, spaceId, utmCampaign]
    )

    const [sessionId, setSessionId] = useState<string | null>(null)

    const $getSession = useDataMutation<any, { sessionId: string }, Error>(
        `/tk/v1/get-session?pb-id=${playbook.id}`,
        'POST',
        {
            onSuccess: ({ sessionId }) => setSessionId(sessionId),
            disableDefaultErrorHandling: true
        }
    )
    const getSessionMutate = $getSession.mutate

    const currentSegmentRef = useRef<CurrentSegment>({
        segment: { start: 0, end: 0 },
        segmentIdx: 0
    })

    const $updateSession = useDataMutation<
        CurrentSegment & { sessionId: string; reason: string },
        {},
        Error & { code: number }
    >('/tk/v1/update-session', 'POST', {
        onFailure: async error => {
            // Session expired, create new session and assign last unsaved segment to it
            if (error.code === 404 && !$getSession.isLoading) {
                currentSegmentRef.current = {
                    segment: currentSegmentRef.current.segment,
                    segmentIdx: 0
                }

                const response = await $getSession.mutate(videoPlayData)
                if (response) {
                    await $updateSession.mutate({
                        reason: 'restartSession',
                        sessionId: response.sessionId,
                        ...currentSegmentRef.current
                    })
                }
            }
        },
        disableDefaultErrorHandling: true
    })
    const updateSessionMutate = $updateSession.mutate

    const shouldUpdateSession = sessionId !== null && !$getSession.isLoading

    const handleUpdateSession = useCallback(
        // reason - doesn't influence on calculation logic, used for logging and trouble-shooting in future
        async ({ reason, onSuccess }: { onSuccess?: () => void; reason: string }) => {
            if (shouldUpdateSession) {
                await updateSessionMutate({ sessionId, reason, ...currentSegmentRef.current })
                onSuccess?.()
            }
        },
        [shouldUpdateSession, updateSessionMutate, sessionId]
    )

    const [isPlaying, setIsPlaying] = useState(false)
    const intervalId = useRef(0)

    const stopKeepAlive = useCallback(() => {
        clearInterval(intervalId.current)
        intervalId.current = 0
    }, [])

    useEffect(() => {
        if (isPlaying) {
            // Send keep alive request each minute
            const id = setInterval(() => handleUpdateSession({ reason: 'timer' }), 60 * 1000)
            intervalId.current = id as unknown as number

            return stopKeepAlive
        }

        stopKeepAlive()
    }, [handleUpdateSession, isPlaying, stopKeepAlive])

    useEffect(() => {
        if (!videoElement) return

        const handlePlay = () => {
            // Start new session or check is existing alive
            if (sessionId === null) {
                getSessionMutate(videoPlayData)
            } else {
                handleUpdateSession({ reason: 'play' })
            }

            setIsPlaying(true)
        }

        videoElement.addEventListener('play', handlePlay)

        return () => {
            videoElement.removeEventListener('play', handlePlay)
        }
    }, [getSessionMutate, handleUpdateSession, sessionId, videoElement, videoPlayData])

    const resetSegments = useCallback(() => {
        const currentSegment = currentSegmentRef.current

        currentSegmentRef.current = {
            segment: { start: currentSegment.segment.end, end: currentSegment.segment.end },
            segmentIdx: currentSegment.segmentIdx + 1
        }
    }, [])

    useEffect(() => {
        if (!videoElement) return

        const handlePause = () => {
            // Save changes, stop keep alive and start new segment
            setIsPlaying(false)

            handleUpdateSession({
                onSuccess: resetSegments,
                reason:
                    videoElement.duration === currentSegmentRef.current.segment.end
                        ? 'end'
                        : 'pause'
            })
        }

        videoElement.addEventListener('pause', handlePause)

        return () => {
            videoElement.removeEventListener('pause', handlePause)
        }
    }, [handleUpdateSession, resetSegments, videoElement])

    useEffect(() => {
        if (!videoElement) return

        const handleTimeUpdate = (event: Event) => {
            if (!event.target) return
            const time = (event.target as HTMLVideoElement).currentTime

            const currentSegment = currentSegmentRef.current
            const differenceInTime = time - currentSegment.segment.end

            // Skip forward or skip backwards - start of new segment
            // Skip forward starts from 2 seconds to distinguish this event between continuous video watch
            if (differenceInTime >= 2 || differenceInTime < 0) {
                handleUpdateSession({ reason: 'skip' })
                currentSegmentRef.current = {
                    segmentIdx:
                        sessionId !== null
                            ? currentSegment.segmentIdx + 1
                            : currentSegment.segmentIdx,
                    segment: { start: time, end: time }
                }

                return
            }

            currentSegmentRef.current = {
                segmentIdx: currentSegment.segmentIdx,
                segment: { start: currentSegment.segment.start, end: time }
            }
        }

        videoElement.addEventListener('timeupdate', handleTimeUpdate)

        return () => {
            videoElement.removeEventListener('timeupdate', handleTimeUpdate)
        }
    }, [handleUpdateSession, sessionId, videoElement])

    useEffect(() => {
        if (!videoElement) return

        const eventHandler = () => {
            // Send current data each time user leaves page
            if (document.visibilityState === 'hidden' && shouldUpdateSession) {
                navigator.sendBeacon(
                    `${host}/tk/v1/update-session`,
                    JSON.stringify({
                        sessionId,
                        ...currentSegmentRef.current,
                        reason: 'visibilitychange'
                    })
                )
            }
        }

        document.addEventListener('visibilitychange', eventHandler, true)

        return () => {
            document.removeEventListener('visibilitychange', eventHandler, true)
        }
    }, [shouldUpdateSession, sessionId, videoElement])

    const willUnmount = useRef<boolean>(false)

    useEffect(() => {
        return () => {
            willUnmount.current = true
        }
    }, [])

    useEffect(() => {
        return () => {
            // Needed only in case if video still playing
            if (willUnmount.current && videoElement && !videoElement.paused) {
                handleUpdateSession({ reason: 'leavePage' })
                stopKeepAlive()
            }
        }
    }, [handleUpdateSession, stopKeepAlive, videoElement])
}

const CheckedCollectVideoPlayAnalytics = (props: Props) => {
    useVideoPlayAnalytics(props)

    return null
}

export const CollectVideoPlayAnalytics = ({ playbook, ...props }: Props) => {
    const { isSuperAdmin } = useRoles()
    const { uid } = useAuth()

    const isOwner = uid === playbook.creator_uid

    if (!isSuperAdmin && !isOwner && !isLocalhost) {
        // Key is very important here, re-starts everything on changing active playbook in playlist
        return <CheckedCollectVideoPlayAnalytics playbook={playbook} {...props} key={playbook.id} />
    }

    return null
}
