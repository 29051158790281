import { useEffect, useRef } from 'react'

import { host } from 'modules'
import { getAuth } from 'firebase/auth'

type BeaconInput = {
    url: string
    data: { [key: string]: unknown }
}

export const useUnloadBeacon = async (
    { url, data }: BeaconInput,
    shouldSend?: boolean,
    sendOnUnmount?: boolean
) => {
    const payloadRef = useRef({})

    useEffect(() => {
        getAuth()
            .currentUser?.getIdToken()
            .then(token => {
                payloadRef.current = { ...data, authorization: `Bearer ${token}` }
            })
    }, [data])

    useEffect(() => {
        const eventHandler = () => {
            navigator.sendBeacon(host + url, JSON.stringify(payloadRef.current))
        }

        if (shouldSend) {
            window.addEventListener('beforeunload', eventHandler, true)
        }

        return () => {
            if (shouldSend) {
                window.removeEventListener('beforeunload', eventHandler, true)
                if (sendOnUnmount) {
                    // in case we unmounted the component but did not leave the site
                    eventHandler()
                }
            }
        }
    }, [url, shouldSend, sendOnUnmount])
}
