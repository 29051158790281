import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'

import { type BrandKitBackgroundType, type BrandKitType } from 'app/types'

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'

import { cardLimits } from 'UI/Routes/brand-kit/PageBrandKit'
import { SpacedGroup } from 'UI/Components'
import { type SectionProps, Section, ColorPopper, StepAppearancePreview } from './components'

import { noBrandKitBackgroundId, useBoolean, useNotification } from 'hooks'
import { logToAnalytics } from 'modules'

type Props = {
    values: {
        background: BrandKitType['background']
        qgHighlightColor: BrandKitType['qgHighlightColor']
        colors: BrandKitType['color']
        stepsNumberingColor: BrandKitType['stepsNumberingColor']
        isCalloutsEnabled: boolean
        isOverlayEnabled: boolean
    }
    active: {
        background?: BrandKitBackgroundType
        qgHighlightColor: string
        stepsNumberingColor: string
    }
} & SectionProps

export const StepAppearance = memo(
    ({ values, isLoading, active, popupProps, onAdd, onDelete, onSetActive, onEdit }: Props) => {
        const advancedBlockExpanded = useBoolean()
        const overlayLoading = useBoolean()
        const calloutsLoading = useBoolean()

        const { id: brandKitId } = useParams<{ id: string }>()

        const { showSuccessNotification } = useNotification()

        const isBackgroundOverLimit = values.background.data.length >= cardLimits.background
        const isQgHighlightColorOverLimit =
            values.qgHighlightColor.data.length >= cardLimits.qgHighlightColor
        const isStepNumberingColorOverLimit =
            values.stepsNumberingColor.data.length >= cardLimits.qgHighlightColor

        const setNewBackground = (newColor: string, type: 'color' | 'gradient' | 'image') => {
            logToAnalytics('brandKit_appearance_backgroundBtn_clicked', {
                workspaceId: brandKitId,
                color: newColor,
                type
            })

            const newActiveBackgroundId = values.background.data.find(color =>
                'url' in color ? newColor === color.url : newColor === color.gradient
            )?.id

            if (!newActiveBackgroundId) {
                console.error('Color not found in the list of colors', { newColor })
                return
            }

            onSetActive({
                background: newActiveBackgroundId
            }).then(() => showSuccessNotification('Background color changed'))
        }

        const setNewElementColor = (newColor: string) => {
            logToAnalytics('brandKit_appearance_elementBtn_clicked', {
                workspaceId: brandKitId,
                newColor
            })

            const newActiveColorId = values.qgHighlightColor.data.find(
                item => newColor === item.value
            )?.id

            if (!newActiveColorId) {
                console.error('Color not found in the list of `qgHighlightColor`colors', {
                    values: values.qgHighlightColor,
                    newColor
                })
                return
            }

            onSetActive({
                qgHighlightColor: newActiveColorId
            }).then(() => showSuccessNotification('Element color changed'))
        }

        const setNewNumbersColor = (newColor: string) => {
            logToAnalytics('brandKit_appearance_numberBtn_clicked', {
                workspaceId: brandKitId,
                newColor
            })

            const newActiveColorId = values.stepsNumberingColor.data.find(
                item => newColor === item.value
            )?.id

            if (!newActiveColorId) {
                console.error('Color not found in the list of `stepsNumberingColor` colors', {
                    values: values.stepsNumberingColor,
                    newColor
                })
                return
            }

            onSetActive({
                stepsNumberingColor: newActiveColorId
            }).then(() => showSuccessNotification('Number color changed'))
        }

        const activeBackground =
            active.background && 'url' in active.background
                ? active.background.url
                : active.background?.gradient || ''
        const activeElementsColor = active.qgHighlightColor
        const activeStepNumberingColor = active.stepsNumberingColor

        const activeBackgroundIsNoBg = active.background?.id === noBrandKitBackgroundId
        const activeBackgroundIsImage = activeBackground.includes('http')

        const scaleFactor = useMemo(() => {
            if (!active.background) return 1
            if ('scaleFactor' in active.background) return active.background.scaleFactor

            return activeBackgroundIsNoBg ? 1 : 0.8
        }, [active.background, activeBackgroundIsNoBg])

        const colorPopperIsDisabled = calloutsLoading.isTrue || overlayLoading.isTrue

        return (
            <Section
                popupProps={popupProps}
                title="Step Appearance"
                subtitle="Enable or disable layout default elements. Specify colors for a cohesive visual language."
                content={
                    <SpacedGroup alignItems="flex-start" ml={2}>
                        <StepAppearancePreview
                            scaleFactor={scaleFactor}
                            background={activeBackground}
                            numberingColor={activeStepNumberingColor}
                            backgroundIsNoBg={activeBackgroundIsNoBg}
                            backgroundIsImage={activeBackgroundIsImage}
                            isOverlayEnabled={values.isOverlayEnabled}
                            isCalloutsEnabled={values.isCalloutsEnabled}
                            elementsColor={activeElementsColor}
                        />
                        <Box>
                            <SpacedGroup>
                                <ColorPopper
                                    title="Background"
                                    popupProps={popupProps}
                                    activeColor={activeBackground}
                                    colors={values.background.data.map(it => {
                                        return 'url' in it ? it : it.gradient
                                    })}
                                    customAngle={values.background.customAngle}
                                    isOverLimit={isBackgroundOverLimit}
                                    overLimitTooltip={`Maximum number of background colors - ${cardLimits.background}`}
                                    onSetActiveColor={setNewBackground}
                                    onAddColor={background => {
                                        return onAdd({ background })
                                            .then(res => {
                                                if (!res) return
                                                showSuccessNotification('Background added')
                                            })
                                            .catch(console.error)
                                    }}
                                    onDeleteColor={colorId => {
                                        return onDelete({ background: colorId }).then(() => {
                                            showSuccessNotification('Background deleted')
                                        })
                                    }}
                                    disabled={colorPopperIsDisabled}
                                    isLoading={isLoading}
                                />

                                <ColorPopper
                                    title="Elements"
                                    popupProps={popupProps}
                                    activeColor={activeElementsColor}
                                    isOverLimit={isQgHighlightColorOverLimit}
                                    overLimitTooltip={`Maximum number of elements colors - ${cardLimits.qgHighlightColor}`}
                                    colors={values.qgHighlightColor.data.map(color => color.value)}
                                    disabled={colorPopperIsDisabled}
                                    onAddColor={newColor => {
                                        if ('url' in newColor) return Promise.resolve()
                                        return onAdd({
                                            qgHighlightColor: newColor[0]
                                        }).then(() => {
                                            showSuccessNotification('Elements color added')
                                        })
                                    }}
                                    onSetActiveColor={setNewElementColor}
                                    isLoading={isLoading}
                                />
                                <ColorPopper
                                    title="Numbers"
                                    popupProps={popupProps}
                                    activeColor={activeStepNumberingColor}
                                    isOverLimit={isStepNumberingColorOverLimit}
                                    overLimitTooltip={`Maximum number of elements colors - ${cardLimits.stepsNumberingColor}`}
                                    colors={values.stepsNumberingColor.data.map(
                                        color => color.value
                                    )}
                                    onSetActiveColor={setNewNumbersColor}
                                    disabled={colorPopperIsDisabled}
                                    onAddColor={newColor => {
                                        if ('url' in newColor) return Promise.resolve()

                                        return onAdd({
                                            stepsNumberingColor: newColor[0]
                                        }).then(() => {
                                            showSuccessNotification('Numbers color added')
                                        })
                                    }}
                                    isLoading={isLoading}
                                />
                            </SpacedGroup>

                            <SpacedGroup
                                style={{ cursor: 'pointer', width: 'min-content' }}
                                onClick={advancedBlockExpanded.toggle}
                                ml={1}
                                mt={1}
                            >
                                <Typography style={{ fontWeight: 500 }}>Advanced</Typography>

                                <ArrowDropDownOutlinedIcon
                                    style={{
                                        transition: 'transform 0.3s ease 0s',
                                        transform: `rotate(${
                                            advancedBlockExpanded.isTrue ? 180 : 0
                                        }deg)`
                                    }}
                                />
                            </SpacedGroup>

                            {advancedBlockExpanded.isTrue && (
                                <SpacedGroup ml={1}>
                                    <CheckBoxSection
                                        value={values.isOverlayEnabled}
                                        popupProps={popupProps}
                                        onClick={() => {
                                            overlayLoading.setTrue()

                                            const newStatus = !values.isOverlayEnabled
                                            const status = newStatus ? 'enabled' : 'disabled'

                                            logToAnalytics(
                                                'brandKit_appearance_overlayChk_selected',
                                                {
                                                    workspaceId: brandKitId,
                                                    state: newStatus ? 'on' : 'off'
                                                }
                                            )

                                            onEdit({
                                                isOverlayEnabled: newStatus
                                            })
                                                .then(() => {
                                                    showSuccessNotification(`Overlay ${status}`)
                                                })
                                                .finally(overlayLoading.setFalse)
                                        }}
                                        disabled={overlayLoading.isTrue}
                                        label="Overlay"
                                    />
                                    <CheckBoxSection
                                        value={values.isCalloutsEnabled}
                                        popupProps={popupProps}
                                        onClick={() => {
                                            calloutsLoading.setTrue()

                                            const newStatus = !values.isCalloutsEnabled
                                            const status = newStatus ? 'enabled' : 'disabled'

                                            logToAnalytics(
                                                'brandKit_appearance_calloutsChk_selected',
                                                {
                                                    workspaceId: brandKitId,
                                                    state: newStatus ? 'on' : 'off'
                                                }
                                            )

                                            onEdit({
                                                isCalloutsEnabled: newStatus
                                            })
                                                .then(() => {
                                                    showSuccessNotification(`Callouts ${status}`)
                                                })
                                                .finally(calloutsLoading.setFalse)
                                        }}
                                        disabled={calloutsLoading.isTrue}
                                        label="Callouts"
                                    />
                                </SpacedGroup>
                            )}
                        </Box>
                    </SpacedGroup>
                }
                showActionCard={false}
            />
        )
    }
)

type CheckBoxSectionProps = {
    value: boolean
    onClick: () => void
    disabled: boolean
    label: string
    popupProps: SectionProps['popupProps']
}

const CheckBoxSection = ({ value, onClick, disabled, label, popupProps }: CheckBoxSectionProps) => (
    <FormControlLabel
        value={value}
        onClick={e => (disabled ? undefined : popupProps.validateClick(e, onClick))}
        control={<Checkbox color="primary" disabled={disabled} checked={value} />}
        label={
            <SpacedGroup spacing={0.5}>
                <Typography style={{ fontSize: '14px', fontWeight: 500 }} color="textSecondary">
                    {label}
                </Typography>
            </SpacedGroup>
        }
    />
)
