import { type ReactNode, createContext, memo, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { DEFAULT_STEP } from './ControlPanel'

import { paths } from 'app/paths'

export const QGContext = createContext({
    scale: 1,
    frameWidth: 0,
    frameHeight: 0
})

type Props = {
    children: ReactNode
}

export const QgProvider = memo(({ children }: Props) => {
    const brandKitPage = useRouteMatch([paths.brandKitDetails, paths.brandKit])?.isExact

    const {
        present: { steps, activeStep }
    } = useSelector(state => state.qgEditor)

    const imageSize = useMemo(
        () => ({
            width: (steps[activeStep] || DEFAULT_STEP).windowDimensions.innerWidth,
            height: (steps[activeStep] || DEFAULT_STEP).windowDimensions.innerHeight
        }),
        [activeStep, steps]
    )

    const [scale, setScale] = useState(1)
    const [frameWidth, setFrameWidth] = useState(0)
    const [frameHeight, setFrameHeight] = useState(0)

    const contextValue = useMemo(
        () => ({
            scale,
            frameWidth,
            frameHeight
        }),
        [frameHeight, frameWidth, scale]
    )

    // Recalculate canvas size on screen resize
    useLayoutEffect(() => {
        const handleResize = () => {
            const workspace = document.getElementById('workspace')
            if (!workspace) return

            const { clientHeight: tempHeight, clientWidth: tempWidth } = workspace
            const scaleX = (brandKitPage ? window.innerWidth * 0.6 : tempWidth) / imageSize.width
            const scaleY = (brandKitPage ? window.innerHeight * 0.6 : tempHeight) / imageSize.height

            const dynamicScale = Math.min(scaleX, scaleY) * 0.9 // to add some spacing

            setScale(dynamicScale)
            setFrameWidth(imageSize.width * dynamicScale)
            setFrameHeight(imageSize.height * dynamicScale)
        }

        handleResize()

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [brandKitPage, imageSize.height, imageSize.width])

    return <QGContext.Provider value={contextValue}>{children}</QGContext.Provider>
})
