import { Box } from '@mui/material'

import { logToAnalytics, openLink, playbookToAnalyticsProps } from 'modules'

import { type QuickGuiddeType, type SingleCtaType } from 'app/types'
import { useOrganization } from 'hooks'
import { getRealCtaLink } from 'UI/Routes/quick-guidde/CanvasEditor'

type Props = {
    cta: SingleCtaType['action']
    playbook: QuickGuiddeType
    margin?: number
}

export const StepCTAPreview = ({ cta, playbook, margin = 3 }: Props) => {
    const { name: orgName } = useOrganization()

    const isLink = cta?.linkType === 'externalLink' || cta?.linkType === 'guiddeLink'

    const target = cta?.linkType === 'externalLink' ? '_blank' : '_self'

    return (
        <Box
            color="#2D9CDB"
            my={margin}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => {
                logToAnalytics('openCta', {
                    ...playbookToAnalyticsProps(playbook),
                    ...{
                        cta,
                        orgName
                    }
                })

                if (isLink) {
                    openLink(getRealCtaLink(cta), target)
                    return
                }

                //scroll to a specific step
                const element = document.getElementById(cta?.link || '')

                if (element) {
                    element.scrollIntoView()
                }
            }}
        >
            {cta.text}
        </Box>
    )
}
