import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Box } from '@mui/material'

import { StepsContext, StepsStatus, VisibilitySensor } from 'UI/Components'
import { DEFAULT_CIRCLE_WITH_TEXT_LAYER } from 'UI/Routes/quick-guidde/CanvasEditor'

import { DocStep } from './DocStep'
import { useWatchedStepsReporter } from './use-watched-steps-reporter'

import { type QuickGuiddeType, type StepType, Shape } from 'app/types'
import { useAuth, useOrgFeatureFlags, usePublicBrandKit, useRoles, useWindowView } from 'hooks'

export const filterIntoOutroSteps = (steps: Array<StepType>) => {
    return steps.filter(step => !['intro', 'outro'].includes(step.kind))
}

export const getStepNumberingColor = (steps: Array<StepType>) => {
    const regularSteps = steps.filter(
        step => step.kind === 'step' && step.layers.every(layer => layer.type !== Shape.Video)
    )

    if (!regularSteps.length) return DEFAULT_CIRCLE_WITH_TEXT_LAYER.text.color

    let numberingColor = ''

    for (let i = 0; i < regularSteps.length; i++) {
        if (numberingColor) break
        const step = regularSteps[i]

        step.layers.forEach(layer => {
            if (layer.type !== Shape.CircleWithText) return

            numberingColor = layer.text.color
        })
    }

    return numberingColor || DEFAULT_CIRCLE_WITH_TEXT_LAYER.text.color
}

type Props = {
    playbook: QuickGuiddeType
    isRtl?: boolean
}

export const Steps = ({ playbook: rawPlaybook, isRtl = false }: Props) => {
    // We do not need to show intro and outro steps in doc view at all
    const playbook = useMemo(() => {
        return {
            ...rawPlaybook,
            steps: filterIntoOutroSteps(rawPlaybook.steps)
        }
    }, [rawPlaybook])

    const { steps, creator_uid, uploadedByOrgId } = playbook

    const { hash, search } = useLocation()

    const history = useHistory()
    const historyReplace = history.replace

    const { disableInlineEdit } = useOrgFeatureFlags()

    const [watchedSteps, setWatchedSteps] = useState<Array<number>>([])

    const { stepsStatus, stepsProgress } = useContext(StepsContext)

    const { isMobileView } = useWindowView()

    const { uid } = useAuth()
    const { isContentManager, isAdmin, isSuperAdmin } = useRoles(uploadedByOrgId)

    // current `useEffect` can't be located inside QGNavigation
    // as such as QG steps are using in playbook & playlist
    // and playlist page doesn't have QGNavigation bar
    useEffect(() => {
        const element = document.getElementById(hash?.replace('#', ''))
        if (!element) return

        element.scrollIntoView()

        // search param is used in a playlist for getting active playbook
        historyReplace({
            hash: '',
            search
        })
    }, [hash, search, historyReplace])

    const canEdit =
        !isMobileView &&
        (uid === creator_uid || isContentManager || isAdmin || isSuperAdmin) &&
        !disableInlineEdit &&
        // MagicCapture flow is in action, need to wait until it's finished
        [StepsStatus.skipped, StepsStatus.done, StepsStatus.retry].includes(stepsStatus)

    const showMagicAnimation = [StepsStatus.generating, StepsStatus.typing].includes(stepsStatus)

    const { brandKitHighlightColor } = usePublicBrandKit(uploadedByOrgId)

    useWatchedStepsReporter({
        playbook,
        watchedSteps: watchedSteps.length <= 1 ? 0 : watchedSteps.length
    })

    const stepNumberingColor = useMemo(() => getStepNumberingColor(steps), [steps])

    if (!steps?.length) return null

    const goToStepIndex = steps.findIndex(step => step.kind === 'step' && !step.recordingEvent)

    return (
        <Box pt={1} m="0 auto" width={isMobileView ? '100%' : '85%'}>
            {steps.map((step, idx) => {
                const numberDelta = steps[0].kind === 'cover' ? 0 : 1
                const stepNumber = (idx + numberDelta).toString().padStart(2, '0')

                const originalIndex = idx + (rawPlaybook.steps[0].kind === 'intro' ? 1 : 0)

                return (
                    // id here are using in IntersectionObserver. please do not remove id
                    <Box id={step.id} key={step.id}>
                        <VisibilitySensor
                            placeholder={<Box height={450} mb={2} borderRadius="12px" />}
                            threshold={0.01}
                            delay={0}
                            triggerOnce={false}
                            inViewCallback={() => {
                                // CALCULATION RULES:

                                // 1. if a user never got to step 2, we don’t count it as a page view anyhow
                                // 2. if a user was at the top (video + step 1) and scrolled down to step 2, 3,
                                //     and 4 - so he viewed steps 1-4 (4/10 is 40%)
                                // 3. if a user was at the top (video + step 1) clicked on the navigation left
                                //     panel to step 8 and scrolled down to 9, 10 - so he viewed steps  8-10 (3/10 = 30%)

                                if (step.kind !== 'step') return

                                const validIdx = idx + numberDelta
                                setWatchedSteps(prev => [...new Set([...prev, validIdx])])
                            }}
                        >
                            <>
                                {/* Show first step as go to link if link exists or user has edit permission */}
                                {idx === goToStepIndex && (step.url || canEdit) && (
                                    <DocStep
                                        step={step}
                                        originalIndex={originalIndex}
                                        stepNumber={stepNumber}
                                        showGoToOnly
                                        stepNumberingColor={stepNumberingColor}
                                        canEdit={canEdit}
                                        playbook={playbook}
                                        isRtl={isRtl}
                                    />
                                )}

                                {/* All the rest steps as normal */}
                                <DocStep
                                    step={step}
                                    originalIndex={originalIndex}
                                    stepNumber={stepNumber}
                                    stepNumberingColor={stepNumberingColor}
                                    showGoToOnly={false}
                                    canEdit={canEdit || stepsProgress[idx] === 100}
                                    brandKitHighlightColor={brandKitHighlightColor}
                                    showMagicAnimation={
                                        showMagicAnimation && stepsProgress[idx] !== 100
                                    }
                                    playbook={playbook}
                                    isRtl={isRtl}
                                />
                            </>
                        </VisibilitySensor>
                    </Box>
                )
            })}
        </Box>
    )
}
