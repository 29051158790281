import { AddLinkDialog, Info } from 'UI/Components'

import { type PlaybookType } from 'app/types'

import { updateFiles, updateLinks, openLink } from 'modules'
import { useBoolean } from 'hooks'

type Props = {
    playbook: PlaybookType
}

export const EditAttachments = ({ playbook }: Props) => {
    const openModal = useBoolean()

    const { id, links, files } = playbook

    const onItemDelete = (itemId: string, isLink?: boolean) => {
        const items: any = isLink ? links : files
        const updatedItems = items.filter((item: any) => item.id !== itemId)
        if (isLink) {
            return updateLinks(id, updatedItems)
        }
        return updateFiles(id, updatedItems)
    }

    return (
        <>
            <Info
                playbook={playbook}
                isEditMode={true}
                isPublicMode={false}
                openModal={openModal.setTrue}
                openLink={openLink}
                onDelete={onItemDelete}
            />

            <AddLinkDialog
                playbook={playbook}
                open={openModal.isTrue}
                onClose={openModal.setFalse}
                updateLinks={updateLinks}
            />
        </>
    )
}
