import { type MutableRefObject, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Layer, Line } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'
import type Konva from 'konva'

import { RectangleShape } from './RectangleShape'
import { CircleShape } from './CircleShape'
import { ArrowShape } from './ArrowShape'
import { TextShape } from './TextShape'
import { ImageShape } from './ImageShape'
import { OverlayShape } from './OverlayShape'
import { StepNumbering } from './StepNumbering'
import { BgShapes } from './BgShapes'
import { BlurShape } from './BlurShape'
import { BrowserBarShape } from './BrowserBarShape'
import { AudioCircleShape } from './AudioCircleShape'
import { ImageCircleShape } from './ImageCircleShape'
import { VideoShape } from './VideoShape'
import { ShapeTransformer } from './ShapeTransformer'

import { selectLayer, setLayerPosition } from 'ducks'
import { logToAnalytics, round } from 'modules'
import { useBoolean } from 'hooks'

import { type StepType, Shape } from 'app/types'

import { calculateAlignment, isAligned } from './shape-utils'

const alignLineColor = '#00FFFF'

type Props = {
    stageRef?: MutableRefObject<Konva.Stage | null>
    scale: number
    step: StepType
    selectedLayersIds: Array<string>
    isSmartPreview: boolean
}

export const Shapes = memo(
    ({ stageRef, scale, step, selectedLayersIds, isSmartPreview }: Props) => {
        const dispatch = useDispatch()
        const { playbookId } = useParams<{ playbookId: string }>()

        const dragged = useBoolean()

        const [horizontalAlignment, setHorizontalAlignment] = useState(null)
        const [verticalAlignment, setVerticalAlignment] = useState(null)
        const [alignmentByCenterX, setAlignmentByCenterX] = useState(false)
        const [alignmentByCenterY, setAlignmentByCenterY] = useState(false)

        const { layers, windowDimensions } = step || {}

        const hasOverlay = Boolean(layers?.find(layer => layer.type === Shape.Overlay))

        const canvasCenter = useMemo(
            () => ({ x: windowDimensions.innerWidth / 2, y: windowDimensions.innerHeight / 2 }),
            [windowDimensions.innerHeight, windowDimensions.innerWidth]
        )

        const handleDragMove = useCallback(
            (e: KonvaEventObject<DragEvent>) => {
                // Only a single layer can be aligned at current version
                if (selectedLayersIds.length !== 1) return

                let xAlignment = null
                let yAlignment = null

                const targetLayer = e.target as Konva.Shape

                const { isAlignedToCenterX, isAlignedToCenterY, alignPosition } =
                    calculateAlignment(targetLayer, canvasCenter)

                if (isAlignedToCenterX || isAlignedToCenterY) {
                    // magnetize to the center of the canvas by the X or Y coordinate
                    targetLayer.setPosition(alignPosition)
                }

                setAlignmentByCenterX(isAlignedToCenterX)
                setAlignmentByCenterY(isAlignedToCenterY)

                const elements = e.target.getStage()?.find((layer: Konva.Shape) => {
                    return layer.attrs.className === 'alignable'
                }) as Array<Konva.Shape>

                // Check alignment with all layers
                elements.forEach(layer => {
                    // Ignore if layer is the same as the dragged one
                    if (layer === e.target) return
                    // Ignore if layer is rotated
                    if (e.target.attrs.rotation || layer.attrs.rotation) return

                    const { width, height } = layer.getClientRect({
                        skipTransform: true,
                        skipStroke: true
                    })
                    const { x, y } = layer.getPosition()

                    const { scaleX, scaleY, strokeWidth = 0 } = layer.attrs

                    // For circle-like shapes, we need to adjust the position from the center of the shape
                    const radiusAdjustment = layer.attrs.radius ? (height * scaleY) / 2 : 0
                    // For shapes with stroke, we need to adjust the position from the center of the stroke
                    const strokeAdjustment =
                        Math.max(e.target.attrs.strokeWidth || 0, strokeWidth) / 2 || 0

                    // Check horizontal alignment
                    if (isAligned(e.target, layer, 'xStart'))
                        xAlignment = x - radiusAdjustment - strokeAdjustment
                    if (isAligned(e.target, layer, 'xEnd'))
                        xAlignment = x + width * scaleX - radiusAdjustment + strokeAdjustment

                    // Check vertical alignment
                    if (isAligned(e.target, layer, 'yStart'))
                        yAlignment = y - radiusAdjustment - strokeAdjustment
                    if (isAligned(e.target, layer, 'yEnd'))
                        yAlignment = y + height * scaleY - radiusAdjustment + strokeAdjustment
                })

                setHorizontalAlignment(xAlignment)
                setVerticalAlignment(yAlignment)
            },
            [canvasCenter, selectedLayersIds.length]
        )

        const handleDragEnd = useCallback(
            (object: string) => (e: KonvaEventObject<DragEvent>) => {
                dragged.setFalse()

                dispatch(
                    setLayerPosition({
                        layerId: e.target.attrs.id,
                        x: round(e.target.attrs.x),
                        y: round(e.target.attrs.y)
                    })
                )

                logToAnalytics('qg-edit', {
                    objectType: object,
                    actionType: 'move',
                    playbookId
                })
            },
            [dispatch, dragged, playbookId]
        )

        const [selectedLayersRef, setSelectedLayersRef] = useState<Array<Konva.Node>>([])

        useEffect(() => {
            if (isSmartPreview || !stageRef?.current) return

            const newLayers = stageRef.current.find((layer: Konva.Node) => {
                const layerId = layer?.attrs?.id
                if (!layerId) return false

                return selectedLayersIds.includes(layerId)
            })

            setSelectedLayersRef(newLayers || [])
            // Note: we need layers here to update reference
        }, [isSmartPreview, selectedLayersIds, stageRef, layers])

        return (
            <>
                {/* Separated Layer for Background Image and Gradient layers */}
                <BgShapes step={step} />

                <Layer>
                    {layers?.map((layer, idx) => {
                        const commonProps = {
                            key: idx,
                            layerIdx: idx,
                            layer: layer as any, // Known typescript issue due to switch-case
                            onSelect: (multiSelect?: boolean) => {
                                dispatch(selectLayer(layer.id, multiSelect))
                            },
                            onDragStart: dragged.setTrue,
                            onDragMove: handleDragMove
                        }

                        switch (layer.type) {
                            case Shape.Rectangle:
                                if (layer.isBackground) return null

                                return (
                                    <RectangleShape
                                        {...commonProps}
                                        hasOverlay={hasOverlay}
                                        onDragEnd={handleDragEnd('rectangle')}
                                    />
                                )

                            case Shape.Circle:
                                return (
                                    <CircleShape
                                        {...commonProps}
                                        hasOverlay={hasOverlay}
                                        onDragEnd={handleDragEnd('circle')}
                                    />
                                )

                            case Shape.ImageCircle:
                                return (
                                    <ImageCircleShape
                                        {...commonProps}
                                        onDragEnd={handleDragEnd('circle')}
                                    />
                                )

                            case Shape.Text:
                                return (
                                    <TextShape
                                        {...commonProps}
                                        scale={scale}
                                        isSmartPreview={isSmartPreview}
                                        onDragEnd={handleDragEnd('text')}
                                    />
                                )

                            case Shape.Arrow:
                                return (
                                    <ArrowShape
                                        {...commonProps}
                                        onDragEnd={handleDragEnd('arrow')}
                                    />
                                )

                            case Shape.Image:
                                // Bg image should have another <Layer /> wrapper
                                if (layer.isBackground) return null

                                return (
                                    <ImageShape
                                        {...commonProps}
                                        onDragEnd={handleDragEnd('image')}
                                    />
                                )

                            case Shape.Overlay:
                                return (
                                    <OverlayShape
                                        key={idx}
                                        layer={layer}
                                        onSelect={multiSelect => {
                                            dispatch(selectLayer(layer.id, multiSelect))
                                        }}
                                    />
                                )

                            case Shape.CircleWithText:
                                return (
                                    <StepNumbering
                                        {...commonProps}
                                        scale={scale}
                                        onDragEnd={handleDragEnd('circle_with_text')}
                                    />
                                )

                            case Shape.Blur:
                                return (
                                    // We control rect only, but in fact there is another layer - Image which is below the overlay layer and sync with this rect
                                    <BlurShape {...commonProps} onDragEnd={handleDragEnd('blur')} />
                                )

                            case Shape.BrowserBar:
                                return <BrowserBarShape key={idx} layer={layer} />

                            case Shape.AudioCircle:
                                return (
                                    <AudioCircleShape
                                        {...commonProps}
                                        onDragEnd={handleDragEnd('blur')}
                                    />
                                )

                            case Shape.Video:
                                return (
                                    <VideoShape
                                        key={idx}
                                        layer={layer}
                                        isSmartPreview={isSmartPreview}
                                    />
                                )

                            default:
                                return null
                        }
                    })}

                    {!isSmartPreview && <ShapeTransformer shapes={selectedLayersRef} />}
                </Layer>

                {dragged.isTrue && (
                    <Layer>
                        {/* Vertical line */}
                        {horizontalAlignment && (
                            <Line
                                points={[
                                    horizontalAlignment,
                                    0,
                                    horizontalAlignment,
                                    windowDimensions.innerHeight / scale
                                ]} // xStart, yStart, xEnd, yEnd
                                stroke={alignLineColor}
                                strokeWidth={3}
                                dash={[10, 10]}
                            />
                        )}

                        {/* Horizontal line */}
                        {verticalAlignment && (
                            <Line
                                points={[
                                    0,
                                    verticalAlignment,
                                    windowDimensions.innerWidth / scale,
                                    verticalAlignment
                                ]} // xStart, yStart, xEnd, yEnd
                                stroke={alignLineColor}
                                strokeWidth={3}
                                dash={[10, 10]}
                            />
                        )}

                        {/* Center line by X */}
                        {alignmentByCenterX && (
                            <Line
                                points={[
                                    canvasCenter.x,
                                    0,
                                    canvasCenter.x,
                                    windowDimensions.innerHeight
                                ]} // xStart, yStart, xEnd, yEnd
                                stroke={alignLineColor}
                                strokeWidth={3}
                                dash={[10, 10]}
                            />
                        )}
                        {/* Center line by Y */}
                        {alignmentByCenterY && (
                            <Line
                                points={[
                                    0,
                                    canvasCenter.y,
                                    windowDimensions.innerWidth,
                                    canvasCenter.y
                                ]} // xStart, yStart, xEnd, yEnd
                                stroke={alignLineColor}
                                strokeWidth={3}
                                dash={[10, 10]}
                            />
                        )}
                    </Layer>
                )}
            </>
        )
    }
)
