import { type ChangeEvent, useState } from 'react'

import { CircularProgress } from '@mui/material'
import { AttachFile as FileIcon } from '@mui/icons-material'

import { useBoolean } from 'hooks'
import { getFirebaseUrl, uploadWithSignedUrl } from 'modules'
import { firebaseConfig } from 'env'

const ALLOWED_FORMATS = '.srt,.vtt'

type Props = {
    storagePath: string
    onDone: (url: string) => void
}

export const SubtitleUploader = ({ storagePath, onDone }: Props) => {
    const loading = useBoolean()

    const [file, setFile] = useState<File | null>(null)

    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0]

        if (!selectedFile) {
            setFile(null)
            return
        }

        setFile(selectedFile)
        loading.setTrue()

        const bucket = 'gs://' + `${firebaseConfig.projectId}-temporary`

        await uploadWithSignedUrl(bucket + '/' + storagePath, selectedFile)
        const url = await getFirebaseUrl(storagePath, true)

        loading.setFalse()
        onDone(url)
    }

    return (
        <label
            style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginRight: '10px',
                padding: '0'
            }}
            data-test="file-uploader-wrapper"
        >
            {loading.isTrue && <CircularProgress size={14} />}

            <input
                type="file"
                onChange={handleChange}
                accept={ALLOWED_FORMATS}
                style={{ display: 'none' }}
            />
            <FileIcon
                fontSize="small"
                sx={{
                    color: '#2D9CDB'
                }}
                data-test="file-icon"
            />
            {file?.name || 'Upload'}
        </label>
    )
}
