import { createContext, useCallback, useContext, useState } from 'react'

const RECHART_CARTESIAN_AXIS_TICK_VALUE_SELECTOR = `.recharts-cartesian-axis-tick-value[orientation="left"],
.recharts-cartesian-axis-tick-value[orientation="right"]`

const defaultWidth = 40
const spaceToChartGrid = 15

export const SyncYAxisWidthContext = createContext<null | {
    value: number
    setValue: (value: number) => void
}>(null)

export const useDynamicYAxisWidth = () => {
    // If value inside context - take maximum possible width and set it to all charts to be aligned in one line
    const contextYAxisWidth = useContext(SyncYAxisWidthContext)

    const [yAxisWidthState, setYAxisWidthState] = useState(defaultWidth)

    const setChartRef = useCallback(
        (chartRef: HTMLDivElement) => {
            if (chartRef != null) {
                const tickValueElements = chartRef.querySelectorAll(
                    RECHART_CARTESIAN_AXIS_TICK_VALUE_SELECTOR
                )

                const lastElement = [...tickValueElements].at(-1)
                const width = lastElement?.getBoundingClientRect().width ?? 0

                if (contextYAxisWidth) {
                    if (contextYAxisWidth.value < width) {
                        contextYAxisWidth.setValue(width)
                    }

                    return
                }

                setYAxisWidthState(width)
            }
        },
        [contextYAxisWidth]
    )

    const yAxisWidth = contextYAxisWidth ? contextYAxisWidth.value : yAxisWidthState

    return {
        yAxisWidth: yAxisWidth + spaceToChartGrid,
        setChartRef
    }
}
