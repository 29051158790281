import { type TypographyVariant, styled, Typography, Box } from '@mui/material'

const PREFIX = 'Label'

const classes = {
    label: `${PREFIX}-label`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.label}`]: {
        color: '#7f8c9a',
        [theme.breakpoints.up('md')]: {
            fontSize: '10px'
        }
    }
}))

type Props = {
    label: string
    hidden?: boolean
    variant?: TypographyVariant
    mt?: number
    mb?: number
}

export const Label = ({ label, hidden, variant = 'body2', ...rest }: Props) => {
    if (!label || hidden) return null

    return (
        <StyledBox {...rest}>
            <Typography variant={variant} className={classes.label}>
                {label}
            </Typography>
        </StyledBox>
    )
}
