import { memo, useState } from 'react'

import { Autocomplete, Box, IconButton, RadioGroup, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBackOutlined'

import { SpacedGroup } from 'UI/Components'
import { type TabsType, tabs } from './SpaceFields'
import { CheckBoxOption } from './CheckBoxOption'
import { Option } from './Option'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'

import { type SetFieldValueType, type FormSpaceType, type UserOption } from './types'

import { SPACE_ROLES, validateEmail } from 'modules'
import { useNotification } from 'hooks'

type Props = {
    setActiveTab: (tabs: TabsType) => void
    setFieldValue: SetFieldValueType
    onClose: () => void
    values: FormSpaceType
    disabled: boolean
    members: Array<UserOption>
}

export const MembersTab = memo(
    ({ members, values, disabled, onClose, setActiveTab, setFieldValue }: Props) => {
        const [value, setValue] = useState('')

        const { showErrorNotification } = useNotification()

        return (
            <>
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                    <SpacedGroup spacing={1}>
                        <IconButton onClick={() => setActiveTab(tabs.main)} size="small">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography style={{ fontWeight: 500 }}>
                            {values.name} Space Members
                        </Typography>
                    </SpacedGroup>

                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Autocomplete
                    freeSolo
                    clearOnEscape
                    clearOnBlur
                    disableClearable={false}
                    disabled={disabled}
                    options={Object.values(values.members)}
                    getOptionLabel={it => (it as UserOption).displayName || ''}
                    filterSelectedOptions={true}
                    inputValue={value}
                    filterOptions={(options, state) => {
                        if (state.inputValue.length < 2) return []

                        return options.filter(item => {
                            const searchValue = state.inputValue.toLowerCase()
                            const userName = item.displayName.toLowerCase()
                            const userEmail = item.email.toLowerCase()

                            return userName.includes(searchValue) || userEmail.includes(searchValue)
                        })
                    }}
                    renderOption={(props, option) => <Option {...props} user={option} />}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Search for members"
                            margin="normal"
                            variant="filled"
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }}
                            onChange={e => setValue(e.target.value)}
                            sx={{
                                borderRadius: '4px 4px 0 0',
                                background: '#F5F5F5',
                                '& div': {
                                    background: '#F5F5F5 !important'
                                },
                                '& div:before': {
                                    borderBottom: '1px solid rgba(9, 12, 16, 0.08) !important'
                                },
                                '&:before': {
                                    borderBottom: '1px solid rgba(9, 12, 16, 0.08) !important'
                                },
                                '& input': {
                                    padding: '22px 12px 8px'
                                }
                            }}
                            InputProps={{
                                ...params.InputProps
                            }}
                        />
                    )}
                    onChange={(_e, option, reason) => {
                        if (!option) return

                        // external email
                        if (typeof option === 'string') {
                            if (!validateEmail(option)) {
                                return showErrorNotification('Email is not valid')
                            }

                            setFieldValue('members', {
                                ...values.members,
                                [option]: {
                                    email: option,
                                    displayName: '',
                                    logo: '',
                                    uid: '',
                                    isSelected: true,
                                    spaceRole: SPACE_ROLES.COLLABORATOR
                                }
                            })
                        } else {
                            setFieldValue('members', {
                                ...values.members,
                                [option.uid]: {
                                    ...option,
                                    isSelected: true,
                                    spaceRole: SPACE_ROLES.COLLABORATOR
                                }
                            })
                        }

                        if (['createOption', 'selectOption'].includes(reason)) setValue('')
                    }}
                />
                <Box height={345} style={{ overflowY: 'auto' }}>
                    {members
                        .filter(it => it.isSelected)
                        ?.map(o => {
                            // disable owners if only one owner selected
                            const disabled =
                                o.spaceRole === SPACE_ROLES.OWNER &&
                                members.filter(it => it.spaceRole === SPACE_ROLES.OWNER).length < 2

                            const onChangeRole = (value: number) => {
                                const key = o.uid || o.email

                                setFieldValue('members', {
                                    ...values.members,
                                    [key]: {
                                        ...values.members[key],
                                        spaceRole: value
                                    }
                                })
                            }

                            return (
                                <Box
                                    mt={3}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    key={o.uid}
                                    width="100%"
                                    overflow="hidden"
                                >
                                    <Box width="45%">
                                        <Option user={o} />
                                    </Box>
                                    <Box
                                        width="55%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <RadioGroup
                                            row={true}
                                            name="roles"
                                            style={{
                                                flexWrap: 'nowrap'
                                            }}
                                            value={o.spaceRole}
                                        >
                                            <CheckBoxOption
                                                value={SPACE_ROLES.OWNER}
                                                label="Owner"
                                                option={o}
                                                disabled={disabled}
                                                onChange={onChangeRole}
                                            />
                                            <CheckBoxOption
                                                value={SPACE_ROLES.COLLABORATOR}
                                                label="Collaborator"
                                                option={o}
                                                disabled={disabled}
                                                onChange={onChangeRole}
                                            />
                                            <CheckBoxOption
                                                value={SPACE_ROLES.VIEWER}
                                                label="Viewer"
                                                option={o}
                                                disabled={disabled}
                                                onChange={onChangeRole}
                                            />
                                        </RadioGroup>
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            aria-label="delete"
                                            disabled={disabled}
                                            onClick={() => {
                                                const isExternal = !o.uid

                                                if (isExternal) {
                                                    const copy = values.members

                                                    delete copy[o.email]

                                                    setFieldValue('members', copy)
                                                    return
                                                }

                                                setFieldValue('members', {
                                                    ...values.members,
                                                    [o.uid]: {
                                                        ...o,
                                                        isSelected: false,
                                                        spaceRole: SPACE_ROLES.VIEWER
                                                    }
                                                })
                                            }}
                                        >
                                            <BinIcon
                                                style={{
                                                    color: disabled ? 'inherit' : '#090C10'
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )
                        })}
                </Box>
            </>
        )
    }
)
