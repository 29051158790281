import { memo, useEffect, useMemo, useState } from 'react'

import {
    type MultipleCtaType,
    type QuickGuiddeType,
    type SingleCtaType,
    type StepType
} from 'app/types'

import { CallToAction } from './CallToAction'
import { CallToActionMultiple } from './CallToActionMultiple'

import { useBoolean } from 'hooks'

const STEP_TIME_OFFSET = 0.001
const LAST_STEP_TIME_OFFSET = 0.0

type Props = {
    videoElement: HTMLVideoElement | null
    playbook: QuickGuiddeType
    onCTaVisibilityChange: (visible: boolean) => void
}

export const CallToActionWrapperQG = memo(
    ({ onCTaVisibilityChange, videoElement, playbook }: Props) => {
        const { steps = [], slicingSuggestion, duration } = playbook

        const [localSteps, setLocalSteps] = useState<StepType[]>([])

        const [currentTime, setCurrentTime] = useState(0)

        const isLastStep = useBoolean(true)

        const isShowMultiCta = useBoolean(true)

        const isShowMultiCtaSet = isShowMultiCta.set

        const multipleCta = useMemo(() => {
            if (!videoElement) return false

            const stepIndex = slicingSuggestion?.findIndex((chapter, index) => {
                const delta = chapter.end - currentTime

                if (delta <= 0.3 && delta >= 0) {
                    // current step (based on time) has a multi CTA
                    if (steps[index]?.cta?.ctaType === 'multiple') {
                        // pause the video only if the video is not in full screen mode
                        if (!document.fullscreenElement) {
                            videoElement.pause()
                            videoElement.currentTime =
                                chapter.end -
                                (isLastStep.isFalse ? STEP_TIME_OFFSET : LAST_STEP_TIME_OFFSET)
                        }
                        return true
                    }
                }
            }) as number

            isLastStep.set(stepIndex + 1 === steps.length)

            isShowMultiCtaSet(true)

            if (stepIndex === -1 || !Boolean(steps.length)) return false

            return steps[stepIndex]?.cta as MultipleCtaType
        }, [videoElement, slicingSuggestion, currentTime, isLastStep, steps, isShowMultiCtaSet])

        const singleCta = useMemo(() => {
            if (!videoElement || !localSteps.length) return false

            const stepIndex = slicingSuggestion?.findIndex((chapter, index) => {
                if (currentTime && currentTime >= chapter.start && currentTime <= chapter.end) {
                    // current step (based on time) has a multi CTA
                    const currentCTA = localSteps[index]?.cta

                    if (currentCTA?.ctaType === 'single' && currentCTA?.action?.enabled) return true
                }
            }) as number

            if (stepIndex === -1 || !Boolean(localSteps.length)) return false

            return (localSteps[stepIndex]?.cta as SingleCtaType)?.action
        }, [videoElement, slicingSuggestion, currentTime, localSteps])

        useEffect(() => {
            const fetchImageAsBlob = async (url: string) => {
                const response = await fetch(url)
                const blob = await response.blob()
                return URL.createObjectURL(blob)
            }

            const processStep = async (step: StepType) => {
                if (!(step.cta?.ctaType === 'single' && Boolean(step.cta?.action?.image)))
                    return step

                const blobUrl = await fetchImageAsBlob(step.cta?.action.image as string)

                return {
                    ...step,
                    cta: {
                        ...step.cta,
                        action: {
                            ...step.cta?.action,
                            image: blobUrl
                        }
                    }
                }
            }

            Promise.all(playbook.steps.map(processStep)).then(setLocalSteps)
        }, [playbook.steps])

        useEffect(() => {
            if (!videoElement) return
            const handleTimeUpdate = (e: Event) => {
                setCurrentTime((e.target as HTMLVideoElement).currentTime)
            }

            videoElement.addEventListener('timeupdate', handleTimeUpdate)
            return () => {
                videoElement.removeEventListener('timeupdate', handleTimeUpdate)
            }
        }, [currentTime, slicingSuggestion, steps, videoElement, multipleCta])

        useEffect(() => {
            onCTaVisibilityChange(multipleCta && isShowMultiCta.isTrue)
        }, [isShowMultiCta.isTrue, multipleCta, onCTaVisibilityChange])

        if (!videoElement) return null

        return (
            <>
                {singleCta && (
                    <CallToAction cta={singleCta} videoElement={videoElement} playbook={playbook} />
                )}

                {multipleCta && isShowMultiCta.isTrue && (
                    <CallToActionMultiple
                        qgCTA={multipleCta}
                        playbook={playbook}
                        videoElement={videoElement}
                        onSkip={() => {
                            // for last step set the time to the end of the video
                            // hide the multi cta and play the video.
                            // playing the video prevents the user to click twice in order to restart the video
                            if (isLastStep.isTrue) {
                                videoElement.currentTime = duration
                                isShowMultiCtaSet(false)
                                return
                            }
                            // for any step but last continue to play
                            videoElement.play()
                        }}
                    />
                )}
            </>
        )
    }
)
