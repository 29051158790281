import { type CSSProperties } from 'react'
import { generatePath } from 'react-router-dom'

import { type QuickGuiddeType } from 'app/types'
import { paths } from 'app/paths'

import { host } from 'modules'

type Props = {
    playbook: QuickGuiddeType
    enableNgnixLinks?: boolean
    width?: CSSProperties['width']
}

export const GifImageWithPlayButton = ({ playbook, enableNgnixLinks, width = '100%' }: Props) => {
    const { id, steps = [] } = playbook

    const coverImgField = enableNgnixLinks ? 'docCoverPublicScreenshot' : 'docCoverScreenshot'

    const pbLink = generatePath(paths.publicPlaybook, {
        playbookId: id
    })

    // use the new image with play button if exists
    // only the first step has this field
    const screenshotUrl = steps?.[0]?.[coverImgField] || playbook.gifUrl

    if (!screenshotUrl) return null

    return (
        <div style={{ maxWidth: '100%' }}>
            <a href={host + pbLink} target="_blank" rel="noreferrer" style={{ maxWidth: '100%' }}>
                Click here to watch
            </a>

            <h3 style={{ maxWidth: '100%' }}>
                <img src={screenshotUrl} alt="Quick guidde" width={width} />
            </h3>
        </div>
    )
}
