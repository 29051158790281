import { type ReactNode, memo } from 'react'
import { Box, Grid, LinearProgress } from '@mui/material'

import { PublicHeader } from './PublicHeader'

import { isQG } from 'modules'
import { usePublicBrandKit } from 'hooks'

import { type AnyPlaybookType, PlaybookMode } from 'app/types'

type Props = {
    playbook: AnyPlaybookType
    sidePanel?: ReactNode
    playbookTitle: string
    children: ReactNode
}

export const PublicDesktopPlaybookContainer = memo(
    ({ children, sidePanel, playbook, playbookTitle }: Props) => {
        const { mode, uploadedByOrgId } = playbook

        const isPlaylist = mode === PlaybookMode.Playlist

        const { logoAndFavicon, brandKitLoading } = usePublicBrandKit(uploadedByOrgId)

        const header = (
            <Box height={72}>
                <PublicHeader
                    customLogo={logoAndFavicon}
                    playbook={playbook}
                    playbookTitle={playbookTitle}
                />
            </Box>
        )

        if (brandKitLoading) return <LinearProgress />

        return (
            <Box
                minHeight="100vh"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                px={isPlaylist ? 0 : 4}
            >
                {!isPlaylist && header}

                {isQG(playbook) ? (
                    <Box display="flex" justifyContent="space-between" width="100%">
                        {sidePanel && (
                            <Box
                                position="sticky"
                                top={72}
                                bottom={0}
                                left={32}
                                height="calc(100vh - 72px)"
                            >
                                {sidePanel}
                            </Box>
                        )}
                        <Box position="relative" px={8} width="100%">
                            {children}
                        </Box>
                    </Box>
                ) : (
                    <Grid container justifyContent="center" style={{ height: '100%' }}>
                        {sidePanel && (
                            <Grid item xs={12} md={3} xl={2}>
                                {sidePanel}
                            </Grid>
                        )}
                        <Grid item xs={12} md={sidePanel ? 9 : 12} xl={sidePanel ? 10 : 12}>
                            <Box
                                px={isPlaylist ? 4 : 0}
                                height={isPlaylist ? '100vh' : 'auto'}
                                overflow="auto"
                            >
                                {isPlaylist && header}
                                <Box position="relative">{children}</Box>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        )
    }
)
