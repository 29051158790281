import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Box } from '@mui/material'

import { SpacedGroup } from 'UI/Components'

type Props = {
    deviceId: string
    amountOfDots?: number
}

export const VolumeMeter = ({ deviceId, amountOfDots = 13 }: Props) => {
    const streamRef = useRef<MediaStream | null>(null)
    const audioContextRef = useRef<AudioContext | null>(null)

    const [volume, setVolume] = useState(0)

    const DOTS_ARRAY = useMemo(() => new Array(amountOfDots).fill(''), [amountOfDots])

    const cleanUp = useCallback(() => {
        streamRef.current?.getAudioTracks()?.forEach(track => track.stop())
        streamRef.current = null

        audioContextRef.current?.close()
        audioContextRef.current = null
    }, [])

    useEffect(() => {
        if (!deviceId) return

        if (streamRef.current || audioContextRef.current) {
            cleanUp()
        }

        // Connect to microphone and use the volume value on VolumeMeter component
        navigator.mediaDevices
            .getUserMedia({
                audio: { deviceId }
            })
            .then(stream => {
                streamRef.current = stream
                audioContextRef.current = new AudioContext()

                const analyser = audioContextRef.current.createAnalyser()
                const microphone = audioContextRef.current.createMediaStreamSource(stream)
                const scriptProcessor = audioContextRef.current.createScriptProcessor(2048, 1, 1)

                analyser.smoothingTimeConstant = 0.8
                analyser.fftSize = 1024

                microphone.connect(analyser)
                analyser.connect(scriptProcessor)
                scriptProcessor.connect(audioContextRef.current.destination)
                scriptProcessor.onaudioprocess = function () {
                    const array = new Uint8Array(analyser.frequencyBinCount)
                    analyser.getByteFrequencyData(array)
                    const arraySum = array.reduce((a, value) => a + value, 0)
                    const average = arraySum / array.length
                    setVolume(Math.round(average))
                }
            })
            .catch(console.error)

        return () => {
            cleanUp()
        }
    }, [cleanUp, deviceId])

    return (
        <SpacedGroup spacing={1} justifyContent="space-between" width="100%">
            {DOTS_ARRAY.map((_dot, index) => (
                <Box
                    key={index}
                    sx={{
                        minWidth: '8px',
                        minHeight: '8px',
                        background: '#E0E0E0',
                        borderRadius: '50%',
                        backgroundColor:
                            volume / (100 / amountOfDots) > index ? '#CB0000' : '#E0E0E0'
                    }}
                />
            ))}
        </SpacedGroup>
    )
}
