import { useCallback, useMemo } from 'react'

import { type AnyPlaybookType } from 'app/types'

import { useOrganization } from './use-organization'
import { useServiceUsage } from './use-service-usage'
import { useGlobalFeatureFlags } from './use-feature-flags'

export const useCustomerDomain = () => {
    const { hasCustomSubdomain } = useServiceUsage()

    const { doNotUseCustomSubdomain } = useGlobalFeatureFlags()

    const { vanityUrl } = useOrganization()

    const generateDomain = useCallback(
        ({ isPublic }: AnyPlaybookType) => {
            if (doNotUseCustomSubdomain) {
                return window.location.origin
            }

            if (isPublic && hasCustomSubdomain && vanityUrl) {
                const { protocol, host } = window.location

                return `${protocol}//${vanityUrl}.${host}`
            }

            return window.location.origin
        },
        [hasCustomSubdomain, vanityUrl, doNotUseCustomSubdomain]
    )

    return useMemo(() => ({ generateDomain }), [generateDomain])
}
