import { type ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'

import DefaultImage from 'assets/images/bran-kit2.png'
import { ReactComponent as Icon } from 'assets/icons/medal.svg'

import { type PopoverOrigin, Popover, Button, Typography, Box, LinearProgress } from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircle'

import { showPlanDialog } from 'ducks'

import { SpacedGroup } from 'UI/Components'

import { type PlanIdType, useStigg, usePayment } from 'hooks'

const boxShadow =
    '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'

export type UpgradePlanProps = {
    isLoading?: boolean
    openFeatures?: boolean
    hideSeePlanFeatures?: boolean
    imageSrc?: string
    points?: Array<string>
    title?: string
    text?: ReactNode
    anchorEl: HTMLElement | null
    setAnchorEl: (val: HTMLElement | null) => void
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    planId?: PlanIdType
    onClose?: () => void
    hidePrice?: boolean
    customActionButton?: ReactNode
}

export const UpgradePlanPopup = ({
    isLoading: isLoadingProp,
    customActionButton,
    title = 'Upgrade your Account',
    text = 'Employee and Customer Enablement. Share the knowhow internally and externally.',
    imageSrc = DefaultImage,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'center'
    },
    transformOrigin = {
        vertical: 'center',
        horizontal: 'center'
    },
    anchorEl,
    hidePrice = false,
    hideSeePlanFeatures,
    points,
    openFeatures,
    setAnchorEl,
    onClose,
    planId
}: UpgradePlanProps) => {
    const history = useHistory()

    const dispatch = useDispatch()
    const { isLoading: isTrialLoading } = usePayment()

    const { stiggLoading, brandKit, getProPricePerMonth } = useStigg()

    if (stiggLoading) return null

    const openUpgradePlanDialog = () => {
        onClose?.()
        setAnchorEl(null)
        dispatch(showPlanDialog('upgradePlanDialog'))
    }

    const list = points || brandKit?.features
    const monthlyPrice = planId ? getProPricePerMonth(planId) : brandKit?.monthlyPrice
    const billingPeriod = planId ? BillingPeriod.Annually : brandKit?.billingPeriod
    const isLoading = isLoadingProp || isTrialLoading

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            disablePortal={false}
            onClose={() => {
                setAnchorEl(null)
                onClose?.()
            }}
            slotProps={{
                paper: {
                    sx: {
                        boxShadow
                    }
                }
            }}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <Box style={{ maxWidth: 345 }}>
                <Box height={5}>{isLoading && <LinearProgress />}</Box>
                <Box p={1.2} py={0.5} bgcolor="#F5F5F5">
                    <img src={imageSrc} width={325} height="auto" style={{ objectFit: 'cover' }} />
                </Box>
                <Box p={2}>
                    <Typography style={{ fontWeight: 500, fontSize: 20 }}>{title}</Typography>
                    <Box mt={1} />
                    <Typography variant="caption" color="textSecondary">
                        {text}
                    </Typography>

                    {Boolean(monthlyPrice) && !hidePrice && (
                        <SpacedGroup mt={2} alignItems="flex-end" spacing={1}>
                            <Box mb="-4px">
                                <Typography style={{ fontWeight: 500, fontSize: 20 }}>
                                    ${monthlyPrice}
                                </Typography>
                            </Box>
                            <Typography variant="caption" color="textSecondary">
                                /month
                                {billingPeriod === BillingPeriod.Annually && ', billed annually'}
                            </Typography>
                        </SpacedGroup>
                    )}
                    {list && (
                        <SpacedGroup flexDirection="column" mt={2} spacing={1.5}>
                            {list.map((it, i) => (
                                <SpacedGroup key={i} spacing={1.5}>
                                    <CheckIcon
                                        style={{ color: '#73CC6D' }}
                                        width={20}
                                        height={20}
                                    />
                                    <Typography variant="caption">{it}</Typography>
                                </SpacedGroup>
                            ))}
                        </SpacedGroup>
                    )}
                    <Box my={4}>
                        {!hideSeePlanFeatures && (
                            <Box onClick={openUpgradePlanDialog}>
                                <Typography
                                    color="secondary"
                                    style={{
                                        fontSize: 12,
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                >
                                    See full plan features
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {customActionButton || (
                        <Button
                            variant="contained"
                            onClick={() => {
                                onClose?.()
                                if (openFeatures) {
                                    openUpgradePlanDialog()
                                    return
                                }

                                setAnchorEl(null)
                                const query = new URLSearchParams()

                                query.append('upgradePlanStep', '1')
                                planId && query.append('planId', planId)

                                history.replace({
                                    search: `?${query.toString()}`
                                })
                                dispatch(showPlanDialog('upgradePlanDialog'))
                            }}
                            disabled={isLoading}
                            startIcon={<Icon />}
                        >
                            UPGRADE
                        </Button>
                    )}
                </Box>
            </Box>
        </Popover>
    )
}
