import { type ReactNode } from 'react'
import {
    type SortableContainerProps,
    type SortableElementProps,
    type SortEvent,
    type SortEventWithTag,
    SortableContainer,
    SortableElement
} from 'react-sortable-hoc'
import { type BoxProps, Box } from '@mui/material'

type ChildrenType = { children: ReactNode }

const SortableWrapper = SortableContainer<SortableContainerProps & ChildrenType>(
    ({ children }: { children: ReactNode }) => {
        return <div>{children}</div>
    }
)

const SortableItem = SortableElement<SortableElementProps & ChildrenType>(
    ({ children }: ChildrenType) => <div id="sort-item">{children}</div>
)

type Props = {
    direction?: 'row' | 'column'
    width?: BoxProps['width']
    disabled?: boolean
    shouldCancelStart?: (event: SortEvent | SortEventWithTag) => boolean
    children: ReactNode
} & SortableContainerProps

export const SortableComponent = ({
    direction = 'column',
    width,
    disabled,
    children,
    onSortEnd,
    ...rest
}: Props) => {
    return (
        <SortableWrapper onSortEnd={onSortEnd} {...rest} axis={direction === 'row' ? 'x' : 'y'}>
            <Box display="flex" flexDirection={direction} width={width}>
                {Array.isArray(children) &&
                    children.map((child, index) => {
                        return (
                            <SortableItem
                                key={index}
                                index={index}
                                disabled={disabled}
                                {...child.props}
                            >
                                {child}
                            </SortableItem>
                        )
                    })}
            </Box>
        </SortableWrapper>
    )
}
