import { Typography, Box, styled } from '@mui/material'

import { ReactComponent as SKIP } from 'assets/icons/skip.svg'
import { ReactComponent as ExternalLink } from 'assets/icons/open_in_new.svg'

import { type AnyPlaybookType, type MultipleCtaType } from 'app/types'
import { openLink } from 'modules'
import { useServiceUsage } from 'hooks'

import { useCtaReport } from './use-cta-report'

import { SpacedGroup } from 'UI/Components'

import { PoweredBy } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel/MultipleCTA/PoweredBy'
import { getGuiddeCtaLink, getRealCtaLink } from 'UI/Routes/quick-guidde/CanvasEditor'

const Wrapper = styled(Box)({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    zIndex: 5,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(3px)',
    width: '100%',
    height: '100%'
})

const Container = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 12
})

const Text = styled(Typography)({
    fontWeight: 600,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    width: '100%',
    fontStyle: 'normal',
    maxWidth: '155px'
})

type Props = {
    qgCTA: MultipleCtaType
    videoElement: HTMLVideoElement | null
    playbook: AnyPlaybookType
    onSkip: () => void
}

export const CallToActionMultiple = ({ onSkip, qgCTA, playbook, videoElement }: Props) => {
    const { reportEvent } = useCtaReport({ playbook, videoElement })

    const { isFreePlan } = useServiceUsage()

    return (
        <Wrapper>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={2} color="white" fontSize="20px" fontWeight={600}>
                    {qgCTA.title}
                </Box>
                <SpacedGroup justifyContent="center">
                    <Container
                        sx={{
                            maxWidth: `${qgCTA && qgCTA.actions.length > 3 ? 600 : 300}px`
                        }}
                    >
                        {qgCTA?.actions?.map((item, index: number) => (
                            <SpacedGroup
                                position="relative"
                                alignItems="center"
                                justifyContent="space-between"
                                fontSize="14px"
                                fontWeight="400"
                                sx={{
                                    borderRadius: '33px',
                                    width: '257px',
                                    maxWidth: '257px',
                                    height: '36px',
                                    background: '#FFFFFF',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                                    padding: '0px 8px',
                                    cursor: 'pointer'
                                }}
                                key={index}
                                onClick={() => {
                                    reportEvent(item)

                                    if (item.linkType === 'externalLink') {
                                        if (!item.link) return
                                        openLink(getRealCtaLink(item), '_blank')
                                        return
                                    }

                                    if (item.linkType === 'guiddeLink') {
                                        openLink(getGuiddeCtaLink(item), '_self')
                                        return
                                    }

                                    if (!videoElement) return

                                    // For step link calculate the time by the step id and set the video current time
                                    videoElement.currentTime = item?.start + 0.001 || 0
                                    videoElement.play()
                                }}
                            >
                                <SpacedGroup alignItems="center">
                                    <Box
                                        mt={0.5}
                                        style={{ marginLeft: '10px', marginRight: '8px' }}
                                    >
                                        &#x2022;
                                    </Box>
                                    <Text title={item.text} variant="body1">
                                        {item.text}
                                    </Text>
                                </SpacedGroup>
                                {item.linkType === 'externalLink' && (
                                    <ExternalLink style={{ width: '55px', marginRight: 0 }} />
                                )}
                            </SpacedGroup>
                        ))}

                        <SpacedGroup
                            my={2}
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                color: '#FFFFFF',
                                cursor: 'pointer'
                            }}
                            onClick={onSkip}
                        >
                            <SKIP />
                            <Box ml={1}>SKIP</Box>
                        </SpacedGroup>
                    </Container>
                </SpacedGroup>
            </Box>

            {isFreePlan && <PoweredBy />}
        </Wrapper>
    )
}
