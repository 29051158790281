import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useArrayStorage, useAuth, useBoolean, useNotification } from 'hooks'
import msgraph from 'modules/msgraph'
import msauth from 'modules/msauth'
import { uuid, uploadFromDrive, logVideoImport, batchUpload, logToAnalytics } from 'modules'
import { openUploadProgressPopup, setVideoProgress } from 'ducks/actions'
import { paths } from 'app/paths'

import { useUploadFileMaxLimit } from '../use-upload-file-max-limit'

import { Button, Typography } from '@mui/material'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { UploaderModal, FileTable, FileItem, OneDriveItemThumbnail } from 'UI/Components'

import {
    oneDriveColumns,
    OneDriveAuthConfig,
    sortOneDriveFiles,
    VALID_FILETYPES
} from './constants'

export const OneDriveUploader = ({ labelComponent, onUploadCallback }) => {
    const { uid } = useAuth()
    const history = useHistory()
    const dispatch = useDispatch()
    const loadingState = useBoolean(false)
    const [authState, setAuthState] = useState(null)
    const [oneDriveStorage, setOneDriveStorage] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const selectedItems = useArrayStorage([], 'id')
    const [{ sortBy, sortDesc }, setSortParams] = useState({
        sortBy: oneDriveColumns[0].value,
        sortDesc: false
    })

    const { maxUploadFileSizeInBytes } = useUploadFileMaxLimit()

    const appAuth = useSelector(state => state.appAuth.user)
    const { showErrorNotification } = useNotification()
    const { storage } = selectedItems

    const changeSorting = useCallback(data => ImplSorting(setSortParams(data)), [])

    const getFolderFiles = useCallback(
        folderId => {
            const graph = msgraph.init(authState)
            return graph.selectFolderById(folderId).getChildren()
        },
        [authState]
    )
    const getTopLevelFiles = useCallback(() => {
        const graph = msgraph.init(authState)
        return graph.selectFolderByPath('').getChildren()
    }, [authState])

    const loadDriveFolder = useCallback(
        (folderId, direction) =>
            loadDriverFolderImpl(
                authState,
                loadingState,
                getFolderFiles,
                getTopLevelFiles,
                setOneDriveStorage,
                setBreadcrumbs,
                showErrorNotification
            )(folderId, direction),
        [loadingState, authState, getFolderFiles, getTopLevelFiles, showErrorNotification]
    )

    useEffect(() => {
        if (authState?.isConnectedInstance()) {
            loadDriveFolder()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState])

    const initOneDrive = useCallback(
        () => authAndInitDrive({ uid, authState, loadingState, setAuthState })(),
        [authState, loadingState, uid]
    )

    const loadPreviousFolder = useCallback(
        () => loadPreviousFolderImpl(breadcrumbs, loadDriveFolder)(),
        [breadcrumbs, loadDriveFolder]
    )

    const resetGDriveModal = useCallback(
        data => resetOneDriveModelImpl(breadcrumbs, loadDriveFolder(data), selectedItems),
        [breadcrumbs, loadDriveFolder, selectedItems]
    )

    const uploadFiles = useCallback(() => {
        loadingState.setTrue()
        const { displayName, photoURL, email, uid } = appAuth

        const fileList = []

        const uploadPromises = storage.map(file => {
            const uploadId = uuid()

            dispatch(
                setVideoProgress({
                    playbookId: uploadId,
                    fileName: file.name,
                    source: 'onedrive'
                })
            )
            // we must clean the file as object keys
            // can be alphanumeric only
            const cleanFile = {
                ...file,
                downloadUrl: file['@microsoft.graph.downloadUrl']
            }
            delete cleanFile['@microsoft.graph.downloadUrl']

            return uploadFromDrive(
                {
                    file: cleanFile,
                    uid,
                    uploadId,
                    uploadedBy: { displayName, photoURL, email }
                },
                'onedrive'
            ).then(() => {
                fileList.push(uploadId)

                logVideoImport('oneDrive', {
                    type: file.mimeType,
                    size: Number(file.size)
                })

                logToAnalytics('upload_from_onedrive_drive', {
                    'pb-id': uploadId,
                    'pb-title': file.name
                })
            })
        })

        Promise.all(uploadPromises)
            .then(() => {
                const id = uuid()

                batchUpload({
                    id,
                    uploadedBy: uid,
                    fileList,
                    drive: 'onedrive'
                })

                if (onUploadCallback) {
                    onUploadCallback(fileList, id)
                } else {
                    history.push({ pathname: paths.myVideos })
                }
                const filesObject = storage.reduce(
                    (a, file) => ({
                        ...a,
                        [file.id]: {
                            id: file.id,
                            name: file.title,
                            source: 'onedrive',
                            type: 'video'
                        }
                    }),
                    {}
                )

                dispatch(openUploadProgressPopup(filesObject))
            })
            .finally(loadingState.setFalse)
    }, [history, appAuth, loadingState, storage, dispatch, onUploadCallback])

    const sortedFiles = useMemo(
        () => getSortedFiles(oneDriveStorage, sortBy, sortDesc)(),
        [oneDriveStorage, sortBy, sortDesc]
    )

    const validVideoFiles = filterInvalidFiles(oneDriveStorage, maxUploadFileSizeInBytes)

    const areAllVideosSelected = selectedItems.areAllSelected(validVideoFiles)

    return (
        <UploaderModal
            trigger={labelComponent}
            title="OneDrive"
            subtitle={
                breadcrumbs.length > 0 ? (
                    <Button
                        variant="text"
                        onClick={loadPreviousFolder}
                        sx={{
                            color: 'common.black',
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        <ChevronLeftIcon /> <b>{breadcrumbs[breadcrumbs.length - 1].name}</b>
                    </Button>
                ) : (
                    <Typography variant="subtitle2">
                        <b>My Drive</b>
                    </Typography>
                )
            }
            selectedItems={storage.length}
            areAllItemsSelected={areAllVideosSelected}
            onSelectAll={() =>
                areAllVideosSelected
                    ? selectedItems.deleteMultiple(validVideoFiles)
                    : selectedItems.addMultiple(validVideoFiles)
            }
            onOpen={initOneDrive}
            onClose={resetGDriveModal}
            onSubmit={uploadFiles}
        >
            <FileTable
                isLoading={loadingState.isTrue}
                columns={oneDriveColumns}
                sortBy={sortBy}
                sortDesc={sortDesc}
                onSortChange={changeSorting}
            >
                <>
                    {filterInvalidFileTypes(sortedFiles).map(oneDriveItem => {
                        const isFolderType = msgraph.isFolder(oneDriveItem)
                        const isSizeValid = oneDriveItem.size < maxUploadFileSizeInBytes

                        return (
                            <FileItem
                                key={oneDriveItem.id}
                                isSelectable={isFolderType || isSizeValid}
                                showCheckbox={!isFolderType && isSizeValid}
                                showSizeWarning={!isFolderType && !isSizeValid}
                                columns={oneDriveColumns}
                                thumbnail={<OneDriveItemThumbnail file={oneDriveItem} />}
                                file={oneDriveItem}
                                isSelected={selectedItems.isSelected(oneDriveItem)}
                                onClick={() => {
                                    if (msgraph.isFolder(oneDriveItem)) {
                                        loadDriveFolder(oneDriveItem.id)
                                    } else {
                                        selectedItems.toggleItem(oneDriveItem)
                                    }
                                }}
                            />
                        )
                    })}
                </>
            </FileTable>
        </UploaderModal>
    )
}

function ImplSorting(setSortParams) {
    return sortBy =>
        setSortParams(prevSort => ({
            sortBy,
            sortDesc: sortBy === prevSort.sortBy ? !prevSort.sortDesc : false
        }))
}

function loadDriverFolderImpl(
    authState,
    loadingState,
    getFolderFiles,
    getTopLevelFiles,
    setOneDriveStorage,
    setBreadcrumbs,
    showErrorNotification
) {
    return (folder = null, direction = '') => {
        if (authState) {
            loadingState.setTrue()
            const getFiles = folder ? () => getFolderFiles(folder) : () => getTopLevelFiles()

            getFiles()
                .then(data => {
                    if (data) {
                        setOneDriveStorage(data.value)
                        setBreadcrumbs(breadcrumbs => {
                            if (!folder) {
                                return []
                            }

                            return direction === 'back'
                                ? breadcrumbs.slice(0, -1)
                                : breadcrumbs.concat(folder)
                        })
                    } else {
                        showErrorNotification('Error loading folder!')
                    }
                    loadingState.setFalse()
                })
                .catch(console.error)
        }
    }
}

function authAndInitDrive({ uid, authState, loadingState, setAuthState }) {
    return async () => {
        try {
            if (!authState) {
                loadingState.setTrue()
                const auth = msauth.setConfig(OneDriveAuthConfig).initInstance(uid)

                await auth.loginWithPopup()
                if (auth.isConnectedInstance()) {
                    setAuthState(auth)
                }
                loadingState.setFalse()
            }
        } catch (err) {
            console.error('Error initiating one drive:', err)
        }
    }
}

function loadPreviousFolderImpl(breadcrumbs, loadDriveFolder) {
    return () => {
        const folder = breadcrumbs.length === 1 ? null : breadcrumbs[breadcrumbs.length - 2]

        loadDriveFolder(folder, 'back')
    }
}

function getSortedFiles(oneDriveStorage, sortBy, sortDesc) {
    return () => oneDriveStorage.sort((a, b) => sortOneDriveFiles(sortBy, sortDesc, a, b))
}

function filterInvalidFileTypes(list) {
    return list.filter(item => {
        if (msgraph.isFolder(item)) return true
        if (item.file && VALID_FILETYPES.includes(item.file.mimeType)) return true
        return false
    })
}

function filterInvalidFiles(oneDriveStorage, maxUploadFileSizeInBytes) {
    return () => {
        return oneDriveStorage.filter(driveItem => {
            !msgraph.isFolder(driveItem) &&
                driveItem.size &&
                driveItem.size < maxUploadFileSizeInBytes &&
                driveItem.file &&
                driveItem.file.mimeType !== 'mp4'
        })
    }
}
function resetOneDriveModelImpl(breadcrumbs, loadDriveFolder, selectedItems) {
    return () => {
        if (breadcrumbs.length) {
            loadDriveFolder()
        }
        selectedItems.clearStorage()
    }
}
