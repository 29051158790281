import { useDispatch } from 'react-redux'

import { Typography } from '@mui/material'

import { logToAnalytics } from 'modules'
import { useRoles, useServiceUsage } from 'hooks'
import { showPlanDialog, showPlanNotifications } from 'ducks'

import { ServiceUsageDialog } from './ServiceUsageDialog'

type Props = {
    onClose: () => void
}

export const BasicLimitationDialog = ({ onClose }: Props) => {
    const { isAdmin } = useRoles()

    const { usedPercent, isLoading } = useServiceUsage()
    const dispatch = useDispatch()

    if (isLoading) return null

    return (
        <ServiceUsageDialog
            title="Keep on going"
            onClose={onClose}
            description={
                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                    Your team reached{' '}
                    <Typography component="span" fontWeight={700} color="primary.main">
                        {usedPercent}% of your Free plan.
                    </Typography>
                    {'\n'}
                    Unlock exclusive features today and get unlimited video minutes, video
                    analytics, and more exciting features!
                </Typography>
            }
            actionText={isAdmin ? 'Upgrade plan' : 'Contact your admin'}
            onActionClick={() => {
                if (!isAdmin) {
                    onClose()
                    return
                }

                logToAnalytics('open_upgrade_plan_dialog', {
                    source: 'basic_limitation_dialog'
                })
                dispatch(
                    showPlanNotifications({
                        freePlanLimitation: false
                    })
                )
                dispatch(showPlanDialog('upgradePlanDialog'))
            }}
        />
    )
}
