import * as types from 'ducks/types'
import { type ActionType } from 'ducks/common'
import { type StepType } from 'app/types'

type StateType = {
    pasteIndex: number
    loadingSteps: Array<StepType>
}

const initialState: StateType = {
    pasteIndex: 0,
    loadingSteps: []
}

export const pasteStepsReducer = (state = initialState, action: ActionType): StateType => {
    switch (action.type) {
        case types.SET_QUICK_GUIDDE_STEPS_LOADING: {
            const { loadingSteps, pasteIndex = 0 } = action.payload

            return {
                loadingSteps,
                pasteIndex
            }
        }

        default: {
            return state
        }
    }
}
