import { useDispatch, useSelector } from 'react-redux'

import { Box, Tooltip, styled, IconButton } from '@mui/material'

import { cloneCanvas } from 'UI/Components/VideoEditor/EditableTimelineWithSections'
import IconMerge from 'assets/icons/icon-merge.svg'

import { delay, logToAnalytics } from 'modules'
import { setSections } from 'ducks'

const Container = styled(Box)({
    position: 'absolute',
    top: 'calc(100% + 8px)',
    right: '-0.75rem',
    background: '#090c10',
    borderRadius: '50%'
})

type Props = {
    index: number
    canvasElements: Array<HTMLCanvasElement>
}

export const MergeButton = ({ index, canvasElements }: Props) => {
    const dispatch = useDispatch()

    const { sections } = useSelector(state => state.videoEditor)

    // Logic to merge current section with the next one
    // We always merge with the next section as our merge button is at the end of the section
    const handleMerge = (index: number) => {
        const newSections = JSON.parse(JSON.stringify(sections))

        newSections[index].rangeValues[1] = newSections[index + 1].rangeValues[1] // combine current range values with the next one
        newSections.splice(index + 1, 1) // remove the next section

        dispatch(setSections(newSections))

        const prevCanvasRef = canvasElements
            .map(cloneCanvas)
            .filter(Boolean) as Array<HTMLCanvasElement>

        delay(500).then(() => {
            chapterThumbsMerge(index, prevCanvasRef)
        })

        logToAnalytics('merge_chapter', { chapterIndex: index })
    }

    const chapterThumbsMerge = (slicedAtIndex: number, prevCanvasRef: Array<HTMLCanvasElement>) => {
        canvasElements.forEach((ref, index) => {
            const ctx = ref?.getContext('2d')

            if (!ctx) return
            if (!prevCanvasRef || !prevCanvasRef[slicedAtIndex]) return
            if (index < slicedAtIndex) return

            if (prevCanvasRef[index]) {
                ctx.drawImage(prevCanvasRef[index + 1], 0, 0, 360, 210)
            }
        })
    }

    return (
        <Container>
            <Tooltip title="Merge">
                <IconButton size="small" onClick={() => handleMerge(index)}>
                    <img src={IconMerge} alt="merge" draggable={false} />
                </IconButton>
            </Tooltip>
        </Container>
    )
}
