import { useDispatch, useSelector } from 'react-redux'

import { DEFAULT_STEP } from './AddNewStep'
import { AddStepMenuItem } from './AddStepMenuItem'

import { ReactComponent as TitleStepIcon } from 'assets/icons/title-step.svg'

import { useLayers, DEFAULT_TEXT_LAYER } from 'UI/Routes/quick-guidde/CanvasEditor'
import { useBrandKit, useServiceUsage, useSessionStorage } from 'hooks'

import { addNewStep } from 'ducks'
import { logToAnalytics, uuid } from 'modules'
import { Shape } from 'app/types'

type Props = {
    onDone: () => void
}

export const TitleStep = ({ onDone }: Props) => {
    const dispatch = useDispatch()

    const { isFreePlan } = useServiceUsage()
    const { backgroundRectangle, stepNumbering, watermark, text } = useLayers()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { isCalloutsEnabled } = useBrandKit()

    const needStepNumbering = steps.some(step =>
        step.layers?.some(layer => layer.type === Shape.CircleWithText)
    )

    const { kind, windowDimensions } = steps[activeStep] || {}

    // use the global saved duration from session storage if exists, otherwise omit the field and use the default
    const [savedInput] = useSessionStorage<number | null>('guiddeSavedDuration', null)

    return (
        <AddStepMenuItem
            Icon={TitleStepIcon}
            title="Blank"
            dataCy="editor-title-step-button"
            onClick={() => {
                dispatch(
                    addNewStep({
                        kind: 'step',
                        position: ['end', 'outro'].includes(kind) ? 'before' : 'after',
                        step: {
                            ...DEFAULT_STEP,
                            id: uuid(),
                            timeStamp: Date.now(),
                            windowDimensions,
                            ...(savedInput && { duration: savedInput }),
                            layers: [
                                backgroundRectangle,
                                ...(isFreePlan ? [watermark] : []),
                                ...(isCalloutsEnabled
                                    ? [
                                          {
                                              ...text,
                                              isTitle: true,
                                              x: needStepNumbering
                                                  ? stepNumbering.circle.radius * 2 +
                                                    stepNumbering.x * 2
                                                  : DEFAULT_TEXT_LAYER.x
                                          }
                                      ]
                                    : []),
                                // if any step has step numbering - new step should have it as well
                                ...(needStepNumbering ? [stepNumbering] : [])
                            ]
                        }
                    })
                )
                logToAnalytics('qg-add-chapter', {
                    activeChapter: activeStep + 1,
                    type: 'a blank slide'
                })

                onDone()
            }}
        />
    )
}
