import { type ReactNode, useContext } from 'react'

import { Box, Stack, TextField } from '@mui/material'

import { Icon, ThemeProvider } from '@guidde/design-system'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass'
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'

import { CaptionsEditorContext } from './CaptionsEditorProvider'
import { DownloadCaptions } from '../DownloadCaptions'

import { type QuickGuiddeType } from 'app/types'

type Props = {
    playbook: QuickGuiddeType
}

export const CaptionSearchPanel = ({ playbook }: Props) => {
    const {
        searchQuery,
        setSearchQuery,
        debouncedQuery,
        matchSelectors,
        currentHighlight,
        navigateMatchedText
    } = useContext(CaptionsEditorContext)

    return (
        <Stack
            direction="row"
            spacing={0.5}
            py={1.5}
            px={2}
            height={54}
            width="100%"
            alignItems="center"
        >
            <TextField
                placeholder="Search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                sx={theme => ({
                    width: '90%',
                    '& svg': {
                        color: theme.palette.grey['400']
                    }
                })}
                InputProps={{
                    startAdornment: (
                        <Box mr={1}>
                            <Icon icon={faMagnifyingGlass} />
                        </Box>
                    ),
                    endAdornment: debouncedQuery ? (
                        <Stack direction="row" spacing={2}>
                            <span>
                                {matchSelectors.length ? currentHighlight + 1 : 0}/
                                {matchSelectors.length}
                            </span>
                            <Stack spacing={0.5} direction="row" alignItems="center">
                                <IconWrapper
                                    onClick={() => navigateMatchedText('up')}
                                    disabled={!matchSelectors.length}
                                >
                                    <Icon icon={faChevronUp} />
                                </IconWrapper>

                                <IconWrapper
                                    onClick={() => navigateMatchedText('down')}
                                    disabled={!matchSelectors.length}
                                >
                                    <Icon icon={faChevronDown} />
                                </IconWrapper>
                            </Stack>
                        </Stack>
                    ) : null
                }}
            />

            <ThemeProvider>
                <DownloadCaptions
                    variant="menu"
                    playbookName={playbook.title}
                    playbookId={playbook.id}
                    langCode={playbook.language?.langCode || 'en'}
                />
            </ThemeProvider>
        </Stack>
    )
}

type IconWrapperProps = {
    children: ReactNode
    disabled?: boolean
    onClick: () => void
}

const IconWrapper = ({ children, disabled, onClick }: IconWrapperProps) => (
    <Box
        sx={theme => ({
            pointerEvents: disabled ? 'none' : '',
            cursor: 'pointer',
            '&:hover svg': { color: theme.palette.grey['600'] }
        })}
        onClick={onClick}
    >
        {children}
    </Box>
)
