import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import TagManager from 'react-gtm-module'
import { IntercomProvider } from 'react-use-intercom'

import * as FullStory from '@fullstory/browser'

import { CssBaseline, ThemeProvider } from '@mui/material'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'

import {
    NotificationProvider,
    ErrorBounder,
    InternetConnectionListener,
    GlobalCssPriority
} from 'UI/Components'

import './index.css'
import { newTheme } from './app/theme'
import App from './App'

import { isDev, isLocalhost, envConfig } from 'modules'
import { store } from 'ducks/store'

//FullStory
import { init as initFullStory } from '@fullstory/browser'

//Sentry
import { init as initSentry, setTag } from '@sentry/react'

import { Integrations } from '@sentry/tracing'
import SentryFullStory from '@sentry/fullstory'

try {
    initFullStory({ orgId: '15F24V', devMode: isDev })
} catch (error) {
    console.error('Error initializing FullStory', error)
}

try {
    initSentry({
        enabled: !isLocalhost,
        dsn: 'https://c72b354b5dce44a1bdeb51ea71e3cd01@o999875.ingest.sentry.io/5975512',
        integrations: [
            new Integrations.BrowserTracing(),
            new SentryFullStory('guiddeco', { client: FullStory })
        ],
        environment: envConfig.firebaseConfig.environment,
        // for finer control
        tracesSampleRate: 0,
        // release: import.meta.env.APP_VERSION,
        release: process.env.REACT_APP_VERSION,
        ignoreErrors: ['Non-Error promise rejection captured'] // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
    })

    setTag('project-id', envConfig.firebaseConfig.projectId)
} catch (error) {
    console.error('Error initializing Sentry', error)
}

if (!isDev) {
    try {
        const tagManagerArgs = {
            gtmId: 'GTM-T74SSJN'
        }
        TagManager?.initialize(tagManagerArgs)
    } catch (error) {
        console.error('Error initializing TagManager', error)
    }
}

// here use can add any plugin to dayjs library
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

const root = createRoot(document.getElementById('root') as Element)

root.render(
    <BrowserRouter>
        <ErrorBounder>
            <ThemeProvider theme={newTheme}>
                <ReduxProvider store={store}>
                    <CssBaseline />

                    <NotificationProvider>
                        <InternetConnectionListener>
                            <IntercomProvider appId={isDev ? 'be1vcijb' : 'ejamtgjy'} autoBoot>
                                <GlobalCssPriority>
                                    <App />
                                </GlobalCssPriority>
                            </IntercomProvider>
                        </InternetConnectionListener>
                    </NotificationProvider>
                </ReduxProvider>
            </ThemeProvider>
        </ErrorBounder>
    </BrowserRouter>
)
