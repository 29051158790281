import { useDispatch, useSelector } from 'react-redux'

import { FormControlLabel, Typography } from '@mui/material'

import { IOSSwitch } from 'UI/Components'

import { Shape } from 'app/types'
import { addStepNumbering, removeStepNumbering } from 'ducks'

import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor'

export const StepNumbering = () => {
    const dispatch = useDispatch()

    const { stepNumbering } = useLayers()

    const {
        present: { steps, activeStep }
    } = useSelector(state => state.qgEditor)

    const stepKind = steps[activeStep]?.kind
    const isCoverStep = stepKind === 'cover'
    const isEndingStep = stepKind === 'end'

    if (isCoverStep || isEndingStep) return null

    const hasStepNumbering = Boolean(
        steps.find(step => step.layers.some(layer => layer.type === Shape.CircleWithText))
    )

    return (
        <FormControlLabel
            onChange={() => {
                if (hasStepNumbering) {
                    dispatch(removeStepNumbering())
                } else {
                    dispatch(addStepNumbering(stepNumbering))
                }
            }}
            control={<IOSSwitch checked={hasStepNumbering} color="primary" />}
            label={
                <Typography
                    data-test="editor-numbers-button"
                    variant="subtitle2"
                    style={{
                        fontSize: 14,
                        fontWeight: 500
                    }}
                >
                    Numbers
                </Typography>
            }
        />
    )
}
