import React, { type ReactNode, memo, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
    Typography,
    Box,
    CircularProgress,
    Divider,
    Tooltip,
    TextField,
    styled
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import {
    type CouponResDataType,
    type ApplyCouponProps,
    CheckoutForm,
    SpacedGroup,
    ApplyCoupon,
    PaymentInProcessDialog
} from 'UI/Components'

import { formatDate, getPersonalReferralCode, getReferralCode, logToAnalytics } from 'modules'
import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'

import { type Stripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import {
    type BucketType,
    type EstimateSubscriptionInput,
    type MappedPlanType,
    type SubmitStripeFormType,
    type EstimateSubscriptionResType,
    type StartCheckoutType,
    type ChargeResType,
    type ChargeCustomerProps,
    type TaxType,
    roundPrice,
    useBoolean,
    useWindowView,
    usePayment
} from 'hooks'
import { closePlanDialogs, showPlanNotifications } from 'ducks'

import { SCREEN } from './helpers'
import { PreselectedCardCheckout } from './PreselectedCardCheckout'

type Props = {
    bucket: BucketType
    setStep: React.Dispatch<React.SetStateAction<number>>
    isLoading: boolean
    confirmPlanUpdate: () => Promise<void>
    submitStripeForm: SubmitStripeFormType
    stripePromise: Stripe | null
    clientSecret?: string | null
    selectedPlan?: MappedPlanType
    estimateSubscriptionData?: EstimateSubscriptionResType
    couponId?: string
    onError?: (message?: string) => void
    estimateSubscription: (input: EstimateSubscriptionInput) => void
    isEstimationLoading: boolean
    applyCoupon: (input: ApplyCouponProps) => Promise<CouponResDataType>
    chargeCustomer: (props: ChargeCustomerProps) => Promise<ChargeResType | undefined>
    startCheckout: StartCheckoutType
    couponResData: CouponResDataType
    isCouponApplying: boolean
    taxes: Array<TaxType>
    billingEmail: string
    billingEmailError: string
    setBillingEmail: (billingEmail: string) => void
    currentPlanName: string
    plan?: MappedPlanType
    isStartCheckoutLoading: boolean
}

const StyledTextField = styled(TextField)({
    '& fieldset': {
        border: 'none'
    },

    '& input': {
        padding: '11px 14px',
        borderRadius: '5px'
    },

    '& input, & input:hover': {
        border: '1px solid #e6e6e6',
        boxShadow: '0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)'
    },

    '& input:focus': {
        borderColor: '#0573e180',
        outline: '2px solid #0573e180'
    }
})

const applyLocalTax = (rawTotal: number, taxes: Array<TaxType>, country: string) => {
    const countryUpperCase = country?.toUpperCase()
    const tax = taxes.find(tax => tax.country?.toUpperCase() === countryUpperCase)

    return tax ? rawTotal + (rawTotal * tax.percentage) / 100 : rawTotal
}

export const Payment = memo(
    ({
        bucket,
        setStep,
        isLoading,
        clientSecret,
        submitStripeForm,
        stripePromise,
        selectedPlan,
        onError,
        estimateSubscriptionData,
        estimateSubscription,
        isEstimationLoading,
        applyCoupon,
        couponResData,
        isCouponApplying,
        chargeCustomer,
        confirmPlanUpdate,
        startCheckout,
        taxes,
        setBillingEmail,
        billingEmailError,
        billingEmail,
        plan,
        currentPlanName,
        isStartCheckoutLoading
    }: Props) => {
        const dispatch = useDispatch()

        const isCouponLoading = useBoolean(true)

        const { cardDataIsEmpty } = usePayment()

        const isFirstInit = useBoolean()
        const paymentInProgress = useBoolean()
        const addressReady = useBoolean()
        const addressShown = useBoolean()

        const initialPromoCodeApplyLoading = useBoolean(true)
        const finishInitialPromoCodeApplyLoading = initialPromoCodeApplyLoading.setFalse
        const [promotionCode, setPromotionCode] = useState('')

        const [country, setCountry] = useState('')

        const { period, totalLicenses, planId } = bucket

        const { isMobileView } = useWindowView()

        const paramsToEstimate = useMemo(
            () => ({
                planId,
                billingPeriod: period,
                unitQuantity: totalLicenses,
                ...(promotionCode && { promotionCode })
            }),
            [period, planId, promotionCode, totalLicenses]
        )

        useEffect(() => {
            getPersonalReferralCode().then(async personalReferralCode => {
                const referralCode = await getReferralCode()

                if (!referralCode || personalReferralCode === referralCode)
                    return finishInitialPromoCodeApplyLoading()

                if (isStartCheckoutLoading) return

                applyCoupon({
                    couponId: referralCode,
                    planId,
                    billingPeriod: period,
                    unitQuantity: totalLicenses
                }).then(promoCode => {
                    setPromotionCode(promoCode?.couponId ?? '')
                    finishInitialPromoCodeApplyLoading()
                })
            })
        }, [
            applyCoupon,
            finishInitialPromoCodeApplyLoading,
            isStartCheckoutLoading,
            period,
            planId,
            totalLicenses
        ])

        useEffect(() => {
            if (isFirstInit.isTrue) return

            if (!isLoading) {
                isFirstInit.setTrue()
                isCouponLoading.setFalse()
            }
        }, [isLoading, isFirstInit, isCouponLoading])

        useEffect(() => {
            if (!isStartCheckoutLoading && initialPromoCodeApplyLoading.isFalse) {
                estimateSubscription(paramsToEstimate)
            }
        }, [
            estimateSubscription,
            initialPromoCodeApplyLoading.isFalse,
            isStartCheckoutLoading,
            paramsToEstimate
        ])

        if (!selectedPlan) return null

        const { subscription, discount } = estimateSubscriptionData || {}
        const rawTotal = subscription?.total.amount || 0

        const subTotal = roundPrice(subscription?.subTotal.amount || 0)

        const annualPaymentAmount = roundPrice(
            applyLocalTax(subscription?.subscription.subTotal.amount || 0, taxes, country)
        )
        const totalPrice = roundPrice(applyLocalTax(rawTotal, taxes, country))
        const nextBillingDate = subscription?.billingPeriodRange.end
        const pricePerUnitMonthly =
            plan?.pricePoints.find(it => it.billingPeriod === period)?.amount || 0

        const pricePerUnitMonthlyTaxed = roundPrice(
            applyLocalTax(pricePerUnitMonthly, taxes, country)
        )

        const taxAmount = roundPrice(
            totalPrice > (subscription?.subTotal.amount || 0)
                ? totalPrice - (subscription?.subTotal.amount || 0)
                : 0
        )
        const periodName = period === BillingPeriod.Annually ? 'Annual' : 'Monthly'

        const isLoadingData =
            (isLoading && isFirstInit.isFalse) || !subscription || isEstimationLoading

        return (
            <SpacedGroup
                pt={isMobileView ? 0 : 6}
                spacing={isMobileView ? 0 : 12}
                alignItems="flex-start"
                px={isMobileView ? 0 : 5}
                flexDirection={isMobileView ? 'column' : 'row'}
                minHeight={440}
                height="100%"
                position="relative"
            >
                <Box
                    width={clientSecret ? '50%' : '100%'}
                    height="100%"
                    bgcolor={isMobileView ? '#F5F5F5' : 'transparent'}
                    p={isMobileView && !isLoadingData ? 3 : 0}
                >
                    {isLoadingData && (
                        <>
                            {isMobileView ? (
                                <Box
                                    style={{
                                        position: 'absolute',
                                        top: isFirstInit.isTrue ? '20%' : '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Box
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: isLoading && isFirstInit.isFalse ? '50%' : '25%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </>
                    )}
                    {isLoadingData && isFirstInit.isTrue && <Box height={360} />}

                    <Box mx="auto" width="100%" height="100%">
                        {!isLoadingData && (
                            <Box>
                                <Typography
                                    data-test="payment-order-summary-title-text"
                                    style={{ fontSize: 20, fontWeight: 600 }}
                                >
                                    Order summary
                                </Typography>

                                <OrderRow
                                    mt={3.5}
                                    left={
                                        <Typography
                                            data-test="payment-period-name-title-text"
                                            style={{ fontWeight: 600 }}
                                        >
                                            {periodName} payment
                                        </Typography>
                                    }
                                    right={
                                        <Typography
                                            data-test="payment-total"
                                            style={{ fontWeight: 600 }}
                                        >
                                            ${annualPaymentAmount}
                                        </Typography>
                                    }
                                />
                                <OrderRow
                                    mt={1}
                                    left={
                                        <Typography
                                            data-test="payment-billing-calculation-text"
                                            color="textSecondary"
                                            variant="caption"
                                        >
                                            {periodName} payment/seat
                                        </Typography>
                                    }
                                    right={
                                        <Typography
                                            data-test="payment-billing-calculation"
                                            color="textSecondary"
                                            variant="caption"
                                        >
                                            {period === BillingPeriod.Annually
                                                ? `$ ${roundPrice(
                                                      pricePerUnitMonthlyTaxed / 12
                                                  )} x 12 mo`
                                                : `$ ${pricePerUnitMonthlyTaxed} x mo`}
                                        </Typography>
                                    }
                                />
                                <OrderRow
                                    mt={1}
                                    left={
                                        <SpacedGroup spacing={0.5}>
                                            <Typography
                                                data-test="payment-total-seats-text"
                                                color="textSecondary"
                                                variant="caption"
                                            >
                                                Total seats
                                            </Typography>

                                            <Typography
                                                data-test="payment-add-seats-button"
                                                style={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    color: '#2D9CDB'
                                                }}
                                                variant="caption"
                                                onClick={() => setStep(SCREEN.SelectSeats)}
                                            >
                                                Add
                                            </Typography>
                                        </SpacedGroup>
                                    }
                                    right={
                                        <Typography
                                            data-test="payment-total-seats"
                                            color="textSecondary"
                                            variant="caption"
                                        >
                                            {totalLicenses} seats
                                        </Typography>
                                    }
                                />

                                <Box mt={2} />

                                {discount ? (
                                    <Box
                                        bgcolor="#F5F5F5"
                                        borderRadius={4}
                                        py={1.2}
                                        px={1}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <SpacedGroup spacing={1}>
                                            <CheckCircleIcon
                                                style={{ color: '#73CC6D', width: 14 }}
                                            />
                                            <Typography
                                                style={{ fontWeight: 500 }}
                                                variant="caption"
                                            >
                                                Discount
                                            </Typography>
                                        </SpacedGroup>

                                        <Typography style={{ fontWeight: 500 }} variant="caption">
                                            -${discount.amount}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <>
                                        <Divider />

                                        <Box py={2}>
                                            <ApplyCoupon
                                                applyCoupon={applyCoupon}
                                                promoCode={couponResData?.couponId || ''}
                                                isCouponApplying={isCouponApplying}
                                                onReqStart={isCouponLoading.setTrue}
                                                onReqEnd={isCouponLoading.setFalse}
                                                onSuccess={data => {
                                                    setPromotionCode(data?.couponId || '')
                                                }}
                                                planId={planId}
                                                billingPeriod={period}
                                                unitQuantity={totalLicenses}
                                            />
                                        </Box>

                                        <Divider />
                                    </>
                                )}

                                {subTotal !== annualPaymentAmount && (
                                    <OrderRow
                                        mt={1}
                                        left={
                                            <Typography
                                                data-test="payment-new-plan-text"
                                                color="textSecondary"
                                                variant="caption"
                                            >
                                                New <b>{plan?.planName}</b> plan
                                            </Typography>
                                        }
                                        right={
                                            <Typography
                                                data-test="payment-new-plan"
                                                color="textSecondary"
                                                variant="caption"
                                            >
                                                $ {annualPaymentAmount}
                                            </Typography>
                                        }
                                    />
                                )}
                                {Boolean(subscription.proration?.credit.amount) && (
                                    <OrderRow
                                        mt={1}
                                        left={
                                            <Typography color="textSecondary" variant="caption">
                                                Cancel <b>{currentPlanName}</b> plan
                                            </Typography>
                                        }
                                        right={
                                            <Typography color="textSecondary" variant="caption">
                                                $ {subscription.proration?.credit.amount}
                                            </Typography>
                                        }
                                    />
                                )}
                                <OrderRow
                                    mt={1}
                                    left={
                                        <Typography
                                            data-test="payment-subtotal-text"
                                            color="textSecondary"
                                            variant="caption"
                                        >
                                            Subtotal
                                        </Typography>
                                    }
                                    right={
                                        <Typography
                                            data-test="payment-subtotal"
                                            color="textSecondary"
                                            variant="caption"
                                        >
                                            $ {subTotal}
                                        </Typography>
                                    }
                                />

                                {Boolean(taxAmount) && (
                                    <OrderRow
                                        mt={1}
                                        left={
                                            <Typography color="textSecondary" variant="caption">
                                                Tax ({Math.round((taxAmount / subTotal) * 100)}%)
                                            </Typography>
                                        }
                                        right={
                                            <Typography color="textSecondary" variant="caption">
                                                $ {taxAmount}
                                            </Typography>
                                        }
                                    />
                                )}
                                <Box mt={1} />
                                <Divider />
                                <Tooltip
                                    title="Balance will decrease the amount due on the next invoice."
                                    disableHoverListener={totalPrice >= 0}
                                >
                                    <OrderRow
                                        mt={1}
                                        left={
                                            <Typography
                                                data-test="payment-total-text"
                                                style={{ fontSize: 20, fontWeight: 600 }}
                                            >
                                                {totalPrice < 0 ? `Credit balance` : `Total`}
                                            </Typography>
                                        }
                                        right={
                                            <Typography
                                                data-test="payment-total-balance"
                                                style={{ fontSize: 20, fontWeight: 600 }}
                                            >
                                                {totalPrice < 0
                                                    ? `($${Math.abs(totalPrice)})`
                                                    : `$${totalPrice}`}
                                            </Typography>
                                        }
                                    />
                                </Tooltip>

                                {nextBillingDate && (
                                    <OrderRow
                                        mt={1}
                                        left={
                                            <Typography
                                                data-test="payment-next-payment-text"
                                                color="textSecondary"
                                                variant="caption"
                                            >
                                                Next payment
                                            </Typography>
                                        }
                                        right={
                                            <Typography
                                                data-test="payment-next-payment"
                                                color="textSecondary"
                                                variant="caption"
                                            >
                                                {formatDate(
                                                    Math.round(+new Date(nextBillingDate) / 1000)
                                                )}
                                            </Typography>
                                        }
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>

                {paymentInProgress.isTrue && <PaymentInProcessDialog />}

                {clientSecret && (
                    <Box width="50%" p={isMobileView ? 3 : 0}>
                        <Typography
                            data-test="paymentForm-title-text"
                            style={{ fontSize: 20, fontWeight: 600 }}
                        >
                            Payment information
                        </Typography>
                        {addressReady.isTrue && (
                            <Box mt={3.5} mb={2} maxWidth="400px" mx="auto">
                                <Typography
                                    data-test="paymentForm-billing-email-text"
                                    variant="subtitle2"
                                    style={{ fontWeight: 400, color: '#30313d' }}
                                >
                                    Billing email *
                                </Typography>

                                <SpacedGroup mt={1}>
                                    <StyledTextField
                                        data-test="paymentForm-billing-email-field"
                                        required
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={billingEmail}
                                        onChange={e => setBillingEmail(e.target.value)}
                                    />
                                </SpacedGroup>
                                {billingEmailError && (
                                    <Box pt={1} style={{ color: '#df1b41' }}>
                                        {billingEmailError}
                                    </Box>
                                )}
                            </Box>
                        )}
                        <Box maxWidth="400px" mx="auto" display="flex" alignItems="stretch">
                            <Elements
                                stripe={stripePromise}
                                options={{ clientSecret, loader: 'never' }}
                            >
                                <CheckoutForm
                                    submitBtnName="SUBSCRIBE"
                                    submitStripeForm={submitStripeForm}
                                    isLoading={isLoading}
                                    onError={e => {
                                        if (e.error?.code === 'setup_intent_unexpected_state') {
                                            // reset stripe form for retries, in normal cases this should not happen
                                            startCheckout({
                                                planId,
                                                unitQuantity: totalLicenses,
                                                billingPeriod: period
                                            })
                                        }

                                        onError?.(e.error?.message)
                                        logToAnalytics('setupIntentFailed', e)
                                    }}
                                    onSuccess={async setupResult => {
                                        if (setupResult.setupIntent?.status !== 'succeeded') {
                                            return
                                        }

                                        const clientSecret = setupResult.setupIntent.client_secret
                                        const paymentMethodId =
                                            typeof setupResult.setupIntent.payment_method ===
                                            'string'
                                                ? setupResult.setupIntent.payment_method
                                                : setupResult.setupIntent.payment_method?.id || ''

                                        if (!paymentMethodId || !clientSecret) return

                                        paymentInProgress.setTrue()

                                        return chargeCustomer({
                                            paymentMethodId,
                                            clientSecret
                                        })
                                            .then(res => {
                                                if (res?.code && res.code !== 200) throw res

                                                dispatch(closePlanDialogs())
                                                dispatch(
                                                    showPlanNotifications({
                                                        paidPlanStarted: true
                                                    })
                                                )
                                            })
                                            .catch(e => {
                                                startCheckout({
                                                    planId,
                                                    unitQuantity: totalLicenses,
                                                    billingPeriod: period
                                                })
                                                onError?.(
                                                    e?.error?.message ||
                                                        'Something went wrong, please try again'
                                                )
                                            })
                                            .finally(paymentInProgress.setFalse)
                                    }}
                                    onAddressChange={e => setCountry(e.value.address.country)}
                                    onAddressVisibilityChanged={addressShown.set}
                                    onAddressReady={addressReady.setTrue}
                                />
                            </Elements>
                        </Box>
                    </Box>
                )}
                {!clientSecret && !isLoadingData && !cardDataIsEmpty && (
                    <Box p={isMobileView ? 3 : 0} width="100%">
                        <PreselectedCardCheckout
                            isLoading={isLoading}
                            paymentInProgress={paymentInProgress}
                            confirmPlanUpdate={confirmPlanUpdate}
                        />
                    </Box>
                )}
            </SpacedGroup>
        )
    }
)

type OrderRowProps = {
    left: ReactNode
    right: ReactNode
    mt?: number
}
const OrderRow = ({ left, right, mt }: OrderRowProps) => (
    <SpacedGroup justifyContent="space-between" mt={mt}>
        {left}
        {right}
    </SpacedGroup>
)
