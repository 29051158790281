import { useDispatch } from 'react-redux'

import { ReactComponent as TrialEndImage } from 'assets/images/trial-end.svg'

import { links } from 'app/links'

import { useRoles } from 'hooks'
import { openLink } from 'modules'
import { showPlanDialog } from 'ducks'

import { ServiceUsageActionButton, ServiceUsageDialog } from './ServiceUsageDialog'

type Props = {
    onClose: () => void
}

export const TrialEndDialog = ({ onClose }: Props) => {
    const { isAdmin } = useRoles()
    const dispatch = useDispatch()

    const openDemoPage = () => openLink(links.bookDemo, '_blank')

    return (
        <ServiceUsageDialog
            title="Your trial has expired"
            description={`Your account has been switched to the Free plan. Your step-by-step videos are safe. ${
                isAdmin
                    ? 'Check out our plans and see what you’re missing out on.'
                    : "Contact your admin to upgrade or book a demo. We're here to help!"
            }`}
            actionText={isAdmin ? 'See plans' : 'Book a demo'}
            onActionClick={() => {
                onClose()

                if (isAdmin) {
                    dispatch(showPlanDialog('upgradePlanDialog'))
                } else {
                    openDemoPage()
                }
            }}
            onClose={onClose}
            maxDescriptionWidth={420}
            Image={<TrialEndImage />}
            additionalActionButton={
                <>
                    {isAdmin && (
                        <ServiceUsageActionButton actionText="Book a demo" onClick={openDemoPage} />
                    )}
                </>
            }
        />
    )
}
