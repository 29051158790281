import { useEffect, useState } from 'react'

import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import FileCopyIcon from '@mui/icons-material/LinkOutlined'

import { useNotification } from 'hooks'
import { copyToClipboard } from 'modules'

const maxLength = 600
type Props = {
    isEditMode: boolean
    title: string
    stepId: string
    showCopy: boolean
    onUpdate: (_value: string) => void
}
export const StepTitle = ({ showCopy, isEditMode, title, stepId, onUpdate }: Props) => {
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const [titleField, setTitleField] = useState(title)

    const copyStepUrl = (e: any) => {
        e.stopPropagation()

        copyToClipboard(`${window.location.href}#${stepId}`).then(() => {
            showSuccessNotification('Step URL copied')
        })
    }

    useEffect(() => {
        setTitleField(title)
    }, [title])

    if (isEditMode) {
        return (
            <Box width="100%">
                <TextField
                    label="Step title"
                    value={titleField}
                    variant="filled"
                    fullWidth
                    multiline
                    inputProps={{
                        maxLength
                    }}
                    onBlur={() => {
                        if (!titleField.length) {
                            showErrorNotification('Title cannot be empty')
                            setTitleField(title)
                            return
                        }
                        // we need to make update only if field was changed
                        if (title !== titleField) onUpdate(titleField)
                    }}
                    onClick={e => e.stopPropagation()}
                    onChange={event => setTitleField(event.target.value)}
                />
                <Typography
                    sx={{
                        paddingRight: '4px',
                        paddingTop: '4px',
                        fontSize: '12px',
                        color: '#828282'
                    }}
                    align="right"
                >
                    {`${titleField?.length || 0}/${maxLength}`}
                </Typography>
            </Box>
        )
    }

    return (
        <Typography
            sx={{
                display: 'inline-block',
                fontSize: '20px',
                fontWeight: 'bold',
                wordBreak: 'break-word'
            }}
        >
            <bdi>{title}</bdi>
            {showCopy && (
                <Tooltip title="Copy step URL" placement="top" arrow>
                    <IconButton
                        onClick={copyStepUrl}
                        size="small"
                        sx={{
                            ml: 0.5,
                            p: '3px'
                        }}
                    >
                        <FileCopyIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </Typography>
    )
}
