import { type ReactNode, useMemo } from 'react'

import { Link } from '@mui/material'

import { type AnyPlaybookType } from 'app/types'
import { paths } from 'app/paths'

import { host } from 'modules'

export type UtmLinkProps = {
    playbook: AnyPlaybookType
    children: ReactNode
    path?: (typeof paths)[keyof typeof paths]
    params: {
        utm_campaign: string
        utm_medium: string
    }
}

export const UtmLink = ({ children, params, path = paths.signup, playbook }: UtmLinkProps) => {
    const urlQuery = useMemo(() => {
        const query = new URLSearchParams()

        query.append('utm_source', 'guidde-app')
        query.append('utm_term', playbook.id)
        query.append('utm_content', playbook.uploadedByOrgId)

        Object.entries(params).forEach(([key, value]) => query.append(key, value))

        return query.toString()
    }, [params, playbook])

    return (
        <Link
            href={`${host}${path}?${urlQuery}`}
            rel="noreferrer noopener"
            target="_blank"
            style={{ textDecoration: 'none' }}
        >
            {children}
        </Link>
    )
}
