import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { styled, Box, Tooltip, Toolbar, AppBar } from '@mui/material'
import ShareIcon from '@mui/icons-material/ShareOutlined'

import { SpacedGroup } from 'UI/Components'

import { Share } from 'UI/Routes/main/common'
import { PublicUtmButtons } from './PublicUtmButtons'

import { isQG } from 'modules'

import { type AnyPlaybookType } from 'app/types'

const PREFIX = 'PublicHeader'

const classes = {
    toolbar: `${PREFIX}-toolbar`,
    logo: `${PREFIX}-logo`,
    verticalSeparator: `${PREFIX}-verticalSeparator`
}

const StyledAppBar = styled(AppBar)({
    [`& .${classes.toolbar}`]: {
        height: '72px',
        width: '100%',
        background: '#FFF',
        color: '#000000DE',
        padding: '0 32px'
    },
    [`& .${classes.logo}`]: {
        width: '100px',
        height: '50px',
        objectFit: 'contain'
    },
    [`& .${classes.verticalSeparator}`]: {
        height: '44px',
        width: '1px',
        backgroundColor: '#EAEAEA'
    }
})

type Props = {
    playbookTitle: string
    customLogo?: string
    playbook?: AnyPlaybookType
}

export const PublicHeader = ({ customLogo, playbook, playbookTitle }: Props) => {
    if (!playbook) return null

    return (
        <StyledAppBar position="fixed" style={{ boxShadow: 'none' }}>
            <Toolbar variant="dense" className={classes.toolbar}>
                {isQG(playbook) && (
                    <Box
                        height={2}
                        bgcolor="rgba(9, 12, 16, 0.08)"
                        position="absolute"
                        bottom={0}
                        left={32}
                        right={32}
                    />
                )}

                <SpacedGroup height="100%" width="100%" spacing={2}>
                    <img className={classes.logo} src={customLogo} alt="logo" />

                    <Box className={classes.verticalSeparator} />

                    <Tooltip title={playbookTitle}>
                        <Box
                            style={{
                                fontWeight: 500,
                                fontSize: 20
                            }}
                        >
                            <TextOverflow lineClamp={1}>{playbookTitle}</TextOverflow>
                        </Box>
                    </Tooltip>
                </SpacedGroup>
                <SpacedGroup>
                    {playbook && (
                        <Share
                            isEditMode={false}
                            playbook={playbook}
                            canShare={false}
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                letterSpacing: 1.25,
                                color: 'rgba(9, 12, 16, 0.60)'
                            }}
                            color="inherit"
                            variant="text"
                            size="small"
                            startIcon={<ShareIcon color="inherit" />}
                        />
                    )}
                    <PublicUtmButtons playbook={playbook} />
                </SpacedGroup>
            </Toolbar>
        </StyledAppBar>
    )
}
