import {
  BillingModel,
  BillingPeriod,
  Currency,
  PricingType,
  PromotionalEntitlementStatus,
  SubscriptionStatus,
  TrialPeriodUnits,
  ExperimentGroupType,
  CustomerPortalEntitlementFragment,
  CustomerPortalPromotionalEntitlementFragment,
  CustomerPortalSubscriptionFragment,
  CustomerPortalSubscriptionAddonFragment,
  CustomerPortalBillingInformationFragment,
  PaywallConfigurationFragment,
  CustomerPortalConfigurationFragment,
  WidgetType,
  CustomerPortalSubscriptionScheduledUpdateDataFragment,
  SubscriptionBillingInfo,
  DiscountType,
  DiscountDurationType,
  PackageEntitlementFragment,
  PaywallCurrencyFragment,
} from '@stigg/api-client-js/src/generated/sdk';

export {
  PricingType,
  SubscriptionStatus,
  PromotionalEntitlementStatus,
  Currency,
  BillingPeriod,
  BillingModel,
  TrialPeriodUnits,
  ExperimentGroupType,
  WidgetType,
  FontWeight,
  Alignment,
  SubscriptionScheduleType,
  CustomerPortalSubscriptionScheduledUpdateDataFragment,
  DiscountType,
  DiscountDurationType,
} from '@stigg/api-client-js/src/generated/sdk';

interface Metadata {
  [key: string]: string;
}

export enum AccessDeniedReason {
  InvalidFeatureType = 'InvalidFeatureType',
  CustomerNotFound = 'CustomerNotFound',
  FeatureNotFound = 'FeatureNotFound',
  NoActiveSubscriptions = 'NoActiveSubscriptions',
  CustomerNotEntitledForFeature = 'CustomerNotEntitledForFeature',
  RequestedUsageExceedsLimit = 'RequestedUsageExceedsLimit',
  Unknown = 'Unknown',
}

export enum FeatureType {
  Boolean = 'Boolean',
  Numeric = 'Numeric',
}

export enum MeterType {
  Fluctuating = 'Fluctuating',
  Incremental = 'Incremental',
  None = 'None',
}

export enum EntitlementResetPeriod {
  Daily = 'Daily',
  Hourly = 'Hourly',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export type EntitlementFeature = {
  id: string;
  featureType: FeatureType;
  displayName: string;
  description?: string;
  units?: string;
  unitsPlural?: string;
  meterType?: MeterType.Fluctuating | MeterType.Incremental | MeterType.None;
  isMetered?: boolean;

  metadata?: Record<string, string>;
};

export type BaseEntitlement = {
  hasAccess: boolean;
  isFallback: boolean;
  accessDeniedReason?: AccessDeniedReason;
  feature?: EntitlementFeature;
};

export type DowngradeChangeVariables = {
  addonRefIds?: string;
  billingPeriod?: BillingPeriod;
  downgradePlanRefId: string;
};

export type BillingPeriodChangeVariables = {
  billingPeriod?: BillingPeriod;
};

export type UnitAmountChangeVariables = {
  newUnitAmount?: number;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BooleanEntitlement extends BaseEntitlement {}

export interface NumericEntitlement extends BaseEntitlement {
  value?: number;
  isUnlimited: boolean;
}

export type ResetSettings = {
  nextResetDate: Date;
  resetPeriod: EntitlementResetPeriod;
};

export interface MeteredEntitlement extends BaseEntitlement {
  usageLimit?: number;
  isUnlimited: boolean;
  currentUsage: number;
  requestedUsage: number;
  resetSettings?: ResetSettings;
}

export type Entitlement = BooleanEntitlement | NumericEntitlement | MeteredEntitlement;

/** @internal */
export const BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT: BooleanEntitlement = {
  isFallback: true,
  hasAccess: false,
  accessDeniedReason: AccessDeniedReason.Unknown,
};

/** @internal */
export const NUMERIC_DEFAULT_FALLBACK_ENTITLEMENT: NumericEntitlement = {
  isFallback: true,
  hasAccess: false,
  isUnlimited: false,
  accessDeniedReason: AccessDeniedReason.Unknown,
};

/** @internal */
export const METERED_DEFAULT_FALLBACK_ENTITLEMENT: MeteredEntitlement = {
  isFallback: true,
  hasAccess: false,
  isUnlimited: false,
  currentUsage: 0,
  requestedUsage: 1,
  accessDeniedReason: AccessDeniedReason.Unknown,
};

export type PackageEntitlement = {
  usageLimit: number;
  feature?: EntitlementFeature;
  isCustom?: boolean;
  hasUnlimitedUsage?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  hiddenFromWidgets?: PackageEntitlementFragment['hiddenFromWidgets'];
  displayNameOverride?: PackageEntitlementFragment['displayNameOverride'];
};

export type PromotionalEntitlement = {
  usageLimit: number;
  feature: EntitlementFeature;
  hasUnlimitedUsage?: boolean | null;
  status: PromotionalEntitlementStatus;
  expiresAt?: Date;
  hasExpirationDate: boolean;
  isVisible: boolean;
};

export type Price = {
  pricingModel: BillingModel;
  billingPeriod: BillingPeriod;
  billingId?: string | null;
  amount: number;
  currency: Currency;
  minUnitQuantity?: number | null | undefined;
  maxUnitQuantity?: number | null | undefined;
  billingCountryCode?: string | null | undefined;
  feature?: {
    units?: string | null | undefined;
    unitsPlural?: string | null | undefined;
    unitQuantity?: number | null;
    displayName: string;
  } | null;
};

export type SubscriptionPrice = Price & { grossAmount: number };

export type BasePlan = {
  displayName: string;
  id: string;
};

export type Plan = {
  id: string;
  displayName: string;
  description?: string | null;
  billingId?: string | null;
  order: number;
  basePlan?: BasePlan;
  entitlements: PackageEntitlement[];
  inheritedEntitlements: PackageEntitlement[];
  pricePoints: Price[];
  pricingType?: PricingType | null;
  defaultTrialConfig?: DefaultTrialConfig | null;
  compatibleAddons: Addon[];
  product: Product;
  metadata?: Metadata | null;
};

export type Coupon = {
  id: string;
  name: string;
  description?: string | null;
  discountValue: number;
  metadata?: Metadata | null;
};

export type Product = {
  id: string;
  displayName?: string | null;
  description?: string | null;
  metadata?: Metadata | null;
};

export type DefaultTrialConfig = {
  duration: number;
  units: TrialPeriodUnits;
};

export type GetBooleanEntitlement = {
  featureId: string;
  options?: BooleanEntitlementOptions;
  resourceId?: string;
};

export type GetNumericEntitlement = {
  featureId: string;
  options?: NumericEntitlementOptions;
  resourceId?: string;
};

export type GetMeteredEntitlement = {
  featureId: string;
  options?: MeteredEntitlementOptions;
  resourceId?: string;
};

export type GetActiveSubscriptions = {
  resourceId?: string;
};

export type GetPaywall = {
  productId?: string;
  resourceId?: string;
  context?: WidgetType;
  billingCountryCode?: string;
};

export type GetCustomerPortal = {
  resourceId?: string;
};

export type EntitlementsFallback = {
  [featureId: string]: BooleanEntitlementFallback | NumericEntitlementFallback | MeteredEntitlementFallback;
};

export type BooleanEntitlementFallback = {
  hasAccess: boolean;
};

export type BooleanEntitlementOptions = {
  shouldTrack?: boolean;
  fallback?: BooleanEntitlementFallback;
};

export type NumericEntitlementFallback = {
  hasAccess: boolean;
  value?: number;
  isUnlimited?: boolean;
};

export type NumericEntitlementOptions = {
  shouldTrack?: boolean;
  fallback?: NumericEntitlementFallback;
};

export type MeteredEntitlementFallback = {
  hasAccess: boolean;
  usageLimit?: number;
  isUnlimited?: boolean;
};

export type MeteredEntitlementOptions = {
  requestedUsage?: number;
  shouldTrack?: boolean;
  fallback?: MeteredEntitlementFallback;
};

export type Addon = {
  id: string;
  displayName: string;
  description: string;
  billingId?: string | null;
  entitlements: PackageEntitlement[];
  pricePoints: Price[];
  pricingType?: PricingType | null;
  metadata?: Metadata | null;
};

export type SubscriptionAddon = {
  quantity: number;
  addon: Addon;
};
export type ExperimentInfo = {
  id: string;
  name: string;
  groupName: string;
  groupType?: ExperimentGroupType | null;
};

export type Subscription = {
  id: string;
  status: SubscriptionStatus;
  resourceId?: string;
  plan: Plan;
  price: SubscriptionPrice | null;
  pricingType: PricingType;
  addons: SubscriptionAddon[];
  billingId?: string;
  crmId?: string;
  startDate: Date;
  currentBillingPeriodEnd?: Date;
  /**
   * The calculated end date after which the subscription will be canceled, which takes trial expiration and scheduled cancellations into consideration
   */
  effectiveEndDate?: Date;
  endDate?: Date;
  trialEndDate?: Date;
  cancellationDate?: Date;
  metadata?: Metadata | null;
  experimentInfo?: ExperimentInfo | null;
  scheduledUpdates?: CustomerPortalSubscriptionScheduledUpdateDataFragment[];
};

export type CustomerPortalBillingInformation = Omit<CustomerPortalBillingInformationFragment, '__typename'>;
export type CustomerPortalSubscriptionAddon = Omit<CustomerPortalSubscriptionAddonFragment, '__typename'>;
export type CustomerPortalSubscription = Omit<CustomerPortalSubscriptionFragment, '__typename'>;
export type CustomerPortalEntitlement = Omit<CustomerPortalEntitlementFragment, '__typename'>;
export type CustomerPortalPromotionalEntitlement = Omit<CustomerPortalPromotionalEntitlementFragment, '__typename'>;
export type CustomerPortalConfiguration = Omit<CustomerPortalConfigurationFragment, '__typename'>;
export type CustomerPortal = {
  subscriptions: CustomerPortalSubscription[];
  entitlements: CustomerPortalEntitlement[];
  promotionalEntitlements: CustomerPortalPromotionalEntitlement[];
  billingInformation: CustomerPortalBillingInformation;
  showWatermark: boolean;
  canUpgradeSubscription: boolean;
  billingPortalUrl?: string | null;
  configuration?: CustomerPortalConfiguration | null;
  resource: CustomerResource | null;
};

export type PaywallConfiguration = Omit<PaywallConfigurationFragment, '__typename'>;

export type PaywallCurrency = Omit<PaywallCurrencyFragment, '__typename'>;

export type Paywall = {
  plans: Plan[];
  configuration?: PaywallConfiguration | null;
  customer: Customer | null;
  resource: CustomerResource | null;
  activeSubscriptions: Subscription[] | null;
  currency: PaywallCurrency;
};

export type CustomerResource = {
  id: string;
};

export type Customer = {
  id: string;
  name?: string;
  email?: string;
  createdAt: Date;
  updatedAt: Date;
  subscriptions: Subscription[];
  promotionalEntitlements: PromotionalEntitlement[];
  hasPaymentMethod: boolean;
  paymentMethodDetails?: PaymentMethodDetails;
  metadata?: Metadata | null;
  trialedPlans?: TrialedPlan[] | null;
  eligibleForTrial?: EligibleForTrial[] | null;
  experimentInfo?: ExperimentInfo | null;

  getActivePromotionalEntitlements(): PromotionalEntitlement[];

  /** @deprecated use {@link StiggClient.getActiveSubscriptions} instead with filter for {@link SubscriptionStatus.Active} */
  getActiveSubscriptions(): Subscription[];

  /** @deprecated use {@link StiggClient.getActiveSubscriptions} instead with filter for {@link SubscriptionStatus.InTrial} */
  getActiveTrials(): Subscription[];
};

export type CustomerState = Omit<
  Customer,
  'getActiveSubscriptions' | 'getActivePromotionalEntitlements' | 'getActiveTrials'
>;
export type TrialedPlan = {
  productId?: string | null;
  planId?: string | null;
};

export type EligibleForTrial = {
  productId?: string | null;
  eligible: boolean;
};

export type WaitForCheckoutCompleted = {
  timeoutMs?: number;
  refreshOnComplete?: boolean;
};

export type PaymentMethodDetails = {
  expirationMonth?: number | null;
  expirationYear?: number | null;
  last4Digits?: string | null;
};

export type SubscriptionEstimationAddon = {
  addonId: string;
  quantity?: number;
};

export type DateRange = {
  start?: Date;

  end?: Date;
};

export type Money = {
  amount: number;

  currency: string;
};

export type SubscriptionPreviewProration = {
  prorationDate: Date;

  credit: Money;

  debit: Money;

  netAmount: Money;
};

export type SubscriptionPricePreview = {
  subTotal: Money;
  totalExcludingTax: Money;
  total: Money;
  tax: Money;
};

export type SubscriptionPreviewDiscount = {
  type: DiscountType;
  value: number;
  durationType: DiscountDurationType;
  durationInMonths?: number;
};

export type SubscriptionPreviewTaxDetails = {
  displayName: string;
  percentage: number;
  inclusive: boolean;
};

export interface SubscriptionPreview {
  subTotal: Money;
  totalExcludingTax: Money;
  total: Money;
  tax?: Money;
  taxDetails?: SubscriptionPreviewTaxDetails;
  discount?: SubscriptionPreviewDiscount;
  billingPeriodRange: DateRange;
  proration?: SubscriptionPreviewProration;
  subscription?: SubscriptionPricePreview;
}

export type EstimateSubscription = {
  customerId: string;
  resourceId?: string;
  planId: string;
  addons?: SubscriptionEstimationAddon[];
  startDate?: Date;
  billingPeriod?: BillingPeriod;
  billingCountryCode?: string;
  unitQuantity?: number;
  billingInformation?: SubscriptionBillingInfo;
  promotionCode?: string;
  skipTrial?: boolean;
};

export type EstimateSubscriptionUpdate = {
  subscriptionId: string;
  unitQuantity?: number;
  addons?: SubscriptionEstimationAddon[];
  promotionCode?: string;
};

/** @hidden */
export class CustomerModel implements Customer {
  id!: string;
  createdAt!: Date;
  updatedAt!: Date;
  subscriptions!: Subscription[];
  promotionalEntitlements!: PromotionalEntitlement[];
  hasPaymentMethod!: boolean;
  metadata?: Metadata | null;
  paymentMethodDetails?: PaymentMethodDetails;

  constructor(state: CustomerState) {
    Object.assign(this, state);
  }

  getActiveSubscriptions() {
    return this.subscriptions.filter((x) => x.status === SubscriptionStatus.Active);
  }

  getActiveTrials() {
    return this.subscriptions.filter((x) => x.status === SubscriptionStatus.InTrial);
  }

  getActivePromotionalEntitlements(): PromotionalEntitlement[] {
    return this.promotionalEntitlements.filter((x) => x.status === PromotionalEntitlementStatus.Active);
  }
}
