import { styled, Box, Container, Button, Typography, LinearProgress } from '@mui/material'

import { Formik, Form } from 'formik'

import GuiddeLogoRed from 'assets/icons/guidde_red.svg'
import LockIcon from '@mui/icons-material/Lock'

import { ConnectedInput } from 'UI/Components'

import { useDataMutation, useQuery } from 'hooks'
import { logToAnalytics } from 'modules'

const PREFIX = 'RequestAccess'

const classes = {
    container: `${PREFIX}-container`,
    mainWrapper: `${PREFIX}-mainWrapper`,
    mainTitle: `${PREFIX}-mainTitle`,
    subTitle: `${PREFIX}-subTitle`,
    lockIconWrapper: `${PREFIX}-lockIconWrapper`
}

const StyledContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.container}`]: {
        color: '#212121',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 48px)'
    },

    [`& .${classes.mainWrapper}`]: {
        width: '100%',
        maxWidth: '420px',

        '& > button': {
            fontSize: '16px'
        }
    },

    [`& .${classes.mainTitle}`]: {
        marginTop: 3,
        fontSize: '36px',
        fontWeight: theme.typography.fontWeightBold
    },

    [`& .${classes.subTitle}`]: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'normal',
        fontSize: '20px',
        marginTop: 1
    },

    [`& .${classes.lockIconWrapper}`]: {
        '& > svg': {
            width: '200px',
            height: '200px',
            color: '#cd0000'
        }
    }
}))

type Props = {
    playbookId: string
}

type RequestAccessPayload = {
    playbookId: string
    message: string
}

type RequestAccessGetType = {
    isAccessRequested: boolean
}

export const RequestAccess = ({ playbookId }: Props) => {
    const $requestAccess = useDataMutation<RequestAccessPayload, unknown, Error>(
        '/c/v1/request-access',
        'POST'
    )
    const $requestAccessGet = useQuery<RequestAccessGetType>(
        `/c/v1/request-access?playbookId=${playbookId}`,
        {
            method: 'GET'
        },
        {
            revalidateOnFocus: false
        }
    )

    const isAccessLoading =
        $requestAccessGet.isValidating || (!$requestAccessGet.data && !$requestAccessGet.error)

    if (isAccessLoading) return <LinearProgress />

    const isAccessRequested = $requestAccessGet.data?.isAccessRequested || $requestAccess.data

    return (
        <StyledContainer className={classes.container}>
            <Box className={classes.mainWrapper}>
                <img data-test="requestAccessImage" src={GuiddeLogoRed} alt="Guidde red logo" />
                <Typography
                    data-test="requestAccessTitle"
                    variant="h1"
                    className={classes.mainTitle}
                >
                    {isAccessRequested ? `Request sent` : `You need access`}
                </Typography>
                <Typography data-test="requestAccessText" variant="h2" className={classes.subTitle}>
                    {isAccessRequested
                        ? `You’ll get an email letting you know if your request was approved`
                        : `Ask for access, or switch to an account with access.`}
                </Typography>
                {!isAccessRequested && (
                    <Formik
                        onSubmit={values => {
                            logToAnalytics('requestAccess', {
                                playbookId
                            })
                            return $requestAccess.mutate({ playbookId, message: values.message })
                        }}
                        initialValues={{
                            message: ''
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form style={{ width: '100%' }}>
                                <Box mt={2} mb={2}>
                                    <Box mt={1} position="relative">
                                        <ConnectedInput
                                            data-test="requestAccessMessage"
                                            multiline
                                            fullWidth
                                            name="message"
                                            placeholder="Message (optional)"
                                            variant="outlined"
                                            minRows={3}
                                        />
                                    </Box>
                                </Box>

                                <Button
                                    data-test="requestAccessButton"
                                    disabled={isSubmitting}
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                >
                                    Request access
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
            <Box className={classes.lockIconWrapper}>
                <LockIcon />
            </Box>
        </StyledContainer>
    )
}
