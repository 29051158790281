import { useState, memo } from 'react'

import { Button, Box, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material'

import { GuiddeRoundLoader } from 'UI/Components'

import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js'
import { type SetupIntentResult, type StripeAddressElementChangeEvent } from '@stripe/stripe-js'

import { type SubmitStripeFormType, useBoolean } from 'hooks'
import { logToAnalytics, openLink } from 'modules'
import { links } from 'app/links'

type Props = {
    submitStripeForm: SubmitStripeFormType
    isLoading?: boolean
    onSuccess?: (res: SetupIntentResult) => void
    onError?: (res: SetupIntentResult) => void
    submitBtnName?: string
    onAddressChange?: (event: StripeAddressElementChangeEvent) => void
    onAddressReady?: () => void
    onAddressVisibilityChanged?: (isShown: boolean) => void
}

export const CheckoutForm = memo(
    ({
        submitStripeForm,
        isLoading = false,
        onSuccess,
        onError,
        onAddressChange,
        onAddressReady,
        submitBtnName = 'Submit',
        onAddressVisibilityChanged
    }: Props) => {
        const stripe = useStripe()
        const elements = useElements()

        const [name, setName] = useState('')
        const [address, setAddress] =
            useState<StripeAddressElementChangeEvent['value']['address']>()
        const ready = useBoolean()
        const showAddress = useBoolean()

        return (
            <form
                onSubmit={e => {
                    e.preventDefault()
                    submitStripeForm({ elements, stripe, name, onError, onSuccess })
                }}
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}
            >
                {ready.isTrue && showAddress.isFalse && (
                    <Box mb={2}>
                        <Typography
                            data-test="paymentForm-cardholder-name-text"
                            style={{ color: '#30313d', fontSize: '14px' }}
                        >
                            Cardholder name *
                        </Typography>
                        <TextField
                            data-test="paymentForm-cardholder-name-field"
                            required
                            placeholder=""
                            value={name}
                            variant="outlined"
                            fullWidth
                            onChange={e => setName(e.target.value)}
                            sx={{
                                '& fieldset': {
                                    border: 'none'
                                },

                                '& input': {
                                    padding: '9px 14px',
                                    borderRadius: '5px'
                                },

                                '& input, & input:hover': {
                                    border: '1px solid #e6e6e6',
                                    boxShadow:
                                        '0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)'
                                },

                                '& input:focus': {
                                    borderColor: '#0573e180',
                                    outline: '2px solid #0573e180'
                                }
                            }}
                        />
                    </Box>
                )}

                <Box flexGrow={1} display={ready.isTrue ? 'block' : 'none'}>
                    <PaymentElement
                        onReady={() => {
                            ready.setTrue()
                            onAddressReady?.()
                        }}
                    />
                    {ready.isTrue && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showAddress.isTrue}
                                    onChange={e => {
                                        showAddress.set(e.target.checked)
                                        onAddressVisibilityChanged?.(e.target.checked)
                                    }}
                                    size="small"
                                    sx={{ color: '#6d6e78', fontSize: '12px' }}
                                />
                            }
                            label="Add billing address"
                            labelPlacement="end"
                        />
                    )}
                    {showAddress.isTrue && (
                        <AddressElement
                            options={{
                                mode: 'billing',
                                display: {
                                    name: 'organization'
                                },
                                defaultValues: {
                                    name,
                                    ...(address && { address })
                                }
                            }}
                            onChange={event => {
                                setName(event.value.name)
                                onAddressChange?.(event)
                                setAddress(event.value.address)
                            }}
                        />
                    )}
                </Box>

                {ready.isFalse && (
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                        <GuiddeRoundLoader />
                    </Box>
                )}

                {ready.isTrue && (
                    <CheckoutFormButton
                        submitBtnName={submitBtnName}
                        disabled={isLoading || !elements || !stripe}
                    />
                )}
            </form>
        )
    }
)

type CheckoutFormButtonProps = {
    disabled?: boolean
    submitBtnName: string
    onClick?: () => void
}
export const CheckoutFormButton = ({
    submitBtnName,
    disabled,
    onClick
}: CheckoutFormButtonProps) => {
    return (
        <Box mt={2}>
            <Button
                type="submit"
                disabled={disabled}
                onClick={onClick}
                fullWidth
                style={{ letterSpacing: 1.25, textTransform: 'uppercase' }}
                variant="contained"
            >
                {submitBtnName}
            </Button>

            <Box mt={2} justifyContent="space-between" display="flex" alignItems="center">
                <Typography variant="caption" sx={{ color: '#78909c' }}>
                    Cancel any time.
                </Typography>
                <Typography
                    variant="caption"
                    data-test="payment-viewTerms"
                    onClick={() => {
                        logToAnalytics('open_view_terms')
                        openLink(links.terms)
                    }}
                    sx={{
                        color: '#26c6da',
                        cursor: 'pointer'
                    }}
                >
                    View terms
                </Typography>
            </Box>
        </Box>
    )
}
