import { useRef, memo, useState, useEffect } from 'react'

import Cropper, { type ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Box, Button, Slider, Typography } from '@mui/material'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'
import { ReactComponent as ReplaceImage } from 'assets/icons/add-image.svg'

import { SpacedGroup } from 'UI/Components'

import { GeneralDialog } from './GeneralDialog'

type Props = {
    isOpen: boolean
    hideReplaceImageButton?: boolean
    cropBoxResizable?: boolean
    maxHeight: number
    initialAspectRatio?: number
    title: string
    onClose: () => void
    onSubmit: (file: File, originalFile: File | null) => void
    onDelete: () => void
    isLoading: boolean
    url: string
    dimensions?: [number, number] // [width, height]
}

export const CropperDialog = memo(
    ({
        onSubmit,
        onDelete,
        url,
        hideReplaceImageButton,
        maxHeight,
        cropBoxResizable = false,
        initialAspectRatio = 1,
        dimensions = [200, 200],
        title,
        ...props
    }: Props) => {
        const [zoom, setZoom] = useState(0)

        const [previewURL, setPreviewURL] = useState(url)

        const cropperRef = useRef<ReactCropperElement>(null)
        const inputRef = useRef<HTMLInputElement>(null)

        const [originalFile, setOriginalFile] = useState<File | null>(null)

        const onSave = async () => {
            const imageElement = cropperRef?.current
            const cropper = imageElement?.cropper

            if (!cropper) return
            cropper?.getCroppedCanvas()?.toBlob(file => {
                if (!file) return

                onSubmit(file as File, originalFile)
            })
        }

        useEffect(() => {
            if (props.isOpen) return

            setPreviewURL(url)
            setZoom(0)
            setOriginalFile(null)
        }, [props.isOpen, url])

        useEffect(() => {
            setPreviewURL(url)
        }, [url])

        useEffect(() => {
            const imageElement = cropperRef?.current
            const cropper = imageElement?.cropper

            if (!cropper) return

            const delta = 1 + zoom / 100

            cropper.scale(delta, delta)
        }, [zoom])

        return (
            <>
                <input
                    ref={inputRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/png"
                    onChange={async e => {
                        const file = e.target.files?.[0]

                        if (!file) return

                        setOriginalFile(file)
                        setPreviewURL(URL.createObjectURL(file))
                    }}
                />
                <GeneralDialog
                    title={title}
                    {...props}
                    maxWidth="sm"
                    actions={
                        <Box display="flex" width="100%" justifyContent="space-between">
                            <SpacedGroup spacing={1}>
                                {!hideReplaceImageButton && (
                                    <Button
                                        startIcon={<ReplaceImage />}
                                        variant="text"
                                        size="large"
                                        color="inherit"
                                        onClick={() => inputRef.current?.click()}
                                    >
                                        REPLACE IMAGE
                                    </Button>
                                )}
                                <Button
                                    startIcon={<BinIcon />}
                                    variant="text"
                                    color="inherit"
                                    size="large"
                                    onClick={onDelete}
                                >
                                    DELETE
                                </Button>
                            </SpacedGroup>
                            <Button
                                onClick={onSave}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                SAVE CHANGES
                            </Button>
                        </Box>
                    }
                >
                    <Box height={Math.max(250, dimensions[1]) + 'px'}>
                        <Cropper
                            src={previewURL}
                            style={{ minHeight: dimensions[0], maxHeight, width: '100%' }}
                            initialAspectRatio={initialAspectRatio}
                            minCropBoxWidth={dimensions[0]}
                            autoCropArea={1}
                            minCropBoxHeight={dimensions[1]}
                            zoomOnWheel={false}
                            cropBoxResizable={cropBoxResizable}
                            toggleDragModeOnDblclick={false}
                            background={false}
                            responsive={true}
                            ref={cropperRef}
                            dragMode="none"
                        />
                    </Box>
                    <Box p={2} width="100%" borderBottom="1px solid rgba(33, 33, 33, 0.08)">
                        <Typography align="center" style={{ fontSize: '14px', fontWeight: 500 }}>
                            Drag to reposition image
                        </Typography>
                    </Box>
                    <Box
                        p={2}
                        width="100%"
                        borderBottom="1px solid rgba(33, 33, 33, 0.08)"
                        display="flex"
                        justifyContent="center"
                    >
                        <Box width="250px">
                            <Box
                                mb={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography style={{ fontSize: 14 }}>Zoom</Typography>
                                <Typography style={{ fontSize: 14 }}>{zoom}%</Typography>
                            </Box>
                            <Slider
                                step={1}
                                size="small"
                                value={zoom}
                                onChange={(_, value) => {
                                    if (Array.isArray(value)) return

                                    setZoom(value)
                                }}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                                marks={true}
                            />
                        </Box>
                    </Box>
                </GeneralDialog>
            </>
        )
    }
)
