import dayjs, { type Dayjs } from 'dayjs'

import { secondsToTime } from 'modules'

import { type DatePeriod, type InsightsPredefinedPeriod, InsightsPeriod } from 'app/types/insights'

export const insightsDefaultPeriod = InsightsPeriod.Month

export const insightsPeriodLabel = {
    [InsightsPeriod.Week]: 'Last week',
    [InsightsPeriod.Month]: 'Last month',
    [InsightsPeriod.Quarter]: 'Last 3 months',
    [InsightsPeriod.HalfYear]: 'Last 6 months',
    [InsightsPeriod.Custom]: 'Custom'
}

export const insightsPeriodEndPoint = dayjs()

export const insightsPeriodDates = {
    [InsightsPeriod.Week]: {
        start: dayjs().subtract(1, 'week'),
        end: insightsPeriodEndPoint
    },
    [InsightsPeriod.Month]: {
        start: dayjs().subtract(1, 'month'),
        end: insightsPeriodEndPoint
    },
    [InsightsPeriod.Quarter]: {
        start: dayjs().subtract(90, 'days'),
        end: insightsPeriodEndPoint
    },
    [InsightsPeriod.HalfYear]: {
        start: dayjs().subtract(180, 'days'),
        end: insightsPeriodEndPoint
    }
}

export enum InsightTableSortFields {
    name = 'name',
    loads = 'loads',
    views = 'views',
    avgWatchTime = 'avgWatchTime',
    avgWatched = 'avgWatched',
    viewers = 'viewers',
    creator = 'creator'
}

export const generateEmptyChartData = ({
    period,
    datePeriod
}:
    | { period: InsightsPredefinedPeriod; datePeriod: null }
    | { period: InsightsPeriod.Custom; datePeriod: DatePeriod }) => {
    const periodDates =
        period === InsightsPeriod.Custom
            ? { start: datePeriod.start, end: datePeriod.end }
            : insightsPeriodDates[period]

    const startDate = periodDates.start
    const endDate = periodDates.end
    const amountOfDays = endDate.diff(startDate, 'day')

    if (amountOfDays <= 0) return []

    return Array(amountOfDays)
        .fill(startDate)
        .map((day, index) => ({
            date: day.add(index, 'day').valueOf(),
            loads: 0,
            views: 0,
            viewers: 0,
            avgWatchTime: 0,
            avgWatched: 0
        }))
}

export const formatWatchTime = (watchTime: number) => {
    const { hours, seconds, minutes } = secondsToTime(watchTime)

    const h = hours >= 1 ? [`${hours}h`] : []
    const m = minutes >= 1 ? [`${minutes}m`] : []

    const s = h.length === 0 && (m.length === 0 || seconds !== 0) ? [`${seconds}s`] : []

    // Prevent word-wrap inside charts
    const noBreakSpace = '\u00A0'

    return [...h, ...m, ...s].join(noBreakSpace)
}

export const formatWatchPercent = (watchPercent: number) => `${watchPercent}%`
export const formatViewNumber = (number: number) => new Intl.NumberFormat('en').format(number)

export const formatRequestDate = (date: Dayjs) => date.format('YYYY-MM-DD')
