import { memo, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { type StepTimeline } from './SmartPreviewProvider'
import { getVolumeFromBreakPoint } from 'UI/Components'

import { round } from 'modules'

type Props = {
    currentTime: number
    status: 'playing' | 'paused'
    activeIndex: number
    steps: Array<StepTimeline>
    totalProgress: number
}

export const AudioTracks = memo(
    ({ activeIndex, steps, currentTime, status, totalProgress }: Props) => {
        const voiceoversRef = useRef<Array<HTMLAudioElement | null>>(Array(steps.length).fill(null))
        const playerTime = voiceoversRef.current[activeIndex]?.currentTime || 0

        const { stepStart, transitionDuration } = steps[activeIndex]

        const reduxSteps = useSelector(state => state.qgEditor.present.steps)
        const audioNote = reduxSteps[activeIndex]?.audioNote

        const voiceoverStart = stepStart + transitionDuration
        const audioDuration =
            (audioNote?.type !== 'defaultSubtitles' && audioNote?.audioDuration) || 0

        // Logic to auto play/pause voiceover audio on smart preview change
        useEffect(() => {
            const voiceoverRef = voiceoversRef.current[activeIndex]
            if (!voiceoverRef || !audioDuration) return

            switch (status) {
                case 'playing':
                    if (!voiceoverRef.paused || voiceoverRef.ended) return
                    voiceoverRef.play()
                    break
                case 'paused':
                    if (!voiceoverRef.paused) voiceoverRef.pause()
                    const expectedTime = round(currentTime - voiceoverStart)
                    const offset = expectedTime - playerTime

                    // If the current time was changed by more than 0.2s and audio is not finished
                    // - seek to the expected time
                    if (Math.abs(offset) >= 0.2 && expectedTime < audioDuration) {
                        voiceoverRef.currentTime = expectedTime
                    }
                    break
            }
        }, [currentTime, voiceoverStart, status, activeIndex, audioDuration, playerTime])

        // Logic to stop all other voiceover audio when we switch to another step and current audio is playing
        useEffect(() => {
            voiceoversRef.current.forEach((voiceoverAudio, index) => {
                if (index === activeIndex || !voiceoverAudio) return

                if (!voiceoverAudio.paused) voiceoverAudio.pause()
                if (voiceoverAudio.currentTime) voiceoverAudio.currentTime = 0
            })
        }, [activeIndex])

        return (
            <>
                {reduxSteps.map((step, index) => {
                    const { audioNote } = step
                    if (!audioNote || audioNote.type === 'defaultSubtitles') return null

                    return (
                        <audio
                            key={index}
                            ref={el => {
                                voiceoversRef.current[index] = el
                            }}
                            src={audioNote.audioUrl}
                            controls
                            preload="auto"
                            style={{ display: 'none' }}
                        />
                    )
                })}

                <BgAudioTrack
                    status={status}
                    currentTime={currentTime}
                    totalProgress={totalProgress}
                />
            </>
        )
    }
)

type BgAudioTrackProps = {
    status: 'playing' | 'paused'
    currentTime: number
    totalProgress: number
}

const BgAudioTrack = memo(({ status, currentTime, totalProgress }: BgAudioTrackProps) => {
    const bgAudioRef = useRef<HTMLAudioElement>(null)

    const { audio } = useSelector(state => state.qgMetadata)

    const playerTime = bgAudioRef.current?.currentTime || 0

    useEffect(() => {
        if (status === 'playing') bgAudioRef.current?.play()
        else bgAudioRef.current?.pause()
    }, [status])

    // Logic to seek background audio to the expected time
    useEffect(() => {
        if (!bgAudioRef.current || !audio) return

        // If current time was changed by more than 0.2s and audio is not finished
        // - seek to the expected time
        if (Math.abs((currentTime % audio.duration) - playerTime) >= 0.2) {
            bgAudioRef.current.currentTime = currentTime % audio.duration
        }
    }, [audio, currentTime, playerTime])

    // Change volume of background audio based on qgMetadata reducer
    useEffect(() => {
        if (!bgAudioRef.current || !audio?.volume) return

        bgAudioRef.current.volume = getVolumeFromBreakPoint(audio.volume) / 100
    }, [audio])

    if (!audio?.url) return null

    return (
        <audio
            ref={bgAudioRef}
            src={audio.url}
            controls
            loop
            style={{ display: 'none' }}
            onTimeUpdate={e => {
                // Reset audio when 'smart preview' has ended
                //  to make start from beginning smoothly
                const target = e.target as HTMLAudioElement

                if (totalProgress === 100 && target.paused) target.currentTime = 0
            }}
        />
    )
})
