import { Stack, styled, Typography } from '@mui/material'
import Icon from '@mui/icons-material/ErrorOutline'

import { useServiceUsage } from 'hooks'
import { formatDate } from 'modules'

const Container = styled(Stack)(({ theme }) => ({
    fontWeight: 500,
    borderRadius: '4px',
    padding: '10px 15px',
    background: '#F89804',
    color: '#fff',
    width: '445px',
    marginBottom: theme.spacing(2)
}))

export const UpcomingPlanNotification = () => {
    const { upcomingPlan } = useServiceUsage()

    if (!upcomingPlan) return null

    const date = upcomingPlan?.startsAt?.toMillis() / 1000 || 0

    return (
        <Container direction="row" spacing={2}>
            <Icon />
            <Typography data-test="account-settings-upcoming-plan-banner" variant="subtitle2">
                Your plan will be changed to {upcomingPlan.name} at {formatDate(date)}
            </Typography>
        </Container>
    )
}
