import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import Slicing from './slicing.svg'

export type ActiveBarProps = {
    /**
     * show slice button
     */
    showSlice?: boolean
    /**
     * show current point with slice button
     */
    showEnd?: boolean
    /**
     * split current section into 2
     */
    onSlice?: () => void
    /**
     * percentage of the current section
     */
    progress: number
    /**
     * color of the bar
     */
    background: string
}

export const ActiveBar = ({
    showSlice,
    showEnd,
    progress = 0, // percent of current slice
    background,
    onSlice
}: ActiveBarProps) => {
    return (
        <Box
            sx={{
                width: `${progress}%`,
                background: background,
                height: '100%',
                position: 'absolute',
                bottom: 0,
                borderRadius: 0
            }}
        >
            {showEnd && (
                <>
                    <Box
                        sx={{
                            width: '3px',
                            height: 'calc(100% + 4px)',
                            position: 'absolute',
                            top: '-2px',
                            right: '-1.5px',
                            borderRadius: 0,
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
                            backgroundColor: 'black',
                            zIndex: 12
                        }}
                    />

                    {showSlice && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                position: 'absolute',
                                top: 'calc(100% + 8px)',
                                right: '-0.75rem',
                                background: '#090c10',
                                borderRadius: '50%',
                                zIndex: 1
                            }}
                        >
                            <Tooltip title="Split">
                                <IconButton size="small" onClick={onSlice}>
                                    <img
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            padding: '3px'
                                        }}
                                        src={Slicing}
                                        alt="slice"
                                        draggable={false}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}
