import * as types from 'ducks/types'
import { type ActionType, type Reducer } from 'ducks/common'
import { store } from 'ducks/store'

import { type SortedAudioConfigsType, type SpeakerType } from 'app/types'
import { setUserPreferences } from 'ducks'

type StateType = {
    audioConfigs: SortedAudioConfigsType
    userPreferences: typeof defaultPreferences
}

const convertToSortedObject = (speakersArr: Array<SpeakerType>) => {
    return speakersArr.reduce<SortedAudioConfigsType['speakers']>(
        (acc, it) => ({
            ...acc,
            [it.langCode]: {
                ...acc[it.langCode],
                langName: it.langName,
                options: [...(acc[it.langCode]?.options || []), it]
            }
        }),
        {}
    )
}
const isJsonString = (str: any) => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

const defaultPreferences = {
    s2tBanner: true,
    t2sBanner: true,
    micId: null as string | null,
    s2tAvatar: true,
    t2sAvatar: true,
    playerVolume: 100,
    speakingRate: 1
}

const getUserPreferences = (orgId?: string): StateType['userPreferences'] => {
    return Object.entries(defaultPreferences).reduce((acc, [key, defaultValue]) => {
        const storageValue =
            (orgId
                ? localStorage?.[`${key}${'-' + orgId}`] || localStorage?.[key]
                : localStorage?.[key]) || defaultValue
        const value = isJsonString(storageValue) ? JSON.parse(storageValue) : storageValue

        return { ...acc, [key]: value }
    }, defaultPreferences)
}

export const updateUserPreferences = <K extends keyof typeof defaultPreferences>(
    key: K,
    value: (typeof defaultPreferences)[K]
) => {
    const orgId = store.getState().appAuth.roles.o || ''
    const storageKey = orgId ? `${key}-${orgId}` : key

    if (localStorage) {
        localStorage[storageKey] = JSON.stringify(value)
    }

    store.dispatch(setUserPreferences(orgId))
}

const initialState: StateType = {
    audioConfigs: {
        s2t: { maxDuration: 0 },
        speakers: {},
        t2s: { maxChars: 0 }
    },
    userPreferences: getUserPreferences()
}

export const configsReducer: Reducer<StateType> = (
    state = initialState,
    action: ActionType
): StateType => {
    switch (action.type) {
        case types.SET_AUDIO_CONFIGS: {
            const { speakers, ...rest } = action.payload

            return {
                ...state,
                audioConfigs: {
                    speakers: convertToSortedObject(speakers),
                    ...rest
                }
            }
        }

        case types.SET_USER_PREFERENCES: {
            return { ...state, userPreferences: getUserPreferences(action.payload) }
        }

        default: {
            return state
        }
    }
}
