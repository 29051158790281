import { type ReactElement, memo, useEffect, useRef, useState } from 'react'

import { Box } from '@mui/material'
import { styled } from '@mui/system'

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

import { VideoThumbnail } from './VideoThumbnail'
import { round } from 'modules'

const sliderWidth = 10

export const minMaxValue = (value: number, min: number, max: number): number => {
    return Math.min(Math.max(value, min), max)
}

type FilterProps = {
    side: 'left' | 'right'
}

const Filter = styled(Box, {
    shouldForwardProp: propName => propName !== 'side'
})<FilterProps>(({ side }) => ({
    position: 'absolute',
    top: 0,
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    borderTop: '4px solid lightgray',
    borderBottom: '4px solid lightgray',
    ...(side === 'right' && {
        right: -sliderWidth,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        borderRight: `${sliderWidth}px solid lightgray`
    }),
    ...(side === 'left' && {
        left: -sliderWidth,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderLeft: `${sliderWidth}px solid lightgray`
    })
}))

const Container = styled(Box)({
    position: 'relative',
    width: '100%',
    height: '51px',
    margin: '0 10px !important'
})

const ActiveBlock = styled(Box)({
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '4px solid #CB0000',
    borderBottom: '4px solid #CB0000',
    zIndex: 1,
    cursor: 'pointer'
})

const Slider = styled(Box, {
    shouldForwardProp: propName => propName !== 'side'
})<{ side: 'left' | 'right' }>(({ side }) => ({
    position: 'absolute',
    top: -4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: sliderWidth,
    height: 'calc(100% + 8px)',
    backgroundColor: '#CB0000',
    overflow: 'hidden',
    ...(side === 'left' && {
        left: -sliderWidth,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
    }),
    ...(side === 'right' && {
        right: -sliderWidth,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4
    })
}))

type Props = {
    range: [number, number]
    minRangeDuration: number
    timeIndicator: ReactElement
    thumbnailUrl: string
    onClick: (percentage: number) => void
    onChange: (start: number, end: number) => void
}

export const TrimSection = memo(
    ({ range, minRangeDuration, timeIndicator, thumbnailUrl, onClick, onChange }: Props) => {
        const containerRef = useRef<HTMLDivElement>(null)

        const [resize, setResize] = useState<'left' | 'right' | null>(null)

        useEffect(() => {
            const handleMouseMove = (event: MouseEvent) => {
                event.preventDefault()
                event.stopPropagation()

                if (!resize) return

                const containerRect = containerRef.current!.getBoundingClientRect()
                const mouseX = event.clientX
                const newValue = round(((mouseX - containerRect.left) / containerRect.width) * 100)

                if (resize === 'left') {
                    const leftValue = minMaxValue(newValue, 0, range[1] - minRangeDuration)
                    onChange(leftValue, range[1])
                }

                if (resize === 'right') {
                    const rightValue = minMaxValue(newValue, range[0] + minRangeDuration, 100)
                    onChange(range[0], rightValue)
                }
            }

            const handleMouseUp = (event: MouseEvent) => {
                event.stopPropagation()
                if (resize) setResize(null)
            }

            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)

            return () => {
                document.removeEventListener('mousemove', handleMouseMove)
                document.removeEventListener('mouseup', handleMouseUp)
            }
        }, [minRangeDuration, onChange, range, resize])

        return (
            <Container ref={containerRef}>
                <VideoThumbnail thumbnailUrl={thumbnailUrl} />
                {timeIndicator}

                {/* Grayed filter on the left */}
                <Filter side="left" width={range[0] + '%'} />

                <ActiveBlock
                    left={range[0] + '%'}
                    width={range[1] - range[0] + '%'}
                    onClick={e => {
                        const target = e.target as HTMLDivElement
                        const container = containerRef.current!

                        if (target.className?.includes('slider')) return

                        const clickedX = e.clientX - container.getBoundingClientRect().left
                        const totalPercentage = (clickedX / container.clientWidth) * 100

                        onClick(totalPercentage)
                    }}
                >
                    <Slider
                        side="left"
                        className="slider"
                        style={{ cursor: 'w-resize' }}
                        onMouseDown={() => setResize('left')}
                    >
                        <KeyboardArrowLeftRoundedIcon
                            sx={{ color: 'white', pointerEvents: 'none' }}
                        />
                    </Slider>

                    <Slider
                        side="right"
                        className="slider"
                        style={{ cursor: 'e-resize' }}
                        onMouseDown={() => setResize('right')}
                    >
                        <KeyboardArrowRightRoundedIcon
                            sx={{ color: 'white', pointerEvents: 'none' }}
                        />
                    </Slider>
                </ActiveBlock>

                {/* Grayed filter on the right */}
                <Filter side="right" width={100 - range[1] + '%'} />
            </Container>
        )
    }
)
