import { useRef } from 'react'
import { useDispatch } from 'react-redux'

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'

import { Box, ClickAwayListener, Divider, Grid, Paper, Popper, Tooltip } from '@mui/material'

import { useBoolean } from 'hooks'
import { setFontStyles } from 'ducks'
import { type TextShapeType } from 'app/types'

const options = [
    { icon: FormatAlignLeftIcon, align: 'left' },
    { icon: FormatAlignCenterIcon, align: 'center' },
    { icon: FormatAlignRightIcon, align: 'right' }
] as const

type Props = {
    currentLayer: TextShapeType
}

export const FontAlignment = ({ currentLayer }: Props) => {
    const dispatch = useDispatch()

    const anchorEl = useRef<null | HTMLElement>(null)

    const showOptions = useBoolean()

    const Icon =
        options.find(option => option.align === currentLayer.align)?.icon || options[0].icon

    return (
        <>
            <Tooltip title="Font alignment" ref={anchorEl}>
                <Icon fontSize="small" onClick={showOptions.toggle} style={{ cursor: 'pointer' }} />
            </Tooltip>

            <Popper
                open={showOptions.isTrue}
                anchorEl={anchorEl?.current}
                placement="top"
                style={{ zIndex: 2000, marginBottom: 5 }}
            >
                <ClickAwayListener
                    onClickAway={e => {
                        if ((e.target as Element).nodeName === 'BODY') return
                        showOptions.setFalse()
                    }}
                >
                    <Paper style={{ borderRadius: 2 }}>
                        <Grid container alignItems="center" style={{ borderRadius: 'inherit' }}>
                            {options.map((option, index) => (
                                <Box key={index}>
                                    <option.icon
                                        sx={{
                                            padding: '3px',
                                            background: 'transparent',
                                            '&:hover': {
                                                background: 'rgba(203, 0, 0, 0.2)',
                                                color: '#CB0000',
                                                cursor: 'pointer'
                                            },
                                            ...(option.align === currentLayer.align && {
                                                background: 'rgba(203, 0, 0, 0.1)',
                                                color: '#CB0000'
                                            })
                                        }}
                                        onClick={() => {
                                            dispatch(setFontStyles({ align: option.align }))
                                            showOptions.setFalse()
                                        }}
                                    />
                                    {index !== options.length - 1 && (
                                        <Divider orientation="vertical" flexItem />
                                    )}
                                </Box>
                            ))}
                        </Grid>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
