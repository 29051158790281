import { type ReactNode, memo, useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import clsx from 'clsx'

import { ReactComponent as ReplaceImage } from 'assets/icons/add-image.svg'

import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import {
    Box,
    Button,
    Slider,
    Typography,
    CircularProgress,
    Tooltip,
    IconButton,
    styled
} from '@mui/material'
import HistoryIcon from '@mui/icons-material/History'

import { CommonDialog } from 'UI/Components'

import { firebaseConfig } from 'env'
import { getFirebaseUrl, uploadWithSignedUrl, uuid } from 'modules'

const PREFIX = 'ImageCropper'

const classes = {
    circular: `${PREFIX}-circular`,
    cropper: `${PREFIX}-cropper`
}

const StyledBox = styled(Box)(() => ({
    [`& .${classes.circular}`]: {
        '& .cropper-view-box': {
            borderRadius: '50%'
        }
    },

    [`& .${classes.cropper}`]: {
        zIndex: 2,
        position: 'relative',
        height: 360,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

type Props = {
    labelComponent: ReactNode
    storagePath: string
    isTempStorage: boolean
    aspectRatio?: number
    circularViewBox?: boolean
    initialPreviewURL?: string
    text?: string
    title?: string
    tooltipTitle?: string
    disableReset?: boolean
    showReplacePhotoButton?: boolean
    onClose?: () => void
    onDone: (metadata: { url: string; width: number; height: number; zoom: number }) => void
    onReset?: () => void
}

export const ImageCropper = memo(
    ({
        storagePath,
        isTempStorage,
        showReplacePhotoButton,
        title = 'Crop the image',
        aspectRatio,
        labelComponent,
        initialPreviewURL = '',
        circularViewBox,
        disableReset,
        text = 'Drag to reposition image',
        tooltipTitle = 'Reset changes',
        onClose,
        onDone,
        onReset
    }: Props) => {
        const loading = useBoolean()

        const cropperRef = useRef<any>(null)
        const inputRef = useRef<HTMLInputElement>(null)

        const [zoom, setZoom] = useState(0)
        const [previewURL, setPreviewURL] = useState(initialPreviewURL)

        useEffect(() => {
            if (!initialPreviewURL) return

            setPreviewURL(initialPreviewURL)
        }, [initialPreviewURL])

        const handleClose = () => {
            if (onClose) {
                onClose()
                setPreviewURL('')
                return
            }

            setPreviewURL('')
            setZoom(0)
            if (inputRef.current) inputRef.current.value = ''
        }

        const handleUpload = async () => {
            loading.setTrue()

            const imageElement: any = cropperRef?.current
            const cropper = imageElement?.cropper

            const { width, height } = cropper.getCroppedCanvas()

            cropper.getCroppedCanvas()?.toBlob(async (file: Blob) => {
                const splitName = file.type.split('/')
                const fileFormat = splitName.pop() || 'png'

                const tempStoragePath = isTempStorage ? `${firebaseConfig.projectId}-temporary` : ''
                const bucket = 'gs://' + tempStoragePath
                const storageUrl = `${storagePath}/${uuid()}.${fileFormat}`

                await uploadWithSignedUrl(bucket + '/' + storageUrl, file)
                const url = await getFirebaseUrl(storageUrl, isTempStorage)

                onDone({ url, width, height, zoom: 1 + zoom / 100 })
                loading.setFalse()
                handleClose()
            })
        }

        useEffect(() => {
            const imageElement: any = cropperRef?.current
            const cropper: any = imageElement?.cropper

            if (!cropper) return

            const delta = 1 + zoom / 100

            cropper.scale(delta, delta)
        }, [zoom])

        return (
            <>
                <label style={{ cursor: 'pointer' }}>
                    {labelComponent}
                    <input
                        ref={inputRef}
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                        onChange={e => {
                            const file = e.target.files?.[0]
                            if (!file) return

                            setPreviewURL(URL.createObjectURL(file))
                        }}
                    />
                </label>
                <CommonDialog
                    isOpen={Boolean(previewURL)}
                    loading={loading.isTrue}
                    title={title}
                    titleSize="md"
                    onClose={handleClose}
                    content={
                        <>
                            <StyledBox position="relative">
                                <Box
                                    position="absolute"
                                    zIndex={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    top={0}
                                    bottom={0}
                                    left={0}
                                    right={0}
                                >
                                    <CircularProgress />
                                </Box>

                                <Cropper
                                    ref={cropperRef}
                                    src={previewURL}
                                    className={clsx(classes.cropper, {
                                        [classes.circular]: circularViewBox
                                    })}
                                    aspectRatio={aspectRatio}
                                    initialAspectRatio={1}
                                    viewMode={1}
                                    responsive={true}
                                    background={false}
                                    zoomOnWheel={false}
                                    dragMode="move"
                                />
                            </StyledBox>
                            <Box
                                px={1}
                                py={0.5}
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid rgba(33, 33, 33, 0.08)"
                            >
                                <Box width={30} />
                                <Typography
                                    align="center"
                                    style={{ fontSize: '14px', fontWeight: 500 }}
                                >
                                    {text}
                                </Typography>
                                <Tooltip
                                    title={disableReset ? '' : tooltipTitle}
                                    placement="left"
                                    componentsProps={{
                                        popper: {
                                            sx: theme => ({
                                                zIndex: theme.zIndex.modal + 2
                                            })
                                        }
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        disabled={disableReset}
                                        onClick={() => {
                                            if (onReset) {
                                                onReset()
                                            } else {
                                                cropperRef.current.cropper.reset()
                                                setZoom(0)
                                            }
                                        }}
                                    >
                                        <HistoryIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box
                                mt={1}
                                width="100%"
                                borderBottom="1px solid rgba(33, 33, 33, 0.08)"
                                display="flex"
                                justifyContent="center"
                            >
                                <Box width={250}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography style={{ fontSize: 14 }}>Zoom</Typography>
                                        <Typography style={{ fontSize: 14 }}>{zoom}%</Typography>
                                    </Box>
                                    <Slider
                                        step={1}
                                        value={zoom}
                                        onChange={(_, value) => {
                                            if (Array.isArray(value)) return

                                            setZoom(value)
                                        }}
                                        min={0}
                                        max={100}
                                        valueLabelDisplay="off"
                                        marks={true}
                                    />
                                </Box>
                            </Box>
                        </>
                    }
                    hideCloseBtn={true}
                    hideConfirmBtn={true}
                    extraActions={
                        <Box
                            mt={-2}
                            mb={-1}
                            display="flex"
                            width="100%"
                            justifyContent="space-between"
                        >
                            {showReplacePhotoButton ? (
                                <Button
                                    startIcon={<ReplaceImage />}
                                    variant="text"
                                    color="inherit"
                                    onClick={() => inputRef.current?.click()}
                                >
                                    REPLACE IMAGE
                                </Button>
                            ) : (
                                <Box />
                            )}
                            <Button
                                onClick={handleUpload}
                                disabled={loading.isTrue}
                                variant="contained"
                            >
                                CONFIRM
                            </Button>
                        </Box>
                    }
                />
            </>
        )
    }
)
