import { useState } from 'react'

import { ListItemText, Avatar, Box, ListItemButton } from '@mui/material'
import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { ProfilePopper, SpacedGroup, useMenuStatus } from 'UI/Components'

import { useAuth } from 'hooks'
import { CollapsedMenuTooltip } from './CollapsedMenuTooltip'

export const MenuProfile = () => {
    const [profileRef, setProfileRef] = useState<HTMLElement | null>(null)

    const { displayName, email, photoURL } = useAuth()
    const { isMenuClosed } = useMenuStatus()

    return (
        <Box minHeight={70} display="flex" alignItems="center">
            <CollapsedMenuTooltip title="My Profile">
                <ListItemButton
                    data-test="menu-user"
                    sx={theme => ({
                        ...(!isMenuClosed && { padding: theme.spacing(0.5, 4, 0.5, 2) })
                    })}
                >
                    <SpacedGroup
                        spacing={1}
                        pl={0.5}
                        onClick={e => {
                            const target = e.currentTarget as HTMLElement
                            setProfileRef(target)
                        }}
                    >
                        <Avatar
                            sx={{
                                height: '30px',
                                width: '30px',
                                borderRadius: '50%'
                            }}
                            src={`${photoURL}&time=${performance.now()}`}
                        />

                        {!isMenuClosed && (
                            <TextOverflow>
                                <ListItemText
                                    primary={displayName || email}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontSize: '14px',
                                            letterSpacing: 0.1,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: '150px'
                                        }
                                    }}
                                    secondary={displayName === email ? '' : email}
                                    secondaryTypographyProps={{
                                        sx: {
                                            fontSize: '0.75rem',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: '150px'
                                        }
                                    }}
                                />
                            </TextOverflow>
                        )}
                    </SpacedGroup>
                </ListItemButton>
            </CollapsedMenuTooltip>

            <ProfilePopper anchorEl={profileRef} onClose={() => setProfileRef(null)} />
        </Box>
    )
}
