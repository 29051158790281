import { type ReactNode } from 'react'

import {
    type DialogProps,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    LinearProgress,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { useSubmitDialogWithEnter } from './use-submit-dialog-with-enter'

const PREFIX = 'ConfirmationDialog'

const classes = {
    title: `${PREFIX}-title`,
    text: `${PREFIX}-text`,
    closeButton: `${PREFIX}-closeButton`,
    actions: `${PREFIX}-actions`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.title}`]: {
        fontSize: '1.5rem',
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold
    },

    [`& .${classes.text}`]: {
        textAlign: 'center'
    },

    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5)
    },

    [`& .${classes.actions}`]: {
        padding: theme.spacing(3),
        justifyContent: 'center'
    }
}))

type Props = {
    isOpen: boolean
    title: string
    text?: ReactNode
    body?: ReactNode
    closeBtnText?: string
    confirmBtnText?: string
    onClose: () => void
    onConfirm: () => void
    loading?: boolean
    disablePortal?: boolean
    maxWidth?: DialogProps['maxWidth']
}

export const ConfirmationDialog = ({
    isOpen,
    title,
    text,
    body,
    closeBtnText = 'Cancel',
    confirmBtnText = 'Confirm',
    onClose,
    onConfirm,
    loading,
    disablePortal,
    maxWidth = 'xs'
}: Props) => {
    const { onKeyDown, onKeyUp } = useSubmitDialogWithEnter({ onSubmit: onConfirm })

    return (
        <StyledDialog
            open={isOpen}
            onClose={() => {
                if (loading) return
                onClose()
            }}
            fullWidth
            disablePortal={disablePortal}
            maxWidth={maxWidth}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
        >
            {loading && <LinearProgress />}
            <IconButton
                className={classes.closeButton}
                disabled={loading}
                onClick={onClose}
                size="large"
            >
                <CloseIcon />
            </IconButton>

            <DialogTitle>
                <Typography className={classes.title}>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" className={classes.text}>
                    {text}
                </Typography>
                {body}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    size="medium"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        background: '#EDEEEF',
                        color: 'black',
                        '&:hover': {
                            background: '#EDEEEF'
                        }
                    }}
                >
                    {closeBtnText}
                </Button>
                <Button
                    data-test="confirmation-dialog-submit-button"
                    size="medium"
                    onClick={onConfirm}
                    disabled={loading}
                    variant="contained"
                >
                    {confirmBtnText}
                </Button>
            </DialogActions>
        </StyledDialog>
    )
}
