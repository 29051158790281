import * as types from 'ducks/types'

export type UpdateAuthPayload = {
    user?: {
        uid: string
        displayName: string
        photoURL: string
        email: string
    }
    roles?: {
        o: string
        s: boolean
        a: number
        n?: boolean
        r?: {
            p: boolean
            s: boolean
            u: boolean
        }
    }
    token?: string
    su?: string
    isLoggedInByGuidde?: boolean
    isSessionCalled?: boolean
    refreshPageOnTheNextTokenUpdate?: boolean
}

type ResetAuthPayload = { refreshPageOnTheNextTokenUpdate: boolean }

export const updateAppAuth = (payload: UpdateAuthPayload) =>
    ({
        type: types.UPDATE_APP_AUTH,
        payload
    }) as const

export const resetAppAuth = (payload?: ResetAuthPayload) =>
    ({
        type: types.RESET_APP_AUTH,
        payload
    }) as const

export const setNewAuth = (payload: boolean) =>
    ({
        type: types.REGISTER_SET_AUTH_STATUS,
        payload
    }) as const
