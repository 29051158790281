import { useEffect } from 'react'

import { VideoOverlay } from '@guidde-co/shared.video-player.video-overlay'

import {
    type AnyPlaybookType,
    type VideoOverlayDataType,
    type VideoRefType,
    PlaybookMode
} from 'app/types'
import { paths } from 'app/paths'

import { type PublicBrandKitType, type UseBooleanType, useBoolean } from 'hooks'
import { host, openLink } from 'modules'

import { ShareOnlyDialog } from 'UI/Components'
import { PlaybookFeedbackWrapper } from './PlaybookFeedbackWrapper'
import { VideoOverlayContainer } from './VideoOverlayContainer'

type Props = {
    showVideoOverlay: UseBooleanType
    videoRef: VideoRefType
    playbook: AnyPlaybookType
    watchLabel?: string
    overlayData?: VideoOverlayDataType
    publicBrandKit?: PublicBrandKitType
    onWatchClick?: () => void
}

export const VideoOverlayWrapper = ({
    watchLabel = 'WATCH AGAIN',
    showVideoOverlay,
    overlayData,
    videoRef,
    playbook,
    publicBrandKit,
    onWatchClick
}: Props) => {
    const showShare = useBoolean()

    const showFeedback = useBoolean(true)

    const showFeedbackSetTrue = showFeedback.setTrue

    const isPlaylist = playbook.mode === PlaybookMode.Playlist

    const startPlaying = () => {
        if (!videoRef.current) return

        showVideoOverlay.setFalse()
        videoRef.current.currentTime = 0
        videoRef.current.play()
    }

    useEffect(() => {
        const ref = videoRef.current

        ref?.addEventListener('ended', showFeedbackSetTrue)
        return () => {
            ref?.removeEventListener('ended', showFeedbackSetTrue)
        }
    }, [showFeedbackSetTrue, videoRef])

    if (showVideoOverlay.isFalse) return null

    return (
        <VideoOverlayContainer>
            {showFeedback.isTrue && !overlayData?.hideNewPublicEmbedEnding ? (
                <PlaybookFeedbackWrapper
                    playbook={playbook}
                    background={publicBrandKit?.brandKitBackground}
                    onClose={showFeedback.setFalse}
                />
            ) : (
                <VideoOverlay
                    isVisible={showVideoOverlay.isTrue}
                    onWatch={() => {
                        onWatchClick?.()
                        startPlaying()
                    }}
                    onStartTrialClick={
                        overlayData?.showEndingScreenFooter
                            ? () => openLink(`${host}${paths.signup}`, '_blank')
                            : undefined
                    }
                    icon={
                        overlayData?.brandKit?.logo ? (
                            <img src={overlayData?.brandKit.logo} style={{ maxHeight: 80 }} />
                        ) : null
                    }
                    shareLabel="SHARE"
                    buttonColor={overlayData?.brandKit?.color}
                    watchLabel={watchLabel}
                    onShare={showShare.setTrue}
                />
            )}
            {showShare.isTrue && (
                <ShareOnlyDialog
                    isOpen={true}
                    onDone={() => {}}
                    onClose={showShare.setFalse}
                    playbook={playbook}
                    title={`Share your ${isPlaylist ? 'playlist' : 'video'}`}
                    subtitle={`Invite people to view your ${isPlaylist ? 'Playlist' : 'Playbook'}`}
                    warning=""
                />
            )}
        </VideoOverlayContainer>
    )
}
