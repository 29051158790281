import { createContext, useCallback, useEffect, useState } from 'react'

import { Alert, Snackbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const messageTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    DEFAULT: 'info'
}

export const NotificationContext = createContext({
    showSuccessNotification: (message, params) => ({ message, params }),
    showWarningNotification: (message, params) => ({ message, params }),
    showErrorNotification: (message, params) => ({ message, params }),
    showInfoNotification: (message, params) => ({ message, params })
})

export const NotificationProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState({
        type: messageTypes.DEFAULT,
        text: ''
    })

    const showSuccessNotification = useCallback(
        (text, params) =>
            setMessage({
                type: messageTypes.SUCCESS,
                text,
                params
            }),
        []
    )

    const showWarningNotification = useCallback(
        (text, params) =>
            setMessage({
                type: messageTypes.WARNING,
                text,
                params
            }),
        []
    )

    const showErrorNotification = useCallback(
        (text, params) =>
            setMessage({
                type: messageTypes.ERROR,
                text,
                params
            }),
        []
    )

    const showInfoNotification = useCallback(
        (text, params) =>
            setMessage({
                type: messageTypes.DEFAULT,
                text,
                params
            }),
        []
    )

    const handleClose = (_event, reason) => {
        if (reason === 'clickaway') return
        setOpen(false)
    }

    const contextValue = {
        showSuccessNotification,
        showErrorNotification,
        showWarningNotification,
        showInfoNotification
    }

    useEffect(() => {
        if (message.text) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [message])

    return (
        <NotificationContext.Provider value={contextValue}>
            <Snackbar
                open={open}
                autoHideDuration={message.params?.sticky ? null : 6000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: message.params?.vertical || 'top',
                    horizontal: message.params?.horizontal || 'center'
                }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={message.type}
                    style={message.params?.style}
                    action={
                        <>
                            {message.params?.action}
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                >
                    {message.text}
                </Alert>
            </Snackbar>
            {children}
        </NotificationContext.Provider>
    )
}
