import { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type Konva from 'konva'
import { Group, Rect } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import useImage from 'use-image'

import { type RectangleShapeType } from 'app/types'
import { setLayerTransform } from 'ducks'

import { round } from 'modules'
import { useOrgFeatureFlags } from 'hooks'

function getPatternProps(
    newSize: { width: number; height: number },
    originalSize: { width: number; height: number }
) {
    const widthRatio = newSize.width / originalSize.width
    const heightRatio = newSize.height / originalSize.height

    const scale = Math.min(widthRatio, heightRatio)

    // center the image
    const newWidth = originalSize.width * scale
    const newHeight = originalSize.height * scale

    return {
        fillPatternScaleX: scale,
        fillPatternScaleY: scale,
        fillPatternX: -(newWidth - newSize.width) / 2,
        fillPatternY: -(newHeight - newSize.height) / 2
    }
}

type Props = {
    layer: RectangleShapeType
    hasOverlay: boolean
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const RectangleShape = memo(
    ({ layer, hasOverlay, onSelect, onDragStart, onDragMove, onDragEnd }: Props) => {
        const dispatch = useDispatch()

        const shapeRef = useRef<Konva.Rect>(null)

        const [rectProps, setRectProps] = useState(layer)

        const [patternProps, setPatternProps] = useState<null | {
            fillPatternScaleX: number
            fillPatternScaleY: number
            fillPatternX: number
            fillPatternY: number
        }>(null)

        const [image] = useImage(rectProps?.url || '', 'anonymous')

        const {
            present: { activeStep, steps }
        } = useSelector(state => state.qgEditor)

        const { windowDimensions } = steps[activeStep] || {}

        const { pillarboxWhiteFill } = useOrgFeatureFlags()

        useEffect(() => {
            if (!image || !windowDimensions) return

            const props = getPatternProps(
                { width: windowDimensions.innerWidth, height: windowDimensions.innerHeight },
                { width: image.width, height: image.height }
            )

            setPatternProps(props)
        }, [image, windowDimensions])

        useEffect(() => {
            setRectProps(layer)

            return () => {
                document.body.style.cursor = ''
            }
        }, [layer])

        return (
            <Group>
                {layer.isSpotlight && hasOverlay && (
                    <Rect
                        cornerRadius={rectProps.cornerRadius}
                        x={rectProps.x}
                        y={rectProps.y}
                        width={rectProps.width}
                        height={rectProps.height}
                        scaleX={rectProps.scaleX}
                        scaleY={rectProps.scaleY}
                        rotation={rectProps.rotation}
                        stroke={rectProps.strokeColor}
                        strokeWidth={rectProps.strokeWidth}
                        draggable={false}
                        fill="black"
                        globalCompositeOperation="destination-out"
                    />
                )}

                <>
                    {image && pillarboxWhiteFill && (
                        <Rect
                            width={windowDimensions.innerWidth}
                            height={windowDimensions.innerHeight}
                            fill="white"
                        />
                    )}

                    <Rect
                        className={layer.isBackground ? undefined : 'alignable'}
                        name={
                            layer.isBackground
                                ? 'background'
                                : layer.isSpotlight
                                  ? 'rectangleSpotlight'
                                  : 'rectangle'
                        }
                        id={layer.id}
                        ref={shapeRef}
                        fillPatternRepeat="no-repeat"
                        fillPatternImage={image}
                        fillPatternX={patternProps?.fillPatternX}
                        fillPatternY={patternProps?.fillPatternY}
                        fillPatternScaleX={patternProps?.fillPatternScaleX}
                        fillPatternScaleY={patternProps?.fillPatternScaleY}
                        cornerRadius={rectProps.cornerRadius}
                        x={rectProps.x}
                        y={rectProps.y}
                        fill={rectProps.fill}
                        fillLinearGradientColorStops={rectProps.fillLinearGradientColorStops}
                        fillLinearGradientStartPoint={rectProps.fillLinearGradientStartPoint}
                        fillLinearGradientEndPoint={rectProps.fillLinearGradientEndPoint}
                        width={rectProps.width}
                        height={rectProps.height}
                        scaleX={rectProps.scaleX}
                        scaleY={rectProps.scaleY}
                        rotation={rectProps.rotation}
                        stroke={rectProps.strokeColor}
                        strokeWidth={rectProps.strokeWidth}
                        onMouseOver={() => {
                            if (!Boolean(layer.isBackground)) {
                                document.body.style.cursor = 'move'
                            }
                        }}
                        onMouseLeave={() => (document.body.style.cursor = '')}
                        draggable={!Boolean(layer.isBackground)}
                        onDragStart={onDragStart}
                        onDragMove={e => {
                            setRectProps(prevState => ({
                                ...prevState,
                                x: e.target.attrs.x,
                                y: e.target.attrs.y
                            }))
                            onDragMove(e)
                        }}
                        onDragEnd={onDragEnd}
                        onClick={e => {
                            if (!Boolean(layer.isBackground)) {
                                onSelect(e.evt.shiftKey)
                            }
                        }}
                        onTransform={() => {
                            const node = shapeRef.current
                            if (!node) return

                            const scaleX = node.scaleX()
                            const scaleY = node.scaleY()

                            node.scaleX(1)
                            node.scaleY(1)

                            setRectProps(prevState => ({
                                ...prevState,
                                x: node.x(),
                                y: node.y(),
                                width: node.width() * scaleX,
                                height: node.height() * scaleY,
                                scaleX: 1,
                                scaleY: 1,
                                rotation: node.rotation()
                            }))
                        }}
                        onTransformEnd={() => {
                            const node = shapeRef.current
                            if (!node) return

                            const scaleX = node.scaleX()
                            const scaleY = node.scaleY()

                            node.scaleX(1)
                            node.scaleY(1)

                            dispatch(
                                setLayerTransform({
                                    layerId: layer.id,
                                    x: round(node.x()),
                                    y: round(node.y()),
                                    width: round(node.width() * scaleX),
                                    height: round(node.height() * scaleY),
                                    scaleX: 1,
                                    scaleY: 1,
                                    rotation: node.rotation()
                                })
                            )
                        }}
                    />
                </>
            </Group>
        )
    }
)
