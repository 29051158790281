import { useMemo, memo, useEffect } from 'react'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { Box, Typography, Button, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { ConnectedInput, SpacedGroup } from 'UI/Components'
import { TrackLinksTable } from 'UI/Components/Dialogs/ShareDialog/ShareLink'

import { generalErrorMessage, logToAnalytics } from 'modules'
import { useAuth, useDataMutation, useNotification, useQuery } from 'hooks'

import { type PlaybookTrackingLinkType, type PlaybookType } from 'app/types'

const validateStringsArray = (stringsArray: string[] = []) => {
    return stringsArray.length > 0
        ? new RegExp(`^(?!(${stringsArray.join('|')})$)`)
        : new RegExp('.*')
}

type TrackingLinksResponse = {
    links: Array<PlaybookTrackingLinkType>
}

type CreateTrackingLinkPayload = {
    name: string
    domain?: string
}

type Props = {
    onClose: () => void
    onBack?: () => void
    onDone?: () => void
    setLoading: (val: boolean) => void
    playbook: PlaybookType
}

export const AdvancedTrackingTab = memo(
    ({ onClose, setLoading, onDone, onBack, playbook }: Props) => {
        const { id: playbookId } = playbook

        const { uid, orgId } = useAuth()
        const { showErrorNotification, showSuccessNotification } = useNotification()

        const $trackingLinks = useQuery<TrackingLinksResponse>(
            `/c/v1/tracking-link?playbookId=${playbookId}&orgId=${orgId}`,
            {
                method: 'GET'
            }
        )

        const $createTrackingLink = useDataMutation<
            CreateTrackingLinkPayload,
            { id: string },
            Error
        >(`/c/v1/tracking-link?playbookId=${playbookId}`, 'POST', {
            onSuccess: () => $trackingLinks.mutate()
        })

        useEffect(() => {
            setLoading($trackingLinks.isValidating)
        }, [$trackingLinks.isValidating, setLoading])

        const companyData = useMemo(() => {
            return {
                currentCompanyNames: $trackingLinks.data?.links?.map(tLink => tLink.name) || [],
                currentCompanyDomains: $trackingLinks.data?.links?.map(tLink => tLink.domain) || []
            }
        }, [$trackingLinks.data?.links])

        const validateCompanyNames = validateStringsArray(companyData.currentCompanyNames)

        const handleSubmit = (name: string, domain: string) => {
            return $createTrackingLink
                .mutate({ name, domain })
                .then(response => {
                    if (response) {
                        logToAnalytics('add_tracking_link', {
                            id: response?.id,
                            uid,
                            orgId,
                            track_domain: domain,
                            track_name: name,
                            utm_campaign: name
                        })

                        showSuccessNotification('New link was created')
                        onDone?.()
                    }
                })
                .catch((err: { code?: string }) => {
                    console.error(err)
                    if (err.code === 'permission-denied') {
                        return showErrorNotification(
                            'You don’t have permissions to track this Playbook'
                        )
                    }
                    return showErrorNotification(generalErrorMessage)
                })
        }

        return (
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pt={2}
                    px={2}
                >
                    <SpacedGroup>
                        <IconButton onClick={onBack} size="small">
                            <ArrowBackIcon style={{ color: '#090C10' }} />
                        </IconButton>
                        <Typography
                            sx={theme => ({
                                fontWeight: theme.typography.fontWeightBold
                            })}
                            data-test="dialog-title"
                        >
                            Advanced tracking
                        </Typography>
                    </SpacedGroup>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        data-test="close-button"
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Formik
                    onSubmit={(data, { resetForm }) =>
                        handleSubmit(data.companyName, data.emailDomain).then(() => resetForm())
                    }
                    initialValues={{
                        companyName: '',
                        emailDomain: ''
                    }}
                    validationSchema={yup.object().shape({
                        companyName: yup
                            .string()
                            .min(1)
                            .required('Field is required')
                            .matches(validateCompanyNames, `Company name already exists`),
                        emailDomain: yup
                            .string()
                            .matches(
                                new RegExp(
                                    '(^(([A-Za-z0-9-]{0,61})\\.)+[A-Za-z][A-Za-z]{0,61}[A-Za-z])$'
                                ),
                                'Invalid domain'
                            )
                            .nullable()
                    })}
                >
                    {({ dirty, isValid }) => (
                        <Form>
                            <Box p={2}>
                                <SpacedGroup alignItems="flex-start">
                                    <ConnectedInput
                                        data-test="advnaced-tracking-company-name"
                                        fullWidth
                                        placeholder="Company Name"
                                        name="companyName"
                                        variant="filled"
                                        inputProps={{
                                            style: {
                                                padding: 16
                                            }
                                        }}
                                    />
                                    <ConnectedInput
                                        data-test="advanced-tracking-email-domain"
                                        fullWidth
                                        placeholder="Email Domain"
                                        name="emailDomain"
                                        variant="filled"
                                        inputProps={{
                                            style: {
                                                padding: 16
                                            }
                                        }}
                                    />
                                </SpacedGroup>
                                <Box width="100%" display="flex" justifyContent="flex-end" mt={2}>
                                    <Button
                                        data-test="advanced-tracking-add-link-button"
                                        disabled={
                                            (dirty && !isValid) || $createTrackingLink.isLoading
                                        }
                                        type="submit"
                                        variant="contained"
                                    >
                                        ADD LINK
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
                <Box p={2}>
                    <TrackLinksTable
                        playbook={playbook}
                        trackingLinks={$trackingLinks.data?.links || []}
                        isLoading={$trackingLinks.isValidating}
                        onDelete={$trackingLinks.mutate}
                    />
                </Box>
            </>
        )
    }
)
