import * as types from 'ducks/types'
import { type AudioConfigsType } from 'app/types'

export const setAudioConfigs = (payload: AudioConfigsType) =>
    ({
        type: types.SET_AUDIO_CONFIGS,
        payload
    }) as const

export const setUserPreferences = (payload: string) =>
    ({
        type: types.SET_USER_PREFERENCES,
        payload
    }) as const
