import { useState, memo, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { useAuth, useBoolean, useNotification, usePlaybookShare, useShareableOptions } from 'hooks'
import { generalErrorMessage, logToAnalytics } from 'modules'

import EmptyImage from 'assets/images/space_small.png'

import { paths } from 'app/paths'

import { ShareCommonDialog } from './ShareCommonDialog'

type Props = {
    isOpen: boolean
    onClose: () => void
    playbooksIds: Array<string>
}

export const ShareToSpaceDialog = memo(({ playbooksIds, onClose, isOpen }: Props) => {
    const [title, setTitle] = useState('')

    const history = useHistory()

    const { uid } = useAuth()

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const creating = useBoolean()

    const { sharePlaybook, isSharing, ApprovalModal } = usePlaybookShare({
        onSuccess: (data, input) => {
            if (data?.code === 422) return

            $options.mutate().then(() => {
                showSuccessNotification(
                    input.shareMode === 'unshare'
                        ? 'Successfully removed from the space'
                        : 'Successfully added to the space'
                )
            })
        },
        onFailure: (error: Error) => {
            console.error(error)
            showErrorNotification(error?.message || generalErrorMessage)
        }
    })

    const logShareEvent = () => {
        logToAnalytics('publish_playbook', {
            sharedByUid: uid,
            playbooksIds
        })
    }

    const $options = useShareableOptions({
        playbooksIds,
        params: {
            spaces: true
        },
        onError: onClose
    })

    const isEmptyState = useMemo(() => {
        if ($options.isValidating) return false

        return (
            $options.data?.spaces?.length === 1 &&
            $options.data?.spaces[0].id === 'ALL_CONNECTED_SPACES'
        )
    }, [$options.data, $options.isValidating])

    const showSearchField = useMemo(() => {
        if ($options.isValidating) return false
        return !isEmptyState
    }, [$options.isValidating, isEmptyState])

    const emptyStateText = useMemo(() => {
        if ($options.isValidating) return undefined
        return isEmptyState ? 'Create your first space' : undefined
    }, [$options.isValidating, isEmptyState])

    const emptyStateImage = useMemo(() => {
        if ($options.isValidating) return undefined

        return isEmptyState ? EmptyImage : undefined
    }, [$options.isValidating, isEmptyState])

    const handleItemClick = (spaceId: string, isSelected: boolean) => {
        sharePlaybook({
            playbooksIds,
            spacesIds: [spaceId],
            shareMode: isSelected ? 'unshare' : 'share'
        }).then(logShareEvent)
    }

    const handleCreate = () => {
        sharePlaybook({
            playbooksIds,
            spaceNames: [title],
            shareMode: 'share'
        }).then(data => {
            logShareEvent()
            showSuccessNotification('Shared')
            onClose()

            if (data?.spaceId) {
                history.push(
                    generatePath(paths.spaceLibrary, {
                        spaceId: data.spaceId
                    })
                )
            }
        })
    }

    return (
        <>
            {ApprovalModal}
            <ShareCommonDialog
                maxWidth={450}
                label="New Space"
                header="Add to space"
                buttonLabel="Add new Space"
                showCreateButton={creating.isTrue}
                onAddClick={creating.setTrue}
                title={title}
                setTitle={setTitle}
                onCreateClick={handleCreate}
                onItemClick={handleItemClick}
                disableCreate={title.length < 1}
                isLoading={$options.isValidating || isSharing}
                showSearchField={showSearchField}
                emptyStateText={emptyStateText}
                emptyStateImage={emptyStateImage}
                onClose={() => {
                    onClose()
                    setTitle('')
                    creating.setFalse()
                }}
                isOpen={isOpen}
                options={
                    !isEmptyState
                        ? $options.data?.spaces?.map(it => ({
                              id: it.id,
                              name: it.name,
                              isSelected: it.isSelected,
                              isDisabled: it.isDisabled
                          })) || []
                        : []
                }
            />
        </>
    )
})
