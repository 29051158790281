import { type CSSProperties, useRef } from 'react'
import { type FieldProps, Field } from 'formik'

import {
    type TextFieldProps,
    type InputProps,
    Box,
    TextField,
    FormHelperText,
    type AutocompleteProps,
    Autocomplete
} from '@mui/material'

import { type OptionType, option, uniqueArray } from 'modules'

const isBlank = (value: string) => /^\s*$/.test(value)

type Props = Partial<
    Partial<Omit<AutocompleteProps<OptionType, boolean, boolean, boolean>, 'onChange'>>
> & {
    rootClass?: string
    name: string
    variant?: TextFieldProps['variant']
    width?: CSSProperties['width']
    label?: string
    inputClassName?: string
    onChange?: (option: OptionType | Array<OptionType>) => void
    disableUnderline?: InputProps['disableUnderline']
}

export const ConnectedAutocomplete = ({
    name,
    placeholder,
    fullWidth,
    width,
    id,
    label,
    rootClass,
    inputClassName,
    variant = 'outlined',
    options = [],
    freeSolo = false,
    onChange,
    disableUnderline = false,
    multiple,
    ...props
}: Props) => {
    const inputRef = useRef<OptionType>()

    return (
        <Field name={name}>
            {({ field, form, meta }: FieldProps<Array<OptionType>, Array<OptionType>>) => (
                <Box id={id} className={rootClass}>
                    <Autocomplete
                        autoComplete={false}
                        sx={{
                            '& .MuiAutocomplete-input': {
                                minWidth: '100px !important'
                            }
                        }}
                        fullWidth={fullWidth}
                        multiple={multiple}
                        filterSelectedOptions
                        options={options}
                        loading={!options}
                        getOptionLabel={o => {
                            if (typeof o === 'string') return o
                            return o.label || o.value || ''
                        }}
                        isOptionEqualToValue={(option, value) => {
                            if (Array.isArray(value)) {
                                return value.some(v => v.value === option.value)
                            }
                            return option.value === value.value
                        }}
                        value={field?.value || []}
                        // @ts-ignore we don't use string in this autocomplete
                        onChange={(_e, value: Array<OptionType> | OptionType) => {
                            form.setFieldValue(
                                field.name,
                                Array.isArray(value) ? uniqueArray(value) : value
                            )
                            onChange?.(value)
                        }}
                        freeSolo={freeSolo}
                        clearOnBlur={!freeSolo}
                        size="small"
                        disableCloseOnSelect
                        style={fullWidth ? {} : { width: width ?? '300px' }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label}
                                inputRef={inputRef}
                                variant={variant}
                                placeholder={placeholder}
                                onKeyDown={e => {
                                    const value = (e.target as HTMLInputElement).value

                                    if (e.keyCode === 13) {
                                        e.stopPropagation()
                                        e.preventDefault()

                                        if (value && multiple) {
                                            form.setFieldValue(
                                                field.name,
                                                uniqueArray(
                                                    [
                                                        ...(field.value || []),
                                                        option(value, value)
                                                    ].flat()
                                                )
                                            )
                                        }
                                    }
                                }}
                                onChange={e => {
                                    if (freeSolo && !multiple) {
                                        form.setFieldTouched(field.name)

                                        form.setFieldValue(
                                            field.name,
                                            option(e.target.value, e.target.value)
                                        )
                                    }
                                }}
                                onBlur={e => {
                                    const tempValue = e.target.value

                                    if (freeSolo && tempValue && !isBlank(tempValue)) {
                                        if (multiple) {
                                            form.setFieldValue(
                                                field.name,
                                                uniqueArray(
                                                    [
                                                        field.value,
                                                        option(tempValue, tempValue)
                                                    ].flat()
                                                )
                                            )
                                            return
                                        }

                                        form.setFieldValue(field.name, option(tempValue, tempValue))
                                    }
                                }}
                                margin="none"
                                InputProps={{
                                    ...params.InputProps,
                                    margin: 'none',
                                    fullWidth: true,
                                    autoComplete: 'off',
                                    className: inputClassName,
                                    ...(variant !== 'outlined'
                                        ? {
                                              disableUnderline
                                          }
                                        : {})
                                }}
                            />
                        )}
                        {...props}
                    />

                    {meta.touched && meta.error !== undefined && (
                        <FormHelperText sx={{ color: '#cd0000' }}>{meta.error}</FormHelperText>
                    )}
                </Box>
            )}
        </Field>
    )
}
