import { Box, Stack, Typography } from '@mui/material'

import { WizardProgressBanner } from './WizardProgressBanner'

type Props = {
    stepsCount: number
    isDone: boolean
}

export const WizardLoaderStep = ({ stepsCount, isDone }: Props) => (
    <Stack spacing={3} flex={1} alignItems="center" justifyContent="center">
        <Typography fontSize={36} fontWeight={500} lineHeight={1}>
            Generating your Guidde
        </Typography>
        <Box px={6} width="100%">
            <WizardProgressBanner stepsCount={stepsCount} isDone={isDone} />
        </Box>
    </Stack>
)
