import { type KeyboardEvent, useRef } from 'react'

type Props = {
    onSubmit: () => void
}

export const useSubmitDialogWithEnter = ({ onSubmit }: Props) => {
    const isEnterKeyReleased = useRef(true)

    const onKeyDown = (event: KeyboardEvent) => {
        if (isEnterKeyReleased.current && event.key === 'Enter') {
            isEnterKeyReleased.current = false
            onSubmit()
        }
    }

    const onKeyUp = () => (isEnterKeyReleased.current = true)

    return { onKeyDown, onKeyUp }
}
