import { type CSSProperties } from 'react'
import { type FastFieldProps, FastField } from 'formik'
import {
    type SelectProps,
    Select,
    FormHelperText,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material'

import { type OptionType } from 'modules'

type Props = {
    name: string
    options: Array<OptionType>
    width?: CSSProperties['width']
    inputLabel?: string
} & SelectProps

export const ConnectedSelect = ({
    name,
    options,
    variant = 'standard',
    required = false,
    placeholder = '',
    fullWidth = false,
    width = 'auto',
    autoFocus = false,
    disabled = false,
    style = {},
    className = '',
    inputLabel = '',
    ...rest
}: Props) => (
    <FastField name={name}>
        {({ field, meta }: FastFieldProps) => (
            <>
                <FormControl
                    style={{
                        ...(fullWidth ? { width: '100%' } : { width: width ?? '300px' })
                    }}
                >
                    {inputLabel && (
                        <InputLabel id="select-label" style={{ zIndex: 1, marginTop: '10px' }}>
                            {inputLabel}
                        </InputLabel>
                    )}
                    <Select
                        labelId="select-label"
                        {...field}
                        style={{
                            ...style,
                            ...(fullWidth ? {} : { width: width ?? '300px' }),
                            ...{ marginTop: 0 }
                        }}
                        name={name}
                        native={false}
                        fullWidth={fullWidth}
                        required={required}
                        variant={variant}
                        disabled={disabled}
                        placeholder={placeholder}
                        className={className}
                        {...rest}
                        autoFocus={autoFocus}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {meta.touched && meta.error !== undefined && (
                    <FormHelperText>{meta.error}</FormHelperText>
                )}
            </>
        )}
    </FastField>
)
