// @ts-ignore this package doesn't have types
import Tap from '@tapfiliate/tapfiliate-js'
import { tapfiliateAccountId } from 'env'
import { log } from '@fullstory/browser'
import { logToAnalytics } from './analytics'
import { captureException } from '@sentry/react'

export type ChargeTrackDataType = {
    amount: number
    customerId: string
    chargeId: string
}

const handleTapfiliateError = (error: unknown, errorName: string) => {
    const e = error as Error

    log(`${errorName}: ${e.message}`)
    captureException(e)
    logToAnalytics(errorName, {
        message: e,
        pathname: window.location.pathname
    })
}

export const sendTapfiliateConversion = (props: ChargeTrackDataType | undefined) => {
    try {
        if (!props) return

        const { amount, customerId, chargeId } = props

        Tap.init(tapfiliateAccountId)
        Tap.conversion(chargeId, amount, { customer_id: customerId })
    } catch (e) {
        handleTapfiliateError(e, 'failed_to_send_tapfiliate_conversion')
    }
}

export const createTafiliateCustomer = (uid: string) => {
    try {
        Tap.init(tapfiliateAccountId)
        Tap.lead(uid)
    } catch (e) {
        handleTapfiliateError(e, 'failed_to_create_tapfiliate_customer')
    }
}

export const initTapfiliate = () => {
    Tap.init(tapfiliateAccountId)
}
