import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { BroadcastChannel } from 'broadcast-channel'

import {
    type AdditionalUserInfo,
    type UserCredential,
    type AuthError,
    getAdditionalUserInfo,
    getAuth,
    OAuthProvider,
    signInWithCustomToken,
    signInWithPopup
} from 'firebase/auth'

import * as Sentry from '@sentry/react'

import {
    useQueryParams,
    useDataMutation,
    useNotification,
    useAuth,
    useBoolean,
    useQuery,
    useLocalStorage
} from 'hooks'

import {
    type OnboardingFinishedType,
    OnboardingFinishedEvent
} from 'UI/Routes/onboarding/PageOnboarding'
import { parseLocationParams } from 'UI/Components'

import { setNewAuth, updateAppAuth } from 'ducks/actions'
import { paths } from 'app/paths'

import {
    auth,
    isDev,
    logToAnalytics,
    setGlobalPropsToAnalitics,
    setUserToAnaliticsForLogin,
    extractObjectFromString,
    generalErrorMessage,
    getCustomToken
} from 'modules'
import {
    type LoginMagicLinkQueryParams,
    createLoginMagicLink,
    createSpaceMagicLink,
    refreshTokenForMSLogin,
    type TLoginViaEmailForm
} from 'UI/Routes/signIn/api'
import { handleSessionRedirects, identifyHubspotContact, aliasUser } from 'UI/Routes/signIn/utils'
import { HTTP_CODES } from 'app/http-codes'
import { useAuthState } from 'react-firebase-hooks/auth'

type ResponseProps = {
    code: number
    statusCode: number
    error: {
        message: string
    }
}

export type SignInProvider = 'google.com' | 'microsoft.com'

export const LOGIN_TYPE = {
    login: 'login',
    joinSpace: 'joinSpace'
} as const

type SignInResultProps = {
    isNewUser?: boolean
    isLoggedInByGuidde: boolean
}

type SessionResponseType = {
    refreshToken: boolean
    roles: any
    userState: any
    isNewUser?: boolean
}

type SessionRequestType = {
    userCredential?: UserCredential
    href: string
    [key: string]: any
}

const sentryLoginErrorCodeExceptions = [
    'auth/network-request-failed',
    'auth/user-cancelled',
    'auth/cancelled-popup-request',
    'auth/invalid-credential',
    'auth/account-exists-with-different-credential'
]
export const ERRORS_MAP = {
    [HTTP_CODES.BadRequest]: `The invite link is invalid`,
    [HTTP_CODES.Forbidden]: `The invite link has expired`,
    [HTTP_CODES.NotFound]: `The invite link has expired`,
    default: 'Something went wrong. Please, try again later.'
}

type TContinueWithLink = {
    email: string
    additionalQueryParams?: LoginMagicLinkQueryParams
    formType?: TLoginViaEmailForm
}
type Props = {
    withoutSessionCall?: boolean
}

export const useSignIn = ({ withoutSessionCall }: Props = {}) => {
    const { showErrorNotification } = useNotification()

    const { roles, isLoading: isAuthLoading, email } = useAuth()

    const history = useHistory()
    const dispatch = useDispatch()

    const isSessionCalled = useSelector(state => state.appAuth.isSessionCalled)

    const msLoading = useBoolean()
    const sessionLoaded = useBoolean()

    const joinSpaceMatch = useRouteMatch(paths.joinSpace)
    const onboardingMatch = useRouteMatch(paths.onboarding)
    const loginMatch = useRouteMatch(paths.login)
    const signupMatch = useRouteMatch(paths.signup)

    const userCredentialRef = useRef<
        UserCredential & { additionalUserInfo: AdditionalUserInfo | null }
    >()

    const isRolesLoaded = Boolean(roles?.a) || !isAuthLoading

    const params = useQueryParams()
    const src = params.get('src')
    const { fromPathname, fromSearch } = parseLocationParams(params)
    const showWelcome = src === 'extension'

    const historyPush = history.push

    const [, setEmailForSignIn] = useLocalStorage('guiddeCoEmailForSignIn', '')

    const continueWithLink = useCallback(
        ({ additionalQueryParams, email, formType }: TContinueWithLink) => {
            const linkCreator = additionalQueryParams?.spaceId
                ? createSpaceMagicLink
                : createLoginMagicLink

            return linkCreator(email, additionalQueryParams, formType).then(
                (res: ResponseProps) => {
                    if (res.statusCode === HTTP_CODES.Forbidden) {
                        return res
                    }

                    if (!res.error) {
                        setEmailForSignIn(email.toLowerCase())
                        return res
                    }

                    showErrorNotification(generalErrorMessage)

                    return res
                }
            )
        },
        [setEmailForSignIn, showErrorNotification]
    )

    const $session = useDataMutation<SessionRequestType, SessionResponseType, Error>(
        '/auth/v1/session',
        'POST'
    )

    const $resetNewUserFlag = useQuery(
        `/c/v1/user/reset-flag-new`,
        {
            method: 'GET'
        },
        {
            revalidateOnFocus: false,
            revalidateIfStale: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false
        }
    )

    const handleSignIn = useCallback(
        async ({ isNewUser, isLoggedInByGuidde }: SignInResultProps) => {
            const user = getAuth().currentUser

            if (!isDev && isNewUser) {
                TagManager?.dataLayer?.({
                    dataLayer: { event: 'signup', userId: user?.uid }
                })
            }

            if (user?.email) {
                if (!isLoggedInByGuidde) identifyHubspotContact(user.email, isNewUser)

                if (isNewUser) aliasUser(user.uid)
            }

            if (isNewUser) $resetNewUserFlag.mutate()
        },
        [$resetNewUserFlag]
    )

    const isSessionLoading = $session.isLoading
    const sessionMutate = $session.mutate
    useEffect(() => {
        if (
            isAuthLoading ||
            !email ||
            sessionLoaded.isTrue ||
            isSessionLoading ||
            isSessionCalled ||
            withoutSessionCall
        )
            return

        dispatch(updateAppAuth({ isSessionCalled: true }))

        sessionMutate({
            userCredential: userCredentialRef.current,
            href: window.location.href,
            ...joinSpaceMatch?.params
        })
            .then(async response => {
                const { roles, userState, refreshToken } = response || {}

                const tokenResult = await getAuth().currentUser?.getIdTokenResult(refreshToken)

                const isLoggedInByGuidde = Boolean(tokenResult?.claims?.g)

                if (userCredentialRef.current || response?.isNewUser) {
                    handleSignIn({
                        isLoggedInByGuidde,
                        isNewUser: Boolean(response?.isNewUser)
                    })
                }

                if (tokenResult?.claims?.roles) {
                    dispatch(
                        updateAppAuth({
                            roles: tokenResult.claims.roles as any,
                            isLoggedInByGuidde
                        })
                    )
                }

                const token = await getAuth().currentUser?.getIdToken()
                const data = token && (await getCustomToken(token))

                handleSessionRedirects({
                    token: data?.token,
                    roles,
                    historyPush,
                    spaceId: userState?.spaceId
                })
            })
            .finally(sessionLoaded.setTrue)
    }, [
        isSessionLoading,
        sessionMutate,
        dispatch,
        email,
        fromPathname,
        fromSearch,
        handleSignIn,
        historyPush,
        isAuthLoading,
        isSessionCalled,
        joinSpaceMatch?.params,
        sessionLoaded.isTrue,
        sessionLoaded.setTrue,
        showWelcome,
        withoutSessionCall
    ])

    const continueWithPopup = useCallback(
        async (signInProvider: SignInProvider, callback?: (data: UserCredential) => void) => {
            const provider = new OAuthProvider(signInProvider)

            return signInWithPopup(getAuth(), provider)
                .then(data => {
                    const additionalUserInfo = getAdditionalUserInfo(data)

                    callback?.(data)

                    userCredentialRef.current = { ...data, additionalUserInfo }

                    setUserToAnaliticsForLogin(data)

                    logToAnalytics(data.operationType, {
                        showWelcome,
                        isNewUser: additionalUserInfo?.isNewUser,
                        providerId: additionalUserInfo?.providerId,
                        email: data.user?.email,
                        uid: data.user?.uid
                    })

                    dispatch(setNewAuth(true))
                })
                .catch(async (error: AuthError) => {
                    if (error.message.includes('Please login with your SSO provider.'))
                        throw 'Please login with your SSO provider.'

                    const firebaseResponse = extractObjectFromString(error.message)
                    if (firebaseResponse?.error?.details?.reason === 'environment-forbidden') {
                        throw `redirectUrl:${firebaseResponse.error.details.redirect}`
                    }

                    if (error.message.includes('PERMISSION_DENIED'))
                        return showErrorNotification(
                            'This account has been deactivated. Please contact support.'
                        )

                    if (error.code === 'auth/popup-closed-by-user') return

                    setGlobalPropsToAnalitics()

                    if (signInProvider === 'microsoft.com') {
                        if (error.code === 'auth/account-exists-with-different-credential') {
                            msLoading.setTrue()

                            try {
                                const msLoginData = await refreshTokenForMSLogin({
                                    email: error.customData.email || '',
                                    // It's tricky, _tokenResponse is an internal data which can be changed
                                    // at any moment. So need to lock the node_module and verify that data
                                    // everytime we upgrade the module
                                    // @ts-ignore
                                    idToken: error.customData._tokenResponse?.oauthIdToken
                                })

                                if (msLoginData?.token) {
                                    return signInWithCustomToken(
                                        getAuth(),
                                        msLoginData.token
                                    ).finally(msLoading.setFalse)
                                }
                            } catch (error: any) {
                                msLoading.setFalse()
                                if (error.message.includes('Please login with your SSO provider.'))
                                    throw 'Please login with your SSO provider.'
                            }
                        }

                        showErrorNotification(
                            'Contact your administrator to sign in with Microsoft'
                        )
                        msLoading.setFalse()
                    }

                    if (sentryLoginErrorCodeExceptions.includes(error.code)) {
                        console.error('[POPUP LOGIN]: ', error)
                    } else {
                        Sentry.captureException(new Error('popup-login-failed'), {
                            extra: { signInProvider, ...error }
                        })
                    }
                })
        },
        [showWelcome, dispatch, showErrorNotification, msLoading]
    )

    useEffect(() => {
        const channel = new BroadcastChannel(OnboardingFinishedEvent)

        const handler = (e: MessageEvent<OnboardingFinishedType>) => {
            if (!document.hidden) return
            if (onboardingMatch || loginMatch || signupMatch) return window.location.reload()

            return handleSessionRedirects({ historyPush, roles: e.data?.roles })
        }

        channel.addEventListener('message', handler)

        return () => channel.removeEventListener('message', handler)
    }, [historyPush, loginMatch, onboardingMatch, signupMatch])

    const isLoading = !isRolesLoaded || msLoading.isTrue

    const [user] = useAuthState(auth)

    const isSignedIn = user && !user?.isAnonymous

    return useMemo(
        () => ({
            continueWithLink,
            continueWithPopup,
            isLoading,
            isSignedIn,
            userCredentialRef
        }),
        [continueWithLink, continueWithPopup, isLoading, isSignedIn]
    )
}
