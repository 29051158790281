import { type ReactNode } from 'react'

import { Button, IconButton, Stack, Typography } from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const steps = [...Array(3).keys()].map(i => i + 1)

type Props = {
    title: string
    children: ReactNode
    additionalAction?: ReactNode
    disableBackClick?: boolean
    primaryActionText?: string
    onPrimaryActionClick?: () => void
    onBackButtonClick?: () => void
    activeStep: number | null
    onChangeStep: (step: number) => void
    contentPaddingX?: number
}

export const WizardDialogContent = ({
    title,
    children,
    activeStep,
    onChangeStep,
    disableBackClick,
    primaryActionText,
    additionalAction,
    onPrimaryActionClick,
    contentPaddingX,
    onBackButtonClick
}: Props) => {
    return (
        <Stack flexGrow={1} sx={{ overflowY: 'auto' }}>
            <Stack
                spacing={2}
                alignItems="center"
                height={130}
                flexShrink={0}
                justifyContent="center"
                sx={{ borderBottom: '1px solid rgba(9, 12, 16, 0.1)' }}
            >
                {activeStep !== null && (
                    <Stack direction="row" spacing={2}>
                        {steps.map(step => {
                            const isActiveStep = step <= (activeStep ?? 0)

                            return (
                                <IconButton
                                    key={step}
                                    sx={{
                                        backgroundColor: isActiveStep ? '#FED243' : '#F5F5F5',
                                        borderRadius: '50%',
                                        p: 0,
                                        width: 24,
                                        height: 24
                                    }}
                                    onClick={() => onChangeStep(step)}
                                >
                                    <Typography
                                        fontSize={14}
                                        fontWeight={700}
                                        color={isActiveStep ? '#090C10' : 'rgba(9, 12, 16, 0.6)'}
                                    >
                                        {step}
                                    </Typography>
                                </IconButton>
                            )
                        })}
                    </Stack>
                )}
                <Typography fontSize={36} fontWeight={500} lineHeight={1}>
                    {title}
                </Typography>
            </Stack>
            <Stack flexGrow={1} sx={{ overflowY: 'auto' }}>
                <Stack
                    px={contentPaddingX ?? 5}
                    py={3}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ backgroundColor: '#FAFAFA' }}
                    flexGrow={1}
                >
                    {children}
                </Stack>
            </Stack>
            <Stack
                direction="row"
                sx={{
                    borderTop: '1px solid rgba(9, 12, 16, 0.1)',
                    height: 80,
                    px: 5,
                    justifyContent:
                        disableBackClick && additionalAction === undefined
                            ? 'flex-end'
                            : 'space-between',
                    alignItems: 'center',
                    flexShrink: 0
                }}
            >
                {!disableBackClick && (
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            textTransform: 'uppercase',
                            color: 'rgba(9, 12, 16, 0.6)',
                            '&:hover': {
                                backgroundColor: '#F5F5F5'
                            }
                        }}
                        onClick={() => {
                            onChangeStep((activeStep ?? 0) - 1)
                            onBackButtonClick?.()
                        }}
                    >
                        Back
                    </Button>
                )}
                {additionalAction}
                <Button
                    variant="contained"
                    startIcon={<ArrowForwardIcon />}
                    sx={{
                        textTransform: 'uppercase'
                    }}
                    onClick={() => {
                        onChangeStep((activeStep ?? 0) + 1)
                        onPrimaryActionClick?.()
                    }}
                >
                    {primaryActionText ?? 'Next'}
                </Button>
            </Stack>
        </Stack>
    )
}
