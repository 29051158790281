import { doc, getFirestore, updateDoc } from 'firebase/firestore'

import { gapi } from 'gapi-script'

import { envConfig } from 'modules'

let CLIENT_ID
switch (envConfig.firebaseConfig.projectId) {
    case 'guidde-production':
        CLIENT_ID = '484452355157-4amae42vu22leg2bv3t5bjn09lu2qrmb.apps.googleusercontent.com'
        break
    case 'guidde-dev-staging':
        CLIENT_ID = '824103114815-jbt3sku7g0nhhpndnbrh31f5g3qebun6.apps.googleusercontent.com'
        break
    case 'guidde-dev-testomate':
        CLIENT_ID = '394601010940-t8ko31n0ejd3l1u0mouv3gloarh97iin.apps.googleusercontent.com'
        break
    case 'guidde-dev4':
        CLIENT_ID = '84342788775-0cqb3nkunho1all0ql5rv1a939t7ud72.apps.googleusercontent.com'
        break
    default:
        CLIENT_ID = '209677855796-h40go4b8m5qcncrp5aic68s11bpduueg.apps.googleusercontent.com'
        break
}

const SCOPES = 'https://www.googleapis.com/auth/drive.readonly'
const DISCOVERY = 'https://content.googleapis.com/discovery/v1/apis/drive/v3/rest'

let gauth

export const getCurrentAuth = () => gauth

export const setGDriveAuth = (uid, authData) => {
    return updateDoc(doc(getFirestore(), `users`, uid), { gdrive: authData })
}

export const createGapi = (onInit, onError) => {
    gapi.load('client:auth2', () => {
        gapi.auth2
            .init({ client_id: CLIENT_ID })
            .then(onInit, onError)
            .catch(() => {})
    })
}

export const signOutGapi = uid => {
    // if called this function we can suppose that global gapi was initialized
    const auth2 = gapi.auth2.getAuthInstance()
    auth2.signOut()
    setGDriveAuth(uid, null)
}

export const executeFoldersLoad = async ({ setLoading, setTree, loadedTree }) => {
    async function execute(pageToken, collector) {
        let response
        try {
            response = await gapi.client.drive.files.list({
                corpora: 'user',
                pageSize: 40,
                fields: 'nextPageToken,files(id,name,kind,mimeType,size,parents)',
                q: "mimeType='application/vnd.google-apps.folder'",
                pageToken,
                supportsAllDrives: true,
                includeItemsFromAllDrives: true
            })

            if (response.result) {
                collector.push(...response.result.files)
                if (response.result.nextPageToken) {
                    collector = await execute(response.result.nextPageToken, collector)
                }
            }
        } catch (e) {
            window.executeFoldersLoadErr = window.executeFoldersLoadErr || 0
            window.executeFoldersLoadErr = window.executeFoldersLoadErr + 1
            console.error('ExecuteFoldersLoad error number ' + window.executeFoldersLoadErr, e)
        }
        return collector
    }

    const folders = await execute(null, [])
    if (setLoading) setLoading(false)
    if (loadedTree) loadedTree.folders = folders
    if (setTree) setTree(folders)
}

export const executeFilesLoad = async ({ setLoading, setTree, loadedTree }) => {
    async function execute(pageToken, collector) {
        let response
        try {
            response = await gapi.client.drive.files.list({
                corpora: 'user',
                pageSize: 40,
                fields: 'nextPageToken,files(id,name,kind,mimeType,size,parents)',
                q: "mimeType='video/mp4' or mimeType='video/quicktime'",
                pageToken,
                supportsAllDrives: true,
                includeItemsFromAllDrives: true
            })

            if (response.result) {
                collector.push(...response.result.files)
                if (response.result.nextPageToken) {
                    collector = await execute(response.result.nextPageToken, collector)
                }
            }
        } catch (e) {
            console.error('Execute error', e)
            loadedTree.collector = []
            setLoading(false)
        }
        return collector
    }

    const collector = await execute(null, [])
    setLoading(false)
    loadedTree.collector = collector
    setTree(collector)
}

export const executeFilesFoldersLoad = async ({
    folderId,
    setLoading,
    setTree,
    setAuth,
    loadedTree
}) => {
    async function executeForFolder(subfolderId, pageToken, c) {
        const parentQuery = subfolderId ? ` and "${subfolderId}" in parents` : ''
        try {
            const response = await gapi.client.drive.files.list({
                corpora: 'user',
                pageSize: 40,
                fields: 'nextPageToken,files(id,name,kind,mimeType,size,parents)',
                q: `( mimeType='video/mp4' or mimeType='video/quicktime' or mimeType='application/vnd.google-apps.folder')${parentQuery}`,
                pageToken,
                supportsAllDrives: true,
                includeItemsFromAllDrives: true
            })
            if (response.result) {
                c.push(...response.result.files)
                if (response.result.nextPageToken) {
                    await executeForFolder(subfolderId, response.result.nextPageToken, c)
                }
            }
        } catch (e) {
            console.error('Execute error', e)
            loadedTree.collector = []
            setLoading(false)
        }
        return c
    }

    const getFirstSubfolder = list =>
        list.findIndex(element => element.mimeType === 'application/vnd.google-apps.folder')

    if (!folderId) return executeFilesLoad({ setLoading, setTree, setAuth, loadedTree })

    let subfolderId = folderId
    let subfolderInd = -1
    let collector = []
    let count = 0

    await executeForFolder(subfolderId, null, collector)
    subfolderInd = getFirstSubfolder(collector)
    count += 1

    if (subfolderInd !== -1 || count <= 10) {
        subfolderId = collector[subfolderInd].id
        collector.splice(subfolderInd, 1)
    }

    setLoading(false)
    loadedTree.collector = collector
    setTree(collector)
}

export const initClientGapi = async ({ uid, setLoading, setAuth, afterLoadClient = () => {} }) => {
    setLoading(true)

    const handleSigninStatusChange = isSignedIn => {
        const auth2 = gapi.auth2.getAuthInstance()
        if (isSignedIn) {
            const currentUser = auth2.currentUser.get()
            const authResponse = currentUser.getAuthResponse(true)
            if (authResponse) {
                const gdProfile = currentUser.getBasicProfile()
                setAuth(authResponse)
                gauth = authResponse
                return setGDriveAuth(uid, {
                    auth: authResponse,
                    user: {
                        name: gdProfile.getName(),
                        email: gdProfile.getEmail(),
                        img: gdProfile.getImageUrl() || null
                    }
                }).then
            }
        }
    }

    function authenticate() {
        const auth2 = gapi.auth2.getAuthInstance()
        auth2.isSignedIn.listen(handleSigninStatusChange)

        const currentUser = auth2.currentUser.get()
        const authResponse = currentUser.getAuthResponse(true)
        if (authResponse && currentUser) {
            setAuth(authResponse)
            gauth = authResponse
            return Promise.resolve()
        }

        // Go sign in
        return auth2.signIn({ scope: SCOPES }).then(afterLoadClient, err => {
            console.error('Error signing in', err)
        })
    }

    function loadClient() {
        return gapi.client.load(DISCOVERY).then(
            function () {},
            function (err) {
                console.error('Error loading GAPI client for API', err)
            }
        )
    }

    await authenticate().then(loadClient).then(afterLoadClient)

    setLoading(false)
    return Promise.resolve({})
}

const BASE_PARAMS = {
    corpora: 'user',
    pageSize: 100,
    fields: 'nextPageToken,files(id,name,kind,mimeType,size,parents,hasThumbnail,thumbnailLink,owners,modifiedTime)'
}
// Allow only folders or mp4 files
const MIMETYPE_QUERY =
    "(mimeType='video/mp4' or mimeType='video/quicktime' or mimeType='application/vnd.google-apps.folder')"

export const isFolder = file => file.mimeType === 'application/vnd.google-apps.folder'
export const isDrive = file => file.kind === 'drive#drive'

export const getOwner = owners => {
    if (owners?.some(owner => owner.me)) {
        return 'Me'
    }

    return owners?.[0]?.displayName || 'Shared'
}

export async function getDrivesList(baseFunc = () => null, collector = []) {
    try {
        const response = await gapi.client.drive.drives.list()

        if (response.result) {
            collector.push(...response.result.drives)
            // If there are more pages, load them as recursively
            if (response.result.nextPageToken) {
                collector = await getDrivesList(baseFunc, response.result.nextPageToken, collector)
            }
        }
    } catch (e) {
        console.error(`Error loading drives list ${e}`, e)
    }
    collector.push({
        id: 'root',
        kind: 'drive#drive',
        name: 'My Drive'
    })
    return collector
}

async function executeGDriveRequest(
    baseFunc = () => null,
    requestParams = {},
    pageToken = null,
    collector = []
) {
    try {
        const response = await gapi.client.drive.files.list({
            ...BASE_PARAMS,
            ...requestParams,
            pageToken,
            supportsAllDrives: true,
            includeItemsFromAllDrives: true
        })

        if (response.result) {
            collector.push(...removeSubFolders(response.result.files))

            // If there are more pages, load them as recursively
            if (response.result.nextPageToken) {
                collector = await executeGDriveRequest(
                    baseFunc,
                    requestParams,
                    response.result.nextPageToken,
                    collector
                )
            }
        }
    } catch (e) {
        window.executeFoldersLoadErr = window.executeFoldersLoadErr || 0
        window.executeFoldersLoadErr = window.executeFoldersLoadErr + 1
        console.error(`ExecuteFoldersLoad error number ${window.executeFoldersLoadErr}`, e)
    }

    return collector
}

export const getSharedWithMe = async () => {
    const root = await executeGDriveRequest(getSharedWithMe, {
        q: `${MIMETYPE_QUERY} and sharedWithMe=true`
    })
    return root
}

export const getTopLevelFiles = async drive => {
    const rootFolder = await executeGDriveRequest(getTopLevelFiles, {
        q: `${MIMETYPE_QUERY} and '${drive}' in parents`
    })
    return rootFolder
}

export const getRootItems = async () => {
    const [sharedItems, drivesList] = await Promise.all([getSharedWithMe(), getDrivesList()])

    return [...drivesList, ...sharedItems]
}

export const getFolderFiles = async folderId =>
    await executeGDriveRequest(getFolderFiles, {
        q: `${MIMETYPE_QUERY} and '${folderId}' in parents`
    })

// all shared folders are returned, but we want the top level folders only
// meaning we want to display only the folders that has no parents.

const removeSubFolders = fileList => {
    return fileList.filter(file => {
        if (file.parents && file.parents[0])
            return !fileList.some(
                oneOfAllTheFiles =>
                    oneOfAllTheFiles.id === file.parents[0] || 'root' === file.parents[0]
            )
        return true
    })
}
