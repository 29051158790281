import { useContext } from 'react'

import { Box } from '@mui/material'

import { SmartPreviewContext } from './SmartPreviewProvider'
import { Slides } from './Slides'
import { AudioTracks } from './AudioTracks'

export const SmartPreview = () => {
    const { status, time, stepsTimeline, activeIndex, totalProgress } =
        useContext(SmartPreviewContext)

    return (
        <Box
            id="smart-preview"
            position="absolute"
            top="50%"
            left="50%"
            style={{
                backdropFilter: 'blur(10px)',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <Slides activeIndex={activeIndex} status={status} />

            <AudioTracks
                currentTime={time}
                status={status === 'running' ? 'playing' : 'paused'}
                activeIndex={activeIndex}
                steps={stepsTimeline.steps}
                totalProgress={totalProgress}
            />
        </Box>
    )
}
