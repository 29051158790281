import { type ChangeEvent, useRef, useState } from 'react'

import fixWebmDuration from 'fix-webm-duration'

import { Box, Divider, styled, Typography } from '@mui/material'
import { AttachFile as FileIcon } from '@mui/icons-material'

import { SpacedGroup, LimitationDialog } from 'UI/Components'
import { extensionSchema } from './SpeechToTextTab'

import { useBoolean, useConfiguration, useNotification } from 'hooks'
import {
    type OptionType,
    logToAnalytics,
    playbookToAnalyticsProps,
    getAudioDuration
} from 'modules'

import { type QuickGuiddeType } from 'app/types'

const Wrapper = styled(Box)({
    marginTop: -3,
    background: 'rgb(245, 245, 245)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px'
})

const StyledInput = styled('input')({
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
})

const StyledLabel = styled('label')({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px',
    padding: 0,
    width: 'auto'
})

type Props = {
    language: OptionType | null
    playbook: QuickGuiddeType
    onUpload: (blob: Blob) => Promise<void>
}

export const VoiceOverUploader = ({ onUpload, playbook }: Props) => {
    const [fileSize, setFileSize] = useState<number | null>(null)

    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const durationAlert = useBoolean()

    const { maxCustomVoiceOverAudioSizeInMB, isConfigurationLoading } = useConfiguration()

    const { showErrorNotification } = useNotification()

    return (
        <Wrapper p={2}>
            <Box m={2} mt={0}>
                <Divider />
            </Box>
            <StyledLabel>
                <StyledInput
                    type="file"
                    ref={fileInputRef}
                    disabled={isConfigurationLoading}
                    accept="audio/mp3,audio/wav"
                    multiple={false}
                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                        const file = event.target.files?.[0]

                        if (!file) return

                        // clear input value after successful upload
                        if (fileInputRef.current) {
                            fileInputRef.current.value = ''
                        }

                        if (!maxCustomVoiceOverAudioSizeInMB) {
                            console.error('[maxCustomVoiceOverAudioSizeInMB] is not defined or 0')
                            showErrorNotification(`Something went wrong. Please contact support.`)
                            return
                        }

                        // File size validation
                        const maxSizeInBytes = Number(maxCustomVoiceOverAudioSizeInMB) * 1024 * 1024

                        if (file.size > maxSizeInBytes) {
                            setFileSize(file.size)
                            return
                        }

                        const audioDurationInSec = await getAudioDuration(file)

                        if (audioDurationInSec > 60) {
                            durationAlert.setTrue()
                            return
                        }

                        const reader = new FileReader()
                        reader.onload = async function (event) {
                            if (!extensionSchema[file.type]) {
                                showErrorNotification(
                                    `File format is not supported. Please upload .wav or .mp3 file.`
                                )
                                return
                            }

                            const blob = new Blob([event.target?.result as ArrayBuffer], {
                                type: file.type
                            })

                            const fixedBlob = await fixWebmDuration(
                                blob,
                                audioDurationInSec * 1000,
                                { logger: false }
                            )

                            logToAnalytics(
                                'uploadVoiceoverAudio',
                                playbookToAnalyticsProps(playbook)
                            )

                            await onUpload(fixedBlob)
                        }
                        try {
                            reader.readAsArrayBuffer(file)
                        } catch (e) {
                            console.error('[file reading error]', e)
                            showErrorNotification(`The file ${file.name} couldn’t be opened`)
                        }
                    }}
                />

                <SpacedGroup mb={1} spacing={1}>
                    <FileIcon fontSize="small" />
                    <Typography sx={{ color: 'rgba(9, 12, 16, 0.6)' }}>UPLOAD AUDIO</Typography>
                </SpacedGroup>

                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(9, 12, 16, 0.6)', fontSize: '12px' }}
                >
                    Upload .wav or .mp3 file{' '}
                    {isConfigurationLoading ? '' : `up to ${maxCustomVoiceOverAudioSizeInMB}MB`}
                </Typography>
            </StyledLabel>
            <LimitationDialog
                isOpen={Boolean(fileSize)}
                onClose={() => setFileSize(null)}
                title="Audio limit alert"
                text={
                    <>
                        The selected media file is too large (over {maxCustomVoiceOverAudioSizeInMB}
                        MB).
                    </>
                }
                subtitle="Please choose a smaller audio file."
            />
            <LimitationDialog
                isOpen={durationAlert.isTrue}
                onClose={durationAlert.setFalse}
                title="Audio limit alert"
                text="The selected media file is too long (over 60 seconds)."
                subtitle="Please choose a shorter audio track."
            />
        </Wrapper>
    )
}
