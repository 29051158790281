import * as types from 'ducks/types'
import {
    type ImageShapeType,
    type StepType,
    type RectangleShapeType,
    type CircleWithTextShapeType,
    type QuickGuiddeLayerType,
    type SpeechToTextType,
    type TextToSpeechType,
    type AudioCircleType,
    type SingleCtaType,
    type MultipleCtaType,
    type SubtitlesType,
    type SubtitlesStatusType,
    type SubtitleType
} from 'app/types'
import { type svgArrowData } from 'UI/Routes/quick-guidde/CanvasEditor/Workspace/Shapes/svg-arrow-data'

export const setStepTransition = (
    transition: StepType['transition'] | null,
    applyToAll?: boolean
) =>
    ({
        type: types.SET_STEP_TRANSITION,
        payload: { transition, applyToAll }
    }) as const

export const setQuickGuiddeStepCTA = (payload?: SingleCtaType | MultipleCtaType) =>
    ({
        type: types.SET_QUICK_GUIDDE_STEP_CTA,
        payload
    }) as const

export const setQuickGuiddeDuration = (payload: { duration: number; applyToAll: boolean }) =>
    ({
        type: types.SET_QUICK_GUIDDE_DURATION,
        payload
    }) as const

export const setArrowDirection = (payload: keyof typeof svgArrowData) =>
    ({
        type: types.SET_ARROW_DIRECTION,
        payload
    }) as const

export const setQuickGuiddeSteps = (steps: Array<StepType>, partialUpdate?: boolean) =>
    ({
        type: types.SET_QUICK_GUIDDE_STEPS,
        payload: { steps, partialUpdate }
    }) as const

export const pasteCopiedSteps = (steps: Array<StepType>, activeStep: number) =>
    ({
        type: types.PASTE_COPIED_STEPS,
        payload: { steps, activeStep }
    }) as const

export const toggleMultipleSelectedSteps = (payload: { stepId: string; stepIndex: number }) =>
    ({
        type: types.TOGGLE_MULTIPLE_SELECTED_STEPS,
        payload
    }) as const

export const setActiveStep = (stepIndex: number, isForced?: boolean) =>
    ({
        type: types.SET_ACTIVE_STEP,
        payload: { stepIndex, isForced }
    }) as const

export const setLayerPosition = (payload: { layerId: string; x: number; y: number }) =>
    ({
        type: types.SET_LAYER_POSITION,
        payload
    }) as const

export const setLayerTransform = (payload: {
    layerId: string
    x: number
    y: number
    width?: number
    height?: number
    scaleX?: number
    scaleY?: number
    rotation?: number
    radius?: number
    fontSize?: number
}) =>
    ({
        type: types.SET_LAYER_TRANSFORM,
        payload
    }) as const

export const setLayerStrokeColor = (payload: {
    circle?: {
        strokeColor: string
        fill: string
    }
    strokeColor?: string
    fill?: string
    idx: number
}) =>
    ({
        type: types.SET_LAYER_FILL,
        payload
    }) as const

export const setLayerText = (payload: { layerId: string; title: string; height?: number }) =>
    ({
        type: types.SET_LAYER_TEXT,
        payload
    }) as const

export const setLayerOrder = (payload: {
    order: 'front' | 'forward' | 'backward' | 'back'
    layerId: string
}) =>
    ({
        type: types.SET_LAYER_ORDER,
        payload
    }) as const

export const toggleSpotlight = () =>
    ({
        type: types.TOGGLE_SPOTLIGHT
    }) as const

export const setBackgroundLayer = (
    payload: ImageShapeType | RectangleShapeType,
    applyToAll?: boolean
) => ({ type: types.SET_BACKGROUND_LAYER, payload: { newLayer: payload, applyToAll } }) as const

export const addNewLayer = (payload: QuickGuiddeLayerType) =>
    ({ type: types.ADD_NEW_LAYER, payload }) as const

export const setCompanyLogo = (payload: { url: string; width: number; height: number }) =>
    ({ type: types.SET_COMPANY_LOGO, payload }) as const

export const setFontStyles = (payload: {
    align?: 'left' | 'center' | 'right'
    fontFamily?: string // title of the font in the brand kit
    fontSize?: number
    fontStyle?: string // 'normal' | 'bold' | 'italic' | 'bold normal' | 'bold italic'
    textDecoration?: 'none' | 'underline'
}) => ({ type: types.SET_FONT_STYLES, payload }) as const

export const setTextShapeSize = (payload: { id: string; height: number; width: number }) =>
    ({ type: types.SET_TEXT_SHAPE_SIZE, payload }) as const

export const setTextColors = (payload: { color?: string; backgroundColor?: string }) =>
    ({ type: types.SET_TEXT_COLORS, payload }) as const

export const addStepNumbering = (payload: CircleWithTextShapeType) =>
    ({ type: types.ADD_STEP_NUMBERING, payload }) as const

export const setStepNumberingText = (payload: { layerId: string; title: string }) =>
    ({
        type: types.SET_STEP_NUMBERING_TEXT,
        payload
    }) as const

export const removeStepNumbering = () => ({ type: types.REMOVE_STEP_NUMBERING }) as const

export const copySelectedLayer = () => ({ type: types.COPY_SELECTED_LAYER }) as const
export const resetCopiedLayer = () => ({ type: types.RESET_COPIED_LAYER }) as const

export const moveSelectedLayer = (payload: { direction: 'TOP' | 'RIGHT' | 'BOTTOM' | 'LEFT' }) =>
    ({ type: types.MOVE_SELECTED_LAYER, payload }) as const

export const pasteSelectedLayer = () => ({ type: types.PASTE_SELECTED_LAYER }) as const

export const duplicateSelectedLayer = () => ({ type: types.DUPLICATE_SELECTED_LAYER }) as const

export const removeSelectedLayer = () => ({ type: types.REMOVE_SELECTED_LAYER }) as const

export const overwriteStep = (payload: { newStep: StepType; stepId?: string }) =>
    ({
        type: types.OVERWRITE_STEP,
        payload
    }) as const

export const setVideoPreview = (payload: string) =>
    ({
        type: types.SET_VIDEO_PREVIEW,
        payload
    }) as const

export const addNewStep = (
    payload: { step: StepType } & (
        | { kind: 'step'; position: 'before' | 'after' }
        | { kind: Exclude<StepType['kind'], 'step'> }
    )
) =>
    ({
        type: types.ADD_NEW_STEP,
        payload
    }) as const

export const duplicateSteps = (payload: {
    steps: Array<StepType>
    pasteIdx: number
    newActiveStep: number
}) =>
    ({
        type: types.DUPLICATE_STEPS,
        payload
    }) as const

export const removeActiveStep = () =>
    ({
        type: types.REMOVE_ACTIVE_STEP
    }) as const

export const selectLayer = (payload: string | null | Array<string>, isMultiSelect?: boolean) =>
    ({
        type: types.SELECT_LAYER,
        payload: { selection: payload, isMultiSelect }
    }) as const

export const setTempAudioNote = (payload?: StepType['tempAudioNote']) =>
    ({
        type: types.SET_TEMP_QUICK_GUIDDE_AUDIO_NOTE,
        payload
    }) as const

export const setMultipleAudioNotes = (
    payload: Array<{
        audioNote: TextToSpeechType
        stepId: string
        subtitles?: SubtitlesType
        audioLayer?: AudioCircleType
        subGenerationId?: string
    }>
) =>
    ({
        type: types.SET_MULTIPLE_QUICK_GUIDDE_AUDIO_NOTES,
        payload
    }) as const

export const setAudioNote = (payload?: {
    audioNote?: SpeechToTextType | TextToSpeechType
    stepId?: string
    subtitles?: SubtitlesType
    subGenerationId?: string | null
}) =>
    ({
        type: types.SET_QUICK_GUIDDE_AUDIO_NOTE,
        payload
    }) as const

export const setAudioNoteTempMarkdown = (payload?: string) =>
    ({
        type: types.SET_QUICK_GUIDDE_AUDIO_NOTE_TEMP_MARKDOWN,
        payload
    }) as const

export const resetSteps = () =>
    ({
        type: types.RESET_STEPS
    }) as const

export const setImageStepArea = (payload: { visible: boolean; placement: 'before' | 'after' }) =>
    ({
        type: types.SET_IMAGE_STEP_AREA,
        payload
    }) as const

export const setStepLayer = (payload: { layerId: string; layer: ImageShapeType }) =>
    ({
        type: types.SET_QUICK_GUIDDE_STEP_LAYER,
        payload
    }) as const

export const setStepTitle = (payload: string) =>
    ({
        type: types.SET_QUICK_GUIDDE_STEP_TITLE,
        payload
    }) as const

export const setVideoStepRanges = (payload: { start: number; end: number }) =>
    ({
        type: types.SET_QUICK_GUIDDE_VIDEO_STEP_RANGES,
        payload
    }) as const

export const multiAlignElements = (
    payload: 'left' | 'right' | 'top' | 'bottom' | 'verticalCenter' | 'horizontalCenter'
) => ({ type: types.MULTI_ALIGN_SELECTED_LAYERS, payload }) as const

export const applyLayerToAllSteps = (payload: string) =>
    ({
        type: types.APPLY_LAYER_TO_ALL_STEPS,
        payload
    }) as const

export const setStepsSubtitles = (payload: Array<SubtitlesStatusType>) =>
    ({ type: types.SET_STEPS_SUBTITLES, payload }) as const

export const setStepSubtitlesBlock = (payload: {
    stepIndex: number
    blockIndex: number
    subtitlesBlock?: SubtitleType
    operation: 'add' | 'delete' | 'update'
}) => ({ type: types.SET_STEP_SUBTITLES_BLOCK, payload }) as const

export const enableShortcuts = () =>
    ({
        type: types.ENABLE_QG_SHORTCUTS
    }) as const

export const disableShortcuts = () =>
    ({
        type: types.DISABLE_QG_SHORTCUTS
    }) as const
