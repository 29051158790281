import { type ChangeEvent, type HTMLAttributes, memo, useMemo, useRef, useState } from 'react'

import { Autocomplete, Box, Paper, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { Option } from './Option'
import { string as yupString } from 'yup'
import { GROUP } from '../const'

type OptionType = {
    value: string
    label: string
    [key: string]: any
}

type Props = {
    options: Array<OptionType>
    onChange: (newValues: Array<OptionType>) => void
    placeholder: string
    value: Array<OptionType>
    freeSolo?: boolean
    isShowPopupIcon?: boolean
}

export const OptionsAutocomplete = memo(
    ({
        options,
        onChange,
        placeholder,
        value,
        freeSolo = false,
        isShowPopupIcon = false
    }: Props) => {
        const [inputValue, setInputValue] = useState('')
        const [errorMsg, setErrorMsg] = useState('')
        const paperPropsRef = useRef({
            inputValue,
            errorMsg
        })

        const setInputValueRef = (value: string) => {
            paperPropsRef.current.inputValue = value
            setInputValue(value)
        }

        const setErrorMsgRef = (value: string) => {
            paperPropsRef.current.errorMsg = value
            setErrorMsg(value)
        }

        const handleChange = (e: ChangeEvent<{}> | null, newValues: Array<OptionType | string>) => {
            const isFreeSoloEmailSelected = Boolean(
                newValues.find(option => typeof option === 'string')
            )

            if (isFreeSoloEmailSelected && !yupString().email().isValidSync(inputValue)) {
                setErrorMsgRef('Must be a valid email format')
                setInputValue(inputValue) // it prevents autocomplete input from clearing itself
                return
            }

            onChange(
                newValues.map(value =>
                    typeof value === 'string'
                        ? {
                              value,
                              label: '',
                              group: GROUP.pendingEmails
                          }
                        : value
                )
            )
        }

        const PaperComponent = useMemo(
            () =>
                ({ children, ...paperProps }: HTMLAttributes<HTMLElement>) => {
                    const { errorMsg, inputValue } = paperPropsRef.current || {}

                    const optionsExist = Boolean(
                        Array.isArray(children) ? children[children.length - 1] : children
                    )

                    const updatedChildren =
                        optionsExist || !freeSolo || (freeSolo && !inputValue) ? (
                            children
                        ) : (
                            <Box p={2}>
                                There is no email <b>{inputValue}</b> in your organization. <br />
                                Press enter to add it
                            </Box>
                        )

                    return (
                        <Paper
                            {...paperProps}
                            sx={{
                                '& .MuiAutocomplete-groupLabel': {
                                    fontWeight: 'bold'
                                }
                            }}
                        >
                            {errorMsg && (
                                <Box p={2} style={{ color: 'red' }}>
                                    {errorMsg}
                                </Box>
                            )}
                            {updatedChildren}
                        </Paper>
                    )
                },
            // useMemo prevents list scrolling to the top after selection change
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        )

        return (
            <Autocomplete
                freeSolo={freeSolo}
                value={value}
                multiple
                groupBy={option => option.group}
                onInputChange={(_e, value) => {
                    setErrorMsgRef('')
                    setInputValueRef(value)
                }}
                inputValue={inputValue}
                PaperComponent={PaperComponent}
                autoHighlight
                disableClearable
                disableListWrap
                popupIcon={isShowPopupIcon ? undefined : null}
                onChange={handleChange}
                options={options}
                getOptionDisabled={option => option.disabled}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={({ InputProps, ...params }) => (
                    <TextField
                        {...params}
                        variant="standard"
                        InputProps={{
                            ...InputProps,
                            placeholder,
                            sx: {
                                padding: '8px 0 !important',
                                background: '#F5F5F5',
                                borderRadius: '4px'
                            },
                            startAdornment: (
                                <SearchIcon
                                    style={{ color: 'rgba(9, 12, 16, 0.6)', margin: '0 10' }}
                                />
                            )
                        }}
                    />
                )}
                renderOption={(props, option) => <Option {...props} option={option} />}
                getOptionLabel={o => {
                    if (typeof o === 'string') return o
                    return o.label || o.value || ''
                }}
            />
        )
    }
)
