import { Box, SvgIcon, Link } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import TelegramIcon from '@mui/icons-material/Telegram'
import ReceiptIcon from '@mui/icons-material/Receipt'
import CallToActionIcon from '@mui/icons-material/CallToAction'

import { ReactComponent as RequestPlaybookIcon } from 'assets/icons/request-playbook-activity-icon.svg'
import { ReactComponent as RecordVideoIcon } from 'assets/icons/record-video-activity-icon.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/content-copy.svg'
import { ReactComponent as DocViewIcon } from 'assets/icons/file.svg'

import dayjs from 'dayjs'

const types = {
    SEARCH: 'search',
    DOC_VIEW: 'docView',
    VIDEO_PLAY: 'videoPlay',
    UPLOAD_FROM_LOCAL_DRIVE: 'upload_from_local_drive',
    CREATE_PLAYLIST: 'create_playlist',
    UPLOAD_FROM_ZOOM: 'upload_from_zoom',
    REQUEST_PLAYBOOK_CALL_MADE: 'request_playbook_call_made',
    PUBLISH_PLAYBOOK: 'publish_playbook',
    SEARCH_NO_RESULTS: 'search-no-results',
    UPLOAD_AFTER_RECORDING: 'upload_after_recording',
    DUPLICATE_QG: 'duplicate-qg',
    SHARE_PLAYBOOK: 'share_playbook',
    UPLOADED_FROM_G_DRIVE: 'upload_from_g_drive',
    UPLOAD_FROM_ONE_DRIVE: 'upload_from_onedrive_drive',
    AUTO_UPLOAD: 'auto_upload',
    OPEN_PLAYLIST: 'open_playlist',
    OPEN_CTA: 'openCta',
    UPLOAD_FROM_YOUTUBE: 'upload_from_youtube'
}

export const EventIcon = ({ type, className = '', ...props }) => {
    switch (type) {
        case types.SEARCH:
        case types.SEARCH_NO_RESULTS:
            return (
                <Box className={className}>
                    <SearchIcon {...props} />
                </Box>
            )
        case types.OPEN_CTA:
            return (
                <Box className={className}>
                    <CallToActionIcon {...props} />
                </Box>
            )
        case types.DOC_VIEW: {
            return (
                <Box className={className}>
                    <DocViewIcon {...props} />
                </Box>
            )
        }
        case types.VIDEO_PLAY:
        case types.OPEN_PLAYLIST:
            return (
                <Box className={className}>
                    <PlayCircleOutlineIcon {...props} />
                </Box>
            )
        case types.UPLOAD_FROM_LOCAL_DRIVE:
            return (
                <Box className={className}>
                    <ControlPointIcon {...props} />
                </Box>
            )
        case types.PUBLISH_PLAYBOOK:
        case types.SHARE_PLAYBOOK:
            return (
                <Box className={className}>
                    <TelegramIcon {...props} />
                </Box>
            )
        case types.CREATE_PLAYLIST:
            return (
                <Box className={className}>
                    <RecordVideo />
                </Box>
            )
        case types.UPLOAD_FROM_ZOOM:
        case types.UPLOAD_AFTER_RECORDING:
        case types.UPLOAD_FROM_YOUTUBE:
            return (
                <Box className={className}>
                    <CloudUploadIcon {...props} />
                </Box>
            )
        case types.REQUEST_PLAYBOOK_CALL_MADE:
            return (
                <Box className={className}>
                    <RequestPlaybook />
                </Box>
            )
        case types.DUPLICATE_QG:
            return (
                <Box className={className}>
                    <CopyIcon style={{ color: '#fff' }} />
                </Box>
            )
        default:
            return (
                <Box className={className}>
                    <ReceiptIcon />
                </Box>
            )
    }
}

const typeToPast = type => {
    switch (type) {
        case types.DOC_VIEW:
            return 'Watched Quick guidde document'
        case types.SEARCH:
            return 'Searched'
        case types.SEARCH_NO_RESULTS:
            return 'Searched with no results'
        case types.VIDEO_PLAY:
            return 'Played video'
        case types.UPLOAD_FROM_LOCAL_DRIVE:
            return 'Uploaded video'
        case types.PUBLISH_PLAYBOOK:
            return 'Published video'
        case types.CREATE_PLAYLIST:
            return 'Created playlist'
        case types.UPLOAD_FROM_ZOOM:
            return 'Uploaded video from Zoom'
        case types.UPLOAD_FROM_YOUTUBE:
            return 'Uploaded video from Youtube'
        case types.UPLOAD_AFTER_RECORDING:
            return 'Recorded video'
        case types.REQUEST_PLAYBOOK_CALL_MADE:
            return 'Requested a video'
        case types.UPLOADED_FROM_G_DRIVE:
            return 'Uploaded video from GDrive'
        case types.UPLOAD_FROM_ONE_DRIVE:
            return 'Uploaded video from OneDrive'
        case types.SHARE_PLAYBOOK:
            return 'Shared a video'
        case types.AUTO_UPLOAD:
            return 'Auto uploaded video'
        case types.OPEN_PLAYLIST:
            return 'Opened a playlist'
        case types.OPEN_CTA:
            return 'Clicked action'
        case types.DUPLICATE_QG:
            return 'Duplicated video'
        default:
            return ''
    }
}

const typeToPresent = type => {
    switch (type) {
        case types.DUPLICATE_QG:
            return 'Make a Copy'
        case types.SEARCH:
            return 'Search'
        case types.SEARCH_NO_RESULTS:
            return 'Search with no results'
        case types.VIDEO_PLAY:
            return 'Play video'
        case types.UPLOAD_FROM_LOCAL_DRIVE:
        case types.UPLOAD_FROM_ZOOM:
        case types.UPLOADED_FROM_G_DRIVE:
        case types.UPLOAD_FROM_ONE_DRIVE:
        case types.AUTO_UPLOAD:
        case types.UPLOAD_FROM_YOUTUBE:
            return 'Upload video'
        case types.PUBLISH_PLAYBOOK:
            return 'Published video'
        case types.CREATE_PLAYLIST:
            return 'Create playlist'
        case types.UPLOAD_AFTER_RECORDING:
            return 'Record video'
        case types.REQUEST_PLAYBOOK_CALL_MADE:
            return 'Request a video'
        case types.SHARE_PLAYBOOK:
            return 'Share video'
        case types.OPEN_PLAYLIST:
            return 'Open playlist'
        case types.OPEN_CTA:
            return 'Click action'
        case types.DOC_VIEW:
            return 'Page view'
        default:
            return ''
    }
}

export const eventTypeToTextStatic = type => typeToPresent(type)

export const openCtaLabel = (event, toString) => {
    if (event.ctaType === 'clickedGoTo') {
        if (toString) {
            return `Clicked ${event.ctaLink} in guide`
        }
        return (
            <>
                Clicked{' '}
                <i>
                    <Link
                        href={event.ctaLink}
                        target="_blank"
                        color="inherit"
                        onClick={e => e.stopPropagation()}
                    >
                        {event.ctaLink}
                    </Link>{' '}
                </i>
                in guide
            </>
        )
    }

    return `Clicked '${event?.cta?.text?.label || 'action'}'`
}

export const docViewLabel = event => {
    return `Viewed ${event?.videoMetaData?.percentage || 0}% of the the page`
}

export const eventTypeToText = (type, event, toString) => {
    if (type === types.DOC_VIEW) return docViewLabel(event)

    if (type === types.OPEN_CTA) return openCtaLabel(event, toString)

    return typeToPast(type)
}

/*
    Check if the text displayed in the activity feed
    is a video play event and replace it with an expression
    Has view percentage -> Viewed 80% of the video
    No view percentage -> Viewed the video
*/
export const viewedVideo = (text, percentage) => {
    if (text !== 'Play video' && text !== 'Played video') {
        return text
    }
    if (percentage) {
        return `Viewed ${percentage}% of the video `
    }
    return 'Viewed the video '
}

export const eventTextToType = text => {
    switch (text) {
        case 'Search':
            return [types.SEARCH]
        case 'Search with no results':
            return [types.SEARCH_NO_RESULTS]
        case 'Play video':
            return [types.VIDEO_PLAY]
        case 'Upload video':
            return [
                types.UPLOAD_FROM_LOCAL_DRIVE,
                types.UPLOAD_FROM_ZOOM,
                types.UPLOADED_FROM_G_DRIVE,
                types.UPLOAD_FROM_ONE_DRIVE,
                types.AUTO_UPLOAD,
                types.UPLOAD_FROM_YOUTUBE
            ]
        case 'Published video':
            return [types.PUBLISH_PLAYBOOK]
        case 'Create playlist':
            return [types.CREATE_PLAYLIST]
        case 'Record video':
            return [types.UPLOAD_AFTER_RECORDING]
        case 'Request a video':
            return [types.REQUEST_PLAYBOOK_CALL_MADE]
        case 'Share video':
            return [types.SHARE_PLAYBOOK]
        case 'Open playlist':
            return [types.OPEN_PLAYLIST]
        case 'Click action':
            return [types.OPEN_CTA]
        case 'Make a Copy':
            return [types.DUPLICATE_QG]
        case 'Page view':
            return [types.DOC_VIEW]
        default:
            return []
    }
}

const RecordVideo = props => (
    <SvgIcon {...props}>
        <RecordVideoIcon />
    </SvgIcon>
)

const RequestPlaybook = props => (
    <SvgIcon {...props}>
        <RequestPlaybookIcon />
    </SvgIcon>
)

export const getMetatextFromEvent = (event, useAsHTMLTitle = null) => {
    if (!event) return ''
    switch (event.type) {
        case types.SEARCH:
        case types.SEARCH_NO_RESULTS:
            // in case the text is used as html title attribute don't add the "for" prefix
            // and don't add the quotation marks
            if (event.keyword && event.keyword !== 'undefined') {
                return `${useAsHTMLTitle ? event.keyword : `for "${event.keyword}"`}`
            }
            return null
        case types.AUTO_UPLOAD:
            if (event.title) return event.title
            return null
        case types.OPEN_PLAYLIST:
            return event?.playbook?.title || ''
        case types.OPEN_CTA:
            if (event.ctaType === 'clickedGoTo') return `"${event?.playbook?.title}"`
            if (event?.playbook?.title) return 'in video "' + event?.playbook?.title + '"'
            return ''
        case types.UPLOAD_AFTER_RECORDING:
            return event?.playbookTitle
                ? `"${event.playbookTitle}"`
                : event?.['pb-title']
                  ? `"${event['pb-title']}"`
                  : 'a video'
        case types.DUPLICATE_QG: {
            return event?.playbookTitle
                ? `"${event.playbookTitle}"`
                : event?.['pb-title']
                  ? `"${event['pb-title']}"`
                  : 'a video'
        }
        case types.DOC_VIEW:
        case types.VIDEO_PLAY:
        case types.UPLOAD_FROM_LOCAL_DRIVE:
        case types.PUBLISH_PLAYBOOK:
        case types.CREATE_PLAYLIST:
        case types.UPLOAD_FROM_ZOOM:
        case types.SHARE_PLAYBOOK:
        case types.UPLOADED_FROM_G_DRIVE:
        case types.UPLOAD_FROM_ONE_DRIVE:
        case types.REQUEST_PLAYBOOK_CALL_MADE:
        case types.UPLOAD_FROM_YOUTUBE:
            if (event?.playbook?.title && event?.playbook !== 'undefined')
                return `"${event.playbook.title}" ${getPlaylistText(event)}`
            if (
                (event?.playbook?.originalFileName || event?.playbook?.title) &&
                event?.playbook !== 'undefined'
            )
                return `"${
                    event?.playbook?.originalFileName || event?.playbook?.title
                }" ${getPlaylistText(event)}`
            return 'a video' + getPlaylistText(event)
        default:
            return ''
    }
}

const getPlaylistText = event => {
    if (event?.['playlist-title']) {
        return ' in playlist ' + event?.['playlist-title']
    }
    return ''
}

export const getEventLinkURL = event => {
    if (!event) return null
    switch (event.type) {
        case types.SEARCH:
        case types.SEARCH_NO_RESULTS:
            return null
        case types.VIDEO_PLAY:
        case types.DUPLICATE_QG:
        case types.UPLOAD_FROM_LOCAL_DRIVE:
        case types.PUBLISH_PLAYBOOK:
        case types.CREATE_PLAYLIST:
        case types.UPLOAD_FROM_ZOOM:
        case types.SHARE_PLAYBOOK:
        case types.UPLOADED_FROM_G_DRIVE:
        case types.UPLOAD_FROM_ONE_DRIVE:
        case types.UPLOAD_FROM_YOUTUBE:
        case types.DOC_VIEW:
        case types.UPLOAD_AFTER_RECORDING:
            if (event['playlist-id']) return `/playbooks/playlist/${event['playlist-id']}`
            if (event.playbook?.id) return `/playbooks/${event.playbook?.id}`
            if (event.playbookId) return `/playbooks/${event.playbookId}`
            if (event['pb-id']) return `/playbooks/${event['pb-id']}`
            return null
        default:
            return ''
    }
}

export const getLocalDate = date => dayjs(date).subtract(dayjs(date).utcOffset() * -1, 'minutes')

export const groupByLocalDays = daysList => {
    // reduce all events
    const allEvents = daysList.reduce((prev, curr) => [...prev, curr.events], []).flat()

    //group events by date
    const groupedEvents = groupBy(allEvents, event => {
        return event.date.format('DD/MM/YYYY')
    })

    // restrcture to the expected data type
    const restructured = Object.entries(groupedEvents).map(([date, events]) => ({
        date: dayjs(date, 'DD/MM/YYYY'),
        events
    }))
    return restructured
}

const groupBy = (list, iter) => {
    let grouped = []

    list.map(event => {
        if (grouped[iter(event)]) grouped[iter(event)] = [...grouped[iter(event)], event]
        else grouped[iter(event)] = [event]
    })

    return grouped
}
