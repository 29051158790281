import { useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'

import {
    Box,
    Button,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    Paper,
    Popper,
    Slider,
    Switch
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { SpacedGroup } from 'UI/Components'

import { setQuickGuiddeDuration } from 'ducks'
import { useSessionStorage } from 'hooks'

const PREFIX = 'DurationSelection'

const classes = {
    root: `${PREFIX}-root`,
    thumb: `${PREFIX}-thumb`,
    header: `${PREFIX}-header`,
    divider: `${PREFIX}-divider`,
    root2: `${PREFIX}-root2`,
    paper: `${PREFIX}-paper`,
    closeBtn: `${PREFIX}-closeBtn`,
    description: `${PREFIX}-description`
}

const StyledPopper = styled(Popper)({
    [`& .${classes.header}`]: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px'
    },
    [`& .${classes.divider}`]: {
        margin: '8px 2px',
        background: 'rgba(0, 0, 0, 0.14)'
    },
    [`& .${classes.root2}`]: {
        backgroundColor: '#F5F5F5',
        width: '90px',
        height: '34px',
        fontSize: '14px',
        padding: 0,
        margin: 0,
        '& fieldset': {
            borderColor: 'transparent !important'
        }
    },
    [`& .${classes.paper}`]: {
        width: 250,
        padding: '8px'
    },
    [`& .${classes.closeBtn}`]: {
        position: 'absolute',
        right: '12px',
        top: '5px'
    },
    [`& .${classes.description}`]: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: '#090C10'
    }
})

const GuiddeSlider = Slider

type DurationSelectionProps = {
    open: boolean
    onClose: () => void
    anchorEl: null | HTMLElement
}

export const DurationSelection = ({ open, onClose, anchorEl }: DurationSelectionProps) => {
    const dispatch = useDispatch()

    const {
        present: { steps, activeStep }
    } = useSelector(state => state.qgEditor)

    const { duration = 3.0, audioNote, transition, subtitles } = steps[activeStep] || {}

    // Save the duration in sessionStorage in case the user selected "apply to all"
    // Next time a step will be added (manually) in the current session the step will get the global duration
    // for this session.
    const [, setSavedInput] = useSessionStorage<number | null>('guiddeSavedDuration', null)

    const sliderMin = useMemo(() => {
        // Normal min duration starts from 1
        // In case the step has audio, or captions - use its length as the minimum
        const defaultDuration = 1

        const audioDuration =
            Math.ceil(Number(audioNote?.type !== 'defaultSubtitles' && audioNote?.audioDuration)) ||
            defaultDuration
        const subtitlesDuration = subtitles?.[subtitles.length - 1]?.end || defaultDuration

        return Math.ceil(Math.max(audioDuration, subtitlesDuration))
    }, [audioNote, subtitles])

    // Normal max duration is 10
    // In case the step has audio use length+10 as the maximum
    const sliderMax = useMemo(() => {
        if (!audioNote || audioNote.type === 'defaultSubtitles') return 10

        return Math.ceil(audioNote.audioDuration + 10)
    }, [audioNote])

    const [input, setInput] = useState({
        duration: Math.ceil(Math.max(sliderMin, duration)),
        applyToAll: false
    })

    // Disable apply button
    const isDisabled = duration === input.duration && !input.applyToAll

    return (
        <StyledPopper
            open={open}
            anchorEl={anchorEl}
            placement="top-end"
            style={{ zIndex: 2 }}
            onMouseDown={e => {
                e.stopPropagation()
            }}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Paper className={classes.paper}>
                    <SpacedGroup justifyContent="space-between" alignItems="center" mx={1.5}>
                        <Box className={classes.header}>Timing</Box>
                        <IconButton className={classes.closeBtn} onClick={onClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </SpacedGroup>
                    <Divider className={classes.divider} />
                    <SpacedGroup mx={1.5}>
                        <GuiddeSlider
                            size="small"
                            step={1}
                            min={sliderMin}
                            max={sliderMax}
                            value={input.duration}
                            valueLabelFormat={value => value + (transition?.duration || 0)}
                            valueLabelDisplay="auto"
                            onChange={(_e, newValue) => {
                                setInput(prevState => ({
                                    ...prevState,
                                    duration: newValue as number
                                }))
                            }}
                            classes={{
                                root: classes.root,
                                thumb: classes.thumb
                            }}
                        />
                        <Box minWidth={25}>{input.duration + (transition?.duration || 0)}s</Box>
                    </SpacedGroup>
                    {(!Boolean(audioNote) || audioNote?.type === 'defaultSubtitles') && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Grid item xs={2}>
                                <Box ml={0.5}>
                                    <Switch
                                        size="small"
                                        color="primary"
                                        checked={input.applyToAll}
                                        onChange={(_e, value) => {
                                            setInput(prevState => ({
                                                ...prevState,
                                                applyToAll: value
                                            }))
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={10} className={classes.description}>
                                <Box ml={1}>Apply to all steps</Box>
                            </Grid>

                            <Grid item xs={2}></Grid>
                            <Grid item xs={10}>
                                <Box ml={1}>
                                    all steps with voiceover <br />
                                    won’t be effected
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    <SpacedGroup justifyContent="flex-end" mr={1} my={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isDisabled}
                            onClick={() => {
                                dispatch(setQuickGuiddeDuration(input))
                                if (input.applyToAll) {
                                    setSavedInput(input.duration)
                                }
                                onClose()
                            }}
                        >
                            Apply
                        </Button>
                    </SpacedGroup>
                </Paper>
            </ClickAwayListener>
        </StyledPopper>
    )
}
