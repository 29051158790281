import { type HTMLAttributes, memo, useContext, useEffect, useMemo, useState } from 'react'

import { Form, Formik } from 'formik'

import { ShareButtons } from '@guidde-co/shared.share-only-dialog'

import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Chip,
    FormHelperText,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { GROUP, SpacedGroup, VideoStatusContext, videPlayerId } from 'UI/Components'

import { StartAtInput } from './StartAtInput'

import {
    logShareByEmail,
    useActivePlaybook,
    useAuth,
    useBoolean,
    useCopyLinkedGif,
    useCopySharedLink,
    useNotification,
    useOrganization,
    useOrgFeatureFlags,
    usePlaybookShare,
    useShareableOptions
} from 'hooks'
import {
    formatTime,
    logToAnalytics,
    option,
    playbookToAnalyticsProps,
    timeToSeconds,
    validateEmail
} from 'modules'
import { facebookAppId } from 'env'

import {
    type PendingEmailType,
    type PlaybookType,
    type PlaylistType,
    type ShareableSpacesUserType,
    PlaybookMode
} from 'app/types'
import { links } from 'app/links'

const generateStartValue = (time: number) => {
    const value = Math.floor(time)
    const data = formatTime(value).split(':')
    if (data.length < 3) data.unshift('00')

    return data.join(':')
}

type SubmitType = {
    options: Array<{
        isSelected: boolean
        email: string
        label: string
        value: string
        name: string
        logo: string
        group: typeof GROUP.pendingEmails | typeof GROUP.teamMembers
    }>
}

type Props = {
    playbook: PlaybookType | PlaylistType
    isLoadingData: boolean
    onClose: () => void
    onDone: (playbookId?: string) => void
    setLoading: (val: boolean) => void
}

const getEmailOption = (user: PendingEmailType) => ({
    ...option(user.email, user.email),
    isSelected: false,
    email: user.email,
    group: GROUP.pendingEmails,
    name: '',
    logo: ''
})

const getUserOption = (user: ShareableSpacesUserType) => ({
    ...option(user.uid, user.email),
    email: user.email,
    isSelected: false,
    group: GROUP.teamMembers,
    name: user.displayName,
    logo: user.logo
})

export const ShareTab = memo(({ playbook, isLoadingData, onClose, onDone, setLoading }: Props) => {
    const { isGifGenerationFinished } = useContext(VideoStatusContext)

    const startAt = useBoolean()
    const focussed = useBoolean()

    const [input, setInput] = useState('')

    const { uid } = useAuth()
    const { activePlaybook } = useActivePlaybook()

    const { disableExternalSharing } = useOrgFeatureFlags()
    const { domains: orgDomains } = useOrganization()

    const { copySharedLink, getCopyPath } = useCopySharedLink()
    const { copyLinkedGif } = useCopyLinkedGif()

    const { showErrorNotification, showSuccessNotification } = useNotification()
    const [startTime, setStartTime] = useState('00:00:00')

    // get video element from DOM, then get the active time and listed current time update
    useEffect(() => {
        const video = document.getElementById(videPlayerId) as HTMLVideoElement | undefined

        if (!video) return

        setStartTime(generateStartValue(video.currentTime))

        const handleTimeUpdated = (e: any) => {
            const current = e?.target?.currentTime
            if (!current) return

            setStartTime(generateStartValue(current))
        }

        video.addEventListener('timeupdate', handleTimeUpdated)

        return () => {
            if (!video) return

            video.removeEventListener('timeupdate', handleTimeUpdated)
        }
    }, [])

    const { sharePlaybook, isSharing, ApprovalModal } = usePlaybookShare({
        onSuccess: data => {
            if (data?.code === 422) return

            onClose()
            onDone()
            showSuccessNotification(`Video shared successfully`)
        }
    })

    const $options = useShareableOptions({
        playbooksIds: [playbook.id],
        onError: onClose
    })

    const { data } = $options
    const optionsList = useMemo(
        () => [
            ...(data?.internalUsers?.map(getUserOption) || []),
            ...(data?.externalUsers?.map(getUserOption) || []),
            ...(data?.pendingEmails?.map(getEmailOption) || [])
        ],
        [data]
    )

    const onCopy = () => {
        logToAnalytics('shareModal_shareTab_copyLinkIcon_clicked', {
            playbookId: playbook.id,
            ...playbookToAnalyticsProps(playbook)
        })
        let query = ''

        if (startAt.isTrue) {
            query += `&t=${timeToSeconds(startTime)}`
        }

        if (playbook.mode === PlaybookMode.Playlist) {
            query += `&active=${activePlaybook}`
        }

        copySharedLink(
            playbook,
            true,
            {
                context: 'share-link'
            },
            query
        )
    }

    const loading = $options.isValidating || isSharing

    useEffect(() => {
        setLoading(loading)
    }, [loading, setLoading])

    const notEnteredEmailIsValid = input && validateEmail(input)

    return (
        <Box>
            <StartAtInput
                startAt={startAt}
                startTime={startTime}
                onTimeChange={() => {
                    logToAnalytics('shareModal_shareTab_startAtTime_inserted', {
                        playbookId: playbook.id,
                        ...playbookToAnalyticsProps(playbook)
                    })
                }}
                setStartTime={setStartTime}
                onCheckBoxChange={state => {
                    logToAnalytics('shareModal_shareTab_startAtChk', {
                        state: state ? 'check' : 'uncheck',
                        playbookId: playbook.id,
                        ...playbookToAnalyticsProps(playbook)
                    })
                }}
            />

            <Box mt={4} />
            <Formik
                initialValues={{
                    options: optionsList.filter(it => it.isSelected)
                }}
                enableReinitialize={true}
                onSubmit={({ options }: SubmitType, { setFieldError }) => {
                    const inputValue = input.trim()

                    setInput('')
                    const userIds = options
                        .filter(({ group }) => group === GROUP.teamMembers)
                        .map(o => o.value)

                    const emails = options
                        .filter(({ group }) => group === GROUP.pendingEmails)
                        .map(o => o.value)

                    if (validateEmail(inputValue)) {
                        const teamMember = optionsList.find(it => it.email === inputValue)

                        if (teamMember) userIds.push(teamMember.value)
                        else emails.push(inputValue)
                    }
                    const includeInvalidEmail = emails.some(email => !validateEmail(email))

                    if (disableExternalSharing) {
                        const emailDomains = emails.map(email => {
                            return email.split('@')[1]
                        })

                        const includeExternalDomain = !emailDomains.every(emailDomain =>
                            orgDomains.includes(emailDomain)
                        )

                        if (includeExternalDomain) {
                            setFieldError('options', `Allowed domains: ${orgDomains.join(', ')}`)
                            return
                        }
                    }

                    if (includeInvalidEmail) {
                        showErrorNotification('Some email is invalid')
                        return
                    } else {
                        sharePlaybook({
                            emails: [...new Set(emails)],
                            userIds,
                            playbooksIds: [playbook.id],
                            notificationsReceivers: 'membersAndSpaces',
                            shareMode: 'share'
                        }).then(() => {
                            logShareByEmail({
                                eventName: 'publish_playbook',
                                sharedByUid: uid,
                                sentTo: options.map(it => it?.email).filter(validateEmail),
                                playbookIds: [playbook.id]
                            })
                        })
                    }
                }}
            >
                {({ dirty, values, setFieldValue, handleSubmit, errors }) => (
                    <Form>
                        <SpacedGroup alignItems="flex-end">
                            <Box width="100%">
                                <Autocomplete
                                    multiple
                                    value={values.options}
                                    onChange={(e, value) => {
                                        if (value.every(it => typeof it === 'object')) {
                                            setFieldValue('options', value)
                                        } else {
                                            const modifiedValue = value.map(it => {
                                                if (typeof it === 'object') return it

                                                return {
                                                    value: it.trim(),
                                                    label: it,
                                                    isSelected: false,
                                                    group: GROUP.pendingEmails
                                                }
                                            })

                                            setFieldValue('options', modifiedValue)
                                        }
                                    }}
                                    onFocus={focussed.setTrue}
                                    onBlur={focussed.setFalse}
                                    freeSolo
                                    options={optionsList}
                                    getOptionLabel={option => {
                                        if (typeof option === 'string') return option
                                        return option.label
                                    }}
                                    disabled={$options.isValidating}
                                    filterSelectedOptions
                                    style={{
                                        background: '#FFFFFF',
                                        padding: `${values.options.length < 1 ? 0 : 8}px 4px 0`,
                                        marginTop: values.options.length < 1 ? '-10px' : 0,
                                        borderRadius: '4px 4px 0 0',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        maxHeight: '200px'
                                    }}
                                    sx={{
                                        '& .MuiAutocomplete-endAdornment': {
                                            top: '0',
                                            right: '8px'
                                        }
                                    }}
                                    renderTags={(params, getProps) => {
                                        return params.map((it, index) => (
                                            <Chip
                                                {...getProps({ index })}
                                                key={it.value}
                                                label={it.name || it.email || it.value}
                                                sx={{
                                                    color: '#090C10',
                                                    fontSize: 14,
                                                    background: 'rgba(9, 12, 16, 0.08)',
                                                    border: '1px solid rgba(9, 12, 16, 0.08)',
                                                    borderRadius: 16,
                                                    height: '24px !important'
                                                }}
                                                deleteIcon={
                                                    <CloseIcon
                                                        fontSize="small"
                                                        style={{
                                                            width: 15,
                                                            height: 15,
                                                            color: '#090C10',
                                                            marginRight: 5
                                                        }}
                                                    />
                                                }
                                            />
                                        ))
                                    }}
                                    renderOption={(props, option) => (
                                        <Stack
                                            {...(props as unknown as HTMLAttributes<HTMLDivElement>)}
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Avatar
                                                src={option.logo}
                                                style={{ width: 40, height: 40 }}
                                            />
                                            <Box>
                                                <Typography
                                                    style={{ fontSize: 14, fontWeight: 500 }}
                                                >
                                                    {option.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: 14 }}
                                                >
                                                    {option.email}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    )}
                                    renderInput={params => (
                                        <Box position="relative">
                                            <TextField
                                                variant="standard"
                                                data-test="shareDialog-email-textBox"
                                                {...params}
                                                onClick={() => {
                                                    logToAnalytics(
                                                        'shareModal_shareTab_emailAddressTb_clicked',
                                                        {
                                                            playbookId: playbook.id,
                                                            ...playbookToAnalyticsProps(playbook)
                                                        }
                                                    )
                                                }}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        '&:before, &:after': {
                                                            left: -8,
                                                            right: -8
                                                        }
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        marginTop: '-5px',
                                                        '&.MuiInputLabel-shrink': {
                                                            marginTop: 0
                                                        }
                                                    }
                                                }}
                                                onChange={e => setInput(e.target.value)}
                                                onPaste={e => {
                                                    const email = e.clipboardData
                                                        .getData('Text')
                                                        .trim()

                                                    const isValid = validateEmail(email)

                                                    if (!isValid) return

                                                    const alreadyExists = values.options.find(
                                                        it => it.value === email
                                                    )

                                                    if (alreadyExists) setInput(email)
                                                    else {
                                                        e.preventDefault()

                                                        const teamMember = optionsList.find(
                                                            it => it.email === email
                                                        )

                                                        if (teamMember) {
                                                            setFieldValue('options', [
                                                                ...new Set([
                                                                    ...values.options,
                                                                    teamMember
                                                                ])
                                                            ])
                                                        } else {
                                                            const newValue = {
                                                                value: email,
                                                                label: email,
                                                                isSelected: false,
                                                                group: GROUP.pendingEmails
                                                            }
                                                            setFieldValue('options', [
                                                                ...new Set([
                                                                    ...values.options,
                                                                    newValue
                                                                ])
                                                            ])
                                                        }
                                                    }
                                                }}
                                                style={{
                                                    textAlign: 'center',
                                                    color: 'rgba(0, 0, 0, 0.54)'
                                                }}
                                                label={
                                                    <Typography
                                                        color={
                                                            focussed.isTrue
                                                                ? 'inherit'
                                                                : 'textSecondary'
                                                        }
                                                    >
                                                        Enter email address here
                                                    </Typography>
                                                }
                                            />
                                        </Box>
                                    )}
                                />

                                {errors.options && (
                                    <FormHelperText>{errors.options.toString()}</FormHelperText>
                                )}
                            </Box>
                            <Button
                                variant="contained"
                                data-test="shareDialog-send-button"
                                disabled={(!dirty && !notEnteredEmailIsValid) || isSharing}
                                onClick={() => {
                                    logToAnalytics(
                                        'shareModal_shareTab_emailAddressSendBtn_clicked',
                                        {
                                            playbookId: playbook.id,
                                            ...playbookToAnalyticsProps(playbook)
                                        }
                                    )
                                    if (notEnteredEmailIsValid) {
                                        // prevent adding duplications
                                        if (values.options.every(it => it.value !== input)) {
                                            // add email manually when entered field is email valid
                                            setFieldValue('options', [
                                                ...values.options,
                                                {
                                                    group: GROUP.pendingEmails,
                                                    isSelected: false,
                                                    label: input,
                                                    value: input
                                                }
                                            ])
                                        }
                                    }
                                    handleSubmit()
                                }}
                                color="primary"
                                sx={
                                    (!dirty && !notEnteredEmailIsValid) || isSharing
                                        ? {
                                              backgroundColor: '#CB0000 !important',
                                              opacity: 0.4,
                                              color: '#FFFFFF !important'
                                          }
                                        : {}
                                }
                            >
                                SEND
                            </Button>
                        </SpacedGroup>
                        <Box mt={4} />
                        <ShareButtons
                            data-test="shareDialog-copy-button"
                            playbook={playbook}
                            showCopyButtons={true}
                            showFacebookButton={true}
                            url={getCopyPath(playbook)}
                            guiddeWebsiteUrl={links.guiddeWebsite}
                            onCopy={onCopy}
                            facebookAppId={facebookAppId}
                            socialBtnTooltip="Adjust video visibility to 'Public' for social sharing"
                            disableSocialButtons={{
                                facebook: !playbook.isPublic,
                                twitter: !playbook.isPublic,
                                linkedin: !playbook.isPublic
                            }}
                            onFacebookClick={() => {
                                logToAnalytics('shareModal_shareTab_socialShareIcon_clicked', {
                                    playbookId: playbook.id,
                                    type: 'Facebook',
                                    ...playbookToAnalyticsProps(playbook)
                                })
                            }}
                            onTwitterClick={() => {
                                logToAnalytics('shareModal_shareTab_socialShareIcon_clicked', {
                                    playbookId: playbook.id,
                                    type: 'X (Twitter)',
                                    ...playbookToAnalyticsProps(playbook)
                                })
                            }}
                            onLinkedinClick={() => {
                                logToAnalytics('shareModal_shareTab_socialShareIcon_clicked', {
                                    playbookId: playbook.id,
                                    type: 'LinkedIn',
                                    ...playbookToAnalyticsProps(playbook)
                                })
                            }}
                            onEmailClick={() => {
                                logToAnalytics('shareModal_shareTab_emailIcon_clicked', {
                                    playbookId: playbook.id,
                                    ...playbookToAnalyticsProps(playbook)
                                })
                            }}
                            onCopyGif={() => {
                                logToAnalytics('shareModal_shareTab_GIFIcon_clicked', {
                                    playbookId: playbook.id,
                                    ...playbookToAnalyticsProps(playbook)
                                })
                                copyLinkedGif(playbook)
                            }}
                            gifInProcessing={!isGifGenerationFinished || isLoadingData}
                        />
                    </Form>
                )}
            </Formik>
            {ApprovalModal}
        </Box>
    )
})
