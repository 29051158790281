import { type ReactNode } from 'react'

import { type ButtonProps, Button, CircularProgress } from '@mui/material'

type Props = {
    loading: boolean
    children: ReactNode
} & ButtonProps

export const LoadingButton = ({ loading, children, ...props }: Props) => (
    <Button
        variant="contained"
        {...props}
        disabled={loading}
        sx={{
            '&.Mui-disabled': {
                pointerEvents: 'auto',
                backgroundColor: '#ffffff80',
                '&:hover': {
                    backgroundColor: '#ffffff80'
                }
            }
        }}
    >
        {children}
        {loading && (
            <CircularProgress
                size={14}
                sx={{
                    marginLeft: 0.5,
                    color: 'gray'
                }}
            />
        )}
    </Button>
)
