import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, Button, Divider, IconButton } from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'

import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import { AudioPlayer, ConfirmationDialog, SpacedGroup } from 'UI/Components'
import { useStepSpeakingRate } from 'UI/Routes/quick-guidde/hooks'
import { AsyncSubtitlesContext } from 'UI/Routes/quick-guidde/AsyncSubtitlesProvider'
import { QgTranscript } from './QgTranscript'
import { VoiceOverLoader } from './VoiceOverLoader'

import { setAudioNote, setTempAudioNote } from 'ducks'
import { logToAnalytics } from 'modules'

type Props = {
    onEdit: (data: { markdown: string; speakingRate?: number; applyToAllSteps?: boolean }) => void
    showSpeedButton?: boolean
}

export const VoiceOverPreview = ({ onEdit, showSpeedButton }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const showDeleteDialog = useBoolean()

    const { activeStep, steps } = useSelector(state => state.qgEditor.present)

    const { processingStepsId } = useContext(AsyncSubtitlesContext)

    const { getStepSpeakingRate } = useStepSpeakingRate()

    const { audioNote, id } = steps?.[activeStep]

    const defaultSubtitles = useMemo(() => audioNote?.markdown || '', [audioNote?.markdown])

    const [value, setValue] = useState(defaultSubtitles)

    useEffect(() => {
        if (!audioNote) return
        setValue(audioNote.tempMarkdown || audioNote.markdown)
    }, [audioNote, defaultSubtitles])

    if (!audioNote || audioNote.type === 'defaultSubtitles') return null

    return (
        <>
            <Box mb={3}>
                <AudioPlayer
                    audioFile={audioNote.audioUrl}
                    duration={Math.floor(audioNote.audioDuration)}
                    speakingRate={
                        showSpeedButton ? getStepSpeakingRate(steps[activeStep]) : undefined
                    }
                    speakerConfig={
                        audioNote.type === 'textToSpeech' ? audioNote.speakerConfig : undefined
                    }
                    onChangeSpeed={(speakingRate, applyToAllSteps) => {
                        onEdit({ markdown: value, speakingRate, applyToAllSteps })
                    }}
                    onPlay={() => {
                        logToAnalytics('voiceover_play', {
                            voiceOverType: audioNote.type,
                            playbookId,
                            activeStep
                        })
                    }}
                    onTimePointerClick={timestamp => {
                        if (audioNote.type === 'textToSpeech') {
                            logToAnalytics('voiceover_t2vTab_playerContainer_pointer_clicked', {
                                playbookId,
                                timestamp
                            })
                        }
                    }}
                />
            </Box>
            <Box>
                <QgTranscript
                    text={audioNote?.text}
                    markdown={value}
                    language={
                        audioNote.type === 'speechToText'
                            ? audioNote.languageName
                            : audioNote.speakerConfig.langName
                    }
                    speakerConfig={
                        audioNote.type === 'textToSpeech' ? audioNote.speakerConfig : undefined
                    }
                    type={audioNote.type}
                    onChange={setValue}
                    onSave={markdown => {
                        onEdit({
                            markdown: markdown || value,
                            speakingRate: getStepSpeakingRate(steps[activeStep])
                        })
                    }}
                    onCancel={() => setValue(defaultSubtitles)}
                />

                {audioNote.type === 'speechToText' && processingStepsId.includes(id) && (
                    <VoiceOverLoader title="Creating transcript" />
                )}
            </Box>

            <Box position="absolute" bottom={8} left={0} width="100%" textAlign="center">
                <Divider style={{ marginBottom: 8 }} />

                <SpacedGroup spacing={2} justifyContent="center">
                    {audioNote.type === 'speechToText' && (
                        <Button
                            startIcon={<UndoIcon />}
                            variant="text"
                            color="inherit"
                            onClick={() => {
                                dispatch(setAudioNote())
                                history.pushState({ selectedTab: 0, startRecording: true }, '')
                                logToAnalytics('voiceover_restart_from_preview', {
                                    playbookId,
                                    activeStep
                                })
                            }}
                        >
                            RESTART RECORDING
                        </Button>
                    )}

                    {audioNote.type === 'textToSpeech' && (
                        <Button
                            data-test="editor-edit-textToSpeech"
                            data-intercom="editor-edit-textToSpeech"
                            startIcon={<EditIcon />}
                            variant="text"
                            color="inherit"
                            onClick={() => {
                                const { speakerConfig, type, text, markdown } = audioNote

                                dispatch(setAudioNote())
                                dispatch(
                                    setTempAudioNote({
                                        type,
                                        text,
                                        markdown,
                                        speakerConfig,
                                        speakingRate: getStepSpeakingRate(steps[activeStep])
                                    })
                                )

                                logToAnalytics('voiceover_t2vTab_editVoiceoverBtn_clicked', {
                                    playbookId
                                })
                            }}
                        >
                            EDIT VOICEOVER
                        </Button>
                    )}

                    <IconButton
                        onClick={() => {
                            showDeleteDialog.setTrue()

                            if (audioNote.type === 'textToSpeech') {
                                logToAnalytics('voiceover_t2vTab_deleteVoiceoverIcon_clicked', {
                                    playbookId
                                })
                            }
                        }}
                        size="small"
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </SpacedGroup>
            </Box>

            <ConfirmationDialog
                isOpen={showDeleteDialog.isTrue}
                onClose={() => {
                    showDeleteDialog.setFalse()

                    if (audioNote.type === 'textToSpeech') {
                        logToAnalytics('voiceover_t2vTab_deleteVoiceover_cancelBtn_clicked', {
                            playbookId
                        })
                    }
                }}
                onConfirm={() => {
                    history.pushState(
                        { selectedTab: audioNote.type === 'speechToText' ? 0 : 1 },
                        ''
                    )
                    // ts2 and s2t delete handling
                    showDeleteDialog.setFalse()
                    dispatch(setAudioNote())

                    if (audioNote.type === 'textToSpeech') {
                        logToAnalytics('voiceover_t2vTab_deleteVoiceover_deleteBtn_clicked', {
                            playbookId
                        })
                    }
                }}
                title="Remove voiceover"
                text={<>Are you sure you want to delete current recording?</>}
                confirmBtnText="Delete"
            />
        </>
    )
}
