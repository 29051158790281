import { memo } from 'react'

import { useAuth, useOrgFeatureFlags, useRoles } from 'hooks'
import { type AnyPlaybookType, PlaybookMode } from 'app/types'
import { PlaybookInsightsDrawer } from 'UI/Components'

import { ViewAnalyticsDialog } from './ViewAnalyticsDialog'

type Props = {
    isOwner: boolean
    playbook: AnyPlaybookType
    isOpen: boolean
    onClose: () => void
}

export const PlaybookAnalytics = memo(({ isOwner, isOpen, onClose, playbook }: Props) => {
    const { isContentManager, isAdmin, isSuperAdmin, isViewer } = useRoles()
    const { orgId } = useAuth()

    const { showInsightsV2VideoDrawer, isLoading } = useOrgFeatureFlags()

    if (isLoading) return null

    // users from another orgs shouldn't see the analytic
    if (orgId !== playbook.uploadedByOrgId && !isSuperAdmin) return null

    if (isViewer || (!isOwner && !isContentManager && !isAdmin)) return null

    if (playbook.mode !== PlaybookMode.Playlist && showInsightsV2VideoDrawer) {
        return <PlaybookInsightsDrawer isOpen={isOpen} onClose={onClose} playbookId={playbook.id} />
    }

    return <ViewAnalyticsDialog isOpen={isOpen} onClose={onClose} playbook={playbook} />
})
