import { type FunctionComponent, type SVGProps } from 'react'

import { paths } from 'app/paths'
import { roles } from 'hooks'

import AppsIcon from '@mui/icons-material/Apps'
import type SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as HomeIcon } from 'assets/icons/menu/home.svg'
import { ReactComponent as PlaybooksIcon } from 'assets/icons/menu/icon-playbooks.svg'
import { ReactComponent as InsightsIcon } from 'assets/icons/menu/icon-insights.svg'
import { ReactComponent as SpacesIcon } from 'assets/icons/menu/icon-spaces.svg'
import { ReactComponent as ActivityIcon } from 'assets/icons/menu/icon-activity-feed.svg'
import { ReactComponent as IntegrationsIcon } from 'assets/icons/menu/icon-integrations.svg'
import { ReactComponent as BrandKitIcon } from 'assets/icons/menu/icon-brand-kit.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/menu/trash.svg'

import { ReactComponent as TrophyIcon } from 'assets/icons/medal.svg'

export type IconType = typeof SvgIcon | FunctionComponent<SVGProps<SVGSVGElement>>

type RoleValue = (typeof roles)[keyof typeof roles]
export type MenuItemType = {
    path: string
    startIcon: IconType
    endIcon?: IconType
    title: string
    role?: Array<RoleValue> | RoleValue
    feature?: string
    showDivider?: boolean
}

export type MenuListType = Array<MenuItemType>

export const MENU: MenuListType = [
    {
        path: paths.home,
        startIcon: HomeIcon,
        title: 'Home'
    },
    {
        path: paths.myVideos,
        startIcon: PlaybooksIcon,
        title: 'My Guiddes',
        role: [roles.team]
    },
    {
        path: paths.spaces,
        startIcon: SpacesIcon,
        title: 'Spaces'
    },
    {
        path: paths.insights,
        startIcon: InsightsIcon,
        title: 'Insights',
        role: [roles.contentManager, roles.admin]
    },
    {
        path: paths.activity,
        startIcon: ActivityIcon,
        title: 'Activity feed',
        role: [roles.contentManager, roles.admin]
    },
    {
        path: paths.appsManagement,
        startIcon: AppsIcon,
        title: 'Apps Management',
        role: roles.superAdmin
    },
    {
        path: paths.brandKit,
        startIcon: BrandKitIcon,
        endIcon: TrophyIcon,
        title: 'Brand Kit',
        role: roles.contentManager,
        showDivider: true
    },
    {
        path: paths.integrations,
        startIcon: IntegrationsIcon,
        title: 'Integrations',
        role: roles.team
    },
    {
        path: paths.trash,
        startIcon: TrashIcon,
        title: 'Trash',
        role: roles.team
    }
]

export const MENU_MOBILE: MenuListType = [
    {
        path: paths.home,
        startIcon: HomeIcon,
        title: 'Home'
    },
    {
        path: paths.myVideos,
        startIcon: PlaybooksIcon,
        title: 'My Guiddes',
        role: [roles.contentManager]
    },
    {
        path: paths.spaces,
        startIcon: SpacesIcon,
        title: 'Spaces'
    }
]
