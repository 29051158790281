import { Avatar, Box, Button, Typography } from '@mui/material'

import { type QuickGuiddeType } from 'app/types'
import { useWindowView } from 'hooks'

import { UtmLink } from 'UI/Components'

type Props = {
    playbook: QuickGuiddeType
    brandKitBackground: string
}

const countMinutesSpent = (value: number) => {
    if (value < 10) return 1

    const tens = Math.floor(value / 10)
    const endsWithZero = value % 10 === 0

    // if `value` ends on `0` - return `tens` else add `tens` + 1
    // Example: 10 ends on `0` - 1,`15` - 2, `33` - 4 etc
    return endsWithZero ? tens : tens + 1
}

export const StepsFooterStatistic = ({ playbook, brandKitBackground }: Props) => {
    const { isMobileView } = useWindowView()
    // The time in minutes should be calculated based on `steps` length,
    // every 10 steps is a minute.
    // Example: 15 steps - 2 min, 6 steps - 1min, 44 steps - 5 min

    // maximum time that we can show - 5 minutes
    const timeSpent = Math.min(countMinutesSpent(playbook.steps.length), 5)

    return (
        <Box width={isMobileView ? '100%' : '85%'} m="0 auto">
            <Box
                style={{ background: brandKitBackground }}
                borderRadius="12px"
                py={5}
                px={4}
                mb={4}
                mx={3}
                mt={1}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
            >
                <Box borderRadius="50%" overflow="hidden">
                    <Avatar src={playbook.uploadedBy.photoURL} style={{ width: 80, height: 80 }}>
                        {playbook.uploadedBy.displayName}
                    </Avatar>
                </Box>

                <Box mt={2} />

                <Typography style={{ color: '#FFF' }} variant="h6" align="center">
                    {playbook.uploadedBy.displayName} made this guidde in less than {timeSpent}{' '}
                    minute{timeSpent > 1 ? 's' : ''}.
                </Typography>

                <Box my={3} />

                <UtmLink
                    playbook={playbook}
                    params={{
                        utm_campaign: 'show-how',
                        utm_medium: 'public-bottom-banner'
                    }}
                >
                    <Button
                        color="inherit"
                        style={{
                            background: '#FFF',
                            color: '#090C10',
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 1.25
                        }}
                    >
                        SHOW ME HOW
                    </Button>
                </UtmLink>
            </Box>
        </Box>
    )
}
