import { Box, Dialog, Divider, IconButton, Typography, styled } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import Amazon from 'assets/icons/ctaLibrary/amazon.png'
import Apple from 'assets/icons/ctaLibrary/apple.png'
import Azure from 'assets/icons/ctaLibrary/azure.png'
import Git from 'assets/icons/ctaLibrary/git.png'
import Google from 'assets/icons/ctaLibrary/google.png'
import Stackoverflow from 'assets/icons/ctaLibrary/stackoverflow.png'
import Reddit from 'assets/icons/ctaLibrary/reddit.png'
import Slack from 'assets/icons/ctaLibrary/slack.png'
import Teams from 'assets/icons/ctaLibrary/teams.png'
import Windows from 'assets/icons/ctaLibrary/windows.png'
import Linkedin from 'assets/icons/ctaLibrary/linkedin.png'
import Facebook from 'assets/icons/ctaLibrary/facebook.png'
import Instagram from 'assets/icons/ctaLibrary/Instagram.png'
import Twitter from 'assets/icons/ctaLibrary/twitter.png'
import Tiktok from 'assets/icons/ctaLibrary/tiktok.png'
import Youtube from 'assets/icons/ctaLibrary/youtube.png'

import Forward from 'assets/icons/ctaLibrary/arrowforward.png'
import Backwards from 'assets/icons/ctaLibrary/arrowback.png'
import Play from 'assets/icons/ctaLibrary/play.png'
import Info from 'assets/icons/ctaLibrary/info.png'
import Help from 'assets/icons/ctaLibrary/help.png'
import Home from 'assets/icons/ctaLibrary/home.png'
import Video from 'assets/icons/ctaLibrary/autovideocam.png'
import Settings from 'assets/icons/ctaLibrary/settingstimelapse.png'
import Fastforward from 'assets/icons/ctaLibrary/fastforward.png'
import Skip from 'assets/icons/ctaLibrary/skipnext.png'

import { ReactComponent as NoLogo } from 'assets/icons/no_logo.svg'
import { ReactComponent as Upload } from 'assets/icons/upload.svg'

import { type BrandKitType } from 'app/types'

import { uuid } from 'modules'
import { useBoolean } from 'hooks'

import { FileUploader, SpacedGroup } from 'UI/Components'
import { SingleCTAImageCard } from './SingleCTAImageCard'
import { type ImageType } from './SingleCTA'

const Container = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'flex-start'
})

const generateStyles = (isActive?: boolean) => ({
    border: `1px solid rgba${isActive ? '(203, 0, 0, 1)' : '(9, 12, 16, 0.08)'}`,
    borderRadius: 4,
    background: isActive ? '#FAE5E5' : 'transparent',
    cursor: isActive ? 'auto' : 'pointer',
    transition: 'background-color 0.2s ease',
    width: 90,
    height: 55,
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const logoOptions = [
    { icon: Amazon, name: 'Amazon' },
    { icon: Azure, name: 'Azure' },
    { icon: Google, name: 'Google' },
    { icon: Slack, name: 'Slack' },
    { icon: Teams, name: 'Teams' },
    { icon: Apple, name: 'Apple' },
    { icon: Windows, name: 'Windows' },
    { icon: Reddit, name: 'Reddit' },
    { icon: Git, name: 'Git' },
    { icon: Stackoverflow, name: 'Stack overflow' },
    { icon: Linkedin, name: 'Linkedin' },
    { icon: Facebook, name: 'Facebook' },
    { icon: Instagram, name: 'Instagram' },
    { icon: Twitter, name: 'Twitter' },
    { icon: Tiktok, name: 'Tiktok' },
    { icon: Youtube, name: 'Youtube' }
]

const actionOptions = [
    { icon: Forward, name: 'Forward' },
    { icon: Backwards, name: 'Backwards' },
    { icon: Play, name: 'Play' },
    { icon: Info, name: 'Info' },
    { icon: Help, name: 'Help' },
    { icon: Home, name: 'Home' },
    { icon: Video, name: 'Video' },
    { icon: Settings, name: 'Settings' },
    { icon: Fastforward, name: 'Fastforward' },
    { icon: Skip, name: 'Skip' }
]

type Props = {
    brandKitData: BrandKitType | undefined
    activeStepId: string
    uid: string
    playbookId: string
    isOpen: boolean
    onClose: () => void
    onDone: (url: string, imageType: ImageType) => void
}

export const SingleCTALibrary = ({
    isOpen,
    onClose,
    onDone,
    brandKitData,
    activeStepId,
    uid,
    playbookId
}: Props) => {
    const customImageUploading = useBoolean()

    const handleClick = (icon: string, imageType: ImageType) => {
        onDone(icon, imageType)
        onClose()
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <SpacedGroup alignItems="center" justifyContent="space-between" py={2} px={2}>
                <Typography
                    sx={{ fontWeight: 600, fontSize: '20px' }}
                    data-test="cta-library-dialog-title"
                >
                    Choose image
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    data-test="share-dialog-close-button"
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </SpacedGroup>

            <Box ml={2} mb={1} fontWeight="700" fontSize="14px" letterSpacing="0.25px">
                Logos
            </Box>
            <Container ml={2} mb={1.5}>
                {brandKitData?.logoAndFavicon.data?.map(logo => {
                    return (
                        <SingleCTAImageCard
                            key={logo.id}
                            onClick={async () => {
                                handleClick(logo.url, 'exiting')
                            }}
                        >
                            <img
                                style={{ objectFit: 'contain' }}
                                src={logo.url}
                                width={66}
                                height={18}
                            />
                        </SingleCTAImageCard>
                    )
                })}
            </Container>

            <Box ml={2} my={1} fontWeight="700" fontSize="14px" letterSpacing="0.25px">
                Social
            </Box>
            <Container ml={2} mb={1.5}>
                {logoOptions.map(option => {
                    return (
                        <SpacedGroup
                            justifyContent="center"
                            width={88}
                            height={54}
                            key={option.name}
                            title={option.name}
                            onClick={() => handleClick(option.icon, 'exiting')}
                            sx={{
                                border: '1px solid rgba(9, 12, 16, 0.08)',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            <img src={option.icon} alt={option.name} />
                        </SpacedGroup>
                    )
                })}
            </Container>

            <Box ml={2} my={1} fontWeight="700" fontSize="14px" letterSpacing="0.25px">
                Gallery
            </Box>

            <Container ml={2} mb={2.5}>
                {actionOptions.map(option => {
                    return (
                        <SpacedGroup
                            justifyContent="center"
                            width={88}
                            height={54}
                            key={option.name}
                            title={option.name}
                            onClick={() => handleClick(option.icon, 'exiting')}
                            sx={{
                                border: '1px solid rgba(9, 12, 16, 0.08)',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            <img src={option.icon} alt={option.name} />
                        </SpacedGroup>
                    )
                })}
            </Container>

            <Box mx={2}>
                <Divider />
            </Box>

            <SpacedGroup ml={2} my={2}>
                {/*No logo*/}
                <SingleCTAImageCard
                    onClick={() => {
                        handleClick('', 'exiting')
                    }}
                >
                    <Box alignContent="center" textAlign="center">
                        <NoLogo />
                        <br />
                        <Box fontSize={12} fontWeight={400} p={0} m={0} mt={0.5}>
                            No logo
                        </Box>
                    </Box>
                </SingleCTAImageCard>
                {/*Upload*/}
                <SpacedGroup
                    width={88}
                    height={54}
                    alignContent="center"
                    justifyContent="center"
                    style={{
                        border: '1px solid',
                        borderColor: 'rgba(9, 12, 16, 0.08)',
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                >
                    <FileUploader
                        accept="image/*"
                        onProgressChange={progress => customImageUploading.set(Boolean(progress))}
                        onDone={url => {
                            handleClick(url, 'custom')
                            customImageUploading.setFalse()
                        }}
                        previewStyle={{
                            ...generateStyles(true),
                            objectFit: 'contain'
                        }}
                        previewWidth={50}
                        filename={`${playbookId}_${uuid()}`}
                        isTempStorage
                        storagePath={`imageUploads/${uid}/${playbookId}/cta/${activeStepId}/${uuid()}`}
                        hideAttachFile
                        hidePreview
                        progressStyle={{
                            position: 'absolute',
                            left: 20
                        }}
                        labelComponent={
                            <Box alignContent="center" textAlign="center">
                                <Upload />
                                <br />
                                <Box fontSize={12} fontWeight={400} p={0} m={0} mt={0.5}>
                                    Upload image
                                </Box>
                            </Box>
                        }
                    />
                </SpacedGroup>
            </SpacedGroup>
        </Dialog>
    )
}
