import { type CSSProperties, type ReactNode } from 'react'

import { SpacedGroup } from 'UI/Components'

type Props = {
    children: ReactNode
    onClick: () => void
    style?: CSSProperties
}

export const SingleCTAImageCard = ({ children, onClick, style = {} }: Props) => (
    <SpacedGroup
        width={88}
        height={54}
        alignContent="center"
        justifyContent="center"
        sx={{
            border: '1px solid rgba(9, 12, 16, 0.08)',
            borderRadius: '4px',
            cursor: 'pointer'
        }}
        onClick={onClick}
        style={style}
    >
        {children}
    </SpacedGroup>
)
