import { Box, Card, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledCard = styled(Card, { shouldForwardProp: prop => prop !== 'isActive' })<{
    isActive: boolean
}>(({ theme, isActive }) => ({
    height: 56,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    border: '1px solid rgba(9, 12, 16, 0.08)',
    transition: 'box-shadow 0.2s ease-in-out',
    ...(isActive
        ? {
              background: '#FAE5E5',
              border: '1px solid #CB0000',
              '& ~ p, & > svg': {
                  color: '#090C10'
              }
          }
        : {}),
    '&:hover': {
        boxShadow: theme.shadows[3]
    }
}))

type Props = {
    isActive: boolean
    label: string
    image: string
    onClick: () => void
}

export const TransitionCard = ({ isActive, label, image, onClick }: Props) => {
    return (
        <Box>
            <StyledCard isActive={isActive} onClick={onClick}>
                <img
                    src={image}
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'cover' }}
                    alt={label}
                />
            </StyledCard>
            <Typography
                sx={{
                    color: '#090C1099',
                    textAlign: 'center'
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}
