import { uuid } from 'short-uuid'

import { styled, Box, Dialog, IconButton, LinearProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { LinkForm } from './LinkForm'

import { useAuth, useBoolean } from 'hooks'

import { type PlaybookType, type PlaybookLinkType } from 'app/types'

const PREFIX = 'AddLinkDialog'

const classes = {
    title: `${PREFIX}-title`,
    closeIcon: `${PREFIX}-closeIcon`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.title}`]: {
        fontSize: '1.5rem',
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold
    },

    [`& .${classes.closeIcon}`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5)
    }
}))

type Props = {
    /**
     * Playbook object
     */
    playbook: PlaybookType
    /**
     * Whether the dialog should be open or not
     */
    open: boolean
    /**
     * Handle closing dialog event
     */
    onClose: () => void
    /**
     * Update links
     */
    updateLinks: (playbookId: string, links: Array<PlaybookLinkType>) => Promise<void>
}

export const AddLinkDialog = ({ playbook, open, onClose, updateLinks }: Props) => {
    const { uid } = useAuth()

    const { setTrue: startLoading, setFalse: endLoading, isTrue: isLoading } = useBoolean(false)

    const onSubmit = ({ name, url }: { name: string | null; url: string }) => {
        startLoading()

        const links: Array<PlaybookLinkType> = [
            ...(playbook.links ? playbook.links : []),
            { id: uuid(), name, uid, url }
        ]

        updateLinks(playbook.id, links).then(() => {
            endLoading()
            onClose()
        })
    }

    return (
        <StyledDialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            {isLoading && (
                <Box height="4px" mb={1}>
                    <LinearProgress />
                </Box>
            )}
            <IconButton
                aria-label="close"
                className={classes.closeIcon}
                onClick={onClose}
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <LinkForm onClose={onClose} onSubmit={onSubmit} />
        </StyledDialog>
    )
}
