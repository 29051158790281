import { useState, memo, useEffect } from 'react'

import { Button, Typography, Box, Checkbox } from '@mui/material'

import { CommonDialog } from 'UI/Components'

import { type OptionType } from 'modules'

type Props = {
    isOpen: boolean
    options: Array<OptionType>
    onClose: () => void
    onConfirm: (list: Array<string>) => void
    selectedOptions: Array<string>
}

type SelectedList = {
    [key: string]: boolean
}

const fakeOption = 'ALL'

const generateState = (
    options: Array<OptionType>,
    selectedOptions: Array<string>,
    state = false
) => {
    return options.reduce(
        (acc: SelectedList, { value }: OptionType) => ({
            ...acc,
            [value]: value === fakeOption ? !state : state || selectedOptions.includes(value)
        }),
        {}
    )
}

export const FilterDialog = memo(
    ({ isOpen, options, selectedOptions, onClose, onConfirm }: Props) => {
        const [items, setItems] = useState<SelectedList>(generateState(options, selectedOptions))

        useEffect(() => {
            setItems(generateState(options, selectedOptions))
        }, [options, selectedOptions])

        const handleConfirm = () => {
            const selectedList = Object.keys(items)
                .filter(x => items[x])
                .filter(item => item !== fakeOption)

            onConfirm(selectedList)
            onClose()
        }

        return (
            <CommonDialog
                isOpen={isOpen}
                title="Filter by"
                onClose={onClose}
                onConfirm={handleConfirm}
                confirmBtnText="Show"
                justifyActions="space-between"
                showDivider={true}
                hideCloseBtn={true}
                extraActions={
                    <Button
                        size="medium"
                        variant="contained"
                        onClick={() => {
                            setItems(generateState(options, []))
                            onConfirm(['ALL'])
                            onClose()
                        }}
                    >
                        Clear all
                    </Button>
                }
                content={
                    <>
                        {options.map(({ value, label }, i) => (
                            <Box
                                key={value + i + label}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        mr: 1
                                    }}
                                >
                                    {label}
                                </Typography>

                                <Checkbox
                                    checked={Boolean(items[value])}
                                    value={value}
                                    onChange={(_e, checked) => {
                                        if (value === fakeOption) {
                                            setItems(
                                                generateState(
                                                    options,
                                                    checked ? [] : selectedOptions,
                                                    !checked
                                                )
                                            )

                                            return
                                        }
                                        setItems(prev => ({
                                            ...prev,
                                            [value]: checked
                                        }))
                                    }}
                                    color="primary"
                                />
                            </Box>
                        ))}
                    </>
                }
            />
        )
    }
)
