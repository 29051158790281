import { Typography } from '@mui/material'
import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'
import { type Plan } from '@stigg/js-client-sdk'

import { calcDiscount, roundPrice } from 'hooks'

import { SpacedGroup } from 'UI/Components'

const priceStyles = { fontSize: '32px', fontWeight: 600, lineHeight: 1 }
/**
 * Render plans price text label according to its pricePoints field
 * @param prices
 * @returns Jsx text
 */
export const getPriceLabel = (
    prices: Plan['pricePoints'],
    isCustomPrice: boolean,
    period: BillingPeriod
) => {
    let priceLabel = <Typography style={priceStyles}>Contact us</Typography>
    let annualBenefit
    let benefit
    const monthlyPrice = prices.find(({ billingPeriod }) => billingPeriod === BillingPeriod.Monthly)
    const annuallyPrice = prices.find(
        ({ billingPeriod }) => billingPeriod === BillingPeriod.Annually
    )

    if (isCustomPrice) return { priceLabel, annualBenefit }

    if (monthlyPrice?.amount === 0 || annuallyPrice?.amount === 0) {
        priceLabel = (
            <SpacedGroup spacing={1} alignItems="flex-end">
                <Typography style={priceStyles}>$0</Typography>
                <Typography variant="caption"> per user / month</Typography>
            </SpacedGroup>
        )
    } else if ((monthlyPrice && annuallyPrice) || annuallyPrice) {
        priceLabel = (
            <SpacedGroup spacing={1} alignItems="flex-end">
                <Typography style={priceStyles}>
                    $
                    {roundPrice(
                        period === BillingPeriod.Monthly
                            ? Number(monthlyPrice?.amount)
                            : annuallyPrice.amount / 12
                    )}
                </Typography>
                <Typography variant="caption"> per user / month</Typography>
            </SpacedGroup>
        )
    }

    if (monthlyPrice && annuallyPrice) {
        benefit = calcDiscount(annuallyPrice.amount, monthlyPrice.amount)
        annualBenefit = <>paid annually (save {benefit}%)</>
    }

    return { priceLabel, annualBenefit, benefit }
}

/**
 * Makes screen switching in code more readable
 */
export const SCREEN = {
    SelectPlan: 0,
    SelectSeats: 1,
    PaymentConfirmation: 2
}
