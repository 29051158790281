import { useMemo } from 'react'
import { collection } from 'firebase/firestore'

import { useAuth } from 'hooks'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db } from 'modules'

export type CustomerType = {
    accountOwners: string
    domains: Array<string>
    id: string
    logo: string
    name: string
    ownerAgency: string
}

export const useCustomersDomains = () => {
    const { orgId } = useAuth()

    const [data = []] = useCollectionData(collection(db, `usersPerOrg/${orgId}/customers`))

    const customers = data as Array<CustomerType>

    const domains = useMemo(
        () =>
            customers
                ?.filter(Boolean)
                .map(customer => customer.domains)
                ?.flat(),
        [customers]
    )

    return useMemo(() => ({ customers, domains }), [customers, domains])
}
