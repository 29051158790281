import { type ReactNode, useState } from 'react'

import { Elements } from '@stripe/react-stripe-js'

import { Box, Button, CircularProgress, Typography } from '@mui/material'

import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg'
import { ReactComponent as MastercardIcon } from 'assets/icons/mastercard.svg'
import { ReactComponent as UnionpayIcon } from 'assets/icons/unionpay.svg'
import { ReactComponent as DiscoverIcon } from 'assets/icons/discover.svg'

import { ReactComponent as GuiddeIcon } from 'assets/icons/icon-g.svg'

import { CheckoutFormButton, SpacedGroup, PaymentErrorDialog, CheckoutForm } from 'UI/Components'

import { type UseBooleanType, useBoolean, usePayment, useWindowView } from 'hooks'
import { logToAnalytics } from 'modules'

export const cardIcons: { [key: string]: ReactNode } = {
    visa: <VisaIcon />,
    mastercard: <MastercardIcon />,
    unionpay: <UnionpayIcon />,
    discover: <DiscoverIcon />
}

type Props = {
    confirmPlanUpdate: () => Promise<void>
    isLoading: boolean
    paymentInProgress: UseBooleanType
}

export const PreselectedCardCheckout = ({
    isLoading,
    paymentInProgress,
    confirmPlanUpdate
}: Props) => {
    const {
        paymentMethod,
        submitStripeForm,
        startCardUpdate,
        updateCardSecret,
        stripePromise,
        formIsReady,
        isLoading: paymentCardIsLoading
    } = usePayment()

    const { isMobileView } = useWindowView()
    const changeCard = useBoolean()

    const [errorMessage, setErrorMessage] = useState('')
    const paymentError = useBoolean()

    if (changeCard.isTrue) {
        if (!formIsReady) {
            return (
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="250px"
                >
                    <Box position="relative">
                        <GuiddeIcon
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -65%)'
                            }}
                        />
                        <CircularProgress size={55} />
                    </Box>
                </Box>
            )
        }

        return (
            <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                minHeight={355}
            >
                <Box>
                    <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                        Payment information
                    </Typography>

                    <Box flexGrow={1} display="flex" alignItems="stretch" mt={4}>
                        <Elements
                            stripe={stripePromise}
                            options={{
                                clientSecret: updateCardSecret,
                                loader: 'never'
                            }}
                        >
                            <CheckoutForm
                                submitStripeForm={submitStripeForm}
                                onSuccess={() => {
                                    logToAnalytics('payment_card_changed')
                                    changeCard.setFalse()
                                }}
                                onError={e => {
                                    const errorMessage =
                                        e.error?.message ||
                                        `Something went wrong when adding a new card. Please, try again`

                                    setErrorMessage(errorMessage)
                                    paymentError.setTrue()
                                    logToAnalytics('payment_card_change_failed', { errorMessage })
                                }}
                                isLoading={paymentCardIsLoading}
                            />
                        </Elements>
                    </Box>
                    {paymentError.isTrue && (
                        <PaymentErrorDialog
                            onClose={paymentError.setFalse}
                            isOpen={paymentError.isTrue}
                            onClick={paymentError.setFalse}
                            errorMessage={errorMessage}
                        />
                    )}
                </Box>
            </Box>
        )
    }

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight={isMobileView ? 150 : 355}
        >
            <Box>
                <Typography
                    data-test="paymentForm-preSelectedCard-title-text"
                    style={{ fontSize: 20, fontWeight: 600 }}
                >
                    Payment information
                </Typography>
                <Box
                    mt={3}
                    border="1px solid rgba(9, 12, 16, 0.08)"
                    borderRadius="8px"
                    px={1}
                    py={1.1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Typography color="textSecondary" variant="caption">
                        Pay with
                    </Typography>

                    <SpacedGroup spacing={1.1}>
                        <Box
                            bgcolor="#E8E8E8"
                            width={50}
                            height={30}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="2px"
                        >
                            {cardIcons[paymentMethod.cardBrand]}
                        </Box>
                        <Typography variant="caption" style={{ fontWeight: 500 }}>
                            •••• {paymentMethod.last4}
                        </Typography>
                    </SpacedGroup>
                    <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={() => {
                            startCardUpdate()
                            changeCard.setTrue()
                        }}
                    >
                        Change
                    </Button>
                </Box>
            </Box>

            <CheckoutFormButton
                disabled={isLoading}
                onClick={() => {
                    paymentInProgress.setTrue()
                    confirmPlanUpdate().finally(paymentInProgress.setFalse)
                }}
                submitBtnName="Upgrade"
            />
        </Box>
    )
}
