import { LinearProgress, Box, Typography } from '@mui/material'

import { ReactComponent as GuiddeLogo } from 'assets/icons/guidde_red.svg'
import { useWindowView } from 'hooks'

type Props = {
    message?: string
}

export const GuiddeLoad = ({ message }: Props) => {
    const { isMobileView } = useWindowView()

    return (
        <Box
            px={isMobileView ? 5 : 0}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                // 48px - sum of top and bottom paddings
                height: 'calc(100vh - 48px)'
            }}
        >
            <GuiddeLogo />
            <Box my={2}>
                <Typography>{message || 'Loading...'}</Typography>
            </Box>
            <Box width="300px">
                <LinearProgress />
            </Box>
        </Box>
    )
}
