import { useCallback, useMemo } from 'react'

import { type AnyPlaybookType } from 'app/types'

import { useAuth, useNotification, useCustomerDomain } from 'hooks'
import { getPlaybookPath, isQG, logToAnalytics, playbookToAnalyticsProps } from 'modules'

export const getPlaybookPreviewUrl = (playbook: AnyPlaybookType) => {
    if ('smallPreviewUrl' in playbook) return playbook.smallPreviewUrl

    return playbook.gifUrl || playbook.previewUrl
}

export const useCopyLinkedGif = () => {
    const { uid } = useAuth()
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const { generateDomain } = useCustomerDomain()

    const copyToClipboard = useCallback(
        (html: string) => {
            const container = document.createElement('div')
            container.innerHTML = html
            container.style.position = 'fixed'
            container.style.pointerEvents = 'none'
            container.style.opacity = '0'
            document.body.appendChild(container)
            window?.getSelection()?.removeAllRanges()
            const range = document.createRange()
            range.selectNode(container)
            window?.getSelection()?.addRange(range)
            document.execCommand('copy')
            document.body.removeChild(container)
            showSuccessNotification('Linked GIF was copied to the clipboard')
        },
        [showSuccessNotification]
    )

    const logToAnalyticsCopyGifEvent = useCallback((playbook: AnyPlaybookType) => {
        const context = isQG(playbook) ? 'copy-linked-gif-qg' : 'copy-linked-gif'

        logToAnalytics('copyLink', {
            track_name: '',
            track_domain: '',
            context,
            ...playbookToAnalyticsProps(playbook)
        })
    }, [])

    const copyLinkedGif = useCallback(
        (
            playbook: AnyPlaybookType,
            trackLinkName?: string,
            trackLinkId?: string,
            trackLink?: string
        ) => {
            try {
                const path = getPlaybookPath(playbook)

                const urlParams = new URLSearchParams()

                if (trackLinkName) {
                    urlParams.append('track_link_name', trackLinkName)
                }
                if (trackLinkId) {
                    urlParams.append('track_link_id', trackLinkId)
                }

                urlParams.append('origin', uid)

                const domain = generateDomain(playbook)

                const link = `${domain}${path}?${urlParams.toString()}`

                const imageHtml = `
                <table bgcolor="white" style="background-color: white" width="100%">
                    <style>
                        img {
                            max-width: 480px;
                            objectFit: contain;
                            borderRadius: 2px;
                        }
                    </style>
                  <tr>
                    <td>
                    <p>Check out 👀
                        <a style="text-decoration: underline" href=${link}> ${playbook.title}</a>
                    </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href=${link}>
                        <img width="100%" height="auto" alt="" src=${getPlaybookPreviewUrl(
                            playbook
                        )} />
                      </a>
                    </td>
                  </tr>
                  ${trackLink ? `<tr><td><a href=${trackLink}>Track link</a></td></tr>` : ''}
                </table>`

                window.copiedGIF = imageHtml

                logToAnalyticsCopyGifEvent(playbook)
                copyToClipboard(imageHtml)
            } catch (error) {
                showErrorNotification(error)
            }
        },
        [uid, generateDomain, showErrorNotification, copyToClipboard, logToAnalyticsCopyGifEvent]
    )

    return useMemo(
        () => ({ copyLinkedGif, logToAnalyticsCopyGifEvent }),
        [copyLinkedGif, logToAnalyticsCopyGifEvent]
    )
}
