import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useReactMediaRecorder } from 'react-media-recorder-2'

import fixWebmDuration from 'fix-webm-duration'

import { Box, Link, Stack, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

import { SpacedGroup } from 'UI/Components'

import { VolumeMeter } from './VolumeMeter'
import { MicSelection } from './MicSelection'
import { Recorder } from './Recorder'
import { Player } from './Player'

import { useNotification } from 'hooks'
import { type OptionType, logToAnalytics, getAudioDuration } from 'modules'

import { setTempAudioNote } from 'ducks'
import { updateUserPreferences } from 'ducks/reducers'

import { links } from 'app/links'

let interval: ReturnType<typeof setInterval>

type Props = {
    language: OptionType | null
}

export const AudioRecorder = ({ language }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { showErrorNotification } = useNotification()

    const { s2tBanner } = useSelector(state => state.configs.userPreferences)
    const { audioConfigs } = useSelector(state => state.configs)
    const { activeStep, steps } = useSelector(state => state.qgEditor.present)

    const { tempAudioNote } = steps[activeStep]

    const [deviceId, setDeviceId] = useState('')
    const [timer, setTimer] = useState<number>(0)

    const { startRecording, stopRecording, status, error } = useReactMediaRecorder({
        audio: { deviceId },
        blobPropertyBag: { type: 'audio/webm' },
        askPermissionOnMount: false,
        stopStreamsOnStop: true,
        onStart: () => {
            // to prevent auto record again
            dispatch(setTempAudioNote())
            history.replaceState('', '')

            // Hide speech to text banner for the next time
            if (s2tBanner) updateUserPreferences('s2tBanner', false)

            interval = setInterval(() => {
                setTimer(prevState => {
                    if (prevState >= audioConfigs.s2t.maxDuration) {
                        stopRecording()
                        return prevState
                    }

                    return (prevState || 0) + 1
                })
            }, 1000)
        },
        onStop: async (_url, blob) => {
            window.clearInterval(interval)
            setTimer(0)

            const durationInMs = (await getAudioDuration(_url)) * 1000
            const fixedBlob = await fixWebmDuration(blob, durationInMs, { logger: false })

            dispatch(
                setTempAudioNote({
                    type: 'speechToText',
                    blobFile: fixedBlob,
                    languageCode: language?.value || '',
                    languageName: language?.label || ''
                })
            )
        }
    })

    const protectedObjectUrl = useMemo(() => {
        if (tempAudioNote?.type !== 'speechToText') return ''

        try {
            return window.URL.createObjectURL(tempAudioNote.blobFile)
        } catch (error) {
            dispatch(setTempAudioNote())

            return ''
        }
    }, [tempAudioNote, dispatch])

    // When user clicks on 'restart recording' from VoiceOverPreview,
    // we need to start recording automatically
    useEffect(() => {
        if (history.state.startRecording && language) {
            startRecording()
        }
        // Prevent unnecessary re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    useEffect(() => {
        if (error === 'permission_denied') {
            showErrorNotification('You should allow mic to record')
        }
    }, [error, showErrorNotification])

    if (error) {
        return (
            <SpacedGroup mt={2} p={2} bgcolor="#cd0000" borderRadius={4} color="white">
                <ErrorIcon />
                <Typography>
                    Couldn't access microphone. Please visit our help center in this{' '}
                    <Link style={{ color: 'white' }} target="_blank" href={links.helpMicrophone}>
                        <b>link</b>
                    </Link>
                </Typography>
            </SpacedGroup>
        )
    }

    return (
        <>
            <Box mt={1} p={2} bgcolor="#F5F5F5" borderRadius="4px">
                <Stack spacing={3} alignItems="center" justifyContent="center">
                    <MicSelection deviceId={deviceId} onChange={setDeviceId} />
                    {status === 'recording' && <VolumeMeter deviceId={deviceId} />}

                    {tempAudioNote && tempAudioNote?.type === 'speechToText' ? (
                        <Player
                            audioUrl={protectedObjectUrl}
                            onRestart={() => {
                                startRecording()
                                logToAnalytics('voiceover_restart', { playbookId, activeStep })
                            }}
                            onDelete={() => {
                                dispatch(setTempAudioNote())
                                logToAnalytics('voiceover_delete', { playbookId, activeStep })
                            }}
                            onRewind={() =>
                                logToAnalytics('voiceover_recordTab_rewind_clicked', { playbookId })
                            }
                            onForward={() =>
                                logToAnalytics('voiceover_recordTab_forward_clicked', {
                                    playbookId
                                })
                            }
                        />
                    ) : (
                        <Recorder
                            status={status}
                            timer={timer}
                            startRecording={() => {
                                startRecording()
                                logToAnalytics('voiceover_start', { playbookId, activeStep })
                            }}
                            stopRecording={() => {
                                stopRecording()
                                logToAnalytics('voiceover_stop', { playbookId, activeStep })
                            }}
                            onRestart={() => {
                                stopRecording()
                                startRecording()
                                logToAnalytics('voiceover_restart', { playbookId, activeStep })
                            }}
                        />
                    )}
                </Stack>
            </Box>
        </>
    )
}
