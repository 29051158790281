import { type ReactNode } from 'react'

import { Box } from '@mui/material'

type Props = {
    children: ReactNode
}

export const VideoSize = ({ children }: Props) => {
    return (
        <Box
            margin="0 auto"
            id="videoContainer"
            sx={theme => ({
                [theme.breakpoints.up('md')]: {
                    width: '90%'
                },
                [theme.breakpoints.up('lg')]: {
                    width: '76%'
                },
                [theme.breakpoints.up('xl')]: {
                    width: '60%'
                }
            })}
        >
            {children}
        </Box>
    )
}
