import { type FunctionComponent, type SVGProps, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Stack, Tooltip } from '@mui/material'

import { ReactComponent as FormatBoldIcon } from 'assets/icons/format_bold.svg'
import { ReactComponent as FormatItalicIcon } from 'assets/icons/format_italic.svg'
import { ReactComponent as FormatUnderlineIcon } from 'assets/icons/format_underlined.svg'

import { type TextShapeType } from 'app/types'
import { setFontStyles } from 'ducks'
import { useBrandKit } from 'hooks'

type Props = {
    currentLayer: TextShapeType
}

export const FontStyles = ({ currentLayer }: Props) => {
    const dispatch = useDispatch()

    const { brandKitFont } = useBrandKit()

    const { textDecoration, fontStyle } = currentLayer

    const handleBold = useCallback(() => {
        if (!brandKitFont?.bold) return

        const newValue = fontStyle.includes('bold')
            ? fontStyle.replace('bold', '').trim()
            : ('bold ' + fontStyle).trim()

        dispatch(setFontStyles({ fontStyle: newValue }))
    }, [brandKitFont?.bold, dispatch, fontStyle])

    const handleItalic = useCallback(() => {
        if (!brandKitFont?.italic) return

        const newValue = fontStyle.includes('italic')
            ? fontStyle.replace('italic', 'normal')
            : (fontStyle.replace('normal', '') + ' italic').trim()

        dispatch(setFontStyles({ fontStyle: newValue }))
    }, [brandKitFont?.italic, dispatch, fontStyle])

    const handleUnderline = useCallback(() => {
        const newValue = textDecoration === 'underline' ? 'none' : 'underline'
        dispatch(setFontStyles({ textDecoration: newValue }))
    }, [dispatch, textDecoration])

    useEffect(() => {
        const shortcutsHandler = (e: KeyboardEvent) => {
            const ctrl = e.ctrlKey || e.metaKey
            // Bold
            if (ctrl && e.key === 'b') handleBold()
            // Italic
            if (ctrl && e.key === 'i') handleItalic()
            // Underline
            if (ctrl && e.key === 'u') handleUnderline()
        }

        document.body.addEventListener('keydown', shortcutsHandler)
        return () => document.body.removeEventListener('keydown', shortcutsHandler)
    }, [dispatch, handleBold, handleItalic, handleUnderline])

    return (
        <Stack spacing={0} height="100%" direction="row" alignItems="center">
            {brandKitFont?.bold && (
                <IconButton
                    icon={FormatBoldIcon}
                    isActive={fontStyle.includes('bold')}
                    tooltip="Bold (Ctrl+B)"
                    onClick={handleBold}
                />
            )}

            {brandKitFont?.italic && (
                <IconButton
                    icon={FormatItalicIcon}
                    tooltip="Italic (Ctrl+I)"
                    isActive={fontStyle.includes('italic')}
                    onClick={handleItalic}
                />
            )}

            <IconButton
                icon={FormatUnderlineIcon}
                tooltip="Underline (Ctrl+U)"
                isActive={textDecoration === 'underline'}
                onClick={handleUnderline}
            />
        </Stack>
    )
}

type IconButtonProps = {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>
    tooltip: string
    isActive: boolean
    disabled?: boolean
    onClick: () => void
}

const IconButton = ({ icon, tooltip, isActive, onClick, disabled }: IconButtonProps) => {
    const Icon = icon

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            bgcolor={isActive ? 'rgba(203, 0, 0, 0.1)' : ''}
        >
            <Tooltip title={tooltip}>
                <Icon
                    fontSize="small"
                    style={{
                        ...(disabled && { opacity: 0.4, pointerEvents: 'none' }),
                        color: isActive ? '#CB0000' : '#000000',
                        cursor: 'pointer',
                        height: '100%'
                    }}
                    onClick={onClick}
                />
            </Tooltip>
        </Box>
    )
}
