import {
  BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT,
  BooleanEntitlement,
  Coupon,
  Customer,
  Entitlement,
  GetBooleanEntitlement,
  GetMeteredEntitlement,
  GetNumericEntitlement,
  GetPaywall,
  METERED_DEFAULT_FALLBACK_ENTITLEMENT,
  MeteredEntitlement,
  NUMERIC_DEFAULT_FALLBACK_ENTITLEMENT,
  NumericEntitlement,
  Subscription,
  WaitForCheckoutCompleted,
  CustomerPortal,
  Paywall,
  EstimateSubscription,
  SubscriptionPreview,
  EstimateSubscriptionUpdate,
  GetCustomerPortal,
  GetActiveSubscriptions,
} from './models';
import ApiGateway from './api/ApiGateway';
import {
  ClientConfiguration,
  ensureCustomerRefIdExists,
  getConfiguration,
  validateConfiguration,
} from './configuration';
import { ModelMapper } from './utils/ModelMapper';
import { mapGetEntitlementsFallback } from './utils/mapGetEntitlementsFallback';
import initApolloClient, { initBatchedApolloClient } from './api/initApolloClient';
import { InMemoryCacheService } from './services/inMemoryCacheService';
import { EntitlementsService } from './services/entitlementsService';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { LoggerService } from './services/loggerService';
import { initSentry, Sentry } from './services/sentryService';
import { InitializationStateTracker } from './services/initializationStateTracker';
import { withErrorHandlingAsync, withErrorHandling } from './utils/withErrorHandling';
import { EventNames, Events, TypedEventEmitter } from './services/eventEmitter';
import { isString } from 'lodash';
import { GetPaywallProps } from './api/PaywallApi';
import { EdgeApiClient } from './api/EdgeApiClient';
import { ErrorCode } from '@stigg/api-client-js/src/generated/sdk';

export interface StiggClient {
  isCustomerLoaded: boolean;
  isResourceLoaded: boolean;
  isWidgetWatermarkEnabled: boolean;
  getBooleanEntitlement: (params: GetBooleanEntitlement) => BooleanEntitlement;
  getNumericEntitlement: (params: GetNumericEntitlement) => NumericEntitlement;
  getMeteredEntitlement: (params: GetMeteredEntitlement) => MeteredEntitlement;
  setCustomerId: (customerId: string, customerToken?: string | null, resourceId?: string | null) => Promise<void>;
  clearCustomer: () => void;
  setResource: (resourceId: string) => Promise<void>;
  clearResource: () => void;
  getPaywall: (params?: GetPaywall) => Promise<Paywall>;
  getCustomer: () => Promise<Customer>;
  getActiveSubscriptions: (params?: GetActiveSubscriptions) => Promise<Subscription[]>;
  getCoupons(): Promise<Coupon[]>;
  getEntitlements: (resourceId?: string) => Promise<Entitlement[]>;
  refresh: () => Promise<void>;
  waitForCheckoutCompleted(params?: WaitForCheckoutCompleted): Promise<Subscription | null>;
  waitForInitialization(): Promise<StiggClient>;
  addListener: <K extends EventNames>(eventName: K, callback: Events[K]) => void;
  removeListener: <K extends EventNames>(eventName: K, callback: Events[K]) => void;
  getCustomerPortal(params?: GetCustomerPortal): Promise<CustomerPortal>;
  estimateSubscription(estimateSubscription: EstimateSubscription): Promise<SubscriptionPreview>;
  estimateSubscriptionUpdate(estimateSubscriptionUpdate: EstimateSubscriptionUpdate): Promise<SubscriptionPreview>;
}
const mockDataProductId = 'stiggTestData';

export class Stigg implements StiggClient {
  private readonly apiGateway: ApiGateway;
  private readonly edgeApiClient: EdgeApiClient | null;
  private readonly graphClient: ApolloClient<NormalizedCacheObject>;
  private readonly batchedGraphClient: ApolloClient<NormalizedCacheObject>;
  private readonly configuration: Required<ClientConfiguration>;
  private readonly loggerService: LoggerService;
  private globalEntitlementsService: EntitlementsService | null;
  private resourceEntitlementsService: EntitlementsService | null;
  private readonly modelMapper = new ModelMapper();
  private readonly eventEmitter = new TypedEventEmitter();
  private readonly initializationStateTracker: InitializationStateTracker;
  private showWidgetsWatermark = false;

  private constructor(configuration: Required<ClientConfiguration>, loggerService: LoggerService) {
    this.configuration = configuration;
    this.edgeApiClient = EdgeApiClient.create(configuration, configuration);
    this.graphClient = initApolloClient(this.configuration);
    this.batchedGraphClient = initBatchedApolloClient(this.configuration);
    this.loggerService = loggerService;
    this.apiGateway = new ApiGateway(this.graphClient, this.edgeApiClient);
    this.globalEntitlementsService = null;
    this.resourceEntitlementsService = null;
    this.initializationStateTracker = new InitializationStateTracker(this.eventEmitter);
  }

  /**
   * Creates an instance of Stigg client.
   *
   * @param configuration configuration settings.
   * @returns The new client instance.
   */
  static initialize(configuration: ClientConfiguration): StiggClient {
    const sdkConfiguration = getConfiguration(configuration);
    const loggerService = new LoggerService(
      sdkConfiguration.logConfiguration.logger,
      sdkConfiguration.logConfiguration.logLevel,
    );

    validateConfiguration(sdkConfiguration, loggerService);

    const stigg = new Stigg(sdkConfiguration, loggerService);

    this.initializeSdk(stigg)
      .then(() => {
        if (sdkConfiguration.customerId) {
          if (!sdkConfiguration.lazyLoad) {
            void stigg.setCustomerId(
              sdkConfiguration.customerId,
              sdkConfiguration.customerToken,
              sdkConfiguration.resourceId,
            );
          }
        } else {
          stigg.initializationStateTracker.signalSuccessInit();
        }
      })
      .catch((err) => stigg.initializationStateTracker.signalFailedInit(err));

    return stigg;
  }

  /**
   * Returns a Promise that tracks the client's initialization state.
   *
   * The Promise will be resolved if the client successfully initializes, or rejected if client
   * initialization has irrevocably failed.
   *
   * @returns Stigg client instance.
   */
  async waitForInitialization(): Promise<StiggClient> {
    return this.initializationStateTracker.getInitializationPromise().then(() => this);
  }

  private static async initializeSdk(stigg: Stigg) {
    try {
      const sdkConfigResponse = await stigg.apiGateway.sdkConfiguration.getSdkConfiguration();
      const sdkConfig = sdkConfigResponse.data?.sdkConfiguration;
      // don't initialize Sentry twice
      if (!Sentry.getClient() && sdkConfig?.sentryDsn) {
        void initSentry(sdkConfig.sentryDsn);
      }

      stigg.showWidgetsWatermark = !!sdkConfig?.isWidgetWatermarkEnabled;
    } catch (error: any) {
      if (
        error.response?.status === 401 ||
        (error.graphQLErrors && error.graphQLErrors[0]?.extensions?.code === ErrorCode.Unauthenticated)
      ) {
        const errorMessage = 'Authentication failed. Double check your SDK key.';
        stigg.loggerService.error(errorMessage);
        throw new Error(errorMessage);
      } else {
        stigg.loggerService.error('Failed to load sdk configuration', error);
        throw error;
      }
    }
  }

  get isCustomerLoaded(): boolean {
    return this.globalEntitlementsService?.isInitialized || false;
  }

  get isResourceLoaded(): boolean {
    return this.resourceEntitlementsService?.isInitialized || false;
  }

  get isWidgetWatermarkEnabled(): boolean {
    return this.showWidgetsWatermark;
  }

  /**
   * Add a listener to handle updates of entitlements changes
   * @param eventName
   * @param listener
   */
  addListener<K extends EventNames>(eventName: K, listener: Events[K]) {
    this.eventEmitter.on(eventName, listener);
  }

  /**
   * Remove a listener to stop handle updates of entitlements changes
   * @param eventName
   * @param listener
   */
  removeListener<K extends EventNames>(eventName: K, listener: Events[K]) {
    this.eventEmitter.off(eventName, listener);
  }

  /**
   * Set the customer ID, usually after the customer signs in or restores a session
   */
  async setCustomerId(
    customerId: string,
    customerToken: string | null = null,
    resourceId: string | null = null,
  ): Promise<void> {
    this.clearCustomer();

    if (!customerId) {
      this.loggerService.log('`setCustomerId` was called without a customerId, did you mean to call `clearCustomer`?');
      return;
    }

    if (!isString(customerId)) {
      throw new Error(`customerId parameter must be a string`);
    }

    if (customerToken && !isString(customerToken)) {
      throw new Error(`customerToken parameter must be a string`);
    }

    if (resourceId && !isString(resourceId)) {
      throw new Error(`resourceId parameter must be a string`);
    }

    this.configuration.customerId = customerId;
    this.configuration.customerToken = customerToken;
    this.configuration.resourceId = resourceId;

    this.globalEntitlementsService = new EntitlementsService(
      customerId,
      undefined,
      new InMemoryCacheService(),
      this.graphClient,
      this.batchedGraphClient,
      this.edgeApiClient,
      this.loggerService,
      (entitlements) => this.eventEmitter.emit('entitlementsUpdated', entitlements),
    );

    if (resourceId) {
      this.resourceEntitlementsService = new EntitlementsService(
        customerId,
        resourceId,
        new InMemoryCacheService(),
        this.graphClient,
        this.batchedGraphClient,
        this.edgeApiClient,
        this.loggerService,
        (entitlements) => this.eventEmitter.emit('entitlementsUpdated', entitlements),
      );
    }

    if (this.configuration.useEntitlementPolling) {
      this.globalEntitlementsService.startPolling(this.configuration.entitlementPollingInterval);

      if (this.resourceEntitlementsService) {
        this.resourceEntitlementsService.startPolling(this.configuration.entitlementPollingInterval);
      }
    }

    try {
      await withErrorHandlingAsync(
        async () => {
          await Promise.all([
            this.globalEntitlementsService?.loadEntitlements(),
            this.resourceEntitlementsService?.loadEntitlements(),
          ]);
        },
        {
          loggerService: this.loggerService,
          sdkConfiguration: this.configuration,
          errorMessage: (err) => `Failed to load initial entitlements for customer. Error: ${err.message}`,
          rethrowError: true,
        },
      );

      this.initializationStateTracker.signalSuccessInit();
    } catch (err) {
      this.initializationStateTracker.signalFailedInit(err);
    }
  }

  /**
   * Clear the customer ID, usually after the customer signs out
   */
  clearCustomer(): void {
    this.globalEntitlementsService?.stopPolling();
    this.globalEntitlementsService = null;
    this.resourceEntitlementsService?.stopPolling();
    this.resourceEntitlementsService = null;
    this.configuration.customerId = null;
    this.configuration.customerToken = null;
    this.configuration.resourceId = null;
  }

  /**
   * Set the customer's resource ID, usually after the customer select a specific resource.
   */
  async setResource(resourceId: string): Promise<void> {
    if (!this.configuration.customerId) {
      throw new Error('`setResource` must be called when customer was set, did you forget to call `setCustomerId`?');
    }

    this.clearResource();

    if (!isString(resourceId)) {
      throw new Error(`resourceId parameter must be a string`);
    }

    this.configuration.resourceId = resourceId;
    this.resourceEntitlementsService = new EntitlementsService(
      this.configuration.customerId,
      resourceId,
      new InMemoryCacheService(),
      this.graphClient,
      this.batchedGraphClient,
      this.edgeApiClient,
      this.loggerService,
      (entitlements) => this.eventEmitter.emit('entitlementsUpdated', entitlements),
    );

    if (this.configuration.useEntitlementPolling) {
      this.resourceEntitlementsService.startPolling(this.configuration.entitlementPollingInterval);
    }

    try {
      await withErrorHandlingAsync(
        async () => {
          this.resourceEntitlementsService?.loadEntitlements();
        },
        {
          loggerService: this.loggerService,
          sdkConfiguration: this.configuration,
          errorMessage: (err) => `Failed to load initial entitlements for customer resource. Error: ${err.message}`,
          rethrowError: true,
        },
      );

      this.initializationStateTracker.signalSuccessInit();
    } catch (err) {
      this.initializationStateTracker.signalFailedInit(err);
    }
  }

  /**
   * Unset the customer's resource ID, usually after the customer exit a specific resource.
   */
  clearResource(): void {
    if (!this.configuration.customerId) {
      throw new Error('`clearResource` must be called when customer was set, did you forget to call `setCustomerId`?');
    }

    this.resourceEntitlementsService?.stopPolling();
    this.resourceEntitlementsService = null;
    this.configuration.resourceId = null;
  }

  /**
   * Reload entitlements
   */
  async refresh(): Promise<void> {
    return withErrorHandlingAsync(
      async () => {
        await Promise.all([this.globalEntitlementsService?.refresh(), this.resourceEntitlementsService?.refresh()]);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to refresh entitlements. Error: ${err.message}`,
        rethrowError: true,
      },
    );
  }

  /**
   * Get boolean entitlement of feature for a customer
   *
   * @param {string} featureId
   * @param {string} resourceId
   * @param {BooleanEntitlementOptions} options
   * @return {BooleanEntitlement} boolean entitlement
   */
  getBooleanEntitlement({ featureId, options, resourceId }: GetBooleanEntitlement): BooleanEntitlement {
    const fallbackValue = options?.fallback || this.configuration.entitlementsFallback?.[featureId] || {};
    const fallbackEntitlement = {
      ...BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT,
      ...fallbackValue,
    };

    const entitlement = withErrorHandling(
      () => {
        if (!this.globalEntitlementsService || !this.isCustomerLoaded) {
          return fallbackEntitlement;
        }

        if (resourceId) {
          if (resourceId !== this.configuration.resourceId) {
            this.loggerService.log(
              `Resource ID ${resourceId} does not match the current resource ID ${this.configuration.resourceId}.`,
            );
            return fallbackEntitlement;
          } else if (!this.isResourceLoaded || !this.resourceEntitlementsService) {
            return fallbackEntitlement;
          }
          return this.resourceEntitlementsService!.getBooleanEntitlement(featureId, fallbackEntitlement, options);
        }

        return this.globalEntitlementsService.getBooleanEntitlement(featureId, fallbackEntitlement, options);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get boolean entitlement. Error: ${err.message}`,
        rethrowError: false,
        errorReportMetadata: { featureId, options },
      },
    );

    return entitlement || fallbackEntitlement;
  }

  /**
   * Get numeric entitlement of feature for a customer
   *
   * @param {string} featureId
   * @param {string} resourceId
   * @param {NumericEntitlementOptions} options
   * @return {NumericEntitlement} numeric entitlement
   */
  getNumericEntitlement({ featureId, options, resourceId }: GetNumericEntitlement): NumericEntitlement {
    const fallbackValue = options?.fallback || this.configuration.entitlementsFallback?.[featureId] || {};
    const fallbackEntitlement = {
      ...NUMERIC_DEFAULT_FALLBACK_ENTITLEMENT,
      ...fallbackValue,
    };

    const entitlement = withErrorHandling(
      () => {
        if (!this.globalEntitlementsService || !this.isCustomerLoaded) {
          return fallbackEntitlement;
        }

        if (resourceId) {
          if (resourceId !== this.configuration.resourceId) {
            this.loggerService.log(
              `Resource ID ${resourceId} does not match the current resource ID ${this.configuration.resourceId}.`,
            );
            return fallbackEntitlement;
          } else if (!this.isResourceLoaded || !this.resourceEntitlementsService) {
            return fallbackEntitlement;
          }
          return this.resourceEntitlementsService!.getNumericEntitlement(featureId, fallbackEntitlement, options);
        }

        return this.globalEntitlementsService.getNumericEntitlement(featureId, fallbackEntitlement, options);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get numeric entitlement. Error: ${err.message}`,
        rethrowError: false,
        errorReportMetadata: { featureId, options },
      },
    );

    return entitlement || fallbackEntitlement;
  }

  /**
   * Get metered entitlement of feature for a customer
   *
   * @param {string} featureId
   * @param {string} resourceId
   * @param {MeteredEntitlementOptions} options
   * @return {MeteredEntitlement} metered entitlement
   */
  getMeteredEntitlement({ featureId, options, resourceId }: GetMeteredEntitlement): MeteredEntitlement {
    const fallbackValue = options?.fallback || this.configuration.entitlementsFallback?.[featureId] || {};
    const fallbackEntitlement = {
      ...METERED_DEFAULT_FALLBACK_ENTITLEMENT,
      ...fallbackValue,
    };

    const entitlement = withErrorHandling(
      () => {
        if (!this.globalEntitlementsService || !this.isCustomerLoaded) {
          return fallbackEntitlement;
        }

        if (resourceId) {
          if (resourceId !== this.configuration.resourceId) {
            this.loggerService.log(
              `Resource ID ${resourceId} does not match the current resource ID ${this.configuration.resourceId}.`,
            );
            return fallbackEntitlement;
          } else if (!this.isResourceLoaded || !this.resourceEntitlementsService) {
            return fallbackEntitlement;
          }
          return this.resourceEntitlementsService!.getMeteredEntitlement(featureId, fallbackEntitlement, options);
        }

        return this.globalEntitlementsService.getMeteredEntitlement(featureId, fallbackEntitlement, options);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get metered entitlement. Error: ${err.message}`,
        rethrowError: false,
        errorReportMetadata: { featureId, options },
      },
    );

    return entitlement || fallbackEntitlement;
  }

  /**
   * Get paywall data for rendering the paywall
   * @deprecated *Optional* `productId` is now deprecated and will be changed to a *required* field soon.
   *
   * @returns {Promise<Paywall>}
   */
  async getPaywall({ productId, resourceId, context, billingCountryCode }: GetPaywall = {}): Promise<Paywall> {
    const params: GetPaywallProps = {
      productId,
      customerId: this.configuration.customerId,
      resourceId,
      context,
      billingCountryCode,
    };

    return withErrorHandlingAsync(
      async () => {
        if (productId?.startsWith(mockDataProductId)) {
          const graphResponse = await this.apiGateway.mockPaywall.getMockPaywall(params);
          return this.modelMapper.mapMockPlans(graphResponse.data);
        } else {
          const graphResponse = await this.apiGateway.paywall.getPaywall(params);
          return this.modelMapper.mapPaywall(graphResponse.data);
        }
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get paywall. Error: ${err.message}`,
        rethrowError: true,
        errorReportMetadata: { productId },
      },
    );
  }

  /**
   * Get a list of coupons
   *
   * @returns {Promise<Coupon[]>}
   */
  async getCoupons(): Promise<Coupon[]> {
    return withErrorHandlingAsync(
      async () => {
        const graphResponse = await this.apiGateway.coupons.getCoupons();
        return this.modelMapper.mapCoupons(graphResponse.data);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get coupons. Error: ${err.message}`,
        rethrowError: true,
      },
    );
  }

  /**
   * Get a customer
   *
   * @returns {Promise<Customer>}
   */
  async getCustomer(): Promise<Customer> {
    return withErrorHandlingAsync(
      async () => {
        const customerId = ensureCustomerRefIdExists(this.configuration);
        const resp = await this.apiGateway.customers.getCustomer(customerId);
        const customer = resp.data.getCustomerByRefId;
        if (!customer) {
          throw new Error('Customer not found');
        }
        const subscriptions = customer.subscriptions || [];
        return this.modelMapper.mapCustomer(customer, subscriptions);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get customer. Error: ${err.message}`,
        rethrowError: true,
      },
    );
  }

  /**
   * Get a customer portal
   *
   * @returns {Promise<CustomerPortal>}
   */
  async getCustomerPortal({ resourceId }: GetCustomerPortal = {}): Promise<CustomerPortal> {
    return withErrorHandlingAsync(
      async () => {
        const customerId = ensureCustomerRefIdExists(this.configuration);
        const resp = await this.apiGateway.customers.getCustomerPortal(customerId, resourceId);
        return this.modelMapper.mapCustomerPortal(resp.data.customerPortal);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get customer portal details. Error: ${err.message}`,
        rethrowError: true,
      },
    );
  }

  /**
   * Get a list of entitlements
   *
   * @returns {Promise<Entitlement[]>}
   */
  async getEntitlements(resourceId?: string): Promise<Entitlement[]> {
    const entitlementsFallback = mapGetEntitlementsFallback(this.configuration.entitlementsFallback) as Entitlement[];
    const entitlements = await withErrorHandlingAsync(
      async () => {
        ensureCustomerRefIdExists(this.configuration);
        if (resourceId) {
          if (resourceId !== this.configuration.resourceId) {
            this.loggerService.log(
              `Resource ID ${resourceId} does not match the current resource ID ${this.configuration.resourceId}.`,
            );
            return entitlementsFallback;
          } else if (!this.isResourceLoaded || !this.resourceEntitlementsService) {
            return entitlementsFallback;
          }
          return this.resourceEntitlementsService!.getEntitlements();
        }

        return this.globalEntitlementsService?.getEntitlements();
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get entitlements. Error: ${err.message}`,
        rethrowError: true,
      },
    );

    return entitlements || entitlementsFallback;
  }

  /**
   * Estimate subscription
   * @return {Promise<SubscriptionPreview>} Preview of the subscription.
   * @param {EstimateSubscription} estimateSubscription
   */
  async estimateSubscription(estimateSubscription: EstimateSubscription): Promise<SubscriptionPreview> {
    return withErrorHandlingAsync(
      async () => {
        const { data } = await this.apiGateway.subscriptionEstimations.estimateSubscription(estimateSubscription);
        if (!data) throw new Error('Invalid response');

        return this.modelMapper.mapSubscriptionPreview(data.estimateSubscription);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to estimate subscription. Error: ${err.message}`,
        rethrowError: true,
      },
    );
  }

  /**
   * Estimate subscription update
   * @return {Promise<SubscriptionPreview>} Preview of the subscription.
   * @param {EstimateSubscriptionUpdate} estimateSubscriptionUpdate
   */
  async estimateSubscriptionUpdate(
    estimateSubscriptionUpdate: EstimateSubscriptionUpdate,
  ): Promise<SubscriptionPreview> {
    return withErrorHandlingAsync(
      async () => {
        const { data } = await this.apiGateway.subscriptionEstimations.estimateSubscriptionUpdate(
          estimateSubscriptionUpdate,
        );

        if (!data) throw new Error('Invalid response');

        return this.modelMapper.mapSubscriptionPreview(data.estimateSubscriptionUpdate);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to estimate subscription update. Error: ${err.message}`,
        rethrowError: true,
      },
    );
  }

  /**
   * Waits for a subscription to be activated after a completed checkout.
   * This method should be called on page load after the customer is redirected to the success URL.
   * Returns the new subscription.
   *
   * @returns {Promise<Subscription | null>}
   * @param timeoutMs
   * @param refreshOnComplete should refresh entitlements if subscription was found
   */
  async waitForCheckoutCompleted({
    timeoutMs = 15000,
    refreshOnComplete = true,
  }: WaitForCheckoutCompleted = {}): Promise<Subscription | null> {
    if (typeof window === 'undefined') {
      throw new Error('`waitForCheckoutCompleted` was called, but `window` is not defined');
    }

    const searchParams = new URLSearchParams(window.location.search);
    const subscriptionId = searchParams.get('subscriptionId');
    const resourceId = searchParams.get('resourceId') || undefined;
    const checkoutCompleted = searchParams.get('checkoutCompleted');
    if (!subscriptionId) {
      this.loggerService.log('`waitCheckoutCompleted` was called, but no `subscriptionId` found in query params');
      return null;
    }
    if (!checkoutCompleted || checkoutCompleted.toLowerCase() === 'false') {
      this.loggerService.log('The checkout session was cancelled by the user');
      return null;
    }

    const timestamp = Date.now();
    let isPolling = false;
    let subscription: Subscription | null = null;

    do {
      if (isPolling) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      const activeSubscriptions = await this.getActiveSubscriptions({ resourceId });

      subscription = activeSubscriptions.find((s) => s.id === subscriptionId) || null;
      isPolling = true;
    } while (!subscription && Date.now() - timestamp < timeoutMs);

    if (!subscription) {
      throw new Error(`Timeout while waiting for checkout to complete, subscription ${subscriptionId} is not active`);
    }

    if (refreshOnComplete) {
      await this.refresh();
    }

    return subscription;
  }

  getActiveSubscriptions({ resourceId }: GetActiveSubscriptions = {}): Promise<Subscription[]> {
    return withErrorHandlingAsync(
      async () => {
        const customerId = ensureCustomerRefIdExists(this.configuration);
        const resp = await this.apiGateway.customers.getActiveSubscriptions(customerId, resourceId);
        const activeSubscriptions = resp.data.getActiveSubscriptions;
        if (!activeSubscriptions) {
          throw new Error(`Customer ${resourceId ? 'or resource ' : ''}not found`);
        }

        return this.modelMapper.mapSubscriptions(activeSubscriptions);
      },
      {
        loggerService: this.loggerService,
        sdkConfiguration: this.configuration,
        errorMessage: (err) => `Failed to get active subscriptions. Error: ${err.message}`,
        rethrowError: true,
      },
    );
  }
}
