import { type StepType, type TextShapeType, Shape } from 'app/types'
import { isValidUrl } from 'modules'

export const getStepData = (step: StepType) => {
    const { type, url } = step

    const getStepTitle = () => {
        const defaultTitle = getStepText(step)

        if (type !== 'urlChange') return defaultTitle

        const parsedTitle = getStepText(step).split('"')
        const hasValidLink = parsedTitle.length >= 3 ? isValidUrl(parsedTitle[1]) : false

        if (!hasValidLink) return defaultTitle
        return parsedTitle.filter(e => e !== parsedTitle[1]).join('')
    }

    const getStepGoTo = () => {
        const defaultUrl = { href: '#', host: '' }

        try {
            return url ? new URL(url.includes('http') ? url : `https://${url}`) : defaultUrl
        } catch {
            return defaultUrl
        }
    }

    const getStepUrlChange = () => {
        if (type !== 'urlChange') return ''

        const parsedTitle = getStepText(step).split('"')

        return parsedTitle.length >= 3 ? (isValidUrl(parsedTitle[1]) ? parsedTitle[1] : '') : ''
    }

    return {
        stepTitle: getStepTitle(),
        stepGoTo: getStepGoTo(),
        stepUrlChange: getStepUrlChange()
    }
}

export const getStepText = (step: StepType) => {
    // step has a title, use it
    if (step.title) return step.title
    // step does not hava a title, look for text in the layers
    const textLayer = step.layers.find(layer => layer.type === Shape.Text && layer.title) as
        | TextShapeType
        | undefined

    return textLayer?.title || ''
}
