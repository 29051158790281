import { useRef, useState } from 'react'

import { HoverSection } from './hover-section'

import Box from '@mui/material/Box'

const formatTime = (seconds: number) => {
    const format = (val: number) => `0${Math.floor(val)}`.slice(-2)

    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    const secs = seconds % 60

    return [...(hours >= 1 ? [hours] : []), minutes, secs].map(format).join(':')
}

type VideoSectionType = {
    start: number
    end: number
    duration?: number
    title: string
    thumbnail?: string
    tags?: Array<string>
    applications?: Array<{
        applicationId: string
        applicationName: string
        applicationLogo?: string
    }>
    rangeValues: Array<number>
    isDeleted?: boolean
}

export type HoverBarProps = {
    /**
     * a video duration (seconds)
     */
    duration: number
    /**
     * update time on hover click
     */
    onClick: (_percentage: number) => void
    /**
     * percentage of the whole video (section width)
     */
    width: number
    /**
     * sum of all previous sections (%)
     */
    totalPrevWidth: number
    /**
     * index of the section
     */
    index: number
    /**
     * Total chapters in the full video
     */
    totalSections: number
    /**
     * Chapter data that includes the start and end time, thumbnail, tags and applications
     */
    section: VideoSectionType
}

export const HoverBar = ({
    duration,
    width,
    totalPrevWidth,
    index,
    totalSections,
    section,
    onClick
}: HoverBarProps) => {
    const [hoverProgress, setHoverProgress] = useState(0)

    const progressRef = useRef<HTMLDivElement>(null)

    const allWidthsOnHover = totalPrevWidth + (width / 100) * hoverProgress
    const currentTimeOnHover = (duration / 100) * allWidthsOnHover

    const onProgressClick = (e: any) => {
        const rect = e.target.getBoundingClientRect()
        const x = e.clientX - rect.left
        const fullWidth = Number(progressRef.current?.clientWidth)

        const sectionPercentage = (((x / fullWidth) * 100) / 100) * width
        const totalPercentage = totalPrevWidth + sectionPercentage

        onClick(totalPercentage || 0)
    }

    return (
        <div
            ref={progressRef}
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                zIndex: 12
            }}
            onClick={onProgressClick}
            onMouseLeave={() => setHoverProgress(0)}
            onMouseMove={e => {
                e.preventDefault()
                e.stopPropagation()

                const rect = (e.target as HTMLDivElement).getBoundingClientRect()

                if (e.clientX < rect.left || e.clientX >= rect.right) return

                const x = e.clientX - rect.left
                const fullWidth = Number(progressRef.current?.clientWidth)

                const percentage = (x / fullWidth) * 100
                setHoverProgress(percentage)
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    position: 'absolute',
                    backgroundColor: 'rgba(255,255,255,0.4)',
                    bottom: 0,
                    borderRadius: 0,
                    visibility: hoverProgress ? 'visible' : 'hidden',
                    width: `${hoverProgress}%`,
                    '&::after': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        right: '-0.5px',
                        height: '100%',
                        width: '1px',
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
                        background: 'black',
                        borderRadius: 0
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'block',
                        background: 'white',
                        color: 'black',
                        width: '60px',
                        height: '20px',
                        position: 'absolute',
                        right: '-30px',
                        top: '-30px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        zIndex: 26
                    }}
                    id="hover-time"
                >
                    {formatTime(currentTimeOnHover)}
                </Box>
                <HoverSection section={section} index={index} totalSections={totalSections} />
            </Box>
        </div>
    )
}
