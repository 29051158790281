import { memo, useCallback, useEffect, useMemo, useRef } from 'react'

import { Transformer } from 'react-konva'
import type Konva from 'konva'

const outerAnchors = ['top-left', 'top-right', 'bottom-left', 'bottom-right']
const innerAnchors = ['top-center', 'bottom-center', 'middle-left', 'middle-right']

type Props = {
    shapes: Array<Konva.Node>
}

export const ShapeTransformer = memo(({ shapes }: Props) => {
    const trRef = useRef<Konva.Transformer>(null)

    const includeShapeName = useCallback(
        (name: string | Array<string>) =>
            shapes.some(shape => {
                const shapeName = shape.name()
                if (Array.isArray(name)) return name.includes(shapeName)
                return shapeName === name
            }),
        [shapes]
    )

    const enabledAnchors = useMemo(() => {
        if (includeShapeName(['overlay', 'watermark'])) return []
        if (includeShapeName('text')) return ['middle-left', 'middle-right']

        const shapesWithOuterAnchors = [
            'audioCircle',
            'circle',
            'circleSpotlight',
            'imageCircle',
            'stepNumbering'
        ]
        if (includeShapeName(shapesWithOuterAnchors)) return outerAnchors

        return [...outerAnchors, ...innerAnchors]
    }, [includeShapeName])

    const rotateEnabled =
        shapes.length === 1 &&
        Boolean(enabledAnchors.length) &&
        !['blur', 'circle', 'circleSpotlight'].includes(shapes[0].name())

    useEffect(() => {
        if (!trRef.current) return

        // we need to attach transformer manually
        trRef.current.nodes(shapes)
        trRef.current.getLayer()?.batchDraw()
    }, [shapes])

    if (!shapes.length) return null

    return (
        <Transformer
            ref={trRef}
            name="transformer"
            ignoreStroke={false}
            rotateEnabled={rotateEnabled}
            rotationSnaps={[0, 90, 180, 270]}
            enabledAnchors={enabledAnchors}
            boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox
                }
                return newBox
            }}
        />
    )
})
