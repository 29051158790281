import { useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'

import { alpha, Box, Stack, Typography, Divider, ButtonBase } from '@mui/material'

import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'

import { logToAnalytics } from 'modules'
import { type MetricsByDate } from 'app/types/insights'

import { useDynamicYAxisWidth } from './use-dynamic-y-axis-width'
import { InsightsEmptyChartLabel } from './InsightsEmptyChartLabel'
import { InsightsStatisticContainer } from './InsightsStatisticContainer'

export type ChartArea = {
    id: string
    color: string
    label: string
    analyticsClickEventName?: string
}

type Props = {
    data: Array<MetricsByDate>
    title: string
    description: string
    height: number
    areas: Array<ChartArea>
    valueFormatter?: (value: number, isDetails?: boolean) => string
    isEmpty?: boolean
    analyticsChartHoverEventName: string
    analyticsDescriptionHoverEventName: string
    analyticsEventSource: string
    isSmall?: boolean
}

export const InsightsAreaChart = ({
    data,
    title,
    description,
    areas,
    height,
    valueFormatter,
    isEmpty,
    analyticsChartHoverEventName,
    analyticsDescriptionHoverEventName,
    analyticsEventSource,
    isSmall = false
}: Props) => {
    const [visibleAreasIds, setVisibleAreasIds] = useState(areas.map(area => area.id))

    const visibleAreas = areas.filter(area => visibleAreasIds.includes(area.id))

    const { setChartRef, yAxisWidth } = useDynamicYAxisWidth()

    const areMultipleYears = useMemo(() => {
        const rangeStart = data[0]
        const rangeEnd = data.at(-1)

        if (rangeStart && rangeEnd) {
            return dayjs(rangeStart.date).year() !== dayjs(rangeEnd.date).year()
        }

        return false
    }, [data])

    return (
        <InsightsStatisticContainer
            title={title}
            description={description}
            analyticsDescriptionHoverEventName={analyticsDescriptionHoverEventName}
            analyticsEventSource={analyticsEventSource}
            isEmpty={isEmpty}
            isSmall={isSmall}
            actions={
                areas.length > 1 && (
                    <Stack direction="row" spacing={1}>
                        {areas.map(area => {
                            const isAreaVisible = visibleAreasIds.includes(area.id)

                            return (
                                <ButtonBase
                                    disabled={isEmpty}
                                    key={area.id}
                                    sx={{
                                        border: '1px solid',
                                        borderColor: isAreaVisible
                                            ? area.color
                                            : 'rgba(9, 12, 16, 0.1)',
                                        backgroundColor: isAreaVisible
                                            ? alpha(area.color, 0.2)
                                            : '#FAFAFA',
                                        color: isAreaVisible ? '#090C10' : 'rgba(9, 12, 16, 0.6)',
                                        borderRadius: '8px',
                                        py: 0.75,
                                        px: 1.5,
                                        '&.Mui-disabled': {
                                            borderColor: 'rgba(9, 12, 16, 0.1)',
                                            backgroundColor: 'white',
                                            color: '#BDBDBD'
                                        }
                                    }}
                                    onClick={() => {
                                        if (area.analyticsClickEventName)
                                            logToAnalytics(area.analyticsClickEventName, {
                                                source: analyticsEventSource
                                            })

                                        if (isAreaVisible) {
                                            if (visibleAreas.length === 1) {
                                                return
                                            }

                                            setVisibleAreasIds(prevVisibleAreas =>
                                                prevVisibleAreas.filter(id => id !== area.id)
                                            )
                                            return
                                        }
                                        setVisibleAreasIds(prevVisibleAreas => [
                                            ...prevVisibleAreas,
                                            area.id
                                        ])
                                    }}
                                >
                                    <Typography fontSize={12} lineHeight="20px" fontWeight={500}>
                                        {area.label}
                                    </Typography>
                                </ButtonBase>
                            )
                        })}
                    </Stack>
                )
            }
            content={
                <>
                    {isEmpty && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 65,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                zIndex: 1
                            }}
                        >
                            <InsightsEmptyChartLabel />
                        </Box>
                    )}

                    <ResponsiveContainer height={height} width="100%" ref={setChartRef}>
                        <AreaChart
                            data={data}
                            margin={{
                                bottom: 0,
                                left: 10,
                                right: -10,
                                top: 20
                            }}
                        >
                            <defs>
                                {areas.map(area => (
                                    <linearGradient
                                        key={area.id}
                                        id={`${area.id}Gradient`}
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                        rotate="180deg"
                                    >
                                        <stop offset="0" stopColor={area.color} stopOpacity="0.6" />
                                        <stop offset="1" stopColor={area.color} stopOpacity="0" />
                                    </linearGradient>
                                ))}
                            </defs>
                            <CartesianGrid
                                stroke="rgba(9, 12, 16, 0.2)"
                                strokeDasharray="2.5 3.5"
                                vertical={false}
                            />
                            <XAxis
                                axisLine={false}
                                dataKey="date"
                                tickLine={false}
                                tick={{
                                    fontSize: 14,
                                    fill: `rgba(9, 12, 16, ${isEmpty ? 0.2 : 0.4})`,
                                    dy: 4
                                }}
                                padding={{ left: 20, right: 20 }}
                                tickFormatter={value => dayjs(value).format('MMM D')}
                                minTickGap={15}
                                interval="preserveStartEnd"
                            />
                            <YAxis
                                width={yAxisWidth}
                                orientation="right"
                                axisLine={false}
                                tickLine={false}
                                tick={{
                                    fontSize: 14,
                                    fill: `rgba(9, 12, 16, ${isEmpty ? 0.2 : 0.4})`,
                                    dx: yAxisWidth - 20,
                                    textAnchor: 'end'
                                }}
                                tickFormatter={
                                    valueFormatter ? value => valueFormatter(value) : undefined
                                }
                            />
                            {!isEmpty && (
                                <Tooltip
                                    content={({ active, payload, label }) => {
                                        const activePayload = payload?.[0]?.payload

                                        const formattedLabel = dayjs(label).format(
                                            `MMM D ${areMultipleYears ? 'YYYY' : ''}`
                                        )

                                        useEffect(() => {
                                            if (active) {
                                                // Send event only if user is focused on specific date
                                                const timerId = setTimeout(() => {
                                                    logToAnalytics(analyticsChartHoverEventName, {
                                                        date: formattedLabel,
                                                        source: analyticsEventSource
                                                    })
                                                }, 500)

                                                return () => clearTimeout(timerId)
                                            }
                                        }, [active, formattedLabel])

                                        if (!active || !activePayload) return null

                                        return (
                                            <Stack
                                                width={270}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '12px',
                                                    boxShadow:
                                                        '0px 2px 10px 2px rgba(0, 0, 0, 0.14)',
                                                    border: '1px solid rgba(9, 12, 16, 0.1)',
                                                    p: 2
                                                }}
                                                spacing={1}
                                            >
                                                <Typography
                                                    fontSize={16}
                                                    lineHeight={1.5}
                                                    fontWeight={500}
                                                >
                                                    {formattedLabel}
                                                </Typography>
                                                <Stack
                                                    spacing={1}
                                                    divider={
                                                        <Divider
                                                            sx={{
                                                                borderColor: 'rgba(9, 12, 16, 0.1)'
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {visibleAreas.map(area => (
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            key={area.id}
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={1.5}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        border: '2px solid white',
                                                                        borderRadius: '50%',
                                                                        backgroundColor: area.color,
                                                                        boxShadow:
                                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.25)',
                                                                        width: 10,
                                                                        height: 10
                                                                    }}
                                                                />
                                                                <Typography
                                                                    fontSize={14}
                                                                    lineHeight="20px"
                                                                    color="rgba(9, 12, 16, 0.6)"
                                                                >
                                                                    {area.label}
                                                                </Typography>
                                                            </Stack>
                                                            <Typography
                                                                fontSize={14}
                                                                lineHeight="20px"
                                                            >
                                                                {valueFormatter
                                                                    ? valueFormatter(
                                                                          activePayload[area.id],
                                                                          true
                                                                      )
                                                                    : activePayload[area.id]}
                                                            </Typography>
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        )
                                    }}
                                />
                            )}
                            {visibleAreas.map(area => (
                                <Area
                                    key={area.id}
                                    dataKey={area.id}
                                    strokeWidth={2}
                                    stroke={isEmpty ? '' : area.color}
                                    fill={`url(#${area.id}Gradient)`}
                                />
                            ))}
                        </AreaChart>
                    </ResponsiveContainer>
                </>
            }
        />
    )
}
