import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { type SpeakerType } from 'app/types'
import { useOrganization, useServiceUsage } from 'hooks'
import { type OptionType, option, uniqueArray } from 'modules'
import { useLastSpeaker } from './use-last-speaker'

export const enUScode = 'en-US'
export const enGBcode = 'en-GB'

export const useSpeakerOptions = () => {
    const { hasStudioVoices } = useServiceUsage()
    const { speakerId, setLastSpeaker } = useLastSpeaker()
    const { defaultLanguage } = useOrganization()

    const { audioConfigs } = useSelector(state => state.configs)

    const speakers = audioConfigs.speakers

    const languagesList: Array<OptionType> = useMemo(() => {
        if (!speakers) return []

        return uniqueArray([
            ...Object.entries(speakers)
                ?.map(([langCode, { langName }]) => option(langCode, langName))
                ?.sort((a, b) => (a.label > b.label ? 1 : -1))
        ])
    }, [speakers])

    const getLastSelectedLanguage = useCallback(
        (showOther?: boolean) => {
            if (!speakerId) return null
            if (showOther && speakerId === 'other') return option('other', 'Other')
            const speaker = Object.values(speakers)
                .flatMap(item => item.options)
                .find(speaker => speaker.id === speakerId)

            if (speaker) return option(speaker.langCode, speaker.langName)

            return null
        },
        [speakerId, speakers]
    )

    const getSpeakersList = useCallback(
        (languageCode: string) => speakers?.[languageCode]?.options || [],
        [speakers]
    )

    const getDefaultSpeaker = useCallback(
        (options: Array<SpeakerType>) => {
            if (!options?.length) return null
            const fallbackOption =
                options.find(option => option.isStudio === Boolean(hasStudioVoices)) || options[0]

            if (speakerId) {
                const initialOption = options.find(option => option.id === speakerId)
                if (!initialOption) return fallbackOption
                const isDisabled = initialOption.isStudio && !hasStudioVoices

                return isDisabled ? fallbackOption : initialOption
            }

            return fallbackOption
        },
        [hasStudioVoices, speakerId]
    )

    return useMemo(
        () => ({
            languagesList,
            hasStudioVoices,
            defaultLanguage,
            speakers,
            speakerId,
            setLastSpeaker,
            getLastSelectedLanguage,
            getSpeakersList,
            getDefaultSpeaker
        }),
        [
            languagesList,
            speakerId,
            hasStudioVoices,
            speakers,
            defaultLanguage,
            setLastSpeaker,
            getLastSelectedLanguage,
            getSpeakersList,
            getDefaultSpeaker
        ]
    )
}
