import { type ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

import { captureException } from '@sentry/react'
import { log } from '@fullstory/browser'
import { type FallbackProps, ErrorBoundary } from 'react-error-boundary'

import { FallbackMessage } from './FallbackMessage'

import { paths } from 'app/paths'
import { logToAnalytics } from 'modules/analytics'

type Props = {
    children: ReactNode
}

export const ErrorBounder = (props: Props) => (
    <ErrorBoundary
        FallbackComponent={RuntimeErrorDialog}
        onError={error => {
            log('Now there was a page crash')
            captureException(error)
            logToAnalytics('page_crashed', {
                message: error.message,
                pathname: window.location.pathname
            })
        }}
    >
        {props.children}
    </ErrorBoundary>
)

const RuntimeErrorDialog = ({ resetErrorBoundary }: FallbackProps) => {
    const history = useHistory()

    return (
        <FallbackMessage
            title="Oops! Something went wrong"
            subtitle="Let us guidde you back"
            phrase="You got me!"
            onClick={() => {
                history.replace(paths.index)
                resetErrorBoundary()
            }}
        />
    )
}
