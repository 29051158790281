import { Box } from '@mui/material'

import { InnerHTML } from './InnerHTML'
import { type SearchPlaybookType } from 'app/types'

const matchTagValue = (playbook: SearchPlaybookType) =>
    playbook._snippetResult?.tags?.find(tag => tag.matchLevel === 'full')?.value

type Props = {
    playbook: SearchPlaybookType
}

export const MatchedTags = ({ playbook }: Props) => {
    if (!matchTagValue(playbook)) return null

    return (
        <Box ml={1} mr={2}>
            <InnerHTML html={`#${matchTagValue(playbook)}`} />
        </Box>
    )
}
