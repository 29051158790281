import { type MouseEvent, memo } from 'react'

import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'

import { styled, Box, Divider, ListItem, Typography } from '@mui/material'

import { AppImages } from './AppImages'
import { MatchedTags } from './MatchedTags'
import { MobileSearchItem } from './MobileSearchItem'
import { InnerHTML } from './InnerHTML'
import { TimeLineItem } from './TimeLineItem'

import { type SearchPlaybookType } from 'app/types'
import { paths } from 'app/paths'

import { useBoolean, useWindowView } from 'hooks'
import { NewBrowserTabLink } from '../NewBrowserTabLink'
import { SpacedGroup } from 'UI/Components'
import { TextOverflow } from '@guidde-co/shared.textoverflow'

const PREFIX = 'SearchItem'

const classes = {
    creatorLabel: `${PREFIX}-creatorLabel`,
    listItemLink: `${PREFIX}-listItemLink`,
    listItem: `${PREFIX}-listItem`
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
    [`& .${classes.creatorLabel}`]: {
        color: '#78909c',
        fontSize: theme.typography.pxToRem(12),
        marginLeft: '8px'
    },

    [`& .${classes.listItemLink}`]: {
        color: '#212121',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            '& span': {
                textDecoration: 'underline'
            }
        }
    },

    [`& .${classes.listItem}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    }
}))

type Props = {
    playbook: SearchPlaybookType
    onResultClick: (e: MouseEvent<HTMLElement>, playbook: SearchPlaybookType) => void
    compactResults?: boolean
}

export const SearchItem = memo(({ playbook, onResultClick, compactResults = false }: Props) => {
    const history = useHistory()
    const { search } = useLocation()

    const { isMobileView } = useWindowView()

    const hoverState = useBoolean(false)

    const pageLink = {
        pathname: generatePath(
            playbook.isPlaylist ? paths.playlistDetails : paths.playbookDetails,
            {
                playbookId: playbook.id
            }
        ),
        search,
        state: {
            prevRoute: history.location.pathname
        }
    }

    const applications = playbook.applications || []
    const imgURLs = applications.map(it => it.applicationLogo)

    if (isMobileView) {
        return (
            <MobileSearchItem
                applications={applications}
                imgURLs={imgURLs}
                pageLink={pageLink}
                playbook={playbook}
            />
        )
    }

    if (compactResults) {
        return (
            <Box
                onMouseEnter={hoverState.setTrue}
                onMouseLeave={hoverState.setFalse}
                sx={hoverState.isTrue ? { backgroundColor: 'rgba(237, 238, 239, 1)' } : undefined}
            >
                <Divider />
                <StyledListItem
                    key={playbook.id}
                    onClick={(e: MouseEvent<HTMLElement>) => onResultClick?.(e, playbook)}
                >
                    <SpacedGroup>
                        <img src={playbook.img} width="60px" style={{ objectFit: 'contain' }} />
                        <SpacedGroup flexDirection="column" spacing={0}>
                            <Box fontSize={14} fontWeight={700} color="#090C10">
                                <TextOverflow lineClamp={1}>{playbook.title}</TextOverflow>
                            </Box>
                            <Box fontSize={14} fontWeight={400} color="rgba(9, 12, 16, 0.6)">
                                {playbook.creator_name}
                            </Box>
                        </SpacedGroup>
                    </SpacedGroup>
                </StyledListItem>
            </Box>
        )
    }

    if (playbook._snippetResult?.transcript?.matchLevel !== 'none') {
        return (
            <StyledListItem
                className={classes.listItem}
                key={playbook.id}
                onMouseEnter={hoverState.setTrue}
                onMouseLeave={hoverState.setFalse}
            >
                <Box display="flex">
                    {/*Playbook image link*/}
                    <Box display="flex" alignItems="flex-start">
                        <Link
                            to={pageLink}
                            onClick={(e: MouseEvent<HTMLElement>) => onResultClick?.(e, playbook)}
                            className={classes.listItemLink}
                        >
                            <Box mr={2}>
                                <PlaybookSearchPreviewImg playbook={playbook} />
                            </Box>
                        </Link>
                    </Box>
                    <Box display="flex" alignItems="left" flexDirection="column" mr={2}>
                        <Box display="flex" alignItems="top" justifyContent="flex-start">
                            {/*Application images + playbook title +  playbook creator link*/}
                            <Link to={pageLink} className={classes.listItemLink}>
                                <AppImages imgURLs={imgURLs} />
                                <Box display="flex" alignItems="center">
                                    <InnerHTML fontWeight="bold" html={playbook.title} />
                                    <InnerHTML
                                        html={playbook.creator_name}
                                        className={classes.creatorLabel}
                                    />
                                </Box>
                            </Link>
                            {/*Open Playbook in a new tab link*/}
                            {hoverState.isTrue && <NewBrowserTabLink pageLink={pageLink} />}
                        </Box>

                        <Box>
                            <TimeLineItem sections={playbook?.sections} pageLink={pageLink} />
                        </Box>
                    </Box>
                </Box>
                <MatchedTags playbook={playbook} />
            </StyledListItem>
        )
    }

    return (
        <StyledListItem
            key={playbook.id}
            onMouseEnter={hoverState.setTrue}
            onMouseLeave={hoverState.setFalse}
        >
            <Box display="flex" alignItems="center">
                {/*Playbook image + Application images + playbook title +  playbook creator link*/}
                <Link
                    to={pageLink}
                    onClick={(e: MouseEvent<HTMLElement>) => onResultClick?.(e, playbook)}
                    className={classes.listItemLink}
                    data-test="search-item-link"
                >
                    <Box mr={2} display="inline">
                        <img
                            style={{
                                width: '100px'
                            }}
                            src={playbook.img}
                            data-test="search-item-thumbnail"
                        />
                    </Box>
                    <AppImages imgURLs={imgURLs} showNothing={true} />

                    <InnerHTML
                        html={playbook._snippetResult?.title?.value}
                        fontWeight="bold"
                        data-test="search-item-text"
                    />

                    <InnerHTML html={playbook.creator_name} className={classes.creatorLabel} />
                </Link>
                {/*Open Playbook in a new tab link*/}
                {hoverState.isTrue && <NewBrowserTabLink pageLink={pageLink} />}
            </Box>
            <Link
                to={pageLink}
                onClick={(e: MouseEvent<HTMLElement>) => onResultClick?.(e, playbook)}
                className={classes.listItemLink}
            >
                <MatchedTags playbook={playbook} />
            </Link>
        </StyledListItem>
    )
})

export const PlaybookSearchPreviewImg = ({ playbook }: { playbook: SearchPlaybookType }) => {
    if (playbook.img)
        return (
            <img
                style={{
                    width: '100px'
                }}
                src={playbook.img}
            />
        )

    return (
        <Box
            width="100px"
            height="55px"
            bgcolor={playbook.coverColor}
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
        >
            <TextOverflow lineClamp={2}>
                <Typography
                    style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: 500
                    }}
                >
                    {playbook.title}
                </Typography>
            </TextOverflow>
        </Box>
    )
}
