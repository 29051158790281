import { type CSSProperties } from 'react'

import { Box, styled } from '@mui/material'

const PREFIX = 'NotAvailableMessage'

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    container: `${PREFIX}-container`,
    textContainer: `${PREFIX}-textContainer`,
    text: `${PREFIX}-text`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.wrapper}`]: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#F5F5F5',
        opacity: 0.8,
        zIndex: 2
    },

    [`& .${classes.container}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 5,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },

    [`& .${classes.textContainer}`]: {
        background: 'rgb(255, 255, 255)',
        padding: theme.spacing(1, 2.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    [`& .${classes.text}`]: {
        color: '#828282',
        fontStyle: 'italic',
        fontSize: '12px'
    }
}))

type Props = {
    style?: CSSProperties
    message?: string
}
export const NotAvailableMessage = ({
    style,
    message = 'Options available for public videos'
}: Props) => {
    return (
        <StyledBox>
            <Box className={classes.wrapper} />
            <Box className={classes.container} style={style}>
                <Box width={250} height={95} className={classes.textContainer}>
                    <Box className={classes.text}>{message}</Box>
                </Box>
            </Box>
        </StyledBox>
    )
}
