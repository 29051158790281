import { type ReactNode } from 'react'

import { type FastFieldProps, FastField } from 'formik'
import { type CheckboxProps, Checkbox, FormControlLabel, FormHelperText } from '@mui/material'

type Props = {
    name: string
    label?: ReactNode
    disabled?: boolean
    color?: 'default' | 'primary' | 'secondary'
} & CheckboxProps

export const ConnectedCheckbox = ({ name, label, ...props }: Props) => (
    <FastField name={name}>
        {({ field, meta }: FastFieldProps) => (
            <>
                <FormControlLabel
                    control={<Checkbox {...props} {...field} checked={field.value} name={name} />}
                    label={label}
                />
                {meta.touched && meta.error !== undefined && (
                    <FormHelperText>{meta.error}</FormHelperText>
                )}
            </>
        )}
    </FastField>
)
