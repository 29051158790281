import dayjs from 'dayjs'

import { humanFileSize, envConfig } from 'modules'
import msgraph from 'modules/msgraph'

export const oneDriveColumns = [
    { value: 'name', label: 'Name', cellContent: file => file.name },
    {
        value: 'owner',
        label: 'Owner',
        cellContent: file => file.createdBy.user.displayName
    },
    {
        value: 'modifiedTime',
        label: 'Last modified',
        isSecondary: true,
        cellContent: file => dayjs(file.lastModifiedDateTime).format('DD MMM, YYYY')
    },
    {
        value: 'size',
        label: 'File size',
        cellContent: file => (file.size ? humanFileSize(file.size) : '')
    }
]

const compareStrings = (a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })

export const sortOneDriveFiles = (sortBy, sortDesc, a, b) => {
    if (msgraph.isFolder(1) !== msgraph.isFolder(b)) {
        // Folders first
        return msgraph.isFolder(a) ? -1 : 1
    }

    const paramA = sortBy === 'owner' ? a.createdBy.user.displayName : a[sortBy]
    const paramB = sortBy === 'owner' ? b.createdBy.user.displayName : b[sortBy]

    if (!paramA && !paramB) {
        return compareStrings(a.name, b.name)
    }

    if (sortBy === 'name' || sortBy === 'owner') {
        return sortDesc ? compareStrings(paramB, paramA) : compareStrings(paramA, paramB)
    }

    if (sortBy === 'size') {
        return sortDesc ? paramB - paramA : paramA - paramB
    }

    if (sortBy === 'modifiedTime') {
        const momentA = dayjs(a.lastModifiedDateTime).valueOf()
        const momentB = dayjs(b.lastModifiedDateTime).valueOf()

        return sortDesc ? momentB - momentA : momentA - momentB
    }

    return 0
}

function getMSClientId() {
    switch (envConfig.firebaseConfig.projectId) {
        case 'guidde-production':
            return '6c812c2a-8272-4e21-b063-1320b78adf38' //prod
        case 'guidde-dev-staging':
            return '920bb2a3-6a38-48f6-af4f-47849df20d69' //stg
        case 'guidde-dev-testomate':
            return '312f6254-c7f6-40da-94b1-fbfb6173e8e1' // test env
        default:
            return '980f546a-6d69-42b5-add6-aadfa1425bf1' //dev
    }
}
/** dev auth */
let msAuthConfig = {
    clientId: getMSClientId(),
    authority: 'https://login.microsoftonline.com/common/'
}

export const OneDriveAuthConfig = {
    auth: msAuthConfig
}

export const VALID_FILETYPES = ['video/mp4']
