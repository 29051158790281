import { memo, useEffect, useState } from 'react'

import { Typography, Box, Button } from '@mui/material'
import PaletteIcon from '@mui/icons-material/PaletteOutlined'

import { SpacedGroup } from 'UI/Components'

import { GeneralDialog } from '../GeneralDialog'

import { ColorsPalette, generateColor } from './ColorsPalette'
import { ColorInput } from './ColorInput'

import { isColorLight, colorCodeRegex } from 'modules'

type Props = {
    isOpen: boolean
    onClose: () => void
    initialColor?: string
    onSubmit: (color: string) => void
    validateIfColorAlreadyExists?: (color: string) => boolean
    title: string
    isLoading: boolean
    inputFullWidth?: boolean
}

export const ColorDialog = memo(
    ({
        inputFullWidth,
        initialColor = '',
        onSubmit,
        validateIfColorAlreadyExists,
        ...props
    }: Props) => {
        const [color, setColor] = useState(generateColor(initialColor))

        useEffect(() => {
            setColor(generateColor(initialColor))
        }, [initialColor])

        useEffect(() => {
            if (props.isOpen) return

            setColor('')
        }, [props.isOpen])

        const result = '#' + color
        const isValidColor = colorCodeRegex.test(result)

        const colorAlreadyExists = validateIfColorAlreadyExists?.(result)

        const disableSave = !isValidColor || result === initialColor || colorAlreadyExists

        return (
            <GeneralDialog
                {...props}
                maxWidth="sm"
                actions={
                    <Box display="flex" width="100%" justifyContent="flex-end">
                        <Button
                            data-test="colors-save-changes-button"
                            disabled={disableSave}
                            onClick={() => onSubmit(result)}
                            size="large"
                            variant="contained"
                        >
                            SAVE CHANGES
                        </Button>
                    </Box>
                }
            >
                <SpacedGroup spacing={1} px={2}>
                    <Box
                        mr={1}
                        minWidth="55px"
                        minHeight="55px"
                        borderRadius="4px"
                        bgcolor={isValidColor ? result : '#F5F5F5'}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        border={isColorLight(result) ? '1px solid grey' : ''}
                    >
                        {!isValidColor && (
                            <PaletteIcon
                                width={20}
                                height={20}
                                style={{ color: 'rgba(9, 12, 16, 0.6)' }}
                            />
                        )}
                    </Box>
                    <ColorInput
                        data-test="brandkit-color-input"
                        setColor={setColor}
                        inputFullWidth={inputFullWidth}
                        color={color}
                        autoFocus={true}
                    />
                </SpacedGroup>

                <Box height="8px" marginLeft="82px">
                    {colorAlreadyExists && (
                        <Typography color="error" variant="caption">
                            Element color already exists
                        </Typography>
                    )}
                </Box>

                <ColorsPalette setColor={setColor} result={result} />
            </GeneralDialog>
        )
    }
)
