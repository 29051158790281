import { type ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Dialog, DialogContent, Box, LinearProgress, styled } from '@mui/material'

import { PaymentErrorDialog } from 'UI/Components'

import { SelectPlan } from './SelectPlan'
import { SelectSeats } from './SelectSeats'
import { Payment } from './Payment'
import { UpgradeDialogHeader } from './UpgradeDialogHeader'
import { SCREEN } from './helpers'

import { filterURLParams, logToAnalytics } from 'modules'
import {
    usePayment,
    useBoolean,
    useStigg,
    useQueryParams,
    useServiceUsage,
    useWindowView
} from 'hooks'
import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'

const StyledDialogContent = styled(DialogContent, {
    shouldForwardProp: prop => prop !== 'noPadding'
})<{ noPadding: boolean }>(({ theme, noPadding }) => ({
    ...(noPadding
        ? { padding: 0 }
        : {
              paddingBottom: '48px',
              [theme.breakpoints.down('sm')]: {
                  paddingLeft: theme.spacing(6),
                  paddingRight: theme.spacing(6)
              }
          })
}))

type Props = {
    onClose: () => void
}

export const UpgradePlanDialog = ({ onClose }: Props) => {
    const params = useQueryParams()
    const initialStep =
        Number(params.get('upgradePlanStep')) === 1 ? SCREEN.SelectSeats : SCREEN.SelectPlan
    const [step, setStep] = useState(initialStep)
    const history = useHistory()

    const { usedUsersSeats, userSeatsQuota, billingPeriod, isTrialPlan, planName } =
        useServiceUsage()

    const [errorMessage, setErrorMessage] = useState('some text')

    const { isMobileView } = useWindowView()

    const paymentError = useBoolean()
    const trialDialog = useBoolean()
    const isLoading = useBoolean()

    const {
        bucket,
        plans,
        stiggLoading,
        updateBucket,
        availablePlanIds,
        revalidateStiggPaywall,
        brandKit
    } = useStigg()

    const { period, planId, totalLicenses } = bucket

    const {
        startCheckout,
        updatePlan,
        startTrial,
        submitStripeForm,
        clientSecret,
        stripePromise,
        estimateSubscriptionData,
        estimateSubscription,
        isEstimationLoading,
        isLoading: isPaymentLoading,
        fetchUsersToDowngrade,
        usersToDowngrade,
        applyCoupon,
        couponResData,
        isCouponApplying,
        chargeCustomer,
        taxes,
        setBillingEmail,
        billingEmailError,
        billingEmail,
        isStartCheckoutLoading
    } = usePayment({ totalLicenses })

    const selectedPlan = plans?.[bucket.planId]

    const handleError = (message?: string) => {
        setErrorMessage(message || '')
        paymentError.setTrue()
    }

    useEffect(() => {
        if (Number(params.get('upgradePlanStep')) !== 1) return

        const planId = params.get('planId') || brandKit?.planId
        const period =
            params.get('billingPeriod') || brandKit?.billingPeriod || BillingPeriod.Annually

        if (!planId) return

        history.replace(
            filterURLParams(history.location, ['upgradePlanStep', 'planId', 'billingPeriod'])
        )
        updateBucket({
            planId,
            period,
            totalLicenses: usedUsersSeats
        })
    }, [updateBucket, params, period, planId, usedUsersSeats, brandKit, history])

    useEffect(() => {
        revalidateStiggPaywall()
    }, [revalidateStiggPaywall])

    return (
        <>
            <PaymentErrorDialog
                onClose={paymentError.setFalse}
                isOpen={paymentError.isTrue}
                onClick={paymentError.setFalse}
                errorMessage={errorMessage}
            />

            <Dialog
                open
                disableAutoFocus
                onClick={e => e.stopPropagation()}
                onClose={onClose}
                fullWidth
                maxWidth={isMobileView ? 'xs' : 'lg'}
                sx={{
                    [`& .MuiPaper-root`]: {
                        maxWidth: 940
                    }
                }}
            >
                {(isPaymentLoading || stiggLoading) && <LinearProgress />}
                <UpgradeDialogHeader
                    onClickBack={() => setStep(step => step - 1)}
                    onClose={onClose}
                    activeStep={step}
                />

                <StyledDialogContent noPadding={step !== SCREEN.SelectPlan && isMobileView}>
                    <TabPanel value={step} index={SCREEN.SelectPlan}>
                        {plans && (
                            <SelectPlan
                                availablePlanIds={availablePlanIds}
                                setStep={setStep}
                                bucket={bucket}
                                updateBucket={updateBucket}
                                isLoading={isPaymentLoading}
                                plans={plans}
                                updatePlan={updatePlan}
                                startTrial={planId => {
                                    isLoading.setTrue()

                                    startTrial(planId).then(() => {
                                        logToAnalytics('send_request_for_starting_trial', {
                                            source: 'upgrade_plan_dialog'
                                        })
                                        onClose()
                                        trialDialog.setTrue()
                                    })
                                }}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={step} index={SCREEN.SelectSeats}>
                        <SelectSeats
                            setStep={setStep}
                            updateBucket={updateBucket}
                            startCheckout={startCheckout}
                            initialUserSeatsToBuy={usedUsersSeats}
                            bucket={bucket}
                            plan={selectedPlan}
                            activePlanPeriod={billingPeriod}
                            userSeatsQuota={userSeatsQuota}
                            isTrialPlan={isTrialPlan}
                            usedUsersSeats={usedUsersSeats}
                            fetchUsersToDowngrade={fetchUsersToDowngrade}
                            usersToDowngrade={usersToDowngrade}
                        />
                    </TabPanel>
                    <TabPanel value={step} index={SCREEN.PaymentConfirmation}>
                        <Payment
                            currentPlanName={planName}
                            billingEmailError={billingEmailError}
                            taxes={taxes}
                            chargeCustomer={chargeCustomer}
                            setBillingEmail={setBillingEmail}
                            applyCoupon={applyCoupon}
                            couponResData={couponResData}
                            isCouponApplying={isCouponApplying}
                            bucket={bucket}
                            setStep={setStep}
                            stripePromise={stripePromise}
                            plan={selectedPlan}
                            confirmPlanUpdate={() => {
                                // if user added extra seats to basic plan we add object to addons otherwise it must be empty

                                return updatePlan({
                                    planId,
                                    unitQuantity: totalLicenses,
                                    billingPeriod: period
                                })
                                    .then(onClose)
                                    .catch(message => handleError(message))
                            }}
                            clientSecret={clientSecret}
                            isLoading={isPaymentLoading}
                            submitStripeForm={submitStripeForm}
                            selectedPlan={selectedPlan}
                            onError={handleError}
                            estimateSubscriptionData={estimateSubscriptionData}
                            estimateSubscription={estimateSubscription}
                            isEstimationLoading={isEstimationLoading}
                            startCheckout={startCheckout}
                            billingEmail={billingEmail}
                            isStartCheckoutLoading={isStartCheckoutLoading}
                        />
                    </TabPanel>
                </StyledDialogContent>
            </Dialog>
        </>
    )
}

type PanelProps = {
    children: ReactNode
    value: number
    index: number
}

const TabPanel = ({ children, value, index }: PanelProps) => {
    return <Box hidden={value !== index}>{value === index && children}</Box>
}
