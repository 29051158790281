import { useRef } from 'react'

import {
    Box,
    capitalize,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Stack,
    Typography
} from '@mui/material'

import { useBoolean } from 'hooks'
import { type BrandKitFontSizeType, type BrandKitType } from 'app/types'

type Props = {
    fontSize: BrandKitType['fontSize']
    onUpdate: (_fontSizeId: BrandKitFontSizeType['id']) => void
}
export const FontSize = ({ fontSize, onUpdate }: Props) => {
    const open = useBoolean()

    const anchorEl = useRef<HTMLDivElement>(null)

    const activeSize = fontSize.data.find(item => item.id === fontSize.active)?.id

    if (!activeSize) return null

    return (
        <Stack spacing={1}>
            <Typography sx={{ color: 'rgba(9, 12, 16, 0.6)', fontSize: 14 }}>
                Default size
            </Typography>

            <Box
                ref={anchorEl}
                height={54}
                width={150}
                sx={{
                    fontSize: 16,
                    borderRadius: '4px 4px 0px 0px',
                    borderBottom: '1px solid rgba(9, 12, 16, 0.10)',
                    background: 'rgba(0, 0, 0, 0.06)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#090C10',
                    cursor: 'pointer',
                    '&:hover': { background: 'rgba(0, 0, 0, 0.09)' }
                }}
                onClick={open.setTrue}
            >
                {capitalize(activeSize.toLowerCase().replaceAll('_', ' '))}
            </Box>

            <Popover open={open.isTrue} anchorEl={anchorEl.current}>
                <ClickAwayListener onClickAway={open.setFalse}>
                    <Paper sx={{ width: 150 }}>
                        <MenuList sx={{ padding: 0 }}>
                            {fontSize.data.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => {
                                        onUpdate(item.id)
                                        open.setFalse()
                                    }}
                                >
                                    {capitalize(item.id.toLowerCase().replaceAll('_', ' '))}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popover>
        </Stack>
    )
}
