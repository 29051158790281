import { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, ClickAwayListener, IconButton, styled, Typography } from '@mui/material'

import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'

import { SpacedGroup } from 'UI/Components'
import { enGBcode, enUScode } from 'UI/Routes/quick-guidde/hooks'

import { ReactComponent as Premium } from 'assets/icons/workspace_premium.svg'

import { delay, getTimeString, logToAnalytics } from 'modules'
import { showPlanDialog } from 'ducks'

import { type SortedAudioConfigsType, type SpeakerType } from 'app/types'
import { useServiceUsage } from 'hooks'

const enum playMode {
    PLAY = 'play',
    STOP = 'stop'
}

export const getFirstSpeaker = (
    speakers: SortedAudioConfigsType['speakers'],
    gender: string,
    langCode: string
) => {
    return speakers[langCode]?.options?.find(speaker => {
        return (
            speaker.gender.toLowerCase() === gender.toLowerCase() &&
            speaker.langCode.toLowerCase() === langCode.toLowerCase()
        )
    })
}

const Card = styled(Box)({
    backgroundColor: 'white',
    borderRadius: '4px',
    width: 85,
    height: '130px',
    border: 'solid transparent 1px',
    '&:hover': {
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 20px 0 rgba(0, 0, 0, 0.12)'
    }
})

const StyledIconButton = styled(IconButton)({
    marginRight: '4px !important',
    color: 'white'
})

const playStopIconStyles = {
    width: '22px;',
    height: '22px',
    cursor: 'pointer'
}

const playingBorder = '1px solid #CB0000'

type Props = {
    onClose: () => void
}

export const T2sBlockedMessage = ({ onClose }: Props) => {
    const dispatch = useDispatch()

    const { planName } = useServiceUsage()

    const audioRef = useRef<HTMLMediaElement | null>(null)

    // current playing time
    const [currentTime, setCurrentTime] = useState('00:00')
    // used to maintain the current playing state
    const [playStatus, setPlayStatus] = useState<playMode>(playMode.STOP)
    // used for toggling the play pause icon for a specific speaker
    const [playSpeaker, setPlaySpeaker] = useState<SpeakerType | null>(null)

    const { speakers } = useSelector(state => state.configs.audioConfigs)

    const figures = useMemo(() => {
        if (!Object.values(speakers || {}).length) return null

        const male = getFirstSpeaker(speakers, 'male', enGBcode)
        const female = getFirstSpeaker(speakers, 'female', enUScode)

        if (!female || !male) return null

        return {
            female,
            male
        }
    }, [speakers])

    return (
        <ClickAwayListener
            onClickAway={() => {
                audioRef?.current?.pause()
                setPlayStatus(playMode.STOP)
                setCurrentTime('00:00')
            }}
        >
            <Box
                data-test="editor-T2sBanner"
                data-intercom="editor-T2sBanner"
                p="10px"
                bgcolor="#F5F5F5"
                borderRadius="4px"
                textAlign="center"
                position="relative"
            >
                <audio
                    src={playSpeaker?.sampleAudioUrl ?? ''}
                    ref={audioRef}
                    onPlay={() => setPlayStatus(playMode.PLAY)}
                    onPause={() => setPlayStatus(playMode.STOP)}
                    onTimeUpdate={(event: any) => {
                        setCurrentTime(getTimeString(Number(event?.target?.currentTime)).toString())
                    }}
                    onEnded={() => {
                        setPlayStatus(playMode.STOP)
                        setCurrentTime('00:00')
                    }}
                />

                <Typography align="center" variant="h6">
                    Type it in
                </Typography>
                <Typography align="center">Get a natural sounding voiceover using text.</Typography>

                <SpacedGroup mt={1} spacing={1} justifyContent="center">
                    {figures &&
                        Object.values(figures).map((figure, i) => {
                            const isPlaying =
                                figure.id === playSpeaker?.id && playStatus === playMode.PLAY

                            return (
                                <Card
                                    key={i}
                                    p={1}
                                    py="4px"
                                    sx={
                                        isPlaying
                                            ? {
                                                  border: playingBorder
                                              }
                                            : undefined
                                    }
                                >
                                    <img
                                        src={figure.avatarUrl}
                                        alt={figure.name}
                                        width="60px"
                                        height="60px"
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            whiteSpace: 'nowrap',
                                            fontWeight: 700
                                        }}
                                    >
                                        <b>
                                            {figure.name},{' '}
                                            {
                                                figure.langName
                                                    .replace('(', '')
                                                    .replace(')', '')
                                                    .split(' ')[1]
                                            }
                                        </b>
                                    </Typography>
                                    <SpacedGroup ml="-5px" mt="4px">
                                        <StyledIconButton
                                            disableRipple
                                            sx={{
                                                backgroundColor: isPlaying ? '#CB0000' : '#F5F5F5',
                                                '&:hover': {
                                                    backgroundColor: isPlaying
                                                        ? '#CB0000'
                                                        : '#F5F5F5'
                                                }
                                            }}
                                            onClick={event => {
                                                event.stopPropagation()
                                                setPlaySpeaker(figure)

                                                delay(0).then(() => {
                                                    if (isPlaying) audioRef.current?.pause()
                                                    else audioRef.current?.play()
                                                })
                                            }}
                                            size="small"
                                        >
                                            {/*Toggle between play and pause button*/}
                                            {isPlaying ? (
                                                <PauseIcon sx={playStopIconStyles} />
                                            ) : (
                                                <PlayArrowIcon
                                                    style={{
                                                        ...playStopIconStyles,
                                                        color: '#000000'
                                                    }}
                                                />
                                            )}
                                        </StyledIconButton>

                                        {/*Display the current audio playing time or "sample" when not playing*/}
                                        {playStatus !== playMode.STOP &&
                                        figure.id === playSpeaker?.id ? (
                                            <Box
                                                sx={{
                                                    fontWeight: 400,
                                                    fontStyle: 'italic',
                                                    fontSize: '12px',
                                                    color: '#CB0000'
                                                }}
                                            >
                                                {currentTime}
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    fontWeight: 400,
                                                    fontStyle: 'italic',
                                                    fontSize: '12px',
                                                    color: 'rgba(9, 12, 16, 0.6)'
                                                }}
                                            >
                                                Sample
                                            </Box>
                                        )}
                                    </SpacedGroup>
                                </Card>
                            )
                        })}
                </SpacedGroup>

                <Box mt={2}>
                    <Button
                        fullWidth
                        onClick={() => {
                            logToAnalytics('voiceover_upgradeBtn_clicked', {
                                source: 'magicCapturePopup',
                                currentPlan: planName
                            })
                            dispatch(showPlanDialog('upgradePlanDialog'))
                        }}
                        variant="contained"
                        size="large"
                        startIcon={<Premium />}
                    >
                        <Box ml={1}>UPGRADE</Box>
                    </Button>
                </Box>
                <Box width="100%" display="flex" mt="10px" justifyContent="center">
                    <Typography
                        variant="caption"
                        color="secondary"
                        onClick={() => {
                            logToAnalytics('voiceover_ignoreUpgradeBtn_clicked', {
                                currentPlan: planName
                            })

                            onClose()
                        }}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        Ignore
                    </Typography>
                </Box>
            </Box>
        </ClickAwayListener>
    )
}
