import { useContext, useMemo } from 'react'

import { Stack, Tooltip } from '@mui/material'

import { generateStepIndex } from 'UI/Routes/quick-guidde/CanvasEditor'
import { ConfirmationDialog, StepsContext, VideoStatusContext } from 'UI/Components'

import { type StepType, type QuickGuiddeType } from 'app/types'
import { useAuth, useBoolean, useDataMutation } from 'hooks'
import { logToAnalytics } from 'modules'

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'

type Payload = {
    playbookId: string
    stepId: string
    action?: 'delete'
    url?: string
}

type Props = {
    playbook: QuickGuiddeType
    step: StepType
    isGoToStep: boolean
    onEdit: () => void
}
export const VerticalToolbar = ({ playbook, step, isGoToStep, onEdit }: Props) => {
    const { id: playbookId, steps, isDraft, lastUpdatedBy } = playbook

    const { displayName } = useAuth()

    const { checkVideoStatus, revalidatePlaybook, processFinished } = useContext(VideoStatusContext)

    const { stepsProcessing } = useContext(StepsContext)

    const processing = !processFinished || stepsProcessing.isTrue

    const stepIndex = generateStepIndex(steps, steps.findIndex(el => el.id === step.id) || 0)

    const deleteDialog = useBoolean()
    const editDialog = useBoolean()

    const $editStep = useDataMutation<Payload, Response, Error>(
        '/c/v1/quickguidde/editDocStep',
        'PUT'
    )

    const confirmationText = useMemo(() => {
        const author = displayName === lastUpdatedBy ? 'you' : lastUpdatedBy
        return `There are unsaved changes made by ${author}, these changes will be lost. Would you like to `
    }, [displayName, lastUpdatedBy])

    return (
        <>
            <Stack
                spacing={0.5}
                sx={{
                    width: 32,
                    position: 'absolute',
                    right: -15,
                    top: -5,
                    padding: '5px 0',
                    borderRadius: '30px',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    textAlign: 'center',
                    filter: `drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
                drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))`,
                    zIndex: 1,
                    alignItems: 'center'
                }}
            >
                <Tooltip title={processing ? 'processing in progress' : ''}>
                    <EditIcon
                        style={{
                            ...(processing && {
                                opacity: 0.5,
                                cursor: 'auto'
                            })
                        }}
                        onClick={() => {
                            if (processing) return

                            if (isDraft) {
                                editDialog.setTrue()
                                logToAnalytics('autoSave-unpublish-changes-dialog-triggered', {
                                    playbookId
                                })
                                return
                            }

                            onEdit()
                        }}
                    />
                </Tooltip>

                {steps.length > 1 && (!isGoToStep || (isGoToStep && step.url)) && (
                    <Tooltip
                        title={
                            steps?.length === 1
                                ? 'A minimum of one step is required'
                                : processing
                                  ? 'processing in progress'
                                  : ''
                        }
                    >
                        <DeleteIcon
                            style={{
                                ...((steps?.length === 1 || processing) && {
                                    opacity: 0.5,
                                    cursor: 'auto'
                                })
                            }}
                            onClick={e => {
                                if (steps?.length === 1 || !processFinished) {
                                    e.stopPropagation()
                                    return
                                }

                                deleteDialog.setTrue()
                                if (isDraft) {
                                    logToAnalytics('autoSave-unpublish-changes-dialog-triggered', {
                                        playbookId
                                    })
                                }
                            }}
                        />
                    </Tooltip>
                )}
            </Stack>
            {editDialog.isTrue && (
                <ConfirmationDialog
                    isOpen
                    title="Unpublished changes"
                    onClose={() => {
                        editDialog.setFalse()
                        logToAnalytics('autoSave-unpublished-change-dialog-clicked-cancel', {
                            playbookId
                        })
                    }}
                    onConfirm={() => {
                        onEdit()
                        logToAnalytics(
                            'autoSave-unpublished-change-dialog-clicked-proceed_editing',
                            {
                                playbookId
                            }
                        )
                    }}
                    text={`${confirmationText} proceed editing?`}
                />
            )}
            {deleteDialog.isTrue && (
                <ConfirmationDialog
                    isOpen
                    title={isDraft ? 'Unpublished changes' : 'Delete step'}
                    loading={$editStep.isLoading}
                    onClose={() => {
                        deleteDialog.setFalse()
                        if (isDraft) {
                            logToAnalytics('autoSave-unpublished-change-dialog-clicked-cancel', {
                                playbookId
                            })
                        }
                    }}
                    onConfirm={() => {
                        if (isDraft) {
                            logToAnalytics(
                                'autoSave-unpublished-change-dialog-clicked-proceed_editing',
                                {
                                    playbookId
                                }
                            )
                        }

                        $editStep
                            .mutate({
                                playbookId,
                                stepId: step.id,
                                ...(isGoToStep ? { url: '' } : { action: 'delete' })
                            })
                            .then(() => {
                                revalidatePlaybook()
                                checkVideoStatus()
                            })
                            .finally(deleteDialog.setFalse)
                    }}
                    text={
                        <>
                            {isDraft
                                ? `${confirmationText} delete `
                                : 'Are you sure you would like to delete '}

                            <>
                                {isNaN(parseFloat(stepIndex)) ? (
                                    <>
                                        the <b>{stepIndex}</b> step
                                    </>
                                ) : (
                                    <>
                                        step <b>{isGoToStep ? 'Go to' : stepIndex}</b>
                                    </>
                                )}
                                ?
                            </>
                        </>
                    }
                />
            )}
        </>
    )
}
