import { useDispatch } from 'react-redux'

import { Tooltip } from '@mui/material'
import BlurCircularIcon from '@mui/icons-material/BlurCircular'

import { toggleSpotlight } from 'ducks'

type Props = {
    hasSpotlight?: boolean
}

export const Spotlight = ({ hasSpotlight }: Props) => {
    const dispatch = useDispatch()

    return (
        <Tooltip
            title={(hasSpotlight ? 'Remove' : 'Add') + ' spotlight'}
            sx={{ cursor: 'pointer' }}
        >
            <BlurCircularIcon onClick={() => dispatch(toggleSpotlight())} />
        </Tooltip>
    )
}
