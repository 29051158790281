import { useState, memo, type CSSProperties } from 'react'

import { Input, Box, Chip, Tooltip, IconButton, styled } from '@mui/material'

import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'

const StyledChip = styled(Chip)({
    border: 'none !important',
    fontSize: '12px',
    textDecoration: 'none'
})

const InputContainer = styled(Box)({
    '& *': {
        color: '#828282',
        fontSize: '12px'
    },
    display: 'flex',
    alignItems: 'center'
})

const tagRegex = new RegExp(/^[a-zA-Z0-9\u0590-\u05fe_.-]*$/)
const forbiddenChars = ['#', '<', '>', ' ', '=', ';']

export type TagsProps = {
    onDeleteTag?: (_index: number) => void
    onSaveTag?: (tags: string) => void
    onTagClick?: (tag: string) => void
    onFocus?: () => void
    onBlur?: () => void
    tags: Array<string>
    disabled: boolean
    defaultColor?: CSSProperties['color']
    width?: CSSProperties['width']
}

export const Tags = memo(
    ({
        onDeleteTag,
        onSaveTag,
        onTagClick,
        onFocus,
        onBlur,
        tags,
        disabled,
        defaultColor = 'black',
        width = '200px'
    }: TagsProps) => {
        const [tagValue, setTagValue] = useState('')

        const handleChange = (e: any) => {
            const tag = e.target.value.trim()?.toLowerCase()

            if (!tagRegex.test(tag)) return

            setTagValue(tag)
        }

        const onKeyPress = (e: any) => {
            const key = e.key
            const tag = e.target.value.trim()?.toLowerCase()

            if (key === 'Enter' && !tags?.includes(tag)) {
                onSaveTag?.(tag)
                setTagValue('')
                return
            }

            if (forbiddenChars.includes(key)) {
                e.preventDefault()
            }
        }

        if (disabled && !tags?.length) return null

        const showClearButton = tags?.join(',') !== tagValue && tagValue !== ''

        return (
            <Box id="tags">
                {tags?.map((tag, idx) => (
                    <Tooltip title={tag} disableHoverListener={tag.length < 25} key={idx}>
                        <StyledChip
                            sx={{
                                '& svg': {
                                    color: '#2D9CDB !important',
                                    cursor: 'pointer',
                                    pointerEvents: 'auto',
                                    '&:hover': {
                                        opacity: 0.7
                                    }
                                },
                                color: onTagClick ? '#2D9CDB' : defaultColor,
                                pointerEvents: onTagClick ? 'auto' : 'none'
                            }}
                            variant="outlined"
                            size="small"
                            label={`#${tag.length < 25 ? tag : `${tag.substring(0, 24)}...`}`}
                            onDelete={disabled ? undefined : () => onDeleteTag?.(idx)}
                            onClick={() => onTagClick?.(tag)}
                            data-cy="tag-chip"
                        />
                    </Tooltip>
                ))}
                {!disabled && (
                    <InputContainer>
                        <Input
                            placeholder="+ Add tag"
                            value={tagValue.toLowerCase()}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            sx={{
                                width,
                                fontSize: '12px',
                                borderBottom: '1px solid #e0e0e0'
                            }}
                            disableUnderline
                            onKeyPress={onKeyPress}
                            data-cy="add-tag-input"
                        />
                        {tagValue && !tags?.includes(tagValue) && (
                            <IconButton
                                onClick={() => {
                                    onSaveTag?.(tagValue)
                                    setTagValue('')
                                }}
                                size="small"
                                data-cy="save-tag-btn"
                            >
                                <DoneIcon />
                            </IconButton>
                        )}
                        {showClearButton && (
                            <IconButton
                                onClick={() => setTagValue('')}
                                size="small"
                                data-cy="clear-tag-button"
                            >
                                <ClearIcon />
                            </IconButton>
                        )}
                    </InputContainer>
                )}
            </Box>
        )
    }
)
