import { paths } from 'app/paths'

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Box,
    DialogActions,
    Button,
    Link
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'

import { ACCOUNT_SETTINGS_TABS } from 'UI/Routes/account-settings/PageAccountSettings'

import { host } from 'modules'

type Props = {
    onClose: () => void
    onConfirm: () => void
    newSeatsAmount: number
    usedUsersSeats: number
    usersList: Array<string>
}

export const DowngradeConfirmationDialog = ({
    onClose,
    newSeatsAmount,
    usedUsersSeats,
    usersList,
    onConfirm
}: Props) => {
    const viewersAmount = usedUsersSeats - newSeatsAmount

    return (
        <Dialog open onClose={onClose} disableAutoFocus fullWidth maxWidth="xs">
            <DialogTitle
                style={{
                    position: 'relative',
                    paddingBottom: '12px'
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <WarningIcon style={{ color: '#f57c00' }} />
                    <Typography
                        sx={{
                            marginLeft: '8px',
                            fontSize: '16px',
                            textAlign: 'center',
                            fontWeight: 600
                        }}
                    >
                        Confirm plan change
                    </Typography>
                    <IconButton onClick={onClose} style={{ marginLeft: 'auto' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                You are about to update the number of seats to {newSeatsAmount}, while there are
                currently {usedUsersSeats} members on the team. <br />
                Due to this update, {viewersAmount} {viewersAmount === 1 ? 'member' : 'members'}{' '}
                will become {viewersAmount === 1 ? 'viewer' : 'viewers'}.
                <br />
                <b>
                    {usersList.map(email => (
                        <>
                            <br />
                            {email}
                        </>
                    ))}
                </b>
                <Box mt={2}>
                    <Link
                        sx={{
                            color: '#26c6da',
                            fontWeight: 500,
                            textDecoration: 'underline'
                        }}
                        href={`${host}${paths.accountSettings}?tab=${ACCOUNT_SETTINGS_TABS.users}`}
                        target="_blank"
                    >
                        Manage users
                    </Link>
                </Box>
            </DialogContent>
            <DialogActions sx={theme => ({ padding: theme.spacing(3), justifyContent: 'center' })}>
                <Button size="medium" color="inherit" variant="contained" onClick={onClose}>
                    Cancel
                </Button>

                <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onClose()
                        onConfirm()
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
