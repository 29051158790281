import { useState, useCallback, useMemo } from 'react'
import { useAuth } from './use-auth'

export type StorageType = 'sessionStorage' | 'localStorage'

export type GetStorageValueType<T> = {
    initialKey: string
    initialValue: T
    storage: StorageType
    orgId: string
}

const createKeyWithOrgId = (initialKey: string, orgId: string) => `${initialKey}-${orgId}`

export const getStorageValue = <T>({
    initialKey,
    initialValue,
    orgId,
    storage
}: GetStorageValueType<T>) => {
    try {
        const keyWithOrgId = createKeyWithOrgId(initialKey, orgId)

        // Get from local storage by key WITH orgId
        const valueByKeyWithOrgId = window?.[storage]?.getItem(keyWithOrgId)

        // use `valueByKeyWithOrgId` ONLY if org id exists
        if (valueByKeyWithOrgId && orgId) {
            try {
                return JSON.parse(valueByKeyWithOrgId)
            } catch (error) {
                return valueByKeyWithOrgId
            }
        } else {
            // Get from local storage by key WITHOUT orgId
            const valueByInitialKey = window?.[storage]?.getItem(initialKey)

            if (valueByInitialKey) {
                try {
                    return JSON.parse(valueByInitialKey)
                } catch (error) {
                    return valueByInitialKey
                }
            }
            return initialValue
        }
    } catch (error) {
        console.error(`[${storage} error]:`, { initialValue, initialKey }, error)

        return initialValue
    }
}

export const useStorage = <T>(initialKey: string, initialValue: T, storage: StorageType) => {
    const { orgId } = useAuth()

    const keyWithOrgId = createKeyWithOrgId(initialKey, orgId)

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        return getStorageValue({
            initialKey,
            initialValue,
            storage,
            orgId
        })
    })

    const setValue = useCallback(
        (value: T) => {
            try {
                // Save state
                setStoredValue(value)

                const key = orgId ? keyWithOrgId : initialKey
                // Save to local storage
                window?.[storage]?.setItem(key, JSON.stringify(value))
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.error(`[$${storage} set error:]`, error)
            }
        },
        [initialKey, keyWithOrgId, orgId, storage]
    )

    const removeStorageValue = useCallback(() => {
        try {
            setStoredValue(initialValue)

            const key = orgId ? keyWithOrgId : initialKey
            window?.[storage]?.removeItem(key)
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error)
        }
    }, [initialKey, initialValue, keyWithOrgId, orgId, storage])

    return useMemo(
        () => [storedValue, setValue, removeStorageValue] as const,
        [storedValue, setValue, removeStorageValue]
    )
}

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    return useStorage(key, initialValue, 'localStorage')
}

export const useSessionStorage = <T>(key: string, initialValue: T) => {
    return useStorage(key, initialValue, 'sessionStorage')
}
