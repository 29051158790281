import * as types from 'ducks/types'

import { type ActionType, type Reducer } from 'ducks/common'
import { type MyVideosType, type FacetsType, type LibraryType } from 'app/types'

type StateType = {
    myVideos: MyVideosType
    libraryPlaybooks: LibraryType
    libraryFacets: FacetsType
}

const initialLibraryState = {
    apps: [],
    tags: [],
    creators: [],
    visibility: [],
    modes: [],
    languages: [],
    spaceIds: []
}

export const initialFiltersState: StateType = {
    myVideos: {
        apps: [],
        tags: [],
        creators: [],
        visibility: [],
        modes: []
    },
    libraryPlaybooks: initialLibraryState,
    libraryFacets: {} as FacetsType
}

export const playbookFiltersReducer: Reducer<StateType> = (
    state = initialFiltersState,
    action: ActionType
): StateType => {
    switch (action.type) {
        case types.SET_MY_GUIDDES_FILTERS: {
            return { ...state, myVideos: { ...state.myVideos, ...action.payload } }
        }
        case types.SET_LIBRARY_FACET_FILTERS: {
            return { ...state, libraryFacets: { ...action.payload } }
        }
        case types.SET_LIBRARY_PLAYBOOK_FILTERS: {
            return { ...state, libraryPlaybooks: { ...action.payload } }
        }
        case types.RESET_LIBRARY_PLAYBOOK_FILTERS: {
            return {
                ...state,
                libraryPlaybooks: initialLibraryState,
                libraryFacets: {} as FacetsType
            }
        }
        case types.RESET_MY_PLAYBOOKS_FILTERS: {
            return {
                ...state,
                myVideos: initialFiltersState.myVideos
            }
        }
        case types.RESET_LIBRARY_PLAYBOOK_MODE: {
            return {
                ...state,
                libraryPlaybooks: {
                    ...state.libraryPlaybooks,
                    modes: []
                }
            }
        }
        default: {
            return state
        }
    }
}
