import { type CSSProperties } from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'

import { SCREEN } from './helpers'

import CloseIcon from '@mui/icons-material/Close'
import BackIcon from '@mui/icons-material/ArrowBackIos'

import { SpacedGroup } from 'UI/Components'
import { useWindowView } from 'hooks'

type Props = {
    onClose: () => void
    onClickBack: () => void
    activeStep: number
}

const numberedSteps = [
    {
        label: 'Team & Billing',
        value: SCREEN.SelectSeats
    },
    {
        label: 'Payment',
        value: SCREEN.PaymentConfirmation
    }
]

export const UpgradeDialogHeader = ({ onClose, activeStep, onClickBack }: Props) => {
    const { isMobileView } = useWindowView()

    const getStyle = (side: 'left' | 'right' = 'left') => {
        const style: CSSProperties = {
            position: 'absolute',
            top: isMobileView ? 20 : 25,
            [side]: isMobileView ? 15 : 25
        }

        return style
    }

    return (
        <Box
            height="85px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            borderBottom="1px solid rgba(9, 12, 16, 0.08)"
        >
            {activeStep !== SCREEN.SelectPlan && (
                <>
                    {isMobileView ? (
                        <IconButton onClick={onClickBack} size="small" style={getStyle()}>
                            <BackIcon fontSize="small" />
                        </IconButton>
                    ) : (
                        <Button
                            variant="text"
                            color="inherit"
                            startIcon={<BackIcon fontSize="small" />}
                            style={getStyle()}
                            onClick={onClickBack}
                        >
                            <Box ml={2}>
                                {activeStep === SCREEN.SelectSeats
                                    ? 'Change plan'
                                    : 'Team & Billing'}
                            </Box>
                        </Button>
                    )}
                </>
            )}

            {activeStep === SCREEN.SelectPlan ? (
                <Box display="flex" height={isMobileView ? '75px' : '100%'} alignItems="center">
                    <Typography data-test="plansDialog-title" variant="h6">
                        Choose a plan
                    </Typography>
                </Box>
            ) : (
                <SpacedGroup spacing={isMobileView ? 2 : 4}>
                    {numberedSteps.map(({ value, label }, idx) => {
                        const isActive = activeStep === value

                        return (
                            <SpacedGroup key={idx} spacing={isMobileView ? 0.5 : 1.5}>
                                <Box
                                    borderRadius="50%"
                                    width={24}
                                    height={24}
                                    bgcolor={isActive ? '#FED243' : '#F5F5F5'}
                                    color={isActive ? '#090C10' : '#BDBDBD'}
                                    fontWeight={700}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {idx + 1}
                                </Box>
                                <Typography
                                    style={{
                                        color: isActive ? '#14192B' : '#BDBDBD',
                                        fontWeight: 600,
                                        fontSize: isMobileView ? 14 : 16
                                    }}
                                >
                                    {label}
                                </Typography>
                            </SpacedGroup>
                        )
                    })}
                </SpacedGroup>
            )}
            <IconButton
                onClick={onClose}
                size="small"
                style={{
                    ...getStyle('right'),
                    transform: isMobileView ? 'translateY(-2px)' : 'none'
                }}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    )
}
