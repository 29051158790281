import { memo, useContext, useEffect, useMemo, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { VideoStatusContext } from 'UI/Components'

import { useBoolean, useWindowView } from 'hooks'
import { SERVICE_STATUS } from 'modules'
import { type StepType, Shape } from 'app/types'

const schema = {
    '20': {
        color: '#629BF2',
        text: 'Saving Graphics'
    },
    '40': {
        color: '#8A5EE8',
        text: 'Converting all Images'
    },
    '60': {
        color: '#D435D4',
        text: 'Applying Transitions'
    },
    '80': {
        color: '#EA36B5',
        text: 'Creating Voiceover'
    },
    '100': {
        color: '#EC5C73',
        text: 'Generating Video'
    }
} as const

const transition = 'left 1s linear'
const background =
    'linear-gradient(270deg, #ED725D -3.18%, #EB507D 10.44%, #EA38A7 19.82%, #EA34C0 34.64%, #E332CE 46.79%, #B439DF 58.78%, #8066EA 77.1%, #629AF1 95.88%)'

type Props = {
    initialState?: boolean
    steps: Array<StepType>
    showOverlay?: boolean
    onDone?: () => void
}

export const QGProgressBanner = memo(({ initialState, steps, showOverlay, onDone }: Props) => {
    const { services, isLoading } = useContext(VideoStatusContext)

    const qgVideoProcessingFinished =
        services.qg_video === SERVICE_STATUS.DONE || services.upload_process === SERVICE_STATUS.DONE

    const { isMobileView } = useWindowView()

    const stepDuration = useMemo(() => {
        return (
            steps.reduce((acc, step) => {
                const isVideoStep = step.layers.find(l => l.type === Shape.Video)
                // In case of video step, we take 60% from its duration, otherwise 5 seconds
                return acc + (isVideoStep ? step.duration * 0.6 : 5)
            }, 0) / steps.length
        )
    }, [steps])

    // you can configure open state as you want
    const open = useBoolean()
    const setOpen = open.set

    // ONLY for banner opening !!!
    useEffect(() => {
        if ((qgVideoProcessingFinished || isLoading) && !initialState) return

        setOpen(true)
    }, [qgVideoProcessingFinished, isLoading, setOpen, initialState])

    const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null)
    const [progress, setProgress] = useState(0)

    const { color, text } = useMemo(() => {
        // calculate the closest value/key of the schema

        // Example:
        // progress - 9, schema key - 20
        // progress - 22, schema key - 40
        // progress - 90, schema key - 100
        const key = Object.keys(schema).reduce(
            (prev, curr) => (+prev >= +progress ? prev : curr),
            Object.keys(schema)[0]
        ) as keyof typeof schema

        return schema[key]
    }, [progress])

    // progress increase
    useEffect(() => {
        if (qgVideoProcessingFinished && !initialState) return

        // Calculate the percentage step per 1 second
        const stepPercent = 100 / stepDuration / steps.length

        const id = setInterval(() => {
            setProgress(prev => {
                const newProgress = Math.min(prev + stepPercent, 90)
                if (newProgress === 90) clearInterval(id)

                return newProgress
            })
        }, 1000)

        setIntervalId(id)

        return () => {
            clearInterval(id)
        }
    }, [steps.length, qgVideoProcessingFinished, initialState, stepDuration])

    // closing banner when percentage is 100% AND status is done
    useEffect(() => {
        if (!qgVideoProcessingFinished || initialState) return
        if (progress === 0) return

        setProgress(100)

        const timeoutId = setTimeout(() => {
            setOpen(false)
            onDone?.()
            setProgress(0)
            clearTimeout(timeoutId)

            if (intervalId) clearInterval(intervalId)
        }, 1000)
    }, [progress, qgVideoProcessingFinished, intervalId, setOpen, onDone, initialState])

    if (open.isFalse) return null

    return (
        <Box
            style={
                showOverlay
                    ? {
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          top: '0',
                          left: '0',
                          backgroundColor: 'rgba(0, 0, 0, 0.6)',
                          zIndex: '1200'
                      }
                    : {}
            }
        >
            <Box
                bgcolor="rgba(9, 12, 16, 0.9)"
                borderRadius="4px"
                px={4}
                pt={2}
                pb={5}
                width={isMobileView ? '90vw' : 410}
                style={{ transform: showOverlay ? 'translateX(50%)' : 'unset' }}
            >
                <Typography
                    variant="caption"
                    style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 700 }}
                >
                    {text}
                </Typography>
                <Box position="relative">
                    {/* progress COLORED background */}
                    <Box
                        width="100%"
                        overflow="hidden"
                        mt={1}
                        height={8}
                        position="relative"
                        borderRadius="45px"
                        zIndex={2}
                        style={{ background }}
                    >
                        {/* step dots */}
                        <Box
                            px="3px"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            height={8}
                        >
                            {Array.from({ length: steps.length + 1 }, (_, i) => i).map(dot => (
                                <Box
                                    key={dot}
                                    width={4}
                                    visibility={dot === 0 ? 'hidden' : 'visible'}
                                    height={4}
                                    borderRadius="50%"
                                    bgcolor="#FFF"
                                    zIndex={2}
                                    position="relative"
                                />
                            ))}
                        </Box>
                        {/* progress NOT COLORED background */}
                        <Box
                            position="absolute"
                            top={0}
                            right={0}
                            left={`${progress}%`}
                            bgcolor="rgba(58, 61, 64, 1)"
                            height={8}
                            style={{ overflow: 'hidden', transition }}
                        />
                    </Box>
                    {/* progress vertical line */}
                    <Box
                        style={{
                            transition,
                            position: 'absolute',
                            left: `calc(${Math.min(progress, 99)}% - 1px)`,
                            top: 0,
                            width: 1,
                            height: 22,
                            background: color
                        }}
                    />
                </Box>
                {/* progress percentage text */}
                <Box width={'100%'} position="relative">
                    <Typography
                        variant="caption"
                        style={{
                            position: 'absolute',
                            left: `${Math.min(progress, 67)}%`,
                            transition,
                            top: 10,
                            color: color
                        }}
                    >
                        {Math.round(progress)}% complete
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
})
