import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Box, styled, Typography, Button, LinearProgress } from '@mui/material'

import { ColoredCircularProgress } from 'UI/Components'
import { SubmitPaymentNotification } from './SubmitPaymentNotification'

import { useServiceUsage, useStigg } from 'hooks'
import { showPlanDialog } from 'ducks'

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 6),
    borderRadius: '6px',
    border: '1px solid #cdcdcd',
    width: '445px',
    marginTop: theme.spacing(3)
}))

const Message = styled(Box)(({ theme }) => ({
    background: 'rgba(245,124,0, 0.1)',
    borderRadius: '8px',
    padding: theme.spacing(1.2),
    width: '100%',
    marginTop: theme.spacing(2)
}))

const Info = styled(Box)(({ theme }) => ({
    background: '#f5f5f5',
    borderRadius: '8px',
    padding: theme.spacing(2.2, 2),
    width: '100%',
    marginTop: theme.spacing(2)
}))

const Row = styled(Box)({
    display: 'flex',
    fontSize: '12px'
})

const RowItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0),
    '&:not(:last-child)': {
        borderRight: '1px solid #eee'
    },
    '&:not(:first-child)': {
        padding: theme.spacing(0.5, 1, 0.5, 2)
    }
}))

export const CompanyStatistic = () => {
    const dispatch = useDispatch()

    const {
        planName,
        planDescription,
        usedPercent,
        playbooksUsedPercent,
        usedPlaybooksSlots,
        usedSpacesSlots,
        usedUniqueViewers,
        usedUsersSeats,
        usedVideoMinutes,
        usedVideoPercents,
        quotaLabels,
        isTrialPlan,
        trialDurationLabel,
        isCustomPrice,
        isFreePlan,
        usersPercent,
        spacesQuota,
        uniqueViewersQuota,
        isLoading
    } = useServiceUsage()
    const { isCurrentPlanArchived, plans, planColor } = useStigg()

    const isUpgrade = isFreePlan || isTrialPlan || isCurrentPlanArchived

    const handleClick = () => {
        const target = isUpgrade ? 'upgradePlanDialog' : 'updatePlanDialog'
        dispatch(showPlanDialog(target))
    }

    const textInsteadValue = useMemo(() => {
        const allMetricsUnlimited = Object.values(quotaLabels).every(label => label === 'unlimited')
        const style = {
            ...(planColor && { color: planColor }),
            fontWeight: 600
        }

        if (isTrialPlan && allMetricsUnlimited) {
            return <Typography style={style}>Trial</Typography>
        }

        if (allMetricsUnlimited) {
            return <Typography style={style}>Unlimited</Typography>
        }

        return ''
    }, [isTrialPlan, planColor, quotaLabels])

    if (isLoading || !plans) return <LinearProgress />

    return (
        <Container>
            <SubmitPaymentNotification />
            <ColoredCircularProgress
                size={110}
                textInsteadValue={textInsteadValue}
                value={Math.max(usedPercent, usersPercent)}
                valuesColorsMap={{
                    0: '#58ee82',
                    75: '#f57c00',
                    100: '#cb0000'
                }}
            />
            {usedPercent >= 100 && (
                <Message>
                    <Typography variant="subtitle2" align="center">
                        Please upgrade for unlimited storage
                    </Typography>
                </Message>
            )}

            <Box
                mt={2}
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="subtitle2">Your plan</Typography>
                <Box style={{ padding: '1px 6px', borderRadius: '3px', background: planColor }}>
                    <Typography
                        data-test="account-settings-current-plan-banner"
                        sx={{
                            fontSize: 12,
                            color: '#fff',
                            fontWeight: 600
                        }}
                    >
                        {planName}
                    </Typography>
                </Box>
            </Box>
            <Info>
                <Typography
                    data-test="account-settings-plan-description"
                    sx={{ fontSize: 12 }}
                    align="center"
                >
                    {planDescription}. {trialDurationLabel}
                </Typography>
            </Info>
            <Box width="100%">
                <Box display="flex" mt={3} mb={1}>
                    <Row flex={0.3} />
                    <Row flex={0.45}>
                        <Typography variant="caption" color="textSecondary">
                            Used
                        </Typography>
                    </Row>
                    <Row flex={0.25}>
                        <Typography variant="caption" color="textSecondary">
                            Total
                        </Typography>
                    </Row>
                </Box>
                <TableRow
                    data-test="account-settings-plan-feature"
                    title="Video minutes"
                    used={`${usedVideoMinutes} min`}
                    total={`${quotaLabels.videoMinutesQuota} ${
                        quotaLabels.videoMinutesQuota !== 'unlimited' ? 'min' : ''
                    }`}
                    percent={usedVideoPercents}
                    showPercent={quotaLabels.videoMinutesQuota !== 'unlimited'}
                />
                <TableRow
                    title="Videos"
                    used={usedPlaybooksSlots.toString()}
                    total={quotaLabels.playbooksQuota}
                    percent={playbooksUsedPercent}
                    showPercent={quotaLabels.playbooksQuota !== 'unlimited'}
                />
                <TableRow
                    title="Users"
                    used={usedUsersSeats.toString()}
                    total={quotaLabels.userSeatsQuota}
                    percent={usersPercent}
                    showPercent={quotaLabels.userSeatsQuota !== 'unlimited'}
                />
                <TableRow
                    title="Spaces"
                    used={usedSpacesSlots.toString()}
                    total={quotaLabels.spacesQuota}
                    percent={Math.ceil((usedSpacesSlots / spacesQuota) * 100)}
                    showPercent={quotaLabels.spacesQuota !== 'unlimited'}
                />
                <TableRow
                    title="Unique viewers"
                    used={usedUniqueViewers.toString()}
                    total={quotaLabels.uniqueViewersQuota}
                    percent={Math.ceil((usedUniqueViewers / uniqueViewersQuota) * 100)}
                    showPercent={quotaLabels.uniqueViewersQuota !== 'unlimited'}
                />
            </Box>

            <Box mt={3} hidden={isCustomPrice}>
                <Button
                    data-test="account-settings-upgradePlan-button"
                    size="small"
                    variant="contained"
                    onClick={handleClick}
                >
                    {isUpgrade ? 'Upgrade plan' : 'Update plan'}
                </Button>
            </Box>
        </Container>
    )
}

const getColor = (percent: number = 0) => {
    if (percent >= 100) return '#cb0000'
    if (percent >= 75) return '#f57c00'

    return 'inherit'
}

type TableRowProps = {
    title: string
    used: string
    total: string
    percent?: number
    showPercent?: boolean
}

const TableRow = ({ title, used, total, percent, showPercent }: TableRowProps) => (
    <Row>
        <RowItem flex={0.3}>
            <Typography data-test={`${title}-item`} variant="caption">
                {title}:
            </Typography>
        </RowItem>
        <RowItem flex={0.45} style={{ color: showPercent ? getColor(percent) : 'inherit' }}>
            <Typography data-test={`${title}-used`} sx={{ fontWeight: 600 }} variant="caption">
                {used || 0}
            </Typography>
            {!!percent && showPercent && <Typography variant="caption">({percent})%</Typography>}
        </RowItem>
        <RowItem
            style={{
                textTransform: 'capitalize'
            }}
            flex={0.25}
        >
            <Typography data-test={`${title}-total`} variant="caption">
                {total}
            </Typography>
        </RowItem>
    </Row>
)
