import { memo } from 'react'
import { useSelector } from 'react-redux'

import { Button, Typography } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import StopIcon from '@mui/icons-material/Stop'

import { SpacedGroup } from 'UI/Components'
import { RedButton } from './RedButton'

import { formatTime } from 'modules'
import { useBoolean } from 'hooks'

type Props = {
    status: string
    timer: number
    startRecording: () => void
    stopRecording: () => void
    onRestart: () => void
}

export const Recorder = memo(
    ({ status, timer, startRecording, stopRecording, onRestart }: Props) => {
        const hover = useBoolean()

        const { audioConfigs } = useSelector(state => state.configs)

        return (
            <>
                {status === 'recording' && (
                    <SpacedGroup mb={2} width="100%">
                        <Button
                            startIcon={<ReplayIcon />}
                            color="inherit"
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={onRestart}
                        >
                            Restart
                        </Button>

                        <Button
                            startIcon={<StopIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth
                            size="large"
                            onClick={stopRecording}
                        >
                            Stop
                        </Button>
                    </SpacedGroup>
                )}

                <RedButton
                    active={hover.isTrue || status === 'recording'}
                    label={status === 'recording' ? formatTime(timer) : 'REC'}
                    onMouseEnter={hover.setTrue}
                    onMouseLeave={hover.setFalse}
                    onClick={() => {
                        if (status === 'recording') stopRecording()
                        else startRecording()
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{ textAlign: 'center', color: 'rgba(9, 12, 16, 0.6)' }}
                >
                    Recording is limited to {audioConfigs.s2t.maxDuration} seconds per step
                </Typography>
            </>
        )
    }
)
