import { ReactComponent as CsvIcon } from 'assets/icons/csv.svg'

import { type InsightsPeriod } from 'app/types/insights'
import { useDownloadFile } from 'hooks'
import { logToAnalytics } from 'modules'

import { type InsightTableSortFields } from './insights-utils'
import { InsightsLayoutButton } from './InsightsLayoutButton'

type Props = {
    requestBody: {
        order: 'asc' | 'desc'
        sort: InsightTableSortFields
        period: InsightsPeriod
        tz: string
        playbookId?: string
        from?: string
        to?: string
    }
    analyticsEventSource: string
}

export const InsightsExportCsvButton = ({ requestBody, analyticsEventSource }: Props) => {
    const { downloadFile, isFileDownloading } = useDownloadFile({
        url: '/ins/v1/insights/csv',
        body: requestBody,
        fileName: 'Guidde-insights',
        fileNameHeader: 'X-Guidde-Filename',
        successMessage: 'Your CSV file has been exported successfully'
    })

    return (
        <InsightsLayoutButton
            description="Export to CSV"
            isLoading={isFileDownloading}
            Icon={<CsvIcon color="#090C10" />}
            onClick={() => {
                logToAnalytics('insights_csvIcn_clicked', { source: analyticsEventSource })
                downloadFile()
            }}
        />
    )
}
