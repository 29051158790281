import { type SwitchProps, styled, Switch } from '@mui/material'

const PREFIX = 'MuiSwitch'

const classes = {
    root: `${PREFIX}-root`,
    switchBase: `${PREFIX}-switchBase`,
    thumb: `${PREFIX}-thumb`,
    track: `${PREFIX}-track`
}

const StyledSwitch = styled(Switch)(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: 26,
        height: 14,
        padding: 0,
        margin: theme.spacing(1)
    },

    [`& .${classes.switchBase}`]: {
        padding: 1,
        color: '#E0E0E0',

        '&.Mui-checked': {
            transform: 'translateX(12px) !important',
            color: theme.palette.primary.main
        }
    },

    [`& .${classes.thumb}`]: {
        width: 12,
        height: 12
    },

    [`& .${classes.track}`]: {
        borderRadius: 38,
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#EDEEEF',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    }
}))

export const IOSSwitch = ({ ...props }: SwitchProps) => {
    return <StyledSwitch disableRipple {...props} />
}
