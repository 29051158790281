import { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VideoSection } from '../video-player/video-section'

import { DeleteButton, MergeButton, SliderButton } from './SectionButtons'

import { setActivePlaybookPart, setSections } from 'ducks/actions'
import { delay, logToAnalytics } from 'modules'

export const cloneCanvas = (oldCanvas: HTMLCanvasElement) => {
    if (!oldCanvas) return null
    //create a new canvas
    const newCanvas = document.createElement('canvas')
    const context = newCanvas.getContext('2d')

    //set dimensions
    newCanvas.width = oldCanvas.width
    newCanvas.height = oldCanvas.height

    //apply the old canvas to the new one
    context!.drawImage(oldCanvas, 0, 0)

    //return the new canvas
    return newCanvas
}

type Props = {
    onUpdateTime: (_percentage: number) => void
    canvasElements: Array<HTMLCanvasElement>
    videoElement: HTMLVideoElement
}

export const EditableTimelineWithSections = memo(
    ({ onUpdateTime, canvasElements, videoElement }: Props) => {
        const dispatch = useDispatch()

        const { sections } = useSelector(state => state.videoEditor)

        const sliderRef = useRef<HTMLDivElement>(null)

        // Logic to split current section into 2 different sections at selected point
        const handleSlice = (index: number, sectionProgress: number) => {
            const newSections = [
                ...sections.slice(0, index), // before the section, which we're going to split
                {
                    ...sections[index],
                    rangeValues: [
                        sections[index].rangeValues[0],
                        sections[index].rangeValues[0] + sectionProgress
                    ]
                }, // section which ends at the selected point
                {
                    ...sections[index],
                    title: `Chapter (${index + 1})`,
                    thumbnail: '',
                    rangeValues: [
                        sections[index].rangeValues[0] + sectionProgress,
                        sections[index].rangeValues[1]
                    ]
                }, // new section, from the selected point to the end of the section
                ...sections.slice(index + 1) // everything after the selected section
            ]

            logToAnalytics('slice_chapter', { chapterIndex: index })
            dispatch(setSections(newSections))
            dispatch(setActivePlaybookPart(index))

            // clone pref canvasRef
            const prevCanvasList = canvasElements
                .map(cloneCanvas)
                .filter(Boolean) as Array<HTMLCanvasElement>

            delay(500).then(() => {
                chapterThumbsSplit(index, prevCanvasList)
            })
        }

        // add change thumb on slide move
        const chapterThumbsSplit = (
            slicedAtIndex: number,
            prevCanvasList: Array<HTMLCanvasElement>
        ) => {
            canvasElements.forEach((ref, index) => {
                const ctx = ref?.getContext('2d')

                if (!ctx) return
                if (index <= slicedAtIndex) return

                // draw the image from prev ref index
                if (prevCanvasList[index - 1]) {
                    ctx.drawImage(prevCanvasList[index - 1], 0, 0, 360, 210)
                }
            })
        }

        const [videoProgress, setVideoProgress] = useState(0)

        useEffect(() => {
            const newProgress = (target: HTMLVideoElement) => {
                const { currentTime, duration } = target
                if (!currentTime || !duration) return 0

                return +((currentTime / duration) * 100).toFixed(2)
            }

            const updateTime = (e: Event) => {
                setVideoProgress(newProgress(e.target as HTMLVideoElement))
            }

            videoElement?.addEventListener('timeupdate', updateTime)

            return () => {
                videoElement?.removeEventListener('timeupdate', updateTime)
            }
        }, [videoElement])

        if (!sections.length) return null

        return (
            <div
                ref={sliderRef}
                style={{
                    display: 'flex'
                }}
            >
                {sections.map((_, index) => {
                    const isFirst = index === 0
                    const isLast = index === sections.length - 1

                    const isOnly = sections.length === 3

                    return (
                        <VideoSection
                            key={index}
                            isEditMode={true}
                            videoProgress={videoProgress}
                            videoElement={videoElement}
                            index={index}
                            sections={sections}
                            onUpdateTime={onUpdateTime}
                            onSlice={sectionProgress => handleSlice(index, sectionProgress)}
                            sliderButton={
                                <>
                                    {!isLast && (
                                        <SliderButton
                                            index={index}
                                            timelineOffsetWidth={
                                                sliderRef?.current?.offsetWidth || 0
                                            }
                                            onUpdateTime={onUpdateTime}
                                        />
                                    )}
                                </>
                            }
                            deleteButton={
                                <>
                                    {!isOnly && !isFirst && !isLast && (
                                        <DeleteButton index={index} />
                                    )}
                                </>
                            }
                            mergeButton={
                                <>
                                    {/*We need to ignore first, last and one before the last as current section will be merged with the next*/}
                                    {!isFirst && !isLast && index !== sections.length - 2 && (
                                        <MergeButton
                                            index={index}
                                            canvasElements={canvasElements}
                                        />
                                    )}
                                </>
                            }
                        />
                    )
                })}
            </div>
        )
    }
)
