import { PublicClientApplication } from '@azure/msal-browser'
import { doc, setDoc } from 'firebase/firestore'

import { db } from 'modules'

const setUserAchievement = (uid, payload) => {
    return setDoc(doc(db, `userAchievements/${uid}`), payload, { merge: true })
}
/**
 * This module authenticates the user to microsoft services
 *
 * By: Nizar Jubran
 * Date: 14.06.2021
 */

const SCOPES = ['user.read', 'Files.Read']

const msauth = () => {
    let config
    let msalInstance
    let tokenData
    let isConnected = false
    let uid

    const setConfig = configJson => {
        if (configJson) {
            config = configJson
        }
        return returnedObject
    }

    const getConfig = () => config

    const initInstance = uidLocal => {
        uid = uidLocal

        if (config) {
            msalInstance = new PublicClientApplication(config)
            isConnected = false
        } else {
            console.error('Please add a valid config before initiating an instance')
        }
        return returnedObject
    }

    const silentLogin = async () => {
        const account = msalInstance.getAllAccounts()[0]

        const accessTokenRequest = {
            scopes: SCOPES,
            account: account
        }
        try {
            const res = await msalInstance.acquireTokenSilent(accessTokenRequest)
            await saveToken(res)
            isConnected = true
        } catch (error) {
            console.error('Error performing silent login')
            console.error(error)
            isConnected = false
        }
    }

    const saveToken = async tokenResponse => {
        tokenData = tokenResponse
        await setUserAchievement(uid, {
            oneDriveConnected: Boolean(tokenResponse)
        })
        //TODO: perhaps this should be saved in the firebase
    }

    const getTokenData = () => tokenData

    const loginWithPopup = async () => {
        if (msalInstance) {
            // if the user is already logged in then perform
            // a silent login
            if (msalInstance.getAllAccounts()[0]) {
                try {
                    await silentLogin()
                } catch (err) {
                    console.error('Error performing silent authentication')
                }
            } else {
                await handlePopupLogin()
            }
        }
        return returnedObject
    }

    const forcePopupLogin = async () => {
        if (msalInstance) await handlePopupLogin()
        return returnedObject
    }

    async function handlePopupLogin() {
        try {
            const res = await msalInstance.loginPopup({
                scopes: SCOPES
            })
            await saveToken(res)
            isConnected = true
        } catch (err) {
            isConnected = false
            console.error('Microsoft authentication failed!')
            console.error(err)
        }
        return
    }

    const isConnectedInstance = () => isConnected

    const logout = () => {
        if (msalInstance) {
            msalInstance.logout()
        } else {
            console.error('Invalid authentication instance')
        }
        return returnedObject
    }

    const logoutWithPopup = async () => {
        if (msalInstance) {
            await msalInstance.logoutPopup()
        } else {
            console.error('Invalid authentication instance')
        }
        return returnedObject
    }

    const getCurrentInstance = () => msalInstance

    const returnedObject = {
        setConfig,
        getConfig,
        initInstance,
        loginWithPopup,
        logout,
        isConnectedInstance,
        getCurrentInstance,
        getTokenData,
        logoutWithPopup,
        forcePopupLogin
    }

    return returnedObject
}

export default msauth()
