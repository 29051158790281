import { Stack, Typography } from '@mui/material'
import { DatePicker } from './DatePicker'
import { type Dayjs } from 'dayjs'

type Props = {
    startDate: Dayjs | null
    onChangeStartDate: (date: Dayjs) => void
    endDate: Dayjs | null
    onChangeEndDate: (date: Dayjs) => void
    minDate?: Dayjs
    maxDate?: Dayjs
    showToday?: boolean
    minPeriodLengthInDays?: number
    maxPeriodLengthInDays?: number
}

export const DateRangePicker = ({
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    minDate,
    maxDate,
    showToday,
    minPeriodLengthInDays,
    maxPeriodLengthInDays
}: Props) => (
    <Stack direction="row" alignItems="center">
        <Typography>Choose dates:</Typography>
        <DatePicker
            value={startDate}
            onChange={onChangeStartDate}
            maxDate={
                endDate && minPeriodLengthInDays
                    ? endDate.subtract(minPeriodLengthInDays, 'days')
                    : endDate ?? maxDate
            }
            minDate={
                maxPeriodLengthInDays && endDate
                    ? endDate.subtract(maxPeriodLengthInDays, 'days')
                    : minDate
            }
            placeholder="From"
            showToday={showToday}
        />
        <Typography>to</Typography>
        <DatePicker
            value={endDate}
            onChange={onChangeEndDate}
            minDate={
                startDate && minPeriodLengthInDays
                    ? startDate.add(minPeriodLengthInDays, 'days')
                    : startDate ?? minDate
            }
            maxDate={
                maxPeriodLengthInDays && startDate
                    ? startDate.add(maxPeriodLengthInDays, 'days')
                    : maxDate
            }
            placeholder="To"
            showToday={showToday}
        />
    </Stack>
)
