import { useParams } from 'react-router-dom'

import { ButtonBase, Stack, Typography } from '@mui/material'

import { ReactComponent as VideoCoverImage } from 'assets/images/cover.svg'

import { logToAnalytics } from 'modules'

import { type RTDBTransitionSequenceType, type WizardData } from '../wizard-utils'
import { WizardDialogContent } from './WizardDialogContent'

type Props = {
    transitions: Array<RTDBTransitionSequenceType>
    activeTransitionName: string | null
    onChangeData: (wizardData: Partial<WizardData>) => void
}

export const WizardTransitionStep = ({
    onChangeData,
    transitions,
    activeTransitionName
}: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    const activeTransition = transitions.find(
        transition => transition.name === activeTransitionName
    )

    return (
        <WizardDialogContent
            title="Choose your motion effects"
            activeStep={2}
            onChangeStep={step => onChangeData({ currentStep: step })}
            contentPaddingX={3}
            onBackButtonClick={() => logToAnalytics('wizard_motionBackBtn_clicked', { playbookId })}
            onPrimaryActionClick={() =>
                logToAnalytics('wizard_motionNextBtn_clicked', { playbookId })
            }
        >
            <Stack spacing={3} width="100%">
                <Stack width={380} height={270} sx={{ borderRadius: '6px', alignSelf: 'center' }}>
                    {activeTransition?.previewUrl ? (
                        <video
                            src={activeTransition.previewUrl}
                            autoPlay
                            style={{ borderRadius: '6px' }}
                        />
                    ) : (
                        <VideoCoverImage />
                    )}
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    {transitions.map(transition => {
                        const isSelected = transition.name === activeTransitionName

                        return (
                            <ButtonBase
                                sx={{
                                    p: 1,
                                    borderRadius: 0.5,
                                    textAlign: 'left',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    },
                                    ...(isSelected && {
                                        outline: '4px solid #FED243',
                                        backgroundColor: 'common.white',
                                        '&:hover': {
                                            backgroundColor: 'common.white'
                                        }
                                    })
                                }}
                                key={transition.name}
                                onClick={() => {
                                    onChangeData({ transitionName: transition.name })
                                    logToAnalytics('wizard_motion_clicked', {
                                        playbookId,
                                        selectedId: transition.name
                                    })
                                }}
                            >
                                <Stack direction="row" spacing={1.5} alignItems="center">
                                    <img
                                        width={60}
                                        height={60}
                                        src={transition.image}
                                        style={{
                                            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)'
                                        }}
                                    />
                                    <Typography
                                        fontSize={14}
                                        fontWeight={700}
                                        lineHeight="20px"
                                        letterSpacing={0.25}
                                        color={isSelected ? 'default' : 'rgba(9, 12, 16, 0.6)'}
                                        maxWidth={68}
                                    >
                                        {transition.label}
                                    </Typography>
                                </Stack>
                            </ButtonBase>
                        )
                    })}
                </Stack>
            </Stack>
        </WizardDialogContent>
    )
}
