import { styled, Box, CircularProgress } from '@mui/material'

import { ReactComponent as GuiddeIcon } from 'assets/icons/icon-g.svg'

const PREFIX = 'GuiddeRoundLoader'

const classes = {
    loading: `${PREFIX}-loading`,
    icon: `${PREFIX}-icon`
}

const StyledBox = styled(Box)(() => ({
    [`&.${classes.loading}`]: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    [`& .${classes.icon}`]: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))

type Props = {
    minHeight?: number
}

export const GuiddeRoundLoader = ({ minHeight = 250 }: Props) => (
    <StyledBox className={classes.loading} minHeight={minHeight}>
        <GuiddeIcon className={classes.icon} />
        <CircularProgress size={55} />
    </StyledBox>
)
