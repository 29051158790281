import { useRef } from 'react'
import { type FastFieldProps, type FormikProps, FastField } from 'formik'

import {
    type AutocompleteProps,
    type TextFieldProps,
    Autocomplete,
    Box,
    TextField,
    FormHelperText
} from '@mui/material'

import { type OptionType } from 'modules'

const isBlank = (value: string) => /^\s*$/.test(value)

// Supports subdomains and removes edge cases
export const DOMAIN_REGEXP =
    /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/

const isValidDomain = (value: string) => DOMAIN_REGEXP.test(value)

const checkDomains = (form: FormikProps<any>) => {
    return form.values?.domains?.forEach((value: string) => {
        if (!isValidDomain(value)) {
            form.setFieldError('domains', 'Domain like "domain.com" is required')
        }
    })
}

type Props = Omit<AutocompleteProps<OptionType, true, false, true>, 'renderInput' | 'options'> & {
    name: string
    options?: Array<OptionType>
    variant?: TextFieldProps['variant']
    width?: string | number
    label?: string
    disableUnderline?: boolean
    allowPaste?: boolean
    disableBorderBottom?: boolean
    pasteValidator?: (value: string) => boolean
    InputLabelProps?: object
    minHeight?: string | number
}

export const ConnectedFreeSoloInput = ({
    name,
    options = [],
    placeholder = '',
    fullWidth = false,
    width = 'auto',
    allowPaste,
    pasteValidator,
    variant,
    disabled = false,
    disableUnderline = false,
    label = '',
    InputLabelProps,
    disableBorderBottom = false,
    minHeight,
    ...rest
}: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const borderBottom =
        disableUnderline || disableBorderBottom ? 'none' : '1px solid rgba(9, 12, 16, 0.08)'

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps) => {
                checkDomains(form)
                return (
                    <Box>
                        <Autocomplete
                            sx={{
                                '& .MuiAutocomplete-input': {
                                    minWidth: '100px !important'
                                }
                            }}
                            fullWidth={fullWidth}
                            multiple
                            disabled={disabled}
                            filterSelectedOptions
                            options={options}
                            value={field.value || []}
                            onChange={(_e, value) => {
                                form.setFieldValue(
                                    field.name,
                                    Array.from(
                                        new Set(
                                            value.map(it => {
                                                if (typeof it !== 'string') return
                                                return it.toLowerCase().trim()
                                            })
                                        )
                                    )
                                )
                                form.setTouched({ [name]: true })
                            }}
                            onBlur={() => {
                                const tempValue = inputRef?.current?.value
                                if (tempValue && !isBlank(tempValue)) {
                                    form.setFieldValue(
                                        field.name,
                                        Array.from(
                                            new Set([
                                                ...(field.value.map((it: string) => {
                                                    return it.toLowerCase().trim()
                                                }) || []),
                                                tempValue.toLowerCase().trim()
                                            ])
                                        )
                                    )
                                    form.setTouched({ [name]: true })
                                }
                            }}
                            clearOnBlur={false}
                            size="small"
                            forcePopupIcon={false}
                            disableCloseOnSelect
                            freeSolo
                            style={fullWidth ? {} : { width: width ?? '300px' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    inputRef={inputRef}
                                    sx={{
                                        borderBottom,
                                        minHeight,
                                        '&:before': {
                                            borderBottom
                                        }
                                    }}
                                    variant={variant}
                                    onPaste={e => {
                                        if (!allowPaste) return

                                        const pasteValue = e.clipboardData.getData('Text').trim()

                                        const isValid = pasteValidator
                                            ? pasteValidator?.(pasteValue)
                                            : true

                                        const alreadyExists = field.value.find(
                                            (it: string) => it === pasteValue
                                        )

                                        if (!isValid || alreadyExists) return

                                        e.preventDefault()
                                        form.setFieldValue(field.name, [...field.value, pasteValue])
                                    }}
                                    label={label}
                                    placeholder={field.value.length > 0 ? '' : placeholder}
                                    margin="none"
                                    InputLabelProps={InputLabelProps}
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline,
                                        style: {
                                            padding:
                                                variant === 'filled' ? '16px 40px 8px 8px' : 'auto'
                                        },
                                        multiline: true,
                                        margin: 'none',
                                        fullWidth: true,
                                        onKeyPress: e => {
                                            const key = e.key
                                            const forbiddenChars = ['<', '>']

                                            if (forbiddenChars.includes(key)) {
                                                e.preventDefault()
                                            }
                                        }
                                    }}
                                />
                            )}
                            {...rest}
                        />

                        {meta.touched && meta.error !== undefined && (
                            <FormHelperText sx={{ color: '#cd0000' }}>{meta.error}</FormHelperText>
                        )}
                    </Box>
                )
            }}
        </FastField>
    )
}
