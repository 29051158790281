import { useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, CircularProgress, capitalize, Typography } from '@mui/material'

import {
    type VideoMetadataType,
    generateVideoStep,
    getVideoMetadata
} from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'
import { FileUploader } from 'UI/Components'

import { ReactComponent as VideoCall } from 'assets/icons/video_call.svg'

import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor/use-layers'
import { useAuth, useNotification } from 'hooks'

import { addNewStep } from 'ducks'
import { logToAnalytics, uuid } from 'modules'
import { type QuickGuiddeType } from 'app/types'

const border = '1px solid rgba(0, 0, 0, 0.12)'

type Props = {
    language: QuickGuiddeType['language']
    kind: 'intro' | 'outro'
    showInnerDivider: boolean
}

export const AddIntroOutroStep = ({ kind, language, showInnerDivider }: Props) => {
    const dispatch = useDispatch()

    const [progress, setProgress] = useState<null | number>(null)

    const videoMetadata = useRef<VideoMetadataType | null>(null)
    const { playbookId } = useParams<{ playbookId: string }>()

    const { showErrorNotification } = useNotification()

    const { extractVideoStep } = useContext(VoiceOverContext)

    const { uid } = useAuth()

    const { backgroundRectangle } = useLayers()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { windowDimensions } = steps[activeStep] || {}

    if (steps?.find(step => step.kind === kind)) return null

    return (
        <FileUploader
            accept="video/*"
            isTempStorage
            hideAttachFile
            hidePreview
            storagePath={`quickguiddes/${uid}/${playbookId}/uploads`}
            filename={`${uuid()}_source`}
            maxFileSizeInMb={10}
            onProgressChange={setProgress}
            hideProgress
            labelComponent={
                <Box
                    pt={1.5}
                    pb={2}
                    sx={{
                        height: 120,
                        minWidth: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderTop: 'none',
                        cursor: 'pointer',
                        position: 'relative',
                        color: 'black',
                        justifyContent: progress === null ? 'flex-end' : 'center',
                        borderLeft: kind === 'intro' || showInnerDivider ? border : undefined,
                        borderRight: kind === 'outro' || showInnerDivider ? border : undefined
                    }}
                >
                    {progress !== null ? (
                        <CircularProgress size={35} variant="determinate" value={progress} />
                    ) : (
                        <Box
                            onClick={() => {
                                logToAnalytics(`videoStep_${capitalize(kind)}StepIcon_clicked`, {
                                    playbookId
                                })
                            }}
                        >
                            <Box mb={2}>
                                <VideoCall />
                            </Box>
                            <Typography variant="subtitle2">{capitalize(kind)}</Typography>
                        </Box>
                    )}
                </Box>
            }
            onDone={(fileUrl, _metadata) => {
                if (!videoMetadata.current) return

                const { width, height, x, y, scale, duration } = videoMetadata.current

                const stepId = uuid()

                extractVideoStep({
                    playbookId,
                    languageCode: language?.langCode || 'en-US',
                    languageName: language?.langName || 'English',
                    stepId,
                    videoUrl: fileUrl,
                    generateThumbnailPreview: true
                })

                dispatch(
                    addNewStep({
                        kind,
                        step: generateVideoStep({
                            stepId,
                            kind,
                            windowDimensions,
                            duration,
                            fileName: _metadata.name,
                            fileUrl,
                            width,
                            height,
                            x,
                            y,
                            scale,
                            backgroundLayer: backgroundRectangle
                        })
                    })
                )

                videoMetadata.current = null
                setProgress(null)
            }}
            onFileChange={async (file, _fileId) => {
                const fileMetadata = await getVideoMetadata(file, windowDimensions, 1)
                if (fileMetadata.duration === Infinity) {
                    showErrorNotification('This file is unsupported. Please choose a different one')
                    return
                }

                videoMetadata.current = fileMetadata

                logToAnalytics('videoStep_video_imported', {
                    playbookId,
                    file,
                    activeStep: activeStep + 1
                })
            }}
        />
    )
}
