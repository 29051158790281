import { type SyntheticEvent, useRef, useState } from 'react'

import { delay, getTimeString, openLink } from 'modules'

import {
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Paper,
    styled,
    Typography,
    Popover,
    type PopoverOrigin
} from '@mui/material'

import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import { SpacedGroup } from 'UI/Components'

import { type SpeakerType } from 'app/types'
import { links } from 'app/links'

import { useBoolean } from 'hooks'

const StudioAvatar = styled(Box)({
    position: 'relative',
    textAlign: 'center',
    '&:after': {
        content: '"HQ"',
        width: 23,
        position: 'absolute',
        right: 4,
        top: 16,
        color: 'white',
        background: '#CB0000',
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 700,
        borderRadius: 8
    }
})

type Props = {
    options: Array<SpeakerType>
    anchorEl: HTMLElement | null
    transformOrigin?: PopoverOrigin
    onClose: () => void
}

export const UpgradePopup = ({
    anchorEl,
    options,
    onClose,
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left'
    }
}: Props) => {
    const audioRef = useRef<HTMLMediaElement | null>(null)

    const [playSpeaker, setPlaySpeaker] = useState<SpeakerType | null>(null)
    const [currentTime, setCurrentTime] = useState(0)

    const playStatus = useBoolean()

    return (
        <>
            <audio
                src={playSpeaker?.sampleAudioUrl ?? ''}
                ref={audioRef}
                onPlay={playStatus.setTrue}
                onPause={playStatus.setFalse}
                onTimeUpdate={(event: SyntheticEvent<HTMLAudioElement, Event>) => {
                    const { currentTime } = event.target as HTMLAudioElement
                    setCurrentTime(currentTime)
                }}
                onEnded={() => {
                    playStatus.setFalse()
                    setCurrentTime(0)
                }}
            />

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disablePortal
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={transformOrigin}
                PaperProps={{
                    style: {
                        padding: 16
                    }
                }}
            >
                <ClickAwayListener disableReactTree={false} onClickAway={onClose}>
                    <Paper elevation={0} style={{ textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: 500
                            }}
                        >
                            Upgrade your account
                        </Typography>
                        <Typography>
                            Find the perfect studio voice <br /> for your video.
                        </Typography>

                        <Box
                            mt={1}
                            mb={2}
                            display="flex"
                            sx={{ gridGap: 16 }}
                            justifyContent="center"
                        >
                            {options.map(speaker => {
                                const isPlaying =
                                    playStatus.isTrue && speaker.id === playSpeaker?.id

                                const Wrapper = speaker.isStudio ? StudioAvatar : Box

                                return (
                                    <Box
                                        id={speaker.id}
                                        key={speaker.id}
                                        data-test="speaker-card"
                                        mr={0}
                                        sx={{
                                            width: 111,
                                            borderRadius: '4px',
                                            padding: '8px 4px',
                                            border: 'solid rgba(9, 12, 16, 0.08) 1px'
                                        }}
                                    >
                                        <Wrapper>
                                            <img
                                                style={{
                                                    borderColor: '#CB0000',
                                                    borderRadius: '50%',
                                                    border: '2px solid transparent'
                                                }}
                                                src={speaker.avatarUrlCompressed}
                                                width={80}
                                                height={80}
                                                alt="avatar"
                                            />
                                        </Wrapper>

                                        <SpacedGroup
                                            flexDirection="column"
                                            position="relative"
                                            spacing={1}
                                            textAlign="center"
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: '14px',
                                                    color: 'rgba(9, 12, 16, 0.6)'
                                                }}
                                            >
                                                {speaker.name}
                                            </Typography>

                                            <SpacedGroup spacing={0.5} justifyContent="center">
                                                <IconButton
                                                    sx={{
                                                        color: 'black',
                                                        backgroundColor: '#F5F5F5'
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation()
                                                        if (playSpeaker?.id !== speaker.id) {
                                                            setPlaySpeaker(speaker)
                                                        }

                                                        const audioElement = audioRef.current
                                                        if (!audioElement) return

                                                        delay(0).then(() => {
                                                            if (isPlaying) audioElement.pause()
                                                            else audioElement.play()
                                                        })
                                                    }}
                                                    size="small"
                                                >
                                                    {isPlaying ? (
                                                        <PauseIcon fontSize="medium" />
                                                    ) : (
                                                        <PlayArrowIcon fontSize="medium" />
                                                    )}
                                                </IconButton>

                                                <Box
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontStyle: 'italic',
                                                        fontSize: '14px',
                                                        color: 'rgba(9, 12, 16, 0.6)'
                                                    }}
                                                >
                                                    {isPlaying
                                                        ? getTimeString(currentTime)
                                                        : 'Sample'}
                                                </Box>
                                            </SpacedGroup>
                                        </SpacedGroup>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box width="100%" display="flex">
                            <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                onClick={() => openLink(links.contactSales)}
                            >
                                CONTACT US
                            </Button>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popover>
        </>
    )
}
