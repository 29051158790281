import { type CSSProperties } from 'react'

import {
    type DialogProps,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    styled,
    Divider,
    Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

const sizeSchema = {
    sm: '16px',
    md: '24px',
    lg: '32px'
} as const

const CloseButton = styled(IconButton)({
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '4px',
    margin: '4px',
    zIndex: 15
})

type Props = {
    maxWidth?: DialogProps['maxWidth']
    isOpen: boolean
    onClose: () => void
    onConfirm?: () => void
    loading?: boolean
    title?: string | null
    titleSize?: 'sm' | 'md' | 'lg'
    titleAlign?: 'left' | 'center' | 'right'
    content: JSX.Element
    confirmBtnText?: string
    confirmStartIcon?: JSX.Element
    closeBtnText?: string
    extraActions?: JSX.Element
    hideConfirmBtn?: boolean
    hideCloseBtn?: boolean
    showDivider?: boolean
    disabled?: boolean
    justifyActions?: CSSProperties['justifyContent']
    noPadding?: boolean
    noBackground?: boolean
}

export const CommonDialog = ({
    maxWidth = 'sm',
    isOpen,
    onClose,
    onConfirm,
    loading = false,
    title,
    titleSize = 'sm',
    titleAlign = 'center',
    content,
    closeBtnText = 'Close',
    confirmBtnText = 'Confirm',
    confirmStartIcon,
    justifyActions = 'center',
    extraActions,
    hideConfirmBtn = false,
    hideCloseBtn = false,
    showDivider = false,
    disabled = false,
    noPadding,
    noBackground
}: Props) => {
    return (
        <Dialog
            open={isOpen}
            onClose={loading ? undefined : onClose}
            fullWidth
            maxWidth={maxWidth}
            PaperProps={{
                sx: noBackground
                    ? {
                          backgroundColor: 'transparent',
                          boxShadow: 'none'
                      }
                    : {}
            }}
        >
            <>
                {loading && <LinearProgress />}
                <CloseButton aria-label="close" size="medium" onClick={onClose}>
                    <CloseIcon />
                </CloseButton>

                {title && (
                    <DialogTitle>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: sizeSchema[titleSize]
                            }}
                            align={titleAlign}
                        >
                            {title}
                        </Typography>
                    </DialogTitle>
                )}

                {showDivider && <Divider />}

                <DialogContent
                    sx={{
                        ...(noPadding && {
                            padding: 0
                        })
                    }}
                >
                    {content}
                </DialogContent>

                {(!hideCloseBtn || !hideConfirmBtn || extraActions) && (
                    <DialogActions
                        style={{
                            padding: '24px',
                            justifyContent: justifyActions
                        }}
                    >
                        {extraActions}
                        {!hideCloseBtn && (
                            <Button
                                size="medium"
                                color="inherit"
                                variant="contained"
                                onClick={onClose}
                            >
                                {closeBtnText}
                            </Button>
                        )}
                        {!hideConfirmBtn && (
                            <Button
                                startIcon={confirmStartIcon}
                                size="medium"
                                variant="contained"
                                onClick={onConfirm}
                                disabled={loading || disabled}
                            >
                                {confirmBtnText}
                            </Button>
                        )}
                    </DialogActions>
                )}
            </>
        </Dialog>
    )
}
