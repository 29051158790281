import { useCallback, useMemo } from 'react'

import { ref } from 'firebase/database'
import { collection, query, where } from 'firebase/firestore'

import { createDictionaryWord, updateDictionaryWord, deleteDictionaryWord, rtdb, db } from 'modules'

import { useAuth, useNotification, useOrganization } from 'hooks'
import { useObjectVal } from 'react-firebase-hooks/database'
import { useCollectionData } from 'react-firebase-hooks/firestore'

export const languageOptions = [
    { label: 'None', value: '' },
    { label: 'German (Germany)', value: 'de-DE' },
    { label: 'Spanish (Spain)', value: 'es-ES' },
    { label: 'Italian (Italy)', value: 'it-IT' },
    { label: 'French (France)', value: 'fr-FR' },
    { label: 'Hebrew (Israel)', value: 'iw-IL' },
    { label: 'Portuguese (Brazil)', value: 'pt-BR' },
    {
        label: 'Chinese, Mandarin (Simplified, China)',
        value: 'zh'
    }
]

const initialTypes = [
    { name: 'Competitor', value: 'competitor' },
    { name: 'Customer', value: 'customer' },
    { name: 'Feature', value: 'feature' },
    { name: 'Industry Term', value: 'industry' },
    { name: 'Product', value: 'product' },
    { name: 'Name', value: 'name' },
    { name: 'Other', value: 'other' }
]

export const labelCodes = [
    { label: 'German (DE)', value: 'de-DE' },
    { label: 'Spanish (ES)', value: 'es-ES' },
    { label: 'Italian (IT)', value: 'it-IT' },
    { label: 'French (FR)', value: 'fr-FR' },
    { label: 'Hebrew (IL)', value: 'iw-IL' },
    { label: 'Portuguese (BR)', value: 'pt-BR' },
    { label: 'English (US)', value: 'en-US' },
    { label: 'English (AU)', value: 'en-AU' },
    { label: 'English (UK)', value: 'en-GB' },
    { label: 'English (CA)', value: 'en-CA' },
    { label: 'English (IN)', value: 'en-IN' },
    { label: 'English (IE)', value: 'en-IE' },
    { label: 'English (ZA)', value: 'en-ZA' },
    {
        label: 'Chinese, Mandarin (Simplified, China)',
        value: 'zh'
    }
]

export const getLabelFromCode = code => {
    return labelCodes.find(option => option.value === code)?.label || code
}

const upperCasesDictionaries = ['product', 'customer', 'competitor', 'name']

const roleValue = 'role'

const defaultLangOption = { label: 'English', value: 'en' }

const generateData = initialData => {
    const initialPhrase = initialData.phrase.trim()

    const transformPhrase = upperCasesDictionaries.includes(initialData.description)

    const phrase = transformPhrase
        ? initialPhrase[0].toUpperCase() + initialPhrase.slice(1)
        : initialPhrase.toLowerCase()

    return Object.fromEntries(
        Object.entries({
            ...initialData,
            phrase
        }).filter(([_, val]) => val)
    )
}

export const useDictionary = () => {
    const { orgId, uid } = useAuth()
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const [userRoleId, userRoleIdLoading] = useObjectVal(ref(rtdb, `onboarding/${uid}/userRole`))

    const [userRoles = [], userRolesLoading] = useCollectionData(collection(db, 'userRoles'))

    const [customPhrases = [], customPhrasesLoading] = useCollectionData(
        query(collection(db, 'customPhrases'), where('orgId', '==', orgId))
    )

    const { defaultLanguage } = useOrganization()

    const userRoleData = userRoles.find(role => role.id === userRoleId)

    const languages = useMemo(() => {
        const result = languageOptions.find(it => defaultLanguage === it.value)

        return [defaultLangOption, result].filter(Boolean).map(it => ({
            name: it.label,
            value: it.value
        }))
    }, [defaultLanguage])

    const isLoading = useMemo(() => {
        return (
            userRolesLoading ||
            userRoleIdLoading ||
            customPhrasesLoading ||
            (languages.length > 0 && !languages.some(Boolean))
        )
    }, [userRolesLoading, userRoleIdLoading, customPhrasesLoading, languages])

    const dictionariesTypes = useMemo(() => {
        if (!userRoleData) return initialTypes

        return [...initialTypes, { ...userRoleData, value: roleValue }].sort((a, b) =>
            a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1
        )
    }, [userRoleData])

    const showError = useCallback(() => {
        showErrorNotification('Something went wrong')
    }, [showErrorNotification])

    const userRoleDataId = userRoleData?.id
    const checkToAddRoleId = useCallback(
        ({ description }) => {
            if (description !== roleValue || !userRoleDataId) return {}

            return { roleId: userRoleDataId }
        },
        [userRoleDataId]
    )

    const createPhrase = useCallback(
        data => {
            return createDictionaryWord({
                uid,
                orgId,
                ...generateData(data),
                ...checkToAddRoleId(data)
            })
                .then(() => showSuccessNotification('Item was added'))
                .catch(showError)
        },
        [orgId, uid, checkToAddRoleId, showError, showSuccessNotification]
    )

    const updatePhrase = useCallback(
        data => {
            return updateDictionaryWord({
                ...generateData({ ...data, orgId }),
                ...checkToAddRoleId(data)
            })
                .then(() => showSuccessNotification('Word was changed'))
                .catch(showError)
        },
        [orgId, checkToAddRoleId, showError, showSuccessNotification]
    )

    const deletePhrase = useCallback(
        deleteId => {
            return deleteDictionaryWord(deleteId)
                .then(() => showSuccessNotification('Word was removed'))
                .catch(showError)
        },
        [showError, showSuccessNotification]
    )

    return useMemo(
        () => ({
            isLoading,
            dictionary: customPhrases,
            dictionariesTypes,
            languageOptions: languages,
            createPhrase,
            updatePhrase,
            deletePhrase
        }),
        [
            isLoading,
            customPhrases,
            dictionariesTypes,
            languages,
            createPhrase,
            updatePhrase,
            deletePhrase
        ]
    )
}
