import { memo, useEffect } from 'react'

import { Box, Button, Typography, styled } from '@mui/material'

import { SpacedGroup } from 'UI/Components'
import { negativeAnswersList } from 'UI/Components/VideoReactions'

import { type UseBooleanType, reactionValues, useBoolean, useSetPlaybookReaction } from 'hooks'

const Title = styled(Typography)({
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    width: '100%',
    position: 'relative'
})

const RatherNot = styled('span')({
    fontSize: '12px',
    color: 'inherit',
    fontWeight: 'bold',
    position: 'absolute',
    paddingRight: '24px',
    right: 0,
    top: 0,
    cursor: 'pointer'
})

const NegativeAnswer = styled(Box)({
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '5%',
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), #000)',
    right: 0
})

const ButtonNegative = styled(Button)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    maxWidth: '150px',
    padding: '6px 12px',
    height: '60px',
    backdropFilter: 'blur(10px)',
    '&:hover': {
        backgroundColor: '#fff',
        color: theme.palette.text.primary
    }
}))

type Props = {
    showReactions?: UseBooleanType
    playbookId: string
    width: number
    height: number
    showFinishStep: boolean
    /**
     * The distance between the reactions element and video element - in order to
     * calculate dynamically the position of the answers overlay
     */
    distance: number
}

export const VideoReactionsAnswers = memo(
    ({ showReactions, playbookId, showFinishStep = false, width, height, distance }: Props) => {
        const { setUserReaction } = useSetPlaybookReaction(playbookId)

        const finishStep = useBoolean(showFinishStep)

        const handleClick = (e: any, result: number = 0) => {
            e.persist()
            setUserReaction({
                reaction: reactionValues.notHelpful,
                result
            }).then(finishStep.setTrue)
        }

        const finishStepIsActive = finishStep.isTrue
        const setFinishStet = finishStep.setFalse
        const setShowReactions = showReactions?.setFalse

        useEffect(() => {
            let timeout: ReturnType<typeof setTimeout>
            if (finishStepIsActive) {
                timeout = setTimeout(() => {
                    if (setShowReactions) {
                        setShowReactions()
                    }
                    setFinishStet()
                    clearTimeout(timeout)
                }, 1500)
            }

            return () => {
                if (timeout) clearTimeout(timeout)
            }
        }, [finishStepIsActive, setFinishStet, setShowReactions])

        const containerStyles = {
            bottom: distance - 10,
            height: `${height}px`,
            width: `${width}px`
        }

        if (finishStep.isTrue) {
            return (
                <NegativeAnswer sx={{ ...containerStyles, paddingBottom: '10%' }}>
                    <Title>Thank you for your feedback!</Title>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#fff',
                            textAlign: 'center'
                        }}
                    >
                        We are working hard to avoid mismatches
                    </Typography>
                </NegativeAnswer>
            )
        }

        if (showReactions?.isFalse) return null

        return (
            <NegativeAnswer sx={containerStyles}>
                <Box m={2} width="100%">
                    <Title>
                        Ouch! Would you mind tell us why?
                        <RatherNot onClick={handleClick}>I rather not</RatherNot>
                    </Title>
                </Box>
                <SpacedGroup>
                    {negativeAnswersList.map((text, i) => (
                        <ButtonNegative
                            variant="contained"
                            key={i}
                            onClick={e => handleClick(e, i + 1)}
                        >
                            {text}
                        </ButtonNegative>
                    ))}
                </SpacedGroup>
            </NegativeAnswer>
        )
    }
)
