import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Typography
} from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import CheckIcon from '@mui/icons-material/CheckOutlined'

import { ConfirmationDialog, FullScreenLoader, SpacedGroup, WorkspaceLogo } from 'UI/Components'

import { type WorkspaceType } from './DesktopWorkspacePanel'

import { useAuth, useBoolean, useDataMutation, useWindowView } from 'hooks'
import { logToAnalytics, sendSoleMessageToExt } from 'modules'
import { resetAppAuth } from 'ducks'
import { paths } from 'app/paths'

type Props = {
    isActive: boolean
    canLeaveWorkspace: boolean
    menuIconColor?: string
    workspace: WorkspaceType
    onFailure?: () => void
}

export const notifyExtension = () => {
    sendSoleMessageToExt({
        msg: { type: 'WEBAPP/SWITCH_WORKSPACE' }
    })
}

export const WorkspaceListItem = ({
    workspace,
    menuIconColor,
    isActive,
    canLeaveWorkspace,
    onFailure = () => {}
}: Props) => {
    const history = useHistory()

    const { isMobileView } = useWindowView()
    const showSwitch = useBoolean(isMobileView)
    const dispatch = useDispatch()

    const { uid } = useAuth()

    const [workspaceToLeave, setTeamToLeave] = useState<WorkspaceType | null>(null)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const $switchWorkspace = useDataMutation<void, {}, Error>(
        `/c/v1/workspaces/${workspace.orgId}`,
        'PUT',
        {
            onFailure
        }
    )

    const $leaveWorkspace = useDataMutation<void, {}, Error>(
        `/c/v1/workspaces/${workspace.orgId}/leave`,
        'DELETE',
        {
            onFailure
        }
    )

    return (
        <>
            <ListItem
                data-test="teamDiscovery_workspaceListItem"
                dense
                key={workspace.orgId}
                disableGutters
                onMouseEnter={showSwitch.setTrue}
                onMouseLeave={showSwitch.setFalse}
                sx={{
                    p: 1,
                    pl: isMobileView ? 2 : 1,
                    pr: isMobileView ? '12px' : 2,
                    width: '100%',
                    height: 56,
                    backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : undefined
                }}
            >
                <WorkspaceLogo
                    src={workspace.logo}
                    testIdentified="teamDiscovery_workspaceLogo"
                    size={isMobileView ? 40 : 36}
                />
                <ListItemText
                    sx={{
                        marginLeft: isMobileView ? 1 : '12px',
                        overflow: 'hidden',
                        '& .MuiTypography-root': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }
                    }}
                    primary={
                        isActive ? (
                            <Typography
                                data-test="teamDiscovery_yourWorkspace_txt"
                                noWrap
                                variant="caption"
                                style={{ color: 'rgba(9, 12, 16, 0.60)' }}
                            >
                                Your workspace
                            </Typography>
                        ) : (
                            <Typography
                                data-test="teamDiscovery_firstWorkspace"
                                style={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: '#000'
                                }}
                            >
                                {workspace.name}
                            </Typography>
                        )
                    }
                    secondary={
                        isActive ? (
                            <Typography
                                data-test="teamDiscovery_yourWorkspace"
                                style={{
                                    fontSize: isMobileView ? 14 : 16,
                                    color: '#000',
                                    fontWeight: 600
                                }}
                            >
                                {workspace.name}
                            </Typography>
                        ) : (
                            <>
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: '#2D9CDB',
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        visibility: showSwitch.isTrue ? 'visible' : 'hidden'
                                    }}
                                    onClick={() => {
                                        logToAnalytics('teamDiscovery_switchButton_clicked', {
                                            uid,
                                            workspaceId: workspace.orgId,
                                            workspaceName: workspace.name
                                        })

                                        // The next EP will trigger roles to be updated on BE and
                                        // FE will read those changes before the request will end
                                        // and this crashes the page. To avoid this we reset roles
                                        // so users will see the guidde loader, as if he opens the
                                        // app for the first time; and we set flag to the store that
                                        // we use to prevent setting new token to the state and just
                                        // refresh the page
                                        $switchWorkspace.mutate()
                                        notifyExtension()
                                        dispatch(
                                            resetAppAuth({
                                                refreshPageOnTheNextTokenUpdate: true
                                            })
                                        )
                                        history.push(paths.home)
                                    }}
                                >
                                    Switch
                                </Typography>
                            </>
                        )
                    }
                />
                <SpacedGroup>
                    {isActive && (
                        <CheckIcon
                            style={{
                                color: 'rgba(28, 27, 31, 1)',
                                width: 24,
                                height: 24
                            }}
                        />
                    )}
                    {canLeaveWorkspace && (
                        <IconButton
                            edge="end"
                            size="small"
                            onClick={e => setAnchorEl(e.currentTarget)}
                        >
                            <MoreVertIcon
                                fontSize="small"
                                style={{ color: menuIconColor || 'rgba(28, 27, 31, 1)' }}
                            />
                        </IconButton>
                    )}
                </SpacedGroup>
            </ListItem>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disablePortal={false}
                onClose={() => {
                    if ($leaveWorkspace.isLoading) return
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <List style={{ width: 240, padding: 8 }}>
                    <ListItemButton
                        divider
                        style={{ padding: 8 }}
                        onClick={() => {
                            logToAnalytics('teamDiscovery_leaveButton_clicked', {
                                uid,
                                workspaceId: workspace.orgId,
                                workspaceName: workspace.name
                            })
                            setTeamToLeave(workspace)
                        }}
                        disabled={$leaveWorkspace.isLoading}
                    >
                        <ListItemText
                            primary={
                                <Typography style={{ fontWeight: 500 }}>
                                    Leave this workspace
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </List>
            </Popover>
            {workspaceToLeave && (
                <ConfirmationDialog
                    title="Leave Workspace"
                    text={
                        <Typography>
                            Are you sure you want to leave <b>{workspaceToLeave.name}</b>?
                        </Typography>
                    }
                    onClose={() => setTeamToLeave(null)}
                    isOpen={true}
                    onConfirm={() => {
                        // The next EP will trigger roles to be updated on BE and
                        // FE will read those changes before the request will end
                        // and this crashes the page. To avoid this we reset roles
                        // so users will see the guidde loader, as if he opens the
                        // app for the first time; and we set flag to the store that
                        // we use to prevent setting new token to the state and just
                        // refresh the page
                        $leaveWorkspace.mutate()
                        notifyExtension()
                        resetAppAuth({ refreshPageOnTheNextTokenUpdate: true })
                        history.push(paths.home)
                    }}
                />
            )}

            <FullScreenLoader
                title="Switching"
                isOpen={$switchWorkspace.isLoading || $leaveWorkspace.isLoading}
            />
        </>
    )
}
