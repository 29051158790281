import { ListItemText, Box } from '@mui/material'

import { type IconType } from 'app/menu'

type Props = {
    text: string
    Icon: IconType
    isActive?: boolean
}

export const MobileItemBody = ({ text, Icon, isActive = false }: Props) => (
    <Box display="flex" width="100%" alignItems="center">
        <Box minWidth="25px" mr={2}>
            <Icon style={{ color: isActive ? '#cd0000' : 'rgba(130, 130, 130, 1)' }} />
        </Box>
        <ListItemText style={{ color: 'rgba(130, 130, 130, 1)' }}>{text}</ListItemText>
    </Box>
)
