import { useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, capitalize } from '@mui/material'
import { ReactComponent as VideoCall } from 'assets/icons/video_call.svg'

import { FileUploader } from 'UI/Components'
import { getVideoMetadata } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'

import { logToAnalytics, uuid } from 'modules'
import { overwriteStep } from 'ducks'
import { useAuth, useBoolean, useNotification } from 'hooks'
import { type QuickGuiddeType, Shape } from 'app/types'

type MetadataType = {
    width: number
    height: number
    duration: number
    scale: number
    x: number
    y: number
}

type Props = {
    language: QuickGuiddeType['language']
}

export const VideoUploader = ({ language }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { showErrorNotification } = useNotification()

    const { extractVideoStep } = useContext(VoiceOverContext)

    const { uid } = useAuth()

    const loading = useBoolean()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { kind, windowDimensions, layers } = steps[activeStep] || {}

    const videoMetadata = useRef<MetadataType | null>(null)

    if (kind !== 'intro' && kind !== 'outro') return null

    return (
        <FileUploader
            accept="video/*"
            isTempStorage
            hideAttachFile
            hidePreview
            storagePath={`quickguiddes/${uid}/${playbookId}/uploads`}
            filename={`${uuid()}_source`}
            maxFileSizeInMb={10}
            hideProgress={loading.isFalse}
            labelComponent={
                loading.isFalse && (
                    <Box
                        width={32}
                        height={32}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => {
                            logToAnalytics(
                                `videoStep_${capitalize(kind)}Modal_cameraIcon_clicked`,
                                { playbookId }
                            )
                        }}
                    >
                        <VideoCall />
                    </Box>
                )
            }
            onDone={(fileUrl, _metadata) => {
                if (!videoMetadata.current) return

                const { width, height, x, y, scale, duration } = videoMetadata.current

                extractVideoStep({
                    playbookId,
                    languageCode: language?.langCode || 'en-US',
                    languageName: language?.langName || 'English',
                    stepId: steps[activeStep].id,
                    videoUrl: fileUrl,
                    generateThumbnailPreview: true
                })

                dispatch(
                    overwriteStep({
                        newStep: {
                            ...steps[activeStep],
                            duration: Math.ceil(duration),
                            layers: [
                                ...layers.filter(layer => layer.type !== Shape.Video),
                                {
                                    type: Shape.Video,
                                    id: uuid(),
                                    sourceVideoUrl: fileUrl,
                                    width,
                                    height,
                                    x,
                                    y,
                                    scaleX: scale,
                                    scaleY: scale,
                                    start: 0,
                                    end: duration,
                                    sourceDuration: duration,
                                    generateArtifacts: true as const,
                                    sourceVideoThumbnailPreview: '',
                                    isBackground: true
                                }
                            ]
                        }
                    })
                )

                videoMetadata.current = null
                loading.setFalse()
            }}
            onFileChange={async (file, _fileId) => {
                const fileMetadata = await getVideoMetadata(file, windowDimensions, 1)
                if (fileMetadata.duration === Infinity) {
                    showErrorNotification('This file is unsupported. Please choose a different one')
                    return
                }

                videoMetadata.current = fileMetadata

                logToAnalytics('videoStep_video_imported', {
                    playbookId,
                    file,
                    activeStep: activeStep + 1
                })
            }}
        />
    )
}
