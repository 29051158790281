import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
    type ButtonProps,
    type SxProps,
    Box,
    Button,
    ClickAwayListener,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    styled
} from '@mui/material'

import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import ComputerIcon from '@mui/icons-material/Computer'

import GoogleDriveIcon from 'assets/icons/google-drive.svg'
import OneDriveIcon from 'assets/icons/one-drive.svg'
import ZoomIcon from 'assets/icons/zoom.svg'
import YoutubeIcon from 'assets/icons/youtube-grey.svg'
import { ReactComponent as IconRecordMono } from 'assets/icons/icon-record-mono.svg'

import { VideoUploader } from './VideoUploader'
import { VideoRecorder } from './VideoRecorder'
import { GoogleDriveUploader } from './GoogleDriveUploader'
import { ZoomUploader } from './ZoomUploader'
import { YouTubeUploader } from './YouTubeUploader'
import { OneDriveUploader } from './OneDriveUploader'

import { useBoolean, useServiceUsage, useQuery } from 'hooks'

import { showPlanNotifications } from 'ducks'

const PREFIX = 'NewPlaybook'

const classes = {
    menuItem: `${PREFIX}-menuItem`,
    itemIcon: `${PREFIX}-itemIcon`,
    imgIcon: `${PREFIX}-imgIcon`
}

const StyledPaper = styled(Paper)({
    [`& .${classes.menuItem}`]: {
        minHeight: '3rem',
        padding: 0,
        '& > div, & > label, & > button': {
            padding: '0.25rem 1rem',
            alignSelf: 'stretch',
            width: '100%'
        }
    },
    [`& .${classes.itemIcon}`]: {
        minWidth: '35px',
        color: 'black'
    },
    [`& .${classes.imgIcon}`]: {
        width: '20px'
    }
})

export type NewPlaybookProps = {
    label?: string
    showIcon?: boolean
    variant?: ButtonProps['variant']
    showRecordOption?: boolean
    StartIcon?: any
    onUploadCallback?: (playbookIds: Array<string>, batchUploadId?: string) => void
    color?: ButtonProps['color'] | 'default'
    isExtensionInstalled?: boolean
}

type YoutubeResponseType = {
    channelId: string
    orgId: string
    uploadsPlayList: string
}

const queriesForAutoOpeningMenu = ['zoomDialog', 'youtubeDialog']

export const NewPlaybook = ({
    label,
    variant = 'contained',
    showIcon = true,
    showRecordOption = true,
    StartIcon = AddToPhotosIcon,
    onUploadCallback,
    color = 'primary',
    isExtensionInstalled = false
}: NewPlaybookProps) => {
    const anchorEl = useRef<HTMLDivElement | null>(null)
    const { search: locationQuery } = useLocation()

    const isQGRecording = useSelector(state => state.extensionStatus.qgIsRecording)

    const {
        playbookCreatingLocked,
        isFreePlan,
        hasGdrive,
        hasOneDrive,
        hasYoutube,
        hasZoom,
        hasVideoUploads
    } = useServiceUsage()

    const hide = useBoolean()
    const menu = useBoolean()

    const isRecordVideoSelected = useBoolean()

    const dispatch = useDispatch()

    const isAllIntegrationsLocked =
        !hasGdrive && !hasOneDrive && !hasYoutube && !hasZoom && !hasVideoUploads

    const toggleMenu = () => {
        if (playbookCreatingLocked) {
            dispatch(
                showPlanNotifications({
                    freePlanLimitation: isFreePlan,
                    paidPlanLimitation: !isFreePlan
                })
            )
            return
        }

        // change component visibility instead removing from DOM
        if (menu.isTrue || hide.isTrue) {
            hide.toggle()
            return
        }

        menu.toggle()
    }

    const $youtubeSettings = useQuery<YoutubeResponseType>('/c/v1/get-yt-settings', {
        method: 'POST'
    })

    const isScreenCaptureSupported = isExtensionInstalled

    const openMenu = menu.setTrue
    // modal has to be opened after connecting into zoom/youtube
    useEffect(() => {
        if (queriesForAutoOpeningMenu.some(it => locationQuery?.includes(it))) {
            openMenu()
        }
    }, [locationQuery, openMenu])

    useEffect(() => {
        // close opened upload menu if it isn't closed and show dialog that user can't upload anymore
        if (!playbookCreatingLocked || menu.isFalse) return

        menu.setFalse()

        dispatch(
            showPlanNotifications({
                freePlanLimitation: isFreePlan,
                paidPlanLimitation: !isFreePlan
            })
        )
    }, [dispatch, isFreePlan, menu, playbookCreatingLocked])

    const isYoutubeConnected = Boolean($youtubeSettings.data?.channelId)

    const buttonColor = color === 'default' ? undefined : color
    const defaultColorStyles: SxProps | undefined =
        color === 'default'
            ? {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  color: 'common.black',
                  '&:hover': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      background: 'rgba(0, 0, 0, 0.04)'
                  }
              }
            : undefined

    if (isAllIntegrationsLocked && isScreenCaptureSupported && showRecordOption) {
        // when all integrations locked no need to show popup, just open new video recording
        return (
            <VideoRecorder
                labelComponent={
                    <Button
                        disabled={isQGRecording}
                        component="div"
                        variant={variant}
                        startIcon={showIcon ? <IconRecordMono fontSize="small" /> : null}
                        color={buttonColor}
                        sx={{ whiteSpace: 'nowrap', ...defaultColorStyles }}
                    >
                        {label || 'Capture how-to'}
                    </Button>
                }
            />
        )
    }

    if (isAllIntegrationsLocked) return null

    return (
        <ClickAwayListener
            onClickAway={() => {
                // do nothing if component is hidden
                if (hide.isTrue) return

                if (menu.isTrue) toggleMenu()
            }}
        >
            <Box>
                <Box ref={anchorEl}>
                    <Button
                        variant={variant}
                        data-test="new-video-btn"
                        onClick={toggleMenu}
                        startIcon={showIcon ? <StartIcon /> : null}
                        color={buttonColor}
                        sx={{ minWidth: 140, py: 0.75, px: 2, ...defaultColorStyles }}
                    >
                        {label || 'New Video'}
                    </Button>
                </Box>

                {menu.isTrue && (
                    <Popper
                        style={{
                            zIndex: 3,
                            minWidth: '210px',
                            position: 'relative',
                            visibility: hide.isFalse ? 'visible' : 'hidden'
                        }}
                        open={true}
                        anchorEl={anchorEl?.current}
                        role="menu"
                    >
                        <StyledPaper>
                            <MenuList
                                autoFocusItem={false}
                                onMouseOver={isRecordVideoSelected.setFalse}
                                style={{ zIndex: 3 }}
                            >
                                {hasVideoUploads && (
                                    <MenuItem className={classes.menuItem}>
                                        <VideoUploader
                                            labelComponent={
                                                <>
                                                    <ListItemIcon className={classes.itemIcon}>
                                                        <ComputerIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="My Device" />
                                                </>
                                            }
                                        />
                                    </MenuItem>
                                )}

                                {hasGdrive && (
                                    <MenuItem className={classes.menuItem}>
                                        <GoogleDriveUploader
                                            onUploadCallback={onUploadCallback}
                                            labelComponent={
                                                <>
                                                    <ListItemIcon className={classes.itemIcon}>
                                                        <img
                                                            alt="gdrive"
                                                            className={classes.imgIcon}
                                                            src={GoogleDriveIcon}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Google Drive" />
                                                </>
                                            }
                                        />
                                    </MenuItem>
                                )}

                                {hasOneDrive && (
                                    <MenuItem className={classes.menuItem}>
                                        <OneDriveUploader
                                            onUploadCallback={onUploadCallback}
                                            labelComponent={
                                                <>
                                                    <ListItemIcon className={classes.itemIcon}>
                                                        <img
                                                            alt="one-drive"
                                                            className={classes.imgIcon}
                                                            src={OneDriveIcon}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="OneDrive" />
                                                </>
                                            }
                                        />
                                    </MenuItem>
                                )}

                                {hasZoom && (
                                    <MenuItem className={classes.menuItem}>
                                        <ZoomUploader
                                            // @ts-ignore JS file
                                            onUploadCallback={onUploadCallback}
                                            labelComponent={
                                                <>
                                                    <ListItemIcon className={classes.itemIcon}>
                                                        <img
                                                            alt="zoom"
                                                            className={classes.imgIcon}
                                                            src={ZoomIcon}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Zoom" />
                                                </>
                                            }
                                        />
                                    </MenuItem>
                                )}
                                {isYoutubeConnected && hasYoutube && (
                                    <MenuItem className={classes.menuItem}>
                                        <YouTubeUploader
                                            onUploadCallback={onUploadCallback}
                                            labelComponent={
                                                <>
                                                    <ListItemIcon className={classes.itemIcon}>
                                                        <img
                                                            alt="youtube"
                                                            className={classes.imgIcon}
                                                            src={YoutubeIcon}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="YouTube" />
                                                </>
                                            }
                                        />
                                    </MenuItem>
                                )}
                            </MenuList>
                        </StyledPaper>
                    </Popper>
                )}
            </Box>
        </ClickAwayListener>
    )
}
