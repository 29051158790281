import React, { memo, useEffect } from 'react'

import { Button, Typography, Box, FormControlLabel, Radio, Divider } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'
import { logToAnalytics } from 'modules'

import {
    type BucketType,
    type StartCheckoutType,
    type UpdateBucketType,
    type MappedPlanType,
    type UserToDowngradeType,
    useNotification,
    MAX_PLAN_UNITS,
    useBoolean,
    useStigg,
    useWindowView
} from 'hooks'

import { SCREEN, getPriceLabel } from './helpers'
import { DowngradeConfirmationDialog } from './DowngradeConfirmationDialog'
import { PlanItem } from './PlanItem'
import { SpacedGroup } from 'UI/Components'

type Props = {
    bucket: BucketType
    startCheckout: StartCheckoutType
    setStep: React.Dispatch<React.SetStateAction<number>>
    updateBucket: UpdateBucketType
    initialUserSeatsToBuy: number
    activePlanPeriod: BillingPeriod | null
    userSeatsQuota: number
    plan?: MappedPlanType
    isTrialPlan: boolean
    usedUsersSeats: number
    fetchUsersToDowngrade?: (input: void) => Promise<UserToDowngradeType[] | undefined>
    usersToDowngrade?: Array<UserToDowngradeType>
}

export const SelectSeats = memo(
    ({
        bucket,
        startCheckout,
        setStep,
        updateBucket,
        plan,
        activePlanPeriod,
        userSeatsQuota,
        isTrialPlan,
        usedUsersSeats,
        fetchUsersToDowngrade,
        usersToDowngrade
    }: Props) => {
        const { showWarningNotification } = useNotification()

        const { isMobileView } = useWindowView()

        const { period, totalPrice, planId, totalLicenses, pricePerUnitMonthly } = bucket
        const downgradeDialog = useBoolean()
        const { plans, availablePlanIds } = useStigg()

        if (!plan) return null

        const { pricePoints, metadata } = plan

        const incrementSeatsBy = metadata.incrementSeatsBy || 1
        const minUnitQuantity = metadata.minUnitQuantity?.[period] || 1
        const maxUnitQuantity = metadata.maxUnitQuantity?.[period] || MAX_PLAN_UNITS

        const setPeriod = (period: BillingPeriod) => updateBucket({ period })
        const setSeats = (v: number) => updateBucket({ totalLicenses: totalLicenses + v })

        const goToCheckoutScreen = () => {
            setStep(SCREEN.PaymentConfirmation)
            return startCheckout({
                planId,
                unitQuantity: totalLicenses,
                billingPeriod: period
            })
        }

        const handleContinue = async () => {
            if (
                bucket.totalLicenses < Math.max(userSeatsQuota, usedUsersSeats) ||
                !userSeatsQuota
            ) {
                const users = await fetchUsersToDowngrade?.()

                if (users?.length && !isTrialPlan) return downgradeDialog.setTrue()
            }

            return goToCheckoutScreen()
        }

        useEffect(() => {
            if (totalLicenses !== 0 && totalLicenses >= minUnitQuantity) return

            updateBucket({
                totalLicenses: Math.max(usedUsersSeats, minUnitQuantity)
            })
        }, [
            usedUsersSeats,
            metadata.minUnitQuantity,
            minUnitQuantity,
            period,
            totalLicenses,
            updateBucket
        ])

        const { benefit } = getPriceLabel(
            pricePoints,
            Boolean(metadata.isCustomPrice),
            BillingPeriod.Annually
        )

        return (
            <>
                <Box display="flex" width="100%" flexDirection={isMobileView ? 'column' : 'row'}>
                    <Box
                        width={isMobileView ? '100%' : '50%'}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="#F5F5F5"
                    >
                        <Box width={210} my={isMobileView ? 3 : 10}>
                            <PlanItem
                                planNames={availablePlanIds.map(
                                    plan => plans?.[plan]?.planName || ''
                                )}
                                selectedPeriod={period}
                                planId={planId}
                                plan={plan}
                            />
                        </Box>
                    </Box>

                    <Box
                        width={isMobileView ? '100%' : '50%'}
                        display="flex"
                        my={isMobileView ? 3 : 5}
                        justifyContent="center"
                    >
                        <Box width={isMobileView ? '100%' : 300} px={isMobileView ? 3 : 0}>
                            <Typography
                                data-test="seatSelection-billing-period-title-text"
                                style={{ fontSize: 20, fontWeight: 700 }}
                            >
                                Billing period
                            </Typography>
                            <Box display="flex" width="100%">
                                <Box width="50%">
                                    <FormControlLabel
                                        style={{ alignItems: 'flex-start', marginLeft: 0 }}
                                        control={
                                            <Radio
                                                data-test="seatSelection-annual-billing-radioButton"
                                                color="primary"
                                                style={{
                                                    transform: 'translateX(-10px)'
                                                }}
                                                checked={bucket.period === BillingPeriod.Annually}
                                            />
                                        }
                                        label={
                                            <SpacedGroup spacing={1.5}>
                                                <Typography
                                                    data-test="seatSelection-annual-billing-text"
                                                    style={{ fontWeight: 600 }}
                                                >
                                                    Annually
                                                </Typography>

                                                <Box
                                                    data-test="seatSelection-discount-banner"
                                                    bgcolor="#F8C521"
                                                    borderRadius="4px"
                                                    py="0.5px"
                                                    px={0.5}
                                                    fontSize={12}
                                                    fontWeight={500}
                                                >
                                                    {benefit}% off
                                                </Box>
                                            </SpacedGroup>
                                        }
                                        labelPlacement="bottom"
                                        onChange={() => {
                                            logToAnalytics('select_annual_plan_type')
                                            setPeriod(BillingPeriod.Annually)
                                        }}
                                    />
                                    <Box mt={1}>
                                        <Typography
                                            data-test="seatSelection-annual-billing-calculation"
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            ${' '}
                                            {Number(
                                                plan.pricePoints.find(
                                                    it =>
                                                        it.billingPeriod === BillingPeriod.Annually
                                                )?.amount
                                            ) / 12}{' '}
                                            x 12 mo
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box width="50%" borderLeft="1px solid #EBECEC" pl={2}>
                                    <FormControlLabel
                                        value={bucket.period}
                                        style={{ alignItems: 'flex-start', marginLeft: 0 }}
                                        control={
                                            <Radio
                                                data-test="seatSelection-monthly-billing-radioButton"
                                                color="primary"
                                                style={{
                                                    transform: 'translateX(-10px)'
                                                }}
                                                checked={bucket.period === BillingPeriod.Monthly}
                                            />
                                        }
                                        label={
                                            <Typography
                                                data-test="seatSelection-monthly-billing-text"
                                                style={{ fontWeight: 600 }}
                                            >
                                                Monthly
                                            </Typography>
                                        }
                                        labelPlacement="bottom"
                                        onChange={() => {
                                            if (
                                                activePlanPeriod === BillingPeriod.Annually &&
                                                !isTrialPlan
                                            ) {
                                                return showWarningNotification(
                                                    'Please, contact sales to make this change'
                                                )
                                            }

                                            logToAnalytics('select_monthly_plan_type')
                                            setPeriod(BillingPeriod.Monthly)
                                        }}
                                    />
                                    <Box mt={1}>
                                        <Typography
                                            data-test="seatSelection-monthly-billing-calculation"
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            ${' '}
                                            {Number(
                                                plan.pricePoints.find(
                                                    it => it.billingPeriod === BillingPeriod.Monthly
                                                )?.amount
                                            )}{' '}
                                            x 12 mo
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={5} />
                            <Typography
                                data-test="seatSelection-team-size-title-text"
                                style={{ fontSize: 20, fontWeight: 700 }}
                            >
                                Team size
                            </Typography>
                            <Box mt={1} />
                            <Typography
                                data-test="seatSelection-team-size-subtitle-text"
                                color="textSecondary"
                                style={{ fontSize: 14 }}
                            >
                                How many seats do you need?
                            </Typography>
                            <SpacedGroup spacing={1} mt={3} mb={1}>
                                <Button
                                    style={{
                                        height: 40,
                                        minWidth: 40,
                                        borderColor: 'rgba(9, 12, 16, 0.6)'
                                    }}
                                    onClick={() => {
                                        if (
                                            activePlanPeriod === BillingPeriod.Annually &&
                                            totalLicenses - incrementSeatsBy < userSeatsQuota &&
                                            !isTrialPlan
                                        ) {
                                            return showWarningNotification(
                                                'Please, contact sales to make this change'
                                            )
                                        }

                                        logToAnalytics('remove_seat_in_plan_configuration', {
                                            seats: totalLicenses
                                        })
                                        setSeats(-incrementSeatsBy)
                                    }}
                                    disabled={totalLicenses <= minUnitQuantity}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    <RemoveIcon
                                        data-test="seatSelection-remove-seat-button"
                                        style={{ color: 'rgba(9, 12, 16, 0.6)' }}
                                    />
                                </Button>
                                <Box
                                    bgcolor="#F5F5F5"
                                    borderRadius="4px"
                                    padding="1px"
                                    width="120px"
                                    style={{ height: 40 }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography
                                        data-test="seatSelection-total-seats"
                                        variant="h6"
                                        align="center"
                                    >
                                        {totalLicenses}
                                    </Typography>
                                </Box>
                                <Button
                                    style={{
                                        height: 40,
                                        minWidth: 40,
                                        borderColor: 'rgba(9, 12, 16, 0.6)'
                                    }}
                                    onClick={() => {
                                        logToAnalytics('add_seat_in_plan_configuration', {
                                            seats: totalLicenses
                                        })

                                        setSeats(incrementSeatsBy)
                                    }}
                                    disabled={totalLicenses >= maxUnitQuantity}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    <AddIcon
                                        data-test="seatSelection-add-seat-button"
                                        style={{ color: 'rgba(9, 12, 16, 0.6)' }}
                                    />
                                </Button>
                            </SpacedGroup>
                            <Typography
                                data-test="seatSelection-selection-description"
                                color="textSecondary"
                                variant="caption"
                            >
                                seats on{' '}
                                {bucket.period === BillingPeriod.Annually ? 'annual' : 'monthly'}{' '}
                                plan, ${pricePerUnitMonthly} /seat/mo
                            </Typography>
                            <Box my={2}>
                                <Divider />
                            </Box>

                            <Box display="flex" justifyContent="space-between" mt={1} mb={3}>
                                <Typography
                                    data-test="seatSelection-total-price-text"
                                    style={{ fontWeight: 600 }}
                                >
                                    Total
                                </Typography>
                                <Typography
                                    data-test="seatSelection-total-price"
                                    style={{ fontWeight: 600 }}
                                >
                                    $ {totalPrice}
                                </Typography>
                            </Box>

                            <Button
                                data-test="seatSelection-go-to-payment-button"
                                style={{
                                    letterSpacing: 1.25,
                                    fontSize: 14
                                }}
                                onClick={handleContinue}
                                fullWidth
                                variant="contained"
                                size="large"
                            >
                                GO TO PAYMENT
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {downgradeDialog.isTrue && !!usersToDowngrade?.length && !isTrialPlan && (
                    <DowngradeConfirmationDialog
                        onConfirm={goToCheckoutScreen}
                        onClose={downgradeDialog.setFalse}
                        newSeatsAmount={totalLicenses}
                        usedUsersSeats={usedUsersSeats}
                        usersList={usersToDowngrade.map(({ email }) => email)}
                    />
                )}
            </>
        )
    }
)
