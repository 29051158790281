import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'

import WebFont from 'webfontloader'

import useImage from 'use-image'

import type Konva from 'konva'
import { Group, Label, Rect, Tag, Text } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import Image from 'assets/images/dashed-background.png'

import { type TextShapeType } from 'app/types'

import { setLayerText, setLayerTransform, setTextShapeSize } from 'ducks'
import { delay, logToAnalytics, round } from 'modules'
import { useBoolean, useBrandKit } from 'hooks'
import { paths } from 'app/paths'

const PADDING_OFFSET = 3

// used for text label + floating actions positioning
export const LABEL_SPACING = {
    x: 100,
    y: 300
}

const containsRtl = (text: string) => {
    const rtlRegex = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/
    return rtlRegex.test(text)
}

const focusElement = (el: HTMLElement) => {
    el.setAttribute('contenteditable', 'true')

    const timeout = setTimeout(() => {
        // select textarea
        const selection = window.getSelection()
        const range = document.createRange()
        selection!.removeAllRanges()
        range.selectNodeContents(el)
        range.collapse(false)
        selection!.addRange(range)
        el.focus()

        clearTimeout(timeout)
    }, 0)
}

type Props = {
    scale: number
    layer: TextShapeType
    isSmartPreview: boolean
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const TextShape = memo(
    ({ scale, layer, isSmartPreview, onSelect, onDragStart, onDragMove, onDragEnd }: Props) => {
        const {
            id,
            x,
            y,
            rotation,
            title,
            fontSize,
            fontStyle,
            textDecoration,
            fontFamily,
            width,
            height,
            color,
            backgroundColor,
            padding,
            align,
            scaleX = 1,
            scaleY = 1,
            isTitle,
            cornerRadius
        } = layer

        const dispatch = useDispatch()
        const { playbookId } = useParams<{ playbookId: string }>()

        const {
            present: { selectedLayersIds }
        } = useSelector(state => state.qgEditor)

        const brandKitPage = useRouteMatch([paths.brandKitDetails, paths.brandKit])?.isExact

        const [image] = useImage(Image)

        const shapeRef = useRef<any>(null)
        const textRef = useRef<Konva.Text>(null)

        const editMode = useBoolean()
        const hideLabel = useBoolean()

        const isRtl = containsRtl(title || '')

        const [textProps, setTextProps] = useState({
            x,
            y,
            width,
            height,
            fontSize,
            fontStyle,
            color,
            title,
            rotation,
            textDecoration,
            cornerRadius
        })

        // Needed to show correct label's size when text layer with isTitle property is selected
        const { width: layerWidth = 0, height: layerHeight = 0 } =
            textRef.current?.getClientRect({
                skipTransform: true,
                skipStroke: true,
                skipShadow: true
            }) || {}

        const showTextLabel = useMemo(() => {
            if (!brandKitPage || !isTitle || hideLabel.isTrue) return false

            return selectedLayersIds.length === 1 && selectedLayersIds[0] === id
        }, [brandKitPage, hideLabel.isTrue, id, isTitle, selectedLayersIds])

        const fontLoaded = useBoolean()
        const setFontLoaded = fontLoaded.set

        const { brandKitFont, brandKitFonts } = useBrandKit()

        const activeFont = useMemo(() => {
            if (!fontFamily) return brandKitFont
            return brandKitFonts.find(font => font.title === fontFamily)
        }, [brandKitFont, brandKitFonts, fontFamily])

        const font = `${activeFont?.title}, Roboto, sans-serif`

        useEffect(() => {
            if (!activeFont) return

            setFontLoaded(false)

            WebFont.load({
                custom: {
                    urls: [activeFont?.url],
                    families: [activeFont?.title]
                },
                fontactive: () => setFontLoaded(true),
                inactive: () => setFontLoaded(true)
            })
        }, [activeFont, setFontLoaded])

        useEffect(() => {
            const {
                x,
                y,
                rotation,
                title,
                fontSize,
                fontStyle,
                textDecoration,
                width,
                height,
                color,
                cornerRadius
            } = layer

            setTextProps({
                x,
                y,
                width,
                height,
                fontSize,
                fontStyle,
                textDecoration,
                color,
                title,
                rotation,
                cornerRadius
            })
        }, [layer])

        useEffect(() => {
            if (!selectedLayersIds.includes(id) || isSmartPreview || !textRef.current) return

            // when font changes make the height dynamic
            textRef.current.setAttr('height', undefined)

            delay(0).then(() => {
                const textRefHeight = round(textRef.current!.height() + PADDING_OFFSET * 2)
                const textRefWidth = round(textRef.current!.width())

                if (height === textRefHeight && width === textRefWidth) return

                dispatch(
                    setTextShapeSize({
                        id,
                        height: textRefHeight,
                        width: textRefWidth
                    })
                )
            })

            // In order to prevent unnecessary re-renders
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, fontSize, isSmartPreview, id, selectedLayersIds])

        return (
            <>
                {showTextLabel && (
                    <Group
                        x={textProps.x}
                        y={textProps.y}
                        offset={{ x: LABEL_SPACING.x / 2, y: LABEL_SPACING.y / 2 }}
                        scaleX={scaleX}
                        scaleY={scaleY}
                        rotation={textProps.rotation}
                    >
                        <Rect
                            width={layerWidth + LABEL_SPACING.x}
                            height={layerHeight + LABEL_SPACING.y}
                            stroke="#FED243"
                            strokeWidth={5}
                            fillPatternImage={image}
                            fillPatternScaleX={2}
                            fillPatternScaleY={2}
                        />

                        <Label>
                            <Tag fill="#FED243" />
                            <Text text="MAIN TITLE" fill="black" fontSize={30} padding={15} />
                        </Label>
                    </Group>
                )}

                <Label
                    id={id}
                    className="alignable"
                    name="text"
                    ref={shapeRef}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    x={textProps.x}
                    y={textProps.y}
                    rotation={textProps.rotation}
                    draggable
                    onDragStart={onDragStart}
                    onDragMove={e => {
                        setTextProps({
                            ...textProps,
                            x: e.target.x(),
                            y: e.target.y()
                        })
                        onDragMove(e)
                    }}
                    onDragEnd={onDragEnd}
                    onClick={e => onSelect(e.evt.shiftKey)}
                    onMouseOver={() => (document.body.style.cursor = 'move')}
                    onMouseLeave={() => (document.body.style.cursor = '')}
                    onTransformStart={() => {
                        hideLabel.setTrue()
                        // text height will be dynamic if transformed
                        if (textRef.current) textRef.current.setAttr('height', undefined)
                    }}
                    onTransform={({ target }) => {
                        setTextProps(prevState => ({
                            ...prevState,
                            scaleX: 1,
                            scaleY: 1,
                            width: target.width() * target.scaleX(),
                            rotation: target.rotation(),
                            fontSize: Math.floor(layer.fontSize * layer.scaleX)
                        }))

                        target.scaleX(1)
                        target.scaleY(1)
                    }}
                    onTransformEnd={({ target }) => {
                        hideLabel.setFalse()
                        dispatch(
                            setLayerTransform({
                                layerId: layer.id,
                                x: target.x(),
                                y: target.y(),
                                scaleX: 1,
                                scaleY: 1,
                                width: target.width(),
                                height: target.height() + PADDING_OFFSET * 2,
                                fontSize: Math.floor(layer.fontSize * layer.scaleX),
                                rotation: target.rotation()
                            })
                        )
                    }}
                    onDblClick={event => {
                        editMode.setTrue()

                        // find position of stage container on the page:
                        const stageBox = event.target
                            .getStage()
                            ?.attrs.container.getBoundingClientRect()

                        const textPosition = event.target.getAbsolutePosition()

                        // so position of span will be the sum of positions above:
                        const areaPosition = {
                            x: stageBox.left + textPosition.x,
                            y: stageBox.top + textPosition.y + window.scrollY
                        }

                        // create span and style it
                        const span = document.createElement('span')
                        document.body.appendChild(span)
                        focusElement(span)

                        // prevent pasting html tags inside span
                        span.onpaste = e => {
                            e.preventDefault()

                            if (e.clipboardData) {
                                const content = e.clipboardData.getData('text/plain')
                                document.execCommand('insertText', false, content)
                            }
                        }

                        span.oninput = () => {
                            setTextProps(prevState => ({
                                ...prevState,
                                title: span.innerText.trim()
                            }))
                        }

                        span.dir = isRtl ? 'rtl' : 'ltr'

                        span.style.fontFamily = font
                        span.innerText = textProps.title

                        span.style.position = 'absolute'
                        span.style.top = areaPosition.y + 'px'
                        span.style.left = areaPosition.x + 'px'

                        span.style.width =
                            (textProps.width || round(textRef.current!.width())) + 'px'

                        span.style.padding = `${padding - PADDING_OFFSET}px`
                        span.style.margin = '0'

                        span.style.transformOrigin = 'left top'
                        span.style.background = backgroundColor || 'none'
                        span.style.color = textProps.color

                        span.style.fontSize = textProps.fontSize + 'px'
                        span.style.fontWeight = textProps.fontStyle
                        span.style.textAlign = align || 'left'

                        span.style.lineHeight = textProps.fontSize + 'px'
                        span.style.lineBreak = 'word'

                        span.style.overflow = 'hidden'
                        span.style.outline = 'none'
                        span.style.zIndex = '1300'

                        span.style.borderRadius = textProps.cornerRadius + 'px'
                        span.style.transform = `scale(${scale * scaleX},${scale * scaleY})${
                            textProps.rotation ? ' rotateZ(' + textProps.rotation + 'deg)' : ''
                        }`

                        const abortController = new AbortController()

                        const updateText = () => {
                            const title = span.innerText.trim()

                            dispatch(
                                setLayerText({
                                    layerId: id,
                                    title,
                                    height: span.offsetHeight + PADDING_OFFSET * 2
                                })
                            )

                            if (span.innerText !== textProps.title) {
                                logToAnalytics('qg-edit', {
                                    objectType: 'text',
                                    actionType: 'edit-text',
                                    playbookId
                                })
                            }

                            abortController.abort()
                            document.body.removeChild(span)
                            editMode.setFalse()
                        }

                        document.body.addEventListener(
                            'mousedown',
                            e => {
                                if (e.target !== span) {
                                    updateText()
                                }
                            },
                            { signal: abortController.signal }
                        )
                    }}
                >
                    {backgroundColor && (
                        <Tag
                            visible={editMode.isFalse}
                            fill={backgroundColor}
                            cornerRadius={textProps.cornerRadius}
                        />
                    )}

                    <Text
                        key={String(fontLoaded.isTrue)}
                        direction={isRtl ? 'rtl' : 'ltr'}
                        visible={editMode.isFalse}
                        ref={textRef}
                        width={textProps.width || undefined}
                        height={textProps.height || undefined}
                        text={textProps.title || 'T+'}
                        fill={textProps.title ? textProps.color : '#FED243'}
                        fontSize={textProps.fontSize}
                        fontStyle={textProps.fontStyle}
                        textDecoration={textProps.textDecoration}
                        fontFamily={font}
                        padding={padding - PADDING_OFFSET}
                        align={align}
                    />
                </Label>
            </>
        )
    }
)
