import { type CSSProperties } from 'react'

import { type FieldProps, Field } from 'formik'

import { styled } from '@mui/material/styles'

import {
    type InputProps,
    type TextFieldProps,
    Box,
    FormControl,
    FormHelperText,
    TextField,
    Typography
} from '@mui/material'

const PREFIX = 'ConnectedInput'

const classes = {
    root: `${PREFIX}-root`
}

const StyledField = styled(Field)({
    [`& .${classes.root}`]: {
        '&:before': {
            borderBottom: '1px solid rgba(9, 12, 16, 0.08)'
        }
    }
})

export type ConnectedInputProps = TextFieldProps & {
    width?: CSSProperties['width']
    disableUnderline?: InputProps['disableUnderline']
    errorStyle?: object
}

export const ConnectedInput = ({
    name,
    required = false,
    placeholder,
    fullWidth,
    type = 'text',
    multiline,
    minRows,
    width,
    autoFocus,
    disabled,
    style,
    disableUnderline,
    className,
    variant,
    maxRows,
    inputProps,
    InputLabelProps,
    InputProps,
    label,
    errorStyle = {},
    ...rest
}: ConnectedInputProps) => {
    return (
        <StyledField name={name}>
            {({ field, meta }: FieldProps) => {
                const isError = meta.touched && meta.error !== undefined

                return (
                    <FormControl fullWidth={fullWidth}>
                        <TextField
                            {...field}
                            label={label}
                            inputProps={inputProps}
                            InputProps={
                                variant === 'outlined'
                                    ? {}
                                    : {
                                          ...InputProps,
                                          disableUnderline,
                                          classes: {
                                              root: classes.root
                                          }
                                      }
                            }
                            InputLabelProps={InputLabelProps}
                            /* any used to fix TS bug. details here https://github.com/mui-org/material-ui/issues/15697 */
                            variant={(variant ?? 'standard') as any}
                            type={type}
                            required={required}
                            placeholder={placeholder}
                            onBlur={field.onBlur}
                            value={field.value || ''}
                            error={meta.touched && meta.error !== undefined}
                            style={{
                                ...style,
                                ...(fullWidth ? {} : { width: width ?? '300px' })
                            }}
                            className={className}
                            multiline={multiline}
                            minRows={minRows}
                            maxRows={maxRows ?? 5}
                            autoComplete="off"
                            autoFocus={autoFocus}
                            disabled={disabled}
                            onKeyPress={e => {
                                const key = e.key
                                const forbiddenChars = ['<', '>']

                                if (forbiddenChars.includes(key)) {
                                    e.preventDefault()
                                }
                            }}
                            {...rest}
                        />

                        <Box
                            display="flex"
                            justifyContent={isError ? 'space-between' : 'flex-end'}
                            alignItems="center"
                        >
                            {isError && (
                                <FormHelperText style={{ color: 'red', ...errorStyle }}>
                                    {meta.error}
                                </FormHelperText>
                            )}
                            {inputProps?.maxLength && (
                                <Typography color="textSecondary" variant="caption">
                                    {field?.value?.length || 0}/{inputProps.maxLength}
                                </Typography>
                            )}
                        </Box>
                    </FormControl>
                )
            }}
        </StyledField>
    )
}
