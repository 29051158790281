import { Fragment, useMemo, useState } from 'react'

import { Box, Divider, Drawer, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useNotification, useQuery } from 'hooks'

import {
    type InsightsPeriodSelectValue,
    type InsightsV2Response,
    type InsightsWithinPlaybook,
    InsightsPeriod
} from 'app/types/insights'

import {
    InsightTableSortFields,
    formatRequestDate,
    formatViewNumber,
    formatWatchPercent,
    formatWatchTime,
    generateEmptyChartData,
    insightsDefaultPeriod
} from './insights-utils'

import { InsightsPeriodSelect } from './InsightsPeriodSelect/InsightsPeriodSelect'
import { InsightsTotalMetrics } from './InsightsTotalMetrics'
import { InsightsVideoEngagementChart } from './Charts/InsightsVideoEngagementChart'
import { InsightsAvgPercentWatchedChart } from './Charts/InsightsAvgPercentWatchedChart'
import { InsightsAvgWatchTimeChart } from './Charts/InsightsAvgWatchTimeChart'
import { InsightsExportCsvButton } from './InsightsExportCsvButton'
import { SyncYAxisWidthContext } from './Charts/InsightsAreaChart/use-dynamic-y-axis-width'

const analyticsEventSource = 'videoDrawer'

type Props = {
    playbookId: string
    isOpen: boolean
    onClose: () => void
}

export const PlaybookInsightsDrawer = ({ isOpen, onClose, playbookId }: Props) => {
    const { showErrorNotification } = useNotification()

    const [period, setPeriod] = useState<InsightsPeriodSelectValue>({
        period: insightsDefaultPeriod,
        dateRange: null
    })

    const insightsRequestParams = {
        playbookId,
        period: period.period,
        from: period.dateRange?.start && formatRequestDate(period.dateRange.start),
        to: period.dateRange?.end && formatRequestDate(period.dateRange.end),
        tz: new Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    const $insights = useQuery<InsightsV2Response<InsightsWithinPlaybook>>(
        '/ins/v1/insights',
        {
            method: 'POST',
            body: { ...insightsRequestParams, forcePlaybooksTable: true }
        },
        {
            revalidateOnFocus: false,
            onError: ({ message }) => {
                console.error(message)
                showErrorNotification('Something went wrong. Please try again later')
            }
        }
    )

    const insights = $insights.data?.data

    const chartData = useMemo(() => {
        if (insights) {
            return insights.metricsByDate
        }

        const { period: periodType, dateRange } = period

        if (periodType === InsightsPeriod.Custom) {
            return dateRange
                ? generateEmptyChartData({
                      period: periodType,
                      datePeriod: {
                          start: dateRange.start,
                          end: dateRange.end
                      }
                  })
                : []
        }

        return generateEmptyChartData({
            period: periodType,
            datePeriod: null
        })
    }, [insights, period])

    const tableData = useMemo(() => {
        const data = insights?.playbooksTableMetrics?.[0]

        if (data) {
            return [
                {
                    label: 'Loads',
                    value: formatViewNumber(data.loads)
                },
                {
                    label: 'Total views',
                    value: formatViewNumber(data.views)
                },
                {
                    label: 'Avg. watch time',
                    value: formatWatchTime(data.avgWatchTime)
                },
                {
                    label: 'Avg. Watch %',
                    value: formatWatchPercent(data.avgWatched)
                },
                {
                    label: 'Unique viewers',
                    value: formatViewNumber(data.viewers)
                },
                {
                    label: 'Creator',
                    value: data.creator
                }
            ]
        }

        return null
    }, [insights?.playbooksTableMetrics])

    const [chartYAxisWidth, setChartYAxisWidth] = useState(0)

    const changePeriod = (period: InsightsPeriodSelectValue) => {
        setPeriod(period)
        // Re-calculate width
        setChartYAxisWidth(0)
    }

    return (
        <Drawer
            open={isOpen}
            anchor="right"
            sx={{
                '& .MuiBackdrop-root': {
                    opacity: '0 !important'
                }
            }}
            PaperProps={{
                sx: {
                    width: 460
                }
            }}
            onClose={onClose}
        >
            <Stack
                sx={{
                    p: 2,
                    borderBottom: '1px solid rgba(9, 12, 16, 0.10)',
                    background: '#FAFAFA'
                }}
                spacing={2}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography fontSize={20} lineHeight={1.2} fontWeight={500}>
                            Analytics
                        </Typography>
                        {tableData && (
                            <Box
                                sx={{
                                    '&&': {
                                        my: -1
                                    }
                                }}
                            >
                                <InsightsExportCsvButton
                                    requestBody={{
                                        ...insightsRequestParams,
                                        order: 'desc',
                                        sort: InsightTableSortFields.views
                                    }}
                                    analyticsEventSource={analyticsEventSource}
                                />
                            </Box>
                        )}
                    </Stack>
                    <IconButton sx={{ m: -1 }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <InsightsPeriodSelect
                    value={period}
                    onSelectPeriod={changePeriod}
                    analyticsEventSource={analyticsEventSource}
                />
            </Stack>

            <Stack sx={{ py: 3, px: 2 }}>
                {insights ? (
                    <Stack
                        spacing={3}
                        divider={
                            <Box>
                                <Divider
                                    flexItem
                                    sx={{
                                        mx: -2
                                    }}
                                />
                            </Box>
                        }
                    >
                        <InsightsTotalMetrics
                            isSmall
                            totalMetrics={insights.totalMetrics}
                            totalMetricsPrevious={insights.totalMetricsPrevious}
                        />
                        {/* TODO: fix empty state */}
                        <SyncYAxisWidthContext.Provider
                            value={{ value: chartYAxisWidth, setValue: setChartYAxisWidth }}
                        >
                            <InsightsVideoEngagementChart
                                isEmpty={false}
                                chartData={chartData}
                                analyticsEventSource={analyticsEventSource}
                                chartHeight={175}
                                isSmall
                            />
                            <InsightsAvgPercentWatchedChart
                                isEmpty={false}
                                chartData={chartData}
                                analyticsEventSource={analyticsEventSource}
                                chartHeight={175}
                                isSmall
                            />
                            <InsightsAvgWatchTimeChart
                                isEmpty={false}
                                chartData={chartData}
                                analyticsEventSource={analyticsEventSource}
                                chartHeight={175}
                                isSmall
                            />
                        </SyncYAxisWidthContext.Provider>
                        {tableData && (
                            <Box
                                sx={{
                                    display: 'grid',
                                    columnGap: 1,
                                    rowGap: 2,
                                    gridTemplateColumns: 'minmax(140px, auto) 1fr'
                                }}
                            >
                                {tableData.map(({ label, value }) => (
                                    <Fragment key={label}>
                                        <Typography
                                            fontSize={14}
                                            lineHeight="20px"
                                            fontWeight={500}
                                        >
                                            {label}
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            lineHeight="20px"
                                            color="rgba(9, 12, 16, 0.60)"
                                        >
                                            {value}
                                        </Typography>
                                    </Fragment>
                                ))}
                            </Box>
                        )}
                    </Stack>
                ) : (
                    <Stack mt={7} alignItems="center" spacing={2}>
                        <Typography>Loading...</Typography>
                        <LinearProgress sx={{ width: 300 }} />
                    </Stack>
                )}
            </Stack>
        </Drawer>
    )
}
