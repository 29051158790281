import { useDispatch, useSelector } from 'react-redux'

import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'

import { ReactComponent as UploadIcon } from 'assets/icons/upload-icon.svg'
import { ReactComponent as StepByStepIcon } from 'assets/icons/step-by-step.svg'

import { Button } from '@mui/material'

import { InstallExtButton, ProtectedViewerAction, SpacedGroup } from 'UI/Components'

import { type NewPlaybookProps, NewPlaybook } from './NewPlaybook'
import { useBoolean, useRoles, useServiceUsage } from 'hooks'
import { sendSoleMessageToExt } from 'modules'
import { showPlanNotifications } from 'ducks'

export const NewPlaybookButtons = ({ label = 'Import video', ...props }: NewPlaybookProps) => {
    const dispatch = useDispatch()
    const { playbookCreatingLocked, isFreePlan } = useServiceUsage()

    const { isViewer, isMember } = useRoles()

    const loadingViewerInfo = useBoolean()
    const isExtensionInstalled = useSelector(state => state.extensionStatus.isInstalled)
    const isQGRecording = useSelector(state => state.extensionStatus.qgIsRecording)

    const { isAllIntegrationsLocked } = useServiceUsage()

    // if only "Record Video" options is available we don't show "Record step by step" and "Record Video" at the same time
    return (
        <SpacedGroup>
            {!isAllIntegrationsLocked && !isViewer && (
                <NewPlaybook
                    {...props}
                    isExtensionInstalled={isExtensionInstalled}
                    label={label}
                    variant="outlined"
                    showRecordOption={isExtensionInstalled}
                    color="default"
                    StartIcon={isExtensionInstalled ? AddToPhotosIcon : UploadIcon}
                />
            )}
            <ProtectedViewerAction setLoading={loadingViewerInfo.set}>
                {!isExtensionInstalled && isMember && <InstallExtButton />}
            </ProtectedViewerAction>
            {isExtensionInstalled && (
                <ProtectedViewerAction setLoading={loadingViewerInfo.set}>
                    <Button
                        variant="contained"
                        startIcon={<StepByStepIcon />}
                        disabled={isQGRecording || loadingViewerInfo.isTrue}
                        onClick={() => {
                            if (playbookCreatingLocked) {
                                return dispatch(
                                    showPlanNotifications({
                                        freePlanLimitation: isFreePlan,
                                        paidPlanLimitation: !isFreePlan
                                    })
                                )
                            }

                            sendSoleMessageToExt({
                                msg: { type: 'WEBAPP/LAUNCH_EXT_QG_RECORDER' }
                            })
                        }}
                        sx={{
                            textTransform: 'uppercase',
                            letterSpacing: '1.25 px',
                            whiteSpace: 'nowrap',
                            py: 0.75,
                            px: 2
                        }}
                    >
                        Capture how-to video
                    </Button>
                </ProtectedViewerAction>
            )}
        </SpacedGroup>
    )
}
