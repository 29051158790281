import { useCallback, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { PlaybookFeedback } from '@guidde-co/shared.playbook-feedback'

import {
    type SignInProvider,
    useSignIn,
    useDataMutation,
    useNotification,
    useQueryParams,
    useWindowView
} from 'hooks'
import { getPlaybookPath, host, logToAnalytics } from 'modules'

import { stringifyLocation, RegionRedirectDialog } from 'UI/Components'

import { type AnyPlaybookType } from 'app/types'
import { links } from 'app/links'

const source = 'public'

type RequestType = {
    playbookId: string
    comment: string
    origin?: string
    playbookURL: string
}

type Props = {
    onClose: () => void
    background?: string
    playbook: AnyPlaybookType
}

export const PlaybookFeedbackWrapper = ({ onClose, playbook, background }: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()
    const [redirectUrl, setRedirectUrl] = useState('')
    const location = useLocation()
    const history = useHistory()

    const params = useQueryParams()
    const origin = params.get('origin') || ''

    const { continueWithPopup } = useSignIn()

    const { showErrorNotification } = useNotification()

    const { isMobileView } = useWindowView()

    const $sendFeedback = useDataMutation<RequestType, { status: string }, Error>(
        '/b/v1/send-comment',
        'POST',
        {
            onFailure: e => {
                console.error('[SEND FEEDBACK]:', e)
                showErrorNotification('Something went wrong, please try later')
            }
        }
    )

    const sendFeedbackMutate = $sendFeedback.mutate

    const sendFeedback = useCallback(
        (comment: string) => {
            return sendFeedbackMutate({
                comment,
                playbookId,
                origin,
                playbookURL: host + getPlaybookPath(playbook)
            })
        },
        [sendFeedbackMutate, origin, playbookId, playbook]
    )

    const signInAndSendFeedback = (provider: SignInProvider, feedback: string) => {
        continueWithPopup(provider, async ({ user }) => {
            history.replace({
                search: stringifyLocation(location)
            })

            logToAnalytics('post_comment_signup', {
                source,
                email: user.email,
                feedback,
                id: user.uid,
                uploadedByOrgId: playbook.uploadedByOrgId
            })

            sendFeedback(feedback)
        }).catch(err => {
            const isRedirectMessage = typeof err === 'string' && err.startsWith('redirectUrl:')
            if (isRedirectMessage) {
                const redirectUrl = err.split('redirectUrl:')[1]
                setRedirectUrl(redirectUrl)
            } else {
                showErrorNotification(err)
            }
        })
    }

    return (
        <>
            <PlaybookFeedback
                isLoading={$sendFeedback.isLoading}
                onClose={onClose}
                authorName={playbook.uploadedBy.displayName}
                authorPhotoURL={playbook.uploadedBy.photoURL}
                background={background}
                onPostClick={feedback =>
                    logToAnalytics('post_comment_clicked', { feedback, source })
                }
                onGoogleClick={feedback => signInAndSendFeedback('google.com', feedback)}
                onMicrosoftClick={feedback => signInAndSendFeedback('microsoft.com', feedback)}
                onPostAnonymously={feedback => {
                    logToAnalytics('post_comment_anonymously', { feedback, source })

                    return sendFeedback(feedback)
                }}
                termsLink={links.terms}
                privacyPolicyLink={links.privacyPolicy}
                isMobileView={isMobileView}
            />
            <RegionRedirectDialog
                isOpen={!!redirectUrl}
                redirectUrl={redirectUrl}
                onClose={() => setRedirectUrl('')}
            />
        </>
    )
}
