import { useMemo, useState } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    LinearProgress,
    Radio,
    Grid,
    Tooltip
} from '@mui/material'

import { type RowUserType } from 'UI/Routes/account-settings/tabs'

import { HTTP_CODES } from 'app/http-codes'
import { generalErrorMessage, logToAnalytics } from 'modules'
import { useBoolean, useDataMutation, useNotification, getRoleName, ROLES_LIST, ROLE } from 'hooks'

type Props = {
    onClose: () => void
    refetchOrgUsers: () => void
    user: RowUserType
    isRoleUpgradeDisabled: boolean
    orgId: string
    isSingleAdminRole: boolean
}

const copy = [...ROLES_LIST]
const orderedList = copy.reverse()

export const PermissionsDialog = ({
    onClose,
    user,
    isRoleUpgradeDisabled,
    refetchOrgUsers,
    orgId,
    isSingleAdminRole
}: Props) => {
    const loading = useBoolean()

    const roleOption = useMemo(() => {
        if (user.status === 'pending') {
            return getRoleName(user.rolesNumber || ROLE.VIEWER)
        }
        return ROLES_LIST.find(role => role.name === user.roles)
    }, [user])

    const [option, setOption] = useState(roleOption)

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const $setUserPermissions = useDataMutation('/c/v1/user', 'POST', {
        onSuccess: refetchOrgUsers,
        throwOnFailure: true
    })

    const handleRolesUpdateFailure = (e: { code?: number; message?: string }) => {
        console.error(e)

        if (e.code === HTTP_CODES.Forbidden && e.message) {
            return showErrorNotification(e.message)
        }

        showErrorNotification(generalErrorMessage)
    }

    const setPendingUserPermissions = () => {
        if (!option) return Promise.resolve()

        return $setUserPermissions
            .mutate({
                orgId,
                email: user.email,
                roles: { a: option.level }
            })
            .then(() => {
                logToAnalytics('change_pending_user_permissions', {
                    user,
                    permissions: option.name
                })
            })
    }

    const setExistingUserPermissions = () => {
        if (!option) return Promise.resolve()

        return $setUserPermissions
            .mutate({
                orgId,
                uid: user.uid,
                roles: { a: option.level }
            })
            .then(() => {
                logToAnalytics('change_user_permissions', {
                    user,
                    permissions: option.name
                })
            })
    }

    const handleSave = () => {
        const isUserPending = user.status === 'pending'

        const setNewPermissions = isUserPending
            ? setPendingUserPermissions
            : setExistingUserPermissions

        setNewPermissions()
            .then(() => {
                showSuccessNotification('User permissions have been updated')
                onClose()
            })
            .catch(handleRolesUpdateFailure)
    }

    const isUpgradeLockedInOrg = isRoleUpgradeDisabled && user.rolesNumber === ROLE.VIEWER

    return (
        <Dialog fullWidth maxWidth="sm" onClose={onClose} open={true}>
            {$setUserPermissions.isLoading && <LinearProgress />}
            <DialogTitle>Set Role</DialogTitle>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                style={{ height: 170 }}
            >
                {orderedList.map(o => {
                    const isUserDowngraded =
                        user.viewerOnlyAfterMs && user.viewerOnlyAfterMs < Date.now()
                    const planLimitDisable =
                        (isUserDowngraded || isUpgradeLockedInOrg) && o.level !== ROLE.VIEWER
                    const roleLimitDisable =
                        o.level !== ROLE.ADMIN &&
                        isSingleAdminRole &&
                        user.rolesNumber === ROLE.ADMIN

                    return (
                        <Grid item key={o.name}>
                            <Tooltip
                                disableFocusListener={!planLimitDisable && !roleLimitDisable}
                                disableHoverListener={!planLimitDisable && !roleLimitDisable}
                                disableTouchListener={!planLimitDisable && !roleLimitDisable}
                                title={
                                    planLimitDisable
                                        ? `You have used up all seats on your plan. To update this user, free up a seat.`
                                        : `An organization has to have at least one admin.`
                                }
                                PopperProps={{
                                    sx: theme => ({
                                        zIndex: theme.zIndex.modal + 1
                                    })
                                }}
                            >
                                <FormControlLabel
                                    labelPlacement="bottom"
                                    label={o.name}
                                    disabled={planLimitDisable || roleLimitDisable}
                                    control={
                                        <Radio
                                            checked={option?.name === o.name}
                                            onChange={() => setOption(o)}
                                            value={o.name}
                                            name={o.name}
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    )
                })}
            </Grid>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={onClose} color="inherit" size="large" variant="contained">
                    Cancel
                </Button>
                <Button
                    disabled={loading.isTrue}
                    onClick={handleSave}
                    size="large"
                    variant="contained"
                >
                    Set
                </Button>
            </DialogActions>
        </Dialog>
    )
}
