import { ServiceUsageDialog } from './ServiceUsageDialog'

type Props = {
    onClose: () => void
}

export const FreePlanStartedDialog = ({ onClose }: Props) => (
    <ServiceUsageDialog
        title="We’ll miss you :("
        onClose={onClose}
        description="Your account has been successfully
            downgraded to a free plan"
        actionText="Let’s go!"
        onActionClick={onClose}
        maxDescriptionWidth={260}
    />
)
