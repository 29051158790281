import { useState } from 'react'
import { type FieldProps, Field } from 'formik'

import {
    Box,
    ClickAwayListener,
    List,
    ListItem,
    Paper,
    Popover,
    Tooltip,
    styled
} from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { SpacedGroup } from 'UI/Components'

type optionType = {
    value: string
    label: string
    caption: string
    disabled?: boolean
    disabledTooltipMessage?: string
    icon?: JSX.Element
}

type Props = {
    options: Array<optionType>
    name: string
    disabled?: boolean
    onChange?: (value: string) => void
}

const GuiddeListItem = styled(ListItem)({
    '&:hover': {
        backgroundColor: '#F5F5F5'
    }
})

export const FancyDropdown = ({ options, name, disabled = false, onChange }: Props) => {
    const labelColor = disabled ? '#d3d3d3' : '#090C10'
    return (
        <Field name={name} id={name}>
            {({ field, form: { setFieldValue } }: FieldProps) => {
                const selectedValue = options.find(
                    (option: optionType) => option.value === field.value
                )

                const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

                return (
                    <>
                        <Box
                            sx={{ cursor: 'pointer', height: '45px' }}
                            onClick={(e: any) => {
                                if (!disabled) setAnchorEl(e.currentTarget)
                            }}
                        >
                            <SpacedGroup alignContent="center">
                                {selectedValue?.icon && <Box>{selectedValue?.icon}</Box>}
                                <SpacedGroup flexDirection="column" spacing={0.25}>
                                    <SpacedGroup>
                                        <Box
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: labelColor
                                            }}
                                        >
                                            {selectedValue?.label}
                                        </Box>
                                        <ArrowDropDownIcon style={{ color: labelColor }} />
                                    </SpacedGroup>
                                    <Box
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: labelColor
                                        }}
                                    >
                                        {selectedValue?.caption}
                                    </Box>
                                </SpacedGroup>
                            </SpacedGroup>
                        </Box>
                        <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} disablePortal>
                            <Paper elevation={0}>
                                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                    <List style={{ cursor: 'pointer', padding: '8px' }} {...field}>
                                        {options.map((listOption, idx) => (
                                            <Tooltip
                                                title={listOption.disabledTooltipMessage || ''}
                                                key={idx}
                                                placement="right"
                                                disableFocusListener={!listOption.disabled}
                                                disableHoverListener={!listOption.disabled}
                                                disableTouchListener={!listOption.disabled}
                                            >
                                                <Box>
                                                    <GuiddeListItem
                                                        divider={true}
                                                        disabled={listOption.disabled}
                                                        onClick={() => {
                                                            const selected = options.find(
                                                                option =>
                                                                    option.value ===
                                                                    listOption.value
                                                            )
                                                            setAnchorEl(null)
                                                            setFieldValue(name, selected?.value)

                                                            if (onChange && selected?.value) {
                                                                onChange(selected.value)
                                                            }
                                                        }}
                                                    >
                                                        <Box mr={0.5}>{listOption.icon}</Box>
                                                        {listOption.label}
                                                    </GuiddeListItem>
                                                </Box>
                                            </Tooltip>
                                        ))}
                                    </List>
                                </ClickAwayListener>
                            </Paper>
                        </Popover>
                    </>
                )
            }}
        </Field>
    )
}
