import { useEffect } from 'react'

import { type PlaybookType } from 'app/types'
import { useBoolean } from 'hooks'

import { CallToAction } from './CallToAction'

type Props = {
    videoElement: HTMLVideoElement | null
    playbook: PlaybookType
    onCTaVisibilityChange?: (visible: boolean) => void
}

export const CallToActionWrapperVideo = ({
    onCTaVisibilityChange,
    videoElement,
    playbook
}: Props) => {
    const { duration, cta, slicingSuggestion } = playbook

    const { image, link = '', start = 0, end = duration || 0 } = cta || {}

    const showCta = useBoolean()

    useEffect(() => {
        onCTaVisibilityChange?.(Boolean(cta && showCta.isTrue))
    }, [cta, showCta.isTrue, onCTaVisibilityChange])

    useEffect(() => {
        if (!videoElement) return

        const handleShowVideoCta = (e: Event) => {
            const time = Math.floor((e.target as HTMLVideoElement).currentTime)

            if (time >= start && time <= end) showCta.setTrue()
            else showCta.setFalse()
        }

        videoElement.addEventListener('timeupdate', handleShowVideoCta)
        return () => {
            videoElement.removeEventListener('timeupdate', handleShowVideoCta)
        }
    }, [end, playbook, start, videoElement, image, link, slicingSuggestion, cta, showCta])

    if (!cta || showCta.isFalse) return null

    return <CallToAction videoElement={videoElement} cta={cta} playbook={playbook} />
}
