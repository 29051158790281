import { useHistory } from 'react-router-dom'

import { Box, Button } from '@mui/material'

import { ReactComponent as DeleteIcon } from 'assets/icons/trash-outlined.svg'

import { MoveToTrashDialog } from 'UI/Components'

import { useBoolean } from 'hooks'
import { logPlaybookDelete, logToAnalytics } from 'modules'

import { type AnyPlaybookType } from 'app/types'
import { paths } from 'app/paths'

type Props = {
    playbook: AnyPlaybookType
    label?: JSX.Element
}

export const Delete = ({ playbook, label }: Props) => {
    const showDialog = useBoolean()

    const history = useHistory()

    return (
        <>
            {!label ? (
                <Button
                    color="inherit"
                    variant="contained"
                    style={{ height: 32, color: 'black' }}
                    onClick={() => {
                        logToAnalytics('moveToTrashBtn_clicked', {
                            playbookIds: [playbook.id],
                            multiselect: 'off'
                        })
                        showDialog.setTrue()
                    }}
                    title="Delete"
                    startIcon={<DeleteIcon style={{ color: 'inherit' }} />}
                >
                    Move to trash
                </Button>
            ) : (
                <Box onClick={showDialog.setTrue}>{label}</Box>
            )}
            <MoveToTrashDialog
                isOpen={showDialog.isTrue}
                onClose={showDialog.setFalse}
                playbooks={[playbook]}
                onSuccess={() => {
                    logPlaybookDelete(playbook)
                    history.push(paths.home)
                }}
                onFinally={showDialog.setFalse}
                playbookIds={[playbook.id]}
            />
        </>
    )
}
