import { encodeEmailToHeader, fetchData } from 'modules'

/** We can't use DataMutation hook here cause request is made
 *  from not logged in users and with custom headers
 */

export type LoginMagicLinkQueryParams = Partial<{
    spaceId: string
    invitationId: string
    [key: string]: string
}>

export type TLoginViaEmailForm = 'sign-in' | 'sign-up'

export const createLoginMagicLink = (
    email: string,
    additionalQueryParams?: LoginMagicLinkQueryParams,
    formType?: TLoginViaEmailForm
) => {
    const linkParams = new URLSearchParams(location.search)

    if (additionalQueryParams?.fromPathname) {
        linkParams.set('fromPathname', additionalQueryParams.fromPathname)
    }

    return fetchData(`/auth/v1/magic-link/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-guidde-message': encodeEmailToHeader(email)
        },
        body: JSON.stringify({
            email,
            formType,
            pageName: document.title,
            pageUrl: window.location.href,
            searchParams: linkParams.toString(),
            additionalQueryParams
        })
    })
}

export const createSpaceMagicLink = (
    email: string,
    additionalQueryParams?: LoginMagicLinkQueryParams
) => {
    const linkParams = new URLSearchParams(location.search)

    if (additionalQueryParams?.fromPathname) {
        linkParams.set('fromPathname', additionalQueryParams.fromPathname)
    }

    return fetchData(`/auth/v1/magic-link/space`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-guidde-message': encodeEmailToHeader(email)
        },
        body: JSON.stringify({
            email,
            searchParams: linkParams.toString(),
            additionalQueryParams
        })
    })
}

export const refreshTokenForMSLogin = async (data: {
    email: string
    idToken: string
}): Promise<{ token: string | null }> =>
    fetchData(`/auth/v1/ms-login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

type BodyParams = {
    email: string
    uid: string
    context?: {
        spaceId?: string
        invitationId?: string
    }
}

export type SignInOptionsType = {
    skipOnboarding: boolean
    invisibleMode: boolean
}

export type ResponseProps = {
    signInLink?: string
    customToken?: string
    secretKey?: string
    options?: SignInOptionsType
}

type ResponseErrorProps = {
    error?: string
    message?: string
    statusCode?: string
}

export const revalidateGuiddeMagicLink = ({
    email,
    uid,
    context
}: BodyParams): Promise<void | ResponseProps | ResponseErrorProps> => {
    /** We can't use DataMutation hook here cause request is made
     *  from not logged in users and with custom headers
     */
    return fetchData(`/auth/v1/magic-link/revalidate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-guidde-message': encodeEmailToHeader(email)
        },
        body: JSON.stringify({
            email,
            uid,
            guiddeMagicLink: window.location.href,
            ...(context && { context })
        })
    })
        .then((data: ResponseProps) => data)
        .catch((e: ResponseErrorProps) => {
            console.error(e)
            return e
        })
}
