import { useCallback, useMemo } from 'react'

import { capitalize } from '@mui/material'

import { useOrganization } from 'hooks'
import { type PlaybookType } from 'app/types'

export const playbookFilters = {
    apps: 'applications',
    tags: 'tags',
    displayName: 'displayName',
    type: 'type',
    visibility: 'visibility'
} as const

export const getVisibilityList = (orgName: string) => ({
    private: 'Private',
    public: 'Public',
    team: 'Team',
    connected: 'Connected',
    mainSpace: capitalize(orgName)
})

export const getShortVisibilityList = (orgName: string) => ({
    public: 'Public',
    mainSpace: capitalize(orgName),
    private: 'Private'
})

export const channelList = {
    private: 'Private',
    team: 'Team',
    company: 'Company',
    community: 'Community'
}

export const typeList = {
    playbook: 'Guide',
    playlist: 'Playlist',
    quickGuidde: 'Step by step'
}

export const usePlaybooksFilter = (
    initialArray: Array<PlaybookType>,
    query: Record<string, Array<string>>
) => {
    const newArray = [...initialArray] // creates new array in order to prevent mutation
    const { name: orgName } = useOrganization()

    const handleFilter = useCallback(
        (elem: PlaybookType) => {
            return Object.keys(query).every(key => {
                if (query[key].length === 0) return true

                // Filter by applications
                if (key === playbookFilters.apps) {
                    return query[key].some(q => {
                        if (elem.hasOwnProperty(key)) {
                            return elem[key].some(a => a.applicationId === q)
                        } else {
                            return false
                        }
                    })
                }

                // Filter by tags
                else if (key === playbookFilters.tags) {
                    return query[key].some(q => {
                        if (elem.hasOwnProperty(key)) {
                            return elem[key].includes(q)
                        } else {
                            return false
                        }
                    })
                }

                // Filter by creator (uploadedBy.displayName)
                else if (key === playbookFilters.displayName) {
                    return query[key].some(q => {
                        if (elem.uploadedBy?.hasOwnProperty(key)) {
                            return elem.uploadedBy[key] === q
                        }
                        return false
                    })
                }

                // Filter by visibility
                else if (key === playbookFilters.visibility) {
                    return query[key].some(q => {
                        if (elem.hasOwnProperty(key)) {
                            return (
                                elem[key] === q ||
                                (q === getVisibilityList(orgName).mainSpace && elem.isMainIncluded)
                            )
                        }

                        return false
                    })
                }

                // Filter by type (isPlaylist)
                else if (key === playbookFilters.type) {
                    return query[key].some(q => {
                        return elem.mode === Object.entries(typeList).find(e => e[1] === q)?.[0]
                    })
                }

                // do not match by default
                return false
            })
        },
        [orgName, query]
    )

    const filteredResult = newArray.filter(handleFilter)

    return useMemo(() => ({ filteredResult }), [filteredResult])
}
