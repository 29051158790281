import { memo, useEffect, useState } from 'react'

import { Elements } from '@stripe/react-stripe-js'

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Box,
    Button,
    DialogActions,
    CircularProgress,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as GuiddeIcon } from 'assets/icons/icon-g.svg'
import PaymentCard from 'assets/images/payment-card.png'

import { CheckoutForm, PaymentErrorDialog } from 'UI/Components'

import { useBoolean, usePayment } from 'hooks'
import { logToAnalytics } from 'modules'

const CloseIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
}))

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontWeight: 500,
    fontSize: '24px',
    marginTop: theme.spacing(4),
    textAlign: 'center'
}))

const LoadingContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '250px'
})

type Props = {
    onClose: () => void
    type: string
}

export const UpdateCardDialog = memo(({ onClose, type }: Props) => {
    const [step, setStep] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const paymentError = useBoolean()

    const {
        submitStripeForm,
        startCardUpdate,
        updateCardSecret,
        stripePromise,
        formIsReady,
        isLoading
    } = usePayment()

    useEffect(() => {
        startCardUpdate()
        logToAnalytics('open_dialog_for_changing_card_details')
    }, [startCardUpdate])

    if (!formIsReady) {
        return (
            <Dialog open onClose={onClose} fullWidth maxWidth="xs">
                <StyledDialogTitle>{type} Payment Method</StyledDialogTitle>
                <CloseIconButton onClick={onClose}>
                    <CloseIcon />
                </CloseIconButton>
                <DialogContent>
                    <LoadingContainer>
                        <Box position="relative">
                            <GuiddeIcon
                                style={{
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%, -65%)'
                                }}
                            />
                            <CircularProgress size={55} />
                        </Box>
                    </LoadingContainer>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="xs">
            <CloseIconButton onClick={onClose}>
                <CloseIcon />
            </CloseIconButton>
            <TabPanel index={0} value={step}>
                <StyledDialogTitle>{type} Payment Method</StyledDialogTitle>

                <DialogContent
                    style={{
                        minHeight: '465px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box>
                        <Typography variant="subtitle2" align="left">
                            Credit card details
                        </Typography>
                    </Box>
                    <Box flexGrow={1} display="flex" alignItems="stretch">
                        <Elements
                            stripe={stripePromise}
                            options={{
                                clientSecret: updateCardSecret,
                                loader: 'never'
                            }}
                        >
                            <CheckoutForm
                                submitStripeForm={submitStripeForm}
                                onSuccess={() => {
                                    logToAnalytics('payment_card_changed')
                                    setStep(1)
                                }}
                                onError={e => {
                                    const errorMessage =
                                        e.error?.message ||
                                        `Something went wrong when adding a new card. Please, try again`

                                    setErrorMessage(errorMessage)
                                    paymentError.setTrue()
                                    logToAnalytics('payment_card_change_failed', { errorMessage })
                                }}
                                isLoading={isLoading}
                            />
                        </Elements>
                    </Box>
                    {paymentError.isTrue && (
                        <PaymentErrorDialog
                            onClose={paymentError.setFalse}
                            isOpen={paymentError.isTrue}
                            onClick={paymentError.setFalse}
                            errorMessage={errorMessage}
                        />
                    )}
                </DialogContent>

                <DialogActions />
            </TabPanel>

            <TabPanel index={1} value={step}>
                <StyledDialogTitle sx={{ color: '#26c6da' }}>
                    Card Updated Successfully!
                </StyledDialogTitle>
                <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={PaymentCard} alt="img" />
                </DialogContent>
                <DialogActions
                    sx={theme => ({
                        padding: theme.spacing(3),
                        justifyContent: 'center'
                    })}
                >
                    <Button
                        sx={{
                            color: '#FFF',
                            background: '#26c6da',
                            '&:hover': {
                                background: '#26c6da'
                            }
                        }}
                        onClick={onClose}
                    >
                        Done
                    </Button>
                </DialogActions>
            </TabPanel>
        </Dialog>
    )
})

type TabProps = {
    children: JSX.Element | JSX.Element[]
    value: number
    index: number
}

const TabPanel = ({ children, value, index }: TabProps) => {
    return <Box hidden={value !== index}>{children}</Box>
}
