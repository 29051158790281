import { useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { Box, Button, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as PremiumIcon } from 'assets/icons/premium.svg'
import { ReactComponent as BinGray } from 'assets/icons/bin_gray.svg'
import { ReactComponent as EditGray } from 'assets/icons/edit_gray.svg'

import { brandKitRequestPath } from './PageBrandKit'
import { PlanUsageBanner, UpgradePlanPopup, ConfirmationDialog } from 'UI/Components'
import { BrandKitCardDialog } from './sections/dialogs'

import { planTypes, useBoolean, useBrandKit, useNotification, useServiceUsage } from 'hooks'
import { openLink, request } from 'modules'

import { links } from 'app/links'
import { type MultiBrandKitItem } from 'app/types'
import { paths } from 'app/paths'

const BreadCrumbTypography = styled(Typography)({
    fontSize: 20,
    fontWeight: 600,
    marginRight: 8,
    cursor: 'pointer'
})

const ContactUsButton = styled(Button)({
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25
})

export const BrandKitHeader = () => {
    const { brandKitsLimit } = useServiceUsage()

    const history = useHistory()

    const { id: brandKitId } = useParams<{ id: string }>()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const showAddDialog = useBoolean()
    const showDeleteDialog = useBoolean()
    const showRenameDialog = useBoolean()

    const { revalidateMultiBrandKit, multiBrandKitData } = useBrandKit()

    const multiBrandKitItem = multiBrandKitData?.find(({ id }) => id === brandKitId) as
        | MultiBrandKitItem
        | undefined

    const multiBrandKitCounter = multiBrandKitData?.length || 1

    const { showSuccessNotification } = useNotification()

    const isReachedLimit = multiBrandKitCounter >= brandKitsLimit

    const { activePlanId } = useServiceUsage()

    return (
        <>
            <Box py={2} display="flex" alignItems="center" justifyContent="space-between">
                <Stack direction="row" height={35} alignItems="center">
                    <BreadCrumbTypography
                        onClick={() => {
                            if (multiBrandKitCounter == 1) return
                            history.push(paths.brandKit)
                            revalidateMultiBrandKit()
                        }}
                        sx={{
                            cursor: multiBrandKitCounter > 1 ? 'pointer' : 'default'
                        }}
                    >
                        Brand Kit
                    </BreadCrumbTypography>

                    {multiBrandKitItem && multiBrandKitCounter > 1 && (
                        <Box color="#090C10" fontWeight={400} fontSize={20}>
                            <KeyboardArrowRightIcon /> {multiBrandKitItem.name}
                        </Box>
                    )}
                </Stack>

                <PlanUsageBanner />

                {/*Rename or delete selected BrandKit*/}
                {multiBrandKitItem && multiBrandKitCounter > 1 && (
                    <Stack direction="row" spacing={3}>
                        <Button
                            color="inherit"
                            startIcon={<EditGray />}
                            sx={{
                                cursor: 'pointer',
                                color: 'rgba(9, 12, 16, 0.60)',
                                letterSpacing: 1.25
                            }}
                            onClick={showRenameDialog.setTrue}
                        >
                            RENAME
                        </Button>

                        {!multiBrandKitItem.isDefault && (
                            <Button
                                startIcon={<BinGray />}
                                color="inherit"
                                sx={{
                                    cursor: 'pointer',
                                    color: 'rgba(9, 12, 16, 0.60)',
                                    letterSpacing: 1.25
                                }}
                                onClick={showDeleteDialog.setTrue}
                            >
                                DELETE BRAND KIT
                            </Button>
                        )}
                    </Stack>
                )}

                {(!multiBrandKitItem || multiBrandKitCounter === 1) && (
                    <Button
                        color="inherit"
                        variant="contained"
                        size="large"
                        startIcon={brandKitsLimit === 1 ? <PremiumIcon /> : <PlusIcon />}
                        onClick={event => {
                            if (brandKitsLimit === 1 || isReachedLimit) {
                                return setAnchorEl(event.currentTarget)
                            }

                            showAddDialog.setTrue()
                        }}
                    >
                        ADD BRAND KIT
                    </Button>
                )}
            </Box>

            <Box mx={-3} bgcolor="rgba(9, 12, 16, 0.08)" height="1px" />

            {anchorEl && (
                <UpgradePlanPopup
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    hideSeePlanFeatures={isReachedLimit}
                    hidePrice={true}
                    points={
                        activePlanId === planTypes.enterprise
                            ? []
                            : ['Multiple brand kits', 'Access to studio voices', 'Auto Translation']
                    }
                    title="Upgrade your account"
                    planId={planTypes.enterprise}
                    text={
                        activePlanId === planTypes.enterprise ? (
                            <>
                                Hi there! Managing more than{' '}
                                {brandKitsLimit > 1 ? `${brandKitsLimit} brands` : '1 brand'}?{' '}
                                <br />
                                just contact our team and we'll be happy to assist you.
                            </>
                        ) : (
                            <>
                                Hi there! Managing more than{' '}
                                {brandKitsLimit > 1 ? `${brandKitsLimit} brands` : '1 brand'}? You
                                can create and manage up to 5 separate brands, all in one place.
                            </>
                        )
                    }
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    customActionButton={
                        <ContactUsButton
                            onClick={() => openLink(links.contactSales)}
                            variant="contained"
                        >
                            CONTACT US
                        </ContactUsButton>
                    }
                />
            )}

            {showDeleteDialog.isTrue && (
                <ConfirmationDialog
                    isOpen={true}
                    onClose={showDeleteDialog.setFalse}
                    onConfirm={() => {
                        if (!multiBrandKitItem) return

                        request(`${brandKitRequestPath}/${multiBrandKitItem.id}`, 'DELETE').then(
                            () => {
                                revalidateMultiBrandKit()
                                history.push(paths.brandKit)
                                showDeleteDialog.setFalse()
                                showSuccessNotification('Brand Kit successfully removed')
                            }
                        )
                    }}
                    title={`Delete ${multiBrandKitItem?.name}`}
                    text={`Are you sure you want to delete the ${multiBrandKitItem?.name} ?`}
                />
            )}

            {(showRenameDialog.isTrue || showAddDialog.isTrue) && (
                <BrandKitCardDialog
                    onClose={() => {
                        showAddDialog.setFalse()
                        showRenameDialog.setFalse()
                    }}
                    currentName={showRenameDialog.isTrue ? multiBrandKitItem?.name : ''}
                    id={multiBrandKitItem?.id}
                    isDefault={multiBrandKitItem?.isDefault}
                />
            )}
        </>
    )
}
