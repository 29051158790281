import { ReactComponent as TrialPlanStartedImage } from 'assets/images/trial-start.svg'

import { ServiceUsageDialog } from './ServiceUsageDialog'

type Props = {
    trialDays: number
    onClose: () => void
}

export const TrialPlanStartedDialog = ({ onClose, trialDays }: Props) => {
    const trialDuration = trialDays ? `${trialDays} days` : ''

    return (
        <ServiceUsageDialog
            title="Your trial has started!"
            onClose={onClose}
            description={`During your ${trialDuration} trial, you will have access to unlimited step-by-step videos with custom branding, blur of sensitive information, and activity feed`}
            actionText="Let’s go!"
            onActionClick={onClose}
            Image={<TrialPlanStartedImage />}
            maxDescriptionWidth={456}
        />
    )
}
