import { Form, Formik } from 'formik'

import { Box, Button, Grid, MenuItem, Select } from '@mui/material'

import { ConnectedSelect } from 'UI/Components'

import {
    useAuth,
    languageOptions,
    useNotification,
    useOrganization,
    useUpdateOrganization
} from 'hooks'
import { logToAnalytics } from 'modules'

export const PreferencesTab = () => {
    const { orgId } = useAuth()
    const { showSuccessNotification } = useNotification()

    const { defaultLanguage, scheduleURL, requestRouting, revalidateOrgData } = useOrganization()

    const $updateOrganization = useUpdateOrganization({
        orgId,
        onSuccess: () => {
            revalidateOrgData()
            showSuccessNotification('Organization has been updated')
            logToAnalytics('change_settings')
        }
    })

    return (
        <Formik
            enableReinitialize
            onSubmit={$updateOrganization.mutate}
            initialValues={{
                scheduleURL: scheduleURL || '',
                requestRouting: requestRouting || '',
                defaultLanguage: defaultLanguage || ''
            }}
        >
            <Box mt={2}>
                <Form>
                    <Row
                        label="Primary lang"
                        item={
                            <Select
                                disabled
                                value="en-US"
                                style={{ width: '300px' }}
                                variant="standard"
                            >
                                <MenuItem value="en-US">English</MenuItem>
                            </Select>
                        }
                    />

                    <Row
                        label="Secondary lang"
                        item={<ConnectedSelect name="defaultLanguage" options={languageOptions} />}
                    />

                    <Box mt={2}>
                        <Button size="medium" type="submit" variant="contained">
                            Update
                        </Button>
                    </Box>
                </Form>
            </Box>
        </Formik>
    )
}

type RowProps = {
    label: string
    item: JSX.Element
}

const Row = ({ label, item }: RowProps) => {
    return (
        <Box mb={2}>
            <Grid container alignItems="center">
                <Grid item xs={2}>
                    <b>{label}</b>
                </Grid>
                <Grid item xs={10}>
                    {item}
                </Grid>
            </Grid>
        </Box>
    )
}
