import { type MouseEvent, type CSSProperties, useEffect, useRef, useState, memo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import {
    type BoxProps,
    Box,
    ClickAwayListener,
    FormControl,
    InputAdornment,
    Paper,
    Popper,
    TextField,
    Typography,
    Link as MaterialLink,
    IconButton,
    styled
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

import { type SearchPageProps, SearchPage } from './SearchPage'

import { type UseBooleanType, useBoolean, useTextDebounce, useWindowView } from 'hooks'

import { type SearchPlaybookType } from 'app/types'

const PREFIX = 'PlaybookSearch'

const classes = {
    popper: `${PREFIX}-popper`,
    paper: `${PREFIX}-paper`,
    listItemLink: `${PREFIX}-listItemLink`,
    showMoreContainer: `${PREFIX}-showMoreContainer`,
    showMore: `${PREFIX}-showMore`,
    input: `${PREFIX}-input`,
    root: `${PREFIX}-root`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.popper}`]: {
        width: '100%',
        maxWidth: '1200px',
        zIndex: 100
    },

    [`& .${classes.paper}`]: {
        overflow: 'overlay'
    },

    [`& .${classes.listItemLink}`]: {
        color: '#212121',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },

    [`& .${classes.showMoreContainer}`]: {
        margin: theme.spacing(0, 4, 2),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 2, 2)
        }
    },

    [`& .${classes.showMore}`]: {
        paddingTop: '4px',
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(12)
        }
    },

    [`& .${classes.input}`]: {
        padding: '8px 4px',
        height: 19,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: 14,
            color: '#BDBDBD'
        }
    },

    [`& .${classes.root}`]: {
        borderColor: '#F5F5F5 !important'
    }
}))

type Props = {
    onResultClick?: (
        e: MouseEvent<HTMLElement>,
        playbook: SearchPlaybookType,
        open?: UseBooleanType
    ) => void
    // this field are using ONLY on space page search
    isFullSpaceSearch?: boolean
    seeAllLink?: string
    extraMixpanelProps?: {
        spaceId?: string
    }
    mb?: BoxProps['mb']
    ignoreFilters?: boolean
    compactResults?: boolean
    placeholder?: string
    compactMaxHeight?: CSSProperties['height']
    initialSearchText?: string
    isSaveResultToSearch?: boolean
    onSearchClear?: () => void
    onInputFocus?: () => void
} & SearchPageProps['searchParams']

export const PlaybookSearch = memo(
    ({
        onResultClick,
        seeAllLink = '',
        mb = 2,
        compactMaxHeight = 160,
        isFullSpaceSearch,
        extraMixpanelProps,
        ignoreFilters = false,
        compactResults = false,
        placeholder = 'Search video name, transcript or tags',
        searchParams,
        initialSearchText,
        isSaveResultToSearch = false,
        onSearchClear,
        onInputFocus
    }: Props) => {
        const anchorRef = useRef(null)

        const { isMobileView } = useWindowView()

        const [text, setText] = useState(initialSearchText ?? '')
        const debouncedText = useTextDebounce<string>(text)

        const validLength = debouncedText?.length > 2
        const preventOpen = useBoolean(true)
        const open = useBoolean()
        const history = useHistory()

        const hasMore = useBoolean()

        const [page, setPage] = useState(0)

        const pages = []

        for (let i = 0; i <= page; i++) {
            if (validLength) {
                pages.push(
                    <SearchPage
                        index={i}
                        searchParams={searchParams}
                        key={i}
                        text={debouncedText}
                        isFullSpaceSearch={isFullSpaceSearch}
                        hasMore={i === page ? hasMore : undefined}
                        onResultClick={(e, pb) => {
                            if (isSaveResultToSearch) {
                                preventOpen.setTrue()
                                setText(pb.title)
                            }
                            if (compactResults) open.setFalse()
                            onResultClick?.(e, pb, open)
                        }}
                        seeAllLink={seeAllLink}
                        extraMixpanelProps={extraMixpanelProps}
                        ignoreFilters={ignoreFilters}
                        compactResults={compactResults}
                    />
                )
            }
        }

        const handleChange = (e: any) => {
            e.persist()
            preventOpen.setFalse()

            const value = e.target?.value
            setText(value)
            if (!value.length) onSearchClear?.()

            setPage(0)
        }

        const onKeyDown = ({ keyCode }: { keyCode: number }) => {
            if (keyCode === 13 && seeAllLink && validLength) {
                history.push({
                    pathname: generatePath(seeAllLink),
                    search: `?search=${debouncedText}`
                })
            }
        }

        const setOpen = open.set

        useEffect(() => {
            if (preventOpen.isTrue) return

            setOpen(validLength)
        }, [debouncedText, preventOpen.isTrue, setOpen, validLength])

        return (
            <StyledBox mb={mb} style={{ position: 'relative' }} maxWidth={750} width="100%">
                <FormControl fullWidth>
                    <TextField
                        ref={anchorRef}
                        value={text}
                        data-test="playbook-search"
                        placeholder={placeholder}
                        onClick={() => {
                            if (!validLength) return

                            open.setTrue()
                        }}
                        onChange={handleChange}
                        onKeyDown={onKeyDown}
                        onFocus={onInputFocus}
                        variant="outlined"
                        InputProps={{
                            classes: { input: classes.input, root: classes.root },
                            sx: {
                                background: '#F5F5F5'
                            },
                            startAdornment: (
                                <InputAdornment position="start" sx={{ ml: -1 }}>
                                    <SearchIcon
                                        fontSize={isMobileView ? 'small' : 'medium'}
                                        style={{
                                            color: '#BDBDBD',
                                            width: 18,
                                            height: 18
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: text && (
                                <InputAdornment position="end" sx={{ mr: -1 }}>
                                    <IconButton
                                        onClick={event => {
                                            event.stopPropagation()
                                            setText('')
                                            onSearchClear?.()
                                        }}
                                    >
                                        <CloseIcon
                                            fontSize={isMobileView ? 'small' : 'medium'}
                                            style={{
                                                width: 18,
                                                height: 18
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>

                <Popper
                    open={open.isTrue}
                    anchorEl={anchorRef?.current}
                    placement="bottom-start"
                    disablePortal
                    className={classes.popper}
                    modifiers={[
                        { name: 'flip', enabled: false },
                        {
                            name: 'preventOverflow',
                            enabled: true
                        }
                    ]}
                >
                    <ClickAwayListener
                        onClickAway={() => {
                            setPage(0)
                            open.setFalse()
                        }}
                    >
                        <Paper
                            elevation={3}
                            className={classes.paper}
                            style={{ maxHeight: compactResults ? compactMaxHeight : 600 }}
                        >
                            <Box p={1} pb={1}>
                                {pages}
                            </Box>
                            {validLength && hasMore.isTrue && (
                                <Box className={classes.showMoreContainer} display="inline-block">
                                    <MaterialLink
                                        className={classes.listItemLink}
                                        onClick={event => {
                                            event.preventDefault()
                                            setPage(prev => prev + 1)
                                        }}
                                    >
                                        <Typography component="span" className={classes.showMore}>
                                            Show more
                                        </Typography>
                                    </MaterialLink>
                                </Box>
                            )}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </StyledBox>
        )
    }
)
