import { ReactComponent as NextIcon } from 'assets/icons/roll-next.svg'
import { ReactComponent as PrevIcon } from 'assets/icons/roll-prev.svg'

import { Button, Stack, styled } from '@mui/material'

const StyledButton = styled(Button)({
    background: 'rgba(0, 0, 0, 0.4)',
    color: '#fff !important',
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.3)'
    }
})

type Props = {
    onClickPrev: () => void
    onClickNext: () => void
    disableNext?: boolean
    disablePrev?: boolean
    isVisible: boolean
}

export const ChangeActiveVideo = ({
    onClickPrev,
    onClickNext,
    disableNext = false,
    disablePrev = false,
    isVisible
}: Props) => {
    return (
        <Stack
            direction="row"
            spacing={3}
            sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                padding: '0 28px',
                width: '100%',
                position: 'absolute',
                top: 20,
                transition: 'all 0.1s linear',
                zIndex: 1,
                ...(isVisible
                    ? { opacity: 1, transform: 'translateY(0)' }
                    : { opacity: 0, transform: 'translateY(-30px)' })
            }}
        >
            <StyledButton
                size="small"
                disabled={disablePrev}
                onClick={onClickPrev}
                startIcon={<PrevIcon />}
            >
                Prev Video
            </StyledButton>

            <StyledButton
                size="small"
                disabled={disableNext}
                onClick={onClickNext}
                startIcon={<NextIcon />}
            >
                Next Video
            </StyledButton>
        </Stack>
    )
}
