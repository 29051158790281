import {
    Box,
    Typography,
    IconButton,
    MenuItem,
    Select,
    Divider,
    Button,
    styled
} from '@mui/material'

import { ConnectedFilledInput, SpacedGroup } from 'UI/Components'

import CloseIcon from '@mui/icons-material/Close'
import PeopleAltIcon from '@mui/icons-material/LockOutlined'
import AccountIcon from '@mui/icons-material/AccountCircleOutlined'

import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'

import { AvatarsList } from './AvatarList'
import { type TabsType, tabs } from './SpaceFields'
import { type SetFieldValueType, type FormSpaceType, type UserOption } from './types'

import { TextOverflow } from '@guidde-co/shared.textoverflow'
import { SpaceColorPicker } from './SpaceColorPicker'

const StyledSelect = styled(Select)({
    fontSize: '14px',
    fontWeight: 500,
    paddingBottom: 0,
    '&:before, &:after': {
        display: 'none'
    }
})

const RoundedIcon = styled(Box)({
    background: '#EDEEEF',
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    '& svg': {
        color: 'inherit'
    }
})

const schema = {
    1: {
        icon: <PeopleAltIcon />,
        text: 'Members must be invited by the Space owner.'
    },
    0: {
        icon: <SupervisorAccountOutlinedIcon />,
        text: 'Anyone in your organization can view, invite and join this Space.'
    }
}

type Props = {
    setActiveTab: (tab: TabsType) => void
    setFieldValue: SetFieldValueType
    values: FormSpaceType
    title: string
    withNotOwnerRole: Array<UserOption>
    withOwnerRole: Array<UserOption>
    onClose: () => void
}

export const MainTab = ({
    values,
    withNotOwnerRole,
    withOwnerRole,
    title,
    setActiveTab,
    onClose,
    setFieldValue
}: Props) => (
    <>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <TextOverflow lineClamp={1}>
                <Typography style={{ fontWeight: 500 }}>{title}</Typography>
            </TextOverflow>
            <IconButton onClick={onClose} size="small">
                <CloseIcon />
            </IconButton>
        </Box>

        <ConnectedFilledInput
            data-test="space-name"
            name="name"
            label="Space name"
            onChange={e => setFieldValue('name', e.target.value.trimStart())}
        />

        <ConnectedFilledInput
            data-test="space-description"
            name="description"
            label="Description"
            multiline
            inputProps={{ maxLength: 500 }}
        />

        <SpaceColorPicker
            activeColor={values.color}
            onSelect={color => setFieldValue('color', color)}
        />

        <Box mt={2} />
        <Typography style={{ fontWeight: 600 }}>Access</Typography>
        <SpacedGroup mt={1}>
            <RoundedIcon mr={1}>{schema[values.isPrivate ? 1 : 0]?.icon}</RoundedIcon>
            <Box>
                <StyledSelect
                    variant="standard"
                    value={Number(values.isPrivate)}
                    onChange={e => setFieldValue('isPrivate', Boolean(e.target.value))}
                    MenuProps={{
                        sx: theme => ({
                            '& ul': {
                                width: '225px',
                                padding: theme.spacing(1),
                                '& .Mui-selected': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)'
                                },
                                '& li': {
                                    width: '100%',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    padding: theme.spacing(1.7, 2)
                                }
                            }
                        })
                    }}
                    renderValue={v => (v ? 'Private' : 'Restricted')}
                >
                    <MenuItem value={1}>Private</MenuItem>
                    <MenuItem value={0}>Restricted</MenuItem>
                </StyledSelect>
                <Typography color="textSecondary" style={{ fontSize: 14 }}>
                    {schema[values.isPrivate ? 1 : 0]?.text}
                </Typography>
            </Box>
        </SpacedGroup>

        <Box mt={2}>
            <Typography style={{ fontWeight: 600 }}>Members</Typography>

            <SpacedGroup mt={2} style={{ overflowX: 'auto' }} alignItems="flex-start">
                <AvatarsList items={withOwnerRole} limit={3} label="Owners" />

                <Box height="50px" mt={2}>
                    <Divider orientation="vertical" />
                </Box>

                <AvatarsList items={withNotOwnerRole} limit={6} label="Members" />
            </SpacedGroup>
        </Box>
        <Box>
            <Button
                color="secondary"
                variant="text"
                style={{
                    fontWeight: 500,
                    fontSize: 14,
                    letterSpacing: 1.25,
                    textTransform: 'uppercase'
                }}
                startIcon={<AccountIcon fontSize="small" />}
                onClick={() => setActiveTab(tabs.members)}
            >
                Add/edit members
            </Button>
        </Box>
    </>
)
