import { styled, Box, CircularProgress, Dialog, Typography } from '@mui/material'

const PREFIX = 'FullScreenLoader'

const classes = {
    paper: `${PREFIX}-paper`,
    text: `${PREFIX}-text`
}

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
    [`& .${classes.paper}`]: {
        width: 310,
        padding: spacing(3),
        boxShadow: 'none',
        background: '#F5F5F5',
        borderRadius: 20,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'visible',
        '&::-webkit-scrollbar': {
            height: 0,
            width: 0,
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            height: 0,
            background: 'transparent'
        }
    },

    [`& .${classes.text}`]: {
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: 0.15,
        textAlign: 'center'
    }
}))

type Props = {
    isOpen: boolean
    title: string
    text?: string
    dataCy?: string
}
export const FullScreenLoader = ({ isOpen, title, text, dataCy }: Props) => {
    if (!isOpen) return null

    return (
        <StyledDialog
            open
            fullWidth
            data-test={dataCy}
            classes={{
                paper: classes.paper
            }}
            maxWidth="xs"
        >
            <CircularProgress size={54} variant="indeterminate" />
            <Box mt={2} />
            <Typography className={classes.text}>{title}</Typography>

            <Typography className={classes.text}>{text}</Typography>
        </StyledDialog>
    )
}
