import { useEffect, useRef } from 'react'

import { styled } from '@mui/system'

const generateThumbnail = (
    canvas: HTMLCanvasElement,
    imageUrl: string,
    frameWidth: number,
    frameHeight: number,
    gridSize: number
) => {
    const canvasWidth = canvas.offsetWidth
    canvas.width = canvasWidth // setting canvas content width to 100% of the container

    const ctx = canvas.getContext('2d')
    ctx!.fillStyle = 'black'
    ctx!.clearRect(0, 0, canvas.width, canvas.height)

    const image = new Image()
    image.src = imageUrl

    image.onload = () => {
        const amountOfFrames = Math.ceil(canvasWidth / frameWidth)
        const arrayOfIndexes = getMiddleIndexes(amountOfFrames, gridSize * gridSize)

        arrayOfIndexes.map((frameValue, index) => {
            const xIndex = frameValue % gridSize
            const yIndex = Math.floor(frameValue / gridSize)

            const frameX = frameWidth * xIndex
            const frameY = frameHeight * yIndex
            const destinationX = frameWidth * index
            const destinationY = 0

            ctx!.drawImage(
                image,
                frameX,
                frameY,
                frameWidth,
                frameHeight,
                destinationX,
                destinationY,
                frameWidth,
                frameHeight
            )
        })
    }
}

const getMiddleIndexes = (value: number, totalElements: number) => {
    const step = Math.floor((totalElements - 1) / (value - 1))
    const middleValues = Array.from({ length: value - 2 }, (_, i) => (i + 1) * step)
    return [0, ...middleValues, totalElements - 1]
}

const StyledCanvas = styled('canvas')({
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderTop: '3px solid lightgray',
    borderBottom: '3px solid lightgray'
})

type Props = {
    thumbnailUrl: string
}
export const VideoThumbnail = ({ thumbnailUrl }: Props) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)

    useEffect(() => {
        if (!thumbnailUrl || !canvasRef.current) return

        generateThumbnail(canvasRef.current, thumbnailUrl, 80, 45, 5)
    }, [thumbnailUrl])

    return <StyledCanvas ref={canvasRef} height={45} />
}
