import { useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import { InstallExtDialog } from './InstallExtDialog'

import { useBoolean } from 'hooks'

import { sendSoleMessageToExt } from 'modules'

const installedTexts = {
    text1: 'Capture your',
    text2: 'first how-to video'
}

const notInstalledTexts = {
    text1: 'Add your first',
    text2: 'how-to video'
}
export const OnboardingExtCard = () => {
    const extDialog = useBoolean()

    const isExtensionInstalled = useSelector(state => state.extensionStatus.isInstalled)
    const isQGRecording = useSelector(state => state.extensionStatus.qgIsRecording)

    const texts = isExtensionInstalled ? installedTexts : notInstalledTexts

    return (
        <>
            <Box
                data-test="add-your-first-how-to-video-card"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                style={{ cursor: isQGRecording ? 'auto' : 'pointer' }}
                onClick={() => {
                    if (isQGRecording) return

                    if (!isExtensionInstalled) {
                        extDialog.setTrue()
                    } else {
                        sendSoleMessageToExt({
                            msg: { type: 'WEBAPP/LAUNCH_EXT_QG_RECORDER' }
                        })
                    }
                }}
                alignItems="center"
                flex={1}
                width="100%"
                height={220}
                border="1px solid #E0E0E0"
                borderRadius="4px"
            >
                <AddIcon color="primary" />
                <Box mt={2} />
                <Typography variant="caption">{texts.text1}</Typography>
                <Typography variant="caption">{texts.text2}</Typography>
            </Box>
            <InstallExtDialog
                isOpen={extDialog.isTrue}
                onClose={extDialog.setFalse}
                onDownloadLaterClick={extDialog.setFalse}
            />
        </>
    )
}
