import { useEffect, useState } from 'react'

export const useTextDebounce = <T extends string | number | boolean>(
    payload: T,
    delay = 500
): T => {
    const [text, setText] = useState<T>('' as T)

    useEffect(() => {
        const timeOutId = setTimeout(() => setText(payload), delay)

        return () => clearTimeout(timeOutId)
    }, [delay, payload])

    return text
}
