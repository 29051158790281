import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import dayjs from 'dayjs'

import { ref } from 'firebase/database'
import { useObjectVal } from 'react-firebase-hooks/database'

import TheatersIcon from '@mui/icons-material/Theaters'

import { Button, TableRow, TableCell, Box } from '@mui/material'

import { paths } from 'app/paths'
import { useArrayStorage, useAuth, useBoolean, useNotification } from 'hooks'
import { openUploadProgressPopup, setVideoProgress } from 'ducks/actions'

import * as ZOOM_API from 'modules/zoom'
import { logToAnalytics, uuid, batchUpload, logVideoImport, rtdb } from 'modules'

import { FileTable, FileItem, UploaderModal, DateRangePicker } from 'UI/Components'

import { useUploadFileMaxLimit } from '../use-upload-file-max-limit'
import { zoomColumns, sortZoomFiles } from './constants'

const DEFAULT_START_TIME = 4 * 7 * 24 * 60 * 60 * 1000 // 28 days in ms
const DEFAULT_SORT_BY = 'startTime'

export const ZoomUploader = ({ labelComponent }) => {
    const dispatch = useDispatch()
    const { search: locationQuery } = useLocation()
    const { replace: replaceQuery, push: pushToHistory } = useHistory()
    const { isTrue: isLoading, setTrue: enableLoading, setFalse: disableLoading } = useBoolean()
    const selectedItems = useArrayStorage([], 'id')
    const [recordings, setRecordings] = useState([])
    const [selectedDate, handleDateChange] = useState({
        startDate: dayjs(new Date().getTime() - DEFAULT_START_TIME),
        endDate: dayjs()
    })
    const [{ sortBy, sortDesc }, setSortParams] = useState({
        sortBy: DEFAULT_SORT_BY,
        sortDesc: true
    })
    const { showSuccessNotification } = useNotification()

    const { maxUploadFileSizeInBytes } = useUploadFileMaxLimit()

    const changeSorting = useCallback(
        sortBy =>
            setSortParams(prevSort => ({
                sortBy,
                sortDesc: sortBy === prevSort.sortBy ? !prevSort.sortDesc : false
            })),
        []
    )

    const { displayName, photoURL, email, uid, token } = useAuth()

    const [zoomConnected, zoomLoading, zoomError] = useObjectVal(
        ref(rtdb, `zoomUsers/${uid}/connected`)
    )

    useEffect(() => {
        const queryParams = new URLSearchParams(locationQuery)
        const code = queryParams.get('code')

        if (code) {
            ZOOM_API.sendAuthCode(code).finally(() => {
                queryParams.delete('code')
                replaceQuery({ search: queryParams.toString() })
                showSuccessNotification(
                    'Connected successfully! Any new recording will be uploaded automatically to your videos as private. For more information, see zoom settings.'
                )
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // If called twice, auth code will become invalid

    useEffect(() => {
        if (!zoomLoading && !zoomError && zoomConnected) {
            enableLoading()
            ZOOM_API.getRecordings(selectedDate)
                .then(res => {
                    const recordings = res?.meetings?.reduce((allRecordings, meeting) => {
                        // Can be more than 1 recording for the meeting
                        const mp4Files = meeting.recording_files.filter(
                            file => file.file_type === 'MP4'
                        )

                        if (mp4Files.length) {
                            const files = mp4Files.map(file => ({
                                title: meeting.topic,
                                id: file.id,
                                url: file.download_url,
                                fileSize: file.file_size,
                                fileType: file.fileType,
                                fileMimeType: file.fileMimeType,
                                startTime: file.recording_start
                            }))
                            return allRecordings.concat(files)
                        }

                        return allRecordings
                    }, [])

                    setRecordings(recordings || [])
                })
                .finally(disableLoading)
        }
    }, [selectedDate, enableLoading, disableLoading, token, zoomConnected, zoomLoading, zoomError])

    const { storage, areAllSelected, clearStorage } = selectedItems
    const validVideoFiles = useMemo(
        () => recordings.filter(file => file.fileSize < maxUploadFileSizeInBytes),
        [recordings, maxUploadFileSizeInBytes]
    )
    const areAllRecordingsSelected = useMemo(
        () => areAllSelected(validVideoFiles),
        [areAllSelected, validVideoFiles]
    )
    const clearSelection = useCallback(() => clearStorage(), [clearStorage])

    const uploadedBy = useMemo(
        () => ({
            displayName: displayName || '',
            photoURL: photoURL || '',
            email: email || ''
        }),
        [displayName, email, photoURL]
    )

    const uploadFiles = useCallback(() => {
        enableLoading()
        const fileList = []
        const uploadPromises = storage.map(file => {
            const uploadId = uuid()

            dispatch(
                setVideoProgress({
                    playbookId: uploadId,
                    fileName: file.name,
                    source: 'zoom'
                })
            )
            return ZOOM_API.uploadZoomFile(file, uid, uploadId, uploadedBy).then(() => {
                fileList.push(uploadId)

                logVideoImport('Zoom', {
                    type:
                        file.mimeType ||
                        file.mime_type ||
                        file.file_type ||
                        file.type ||
                        file.fileType,
                    size: Number(file.file_size) || Number(file.size) || Number(file.fileSize)
                })

                logToAnalytics('upload_from_zoom', {
                    'pb-id': uploadId,
                    'pb-title': file.title
                })
            })
        })

        Promise.all(uploadPromises)
            .then(() => {
                batchUpload({
                    uploadedBy: uid,
                    fileList,
                    drive: 'zoom'
                })
                pushToHistory({ pathname: paths.myVideos })

                const filesObject = storage.reduce(
                    (a, file) => ({
                        ...a,
                        [file.id]: {
                            id: file.id,
                            name: file.title,
                            source: 'zoom',
                            type: 'video'
                        }
                    }),
                    {}
                )

                dispatch(openUploadProgressPopup(filesObject))
            })
            .finally(disableLoading)
    }, [pushToHistory, storage, dispatch, uid, uploadedBy, enableLoading, disableLoading])

    const sortedFiles = useMemo(
        () => recordings.sort((a, b) => sortZoomFiles(sortBy, sortDesc, a, b)),
        [recordings, sortBy, sortDesc]
    )

    return (
        <UploaderModal
            trigger={labelComponent}
            title="Zoom Cloud Recordings"
            openQueryParam="zoomDialog"
            subtitle={
                <DateRangePicker
                    startDate={selectedDate.startDate}
                    onChangeStartDate={date =>
                        handleDateChange(prevDate => ({
                            ...prevDate,
                            startDate: date
                        }))
                    }
                    endDate={selectedDate.endDate}
                    onChangeEndDate={date =>
                        handleDateChange(prevDate => ({
                            ...prevDate,
                            endDate: date
                        }))
                    }
                />
            }
            selectedItems={storage.length}
            areAllItemsSelected={areAllRecordingsSelected}
            onSelectAll={() =>
                areAllRecordingsSelected
                    ? selectedItems.deleteMultiple(validVideoFiles)
                    : selectedItems.addMultiple(validVideoFiles)
            }
            onClose={clearSelection}
            onSubmit={uploadFiles}
        >
            <FileTable
                isLoading={zoomLoading || isLoading}
                columns={zoomColumns}
                sortBy={sortBy}
                sortDesc={sortDesc}
                onSortChange={changeSorting}
            >
                {!zoomLoading && !zoomError && (
                    <>
                        {!zoomConnected && (
                            <EmptyState>
                                <Box>
                                    <Box>Zoom account not found</Box>
                                    <Button
                                        variant="contained"
                                        onClick={ZOOM_API.initZoomAuthFlow}
                                        sx={{ width: 'fit-content', margin: '1rem auto' }}
                                    >
                                        Connect Zoom
                                    </Button>
                                </Box>
                            </EmptyState>
                        )}
                        {zoomConnected &&
                            (sortedFiles.length === 0 ? (
                                <EmptyState>
                                    No recordings found. Try selecting different dates.
                                </EmptyState>
                            ) : (
                                sortedFiles.map(recording => {
                                    const isSizeValid =
                                        recording.fileSize < maxUploadFileSizeInBytes

                                    return (
                                        <FileItem
                                            key={recording.id}
                                            isSelectable={isSizeValid}
                                            showCheckbox={isSizeValid}
                                            showSizeWarning={!isSizeValid}
                                            columns={zoomColumns}
                                            thumbnail={<TheatersIcon />}
                                            file={recording}
                                            isSelected={selectedItems.isSelected(recording)}
                                            onClick={() => selectedItems.toggleItem(recording)}
                                        />
                                    )
                                })
                            ))}
                    </>
                )}
            </FileTable>
        </UploaderModal>
    )
}

const EmptyState = ({ children }) => {
    return (
        <TableRow>
            <TableCell
                colSpan={99}
                sx={{
                    padding: '3rem 0',
                    color: '#78909c',
                    borderBottom: 'unset',
                    textAlign: 'center'
                }}
            >
                {children}
            </TableCell>
        </TableRow>
    )
}
