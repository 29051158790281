import { Box, Toolbar, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { UtmLink, SpacedGroup } from 'UI/Components'

import { type PlaybookType, type QuickGuiddeType } from 'app/types'
import { useAnonymAuth, usePublicBrandKit } from 'hooks'

type Props = {
    playbook: PlaybookType | QuickGuiddeType
}

export const MobileStartUsingMessage = ({ playbook }: Props) => {
    const { isAnonymous, isLoadingAuth } = useAnonymAuth()

    const { brandKitLoading, isEnterprise } = usePublicBrandKit(
        isAnonymous && !isLoadingAuth ? playbook.uploadedByOrgId : ''
    )

    if (isEnterprise || brandKitLoading) return null

    return (
        <Toolbar>
            <SpacedGroup
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                bgcolor="#CB0000"
                py={2.5}
                px={3}
                boxShadow="0px -4px 4px rgba(0, 0, 0, 0.25)"
                justifyContent="space-between"
                pr="75px"
            >
                <Box>
                    <Typography style={{ color: '#fff', fontWeight: 500 }}>
                        Use guidde for free
                    </Typography>
                    <Typography style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                        Capture how-tos in seconds
                    </Typography>
                </Box>
                <UtmLink
                    playbook={playbook}
                    params={{
                        utm_campaign: 'get+started',
                        utm_medium: 'public-mobile-footer'
                    }}
                >
                    <Box
                        style={{ cursor: 'pointer' }}
                        borderRadius={4}
                        bgcolor="#090C10"
                        width={40}
                        height={40}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ArrowForwardIcon style={{ color: '#fff' }} />
                    </Box>
                </UtmLink>
            </SpacedGroup>
        </Toolbar>
    )
}
