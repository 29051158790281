import { Box, Grid, LinearProgress } from '@mui/material'

import { useOrganization, useNotification, useUpdateOrganization } from 'hooks'

import { OrganizationForm } from './OrganizationForm'
import { CompanyStatistic } from './CompanyStatistic'
import { UpcomingPlanNotification } from './UpcomingPlanNotification'
import { ExceededUsersNotification } from '../ExceededUsersNotification'

import { logToAnalytics } from 'modules'

type Props = {
    orgId: string
    usersExceeded: boolean
}

export const AccountTab = ({ orgId, usersExceeded }: Props) => {
    const { showSuccessNotification } = useNotification()

    const { isLoading, revalidateOrgData } = useOrganization(orgId)

    const $updateOrganization = useUpdateOrganization({
        orgId,
        onSuccess: () => {
            revalidateOrgData()
            showSuccessNotification('Organization has been updated')
            logToAnalytics('change_settings')
        }
    })

    return (
        <>
            <Box height={4}>
                {(isLoading || $updateOrganization.isLoading) && <LinearProgress />}
            </Box>

            <Grid container>
                <Grid item sm={6} md={4}>
                    <OrganizationForm
                        orgId={orgId}
                        disabled={isLoading || $updateOrganization.isLoading}
                        onSubmit={$updateOrganization.mutate}
                    />
                </Grid>
                <Grid item sm={7} md={3} />

                <Grid item sm={7} md={5}>
                    <UpcomingPlanNotification />
                    {usersExceeded && <ExceededUsersNotification />}

                    <CompanyStatistic />
                </Grid>
            </Grid>
        </>
    )
}
