import { Box } from '@mui/material'

import AppsIcon from '@mui/icons-material/Apps'

import { SpacedGroup } from 'UI/Components'

type Props = {
    imgURLs: Array<string>
    showNothing?: boolean
}
export const AppImages = ({ imgURLs, showNothing }: Props) => {
    const emptyResult = showNothing ? null : <AppsIcon />

    if (!imgURLs.length) return emptyResult

    return (
        <SpacedGroup spacing={0.5} mr={1}>
            {imgURLs
                .filter(Boolean)
                .slice(0, 3)
                .map((imgURL, i) => (
                    <Box
                        key={i}
                        sx={{
                            width: '40px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img
                            alt="placeholder"
                            src={imgURL}
                            style={{ width: '40px', height: '20px', objectFit: 'contain' }}
                        />
                    </Box>
                ))}
        </SpacedGroup>
    )
}
