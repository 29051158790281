import { type ReactNode, createContext, useContext, useEffect } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { useSessionStorage } from 'hooks'
import { paths } from 'app/paths'

const MenuStatusContext = createContext({
    isMenuClosed: false,
    setIsMenuClosed: (_value: boolean) => {}
})

export const useMenuStatus = () => useContext(MenuStatusContext)

type ProviderProps = {
    children: ReactNode
}

export const MenuStatusProvider = ({ children }: ProviderProps) => {
    const isQGEditPage = useRouteMatch(paths.editQuickGuidde)?.isExact || false

    const [isMenuClosed, setIsMenuClosed] = useSessionStorage('isMainMenuClosed', isQGEditPage)

    const location = useLocation<{ prevPath: string }>()

    useEffect(() => {
        if (!location || !isQGEditPage) return

        setIsMenuClosed(true)
    }, [isQGEditPage, location, setIsMenuClosed])

    return (
        <MenuStatusContext.Provider
            value={{
                setIsMenuClosed,
                isMenuClosed
            }}
        >
            {children}
        </MenuStatusContext.Provider>
    )
}
