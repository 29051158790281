import { forwardRef, memo } from 'react'
import { type ButtonProps, Box, Button, CircularProgress } from '@mui/material'

const ForwardedRoundedWhiteButton = forwardRef(
    (
        {
            children,
            disabled,
            height,
            isLoading,
            ...props
        }: ButtonProps & { height: number; isLoading?: boolean },
        ref: any
    ) => {
        return (
            <Box position="relative">
                {isLoading && (
                    <CircularProgress
                        size={height}
                        thickness={2}
                        style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 5
                        }}
                    />
                )}
                <Button
                    ref={ref}
                    {...props}
                    disabled={disabled || isLoading}
                    key={Number(disabled)}
                    sx={{
                        background: '#FFF !important',
                        fontWeight: 500,
                        fontSize: 14,
                        letterSpacing: 1.25,
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        color: disabled ? '#BDBDBD' : '#090C10',
                        '& svg': {
                            color: disabled ? '#BDBDBD' : '#090C10'
                        },
                        minWidth: height,
                        width: height,
                        height: height,
                        padding: '12px',
                        borderRadius: '50%',
                        boxShadow: 'none'
                    }}
                >
                    {children}
                </Button>
            </Box>
        )
    }
)

export const RoundedWhiteButton = memo(ForwardedRoundedWhiteButton)
