import {
    type FunctionComponent,
    type ReactNode,
    type SVGProps,
    type SyntheticEvent,
    Fragment,
    useRef
} from 'react'

import { styled, Box, Divider, MenuItem, Paper, Tooltip, Typography } from '@mui/material'
import type SvgIcon from '@mui/material/SvgIcon'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { useBoolean } from 'hooks'

const MENU_WIDTH = 240

const LightTooltip = styled(Tooltip)(({ theme }) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11
    }
}))

type Props = {
    endWithDivider?: boolean
    icon?: typeof SvgIcon | FunctionComponent<SVGProps<SVGSVGElement>>
    text: string
    extraIcon?: ReactNode
    disabled?: boolean
    dataCy?: string
    onClick?: (event: SyntheticEvent) => void
    nestedItems?: Array<Props & { hidden?: boolean }>
    extraNestedItem?: JSX.Element
    tooltipText?: string
    width?: number
}

export const NestedMenuItem = ({
    icon,
    text,
    disabled,
    extraIcon,
    endWithDivider,
    onClick,
    dataCy,
    nestedItems,
    extraNestedItem,
    tooltipText = '',
    width = MENU_WIDTH
}: Props) => {
    const Icon = icon

    const showNestedMenu = useBoolean()

    const ref = useRef<HTMLDivElement>(null)
    const Wrapper = (nestedItems ? Box : Fragment) as any

    const nestedItemsProps = {
        ref,
        position: 'relative',
        onMouseEnter: showNestedMenu.setTrue,
        onMouseLeave: showNestedMenu.setFalse
    }

    return (
        <Wrapper {...(nestedItems ? nestedItemsProps : {})}>
            <LightTooltip title={tooltipText} placement="top">
                <span>
                    <MenuItem
                        sx={{ padding: '12px 16px', width: '100%', alignItems: 'center' }}
                        onClick={e => {
                            // Prevent closing menu when clicking on item with nested items
                            if (nestedItems) e.stopPropagation()
                            onClick?.(e)
                        }}
                        disabled={disabled}
                    >
                        {Icon && (
                            <Box minWidth={30}>
                                <Icon fontSize="small" />
                            </Box>
                        )}

                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography data-test={dataCy}>{text}</Typography>
                            {extraIcon}
                            {nestedItems && <ArrowRightIcon />}
                        </Box>
                    </MenuItem>
                </span>
            </LightTooltip>
            {endWithDivider && <Divider />}

            {nestedItems && (
                <Paper
                    style={{
                        minWidth: width,
                        position: 'absolute',
                        left: width,
                        top: 0,
                        ...(showNestedMenu.isFalse ? { opacity: 0, pointerEvents: 'none' } : {})
                    }}
                >
                    {nestedItems.map((item, idx) => {
                        if (item.hidden) return null

                        return <NestedMenuItem key={idx} {...item} />
                    })}
                    {/* Currently used for FileUploader only since it requires different structure */}
                    {extraNestedItem}
                </Paper>
            )}
        </Wrapper>
    )
}
