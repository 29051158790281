import { type ChangeEvent, useState } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    LinearProgress,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { negativeAnswersList } from './VideoReactions'

import { useSetPlaybookReaction, reactionValues } from 'hooks'

type Props = {
    isOpen: boolean
    onClose: () => void
    loading?: boolean
    playbookId: string
}

export const NotHelpfulDialog = ({ isOpen, onClose, playbookId }: Props) => {
    const { setUserReaction, isLoading } = useSetPlaybookReaction(playbookId)

    const [value, setValue] = useState<string | number>('')

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(+(event.target as HTMLInputElement).value)
    }

    const sendReaction = () => {
        setUserReaction({
            reaction: reactionValues.notHelpful,
            result: null
        }).then(() => {
            onClose()
            setValue('')
        })
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            {isLoading && <LinearProgress />}
            <IconButton
                aria-label="close"
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    padding: '4px',
                    margin: '4px'
                }}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>

            <DialogTitle>
                <Typography align="center" sx={{ fontWeight: 600 }}>
                    Would you mind tell us why?
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <RadioGroup name="gender1" value={value} onChange={handleChange}>
                        {negativeAnswersList.map((text, i) => (
                            <FormControlLabel
                                key={i}
                                value={i + 1}
                                control={<Radio />}
                                label={
                                    <Typography variant="subtitle2" sx={{ fontSize: '13px' }}>
                                        {text}
                                    </Typography>
                                }
                            />
                        ))}

                        <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label={
                                <Typography variant="subtitle2" sx={{ fontSize: '13px' }}>
                                    Rather not
                                </Typography>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ p: 3, justifyContent: 'center' }}>
                <Button
                    size="medium"
                    variant="contained"
                    onClick={sendReaction}
                    disabled={isLoading || value === ''}
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}
