import { useState, memo } from 'react'
import dayjs, { type Dayjs } from 'dayjs'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Box, TextField, Popover, Button, ClickAwayListener } from '@mui/material'

type Props = {
    minDate?: Dayjs
    maxDate?: Dayjs
    value: Dayjs | null
    onChange: (date: Dayjs) => void
    showToday?: boolean
    placeholder?: string
}

export const DatePicker = memo(
    ({ minDate, maxDate, value, onChange, showToday = true, placeholder }: Props) => {
        const initialDate = (value ? value : dayjs(maxDate)).startOf('day') // To avoid time bugs

        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
        const [calendarDate, changeCalendarDate] = useState(initialDate)

        const applyDate = (date: Dayjs, shouldClose = true) => {
            const formattedDate = date.startOf('day')
            changeCalendarDate(date)
            if (date.isBetween(minDate ?? dayjs(0), maxDate ?? dayjs(), 'day', '[]')) {
                onChange(formattedDate)
            }
            if (shouldClose) {
                setAnchorEl(null)
            }
        }

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                    value={value ? value.format('DD MMM, YYYY') : ''}
                    variant="standard"
                    sx={{
                        margin: '0 0.5rem',
                        maxWidth: '7.5rem',
                        '& input': {
                            textAlign: 'center'
                        }
                    }}
                    onClick={e => setAnchorEl(e.currentTarget)}
                    placeholder={placeholder}
                />

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    disablePortal
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Box>
                            {showToday && (
                                <Box
                                    sx={theme => ({
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0.5rem 1rem',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: theme.palette.primary.main
                                    })}
                                >
                                    {initialDate.format('ddd DD MMM, YYYY')}
                                    <Button
                                        sx={{
                                            bgcolor: '#e0e0e0',
                                            color: '#000',
                                            '&:hover': {
                                                bgcolor: '#e0e0e0'
                                            }
                                        }}
                                        color="inherit"
                                        onClick={() => applyDate(dayjs().startOf('day'), false)}
                                    >
                                        Today
                                    </Button>
                                </Box>
                            )}
                            <DateCalendar
                                value={calendarDate}
                                autoFocus={false}
                                dayOfWeekFormatter={(_, date) => date.format('ddd')}
                                onChange={data => applyDate(dayjs(data))}
                                disableFuture
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                        </Box>
                    </ClickAwayListener>
                </Popover>
            </LocalizationProvider>
        )
    }
)
