import { type ReactNode, memo } from 'react'

import { Divider, ListItemButton, ListItemIcon, ListItemText, Typography, Box } from '@mui/material'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { useMenuStatus } from 'UI/Components'

import { CollapsedMenuTooltip } from './CollapsedMenuTooltip'

export type DrawerItemProps = {
    color?: string
    activeColor?: string
    isActive: boolean
    title: string
    startIcon?: ReactNode
    endIcon?: ReactNode
    divider?: boolean
}

export const DrawerItem = memo(
    ({
        isActive,
        divider,
        startIcon,
        endIcon,
        title,
        color,
        activeColor = '#cd0000'
    }: DrawerItemProps) => {
        const { isMenuClosed } = useMenuStatus()

        return (
            <>
                {divider && (
                    <Box px={2}>
                        <Divider />
                    </Box>
                )}
                <CollapsedMenuTooltip title={title} disabled={isActive}>
                    <ListItemButton
                        disableRipple={isActive}
                        sx={theme => ({
                            padding: theme.spacing(1, 2.5),
                            ...(isMenuClosed && { justifyContent: 'center' }),
                            ...(isActive && {
                                '&.MuiButtonBase-root': {
                                    cursor: 'default',
                                    '&:hover': {
                                        background: 'none'
                                    }
                                }
                            })
                        })}
                    >
                        {startIcon && (
                            <ListItemIcon
                                sx={theme => ({
                                    color: '#78909c',
                                    [theme.breakpoints.down('md')]: {
                                        color: '#828282'
                                    },
                                    minWidth: '25px',
                                    marginRight: isMenuClosed ? 0 : theme.spacing(1),
                                    ...(isMenuClosed && {
                                        transition: 'margin-right 1s'
                                    })
                                })}
                            >
                                {startIcon}
                            </ListItemIcon>
                        )}
                        <ListItemText
                            sx={theme => ({
                                color: color ?? '#78909c',
                                [theme.breakpoints.down('md')]: {
                                    color: color ?? '#828282'
                                },
                                textDecoration: 'none !important',
                                ...(isActive && { color: activeColor }),
                                ...(isMenuClosed && {
                                    color: 'transparent',
                                    transition: 'color 0.3s'
                                })
                            })}
                            primary={
                                <TextOverflow>
                                    <Typography
                                        sx={{
                                            ml: { xs: 1, md: 0 },
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {title}
                                    </Typography>
                                </TextOverflow>
                            }
                        />

                        {endIcon && !isMenuClosed && (
                            <ListItemIcon
                                sx={theme => ({
                                    color: '#78909c',
                                    [theme.breakpoints.down('md')]: {
                                        color: '#828282'
                                    },
                                    minWidth: '25px',
                                    marginRight: theme.spacing(1)
                                })}
                            >
                                {endIcon}
                            </ListItemIcon>
                        )}
                    </ListItemButton>
                </CollapsedMenuTooltip>
            </>
        )
    }
)
