import { type MouseEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, Link, useParams } from 'react-router-dom'

import { Box, Divider, List, Typography } from '@mui/material'

import { filterEmptyFields } from 'UI/Components'

import { SearchItem } from './SearchItem'

import EmptySearchIon from 'assets/images/gate.png'

import {
    type UseBooleanType,
    type FolderType,
    useQuery,
    useAuth,
    useWindowView,
    useQueryParams,
    useSessionStorage
} from 'hooks'
import { logToAnalytics, generateSpaceIds } from 'modules'

import { type SpaceType, type SearchPlaybookType } from 'app/types'

const pageSize = 7

export type SearchPageProps = {
    index: number
    text: string
    hasMore?: UseBooleanType
    onResultClick: (e: MouseEvent<HTMLElement>, playbook: SearchPlaybookType) => void
    seeAllLink?: string
    extraMixpanelProps?: {
        spaceId?: string
    }
    ignoreFilters: boolean
    isFullSpaceSearch?: boolean
    compactResults?: boolean
    searchParams?: {
        // here can be really any, boolean value, string, array etc.
        [key: string]: any
    }
}

type UseParamsType = { appId: string; spaceId: string }

export type SearchResultType = {
    facets: any
    nbHits: number
    status: string
    playbooks: Array<SearchPlaybookType>
}

export const SearchPage = ({
    index,
    text,
    hasMore,
    isFullSpaceSearch = false,
    onResultClick,
    seeAllLink,
    extraMixpanelProps = {},
    searchParams = {},
    ignoreFilters,
    compactResults = false
}: SearchPageProps) => {
    const { uid, orgId } = useAuth()

    const { isDesktopView } = useWindowView()

    const { appId, spaceId } = useParams<UseParamsType>()
    const query = useQueryParams()
    const activeSpaceId = spaceId || query.get('spaceId') || ''

    const isMainSpace = activeSpaceId === orgId

    const $space = useQuery<{ space: SpaceType; folders: Array<FolderType> }>(
        activeSpaceId ? `/c/v1/space?id=${activeSpaceId}` : '',
        {
            method: 'GET'
        },
        {
            dedupingInterval: undefined,
            revalidateOnFocus: false
        }
    )

    const space = $space.data?.space

    const playbookFilters = useSelector(state => state.playbookFilters.libraryPlaybooks)

    const [lastSearchedText, setLastSearchedText] = useSessionStorage(`lastSearchedText/${uid}`, '')

    const $playbookSearch = useQuery<SearchResultType>(
        '/a/v1/search',
        {
            method: 'POST',
            body: {
                ...(!ignoreFilters &&
                    filterEmptyFields({
                        ...playbookFilters,
                        spaceIds: generateSpaceIds(playbookFilters.spaceIds)
                    })),
                isMainSpace:
                    isMainSpace ||
                    playbookFilters.spaceIds?.some((it: string) => it === 'mainSpace'),

                text,
                isFullSpaceSearch,
                size: pageSize,
                page: index,
                ...(appId && { applicationId: appId }),
                method: 'search',
                ...(activeSpaceId && !isMainSpace && { spaceId: activeSpaceId }),
                spaceGroups: space?.spaceGroups,
                ...searchParams
            }
        },
        {
            onSuccess: data => {
                const searchText = text.toLowerCase().trim()

                if (searchText === lastSearchedText) {
                    return
                }

                logToAnalytics(data?.nbHits ? 'search' : 'search-no-results', {
                    text,
                    hits: data?.nbHits,
                    ...filterEmptyFields(playbookFilters),
                    ...(appId && { 'pb-appId': appId }),
                    ...extraMixpanelProps
                })

                setLastSearchedText(searchText)
            }
        }
    )

    const isLoading = $playbookSearch.isValidating
    const hitsNumber = isLoading ? 0 : $playbookSearch.data?.nbHits
    const playbooks = $playbookSearch.data?.playbooks

    const renderResultBox = () => {
        if (!isLoading && playbooks?.length === 0) {
            return (
                <Box p={2} data-test="search-page-wrapper">
                    {isDesktopView ? (
                        <Typography>No Match Found</Typography>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img src={EmptySearchIon} />

                            <Typography style={{ fontSize: '12px' }}>No Results</Typography>
                        </Box>
                    )}
                </Box>
            )
        }

        if (!isLoading && playbooks?.length) {
            return (
                <>
                    {index === 0 && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            data-test="playbook-results"
                        >
                            <Typography
                                sx={theme => ({
                                    color: '#78909c',
                                    lineHeight: 1,
                                    margin: theme.spacing(1),
                                    fontSize: theme.typography.pxToRem(14),
                                    [theme.breakpoints.down('md')]: {
                                        margin: theme.spacing(2, 2, 0),
                                        fontSize: theme.typography.pxToRem(12)
                                    }
                                })}
                            >
                                Videos({hitsNumber})
                            </Typography>
                            {seeAllLink && isDesktopView && (
                                <Link
                                    to={{
                                        pathname: generatePath(seeAllLink),
                                        search: `?search=${text}`
                                    }}
                                    style={{
                                        color: 'inherit'
                                    }}
                                >
                                    See all results
                                </Link>
                            )}
                        </Box>
                    )}
                    <List component="nav" style={{ paddingBottom: `${compactResults ? 0 : 8}px` }}>
                        {playbooks.map(playbook => (
                            <SearchItem
                                key={playbook.id}
                                playbook={playbook}
                                onResultClick={onResultClick}
                                compactResults={compactResults}
                            />
                        ))}
                    </List>
                    <Box my={compactResults ? 0 : 1}>
                        <Divider />
                    </Box>
                </>
            )
        }

        return (
            <Box p={2}>
                <Typography
                    sx={theme => ({
                        [theme.breakpoints.down('md')]: {
                            fontSize: '12px'
                        }
                    })}
                >
                    Loading...
                </Typography>
            </Box>
        )
    }

    useEffect(() => {
        if (hasMore === undefined) return

        if (hitsNumber && hitsNumber > (index + 1) * pageSize) {
            hasMore.setTrue()
            return
        }

        hasMore.setFalse()
    }, [hasMore, hitsNumber, index])

    return <>{renderResultBox()}</>
}
