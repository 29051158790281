import { Stack, Tooltip, Typography, Avatar, styled } from '@mui/material'

import IconRating from 'assets/icons/rating.svg'
import ViewsIcon from 'assets/icons/eye.svg'

const formatDate = (seconds: number) => {
    return new Date(seconds * 1000).toString().substring(4, 16)
}

const minimalRatingToShow = 50

const Views = styled('img')({
    width: '24px',
    height: '24px',
    marginLeft: '12px',
    marginRight: '4px !important'
})

const UserName = styled(Typography)({
    marginLeft: '8px',
    fontWeight: 'bold',
    fontSize: '12px',
    wordBreak: 'break-word',
    color: '#000'
})

const Rating = styled('img')({
    marginRight: '4px !important',
    width: '14px',
    height: '14px'
})

type Props = {
    hideTooltip?: boolean
    playbook: {
        username: string
        uploadedBy: {
            displayName: string
            email: string
        }
        userImage?: string
        uploadedAt: number
        views: number
        rating: number
    }
}

export const BasicMetadata = ({ playbook, hideTooltip = false }: Props) => {
    const isEmpty = !playbook.uploadedBy?.displayName && !playbook.uploadedBy?.email

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            {playbook.username?.slice(0, 1).toUpperCase() && (
                <Tooltip
                    title={
                        hideTooltip || isEmpty ? null : (
                            <>
                                {playbook.uploadedBy?.displayName}
                                <br />
                                {playbook.uploadedBy?.email}
                            </>
                        )
                    }
                >
                    <Avatar
                        sx={{ width: '24px', height: '24px' }}
                        src={playbook?.userImage}
                        data-cy="user-image"
                    >
                        {playbook.username?.slice(0, 1).toUpperCase()}
                    </Avatar>
                </Tooltip>
            )}
            <UserName variant="body2" data-cy="username">
                {playbook.username}
            </UserName>
            <Typography
                variant="body2"
                style={{
                    fontSize: '8px'
                }}
            >
                &#9679;
            </Typography>
            <Typography variant="body2" data-cy="uploaded-at-time">
                {formatDate(playbook.uploadedAt)}
            </Typography>

            {playbook.views !== 0 && (
                <Stack spacing={0.2} direction="row" alignItems="center">
                    <Views src={ViewsIcon} alt="views" data-cy="views-icon" />
                    <Typography variant="body2" sx={{ color: '#828282' }}>
                        {playbook.views}
                    </Typography>
                </Stack>
            )}
            {playbook.rating >= minimalRatingToShow && (
                <Stack spacing={0.2} direction="row" alignItems="center">
                    <Rating src={IconRating} alt="rating" data-cy="rating-icon" />
                    <Typography variant="body2" sx={{ color: '#828282' }}>
                        {playbook.rating}%
                    </Typography>
                </Stack>
            )}
        </Stack>
    )
}
