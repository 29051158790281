import { memo } from 'react'
import clsx from 'clsx'

import { Avatar, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { SpacedGroup } from 'UI/Components'
import { OPTION_TYPES, SPACE_GROUP_VALUES } from '../const'

const PREFIX = 'Option'

const classes = {
    small: `${PREFIX}-small`,
    medium: `${PREFIX}-medium`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.small}`]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft: theme.spacing(0.5),
        '& img': {
            objectFit: 'contain'
        }
    },
    [`& .${classes.medium}`]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        padding: '3px',
        '& img': {
            objectFit: 'contain'
        }
    }
}))

type Props = {
    option: any
}

export const Option = memo(({ option, ...props }: Props) => {
    return (
        <StyledGrid container alignItems="center" {...props}>
            <Grid item xs>
                <SpacedGroup>
                    <Avatar
                        src={option.logo}
                        className={clsx(
                            option.group === 'Spaces' && option.logo
                                ? classes.medium
                                : classes.small
                        )}
                    />

                    <Typography variant="subtitle2">
                        <b>{option.label || option.value || ''}</b>
                        {option.group === OPTION_TYPES.USERS && (
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                component="p"
                                style={{ fontWeight: 400 }}
                            >
                                {option.value || ''}
                            </Typography>
                        )}
                    </Typography>
                </SpacedGroup>
            </Grid>

            {option.disabled && option.group === OPTION_TYPES.SPACES && (
                <Grid item xs={5}>
                    <Typography variant="body2">
                        {option.value === SPACE_GROUP_VALUES.CONNECTED ? 'admin only' : 'view only'}
                    </Typography>
                </Grid>
            )}
        </StyledGrid>
    )
})
