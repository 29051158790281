import { useState, useContext } from 'react'

import { Box, MenuItem, Select, Tooltip } from '@mui/material'

import {
    getLabelFromCode,
    useBoolean,
    useDataMutation,
    useNotification,
    useAuth,
    useOrganization
} from 'hooks'

import { ConfirmationDialog, VideoStatusContext } from 'UI/Components'

const shortLangLabel = (code: any) => {
    switch (code) {
        case 'en-US':
        case 'en-GB':
        case 'en-IN':
            return 'ENG'
        case 'de-DE':
            return 'GER'
        case 'es-ES':
            return 'SPA'
        case 'it-IT':
            return 'ITA'
        case 'fr-FR':
            return 'FRE'
        case 'iw-IL':
            return 'HEB'
        case 'pt-BR':
            return 'POR'
        case 'zh':
            return 'CHI'
        default:
            return code
    }
}

export const ReTranscribe = ({
    playbookId,
    transcriptLang
}: {
    playbookId: string
    transcriptLang: string
}) => {
    const { orgId } = useAuth()

    const { showSuccessNotification } = useNotification()
    const { editable, checkVideoStatus } = useContext(VideoStatusContext)

    const showModal = useBoolean()

    const [lang, setLang] = useState<string>(transcriptLang)

    const onClose = () => {
        showModal.setFalse()
        setLang(transcriptLang)
    }

    const { defaultLanguage = '' } = useOrganization(orgId)

    const $reTranscribe = useDataMutation('/b/v1/playbookAction', 'POST', {
        onSuccess: () => {
            onClose()
            showSuccessNotification('Your request was sent. Please check result a bit later')
            checkVideoStatus()
        },
        onFailure: onClose
    })

    if (transcriptLang === 'en-US' && !defaultLanguage) return null

    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Tooltip title={editable ? '' : 'Video is still processing'}>
                <span>
                    <Select
                        disabled={!editable}
                        value={transcriptLang}
                        onChange={e => {
                            showModal.setTrue()
                            setLang(e.target.value as string)
                        }}
                        sx={{
                            paddingRight: '0 !important',
                            '&:focus': {
                                backgroundColor: 'transparent'
                            },
                            '& ~ svg': {
                                display: 'none'
                            }
                        }}
                        renderValue={shortLangLabel}
                        style={{
                            color: editable ? '#cd0000' : '#424242',
                            fontSize: '14px',
                            fontWeight: 500
                        }}
                        disableUnderline
                    >
                        <MenuItem value={defaultLanguage}>
                            {getLabelFromCode(defaultLanguage)}
                        </MenuItem>
                        <MenuItem value="en-US">English (United States)</MenuItem>
                        {transcriptLang &&
                        transcriptLang !== defaultLanguage &&
                        transcriptLang !== 'en-US' ? (
                            <MenuItem value={transcriptLang}>
                                {getLabelFromCode(transcriptLang)}
                            </MenuItem>
                        ) : null}
                    </Select>
                </span>
            </Tooltip>

            <ConfirmationDialog
                isOpen={showModal.isTrue}
                onClose={onClose}
                onConfirm={() =>
                    $reTranscribe.mutate({
                        action: 'transcribe',
                        playbookId,
                        language: lang
                    })
                }
                title="Are you sure?"
                text={`You are going to re-transcribe to ${getLabelFromCode(
                    lang
                )}. All changes done in the current transcript will be lost `}
                loading={$reTranscribe.isLoading}
            />
        </Box>
    )
}
