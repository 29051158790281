import { type ElementType } from 'react'
import { generatePath } from 'react-router-dom'

import { paths } from 'app/paths'

import { host } from 'modules'

type Props = {
    title: string
    playbookId: string
    headerSize?: number
    headerAlignment?: 'left' | 'center'
    withHyperLinkTitle?: boolean
}

export const Header = ({
    title,
    playbookId,
    headerSize = 1,
    headerAlignment,
    withHyperLinkTitle = true
}: Props) => {
    const generatedPath = generatePath(paths.playbookDetails, {
        playbookId
    })

    // Header size is translated to H1 H2 etc, up to H6
    const HeadTag = `h${headerSize > 6 ? '6' : headerSize}` as ElementType

    return (
        <div style={{ textAlign: headerAlignment ? headerAlignment : 'left' }}>
            <div>
                <HeadTag>
                    {withHyperLinkTitle ? (
                        <a href={`${host}${generatedPath}`} target="_blank" rel="noreferrer">
                            {title}
                        </a>
                    ) : (
                        <>{title}</>
                    )}
                </HeadTag>
            </div>
        </div>
    )
}
