import { useMemo } from 'react'

import useMutation from 'use-mutation'

import { generalErrorMessage, request } from 'modules'
import { useNotification } from './use-notification'

export const useDataMutation = <Input, Data, E extends Error>(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    callback?: {
        onSuccess?: (data: Data, input: Input) => void
        onFailure?: (error: E, input: Input) => void
        disableDefaultErrorHandling?: boolean
        throwOnFailure?: boolean
    }
) => {
    const { showErrorNotification } = useNotification()

    const [mutate, { status, data, error, reset }] = useMutation<Input, Data, E>(
        body => request(url, method, body),
        {
            onSettled(params) {
                const { status, input } = params

                switch (status) {
                    default:
                    case 'success': {
                        return callback?.onSuccess?.(params.data, input)
                    }
                    case 'failure': {
                        if (!callback?.disableDefaultErrorHandling) {
                            showErrorNotification(params.error.message || generalErrorMessage)
                        }

                        return callback?.onFailure?.(params.error, input)
                    }
                }
            },
            throwOnFailure: callback?.throwOnFailure || false
        }
    )

    const isLoading = status === 'running'

    return useMemo(
        () => ({ mutate, isLoading, data, error, reset }),
        [data, error, isLoading, mutate, reset]
    )
}
