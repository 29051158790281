import { memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Grid } from '@mui/material'

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import GolfCourseIcon from '@mui/icons-material/GolfCourse'

import { type SectionProps, StepCard, Section } from 'UI/Routes/brand-kit/sections/components'

import { ConfirmationDialog } from 'UI/Components'
import { CoverEndingPreviewDialog } from './dialogs'

import { type BrandKitType, type StepType } from 'app/types'

import { useBoolean, useNotification } from 'hooks'
import { resetSteps } from 'ducks'

const protectedTemplates = ['guiddeCover', 'guiddeEnding']

type TemplateType = 'cover' | 'end'

type Props = {
    kind: TemplateType
    values: BrandKitType[TemplateType]
    background: BrandKitType['background']
    title: string
    subtitle: string
    cardsLimit: number
} & SectionProps

export const SlideTemplates = memo(
    ({
        kind,
        values,
        cardsLimit,
        background,
        title,
        subtitle,
        popupProps,
        onSetActive,
        onAdd,
        onEdit,
        onDelete,
        isLoading
    }: Props) => {
        const dispatch = useDispatch()

        const { showSuccessNotification } = useNotification()

        const [activeTemplate, setActiveTemplate] = useState<null | StepType>(null)
        const [deleteTemplate, setDeleteTemplate] = useState<null | StepType>(null)

        const addDialog = useBoolean()
        const closeAddDialog = addDialog.setFalse

        const handleSave = (step: StepType) => {
            if (addDialog.isTrue) {
                onAdd({ [kind]: step })
                    .finally(closePreviewDialog)
                    .then(() => showSuccessNotification('Template successfully created'))
                return
            }

            if (activeTemplate) {
                onEdit({
                    [kind]: {
                        id: activeTemplate.id,
                        data: step
                    }
                })
                    .finally(closePreviewDialog)
                    .then(() => showSuccessNotification('Template successfully updated'))
            }
        }

        const closePreviewDialog = () => {
            setActiveTemplate(null)
            closeAddDialog()
            dispatch(resetSteps())
        }

        const handleDeleteTemplate = () => {
            if (!deleteTemplate) return

            onDelete({ [kind]: deleteTemplate.id }).then(() => {
                setDeleteTemplate(null)
                showSuccessNotification('Template successfully removed')
            })
        }

        // If it's default org cover or ending template - we show background gradient color instead of template.drawnScreenshot
        const brandKitBackground = background.data?.find(it => it.id === background.active)
        const gradient =
            brandKitBackground && 'url' in brandKitBackground ? '' : brandKitBackground?.gradient

        return (
            <>
                <Section
                    popupProps={popupProps}
                    title={title}
                    subtitle={subtitle}
                    showActionCard={cardsLimit > values.data.length}
                    actionIcon={kind === 'end' ? GolfCourseIcon : ImageOutlinedIcon}
                    onActionItemClick={addDialog.setTrue}
                    actionTitle={`Add ${title} Slide`}
                    content={values.data.map((template, index) => {
                        const isActive = template.id === values.active
                        const isTemplateIncluded = protectedTemplates.includes(template.id)

                        const labelText = isActive ? 'Default' : `${title} #${index + 1}`

                        return (
                            <Grid
                                item
                                key={template.drawnScreenshot + index}
                                sx={{
                                    borderRight:
                                        index === values.data.length - 1
                                            ? '1px solid #E0E0E0'
                                            : 'none'
                                }}
                            >
                                <StepCard
                                    step={template}
                                    onDelete={
                                        isTemplateIncluded
                                            ? undefined
                                            : () => setDeleteTemplate(template)
                                    }
                                    onEdit={
                                        isTemplateIncluded
                                            ? undefined
                                            : () => setActiveTemplate(template)
                                    }
                                    onSetActive={onSetActive}
                                    isActive={isActive}
                                    labelText={labelText}
                                    gradient={gradient}
                                    disableActions={isTemplateIncluded}
                                    popupProps={popupProps}
                                />
                            </Grid>
                        )
                    })}
                />

                <CoverEndingPreviewDialog
                    isLoading={isLoading}
                    onClose={closePreviewDialog}
                    onSave={handleSave}
                    isAddMode={addDialog.isTrue}
                    kind={kind}
                    title={
                        <>
                            {activeTemplate ? 'Edit' : 'Create new'} {kind.toLowerCase()} template
                        </>
                    }
                    isOpen={Boolean(addDialog.isTrue || activeTemplate)}
                    activeTemplate={activeTemplate}
                />

                {deleteTemplate && (
                    <ConfirmationDialog
                        isOpen={true}
                        onClose={() => setDeleteTemplate(null)}
                        onConfirm={handleDeleteTemplate}
                        title={`Delete ${title.toLowerCase()}`}
                        text={`Are you sure you want to delete the ${title.toLowerCase()} template?`}
                        loading={isLoading}
                    />
                )}
            </>
        )
    }
)
