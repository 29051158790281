import { useState } from 'react'

import { MenuItem, Select, Box, Typography, Checkbox } from '@mui/material'

import { FilterLabel, CommonDialog, SpacedGroup } from 'UI/Components'

import { useBoolean, useWindowView } from 'hooks'
import { type OptionType } from 'modules'

type Props = {
    setSorting: (_: any) => void
    sorting: string
    defaultValue?: string
    options: Array<OptionType>
}

export const SortingDropdown = ({ setSorting, sorting, defaultValue, options }: Props) => {
    const { isMobileView } = useWindowView()
    const open = useBoolean()

    if (isMobileView) {
        const label = options.find(it => it.value === sorting)?.label || ''

        return (
            <Box mr={1}>
                <FilterLabel
                    transparent={true}
                    width="auto"
                    onClick={open.toggle}
                    selectedCount={0}
                    label={label}
                    defaultLabel={label}
                />
                <SortingDialog
                    isOpen={open.isTrue}
                    options={options}
                    onClose={open.setFalse}
                    onConfirm={setSorting}
                    currentSorting={sorting}
                />
            </Box>
        )
    }

    return (
        <SpacedGroup style={{ minWidth: '120px' }} spacing={1}>
            <Typography
                style={{
                    fontWeight: 500,
                    fontSize: 14
                }}
            >
                Sort by
            </Typography>
            <Select
                value={sorting}
                displayEmpty
                disableUnderline
                sx={{
                    fontSize: 14,
                    p: 0,
                    '& .MuiSelect-select': {
                        p: '6px 24px 6px 0'
                    },
                    '& fieldset': {
                        border: 'none'
                    },
                    '& em': {
                        fontStyle: 'normal',
                        color: 'rgba(9, 12, 16, 0.6)'
                    }
                }}
                onChange={e => setSorting(e.target.value)}
            >
                {options.map(({ value, label }, i) => (
                    <MenuItem
                        value={value}
                        key={i}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)'
                                }
                            }
                        }}
                    >
                        <em>{i === 0 ? defaultValue || label : label}</em>
                    </MenuItem>
                ))}
            </Select>
        </SpacedGroup>
    )
}

type DialogProps = {
    isOpen: boolean
    options: Array<OptionType>
    currentSorting: string
    onClose: () => void
    onConfirm: (sorting: string) => void
}

const SortingDialog = ({ isOpen, options, currentSorting, onClose, onConfirm }: DialogProps) => {
    const [active, setActive] = useState(currentSorting || '')

    return (
        <CommonDialog
            isOpen={isOpen}
            title="Sort by"
            onClose={() => {
                onClose()
                setActive(currentSorting)
            }}
            onConfirm={() => {
                onConfirm(active)
                onClose()
            }}
            confirmBtnText="Show"
            justifyActions="flex-end"
            showDivider={true}
            hideCloseBtn={true}
            content={
                <>
                    {options.map(({ value, label }, i) => (
                        <Box
                            key={i}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginRight: 1
                                }}
                            >
                                {label}
                            </Typography>

                            <Checkbox
                                checked={active === value}
                                value={value}
                                onChange={() => setActive(value)}
                                color="primary"
                            />
                        </Box>
                    ))}
                </>
            }
        />
    )
}
