import { Box } from '@mui/material'

import { PlaybookReactions } from 'UI/Components'

import { Metadata } from './Metadata'
import { Attachments } from './Attachments'

import { type PlaybookType, type QuickGuiddeType, type VideoRefType } from 'app/types'

import { useAuth, useAnonymAuth, useRoles } from 'hooks'

type Props = {
    playbook: PlaybookType | QuickGuiddeType
    videoRef: VideoRefType
    isMobileView?: boolean
    hideVideoReactions?: boolean
    onSourceUrlClick?: () => void
}

export const ViewPlaybookMetadata = ({
    playbook,
    isMobileView,
    videoRef,
    hideVideoReactions,
    onSourceUrlClick
}: Props) => {
    const { uid } = useAuth()

    const { isAnonymous } = useAnonymAuth()

    const { isContentManager } = useRoles(playbook.uploadedByOrgId)

    const isOwner = uid === playbook.creator_uid
    const canEdit = isOwner || isContentManager

    return (
        <Box position="relative">
            <Metadata
                canEdit={canEdit}
                isMobileView={isMobileView}
                playbook={playbook}
                applications={!isAnonymous ? playbook.applications : []}
                tags={!isAnonymous ? playbook.tags : []}
                onLinkClick={onSourceUrlClick}
                showCreatorTooltip={canEdit}
                hideVideoReactions={hideVideoReactions}
                reactionsComponent={
                    !isOwner && !hideVideoReactions ? (
                        <PlaybookReactions playbookId={playbook.id} videoRef={videoRef} />
                    ) : null
                }
            />
            <Box height="4px" />
            <Box my={2} ml={4}>
                <Attachments links={playbook.links || []} files={playbook.files || []} />
            </Box>
        </Box>
    )
}
