import { memo, useEffect, useMemo, useState } from 'react'

import { Box, Button, IconButton, Paper, TextField, Typography, Popover } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { ReactComponent as DeleteIcon } from 'assets/icons/bin.svg'

import {
    type PendingEmailType,
    type PlaybookType,
    type PlaybookVisibilityType,
    type PlaylistType,
    type ShareableSpacesUserType,
    PlaybookMode
} from 'app/types'
import { type SearchOptionType, GROUP, SpacedGroup } from 'UI/Components'
import {
    useCopySharedLink,
    useNotification,
    useOrganizationUsers,
    usePlaybookShare,
    useShareableOptions
} from 'hooks'

import { generalErrorMessage, logToAnalytics, option } from 'modules'
import { SelectedOption } from '../SearchFieldArray/SelectedOption'

type Props = {
    onClose: () => void
    onBack?: () => void
    onDone?: (playbookId: string, newVisibility?: PlaybookVisibilityType) => void
    playbook: PlaybookType | PlaylistType
    setLoading: (val: boolean) => void
}

type Options = Array<{
    value: string
    label: string
    group: string
    logo?: string
    uid?: string
}>

const generateUsers = (arr: Options) => {
    return arr
        .filter(it => it.group === GROUP.externalMembers || it.group === GROUP.teamMembers)
        .map(it => ({
            email: it.value,
            uid: it.uid
        }))
}

const generateEmails = (arr: Options) => {
    return arr.filter(it => it.group === GROUP.pendingEmails).map(it => it.value)
}

export const getUserOption = (group: string) => (user: ShareableSpacesUserType) => ({
    ...option(user.email, user.displayName || user.email),
    uid: user.uid,
    logo: user.logo,
    isSelected: user.isSelected,
    owner: false,
    group
})

export const getEmailOption = (group: string) => (user: PendingEmailType) => ({
    ...option(user.email, ''),
    isSelected: user.isSelected,
    owner: false,
    group
})

export const AdvancedSharingTab = memo(
    ({ onClose, setLoading, onDone, onBack, playbook }: Props) => {
        const [searchValue, setSearchValue] = useState('')

        const { showErrorNotification, showSuccessNotification } = useNotification()

        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
        const [popperData, setPopperData] = useState<null | SearchOptionType>(null)

        const { orgUsers } = useOrganizationUsers()

        const { copySharedLink } = useCopySharedLink()

        const $options = useShareableOptions({
            playbooksIds: [playbook.id],
            params: {
                spaces: true,
                internalUsers: true,
                externalUsers: true,
                pendingEmails: true
            },
            onError: onClose
        })

        const { sharePlaybook, isSharing, ApprovalModal } = usePlaybookShare({
            onSuccess: data => {
                if (data?.code === 422) return

                logToAnalytics(`unshare_${playbook.mode}`, {
                    'pb-id': playbook.id,
                    playbookType: playbook.mode,
                    data: {
                        value: popperData?.value
                    }
                })

                const type = playbook.mode === PlaybookMode.Playlist ? 'Playlist' : 'Video'

                showSuccessNotification(`${type} was successfully unshared`)

                onDone?.(playbook.id, data.newData[0].visibility)
                $options.mutate()
                copySharedLink(playbook, false)
            },
            onFailure: () => {
                showErrorNotification(generalErrorMessage)
                onClose()
            }
        })

        const { data } = $options
        const optionsList = useMemo(
            () => [
                ...(data?.internalUsers?.map(getUserOption(GROUP.teamMembers)) || []),
                ...(data?.externalUsers?.map(getUserOption(GROUP.externalMembers)) || []),
                ...(data?.pendingEmails?.map(getEmailOption(GROUP.pendingEmails)) || [])
            ],
            [data]
        )

        const playbookOwner = orgUsers.find(({ uid }) => uid === playbook?.uploadedByUid)

        const ownerOption = useMemo(
            () =>
                playbookOwner
                    ? {
                          ...option(
                              playbookOwner.email,
                              playbookOwner.displayName || playbookOwner.email
                          ),
                          uid: playbookOwner.uid,
                          logo: playbookOwner.photoURL,
                          owner: true,
                          group: GROUP.teamMembers
                      }
                    : null,
            [playbookOwner]
        )

        const isLoading = isSharing || $options.isValidating

        useEffect(() => {
            setLoading(isLoading)
        }, [setLoading, isLoading])

        const initialList = useMemo(
            () => [ownerOption, ...optionsList.filter(it => Boolean(it?.isSelected))],
            [optionsList, ownerOption]
        )

        const filteredResult = useMemo(() => {
            const data = initialList.filter(
                it => it?.value?.includes(searchValue) || it?.label?.includes(searchValue)
            )

            return data
        }, [initialList, searchValue])

        const handleFormSubmit = () => {
            if (!popperData) return

            const users = generateUsers([popperData])
            const emails = generateEmails([popperData])

            sharePlaybook({
                playbooksIds: [playbook.id],
                ...(users.length > 0 && {
                    userIds: users.map(({ uid }) => uid).filter(Boolean) as Array<string>
                }),
                ...(emails.length > 0 && {
                    emails
                }),
                shareMode: 'unshare'
            }).catch(console.error)
        }

        return (
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pt={2}
                    px={2}
                >
                    <SpacedGroup>
                        <IconButton onClick={onBack} size="small">
                            <ArrowBackIcon style={{ color: '#090C10' }} />
                        </IconButton>
                        <Typography style={{ fontWeight: 500 }} data-test="dialog-title">
                            Manage Sharing
                        </Typography>
                    </SpacedGroup>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        data-test="close-button"
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box px={3} my={3}>
                    <TextField
                        fullWidth
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        placeholder="Search for members"
                        variant="filled"
                        inputProps={{
                            sx: {
                                padding: '12px 0',
                                borderRadius: '4px'
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <SearchIcon
                                    style={{ color: 'rgba(9, 12, 16, 0.6)', marginRight: '10px' }}
                                />
                            )
                        }}
                    />

                    <Box mt={2} maxHeight="33vh" overflow="auto" minHeight="200px">
                        {filteredResult?.map((option, idx) => (
                            <Box
                                py={1}
                                key={idx}
                                sx={theme => ({
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: theme.palette.action.hover
                                    }
                                })}
                            >
                                <SelectedOption
                                    option={option}
                                    controls={
                                        !option?.owner ? (
                                            <IconButton
                                                sx={{
                                                    padding: 0,
                                                    textAlign: 'center'
                                                }}
                                                size="small"
                                                edge="end"
                                                onClick={e => {
                                                    if (!option) return

                                                    setAnchorEl(e.currentTarget)
                                                    setPopperData(option)
                                                }}
                                            >
                                                <DeleteIcon style={{ color: '#090C10' }} />
                                            </IconButton>
                                        ) : null
                                    }
                                />
                            </Box>
                        ))}
                        {searchValue && !filteredResult.length && (
                            <Typography variant="caption" color="textSecondary" align="center">
                                No search results
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    disablePortal={false}
                    onClose={() => {
                        setAnchorEl(null)
                        setPopperData(null)
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <Paper>
                        <Box px={2} py={3} width={280}>
                            <Typography
                                style={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    fontSize: 14
                                }}
                            >
                                Are you sure you want to remove the access for {popperData?.label}?
                            </Typography>
                            <SpacedGroup mt={3}>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    style={{
                                        backgroundColor: '#F5F5F5',
                                        color: 'rgba(9, 12, 16, 0.6)',
                                        boxShadow: 'none',
                                        letterSpacing: 1.25
                                    }}
                                    onClick={() => {
                                        setAnchorEl(null)
                                        setPopperData(null)
                                    }}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        boxShadow: 'none',
                                        letterSpacing: 1.25,
                                        whiteSpace: 'nowrap'
                                    }}
                                    onClick={() => {
                                        if (!popperData) return

                                        setAnchorEl(null)
                                        handleFormSubmit()
                                    }}
                                >
                                    REMOVE ACCESS
                                </Button>
                            </SpacedGroup>
                        </Box>
                    </Paper>
                </Popover>

                {ApprovalModal}
            </>
        )
    }
)
