import { Box, Stack, Typography } from '@mui/material'

import { FontDialog } from '../dialogs'

import { useBoolean } from 'hooks'
import { type BrandKitFontType, type BrandKitType } from 'app/types'

type Props = {
    activeFont: BrandKitFontType
    font: BrandKitType['font']
    isLoading: boolean
    onChange: (_font: string) => void
}

export const FontFamily = ({ activeFont, font, isLoading, onChange }: Props) => {
    const dialog = useBoolean()

    return (
        <>
            <Stack spacing={1}>
                <Typography sx={{ color: 'rgba(9, 12, 16, 0.6)', fontSize: 14 }}>
                    Font family
                </Typography>

                <Box
                    height={54}
                    width={200}
                    sx={{
                        fontSize: 16,
                        fontFamily: activeFont.title,
                        borderRadius: '4px 4px 0px 0px',
                        borderBottom: '1px solid rgba(9, 12, 16, 0.10)',
                        background: 'rgba(0, 0, 0, 0.06)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#090C10',
                        cursor: 'pointer',
                        '&:hover': { background: 'rgba(0, 0, 0, 0.09)' }
                    }}
                    onClick={dialog.setTrue}
                >
                    {activeFont.title}
                </Box>
            </Stack>
            <FontDialog
                isOpen={dialog.isTrue}
                onClose={dialog.setFalse}
                onSubmit={onChange}
                isLoading={isLoading}
                activeFont={activeFont}
                fonts={font.data}
            />
        </>
    )
}
