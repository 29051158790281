export const GROUP = {
    spaces: 'Spaces',
    teamMembers: 'Team Members',
    externalMembers: 'External Members',
    pendingEmails: 'Pending Emails'
}

export const SPACE_GROUP_VALUES = {
    MAIN: 'MAIN_SPACE',
    CONNECTED: 'ALL_CONNECTED_SPACES'
}

export const OPTION_TYPES = {
    USERS: GROUP.teamMembers,
    SPACES: GROUP.spaces
}
