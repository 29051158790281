import { type MouseEvent, memo } from 'react'

import { type SxProps, styled } from '@mui/material/styles'

import clsx from 'clsx'

import { Box, IconButton, Stack, Typography } from '@mui/material'

import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import LockIcon from '@mui/icons-material/Lock'

import { ReactComponent as PremiumIcon } from 'assets/icons/premium.svg'

import { SpacedGroup } from 'UI/Components'

import { type SpeakerType } from 'app/types'

import { getTimeString } from 'modules'

const PREFIX = 'SpeakerItem'

const classes = {
    name: `${PREFIX}-name`,
    sample: `${PREFIX}-sample`,
    premiumIcon: `${PREFIX}-premiumIcon`,
    showPremium: `${PREFIX}-showPremium`,
    lockIcon: `${PREFIX}-lockIcon`,
    speakerCard: `${PREFIX}-speakerCard`,
    speakerSelected: `${PREFIX}-speakerSelected`,
    studioAvatar: `${PREFIX}-studioAvatar`,
    avatar: `${PREFIX}-avatar`,
    playIcon: `${PREFIX}-playIcon`
}

const StyledStack = styled(Stack)({
    [`& .${classes.name}`]: {
        fontWeight: 700,
        fontSize: '14px',
        color: 'rgba(9, 12, 16, 0.6)'
    },
    [`& .${classes.sample}`]: {
        fontWeight: 400,
        fontStyle: 'italic',
        fontSize: '14px',
        color: 'rgba(9, 12, 16, 0.6)'
    },
    [`& .${classes.premiumIcon}`]: {
        display: 'none'
    },
    [`& .${classes.lockIcon}`]: {
        display: 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: '50%',
        border: '2px solid #CB0000'
    },
    [`&.${classes.showPremium}`]: {
        '&:hover': {
            [`& .${classes.premiumIcon}`]: {
                display: 'block'
            },
            [`& .${classes.lockIcon}`]: {
                display: 'flex'
            }
        }
    },
    [`&.${classes.speakerSelected}`]: {
        border: 'solid #CB0000 1px',
        backgroundColor: '#FAE5E5'
    },
    [`& .${classes.studioAvatar}`]: {
        position: 'relative',
        '&:after': {
            content: '"HQ"',
            width: 23,
            position: 'absolute',
            right: -8,
            top: 16,
            color: 'white',
            background: '#CB0000',
            textAlign: 'center',
            fontSize: 10,
            fontWeight: 700,
            borderRadius: 8
        },
        '& img': {
            borderColor: '#CB0000'
        }
    },
    [`& .${classes.avatar}`]: {
        borderRadius: '50%',
        border: '2px solid transparent'
    },
    [`& .${classes.playIcon}`]: {
        color: 'black',
        backgroundColor: '#F5F5F5'
    }
})

export type SpeakerItemProps = {
    speaker: SpeakerType
    isActive: boolean
    isPremium?: boolean
    isPlaying: boolean
    currentTime: number
    dataCy?: string
    onContainerClick: (event: MouseEvent<HTMLElement>, speaker: SpeakerType) => void
    onActionButtonClick: (speaker: SpeakerType) => void
    sx?: SxProps
    premiumDisplayMode?: 'lock' | 'upgrade'
}

export const SpeakerItem = memo(
    ({
        speaker,
        dataCy,
        isPlaying,
        currentTime,
        isPremium,
        isActive,
        onActionButtonClick,
        onContainerClick,
        sx,
        premiumDisplayMode = 'upgrade'
    }: SpeakerItemProps) => {
        return (
            <StyledStack
                id={speaker.id}
                data-test={dataCy}
                onClick={e => onContainerClick(e, speaker)}
                className={clsx({
                    [classes.speakerSelected]: isActive,
                    [classes.showPremium]: isPremium
                })}
                direction="row"
                sx={{
                    height: '88px',
                    borderRadius: '4px',
                    padding: '4px',
                    marginRight: `0 !important`,
                    border: 'solid rgba(9, 12, 16, 0.08) 1px',
                    cursor: 'pointer',
                    ...sx
                }}
                spacing={2}
            >
                <Box
                    className={clsx({
                        [classes.studioAvatar]: speaker.isStudio
                    })}
                >
                    {speaker.isStudio && premiumDisplayMode === 'lock' && (
                        <Box className={classes.lockIcon}>
                            <LockIcon sx={{ color: '#FED243' }} />
                        </Box>
                    )}
                    <img
                        className={classes.avatar}
                        src={speaker.avatarUrlCompressed}
                        width={80}
                        height={80}
                        alt="avatar"
                    />
                </Box>

                <SpacedGroup flexDirection="column" position="relative">
                    <SpacedGroup height={25} spacing={0.5}>
                        <Typography className={classes.name}>{speaker.name}</Typography>
                        {speaker.isStudio && premiumDisplayMode === 'upgrade' && (
                            <PremiumIcon className={classes.premiumIcon} />
                        )}
                    </SpacedGroup>

                    <SpacedGroup spacing={0.5}>
                        <IconButton
                            className={classes.playIcon}
                            onClick={event => {
                                event.stopPropagation()

                                onActionButtonClick(speaker)
                            }}
                            size="small"
                        >
                            {isPlaying ? (
                                <PauseIcon fontSize="medium" />
                            ) : (
                                <PlayArrowIcon fontSize="medium" />
                            )}
                        </IconButton>

                        <Box className={classes.sample}>
                            {isPlaying ? getTimeString(currentTime) : 'Sample'}
                        </Box>
                    </SpacedGroup>
                </SpacedGroup>
            </StyledStack>
        )
    }
)
