import { useState } from 'react'

import { TextOverflow } from '@guidde-co/shared.textoverflow'
import { Box, Grid, Link, Typography } from '@mui/material'

import { type PlaybookFileType, type PlaybookLinkType } from 'app/types'

import { SpacedGroup } from 'UI/Components'
import { useNotification, useWindowView } from 'hooks'

type Props = {
    links?: Array<PlaybookLinkType>
    files?: Array<PlaybookFileType>
}

export const Attachments = ({ links = [], files = [] }: Props) => {
    const { showErrorNotification } = useNotification()

    const [disableDownload, setDisableDownload] = useState<{ [key: string]: boolean }>({})

    const toggleDisableDownload = (fileId: string) => {
        setDisableDownload(prev => ({
            ...prev,
            [fileId]: !prev[fileId]
        }))
    }
    const downloadFileFromURL = async (item: PlaybookFileType) => {
        toggleDisableDownload(item.id)
        await fetch(item.url)
            .then(response => {
                if (!response.ok) {
                    showErrorNotification('Something went wrong')
                    console.error(response)
                }
                return response.blob()
            })
            .then(blob => {
                const blobUrl = window.URL.createObjectURL(blob)

                // Create an anchor element for initiating the download
                const a = document.createElement('a')
                a.href = blobUrl
                a.download = item.name

                a.click()

                // Clean up by revoking the blob URL
                window.URL.revokeObjectURL(blobUrl)
            })
            .catch(error => {
                showErrorNotification('Something went wrong')
                console.error('Error downloading the file:', error)
            })
            .finally(() => toggleDisableDownload(item.id))
    }

    const totalLength = Number(links?.length) + Number(files?.length)

    if (totalLength < 1) return null

    return (
        <Grid
            container
            spacing={3}
            sx={{
                pb: 3,
                mt: 1,
                background: '#FFF',
                borderRadius: '8px'
            }}
        >
            {links.map(item => (
                <Attachment key={item.id} item={item} />
            ))}

            {files.map(item => (
                <Attachment
                    key={item.id}
                    item={item}
                    disableDownload={disableDownload[item.id]}
                    onDownload={() => downloadFileFromURL(item)}
                />
            ))}
        </Grid>
    )
}

type AttachmentProps = {
    item: PlaybookFileType | PlaybookLinkType
    onDownload?: () => Promise<void>
    disableDownload?: boolean
}

const Attachment = ({ item, disableDownload, onDownload }: AttachmentProps) => {
    const { isMobileView } = useWindowView()

    return (
        <Grid
            key={item.id}
            item
            xs={isMobileView ? 12 : 'auto'}
            style={{
                width: 'fit-content'
            }}
        >
            <SpacedGroup spacing={1} alignItems="flex-start">
                <Typography>{onDownload ? '📎' : '🔗'}</Typography>
                <Box>
                    {onDownload ? (
                        <>
                            <TextOverflow lineClamp={1}>
                                <Typography style={{ color: '#2D9CDB' }} variant="caption">
                                    {item.name}
                                </Typography>
                            </TextOverflow>
                            <Box mt={-0.8}>
                                <Typography
                                    onClick={disableDownload ? undefined : onDownload}
                                    style={{
                                        color: '#BDBDBD',
                                        cursor: disableDownload ? 'auto' : 'pointer'
                                    }}
                                    variant="caption"
                                >
                                    Download
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <TextOverflow>
                            <Link
                                href={item.url}
                                target="_blank"
                                underline="none"
                                variant="caption"
                                style={{ color: '#2D9CDB', maxWidth: '90vw' }}
                            >
                                {item.name}
                            </Link>
                        </TextOverflow>
                    )}
                </Box>
            </SpacedGroup>
        </Grid>
    )
}
