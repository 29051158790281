import { useDispatch } from 'react-redux'

import { Box, Typography, styled } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { type StepType } from 'app/types'
import { setStepTransition } from 'ducks'
import { logToAnalytics } from 'modules'

const directions = [
    { value: 'right', icon: ArrowForwardIcon },
    { value: 'left', icon: ArrowBackIcon },
    { value: 'up', icon: ArrowUpwardIcon },
    { value: 'down', icon: ArrowDownwardIcon }
] as const

const StyledCard = styled(Box, { shouldForwardProp: prop => prop !== 'isActive' })<{
    isActive: boolean
}>(({ theme, isActive }) => ({
    height: 56,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    border: '1px solid rgba(9, 12, 16, 0.08)',
    transition: 'box-shadow 0.2s ease-in-out',
    ...(isActive
        ? {
              background: '#FAE5E5',
              border: '1px solid #CB0000',
              '& ~ p, & > svg': {
                  color: '#090C10'
              }
          }
        : {}),
    '&:hover': {
        boxShadow: theme.shadows[3]
    }
}))

type Props = {
    transition: StepType['transition']
    motionToAll: boolean
}

export const TransitionDirection = ({ transition, motionToAll }: Props) => {
    const dispatch = useDispatch()

    if (!transition?.direction) return null

    return (
        <Box>
            <Typography>Direction</Typography>
            <Box mt={1} display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="12px">
                {directions.map((direction, idx) => {
                    const isActive = transition.direction === direction.value

                    return (
                        <StyledCard
                            p={1}
                            key={idx}
                            height="auto !important"
                            isActive={isActive}
                            onClick={() => {
                                window.showTransition()

                                const newTransition = {
                                    ...transition,
                                    direction: direction.value
                                }

                                dispatch(setStepTransition(newTransition, motionToAll))

                                if (!isActive) {
                                    logToAnalytics('selectedTransition', {
                                        transition: newTransition,
                                        applyToAll: motionToAll,
                                        action: 'change direction'
                                    })
                                }
                            }}
                        >
                            <direction.icon
                                sx={{
                                    color: '#090C1099',
                                    textAlign: 'center'
                                }}
                            />
                        </StyledCard>
                    )
                })}
            </Box>
        </Box>
    )
}
