import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
    styled,
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Popover,
    Typography
} from '@mui/material'

import SettingsIcon from '@mui/icons-material/Settings'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'

import { SpacedGroup } from 'UI/Components'

import { showPlanDialog } from 'ducks'
import { type UserType, getRoleName, useRoles } from 'hooks'

import { paths } from 'app/paths'

const PREFIX = 'WorkspaceMembersPopover'

const classes = {
    listWithScroll: `${PREFIX}-listWithScroll`
}

const StyledPopover = styled(Popover)({
    [`& .${classes.listWithScroll}`]: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '20px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D8D8D8',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box'
        }
    }
})

type Props = {
    anchorEl: HTMLElement | null
    onClose: () => void
    members: Array<UserType>
    isUsersExceeded: boolean
}

export const WorkspaceMembersPopover = ({ anchorEl, members, isUsersExceeded, onClose }: Props) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { isAdmin } = useRoles()

    const sortedUsers = useMemo(() => {
        return members.sort((a, b) =>
            a?.displayName?.toLowerCase() > b?.displayName?.toLowerCase() ? 1 : -1
        )
    }, [members])

    return (
        <StyledPopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            disablePortal={false}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <Paper style={{ width: 540 }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    py={2.5}
                >
                    <Typography variant="h6">Workspace members</Typography>
                    <SpacedGroup spacing={1}>
                        {isAdmin && (
                            <Button
                                style={{
                                    letterSpacing: 1.25,
                                    fontSize: 14,
                                    textTransform: 'uppercase',
                                    color: '#2D9CDB'
                                }}
                                variant="text"
                                size="small"
                                color="secondary"
                                startIcon={<SettingsIcon />}
                                onClick={() => {
                                    history.push({
                                        search: '?tab=3',
                                        pathname: paths.accountSettings
                                    })
                                    onClose()
                                }}
                            >
                                Settings
                            </Button>
                        )}
                        <IconButton onClick={onClose} size="small">
                            <CloseIcon fontSize="small" style={{ color: 'rgba(9, 12, 16, 1)' }} />
                        </IconButton>
                    </SpacedGroup>
                </Box>
                <Divider />

                {isAdmin && isUsersExceeded && members.length > 1 && (
                    <Box
                        style={{
                            borderTop: '4px solid #FED243',
                            background: '#FEFAE4'
                        }}
                        px={1.5}
                        py={2}
                    >
                        <Box display="flex" justifyContent="center">
                            <SpacedGroup spacing={1.5}>
                                <ErrorIcon style={{ color: '#FED243' }} />
                                <Typography variant="h6">Users</Typography>
                            </SpacedGroup>
                        </Box>
                        <Box
                            mt={1.2}
                            bgcolor="#FEFAE4"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Typography noWrap>
                                Your plan has reached its maximum capacity.{' '}
                                <Typography
                                    component="span"
                                    style={{
                                        color: '#2D9CDB',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        const query = new URLSearchParams()

                                        query.append('upgradePlanStep', '1')

                                        history.replace({
                                            search: `?${query.toString()}`
                                        })
                                        dispatch(showPlanDialog('updatePlanDialog'))
                                    }}
                                >
                                    Upgrade now
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box px={2} py={1.5} maxHeight={500} className={classes.listWithScroll}>
                    {sortedUsers.map(u => (
                        <SpacedGroup key={u.uid} mt={2}>
                            <Avatar src={u.photoURL} variant="circular" sizes="40px" />
                            <Box>
                                <SpacedGroup spacing={0.5}>
                                    <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                                        {u.displayName} •
                                    </Typography>
                                    <Typography style={{ fontSize: 14 }}>
                                        {getRoleName(u.roles).name}
                                    </Typography>
                                </SpacedGroup>
                                <Typography style={{ fontSize: 14 }} color="textSecondary">
                                    {u.email}
                                </Typography>
                            </Box>
                        </SpacedGroup>
                    ))}
                </Box>
            </Paper>
        </StyledPopover>
    )
}
