import { type ReactNode, type MouseEvent } from 'react'

import {
    type BoxProps,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid
} from '@mui/material'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'

import { type BrandKitResponseType, useBoolean } from 'hooks'

import { type UpgradePlanProps, SpacedGroup } from 'UI/Components'

import { ActionCard } from './ActionCard'

import {
    type AddBrandKitType,
    type DeleteBrandKitType,
    type EditBrandKitType,
    type SetActiveBrandKitType
} from 'app/types'
import { type IconType } from 'app/menu'
import { styled } from '@mui/system'

const StyledAccordion = styled(Accordion)({
    boxShadow: 'none',
    border: 'none',
    overflowY: 'hidden',
    '&:before': {
        display: 'none'
    }
})

export type SectionAction<T> = (data: T) => Promise<BrandKitResponseType>

export type SectionProps = {
    isLoading: boolean
    onEdit: SectionAction<EditBrandKitType>
    onAdd: SectionAction<AddBrandKitType>
    onDelete: SectionAction<DeleteBrandKitType>
    onSetActive: SectionAction<SetActiveBrandKitType>
    popupProps: UpgradePlanProps & {
        validateClick: (e: MouseEvent<HTMLElement>, callback: () => void) => void
    }
}

type Props = {
    title: string
    subtitle: ReactNode
    actionTitle?: string
    actionIcon?: IconType
    showActionCard?: boolean
    actionCardHeight?: BoxProps['height']
    actionCardWidth?: BoxProps['width']
    content: Array<ReactNode> | ReactNode
    onActionItemClick?: () => void
    popupProps: UpgradePlanProps & {
        validateClick: (e: MouseEvent<HTMLElement>, callback: () => void) => void
    }
}

export const Section = ({
    title,
    subtitle,
    actionTitle,
    actionIcon,
    content = null,
    actionCardHeight,
    actionCardWidth,
    showActionCard = true,
    onActionItemClick = () => {},
    popupProps
}: Props) => {
    const expanded = useBoolean(true)

    return (
        <StyledAccordion defaultExpanded onChange={(_, value) => expanded.set(value)}>
            <AccordionSummary>
                <Box>
                    <SpacedGroup>
                        <Typography style={{ fontWeight: 500 }}>{title}</Typography>

                        <ArrowDropDownOutlinedIcon
                            style={{
                                transition: 'transform 0.3s ease 0s',
                                transform: `rotate(${expanded.isTrue ? 180 : 0}deg)`
                            }}
                        />
                    </SpacedGroup>

                    <Box color="rgba(9, 12, 16, 0.6)" style={{ fontSize: 14 }}>
                        {subtitle}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} style={{ flexWrap: 'wrap' }}>
                    {Array.isArray(content) ? (
                        content.map((it, i) => (
                            <Grid item key={i}>
                                {it}
                            </Grid>
                        ))
                    ) : (
                        <Grid>{content}</Grid>
                    )}
                    {showActionCard && (
                        <Grid item>
                            <ActionCard
                                height={actionCardHeight}
                                width={actionCardWidth}
                                title={actionTitle}
                                Icon={actionIcon}
                                onClick={e => popupProps.validateClick(e, onActionItemClick)}
                            />
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </StyledAccordion>
    )
}
