import * as types from 'ducks/types'
import { type AudioBackgroundType, type PlaybookApplicationType } from 'app/types'
import { type SidePanelModeType } from 'ducks/reducers/qgMetadata'

export const setSidePanelMode = (payload: SidePanelModeType) =>
    ({
        type: types.SET_SIDE_PANEL_MODE,
        payload
    }) as const

export const setQuickGuiddeMetadata = (payload: {
    qgDescription: string
    qgApplications: Array<PlaybookApplicationType>
    qgNewTags: Array<string>
    audio?: AudioBackgroundType | null
}) =>
    ({
        type: types.SET_QUICK_GUIDDE_METADATA,
        payload
    }) as const

export const setQuickGuiddeDescription = (payload: string) =>
    ({
        type: types.SET_QUICK_GUIDDE_DESCRIPTION,
        payload
    }) as const

export const setQuickGuiddeApplications = (payload: {
    qgApplications: Array<PlaybookApplicationType>
    qgDeletedApps: Array<PlaybookApplicationType>
}) =>
    ({
        type: types.SET_QUICK_GUIDDE_APPLICATIONS,
        payload
    }) as const

export const setQuickGuiddeTags = (payload: {
    qgNewTags: Array<string>
    qgDeletedTags: Array<string>
}) =>
    ({
        type: types.SET_QUICK_GUIDDE_TAGS,
        payload
    }) as const

export const setQuickGuiddeAudio = (payload?: AudioBackgroundType | null) =>
    ({
        type: types.SET_QUICK_GUIDDE_AUDIO,
        payload: payload
    }) as const

export const setQuickGuiddeAudioVolume = (payload: number) =>
    ({
        type: types.SET_QUICK_GUIDDE_AUDIO_VOLUME,
        payload
    }) as const
