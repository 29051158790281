import { type ChangeEvent, memo, useRef, useState } from 'react'

import { Box, Grid, capitalize } from '@mui/material'
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined'

import { DEFAULT_STEP, getVideoMetadata } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { type SectionProps, StepCard, Section } from 'UI/Routes/brand-kit/sections/components'

import { VideoPreviewDialog, VideoPreviewAndUploadDialog } from './dialogs'

import { type BrandKitType, type StepType, type VideoShapeType, Shape } from 'app/types'
import { useNotification } from 'hooks'
import { bytesToMB } from 'modules'

const maxFileSizeInMb = 10
const allowedType = 'video/mp4'

type TemplateType = 'intro' | 'outro'

type Props = {
    kind: TemplateType
    values: BrandKitType[TemplateType]
    title: string
    subtitle: string
    cardsLimit: number
} & SectionProps

export const VideoTemplates = memo(
    ({
        kind,
        values,
        cardsLimit,
        title,
        subtitle,
        popupProps,
        onSetActive,
        onAdd,
        onDelete,
        isLoading
    }: Props) => {
        const { showErrorNotification, showSuccessNotification } = useNotification()

        const inputRef = useRef<HTMLInputElement>(null)
        const gridRef = useRef<HTMLDivElement>(null)

        const [videoFile, setVideoFile] = useState<File | null>(null)
        const [existingVideoPreviewData, setExistingVideoPreviewData] = useState<null | {
            videoUrl: string
            title: string
        }>(null)

        const videoPreviewUrl = videoFile ? URL.createObjectURL(videoFile) : ''

        const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
            const uploadedFile = event.target.files?.[0]

            if (!uploadedFile) return

            if (maxFileSizeInMb < bytesToMB(Number(uploadedFile?.size))) {
                showErrorNotification(
                    `The selected media file is too large (over ${maxFileSizeInMb}MB)`
                )
                return
            }

            const fileMetadata = await getVideoMetadata(
                uploadedFile,
                DEFAULT_STEP.windowDimensions,
                1
            )
            if (fileMetadata.duration === Infinity) {
                showErrorNotification('This file is unsupported. Please choose a different one')
                return
            }

            setVideoFile(uploadedFile)
        }

        const closeVideoPreview = () => {
            setVideoFile(null)
            if (inputRef.current) inputRef.current.value = ''
        }

        const openVideoPreview = (template: StepType, title: string) => {
            const videoLayer = template.layers.find(
                layer => layer.type === Shape.Video
            ) as VideoShapeType

            const videoUrl = videoLayer?.sourceVideoUrl

            if (!videoUrl) {
                console.error('No video found in template', {
                    id: template.id,
                    layers: template.layers
                })
                return
            }

            setExistingVideoPreviewData({
                videoUrl,
                title
            })
        }

        return (
            <>
                <Section
                    popupProps={popupProps}
                    title={title}
                    subtitle={subtitle}
                    actionIcon={VideoCallOutlinedIcon}
                    actionTitle={`Add ${capitalize(kind)} Video`}
                    onActionItemClick={() => inputRef.current?.click()}
                    showActionCard={cardsLimit > (values?.data?.length || 0)}
                    content={(values?.data || []).map((template, index) => {
                        const isActive = template.id === values.active

                        const labelText = isActive ? 'Default' : `${capitalize(kind)} #${index + 1}`

                        return (
                            <Grid item key={template.id} ref={gridRef}>
                                <Box style={{ position: 'relative' }}>
                                    <StepCard
                                        step={template}
                                        onDelete={() => {
                                            onDelete({
                                                [kind]: template.id
                                            }).then(() => {
                                                showSuccessNotification(
                                                    'Template successfully removed'
                                                )
                                            })
                                        }}
                                        onThumbnailClick={() =>
                                            openVideoPreview(template, labelText)
                                        }
                                        EditIcon={VideoCallOutlinedIcon}
                                        onEdit={() => openVideoPreview(template, labelText)}
                                        disableActions={isLoading}
                                        onSetActive={onSetActive}
                                        isActive={isActive}
                                        labelText={labelText}
                                        popupProps={popupProps}
                                    />
                                </Box>
                            </Grid>
                        )
                    })}
                />

                <input
                    ref={inputRef}
                    type="file"
                    onChange={onFileChange}
                    accept={allowedType}
                    style={{ display: 'none' }}
                />

                {videoFile && (
                    <VideoPreviewAndUploadDialog
                        isOpen={true}
                        stepKind={kind}
                        file={videoFile}
                        previewUrl={videoPreviewUrl}
                        onClose={closeVideoPreview}
                        onCancel={closeVideoPreview}
                        onPrimaryBtnClick={step => {
                            onAdd({
                                [kind]: step
                            })
                                .then(() => {
                                    showSuccessNotification('Template successfully created')
                                })
                                .finally(closeVideoPreview)
                        }}
                        buttonLabel="SAVE"
                        loading={isLoading}
                    />
                )}
                {existingVideoPreviewData && (
                    <VideoPreviewDialog
                        isOpen={true}
                        title={existingVideoPreviewData.title}
                        videoUrl={existingVideoPreviewData.videoUrl}
                        onClose={() => setExistingVideoPreviewData(null)}
                        onCancel={() => setExistingVideoPreviewData(null)}
                    />
                )}
            </>
        )
    }
)
