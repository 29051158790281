import { useCallback, useMemo, useState } from 'react'

let startTime: number | null = null
let animationFrameId: number | null = null

type Props = {
    duration: number
    finalValue: number
}

export const useAnimationFrame = ({ duration = 3000, finalValue }: Props) => {
    const [currentValue, setCurrentValue] = useState(0)

    const animate = useCallback(
        (timestamp: number) => {
            if (!startTime) startTime = timestamp

            const timePassed = timestamp - startTime
            const newValue = timePassed / duration

            // Limit value to >=0 and <=finalValue
            const protectedValue = Math.min(finalValue, Math.max(finalValue * newValue, 0))
            setCurrentValue(protectedValue)

            if (timePassed < duration) animationFrameId = requestAnimationFrame(animate)
            else cancelAnimationFrame(animationFrameId as number)
        },
        [duration, finalValue]
    )

    const stop = useCallback(() => {
        setCurrentValue(0)
        startTime = null

        if (animationFrameId) {
            cancelAnimationFrame(animationFrameId)
            animationFrameId = null
        }
    }, [])

    const start = useCallback(() => {
        stop()
        requestAnimationFrame(animate)
    }, [animate, stop])

    return useMemo(
        () => ({
            currentValue,
            start,
            stop
        }),
        [currentValue, start, stop]
    )
}
