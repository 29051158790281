import { combineReducers } from 'redux'
import undoable, { excludeAction } from 'redux-undo'

import {
    appAuthReducer,
    extensionStatusReducer,
    playbookFiltersReducer,
    uploadPopupReducer,
    videoEditorReducer,
    videoProgressReducer,
    qgEditorReducer,
    qgMetadataReducer,
    serviceUsageReducer,
    configsReducer,
    qgCtaReducer,
    pasteStepsReducer
} from './reducers'

import * as types from './types'

export const rootReducer = combineReducers({
    configs: configsReducer,
    appAuth: appAuthReducer,
    serviceUsage: serviceUsageReducer,
    playbookFilters: playbookFiltersReducer,
    videoEditor: videoEditorReducer,
    uploadProgressPopup: uploadPopupReducer,
    extensionStatus: extensionStatusReducer,
    videoProgress: videoProgressReducer,
    qgMetadata: qgMetadataReducer,
    qgCta: qgCtaReducer,
    pasteSteps: pasteStepsReducer,
    qgEditor: undoable(qgEditorReducer, {
        limit: 20,
        filter: excludeAction([
            types.SET_TEXT_SHAPE_SIZE,
            types.ENABLE_QG_SHORTCUTS,
            types.DISABLE_QG_SHORTCUTS,
            types.COPY_SELECTED_LAYER
        ])
    })
})
