import { LinearProgress, MenuItem, Typography } from '@mui/material'

import { type IconType } from 'app/menu'

type Props = {
    title: string
    Icon: IconType
    dataCy?: string
    disabled?: boolean
    isLoading?: boolean
    onClick?: () => void
}

export const AddStepMenuItem = ({
    title,
    isLoading,
    Icon,
    onClick = () => {},
    dataCy,
    disabled
}: Props) => (
    <MenuItem
        data-test={dataCy}
        style={{ padding: '8px', width: '100%', position: 'relative' }}
        onClick={onClick}
        disabled={disabled}
    >
        {isLoading && (
            <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} />
        )}
        <Icon style={{ height: 55, minWidth: 88, marginRight: 8 }} />

        <Typography variant="subtitle2" sx={{ fontWeight: 500, textWrap: 'wrap' }}>
            {title}
        </Typography>
    </MenuItem>
)
