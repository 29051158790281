import { type CSSProperties } from 'react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import { Box, Container, Button, Typography } from '@mui/material'

import GuiddeLogoRed from 'assets/icons/guidde_red.svg'
import NotFoundLogo from 'assets/icons/404.svg'

import { paths } from 'app/paths'

const PREFIX = 'PageNotFound'

const classes = {
    container: `${PREFIX}-container`,
    mainLogo: `${PREFIX}-mainLogo`,
    message: `${PREFIX}-message`,
    subtitle: `${PREFIX}-subtitle`,
    youGotMe: `${PREFIX}-youGotMe`,
    link: `${PREFIX}-link`,
    oops: `${PREFIX}-oops`
}

const StyledContainer = styled(Container)(() => ({
    [`&.${classes.container}`]: {
        color: '#212121',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 48px)'
    },
    [`& .${classes.mainLogo}`]: {
        padding: '50px',
        width: '100%',
        height: '100%'
    },
    [`& .${classes.message}`]: {
        position: 'absolute',
        top: '22%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'inherit'
    },
    [`& .${classes.subtitle}`]: {
        width: '80%',
        textAlign: 'center'
    },
    [`& .${classes.youGotMe}`]: {
        position: 'absolute',
        top: '40px',
        right: '20px',
        color: 'inherit'
    },
    [`& .${classes.link}`]: {
        position: 'absolute',
        bottom: '70px',
        right: '11%',
        textDecoration: 'none'
    },
    [`& .${classes.oops}`]: {
        position: 'absolute',
        bottom: 0,
        left: 0
    }
}))

type Props = {
    title?: string
    subtitle?: string
    titleSize?: CSSProperties['fontSize']
}

const PageNotFound = ({ title, subtitle, titleSize }: Props) => {
    return (
        <StyledContainer className={classes.container}>
            <Box className={classes.message}>
                <img src={GuiddeLogoRed} alt="red logo" />
                <Typography variant="h1" style={{ fontSize: titleSize }}>
                    {title || 'Got Lost?'}
                </Typography>
                <Typography variant="h4" className={classes.subtitle}>
                    {subtitle || 'Let us guidde you back'}
                </Typography>
            </Box>
            <Typography className={classes.youGotMe} variant="h6">
                You got me!
            </Typography>
            <Link className={classes.link} to={paths.home}>
                <Button>Take me Home</Button>
            </Link>
            <Box className={classes.oops}>
                <img src={GuiddeLogoRed} style={{ width: '80px' }} alt="red logo" />
            </Box>
            <img className={classes.mainLogo} src={NotFoundLogo} alt="400 logo" />
        </StyledContainer>
    )
}

export default PageNotFound
