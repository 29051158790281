import { type ReactNode } from 'react'

import { Button, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

export const ServiceUsageActionButton = ({
    actionText,
    onClick
}: {
    actionText: string
    onClick: () => void
}) => (
    <Button
        sx={{
            textTransform: 'uppercase',
            backgroundColor: '#090C10',
            color: 'white',
            py: '6px',
            '&:hover': { backgroundColor: '#090C10' }
        }}
        variant="contained"
        onClick={onClick}
    >
        {actionText}
    </Button>
)

type Props = {
    title: string
    description: ReactNode
    maxDescriptionWidth?: number
    actionText: string
    Image?: ReactNode
    onClose: () => void
    onActionClick: () => void
    additionalActionButton?: ReactNode
}

export const ServiceUsageDialog = ({
    onClose,
    title,
    description,
    actionText,
    onActionClick,
    Image,
    maxDescriptionWidth,
    additionalActionButton
}: Props) => (
    <Dialog
        open
        PaperProps={{ sx: { pt: 6, pb: 2, px: 4, textAlign: 'center', alignItems: 'center' } }}
        fullWidth
    >
        <IconButton sx={{ position: 'absolute', top: 12, right: 12 }} onClick={onClose}>
            <CloseIcon />
        </IconButton>
        <Stack spacing={4} alignItems="center">
            {Image}
            <Stack spacing={2}>
                <Typography fontWeight={700} fontSize={34} lineHeight="42px">
                    {title}
                </Typography>
                <Typography
                    fontSize={16}
                    lineHeight={1.5}
                    letterSpacing="0.15px"
                    color="rgba(9, 12, 16, 0.6)"
                    maxWidth={maxDescriptionWidth}
                >
                    {description}
                </Typography>
            </Stack>
        </Stack>
        <Divider sx={{ mx: -4, mt: 5, mb: 2 }} flexItem />
        <Stack direction="row" spacing={2}>
            <ServiceUsageActionButton actionText={actionText} onClick={onActionClick} />
            {additionalActionButton}
        </Stack>
    </Dialog>
)
