import { useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useQueryParams } from 'hooks'

export const useActivePlaybook = () => {
    const { replace } = useHistory()
    const params = useQueryParams()

    const activePlaybook = Number(params.get('active'))

    const setActivePlaybook = useCallback(
        (index: number) => {
            params.set('active', index.toString())

            replace({
                search: params.toString()
            })
        },
        [params, replace]
    )

    return useMemo(
        () => ({
            activePlaybook,
            setActivePlaybook
        }),
        [activePlaybook, setActivePlaybook]
    )
}
