import { generatePath, useHistory } from 'react-router-dom'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    Divider,
    IconButton,
    Typography,
    styled,
    Stack,
    LinearProgress
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg'

import { ConnectedInput, SpacedGroup } from 'UI/Components'
import { brandKitRequestPath } from 'UI/Routes/brand-kit/PageBrandKit'

import { type BrandKitResponseType, useBrandKit, useDataMutation, useNotification } from 'hooks'

import { type AddMultiBrandKitType, type MultiBrandKitItem } from 'app/types'
import { paths } from 'app/paths'

const borderColor = '1px solid rgba(9, 12, 16, 0.08)'

type Props = {
    onClose: () => void
    isDefault?: boolean
    currentName?: string
    id?: string
}

export const BrandKitCardDialog = ({
    onClose,
    currentName = '',
    id = '',
    isDefault = false
}: Props) => {
    // use styled component inside render method due to compatibility issue
    // until all usages of ConnectedInput will be converted to mui 5
    const StyledInput = styled(ConnectedInput)({
        backgroundColor: '#F5F5F5',
        borderBottomColor: borderColor,
        '& ~ p': {
            margin: '-8px 0 8px 0'
        },
        '& div:before': {
            borderBottom: `${borderColor} !important`
        },
        '&:before': {
            borderBottom: `${borderColor} !important`
        },
        '& input': {
            padding: '22px 12px 8px'
        }
    })

    const history = useHistory()

    const { showSuccessNotification } = useNotification()
    const { revalidateMultiBrandKit } = useBrandKit()

    const $addMultiBrandKit = useDataMutation<
        AddMultiBrandKitType,
        BrandKitResponseType & { id: string },
        Error
    >(`${brandKitRequestPath}`, 'POST', {
        onSuccess: ({ id }) => {
            showSuccessNotification(`Brand Kit successfully created`)
            revalidateMultiBrandKit()

            history.push(
                generatePath(paths.brandKitDetails, {
                    id
                })
            )
        }
    })

    const $updateMultiBrandKit = useDataMutation<
        Partial<MultiBrandKitItem>,
        BrandKitResponseType,
        Error
    >(
        `${brandKitRequestPath}`,
        'PATCH',

        {
            onSuccess: () => {
                showSuccessNotification(`Brand Kit successfully updated`)
                revalidateMultiBrandKit()
            }
        }
    )

    // decide if to add a brand kit or modify the name
    const $action = currentName ? $updateMultiBrandKit : $addMultiBrandKit

    return (
        <Dialog
            maxWidth="sm"
            open
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%'
                }
            }}
            onClose={onClose}
        >
            {$action.isLoading && <LinearProgress />}
            <Box pt={2.5}>
                <Formik
                    initialValues={{
                        name: currentName
                    }}
                    validationSchema={yup.object().shape({
                        name: yup
                            .string()
                            .nullable()
                            .test('is-required', 'Field is required', value => value?.trim() !== '')
                    })}
                    onSubmit={(values, isValid) => {
                        if (!isValid) return

                        const payload = {
                            name: values.name,
                            ...(id && { id }),
                            ...(currentName && { default: isDefault })
                        }

                        $action.mutate(payload).finally(onClose)
                    }}
                    enableReinitialize
                    validateOnMount
                    validateOnChange
                >
                    {({ isValid, values }) => (
                        <Form>
                            <Stack direction="column" pb={1} spacing={3}>
                                <Box
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    px={3.5}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: 20,
                                            letterSpacing: 0.15
                                        }}
                                    >
                                        {currentName ? 'Edit ' : ''} Brand Kit Name
                                    </Typography>

                                    <IconButton onClick={onClose} size="small">
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Box px={3.5}>
                                    <StyledInput
                                        fullWidth
                                        name="name"
                                        autoFocus
                                        placeholder="Brand Kit Name"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                    <Box
                                        fontSize={14}
                                        fontWeight={400}
                                        color="rgba(9, 12, 16, 0.6)"
                                    >
                                        Everyone in your team will have access to this Brand Kit.{' '}
                                        <br />
                                        Only you can make changes to it.
                                    </Box>
                                </Box>

                                <Divider />

                                <DialogActions disableSpacing style={{ marginTop: 8 }}>
                                    <SpacedGroup
                                        px={2}
                                        justifyContent={'space-between'}
                                        width="100%"
                                    >
                                        <Button
                                            data-cy="cta-delete-button"
                                            size="medium"
                                            color="secondary"
                                            type="button"
                                            startIcon={<CloseIcon />}
                                            onClick={onClose}
                                            style={{
                                                letterSpacing: 1.25,
                                                color: 'rgba(9, 12, 16, 0.6)',
                                                backgroundColor: '#EDEEEF'
                                            }}
                                        >
                                            CANCEL
                                        </Button>

                                        <Button
                                            data-cy="cta-save-button"
                                            size="medium"
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={
                                                $action.isLoading ||
                                                !isValid ||
                                                !values.name.trim().length
                                            }
                                            startIcon={<CheckmarkIcon color="inherit" />}
                                            style={{ letterSpacing: 1.25 }}
                                        >
                                            {currentName ? 'UPDATE ' : 'CREATE'}
                                        </Button>
                                    </SpacedGroup>
                                </DialogActions>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    )
}
