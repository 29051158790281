import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { type AudioBackgroundType, type QuickGuiddeType, type SpeakerType } from 'app/types'
import { logToAnalytics } from 'modules'
import { useDataMutation, useLocalStorage, useNotification, useServiceUsage } from 'hooks'

import {
    WizardAudioBackgroundStep,
    WizardIntroStep,
    WizardLoaderStep,
    WizardSpeakerStep,
    WizardTransitionStep
} from './steps'
import {
    type RTDBTransitionSequenceType,
    type WizardData,
    WizardStep,
    currentWizardPreferencesStorageKey,
    getRandomElement,
    wizardPreferencesStorageKey
} from './wizard-utils'

type MutationProps = {
    playbookId: string
    selectedSpeaker?: SpeakerType
    selectedBgMusic?: AudioBackgroundType
    selectedTransition?: RTDBTransitionSequenceType
}

type Props = {
    playbook: QuickGuiddeType
    wizardInitialData: WizardData
    wizardPreferences: WizardData | null
    speakers: Array<SpeakerType>
    transitions: Array<RTDBTransitionSequenceType>
    audioBackgrounds: Array<AudioBackgroundType>
    topAudioBackgroundIds: Array<string>
    onClose: () => void
}

export const Wizard = ({
    wizardPreferences,
    wizardInitialData,
    speakers,
    transitions,
    audioBackgrounds,
    topAudioBackgroundIds,
    onClose,
    playbook
}: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    const { hasVoiceOverTextToSpeech, hasStudioVoices } = useServiceUsage()
    const { showErrorNotification } = useNotification()

    const [wizardData, setWizardData] = useState<WizardData>(wizardInitialData)

    const [, setCurrentWizardPreferences, removeCurrentWizardPreferences] =
        useLocalStorage<WizardData | null>(currentWizardPreferencesStorageKey, null)
    const [, setWizardPreferences] = useLocalStorage<WizardData | null>(
        wizardPreferencesStorageKey,
        null
    )

    const updateWizardData = useCallback(
        (newState: Partial<WizardData>) => {
            setWizardData(prevData => {
                setCurrentWizardPreferences({ ...prevData, ...newState })
                return { ...prevData, ...newState }
            })
        },
        [setCurrentWizardPreferences]
    )

    const onChangeStep = (step: number) => updateWizardData({ currentStep: step })

    const $updateWizardSelection = useDataMutation<MutationProps, {}, Error>(
        '/c/v1/quickguidde/wizard',
        'POST'
    )

    const handleSubmitWizard = useCallback(
        ({
            wizardData,
            analyticsEventName
        }: {
            wizardData: WizardData
            analyticsEventName: string
        }) => {
            updateWizardData({ currentStep: WizardStep.LOADER })

            const selectedSpeaker = speakers.find(speaker => speaker.id === wizardData.speakerId)
            const selectedBgMusic = audioBackgrounds?.find(audio => audio.id === wizardData.audioId)
            const selectedTransition = transitions?.find(
                transition => transition.name === wizardData.transitionName
            )

            $updateWizardSelection
                .mutate({
                    playbookId,
                    selectedSpeaker: hasVoiceOverTextToSpeech ? selectedSpeaker : undefined,
                    selectedBgMusic,
                    selectedTransition
                })
                .then(() => {
                    setWizardPreferences(wizardData)
                    removeCurrentWizardPreferences()
                    logToAnalytics(analyticsEventName, {
                        playbookId,
                        selection: wizardData
                    })
                    onClose()
                })
                .catch(() => {
                    logToAnalytics('wizard_api_call_failed', {
                        playbookId,
                        selection: wizardData
                    })
                    showErrorNotification('Something went wrong')
                    updateWizardData({ currentStep: WizardStep.AUDIO })
                })
        },
        [
            updateWizardData,
            speakers,
            audioBackgrounds,
            transitions,
            $updateWizardSelection,
            playbookId,
            hasVoiceOverTextToSpeech,
            setWizardPreferences,
            removeCurrentWizardPreferences,
            onClose,
            showErrorNotification
        ]
    )

    const currentStep = wizardData.currentStep

    if (currentStep === WizardStep.INTRO) {
        const areWizardPreferencesExist = wizardPreferences !== null

        const onSkipClick = !areWizardPreferencesExist
            ? () => {
                  const randomSpeaker = getRandomElement(
                      !hasStudioVoices ? speakers.filter(speaker => !speaker.isStudio) : speakers,
                      'id'
                  )
                  const randomTransition = getRandomElement(transitions, 'name')
                  const randomAudio = getRandomElement(audioBackgrounds, 'id')

                  handleSubmitWizard({
                      wizardData: {
                          speakerId: randomSpeaker,
                          transitionName: randomTransition,
                          audioId: randomAudio,
                          currentStep: 0
                      },
                      analyticsEventName: 'wizard_feelingLuckyBtn_clicked'
                  })
              }
            : undefined

        const onRepeatPreviousChoicesClick = areWizardPreferencesExist
            ? () => {
                  handleSubmitWizard({
                      wizardData: wizardPreferences,
                      analyticsEventName: 'wizard_previousSelectionBtn_clicked'
                  })
              }
            : undefined

        return (
            <WizardIntroStep
                speakers={speakers}
                onChangeStep={onChangeStep}
                onRepeatPreviousChoicesClick={onRepeatPreviousChoicesClick}
                onSkipClick={onSkipClick}
            />
        )
    }

    if (currentStep === WizardStep.SPEAKER) {
        return (
            <WizardSpeakerStep
                onChangeData={updateWizardData}
                speakers={speakers}
                activeSpeakerId={wizardData.speakerId}
            />
        )
    }

    if (currentStep === WizardStep.TRANSITION) {
        return (
            <WizardTransitionStep
                onChangeData={updateWizardData}
                transitions={transitions}
                activeTransitionName={wizardData.transitionName}
            />
        )
    }

    if (currentStep === WizardStep.AUDIO) {
        return (
            <WizardAudioBackgroundStep
                onChangeData={updateWizardData}
                audioBackgrounds={audioBackgrounds}
                activeAudioId={wizardData.audioId}
                topAudioBackgroundIds={topAudioBackgroundIds}
                onPrimaryActionClick={() =>
                    handleSubmitWizard({ wizardData, analyticsEventName: 'wizard_lets_go_clicked' })
                }
            />
        )
    }

    if (currentStep === WizardStep.LOADER) {
        return (
            <WizardLoaderStep
                stepsCount={playbook.steps.length}
                isDone={!$updateWizardSelection.isLoading}
            />
        )
    }

    return null
}
