import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { Box, Typography } from '@mui/material'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'

import { SpacedGroup } from 'UI/Components'
import { type PlaybookType, type PlaylistType } from 'app/types'

type Props = {
    tabValue: number
    setTabValue: (val: number) => void
    playbookList: Array<PlaybookType>
    activePlaybookId: string
    playlist: PlaylistType
    transparent?: boolean
    showStartUsing?: boolean
    position?: 'fixed' | 'relative'
}

export const ControlPanel = ({
    tabValue,
    transparent,
    playlist,
    playbookList,
    setTabValue,
    position = 'relative',
    activePlaybookId,
    showStartUsing
}: Props) => {
    const currentPlaybookIdx = playbookList.findIndex(pb => pb.id === activePlaybookId)
    const nextPlaybookTitle = playbookList[currentPlaybookIdx + 1]?.title

    const redMode = tabValue === 1 && !transparent
    return (
        <Box
            px={3}
            py={2}
            bgcolor={redMode ? '#CB0000' : 'rgb(237, 238, 239)'}
            color={redMode ? '#fff' : 'inherit'}
            zIndex={2}
            style={{
                position,
                opacity: position === 'fixed' ? 0.8 : 1,
                cursor: showStartUsing ? 'auto' : 'pointer',
                bottom: 0,
                left: 0,
                right: 0
            }}
            onClick={() => setTabValue(tabValue === 0 ? 1 : 0)}
        >
            <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    {tabValue === 0 ? (
                        <TextOverflow lineClamp={1}>
                            <Typography style={{ color: '#090C10', fontWeight: 'bold' }}>
                                {playlist.title}
                            </Typography>
                        </TextOverflow>
                    ) : (
                        <>
                            {nextPlaybookTitle && (
                                <SpacedGroup spacing={1} maxWidth="95%">
                                    <PlaylistPlayIcon color="inherit" />
                                    <Typography
                                        color="inherit"
                                        style={{ fontWeight: 'bold', fontSize: '12px' }}
                                    >
                                        Next:
                                    </Typography>

                                    <TextOverflow lineClamp={1}>
                                        <Typography color="inherit" style={{ fontSize: '12px' }}>
                                            {nextPlaybookTitle}
                                        </Typography>
                                    </TextOverflow>
                                </SpacedGroup>
                            )}
                        </>
                    )}
                </Box>

                {!showStartUsing && (
                    <Box position="absolute" right={24} top={16}>
                        {tabValue === 1 ? (
                            <ArrowIcon
                                style={{
                                    color: '#fff',
                                    marginTop: '-5px',
                                    fontSize: '12px',
                                    transform: 'rotate(-90deg)'
                                }}
                            />
                        ) : (
                            <CloseIcon
                                style={{
                                    color: '#090c10',
                                    fontSize: '18px'
                                }}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <TextOverflow lineClamp={1}>
                <Typography
                    variant="caption"
                    style={{
                        color: tabValue === 1 ? 'inherit' : '#090C10'
                    }}
                >
                    {currentPlaybookIdx + 1}/{playbookList.length}{' '}
                    {tabValue === 1 && playlist.title}
                </Typography>
            </TextOverflow>
        </Box>
    )
}
