import { Box } from '@mui/material'

import { ItemCard } from './ItemCard'
import { RadioLabel } from './RadioLabel'
import { type SectionProps } from './Section'

import { type StepType } from 'app/types'
import { useNotification } from 'hooks'
import { type IconType } from 'app/menu'

type Props = {
    isActive: boolean
    disableActions?: boolean
    onEdit?: () => void
    onDelete?: () => void
    onSetActive: SectionProps['onSetActive']
    onThumbnailClick?: () => void
    popupProps: SectionProps['popupProps']
    labelText: string
    gradient?: string
    step: StepType
    EditIcon?: IconType
}
export const StepCard = ({
    isActive,
    labelText,
    step,
    gradient,
    disableActions,
    onDelete,
    onThumbnailClick,
    onSetActive,
    onEdit,
    EditIcon,
    popupProps
}: Props) => {
    const { showSuccessNotification } = useNotification()

    return (
        <ItemCard
            disableLabelButton={isActive}
            onThumbnailClick={onThumbnailClick}
            onEdit={
                onEdit
                    ? () => {
                          if (!disableActions) onEdit()
                      }
                    : undefined
            }
            EditIcon={EditIcon}
            onDelete={
                onDelete
                    ? () => {
                          if (!disableActions) onDelete()
                      }
                    : undefined
            }
            popupProps={popupProps}
            disableDelete={disableActions}
            disableEdit={disableActions}
            label={
                <RadioLabel
                    validateClick={popupProps.validateClick}
                    isActive={isActive}
                    labelText={labelText}
                    onClick={() => {
                        onSetActive({
                            [step.kind]: step.id
                        }).then(() => showSuccessNotification(`Active ${step.kind} changed`))
                    }}
                />
            }
        >
            {disableActions && gradient ? (
                <Box width="100%" height="100%" style={{ background: gradient }} />
            ) : (
                step.drawnScreenshot && (
                    <img
                        src={step.drawnScreenshot}
                        width="100%"
                        height="100%"
                        style={{ objectFit: 'cover' }}
                        alt="preview"
                    />
                )
            )}
        </ItemCard>
    )
}
