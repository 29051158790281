import { memo, useState } from 'react'

import Highlighter from 'react-highlight-words'

import { Box, Popover } from '@mui/material'

import { type Subtitle } from 'UI/Components'

import { DictionaryPotentialItem } from './DictionaryPotentialItem'
import { DictionaryTranscriptForm } from './DictionaryTranscriptForm'

import { useDictionary } from 'hooks'

import { type PlaybookApplicationType } from 'app/types'

const MIN_SEARCH_LENGTH = 2

const generateWords = (text: string) => {
    if (!text) return []

    return text
        .split(' ')
        .map(it => it.replace(/[.,\s]/g, ''))
        .filter(Boolean)
        .map(it => it + ' ')
}

type Props = {
    text: string
    applications: Array<PlaybookApplicationType>
    transcriptLanguage: string
    showAddToDictionary: boolean
    searchWords: string
    subtitle: Subtitle
}
export const DictionaryWrapper = memo(
    ({
        text,
        applications = [],
        transcriptLanguage,
        showAddToDictionary,
        searchWords,
        subtitle
    }: Props) => {
        const { createPhrase } = useDictionary()

        const [anchorEl, setAnchorEl] = useState<Element | null>(null)
        const [phrase, setPhrase] = useState('')

        const onClick = (e: Event, phrase: string) => {
            setAnchorEl(e.currentTarget as Element)
            setPhrase(phrase.trim())
        }

        return (
            <Box fontSize="12px" fontWeight={500}>
                {showAddToDictionary ? (
                    <Box
                        sx={{
                            fontWeight: 500,
                            '&:hover': {
                                fontWeight: '400 !important'
                            }
                        }}
                    >
                        {generateWords(text).map((word, i) => (
                            <DictionaryPotentialItem key={i + word} onClick={onClick}>
                                {word}
                            </DictionaryPotentialItem>
                        ))}
                    </Box>
                ) : (
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#FFF2C7'
                        }}
                        searchWords={searchWords?.length <= MIN_SEARCH_LENGTH ? [] : [searchWords]}
                        subtitle={subtitle}
                        textToHighlight={text}
                    />
                )}

                {anchorEl && (
                    <Popover
                        open={true}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <DictionaryTranscriptForm
                            initialPhrase={phrase}
                            createPhrase={createPhrase}
                            applicationsList={applications.map(it => ({
                                name: it.applicationName,
                                value: it.applicationId
                            }))}
                            handleClose={() => setAnchorEl(null)}
                            transcriptLanguage={transcriptLanguage}
                        />
                    </Popover>
                )}
            </Box>
        )
    }
)
