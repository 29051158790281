import { memo } from 'react'
import { useSelector } from 'react-redux'

import { ShareOnlyDialog as ShareOnlyDialogBitDev } from '@guidde-co/shared.share-only-dialog'

import { type AnyPlaybookType, type QuickGuiddeType, PlaybookMode } from 'app/types'
import { links } from 'app/links'

import {
    logShareByEmail,
    useActivePlaybook,
    useAnonymAuth,
    useAuth,
    useCopyLinkedGif,
    useCopySharedLink,
    useDataMutation,
    useNotification,
    useQuery,
    useShareableOptions
} from 'hooks'
import { logToAnalytics, playbookToAnalyticsProps, uniqueArray, validateEmail } from 'modules'
import { facebookAppId } from 'env'

type Props = {
    isOpen: boolean
    onClose: () => void
    onDone: () => void
    title: string
    playbook: AnyPlaybookType
    // not needed will remove when we will remove feature flag
    subtitle: string
    warning: string
}

export const ShareOnlyDialog = memo(({ onDone, ...props }: Props) => {
    const { playbook, onClose } = props

    const { logToAnalyticsCopyLinkEvent, getCopyPath } = useCopySharedLink()
    const { logToAnalyticsCopyGifEvent } = useCopyLinkedGif()

    const { currentTime } = useSelector(state => state.videoEditor)

    const { activePlaybook } = useActivePlaybook()
    const { isAnonymous } = useAnonymAuth()
    const { showSuccessNotification, showErrorNotification } = useNotification()
    const { uid } = useAuth()

    const $quickGuidde = useQuery<QuickGuiddeType>(
        `/c/v1/quickguidde?id=${playbook.id}`,
        {
            method: 'GET'
        },
        { onError: console.error }
    )

    const $options = useShareableOptions({
        playbooksIds: [playbook.id],
        blockRequest: isAnonymous,
        onError: onClose
    })

    const $anonymousShare = useDataMutation('/b/v1/share-to-email', 'POST', {
        onSuccess: () => {
            onClose()
            onDone()
            showSuccessNotification('Video shared successfully')
        }
    })

    const getCopyLinkPath = () => {
        let query = ''

        if (playbook.mode === PlaybookMode.Playlist) {
            query += `&active=${activePlaybook}`
        }

        return getCopyPath(playbook) + query
    }

    return (
        <ShareOnlyDialogBitDev
            currentTime={currentTime}
            options={$options.data}
            url={getCopyPath(playbook)}
            facebookAppId={facebookAppId}
            guiddeWebsiteUrl={links.guiddeWebsite}
            socialBtnTooltip="Adjust video visibility to 'Public' for social sharing"
            disableSocialButtons={{
                facebook: !playbook.isPublic,
                twitter: !playbook.isPublic,
                linkedin: !playbook.isPublic
            }}
            showFacebookButton={true}
            onFacebookClick={() => {
                logToAnalytics('shareModal_shareTab_socialShareIcon_clicked', {
                    playbookId: playbook.id,
                    type: 'Facebook',
                    ...playbookToAnalyticsProps(playbook)
                })
            }}
            onTwitterClick={() => {
                logToAnalytics('shareModal_shareTab_socialShareIcon_clicked', {
                    playbookId: playbook.id,
                    type: 'X (Twitter)',
                    ...playbookToAnalyticsProps(playbook)
                })
            }}
            onLinkedinClick={() => {
                logToAnalytics('shareModal_shareTab_socialShareIcon_clicked', {
                    playbookId: playbook.id,
                    type: 'LinkedIn',
                    ...playbookToAnalyticsProps(playbook)
                })
            }}
            onEmailClick={() => {
                logToAnalytics('shareModal_shareTab_emailIcon_clicked', {
                    playbookId: playbook.id,
                    ...playbookToAnalyticsProps(playbook)
                })
            }}
            gifInProcessing={false}
            copyLinkPath={getCopyLinkPath()}
            onShare={({ emails, userIds }) => {
                const includeInvalidEmail = emails.some(email => !validateEmail(email))

                if (includeInvalidEmail) {
                    showErrorNotification('Some email is invalid')
                    return
                }

                const allUsers = [
                    ...($options.data?.externalUsers || []),
                    ...($options.data?.internalUsers || [])
                ]

                const knownEmails = userIds
                    .map(uid => allUsers.find(user => user.uid === uid)?.email)
                    .filter(Boolean) as Array<string>

                const uniqueEmails = uniqueArray([...emails, ...knownEmails])

                $anonymousShare
                    .mutate({
                        playbookId: playbook.id,
                        emails: uniqueEmails
                    })
                    .then(() => {
                        logShareByEmail({
                            eventName: 'share_playbook',
                            sharedByUid: uid,
                            sentTo: uniqueEmails,
                            playbookIds: [playbook.id]
                        })
                    })
            }}
            steps={$quickGuidde.data?.steps || []}
            uid={uid}
            isLoading={$quickGuidde.isValidating || $anonymousShare.isLoading}
            onCopySharedLink={() => {
                logToAnalytics('shareModal_shareTab_copyLinkIcon_clicked', {
                    playbookId: playbook.id,
                    ...playbookToAnalyticsProps(playbook)
                })

                logToAnalyticsCopyLinkEvent(playbook, {
                    context: 'share-link'
                })

                showSuccessNotification('Link copied to the clipboard')
            }}
            onCopyGifLink={() => {
                logToAnalytics('shareModal_shareTab_GIFIcon_clicked', {
                    playbookId: playbook.id,
                    ...playbookToAnalyticsProps(playbook)
                })
                showSuccessNotification('Linked GIF was copied to the clipboard')
                logToAnalyticsCopyGifEvent(playbook)
            }}
            {...props}
            playbook={playbook}
        />
    )
})
