import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import {
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Paper,
    Popper,
    Slider,
    Typography,
    Divider,
    styled
} from '@mui/material'

import QueueMusicIcon from '@mui/icons-material/QueueMusic'
import EditIcon from '@mui/icons-material/EditOutlined'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'
import { ReactComponent as RingIcon } from 'assets/icons/ring.svg'

import { useBoolean, useBrandKit, useConfiguration } from 'hooks'
import { setQuickGuiddeAudio, setQuickGuiddeAudioVolume } from 'ducks'
import { paths } from 'app/paths'
import { logToAnalytics } from 'modules'

import { AudioBackgroundDialog } from './AudioBackgroundDialog'

import { SpacedGroup } from 'UI/Components'

const PREFIX = 'AudioBackground'

const classes = {
    root: `${PREFIX}-root`,
    sliderMark: `${PREFIX}-sliderMark`,
    rail: `${PREFIX}-rail`,
    track: `${PREFIX}-track`,
    thumb: `${PREFIX}-thumb`,
    actions: `${PREFIX}-actions`,
    divider: `${PREFIX}-divider`
}

const StyledPaper = styled(Paper)({
    [`& .${classes.root}`]: {
        [`&:hover .${classes.thumb}`]: {
            opacity: 1, // Show the thumb when hovering over the root
            boxShadow: 'none !important',
            backgroundColor: '#CB0000'
        }
    },
    [`& .${classes.sliderMark}`]: {
        background: 'transparent'
    },
    [`& .${classes.rail}`]: {
        borderRadius: '10px',
        height: 5
    },
    [`& .${classes.track}`]: {
        borderRadius: '10px',
        border: 'none',
        height: 5
    },
    [`& .${classes.thumb}`]: {
        width: 12,
        height: 12,
        // Initial state: hide the thumb
        opacity: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:focus': {
            boxShadow: 'none  !important'
        },
        '&:active': {
            boxShadow: 'none !important',
            backgroundColor: '#CB0000'
        }
    },
    [`& .${classes.actions}`]: {
        height: 16,
        width: 16,
        borderRadius: 2,
        overflow: 'hidden',
        background: '#transparent'
    },
    [`& .${classes.divider}`]: {
        backgroundColor: '#E0E0E0',
        height: 16
    }
})

const breakPointStep = 5

export const getVolumeFromBreakPoint = (breakPoint: number) => {
    return breakPoint * breakPointStep
}

// max 1 (100%), min 0.01 (1%)
export const getBreakPointFromPercentage = (percentage: number) => {
    const value = percentage * 100
    const breakPoint = Math.ceil(value / breakPointStep)

    return Math.max(breakPoint, 1)
}

export const AudioBackground = () => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()
    const isBrandKitPage = useRouteMatch(paths.brandKit)?.isExact

    const { isConfigurationLoading, audio: audioConfig } = useConfiguration()

    const { steps } = useSelector(state => state.qgEditor.present)

    const includedVO = steps.some(step => step.audioNote)

    const defaultVolume = includedVO
        ? audioConfig?.defaultAudioVolumeWithVO
        : audioConfig?.defaultAudioVolumeWithoutVO

    const [volumeAnchorEl, setVolumeAnchorEl] = useState<HTMLButtonElement | null>(null)

    const { brandKitData, brandKitLoading } = useBrandKit()

    const { audio } = useSelector(state => state.qgMetadata)

    const audios = brandKitData?.audioBackground?.data || []

    const hovered = useBoolean()
    const audioDialog = useBoolean()

    if (isBrandKitPage) return null

    return (
        <>
            <Box
                height={30}
                display="flex"
                alignItems="center"
                borderRadius="4px"
                bgcolor={hovered.isTrue ? '#F5F5F5' : 'transparent'}
            >
                {audio ? (
                    <>
                        <Button
                            variant="text"
                            onClick={e => setVolumeAnchorEl(e.currentTarget)}
                            disableElevation
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                        >
                            <SpacedGroup spacing={1} ml={1}>
                                <RingIcon />
                                <TextOverflow lineClamp={1}>
                                    <Typography
                                        sx={{
                                            fontStyle: 'italic',
                                            fontWeight: 700,
                                            pr: 0.5,
                                            fontSize: 12,
                                            color: '#090C10'
                                        }}
                                        variant="caption"
                                    >
                                        {audio.name}
                                    </Typography>
                                </TextOverflow>
                            </SpacedGroup>
                        </Button>
                    </>
                ) : (
                    <Button
                        color="secondary"
                        onClick={() => {
                            audioDialog.toggle()
                            logToAnalytics('editor_addMusicBtn_clicked', { playbookId })
                        }}
                        variant="text"
                        size="small"
                        startIcon={<QueueMusicIcon />}
                        style={{ color: '#2D9CDB', marginLeft: 8 }}
                    >
                        <Typography
                            color="inherit"
                            style={{
                                fontSize: 12,
                                fontWeight: 500
                            }}
                        >
                            ADD MUSIC
                        </Typography>
                    </Button>
                )}

                <AudioBackgroundDialog
                    isOpen={audioDialog.isTrue}
                    onClose={audioDialog.setFalse}
                    isLoading={brandKitLoading || isConfigurationLoading}
                    onApply={audio => {
                        if (!defaultVolume) {
                            console.error('no default audio volume')
                            return
                        }

                        const modifiedAudio = audio ? { ...audio, volume: defaultVolume } : audio

                        dispatch(setQuickGuiddeAudio(modifiedAudio))

                        audioDialog.setFalse()
                        hovered.setFalse()
                    }}
                    options={audios}
                    initialActiveId={audio?.id || audios?.[0]?.id}
                    onPlaySample={() =>
                        logToAnalytics('music_track_sample_selected', { playbookId })
                    }
                    onCloseButtonClick={() => logToAnalytics('music_xIcon_clicked', { playbookId })}
                />
            </Box>
            <Popper
                open={Boolean(volumeAnchorEl)}
                anchorEl={volumeAnchorEl}
                placement="bottom-start"
                style={{
                    zIndex: 11
                }}
            >
                <StyledPaper style={{ width: 240 }}>
                    <ClickAwayListener onClickAway={() => setVolumeAnchorEl(null)}>
                        <SpacedGroup spacing={0.5} ml={1} py={1}>
                            <Slider
                                value={getVolumeFromBreakPoint(
                                    audio?.volume || defaultVolume || 20
                                )}
                                step={5}
                                marks
                                min={5}
                                max={100}
                                onChange={(_, value) => {
                                    if (Array.isArray(value)) return

                                    const breakPoint = getBreakPointFromPercentage(value / 100)
                                    dispatch(setQuickGuiddeAudioVolume(breakPoint))
                                }}
                                onChangeCommitted={() =>
                                    logToAnalytics('editor_volumeMusicIcon_clicked', { playbookId })
                                }
                                valueLabelDisplay="auto"
                                classes={{
                                    root: classes.root,
                                    mark: classes.sliderMark,
                                    rail: classes.rail,
                                    track: classes.track,
                                    thumb: classes.thumb
                                }}
                                style={{ width: 160, margin: '0 10px 0 5px ' }}
                            />

                            <Box>
                                <Divider orientation="vertical" className={classes.divider} />
                            </Box>

                            <IconButton
                                onClick={() => {
                                    audioDialog.toggle()
                                    setVolumeAnchorEl(null)

                                    logToAnalytics('editor_editMusicIcon_clicked', { playbookId })
                                }}
                                size="small"
                            >
                                <EditIcon color="inherit" style={{ height: 16, width: 16 }} />
                            </IconButton>

                            <Box>
                                <Divider orientation="vertical" className={classes.divider} />
                            </Box>

                            <IconButton
                                size="small"
                                onClick={() => {
                                    dispatch(setQuickGuiddeAudio(null))
                                    setVolumeAnchorEl(null)

                                    logToAnalytics('editor_deleteMusicIcon_clicked', { playbookId })
                                }}
                                style={{ marginRight: 8 }}
                            >
                                <BinIcon color="inherit" style={{ height: 16, width: 16 }} />
                            </IconButton>
                        </SpacedGroup>
                    </ClickAwayListener>
                </StyledPaper>
            </Popper>
        </>
    )
}
