import { useMemo } from 'react'

import { type ShareableSpacesType } from 'app/types'

import { useNotification, useQuery } from 'hooks'
import { generalErrorMessage } from 'modules'

const initialData = {
    spaces: [],
    internalUsers: [],
    externalUsers: [],
    pendingEmails: []
}

const initialParams = {
    internalUsers: true,
    externalUsers: true,
    pendingEmails: true
}

type Props = {
    playbooksIds: Array<string>
    blockRequest?: boolean
    params?: Partial<{
        spaces: boolean
        internalUsers: boolean
        externalUsers: boolean
        pendingEmails: boolean
    }>
    onError?: () => void
}

export const useShareableOptions = ({
    playbooksIds,
    blockRequest = false,
    params = initialParams,
    onError
}: Props) => {
    const { showErrorNotification } = useNotification()

    const $options = useQuery<ShareableSpacesType>(
        playbooksIds.length > 0 && !blockRequest ? '/c/v1/get-shareable-options' : '',
        {
            method: 'POST',
            body: {
                playbooksIds,
                ...params
            }
        },
        {
            onError: ({ message }) => {
                onError?.()
                console.error(message)
                showErrorNotification(generalErrorMessage)
            },
            dedupingInterval: 0
        }
    )

    return useMemo(() => ({ ...$options, data: $options.data || initialData }), [$options])
}
