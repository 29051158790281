import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, styled } from '@mui/material'

import IconSlider from 'assets/icons/icon-slider.svg'

import { logToAnalytics, round } from 'modules'
import { setSections } from 'ducks/actions'
import { useTextDebounce } from 'hooks'

const getPercentage = (containerWidth: number, distanceMoved: number) => {
    return (distanceMoved / containerWidth) * 100
}

const limitNumberWithinRange = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), Math.min(max, 100))
}

const MIN_WIDTH = 3

const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1em',
    height: '100%',
    position: 'absolute',
    right: '-0.55em',
    top: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 15,
    cursor: 'ew-resize',
    userSelect: 'none'
})

type Props = {
    index: number
    timelineOffsetWidth: number
    onUpdateTime: (percentage: number) => void
}

export const SliderButton = ({ index, timelineOffsetWidth, onUpdateTime }: Props) => {
    const dispatch = useDispatch()

    const [percent, setPercent] = useState<number | string>('')
    const debouncedPercent = useTextDebounce(percent)

    const { sections, currentTime } = useSelector(state => state.videoEditor)

    const handleSectionMove = (e: { preventDefault: () => void; pageX: number }) => {
        e.preventDefault()
        document.body.style.cursor = 'ew-resize'

        const startDragX = e.pageX

        const resize = (e: any) => {
            e.preventDefault()

            const endDragX = e.touches ? e.touches[0].pageX : e.pageX
            const distanceMoved = endDragX - startDragX
            const maxPercent =
                sections[index + 1].rangeValues[1] - (index === sections.length - 2 ? 0 : MIN_WIDTH) // set limit for all except the last one

            const percentageMoved = getPercentage(timelineOffsetWidth, distanceMoved)

            const newSections = [
                ...sections.slice(0, index), // before the section, which we're going to change
                {
                    ...sections[index],
                    rangeValues: [
                        sections[index].rangeValues[0],
                        limitNumberWithinRange(
                            round(sections[index].rangeValues[1] + percentageMoved),
                            index === 0 ? 0 : sections[index - 1].rangeValues[1] + MIN_WIDTH,
                            maxPercent
                        ) // limit current section width to MIN_WIDTH property
                    ]
                }, // change end time of the current section
                {
                    ...sections[index + 1],
                    rangeValues: [
                        limitNumberWithinRange(
                            round(sections[index + 1].rangeValues[0] + percentageMoved),
                            index === 0 ? 0 : sections[index - 1].rangeValues[1] + MIN_WIDTH,
                            maxPercent
                        ), // limit current section width to MIN_WIDTH property
                        sections[index + 1].rangeValues[1]
                    ]
                }, // change start time of the next section based on changes of the prev section
                ...sections.slice(index + 2) // everything else after the 2 changed sections
            ]

            // Update sections in reducer
            dispatch(setSections(newSections))

            // Update current percent
            setPercent(
                limitNumberWithinRange(
                    sections[index].rangeValues[1] + percentageMoved,
                    index === 0 ? 0 : sections[index - 1].rangeValues[1] + MIN_WIDTH,
                    maxPercent
                ) // needs to prevent jumping outside of the section's ranges
            )
        }

        window.addEventListener('pointermove', resize)

        const handleEventUp = (e: { preventDefault: () => void }) => {
            e.preventDefault()
            document.body.style.cursor = 'initial'
            window.removeEventListener('pointermove', resize)
        }

        window.addEventListener('pointerup', handleEventUp)
    }

    // Adds the delay of 500ms before update current video time when we move sliders
    // Made to improve user experience + performance
    useEffect(() => {
        if (currentTime === debouncedPercent || typeof debouncedPercent !== 'number') return

        onUpdateTime(debouncedPercent)
        logToAnalytics(
            index === 0
                ? 'trim_start'
                : index === sections.length - 2
                  ? 'trim_end'
                  : 'move_chapter',
            { chapterIndex: index }
        )
        // we don't actually need to update when anything except debouncedPercent changes as it causes re-renders
        // eslint-disable-next-line
    }, [debouncedPercent])

    return (
        <Container onMouseDown={handleSectionMove}>
            <img alt="arrows" src={IconSlider} height="10px" />
        </Container>
    )
}
