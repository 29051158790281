import * as types from 'ducks/types'
import { type ActionType, type Reducer } from 'ducks/common'

const initialState = {
    dialogs: {
        upgradePlanDialog: false,
        updatePlanDialog: false
    },
    notifications: {
        freePlanLimitation: false,
        paidPlanLimitation: false,
        paidPlanStarted: false,
        trialPlanStarted: false,
        freePlanStarted: false
    }
}

export type ServiceUsageStateType = typeof initialState

export const serviceUsageReducer: Reducer<ServiceUsageStateType> = (
    state = initialState,
    action: ActionType
): ServiceUsageStateType => {
    switch (action.type) {
        case types.SHOW_SERVICE_USAGE_DIALOG: {
            return {
                ...state,
                dialogs: { ...state.dialogs, [action.payload]: true }
            }
        }
        case types.CLOSE_SERVICE_USAGE_DIALOGS: {
            return {
                ...initialState
            }
        }
        case types.SHOW_SERVICE_USAGE_NOTIFICATIONS: {
            return {
                ...state,
                notifications: { ...state.notifications, ...action.payload }
            }
        }
        default: {
            return state
        }
    }
}
