import { memo, useEffect, useState } from 'react'
import MaskedInput from 'react-input-mask'

import { IconButton, Stack, TextField, Typography } from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo'

const timeToSeconds = (time: string) => {
    const [minutes, seconds] = time.split(':').map(Number)
    return minutes * 60 + seconds
}

const secondsToTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
}

type Props = {
    value: number
    label: string
    onChange: (_seconds: number) => void
    onReset: () => void
}

export const StyledMaskedInput = memo(({ label, value, onChange, onReset }: Props) => {
    const [tempValue, setTempValue] = useState(secondsToTime(value))

    useEffect(() => {
        setTempValue(secondsToTime(value))
    }, [value])

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <Typography>{label}</Typography>
            <MaskedInput
                mask="99:99"
                maskPlaceholder="-"
                placeholder="00:00"
                value={tempValue}
                onBlur={() => {
                    onChange(Math.floor(timeToSeconds(tempValue)))

                    // Set the last valid value, If new value in onChange is valid, it will trigger the useEffect
                    // We need it to reset invalid temp state
                    setTempValue(secondsToTime(value))
                }}
                onChange={event => setTempValue(event.target.value)}
            >
                <TextField
                    sx={{
                        width: '55px',
                        '& input': {
                            padding: 0,
                            borderRadius: '4px 4px 0 0',
                            color: 'rgba(0, 0, 0, 0.54)',
                            textAlign: 'center'
                        }
                    }}
                    size="small"
                    variant="filled"
                />
            </MaskedInput>

            <IconButton onClick={onReset} size="small">
                <UndoIcon />
            </IconButton>
        </Stack>
    )
})
