import { useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import { AudioBackground, SpacedGroup } from 'UI/Components'
import { StepNumbering } from './StepNumbering'

import { formatDate } from 'modules'
import { type QuickGuiddeType } from 'app/types'

type Props = {
    quickGuidde: QuickGuiddeType
}

export const InfoPanel = ({ quickGuidde }: Props) => {
    const { steps } = useSelector(state => state.qgEditor.present)

    return (
        <Box
            px={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height={62}
            borderBottom="1px solid rgba(9, 12, 16, 0.08)"
        >
            <Box>
                <Typography>
                    <b>
                        {steps?.length} step{steps?.length > 1 ? 's' : ''}
                    </b>
                </Typography>
                <SpacedGroup spacing={0.5}>
                    <Typography variant="body2" color="textSecondary">
                        Date created: <b>{formatDate(quickGuidde.uploadedAt)} •</b>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Last updated: <b>{formatDate(quickGuidde.lastUpdatedAt)}</b>
                    </Typography>
                </SpacedGroup>
            </Box>

            <SpacedGroup>
                <StepNumbering />
                <AudioBackground />
            </SpacedGroup>
        </Box>
    )
}
