import { useCallback, useMemo } from 'react'

import { type AnyPlaybookType } from 'app/types'

import { useAuth, useNotification, useCustomerDomain } from 'hooks'
import { copyToClipboard, getPlaybookPath, logToAnalytics, playbookToAnalyticsProps } from 'modules'

export const useCopySharedLink = () => {
    const { uid } = useAuth()
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const { generateDomain } = useCustomerDomain()

    const logToAnalyticsCopyLinkEvent = useCallback(
        (playbook: AnyPlaybookType, analyticsProps: Record<string, unknown> = {}) => {
            logToAnalytics('copyLink', {
                ...analyticsProps,
                ...playbookToAnalyticsProps(playbook)
            })
        },
        []
    )

    const getCopyPath = useCallback(
        (playbook: AnyPlaybookType) => {
            const domain = generateDomain(playbook)
            const path = getPlaybookPath(playbook)

            return `${domain}${path}?origin=${uid}`
        },
        [generateDomain, uid]
    )
    const copySharedLink = useCallback(
        (
            playbook: AnyPlaybookType,
            showNotification: boolean,
            analyticsProps: Record<string, unknown> = {},
            queryParams?: string
        ) => {
            try {
                const link = `${getCopyPath(playbook)}${queryParams || ''}`

                window.copiedShareLink = link

                logToAnalyticsCopyLinkEvent(playbook, analyticsProps)

                copyToClipboard(link).then(() => {
                    if (showNotification) {
                        showSuccessNotification('Link copied to the clipboard')
                    }
                })
            } catch (error) {
                showErrorNotification(error)
            }
        },
        [getCopyPath, logToAnalyticsCopyLinkEvent, showSuccessNotification, showErrorNotification]
    )

    return useMemo(
        () => ({ copySharedLink, logToAnalyticsCopyLinkEvent, getCopyPath }),
        [copySharedLink, logToAnalyticsCopyLinkEvent, getCopyPath]
    )
}
