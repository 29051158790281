import { Stack, Typography } from '@mui/material'

import { type BrandKitType } from 'app/types'

import { type SectionProps, ItemCard, Section, ColorPopper } from 'UI/Routes/brand-kit/sections'
import { cardLimits } from 'UI/Routes/brand-kit/PageBrandKit'
import { FontSize } from './FontSize'
import { FontFamily } from './FontFamily'

import { useNotification } from 'hooks'

type Props = {
    values: {
        font: BrandKitType['font']
        fontSize: BrandKitType['fontSize']
        fontColor: BrandKitType['fontColor']
        fontFillColor: BrandKitType['fontFillColor']
    }
} & SectionProps

export const FontSettings = ({ popupProps, isLoading, values, onSetActive, onAdd }: Props) => {
    const { showSuccessNotification } = useNotification()

    const { font, fontSize, fontColor, fontFillColor } = values

    const activeFont = font.data.find(item => item.id === font.active)
    const activeFontSize = fontSize.data.find(item => item.id === fontSize.active)?.value
    const activeColor = fontColor.data.find(item => item.id === fontColor.active)?.value
    const activeFillColor = fontFillColor.data.find(item => item.id === fontFillColor.active)?.value

    const isFontColorOverLimit = fontColor.data.length >= cardLimits.fontColor
    const isFontFillColorOverLimit = fontFillColor.data.length >= cardLimits.fontFillColor

    const handleAction = ({
        type,
        field,
        value
    }: {
        type: 'add' | 'setActive'
        field: keyof Props['values']
        value: string
    }) => {
        const handler = type === 'add' ? onAdd : onSetActive

        return handler({
            [field]: value
        }).then(() => showSuccessNotification(`Default ${field} was changed`))
    }

    if (!activeFont) {
        console.error("Active font doesn't exist")
        return null
    }

    return (
        <>
            <link href={activeFont.url} rel="stylesheet" />

            <Section
                popupProps={popupProps}
                title="Font"
                subtitle="Select your brand font for video text elements"
                showActionCard={false}
                content={[
                    <Stack
                        key={activeFont.id}
                        direction="row"
                        alignItems="center"
                        spacing={0}
                        flexWrap="wrap"
                        gap={2}
                    >
                        <ItemCard popupProps={popupProps} bgcolor={activeFillColor}>
                            <Typography
                                data-test="brandkit-change-font"
                                style={{
                                    fontFamily: activeFont.title,
                                    fontSize: activeFontSize,
                                    color: activeColor
                                }}
                            >
                                Aa
                            </Typography>
                        </ItemCard>
                        <FontFamily
                            activeFont={activeFont}
                            font={font}
                            isLoading={isLoading}
                            onChange={font => {
                                handleAction({
                                    type: 'setActive',
                                    field: 'font',
                                    value: font
                                })
                            }}
                        />
                        <FontSize
                            fontSize={fontSize}
                            onUpdate={fontSizeId => {
                                console.info('On update: ', { fontSizeId })
                                handleAction({
                                    type: 'setActive',
                                    field: 'fontSize',
                                    value: fontSizeId
                                })
                            }}
                        />

                        {activeColor && (
                            <ColorPopper
                                title="Font color"
                                popupProps={popupProps}
                                activeColor={activeColor}
                                isLoading={isLoading}
                                disabled={false}
                                isOverLimit={isFontColorOverLimit}
                                overLimitTooltip={`Maximum number of elements colors - ${cardLimits.fontColor}`}
                                colors={values.fontColor.data.map(color => color.value)}
                                onSetActiveColor={newColor => {
                                    const newColorId = fontColor.data.find(
                                        item => item.value === newColor
                                    )?.id

                                    if (!newColorId) return

                                    handleAction({
                                        type: 'setActive',
                                        field: 'fontColor',
                                        value: newColorId
                                    })
                                }}
                                onAddColor={async newColor => {
                                    await handleAction({
                                        type: 'add',
                                        field: 'fontColor',
                                        value: (newColor as [string, string])[0]
                                    })
                                }}
                            />
                        )}

                        {activeFillColor !== undefined && (
                            <ColorPopper
                                title="Fill color"
                                popupProps={popupProps}
                                activeColor={activeFillColor}
                                isLoading={isLoading}
                                disabled={false}
                                isOverLimit={isFontFillColorOverLimit}
                                overLimitTooltip={`Maximum number of elements colors - ${cardLimits.fontFillColor}`}
                                colors={values.fontFillColor.data.map(color => color.value)}
                                onSetActiveColor={newColor => {
                                    const newColorId = fontFillColor.data.find(
                                        item => item.value === newColor
                                    )?.id

                                    if (!newColorId) return

                                    handleAction({
                                        type: 'setActive',
                                        field: 'fontFillColor',
                                        value: newColorId
                                    })
                                }}
                                onAddColor={async newColor => {
                                    await handleAction({
                                        type: 'add',
                                        field: 'fontFillColor',
                                        value: (newColor as [string, string])[0]
                                    })
                                }}
                            />
                        )}
                    </Stack>
                ]}
            />
        </>
    )
}
