import { memo } from 'react'

import { styled, Avatar, Box, IconButton, LinearProgress, Typography } from '@mui/material'

import { Carousel } from 'UI/Components'

import {
    copyToClipboardMessage,
    useAuth,
    useCopyToClipboard,
    useCustomerDomain,
    useNotification,
    useQuery
} from 'hooks'
import {
    copyToClipboard,
    getPlaybookPath,
    isQG,
    logToAnalytics,
    playbookToAnalyticsProps
} from 'modules'

import { type AnyPlaybookType } from 'app/types'

type LinkAppType = {
    enableTrackedLink: boolean
    favicon: string
    logo: string
    recipientEmailVariable: string
    senderEmailVariable: string
    title: string
    doNotUseGif?: boolean
    id: string
}

const StyledAvatar = styled(Avatar, {
    shouldForwardProp: propName => propName !== 'isEmpty'
})<{ isEmpty: boolean }>(({ theme, isEmpty }) => ({
    background: isEmpty ? theme.palette.secondary.main : 'inherit'
}))

type Props = {
    playbook: AnyPlaybookType
    startAt?: number
}

export const LinkTemplates = memo(({ startAt, playbook }: Props) => {
    const { isPublic, title, gifUrl, screenshotUrl } = playbook
    window.gifUrl = gifUrl
    window.screenshotUrl = screenshotUrl

    const { uid } = useAuth()

    const { generateDomain } = useCustomerDomain()

    const { copyToClipboard: copyToClipboardByHook } = useCopyToClipboard()

    const { showErrorNotification, showWarningNotification, showSuccessNotification } =
        useNotification()

    const { data, isValidating } = useQuery<{
        data: Array<LinkAppType>
    }>(
        `/b/v1/trackLinkApps`,
        { method: 'GET' },
        {
            onError: () => showErrorNotification(copyToClipboardMessage),
            revalidateOnFocus: false
        }
    )

    const linkApps = data?.data || []

    const domain = generateDomain(playbook)
    const path = getPlaybookPath(playbook)

    const copyServiceNow = () => {
        const href = `${domain}${path}?origin=${uid}`
        const copyData = `[code]<a href="${href}">${title.trim()}</a>[/code]`

        copyToClipboard(copyData).then(() => showSuccessNotification(copyToClipboardMessage))
    }

    const handleLinkClick = (link: LinkAppType) => {
        const { senderEmailVariable: sender, recipientEmailVariable: recipient, doNotUseGif } = link

        const width = isQG(playbook) ? '700px' : '250px'
        const height = isQG(playbook) ? 'auto' : '175px'

        let linkTemplate = `${domain}${path}?track_link_name=${recipient}&origin=${sender || uid}`

        if (startAt) {
            linkTemplate += `&startAt=${startAt}`
        }

        if (doNotUseGif) {
            copyToClipboard(linkTemplate).then(() => {
                showSuccessNotification('Link was copied to the clipboard')
            })

            return
        }

        window.linkTemplate = linkTemplate

        const imageHtml = `
            <table bgcolor="white" style="background-color: white" width="100%">
                <tr>
                    <td>
                        <a style="text-decoration: underline" href="${linkTemplate}">
                          ${playbook.title}
                        </a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="${linkTemplate}">
                            <img 
                              width=${width} 
                              height=${height} 
                              alt="" 
                              style={{width,height,objectFit:'contain',borderRadius:'2px'}} 
                              src=${gifUrl || screenshotUrl} 
                            />
                        </a>
                    </td>
                </tr>
            </table>`

        copyToClipboardByHook(imageHtml)
        window.trackingGif = imageHtml
    }

    if (isValidating) return <LinearProgress />

    return (
        <Box position="relative">
            <Carousel spacing={1.5}>
                {linkApps.map(link => (
                    <Box
                        key={link.id}
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <IconButton
                            data-test={`copy_track_gif_${link.title.toLowerCase()}`}
                            size="small"
                            onClick={() => {
                                if (!isPublic) {
                                    return showWarningNotification(
                                        'The video privacy settings require you to share it with anyone in order to copy tracked link'
                                    )
                                }

                                logToAnalytics('shareModal_trackTab_trackIcon_clicked', {
                                    playbookId: playbook.id,
                                    source: link.title,
                                    ...playbookToAnalyticsProps(playbook)
                                })

                                // ServiceNow have a specific format to copy
                                if (link.title === 'ServiceNow') {
                                    copyServiceNow()
                                    return
                                }
                                handleLinkClick(link)
                            }}
                            style={{
                                width: '30px',
                                height: '30px',
                                opacity: isPublic ? 1 : 0.5
                            }}
                        >
                            <StyledAvatar
                                src={link.favicon || link.logo}
                                alt={link.title}
                                sx={{ width: '30px', height: '30px', fontSize: '14px' }}
                                isEmpty={!link.favicon && !link.logo}
                            >
                                <Box title={link.title}>{link.title.charAt(0).toUpperCase()}</Box>
                            </StyledAvatar>
                        </IconButton>
                        <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
                            {link.title}
                        </Typography>
                    </Box>
                ))}
            </Carousel>
        </Box>
    )
})
