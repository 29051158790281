import { CommonDialog } from 'UI/Components'

type Props = {
    isOpen: boolean
    redirectUrl: string
    onClose: () => void
}

export const RegionRedirectDialog = ({ isOpen, redirectUrl, onClose }: Props) => {
    const redirectInitials = redirectUrl === 'eu.guidde.com' ? 'EU' : 'US'

    return (
        <CommonDialog
            isOpen={isOpen}
            onClose={onClose}
            content={
                <div>
                    <p>Your account is registered in the {redirectInitials} region.</p>
                    <p style={{ marginBottom: '35px' }}>
                        To continue, click 'Switch to {redirectInitials}' and login again.
                    </p>
                    <p>Thank you.</p>
                </div>
            }
            hideCloseBtn
            confirmBtnText={`SWITCH TO ${redirectInitials}`}
            title="Region Access Update"
            titleSize="lg"
            onConfirm={() => (window.location.href = `https://${redirectUrl}`)}
        />
    )
}
