import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { DEFAULT_STEP } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'

import { useDataMutation } from 'hooks'
import { addNewStep } from 'ducks'
import { type StepType } from 'app/types'

const border = '1px solid rgba(0, 0, 0, 0.12)'

const StyledBox = styled(Box)({
    height: 120,
    minWidth: 70,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    borderTop: 'none',
    color: '#CB0000',
    cursor: 'pointer'
})

type PayloadType = {
    kind: Props['kind']
    width: number
    height: number
    title: string
    playbookId: string
}

type Props = {
    title: string
    kind: 'cover' | 'end'
}

export const AddCoverEndingStep = ({ title, kind }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const $generateStep = useDataMutation<PayloadType, StepType, Error>(
        '/c/v1/quickguidde/generateTemplate',
        'POST',
        {
            onSuccess: data => dispatch(addNewStep({ step: data, kind }))
        }
    )

    if ($generateStep.isLoading)
        return (
            <Box
                minWidth={70}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    borderLeft: border,
                    borderRight: border
                }}
            >
                <CircularProgress size={35} />
            </Box>
        )

    if (steps?.find(step => step.kind === kind)) return null

    return (
        <StyledBox
            data-test="editor-add-cover-step"
            pt={1.5}
            pb={2}
            sx={{
                color: 'black',
                borderLeft: border,
                borderRight: border
            }}
            onClick={() => {
                $generateStep.mutate({
                    playbookId,
                    kind,
                    title,
                    width: (steps[activeStep] || DEFAULT_STEP).windowDimensions.innerWidth,
                    height: (steps[activeStep] || DEFAULT_STEP).windowDimensions.innerHeight
                })
            }}
        >
            <Box mb={2}>
                <AddCircleOutlineIcon />
            </Box>
            <Typography variant="subtitle2">{kind === 'cover' ? 'Cover' : 'Ending'}</Typography>
        </StyledBox>
    )
}
