import { renderToString } from 'react-dom/server'

import {
    type StepType,
    type QuickGuiddeType,
    type AnyPlaybookType,
    type SingleCtaType,
    type MultipleCtaType,
    PlaybookMode
} from 'app/types'

import { isValidUrl } from 'modules'
import { firebaseConfig } from 'env'

import {
    Description,
    Header,
    PoweredByGuidde,
    filterIntoOutroSteps,
    getStepText
} from 'UI/Components'
import { buildCTAUrl } from 'UI/Routes/quick-guidde/CanvasEditor'

import { type CreateStepsProps, type PlaybookImageWidthType, type GenerateHTMLProps } from './types'

export const createSteps = ({
    steps,
    enableNgnixLinks,
    width = '100%',
    goToLabel,
    id
}: CreateStepsProps) => {
    let res: Array<string> = []

    const textStyle = `width: ${width}; word-break: break-word; max-width:100%;`
    const indexDelta = steps[0]?.kind === 'cover' ? 0 : 1

    const screenshotKey = enableNgnixLinks ? 'docPublicScreenshot' : 'docScreenshot'

    const buildCtaLink = (action: SingleCtaType['action']) => {
        if (!action.enabled) return ''

        return `<a href="${buildCTAUrl(action, id).replaceAll('&', '&amp;')}" target="_blank">${
            action.text
        }</a> <br/>`
    }

    const buildCtaLinks = (cta: SingleCtaType | MultipleCtaType) => {
        if (cta.ctaType === 'single') {
            return buildCtaLink(cta.action)
        }

        const header = `<h4>${cta.title}</h4>`
        const actions = cta.actions
            .map(action => buildCtaLink(action))
            .filter(Boolean)
            .join('')

        return `<div style="margin-top:16px; margin-bottom:16px" >${header}${actions}</div>`
    }

    steps.flatMap((step: StepType, index: number) => {
        const { cta } = step

        const noteText = step.audioNote?.text

        const stepNoteBlock = `<div style="margin-top:16px; margin-bottom:16px" >
            <p style="font-size:14px; color: rgba(9, 12, 16, 0.6); ${textStyle}">${noteText}</p>
            ${cta ? buildCtaLinks(cta) : ''}
        </div>`

        // For cover and ending step we only need to show audio note and nothing more

        if (step.kind === 'cover' || step.kind === 'end') {
            if (noteText) res.push(stepNoteBlock)
            return
        }

        const url = step.url ? new URL(step.url) : { origin: '#', host: '' }

        if (index === 0 + (steps[0]?.kind === 'cover' ? 1 : 0)) {
            if (url.host) {
                res.push(
                    `<h3 style="${textStyle}">
                      ${goToLabel} <a href="${url.origin}" target="_blank">${url.host}</a>
                  </h3>`
                )
            }
        }

        const defaultTitle = `<h3 style="${textStyle}">${index + indexDelta}. ${getStepText(
            step
        )}</h3>`

        if (step.type === 'urlChange') {
            // take link from the title string if the one exist
            const parsedTitle = getStepText(step).split('"')
            const hasValidLink = parsedTitle.length >= 3 ? isValidUrl(parsedTitle[1]) : false

            if (!step.url || !hasValidLink) res.push(defaultTitle)
            else
                res.push(
                    `<h3 style="${textStyle}">${index + indexDelta}. ${parsedTitle
                        .filter(e => e !== parsedTitle[1])
                        .join('')}
                  <a style="${textStyle}" href="${step.url.includes('http') ? '' : '//'}${url}">${
                      parsedTitle[1]
                  }</a>
                  </h3>`
                )
        } else {
            res.push(defaultTitle)
        }

        if (noteText) res.push(stepNoteBlock)

        if (cta && !noteText) {
            res.push(buildCtaLinks(cta))
        }

        if (step?.[screenshotKey] || step.drawnScreenshot) {
            res.push(
                `<img width="${width}" src="${(
                    step?.[screenshotKey] || step.drawnScreenshot
                ).replaceAll('&', '&amp;')}" alt="${step.title.replaceAll('"', "'")}" />`
            )
        }
    })
    return res.join(' ')
}

export const getIframeURL = ({ id, mode }: AnyPlaybookType) =>
    `https://embed.${firebaseConfig.authDomain}/${
        mode === PlaybookMode.Playlist ? 'playlists' : 'playbooks'
    }/${id}`

export const generateCommonAttributes = (playbook: AnyPlaybookType) => {
    return `
      src="${getIframeURL(playbook)}"
      title="${playbook.title}"
      frameborder="0"
      referrerpolicy="unsafe-url"
      allowfullscreen="true"
      allow="clipboard-write"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"`
}

export const createNotResponsiveEmbedCode = (
    playbook: AnyPlaybookType,
    { width, height }: { width: number; height: number }
) => {
    const common = generateCommonAttributes(playbook)

    window.embedCode = common

    return `<iframe width="${width}px" height="${height}px" ${common}></iframe>`
}

export const createResponsiveEmbedCode = (playbook: AnyPlaybookType) => {
    const common = generateCommonAttributes(playbook)

    window.embedCode = common

    // about responsive code - https://guidde.atlassian.net/browse/GD-7712
    return `
      <div style="position:relative;padding-bottom:56.25%;">
          <iframe style="width:100%;height:100%;position:absolute;left:0px;top:0px" ${common}></iframe>
      </div>`
}

export const generateHTML = ({
    playbook,
    content,
    enableNgnixLinks,
    width,
    headerSize,
    headerAlignment,
    withHyperLinkTitle,
    hidePoweredByGuidde
}: GenerateHTMLProps) => {
    const { title, steps, id, goToLabel } = playbook

    const filteredSteps = filterIntoOutroSteps(steps)

    return `
      <div>
          ${renderToString(
              <Header
                  withHyperLinkTitle={withHyperLinkTitle}
                  title={title}
                  playbookId={id}
                  headerSize={headerSize}
                  headerAlignment={headerAlignment}
              />
          )}
          ${content}
          ${renderToString(<Description description={playbook.description} />)}
          ${createSteps({ steps: filteredSteps, enableNgnixLinks, width, goToLabel, id })}
          ${hidePoweredByGuidde ? '' : renderToString(<PoweredByGuidde />)}
      </div>
  `
}

type GenerateAndCopyStyledHTMLProps = {
    playbook: QuickGuiddeType
    content: string
    enableNgnixLinks?: boolean
    width?: PlaybookImageWidthType
    hidePoweredByGuidde?: boolean
}

export const generateAndCopyStyledHTML = ({
    playbook,
    content,
    enableNgnixLinks,
    width,
    hidePoweredByGuidde
}: GenerateAndCopyStyledHTMLProps) => {
    const html = generateHTML({ playbook, content, enableNgnixLinks, width, hidePoweredByGuidde })

    const container = document.createElement('div')
    container.innerHTML = html
    container.style.position = 'fixed'
    container.style.pointerEvents = 'none'
    container.style.opacity = '0'
    document.body.appendChild(container)

    window?.getSelection()?.removeAllRanges()
    const range = document.createRange()
    range.selectNode(container)
    window?.getSelection()?.addRange(range)
    document.execCommand('copy')
    document.body.removeChild(container)
}
