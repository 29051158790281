import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { privateDomains } from 'modules'

export const useAuth = () => {
    const { roles, su, user, token, newAuth, isLoading } = useSelector(state => state.appAuth) || {}

    return useMemo(
        () => ({
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
            email: user.email,
            orgId: (roles?.s && su ? su : roles?.o) as string,
            su,
            token,
            roles,
            newAuth,
            isLoading,
            isNotLogged: Boolean(!user.email || !roles),
            isPrivateAccount: privateDomains.includes((user.email.split('@')[1] || '').trim())
        }),
        [
            user.uid,
            user.displayName,
            user.photoURL,
            user.email,
            su,
            token,
            roles,
            isLoading,
            newAuth
        ]
    )
}
