import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import type Konva from 'konva'
import { Circle } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { type ImageCircleType } from 'app/types'
import { setLayerTransform } from 'ducks'
import { round } from 'modules'
import useImage from 'use-image'

type Props = {
    layer: ImageCircleType
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const ImageCircleShape = ({
    layer,
    onDragStart,
    onDragMove,
    onDragEnd,
    onSelect
}: Props) => {
    const dispatch = useDispatch()

    const shapeRef = useRef<Konva.Circle>(null)

    const [circleProps, setCircleProps] = useState(layer)
    const [image] = useImage(circleProps.url, 'anonymous')
    const patternScale = Math.max(
        (circleProps.radius * 2) / (image?.width || 1),
        (circleProps.radius * 2) / (image?.height || 1)
    )

    useEffect(() => {
        setCircleProps(layer)
    }, [layer])

    return (
        <Circle
            ref={shapeRef}
            name="imageCircle"
            className="alignable"
            id={circleProps.id}
            x={circleProps.x}
            y={circleProps.y}
            radius={circleProps.radius}
            scaleX={circleProps.scaleX}
            scaleY={circleProps.scaleY}
            fillPatternX={-circleProps.radius}
            fillPatternY={-circleProps.radius}
            fillPatternScaleX={patternScale}
            fillPatternScaleY={patternScale}
            fillPatternImage={image}
            fillPatternRepeat="no-repeat"
            onMouseOver={() => (document.body.style.cursor = 'move')}
            onMouseLeave={() => (document.body.style.cursor = '')}
            draggable={true}
            onDragStart={onDragStart}
            onDragMove={e => {
                setCircleProps(prevState => ({
                    ...prevState,
                    x: e.target.attrs.x,
                    y: e.target.attrs.y
                }))
                onDragMove(e)
            }}
            onDragEnd={onDragEnd}
            onClick={e => onSelect(e.evt.shiftKey)}
            onTransform={({ target }) => {
                setCircleProps(prevState => ({
                    ...prevState,
                    x: target.x(),
                    y: target.y(),
                    scaleX: target.scaleX(),
                    scaleY: target.scaleY()
                }))
            }}
            onTransformEnd={({ target }) => {
                dispatch(
                    setLayerTransform({
                        layerId: layer.id,
                        x: round(target.x()),
                        y: round(target.y()),
                        scaleX: target.scaleX(),
                        scaleY: target.scaleY()
                    })
                )
            }}
        />
    )
}
