import {
    type CSSProperties,
    type ReactNode,
    type ReactEventHandler,
    type MutableRefObject,
    useEffect,
    useRef,
    memo
} from 'react'

import { type SxProps, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'
import MusicImage from 'assets/images/my_sound.png'

import { SpacedGroup } from 'UI/Components'

import { type AudioBackgroundType } from 'app/types'

import { useBoolean } from 'hooks'

type Props = AudioBackgroundType & {
    isActive?: boolean
    onClick?: () => void
    onRemove?: () => void
    titleColor?: CSSProperties['color']
    disabled?: boolean
    playingId?: string
    img?: string
    secondaryText?: string
    size: number
    widthBorder?: boolean
    maxHeight: number
    setPlayingId: (id: string) => void
    cardSx?: SxProps
    playingStatus?: ReactNode
    onTimeUpdate?: ReactEventHandler<HTMLAudioElement>
    externalAudioRef?: MutableRefObject<HTMLAudioElement | null>
    onEnded?: () => void
    onPlaySample?: () => void
}

export const AudioItem = memo(
    ({
        isActive = false,
        img,
        titleColor = 'rgba(9, 12, 16, 0.6)',
        widthBorder = true,
        name,
        url,
        id,
        description,
        secondaryText = 'Sample',
        maxHeight,
        size,
        disabled,
        playingId,
        setPlayingId,
        onClick = () => {},
        onRemove,
        cardSx,
        playingStatus,
        onTimeUpdate,
        externalAudioRef,
        onEnded,
        onPlaySample
    }: Props) => {
        const internalAudioRef = useRef<HTMLAudioElement | null>(null)
        const audioRef = externalAudioRef ?? internalAudioRef

        const playing = useBoolean()

        const Icon = playing.isFalse ? PlayIcon : PauseIcon

        useEffect(() => {
            if (playingId === id) return

            audioRef.current?.pause()
        }, [playingId, id, audioRef])

        return (
            <Box position="relative">
                {disabled && (
                    <Box
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        zIndex={2}
                        bgcolor="#090C10"
                        style={{
                            opacity: 0.05
                        }}
                    />
                )}
                <Stack
                    sx={{
                        transition: 'background 0.3s ease',
                        border: widthBorder
                            ? `1px solid ${isActive ? '#CB0000' : 'rgba(9, 12, 16, 0.08)'}`
                            : 'none',
                        background: isActive ? '#F6E6E5' : '#FFF',
                        cursor: disabled ? 'auto' : 'pointer',
                        ...cardSx
                    }}
                    p={1}
                    position="relative"
                    borderRadius={0.5}
                    width="100%"
                    height="100%"
                    direction="row"
                    spacing={2}
                    maxHeight={maxHeight}
                    onClick={disabled ? undefined : onClick}
                    alignItems="center"
                >
                    <img
                        src={img || MusicImage}
                        style={{ objectFit: 'cover', width: size, minWidth: size, height: size }}
                    />

                    <Box>
                        {onRemove && (
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 2
                                }}
                                onClick={e => {
                                    e.preventDefault()
                                    onRemove()
                                }}
                                size="small"
                            >
                                <BinIcon />
                            </IconButton>
                        )}
                        <Box>
                            <TextOverflow lineClamp={1}>
                                <Typography
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 700,
                                        color: titleColor,
                                        lineHeight: '20px',
                                        paddingRight: onRemove ? 20 : 0
                                    }}
                                >
                                    {name}
                                </Typography>
                            </TextOverflow>

                            {description && (
                                <Box
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        wordBreak: 'break-word',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    <Tooltip title={description} disableInteractive>
                                        <Typography
                                            style={{
                                                fontStyle: 'italic',
                                                lineHeight: '16px',
                                                color: 'rgba(9, 12, 16, 0.4)'
                                            }}
                                            component="p"
                                            color="textSecondary"
                                            variant="caption"
                                        >
                                            {description}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                        <SpacedGroup mt={0.5} spacing={1}>
                            <IconButton
                                style={{
                                    background: isActive ? '#FFF' : '#F5F5F5',
                                    height: 24,
                                    width: 24
                                }}
                                size="small"
                                disableRipple
                                onClick={e => {
                                    e.stopPropagation()

                                    if (playing.isTrue) {
                                        setPlayingId('')
                                        audioRef.current?.pause()
                                    } else {
                                        setPlayingId(id)
                                        audioRef.current?.play()

                                        onPlaySample?.()
                                    }
                                }}
                            >
                                <Icon style={{ height: 18, width: 18, color: '#000000' }} />
                            </IconButton>
                            {playing.isTrue && playingStatus ? (
                                playingStatus
                            ) : (
                                <TextOverflow lineClamp={1}>
                                    <Typography
                                        style={{
                                            fontStyle: 'italic',
                                            paddingRight: 4
                                        }}
                                        color="textSecondary"
                                        fontSize={14}
                                        lineHeight="20px"
                                        letterSpacing={0.25}
                                    >
                                        {secondaryText}
                                    </Typography>
                                </TextOverflow>
                            )}
                        </SpacedGroup>
                    </Box>
                </Stack>

                <audio
                    style={{ display: 'none' }}
                    ref={audioRef}
                    src={url}
                    controls
                    onPlay={playing.setTrue}
                    onPause={playing.setFalse}
                    onEnded={() => {
                        setPlayingId('')
                        playing.setFalse()
                        onEnded?.()
                    }}
                    onTimeUpdate={onTimeUpdate}
                />
            </Box>
        )
    }
)
