import { type ReactNode } from 'react'

import { Button, IconButton } from '@mui/material'
import PersonIcon from '@mui/icons-material/PersonOutline'

import { type UtmLinkProps, SpacedGroup, UtmLink } from 'UI/Components'

import { type AnyPlaybookType } from 'app/types'
import { paths } from 'app/paths'

import { useWindowView } from 'hooks'

const buttonStyles = {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25
} as const

type Props = {
    playbook: AnyPlaybookType
}

export const PublicUtmButtons = ({ playbook }: Props) => {
    const { isMobileView } = useWindowView()

    return (
        <SpacedGroup spacing={isMobileView ? 1 : 2}>
            <RedirectButtonWrapper playbook={playbook} utm_campaign="sign-in" path={paths.login}>
                {isMobileView ? (
                    <IconButton size="small">
                        <PersonIcon style={{ color: 'rgba(9, 12, 16, 0.60)' }} />
                    </IconButton>
                ) : (
                    <Button
                        size="small"
                        variant="text"
                        style={{
                            whiteSpace: 'nowrap',
                            ...buttonStyles,
                            color: 'rgba(9, 12, 16, 0.60)'
                        }}
                        startIcon={<PersonIcon color="inherit" />}
                    >
                        SIGN IN
                    </Button>
                )}
            </RedirectButtonWrapper>

            <RedirectButtonWrapper playbook={playbook} utm_campaign="sign-up">
                <Button size="small" variant="contained" style={{ width: 120, ...buttonStyles }}>
                    GET GUIDDE
                </Button>
            </RedirectButtonWrapper>
        </SpacedGroup>
    )
}

type RedirectButtonWrapperProps = {
    playbook: AnyPlaybookType
    utm_campaign: string
    children: ReactNode
    path?: UtmLinkProps['path']
}

const RedirectButtonWrapper = ({
    playbook,
    utm_campaign,
    children,
    path
}: RedirectButtonWrapperProps) => (
    <UtmLink
        playbook={playbook}
        params={{
            utm_campaign,
            utm_medium: 'public-header'
        }}
        path={path}
    >
        {children}
    </UtmLink>
)
