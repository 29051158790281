import { type Dispatch, type SetStateAction } from 'react'

import { styled, Box, Typography, Tooltip } from '@mui/material'

import clsx from 'clsx'

import { getDateUntilNow } from 'modules'

const PREFIX = 'AnalyticsFilter'

const classes = {
    dateFilter: `${PREFIX}-dateFilter`,
    activeFilter: `${PREFIX}-activeFilter`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.dateFilter}`]: {
        cursor: 'pointer',
        '&:first-child': {
            marginRight: theme.spacing(2)
        }
    },
    [`& .${classes.activeFilter}`]: {
        fontWeight: 'bold',
        borderBottom: `2px solid ${theme.palette.common.black}`
    }
}))

type Props = {
    filter: 7 | 30
    onFilterChange: Dispatch<SetStateAction<7 | 30>>
}

export const AnalyticsFilter = ({ filter, onFilterChange }: Props) => (
    <StyledBox display="flex" justifyContent="flex-end" mb={2}>
        <Tooltip title={getDateUntilNow(30)}>
            <Typography
                display="inline"
                onClick={() => onFilterChange(30)}
                className={clsx(classes.dateFilter, {
                    [classes.activeFilter]: filter === 30
                })}
            >
                Last 30 days
            </Typography>
        </Tooltip>
    </StyledBox>
)
