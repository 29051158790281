import { type ReactNode, type CSSProperties, useState, useLayoutEffect, useRef } from 'react'

type Props = {
    children: ReactNode
    deps?: Array<unknown>
    additional?: number
    overflow?: CSSProperties['overflow']
}

// use this element if you want to set fixed element height
// from start component position to the bottom of visible screen size
export const FixedVisibleHeight = ({
    children,
    deps = [],
    additional = 0,
    overflow = 'auto'
}: Props) => {
    const [height, setHeight] = useState<'none' | number>('none')
    const ref = useRef<HTMLDivElement | null>(null)

    const windowHeight = window.innerHeight

    useLayoutEffect(() => {
        if (!ref.current) return

        const height = windowHeight - ref.current.offsetTop - additional

        setHeight(height)
    }, [windowHeight, additional, deps])

    return (
        <div
            ref={ref}
            style={{
                height,
                overflow
            }}
        >
            {children}
        </div>
    )
}
