import { useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor/use-layers'
import { QG_BROWSER_BAR_HEIGHT } from 'UI/Routes/quick-guidde/CanvasEditor/Workspace'
import { TrimDialog } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel/EditVideo/TrimDialog'
import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'

import { AddStepMenuItem } from './AddStepMenuItem'
import { generateVideoStep, getVideoMetadata } from './VideoStep'

import { ReactComponent as VideoStepFromRecordingIcon } from 'assets/icons/video-step-from-recording.svg'

import {
    type BrowserBarShapeType,
    type QuickGuiddeType,
    type RectangleShapeType,
    Shape
} from 'app/types'

import { addNewStep } from 'ducks'
import { logToAnalytics, request, round, uuid } from 'modules'
import { noBrandKitBackgroundId, useBoolean, useBrandKit } from 'hooks'

type Props = {
    language: QuickGuiddeType['language']
    sourceCaptureVideo: Exclude<QuickGuiddeType['sourceCaptureVideo'], undefined>
    onDone: () => void
}

export const VideoRecordingStep = ({ sourceCaptureVideo, language, onDone }: Props) => {
    const dispatch = useDispatch()

    const open = useBoolean()
    const disable = useBoolean()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { extractVideoStep } = useContext(VoiceOverContext)

    const { backgroundRectangle } = useLayers()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const {
        id,
        layers,
        kind,
        windowDimensions,
        eventTimeMarkers,
        relativeRecordingTime: activeStepStart
    } = steps[activeStep]
    const { relativeRecordingTime: nextStepStart } = steps[activeStep + 1] || {}

    const videoTimeFromRecording = useMemo(() => {
        const defaultStart = eventTimeMarkers?.start || 0
        const defaultEnd = eventTimeMarkers?.end || sourceCaptureVideo.duration

        if (typeof activeStepStart !== 'number' || typeof nextStepStart !== 'number') {
            return { start: defaultStart, end: defaultEnd }
        }

        const start = round(activeStepStart / 1000)
        const end = round(nextStepStart / 1000)

        if (end <= start) return { start, end: defaultEnd }

        return { start, end }
    }, [
        activeStepStart,
        eventTimeMarkers?.end,
        eventTimeMarkers?.start,
        nextStepStart,
        sourceCaptureVideo
    ])

    const { brandKitBackground } = useBrandKit()

    const bgRectLayer = layers?.find(
        layer => layer.type === Shape.Rectangle && layer.isBackground
    ) as RectangleShapeType | undefined

    const scaleFactor = useMemo(() => {
        if (bgRectLayer && bgRectLayer.scaleFactor) return bgRectLayer.scaleFactor

        const noBackground = brandKitBackground?.id === noBrandKitBackgroundId
        if (!brandKitBackground || noBackground) return 1

        const hasScaleFactor = 'scaleFactor' in brandKitBackground
        return hasScaleFactor ? brandKitBackground.scaleFactor : 0.8
    }, [bgRectLayer, brandKitBackground])

    if (!sourceCaptureVideo) return null

    return (
        <>
            <AddStepMenuItem
                title="Insert captured clip"
                Icon={VideoStepFromRecordingIcon}
                disabled={disable.isTrue}
                onClick={open.setTrue}
            />

            {open.isTrue && (
                <TrimDialog
                    videoInfo={{
                        url: sourceCaptureVideo.url,
                        duration: sourceCaptureVideo.duration,
                        thumbnail: sourceCaptureVideo.videoThumbnailPreview
                    }}
                    initialStart={videoTimeFromRecording.start}
                    initialEnd={videoTimeFromRecording.end}
                    loading={disable.isTrue}
                    onClose={open.setFalse}
                    onConfirm={async (start, end) => {
                        disable.setTrue()

                        logToAnalytics('addVideoStepFromRecording_clicked', { playbookId })

                        const { width, height, x, y, scale } = await getVideoMetadata(
                            sourceCaptureVideo.url,
                            windowDimensions,
                            scaleFactor
                        )

                        const browserLayer: BrowserBarShapeType | undefined = await new Promise(
                            resolve => {
                                if (scaleFactor === 1) return resolve(undefined)

                                request('/c/v1/quickguidde/browserBar', 'POST', {
                                    width: width * scale
                                })
                                    .then(defaultLayer => ({
                                        ...defaultLayer,
                                        x,
                                        y: y - QG_BROWSER_BAR_HEIGHT / 2
                                    }))
                                    .then(resolve)
                            }
                        )

                        dispatch(
                            addNewStep({
                                kind: 'step',
                                position: ['end', 'outro'].includes(kind) ? 'before' : 'after',
                                step: generateVideoStep({
                                    windowDimensions,
                                    width,
                                    height,
                                    x,
                                    y: y + (browserLayer ? QG_BROWSER_BAR_HEIGHT / 2 : 0),
                                    scale,
                                    stepId: uuid(),
                                    fileName: 'Overview',
                                    duration: end - start,
                                    sourceDuration: sourceCaptureVideo.duration,
                                    fileUrl: sourceCaptureVideo.url,
                                    sourceVideoThumbnailPreview:
                                        sourceCaptureVideo.videoThumbnailPreview,
                                    start,
                                    end,
                                    ...(scaleFactor !== 1 && {
                                        backgroundLayer: backgroundRectangle,
                                        browserLayer
                                    })
                                })
                            })
                        )

                        extractVideoStep({
                            playbookId,
                            start,
                            end,
                            languageCode: language?.langCode || 'en-US',
                            languageName: language?.langName || 'English',
                            stepId: id,
                            videoUrl: sourceCaptureVideo.url
                        })

                        disable.setFalse()
                        open.setFalse()
                        onDone()
                    }}
                />
            )}
        </>
    )
}
