import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router-dom'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Dialog,
    DialogActions,
    Divider,
    FormControlLabel,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    Paper,
    Typography,
    styled,
    CircularProgress,
    Popover
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { ReactComponent as PositionBottomLeft } from 'assets/icons/position_bottom_left.svg'
import { ReactComponent as PositionBottomRight } from 'assets/icons/position_bottom_right.svg'
import { ReactComponent as PositionTopLeft } from 'assets/icons/position_top_left.svg'
import { ReactComponent as PositionTopRight } from 'assets/icons/position_top_right.svg'

import { ReactComponent as NoLogo } from 'assets/icons/no_logo.svg'
import { ReactComponent as BinGray } from 'assets/icons/bin_gray.svg'
import { ReactComponent as Replay } from 'assets/icons/replay.svg'

import {
    CallToAction,
    ConnectedAutocomplete,
    ConnectedFilledInput,
    ConnectedInput,
    ConnectedRadioGroup,
    ConnectedSelect,
    IOSSwitch,
    PlaybookSearch,
    SpacedGroup
} from 'UI/Components'

import { useAuth, useBoolean, useBrandKit, useQuery } from 'hooks'
import {
    getFirebaseUrl,
    host,
    isDeepEqual,
    isValidUrl,
    logToAnalytics,
    option,
    uploadWithSignedUrl,
    uuid
} from 'modules'
import {
    type AnyPlaybookType,
    type MultipleCtaType,
    type QuickGuiddeType,
    type SearchPlaybookType,
    type SingleCtaType,
    PlaybookMode
} from 'app/types'
import {
    setCurrentCTA,
    setQuickGuiddeStepCTA,
    setStepCtaVisibility,
    setStepMultiApplyVisibility
} from 'ducks'

import { SingleCTALibrary } from './SingleCTALibrary'
import { SingleCTAImageCard } from './SingleCTAImageCard'

import { firebaseConfig } from 'env'
import { DEFAULT_TEXT } from '../MultipleCTA'
import { paths } from 'app/paths'

const uploadImage = async (fileUrl: string, storageUrl: string): Promise<string> => {
    const response = await fetch(fileUrl)
    const blob = await response.blob()

    const bucket = `gs://${firebaseConfig.projectId}-temporary`

    await uploadWithSignedUrl(bucket + '/' + storageUrl, blob)
    return getFirebaseUrl(storageUrl, true)
}

const textOptions = [
    option('Learn more', 'Learn more'),
    option('Open in App', 'Open in App'),
    option('Book a Demo', 'Book a Demo'),
    option('Sign up', 'Sign up'),
    option('Schedule a meeting', 'Schedule a meeting')
]

const icons = new Map()
icons.set('bottomLeft', <PositionBottomLeft />)
icons.set('bottomRight', <PositionBottomRight />)
icons.set('topRight', <PositionTopRight />)
icons.set('topLeft', <PositionTopLeft />)

const positionOptions = [
    option('bottomLeft', 'Lower left'),
    option('bottomRight', 'Lower Right'),
    option('topRight', 'Upper Right'),
    option('topLeft', 'Upper Left')
] as Array<{ label: string; value: SingleCtaType['action']['position'] }>

const linkOptions = [
    option('goToStep', 'Go to step'),
    option('guiddeLink', 'Go to video'),
    option('externalLink', 'External link (opens in a new tab)')
]

const GuiddeListItem = styled(ListItem)({
    '&:hover': {
        backgroundColor: '#F5F5F5'
    }
})

const defaultInitialValues = {
    enabled: true,
    text: textOptions[0],
    start: 0,
    end: null,
    link: '',
    guiddeOrExternalLink: '',
    image: '',
    position: positionOptions[1].value
} as const

const itemTitleStyle = {
    fontWeight: 600,
    fontSize: 16
}

type Props = {
    quickGuidde: QuickGuiddeType
}

const URL_S_PREFIX = 'https://www.'

const addUrlPrefix = (url: string, prefix: string) => {
    if (url.toLowerCase().indexOf(prefix) === -1) {
        return `${prefix}${url}`
    }
    return url
}

export const buildCTAUrl = (cta: SingleCtaType['action'], playbookId: string) => {
    const generatedPath = generatePath(paths.playbookDetails, {
        playbookId
    })

    const isLink = cta.linkType === 'externalLink' || cta.linkType === 'guiddeLink'

    const playbookUrl = `${host}${generatedPath}`

    //  For external links use the cta link, for step link use the playbook url + step link
    return isLink ? getRealCtaLink(cta) : `${playbookUrl}#${cta.link}`
}

export const getRealCtaLink = (cta: SingleCtaType['action']) => {
    const { linkType } = cta

    if (linkType === 'guiddeLink') return getGuiddeCtaLink(cta)

    return !cta.link.startsWith('http://') && !cta.link.startsWith('https://')
        ? `https://${cta.link}`
        : cta.link
}

export const getGuiddeCtaLink = (cta: SingleCtaType['action']) => {
    const { link, mode } = cta

    const playbookPath = generatePath(paths.playbookDetails, {
        playbookId: link
    })
    const playlistPath = generatePath(paths.playlistDetails, {
        playbookId: link
    })

    const url = mode === PlaybookMode.Playlist ? playlistPath : playbookPath
    return `${host}${url}`
}

export const fakeCtaPlaybook = (): SearchPlaybookType => {
    return {
        _snippetResult: {
            transcript: {
                value: '',
                matchLevel: 'none'
            },
            title: {
                value: '',
                matchLevel: 'full'
            },
            tags: []
        },
        visibility: 'Public',
        isPlaylist: false,
        applications: [],
        title: '',
        creator_name: '',
        id: '',
        img: '',
        sections: [],
        tags: [],
        nbHits: 0,
        duration: 58.934,
        mode: 'playbook'
    }
}

export type ImageType = 'custom' | 'exiting' | null

export const SingleCTA = memo(({ quickGuidde }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const [imageType, setImageType] = useState<ImageType>(null)

    const imageLoaded = useBoolean()
    const imageLoadedSet = imageLoaded.set

    const { slicingSuggestion } = quickGuidde

    const dispatch = useDispatch()

    const { brandKitData } = useBrandKit()

    const { currentCta, singleCtaVisibility, action, isSingleCTA, editItemId } = useSelector(
        state => state.qgCta
    )

    const [goToVideo, setGoToVideo] = useState<SearchPlaybookType | null>(fakeCtaPlaybook())

    const isCtaLibraryOpen = useBoolean()
    const customImageUploading = useBoolean()
    const submitImageUploading = useBoolean()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { id: activeStepId } = steps[activeStep]

    const params = useParams<{ playbookId: string }>()

    const { uid } = useAuth()

    const stepsOptions = useMemo(() => {
        return steps.map((step, idx) => {
            let title = ''
            if (step.kind === 'intro') title = 'Intro'
            if (step.kind === 'cover') title = 'Cover'
            if (step.kind === 'end') title = 'Ending'
            if (step.kind === 'outro') title = 'Outro'
            if (step.kind === 'step') {
                const newTitle =
                    step.title.length > 70 ? `${step.title.substring(0, 70)}...` : step.title
                const prefix = idx.toString().padStart(2, '0')

                title = step.title ? `${prefix} ${newTitle}` : `${prefix} Step #${idx}`
            }

            return option(step.id, title)
        })
    }, [steps])

    const root = action || defaultInitialValues

    const headerLabel = useMemo(() => {
        let label = `action into step #${activeStep}`
        if (steps[activeStep].kind === 'cover') {
            label = 'action into cover step'
        } else if (steps[activeStep].kind === 'end') {
            label = 'action into ending step'
        }
        return label
    }, [activeStep, steps])

    /*
            By default, Formik will only set the initial value of a field if it matches one of the options exactly.
            If the initial value doesn't match any option in the list, Formik will treat it as an empty value
            If the user entered a manual value we need to add it to the list of options in order to see it selected
        */
    const getTextOptions = useMemo(() => {
        if (!action) return textOptions[0]
        if (action.text === DEFAULT_TEXT) return textOptions[0]

        // look for the text in the list of options
        const temp = textOptions.find(it =>
            action && 'text' in action ? it.value === action.text : false
        )

        // value was not found in the list of options, add it manually
        if (!temp && action?.text) {
            textOptions.push({
                value: action?.text || '',
                label: action?.text || ''
            })
            return option(action.text, action.text)
        }

        return temp
    }, [action])

    const getGoToStepOption = useMemo(() => {
        const link = action?.link || ''

        const temp = stepsOptions.find(it =>
            action && 'link' in action ? it.value === link : false
        )
        return temp?.value || stepsOptions[0].value
    }, [action, stepsOptions])

    const defaultLogo = useMemo(() => {
        if (Boolean(root.image)) return root.image

        const activeLogoId = brandKitData?.logoAndFavicon.active
        return brandKitData?.logoAndFavicon.data.find(it => it.id === activeLogoId)?.url
    }, [brandKitData?.logoAndFavicon.active, brandKitData?.logoAndFavicon.data, root.image])

    const validateExternalLink = (value: string, prefix: string) => {
        if (!value) return true

        const transformedValue = addUrlPrefix(value, prefix)
        return isValidUrl(transformedValue)
    }

    const ctaOpacity = useMemo(() => {
        if (!goToVideo) return 0

        return imageLoaded.isTrue ? 1 : 0
    }, [goToVideo, imageLoaded.isTrue])

    const close = () => {
        setGoToVideo(fakeCtaPlaybook())
        dispatch(setStepCtaVisibility(false))
    }

    const $fullPlaybook = useQuery<AnyPlaybookType>(
        action?.link && action.linkType === 'guiddeLink'
            ? `/c/v1/quickguidde?id=${action?.link}`
            : '',
        {
            method: 'GET'
        },
        {
            revalidateOnMount: false,
            revalidateOnFocus: false
        }
    )

    const { data, isValidating } = $fullPlaybook

    const storagePath = useMemo(
        () => `imageUploads/${uid}/${params.playbookId}/cta/${activeStepId}/${uuid()}`,
        [activeStepId, params.playbookId, uid]
    )

    useEffect(() => {
        if (
            singleCtaVisibility &&
            action?.link &&
            action.linkType === 'guiddeLink' &&
            !$fullPlaybook.data &&
            !$fullPlaybook.isValidating
        ) {
            $fullPlaybook.mutate()
        }
    }, [singleCtaVisibility, $fullPlaybook, action?.link, action?.linkType])

    useEffect(() => {
        if (!data || !singleCtaVisibility) return
        const { id, mode, title, uploadedBy, screenshotUrl } = data
        imageLoadedSet(false)
        const searchPb = {
            ...fakeCtaPlaybook(),
            id,
            mode,
            title,
            img: screenshotUrl,
            creator_name: uploadedBy.displayName
        }

        setGoToVideo(searchPb)
    }, [data, singleCtaVisibility, imageLoadedSet])

    if (!singleCtaVisibility) return null

    return (
        <Dialog
            maxWidth="sm"
            open={true}
            style={{ width: '100%' }}
            fullWidth
            onClose={(e: Event) => {
                e.stopPropagation()
                close()
            }}
        >
            <Box pt={2.5}>
                <Formik
                    onSubmit={async (values, isValid) => {
                        const {
                            linkOption,
                            goToStepLink,
                            externalLink,
                            guiddeLink,
                            text,
                            multiCtaDescription,
                            ...updatedValues
                        } = values

                        let uploadedUrl = values.image
                        // upload image on submit when the user selected an image and did not upload a new one

                        const isOriginalImage = (action ? root.image : defaultLogo) === values.image

                        // upload if an images exists,
                        // it is not custom which means it was already uploaded and
                        // it was changed
                        if (values.image && imageType !== 'custom' && !isOriginalImage) {
                            submitImageUploading.setTrue()
                            uploadedUrl = await uploadImage(values.image, storagePath)
                            submitImageUploading.setFalse()
                        }

                        if (isValid) close()

                        let selectedText = ''
                        if (isSingleCTA) {
                            selectedText = (typeof text === 'string' ? text : text?.value) || ''
                        } else {
                            selectedText = multiCtaDescription || ''
                        }

                        let link = ''
                        if (linkOption === 'externalLink') link = externalLink
                        if (linkOption === 'guiddeLink' && goToVideo) link = goToVideo.id
                        if (linkOption === 'goToStep') link = goToStepLink

                        const stepIndex = steps?.findIndex(step => step.id === link)

                        if (!isSingleCTA) {
                            let isItemFound = false
                            let newActions: MultipleCtaType['actions'] = []

                            const { actions, title } = (currentCta as MultipleCtaType) || []

                            // loop exiting item and search is a specific item should be updated
                            if (actions) {
                                newActions = actions.map((item, index) => {
                                    if (index === editItemId) {
                                        isItemFound = true
                                        return {
                                            enabled: updatedValues.enabled,
                                            text: selectedText,
                                            link,
                                            linkType: values.linkOption,
                                            image: uploadedUrl,
                                            start: slicingSuggestion?.[stepIndex]?.start || 0,
                                            mode: goToVideo?.mode ? goToVideo.mode : 'playbook'
                                        }
                                    }
                                    return item
                                })
                            }

                            // I did not update an exiting item
                            if (!isItemFound && singleCtaVisibility) {
                                newActions.push({
                                    enabled: updatedValues.enabled,
                                    text: selectedText,
                                    link,
                                    linkType: values.linkOption,
                                    image: uploadedUrl,
                                    start: slicingSuggestion?.[stepIndex]?.start || 0,
                                    mode: goToVideo?.mode ? goToVideo.mode : 'playbook'
                                })
                            }

                            const newMultipleCta: MultipleCtaType = {
                                ctaType: 'multiple',
                                title,
                                actions: newActions
                            }

                            // in case of a multiple cta change the visibility of the apply button
                            dispatch(
                                setStepMultiApplyVisibility(
                                    !isDeepEqual(currentCta, newMultipleCta)
                                )
                            )

                            // get the updated item (newActions[editItemId]) or the last new added item
                            logToAnalytics('add-cta', {
                                ctaType: 'multiple',
                                step: activeStep,
                                ...(isItemFound ? newActions[editItemId] : newActions.at(-1))
                            })

                            dispatch(setCurrentCTA(newMultipleCta))
                            return
                        }

                        const newAction = {
                            ...updatedValues,
                            text: selectedText,
                            link,
                            linkType: values.linkOption,
                            position: values.position.value,
                            image: uploadedUrl,
                            start: slicingSuggestion?.[stepIndex]?.start || 0,
                            mode: goToVideo?.mode ? goToVideo.mode : 'playbook'
                        }

                        const newCta: SingleCtaType = {
                            ctaType: 'single',
                            action: newAction
                        }

                        dispatch(setQuickGuiddeStepCTA(newCta))

                        logToAnalytics('add-cta', {
                            ctaType: 'single',
                            step: activeStep,
                            ...newAction
                        })
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        ...root,
                        image: action ? root.image : !isSingleCTA ? '' : defaultLogo,
                        position:
                            positionOptions.find(it =>
                                action && 'position' in action
                                    ? it.value === action?.position
                                    : false
                            ) || positionOptions[1],
                        text: getTextOptions,
                        multiCtaDescription:
                            action?.text === DEFAULT_TEXT ? 'Getting started' : action?.text || '',
                        linkOption: action?.linkType ?? 'goToStep',
                        goToStepLink: getGoToStepOption,
                        externalLink: action?.linkType === 'externalLink' ? action?.link || '' : '',
                        guiddeLink: action?.linkType === 'guiddeLink' ? action?.link || '' : '',
                        isSingleCTA
                    }}
                    validateOnMount={true} // This will trigger validation when the form is mounted.
                    validateOnChange={true} // This will trigger validation on each change of form values.
                    validationSchema={yup.object().shape({
                        isSingleCTA: yup.boolean(),
                        multiCtaDescription: yup.string().when('isSingleCTA', {
                            is: false,
                            then: yup.string().required('Field is required')
                        }),
                        externalLink: yup.string().when('linkOption', {
                            is: 'externalLink',
                            then: yup
                                .string()
                                .test('custom-validation-1', 'Link is invalid', value => {
                                    if (!value) return false
                                    return validateExternalLink(value, URL_S_PREFIX)
                                })
                        }),
                        text: yup.object().test((option, { createError }: any) => {
                            if (!isSingleCTA) return true

                            const empty = !option?.value

                            if (empty) {
                                return createError({
                                    message: 'Field is required',
                                    path: 'text'
                                })
                            }

                            return true
                        })
                    })}
                >
                    {({ setFieldValue, values, isValid }) => {
                        const isGuiddeLink = values.linkOption === 'guiddeLink'
                        const isGoToVideoValid = isGuiddeLink ? Boolean(goToVideo?.id) : true

                        const isFormValuesValid = isValid && isGoToVideoValid

                        return (
                            <Form>
                                <SpacedGroup px={3.5} flexDirection="column" pb={3} spacing={3}>
                                    <Box
                                        width="100%"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography style={itemTitleStyle}>
                                            {action ? 'Update' : 'Add'} {headerLabel}
                                        </Typography>

                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation()
                                                close()
                                            }}
                                            size="small"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>

                                    {!isSingleCTA && (
                                        <Box pb={2}>
                                            <ConnectedInput
                                                fullWidth
                                                name="multiCtaDescription"
                                                label="Description"
                                                placeholder=""
                                                inputProps={{ maxLength: isSingleCTA ? 30 : 21 }}
                                            />
                                            <Typography variant="caption" color="textSecondary">
                                                Description will appear on the action item
                                            </Typography>
                                        </Box>
                                    )}

                                    {isSingleCTA && (
                                        <Box data-test="cta-title-dropdown">
                                            <ConnectedAutocomplete
                                                disableCloseOnSelect={false}
                                                fullWidth={true}
                                                name="text"
                                                variant="filled"
                                                label="Description"
                                                freeSolo={true}
                                                multiple={false}
                                                disableClearable={true}
                                                options={textOptions}
                                                filterSelectedOptions={false}
                                            />
                                            <Typography variant="caption" color="textSecondary">
                                                Description will appear on the action item
                                            </Typography>
                                        </Box>
                                    )}

                                    <Box fontWeight="600" fontSize="16">
                                        Link
                                    </Box>

                                    <ConnectedRadioGroup
                                        options={linkOptions}
                                        spacing={3}
                                        checkboxSpacing={1}
                                        optionFontSize={14}
                                        optionFontWeight={700}
                                        name="linkOption"
                                    />

                                    {values?.linkOption === 'externalLink' && (
                                        <Box>
                                            <ConnectedFilledInput
                                                name="externalLink"
                                                label="Link"
                                                placeholder="google.com"
                                                InputProps={{
                                                    style: {
                                                        height: '56px'
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}

                                    {/*guidde link -> search playbook + view selection*/}
                                    {values?.linkOption === 'guiddeLink' && (
                                        <Box>
                                            <PlaybookSearch
                                                onResultClick={(_, pb) => setGoToVideo(pb)}
                                                compactResults={true}
                                                placeholder={'Search for a video'}
                                            />
                                            <Box minHeight={100} p={0} m={0}>
                                                {isValidating && <LinearProgress />}
                                                {goToVideo && (
                                                    <Box>
                                                        {goToVideo.title && (
                                                            <h4>
                                                                Selected{' '}
                                                                {goToVideo.isPlaylist
                                                                    ? 'playlist'
                                                                    : 'video'}
                                                            </h4>
                                                        )}
                                                        <SpacedGroup
                                                            style={{
                                                                opacity: ctaOpacity
                                                            }}
                                                        >
                                                            <img
                                                                src={goToVideo?.img}
                                                                width="60"
                                                                style={{ objectFit: 'contain' }}
                                                                onLoad={imageLoaded.setTrue}
                                                            />
                                                            <SpacedGroup
                                                                flexDirection="column"
                                                                spacing={0}
                                                            >
                                                                <Box
                                                                    fontSize={14}
                                                                    fontWeight={700}
                                                                    color="#090C10"
                                                                >
                                                                    <TextOverflow lineClamp={1}>
                                                                        {goToVideo.title}
                                                                    </TextOverflow>
                                                                </Box>
                                                                <Box
                                                                    fontSize={14}
                                                                    fontWeight={400}
                                                                    color="rgba(9, 12, 16, 0.6)"
                                                                >
                                                                    {goToVideo?.creator_name}
                                                                </Box>
                                                            </SpacedGroup>
                                                        </SpacedGroup>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    )}

                                    {/*step selection*/}
                                    {values?.linkOption === 'goToStep' && (
                                        <Box>
                                            <ConnectedSelect
                                                style={{
                                                    backgroundColor: '#F5F5F5'
                                                }}
                                                fullWidth
                                                variant="filled"
                                                name="goToStepLink"
                                                options={stepsOptions}
                                                inputLabel="Step"
                                            />
                                        </Box>
                                    )}

                                    {isSingleCTA && (
                                        <Box
                                            style={{ marginTop: '10px' }}
                                            id="single-cta-content-wrapper"
                                        >
                                            <Box fontWeight="600" fontSize="16" mt={3} mb={1}>
                                                Image
                                            </Box>

                                            {/*selected image + modify button*/}
                                            <SingleCTAImageCard
                                                onClick={isCtaLibraryOpen.setTrue}
                                                style={{ position: 'relative' }}
                                            >
                                                <Replay
                                                    style={{
                                                        position: 'absolute',
                                                        top: '2px',
                                                        left: '2px'
                                                    }}
                                                    title="click to edit"
                                                />
                                                {values.image ? (
                                                    <img
                                                        style={{ objectFit: 'contain' }}
                                                        src={values.image}
                                                        width={66}
                                                        height={18}
                                                    />
                                                ) : (
                                                    <Box alignContent="center" textAlign="center">
                                                        <NoLogo />
                                                        <br />
                                                        <Box
                                                            fontSize={12}
                                                            fontWeight={400}
                                                            p={0}
                                                            m={0}
                                                            mt={0.5}
                                                        >
                                                            No logo
                                                        </Box>
                                                    </Box>
                                                )}
                                            </SingleCTAImageCard>

                                            {/*Library*/}
                                            <Box mb={3}>
                                                <SingleCTALibrary
                                                    isOpen={isCtaLibraryOpen.isTrue}
                                                    onDone={(url, imageType) => {
                                                        setFieldValue('image', url)
                                                        setImageType(imageType)
                                                    }}
                                                    onClose={isCtaLibraryOpen.setFalse}
                                                    brandKitData={brandKitData}
                                                    activeStepId={activeStepId}
                                                    uid={uid}
                                                    playbookId={params.playbookId}
                                                />
                                            </Box>

                                            {/*CTA preview*/}
                                            <SpacedGroup alignItems="flex-start" mt={3}>
                                                <Box
                                                    width="320px"
                                                    height="107px"
                                                    style={{ background: '#EDEEEF' }}
                                                    position="relative"
                                                >
                                                    {(values.text || values.image) && (
                                                        <CallToAction
                                                            playbook={{} as AnyPlaybookType}
                                                            videoElement={null}
                                                            cta={{
                                                                enabled: values.enabled,
                                                                image: values.image,
                                                                linkType: 'externalLink',
                                                                link: '',
                                                                text:
                                                                    (typeof values.text === 'string'
                                                                        ? values.text
                                                                        : values.text?.value) || '',
                                                                position: values.position.value,
                                                                start: 0,
                                                                mode: 'playbook'
                                                            }}
                                                        />
                                                    )}
                                                </Box>

                                                {/*position drop down*/}
                                                <SpacedGroup>
                                                    <Box>
                                                        <Avatar
                                                            style={{ backgroundColor: '#F5F5F5' }}
                                                        >
                                                            {icons.get(values.position.value) ||
                                                                icons.get(positionOptions[1].value)}
                                                        </Avatar>
                                                    </Box>
                                                    <SpacedGroup
                                                        data-test="cta-position-dropdown"
                                                        flexDirection="column"
                                                        spacing={0}
                                                        onClick={({ currentTarget }) =>
                                                            setAnchorEl(currentTarget)
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                            display: 'inline-block'
                                                        }}
                                                    >
                                                        <SpacedGroup>
                                                            <Box
                                                                style={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 600
                                                                }}
                                                            >
                                                                Position
                                                            </Box>
                                                            <ArrowDropDownIcon
                                                                style={{ color: '#090C10' }}
                                                            />
                                                        </SpacedGroup>
                                                        <Box>
                                                            {values.position.label ||
                                                                positionOptions[1].label}
                                                        </Box>
                                                    </SpacedGroup>
                                                </SpacedGroup>

                                                <Popover
                                                    open={Boolean(anchorEl)}
                                                    anchorEl={anchorEl}
                                                    disablePortal
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center'
                                                    }}
                                                >
                                                    <Paper elevation={0}>
                                                        <ClickAwayListener
                                                            onClickAway={() => setAnchorEl(null)}
                                                        >
                                                            <List
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '8px'
                                                                }}
                                                                data-test="cta-position-dropdown"
                                                            >
                                                                {positionOptions.map(option => (
                                                                    <GuiddeListItem
                                                                        key={option.value}
                                                                        divider={true}
                                                                        sx={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            setFieldValue(
                                                                                'position',
                                                                                option
                                                                            )
                                                                            setAnchorEl(null)
                                                                        }}
                                                                    >
                                                                        <SpacedGroup>
                                                                            <Box mr={2}>
                                                                                {' '}
                                                                                {icons.get(
                                                                                    option.value
                                                                                )}
                                                                            </Box>
                                                                            <Box
                                                                                fontWeight="500"
                                                                                fontSize="14px"
                                                                            >
                                                                                {option.label}
                                                                            </Box>
                                                                        </SpacedGroup>
                                                                    </GuiddeListItem>
                                                                ))}
                                                            </List>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Popover>
                                            </SpacedGroup>
                                        </Box>
                                    )}
                                </SpacedGroup>

                                <Divider />

                                <DialogActions disableSpacing>
                                    <SpacedGroup
                                        px={2}
                                        py={1}
                                        justifyContent={`${isSingleCTA ? 'space-between' : 'flex-end'}`}
                                        spacing={4}
                                        width="100%"
                                    >
                                        {isSingleCTA && (
                                            <Button
                                                data-test="cta-delete-button"
                                                size="medium"
                                                color="inherit"
                                                variant="contained"
                                                type="button"
                                                startIcon={<BinGray />}
                                                onClick={() => {
                                                    if (isSingleCTA) {
                                                        logToAnalytics(
                                                            'insert_action_single_deleteBtn_clicked',
                                                            {
                                                                playbookId: quickGuidde.id,
                                                                type: 'single'
                                                            }
                                                        )
                                                    }

                                                    dispatch(setQuickGuiddeStepCTA())
                                                    close()
                                                }}
                                                sx={{
                                                    letterSpacing: 1.25,
                                                    color: 'rgba(9, 12, 16, 0.6)',
                                                    backgroundColor: 'rgb(237, 238, 239) !important'
                                                }}
                                            >
                                                DELETE
                                            </Button>
                                        )}

                                        <Box>
                                            {isSingleCTA && (
                                                <FormControlLabel
                                                    onChange={() => {
                                                        setFieldValue('enabled', !values.enabled)
                                                    }}
                                                    control={
                                                        <IOSSwitch
                                                            checked={values.enabled}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                letterSpacing: 1.25,
                                                                textTransform: 'uppercase',
                                                                color: 'rgba(9, 12, 16, 0.6)'
                                                            }}
                                                        >
                                                            {values.enabled
                                                                ? 'ENABLED'
                                                                : 'DISABLED'}
                                                        </Typography>
                                                    }
                                                />
                                            )}

                                            <Button
                                                data-test="cta-save-button"
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                disabled={
                                                    customImageUploading.isTrue ||
                                                    !isFormValuesValid
                                                }
                                                onClick={() => {
                                                    if (isSingleCTA) {
                                                        logToAnalytics(
                                                            'insert_action_single_applyBtn_clicked',
                                                            {
                                                                playbookId: quickGuidde.id,
                                                                type: 'single'
                                                            }
                                                        )
                                                        return
                                                    }
                                                    logToAnalytics(
                                                        'insert_action_multi_updateBtn_clicked',
                                                        {
                                                            playbookId: quickGuidde.id,
                                                            type: 'multiple'
                                                        }
                                                    )
                                                }}
                                                style={{ letterSpacing: 1.25 }}
                                            >
                                                <Box mr={submitImageUploading.isTrue ? 1 : 0}>
                                                    {isSingleCTA ? 'APPLY' : 'UPDATE'}{' '}
                                                </Box>
                                                {submitImageUploading.isTrue && (
                                                    <CircularProgress
                                                        style={{ color: 'white' }}
                                                        size={20}
                                                    />
                                                )}
                                            </Button>
                                        </Box>
                                    </SpacedGroup>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </Dialog>
    )
})
