import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { showPlanDialog } from 'ducks'

import { Box, Button, styled, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

const Container = styled(Box)({
    borderTop: '4px solid #FED243',
    padding: '16px',
    background: '#FEFAE4',
    maxWidth: '445px',
    marginBottom: '10px',
    textAlign: 'center',
    '& svg': {
        color: '#FED243'
    }
})

const StyledButton = styled(Button)({
    marginLeft: '8px',
    color: '#26c6da',
    background: 'transparent !important',
    padding: 0,
    lineHeight: 'normal'
})

type Props = {
    inline?: boolean
    onClick?: () => void
}

export const ExceededUsersNotification = ({ inline, onClick }: Props) => {
    const dispatch = useDispatch()

    const history = useHistory()

    return (
        <Container
            sx={{
                width: '100%',
                ...(inline && {
                    display: 'flex',
                    maxWidth: 'none',
                    justifyContent: 'center'
                })
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="center">
                <ErrorIcon />
                {!inline && (
                    <Typography
                        sx={{
                            fontSize: '20px',
                            marginLeft: '12px',
                            fontWeight: 500
                        }}
                    >
                        Users
                    </Typography>
                )}
            </Box>
            <Box px={1} fontSize="16px" lineHeight={1.5} width="100%">
                Your plan has reached its maximum capacity.{' '}
                <StyledButton
                    variant="text"
                    disableRipple
                    onClick={() => {
                        onClick?.()
                        const query = new URLSearchParams()

                        query.append('upgradePlanStep', '1')

                        history.replace({
                            search: `?${query.toString()}`
                        })
                        dispatch(showPlanDialog('updatePlanDialog'))
                    }}
                >
                    Upgrade now
                </StyledButton>
            </Box>
        </Container>
    )
}
