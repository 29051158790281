import { type MetricsByDate } from 'app/types/insights'
import { useOrgFeatureFlags } from 'hooks'

import { formatViewNumber } from 'UI/Components'

import { type ChartArea, InsightsAreaChart } from './InsightsAreaChart/InsightsAreaChart'

type Props = {
    isEmpty: boolean
    chartData: Array<MetricsByDate>
    chartHeight: number
    analyticsEventSource: string
    isSmall?: boolean
}

export const InsightsVideoEngagementChart = ({
    isEmpty,
    chartData,
    chartHeight,
    analyticsEventSource,
    isSmall
}: Props) => {
    const { hideInsightsLoadsAndWatchTime } = useOrgFeatureFlags()

    return (
        <InsightsAreaChart
            isEmpty={isEmpty}
            isSmall={isSmall}
            data={chartData}
            title="Video engagement"
            description={
                'Overall viewer engagement.\n Data collection for Video Loads started in mid-February.'
            }
            areas={
                [
                    hideInsightsLoadsAndWatchTime
                        ? undefined
                        : {
                              id: 'loads',
                              color: '#1E67BC',
                              label: 'Video Loads',
                              analyticsClickEventName:
                                  'insights_videoEngagement_videoLoadsBtn_clicked'
                          },
                    {
                        id: 'views',
                        color: '#1B9CD6',
                        label: 'Total Views',
                        analyticsClickEventName: 'insights_videoEngagement_totalViewsBtn_clicked'
                    },
                    {
                        id: 'viewers',
                        color: '#76D4FF',
                        label: 'Unique Viewers',
                        analyticsClickEventName: 'insights_videoEngagement_uniqueViewersBtn_clicked'
                    }
                ].filter(Boolean) as Array<ChartArea>
            }
            valueFormatter={formatViewNumber}
            height={chartHeight}
            analyticsChartHoverEventName="insights_videoEngagementChart_hover"
            analyticsDescriptionHoverEventName="insights_videoEngagementTitleTooltip_hover"
            analyticsEventSource={analyticsEventSource}
        />
    )
}
