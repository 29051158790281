import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { VoiceOverPanel } from './VoiceOverPanel'
import { MotionPanel } from './MotionPanel'
import { SubtitlesStylePanel } from './SubtitlesStylePanel'
import { CaptionsPanel } from './CaptionsPanel'

import { type QuickGuiddeType } from 'app/types'

type Props = {
    playbook: QuickGuiddeType
}

export const LeftPanel = ({ playbook }: Props) => {
    const { sidePanelMode } = useSelector(state => state.qgMetadata)

    return useMemo(() => {
        switch (sidePanelMode) {
            case 'motion':
                return <MotionPanel />
            case 'subtitles':
                return <SubtitlesStylePanel />
            case 'voiceOver':
                return <VoiceOverPanel playbook={playbook} />
            case 'captions':
                return <CaptionsPanel playbook={playbook} />
        }
    }, [sidePanelMode, playbook])
}
