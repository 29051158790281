import { Tooltip, Box, Avatar } from '@mui/material'

import { SpacedGroup } from 'UI/Components'

import { type UserOption } from './types'

type Props = {
    label: string
    items: Array<UserOption>
    limit: number
}

export const AvatarsList = ({ limit, label, items }: Props) => (
    <Box>
        <Box style={{ fontWeight: 600 }} pb={1}>
            {label}
        </Box>
        <SpacedGroup spacing={1}>
            {items.slice(0, limit)?.map(it => (
                <Box key={it.uid}>
                    <Tooltip
                        PopperProps={{
                            sx: theme => ({
                                zIndex: theme.zIndex.modal + 1
                            })
                        }}
                        title={it.displayName || it.email}
                    >
                        <Avatar src={it.logo} />
                    </Tooltip>
                </Box>
            ))}
            {items.length - limit > 0 && <b>+{items.length - limit}</b>}
        </SpacedGroup>
    </Box>
)
