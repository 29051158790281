import { IconButton } from '@mui/material'
import { Gif as GifIcon } from '@mui/icons-material'

import { type PlaybookType, type PlaylistType } from 'app/types'
import { useCopyLinkedGif } from 'hooks'
import { isQG, logToAnalytics, playbookToAnalyticsProps } from 'modules'

type Props = {
    playbook: PlaybookType | PlaylistType
    color?: string
    trackLinkName?: string
    trackLinkId?: string
}

export const CopyLinkedGif = ({ playbook, color = 'white', trackLinkName, trackLinkId }: Props) => {
    const { copyLinkedGif } = useCopyLinkedGif()

    const handleIconClick = () => {
        const context = isQG(playbook) ? 'copy-linked-gif-qg' : 'copy-linked-gif'

        copyLinkedGif(playbook, trackLinkName, trackLinkId)

        logToAnalytics('copyLink', {
            track_name: trackLinkName,
            track_domain: trackLinkId || '',
            context,
            ...playbookToAnalyticsProps(playbook)
        })
    }

    return (
        <IconButton
            size="small"
            title="Copy linked GIF"
            onClick={handleIconClick}
            data-test="copy-linked-gif"
        >
            <GifIcon style={{ color }} fontSize="large" />
        </IconButton>
    )
}
