import { useMemo } from 'react'

import { Autocomplete, Checkbox, TextField, useTheme, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { FilterLabel } from './FilterLabel'
import { FilterDialog } from './FilterDialog'

import { type OptionType, option } from 'modules'
import { useBoolean, useWindowView } from 'hooks'

type ValuesType = Array<string>

type Props = {
    value: ValuesType
    onChange: (newValues: ValuesType, oldValues: ValuesType) => void
    options: Array<OptionType>
    defaultLabel: string
    defaultOption: OptionType
    labelIcon?: null | JSX.Element
    multipleItemsLabel?: string
    width?: number | string
    minWidth?: number
}

export const MultipleSelect = ({
    value,
    onChange,
    options,
    defaultLabel,
    labelIcon,
    defaultOption,
    width,
    minWidth,
    multipleItemsLabel
}: Props) => {
    const theme = useTheme()

    const { isMobileView, isDesktopView } = useWindowView()

    const allOptions = defaultOption ? [defaultOption, ...options] : options

    const selectedOptions = allOptions.filter(o => value.includes(o.value))
    const selectedCount = selectedOptions.length

    const open = useBoolean()

    const color =
        selectedCount === 0 ||
        (defaultOption && selectedOptions.map(o => o.value).includes(defaultOption.value))
            ? theme.palette.text.primary
            : theme.palette.primary.main

    const labelProps = {
        defaultLabel,
        selectedCount,
        label: selectedOptions?.[0]?.label || '',
        multipleItemsLabel,
        color,
        width,
        transparent: isDesktopView
    }

    if (isMobileView) {
        return (
            <Box>
                <FilterLabel {...labelProps} onClick={open.setTrue} />
                <FilterDialog
                    isOpen={open.isTrue}
                    onConfirm={newValues => {
                        onChange(
                            newValues,
                            selectedOptions.map(o => o.value)
                        )
                    }}
                    onClose={open.setFalse}
                    options={allOptions}
                    selectedOptions={value}
                />
            </Box>
        )
    }

    if (open.isFalse) {
        return <FilterLabel onFocus={open.setTrue} labelIcon={labelIcon} {...labelProps} />
    }

    return (
        <Autocomplete
            open
            autoHighlight
            multiple
            disableCloseOnSelect
            disableListWrap
            fullWidth
            style={{ minWidth, maxWidth: labelProps.width }}
            popupIcon={null}
            value={selectedOptions}
            data-test="filter-autocomplete"
            onChange={(_event, newValue) => {
                onChange(
                    newValue.map(o => o.value),
                    selectedOptions.map(o => o.value)
                )
            }}
            onBlur={open.setFalse}
            options={allOptions}
            renderInput={({ InputProps, ...params }) => (
                <TextField
                    {...params}
                    variant="standard"
                    autoFocus={open.isTrue}
                    data-test="filter-text-field"
                    InputProps={{
                        ...InputProps,
                        fullWidth: true,
                        disableUnderline: true,
                        startAdornment: <SearchIcon />
                    }}
                    onKeyDown={event => {
                        // Disable selected items deletion by pressing backspace
                        if (event.keyCode === 8) {
                            event.stopPropagation()
                        }
                    }}
                />
            )}
            renderOption={(props, o, { selected }) => (
                // @ts-ignore
                <Box
                    {...props}
                    sx={theme => ({
                        padding: theme.spacing(0.5, 1),
                        fontSize: theme.typography.pxToRem(14),
                        lineHeight: theme.typography.pxToRem(20)
                    })}
                >
                    <Checkbox
                        checked={selected}
                        sx={{ padding: '4px' }}
                        color="primary"
                        data-test="filter-checkbox"
                    />
                    {o.label}
                </Box>
            )}
            getOptionLabel={o => o.label || ''}
            sx={{
                '& + .MuiAutocomplete-popper .MuiAutocomplete-listbox': {
                    padding: 0
                }
            }}
            disablePortal
        />
    )
}

export const allIfEmpty = (values: ValuesType) => (values.length === 0 ? ['ALL'] : values)

export const handleChange =
    (allValues: ValuesType) => (values: ValuesType, prevValues: ValuesType) => {
        if (values.length === 0 && prevValues[0] === 'ALL') {
            return allValues
        }

        if (values.length === 0 || values[values.length - 1] === 'ALL') {
            return []
        }

        return values.filter(value => value !== 'ALL')
    }

type MultipleProps = {
    value: ValuesType
    onChange: (values: ValuesType) => void
    options: Array<OptionType>
    labelIcon?: null | JSX.Element
    multipleItemsLabel?: string
    width?: string | number
    minWidth?: number
}

export const FilterSelectMultiple = ({ multipleItemsLabel = '', ...props }: MultipleProps) => {
    const getNewValue = useMemo(
        () => handleChange(props.options.map(option => option.value)),
        [props.options]
    )

    return (
        <MultipleSelect
            value={allIfEmpty(props.value)}
            width={props.width || 'auto'}
            minWidth={props.minWidth || 0}
            onChange={(values, prevValues) => {
                props.onChange(getNewValue(values, prevValues))
            }}
            multipleItemsLabel={multipleItemsLabel}
            defaultLabel={`All ${multipleItemsLabel}`}
            labelIcon={props.labelIcon}
            options={props.options}
            defaultOption={option('ALL', `All ${multipleItemsLabel}`)}
        />
    )
}
