import { type ReactNode } from 'react'

import { Box, Typography, styled } from '@mui/material'

const BTN_SIZE = 90
const RING_SIZE = 30
const MIN_RING_WIDTH = 8

const Circle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    transition: 'width 0.3s, height 0.3s'
})

const OuterRing = styled(Circle)({
    width: `${BTN_SIZE + 2 * RING_SIZE}px !important`,
    height: `${BTN_SIZE + 2 * RING_SIZE}px !important`,
    backgroundColor: 'rgba(203, 0, 0, 0.1)'
})

const MiddleRing = styled(Circle, { shouldForwardProp: propName => propName !== 'isActive' })(
    ({ isActive }: { isActive: boolean }) => ({
        width: `${BTN_SIZE + RING_SIZE}px`,
        height: `${BTN_SIZE + RING_SIZE}px`,
        backgroundColor: 'rgba(203, 0, 0, 0.1)',
        ...(isActive && {
            width: `${BTN_SIZE + 2 * RING_SIZE - MIN_RING_WIDTH}px`,
            height: `${BTN_SIZE + 2 * RING_SIZE - MIN_RING_WIDTH}px`
        })
    })
)

const InnerRing = styled(Circle, { shouldForwardProp: propName => propName !== 'isActive' })<{
    isActive: boolean
}>(({ theme, isActive }) => ({
    width: `${BTN_SIZE}px`,
    height: `${BTN_SIZE}px`,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '14px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    ...(isActive && {
        width: `${BTN_SIZE + 2 * RING_SIZE - 2 * MIN_RING_WIDTH}px`,
        height: `${BTN_SIZE + 2 * RING_SIZE - 2 * MIN_RING_WIDTH}px`
    })
}))

type Props = {
    active: boolean
    label: ReactNode
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onClick: () => void
}

export const RedButton = ({ active, label, onMouseEnter, onMouseLeave, onClick }: Props) => {
    return (
        <OuterRing
            data-test="editor-rec-button"
            data-intercom="editor-rec-button"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <MiddleRing isActive={active}>
                <InnerRing isActive={active} onClick={onClick}>
                    <Typography style={{ fontSize: 20 }}>{label}</Typography>
                </InnerRing>
            </MiddleRing>
        </OuterRing>
    )
}
