import { useContext } from 'react'

import { styled, Typography, Box, IconButton } from '@mui/material'

import { useBoolean } from 'hooks'

import { VideoStatusContext, VideoUploadDialog } from 'UI/Components'

import { type VideoRefType, type PlaybookType, type QuickGuiddeType } from 'app/types'

import { ReactComponent as MagicIcon } from 'assets/icons/magic.svg'
import clsx from 'clsx'
import { isQG } from 'modules'

const PREFIX = 'VideoUploadBar'

const classes = {
    barContainer: `${PREFIX}-barContainer`,
    clickableContainer: `${PREFIX}-clickableContainer`,
    magicIcon: `${PREFIX}-magicIcon`,
    buttonIcon: `${PREFIX}-buttonIcon`
}

const StyledBox = styled(Box)(() => ({
    [`& .${classes.barContainer}`]: {
        margin: 'auto',
        width: 'fit-content',
        paddingTop: '5px',
        paddingBottom: '5px',
        color: '#000'
    },

    [`& .${classes.clickableContainer}`]: {
        '&:hover, &.Mui-focusVisible': {
            cursor: 'pointer'
        }
    },

    [`& .${classes.magicIcon}`]: {
        height: '16px',
        width: '16px'
    },

    [`& .${classes.buttonIcon}`]: {
        padding: '7px',
        backgroundColor: 'rgba(0,0,0, 0.1)',
        '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'rgba(0,0,0, 0.2)'
        }
    }
}))

type VideoUploadBarProps = {
    videoRef?: VideoRefType
    isEditMode?: boolean
    playbook: PlaybookType | QuickGuiddeType
}

type ProcessingBannerProps = {
    onClick?: () => void
    hoverTitle?: string
}

const ProcessingBanner = ({ onClick, hoverTitle }: ProcessingBannerProps) => {
    return (
        <StyledBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => onClick?.()}
            title={hoverTitle}
            className={clsx(classes.barContainer, { [classes.clickableContainer]: !!onClick })}
        >
            <Box>
                <Typography variant="body2" color="inherit">
                    guidde is processing your video, magic is in progress
                </Typography>
            </Box>
            <Box ml={2}>
                <IconButton disabled={!onClick} className={classes.buttonIcon} size="large">
                    <MagicIcon className={classes.magicIcon} />
                </IconButton>
            </Box>
        </StyledBox>
    )
}
export const VideoUploadBar = ({ videoRef, playbook, isEditMode = false }: VideoUploadBarProps) => {
    const { processFinished, isLoading } = useContext(VideoStatusContext)
    const openDialog = useBoolean(isEditMode)

    // for QGs we are showing progress bar under the video player play button
    if (isQG(playbook)) return null

    if (isLoading || processFinished) return null

    return (
        <>
            <ProcessingBanner
                onClick={openDialog.setTrue}
                hoverTitle="Click here to show progress"
            />
            <VideoUploadDialog
                playbookId={playbook.id}
                open={openDialog}
                videoRef={videoRef}
                playbookTitle={playbook.title}
            />
        </>
    )
}
