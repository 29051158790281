import { type ReactElement, memo } from 'react'
import InView, { useInView } from 'react-intersection-observer'

import { Box } from '@mui/material'

type Props = {
    placeholder: JSX.Element
    threshold: number
    delay?: number
    triggerOnce?: boolean
    children: ReactElement
    inViewCallback?: () => void
}

export const VisibilitySensor = memo(
    ({
        children,
        placeholder,
        inViewCallback,
        threshold = 0.5,
        delay = 500,
        triggerOnce = true
    }: Props) => {
        const { ref, inView } = useInView({
            threshold,
            delay,
            triggerOnce: true
        })

        if (inView) {
            return (
                <InView
                    onChange={result => {
                        if (!result) return

                        inViewCallback?.()
                    }}
                    delay={delay}
                    triggerOnce={triggerOnce}
                    threshold={threshold}
                >
                    {children}
                </InView>
            )
        }

        return <Box ref={ref}>{placeholder}</Box>
    }
)
