import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import GetStarted from 'assets/images/get_started.png'

import { UtmLink, ShareOnlyDialog } from 'UI/Components'

import { type AnyPlaybookType, PlaybookMode } from 'app/types'

import { useBoolean } from 'hooks'

type Props = {
    playbook: AnyPlaybookType
    fullWidth?: boolean
}

export const StartUsingMessage = ({ playbook, fullWidth }: Props) => {
    //special treatment for small height screens, all the rest works as expected
    const matches = useMediaQuery('(min-height:720px)')

    const isPlaylist = playbook.mode === PlaybookMode.Playlist

    const shareDialog = useBoolean()

    return (
        <>
            <Box width="100%" display="flex" alignItems="center" flexDirection="column">
                <Box maxWidth={fullWidth ? 'none' : 300} width="100%">
                    <Box
                        bgcolor="#F5F5F5"
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        py={matches ? 4 : 1}
                        px={matches ? 5 : 2}
                    >
                        <img src={GetStarted} width={matches ? 200 : 150} />
                        <Box my={matches ? 2 : 1}>
                            <Typography
                                style={{ fontSize: matches ? 20 : 15, fontWeight: 500 }}
                                align="center"
                            >
                                Use guidde for free
                            </Typography>
                            <Typography align="center" style={{ fontSize: matches ? 16 : 13 }}>
                                Capture a video like this one in seconds for any workflow or
                                software
                            </Typography>
                        </Box>
                        <UtmLink
                            playbook={playbook}
                            params={{
                                utm_campaign: 'get+started',
                                utm_medium: 'public-right-cta'
                            }}
                        >
                            <Button size={matches ? 'medium' : 'small'} variant="contained">
                                GET STARTED
                            </Button>
                        </UtmLink>
                    </Box>
                </Box>
            </Box>
            {shareDialog.isTrue && (
                <ShareOnlyDialog
                    isOpen={true}
                    onDone={() => {}}
                    onClose={shareDialog.setFalse}
                    playbook={playbook}
                    title={`Share your ${isPlaylist ? 'playlist' : 'video'}`}
                    subtitle={`Invite people to view your ${isPlaylist ? 'Playlist' : 'Playbook'}`}
                    warning=""
                />
            )}
        </>
    )
}
