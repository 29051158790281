import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Typography } from '@mui/material'

import { StartAtInput } from './StartAtInput'
import { NotAvailableMessage } from './NotAvailableMessage'

import LockedImage from 'assets/images/locked_tracking.png'

import { LinkTemplates, SpacedGroup } from 'UI/Components'

import { type AnyPlaybookType } from 'app/types'

import { logToAnalytics, playbookToAnalyticsProps, timeToSeconds } from 'modules'
import { useBoolean } from 'hooks'
import { showPlanDialog } from 'ducks'

type Props = {
    playbook: AnyPlaybookType
    isLocked: boolean
}

export const TrackTab = ({ playbook, isLocked }: Props) => {
    const [startTime, setStartTime] = useState('00:00:00')

    const dispatch = useDispatch()

    const startAt = useBoolean()

    return (
        <>
            {isLocked && (
                <Box bgcolor="#fff" borderRadius="4px" p={1} width="100%" my={2}>
                    <SpacedGroup>
                        <img src={LockedImage} style={{ height: 60, objectFit: 'cover' }} />
                        <Box>
                            <Typography style={{ fontSize: 14 }}>
                                Track your link data in one place. View metrics
                            </Typography>
                            <SpacedGroup spacing={1}>
                                <Typography style={{ fontSize: 14 }}>
                                    by account or campaign.
                                </Typography>
                                <Typography
                                    color="primary"
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        fontSize: 14
                                    }}
                                    onClick={() => dispatch(showPlanDialog('upgradePlanDialog'))}
                                >
                                    Upgrade your plan
                                </Typography>
                            </SpacedGroup>
                        </Box>
                    </SpacedGroup>
                </Box>
            )}
            <Box position="relative">
                {(!playbook.isPublic || isLocked) && (
                    <NotAvailableMessage
                        message={
                            isLocked
                                ? 'Tracking isn’t available in your plan'
                                : 'Options available for public videos'
                        }
                    />
                )}
                <StartAtInput
                    startTime={startTime}
                    setStartTime={setStartTime}
                    startAt={startAt}
                    onTimeChange={() => {
                        logToAnalytics('shareModal_trackTab_startAtTime_inserted', {
                            playbookId: playbook.id,
                            ...playbookToAnalyticsProps(playbook)
                        })
                    }}
                    onCheckBoxChange={state => {
                        logToAnalytics('shareModal_trackTab_startAtChk', {
                            state: state ? 'check' : 'uncheck',
                            playbookId: playbook.id,
                            ...playbookToAnalyticsProps(playbook)
                        })
                    }}
                />
                <Box my={1.5}>
                    <Typography color="textSecondary" style={{ fontSize: '14px' }}>
                        Share tracking links to help you track the activity when users watch public
                        content
                    </Typography>
                </Box>
                <LinkTemplates playbook={playbook} startAt={timeToSeconds(startTime)} />
            </Box>
        </>
    )
}
