import { createContext, type ReactNode, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useTextDebounce } from 'hooks'
import { logToAnalytics } from 'modules'

const scrollToMatch = (id: string) => {
    const element = document.getElementById(id)
    element?.scrollIntoView()
}

export const CaptionsEditorContext = createContext({
    debouncedQuery: '',
    searchQuery: '',
    setSearchQuery: (_query: string) => {},
    matchSelectors: [] as Array<string>,
    currentHighlight: 0,
    navigateMatchedText: (_direction: 'up' | 'down') => {}
})

type Props = {
    children: ReactNode
}

export const CaptionsEditorProvider = ({ children }: Props) => {
    const [currentHighlight, setCurrentHighlight] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')

    const debouncedQuery = useTextDebounce(searchQuery, 100)

    const { steps } = useSelector(state => state.qgEditor.present)

    const matchSelectors = useMemo(() => {
        if (debouncedQuery.length < 3) return []

        const results: Array<string> = []

        steps.forEach((step, stepIndex) => {
            if (!step.subtitles) return
            step.subtitles.forEach((caption, captionIndex) => {
                const regex = new RegExp(debouncedQuery, 'gi')
                const matches = caption.text.match(regex)

                if (!matches) return
                matches.forEach((_, index) => {
                    const id = `match-${stepIndex}-${captionIndex}-${index}`
                    return results.push(id)
                })
            })
        })

        logToAnalytics('captions_editor_search', { query: debouncedQuery })
        setCurrentHighlight(0)
        scrollToMatch(results[0])

        return results
    }, [debouncedQuery, steps])

    const navigateMatchedText = useCallback(
        (direction: 'up' | 'down') => {
            logToAnalytics('captions_editor_search_navigation', { direction })
            setCurrentHighlight(prev => {
                let newIndex = 0
                if (direction === 'up') {
                    const isFirst = prev === 0
                    newIndex = isFirst ? matchSelectors.length - 1 : prev - 1
                }
                if (direction === 'down') {
                    const isLast = prev === matchSelectors.length - 1
                    newIndex = isLast ? 0 : prev + 1
                }

                scrollToMatch(matchSelectors[newIndex])
                return newIndex
            })
        },
        [matchSelectors]
    )

    const contextValue = {
        matchSelectors,
        debouncedQuery: searchQuery.length > 2 ? debouncedQuery : '',
        searchQuery,
        setSearchQuery,
        currentHighlight,
        navigateMatchedText
    }

    return (
        <CaptionsEditorContext.Provider value={contextValue}>
            {children}
        </CaptionsEditorContext.Provider>
    )
}
