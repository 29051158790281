import { type RouteComponentProps, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { type Location } from 'history'

import { DelayedRender, GuiddeLoad } from 'UI/Components'

import { paths } from 'app/paths'
import {
    type RoleType,
    type OrgFeatureFlagType,
    type GlobalFeatureFlagType,
    useAuth,
    useOrgFeatureFlags,
    useGlobalFeatureFlags,
    useRoles
} from 'hooks'

import { auth } from 'modules'

type Props = {
    children: JSX.Element
    role?: string | Array<string>
    feature?: string
}

export const stringifyLocation = (location: Location) => {
    // save current location before logout (or redirect from private route if user not logged in)
    // and add to url params to redirect user to that path after login
    return new URLSearchParams({
        ...(location.pathname && { fromPathname: location.pathname }),
        ...(location.search && { fromSearch: location.search })
    }).toString()
}

export const parseLocationParams = (params: URLSearchParams) => {
    try {
        return {
            fromPathname: params.get('fromPathname') || '',
            fromSearch: params.get('fromSearch') || ''
        }
    } catch (e) {
        return {}
    }
}

export const PrivateRoute = ({ children, role, feature, ...remainingProps }: Props) => {
    const { token, roles } = useAuth()
    const [user, isAuthLoading] = useAuthState(auth)

    const orgFeatureFlags = useOrgFeatureFlags()
    const globalFeatureFlags = useGlobalFeatureFlags()
    const availableRoles = useRoles()

    const isOnboardingPage = useRouteMatch(paths.onboarding)?.isExact

    const goToLogin = (location: Location) => (
        <DelayedRender>
            <Redirect
                to={{
                    pathname: paths.login,
                    state: { from: location },
                    search: stringifyLocation(location)
                }}
            />
        </DelayedRender>
    )

    const goToIndex = (location: Location) => (
        <Redirect
            to={{
                pathname: paths.index,
                state: { from: location }
            }}
        />
    )

    const goToOnboarding = (location: Location) => (
        <Redirect
            to={{
                pathname: paths.onboarding,
                search: stringifyLocation(location)
            }}
        />
    )

    const loading = () => {
        return <GuiddeLoad message="Magically create video documentation with AI in seconds!" />
    }

    const renderFunc = ({ location }: RouteComponentProps<{}, any, unknown>) => {
        // Loading state
        if (isAuthLoading) return loading()

        // If user is not logged in - redirect to login page
        if (!user || user.isAnonymous) {
            return goToLogin(location)
        }
        // Some required data is still fetching
        if (!token || roles.a === 0) return loading()

        if (orgFeatureFlags.isLoading || globalFeatureFlags.isLoading) return loading()

        if (roles?.n && !isOnboardingPage) return goToOnboarding(location)

        // Role could have string and array type. We need to check both
        if (Array.isArray(role) && (role as Array<RoleType>).every(r => !availableRoles?.[r])) {
            return goToIndex(location)
        }
        if (role && !Array.isArray(role) && !availableRoles?.[role as RoleType]) {
            return goToIndex(location)
        }

        // Redirect to index if no featureFlag
        if (
            feature &&
            !orgFeatureFlags?.[feature as OrgFeatureFlagType] &&
            !globalFeatureFlags?.[feature as GlobalFeatureFlagType]
        ) {
            return goToIndex(location)
        }

        return children
    }

    return <Route {...remainingProps} render={renderFunc} />
}
