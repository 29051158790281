import { useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from '@mui/material'
import ContentCutIcon from '@mui/icons-material/ContentCut'

import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'
import { TrimDialog } from './TrimDialog'

import { useBoolean } from 'hooks'
import { type VideoShapeType, Shape, type QuickGuiddeType } from 'app/types'

import { isDeepEqual, logToAnalytics } from 'modules'
import { setVideoStepRanges } from 'ducks'

type Props = {
    language: QuickGuiddeType['language']
}
export const EditVideo = ({ language }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const open = useBoolean()

    const { steps, activeStep } = useSelector(state => state.qgEditor.present)

    const { extractVideoStep } = useContext(VoiceOverContext)

    const { layers, id, audioNote } = steps[activeStep]

    const ignoreVoiceoverGenerate = useMemo(() => {
        if (!audioNote) return false
        return 'isUserEdited' in audioNote && Boolean(audioNote.isUserEdited)
    }, [audioNote])

    const videoLayer = layers.find(layer => layer.type === Shape.Video) as
        | VideoShapeType
        | undefined

    if (!videoLayer) return null

    return (
        <>
            <Button
                variant="text"
                color="inherit"
                onClick={() => {
                    open.setTrue()
                    logToAnalytics('editVideoStep_clicked', { playbookId, stepId: id })
                }}
                style={{ minWidth: 30 }}
                startIcon={<ContentCutIcon fontSize="small" />}
            >
                Edit video
            </Button>

            {open.isTrue && (
                <TrimDialog
                    videoInfo={{
                        duration: videoLayer.sourceDuration,
                        url: videoLayer.sourceVideoUrl,
                        thumbnail: videoLayer.sourceVideoThumbnailPreview
                    }}
                    initialStart={videoLayer.start}
                    initialEnd={videoLayer.end}
                    onClose={open.setFalse}
                    onConfirm={(start, end) => {
                        open.setFalse()

                        const initialValues = { start: videoLayer.start, end: videoLayer.end }
                        const newValues = { start, end }
                        if (isDeepEqual(initialValues, newValues)) return

                        dispatch(setVideoStepRanges({ start, end }))
                        logToAnalytics('videoStep_video_trimmed', { playbookId, start, end })

                        if (ignoreVoiceoverGenerate) return

                        extractVideoStep({
                            playbookId,
                            start,
                            end,
                            languageCode: language?.langCode || 'en-US',
                            languageName: language?.langName || 'English',
                            stepId: id,
                            videoUrl: videoLayer.sourceVideoUrl
                        })
                    }}
                />
            )}
        </>
    )
}
