import { memo, useEffect, useState } from 'react'

import { Box, Switch, Typography, Divider } from '@mui/material'

import { SearchTranscript } from '@guidde-co/shared.transcript.search-transcript'

import {
    type PlaybookTranscriptType,
    SpacedGroup,
    Transcript,
    FixedVisibleHeight
} from 'UI/Components'
import { StartUsingMessage } from 'UI/Routes/main/common'
import { PlaybookChapters } from './PlaybookChapters'

import { useBoolean } from 'hooks'
import { fetchTranscript } from 'modules'

import { type PlaybookType, type CanvasRefType, type VideoRefType } from 'app/types'

type Props = {
    playbook: PlaybookType
    videoRef: VideoRefType
    isEditMode: boolean
    canvasRef: CanvasRefType
    showStartUsing?: boolean
}

export const PlaybookSidePanel = memo(
    ({ playbook, videoRef, isEditMode = false, canvasRef, showStartUsing }: Props) => {
        const isTranscript = useBoolean()

        const [searchString, setSearchString] = useState('')

        const [transcript, setTranscript] = useState<PlaybookTranscriptType>({
            playbookId: '',
            segments: []
        })

        useEffect(() => {
            if (!playbook?.transcriptUrl) return

            fetchTranscript(playbook.transcriptUrl).then(setTranscript)
        }, [playbook?.transcriptUrl])

        return (
            <Box p={2} style={{ position: 'sticky', top: 0 }}>
                {!showStartUsing && (
                    <>
                        <SpacedGroup mr={playbook?.transcriptUrl ? 0 : 1}>
                            <SearchTranscript
                                onChange={setSearchString}
                                data-test="search-transcript"
                            />

                            <Box pr={1}>
                                <Typography variant="body2">Transcript</Typography>
                                {/*Toggle between chapters and transcript*/}
                                <Switch
                                    checked={isTranscript.isTrue}
                                    size="small"
                                    onChange={isTranscript.toggle}
                                    color="primary"
                                    data-test="transcript-switch"
                                />
                            </Box>
                        </SpacedGroup>

                        <Box pr={1}>
                            <Divider />
                        </Box>
                    </>
                )}

                {isTranscript.isFalse ? (
                    <FixedVisibleHeight additional={90}>
                        {showStartUsing ? (
                            <Box mt={-2}>
                                <StartUsingMessage playbook={playbook} />
                            </Box>
                        ) : (
                            <PlaybookChapters
                                playbook={playbook}
                                searchString={searchString}
                                videoRef={videoRef?.current as HTMLVideoElement}
                                canvasRef={canvasRef?.current as Array<HTMLCanvasElement>}
                                isEditMode={isEditMode}
                            />
                        )}
                    </FixedVisibleHeight>
                ) : (
                    <Box mr={1}>
                        <Transcript
                            playbook={playbook}
                            searchString={searchString}
                            videoRef={videoRef}
                            transcript={transcript}
                            isEditMode={isEditMode}
                        />
                    </Box>
                )}
            </Box>
        )
    }
)
