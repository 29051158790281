import { Box, Link, Grid } from '@mui/material'

import {
    BasicMetadata,
    PlaybookColorPicker,
    Label,
    SpacedGroup,
    SharePublishDialog
} from 'UI/Components'

import {
    PlaybookCover,
    EditTitle,
    EditSourceUrl,
    EditDescription,
    EditTags,
    EditApplications,
    EditAttachments
} from 'UI/Routes/main/common'

import { useBoolean, useWindowView } from 'hooks'
import { type PlaybookType } from 'app/types'

type Props = {
    playbook: PlaybookType
    revalidatePlaybook?: () => void
}

export const EditPlaybookMetadata = ({ playbook, revalidatePlaybook }: Props) => {
    const { id, title, description, link, tags, coverColor, mode } = playbook

    const showShareDialog = useBoolean()

    const { isMobileView } = useWindowView()

    return (
        <>
            <Grid
                container
                direction={isMobileView ? 'column' : 'row'}
                justifyContent="space-between"
                spacing={isMobileView ? 2 : 5}
            >
                <Grid item md={8} xs={12}>
                    <Box my={2}>
                        <BasicMetadata playbook={playbook} />
                    </Box>
                    <Box mt={4} display="flex" flexDirection="column" style={{}}>
                        <Box mb={1}>
                            <EditTitle playbookId={id} value={title} mode={mode} multiline={true} />
                        </Box>
                        <Box mb={2}>
                            <EditDescription
                                playbookId={id}
                                value={description}
                                disabled={false}
                                showCharCount={true}
                                multiline={true}
                                mode={mode}
                                maxLength={1500}
                            />
                        </Box>

                        <SpacedGroup flexDirection="column" width="100%" mt={2}>
                            <Box mt={1} mb={2}>
                                <EditApplications playbook={playbook} />
                            </Box>

                            <Box ml={-1} my={2}>
                                <EditTags playbookId={id} values={tags} mode={mode} />
                            </Box>

                            <EditSourceUrl playbookId={id} value={link} disabled={false} />
                        </SpacedGroup>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box mt={2.5}>
                        <Label variant="body2" label="Visibility" />
                        <Link
                            target="_blank"
                            color="inherit"
                            onClick={showShareDialog.setTrue}
                            style={{
                                cursor: 'pointer',
                                color: '#2D9CDB',
                                fontSize: '12px',
                                textDecoration: 'none'
                            }}
                            data-test="visibility"
                        >
                            {playbook.visibility}
                        </Link>
                    </Box>

                    <Box mt={3}>
                        <Label variant="body2" label="Cover" />
                        <PlaybookCover playbook={playbook} />
                    </Box>
                    <Box mt={1} mb={2}>
                        <Label variant="body2" label="Select cover color" />
                        <Box mt={1} />
                        <PlaybookColorPicker color={coverColor} playbookId={id} />
                    </Box>
                    <Box mt={1}>
                        <Label label="Attachments" />
                        <Box ml={1}>
                            <EditAttachments playbook={playbook} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <SharePublishDialog
                isOpen={showShareDialog.isTrue}
                onClose={showShareDialog.setFalse}
                playbook={playbook}
                onDone={() => revalidatePlaybook?.()}
                title="Share your video"
            />
        </>
    )
}
