import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { updateUserPreferences } from 'ducks/reducers'
import { type StepType } from 'app/types'

export const defaultSpeakingRate = 1

export const useStepSpeakingRate = () => {
    const { speakingRate } = useSelector(state => state.configs.userPreferences)

    const updateSystemSpeakingRate = useCallback((speed: number) => {
        updateUserPreferences('speakingRate', speed)
    }, [])

    const getStepSpeakingRate = useCallback(
        (step: StepType) => {
            if (step.audioNote?.type === 'textToSpeech') {
                return step.audioNote?.speakingRate || defaultSpeakingRate
            }

            if (step.tempAudioNote?.type === 'textToSpeech') {
                return step.tempAudioNote?.speakingRate || speakingRate
            }

            return speakingRate
        },
        [speakingRate]
    )

    return useMemo(
        () => ({
            updateSystemSpeakingRate,
            getStepSpeakingRate,
            resetSpeakingRage: () => updateSystemSpeakingRate(1),
            speakingRate
        }),
        [speakingRate, updateSystemSpeakingRate, getStepSpeakingRate]
    )
}
