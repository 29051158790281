import { memo, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch, useHistory, useLocation, matchPath } from 'react-router-dom'

import { resetVideoProgress } from 'ducks/actions'

import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
    styled,
    Box,
    IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as MagicIcon } from 'assets/icons/magic.svg'

import { SpacedGroup, BorderLinearProgress, VideoStatusContext } from 'UI/Components'

import { SERVICE_STATUS } from 'modules'
import { type UseBooleanType, useQuery } from 'hooks'

import { paths } from 'app/paths'
import { type VideoRefType } from 'app/types'

const PREFIX = 'VideoUploadDialog'

const classes = {
    modal: `${PREFIX}-modal`,
    bold: `${PREFIX}-bold`,
    actions: `${PREFIX}-actions`,
    massage: `${PREFIX}-massage`,
    closeIcon: `${PREFIX}-closeIcon`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.modal}`]: {
        padding: theme.spacing(3),
        width: '764x',
        maxWidth: 'none',
        position: 'relative'
    },

    [`& .${classes.bold}`]: {
        fontWeight: 600,
        fontSize: 'inherit'
    },

    [`& .${classes.actions}`]: { justifyContent: 'center', padding: theme.spacing(3, 0) },

    [`& .${classes.massage}`]: {
        textAlign: 'center',
        padding: theme.spacing(3),
        borderRadius: '8px',
        background: theme.palette.grey[200]
    },

    [`& .${classes.closeIcon}`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5)
    }
}))

type Props = {
    playbookId: string
    open?: UseBooleanType
    videoRef?: VideoRefType
    playbookTitle?: string
}

type QueryData = {
    data: Array<{
        displayToUser: string
        serviceName: string
        title: string
        serviceStatus: keyof typeof SERVICE_STATUS
    }>
}

type MatchParams = {
    playbookId: string
}

type LocationState = {
    prevRoute?: string
}

export const VideoUploadDialog = memo(({ playbookId, open, videoRef, playbookTitle }: Props) => {
    const { services, processFinished } = useContext(VideoStatusContext)

    const history = useHistory()
    const location = useLocation<LocationState>()

    const dispatch = useDispatch()

    const { data, error, isValidating } = useQuery<QueryData>(`/b/v1/getMicroServices`, {
        method: 'GET'
    })

    const viewPage = useRouteMatch<MatchParams>(paths.playbookDetails)
    const editPage = useRouteMatch<MatchParams>(paths.editPlaybook)

    const pageIsOpen = viewPage?.isExact || editPage?.isExact
    const routePlaybookId = viewPage?.params?.playbookId || editPage?.params?.playbookId

    const handleClose = () => {
        open?.setFalse()
        dispatch(resetVideoProgress(playbookId))
    }

    const handleRedirect = () => {
        const prevRoute = location.state?.prevRoute || ''
        const match = matchPath(prevRoute, {
            path: [paths.playbookDetails, paths.editPlaybook],
            exact: true,
            strict: true
        })

        // the user were in edit/view before going to the later so go 2 routes back
        if (match?.isExact) return history.go(-2)

        // no previous route
        if (!location.state?.prevRoute) return history.push({ pathname: paths.home })

        return history.goBack()
    }

    useEffect(() => {
        if (processFinished) dispatch(resetVideoProgress(playbookId))
    }, [playbookId, processFinished, dispatch])

    if (!services || isValidating || error) return null

    const isOpen = Boolean(
        pageIsOpen &&
            routePlaybookId === playbookId &&
            // this value should be true if open props doesn't exist
            (open?.isTrue ?? true)
    )

    if (isOpen) videoRef?.current?.pause()

    const servicesConfig = data?.data || []

    const processingMessage = Object.values(services).find(
        status => status !== SERVICE_STATUS.PENDING
    )
        ? "is currently processing your video. Yeah, that's right!"
        : "will soon start processing your video. Yeah, you're next!"

    return (
        <StyledDialog
            open={isOpen}
            classes={{ paper: classes.modal }}
            onClose={() => {
                // disable dialog dismiss on edit mode
                if (editPage?.isExact) return
                handleClose()
            }}
        >
            <DialogTitle>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <MagicIcon
                        style={{
                            marginRight: '4px'
                        }}
                    />

                    <Typography className={classes.bold} style={{ textAlign: 'center' }}>
                        Video is in process
                    </Typography>
                </Box>
            </DialogTitle>
            {viewPage?.isExact && (
                <IconButton
                    aria-label="close"
                    className={classes.closeIcon}
                    onClick={handleClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            )}

            <DialogContent style={{ textAlign: 'center', maxWidth: '600px' }}>
                <Box>
                    <Typography color="error" component="span" className={classes.bold}>
                        guidde
                    </Typography>{' '}
                    is processing your video{' '}
                    {playbookTitle && (
                        <Typography className={classes.bold} component="span">
                            "{playbookTitle}"
                        </Typography>
                    )}
                    <Typography variant="body2">
                        guidde will transcribe your video, automatically tag it and magically slice
                        it into chapters :)
                    </Typography>
                </Box>
                <Box my={4}>
                    <SpacedGroup spacing={3} style={{ justifyContent: 'center' }}>
                        {servicesConfig.map((service, idx: number) => (
                            <BorderLinearProgress
                                key={idx}
                                title={service?.displayToUser}
                                serviceStatus={services[service.serviceName]}
                            />
                        ))}
                    </SpacedGroup>
                </Box>
                <Box className={classes.massage}>
                    <Typography variant="body2">
                        <Typography color="error" component="span" className={classes.bold}>
                            guidde
                        </Typography>{' '}
                        <Typography variant="body2" component="span">
                            {processingMessage}
                        </Typography>
                    </Typography>
                </Box>
            </DialogContent>

            <DialogActions className={classes.actions}>
                <SpacedGroup>
                    <Button onClick={handleRedirect} variant="contained">
                        Go Back
                    </Button>
                    <Typography variant="body2">Or wait here</Typography>
                </SpacedGroup>
            </DialogActions>
            <Box flex={1} display="flex" justifyContent="center">
                <Typography variant="body2">
                    We will notify you via email when your video is ready.
                </Typography>
            </Box>
        </StyledDialog>
    )
})
