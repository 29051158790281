import { useLocation, Link, generatePath } from 'react-router-dom'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Typography,
    styled
} from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import StarSharpIcon from '@mui/icons-material/StarSharp'

import { ReactComponent as SpacesIcon } from 'assets/icons/menu/icon-spaces.svg'

import { DrawerLink } from './common'

import { useBoolean, useQuery, useRoles, useWindowView } from 'hooks'
import { paths } from 'app/paths'

import {
    FirstSpaceCreatingDialog,
    ProtectedViewerAction,
    SpacedGroup,
    useMenuStatus
} from 'UI/Components'
import { CreateSpaceDialog } from 'UI/Routes/spaces/dialogs'

const PREFIX = 'SpaceMenuItem'

const classes = {
    accordionRoot: `${PREFIX}-accordionRoot`,
    accordionSummaryRoot: `${PREFIX}-accordionSummaryRoot`,
    content: `${PREFIX}-content`,
    accordionDetailsRoot: `${PREFIX}-accordionDetailsRoot`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.accordionRoot}`]: {
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&.Mui-expanded': {
            margin: 'auto'
        }
    },

    [`& .${classes.accordionSummaryRoot}`]: {
        border: 'none',
        marginBottom: -1,
        minHeight: 56,
        '&.Mui-expanded': {
            minHeight: 56
        }
    },

    [`& .${classes.content}`]: {
        '&.Mui-expanded': {
            margin: '12px 0'
        }
    },

    [`& .${classes.accordionDetailsRoot}`]: {
        padding: theme.spacing(0, 2, 2)
    }
}))

type SpaceItemType = {
    id: string
    name: string
    isPrivate: boolean
}
type Response = {
    spaces: Array<SpaceItemType>
    allMySpacesCount: number
    hasUserEverCreatedSpace: boolean
}

type Props = {
    onClick?: () => void
}
export const SpaceMenuItem = ({ onClick = () => {} }: Props) => {
    const location = useLocation()

    const { isMenuClosed } = useMenuStatus()

    const { isMobileView } = useWindowView()

    // be careful with this key, if one day we change url on `MySpacesList` component
    // this key should be updated as well
    const $spaces = useQuery<Response>('/c/v1/space/favourite', {
        method: 'GET'
    })

    const expanded = useBoolean()
    const firstSpaceDialog = useBoolean()
    const createDialog = useBoolean()

    const { isMember } = useRoles()

    const isActive = location?.pathname === paths.spaces

    const isEmpty = !$spaces.data?.spaces.length

    return (
        <StyledBox>
            <CreateSpaceDialog open={createDialog.isTrue} onClose={createDialog.setFalse} />
            <FirstSpaceCreatingDialog
                isOpen={firstSpaceDialog.isTrue}
                onClose={firstSpaceDialog.setFalse}
                onCreate={createDialog.setTrue}
            />
            <DrawerLink
                to={{
                    pathname: paths.spaces,
                    state: { prevPath: location.pathname }
                }}
                onClick={onClick}
                isActive={isActive}
                endIcon={
                    isMobileView || !isMember ? null : (
                        <ProtectedViewerAction>
                            <IconButton
                                onClick={() => {
                                    if ($spaces.data?.hasUserEverCreatedSpace) {
                                        createDialog.setTrue()
                                    } else {
                                        firstSpaceDialog.setTrue()
                                    }
                                }}
                                size="small"
                            >
                                <AddCircleOutlineIcon
                                    style={{
                                        color: 'rgba(130, 130, 130, 1)',
                                        height: 20
                                    }}
                                />
                            </IconButton>
                        </ProtectedViewerAction>
                    )
                }
                startIcon={<SpacesIcon style={{ color: isActive ? '#cd0000' : 'inherit' }} />}
                title={`Spaces ${
                    $spaces.isValidating ? '' : `(${$spaces.data?.allMySpacesCount})`
                }`}
            />

            {!$spaces.isValidating && !isEmpty && !isMenuClosed && (
                <Box>
                    <Accordion
                        square
                        expanded={expanded.isTrue}
                        classes={{
                            root: classes.accordionRoot
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            classes={{
                                root: classes.accordionSummaryRoot,
                                content: classes.content
                            }}
                        >
                            <SpacedGroup flexDirection="column" width="100%">
                                {$spaces.data?.spaces
                                    ?.slice(0, 3)
                                    ?.map(space => (
                                        <SpaceItem key={space.id} {...space} onClick={onClick} />
                                    ))}
                            </SpacedGroup>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{
                                root: classes.accordionDetailsRoot
                            }}
                        >
                            <SpacedGroup flexDirection="column" width="100%">
                                {$spaces.data?.spaces
                                    ?.slice(3)
                                    ?.map(space => (
                                        <SpaceItem key={space.id} {...space} onClick={onClick} />
                                    ))}
                            </SpacedGroup>
                        </AccordionDetails>
                    </Accordion>

                    {$spaces.data && $spaces.data.spaces.length > 3 && (
                        <Box ml="20px">
                            <Button
                                variant="text"
                                size="small"
                                onClick={expanded.toggle}
                                style={{
                                    color: 'rgba(130, 130, 130, 1)',
                                    fontSize: 14,
                                    textTransform: 'uppercase',
                                    letterSpacing: 1.25
                                }}
                                startIcon={
                                    <ArrowDropDownIcon
                                        style={{
                                            transform: `rotate(${expanded.isFalse ? 0 : '180deg'})`,
                                            transition: 'rotate 0.3 linear'
                                        }}
                                    />
                                }
                            >
                                {expanded.isFalse ? 'More' : 'Less'}
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </StyledBox>
    )
}

const SpaceItem = ({ id, name, onClick }: SpaceItemType & { onClick: () => void }) => {
    const { isMobileView } = useWindowView()

    return (
        <Link
            to={generatePath(paths.spaceLibrary, {
                spaceId: id
            })}
            onClick={onClick}
            style={{
                width: '100%',
                textDecoration: 'none',
                color: isMobileView ? '#CB0000' : 'rgba(9, 12, 16, 0.6)',
                padding: '4px 8px',
                position: 'relative',
                zIndex: 10,
                background: '#FFFFFF',
                borderRadius: '0px 43px 43px 0px'
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <SpacedGroup spacing={1}>
                    <TextOverflow lineClamp={1}>
                        <Typography color="inherit">{name}</Typography>
                    </TextOverflow>
                </SpacedGroup>
                <StarSharpIcon style={{ color: 'rgba(254, 210, 67, 1)', height: 16 }} />
            </Box>
        </Link>
    )
}
