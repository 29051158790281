import { useState, useMemo, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import dayjs from 'dayjs'

import { type GridCellParams, type GridSortItem, DataGrid } from '@mui/x-data-grid'
import {
    Avatar,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
    Box,
    IconButton,
    Tooltip,
    Stack,
    Button,
    CircularProgress,
    styled
} from '@mui/material'
import { Delete, Email, EditOutlined, Add } from '@mui/icons-material'

import {
    SearchField,
    SpacedGroup,
    ConfirmationDialog,
    PermissionsDialog,
    getInviteDefaults,
    useMenuStatus,
    InviteDialog
} from 'UI/Components'
import { type WorkspacesResponse } from 'UI/Components/Menu/WorkspaceControlPanel'

import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as DeclineCrossIcon } from 'assets/icons/decline-cross.svg'
import { ReactComponent as CsvIcon } from 'assets/icons/csv.svg'

import { logToAnalytics } from 'modules'

import {
    type UserType,
    type UsersResponseType,
    getRoleName,
    ROLE,
    useAuth,
    useDataMutation,
    useNotification,
    useOrganization,
    useRoles,
    useServiceUsage,
    useQueryParams,
    useDownloadFile,
    useBoolean,
    useQuery
} from 'hooks'

import { ExceededUsersNotification } from '../ExceededUsersNotification'

const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
        outline: 'none!important'
    },
    '& .MuiDataGrid-cell': {
        cursor: 'pointer'
    },
    '& .MuiDataGrid-columnSeparator': { visibility: 'visible' }
})

const StyledButton = styled(Button)({
    border: 'none',
    color: '#090C10',
    lineHeight: '16px',
    padding: '4px 8px',
    backgroundColor: '#F5F5F5',
    '&:hover': {
        border: 'none !important',
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& + &': {
        marginLeft: '16px'
    }
})

type UserListProps = {
    users: {
        [key: string]: UserType
    }
    orgId: string
    role?: string
    refetchOrgUsers: () => Promise<UsersResponseType>
    isSingleAdminRole: boolean
}

export type RowUserType = {
    id: string
    uid: string
    name: string
    email: string
    roles: string
    rolesNumber: number
    photoURL: string
    status: string
    viewerOnlyAfterMs?: number
}

type DeleteRequestBody = {
    orgId: string
    docId: string
    status: 'pending' | 'active'
}

const UserList = ({ users = {}, orgId, refetchOrgUsers, isSingleAdminRole }: UserListProps) => {
    const { isPrivateAccount, uid } = useAuth()

    const { isMenuClosed } = useMenuStatus()

    const [selectedUser, setSelectedUser] = useState<RowUserType | null>(null)
    const [deleteId, setDeleteId] = useState<string | null>(null)
    const [pageSize, setPageSize] = useState<number>(50)
    const [actionLoaders, setActionLoaders] = useState<Array<string>>([])
    const [sortModel, setSortModel] = useState<Array<GridSortItem>>([
        {
            field: 'name',
            sort: 'asc'
        }
    ])

    const { isAdmin } = useRoles(orgId)

    const { showSuccessNotification, showErrorNotification } = useNotification()
    const { name: orgName } = useOrganization()
    const { userSeatsQuota, usedUsersSeats } = useServiceUsage()

    const $userDelete = useDataMutation<DeleteRequestBody, { message: string }, Error>(
        '/c/v1/user/delete',
        'POST',
        {
            onSuccess: refetchOrgUsers
        }
    )

    const handleRemove = (uid: string) => {
        $userDelete
            .mutate({
                orgId,
                docId: uid,
                status: users[uid].status
            })
            .then(() => {
                setDeleteId(null)
                showSuccessNotification('User was removed')
                logToAnalytics('remove_user', {
                    user: users[uid]
                })
            })
            .catch(() => showErrorNotification('Something went wrong'))
    }

    const $invite = useDataMutation('/auth/v1/invite/organization', 'POST', {
        onSuccess: () => {
            showSuccessNotification('Invite has been sent')
            logToAnalytics('send_invite_again')
        }
    })

    const $processRequest = useDataMutation<
        {
            processingUid: string
            action: 'declined' | 'approved'
        },
        { message: string },
        Error
    >('/auth/v1/process-join-request', 'POST', {
        onSuccess: (_, { processingUid }) =>
            refetchOrgUsers().then(() =>
                setActionLoaders(actionLoaders =>
                    actionLoaders.filter(uid => uid !== processingUid)
                )
            ),
        onFailure: (_, { processingUid }) => {
            setActionLoaders(actionLoaders => actionLoaders.filter(uid => uid !== processingUid))
            showErrorNotification('Something went wrong while dealing with the request')
        }
    })

    const processRequestMutate = $processRequest.mutate
    const inviteMutate = $invite.mutate

    const handleSendInvite = useCallback(
        async (email: string, permissions: number) => {
            if (!orgName) return

            const invite = {
                emails: email,
                message: getInviteDefaults(orgName, isPrivateAccount).message,
                inviteTo: orgId,
                ...(isAdmin ? { permissions } : {})
            }

            inviteMutate(invite).then(() => {
                logToAnalytics('send_invite', { type: 'auto reminder' })
            })
        },
        [inviteMutate, isAdmin, isPrivateAccount, orgId, orgName]
    )

    const disableRemove = useCallback(
        (user?: UserType) => {
            if (!user || !isAdmin || user.uid === uid) return true

            return false
        },
        [isAdmin, uid]
    )

    const openEditRoleDialog = useCallback(
        (user: RowUserType) => {
            if (actionLoaders.includes(user.uid)) return

            setSelectedUser(user)
        },
        [actionLoaders]
    )

    const actionButtons = useMemo(() => {
        if (!isAdmin) return []

        return {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            flex: 1,
            minWidth: 250,
            renderCell: ({ row }: GridCellParams) => {
                const isLoading = actionLoaders.includes(row.uid)

                if (row.status === 'requested-to-join') {
                    return (
                        <ListItem style={{ justifyContent: 'flex-end' }}>
                            <StyledButton
                                variant="outlined"
                                startIcon={<CheckmarkIcon color="#73CC6D" />}
                                onClick={e => {
                                    e.stopPropagation()
                                    setActionLoaders(actionLoaders => [...actionLoaders, row.uid])
                                    logToAnalytics('teamDiscovery_teamInvite_approved', {
                                        teamName: orgName,
                                        teamId: orgId,
                                        uid,
                                        approvedUserUid: row.uid
                                    })
                                    processRequestMutate({
                                        processingUid: row.uid,
                                        action: 'approved'
                                    }).then(() =>
                                        showSuccessNotification(
                                            `User ${row.email} has been added to your workspace`
                                        )
                                    )
                                }}
                                disabled={isLoading}
                            >
                                Approve
                            </StyledButton>
                            <StyledButton
                                variant="outlined"
                                startIcon={<DeclineCrossIcon color="#CB0000" />}
                                onClick={e => {
                                    e.stopPropagation()
                                    setActionLoaders(actionLoaders => [...actionLoaders, row.uid])
                                    logToAnalytics('teamDiscovery_teamInvite_declined', {
                                        teamName: orgName,
                                        teamId: orgId,
                                        uid,
                                        declinedUserUid: row.uid
                                    })
                                    processRequestMutate({
                                        processingUid: row.uid,
                                        action: 'declined'
                                    }).then(() =>
                                        showSuccessNotification(`User request has been declined`)
                                    )
                                }}
                                disabled={isLoading}
                            >
                                Decline
                            </StyledButton>
                        </ListItem>
                    )
                }

                return (
                    <ListItem style={{ justifyContent: 'flex-end' }}>
                        <Stack direction="row" spacing={2}>
                            {row.status === 'pending' && (
                                <Tooltip arrow placement="top" title="Resend invite">
                                    <IconButton
                                        size="small"
                                        onClick={e => {
                                            e.stopPropagation()
                                            row.email &&
                                                handleSendInvite(
                                                    row.email,
                                                    row.rolesNumber || ROLE.MEMBER
                                                )
                                        }}
                                    >
                                        <Email />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip arrow placement="top" title="Edit user">
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        openEditRoleDialog(row)
                                    }}
                                >
                                    <EditOutlined />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="top" title="Remove user">
                                <IconButton
                                    size="small"
                                    disabled={disableRemove(users[row.uid])}
                                    onClick={e => {
                                        e.stopPropagation()
                                        setDeleteId(row.id)
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </ListItem>
                )
            }
        }
    }, [
        isAdmin,
        actionLoaders,
        disableRemove,
        users,
        orgName,
        orgId,
        uid,
        processRequestMutate,
        showSuccessNotification,
        handleSendInvite,
        openEditRoleDialog
    ])

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                sortDirection: 'asc',
                renderCell: ({ row }: GridCellParams) => (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar src={row.photoURL} />
                        </ListItemAvatar>
                        <ListItemText>{row.name || row.email}</ListItemText>
                    </ListItem>
                )
            },
            {
                field: 'email',
                headerName: 'Email',
                sortable: true,
                flex: 1,
                renderCell: ({ row }: GridCellParams) => <ListItemText>{row.email}</ListItemText>
            },
            {
                field: 'roles',
                headerName: 'Role',
                sortable: true,
                flex: 1,
                renderCell: ({ row }: GridCellParams & { row: RowUserType }) => (
                    <ListItemText style={{ position: 'relative' }}>
                        {row.roles}
                        {row.viewerOnlyAfter && (
                            <Typography
                                component="span"
                                sx={{
                                    color: '#78909c',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    marginTop: '-4px'
                                }}
                            >
                                viewer starting {dayjs(row.viewerOnlyAfter).format('DD MMM YYYY')}
                            </Typography>
                        )}
                    </ListItemText>
                )
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: ({ row }: GridCellParams) => {
                    return (
                        <ListItemText>
                            {row.status === 'requested-to-join' ? 'Pending approval' : row.status}
                        </ListItemText>
                    )
                }
            },
            ...[actionButtons]
        ],
        [actionButtons]
    )

    const rows: Array<RowUserType> = useMemo(() => {
        return Object.values(users)
            .filter(Boolean)
            .map(r => ({
                id: r.uid, // for data grid
                uid: r.uid, // for edit permissions
                name: r.displayName,
                email: r.email,
                roles: getRoleName(r?.roles)?.name,
                rolesNumber: r?.roles,
                photoURL: 'photoURL' in r ? r.photoURL : '',
                status: r.status,
                viewerOnlyAfter: r.viewerOnlyAfterMs
            }))
    }, [users])

    return (
        <>
            <Box mt={1} width={`calc(100vw - ${isMenuClosed ? 120 : 290}px)`}>
                <StyledDataGrid
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize
                            }
                        }
                    }}
                    onPaginationModelChange={({ pageSize }) => {
                        setPageSize(pageSize)
                    }}
                    disableColumnMenu
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    columns={columns as any}
                    disableRowSelectionOnClick
                    rows={rows}
                />
            </Box>
            {selectedUser && (
                <PermissionsDialog
                    user={selectedUser}
                    onClose={() => setSelectedUser(null)}
                    isRoleUpgradeDisabled={Boolean(
                        userSeatsQuota && usedUsersSeats >= userSeatsQuota
                    )}
                    refetchOrgUsers={refetchOrgUsers}
                    orgId={orgId}
                    isSingleAdminRole={isSingleAdminRole}
                />
            )}
            {deleteId && (
                <ConfirmationDialog
                    isOpen={true}
                    onClose={() => setDeleteId(null)}
                    onConfirm={() => handleRemove(deleteId)}
                    title="Are you sure?"
                    text={
                        <>
                            Remove user{' '}
                            <Typography component="b" style={{ fontWeight: 600 }}>
                                {deleteId &&
                                    (users[deleteId]?.displayName || users[deleteId]?.email)}
                            </Typography>{' '}
                            ?
                        </>
                    }
                />
            )}
        </>
    )
}

type UsersTabProps = {
    orgId: string
    usersExceeded: boolean
    refetchOrgUsers: () => Promise<UsersResponseType | undefined>
    orgUsers: Array<UserType>
    pendingUsers: Array<UserType>
    pendingRequests: Array<UserType>
}

const downloadCsvUrl = '/c/v1/org-users-csv'

export const UsersTab = ({
    orgId,
    usersExceeded,
    refetchOrgUsers,
    orgUsers,
    pendingUsers,
    pendingRequests
}: UsersTabProps) => {
    const inviteDialog = useBoolean()

    const { isAdmin, isSuperAdmin } = useRoles()

    const queryParams = useQueryParams()
    const history = useHistory()
    const { uid } = useAuth()
    const { name: orgName, isLoading } = useOrganization()

    const viewRequestClicked = queryParams.get('view-request-clicked')
    const [text, setText] = useState('')

    const superAdminCsvUrl = downloadCsvUrl + `?orgId=${orgId}`
    const csvUrl = isSuperAdmin ? superAdminCsvUrl : downloadCsvUrl
    const { downloadFile, isFileDownloading } = useDownloadFile({
        fileName: `guidde-${orgName.toLowerCase()}-users-${dayjs().format('DD.MM.YYYY')}.csv`,
        url: csvUrl,
        successMessage: 'Your CSV file has been exported successfully'
    })

    const $workspaces = useQuery<WorkspacesResponse>('/c/v1/workspaces', { method: 'GET' })

    const activeWorkspace = useMemo(() => {
        return $workspaces.data?.workspaces?.find(workspace => workspace.orgId === orgId)
    }, [$workspaces.data, orgId])

    const filteredUsers = useMemo(() => {
        return [...pendingRequests, ...orgUsers, ...pendingUsers]?.reduce((acc, curr) => {
            let currentObj = {}

            if (
                curr.displayName.toLowerCase().includes(text.toLowerCase()) ||
                curr.email.toLowerCase().includes(text.toLowerCase())
            ) {
                currentObj = { [curr.uid]: curr }
            }

            return { ...acc, ...currentObj }
        }, {})
    }, [orgUsers, pendingUsers, pendingRequests, text])

    useEffect(() => {
        if (!viewRequestClicked || isLoading) return

        logToAnalytics('teamDiscovery_viewRequestBtn_clicked', {
            teamName: orgName,
            teamId: orgId,
            uid
        })

        queryParams.delete('view-request-clicked')

        history.replace({
            search: queryParams.toString()
        })
    }, [viewRequestClicked, queryParams, history, orgName, orgId, uid, isLoading])

    const adminOrgUsers = orgUsers.filter(orgUser => orgUser.roles === ROLE.ADMIN)
    const isSingleAdminRole = adminOrgUsers.length === 1

    const onInviteClick = () => {
        if (!activeWorkspace) return

        inviteDialog.setTrue()
        logToAnalytics('teamDiscovery_inviteButton_clicked', {
            uid,
            source: 'usersTab',
            workspaceName: activeWorkspace.name,
            workspaceId: activeWorkspace.orgId
        })
    }

    return (
        <>
            {inviteDialog.isTrue && activeWorkspace && (
                <InviteDialog
                    onClose={inviteDialog.setFalse}
                    isOpen={true}
                    activeWorkspace={{
                        teamId: activeWorkspace?.orgId,
                        teamName: activeWorkspace?.name
                    }}
                />
            )}

            {usersExceeded && (
                <Box mb={4}>
                    <ExceededUsersNotification inline />
                </Box>
            )}

            <Box width="100%" display="flex" justifyContent="flex-end">
                <Button onClick={onInviteClick} color="secondary" startIcon={<Add />}>
                    Invite Members
                </Button>
            </Box>
            <SpacedGroup>
                <Box width="60px" textAlign="center" pb={2}>
                    <Typography
                        variant="h2"
                        gutterBottom
                        sx={{ fontSize: '24px', fontWeight: 400 }}
                    >
                        {Object.keys(filteredUsers || {}).length}
                    </Typography>
                    <Typography sx={{ fontSize: 11 }}>Total Users</Typography>
                </Box>
                <SearchField placeholder="Search" text={text} setText={setText} />
                {isAdmin && (
                    <Box position="relative">
                        {isFileDownloading && (
                            <CircularProgress
                                style={{
                                    zIndex: 1,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0
                                }}
                            />
                        )}
                        <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                                logToAnalytics('usersTab_csvBtn_clicked', { org_id: orgId })
                                downloadFile()
                            }}
                            disabled={isFileDownloading}
                            style={{
                                width: 40,
                                height: 40,
                                background: '#F5F5F5'
                            }}
                        >
                            <CsvIcon style={{ color: '#090C10' }} />
                        </IconButton>
                    </Box>
                )}
            </SpacedGroup>
            {isAdmin ? (
                <UserList
                    users={filteredUsers || {}}
                    orgId={orgId}
                    refetchOrgUsers={refetchOrgUsers}
                    isSingleAdminRole={isSingleAdminRole}
                />
            ) : (
                <Typography variant="body1">
                    User administration permissions verification ...
                </Typography>
            )}
        </>
    )
}
