import { SpacedGroup } from 'UI/Components'

type Props = {
    text?: string
}

const DEFAULT_TEXT = 'ADD ACTION ITEM'

export const AddAction = ({ text = DEFAULT_TEXT }: Props) => {
    return (
        <SpacedGroup
            spacing={0}
            gap={0}
            mx={0}
            my={0}
            alignItems="center"
            justifyContent="center"
            width="257px"
            height="36px"
            fontWeight={500}
            fontSize={14}
            color="#FFFFFF"
            borderRadius={33}
            border="1px dashed #FFFFFF"
            style={{
                cursor: 'pointer'
            }}
        >
            + {text}
        </SpacedGroup>
    )
}
