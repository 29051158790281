import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import type Konva from 'konva'
import { Circle, Group } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { type CircleShapeType } from 'app/types'
import { setLayerTransform } from 'ducks'
import { round } from 'modules'

type Props = {
    layer: CircleShapeType
    hasOverlay: boolean
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const CircleShape = ({
    layer,
    hasOverlay,
    onDragStart,
    onDragMove,
    onDragEnd,
    onSelect
}: Props) => {
    const dispatch = useDispatch()

    const shapeRef = useRef<Konva.Circle>(null)

    const [circleProps, setCircleProps] = useState(layer)

    useEffect(() => {
        setCircleProps(layer)
    }, [layer])

    return (
        <Group>
            {circleProps.isSpotlight && hasOverlay && (
                <Circle
                    data-test="editor-insert-circle"
                    x={circleProps.x}
                    y={circleProps.y}
                    radius={circleProps.radius}
                    scaleX={circleProps.scaleX}
                    scaleY={circleProps.scaleY}
                    strokeWidth={circleProps.strokeWidth}
                    stroke={circleProps.strokeColor}
                    draggable={false}
                    fill="black"
                    globalCompositeOperation="destination-out"
                />
            )}

            <Circle
                ref={shapeRef}
                id={circleProps.id}
                className="alignable"
                name={layer.isSpotlight ? 'circleSpotlight' : 'circle'}
                x={circleProps.x}
                y={circleProps.y}
                radius={circleProps.radius}
                scaleX={circleProps.scaleX}
                scaleY={circleProps.scaleY}
                strokeWidth={circleProps.strokeWidth}
                stroke={circleProps.strokeColor}
                fill={circleProps.fill}
                onMouseOver={() => (document.body.style.cursor = 'move')}
                onMouseLeave={() => (document.body.style.cursor = '')}
                draggable={true}
                onDragStart={onDragStart}
                onDragMove={e => {
                    setCircleProps(prevState => ({
                        ...prevState,
                        x: e.target.attrs.x,
                        y: e.target.attrs.y
                    }))
                    onDragMove(e)
                }}
                onDragEnd={onDragEnd}
                onClick={e => onSelect(e.evt.shiftKey)}
                onTransform={({ target }) => {
                    setCircleProps(prevState => ({
                        ...prevState,
                        x: target.x(),
                        y: target.y(),
                        scaleX: target.scaleX(),
                        scaleY: target.scaleY()
                    }))
                }}
                onTransformEnd={({ target }) => {
                    dispatch(
                        setLayerTransform({
                            layerId: layer.id,
                            x: round(target.x()),
                            y: round(target.y()),
                            scaleX: target.scaleX(),
                            scaleY: target.scaleY()
                        })
                    )
                }}
            />
        </Group>
    )
}
