import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, TextField } from '@mui/material'

import { AddAction } from './AddAction'

import { setCurrentCTA, setStepMultiApplyVisibility } from 'ducks'
import { delay } from 'modules'
import { useBoolean } from 'hooks'

type Props = {
    title: string
}

export const MultipleCtaTitle = ({ title }: Props) => {
    const dispatch = useDispatch()

    const textFieldRef = useRef<HTMLDivElement | null>(null)

    const showAddText = useBoolean()
    const setShowAddText = showAddText.set

    const [text, setText] = useState<string>('')

    const { currentCta } = useSelector(state => state.qgCta)

    const update = useCallback(() => {
        if (text.length === 0) setShowAddText(true)
        if (!currentCta) return

        if (title !== text) dispatch(setStepMultiApplyVisibility(true))
        dispatch(
            setCurrentCTA({
                ctaType: 'multiple',
                title: text,
                actions: currentCta.actions
            })
        )
    }, [currentCta, dispatch, setShowAddText, text, title])

    const handleKeyPress = (event: { key: string }) => {
        if (event.key !== 'Enter') return
        update()
    }

    useEffect(() => {
        setText(title)
        setShowAddText(title.length === 0)
    }, [setShowAddText, title])

    return (
        <Box my={2}>
            {showAddText.isTrue ? (
                <Box
                    mt={2}
                    ml={1.5}
                    onClick={() => {
                        showAddText.setFalse()
                        delay(0).then(() => textFieldRef.current?.click())
                    }}
                >
                    <AddAction text="ADD TEXT" />
                </Box>
            ) : (
                <TextField
                    id="filled-required"
                    label=""
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    value={text}
                    size="small"
                    inputProps={{ maxLength: 60, textAlign: 'center' }}
                    InputProps={{ ref: textFieldRef }}
                    sx={{
                        width: 450,
                        '& input': {
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 600,
                            textAlign: 'center',
                            '&:hover, &:focus': {
                                '& + .MuiOutlinedInput-notchedOutline': {
                                    border: '1px dashed white'
                                }
                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        }
                    }}
                    onChange={event => setText(event.target.value)}
                    onKeyPress={handleKeyPress}
                    onBlur={update}
                />
            )}
        </Box>
    )
}
