import { type SpaceType } from 'app/types'
import { useState } from 'react'

import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'

import { getInviteDefaults, CommonInviteDialog } from 'UI/Components'

import { type CustomerType, useCustomersDomains } from './use-customers-domains'

import { ConfirmOutsideDialog } from './ConfirmOutsideDialog'

import {
    type OptionType,
    updateSpace,
    logToAnalytics,
    SPACE_ROLES,
    option,
    generalErrorMessage
} from 'modules'

import {
    useAuth,
    useDataMutation,
    useNotification,
    useOrganization,
    useRoles,
    useOrgFeatureFlags,
    useOrganizationUsers
} from 'hooks'

type ToggleSpaceInviteType = {
    spaceId: string
    shareLinkId?: string
    orgId: string
}

type Props = {
    space: SpaceType
    isOpen: boolean
    onClose: () => void
    revalidate: () => void
}

type ValuesType = {
    emails: Array<string>
    role: number
    message: string
}

const linkStatuses = [
    {
        label: 'Enabled',
        value: '0',
        icon: <LinkIcon />
    },
    {
        label: 'Disabled',
        value: '1',
        icon: <LinkOffIcon />
    }
]

export const InviteToSpaceDialog = ({ space, isOpen, onClose, revalidate }: Props) => {
    const [values, setValues] = useState<null | ValuesType>(null)
    const [linkStatus, setLinkStatus] = useState(linkStatuses[space.shareLinkId ? 0 : 1])

    const { domains: customersDomains, customers } = useCustomersDomains()
    const { domains: orgDomains, name: orgName } = useOrganization()
    const { orgUsers } = useOrganizationUsers()

    const { uid, isPrivateAccount } = useAuth()
    const { isAdmin, isContentManager, isViewer } = useRoles(space?.organizationId)

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const { disableExternalSharing } = useOrgFeatureFlags()

    const $inviteToSpace = useDataMutation('/auth/v1/invite/space', 'POST', {
        onSuccess: () => showSuccessNotification('Invitation has been sent')
    })

    const $toggleInviteLink = useDataMutation<ToggleSpaceInviteType, { enabled: Boolean }, Error>(
        '/c/v1/toggle-public-invite',
        'POST',
        {
            onSuccess: ({ enabled }) => {
                showSuccessNotification(
                    `The invite link has been ${enabled ? 'enabled' : 'disabled'}`
                )
            }
        }
    )

    const addSpacePartners = (emails: Array<OptionType>, customers?: Array<CustomerType>) => {
        // Check each email's domain. If current domain belongs to customer's domain
        // - we should add info (id, name, domains) of customer to the space.spacePartners property
        const spacePartnersEntries = emails
            .map(email => {
                const domain = email.value.split('@')[1]

                // Current domain - is our domain. No action requires
                if (orgDomains.includes(domain)) {
                    return []
                }

                // Current domain - is not of one of our customers. This handle backend
                if (!customersDomains.includes(domain)) {
                    return []
                }

                // get customer who contains current email domain
                const customer = customers?.find(customer => customer.domains.includes(domain))

                if (!customer?.id) return []

                // check whether this customer is already added to the spacePartners property
                if (space.spacePartners?.[customer.id] !== undefined) {
                    return []
                }

                const { id, name, domains } = customer

                return [id, { id, name, domains }]
            })
            .filter(arr => arr.length)

        if (!spacePartnersEntries.length) {
            return
        }

        const spacePartners =
            spacePartnersEntries.length > 1 ? Object.fromEntries(spacePartnersEntries) : {}

        return updateSpace(space.id, {
            spacePartners: {
                ...(space.spacePartners || {}),
                ...spacePartners
            }
        })
    }

    const handleInvite = async ({ emails, message }: ValuesType) => {
        const email = emails.map(email => {
            const label = orgUsers.find(oUser => oUser?.email === email)?.displayName || email

            return option(email, label)
        })

        // Before send an api call, we need to check each email's domain for use cases:
        // 1) domain belongs to our organization - no additional actions required
        // 2) domain belongs to our customer - add info (id, name, domains) of customer to the space.spacePartners property

        await addSpacePartners(email, customers)

        // Based on current user's role (roles.a property) inside this space he can set different roles to the new user during invite
        // If current user is OWNER (a=60) or COLLABORATOR (a=40) new user can be COLLABORATOR or OWNER (default collaborator)
        // If current user is VIEWER (a=20) he can only invite new viewer

        return $inviteToSpace
            .mutate({
                emails: emails.join(','),
                message,
                spaceId: space.id,
                spacePermission: SPACE_ROLES.VIEWER
            })

            .then(() => {
                logToAnalytics('send_invite_to_space', {
                    'space-name': space.name,
                    'space-id': space.id,
                    emails,
                    numberOfInvitees: emails.length
                })
            })
            .catch(e => {
                const message = typeof e === 'string' ? e : generalErrorMessage
                showErrorNotification(message)
                return false
            })
            .finally(() => {
                setValues(null)
                onClose()
            })
    }

    const shareLink = `${location.origin}/join-space/${space.id}/${space.shareLinkId}?origin=${uid}&referral=invite&invited_email=`

    const isOwner = space.members?.[uid]?.roles?.a === SPACE_ROLES.OWNER
    /* Admin and content manager can see and enable/disable link despite space role
     * Member in org can only see link if it is enabled
     * Space owner can see and enable/disable link if he isn't viewer in org
     */
    const canSeeShareLink = Boolean(
        isAdmin || isContentManager || ((isOwner || space.shareLinkId) && !isViewer)
    )

    const { message, maxLength } = getInviteDefaults(orgName, isPrivateAccount)

    const onInvite = (data: ValuesType, setFieldError: (error: string) => void) => {
        if (disableExternalSharing) {
            const emailDomains = data.emails.map(email => {
                return email.split('@')[1]
            })

            const includeExternalDomain = !emailDomains.every(emailDomain =>
                orgDomains.includes(emailDomain)
            )

            if (includeExternalDomain) {
                setFieldError(`Allowed domains: ${orgDomains.join(', ')}`)
                return
            }
        }

        const forbiddenDomains = data.emails.filter(
            email => !orgDomains.includes(email.split('@')[1])
        )

        if (!forbiddenDomains.length) {
            return handleInvite(data)
        }

        // An invite to a space for an outside domain could be only done by a space owner or space admin or content manager
        if (space.members?.[uid]?.roles?.a !== SPACE_ROLES.OWNER && !isAdmin && !isContentManager) {
            const error = isPrivateAccount
                ? `You don't have permissions to invite users in this space`
                : `You don't have permission to invite user outside of your organization. Forbidden domains: ${forbiddenDomains.join(
                      ', '
                  )}`

            return showErrorNotification(error)
        }

        if (isPrivateAccount) {
            handleInvite(data)
        } else {
            setValues(data)
        }
    }

    return (
        <>
            <CommonInviteDialog
                title="Invite to space"
                subtitle={
                    <>
                        People you invite will receive an email with an invitation to join{' '}
                        <b>{space?.name}</b>
                    </>
                }
                isLoading={$inviteToSpace.isLoading || $toggleInviteLink.isLoading}
                initialMessage={message}
                selectorLabel="Status"
                showShareLink={canSeeShareLink}
                maxLength={maxLength}
                onInvite={onInvite}
                linkStatuses={linkStatuses}
                linkStatus={linkStatus}
                isLinkDisabled={!space.shareLinkId}
                onLinkStatusChange={value => {
                    $toggleInviteLink
                        .mutate({
                            spaceId: space.id,
                            shareLinkId: space.shareLinkId,
                            orgId: space.organizationId
                        })
                        .then(() => {
                            setLinkStatus(linkStatuses[value])
                            revalidate()
                        })
                }}
                shareLink={shareLink}
                isOpen={isOpen}
                onClose={onClose}
            />
            {values && (
                <ConfirmOutsideDialog
                    isOpen={true}
                    emails={values?.emails}
                    onClose={() => setValues(null)}
                    onConfirm={() => handleInvite(values)}
                />
            )}
        </>
    )
}
