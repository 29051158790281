import { type UserNotificationSettingsType, type UserType } from 'app/types'
import { useDataMutation } from './use-data-mutation'
import { useNotification } from './use-notification'

type RequestBodyType = Partial<{
    notifyAbout: Partial<UserNotificationSettingsType['notifyAbout']>
    notifyVia: UserNotificationSettingsType['notifyVia']
    displayName: UserType['displayName']
    englishFlavor: UserType['englishFlavor']
    secondaryLanguage: UserType['secondaryLanguage']
    organizationRole: UserType['organizationRole']
}>

export const useUpdateProfile = () => {
    const { showErrorNotification } = useNotification()

    const $updateProfile = useDataMutation<RequestBodyType, { message: string }, Error>(
        '/c/v1/profile',
        'PUT',
        {
            onFailure: (error, input) => {
                showErrorNotification('Something went wrong')
                console.error(['UPDATE PROFILE ERROR:'], { error, input })
            }
        }
    )

    return $updateProfile
}
