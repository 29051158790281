import { memo, useState, type MouseEvent } from 'react'

import { type VideoSectionType } from './types'

import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'

const getTimeSuffix = (time: number) => {
    let formattedTime = 0
    let suffix = ''

    /** Seconds */
    if (time < 60) {
        formattedTime = time
        suffix = 's'
        /** Minutes */
    } else if (time < 3600) {
        formattedTime = time / 60
        suffix = 'm'
        /** Hours */
    } else {
        formattedTime = time / 60 / 60
        suffix = 'h'
    }
    return `${Math.round(formattedTime)}${suffix}`
}

const PREFIX = 'Hover-Section'

const classes = {
    container: `${PREFIX}-container`,
    screenShotImg: `${PREFIX}-screenShotImg`,
    chapterName: `${PREFIX}-chapterName`,
    applications: `${PREFIX}-applications`,
    logo: `${PREFIX}-logo`,
    chipRoot: `${PREFIX}-chipRoot`,
    moreApps: `${PREFIX}-moreApps`,
    tooltipContent: `${PREFIX}-tooltipContent`
}

const Wrapper = styled(Box)(({ theme }) => ({
    [`& .${classes.container}`]: {
        [theme.breakpoints.down('md')]: {
            width: '120px'
        },
        [theme.breakpoints.between('md', 'xl')]: {
            width: '200px'
        },
        [theme.breakpoints.up('xl')]: {
            width: '240px'
        },
        maxHeight: '230px',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid gainsboro',
        zIndex: 25,
        position: 'absolute',
        bottom: '50px',
        overflow: 'hidden',
        '&:after': {
            position: 'absolute',
            backgroundColor: 'yellow',
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '5px solid red',
            borderRight: '5px solid red',
            borderTop: '7px solid rgba(0,0,0, .7)'
        }
    },
    [`& .${classes.screenShotImg}`]: {
        width: '100%',
        height: 'auto',
        objectFit: 'cover'
    },
    [`& .${classes.chapterName}`]: {
        maxWidth: '250px',
        webkitLineClamp: 2,
        webkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        wordBreak: 'break-word',
        marginLeft: '4px',
        fontWeight: 'bold'
    },
    [`& .${classes.applications}`]: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        width: '12vw',
        marginTop: '2px'
    },
    [`& .${classes.logo}`]: {
        height: '15px',
        width: 'auto'
    },
    [`& .${classes.chipRoot}`]: {
        padding: '4px',
        margin: '4px 4px 0 0',
        fontWeight: 500,
        fontSize: '12px',
        background: '#eeeeee'
    },
    [`& .${classes.moreApps}`]: {
        position: 'relative'
    },
    [`& .${classes.tooltipContent}`]: {
        display: 'flex',
        alignItems: 'center',
        gap: 2
    }
}))

export type HoverSectionProps = {
    /**
     * The chapter data that includes the start and the end time, thumbnail, title and apps
     * */
    section: VideoSectionType
    /**
     * The index of the chapter out of all the chapters
     * */
    index: number
    /**
     *  Total chapters of the playbook
     */
    totalSections: number
}

export const HoverSection = memo(({ section, index, totalSections }: HoverSectionProps) => {
    const { thumbnail, start, end, duration, title, applications } = section

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const open = Boolean(anchorEl)

    const showMoreApplications = applications && applications?.length > 2

    const applicationsToShow = showMoreApplications ? applications.slice(0, 2) : applications

    const hiddenApplications = showMoreApplications ? applications.length - 2 : 0

    return (
        <Wrapper>
            <Box className={classes.container}>
                <img
                    className={classes.screenShotImg}
                    src={thumbnail} // generateDefaultThumb
                    alt={`${title} screenshot`}
                />
                <Box p={1} mt={1}>
                    <Box display="flex" alignItems="center" mb={0.5}>
                        <Typography variant="body2">
                            <b>{`${index}/${totalSections}`}</b>
                        </Typography>
                        <Typography variant="body2" className={classes.chapterName}>
                            {title}
                        </Typography>
                    </Box>
                    <Typography variant="body2">
                        {getTimeSuffix(
                            duration
                                ? duration
                                : end !== undefined && start !== undefined
                                  ? end - start
                                  : 0
                        )}
                    </Typography>
                    {!!applications?.length && (
                        <Box className={classes.applications}>
                            {applicationsToShow?.map(application => (
                                <img
                                    src={application.applicationLogo}
                                    width="25px"
                                    key={application.applicationId}
                                />
                            ))}

                            {!!hiddenApplications && (
                                <div className={classes.moreApps}>
                                    <Typography
                                        variant="body2"
                                        onMouseEnter={(event: MouseEvent<HTMLElement>) => {
                                            setAnchorEl(event.currentTarget)
                                        }}
                                        onMouseLeave={() => setAnchorEl(null)}
                                    >
                                        +{hiddenApplications} more {open}
                                    </Typography>

                                    <Popover
                                        id="mouse-over-popover"
                                        open={open}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        style={{
                                            pointerEvents: 'none'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                        onClose={() => {
                                            setAnchorEl(null)
                                        }}
                                        disableRestoreFocus
                                    >
                                        <Box className={classes.tooltipContent}>
                                            {applications
                                                ?.slice(-hiddenApplications)
                                                .map(app => (
                                                    <img
                                                        src={app.applicationLogo}
                                                        width="25px"
                                                        key={app.applicationId}
                                                        alt={app.applicationName}
                                                    />
                                                ))}
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Wrapper>
    )
})
