import { useDispatch } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'

import { setActiveStep, setImageStepArea } from 'ducks'
import { paths } from 'app/paths'

type Props = {
    playbookId: string
    visible: boolean
    stepIndex: number
    placement: 'before' | 'after'
    top?: number
    bottom?: number
}

export const AddStep = ({ playbookId, visible, stepIndex, placement, top, bottom }: Props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    if (!visible) return null

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top={top}
            bottom={bottom}
            width="100%"
            zIndex={1}
        >
            <Button
                size="small"
                startIcon={<AddIcon />}
                onClick={() => {
                    dispatch(setActiveStep(stepIndex, true))
                    dispatch(setImageStepArea({ visible: true, placement }))

                    history.push(
                        generatePath(paths.editQuickGuidde, {
                            playbookId
                        })
                    )
                }}
                sx={{
                    backgroundColor: '#FED243',
                    borderRadius: 30,
                    width: 100,
                    color: 'common.black',
                    '&:hover': {
                        backgroundColor: '#cda932'
                    }
                }}
                variant="contained"
            >
                Add Step
            </Button>
        </Box>
    )
}
