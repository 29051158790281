import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Tooltip } from '@mui/material'
import CropIcon from '@mui/icons-material/Crop'

import { ImageCropper } from 'UI/Components'

import { useAuth, useBoolean } from 'hooks'
import { uuid } from 'modules'

import { setStepLayer } from 'ducks'
import { type ImageShapeType, type StepType } from 'app/types'

type Props = {
    currentLayer: ImageShapeType
    windowDimensions: StepType['windowDimensions']
}

export const CropImage = ({ currentLayer, windowDimensions }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()
    const { uid } = useAuth()

    const imageCropper = useBoolean()

    return (
        <>
            <Tooltip title="Crop element">
                <CropIcon
                    data-test="editor-crop-icon"
                    onClick={imageCropper.toggle}
                    style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                />
            </Tooltip>

            {imageCropper.isTrue && (
                <ImageCropper
                    initialPreviewURL={currentLayer.url || ''}
                    tooltipTitle="Revert to Default"
                    title="Drag & Zoom"
                    showReplacePhotoButton={false}
                    isTempStorage
                    storagePath={`imageUploads/${uid}/${playbookId}_${uuid()}`}
                    labelComponent={null}
                    onDone={({ url, width, height }) => {
                        imageCropper.setFalse()

                        dispatch(
                            setStepLayer({
                                layerId: currentLayer.id,
                                layer: {
                                    ...currentLayer,
                                    x: (windowDimensions.innerWidth - width) / 2,
                                    y: (windowDimensions.innerHeight - height) / 2,
                                    url,
                                    width,
                                    height,
                                    scaleX: 1,
                                    scaleY: 1
                                }
                            })
                        )
                    }}
                    onClose={imageCropper.setFalse}
                />
            )}
        </>
    )
}
