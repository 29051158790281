import { links } from 'app/links'

export const PoweredByGuidde = () => (
    <div style={{ marginRight: '2px' }}>
        <a
            href={links.guiddeWebsite}
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: 'none', color: '#000000' }}
        >
            Powered by <strong style={{ color: '#CB0000' }}>guidde</strong>
        </a>
    </div>
)
