import { type ReactNode, memo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { pdf } from '@react-pdf/renderer'

import {
    type BoxProps,
    Popover,
    Box,
    ClickAwayListener,
    Divider,
    Link,
    ListItemIcon,
    MenuItem,
    MenuList,
    Tooltip,
    Typography
} from '@mui/material'

import { ReactComponent as SlideshowIcon } from 'assets/icons/slideshow.svg'
import { ReactComponent as GifIcon } from 'assets/icons/gif_square.svg'
import { ReactComponent as ArticleIcon } from 'assets/icons/article.svg'
import { ReactComponent as ScreenshotMonitorIcon } from 'assets/icons/screenshot_monitor.svg'
import { ReactComponent as MedalIcon } from 'assets/icons/medal.svg'
import { ReactComponent as Captions } from 'assets/icons/captions.svg'
import { ReactComponent as NoCaptions } from 'assets/icons/no_captions.svg'

import { openDemoPage, RoundedWhiteButton, SpacedGroup } from 'UI/Components'

import {
    type UseBooleanType,
    useActivePlaybook,
    useBoolean,
    useBrandKit,
    useFavicon,
    useGlobalFeatureFlags,
    useNotification,
    useOrgFeatureFlags,
    useRoles,
    useServiceUsage
} from 'hooks'
import { showPlanDialog } from 'ducks'
import { isQG, logToAnalytics, playbookToAnalyticsProps, request } from 'modules'

import { type AnyPlaybookType, PlaybookMode } from 'app/types'

import { PdfDocument } from './PdfDocument'
import { PdfSlidesDocument } from './PdfSlidesDocument'

type Props = {
    playbookId: string
    playbook: AnyPlaybookType
    isGifGenerationFinished: boolean
    disableMP4?: boolean
    disableGIF?: boolean
}

export const Download = memo(
    ({ playbook, isGifGenerationFinished, disableGIF, disableMP4, playbookId }: Props) => {
        const loadingMP4 = useBoolean()
        const showMp4Options = useBoolean()
        const loadingGif = useBoolean()

        const anchorRef = useRef<null | any>(null)

        const dispatch = useDispatch()

        const { applications, mode, title } = playbook
        const isPlaylist = mode === PlaybookMode.Playlist

        const { isAdmin } = useRoles()
        const { allowDownloads } = useOrgFeatureFlags()
        const { downloadWithSubtitles } = useGlobalFeatureFlags()

        const { isTrialPlan, isLoading, canHideWatermarkEmbed } = useServiceUsage()
        const { activePlaybook } = useActivePlaybook()

        const isBlocked = isLoading || (isTrialPlan && !allowDownloads)

        const pdfLoading = useBoolean()
        const pdfSlidesLoading = useBoolean()

        const { brandKitHighlightColor, brandKitLoading } = useBrandKit()

        const favIcon = useFavicon(applications)

        const downloadPdfFile = (blob: Blob) => {
            const fileURL = window.URL.createObjectURL(blob)
            // Setting various property values
            const alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `${title.replace(/\./g, '-')}.pdf`
            alink.click()
        }

        const onPdfDownloadSlides = async () => {
            if (!isQG(playbook)) return null

            pdfSlidesLoading.setTrue()

            const blob = await pdf(
                <PdfSlidesDocument onRender={pdfSlidesLoading.setFalse} playbook={playbook} />
            ).toBlob()

            downloadPdfFile(blob)
            logDownloadEvent('Slides PDF')
        }

        const onPdfDownloadA4 = async () => {
            if (!isQG(playbook)) return null

            pdfLoading.setTrue()

            const blob = await pdf(
                <PdfDocument
                    onRender={pdfLoading.setFalse}
                    favIcon={favIcon}
                    playbook={playbook}
                    brandKitHighlightColor={brandKitHighlightColor}
                    hidePoweredByGuidde={Boolean(canHideWatermarkEmbed)}
                />
            ).toBlob()

            downloadPdfFile(blob)
            logDownloadEvent('DocumentPDF')
        }

        const { showErrorNotification } = useNotification()

        // gifUrl and video url can't be accessed directly
        // since it is possible that the page is stale
        const handleDownload = async (
            artifact: 'gif' | 'mp4',
            id: string,
            loading: UseBooleanType,
            includeSubtitles: boolean = false
        ) => {
            try {
                loading.setTrue()
                const res = await request(
                    `/c/v1/quickguidde/download?id=${id}&type=${artifact}&subtitles=${includeSubtitles}`,
                    'GET'
                )

                const artifactRes = await fetch(res.url)
                const blob = await artifactRes.blob()

                const objectURL = URL.createObjectURL(blob)
                // create a tag with attributes
                let a = document.createElement('a')
                a.href = objectURL
                a.setAttribute('download', title.replace(/\./g, '-') + `.${artifact}`)
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                // clean up
                document.body.removeChild(a)
                URL.revokeObjectURL(a.href)
                loading.setFalse()
            } catch (error) {
                loading.setFalse()
                showErrorNotification('Something went wrong')
                return null
            }
        }

        const generateId = () => {
            let id = ''

            if (isPlaylist) {
                if (Number(playbook.playbookList?.length) < 1) return

                // get active playbook id if active pb exist
                // or get first playlist item
                const playlistItemId = playbook.playbookList?.[activePlaybook]
                if (!playlistItemId) return

                id = playlistItemId
            } else id = playbookId

            return id
        }

        const downloadMP4 = (includeSubtitles: boolean) => {
            showMp4Options.setFalse()
            if (disableMP4) return

            const id = generateId()
            if (!id) return

            handleDownload('mp4', id, loadingMP4, includeSubtitles)
            logDownloadEvent('MP4')
        }

        const downloadGIF = () => {
            if (disableGIF) return

            const id = generateId()
            if (!id) return

            handleDownload('gif', id, loadingGif)
            logDownloadEvent('GIF')
        }

        const logDownloadEvent = (type: string) => {
            logToAnalytics('shareModal_exportTab_downloadIcon_clicked', {
                playbookId,
                type,
                ...playbookToAnalyticsProps(playbook)
            })
        }

        const isEmptyPlaylist = isPlaylist && Number(playbook.playbookList?.length) < 1

        return (
            <Box width="100%">
                <Typography
                    data-test="exportTab-download-video-title-text"
                    style={{ fontWeight: 700 }}
                >
                    Download video
                </Typography>

                {isTrialPlan && !allowDownloads && (
                    <Box
                        p="10px"
                        display="flex"
                        alignItems="center"
                        bgcolor="#FFF"
                        borderRadius={4}
                        my={2}
                    >
                        <MedalIcon color="#cb4000" />
                        <Box mx="10px">
                            <Typography
                                data-test="exportTab-downloads-disabled-text"
                                color="textSecondary"
                                style={{ fontSize: 14 }}
                            >
                                Downloads are not available during a trial
                            </Typography>
                        </Box>
                        <Link
                            data-test="exportTab-upgrade-plan-cta"
                            color="primary"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (isAdmin) {
                                    dispatch(showPlanDialog('upgradePlanDialog'))
                                } else {
                                    openDemoPage()
                                }
                            }}
                        >
                            Upgrade the plan
                        </Link>
                    </Box>
                )}
                <SpacedGroup mt={1} spacing={6}>
                    <PlaylistTooltip display={isEmptyPlaylist}>
                        <ButtonContainer label="MP4">
                            <RoundedWhiteButton
                                ref={anchorRef}
                                height={40}
                                data-test="exportTab-download-mp4-button"
                                disabled={disableMP4 || isBlocked || isEmptyPlaylist}
                                isLoading={loadingMP4.isTrue}
                                style={{ zIndex: 4 }}
                                onClick={() => {
                                    if (!downloadWithSubtitles) {
                                        downloadMP4(false)
                                        return
                                    }
                                    showMp4Options.setTrue()
                                }}
                            >
                                <SlideshowIcon />
                            </RoundedWhiteButton>
                        </ButtonContainer>

                        <Popover
                            open={showMp4Options.isTrue}
                            anchorEl={anchorRef?.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        >
                            <ClickAwayListener onClickAway={showMp4Options.setFalse}>
                                <MenuList>
                                    <MenuItem onClick={() => downloadMP4(true)}>
                                        <ListItemIcon style={{ color: '#1C1B1F', minWidth: 30 }}>
                                            <Captions />
                                        </ListItemIcon>
                                        <Typography>Include subtitles</Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={() => downloadMP4(false)}>
                                        <ListItemIcon style={{ color: '#1C1B1F', minWidth: 30 }}>
                                            <NoCaptions />
                                        </ListItemIcon>
                                        <Typography>No subtitles</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Popover>
                    </PlaylistTooltip>
                    <PlaylistTooltip display={isEmptyPlaylist}>
                        <ButtonContainer label="Gif">
                            <Tooltip
                                title={isGifGenerationFinished ? '' : 'processing in progress'}
                            >
                                <Box>
                                    <RoundedWhiteButton
                                        height={40}
                                        data-test="exportTab-download-gif-button"
                                        disabled={
                                            !isGifGenerationFinished ||
                                            disableGIF ||
                                            isBlocked ||
                                            isEmptyPlaylist
                                        }
                                        isLoading={loadingGif.isTrue}
                                        style={{ zIndex: 4 }}
                                        onClick={downloadGIF}
                                    >
                                        <GifIcon />
                                    </RoundedWhiteButton>
                                </Box>
                            </Tooltip>
                        </ButtonContainer>
                    </PlaylistTooltip>

                    {isQG(playbook) && (
                        <>
                            <ButtonContainer label="Document PDF">
                                <RoundedWhiteButton
                                    height={40}
                                    data-test="exportTab-download-documentPDF-button"
                                    disabled={brandKitLoading || isBlocked}
                                    isLoading={pdfLoading.isTrue}
                                    style={{ zIndex: 4 }}
                                    onClick={onPdfDownloadA4}
                                >
                                    <ArticleIcon />
                                </RoundedWhiteButton>
                            </ButtonContainer>

                            <ButtonContainer label="Slides PDF">
                                <RoundedWhiteButton
                                    height={40}
                                    data-test="exportTab-download-slidesPDF-button"
                                    disabled={brandKitLoading || isBlocked}
                                    isLoading={pdfSlidesLoading.isTrue}
                                    style={{ zIndex: 4 }}
                                    onClick={onPdfDownloadSlides}
                                >
                                    <ScreenshotMonitorIcon />
                                </RoundedWhiteButton>
                            </ButtonContainer>
                        </>
                    )}
                </SpacedGroup>
            </Box>
        )
    }
)

type PlaylistTooltipProps = {
    display: boolean
    children: ReactNode
}
const PlaylistTooltip = ({ display, children }: PlaylistTooltipProps) => {
    if (!display) {
        return <>{children}</>
    }

    return (
        <Tooltip title="Playlist is empty">
            <Box>{children}</Box>
        </Tooltip>
    )
}

type ButtonContainerProps = {
    label: string
    children: ReactNode
} & BoxProps

const ButtonContainer = ({ label, children, ...rest }: ButtonContainerProps) => (
    <Box
        flexDirection="column"
        display="flex"
        alignItems="center"
        maxWidth={40}
        width="100%"
        {...rest}
    >
        {children}
        <Box mt={1} />
        <Typography variant="caption" color="textSecondary" noWrap>
            {label}
        </Typography>
    </Box>
)
