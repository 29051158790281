import { useContext } from 'react'
import { useDispatch } from 'react-redux'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    styled,
    Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'

import { ReactComponent as MagicIcon } from 'assets/icons/auto_fix.svg'

import {
    SpacedGroup,
    StepsContext,
    StepsStatus,
    useCanEditPlaybook,
    VideoStatusContext
} from 'UI/Components'

import { ConvertStep } from './ConvertStep'

import {
    useBoolean,
    useBrandKit,
    useDataMutation,
    useOrgFeatureFlags,
    useQuery,
    useServiceUsage
} from 'hooks'
import { type AudioConfigsType, type QuickGuiddeType } from 'app/types'
import { setAudioConfigs } from 'ducks'

const StyledAccordion = styled(Accordion)({
    position: 'fixed',
    bottom: 0,
    right: '4.5rem',
    zIndex: 7,
    borderRadius: '4px',
    overflow: 'hidden',
    '& .MuiAccordionSummary-root': {
        backgroundColor: 'black',
        color: '#fff',
        fontWeight: 600
    },
    '& .MuiAccordionSummary-content': {
        width: '15rem',
        padding: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    '& .Mui-expanded': {
        minHeight: '56px !important',
        margin: '0 !important'
    }
})

type Props = {
    playbook: QuickGuiddeType
}

export const StepsStatusBox = ({ playbook }: Props) => {
    const dispatch = useDispatch()

    const { isLoading: isServiceUsageLoading, hasVoiceOverTextToSpeech } = useServiceUsage()
    const { showWizard, isLoading: isFFLoading } = useOrgFeatureFlags()

    const { brandKitGenerateVoiceoverForNewQG, brandKitLoading } = useBrandKit()

    const canUseAutomaticVoiceover = brandKitGenerateVoiceoverForNewQG && hasVoiceOverTextToSpeech

    useQuery<AudioConfigsType>(
        '/c/v1/config/qg/audio',
        {
            method: 'GET'
        },
        {
            onSuccess: data => dispatch(setAudioConfigs(data)),
            revalidateOnFocus: false
        }
    )

    const $quickGuidde = useDataMutation<
        {
            showAiDialog: boolean
            playbookId: string
            skipDraftCheck: true
            generateAutomaticVoiceoverSkipped: false
        },
        Response,
        Error
    >('/c/v1/quickguidde', 'PUT')

    const closed = useBoolean()

    const setDialogShown = () => {
        return $quickGuidde.mutate({
            playbookId: playbook.id,
            showAiDialog: false,
            skipDraftCheck: true,
            generateAutomaticVoiceoverSkipped: false
        })
    }

    const onClose = () => {
        setDialogShown()
        closed.setTrue()
    }
    const { stepsStatus } = useContext(StepsContext)
    const { converted } = useContext(VideoStatusContext)

    const canEdit = useCanEditPlaybook(playbook)

    const isStepsHidden = stepsStatus !== StepsStatus.done

    // Do not render if there are no steps yet or was closed
    if (!playbook?.steps?.length || closed.isTrue) return null

    // Do not render before animation starts or popup was closed
    if (showWizard || isStepsHidden) return null

    // User doesn't have edit permission
    if (!canEdit) return null

    // FF or service usage data is loading
    if (isFFLoading || isServiceUsageLoading || brandKitLoading) return null

    if (canUseAutomaticVoiceover && !playbook.generateAutomaticVoiceoverSkipped) return null

    const isSmallWidth = !converted && !hasVoiceOverTextToSpeech

    return (
        <StyledAccordion
            defaultExpanded
            sx={{
                width: isSmallWidth ? 230 : 'auto'
            }}
        >
            <AccordionSummary>
                <SpacedGroup spacing={1}>
                    <MagicIcon width={24} height={24} fontSize="2rem" style={{ fontSize: 30 }} />
                    <Typography>Magic capture</Typography>
                </SpacedGroup>

                <Box display="flex">
                    <IconButton color="inherit" size="small">
                        <ExpandMoreIcon color="inherit" />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={event => {
                            event.stopPropagation()
                            onClose()
                        }}
                    >
                        <CloseIcon fontSize="small" color="inherit" />
                    </IconButton>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: 0
                }}
            >
                <ConvertStep
                    isBlocked={!hasVoiceOverTextToSpeech}
                    playbook={playbook}
                    onClose={onClose}
                    onDone={onClose}
                />
            </AccordionDetails>
        </StyledAccordion>
    )
}
