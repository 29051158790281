export const paths = {
    index: '/',
    home: '/home',
    myVideos: '/my-videos',
    playbookDetails: '/playbooks/:playbookId',
    editPlaybook: '/playbooks/:playbookId/edit',
    playlistDetails: '/playbooks/playlist/:playbookId',
    editPlaylist: '/playbooks/playlist/:playbookId/edit',
    searchPlaylist: '/search/playlist/:playbookId',
    searchPlaybook: '/search/playbook/:playbookId',
    editQuickGuidde: '/workflows/:playbookId/edit',
    integrations: '/integrations',
    guiddeInside: '/integrations/guidde-inside',
    broadcast: '/integrations/broadcast',
    profile: '/profile',
    notifications: '/notifications',
    login: '/login',
    signup: '/signup',
    join: '/join/:orgId',
    emailLink: '/emailLink',
    joinSpace: '/join-space/:spaceId/:invitationId',
    appsManagement: '/apps-management',
    backoffice: '/backoffice',
    invite: '/invite',
    accountSettings: '/account-settings',
    organizationSettings: '/account-settings/:orgId',
    slackAuth: '/slack-auth',
    insights: '/insights',
    insightsTable: '/insights/table',
    onboarding: '/onboarding',
    spaces: '/spaces',
    spaceLibrary: '/spaces/library/:spaceId',
    spaceLibraryFolder: '/spaces/library/:spaceId/:folderId/:nestedFolderId?/:nestedFolderId2?',
    spaceDetails: '/spaces/:spaceId',
    activity: '/activity',
    publicPlaybook: '/share/playbooks/:playbookId',
    publicPlaylist: '/share/playlists/:playbookId',
    ssoLogin: '/ssologin',
    ssoTenantLogin: '/ssologin/:tenantId',
    welcome: '/welcome',
    brandKit: '/brand-kit',
    brandKitDetails: '/brand-kit/:id',
    extUninstalled: '/ext-uninstalled',
    trash: '/trash'
} as const
