import { Box, Button, Dialog, LinearProgress, Typography, Divider } from '@mui/material'

import { useSubmitDialogWithEnter } from '../use-submit-dialog-with-enter'

export type Props = {
    isOpen: boolean
    isLoading?: boolean
    title: Array<string>
    submitButtonLabel: string
    body: Array<string>
    onClose: () => void
    onSubmit: () => void
}

const btnStyles = {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25
}

export const CommonTrashDialog = ({
    isOpen,
    isLoading,
    submitButtonLabel,
    title,
    body,
    onClose,
    onSubmit
}: Props) => {
    const { onKeyDown, onKeyUp } = useSubmitDialogWithEnter({ onSubmit })

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
        >
            <Box height={4}>{isLoading && <LinearProgress />}</Box>

            <Box pb={5} pt={5.5}>
                {title.map((row, i) => (
                    <Typography key={i} style={{ fontSize: 34, fontWeight: 700 }} align="center">
                        {row}
                    </Typography>
                ))}

                <Box mt={4} />
                {body.map((row, i) => (
                    <Typography
                        align="center"
                        style={{ color: 'rgba(9, 12, 16, 0.60)', fontSize: 16 }}
                        key={i}
                    >
                        {row}
                    </Typography>
                ))}
            </Box>

            <Divider />

            <Box display="flex" alignItems="center" justifyContent="space-between" px={4} py={2}>
                <Button
                    onClick={onClose}
                    sx={{
                        ...btnStyles,
                        color: 'rgba(0, 0, 0, 0.40)',
                        background: '#EDEEEF',
                        '&:hover': {
                            background: '#EDEEEF'
                        }
                    }}
                    variant="contained"
                >
                    CANCEL
                </Button>
                <Button onClick={onSubmit} disabled={isLoading} sx={btnStyles} variant="contained">
                    {submitButtonLabel}
                </Button>
            </Box>
        </Dialog>
    )
}
