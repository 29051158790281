import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { SourceType } from '@stigg/api-client-js/src/generated/sdk';
import { EntitlementsFallback } from './models';
import { LogConfiguration, LoggerService, LogLevel } from './services/loggerService';

const MIN_ENTITLEMENTS_POLLING_INTERVAL = 5000;

export const SDK_SOURCE_TYPE = SourceType.JsClientSdk;
export const SDK_NAME = 'js-client-sdk';

export type ClientConfiguration = {
  apiKey: string;
  baseUri?: string;
  baseEdgeUri?: string;
  customerId?: string | null;
  resourceId?: string | null;
  customerToken?: string | null;
  entitlementPollingInterval?: number;
  useEntitlementPolling?: boolean;
  logConfiguration?: LogConfiguration;
  lazyLoad?: boolean;
  enableEdge?: boolean;
  entitlementsFallback?: EntitlementsFallback;
};

const defaults = (): Partial<ClientConfiguration> => {
  const baseUri = 'https://api.stigg.io';
  return {
    baseUri,
    baseEdgeUri: getBaseEdgeApiUri(baseUri),
    entitlementPollingInterval: 30000,
    useEntitlementPolling: true,
    logConfiguration: {
      logger: console,
      logLevel: LogLevel.ERROR,
    },
    enableEdge: true,
  };
};

export function validateConfiguration(configuration: ClientConfiguration, loggerService: LoggerService) {
  if (isEmpty(configuration.apiKey)) {
    const error = 'You must configure the client with an SDK key';
    loggerService.error(error);
    throw new Error(error);
  }

  const defaultConfig = defaults();
  if (
    configuration.useEntitlementPolling &&
    configuration.entitlementPollingInterval &&
    configuration.entitlementPollingInterval < MIN_ENTITLEMENTS_POLLING_INTERVAL
  ) {
    loggerService.error(
      `Entitlements polling interval cannot be lower than 5000ms, using default value ${defaultConfig.entitlementPollingInterval}`,
    );
    configuration.entitlementPollingInterval = defaultConfig.entitlementPollingInterval!;
  }
}

export function getConfiguration(configuration: ClientConfiguration) {
  const defaultConfig = defaults();
  const baseEdgeUri = configuration.baseEdgeUri
    ? configuration.baseEdgeUri
    : configuration.baseUri
    ? getBaseEdgeApiUri(configuration.baseUri)
    : defaultConfig.baseEdgeUri;

  return merge(defaultConfig, configuration, { baseEdgeUri }) as Required<ClientConfiguration>;
}

function getBaseEdgeApiUri(apiUrl: string) {
  const url = new URL(apiUrl);

  if (url.hostname === 'localhost') {
    url.port = '3060';
  } else {
    url.hostname = `edge.${url.hostname}`;
  }

  // Remove last char since URL.toString() adds a redundant backslash
  return url.toString().slice(0, -1);
}

export function ensureCustomerRefIdExists({ customerId }: ClientConfiguration) {
  if (!customerId || isEmpty(customerId)) {
    throw new Error('You must set customer ID first, use setCustomerId function or pass it via the constructor');
  }
  return customerId;
}
