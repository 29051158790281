import { useMemo, useRef } from 'react'

import dayjs from 'dayjs'

import { MenuItem, Select, Stack, Typography } from '@mui/material'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'

import { useBoolean, useServiceUsage } from 'hooks'
import { logToAnalytics } from 'modules'

import {
    type InsightsPeriodSelectValue,
    InsightsPeriod,
    insightsPeriodSchema
} from 'app/types/insights'

import { insightsDefaultPeriod, insightsPeriodDates, insightsPeriodLabel } from 'UI/Components'

import { DialogSelectCustomRange } from './DialogSelectCustomRange'

type Props = {
    value: InsightsPeriodSelectValue
    onSelectPeriod: (value: InsightsPeriodSelectValue) => void
    analyticsEventSource: string
}

export const InsightsPeriodSelect = ({ value, onSelectPeriod, analyticsEventSource }: Props) => {
    const { insightsDays } = useServiceUsage()

    const insightsDaysQuota = insightsDays || insightsPeriodSchema[insightsDefaultPeriod]

    const periodMenu = useBoolean()
    const dialogSelectDateRange = useBoolean(false)

    const selectDateRangeAnchorRef = useRef<HTMLDivElement | null>(null)

    const insightsPeriodOptions = useMemo(() => {
        const allowedPeriods = (
            [
                InsightsPeriod.Week,
                InsightsPeriod.Month,
                InsightsPeriod.Quarter,
                InsightsPeriod.HalfYear
            ] as const
        ).filter(period => insightsPeriodSchema[period] <= insightsDaysQuota)

        return [...allowedPeriods, InsightsPeriod.Custom]
    }, [insightsDaysQuota])

    const { period, dateRange } = value

    const periodStartDate =
        period === InsightsPeriod.Custom ? dateRange?.start : insightsPeriodDates[period].start
    const periodEndDate =
        period === InsightsPeriod.Custom ? dateRange?.end : insightsPeriodDates[period].end

    return (
        <>
            {dialogSelectDateRange.isTrue && (
                <DialogSelectCustomRange
                    onClose={dialogSelectDateRange.setFalse}
                    onApply={({ start, end }) => {
                        onSelectPeriod({ period: InsightsPeriod.Custom, dateRange: { start, end } })

                        logToAnalytics('insights_dateRange_submitBtn_clicked', {
                            start: start.format('MMM D, YYYY'),
                            end: end.format('MMM D, YYYY'),
                            source: analyticsEventSource
                        })
                    }}
                    initialStartDate={dateRange?.start ?? null}
                    initialEndDate={dateRange?.end ?? null}
                    anchorElement={selectDateRangeAnchorRef.current}
                    maxPeriodLengthInDays={insightsDaysQuota}
                    analyticsEventSource={analyticsEventSource}
                />
            )}

            <Select
                value={period}
                sx={{
                    width: 235,
                    p: 0,
                    '& .MuiSelect-select': {
                        p: 0,
                        pl: 2,
                        pr: 1
                    },
                    '& fieldset': {
                        border: 'none'
                    },
                    '& .MuiSelect-iconOutlined': {
                        display: 'none'
                    }
                }}
                onOpen={() => {
                    periodMenu.setTrue()
                    logToAnalytics('insights_date_clicked', { source: analyticsEventSource })
                }}
                onClose={periodMenu.setFalse}
                ref={selectDateRangeAnchorRef}
                startAdornment={
                    <Stack
                        width={40}
                        height={40}
                        sx={{
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(9, 12, 16, 0.1)',
                            flexShrink: 0
                        }}
                    >
                        <CalendarTodayIcon />
                    </Stack>
                }
                renderValue={() => (
                    <Stack>
                        <Stack direction="row" spacing={0.75} alignItems="center">
                            <Typography fontSize={14} lineHeight="16px" fontWeight={500}>
                                {insightsPeriodLabel[period]}
                            </Typography>
                            <ArrowDropDownIcon
                                sx={{
                                    transform: periodMenu.isTrue ? 'rotate(180deg)' : undefined
                                }}
                            />
                        </Stack>
                        <Typography
                            fontSize={14}
                            lineHeight="16px"
                            fontWeight={500}
                            color="rgba(9, 12, 16, 0.6)"
                        >
                            {`${dayjs(periodStartDate).format('MMM D, YYYY')} - ${dayjs(
                                periodEndDate
                            ).format('MMM D, YYYY')}`}
                        </Typography>
                    </Stack>
                )}
                variant="outlined"
            >
                {insightsPeriodOptions.map(option => (
                    <MenuItem
                        key={option}
                        value={option}
                        divider
                        sx={{
                            height: 48,
                            '&&.Mui-selected': {
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                },
                                backgroundColor: '#F5F5F5'
                            },
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                        onClick={() => {
                            logToAnalytics('insights_dateSelect_clicked', {
                                state: option,
                                source: analyticsEventSource
                            })
                            if (option === InsightsPeriod.Custom) {
                                dialogSelectDateRange.setTrue()
                                return
                            }

                            onSelectPeriod({ period: option, dateRange: null })
                        }}
                    >
                        <Typography fontSize={14} lineHeight="24px" fontWeight={500}>
                            {insightsPeriodLabel[option]}
                        </Typography>
                        {period === option && <CheckIcon />}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}
