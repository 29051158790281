import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Button, ClickAwayListener, Paper, Popper, TextField, Typography } from '@mui/material'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { setStepTitle } from 'ducks'
import { useBoolean } from 'hooks'

type Props = {
    title?: string
}

export const StepTitle = ({ title = '' }: Props) => {
    const dispatch = useDispatch()

    const hover = useBoolean()
    const open = useBoolean()

    const anchorEl = useRef<null | HTMLElement>(null)
    const [value, setValue] = useState(title)

    useEffect(() => {
        setValue(title)
    }, [title])

    return (
        <>
            <TextOverflow lineClamp={1}>
                <Typography
                    ref={anchorEl}
                    variant="body2"
                    onMouseDown={open.setTrue}
                    onMouseEnter={hover.setTrue}
                    onMouseLeave={hover.setFalse}
                    sx={{
                        fontSize: '12px',
                        color: 'rgba(9, 12, 16, 0.6)',
                        fontStyle: 'italic',
                        margin: '6px 0 4px 0',
                        background: hover.isTrue ? 'lightgray' : 'transparent'
                    }}
                >
                    {title || 'Untitled step'}
                </Typography>
            </TextOverflow>

            <Popper open={open.isTrue} anchorEl={anchorEl?.current} sx={{ zIndex: 1 }}>
                <ClickAwayListener
                    disableReactTree={true}
                    mouseEvent="onMouseDown"
                    onClickAway={open.setFalse}
                >
                    <Paper>
                        <Box p={2}>
                            <TextField
                                size="small"
                                value={value}
                                multiline
                                variant="standard"
                                inputProps={{ style: { fontSize: 14 } }}
                                onChange={e => setValue(e.target.value)}
                            />

                            <Box mt={3} display="flex" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                        dispatch(setStepTitle(value))
                                        open.setFalse()
                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
