import { memo } from 'react'
import { useHistory } from 'react-router-dom'

import { styled, Box, Typography } from '@mui/material'

import { ReactComponent as BackIcon } from 'assets/icons/arrow-outline.svg'

import { paths } from 'app/paths'
import { type IconType } from 'app/menu'

const PREFIX = 'BackButton'

const classes = {
    black: `${PREFIX}-black`,
    white: `${PREFIX}-white`
}

const StyledBox = styled(Box)({
    [`& .${classes.black}`]: {
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    [`& .${classes.white}`]: {
        color: 'white',
        textDecoration: 'none',
        '& *': {
            fontSize: '1rem',
            fontWeight: 'bold'
        },
        '&:hover': {
            color: 'white',
            textDecoration: 'underline'
        }
    }
})

type Props = {
    text?: string
    color?: 'white' | 'black'
    iconColor?: 'white' | 'black'
    icon?: IconType
}

export const BackButton = memo(({ text = 'Back', color = 'black', icon, iconColor }: Props) => {
    const history = useHistory()

    const Icon = icon || BackIcon

    return (
        <StyledBox
            display="flex"
            alignItems="center"
            onClick={() => {
                // check if the user has just opened the page
                if (history.length > 1) {
                    history.go(-1)
                } else {
                    history.push(paths.home)
                }
            }}
            style={{ cursor: 'pointer' }}
            className={classes[color]}
            data-test="back-button"
        >
            <Icon style={{ color: iconColor }} />
            <Typography component="span">{text}</Typography>
        </StyledBox>
    )
})
