import { useEffect, useState, memo } from 'react'

import { Autocomplete, TextField, Chip, Typography, Popper } from '@mui/material'

import { objectOption } from 'modules'
import { type PlaybookApplicationType } from 'app/types'

const generateOptions = (value: Array<PlaybookApplicationType>): any => {
    return value.map(option => {
        // rest is applicationLogo and applicationId
        const { applicationName, ...rest } = option
        return objectOption(rest, applicationName)
    })
}

type Props = {
    value: Array<PlaybookApplicationType>
    options: Array<{
        value: { applicationId: string; applicationLogo: string }
        label: string
    }>
    disabled?: boolean
    canEdit?: boolean
    limit?: number | null
    chipBackground?: string
    onChange?: (data: Array<PlaybookApplicationType>, deletedApp?: PlaybookApplicationType) => void
    onFocus?: () => void
    onBlur?: () => void
}

const NO_OPTIONS = 'No options'
const START_TYPING = 'Start typing'

export const MultipleApplications = memo(
    ({
        value = [],
        options = [],
        disabled,
        canEdit,
        limit = 10,
        chipBackground = 'white',
        onChange = () => {},
        onFocus,
        onBlur
    }: Props) => {
        const [selectedOptions, setSelectedOptions] = useState(generateOptions(value))

        const [optionsPlaceholder, setOptionsPlaceholder] = useState(START_TYPING)

        /*
            In case the user did not enter any search string return an empty results set
            Return only prefix match (startsWith)
        */
        const extendAutoComplete = (options: any, state: { inputValue: string }) => {
            if (state?.inputValue?.length <= 0) {
                setOptionsPlaceholder(START_TYPING)
                return []
            }
            const searchString = state?.inputValue?.toLowerCase()

            const res = options.filter((item: { label: string; value: string }) =>
                item?.label?.toLowerCase()?.startsWith(searchString)
            )

            if (!res.length) setOptionsPlaceholder(NO_OPTIONS)

            return res
        }

        useEffect(() => {
            setSelectedOptions(generateOptions(value))
        }, [value])

        if (selectedOptions.length === 0 && disabled) return null

        return (
            <>
                <Autocomplete
                    PopperComponent={({ style, ...props }) => (
                        <Popper {...props} style={{ ...style, height: 0 }} />
                    )}
                    noOptionsText={optionsPlaceholder}
                    filterOptions={extendAutoComplete}
                    multiple={true}
                    disableClearable={!canEdit}
                    disabled={disabled}
                    value={selectedOptions}
                    placeholder="Add Applications"
                    onChange={(_, newValue, reason, option) => {
                        if (limit !== null && newValue.length > limit) {
                            return
                        }
                        setSelectedOptions(newValue)

                        const deletedApp =
                            reason === 'removeOption'
                                ? {
                                      applicationName: option?.option.label,
                                      ...option?.option.value
                                  }
                                : undefined

                        const result: Array<PlaybookApplicationType> = newValue.map(option => ({
                            applicationName: option.label,
                            ...option.value
                        }))
                        onChange(result, deletedApp)
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    getOptionDisabled={option => {
                        return (
                            selectedOptions.length === 1 &&
                            selectedOptions[0].value.applicationId === option.value.applicationId
                        )
                    }}
                    options={options.sort((a, b) => (a.label > b.label ? 1 : -1))}
                    getOptionLabel={option => option?.label || ''}
                    isOptionEqualToValue={(option, value) =>
                        option.value.applicationId === value.applicationId
                    }
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={index}
                                label={option.label}
                                style={{
                                    background: chipBackground
                                }}
                                sx={{
                                    border: 'none',
                                    fontSize: '12px',
                                    textDecoration: 'none',
                                    textTransform: 'lowercase',
                                    color: 'black',
                                    marginLeft: '-8px',
                                    marginRight: '12px',
                                    '& .MuiChip-deleteIcon': {
                                        fontSize: '12px',
                                        color: '#2D9CDB',
                                        width: '15px',
                                        height: '15px',
                                        marginLeft: '0 0 0 -6px !important',
                                        display: canEdit ? 'block' : 'none'
                                    }
                                }}
                                data-test="application-chip"
                            />
                        ))
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: disabled || !canEdit
                            }}
                            sx={{
                                '& .MuiInput-input': {
                                    fontSize: 12
                                },
                                '& .MuiChip-root': {
                                    margin: 0,
                                    height: 24
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                    display: 'none'
                                },
                                ...(!canEdit && {
                                    caretColor: 'transparent',
                                    pointerEvents: 'none'
                                })
                            }}
                            placeholder={canEdit ? '+ Select Apps from the list' : ''}
                            InputLabelProps={{
                                shrink: true
                            }}
                            data-test="add-applications"
                        />
                    )}
                    data-test="applications-autocomplete"
                />
                {limit !== null && selectedOptions.length >= limit && canEdit && (
                    <Typography color="error" variant="caption">
                        You can define up to {limit} applications
                    </Typography>
                )}
            </>
        )
    }
)
