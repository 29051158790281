import { type MutableRefObject, memo, useContext } from 'react'
import { Provider, useSelector } from 'react-redux'

import type Konva from 'konva'
import { Stage } from 'react-konva'

import { QGContext, Shapes } from 'UI/Routes/quick-guidde/CanvasEditor'
import { store } from 'ducks/store'

type CanvasSlideProps = {
    stepIndex: number
    isActive?: boolean
    stageRefs: MutableRefObject<Array<Konva.Stage | null>>
}

export const CanvasSlide = memo(({ stageRefs, stepIndex, isActive }: CanvasSlideProps) => {
    const { frameWidth, frameHeight, scale } = useContext(QGContext)

    const {
        present: { selectedLayersIds, steps }
    } = useSelector(state => state.qgEditor)

    return (
        <Stage
            ref={ref => (stageRefs.current[stepIndex] = ref)}
            width={frameWidth}
            height={frameHeight}
            scaleX={scale}
            scaleY={scale}
            style={{
                display: isActive ? 'flex' : 'none',
                justifyContent: 'center'
            }}
        >
            {/* Workaround based on this post: https://github.com/konvajs/react-konva/issues/311#issuecomment-454411007 */}
            <Provider store={store}>
                {/* All supported shapes/layers */}
                <Shapes
                    scale={scale}
                    step={steps[stepIndex]}
                    selectedLayersIds={selectedLayersIds}
                    isSmartPreview={true}
                />
            </Provider>
        </Stage>
    )
})
