import { PlaybookPauseScreen } from '@guidde-co/shared.playbook-pause-screen'

import { host, openLink } from 'modules'
import { useBoolean, useWindowView } from 'hooks'

import { paths } from 'app/paths'
import { type AnyPlaybookType, type VideoOverlayDataType, type VideoRefType } from 'app/types'

import { VideoOverlayContainer } from './VideoOverlayContainer'

export const checkToOpenPlaybookPauseScreen = ({
    videoRef,
    overlayData,
    openPauseScreen,
    isPauseScreenShowed
}: {
    isPauseScreenShowed: boolean
    videoRef: VideoRefType
    overlayData: VideoOverlayDataType
    openPauseScreen: () => void
}) => {
    if (isPauseScreenShowed) {
        return
    }

    const currentTime = videoRef.current?.currentTime

    if (currentTime === 0 || currentTime === videoRef.current?.duration) {
        return
    }

    if ((overlayData.isFreePlan || overlayData.isTrialPlan) && !overlayData.hidePauseScreen) {
        openPauseScreen()
    }
}

type Props = {
    playbook: AnyPlaybookType
}

export const PlaybookPauseScreenWrapper = ({ playbook }: Props) => {
    const showPauseScreen = useBoolean(true)

    const { isMobileView } = useWindowView()

    const onSignUpClick = () => {
        const utmParams = new URLSearchParams({
            utm_source: 'guidde-app',
            utm_medium: 'pause-screen',
            utm_campaign: 'get-guidde',
            utm_term: playbook.id,
            utm_content: playbook.uploadedByOrgId
        })

        const url = `${host}${paths.signup}?${utmParams}`
        openLink(url)
    }

    if (showPauseScreen.isTrue) {
        return (
            <VideoOverlayContainer>
                <PlaybookPauseScreen
                    isMobileView={isMobileView}
                    authorName={playbook.uploadedBy?.displayName || ''}
                    onClose={showPauseScreen.setFalse}
                    onSingUpClick={onSignUpClick}
                />
            </VideoOverlayContainer>
        )
    }

    return null
}
