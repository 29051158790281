import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAnonymAuth, useAuth, useBoolean, useQueryParams, useUnloadBeacon } from 'hooks'
import { globalProperties, logToAnalytics, playbookToAnalyticsProps, userProperties } from 'modules'

import { type QuickGuiddeType } from 'app/types'

type Props = {
    playbook: QuickGuiddeType
    watchedSteps: number
}

export const useWatchedStepsReporter = ({ playbook, watchedSteps }: Props) => {
    const { isAnonymous } = useAnonymAuth()
    const { uid } = useAuth()

    const params = useQueryParams()

    const sent = useBoolean()
    const setSent = sent.setTrue

    const origin = params.get('origin') || ''
    const utmCampaign = params.get('track_link_name') || ''
    const campaignId = params.get('track_link_id') || ''

    const [timeoutId, setTimeoutId] = useState<number>(0)

    const isOwner = playbook.creator_uid === uid

    const eventData = useMemo(() => {
        const totalSteps = Number(playbook.steps?.filter(step => step.kind === 'step')?.length)

        return {
            event: 'docView',
            url: window.location.href,
            videoMetaData: {
                percentage: Math.min(100, Math.round((watchedSteps / totalSteps) * 100)),
                totalSegments: totalSteps,
                segmentsWatched: watchedSteps
            },
            origin,
            ...playbookToAnalyticsProps(
                {
                    ...playbook,
                    campaignId,
                    utmCampaign
                },
                isAnonymous
            )
        }
    }, [campaignId, isAnonymous, playbook, utmCampaign, watchedSteps, origin])

    const trackDocViewEvent = useCallback(() => {
        if (sent.isTrue || watchedSteps < 1 || isOwner) return

        logToAnalytics('docView', eventData)
        setSent()
    }, [sent.isTrue, watchedSteps, eventData, setSent, isOwner])

    // Send videoPlay if user switched to another tab for more than 30min
    useEffect(() => {
        // remove timeout and listener if api call has already been sent or user clicked on play
        if (sent.isTrue) {
            if (timeoutId) {
                window.onblur = null
                clearTimeout(timeoutId)
                setTimeoutId(0)
            }
        }

        // add timeout on blur
        if (sent.isFalse) {
            window.onblur = () => {
                // if we have active timeout - we need to clear it before start a new one
                if (timeoutId) {
                    window.onblur = null
                    clearTimeout(timeoutId)
                    setTimeoutId(0)
                }

                const id = setTimeout(
                    () => {
                        trackDocViewEvent()
                        clearTimeout(id)
                    },
                    60 * 1000 * 30
                ) // 30 minutes

                // React doesn't have built in Timeout type
                setTimeoutId(id as unknown as number)
            }
        }
    }, [sent.isFalse, sent.isTrue, trackDocViewEvent, timeoutId])

    useUnloadBeacon(
        {
            url: '/tk/v1/eov',
            data: {
                eventData,
                userProperties,
                globalProperties
            }
        },
        sent.isFalse && watchedSteps > 0 && !isOwner,
        true
    )
}
