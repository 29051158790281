import { type MutableRefObject, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, MenuList, Paper } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FlipToFrontIcon from '@mui/icons-material/FlipToFront'

import type Konva from 'konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { NestedMenuItem } from 'UI/Components'

import { applyLayerToAllSteps, selectLayer, setLayerOrder } from 'ducks'
import { type QuickGuiddeLayerType, Shape } from 'app/types'

export const getMinLayerIndex = (layers: Array<QuickGuiddeLayerType>) => {
    return layers.filter(layer => {
        const specialLayer = [Shape.Overlay, Shape.BrowserBar].includes(layer.type)
        const bgLayer = 'isBackground' in layer && Boolean(layer.isBackground)
        return specialLayer || bgLayer
    }).length
}

const disabledLayersName = [
    'watermark',
    'background',
    'overlay',
    'browserBar',
    'video',
    'rectangleSpotlight',
    'circleSpotlight'
]

type OrderType = 'front' | 'forward' | 'backward' | 'back'

type Props = {
    stageRef: MutableRefObject<Konva.Stage | null>
}

export const ContextMenu = ({ stageRef }: Props) => {
    const dispatch = useDispatch()

    const [contextProps, setContextProps] = useState<null | {
        layerId: string
        x: number
        y: number
    }>(null)

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { layers } = steps[activeStep]
    const layerIndex = layers.findIndex(layer => layer.id === contextProps?.layerId)

    const minIndex = getMinLayerIndex(layers)

    const isFirstLayer = layerIndex === minIndex
    const isLastLayer = layerIndex === layers.length - 1

    const changeOrder = (order: OrderType) => {
        if (!contextProps) return
        dispatch(setLayerOrder({ order, layerId: contextProps.layerId }))
    }

    const stage = stageRef.current

    useEffect(() => {
        if (!stage) return

        const handleContextMenu = (e: KonvaEventObject<MouseEvent>) => {
            e.evt.preventDefault()

            const { id, name } = (e.target as Konva.Shape).attrs
            // In case of text layer, we need to get the parent <Label /> node
            const { id: parentId, name: parentName } = (e.target as Konva.Shape).parent?.attrs
            if (disabledLayersName.includes(name || parentName)) return setContextProps(null)

            const pointerPosition = stage.getPointerPosition()
            const { top = 0, left = 0 } = stage.attrs.container?.getBoundingClientRect()

            dispatch(selectLayer(id || parentId))

            setContextProps({
                layerId: id || parentId,
                x: left + pointerPosition?.x + 10,
                y: top + pointerPosition?.y
            })
        }

        stage.on('contextmenu', handleContextMenu)

        return () => {
            stage.off('contextmenu', handleContextMenu)
        }
    }, [dispatch, stage])

    useEffect(() => {
        const handleClose = (e: MouseEvent) => {
            const target = e.target as HTMLElement

            if (target.id === 'context-menu') return
            setContextProps(null)
        }

        document.addEventListener('click', handleClose)
        return () => document.removeEventListener('click', handleClose)
    }, [])

    if (!contextProps) return null

    return (
        <Box
            id="context-menu"
            position="fixed"
            top={contextProps.y}
            left={contextProps.x}
            zIndex={1}
            boxShadow="0px 1px 4px 2px rgba(0, 0, 0, 0.23)"
        >
            <Paper>
                <MenuList style={{ padding: 0 }}>
                    <NestedMenuItem
                        icon={FlipToFrontIcon}
                        text="Order"
                        width={185}
                        nestedItems={[
                            {
                                text: 'Bring to front',
                                disabled: isLastLayer,
                                onClick: () => changeOrder('front')
                            },
                            {
                                text: 'Bring forward',
                                disabled: isLastLayer,
                                onClick: () => changeOrder('forward')
                            },
                            {
                                text: 'Send backward',
                                disabled: isFirstLayer,
                                onClick: () => changeOrder('backward')
                            },
                            {
                                text: 'Send to back',
                                disabled: isFirstLayer,
                                onClick: () => changeOrder('back')
                            }
                        ]}
                        endWithDivider
                    />
                    <NestedMenuItem
                        icon={ContentCopyIcon}
                        width={185}
                        text="Apply to all steps"
                        onClick={() => dispatch(applyLayerToAllSteps(contextProps?.layerId))}
                    />
                </MenuList>
            </Paper>
        </Box>
    )
}
