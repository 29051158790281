import * as types from 'ducks/types'

import { type ActionType, type Reducer } from 'ducks/common'

type StateType = {
    [key: string]: {
        playbookId: string
        fileName: string
        source: 'playbooks' | 'gdrive' | 'onedrive' | 'zoom' | 'youtube'
    }
}
const initialState: StateType = {}

export const videoProgressReducer: Reducer<StateType> = (
    state = initialState,
    action: ActionType
): StateType => {
    switch (action.type) {
        case types.SET_VIDEO_PROGRESS: {
            const { payload } = action
            return {
                [payload.playbookId]: {
                    playbookId: payload.playbookId,
                    fileName: payload.fileName,
                    source: payload.source
                },
                ...state
            }
        }
        case types.RESET_VIDEO_PROGRESS: {
            return Object.fromEntries(
                Object.entries(state).filter(([key]) => key !== action.payload)
            )
        }
        default: {
            return state
        }
    }
}
