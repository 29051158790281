import MaskedInput from 'react-input-mask'

import { FormControlLabel, Checkbox, Typography, TextField } from '@mui/material'

import { type UseBooleanType } from 'hooks'

import { SpacedGroup } from 'UI/Components'

type Props = {
    setStartTime: (val: string) => void
    onCheckBoxChange?: (val: boolean) => void
    onTimeChange?: () => void
    startTime: string
    startAt: UseBooleanType
}
export const StartAtInput = ({
    setStartTime,
    onCheckBoxChange,
    onTimeChange,
    startAt,
    startTime
}: Props) => (
    <SpacedGroup>
        <FormControlLabel
            control={
                <Checkbox
                    checked={startAt.isTrue}
                    color="primary"
                    onChange={(e: any, val) => {
                        onCheckBoxChange?.(val)
                        startAt.set(val)
                    }}
                    name="checkedA"
                />
            }
            label={<Typography color="textSecondary">Start at</Typography>}
        />

        <MaskedInput
            mask="99:99:99"
            maskPlaceholder="-"
            placeholder="00:00:00"
            value={startTime}
            onChange={event => {
                setStartTime(event.target.value)
                onTimeChange?.()
            }}
        >
            <TextField
                style={{ width: '70px' }}
                size="small"
                inputProps={{
                    style: {
                        background: '#FFFFFF',
                        textAlign: 'center',
                        borderRadius: '4px 4px 0 0',
                        color: 'rgba(0, 0, 0, 0.54)'
                    }
                }}
                variant="standard"
            />
        </MaskedInput>
    </SpacedGroup>
)
