import { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useImage from 'use-image'

import Konva from 'konva'
import { Image } from 'react-konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import { type BlurShapeType, type ImageShapeType, type OverlayShapeType, Shape } from 'app/types'
import { setLayerTransform } from 'ducks'
import { round } from 'modules'

type Props = {
    layer: BlurShapeType
    onSelect: (shiftPressed: boolean) => void
    onDragStart: () => void
    onDragMove: (_e: KonvaEventObject<DragEvent>) => void
    onDragEnd: (_e: KonvaEventObject<DragEvent>) => void
}

export const BlurShape = memo(({ onSelect, layer, onDragStart, onDragMove, onDragEnd }: Props) => {
    const dispatch = useDispatch()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const bgImageLayer = steps[activeStep]?.layers.find(
        layer => layer.type === Shape.Image && layer.isBackground
    ) as ImageShapeType | undefined

    const overlayLayer = steps[activeStep]?.layers.find(layer => layer.type === Shape.Overlay) as
        | OverlayShapeType
        | undefined

    const shapeRef = useRef<Konva.Image>(null)

    const [image] = useImage(bgImageLayer?.url || '', 'anonymous')

    const [blurProps, setBlurProps] = useState(layer)

    useEffect(() => {
        setBlurProps(layer)
    }, [layer])

    useEffect(() => {
        if (!image || !shapeRef.current) return
        const shape = shapeRef.current as Konva.Image
        shape.filters([Konva.Filters.Blur, Konva.Filters.Brighten])

        shape.blurRadius(15)
        shape.brightness(overlayLayer ? -0.5 : 0)

        shape.cache({ pixelRatio: 0.5 })
    }, [image, blurProps, overlayLayer])

    if (!bgImageLayer || !image) return null

    return (
        <Image
            id={layer.id}
            name="blur"
            className="alignable"
            ref={shapeRef}
            image={image}
            x={blurProps.x}
            y={blurProps.y}
            scaleX={bgImageLayer.scaleX}
            scaleY={bgImageLayer.scaleY}
            width={blurProps.width}
            height={blurProps.height}
            cropX={(blurProps.x - bgImageLayer.x) / bgImageLayer.scaleX}
            cropY={(blurProps.y - bgImageLayer.y) / bgImageLayer.scaleY}
            cropWidth={blurProps.width}
            cropHeight={blurProps.height}
            onClick={e => onSelect(e.evt.shiftKey)}
            draggable
            onDragStart={onDragStart}
            onDragMove={e => {
                setBlurProps({
                    ...blurProps,
                    x: e.target.x(),
                    y: e.target.y()
                })
                onDragMove(e)
            }}
            onDragEnd={onDragEnd}
            onTransform={() => {
                const node = shapeRef.current
                if (!node) return

                const scaleX = node.scaleX()
                const scaleY = node.scaleY()

                // Reserve the scale of the image
                node.scaleX(bgImageLayer.scaleX)
                node.scaleY(bgImageLayer.scaleY)

                setBlurProps(prevState => ({
                    ...prevState,
                    x: node.x(),
                    y: node.y(),
                    width: (node.width() / node.scaleX()) * scaleX,
                    height: (node.height() / node.scaleY()) * scaleY,
                    rotation: node.rotation()
                }))
            }}
            onTransformEnd={() => {
                const node = shapeRef.current
                if (!node) return

                const scaleX = node.scaleX()
                const scaleY = node.scaleY()

                // Reserve the scale of the image
                node.scaleX(bgImageLayer.scaleX)
                node.scaleY(bgImageLayer.scaleY)

                dispatch(
                    setLayerTransform({
                        layerId: layer.id,
                        x: round(node.x()),
                        y: round(node.y()),
                        width: round((node.width() / node.scaleX()) * scaleX),
                        height: round((node.height() / node.scaleY()) * scaleY),
                        rotation: node.rotation()
                    })
                )
            }}
        />
    )
})
