import * as types from 'ducks/types'

type SourceType = 'zoom' | 'onedrive' | 'gdrive' | 'upload' | 'youtube'

type UploadDataType = 'video' | 'playlist'

type FilesType = {
    [key: string]: {
        id: string
        name: string
        progress?: number
        thumbnail?: string
        source: SourceType
        type: UploadDataType
    }
}

// type - video or playlist
export const openUploadProgressPopup = (files: FilesType) =>
    ({
        type: types.OPEN_UPLOAD_PROGRESS_POPUP,
        payload: { files }
    }) as const

export const closeUploadProgressPopup = () =>
    ({
        type: types.CLOSE_UPLOAD_PROGRESS_POPUP
    }) as const

export const updateFileProgress = (fileId: string, progress: number) =>
    ({
        type: types.UPDATE_FILE_PROGRESS,
        payload: { fileId, progress }
    }) as const
