import { type MouseEvent, useState, memo } from 'react'

import { styled, ListItemText, Box, Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

import { type KeyedMutator } from 'swr'

import { ReactComponent as DeleteIcon } from 'assets/icons/trash-outlined.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'

import { ConfirmationDialog } from 'UI/Components'
import { type Dictionary } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/QgTranscript/PronunciationText'

import { useAuth, useDataMutation, useNotification, useRoles } from 'hooks'
import { logToAnalytics } from 'modules'

const StyledGrid = styled(DataGrid)(() => ({
    '& .MuiDataGrid-cell': {
        outline: 'none!important',
        cursor: 'pointer'
    }
}))

export const processDictionaryData = (newDictionary: Dictionary): Array<DictionaryData> => {
    return Object.keys(newDictionary.words)
        .map(language => {
            return Object.keys(newDictionary.words[language]).map((word: string, idx) => {
                const details = newDictionary.words[language][word]

                const { phonetic, type } = details
                return {
                    id: `${idx}-${word}-${language}`,
                    word,
                    language,
                    phonetic,
                    type,
                    alternative: type === 'sub' ? phonetic : '',
                    ipa: type === 'phoneme' ? phonetic : '',
                    actions: ''
                }
            })
        })
        .flat()
}

type GridRowData = {
    [key: string]: any
}

type DictionaryData = {
    id: string
    word: string
    language: string
    phonetic: string
    type: string
    alternative: string
    ipa: string
    actions: string
}

type Props = {
    newDictionary: Dictionary
    mutate: KeyedMutator<Dictionary>
    onDictionaryEdit: (anchorEl: HTMLElement | null, row: GridRowData) => void
}

export const DictionaryList = memo(({ newDictionary, onDictionaryEdit, mutate }: Props) => {
    const { isAdmin } = useRoles()

    const { orgId } = useAuth()

    const extractDictionaryAnalyticsData = (ipa: string, word: string) => {
        return {
            orgId,
            origin: word,
            type: ipa ? 'IPA' : 'alternative'
        }
    }

    const [setting, setSettings] = useState<null | {
        word: string
        language: string
        type: string
    }>(null)

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const $deleteWord = useDataMutation<void, {}, Error>(
        `/c/v1/pronunciations?word=${setting?.word}&langCode=${setting?.language}`,
        'DELETE'
    )

    if (!newDictionary) return null

    return (
        <Box mt={3} width="100%">
            <StyledGrid
                autoHeight
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25
                        }
                    }
                }}
                pageSizeOptions={[25]}
                columns={[
                    {
                        field: 'word',
                        flex: 1,
                        headerName: 'Original word',
                        renderCell: ({ row }) => <ListItemText>{row.word}</ListItemText>
                    },

                    {
                        field: 'phonetic',
                        headerName: 'Alternative word',
                        flex: 1,

                        renderCell: ({ row }: GridRowData) => (
                            <ListItemText>{row.alternative}</ListItemText>
                        )
                    },
                    {
                        field: 'ipa',
                        headerName: 'IPA',
                        flex: 1,
                        renderCell: ({ row }: GridRowData) => <ListItemText>{row.ipa}</ListItemText>
                    },
                    {
                        field: 'language',
                        flex: 1,
                        headerName: 'Language',
                        renderCell: ({ row }: GridRowData) => (
                            <ListItemText>{row.language}</ListItemText>
                        )
                    },
                    ...(isAdmin
                        ? [
                              {
                                  field: 'actions',
                                  headerName: 'Actions',
                                  flex: 0.5,
                                  renderCell: ({ row }: any) => (
                                      <ListItemText>
                                          <Stack direction="row" spacing={2}>
                                              <Box
                                                  onClick={(e: MouseEvent<HTMLElement>) => {
                                                      onDictionaryEdit(
                                                          e.currentTarget as HTMLElement,
                                                          row
                                                      )
                                                      logToAnalytics(
                                                          'dictionary_editIcn_clicked',
                                                          extractDictionaryAnalyticsData(
                                                              row.ipa,
                                                              row.word
                                                          )
                                                      )
                                                  }}
                                              >
                                                  <EditIcon />
                                              </Box>

                                              <DeleteIcon
                                                  style={{ color: 'inherit' }}
                                                  onClick={() => {
                                                      logToAnalytics(
                                                          'dictionary_deleteIcn_clicked',
                                                          extractDictionaryAnalyticsData(
                                                              row.ipa,
                                                              row.word
                                                          )
                                                      )
                                                      setSettings({
                                                          word: row.word,
                                                          language: row.language,
                                                          type: row.ipa ? 'phoneme' : 'sub'
                                                      })
                                                  }}
                                              />
                                          </Stack>
                                      </ListItemText>
                                  )
                              }
                          ]
                        : [])
                ]}
                disableColumnMenu
                sortModel={[{ field: 'word', sort: 'asc' }]}
                rows={processDictionaryData(newDictionary)}
            />
            <ConfirmationDialog
                isOpen={Boolean(setting)}
                onClose={() => {
                    if (!setting) return

                    logToAnalytics(
                        'wordDeletionModal_cancelBtn_clicked',
                        extractDictionaryAnalyticsData(setting.type, setting.word)
                    )
                    setSettings(null)
                }}
                onConfirm={() => {
                    if (!setting) return

                    logToAnalytics(
                        'wordDeletionModal_deleteBtn_clicked',
                        extractDictionaryAnalyticsData(setting.type, setting.word)
                    )

                    $deleteWord
                        .mutate()
                        .then(() => {
                            showSuccessNotification('Word deleted successfully')
                            mutate()
                        })
                        .catch(() => {
                            showErrorNotification('Something went wrong, please try again later')
                        })
                        .finally(() => {
                            setSettings(null)
                        })
                }}
                title="Word Deletion Confirmation"
                text={<>Are you sure you want to delete this word from the Dictionary?</>}
                confirmBtnText="Delete"
            />
        </Box>
    )
})
