import { Box, Button, Popover } from '@mui/material'

import { ReactComponent as Icon } from 'assets/icons/medal.svg'

import upgradeCta from 'assets/images/upgradeCta.svg'

const boxShadow =
    '0px 8px 10px -5px rgba(0, 0, 0, 0.20), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14)'

type Props = {
    anchorEl: HTMLElement | null
    setAnchorEl: (val: HTMLElement | null) => void
    onClick: () => void
}

export const UpgradeActionsPopup = ({ anchorEl, setAnchorEl, onClick }: Props) => (
    <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disablePortal={false}
        onClose={() => {
            setAnchorEl(null)
        }}
        slotProps={{
            paper: {
                sx: {
                    boxShadow
                }
            }
        }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
    >
        <Box maxWidth={400}>
            <img src={upgradeCta} height={300} />
            <Box px={5} mt={3}>
                <Box
                    fontWeight={500}
                    fontSize={20}
                    letterSpacing={0.15}
                    maxWidth={300}
                    my={2}
                    color="rgba(9, 12, 16, 1)"
                >
                    Create interactive guides
                </Box>
                <Box
                    fontWeight={400}
                    fontSize={14}
                    letterSpacing={0.15}
                    maxWidth={300}
                    mb={2}
                    color="rgba(9, 12, 16, 1)"
                >
                    that adapt to each person and lead them down the perfect path to onboarding
                </Box>
                <Box mb={5}>
                    <Button
                        variant="contained"
                        size="large"
                        style={{ fontWeight: 500, fontSize: 14, letterSpacing: 1.25 }}
                        startIcon={<Icon />}
                        onClick={() => {
                            setAnchorEl(null)
                            onClick()
                        }}
                    >
                        UPGRADE
                    </Button>
                </Box>
            </Box>
        </Box>
    </Popover>
)
