import { type CSSProperties, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { ClickAwayListener, Paper, Popper, Tooltip } from '@mui/material'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'

import { SpacedGroup, BrandKitColorPicker } from 'UI/Components'

import { useBoolean } from 'hooks'
import { setTextColors } from 'ducks'

type Props = {
    color: CSSProperties['color']
    backgroundColor?: CSSProperties['color']
}

export const FontColors = ({ color, backgroundColor = '' }: Props) => {
    const dispatch = useDispatch()

    const fontColorRef = useRef(null)
    const fontBackgroundRef = useRef(null)

    const fontColor = useBoolean()
    const fontBackground = useBoolean()

    return (
        <div>
            <SpacedGroup spacing={1}>
                <Tooltip title="Text color">
                    <FormatColorTextIcon
                        fontSize="small"
                        ref={fontColorRef}
                        onClick={() => {
                            fontColor.setTrue()
                            fontBackground.setFalse()
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </Tooltip>

                <Tooltip title="Fill color">
                    <FormatColorFillIcon
                        fontSize="small"
                        ref={fontBackgroundRef}
                        onClick={() => {
                            fontBackground.setTrue()
                            fontColor.setFalse()
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </Tooltip>
            </SpacedGroup>

            <Popper
                open={fontColor.isTrue || fontBackground.isTrue}
                anchorEl={fontColor.isTrue ? fontColorRef?.current : fontBackgroundRef.current}
                placement="top-end"
                style={{ zIndex: 1300 }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        fontColor.setFalse()
                        fontBackground.setFalse()
                    }}
                >
                    <Paper>
                        <BrandKitColorPicker
                            currentColor={fontColor.isTrue ? color : backgroundColor}
                            additionalColors={fontBackground.isTrue ? ['transparent'] : undefined}
                            onClick={value => {
                                const color = fontColor.isTrue ? value : ''
                                const backgroundColor = fontBackground.isTrue ? value : ''
                                dispatch(setTextColors({ color, backgroundColor }))
                            }}
                        />
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}
