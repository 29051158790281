import { useCallback, useEffect, useMemo, useState } from 'react'

import { type Plan, BillingPeriod } from '@stigg/js-client-sdk'

import { useQuery } from 'hooks'
import { type PlanIdType, planTypes, useServiceUsage } from './use-service-usage'

const DEFAULT_BUCKET_STATE = {
    planId: planTypes.individual as PlanIdType,
    period: BillingPeriod.Annually,
    pricePerUnit: 0,
    totalPrice: 0,
    totalLicenses: 0,
    annualBenefit: 0,
    pricePerUnitMonthly: 0
}

export type BucketType = typeof DEFAULT_BUCKET_STATE

export type UpdateBucketType = (update: {
    planId?: string
    period?: string | null
    totalLicenses?: number
}) => void

type UpdateBucketProps = typeof DEFAULT_BUCKET_STATE

type PlanMetadataType = Partial<{
    color: string
    actionTitle: string
    includesTitle: string
    isArchived: boolean
    isCustomPrice: boolean
    isReversedTrial: boolean
    isRecommended: boolean
    orderIndex: number
    incrementSeatsBy: number
    features: Array<string>
    minUnitQuantity: Record<BillingPeriod, number | null>
    maxUnitQuantity: Record<BillingPeriod, number | null>
}>

export type MappedPlanType = Pick<Plan, 'pricePoints'> & {
    planName: string
    planId: string
    planDescription: string
    metadata: PlanMetadataType
}

export type GetPaywallResType = {
    allPlans: Record<PlanIdType, MappedPlanType>
    archivedPlanIds: Array<PlanIdType>
    availablePlanIds: Array<PlanIdType>
    brandKit: {
        planId: PlanIdType
        features: Array<string>
        billingPeriod: BillingPeriod
        hasAnnualPeriod: boolean
        monthlyPrice: number
    }
    trial: {
        planId: PlanIdType
        days: number
        isTrialUsed: boolean
    }
}

export const MAX_PLAN_UNITS = 100000
export const calcDiscount = (annual: number, monthly: number) =>
    Math.round((1 - annual / (monthly * 12)) * 100)
export const roundPrice = (price: number) => Number(price.toFixed(1))

export const useStigg = () => {
    const {
        isValidating: stiggLoading,
        data,
        mutate: revalidateStiggPaywall
    } = useQuery<GetPaywallResType>(
        `/c/v1/get-paywall`,
        {
            method: 'GET'
        },
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    )

    const plans = data?.allPlans

    const {
        usedUsersSeats,
        activePlanId,
        billingPeriod,
        userSeatsQuota,
        isTrialPlan,
        isFreePlan,
        isLoading: isUsageLoading
    } = useServiceUsage()

    const [bucket, setBucket] = useState(DEFAULT_BUCKET_STATE)

    const getProPricePerMonth = useCallback(
        (planId?: PlanIdType) => {
            if (!plans) return 0

            const plan = plans[planId || planTypes.pro]

            const monthlyPricePerUnit = roundPrice(
                (plan?.pricePoints?.find(
                    ({ billingPeriod }) => billingPeriod === BillingPeriod.Annually
                )?.amount || 0) / 12
            )

            return monthlyPricePerUnit
        },
        [plans]
    )

    const updateBucket = useCallback<UpdateBucketType>(
        // TODO: replace all calculations below with a request to get
        // these calculated values after stigg will finish it
        updated => {
            if (!plans) return

            setBucket((prev: UpdateBucketProps) => {
                const planId = (updated.planId || prev.planId) as PlanIdType
                let totalLicenses = updated.totalLicenses || prev.totalLicenses
                let period = (updated.period || prev.period) as BillingPeriod

                if (!plans[planId]) return prev

                const { pricePoints, metadata } = plans[planId]
                const incrementSeatsBy = metadata.incrementSeatsBy || 1
                // always set total licences as multiple of a number from incrementSeatsBy variable
                totalLicenses =
                    totalLicenses % incrementSeatsBy
                        ? totalLicenses + incrementSeatsBy - (totalLicenses % incrementSeatsBy)
                        : totalLicenses

                if (!pricePoints.find(({ billingPeriod }) => billingPeriod === period)) {
                    // if selected period doesn't exist fallback to the one that exist
                    period =
                        period === BillingPeriod.Monthly
                            ? BillingPeriod.Annually
                            : BillingPeriod.Monthly
                }

                if (totalLicenses < (metadata?.minUnitQuantity?.[period] || 1)) {
                    // if seats are set less than allowed in plan we update it to be equal with minimum
                    totalLicenses = metadata?.minUnitQuantity?.[period] || 1
                }

                if (!pricePoints) return prev

                const monthlyPrice =
                    pricePoints.find(({ billingPeriod }) => billingPeriod === BillingPeriod.Monthly)
                        ?.amount || 0
                const annuallyPrice =
                    pricePoints.find(
                        ({ billingPeriod }) => billingPeriod === BillingPeriod.Annually
                    )?.amount || 0
                const pricePerUnit = period === BillingPeriod.Monthly ? monthlyPrice : annuallyPrice
                const pricePerUnitMonthly = roundPrice(
                    period === BillingPeriod.Monthly ? monthlyPrice : annuallyPrice / 12
                )

                const totalPrice = roundPrice(pricePerUnit * totalLicenses)
                const annualBenefit = calcDiscount(annuallyPrice, monthlyPrice)

                return {
                    planId,
                    period,
                    pricePerUnit,
                    totalPrice,
                    totalLicenses,
                    annualBenefit,
                    pricePerUnitMonthly
                }
            })
        },
        [plans]
    )

    useEffect(() => {
        // set current active plan details into bucket state as initial for updates
        if (isUsageLoading || bucket.planId !== DEFAULT_BUCKET_STATE.planId) return
        updateBucket({
            planId: activePlanId,
            totalLicenses: Math.max(usedUsersSeats, userSeatsQuota),
            period: isTrialPlan || isFreePlan ? BillingPeriod.Annually : billingPeriod
        })
    }, [
        isFreePlan,
        isTrialPlan,
        bucket.planId,
        activePlanId,
        billingPeriod,
        updateBucket,
        usedUsersSeats,
        isUsageLoading,
        userSeatsQuota
    ])

    return useMemo(
        () => ({
            revalidateStiggPaywall,
            plans,
            getProPricePerMonth,
            stiggLoading,
            bucket,
            updateBucket,
            isCurrentPlanArchived: data?.archivedPlanIds?.includes(activePlanId),
            availablePlanIds: data?.availablePlanIds || [],
            planColor: plans?.[activePlanId]?.metadata.color || 'rgba(0, 0, 0, 0.87)',
            brandKit: data?.brandKit,
            trial: data?.trial
        }),
        [
            revalidateStiggPaywall,
            plans,
            getProPricePerMonth,
            stiggLoading,
            bucket,
            updateBucket,
            data?.archivedPlanIds,
            data?.availablePlanIds,
            data?.brandKit,
            data?.trial,
            activePlanId
        ]
    )
}
