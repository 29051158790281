import { Box, Typography } from '@mui/material'

import { ConfirmationDialog } from 'UI/Components'

import { useBoolean, useOrganization } from 'hooks'

type Props = {
    isOpen: boolean
    emails: Array<string>
    onClose: () => void
    onConfirm: () => void
}

export const ConfirmOutsideDialog = ({ isOpen, emails, onClose, onConfirm }: Props) => {
    const loading = useBoolean()

    const { domains: orgDomains } = useOrganization()

    return (
        <ConfirmationDialog
            isOpen={isOpen}
            title="Share outside of your organization?"
            body={
                <Box py={6}>
                    <Typography style={{ paddingBottom: '8px' }}>
                        The following emails are not part of your organization:
                    </Typography>
                    {/* Show emails outside of user's organization */}
                    {emails
                        ?.filter(email => !orgDomains.includes(email.split('@')[1]))
                        .map((email, idx) => (
                            <Typography key={idx}>
                                <b>{email}</b>
                            </Typography>
                        ))}
                </Box>
            }
            loading={loading.isTrue}
            confirmBtnText="Share anyway"
            maxWidth="sm"
            onClose={onClose}
            onConfirm={() => {
                loading.setTrue()
                onConfirm()
            }}
        />
    )
}
