import { type ReactNode, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    IconButton,
    Button,
    FormControlLabel,
    Checkbox,
    Box,
    styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { useBoolean } from 'hooks'

const PREFIX = 'UploaderModal'

const classes = {
    modal: `${PREFIX}-modal`,
    titleContainer: `${PREFIX}-titleContainer`,
    title: `${PREFIX}-title`,
    toolbar: `${PREFIX}-toolbar`,
    close: `${PREFIX}-close`,
    content: `${PREFIX}-content`,
    footer: `${PREFIX}-footer`
}

const StyledDialog = styled(Dialog)({
    [`& .${classes.modal}`]: {
        maxWidth: '78vw',
        minHeight: '72vh',
        width: '78vw'
    },
    [`& .${classes.titleContainer}`]: {
        padding: '0',
        position: 'relative'
    },
    [`& .${classes.title}`]: {
        padding: '1rem 3rem 0',
        fontWeight: 700
    },
    [`& .${classes.toolbar}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 3rem'
    },
    [`& .${classes.close}`]: {
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem'
    },
    [`& .${classes.content}`]: {
        padding: '0 3rem'
    },
    [`& .${classes.footer}`]: {
        justifyContent: 'center',
        boxShadow: '0px -4px 3px #eeeeee'
    }
})

type Props = {
    openQueryParam?: string
    fullSubtitleWidth?: boolean
    children: ReactNode
    trigger: JSX.Element
    title: string
    subtitle?: JSX.Element
    selectedItems: number
    areAllItemsSelected: boolean
    hideSelectAll?: boolean
    onSelectAll: () => void
    onOpen: () => void
    onClose: () => void
    onSubmit: () => void
}

export const UploaderModal = ({
    openQueryParam,
    fullSubtitleWidth,
    children,
    trigger,
    title,
    subtitle,
    selectedItems,
    areAllItemsSelected,
    onSelectAll,
    hideSelectAll = false,
    onOpen = () => {},
    onClose = () => {},
    onSubmit = () => {}
}: Props) => {
    const { search: locationQuery } = useLocation()
    const { replace: replaceQuery } = useHistory()

    const modalState = useBoolean()

    const { setTrue, setFalse } = modalState

    const openModal = useCallback(() => {
        setTrue()
        onOpen()
    }, [setTrue, onOpen])

    const closeModal = useCallback(() => {
        setFalse()
        onClose()
    }, [setFalse, onClose])

    const submitModal = useCallback(() => {
        onSubmit()
        closeModal()
    }, [onSubmit, closeModal])

    useEffect(() => {
        if (!openQueryParam) return

        const queryParams = new URLSearchParams(locationQuery)
        const openParam = queryParams.get(openQueryParam)

        if (openParam === 'open') {
            openModal()
            queryParams.delete(openQueryParam)
            replaceQuery({ search: queryParams.toString() })
        }
    }, [openQueryParam, replaceQuery, locationQuery, openModal])

    return (
        <>
            <button
                onClick={openModal}
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'unset',
                    border: 'none',
                    outline: 'none',
                    textAlign: 'left',
                    cursor: 'pointer'
                }}
            >
                {trigger}
            </button>
            <StyledDialog
                open={modalState.isTrue}
                onClose={closeModal}
                classes={{ paper: classes.modal }}
            >
                <DialogTitle className={classes.titleContainer}>
                    <IconButton size="small" className={classes.close} onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>

                    <Box className={classes.title}>{title}</Box>
                    <Box
                        className={classes.toolbar}
                        flexDirection={fullSubtitleWidth ? 'column' : 'row'}
                        alignItems={fullSubtitleWidth ? 'flex-end' : 'center'}
                    >
                        {subtitle}

                        {!hideSelectAll && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={areAllItemsSelected}
                                        onChange={onSelectAll}
                                    />
                                }
                                label={areAllItemsSelected ? 'Unselect all' : 'Select All'}
                                labelPlacement="end"
                            />
                        )}
                    </Box>
                </DialogTitle>

                <DialogContent className={classes.content}>{children}</DialogContent>

                <DialogActions className={classes.footer}>
                    <Button onClick={closeModal} variant="contained">
                        Cancel
                    </Button>
                    <Button
                        disabled={selectedItems === 0}
                        onClick={submitModal}
                        variant="contained"
                    >
                        Create {selectedItems !== 0 && `(${selectedItems})`}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    )
}
