import { styled } from '@mui/material/styles'

import { type ConnectedInputProps, ConnectedInput } from './ConnectedInput'

const PREFIX = 'ConnectedFilledInput'

const classes = {
    root: `${PREFIX}-root`
}

const StyledConnectedInput = styled(ConnectedInput)({
    [`& .${classes.root}`]: {
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        '& div': {
            background: '#F5F5F5 !important'
        },
        '& div:before': {
            borderBottom: '1px solid rgba(9, 12, 16, 0.08) !important'
        },
        '&:before': {
            borderBottom: '1px solid rgba(9, 12, 16, 0.08) !important'
        },
        '& input': {
            padding: '22px 12px 8px'
        }
    },

    '& .MuiInputBase-root': {
        backgroundColor: '#F5F5F5 !important',
        '&:hover': {
            backgroundColor: '#F5F5F5 !important'
        }
    }
})

export const ConnectedFilledInput = (props: ConnectedInputProps) => {
    return (
        <StyledConnectedInput
            size="small"
            variant="filled"
            inputProps={{
                className: classes.root
            }}
            InputProps={{
                classes: {
                    multiline: classes.root
                },
                sx: {
                    backgroundColor: '#F5F5F5 !important'
                }
            }}
            className={classes.root}
            {...props}
            fullWidth={true}
        />
    )
}
