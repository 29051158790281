import { useCallback, useMemo } from 'react'
import { mutate } from 'swr'

import { useAuth, useDataMutation, useNotification, useQuery } from 'hooks'
import { logToAnalytics } from 'modules'

type ReactionType = {
    result?: null | number
    reaction: number
}

const reactionURL = '/c/v1/playbooks/reaction'

export const reactionValues = {
    notHelpful: 1,
    helpful: 2
}

export const useGetPlaybookReaction = (playbookId: string) => {
    const $reaction = useQuery<ReactionType>(`${reactionURL}?playbookId=${playbookId}`, {
        method: 'GET'
    })

    return useMemo(
        () => ({
            data: $reaction.data || null,
            isLoading: $reaction.isValidating
        }),
        [$reaction.data, $reaction.isValidating]
    )
}

export const useSetPlaybookReaction = (playbookId: string) => {
    const { showSuccessNotification } = useNotification()

    const { uid } = useAuth()

    const $setReaction = useDataMutation<
        ReactionType & { playbookId: string },
        { message: string },
        Error
    >(reactionURL, 'POST', {
        onSuccess: (_, input) => {
            showSuccessNotification('Your feedback was sent')
            logToAnalytics('video_reaction_sent', {
                playbookId,
                userUid: uid,
                reaction: input.reaction === reactionValues.helpful ? 'happy' : 'not happy',
                ...('result' in input ? { result: input.result } : {})
            })
            mutate([`${reactionURL}?playbookId=${playbookId}`])
        }
    })

    const setUserReaction = useCallback(
        (data: ReactionType) => {
            return $setReaction.mutate({
                playbookId,
                reaction: data.reaction,
                ...('result' in data ? { result: data.result } : {})
            })
        },
        [$setReaction, playbookId]
    )

    return useMemo(
        () => ({
            setUserReaction,
            isLoading: $setReaction.isLoading
        }),
        [setUserReaction, $setReaction.isLoading]
    )
}
