import { type SearchOptionType } from 'UI/Components'

export enum BroadcastWidgetKind {
    ext = 'ext',
    sdk = 'sdk'
}

export const broadcastWidgetKindFormatted = {
    [BroadcastWidgetKind.ext]: 'Extension',
    [BroadcastWidgetKind.sdk]: 'SDK'
}

export type BroadcastWidgetStyles = {
    buttonText: string
    buttonIcon: string
}

export type BroadcastWidgetConfig = {
    apiKey: string
    id: string
    name: string
    organizationId: string
    spaces: Array<string>
    domains: Array<string>
    kind: BroadcastWidgetKind
    styles: BroadcastWidgetStyles
}

export type BroadcastWidgetFieldValues = {
    name: string
    buttonText: string
    icon: string
    spaces: Array<SearchOptionType>
    kind: BroadcastWidgetKind
    domains: Array<string>
}

export enum BroadcastWidgetWizardStep {
    basicInfo,
    spaces,
    connect,
    summary,
    sdkSnippets
}

export type CreateBroadcastWidgetRequestBody = {
    apiKey: string
    name: string
    kind: BroadcastWidgetKind
    styles: BroadcastWidgetStyles
    domains: Array<string>
    spaces: Array<string>
}

export type UpdateBroadcastWidgetRequestBody = {
    id: string
    name?: string
    styles?: BroadcastWidgetStyles
    domains?: Array<string>
    spaces?: Array<string>
}
