import { type CSSProperties, type ReactNode, useState } from 'react'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import {
    type BoxProps,
    Box,
    Button,
    Dialog,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
    Skeleton
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as AvailableIcon } from 'assets/icons/available.svg'

import { SpacedGroup } from 'UI/Components'

import { logToAnalytics } from 'modules'

type SlidesType = Array<{
    img: string
    index: number
}>

export type FileMetadataType = {
    name: string
    size: number
    type: string
}

export type CommonSlidesDialogTextsType = {
    texts: {
        selectAllButtonLabel: string
        dialogTitle: string
        onSubmitButtonLabel: string
        selectedCountLabel: {
            one: string
            other: string
        }
    }
}
type Props = {
    isOpen: boolean
    fileMetadata: FileMetadataType
    slides: SlidesType
    isParsing: boolean
    isUploading: boolean
    isConverting: boolean
    playbookId: string
    skeletonsCount?: number
    uploadProgress: number
    onClose: () => void
    onImport: (data: Array<number>) => void
} & CommonSlidesDialogTextsType

const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const getAnalyticsMetadata = ({ name, size }: FileMetadataType) => ({
    size: formatBytes(size),
    name,
    type: name.substr(name.lastIndexOf('.') + 1)
})

const containerStyles = {
    border: '1px solid rgba(9, 12, 16, 0.08)',
    padding: '12px',
    maxHeight: '314px',
    overflow: 'auto'
}

const buttonStyles = {
    whiteSpace: 'nowrap',
    boxShadow: 'none',
    background: '#F5F5F5'
} as const

export const PresentationSlidesDialog = ({
    texts,
    fileMetadata,
    isOpen,
    isParsing,
    isUploading,
    isConverting,
    uploadProgress,
    skeletonsCount = 8,
    playbookId,
    onClose,
    slides,
    onImport
}: Props) => {
    const [selected, setSelected] = useState<SlidesType>([])

    const handleClose = () => {
        onClose()
        setSelected([])
    }

    const isLoading = isParsing || isUploading
    const disableButtons = isLoading || isConverting

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            PaperProps={{ style: { maxWidth: 800 } }}
        >
            <Box height={4}>{isConverting && <LinearProgress />}</Box>
            <Box p={3} py={2.5}>
                <Box display="flex" justifyContent="space-between">
                    <Typography style={{ fontSize: 20, fontWeight: 500 }}>
                        {texts.dialogTitle}
                    </Typography>
                    <IconButton size="small" onClick={handleClose}>
                        <CloseIcon fontSize="small" style={{ color: '#090C10' }} />
                    </IconButton>
                </Box>
                <Box
                    mt={4}
                    display="flex"
                    justifyContent={isUploading ? 'flex-end' : 'space-between'}
                >
                    {!isUploading && (
                        <SpacedGroup spacing={1} mr={4}>
                            <AvailableIcon style={{ color: '#CB0000' }} />
                            <TextOverflow lineClamp={1}>
                                <Typography style={{ fontWeight: 500 }}>
                                    {fileMetadata.name}
                                </Typography>
                            </TextOverflow>
                        </SpacedGroup>
                    )}
                    <SpacedGroup spacing={2}>
                        <Button
                            variant="contained"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setSelected(slides)

                                logToAnalytics('PPTSteps_selectAllSlidesBtn_clicked', {
                                    ...getAnalyticsMetadata(fileMetadata),
                                    stepsSelected: slides.length,
                                    playbookId
                                })
                            }}
                            style={buttonStyles}
                            disabled={disableButtons}
                        >
                            {texts.selectAllButtonLabel}
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            color="inherit"
                            onClick={() => setSelected([])}
                            style={buttonStyles}
                            disabled={disableButtons}
                        >
                            Clear
                        </Button>
                    </SpacedGroup>
                </Box>
                <Box
                    mt={3}
                    style={
                        isUploading
                            ? {
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: 224,
                                  ...containerStyles
                              }
                            : {}
                    }
                >
                    {isUploading ? (
                        <Box bgcolor="#F0F4F9" borderRadius={6} p={3} width={499}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={2}
                            >
                                <TextOverflow lineClamp={1}>
                                    <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                                        {fileMetadata.name}
                                    </Typography>
                                </TextOverflow>
                                <SpacedGroup>
                                    <Typography style={{ fontSize: 14 }}>
                                        {formatBytes((fileMetadata.size * uploadProgress) / 100)}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        style={{ width: 20, height: 20 }}
                                        onClick={handleClose}
                                    >
                                        <CloseIcon style={{ color: '#090C10' }} />
                                    </IconButton>
                                </SpacedGroup>
                            </Box>
                            <LinearProgress variant="determinate" value={uploadProgress} />
                        </Box>
                    ) : isParsing ? (
                        <Grid container spacing={1} style={containerStyles}>
                            {[...Array(skeletonsCount)].map((_, idx) => (
                                <SlideItemContainer key={idx} idx={idx}>
                                    <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                        style={{ background: '#F0F4F9' }}
                                    />
                                </SlideItemContainer>
                            ))}
                        </Grid>
                    ) : (
                        <>
                            {slides.length < 1 ? (
                                <Typography align="center">
                                    There are no available slides
                                </Typography>
                            ) : (
                                <Grid container spacing={1} style={containerStyles}>
                                    {slides.map((slide, idx) => {
                                        const isSelected = selected.some(
                                            it => it.index === slide.index
                                        )

                                        return (
                                            <SlideItemContainer
                                                key={slide.index}
                                                cursor={isConverting ? 'auto' : 'pointer'}
                                                border={`1px solid ${
                                                    isSelected ? 'transparent' : '#E0E0E0'
                                                }`}
                                                idx={idx}
                                                onClick={() => {
                                                    if (isConverting) return
                                                    if (isSelected) {
                                                        setSelected(prev =>
                                                            prev.filter(
                                                                it => it.index !== slide.index
                                                            )
                                                        )
                                                    } else {
                                                        setSelected(prev => [...prev, slide])
                                                    }
                                                }}
                                                isSelected={isSelected}
                                            >
                                                <img
                                                    src={slide.img}
                                                    style={{
                                                        objectFit: 'cover',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                />
                                                {isSelected && (
                                                    <Box
                                                        position="absolute"
                                                        top={0}
                                                        left={0}
                                                        right={0}
                                                        bottom={0}
                                                        width="100%"
                                                        height="100%"
                                                        border="3px solid #CB0000"
                                                    />
                                                )}
                                            </SlideItemContainer>
                                        )
                                    })}
                                </Grid>
                            )}
                        </>
                    )}
                </Box>
            </Box>
            <Divider style={{ background: '#E0E0E0' }} />
            <Box display="flex" justifyContent="space-between" px={4} py={2}>
                <Button
                    variant="contained"
                    color="inherit"
                    style={{ background: '#EDEEEF', color: 'rgba(9, 12, 16, 0.60)' }}
                    onClick={handleClose}
                >
                    CANCEL
                </Button>
                <SpacedGroup>
                    <Typography style={{ fontSize: 14 }}>
                        {selected.length}{' '}
                        {selected.length !== 1
                            ? texts.selectedCountLabel.other
                            : texts.selectedCountLabel.one}{' '}
                        selected
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            logToAnalytics('PPTSteps_importSlidesBtn_clicked', {
                                ...getAnalyticsMetadata(fileMetadata),
                                stepsSelected: selected.length,
                                totalSteps: slides.length,
                                playbookId
                            })
                            onImport(selected.map(it => it.index))
                        }}
                        disabled={disableButtons || selected.length < 1}
                    >
                        {texts.onSubmitButtonLabel}
                    </Button>
                </SpacedGroup>
            </Box>
        </Dialog>
    )
}

type SlideItemContainerProps = {
    onClick?: () => void
    border?: BoxProps['border']
    isSelected?: boolean
    cursor?: CSSProperties['cursor']
    idx: number
    children: ReactNode | Array<ReactNode>
}

const SlideItemContainer = ({
    onClick,
    isSelected,
    cursor = 'auto',
    border,
    children,
    idx
}: SlideItemContainerProps) => (
    <Grid item xs={3}>
        <Box
            style={{ cursor }}
            onClick={onClick}
            position="relative"
            width="100%"
            height="100px"
            border={border}
        >
            {children}
            <Box
                position="absolute"
                borderRadius="50%"
                border="1px solid #FFF"
                width={24}
                height={24}
                display="flex"
                alignItems="center"
                justifyContent="center"
                left={10}
                bottom={10}
                bgcolor={isSelected ? '#CB0000' : 'rgba(9, 12, 16, 0.50)'}
            >
                <Typography
                    style={{
                        fontWeight: 700,
                        color: '#FFF'
                    }}
                >
                    {idx + 1}
                </Typography>
            </Box>
        </Box>
    </Grid>
)
