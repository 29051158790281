import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, TextField, Typography } from '@mui/material'

type Props = {
    isEditMode: boolean
    note?: string
    onUpdate: (_value: string) => void
}

export const StepDescription = ({ isEditMode, note = '', onUpdate }: Props) => {
    const maxLength = useSelector(state => state.configs.audioConfigs.t2s.maxChars)

    const [noteField, setNoteField] = useState(note)

    useEffect(() => {
        setNoteField(note)
    }, [note])

    if (isEditMode) {
        return (
            <Box mb={2}>
                <TextField
                    label="Description"
                    value={noteField}
                    variant="filled"
                    fullWidth
                    multiline
                    inputProps={{
                        maxLength
                    }}
                    onBlur={() => {
                        // we need to make update only if field was changed
                        if (note !== noteField) onUpdate(noteField)
                    }}
                    onClick={e => e.stopPropagation()}
                    onChange={({ target }) => setNoteField(target.value)}
                />
                <Typography
                    align="right"
                    sx={{
                        paddingRight: '4px',
                        paddingTop: '4px',
                        fontSize: '12px',
                        color: '#828282'
                    }}
                >
                    {`${noteField?.length || 0}/${maxLength}`}
                </Typography>
            </Box>
        )
    }

    if (!note) return null

    return (
        <Typography
            sx={{
                fontSize: 14,
                color: 'rgba(9, 12, 16, 0.6)',
                mb: 2,
                wordBreak: 'break-word'
            }}
        >
            <bdi>{note}</bdi>
        </Typography>
    )
}
