import { useEffect, memo } from 'react'
import { useHistory } from 'react-router-dom'

import { paths } from 'app/paths'

import { FallbackMessage } from './FallbackMessage'
import { useBoolean } from 'hooks'

type Props = {
    children: JSX.Element
}

export const InternetConnectionListener = memo(({ children }: Props) => {
    const history = useHistory()

    const online = useBoolean(navigator.onLine)
    const setOnline = online.set

    useEffect(() => {
        window.addEventListener('online', online.setTrue)
        window.addEventListener('offline', online.setFalse)

        return () => {
            window.removeEventListener('online', online.setTrue)
            window.removeEventListener('offline', online.setFalse)
        }
    }, [online.setFalse, online.setTrue, setOnline])

    if (online.isTrue) {
        return children
    }

    return (
        <FallbackMessage
            title="Check your internet connection"
            subtitle="and try again"
            phrase="We couldn’t connect to the server"
            onClick={() => history.replace(paths.index)}
        />
    )
})
