import { useRef } from 'react'

import { Box, ClickAwayListener, Paper, Popper, Typography } from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { useBoolean, useBrandKit } from 'hooks'
import { isColorLight } from 'modules'

import { SpacedGroup, BrandKitColorPicker } from 'UI/Components'

type Props = {
    activeColor?: string
    onSelect: (color: string) => void
}

export const SpaceColorPicker = ({ activeColor = '', onSelect }: Props) => {
    const { brandKitBackgrounds } = useBrandKit()

    const anchorRef = useRef(null)
    const open = useBoolean()

    return (
        <>
            <SpacedGroup onClick={open.setTrue} style={{ cursor: 'pointer' }}>
                <Box
                    mr={1}
                    style={{
                        background: activeColor,
                        borderRadius: '50%',
                        minWidth: '40px',
                        minHeight: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px',
                        border: `1px solid ${
                            activeColor === 'transparent' || isColorLight(activeColor)
                                ? 'rgba(9, 12, 16, 0.08)'
                                : 'none'
                        }`
                    }}
                />

                <Box>
                    <SpacedGroup spacing={1}>
                        <Typography style={{ fontWeight: 500 }}>Color</Typography>
                        <ArrowDropDownIcon
                            style={{
                                transform: `rotate(${open.isTrue ? 180 : 0}deg)`,
                                transition: 'transform 0.2s ease-in-out'
                            }}
                        />
                    </SpacedGroup>

                    <Typography color="textSecondary" style={{ fontSize: 14 }}>
                        Helps identify & define spaces
                    </Typography>
                    <Box ref={anchorRef} />
                </Box>
            </SpacedGroup>

            <Popper
                open={open.isTrue}
                anchorEl={anchorRef?.current}
                placement="bottom-start"
                style={{ zIndex: 1300 }}
            >
                <ClickAwayListener onClickAway={open.setFalse}>
                    <Paper style={{ padding: '8px 16px 16px 16px' }}>
                        <SpacedGroup
                            mt={2}
                            flexWrap="wrap"
                            spacing={0}
                            border="1px solid rgba(9, 12, 16, 0.08)"
                            borderRadius={4}
                            width="fit-content"
                        >
                            {brandKitBackgrounds.map(bg => {
                                // Remove images as bg since it's not supported here
                                if (!('gradient' in bg && Boolean(bg.gradient))) return null

                                const { id, gradient } = bg
                                const isActive = gradient === activeColor

                                return (
                                    <Box
                                        key={id + activeColor}
                                        width={29}
                                        height={28}
                                        p={0.5}
                                        style={{
                                            cursor: isActive ? 'auto' : 'pointer'
                                        }}
                                        bgcolor={isActive ? 'rgba(203, 0, 0, 0.1)' : 'none'}
                                        onClick={() => onSelect(gradient)}
                                    >
                                        <Box
                                            width="100%"
                                            height="100%"
                                            style={{
                                                background: gradient
                                            }}
                                            border={isActive ? '2px solid #CB0000' : 'none'}
                                            borderRadius="50%"
                                        />
                                    </Box>
                                )
                            })}
                        </SpacedGroup>
                        <Box mt={2} />
                        <BrandKitColorPicker
                            currentColor={activeColor}
                            withBorder={true}
                            sectionsDirection="row"
                            onClick={onSelect}
                            additionalColors={['transparent']}
                        />
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
