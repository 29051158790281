import { useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Tabs, Tab, Box, IconButton } from '@mui/material'
import NextIcon from '@mui/icons-material/SkipNext'
import PreviousIcon from '@mui/icons-material/SkipPrevious'

import { SpacedGroup, FileItem, UploaderModal, FileTable } from 'UI/Components'

import { useDataMutation, useArrayStorage, useNotification, useAuth } from 'hooks'

import { batchUpload, logToAnalytics, logVideoImport, uuid } from 'modules'

import { openUploadProgressPopup } from 'ducks/actions'
import { type UploadFiles } from 'ducks/reducers'

import {
    type SortingByType,
    type YoutubeItemType,
    youtubeColumns,
    sortYoutubeFiles
} from './constants'

type Props = {
    labelComponent: JSX.Element
    onUploadCallback?: (playbookIds: Array<string>, batchUploadId?: string) => void
}

type DataType = {
    etag: string
    items: Array<YoutubeItemType>
    kind: string
    nextPageToken?: string
    prevPageToken?: string
    pageInfo: {
        resultsPerPage: number
        totalResults: number
    }
}

type SortingType = {
    sortBy: SortingByType
    sortDesc: boolean
}

type MutateDataType = {
    [key: string]: string
}

type UploadResponseType = {
    playbookIds: Array<string>
}

export const YouTubeUploader = ({ labelComponent, onUploadCallback }: Props) => {
    const { showErrorNotification } = useNotification()

    const { uid } = useAuth()

    const dispatch = useDispatch()

    const [tabValue, setTabValue] = useState(0)
    const [{ sortBy, sortDesc }, setSortParams] = useState<SortingType>({
        sortBy: youtubeColumns[1].value as SortingByType,
        sortDesc: false
    })

    const selectedItems = useArrayStorage([], 'id')

    const $uploadVideos = useDataMutation<unknown, UploadResponseType, Error>(
        '/c/v1/download-yt-videos',
        'POST'
    )
    const $uploadPlaylists = useDataMutation<unknown, UploadResponseType, Error>(
        '/c/v1/download-yt-playlists',
        'POST'
    )

    const $youtubeVideos = useDataMutation<unknown, DataType, Error>('/c/v1/get-yt-videos', 'POST')
    const $youtubePlaylists = useDataMutation<unknown, DataType, Error>(
        '/c/v1/get-yt-playlists',
        'POST'
    )

    const uploadVideos = $uploadVideos.mutate
    const uploadPlaylists = $uploadPlaylists.mutate

    const { deleteMultiple, addMultiple, areAllSelected, clearStorage, storage } = selectedItems

    const isLoading = $youtubePlaylists.isLoading || $youtubeVideos.isLoading

    const isVideoTab = tabValue === 0

    const data = useMemo(() => {
        return isVideoTab ? $youtubeVideos.data : $youtubePlaylists.data
    }, [isVideoTab, $youtubeVideos.data, $youtubePlaylists.data])

    const sortedFiles = useMemo(() => {
        if (!data?.items) return []

        return data?.items.sort((a, b) => {
            return sortYoutubeFiles(sortBy, sortDesc, a, b)
        })
    }, [data?.items, sortBy, sortDesc])

    const isAllSelected = areAllSelected(sortedFiles)

    const onClose = useCallback(() => {
        clearStorage()
        setTabValue(0)
    }, [clearStorage])

    const onSelectAll = () => {
        const action = isAllSelected ? deleteMultiple : addMultiple

        action(sortedFiles)
    }

    const onTabChange = (_: any, newValue: number) => {
        setTabValue(newValue)
        loadData(newValue)
        clearStorage()
    }

    const onSortChange = useCallback((sortBy: string) => {
        setSortParams(prevSort => ({
            sortBy: sortBy as SortingByType,
            sortDesc: sortBy === prevSort.sortBy ? !prevSort.sortDesc : false
        }))
    }, [])

    const loadData = (value: number, mutateData: MutateDataType = {}) => {
        if (value === 0) $youtubeVideos.mutate(mutateData)
        else $youtubePlaylists.mutate(mutateData)
    }

    const uploadFiles = useCallback(() => {
        const selectedIds = storage.map((it: YoutubeItemType) =>
            isVideoTab ? it.snippet.resourceId.videoId : it.id
        )

        const key = isVideoTab ? 'videoIds' : 'playlistIds'
        const action = isVideoTab ? uploadVideos : uploadPlaylists

        action({
            [key]: selectedIds
        })
            .then(data => {
                if (!data) return []

                const id = uuid()

                batchUpload({
                    id,
                    uploadedBy: uid,
                    fileList: data.playbookIds,
                    drive: 'youtube',
                    isPlaylists: !isVideoTab
                }).then(() => onUploadCallback?.(selectedIds, id))

                return data.playbookIds
            })
            .then((ids: Array<string>) => {
                const filesObject = storage.reduce(
                    (a: UploadFiles, item: YoutubeItemType, i: number) => ({
                        ...a,
                        [ids[i]]: {
                            id: ids[i],
                            name: item.snippet.title,
                            thumbnail: item.snippet.thumbnails.default.url,
                            source: 'youtube',
                            type: isVideoTab ? 'video' : 'playlist'
                        }
                    }),
                    {}
                )

                storage.forEach((it: YoutubeItemType, i: number) => {
                    const playbookId = ids[i]
                    const playbookTitle = it.snippet.title

                    logVideoImport('Youtube')
                    logToAnalytics('upload_from_youtube', {
                        'pb-id': playbookId,
                        'pb-title': playbookTitle
                    })

                    if (!isVideoTab) {
                        logToAnalytics('create_playlist', {
                            playlistId: playbookId, // two the same fields value (the same as in AddToPlaylistDialog)
                            title: playbookTitle
                        })
                    }
                })

                dispatch(openUploadProgressPopup(filesObject))
            })
            .catch(showErrorNotification)
    }, [
        uid,
        isVideoTab,
        storage,
        dispatch,
        uploadVideos,
        uploadPlaylists,
        showErrorNotification,
        onUploadCallback
    ])

    return (
        <UploaderModal
            trigger={labelComponent}
            title="Youtube"
            fullSubtitleWidth={true}
            openQueryParam="youtubeDialog"
            subtitle={
                <Box width="100%">
                    <Tabs value={tabValue} onChange={onTabChange} centered indicatorColor="primary">
                        <Tab label="Videos" />
                        <Tab label="Playlists" />
                    </Tabs>
                </Box>
            }
            selectedItems={storage.length}
            areAllItemsSelected={isAllSelected}
            onSelectAll={onSelectAll}
            onOpen={() => loadData(tabValue)}
            onClose={onClose}
            onSubmit={uploadFiles}
        >
            <FileTable
                isLoading={isLoading}
                sortDesc={sortDesc}
                sortBy={sortBy}
                onSortChange={onSortChange}
                columns={youtubeColumns}
            >
                {sortedFiles.map(file => (
                    <FileItem
                        file={file}
                        isSelectable={true}
                        showCheckbox={true}
                        thumbnail={<img src={file.snippet.thumbnails.default.url} />}
                        columns={youtubeColumns}
                        isSelected={selectedItems.isSelected(file)}
                        key={file.id}
                        onClick={() => selectedItems.toggleItem(file)}
                    />
                ))}
            </FileTable>
            <Pagination
                nextPageToken={data?.nextPageToken}
                prevPageToken={data?.prevPageToken}
                onClick={pageToken => {
                    loadData(tabValue, { pageToken })
                }}
            />
        </UploaderModal>
    )
}

type PaginationProps = {
    nextPageToken?: string
    prevPageToken?: string
    onClick: (pageToken: string) => void
}

const Pagination = ({ nextPageToken = '', prevPageToken = '', onClick }: PaginationProps) => {
    if (!nextPageToken && !prevPageToken) return null

    return (
        <SpacedGroup my={1} justifyContent="center">
            <IconButton
                disabled={!prevPageToken}
                onClick={() => onClick(prevPageToken)}
                size="large"
            >
                <PreviousIcon />
            </IconButton>
            <IconButton
                disabled={!nextPageToken}
                onClick={() => onClick(nextPageToken)}
                size="large"
            >
                <NextIcon />
            </IconButton>
        </SpacedGroup>
    )
}
