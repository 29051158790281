import { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { SpacedGroup, StepsContext, StepsStatus } from 'UI/Components'

import { ReactComponent as MagicIcon } from 'assets/icons/magic-wand.svg'

type Props = {
    index: number
    description?: string
}

export const MagicDescription = ({ index, description }: Props) => {
    const {
        stepsStatus,
        stepsProgress: { [index]: progress = 0 }
    } = useContext(StepsContext)

    // If one of these statuses is active - we don't need magic description to be shown
    if ([StepsStatus.idle, StepsStatus.done, StepsStatus.skipped].includes(stepsStatus)) return null

    return (
        <SpacedGroup mb={2} minHeight={55} alignItems="flex-start" position="relative">
            <MagicIcon style={{ position: 'absolute', left: -50 }} />
            <Box
                sx={{
                    borderRadius: '4px 4px 0 0',
                    background: '#F5F5F5',
                    borderBottom: '1px solid rgba(9, 12, 16, 0.08)'
                }}
                height="100%"
                width="100%"
                px={1}
                py={1}
            >
                <Typography variant="body2" sx={{ color: 'rgba(9, 12, 16, 0.6)' }}>
                    Magic Description
                </Typography>
                <Typography
                    sx={{
                        '@keyframes blink': {
                            'from, to': {
                                color: 'transparent'
                            },
                            '50%': {
                                color: '#CB0000'
                            }
                        },
                        fontStyle: 'italic',
                        '&::after': {
                            content: '"|"',
                            fontWeight: 'bold',
                            color: '#CB0000',
                            animation: 'blink step-end 1.2s infinite'
                        },
                        ...(stepsStatus === StepsStatus.generating && {
                            color: 'rgba(9, 12, 16, 0.6)'
                        })
                    }}
                >
                    {stepsStatus === StepsStatus.generating
                        ? 'Typing ...'
                        : description?.slice(0, (description.length / 100) * progress || 0)}
                </Typography>
            </Box>
        </SpacedGroup>
    )
}
