import {
    type User,
    type UserCredential,
    getAuth,
    onAuthStateChanged,
    onIdTokenChanged
} from 'firebase/auth'
import {
    type DataSnapshot,
    type DatabaseReference,
    getDatabase,
    ref,
    off,
    onValue
} from 'firebase/database'

import { uuid } from 'short-uuid'

import { envConfig } from './envConfig'

import { store } from 'ducks/store'
import {
    type UpdateAuthPayload,
    setExtensionStatus,
    setQuickGuiddeRecordingStatus
} from 'ducks/actions'

import { registerUserInAnalytics } from './analytics'
import { pingExtension } from './extensionMessaging'
import { fetchData, isDeepEqual } from './utils'

import { type CustomClaimsType } from 'app/types'

// Update redux only if the value has changed
const setExtStatus = (status: boolean) => {
    const { isInstalled } = store.getState().extensionStatus
    if (isInstalled === status) return
    store.dispatch(setExtensionStatus(status))
}

let claimsListener: {
    ref: DatabaseReference
    callback: (ds: DataSnapshot) => void
} | null = null
let ports: Record<string, chrome.runtime.Port | null> = {}

export const getCustomToken = async (token: string) => {
    try {
        return await fetchData('/b/v1/extension', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            }
        })
    } catch (e) {
        console.error(e)
        return Promise.reject()
    }
}

export const sendExtMessage = (message: Record<string, any>) => {
    if (message) {
        return Object.values(ports)
            .filter(Boolean)
            .forEach(port => {
                try {
                    port?.postMessage?.(message)
                } catch (e) {
                    console.error(e)
                }
            })
    }
}

export const checkIfExtIsConnected = () => {
    // Don't send message if not desktop
    if (screen.availWidth < 1280) return

    const activePorts = Object.entries(ports).filter(([_, value]) => value)

    const revalidateStatus = () => {
        Promise.any?.(
            envConfig.extensionIds.map(id => pingExtension(id, { type: 'pingConnection' }))
        )
            .then(() => setExtStatus(true))
            .catch(() => setExtStatus(false))
    }

    if (!activePorts.length) {
        revalidateStatus()
    }

    activePorts?.forEach(([id]) => {
        if (!id) return
        revalidateStatus()
    })
}

const connect = async (id: string) => {
    const connectFunc = window?.chrome?.runtime?.connect

    return new Promise<chrome.runtime.Port | null>((resolve, reject) => {
        if (connectFunc) {
            const port = connectFunc(id, {
                name: 'guidde-app'
            })

            port?.onMessage.addListener(msg => {
                if (msg.type === 'START_QG_RECORDING') {
                    store.dispatch(setQuickGuiddeRecordingStatus(true))
                }
                if (msg.type === 'STOP_QG_RECORDING') {
                    store.dispatch(setQuickGuiddeRecordingStatus(false))
                }
            })

            resolve(port)

            return
        }
        reject(null)
    })
}

async function reconnect(timeout: number, extId: string) {
    const isExtensionInstalled = store.getState().extensionStatus.isInstalled

    if (isExtensionInstalled) return null

    ports[extId] = timeout
        ? await new Promise<ReturnType<typeof setTimeout>>(resolve =>
              setTimeout(resolve, timeout)
          ).then(timerId => {
              const extPort = connect(extId)
              clearTimeout(timerId)
              return extPort
          })
        : await connect(extId)

    if (ports[extId]) {
        // @ts-ignore
        ports[extId].onMessage.addListener(msg => {
            if (msg.type === 'connected') setExtStatus(true)
        })
    }

    return ports[extId]?.onDisconnect.addListener(async () => {
        if (window?.chrome?.runtime?.lastError) {
            ports[extId] = null
        }

        // we don't need to reconnect and set {isInstalled: false} if at least 1 ext is connected
        if (!Object.values({ ...ports })?.find(e => e)) {
            setExtStatus(false)
            ports[extId] = (await reconnect(250, extId)) || null
        }
    })
}

export const registerToFirebaseAuth = async (
    setToken: (payload: UpdateAuthPayload | null) => void
) => {
    for (const extId of envConfig.extensionIds) {
        await reconnect(0, extId).catch(() => {})
    }

    onAuthStateChanged(getAuth(), async user => {
        if (claimsListener) off(claimsListener.ref, 'value', claimsListener.callback)

        claimsListener = null

        if (!user) return tokenChanged(null)

        const metadataRef = ref(getDatabase(), `userClaims/${user.uid}/customClaims/roles`)

        claimsListener = {
            ref: metadataRef,
            callback: async ds => {
                const newRoles = ds.val()
                const idTokenResult = await user.getIdTokenResult()
                const currentRoles = idTokenResult.claims.roles

                if (isDeepEqual(newRoles, currentRoles)) return
                return user.getIdToken(true)
            }
        }

        // Attach the new listener
        onValue(claimsListener.ref, claimsListener.callback)

        window.currentUser = user
    })

    onIdTokenChanged(getAuth(), user => {
        // logout, onAuthStateChanged will handle it
        if (!user) return null

        tokenChanged(user)
    })

    async function tokenChanged(user: User | null) {
        if (!user) {
            setToken(null)
            sendExtMessage({ type: 'logout' })
            return
        }

        const { refreshPageOnTheNextTokenUpdate, roles } = store.getState().appAuth

        const idTokenResult = await user.getIdTokenResult(refreshPageOnTheNextTokenUpdate)

        const newRoles = (idTokenResult.claims as CustomClaimsType).roles

        if (
            refreshPageOnTheNextTokenUpdate ||
            (roles.o !== newRoles?.o && roles.o && newRoles?.o) ||
            (!newRoles?.n && roles.n && document.hidden)
        ) {
            return window.location.reload()
        }

        if (idTokenResult.claims) {
            const { user_id, name, picture, email, roles, g } =
                idTokenResult.claims as CustomClaimsType

            setToken({
                user: {
                    uid: user_id || '',
                    displayName: name || '',
                    photoURL: picture || '',
                    email: email || ''
                },
                token: idTokenResult.token,
                ...(roles?.n || !roles ? {} : { roles }),
                ...(roles ? { isLoggedInByGuidde: g } : {})
            })

            if (roles) {
                const isExtensionInstalled = store.getState().extensionStatus.isInstalled
                if (!isExtensionInstalled) return null

                const data = await getCustomToken(idTokenResult.token)
                return sendExtMessage({ type: 'token', token: data?.token })
            }
        }
    }
}

/**
 * Used for string encoding
 * @param {string} salt Secret word for encoding (use the same for decoding)
 * @returns {(textToEncode: string) => string} encoder function that create encoded message
 */
export const cipher = (salt: string) => {
    const textToChars = (text: string) => text.split('').map(c => c.charCodeAt(0))
    const byteHex = (n: number) => ('0' + Number(n).toString(16)).substr(-2)
    const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code)

    return (text: string) =>
        text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('')
}

/**
 * Enpoint for creating magic link is open to everyone. To protect us from bots, who could generate magic links
 * using our API we add header to the request with encoded email. Only we know algorythm of encoding and decoding.
 * Without correct header our backend ignore requests.
 * @param {string} email
 * @returns encoded header
 */
export function encodeEmailToHeader(email: string) {
    const e = email.split('@')
    const encoder = cipher('Welcome to Guidde')
    const header = `${uuid()};${e[0]};${uuid()};${e[1]};${uuid()}`

    return encoder(header)
}

export const setUserToAnaliticsForLogin = (data: UserCredential) =>
    registerUserInAnalytics({
        uid: data.user?.uid || '',
        displayName: data.user?.displayName || '',
        email: data.user?.email || '',
        env: envConfig.firebaseConfig.projectId
    })

export const setGlobalPropsToAnalitics = ({ email = '' } = {}) =>
    registerUserInAnalytics({
        email,
        env: envConfig.firebaseConfig.projectId
    })
