import { useRef } from 'react'

import type Konva from 'konva'
import { Tag } from 'react-konva'

import { type OverlayShapeType } from 'app/types'

type Props = {
    layer: OverlayShapeType
    onSelect: (shiftPressed: boolean) => void
}

export const OverlayShape = ({ layer, onSelect }: Props) => {
    const shapeRef = useRef<Konva.Tag>(null)

    const { id, fill, x, y, width, height, scaleX, scaleY } = layer

    return (
        <Tag
            ref={shapeRef}
            name="overlay"
            id={id}
            fill={fill}
            x={x}
            y={y}
            width={width}
            height={height}
            scaleX={scaleX}
            scaleY={scaleY}
            onClick={e => onSelect(e.evt.shiftKey)}
            onMouseOver={() => (document.body.style.cursor = 'pointer')}
            onMouseLeave={() => (document.body.style.cursor = '')}
        />
    )
}
