import { type MouseEvent, useCallback, useRef, useState } from 'react'

import { Box, Stack } from '@mui/material'

import { type AudioBackgroundType } from 'app/types'

import { AudioItem } from 'UI/Components/AudioBackground'

const getRandomHeight = (min = 5, max = 20) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const waveLength = 24

type Props = {
    audio: AudioBackgroundType
    isSelected: boolean
    onClick: () => void
    playingId?: string
    setPlayingId: (id: string) => void
}

export const WizardAudioBackgroundItem = ({
    audio,
    isSelected,
    onClick,
    setPlayingId,
    playingId
}: Props) => {
    const [wavePattern] = useState(Array.from({ length: waveLength }, () => getRandomHeight()))

    const [playingWaveBar, setPlayingWaveBar] = useState(0)

    const audioRef = useRef<HTMLAudioElement | null>(null)

    const seekToPosition = useCallback((e: MouseEvent<HTMLElement>) => {
        if (!audioRef.current) return

        const { width, left } = e.currentTarget.getBoundingClientRect()
        const position = e.clientX - left
        const percent = Math.min(100, Math.max(0, position / width))

        setPlayingWaveBar(Math.floor(percent * waveLength))

        const newTime = audioRef.current.duration * percent
        audioRef.current.currentTime = newTime
    }, [])

    return (
        <AudioItem
            key={audio.id}
            {...audio}
            setPlayingId={setPlayingId}
            playingId={playingId}
            size={60}
            maxHeight={78}
            cardSx={{
                border: 'none',
                background: 'none',
                '&:hover': {
                    backgroundColor: isSelected ? 'common.white' : 'rgba(0, 0, 0, 0.04)'
                },
                ...(isSelected && {
                    outline: '4px solid #FED243',
                    backgroundColor: 'common.white'
                })
            }}
            onClick={onClick}
            playingStatus={
                <Stack
                    direction="row"
                    spacing={0.25}
                    alignItems="flex-end"
                    onClick={event => {
                        event.stopPropagation()
                        seekToPosition(event)
                    }}
                >
                    {wavePattern.map((height, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: 2,
                                height,
                                backgroundColor:
                                    index <= playingWaveBar
                                        ? 'primary.main'
                                        : 'rgba(9, 12, 16, 0.1)',
                                borderTopLeftRadius: '2px',
                                borderTopRightRadius: '2px'
                            }}
                        ></Box>
                    ))}
                </Stack>
            }
            onTimeUpdate={({ currentTarget }) => {
                const percent = currentTarget.currentTime / currentTarget.duration
                setPlayingWaveBar(Math.floor(percent * waveLength))
            }}
            onEnded={() => setPlayingWaveBar(0)}
            externalAudioRef={audioRef}
        />
    )
}
