import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { type SWRConfiguration } from 'swr'

import { useQuery } from 'hooks'

import { type ErrorType, type SearchPlaybookType } from 'app/types'

import { setLibraryFacetFilters } from 'ducks/actions'

import { isFiltersEmpty } from 'UI/Components'

export type SearchResultType = {
    facets: any
    nbHits: number
    status: string
    playbooks: Array<SearchPlaybookType>
    featuredVideos: Array<{
        id: string
        title: string
        isPlaylist: boolean
    }>
    folders?: Array<FolderType>
}

export type FolderType = {
    videosIds: Array<string>
    id: string
    name: string
    parentFolderId?: string
    nestedFolders: Array<NestedFolderType>
    createdBy: {
        uid: string
        photoURL: string
        displayName: string
    }
}

export type NestedFolderType = FolderType & {
    parentFolderId: string
}

type Props = {
    body: {
        spaceId?: string
        spaceGroups?: string[]
        spaceIds?: string[]
        isMainSpace?: boolean
        sortBy?: 'date' | '' | 'modified'
        method?: 'search' | ''
        text?: string
        folderId?: string
        applicationId?: string
        page: number
        pageSize?: number
    }
    onSuccess?: (data: SearchResultType) => void
    shouldFetch?: boolean
    url?: string
    params?: SWRConfiguration<any, ErrorType>
}

export const defaultPageSize = 24

export const useSearch = ({
    body,
    onSuccess = () => {},
    shouldFetch = true,
    params = {},
    url = '/a/v1/search'
}: Props) => {
    const dispatch = useDispatch()

    const filters = useSelector(state => state.playbookFilters.libraryPlaybooks)
    const facets = useSelector(state => state.playbookFilters.libraryFacets)

    const { pageSize, ...rest } = body
    const size = pageSize || defaultPageSize

    const { isValidating, data, mutate } = useQuery<SearchResultType>(
        shouldFetch ? url : '',
        {
            method: 'POST',
            body: {
                ...rest,
                size,
                page: body.page - 1
            }
        },
        {
            onSuccess,
            ...params
        }
    )

    // we want facets to stay the same so we grab it
    // only when filters is empty
    useEffect(() => {
        if (data?.facets && isFiltersEmpty(filters)) {
            dispatch(setLibraryFacetFilters(data.facets))
        }
    }, [data?.facets, filters, dispatch])

    return useMemo(
        () => ({
            playbooksIsEmpty: !isValidating && !data?.playbooks?.length,
            isLoading: isValidating,
            playbooks: data?.playbooks || [],
            facets,
            nbHits: data?.nbHits || 0,
            status: data?.status || 'unknown',
            pageCount: Math.ceil((data?.nbHits || 0) / size),
            featuredVideos: data?.featuredVideos || [],
            mutate
        }),
        [
            isValidating,
            data?.playbooks,
            data?.nbHits,
            data?.featuredVideos,
            data?.status,
            facets,
            size,
            mutate
        ]
    )
}
