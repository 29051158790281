import * as types from 'ducks/types'
import { type ActionType, type Reducer } from 'ducks/common'

export type SectionType = {
    start: number
    end: number
    thumbnail: string
    title: string
    rangeValues: Array<number>
    isDeleted?: boolean
    tags?: Array<string>
    applications?: Array<{
        applicationId: string
        applicationName: string
        applicationLogo?: string
    }>
}

type StateType = {
    sections: Array<SectionType>
    initialSections: Array<SectionType>
    activeSection: number
    duration: number
    currentTime: number
    progress: number
}

const initialState: StateType = {
    sections: [],
    initialSections: [],
    activeSection: 0,
    duration: 0,
    currentTime: 0,
    progress: 0 // in %
}

export const videoEditorReducer: Reducer<StateType> = (
    state = initialState,
    action: ActionType
): StateType => {
    const newSections = JSON.parse(JSON.stringify(state.sections))

    switch (action.type) {
        case types.SET_SECTIONS: {
            return {
                ...state,
                sections: action.payload
            }
        }

        case types.SET_INITIAL_SECTIONS:
            return {
                ...state,
                initialSections: action.payload
            }

        case types.SET_SECTIONS_RESET: {
            return {
                ...state,
                sections: JSON.parse(JSON.stringify(state.initialSections))
            }
        }

        case types.SET_CHAPTER_TITLE: {
            newSections[action.payload.idx].title = action.payload.title
            return { ...state, sections: [...newSections] }
        }

        case types.SET_CURRENT_TIME: {
            return {
                ...state,
                currentTime: action.payload,
                progress: (action.payload / state.duration || 0) * 100
            }
        }

        case types.SET_DURATION: {
            return {
                ...state,
                duration: action.payload,
                progress: (state.currentTime / action.payload || 0) * 100
            }
        }

        case types.SET_ACTIVE_SECTION: {
            return { ...state, activeSection: action.payload }
        }

        case types.TOGGLE_PLAYBOOK_SECTION: {
            const idx = action.payload
            const copiedSections = JSON.parse(JSON.stringify(state.sections))
            copiedSections[idx].isDeleted = !copiedSections[idx].isDeleted

            return {
                ...state,
                sections: copiedSections
            }
        }

        case types.CLOSE_VIDEO_EDITOR: {
            return initialState
        }

        default: {
            return state
        }
    }
}
