import { type TooltipProps, Tooltip } from '@mui/material'

import { useMenuStatus } from '../MenuStatusProvider'

type Props = {
    title: TooltipProps['title']
    children: TooltipProps['children']
    disabled?: boolean
}

export const CollapsedMenuTooltip = ({ title, disabled, children }: Props) => {
    const { isMenuClosed } = useMenuStatus()

    if (!isMenuClosed || disabled) {
        return children
    }

    return (
        <Tooltip
            title={title}
            placement="right"
            componentsProps={{
                tooltip: {
                    sx: {
                        padding: '8px 12px',
                        fontSize: 12,
                        fontWeight: 600,
                        backgroundColor: '#14192B',
                        marginLeft: '5px !important'
                    }
                }
            }}
            sx={theme => ({
                zIndex: theme.zIndex.drawer + 1
            })}
        >
            {children}
        </Tooltip>
    )
}
