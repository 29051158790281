import { type CircularProgressProps, CircularProgress, Typography, Box } from '@mui/material'

type Props = {
    renderWhenValueZero?: boolean
    textColor?: string
    textInsteadValue?: string
    label?: string
} & CircularProgressProps

export const CircularProgressWithLabel = ({
    renderWhenValueZero,
    textColor,
    textInsteadValue,
    ...props
}: Props = {}) => {
    const { value, label } = props

    if (value === undefined) return null
    // We don't render component if `value` null, undefined, 0 or '' AND when `label` isn't set
    // But sometimes we need to render even when value 0. We turn on this by passing `renderWhenValueZero`
    if (!(renderWhenValueZero ? isFinite(value) : value) && !label) {
        return null
    }

    const valueToShow = (
        <>
            {renderWhenValueZero || value ? `${Math.round(value)}%` : null}
            {label}
        </>
    )

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} value={value >= 100 ? 100 : value} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    sx={
                        textColor
                            ? {
                                  color: textColor,
                                  fontWeight: 'bold',
                                  fontSize: '15px'
                              }
                            : {}
                    }
                >
                    {textInsteadValue || valueToShow}
                </Typography>
            </Box>
        </Box>
    )
}
