import { useEffect, useState } from 'react'

import { Button, Typography, Box, TextField, Stack } from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard'

import { UpdateCardDialog, cardIcons } from 'UI/Components'

import { InvoicesTable } from './InvoicesTable'

import { useBoolean, usePayment, useServiceUsage } from 'hooks'
import { formatDate, validateEmail } from 'modules'

const getCreditCardIcon = (cardBrand: string) => {
    return cardIcons[cardBrand.toLowerCase()] || <CreditCardIcon />
}

export const BillingTab = () => {
    const updateDialog = useBoolean()

    const { billingEmail, nextBilling, isTrialPlan } = useServiceUsage()
    const { paymentMethod, cardDataIsEmpty, updateEmail, isLoading } = usePayment()

    const [email, setBillingEmail] = useState(billingEmail)

    useEffect(() => {
        setBillingEmail(billingEmail)
    }, [billingEmail])

    const allowSave = validateEmail(email) && email !== billingEmail

    return (
        <>
            {updateDialog.isTrue && (
                <UpdateCardDialog
                    onClose={updateDialog.setFalse}
                    type={cardDataIsEmpty ? 'Create' : 'Update'}
                />
            )}

            <SectionTitle>Payment method</SectionTitle>

            {Boolean(nextBilling && !isTrialPlan) && (
                <Stack direction="row" mt={2.5} spacing={1}>
                    <Typography style={{ fontWeight: 500 }}>Next payment:</Typography>
                    <Typography>
                        <Typography>{formatDate(nextBilling)}</Typography>
                    </Typography>
                </Stack>
            )}

            {!cardDataIsEmpty && (
                <Box my={4} display="flex" alignItems="center">
                    <Box
                        border="1px solid rgba(224, 224, 224, 1)"
                        borderRadius="5.5px"
                        px={1}
                        py={0.7}
                    >
                        {getCreditCardIcon(paymentMethod?.cardBrand)}
                    </Box>
                    <Box ml={2} mr={1} fontWeight={500} fontSize="40px">
                        ····
                    </Box>
                    <Typography variant="subtitle2">{paymentMethod?.last4}</Typography>
                    <Box ml={4} />
                    <Button variant="contained" onClick={updateDialog.setTrue}>
                        CARD UPDATE
                    </Button>
                </Box>
            )}

            <Box mt={2}>
                <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                    Billing account email
                </Typography>
            </Box>

            <Stack direction="row" spacing={2} mt={1} alignItems="flex-end">
                <TextField
                    required
                    variant="filled"
                    placeholder="Billing email"
                    value={email}
                    sx={{ width: 298 }}
                    onChange={e => setBillingEmail(e.target.value)}
                />

                <Button
                    onClick={() => updateEmail(email)}
                    disabled={!allowSave || isLoading}
                    variant="text"
                    style={{
                        textDecoration: 'underline'
                    }}
                >
                    Email update
                </Button>
            </Stack>

            <Box mt={5} mb={2}>
                <SectionTitle>Invoice History</SectionTitle>
            </Box>
            <InvoicesTable />
        </>
    )
}

const SectionTitle = ({ children }: { children: string }) => (
    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 20 }}>
        {children}
    </Typography>
)
