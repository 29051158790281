import { type ReactNode, memo } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import { Grid, Box, Typography } from '@mui/material'

// TODO: fix types import/export style inside @guidde-co/shared
// import { type MenuItemType } from '@guidde-co/shared.playbook-card/playbook-card'
type MenuItemType = {
    icon: any
    title: string
    onClick: (playbook: PlaybookType) => void
    selected?: boolean
    disabled?: boolean
    showDivider?: boolean
    protected?: boolean
    hideForPlaylist?: boolean
}

import { type MenuItemSourceType, OnboardingExtCard, PlaybookMenuItems } from 'UI/Components'

import { PlaybookCard } from './PlaybookCard'

import { type SearchResultType, useWindowView, useRoles, useAuth } from 'hooks'
import { round } from 'modules'

import { paths } from 'app/paths'
import { type AnyPlaybookType, type PlaybookType, type SearchPlaybookType } from 'app/types'

export const generatePlaybook = (o: any): PlaybookType => ({
    ...o,
    description: o.description || '',
    link: o.link || '',
    screenshotUrl: o.img || o.screenshotUrl,
    creator_uid: o.creator_uid || o.uploadedByUid,
    username: o.creator_name || o.uploadedBy?.displayName,
    userImage: o.creator_img || o.uploadedBy?.photoURL,
    uploadedAt: o.uploadedAt?.seconds || o.uploadedAt,
    createdAt: o.created || o.createdAt,
    tags: o.tags || [],
    isPublished: o.isPublished,
    isPublic: o.isPublic,
    views: o.views || 0,
    rating: round(o.rating || 0),
    transcriptUrl: o.transcriptUrl || '',
    subtitlesUrl: o.subtitlesUrl || '',
    publishedForUsers: o.publishedForUsers || [],
    previousScreenshotUrl: o.previousScreenshotUrl || '',
    uploadedByUid: o.uploadedByUid || o.creator_uid,
    mode: o.mode,
    steps: o.steps || [],
    lastUpdatedAt: o.lastUpdatedAt?.seconds || o.lastUpdatedAt
})

const initialFeaturedProps = {
    hideVisibility: false,
    showFeatured: false,
    featuredList: [],
    toggle: (_playbookId: string) => {}
}

export type FeaturedProps = {
    hideVisibility?: boolean
    showFeatured: boolean
    toggle?: (_playbookId: string) => void
    featuredList: SearchResultType['featuredVideos']
}

type OverwrittenMenuItemType = Omit<MenuItemType, 'onClick' | 'disabled'> & {
    hidden?: boolean
    onClick: (playbook: AnyPlaybookType) => void
    disabled?: boolean | ((playbook: AnyPlaybookType) => boolean)
    tooltipText?: (playbook: AnyPlaybookType) => string
}

export type PbHitsProps = {
    searchRefresh?: (playbookId?: string) => void
    onPlayClick?: (playbook: AnyPlaybookType) => void
    id?: string
    featuredProps?: FeaturedProps
    onCheckboxClick?: (playbook: AnyPlaybookType) => void
    selectedPlaybooksIds?: Array<string>
    removeFromSpaceOption?: OverwrittenMenuItemType
    moveToFolderOption?: OverwrittenMenuItemType
} & {}

type Props = {
    playbooks: Array<AnyPlaybookType | SearchPlaybookType>
    disablePlay?: boolean
    showExtCard?: boolean
    mdMargin?: number
    onRestore?: (playbookId: string) => void
    onDeleteForever?: (playbookId: string) => void
    CustomMenuItems?: (props: { onRestore: () => void; onDeleteForever: () => void }) => JSX.Element
    source?: MenuItemSourceType
    filterKeys?: Array<string>
} & PbHitsProps

export const useCanEditPlaybook = (playbook: AnyPlaybookType): boolean => {
    const { checkRoles } = useRoles()
    const { uid } = useAuth()

    const { isContentManager } = checkRoles(playbook?.uploadedByOrgId)
    const isOwner = uid === playbook?.uploadedByUid

    return isOwner || isContentManager
}

export const PlaybookHits = memo(
    ({
        searchRefresh = _playbookId => null,
        selectedPlaybooksIds,
        CustomMenuItems,
        disablePlay = false,
        onCheckboxClick,
        onDeleteForever,
        onRestore,
        onPlayClick,
        playbooks,
        mdMargin = 5,
        source,
        id,
        showExtCard,
        filterKeys = [],
        removeFromSpaceOption,
        featuredProps = initialFeaturedProps,
        moveToFolderOption
    }: Props) => {
        const history = useHistory()
        const location = useLocation()

        const { isMobileView, isWindowWidthSmaller } = useWindowView()

        const historyPush = history.push

        const playbooksLength = playbooks?.length

        const lgSmall = isWindowWidthSmaller(1660)

        const handlePlay = (playbook: AnyPlaybookType) => {
            onPlayClick?.(playbook)

            const query = new URLSearchParams(location.search)
            // remove all filters from url ONLY. if URL includes some other queries - they will be saved
            filterKeys.forEach(filter => query.delete(filter))

            window.scrollTo({
                top: 0
            })

            switch (playbook.mode) {
                case 'playbook':
                case 'quickGuidde':
                    historyPush({
                        search: query.toString(),
                        pathname: generatePath(paths.playbookDetails, {
                            playbookId: playbook.id
                        })
                    })

                    break
                case 'playlist': {
                    query.set('active', '0')

                    historyPush({
                        search: query.toString(),
                        pathname: generatePath(paths.playlistDetails, {
                            playbookId: playbook.id
                        })
                    })
                    break
                }
                default:
                    break
            }
        }

        return (
            <Box mt={{ sm: 2, md: mdMargin }}>
                {isMobileView && playbooksLength > 0 && (
                    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                        <Typography variant="caption" color="textSecondary">
                            {playbooksLength} Playbook
                            {playbooksLength > 1 ? 's' : ''}
                        </Typography>
                    </Box>
                )}
                <Grid container id={id} spacing={2}>
                    {showExtCard && playbooks.filter(Boolean).length > 0 && (
                        <CardGrid lgSmall={lgSmall}>
                            <OnboardingExtCard />
                        </CardGrid>
                    )}

                    {playbooks.filter(Boolean).map((hit: any) => {
                        const playbook = generatePlaybook(hit)

                        const isFeatured = Boolean(
                            featuredProps?.featuredList.find(it => it.id === playbook.id)
                        )

                        const showCheckbox = Boolean(onCheckboxClick) && !isMobileView

                        const handleCheckboxClick = showCheckbox
                            ? () => onCheckboxClick?.(playbook)
                            : undefined

                        return (
                            <CardGrid lgSmall={lgSmall} key={playbook.id}>
                                <PlaybookCard
                                    data-test="playbook-card"
                                    playbook={playbook}
                                    onPlayClick={() => handlePlay(playbook)}
                                    disablePlay={disablePlay}
                                    onCheckboxClick={handleCheckboxClick}
                                    checkboxChecked={selectedPlaybooksIds?.includes(playbook.id)}
                                    MenuItems={
                                        !isMobileView ? (
                                            CustomMenuItems ? (
                                                <CustomMenuItems
                                                    onRestore={() => onRestore?.(playbook.id)}
                                                    onDeleteForever={() => {
                                                        onDeleteForever?.(playbook.id)
                                                    }}
                                                />
                                            ) : (
                                                <PlaybookMenuItems
                                                    playbook={playbook}
                                                    source={source}
                                                    searchRefresh={searchRefresh}
                                                    featuredProps={featuredProps}
                                                    moveToFolderOption={moveToFolderOption}
                                                    removeFromSpaceOption={removeFromSpaceOption}
                                                />
                                            )
                                        ) : undefined
                                    }
                                    isFeatured={isFeatured}
                                />
                            </CardGrid>
                        )
                    })}
                </Grid>
            </Box>
        )
    }
)

type CardGridProps = {
    lgSmall: boolean
    children: ReactNode
}

export const CardGrid = ({ lgSmall, children }: CardGridProps) => (
    <Grid
        item
        xs={12}
        sm={6}
        md={3}
        lg={lgSmall ? 3 : 2}
        xl={2}
        style={{ maxWidth: '220 !important' }}
    >
        {children}
    </Grid>
)
