import { memo } from 'react'

import { Box } from '@mui/material'

import { type BrandKitType, type UploadFileType } from 'app/types'

import { ReactComponent as Icon } from 'assets/icons/box.svg'

import { type SectionProps, ItemCard, Section, RadioLabel } from './components'
import { CropperDialog } from './dialogs'

import { type BrandKitResponseType, useBoolean, useNotification } from 'hooks'

type Props = {
    values: BrandKitType['extensionLogo']
    uploadFile: (data: UploadFileType) => Promise<BrandKitResponseType>
} & SectionProps

export const ExtensionLogo = memo(
    ({ values, popupProps, uploadFile, onDelete, isLoading }: Props) => {
        const { showSuccessNotification } = useNotification()

        const { backgroundUrl, url, originalUrl } = values.data

        const dialog = useBoolean()
        const uploading = useBoolean()

        const onSubmit = (file: File, originalFile: File | null) => {
            uploading.setTrue()

            const data: UploadFileType = { file, type: 'ext' }

            if (originalFile) {
                data.originalFile = originalFile
            }

            return uploadFile(data)
                .then(() => {
                    uploading.setFalse()
                    dialog.setFalse()
                    showSuccessNotification('Extension logo changed')
                })
                .finally(uploading.setFalse)
        }

        const loading = uploading.isTrue || isLoading

        return (
            <>
                <Section
                    popupProps={popupProps}
                    title="Extension logo"
                    subtitle="Logo will be used as a recording panel icon, recommended size 256X256px in .png format"
                    onActionItemClick={dialog.setTrue}
                    actionTitle="Change Logo"
                    actionIcon={Icon}
                    content={[
                        <ItemCard
                            key={url}
                            popupProps={popupProps}
                            onEdit={dialog.setTrue}
                            disableLabelButton={true}
                            label={
                                <RadioLabel
                                    isActive={true}
                                    validateClick={popupProps.validateClick}
                                />
                            }
                        >
                            <Box width="100%" height="100%" position="relative">
                                <img
                                    src={backgroundUrl}
                                    width="100%"
                                    height="100%"
                                    style={{
                                        objectFit: 'cover'
                                    }}
                                />

                                <Box
                                    position="absolute"
                                    bottom={16}
                                    left={16}
                                    width={40}
                                    height={40}
                                    borderRadius="50%"
                                    bgcolor="#fff"
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    boxShadow=" 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)"
                                >
                                    <img src={url} width={22} height={22} />
                                </Box>
                            </Box>
                        </ItemCard>
                    ]}
                />
                <CropperDialog
                    isLoading={loading}
                    title="Extension logo"
                    maxHeight={320}
                    isOpen={dialog.isTrue}
                    onClose={dialog.setFalse}
                    url={originalUrl}
                    dimensions={[256, 256]}
                    onDelete={() => {
                        onDelete({
                            extensionLogo: true
                        }).then(() => {
                            showSuccessNotification('Extension logo removed')
                        })
                    }}
                    onSubmit={onSubmit}
                />
            </>
        )
    }
)
