import { useContext, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import {
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    MenuList,
    Paper,
    Popper
} from '@mui/material'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RectIcon from '@mui/icons-material/Crop169'
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked'
import BlurCircularIcon from '@mui/icons-material/BlurCircular'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import PublishIcon from '@mui/icons-material/Publish'

import { ReactComponent as ImageIcon } from 'assets/icons/image.svg'
import { ReactComponent as TextIcon } from 'assets/icons/text_layer.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_layer.svg'
import { ReactComponent as AddLogo } from 'assets/icons/addLogo.svg'
import { ReactComponent as TrophyIcon } from 'assets/icons/medal.svg'
import { ReactComponent as Avatar } from 'assets/icons/avater.svg'
import { ReactComponent as Shapes } from 'assets/icons/shapes.svg'
import { ReactComponent as SpeakerIcon } from 'assets/icons/speaker.svg'
import { ReactComponent as SingleAction } from 'assets/icons/single-action.svg'
import { ReactComponent as MultipleActionAction } from 'assets/icons/multiple-action.svg'
import { ReactComponent as Action } from 'assets/icons/action.svg'

import {
    DEFAULT_ACTION,
    DEFAULT_HEADER,
    QGContext,
    useLayers
} from 'UI/Routes/quick-guidde/CanvasEditor'

import { FileUploader, UpgradePlanPopup, UpgradeActionsPopup, NestedMenuItem } from 'UI/Components'

import {
    addNewLayer,
    selectLayer,
    setStepMultiCtaVisibility,
    setStepCtaAction,
    setStepCtaVisibility,
    setStepMultiApplyVisibility,
    setCurrentCTA,
    showPlanDialog
} from 'ducks'
import {
    useAuth,
    useBoolean,
    useServiceUsage,
    useBrandKit,
    planTypes,
    useOrgFeatureFlags
} from 'hooks'
import { delay, logToAnalytics, uuid } from 'modules'

import { type ImageShapeType, Shape } from 'app/types'
import { paths } from 'app/paths'

import { updateUserPreferences } from 'ducks/reducers'

const MENU_WIDTH = 240

const SINGLE_CTA_TOOLTIP = 'Single action is already applied for this step'
const MULTIPLE_CTA_TOOLTIP = 'Multiple actions are already applied for this step'

export const AddNewLayer = () => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()
    const { uid } = useAuth()

    const history = useHistory()

    const { scale } = useContext(QGContext)

    const { hasVideoBlur, hasCustomBranding, hasMultipleCta } = useServiceUsage()
    const { disableImageElements } = useOrgFeatureFlags()

    const { brandKitData } = useBrandKit()

    const isBrandKitPage = useRouteMatch([paths.brandKitDetails, paths.brandKit])?.isExact

    const { text, arrow, speaker, blur, circle, profileImage, overlay, rectangle, image } =
        useLayers()

    const open = useBoolean()
    const loading = useBoolean()

    const anchorRef = useRef(null)

    const [blurAnchorEl, setBlurAnchorEl] = useState<HTMLElement | null>(null)
    const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLElement | null>(null)

    const { activeStep, steps } = useSelector(state => state.qgEditor.present)

    const { audioNote, layers, kind, cta } = steps[activeStep] || {}

    const bgImageLayer = layers?.find(layer => layer.type === Shape.Image && layer.isBackground) as
        | ImageShapeType
        | undefined

    const hasCta = useMemo(() => {
        if (!cta?.ctaType) return {}
        return { [cta.ctaType]: true }
    }, [cta])

    const stepIncludesVideo = layers?.some(layer => layer.type === Shape.Video)
    const stepIncludesAudioLayer = layers?.some(layer => layer.type === Shape.AudioCircle)

    const hideLogoImage = !hasCustomBranding || disableImageElements
    const hideAvatarImage = disableImageElements
    const hideSpeakerImage = !audioNote || stepIncludesAudioLayer || disableImageElements

    if (!steps[activeStep]) return null

    return (
        <>
            <Button
                data-test="editor-insert-button"
                ref={anchorRef}
                variant="text"
                color="inherit"
                startIcon={<AddCircleOutlineIcon />}
                endIcon={loading.isTrue && <CircularProgress size={20} />}
                onClick={open.setTrue}
            >
                Insert
            </Button>

            <Popper
                open={open.isTrue}
                placement="top-start"
                anchorEl={anchorRef?.current}
                style={{
                    zIndex: 1300,
                    ...(loading.isTrue ? { opacity: 0, pointerEvents: 'none' } : {})
                }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        if (loading.isFalse) open.setFalse()
                    }}
                >
                    <Paper>
                        <MenuList
                            style={{ padding: '0', minWidth: MENU_WIDTH }}
                            onClick={event => {
                                // To prevent closing the menu when clicking on the image uploader
                                if ((event.target as HTMLElement)?.id === 'file-uploader') return

                                open.setFalse()
                            }}
                        >
                            {/* Video step doesn't support any extra layer yet */}
                            {!stepIncludesVideo && (
                                <div>
                                    <NestedMenuItem
                                        icon={TextIcon}
                                        text="Text"
                                        dataCy="editor-insert-text"
                                        onClick={() => {
                                            logToAnalytics('insert_text_clicked', { playbookId })

                                            dispatch(addNewLayer(text))
                                            delay(0).then(() => dispatch(selectLayer(text.id)))
                                        }}
                                        endWithDivider
                                    />

                                    <NestedMenuItem
                                        dataCy="editor-insert-shape"
                                        icon={Shapes}
                                        text="Shape"
                                        endWithDivider
                                        nestedItems={[
                                            {
                                                icon: RectIcon,
                                                text: 'Rectangle',
                                                dataCy: 'editor-insert-rectangle',
                                                endWithDivider: true,
                                                onClick: () => {
                                                    logToAnalytics('insert_shape_clicked', {
                                                        playbookId,
                                                        type: 'rectangle'
                                                    })

                                                    dispatch(addNewLayer(rectangle))
                                                    delay(0).then(() =>
                                                        dispatch(selectLayer(rectangle.id))
                                                    )
                                                }
                                            },
                                            {
                                                icon: CircleIcon,
                                                text: 'Circle',
                                                dataCy: 'editor-insert-circle',
                                                endWithDivider: true,
                                                onClick: () => {
                                                    logToAnalytics('insert_shape_clicked', {
                                                        playbookId,
                                                        type: 'circle'
                                                    })

                                                    dispatch(addNewLayer(circle))
                                                    delay(0).then(() =>
                                                        dispatch(selectLayer(circle.id))
                                                    )
                                                }
                                            },
                                            {
                                                icon: ArrowIcon,
                                                text: 'Arrow',
                                                dataCy: 'editor-insert-arrow',
                                                onClick: () => {
                                                    logToAnalytics('insert_shape_clicked', {
                                                        playbookId,
                                                        type: 'arrow'
                                                    })

                                                    dispatch(addNewLayer(arrow))
                                                    delay(0).then(() =>
                                                        dispatch(selectLayer(arrow.id))
                                                    )
                                                }
                                            }
                                        ]}
                                    />

                                    <NestedMenuItem
                                        dataCy="editor-insert-image"
                                        icon={ImageIcon}
                                        text="Image"
                                        endWithDivider
                                        nestedItems={[
                                            {
                                                hidden: hideLogoImage,
                                                icon: AddLogo,
                                                text: 'Logo',
                                                dataCy: 'editor-insert-logo',
                                                endWithDivider: true,
                                                onClick: () => {
                                                    logToAnalytics('insert_image_clicked', {
                                                        playbookId,
                                                        type: 'logo'
                                                    })

                                                    const { active, data } =
                                                        brandKitData?.logoAndFavicon || {}
                                                    const activeLogo = data?.find(
                                                        item => item.id === active
                                                    )
                                                    if (!activeLogo) return

                                                    const img = new Image()
                                                    img.src = activeLogo.url
                                                    img.onload = () => {
                                                        dispatch(
                                                            addNewLayer({
                                                                ...image,
                                                                width: img.width * scale,
                                                                height: img.height * scale,
                                                                url: activeLogo.url,
                                                                isBrandkitLogo: true
                                                            })
                                                        )
                                                        delay(0).then(() =>
                                                            dispatch(selectLayer(image.id))
                                                        )
                                                    }
                                                }
                                            },
                                            {
                                                hidden: hideAvatarImage,
                                                icon: Avatar,
                                                text: 'Avatar',
                                                dataCy: 'editor-insert-avatar',
                                                endWithDivider: true,
                                                onClick: () => {
                                                    logToAnalytics('insert_image_clicked', {
                                                        playbookId,
                                                        type: 'avatar'
                                                    })

                                                    dispatch(addNewLayer(profileImage))
                                                    delay(0).then(() =>
                                                        dispatch(selectLayer(profileImage.id))
                                                    )
                                                }
                                            },
                                            {
                                                hidden: hideSpeakerImage,
                                                icon: SpeakerIcon,
                                                text: 'Speaker',
                                                dataCy: 'editor-insert-speaker',
                                                endWithDivider: true,
                                                onClick: () => {
                                                    logToAnalytics('insert_image_clicked', {
                                                        playbookId,
                                                        type: 'speaker'
                                                    })

                                                    dispatch(addNewLayer(speaker))
                                                    delay(0).then(() =>
                                                        dispatch(selectLayer(speaker.id))
                                                    )

                                                    // If we remove audio layer, we need to change user preference to avoid adding it by default after audioNote creation

                                                    if (audioNote?.type === 'speechToText') {
                                                        updateUserPreferences('s2tAvatar', true)
                                                    } else {
                                                        updateUserPreferences('t2sAvatar', true)
                                                    }
                                                }
                                            }
                                        ]}
                                        extraNestedItem={
                                            <FileUploader
                                                accept="image/png, image/jpeg"
                                                isTempStorage
                                                storagePath={`imageUploads/${uid}`}
                                                filename={`${playbookId}_${uuid()}`}
                                                hideAttachFile
                                                hidePreview
                                                progressStyle={{
                                                    position: 'absolute',
                                                    right: -40
                                                }}
                                                onDone={url => {
                                                    const img = new Image()
                                                    img.src = url
                                                    img.onload = () => {
                                                        dispatch(
                                                            addNewLayer({
                                                                ...image,
                                                                width: img.width * scale,
                                                                height: img.height * scale,
                                                                url
                                                            })
                                                        )
                                                        delay(0).then(() =>
                                                            dispatch(selectLayer(image.id))
                                                        )

                                                        loading.setFalse()
                                                        open.setFalse()
                                                    }
                                                }}
                                                onFileChange={loading.setTrue}
                                                labelComponent={
                                                    <Box
                                                        width="100%"
                                                        onClick={e => {
                                                            logToAnalytics('insert_image_clicked', {
                                                                playbookId,
                                                                type: 'uploadImage'
                                                            })

                                                            e.stopPropagation()
                                                        }}
                                                    >
                                                        <NestedMenuItem
                                                            icon={PublishIcon}
                                                            text="Upload image"
                                                            dataCy="image-button"
                                                        />
                                                    </Box>
                                                }
                                            />
                                        }
                                    />
                                    {kind === 'step' && bgImageLayer && (
                                        <NestedMenuItem
                                            icon={BlurLinearIcon}
                                            text="Blur"
                                            disabled={!bgImageLayer}
                                            dataCy="editor-insert-blur"
                                            onClick={() => {
                                                logToAnalytics('insert_blur_clicked', {
                                                    playbookId
                                                })

                                                if (!hasVideoBlur) {
                                                    return setBlurAnchorEl(anchorRef.current)
                                                }

                                                dispatch(addNewLayer(blur))
                                                delay(0).then(() => dispatch(selectLayer(blur.id)))
                                            }}
                                            extraIcon={
                                                <>
                                                    {!hasVideoBlur && (
                                                        <Box width={30}>
                                                            <TrophyIcon
                                                                fontSize="small"
                                                                style={{ color: '#cb0000' }}
                                                            />
                                                        </Box>
                                                    )}
                                                </>
                                            }
                                            endWithDivider
                                        />
                                    )}

                                    <NestedMenuItem
                                        icon={BlurCircularIcon}
                                        text="Overlay"
                                        dataCy="editor-insert-overlay"
                                        endWithDivider
                                        onClick={() => {
                                            logToAnalytics('insert_overlay_clicked', {
                                                playbookId
                                            })

                                            dispatch(addNewLayer(overlay))
                                            delay(0).then(() => dispatch(selectLayer(overlay.id)))
                                        }}
                                    />
                                </div>
                            )}
                            {!isBrandKitPage && (
                                <NestedMenuItem
                                    dataCy="editor-insert-action"
                                    icon={Action}
                                    text="Action"
                                    endWithDivider
                                    nestedItems={[
                                        {
                                            dataCy: 'editor-insert-single-action',
                                            endWithDivider: true,
                                            icon: SingleAction,
                                            text: 'Single action',
                                            tooltipText: hasCta.multiple
                                                ? MULTIPLE_CTA_TOOLTIP
                                                : '',
                                            disabled: hasCta.multiple,
                                            onClick: () => {
                                                logToAnalytics('insert_action_clicked', {
                                                    playbookId,
                                                    type: 'single'
                                                })

                                                dispatch(setStepCtaVisibility(true))

                                                dispatch(
                                                    setStepCtaAction({
                                                        action:
                                                            cta?.ctaType === 'single'
                                                                ? cta.action
                                                                : undefined,
                                                        isSingleCTA: true,
                                                        editItemId: 0
                                                    })
                                                )
                                            }
                                        },
                                        {
                                            dataCy: 'editor-insert-multi-action',
                                            icon: MultipleActionAction,
                                            text: 'Multiple actions',
                                            tooltipText: hasCta.single ? SINGLE_CTA_TOOLTIP : '',
                                            disabled: hasCta.single,
                                            extraIcon: (
                                                <>
                                                    {!hasMultipleCta && (
                                                        <Box width={30}>
                                                            <TrophyIcon
                                                                fontSize="small"
                                                                style={{ color: '#cb0000' }}
                                                            />
                                                        </Box>
                                                    )}
                                                </>
                                            ),
                                            onClick: () => {
                                                logToAnalytics('insert_action_clicked', {
                                                    playbookId,
                                                    type: 'multiple'
                                                })

                                                if (!hasMultipleCta) {
                                                    logToAnalytics('multipleCtaClicked', {
                                                        playbookId
                                                    })
                                                    return setActionsAnchorEl(anchorRef.current)
                                                }

                                                dispatch(setStepMultiApplyVisibility(false))
                                                dispatch(setStepMultiCtaVisibility(true))
                                                if (!cta) {
                                                    dispatch(
                                                        setCurrentCTA({
                                                            ctaType: 'multiple',
                                                            title: DEFAULT_HEADER,
                                                            actions: [DEFAULT_ACTION]
                                                        })
                                                    )
                                                }
                                            }
                                        }
                                    ]}
                                />
                            )}
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>

            <UpgradePlanPopup
                anchorEl={blurAnchorEl}
                setAnchorEl={setBlurAnchorEl}
                hideSeePlanFeatures
                points={[
                    'Remove sensitive content',
                    'Publish videos safely',
                    'Keep confidential information safe'
                ]}
                title="Unlock blurring"
                planId={planTypes.pro}
                text={
                    <>
                        Uh oh, captured sensitive content? <br /> Blur it out and worry no more.
                    </>
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            />

            <UpgradeActionsPopup
                anchorEl={actionsAnchorEl}
                setAnchorEl={setActionsAnchorEl}
                onClick={() => {
                    const query = new URLSearchParams()

                    // Add query string parameter to show the change plan dialog on
                    // team and billing tab, it will be removed in the receiving page
                    query.append('upgradePlanStep', '1')
                    query.append('planId', 'plan-guidde-business')
                    query.append('billingPeriod', 'MONTHLY')

                    history.replace({
                        search: `?${query.toString()}`
                    })
                    dispatch(showPlanDialog('upgradePlanDialog'))
                }}
            />
        </>
    )
}
