import { type MouseEvent } from 'react'

import { Typography, Radio, FormControlLabel } from '@mui/material'

type Props = {
    isActive: boolean
    onClick?: () => void
    labelText?: string
    validateClick: (e: MouseEvent<HTMLElement>, action: () => void) => void
}

export const RadioLabel = ({ isActive, labelText, onClick = () => {}, validateClick }: Props) => (
    <FormControlLabel
        control={
            <Radio
                size="small"
                checked={isActive}
                color="primary"
                style={{
                    color: isActive ? '#CB0000' : 'rgba(9, 12, 16, 0.40)'
                }}
                onClick={e => validateClick?.(e, onClick)}
            />
        }
        label={
            <Typography
                variant="caption"
                style={{
                    fontWeight: 500,
                    lineHeight: 0,
                    whiteSpace: 'nowrap',
                    color: isActive ? '#090C10' : 'rgba(9, 12, 16, 0.40)'
                }}
            >
                {labelText ? labelText : isActive ? 'Default' : 'Set as default'}
            </Typography>
        }
    />
)
