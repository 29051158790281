import { generatePath } from 'react-router-dom'
import { Document, Page, Image, StyleSheet, View, Link } from '@react-pdf/renderer'

import { filterIntoOutroSteps, getStepData, getStepNumberingColor } from 'UI/Components'

import { type StepType, type QuickGuiddeType, type SingleCtaType } from 'app/types'
import { paths } from 'app/paths'
import { links } from 'app/links'

import { buildCTAUrl } from 'UI/Routes/quick-guidde/CanvasEditor'

import { host } from 'modules'
import { WithInternationalFontFamily, registerFonts } from 'assets/fonts/pdfFonts'

// value param it a px which converts to PDF "pt" unit of measurement, 1pt = 1.333px
const pxToPt = (value: number) => Number(value / 1.333).toFixed(2)

const greyColor = 'rgba(9, 12, 16, 0.6)'

const textStyles = {
    fontSize: pxToPt(14),
    lineHeight: 1.15
}

const styles = StyleSheet.create({
    image: {
        minHeight: pxToPt(220),
        borderRadius: 12,
        overflow: 'hidden',
        objectFit: 'fill'
    },
    header: {
        fontSize: pxToPt(32),
        fontWeight: 600
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center'
    },
    divider: {
        backgroundColor: '#E0E0E0',
        height: 1,
        width: '100%',
        marginVertical: pxToPt(20)
    }
})

type StyledImageProps = {
    src?: string
}

const StyledImage = ({ src }: StyledImageProps) => <Image src={src} style={styles.image} />

type Props = {
    onRender: () => void
    playbook: QuickGuiddeType
    favIcon: string
    brandKitHighlightColor?: string
    hidePoweredByGuidde: boolean
}

export const PdfDocument = ({
    hidePoweredByGuidde,
    playbook,
    favIcon,
    onRender,
    brandKitHighlightColor
}: Props) => {
    registerFonts()

    const playbookPath = generatePath(paths.publicPlaybook, {
        playbookId: playbook.id
    })

    const filteredSteps = filterIntoOutroSteps(playbook.steps)

    // use the new image with play button if exists
    // only the first step has this field
    const imageUrl = filteredSteps[0]?.docCoverScreenshot || playbook.gifUrl

    const stepsNumberingColor = getStepNumberingColor(filteredSteps)

    const cover = filteredSteps.find(step => step.kind === 'cover') as StepType | undefined
    const coverCTA = cover?.cta?.ctaType === 'single' ? cover.cta.action : undefined

    const coverNote = cover?.audioNote?.text

    return (
        <Document onRender={onRender}>
            <Page
                size="A4"
                wrap
                style={{
                    paddingVertical: '2.97cm',
                    paddingHorizontal: '2.1cm'
                }}
            >
                <View style={styles.row}>
                    {favIcon && (
                        <Image
                            src={favIcon}
                            style={{
                                minHeight: pxToPt(32),
                                width: pxToPt(32)
                            }}
                        />
                    )}
                    <View style={{ width: pxToPt(16) }} />
                    <WithInternationalFontFamily style={styles.header}>
                        {playbook.title}
                    </WithInternationalFontFamily>
                </View>
                {hidePoweredByGuidde ? (
                    <View style={{ marginTop: pxToPt(16) }} />
                ) : (
                    <View style={{ marginTop: pxToPt(8), marginBottom: pxToPt(16) }}>
                        <PoweredByGuidde />
                    </View>
                )}

                <Link src={host + playbookPath}>
                    <StyledImage src={imageUrl} />
                </Link>

                {playbook.description && (
                    <>
                        <WithInternationalFontFamily
                            style={{
                                fontSize: pxToPt(12),
                                color: '#828282'
                            }}
                        >
                            {playbook.description.trim()}
                        </WithInternationalFontFamily>

                        <View style={styles.divider} />
                    </>
                )}

                {cover && (
                    <View>
                        {coverNote && (
                            <>
                                <WithInternationalFontFamily
                                    style={{
                                        marginVertical: pxToPt(16),
                                        ...textStyles,
                                        color: greyColor
                                    }}
                                >
                                    {coverNote}
                                </WithInternationalFontFamily>

                                {coverCTA && coverCTA.enabled && (
                                    <CTALink cta={coverCTA} playbookId={playbook.id} />
                                )}
                            </>
                        )}
                    </View>
                )}

                {filteredSteps?.map((step, idx) => {
                    if (step.kind === 'cover') return null

                    const currentCTA = step?.cta
                    const noteText = step.audioNote?.text

                    if (step.kind === 'end') {
                        return (
                            <View key={step.id + idx}>
                                <>
                                    {noteText && (
                                        <WithInternationalFontFamily
                                            style={{
                                                marginVertical: pxToPt(16),
                                                ...textStyles,
                                                color: greyColor
                                            }}
                                        >
                                            {noteText}
                                        </WithInternationalFontFamily>
                                    )}

                                    {currentCTA &&
                                        currentCTA.ctaType === 'single' &&
                                        currentCTA.action?.enabled && (
                                            <CTALink
                                                cta={currentCTA.action}
                                                playbookId={playbook.id}
                                            />
                                        )}
                                </>
                                {!hidePoweredByGuidde && (
                                    <View style={{ marginTop: 5 }}>
                                        <PoweredByGuidde />
                                    </View>
                                )}
                            </View>
                        )
                    }

                    const showGoTo = idx === (filteredSteps[0]?.kind === 'cover' ? 1 : 0)
                    const { stepTitle, stepGoTo, stepUrlChange } = getStepData(step)

                    return (
                        <View key={step.id + idx}>
                            {showGoTo && stepGoTo.host && (
                                <WithInternationalFontFamily
                                    style={{
                                        fontWeight: 500,
                                        ...textStyles,
                                        marginVertical: pxToPt(2)
                                    }}
                                >
                                    {playbook.goToLabel}{' '}
                                    <Link
                                        src={stepGoTo.href}
                                        style={{
                                            color: '#2D9CDB',
                                            textDecoration: 'none',
                                            fontWeight: 500,
                                            ...textStyles
                                        }}
                                    >
                                        {stepGoTo.host}
                                    </Link>
                                </WithInternationalFontFamily>
                            )}

                            <WithInternationalFontFamily
                                style={{
                                    fontSize: pxToPt(20),
                                    fontWeight: 500,
                                    marginVertical: pxToPt(16)
                                }}
                            >
                                <View>
                                    <WithInternationalFontFamily
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: brandKitHighlightColor,
                                            width: pxToPt(24),
                                            height: pxToPt(24),
                                            color: stepsNumberingColor,
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            fontSize: pxToPt(16)
                                        }}
                                    >
                                        {' '}
                                        {idx.toString().padStart(2, '0')}{' '}
                                    </WithInternationalFontFamily>
                                </View>{' '}
                                {stepTitle.replace(/\n/g, '')}
                            </WithInternationalFontFamily>

                            <View>
                                {stepUrlChange && (
                                    <Link
                                        src={`${step.url.includes('http') ? '' : '//'}${step.url}`}
                                    >
                                        <WithInternationalFontFamily
                                            style={{
                                                overflow: 'hidden',
                                                height: pxToPt(30),
                                                fontSize: pxToPt(20),
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {stepUrlChange}
                                        </WithInternationalFontFamily>
                                    </Link>
                                )}
                            </View>

                            {noteText && (
                                <WithInternationalFontFamily
                                    style={{
                                        ...textStyles,
                                        color: greyColor,
                                        marginBottom: pxToPt(16)
                                    }}
                                >
                                    {noteText}
                                </WithInternationalFontFamily>
                            )}

                            {currentCTA &&
                                currentCTA.ctaType === 'single' &&
                                currentCTA.action.enabled && (
                                    <CTALink cta={currentCTA.action} playbookId={playbook.id} />
                                )}

                            {currentCTA && currentCTA.ctaType === 'multiple' && (
                                <>
                                    <WithInternationalFontFamily
                                        style={{
                                            ...textStyles,
                                            marginBottom: pxToPt(18)
                                        }}
                                    >
                                        {currentCTA.title}
                                    </WithInternationalFontFamily>

                                    {currentCTA.actions.map((action, index) => (
                                        <CTALink
                                            key={index}
                                            cta={action}
                                            playbookId={playbook.id}
                                        />
                                    ))}
                                </>
                            )}

                            {(step.docScreenshot || step.drawnScreenshot) && (
                                <StyledImage
                                    src={`${
                                        step.docScreenshot || step.drawnScreenshot
                                    }&time=${new Date()}`}
                                />
                            )}
                        </View>
                    )
                })}
            </Page>
        </Document>
    )
}

const PoweredByGuidde = () => (
    <WithInternationalFontFamily>
        <WithInternationalFontFamily style={textStyles}>Powered by </WithInternationalFontFamily>
        <Link src={links.guiddeWebsite}>
            <WithInternationalFontFamily style={{ color: '#cb0000', ...textStyles }}>
                guidde
            </WithInternationalFontFamily>
        </Link>
    </WithInternationalFontFamily>
)

type CTALinkProps = {
    cta: SingleCtaType['action']
    playbookId: string
}
const CTALink = ({ cta, playbookId }: CTALinkProps) => {
    return (
        <Link
            src={buildCTAUrl(cta, playbookId)}
            style={{
                color: '#2D9CDB',
                textDecoration: 'none',
                fontWeight: 500,
                marginBottom: pxToPt(16),
                ...textStyles
            }}
        >
            {cta.text}
        </Link>
    )
}
