import { useMemo } from 'react'

import { type MappedSpaceType } from 'app/types'
import { useQuery } from './use-query'

type Response = {
    mySpaces: Array<MappedSpaceType>
    availableSpaces: Array<MappedSpaceType>
    hasUserEverCreatedSpace: boolean
}

export const useSpaces = () => {
    const { data, isValidating, mutate } = useQuery<Response>('/c/v1/spaces', {
        method: 'GET'
    })

    return useMemo(
        () => ({
            isInitialLoading: isValidating && !data,
            isLoading: isValidating,
            mySpaces: data?.mySpaces || [],
            availableSpaces: data?.availableSpaces || [],
            orgSpaces: data ? [...data.availableSpaces, ...data.mySpaces] : [],
            hasUserEverCreatedSpace: data?.hasUserEverCreatedSpace || false,
            revalidate: mutate
        }),
        [data, isValidating, mutate]
    )
}
