import { request, round } from './utils'
import { firebaseConfig } from 'env'

const getSignedUrl = async (
    storagePath: { url: string; withOrgId?: boolean } | string,
    fileType: string
) => {
    return await request('/c/v1/get-upload-url', 'POST', {
        contentType: fileType,
        filename: typeof storagePath === 'string' ? storagePath : storagePath.url,
        ...(typeof storagePath !== 'string' && {
            withOrgId: storagePath.withOrgId
        })
    })
}

export const uploadWithSignedUrl = async (
    storagePath: { url: string; withOrgId?: boolean } | string, // bucket + path
    file: Blob,
    onProgressChange?: (progress: number) => void
) => {
    const uploadUrl = await getSignedUrl(storagePath, file.type)
    const maskedUrl = uploadUrl.url.replace(
        'storage.googleapis.com',
        `gstorage.${firebaseConfig.authDomain}`
    )

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('PUT', maskedUrl, true)
        xhr.setRequestHeader('Content-Type', file.type)

        xhr.upload.onprogress = event => {
            if (!event.lengthComputable) return
            const percentComplete = round((event.loaded / event.total) * 100)
            onProgressChange?.(percentComplete)
        }

        xhr.onload = () => {
            if (xhr.status === 200) resolve({ status: 'OK', code: 200 })
            else console.error('Upload failed', xhr.status, xhr.statusText)
        }

        xhr.onerror = event => {
            reject({
                status: xhr.status,
                statusText: xhr.statusText,
                event
            })
        }

        xhr.send(file)
    })
}
