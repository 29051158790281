import { useMemo } from 'react'

import { Divider, Stack, Typography } from '@mui/material'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { type TotalMetrics } from 'app/types/insights'

import { formatViewNumber, formatWatchPercent, formatWatchTime } from './insights-utils'

const getMetricArrowState = (current?: number, previous?: number) => {
    if (current === undefined) return 'no-data'

    if (previous === undefined || current === previous) return 'empty'

    return current > previous ? 'increased' : 'decreased'
}

const getMetricArrow = (state: ReturnType<typeof getMetricArrowState>) => {
    switch (state) {
        case 'no-data': {
            return <ArrowForwardIcon sx={{ color: '#BDBDBD' }} />
        }
        case 'empty': {
            return null
        }
        case 'increased': {
            return (
                <ArrowOutwardIcon
                    sx={{
                        color: '#2D9CDB'
                    }}
                />
            )
        }

        case 'decreased': {
            return (
                <ArrowOutwardIcon
                    sx={{
                        transform: 'rotateX(180deg)',
                        color: 'primary.main'
                    }}
                />
            )
        }
    }
}

type Props = {
    totalMetrics: TotalMetrics | null
    totalMetricsPrevious?: TotalMetrics
    isSmall?: boolean
}

export const InsightsTotalMetrics = ({
    totalMetrics,
    totalMetricsPrevious,
    isSmall = false
}: Props) => {
    const metrics = useMemo(
        () =>
            [
                {
                    label: 'Total Views',
                    value: formatViewNumber(totalMetrics?.views ?? 0),
                    arrowState: getMetricArrowState(
                        totalMetrics?.views,
                        totalMetricsPrevious?.views
                    )
                },
                {
                    label: 'Unique viewers',
                    value: formatViewNumber(totalMetrics?.viewers ?? 0),
                    arrowState: getMetricArrowState(
                        totalMetrics?.viewers,
                        totalMetricsPrevious?.viewers
                    )
                },
                {
                    label: 'AVG. Watch time',
                    value: formatWatchTime(totalMetrics?.avgWatchTime ?? 0),
                    arrowState: getMetricArrowState(
                        totalMetrics?.avgWatchTime,
                        totalMetricsPrevious?.avgWatchTime
                    )
                },
                {
                    label: 'Avg. % Watched',
                    value: formatWatchPercent(totalMetrics?.avgWatched ?? 0),
                    arrowState: getMetricArrowState(
                        totalMetrics?.avgWatched,
                        totalMetricsPrevious?.avgWatched
                    )
                }
            ] as const,
        [totalMetrics, totalMetricsPrevious]
    )

    return (
        <Stack direction="row" spacing={1.5}>
            {metrics.map(metric => (
                <Stack direction="row" spacing={1.5} alignItems="center" key={metric.label}>
                    <Divider orientation="vertical" flexItem />
                    <Stack spacing={0.75} py={0.75} width={isSmall ? 'auto' : 180}>
                        <Typography fontSize={14} lineHeight="20px">
                            {metric.label}
                        </Typography>
                        <Stack
                            direction={isSmall ? 'column' : 'row'}
                            spacing={isSmall ? 0.25 : 1.5}
                            alignItems={isSmall ? 'flex-start' : 'center'}
                        >
                            {getMetricArrow(metric.arrowState)}
                            <Typography
                                fontSize={24}
                                fontWeight={500}
                                lineHeight={1}
                                letterSpacing="0.15px"
                                color={metric.arrowState === 'no-data' ? '#BDBDBD' : 'inherit'}
                            >
                                {metric.value}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            ))}
        </Stack>
    )
}
