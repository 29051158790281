import { type ReactNode, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators } from 'redux-undo'
import type Konva from 'konva'

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import { Box, Button, Dialog, LinearProgress, Typography } from '@mui/material'
import { SpacedGroup } from 'UI/Components'

import {
    Workspace,
    ControlPanel,
    DEFAULT_TEXT_LAYER,
    QgProvider,
    DEFAULT_BACKGROUND_COLOR_LAYER
} from 'UI/Routes/quick-guidde/CanvasEditor'
import { DEFAULT_STEP } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { useQgShortcuts } from 'UI/Routes/quick-guidde/hooks'

import { resetSteps, setQuickGuiddeSteps } from 'ducks'
import { isDeepEqual, uuid } from 'modules'
import { useBrandKit } from 'hooks'

import { type QuickGuiddeType, type StepType } from 'app/types'

type Props = {
    isOpen: boolean
    isLoading: boolean
    title: ReactNode
    kind: 'cover' | 'end'
    activeTemplate: StepType | null
    isAddMode: boolean
    onClose: () => void
    onSave: (step: StepType) => void
}
export const CoverEndingPreviewDialog = ({
    onClose,
    onSave,
    isAddMode,
    isLoading,
    activeTemplate,
    title,
    isOpen,
    kind
}: Props) => {
    const { brandKitColor } = useBrandKit()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const dispatch = useDispatch()

    const stageRef = useRef<Konva.Stage>(null)

    useQgShortcuts({ onSave: () => onSave(steps[activeStep]) })

    // dirty trick, need to use memo version to prevent unnecessary re-renders
    const fakeQG = useMemo(() => {
        if (isAddMode) {
            const title =
                kind === 'cover'
                    ? 'Your Title'
                    : kind === 'end'
                      ? 'Thank you'
                      : DEFAULT_TEXT_LAYER.title
            return {
                steps: [
                    {
                        ...DEFAULT_STEP,
                        id: uuid(),
                        timeStamp: Date.now(),
                        kind,
                        layers: [
                            {
                                ...DEFAULT_BACKGROUND_COLOR_LAYER,
                                width: DEFAULT_STEP.windowDimensions.innerWidth,
                                height: DEFAULT_STEP.windowDimensions.innerHeight,
                                id: uuid(),
                                fill: brandKitColor
                            },
                            {
                                ...DEFAULT_TEXT_LAYER,
                                title,

                                isTitle: true,
                                id: uuid()
                            }
                        ]
                    }
                ]
            } as unknown as QuickGuiddeType
        }

        if (activeTemplate) {
            return {
                steps: [activeTemplate]
            } as QuickGuiddeType
        }

        return null
    }, [activeTemplate, isAddMode, brandKitColor, kind])

    useEffect(() => {
        if (!fakeQG) return

        dispatch(setQuickGuiddeSteps(fakeQG.steps))
        dispatch(ActionCreators.clearHistory())

        return () => {
            dispatch(resetSteps())
            dispatch(ActionCreators.clearHistory())
        }
    }, [dispatch, fakeQG])

    // Either it's add new template which has at least one layer
    // or it's edit template which was changed

    const showActionButtons =
        Boolean(isAddMode && steps[activeStep]?.layers.length) ||
        Boolean(
            activeTemplate && steps[activeStep] && !isDeepEqual(activeTemplate, steps[activeStep])
        )

    if (!isOpen) return null

    return (
        <Dialog
            maxWidth="lg"
            open={true}
            onClose={onClose}
            PaperProps={{
                id: 'step-template', // used to enable shortcuts on brand kit page (use-qg-shortcuts)
                style: { position: 'initial' }
            }}
            disableEnforceFocus={true}
        >
            <>
                <Box height={4}> {isLoading && <LinearProgress />}</Box>
                <Box p={2} display="flex" justifyContent="space-between">
                    <SpacedGroup spacing={1}>
                        <ImageOutlinedIcon />
                        <Typography style={{ fontSize: 20, fontWeight: 500 }}>{title}</Typography>
                    </SpacedGroup>

                    {showActionButtons && (
                        <SpacedGroup>
                            <Button
                                disabled={isLoading}
                                color="inherit"
                                size="small"
                                variant="contained"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={isLoading}
                                size="small"
                                onClick={() => onSave(steps[activeStep])}
                                variant="contained"
                            >
                                Save changes
                            </Button>
                        </SpacedGroup>
                    )}
                </Box>

                {fakeQG && (
                    <QgProvider>
                        <Workspace stageRef={stageRef} />
                        <ControlPanel stageRef={stageRef} quickGuidde={fakeQG} />
                    </QgProvider>
                )}
            </>
        </Dialog>
    )
}
