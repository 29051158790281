import { type MouseEvent, type ReactNode } from 'react'

import { type BoxProps, Box, Tooltip } from '@mui/material'
import DefaultEditIcon from '@mui/icons-material/EditOutlined'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'

import { useRoles, useServiceUsage } from 'hooks'

import { type UpgradePlanProps } from 'UI/Components'
import { type IconType } from 'app/menu'

const actionContainerClass = 'ItemCardActionContainer'

type Props = {
    onEdit?: () => void
    onThumbnailClick?: () => void
    onDelete?: () => void
    label?: ReactNode
    showTooltip?: boolean
    tooltipText?: string
    disableDelete?: boolean
    disableEdit?: boolean
    hideDelete?: boolean
    disableLabelButton?: boolean
    divider?: boolean
    children?: ReactNode
    height?: BoxProps['height']
    width?: BoxProps['width']
    labelBgColor?: BoxProps['bgcolor']
    bgcolor?: BoxProps['bgcolor']
    popupProps: UpgradePlanProps & {
        validateClick: (e: MouseEvent<HTMLElement>, callback: () => void) => void
    }
    EditIcon?: IconType
}

export const ItemCard = ({
    label,
    onDelete,
    onEdit,
    onThumbnailClick,
    bgcolor,
    tooltipText = '',
    showTooltip,
    disableDelete,
    disableEdit,
    hideDelete = false,
    height = 120,
    width = 210,
    disableLabelButton,
    labelBgColor,
    divider,
    children = null,
    popupProps,
    EditIcon = DefaultEditIcon
}: Props) => {
    const { isSuperAdmin } = useRoles()

    const { isFreePlan } = useServiceUsage()

    const onUserClick = (e: MouseEvent<HTMLElement>, action: () => void) => {
        if (isFreePlan && !isSuperAdmin) {
            popupProps.setAnchorEl(e.currentTarget)
        } else {
            action()
        }
    }

    return (
        <Box
            border="1px solid rgba(9, 12, 16, 0.08)"
            borderRadius="4px"
            width={width}
            minWidth={width}
            overflow="hidden"
            sx={{
                [`& .${actionContainerClass}`]: {
                    visibility: 'hidden'
                },
                '&:hover': {
                    [`& .${actionContainerClass}`]: {
                        visibility: 'visible'
                    }
                }
            }}
        >
            <Box
                height={height}
                width="100%"
                position="relative"
                bgcolor={bgcolor || 'rgba(9, 12, 16, 0.1)'}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignContent="center"
                textAlign="center"
                style={{ cursor: onThumbnailClick ? 'pointer' : 'auto' }}
                onClick={onThumbnailClick}
            >
                {onEdit && (
                    <ActionContainer
                        position="absolute"
                        left="8px"
                        top="8px"
                        style={{
                            cursor: disableEdit ? 'auto' : 'pointer'
                        }}
                        onClick={e => {
                            e.stopPropagation()

                            if (disableEdit) return
                            onUserClick(e, onEdit)
                        }}
                    >
                        <EditIcon
                            data-test="brandkit-itemcard-edit-button"
                            style={{ color: 'white' }}
                        />
                    </ActionContainer>
                )}

                {onDelete && !hideDelete && (
                    <Tooltip
                        title={showTooltip ? tooltipText : ''}
                        style={{ zIndex: 5, marginBottom: 50 }}
                        placement="top-end"
                    >
                        <>
                            <ActionContainer
                                position="absolute"
                                style={{
                                    cursor: disableDelete ? 'auto' : 'pointer'
                                }}
                                right="8px"
                                top="8px"
                                onClick={e => {
                                    e.stopPropagation()

                                    if (disableDelete) return
                                    onUserClick(e, onDelete)
                                }}
                            >
                                <BinIcon style={{ color: 'white' }} />
                            </ActionContainer>
                        </>
                    </Tooltip>
                )}

                {children}
            </Box>

            {label && (
                <Box
                    width="100%"
                    height="42px"
                    display="flex"
                    alignItems="center"
                    px="12px"
                    position="relative"
                    fontWeight={500}
                    bgcolor={labelBgColor}
                    borderTop={divider ? '1px solid rgba(9, 12, 16, 0.08)' : ''}
                >
                    {disableLabelButton && (
                        <Box zIndex={2} width="100%" height="100%" position="absolute" />
                    )}
                    {label}
                </Box>
            )}
        </Box>
    )
}

type ActionContainerProps = {
    children: ReactNode
} & BoxProps

export const ActionContainer = ({ children, ...rest }: ActionContainerProps) => (
    <Box
        bgcolor="rgba(9, 12, 16, 0.4)"
        borderRadius="4px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        zIndex={3}
        p="2px"
        className={actionContainerClass}
        sx={{
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'rgba(9, 12, 16, 0.6)'
            }
        }}
        {...rest}
    >
        {children}
    </Box>
)
