import { memo } from 'react'

import { SERVICE_STATUS } from 'modules'

import {
    type LinearProgressProps,
    type TypographyProps,
    LinearProgress,
    Box,
    Typography,
    styled
} from '@mui/material'

const PREFIX = 'BorderLinearProgress'

const classes = {
    root: `${PREFIX}-root`,
    bold: `${PREFIX}-bold`,
    label: `${PREFIX}-label`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.root}`]: {
        height: 8,
        marginTop: '12px',
        '&.MuiLinearProgress-colorPrimary': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey[200]
        },
        '& .MuiLinearProgress-bar': {
            borderRadius: 4
        }
    },

    [`& .${classes.bold}`]: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 'inherit'
    },

    [`& .${classes.label}`]: {
        fontWeight: 500
    }
}))

type ServiceProps = Partial<{
    variant: LinearProgressProps['variant']
    value: number
    color: TypographyProps['color']
}>

const getServiceState = (status: Props['serviceStatus']): ServiceProps => {
    switch (status) {
        case SERVICE_STATUS.PENDING:
            return { variant: 'determinate', value: 0, color: 'textSecondary' }
        case SERVICE_STATUS.IN_PROGRESS:
            return { variant: 'indeterminate', color: 'error' }
        case SERVICE_STATUS.SKIPPED:
        case SERVICE_STATUS.DONE:
        case SERVICE_STATUS.ERROR:
            return { variant: 'determinate', value: 100, color: 'error' }
        default:
            return { variant: 'determinate', value: 100, color: 'inherit' }
    }
}

type Props = {
    title: string
    serviceStatus: (typeof SERVICE_STATUS)[keyof typeof SERVICE_STATUS]
}

export const BorderLinearProgress = memo(({ title, serviceStatus }: Props) => {
    const { variant, value, color } = getServiceState(serviceStatus)

    return (
        <StyledBox width="100px">
            <Typography className={classes.bold} color={color} variant="body2">
                {title}
            </Typography>
            <LinearProgress className={classes.root} variant={variant} value={value} />
        </StyledBox>
    )
})
