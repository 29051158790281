import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, Stack, Tooltip } from '@mui/material'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'

import { DEFAULT_BACKGROUND_COLOR_LAYER } from 'UI/Routes/quick-guidde/CanvasEditor/use-layers'
import { generateColorRows, includesDifferentColors } from 'UI/Routes/brand-kit/sections'
import { getGradientPoints } from './BackgroundSelection'
import { ImageCropper } from 'UI/Components'

import { isDeepEqual, uuid } from 'modules'
import { useAuth, useBrandKit, useGlobalFeatureFlags } from 'hooks'

import { setBackgroundLayer } from 'ducks'
import {
    type BrandKitBackgroundType,
    type ImageAsBackgroundType,
    type RectangleShapeType
} from 'app/types'

const borderStyle = '1px solid rgba(9, 12, 16, 0.08)'
const itemSize = 30
const itemsPerRow = 14
const uploadBgImage = 'uploadBgImage'

type Props = {
    bgRectLayer?: RectangleShapeType
    applyToAll: boolean
}

export const Gradients = ({ bgRectLayer, applyToAll }: Props) => {
    const dispatch = useDispatch()

    const { brandKitBackgrounds } = useBrandKit()
    const { enableImageAsBackground } = useGlobalFeatureFlags()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const {
        windowDimensions: { innerWidth, innerHeight },
        kind
    } = steps[activeStep] || {}

    const activeGradient = bgRectLayer?.fillLinearGradientColorStops

    const colorGroups = useMemo(() => {
        const imagesAsBackground: Array<ImageAsBackgroundType> = []
        const multipleColors: Array<BrandKitBackgroundType> = []
        const singleColors: Array<BrandKitBackgroundType> = []

        brandKitBackgrounds.forEach(color => {
            if ('gradient' in color && !Boolean(color.gradient)) return // Ignore no-background option

            if ('url' in color) {
                imagesAsBackground.push(color)
                return
            }

            const target = includesDifferentColors(color.gradient) ? multipleColors : singleColors
            target.push(color)
        })

        return [
            // We show image as a background section for regular steps only
            ...(kind === 'step' && enableImageAsBackground
                ? [generateColorRows([...imagesAsBackground, uploadBgImage])]
                : []),
            generateColorRows([...singleColors], itemsPerRow),
            generateColorRows([...multipleColors], itemsPerRow)
        ]
    }, [brandKitBackgrounds, enableImageAsBackground, kind])

    const updateBackgroundLayer = useCallback(
        (extraProps: Partial<RectangleShapeType>) => {
            const baseRectLayer = {
                ...DEFAULT_BACKGROUND_COLOR_LAYER,
                id: uuid(),
                width: innerWidth,
                height: innerHeight
            }

            dispatch(
                setBackgroundLayer(
                    {
                        ...baseRectLayer,
                        ...extraProps
                    },
                    applyToAll
                )
            )
        },
        [applyToAll, dispatch, innerHeight, innerWidth]
    )

    /* Single & Multiple colors group */
    return colorGroups.map((group, groupIdx) => (
        <Box mt={2} key={groupIdx}>
            {/* Color rows inside a group */}
            {group.map((colorRow, colorRowIdx) => (
                <Stack
                    key={colorRowIdx}
                    direction="row"
                    flexWrap="wrap"
                    gap={typeof colorRow[0] !== 'string' && 'url' in colorRow[0] ? 0.5 : 'unset'}
                    border={
                        typeof colorRow[0] !== 'string' && 'url' in colorRow[0]
                            ? 'none'
                            : borderStyle
                    }
                    borderTop={colorRowIdx === 0 ? '' : 'none'}
                    borderRadius={0.5}
                    width="fit-content"
                >
                    {colorRow.map((color, colorIdx) => {
                        const isLast = colorIdx === colorRow.length - 1

                        // Image as background
                        if (
                            (typeof color === 'string' && color === 'uploadBgImage') ||
                            'url' in color
                        ) {
                            return (
                                <ImageAsBackgroundItem
                                    key={colorIdx}
                                    item={color}
                                    isActive={
                                        typeof color !== 'string' && color.url === bgRectLayer?.url
                                    }
                                    windowDimensions={{ innerWidth, innerHeight }}
                                    onImageUpload={url => {
                                        updateBackgroundLayer({
                                            url,
                                            scaleFactor: bgRectLayer?.scaleFactor || 0.8
                                        })
                                    }}
                                    onClick={() => {
                                        if (typeof color === 'string') return

                                        updateBackgroundLayer({
                                            scaleFactor: color.scaleFactor,
                                            url: color.url
                                        })
                                    }}
                                />
                            )
                        }

                        // Gradient color
                        const isActiveGradient = isDeepEqual(activeGradient, color.canvasGradient)

                        return (
                            <GradientItem
                                key={colorIdx}
                                item={color}
                                isActive={isActiveGradient}
                                borderRightColor={isLast ? 'transparent' : 'rgba(9, 12, 16, 0.08)'}
                                onClick={() => {
                                    const {
                                        fillLinearGradientStartPoint,
                                        fillLinearGradientEndPoint
                                    } = getGradientPoints(innerWidth, innerHeight, color.angle)

                                    updateBackgroundLayer({
                                        fillLinearGradientStartPoint,
                                        fillLinearGradientEndPoint,
                                        fillLinearGradientColorStops: color.canvasGradient
                                    })
                                }}
                            />
                        )
                    })}
                </Stack>
            ))}
        </Box>
    ))
}

type ImageAsBackgroundItemProps = {
    isActive: boolean
    item: ImageAsBackgroundType | typeof uploadBgImage
    onClick: () => void
    windowDimensions: {
        innerWidth: number
        innerHeight: number
    }
    onImageUpload: (url: string) => void
}

const ImageAsBackgroundItem = ({
    item,
    isActive,
    windowDimensions,
    onClick,
    onImageUpload
}: ImageAsBackgroundItemProps) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    const { uid } = useAuth()

    if (item === uploadBgImage) {
        return (
            <ImageCropper
                aspectRatio={windowDimensions.innerWidth / windowDimensions.innerHeight}
                isTempStorage
                storagePath={`imageUploads/${uid}/${playbookId}_${uuid()}`}
                labelComponent={
                    <Tooltip title="Upload image as background">
                        <Box
                            width={80}
                            height={45}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            border={borderStyle}
                            borderRadius="4px"
                        >
                            <AddPhotoAlternateOutlinedIcon fontSize="medium" />
                        </Box>
                    </Tooltip>
                }
                onDone={({ url }) => onImageUpload(url)}
            />
        )
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={80}
            height={45}
            p={0.5}
            borderRadius="4px"
            border={isActive ? '1px solid #cb0000' : borderStyle}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            <img
                height="100%"
                width="100%"
                src={item.url}
                alt="bg_image"
                style={{ objectFit: 'fill' }}
            />
        </Box>
    )
}

type GradientItemProps = {
    isActive: boolean
    borderRightColor: string
    item: Exclude<BrandKitBackgroundType, ImageAsBackgroundType>
    onClick: () => void
}

const GradientItem = ({ item, isActive, borderRightColor, onClick }: GradientItemProps) => {
    return (
        <Box
            sx={{ cursor: isActive ? 'auto' : 'pointer' }}
            bgcolor={isActive ? 'rgba(203, 0, 0, 0.1)' : 'transparent'}
            width={itemSize}
            height={itemSize}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRight={`1px solid ${borderRightColor}`}
            onClick={onClick}
        >
            <Box
                width="20px"
                height="20px"
                style={{ background: item.gradient }}
                border={isActive ? `2px solid rgba(203, 0, 0, 1)` : 'none'}
                borderRadius="50%"
            />
        </Box>
    )
}
