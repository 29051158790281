import { memo } from 'react'

import { type SpaceType } from 'app/types'

import { useDataMutation, useNotification } from 'hooks'

import { type SubmitDataType, CommonSpaceDialog } from './common'
import { mutate } from 'swr'

type Props = {
    title: string
    isOpen: boolean
    space: SpaceType
    onClose: () => void
    editCallback?: () => void
}

type EditSpaceType = {
    spaceId: string
    data: SubmitDataType
}

export const EditSpaceDialog = memo(({ isOpen, onClose, editCallback, space }: Props) => {
    const { showErrorNotification, showSuccessNotification } = useNotification()

    const { name, description, isPrivate, color = '' } = space

    const $editSpace = useDataMutation<EditSpaceType, {}, Error>('/c/v1/space/edit', 'POST', {
        onSuccess: () => {
            showSuccessNotification('Space was successfully updated')

            mutate(['/c/v1/space/favourite'])
            onClose()
            editCallback?.()
        },
        onFailure: () => {
            showErrorNotification('Something went wrong. Please check permissions and try again')
        }
    })

    return (
        <CommonSpaceDialog
            onSubmit={data => {
                $editSpace.mutate({
                    spaceId: space.id,
                    data
                })
            }}
            isOpen={isOpen}
            onClose={onClose}
            title="Edit space"
            initialValues={{
                name,
                description,
                isPrivate,
                color
            }}
            spaceId={space.id}
            isLoading={$editSpace.isLoading}
        />
    )
})
