import { useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import dayjs from 'dayjs'

import { IconButton, Paper } from '@mui/material'
import { type GridCellParams, DataGrid } from '@mui/x-data-grid'

import { Link as LinkIcon } from '@mui/icons-material'
import { ReactComponent as DeleteIcon } from 'assets/icons/bin.svg'

import { SpacedGroup, ConfirmationDialog, CopyLinkedGif } from 'UI/Components'

import { useAuth, useCustomerDomain, useDataMutation, useNotification } from 'hooks'
import { copyToClipboard, logToAnalytics, playbookToAnalyticsProps } from 'modules'

import { paths } from 'app/paths'
import {
    type PlaybookTrackingLinkType,
    type PlaybookType,
    type PlaylistType,
    PlaybookMode
} from 'app/types'

type TableProps = {
    playbook: PlaybookType | PlaylistType
    trackingLinks: Array<PlaybookTrackingLinkType>
    onDelete: () => void
    isLoading: boolean
}

type CreateLinkProps = {
    playbookId: string
    isPlaylist: boolean
    name: string
    linkId: string
    origin: string
    domain: string
}

const createLink = ({ playbookId, isPlaylist, linkId, origin, name, domain }: CreateLinkProps) => {
    const path = generatePath(isPlaylist ? paths.publicPlaylist : paths.publicPlaybook, {
        playbookId
    })

    return `${domain}${path}?track_link_name=${name}&track_link_id=${linkId}&origin=${origin}`
}

export const TrackLinksTable = ({ playbook, trackingLinks, isLoading, onDelete }: TableProps) => {
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const { generateDomain } = useCustomerDomain()

    const { uid } = useAuth()

    const [trackingLinkToRemove, setTrackingLinkToRemove] =
        useState<PlaybookTrackingLinkType | null>(null)

    const [page, setPage] = useState(0)

    const handleCopyLink = (link: PlaybookTrackingLinkType) => {
        const { id: linkId, name, domain, uid: linkUid } = link

        const companyLink = createLink({
            playbookId: playbook.id,
            isPlaylist: playbook.mode === PlaybookMode.Playlist,
            linkId,
            origin: linkUid || uid,
            name,
            domain: generateDomain(playbook)
        })

        window.companyLink = companyLink

        logToAnalytics('copyLink', {
            track_name: name,
            track_domain: domain || '',
            utm_campaign: name,
            context: 'track-link',
            ...playbookToAnalyticsProps(playbook)
        })

        copyToClipboard(companyLink).then(() => {
            showSuccessNotification('Link copied to the clipboard')
        })
    }

    const $deleteTrackingLink = useDataMutation<void, { message: string }, Error>(
        `/c/v1/tracking-link?id=${trackingLinkToRemove?.id}`,
        'DELETE',
        {
            onSuccess: onDelete
        }
    )

    const rows = useMemo(
        () => trackingLinks.sort((a, b) => (b?.createdAt > a?.createdAt ? 1 : -1)) || [],
        [trackingLinks]
    )

    return (
        <>
            <Paper sx={{ width: '100%', marginBottom: 8, boxShadow: 'none' }}>
                <DataGrid
                    key={JSON.stringify(rows)}
                    sx={{
                        background: '#F5F5F5',
                        border: 'none',
                        '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
                            outline: 'none!important'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden'
                        }
                    }}
                    loading={isLoading}
                    autoHeight
                    rows={rows}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Name',
                            width: 140
                        },
                        {
                            field: 'domain',
                            headerName: 'Domain',
                            width: 140
                        },
                        {
                            field: 'createdAt',
                            headerName: 'Created on',
                            width: 140,
                            renderCell: ({ row }: GridCellParams) =>
                                dayjs(row.createdAt).format('MMM DD YYYY')
                        },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            sortable: false,
                            width: 130,
                            renderCell: ({ row }: any) => (
                                <SpacedGroup spacing={1}>
                                    <IconButton
                                        size="small"
                                        title="Copy link"
                                        onClick={() => handleCopyLink(row)}
                                    >
                                        <LinkIcon
                                            data-test="tracking-table-copy-link"
                                            style={{ color: '#090C10' }}
                                        />
                                    </IconButton>
                                    <CopyLinkedGif
                                        playbook={playbook}
                                        color="#090C10"
                                        trackLinkName={row.name}
                                        trackLinkId={row.id}
                                    />
                                    <IconButton
                                        size="small"
                                        title="Remove Link"
                                        onClick={() => setTrackingLinkToRemove(row)}
                                    >
                                        <DeleteIcon style={{ color: '#090C10' }} />
                                    </IconButton>
                                </SpacedGroup>
                            )
                        }
                    ]}
                    sortingOrder={['asc', 'desc']}
                    disableColumnMenu
                    getRowId={row => row?.id}
                    disableColumnFilter
                    disableRowSelectionOnClick
                    onPaginationModelChange={({ page }) => setPage(page)}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                                page
                            }
                        }
                    }}
                />
            </Paper>

            <ConfirmationDialog
                title="Remove Link"
                text={`Are you sure you want to remove the ${trackingLinkToRemove?.name} link?`}
                isOpen={Boolean(trackingLinkToRemove)}
                onClose={() => {
                    setTrackingLinkToRemove(null)
                    setPage(0)
                }}
                onConfirm={() => {
                    $deleteTrackingLink
                        .mutate()
                        .then(() => {
                            logToAnalytics('delete_tracking_link', {
                                track_name: trackingLinkToRemove?.name,
                                track_domain: trackingLinkToRemove?.domain || '',
                                utm_campaign: trackingLinkToRemove?.name
                            })
                            setTrackingLinkToRemove(null)
                        })
                        .catch(() =>
                            showErrorNotification('Something went wrong, Please try again')
                        )
                }}
                loading={$deleteTrackingLink.isLoading}
                confirmBtnText="Delete"
            />
        </>
    )
}
